import * as fromRoot from '../../app.reducer';

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MissingTranslationService {
  constructor(private _store: Store<fromRoot.State>) {}

  getLanguage() {
    let activeLanguage: string;

    this._store
      .select(fromRoot.getLanguage)
      .pipe(filter(data => !!data))
      .subscribe(language => {
        activeLanguage = language;
      });

    return activeLanguage;
  }

  getSupportedLanguages() {
    let supportedLanguages: string[];

    this._store
      .select(fromRoot.getSupportedLanguages)
      .pipe(filter(languages => languages && languages.length > 0))
      .subscribe(languages => {
        supportedLanguages = languages;
      });

    return supportedLanguages;
  }
}
