import { Directive, Input, OnInit } from '@angular/core';
import { Calendar, LocaleSettings } from 'primeng/calendar';
import { FormControlName } from '@angular/forms';
import {
  getLocaleMonthNames,
  getLocaleDateFormat,
  getLocaleDayNames
} from '@angular/common';

import { Store } from '@ngrx/store';

import * as fromRoot from '../../app.reducer';

@Directive({
  selector: '[calendarCustomDefaults]'
})
export class CalendarCustomDefaultsDirective implements OnInit {
  @Input()
  minToday: boolean;
  @Input()
  minDate: Date;

  locale: LocaleSettings = {
    firstDayOfWeek: 1,
    dayNames: [],
    dayNamesShort: [],
    dayNamesMin: [],
    monthNames: [],
    monthNamesShort: [],
    today: null,
    clear: null
  };

  constructor(
    // private formControl: FormControlName,
    private calendar: Calendar,
    private store: Store<fromRoot.State>
  ) {
    // Narrow = 0,
    // Abbreviated = 1,
    // Wide = 2,
    // Short = 3

    this.store.select(fromRoot.getLanguage).subscribe(activeLanguage => {
      this.locale = {
        ...this.locale,
        monthNames: getLocaleMonthNames(activeLanguage, 0, 2),
        monthNamesShort: getLocaleMonthNames(activeLanguage, 0, 1),
        dayNames: getLocaleDayNames(activeLanguage, 0, 2),
        dayNamesShort: getLocaleDayNames(activeLanguage, 0, 3),
        dayNamesMin: getLocaleDayNames(activeLanguage, 0, 0)
      };

      this.calendar.locale = this.locale;
      this.calendar.dateFormat = activeLanguage === 'en' ? 'dd.mm.yy' : getLocaleDateFormat(activeLanguage, 0).toLowerCase();
      
    });
  }

  ngOnInit() {
    if (this.minToday && !this.minDate) {
      this.calendar.minDate = new Date();
    }
  }
}
