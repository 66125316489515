import { Injectable } from '@angular/core';
import * as signalR from '@aspnet/signalr';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { consoleLog } from '../app-utils';

@Injectable({
  providedIn: 'root'
})
export class SignalrService {
  public connection: signalR.HubConnection;
  public connected$ = new BehaviorSubject(false);
  public reconnecting$ = new BehaviorSubject(false);
  private signalRUrl: string;

  private _handlers: { [key: string]: { [key: string]: any } } = {};

  constructor() {
    this.signalRUrl = `${this.getSignalROrigin()}/websockets/notification`;
    this.start();
  }

  start(isReconnect : boolean = false) {
    consoleLog(`Connecting to SignalR at: ${this.signalRUrl}`);

    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(this.signalRUrl)
      .build();

    this.connection
      .start()
      .then(() => {
        this.connected$.next(true);
        this.reconnecting$.next(false);

        if (isReconnect) {
          this.reregisterAllHandlers();
        }

        this.reconnect();
      })
      .catch(() => {
        this.connected$.next(false);
        this.reconnecting$.next(true);
        setTimeout(() => {
          this.start(isReconnect);
        }, 10000);
      });
  }
  
  private getSignalROrigin() {
    //On a server the SignalR is deployed under the virtual directory "signalr", on localhost isn't
    if (environment.origin.includes('localhost')) {
      return "https://localhost:44354";
    } else {
      return environment.protocol + environment.origin + '/signalr';
    }
  }

  reconnect() {
    this.connection.onclose(() => {
      this.connected$.next(false);
      this.reconnecting$.next(true);
      this.start(true);
    });
  }

  disconnect() {
    this.connection.stop().then(() => {
      consoleLog('Disconnecting from SignalR...');
      this.connected$.next(false);
      this.reconnecting$.next(false);
    });
  }

  /**
   * Invoke method on the server
   * Returns promise which resolves another Promise with server response
   * @param methodName
   * @param args
   */
  invoke(methodName: string, ...args): Promise<Promise<any>> {
    const unsubscribe$ = new Subject<void>();
    const alreadyConnected = this.connected$.getValue();

    // make sure that connection is available when invoking method
    const invokePromise = new Promise<Promise<any>>(resolve => {
      if (alreadyConnected) {
        resolve(this.connection.invoke(methodName, ...args));
      } else {
        this.connected$.takeUntil(unsubscribe$).subscribe(connected => {
          if (connected) {
            unsubscribe$.next();
            unsubscribe$.complete();
            resolve(this.connection.invoke(methodName, ...args));
          }
        });
      }
    });

    return invokePromise;
  }

  /**
   * This function reregisters all existing handlers if we're reconnecting to SignalR service.
   * Otherwise handlers would become invalid.
   */
  reregisterAllHandlers() {
    if (this._handlers) {
      Object.keys(this._handlers).forEach(componentName => {
        const component = this._handlers[componentName];

        Object.keys(component).forEach(methodName => {
          const method = component[methodName];
          this.registerHandler(componentName, methodName, method);
        });
      });
    }
  }

  /**
   * This function will register new handler and stores its reference to _handlers object for easier access and removal of handler.
   * If handler is already registered, it will remove this handler and registers new.
   *
   * @param {String} componentName - NOTE: In case of a class you can use this.constructor.name. Name of the component where handler is registered
   * @param {String} methodName - Name of the method that is invoked
   * @param {Function} callback - The handler that will be raised when the hub method is invoked.
   */
  registerHandler(
    componentName: string,
    methodName: string,
    callback: (...args: any[]) => void
  ) {
    const componentHandlers = this._handlers[componentName];
    const currentSubscription =
      componentHandlers && componentHandlers[methodName];

    currentSubscription && this.connection.off(methodName);

    this._handlers[componentName] = { ...componentHandlers };
    this._handlers[componentName][methodName] = callback;
    this.connection.on(methodName, callback);
  }

  /**
   * This function will remove handler and also remove its reference from _handlers object.
   * If no `methodName` is provided, all handlers for provided component name are removed
   *
   * @param {String} fromComponent - Name of the component to remove handlers from
   * @param {String} fromMethod - Name of the method to remove handlers for - Pass `fromMethod` if you want to remove specific handler; Otherwise all handlers are removed
   */
  removeHandler(fromComponent: string, fromMethod?: string) {
    const componentHandlers = this._handlers[fromComponent];
    const hasHandlers = componentHandlers && Object.keys(componentHandlers);

    if (!hasHandlers) {
      return;
    }

    // remove all form component
    if (!fromMethod) {
      for (const methodName in this._handlers[fromComponent]) {
        const handler = this._handlers[fromComponent][methodName];
        handler && this.connection.off(methodName);
      }

      delete this._handlers[fromComponent];
    } else {
      // remove single
      const handler = componentHandlers[fromMethod];

      handler && this.connection.off(fromMethod);

      delete componentHandlers[fromMethod];
    }
  }
}
