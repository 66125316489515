/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./event-passed.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../shared/pipes/hide-translation/hide-translation.pipe";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./event-passed.component";
var styles_EventPassedComponent = [i0.styles];
var RenderType_EventPassedComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EventPassedComponent, data: {} });
export { RenderType_EventPassedComponent as RenderType_EventPassedComponent };
export function View_EventPassedComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.HideTranslationPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "page-padding-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "h2", [["data-translation", "event-passed.is-over"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(5, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 5, 0, i1.ɵnov(_v, 0), i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("event-passed.is-over")))); _ck(_v, 3, 0, currVal_0); }); }
export function View_EventPassedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-event-passed", [], null, null, null, View_EventPassedComponent_0, RenderType_EventPassedComponent)), i1.ɵdid(1, 114688, null, 0, i4.EventPassedComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EventPassedComponentNgFactory = i1.ɵccf("app-event-passed", i4.EventPassedComponent, View_EventPassedComponent_Host_0, {}, {}, []);
export { EventPassedComponentNgFactory as EventPassedComponentNgFactory };
