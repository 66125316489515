import { first, filter } from 'rxjs/operators';
import * as additionalServicesActions from '../../shared/services-with-reducers/additional-services/additional-services.actions';
import * as fromRoot from '../../app.reducer';

import { Component } from '@angular/core';
import { CustomizationService } from '../../shared/services-with-reducers/customization/customization.service';
import { MenuModel } from '../../shared/services-with-reducers/additional-services/additional-services.interface';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import cloneDeep from 'lodash.clonedeep';

@Component({
  moduleId: module.id,
  selector: 'app-web-shop-menu',
  templateUrl: './web-shop-menu.component.html',
  styleUrls: ['./web-shop-menu.component.scss']
})
export class WebShopMenuComponent {
  public menuListForDisplay: MenuModel[];
  public menuListToSave: MenuModel[];

  constructor(
    private _store: Store<fromRoot.State>,
    private _customizationService: CustomizationService
  ) {
    this._store
      .select(fromRoot.getMenuOptions)
      .pipe(
        filter(data => {
          // if there are no data for this exhibition yet, load them
          if (data.length === 0) {
            this.loadMenuViaApi();
          }
          return data.length > 0;
        }),
        first()
      ) // only go for the first one with data
      .subscribe(menuListData => {
        this.menuListForDisplay = menuListData;
        this.menuListToSave = cloneDeep(menuListData);
      });
  }

  loadMenuViaApi() {
    this._store
      .select(fromRoot.getSelectedExhibitionId)
      .pipe(first())
      .subscribe(eventId => {
        this._store.dispatch(
          new additionalServicesActions.GetMenuOptions(eventId)
        );
      });
  }

  onMenuSelection(menu, item) {
    const isValid = true;

    this.menuListToSave = this.menuListToSave.map(menuData => {
      const { id, selectedMenu } = menuData;

      if (item.id === id) {
        return {
          ...menuData,
          selectedMenu: menu.id
        };
      } else {
        return menuData;
      }
    });

    this._store.dispatch(
      new additionalServicesActions.SetMenu(this.menuListToSave)
    );
  }
}
