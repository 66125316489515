import * as fromRoot from '../../app.reducer';

import { Component, OnInit, OnDestroy } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { ErrorHandlingService } from '../../shared/error-handling/error-handling.service';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { StatusBarService } from '../../status-bar/status-bar.service';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { filter, first } from 'rxjs/operators';
import { CustomizationService } from '../../shared/services-with-reducers/customization/customization.service';
import { HelperService } from '../../shared/services-with-reducers/helpers/helper.service';

import * as customizationActions from '../../shared/services-with-reducers/customization/customization.actions';

@Component({
  selector: 'app-data-protection-page',
  templateUrl: './data-protection-page.component.html',
  styleUrls: ['./data-protection-page.component.scss']
})
export class DataProtectionPageComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription = new Subscription();
  private browserLanguage: string = '';
  private currentLanguage: string = '';
  private pageLanguage: string = '';
  private userLanguage: string = '';
  private eventId: string | number;
  private paramEventId: number;
  private isAdmin: boolean;
  public isAdmin$: Observable<boolean>;
  public htmlContent: string = '';
  public activeTab: any = '';
  public tabs: Array<any> = [
    { id: 'home', title: 'Events Overview Page', text: '' }
  ];
  

  constructor(
    private _http: HttpClient,
    private _errorHandlingService: ErrorHandlingService,
    private _translateService: TranslateService,
    private _store: Store<fromRoot.State>,
    private _statusBarService: StatusBarService,
    private _activatedRoute: ActivatedRoute,
    private _customizationService: CustomizationService,
    private _helperService: HelperService
  ) {}

  ngOnInit() {
    this._subscriptions.add(
      this._store.pipe(select(fromRoot.getUserLanguage)).subscribe(userLanguage => {
        this.userLanguage = userLanguage;
      })
    );

    this.browserLanguage = this._translateService.getBrowserLang();

    this._subscriptions.add(
      this._activatedRoute.params.subscribe(params => {
        this.paramEventId = +params.id;
      })
    );
    
    this._subscriptions.add(
      this._activatedRoute.url.subscribe(url => {
        if (url[0].path == "translations") {
          this.isAdmin$ = this._store.pipe(select(fromRoot.isLoggedInAsAdmin));
          this.isAdmin$.pipe(first()).subscribe(isAdmin => (this.isAdmin = isAdmin));
        } else {
          this.isAdmin = false;
        }
      })
    );

    // We have to get the event settings because we don't have them while clicking on data-privacy link and opening a new window
    if (!!this.paramEventId) {
      this._store.dispatch(
        new customizationActions.GetExhibitionSettings(+this.paramEventId)
      );
    }

    this._store.dispatch(
      new customizationActions.GetOperatorsSettings()
    );
    
    this._subscriptions.add(
      this._translateService.onLangChange.subscribe(() => {
        this.loadHTML();       
      })
    );

    this._subscriptions.add(
      this._store
        .pipe(
          select(fromRoot.getSelectedExhibitionId),
          filter(eventId => !!eventId)
        )
        .subscribe(eventId => {
          this.eventId = eventId;
        })
    );

    this._subscriptions.add(
      this._store
        .pipe(
          select(fromRoot.getLanguage),
          filter(data => !!data)
        )
        .subscribe(() => {
          this.loadHTML();
        })
    );
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  loadHTML() {
    const lang = this.userLanguage || this.browserLanguage;
    
    if (!!this.paramEventId) {
      this.eventId = this.paramEventId;
    }

    // In case the active tab is 'home', we don't want to go back to event level 
    // data-protection because we were already redirected to operator level data-protection page.
    if (this.activeTab === 'home') {
      this.eventId = '';
    }

    if (lang !== this.currentLanguage && lang !== this.pageLanguage) {
      this.loadOperatorDataProtectionPage(lang, this.eventId);
    } else if (!!this.pageLanguage) {    
      // We have to set the top-bar language to the already loaded one in case there is no data-protection page for the requested language
      this._helperService.setLanguage(this.pageLanguage);
    }
  }

  changeActiveTab(eventId: string | number) {
    if (!!eventId && this.activeTab != 'event') {
      this.tabs.pop();
      this.tabs.push({ id: 'event', title: 'Event', text: '' });
      this.activeTab = 'event';
    } else if (!eventId && this.activeTab != 'home'){
      this.tabs.pop();
      this.tabs.push({ id: 'home', title: 'Home', text: '' });
      this.activeTab = 'home';
    }
  }

  loadOperatorDataProtectionPage(lang: string, eventId: string | number = '', tryToLoadOperatorTranslation: boolean = true) {
    this.currentLanguage = lang;    

    this.changeActiveTab(eventId);
        
    combineLatest([
      this._customizationService.getOperatorSettings(),
      this._customizationService.getCustomSettings(eventId)
    ])
    .first()
    .subscribe(([operatorSettings, eventSettings]) => {
      const eventOrOperatorLanguages = tryToLoadOperatorTranslation && !!eventId ? eventSettings.languages : operatorSettings.languages;
      const supportedLanguages = !this.isAdmin ? eventOrOperatorLanguages : [lang];
      const languages = supportedLanguages.reduce((accu, curr) => {
        return accu + `&languages=${curr}`;
      }, '');

      if (!this.isAdmin) {
        this._helperService.setSupportedLanguages(supportedLanguages);
      }
            
      this._http
        .get(
          `${environment.protocol}${environment.webApiUrl}/pages/data-protection/data-protection${eventId}/${lang}?${languages}`
        )
        .map((res: { body: string, pageLanguage: string }) => {
          try {
            return res;
          } catch (err) {
            this._statusBarService.setStatus(err, 'error');
          }
        })
        .catch(e => {
          return this._errorHandlingService.errorHandler(e);
        })
        .subscribe((resp: { body: string, pageLanguage: string, requestedLanguage: string }) => {
          const { body, pageLanguage, requestedLanguage } = resp;

          // We have to check is the currentLanguage the same as the requestedLanguage sent to the API,
          // because we can get the default language response later because of the size of it and then set the page to the wrong API response
          if (this.currentLanguage == requestedLanguage && this.pageLanguage !== pageLanguage || !!this.isAdmin) {
            this.pageLanguage = pageLanguage;
            this.htmlContent = body || "";
            this._helperService.setLanguage(pageLanguage);
          }

          // Admin needs to load data-protection page even if it is not defined. In case a regular user is on event level, event level data-protection loads.
          // In case the event data protection is empty load operator data protection for regular user and for Admin in case he came on data-protection via the link
          if (!this.htmlContent && !this.isAdmin && tryToLoadOperatorTranslation) {
            this.loadOperatorDataProtectionPage(lang, '', false);
          }
        });
    });
  }
}
