var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import * as additionalServicesActions from '../../shared/services-with-reducers/additional-services/additional-services.actions';
import * as fromRoot from '../../app.reducer';
import { FormInputsPayloadModel, TicketHolderAdditionalDataModel } from '../../shared/services-with-reducers/step-forms/step.interface';
import { AfterViewInit, OnDestroy, OnInit, Renderer } from '@angular/core';
import { Subscription, combineLatest as observableCombineLatest, fromEvent as observableFromEvent, of as observableOf, Subject, BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { filter, first, throttleTime, debounceTime } from 'rxjs/operators';
import { AppConstants } from '../../shared/app-constants';
import { CustomizationService } from '../../shared/services-with-reducers/customization/customization.service';
import { FormsService } from '../../shared/forms/forms.service';
import { HelperService } from '../../shared/services-with-reducers/helpers/helper.service';
import { StepsFormsService } from '../../shared/services-with-reducers/step-forms/steps-forms.service';
import { WindowSizeService } from '../../shared/window-size/window-size.service';
import { environment } from '../../../environments/environment';
var WebShopWorkshopComponent = /** @class */ (function () {
    function WebShopWorkshopComponent(_store, _customizationService, _formsService, _windowSizeService, _stepsFormsService, _helperService, _renderer) {
        this._store = _store;
        this._customizationService = _customizationService;
        this._formsService = _formsService;
        this._windowSizeService = _windowSizeService;
        this._stepsFormsService = _stepsFormsService;
        this._helperService = _helperService;
        this._renderer = _renderer;
        this.workshopsByDates = {};
        this.workshopsByDatesArray = [];
        this.workshopsLoaded = false;
        this.hourHeighInPixels = 160;
        this.hourMinHeightInPixels = 120;
        this.activeWorkshopId = null;
        this.ticketsWithHolders = new BehaviorSubject([]);
        this.environment = environment;
        this.stepsFormsActionName = ['workshop', 'validation'];
        this.isWorkshopMandatory = false;
        this.limitWorkshopPerTicket = 1;
        this.isWorkshopLimitPerTicketReached = false;
        this.workshopMandatoryForZeroPriceTickets = false;
        this.isWorkshopSelected = false;
        this.hideDateAndTime = false;
        this.toggleDetailWindow = false;
        this.isMobile = false;
        this.elementHasEventListener = false;
        this.roomWidth = 220;
        this.showFakeScrollBar = false;
        this.workshopBookings = [];
        this.showAmountOfAvailableWorkshops = true;
        this.hideWorkshopDate = true;
        this.enableWorkshopSelectionOverlapping = false;
        this.scrollLeft = new Subject();
        this._subscriptions = new Subscription();
        this.getDayBorderHours = function (workshops) {
            return workshops
                .map(function (workshop) {
                var start = new Date(workshop.start);
                var end = new Date(workshop.end);
                return {
                    start: start.getHours(),
                    end: end.getHours() + 1 // +1 so we cover also hour only expressed by minutes
                };
            })
                .reduce(function (acc, workshop) {
                return {
                    earliest: acc.earliest < workshop.start ? acc.earliest : workshop.start,
                    latest: acc.latest > workshop.end ? acc.latest : workshop.end
                };
            }, {
                earliest: 24,
                latest: 0
            });
        };
        this.origin = environment.protocol + environment.origin;
        this.origin = this.origin || window.location.origin;
    }
    WebShopWorkshopComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.workshopsLoaded = false;
        this._subscriptions.add(this._store
            .select(fromRoot.getExhibitionSettings)
            .pipe(filter(function (data) { return !!data; }))
            .subscribe(function (settings) {
            _this.settings = settings;
            _this.enableWorkshopSelectionOverlapping =
                settings.enableWorkshopSelectionOverlapping;
            _this.showAmountOfAvailableWorkshops =
                settings.showAmountOfAvailableWorkshops;
            _this.hideWorkshopDate = settings.hideWorkshopDate;
        }));
        this.scrollLeft.pipe(debounceTime(100)).subscribe(function (scroll) {
            scroll.el.scrollLeft = scroll.fakeScrollLeft;
        });
        //this._formsService.setFormValidity(true, null, ['workshop', 'validation']);
        this._formsService.setStepValid(['workshop', 'validation']);
        // in case we are in angular universal
        if (typeof window !== 'undefined') {
            var resize = observableFromEvent(window, 'resize');
            this.result = resize.pipe(throttleTime(100));
        }
        else {
            this.result = observableOf(null);
        }
        this._subscriptions.add(this._store
            .select(fromRoot.getWorkshopBookings)
            .pipe(filter(function (booking) { return !!booking; }))
            .subscribe(function (bookings) {
            _this.workshopBookings = bookings.bookings;
        }));
        /**
         * CREATE THE RIGHT STRUCTURE OF DATA TO BE SHOWN
         **/
        this._subscriptions.add(this._store
            .select(fromRoot.getSelectedExhibitionId)
            .subscribe(function (eventId) {
            _this._store.dispatch(new additionalServicesActions.GetWorkshops(eventId));
            if (!!eventId) {
                if (eventId.toString() === '99') {
                    _this.hideDateAndTime = true;
                }
            }
            _this.workshopsList$ = _this._store.select(fromRoot.getWorkshops);
            _this._store
                .select(fromRoot.getExhibitionSettings)
                .pipe(first(), filter(function (data) { return !!data; }))
                .subscribe(function (settings) {
                _this.isWorkshopMandatory = settings.isWorkshopsSelectionMandatory;
                _this.limitWorkshopPerTicket = settings.workshopsPerTicket;
                _this.workshopMandatoryForZeroPriceTickets =
                    settings.workshopMandatoryForZeroPriceTickets;
            });
            observableCombineLatest(_this.workshopsList$, _this._store.select(fromRoot.idsOfWorkshopsToShowInWorkshopScreen))
                .pipe(first(function (data) { return !!data[0].length && !!data[1]; }))
                .subscribe(function (data) {
                var workshops = data[0], idsOfWorkshopsToShowInWorkshopScreen = data[1];
                _this.workshops = workshops;
                var allSchedulesByTime = _this.workshops.sort(function (a, b) {
                    return (new Date(a.start).getTime() - new Date(b.start).getTime());
                });
                var workshopsByDates = {};
                allSchedulesByTime.forEach(function (workshop) {
                    workshop.disabled = !idsOfWorkshopsToShowInWorkshopScreen.includes(workshop.workshopId);
                    // check if the workshop starts befor it ends (avoid bugs in BE)
                    if (new Date(workshop.start).getTime() <
                        new Date(workshop.end).getTime()) {
                        //
                        var day = new Date(workshop.date).toLocaleDateString('en-US');
                        // this dayString is used as key of workshopsByDates variable, also serves for showing date in template
                        workshopsByDates = _this.addWorkshopToDay(workshopsByDates, day, workshop);
                    }
                });
                var _loop_1 = function (dayKey) {
                    if (workshopsByDates.hasOwnProperty(dayKey)) {
                        var day_1 = workshopsByDates[dayKey];
                        day_1.todayWorkshops = day_1.todayWorkshops.filter(function (workshop) {
                            return (!workshop.disabled ||
                                (workshop.disabled &&
                                    !_this.settings.showWorkshopsForSelectedTicketsOnly));
                        });
                        var dayBorderHours_1 = _this.getDayBorderHours(day_1.todayWorkshops);
                        var workshopTimeLineItems = [];
                        for (var hour = dayBorderHours_1.earliest; hour < dayBorderHours_1.latest; hour++) {
                            if (_this.isAnyWorkshopInHour(hour, day_1)) {
                                workshopTimeLineItems.push({
                                    hourStart: hour,
                                    hourEnd: null,
                                    hourHeight: _this.hourHeighInPixels,
                                    displayEndTime: false
                                });
                            }
                            else {
                                var last = workshopTimeLineItems.slice(-1)[0];
                                if (!last.hourEnd) {
                                    workshopTimeLineItems.push({
                                        hourStart: hour,
                                        hourEnd: hour,
                                        hourHeight: _this.hourHeighInPixels,
                                        displayEndTime: false
                                    });
                                }
                                else {
                                    last.hourEnd = last.hourEnd + 1;
                                    last.displayEndTime = true;
                                }
                            }
                        }
                        day_1.timeLineList = workshopTimeLineItems;
                        // + 60 for header
                        day_1.timeLineWrapHeight = day_1.timeLineList.reduce(function (wrapHeight, timeLineItem) {
                            return wrapHeight + timeLineItem.hourHeight;
                        }, 60);
                        // add position styles to workshops
                        day_1.todayWorkshops = day_1.todayWorkshops.map(function (workshop) {
                            var positionStyles = _this.positionEvent(dayBorderHours_1.earliest, workshop.start, workshop.end, day_1.todayWorkshops, day_1.timeLineList);
                            return __assign({}, workshop, { styles: positionStyles });
                        });
                        // workshops can overlap this will reposition workshops and adjust the hour line
                        _this.repositionOverlappingWorkshops(day_1);
                        day_1.rooms = day_1.todayWorkshops
                            .map(function (workshop) { return workshop.roomId; }) // map to roomIds
                            .filter(function (value, index, self) {
                            // returns list of unique roomIds
                            return self.indexOf(value) === index;
                        })
                            .map(function (roomId) {
                            var roomName = _this.getRoomName(workshops, roomId);
                            return {
                                roomId: roomId,
                                roomName: roomName,
                                roomSortOrder: day_1.todayWorkshops.find(function (workshop) { return workshop.roomId === roomId; }).roomSortOrder,
                                workshops: day_1.todayWorkshops.filter(function (workshop) { return workshop.roomId === roomId; })
                            };
                        })
                            .filter(function (room) {
                            return room.workshops.length > 0;
                        })
                            // first sort by number
                            .sort(function (a, b) {
                            if (a.roomName.toLowerCase() < b.roomName.toLowerCase())
                                return -1;
                            if (a.roomName.toLowerCase() > b.roomName.toLowerCase())
                                return 1;
                            return 0;
                        })
                            // adjust sorting by sort order
                            .sort(function (a, b) {
                            return a.roomSortOrder - b.roomSortOrder;
                        });
                    }
                };
                for (var dayKey in workshopsByDates) {
                    _loop_1(dayKey);
                }
                _this.workshopsByDates = workshopsByDates;
                _this.workshopsByDatesArray = [];
                Object.keys(workshopsByDates).forEach(function (key, index) {
                    workshopsByDates[key].expanded = true;
                    _this.workshopsByDatesArray.push(workshopsByDates[key]);
                });
                _this.workshopsByDatesArray = _this.workshopsByDatesArray
                    .filter(function (p) { return p.todayWorkshops.length > 0; })
                    .sort(function (a, b) {
                    return (new Date(a.date).getTime() - new Date(b.date).getTime());
                });
                _this.workshopsLoaded = true;
                _this.setWorkshopsSidePosition(null);
            });
        }));
        this.breakpoints = AppConstants.BREAKPOINTS;
        this.mediumBreakpoint = this.breakpoints['sm'];
        this.result.startWith(null).subscribe(function () {
            _this.viewport = _this._windowSizeService.viewportWidth();
        });
        /**
         * handle visitors and their subscription to workshops
         * */
        this._subscriptions.add(observableCombineLatest(this._store.select(fromRoot.getTickets), this._store.select(fromRoot.getTicketsTypes), this._store.select(fromRoot.getTicketHolderInputSets), this._store.select(fromRoot.getTicketHolderAdditionalData), this._store.select(fromRoot.getTicketsCount))
            .pipe(filter(function (data) {
            // TODO: REFACTOR!!!!
            return (!!data[0] &&
                !!data[1] &&
                !!data[2] &&
                data[2].length &&
                !!data[3] &&
                !!data[4]);
        }))
            .subscribe(function (data) {
            var ungroupedTickets = data[0], ticketTypes = data[1], ticketHolderInputSets = data[2], ticketHolderAdditionalData = data[3], ticketCount = data[4];
            var ticketsWithHolders = _this._stepsFormsService.assignUngroupedTicketsToHolders(ungroupedTickets, ticketHolderInputSets, ticketHolderAdditionalData);
            _this.ticketsWithHolders.next(ticketsWithHolders);
            _this.isWorkshopSelected = Object.keys(ticketsWithHolders)
                .map(function (item) {
                var ticket = ticketsWithHolders[item];
                if (ticket.ticketCount === 0)
                    return true;
                if (!ticket.ticketHasAllowedWorkshops)
                    return true;
                return ticket.ticketHolderAdditionalData.workshops.length > 0;
            })
                .every(function (array) {
                if (array) {
                    return true;
                }
            });
            var isWorkshopSelectedForFreeTickets = Object.keys(ticketsWithHolders)
                .map(function (item) {
                var ticket = ticketsWithHolders[item];
                if (ticket.ticketCount === 0)
                    return true;
                if (!ticket.ticketHasAllowedWorkshops)
                    return true;
                if (ticket.ticketPrice > 0)
                    return true;
                return ticket.ticketHolderAdditionalData.workshops.length > 0;
            })
                .every(function (array) {
                if (array) {
                    return true;
                }
            });
            if (_this.isWorkshopMandatory ||
                _this.workshopMandatoryForZeroPriceTickets) {
                var mandatoryValid = !_this.isWorkshopMandatory ||
                    (_this.isWorkshopMandatory && _this.isWorkshopSelected);
                var zeroPriceValid = !_this.workshopMandatoryForZeroPriceTickets ||
                    (_this.workshopMandatoryForZeroPriceTickets &&
                        isWorkshopSelectedForFreeTickets);
                var validationMessage = !mandatoryValid
                    ? 'workshop.not-selected'
                    : !zeroPriceValid
                        ? 'workshop.not-zero-selected'
                        : null;
                _this.setValidation(mandatoryValid && zeroPriceValid, validationMessage);
            }
        }));
    };
    WebShopWorkshopComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        Object.keys(this.workshopsByDates).forEach(function (day, index) {
            _this.scrollHorizontaly(index);
        });
    };
    WebShopWorkshopComponent.prototype.ngOnDestroy = function () {
        this._subscriptions.unsubscribe();
    };
    WebShopWorkshopComponent.prototype.getRoomName = function (workshops, roomId) {
        return workshops.find(function (workshop) {
            return workshop.roomId === roomId;
        }).roomName;
    };
    WebShopWorkshopComponent.prototype.addWorkshopToDay = function (workshops, date, workshop) {
        if (workshops.hasOwnProperty(date)) {
            workshops[date].todayWorkshops.push(workshop);
        }
        else {
            workshops[date] = {
                date: new Date(workshop.date).toISOString(),
                todayWorkshops: [workshop],
                timeLineWrapHeight: 0,
                timeLineList: [],
                rooms: []
            };
        }
        return workshops;
    };
    WebShopWorkshopComponent.prototype.isAnyWorkshopInHour = function (hour, workshops) {
        return workshops.todayWorkshops.some(function (workshop) {
            var start = new Date(workshop.start);
            var end = new Date(workshop.end);
            return start.getHours() <= hour && end.getHours() >= hour;
        });
    };
    WebShopWorkshopComponent.prototype.positionEvent = function (earliestWorkshop, workshopStart, workshopEnd, todayWorkshops, timelineList) {
        var earliestMinutes = earliestWorkshop * 60;
        var start = new Date(workshopStart);
        var end = new Date(workshopEnd);
        var workshopStartMinutes = start.getHours() * 60 + start.getMinutes();
        var workshopEndMinutes = end.getHours() * 60 + end.getMinutes();
        var workshopGridGap = 14;
        var workshopTopOffset = this.getWorkshopTopOffset(start.getHours(), workshopStartMinutes, earliestMinutes, timelineList);
        var workshopHeightOffset = this.getRelativeHourHeight(workshopEndMinutes - workshopStartMinutes);
        if (workshopHeightOffset < this.hourMinHeightInPixels) {
            workshopTopOffset = workshopTopOffset;
            // if (start.getHours() == timelineList[0].hour && ) {
            //   workshopTopOffset = workshopTopOffset + 400;
            // }
        }
        if (workshopHeightOffset < this.hourMinHeightInPixels) {
            workshopHeightOffset = this.hourMinHeightInPixels + workshopGridGap;
        }
        // this.hourMinHeightInPixels;
        var top = 60 + workshopTopOffset + workshopGridGap / 2 + 'px';
        var height = workshopHeightOffset - workshopGridGap + 'px';
        return { top: top, minHeight: height };
    };
    WebShopWorkshopComponent.prototype.repositionOverlappingWorkshops = function (dayData) {
        var gap = 26;
        var rerun = true;
        var _loop_2 = function () {
            rerun = false;
            var additionalOffset = 0;
            var timesOfIncrease = 0;
            var overlappingHour = null;
            var overlappingInRoom = null;
            var _loop_3 = function (i) {
                var prevWorkshop = dayData.todayWorkshops[i];
                for (var j = 0; j < dayData.todayWorkshops.length; j++) {
                    var currWorkshop = dayData.todayWorkshops[j];
                    var inSameRoom = prevWorkshop.roomId == currWorkshop.roomId;
                    var notSameWorkshop = prevWorkshop.workshopId != currWorkshop.workshopId;
                    var prevWorkshopStyles = prevWorkshop.styles;
                    var previousWorkshopTopPosition = parseInt(prevWorkshopStyles.top);
                    var prevWorkshopBottomPosition = previousWorkshopTopPosition +
                        parseInt(prevWorkshopStyles.minHeight);
                    var currWorkshopStyles = currWorkshop.styles;
                    var currWorkshopTopPosition = parseInt(currWorkshopStyles.top);
                    var currWorkshopBottomPosition = currWorkshopTopPosition + parseInt(currWorkshopStyles.minHeight);
                    var workshopOverlap = prevWorkshopBottomPosition > currWorkshopTopPosition &&
                        previousWorkshopTopPosition < currWorkshopBottomPosition;
                    if (notSameWorkshop && inSameRoom && workshopOverlap) {
                        var overlapArea = prevWorkshopBottomPosition - currWorkshopTopPosition;
                        if (timesOfIncrease >= 1) {
                            currWorkshop.styles.top = currWorkshopTopPosition +
                                additionalOffset +
                                gap +
                                overlapArea +
                                1 + "px";
                        }
                        else {
                            currWorkshop.styles.top = currWorkshopTopPosition +
                                overlapArea +
                                1 + "px";
                        }
                        currWorkshop.styles.top = currWorkshopTopPosition +
                            overlapArea +
                            1 + "px";
                        additionalOffset = parseInt(currWorkshopStyles.minHeight) - gap;
                        overlappingHour = new Date(currWorkshop.start).getHours();
                        overlappingInRoom = currWorkshop.roomId;
                        timesOfIncrease += 1;
                    }
                }
                if (additionalOffset) {
                    additionalOffset = additionalOffset / timesOfIncrease;
                    dayData.todayWorkshops.forEach(function (currWorkshop, currIndex) {
                        var inSameRoom = overlappingInRoom === currWorkshop.roomId;
                        var notSameWorkshop = prevWorkshop.workshopId != currWorkshop.workshopId;
                        var workshopHour = new Date(currWorkshop.start).getHours();
                        var sameHour = overlappingHour == workshopHour;
                        var nextWorkshopHour = workshopHour > overlappingHour;
                        var prevDuration = Number(new Date(prevWorkshop.end)) -
                            Number(new Date(prevWorkshop.start));
                        var currDuration = Number(new Date(currWorkshop.end)) -
                            Number(new Date(currWorkshop.start));
                        if (notSameWorkshop && additionalOffset && nextWorkshopHour) {
                            currWorkshop.styles.top = parseInt(currWorkshop.styles.top) +
                                additionalOffset +
                                1 + "px";
                        }
                        if (notSameWorkshop &&
                            !inSameRoom &&
                            additionalOffset &&
                            sameHour &&
                            prevDuration <= currDuration) {
                            currWorkshop.styles.minHeight = parseInt(currWorkshop.styles.minHeight) +
                                additionalOffset +
                                1 + "px";
                        }
                        var currentWorkshopEnd = new Date(currWorkshop.end);
                        var exceedsOverlappingHour = currentWorkshopEnd.getHours() > overlappingHour;
                        if (notSameWorkshop &&
                            additionalOffset &&
                            sameHour &&
                            exceedsOverlappingHour) {
                            var exceedingMinutes = currentWorkshopEnd.getMinutes();
                            var exceedingDelta = (160 * exceedingMinutes) / 60;
                            additionalOffset = additionalOffset - exceedingDelta;
                        }
                    });
                    dayData.timeLineList.find(function (hourLine, index) {
                        if (hourLine.hourStart === overlappingHour) {
                            hourLine.hourHeight = hourLine.hourHeight + additionalOffset;
                            dayData.timeLineList[index] = hourLine;
                            return true;
                        }
                    });
                    dayData.timeLineWrapHeight =
                        dayData.timeLineWrapHeight + additionalOffset;
                    rerun = true;
                    return "break";
                }
            };
            for (var i = 0; i < dayData.todayWorkshops.length; i++) {
                var state_1 = _loop_3(i);
                if (state_1 === "break")
                    break;
            }
        };
        while (rerun) {
            _loop_2();
        }
        return dayData;
    };
    WebShopWorkshopComponent.prototype.getWorkshopTopOffset = function (workshopStartHour, workshopStartMinutes, earliestMinutes, timeLineList) {
        var topOffset = workshopStartMinutes - earliestMinutes;
        for (var i = 0; i < timeLineList.length; i++) {
            var timeLineItem = timeLineList[i];
            if (timeLineItem.hourStart === workshopStartHour)
                break;
            if (timeLineItem.hourEnd) {
                topOffset =
                    topOffset - (timeLineItem.hourEnd - timeLineItem.hourStart) * 60;
            }
        }
        return (topOffset / 60) * this.hourHeighInPixels;
    };
    WebShopWorkshopComponent.prototype.getRelativeHourHeight = function (delta) {
        return (delta / 60) * this.hourHeighInPixels; // divede by sixty to get hour then turn it to pixels
    };
    WebShopWorkshopComponent.prototype.getWorkshopSidePosition = function (workshop, rooms, side) {
        var _this = this;
        var noOfRoomsThisDay = rooms.length;
        var response = '0';
        rooms.forEach(function (room, index) {
            room.workshops.forEach(function (workshopInDay) {
                if (workshopInDay === workshop) {
                    if (side === 'left') {
                        response = _this.roomWidth * index + 5 + 'px';
                    }
                    else if (side === 'right') {
                        response = _this.roomWidth * (noOfRoomsThisDay - index - 1) + 'px';
                    }
                }
            });
        });
        return response;
    };
    WebShopWorkshopComponent.prototype.setWorkshopsSidePosition = function (activeWorkshopId) {
        var _this = this;
        var _loop_4 = function (dayKey) {
            if (this_1.workshopsByDates.hasOwnProperty(dayKey)) {
                var day_2 = this_1.workshopsByDates[dayKey];
                this_1.isMobile = this_1._helperService.isMobile();
                day_2.todayWorkshops.forEach(function (workshop) {
                    if (activeWorkshopId !== null &&
                        activeWorkshopId === workshop.workshopId &&
                        _this.isMobile) {
                        workshop.styles.left = 0;
                        workshop.styles.right = 0;
                        workshop.styles.zIndex = 23;
                        //workshop.styles.minHeight = '300px'; commented out because it caused smaller images to scale according to minHeight
                        workshop.styles.maxWidth = '100%';
                    }
                    else {
                        workshop.styles.left = _this.getWorkshopSidePosition(workshop, day_2.rooms, 'left');
                        workshop.styles.right = _this.getWorkshopSidePosition(workshop, day_2.rooms, 'right');
                        workshop.styles.zIndex = 22;
                        //workshop.styles.minHeight = workshop.styles.height;
                        workshop.styles.width = '200px';
                    }
                });
            }
        };
        var this_1 = this;
        for (var dayKey in this.workshopsByDates) {
            _loop_4(dayKey);
        }
    };
    WebShopWorkshopComponent.prototype.scrollHorizontaly = function (index) {
        var workshopWrapper = document.getElementById('workshopWrapper' + index);
        var workshopRoom = document.getElementById('rooms' + index);
        var fakeScrollBarWrapper = document.getElementById('fakeScrollbarWrapper' + index);
        var fakeScrollBar = document.getElementById('fakeScrollbar' + index);
        if (!workshopWrapper)
            return;
        var timeColumnWidth = 50; //Configured width in css
        var timeColumnWidthOffset = workshopWrapper.offsetWidth - workshopRoom.offsetWidth; // Real rendered width
        if (workshopRoom.scrollWidth > workshopRoom.offsetWidth &&
            !this.isMobile &&
            !this.elementHasEventListener) {
            if (!this._helperService.isMobile()) {
                fakeScrollBar.style.display = 'block';
            }
            var fakeScrollBarChildDiv = fakeScrollBar.children[0];
            this._renderer.setElementStyle(fakeScrollBarChildDiv, 'width', (workshopRoom.scrollWidth - timeColumnWidth).toString() + 'px');
            // Move fake scrollbar right to be same width as content scrollbar due to bar synchronization
            this._renderer.setElementStyle(fakeScrollBarWrapper, 'margin-left', timeColumnWidthOffset.toString() + 'px');
            workshopRoom.addEventListener('mousewheel', function (event) {
                var delta = Math.max(-1, Math.min(1, event.wheelDelta || -event.detail));
                workshopRoom.scrollLeft -= delta * 50;
                fakeScrollBar.scrollLeft = workshopRoom.scrollLeft;
                event.preventDefault();
            }, false);
            workshopRoom.addEventListener('scroll', function (event) {
                fakeScrollBar.scrollLeft = workshopRoom.scrollLeft;
                event.preventDefault();
            }, false);
            this.elementHasEventListener = true;
        }
    };
    WebShopWorkshopComponent.prototype.scrollWorkshopRoomWithFakeScrollbar = function (fakeScrollElement, workshopRoom) {
        this.scrollLeft.next({
            fakeScrollLeft: fakeScrollElement.scrollLeft,
            el: workshopRoom
        });
    };
    WebShopWorkshopComponent.prototype.scrollFakeScrollBar = function (fakeScrollElement, workshopRoom) {
        this.scrollLeft.next({
            fakeScrollLeft: workshopRoom.scrollLeft,
            el: fakeScrollElement
        });
    };
    WebShopWorkshopComponent.prototype.setValidation = function (isValid, validationMessage) {
        if (isValid) {
            this._formsService.removeStepValidationFeedback(this.stepsFormsActionName, 'workshop');
        }
        else {
            this._formsService.addStepValidationFeedback(this.stepsFormsActionName, 'workshop', validationMessage);
        }
        this._formsService.setFormValidity(isValid, null, this.stepsFormsActionName);
    };
    WebShopWorkshopComponent.prototype.toggleDetail = function (activeWorkshopId) {
        this.toggledWorkshop = this.workshops.find(function (workshop) { return workshop.workshopId === activeWorkshopId; });
        if (!!activeWorkshopId) {
            this.toggledWorkshop.top = window.pageYOffset + 100;
        }
        this.activeWorkshopId =
            this.activeWorkshopId === null ? activeWorkshopId : null;
        this.setWorkshopsSidePosition(this.activeWorkshopId);
        this.toggleDetailWindow = !this.toggleDetailWindow;
    };
    WebShopWorkshopComponent.prototype.toggleWorkshopAccordian = function (event, workshop) {
        workshop.expanded = !workshop.expanded;
    };
    WebShopWorkshopComponent.prototype.navigateToDay = function (day) {
        var element = document.getElementById(this.generateDayId(day));
        element.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest'
        });
    };
    WebShopWorkshopComponent.prototype.generateDayId = function (day) {
        return "day" + day;
    };
    WebShopWorkshopComponent.prototype.getScrollY = function () {
        return window.pageYOffset || document.body.scrollTop;
    };
    WebShopWorkshopComponent.prototype.scrollDown = function () {
        var target = this.getScrollY() +
            Math.max(document.documentElement.clientHeight, window.innerHeight || 250);
        this.scrollToTarget(target);
    };
    WebShopWorkshopComponent.prototype.scrollUp = function () {
        var target = this.getScrollY() -
            Math.max(document.documentElement.clientHeight, window.innerHeight || 250);
        this.scrollToTarget(target);
    };
    WebShopWorkshopComponent.prototype.scrollToTarget = function (target) {
        var supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
        if (supportsNativeSmoothScroll) {
            window.scrollTo({
                top: target,
                behavior: 'smooth'
            });
        }
        else {
            window.scroll(window.pageXOffset, target);
        }
    };
    WebShopWorkshopComponent.prototype.convertFromTime = function (date) {
        if (date instanceof Date) {
            var text = ('0' + date.getHours()).substr(-2) + ":" + ('0' + date.getMinutes()).substr(-2);
            var dateString = "2020-01-01T" + text + ":00.000Z";
            return new Date(dateString);
        }
        return new Date(date + ".000Z");
    };
    return WebShopWorkshopComponent;
}());
export { WebShopWorkshopComponent };
