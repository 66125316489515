<div class="basket-wrap" *ngIf="(ticketCount$ | async) && settings">
  <div *ngFor="let group of ticketsWithHolders">
    <div *ngFor="let package of group.packages">
      <div class="ticket-group package-ticket-group" *ngIf="package.ticketTypeGroup.length > 0">
        <div class="package-topbar">
          <div class="package-name">{{ group.packageName | translate | hideTranslation }}</div>

          <div>
            {{ package.price.toFixed(2) | currencySymbolGap: settings.currencySettings }}
          </div>
        </div>
        <div class="ticket-list ticket-list-packages" *ngFor="let ticketTypeGroup of package.ticketTypeGroup">
          <h4>{{ ticketTypeGroup.groupName | translate | hideTranslation }}</h4>
          <div *ngFor="let subGroup of ticketTypeGroup.subGroup">
            <span class="black"
              >{{ subGroup.name | translate | hideTranslation }}
              <span class="voucher-code" *ngIf="subGroup.voucherCode">
                ({{ subGroup.voucherCode }})</span
              >
            </span>
            <span class="price black"
              >{{ subGroup.tickets.length }}x
              {{
                subGroup.tickets[0].price
                  | currencySymbolGap: settings.currencySettings
              }}
            </span>
          </div>
        </div>
      </div>
      <hr *ngIf="package.addBasketPackageBorder" class="package-border">
    </div>
  </div>
  <div *ngFor="let group of ticketsWithHolders">
    <div *ngFor="let ticketGroup of group.tickets">
      <div class="ticket-group">
        <div class="ticket-type-group-name">
          {{ group.groupName | translate | hideTranslation }}
        </div>
        <div class="ticket-list ticket-list-tickets" *ngFor="let subGroup of ticketGroup.subGroup">
          <span class="black"
            >{{ subGroup.name | translate | hideTranslation }}
            <span class="voucher-code" *ngIf="subGroup.voucherCode">
              ({{ subGroup.voucherCode }})</span
            >
          </span>
          <span class="price black"
            >{{ subGroup.tickets.length }}x
            {{
              subGroup.tickets[0].price
                | currencySymbolGap: settings.currencySettings
            }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="parking-ticket-list" *ngIf="hasParkingTickets">
    <hr *ngIf="!this.hasRegularTickets && this.hasPackageTickets" class="package-border">
    <div class="ticket-type">
      {{
        (parsedParkingTickets | keyvalue)[0].value.groupName
          | translate
          | hideTranslation
      }}
    </div>
    <div
      class="ticket-list"
      *ngFor="let parkingTicket of parsedParkingTickets | keyvalue"
    >
      <span class="black">{{
        parkingTicket.value.name | translate | hideTranslation
      }}</span>
      <span class="price black">
        1x
        {{
          parkingTicket.value.price
            | currencySymbolGap: settings.currencySettings
        }}
      </span>
    </div>
  </div>
  <div
    class="total"
    data-translation="confirmation.total"
    [attr.data-translation]="'confirmation.total'"
  >
    {{ 'confirmation.total' | translate | hideTranslation }}:
    <span>
      {{ totalCost.toFixed(2) | currencySymbolGap: settings.currencySettings }}
    </span>
  </div>
  <div class="basket-buttons" *ngIf="selectedStepName !== 'invoice'">
    <button class="button button-option" (click)="gotoTicketSelection()">
      <span [attr.data-translation]="'step-navigation.tickets'">{{
        'step-navigation.tickets' | translate | hideTranslation
      }}</span>
    </button>
    <button
      class="button"
      (click)="toNextStep()"
      [disabled]="isNextButtonDisabled"
    >
      <span [attr.data-translation]="'basket.checkout'">{{
        'basket.checkout' | translate | hideTranslation
      }}</span>
    </button>
  </div>
</div>
