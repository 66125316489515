import * as fromRoot from '../../../app.reducer';
import * as helperActions from './helper.actions';
import * as stepsActions from '../step-forms/steps-forms.actions';
import * as bowser from 'bowser';
import { ElementRef, Renderer } from '@angular/core';
import { Observable, combineLatest as observableCombineLatest, BehaviorSubject, Subject } from 'rxjs';
import { catchError, filter, first, map } from 'rxjs/operators';
import { ErrorHandlingService } from '../../error-handling/error-handling.service';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { StatusBarService } from '../../../status-bar/status-bar.service';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { saveAs } from 'file-saver/FileSaver';
import { getLocalStorageString, setLocalStorageString } from '../../app-utils';
import { AppConstants } from '../../app-constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../error-handling/error-handling.service";
import * as i3 from "../../../status-bar/status-bar.service";
import * as i4 from "@ngrx/store";
import * as i5 from "@ngx-translate/core";
import * as i6 from "@angular/router";
var HelperService = /** @class */ (function () {
    function HelperService(_http, _errorHandlingService, _statusBarService, _store, _translateService, _router, _route) {
        var _this = this;
        this._http = _http;
        this._errorHandlingService = _errorHandlingService;
        this._statusBarService = _statusBarService;
        this._store = _store;
        this._translateService = _translateService;
        this._router = _router;
        this._route = _route;
        this.showModalWindowBH$ = new BehaviorSubject(null);
        this.showModalWindow$ = this.showModalWindowBH$.asObservable();
        this.voteYesNoBH$ = new BehaviorSubject(null);
        this.voteYesNo$ = this.voteYesNoBH$.asObservable();
        this.isStepNavigationRendered = new Subject();
        this.buyerQuestionnaire = [];
        this.visitorQuestionnaire = [];
        this.isReloadSections = false;
        this.emailRequired = false;
        this.verifyEmailRequired = false;
        this._store.select(fromRoot.getAllCountriesList).subscribe(function (list) {
            _this.listOfAllCountries = list;
        });
        this.isMobile();
        observableCombineLatest(this._store.select(fromRoot.getLanguage), this._store.select(fromRoot.getSelectedExhibitionId))
            .pipe(filter(function (data) {
            return !!data[0];
        }))
            .subscribe(function (data) {
            var lang = data[0], eventId = data[1];
            if (eventId === null) {
                eventId = -1;
            }
            setTimeout(function () {
                _this._store.dispatch(new helperActions.LoadAndSetTranslationsAction({ eventId: eventId, lang: lang }));
            });
        });
        this._store
            .select(fromRoot.getTranslations)
            .pipe(filter(function (translations) { return !!translations; }))
            .subscribe(function (translations) {
            // remove old translations
            _this._translateService.resetLang(_this._translateService.currentLang);
            _this._translateService.setTranslation(translations.language, translations.values);
            /* after the translations are loaded and set to translation service for use
            also change the active langueage to be used */
            _this._translateService.use(translations.language);
            _this._translateService.setDefaultLang(translations.language);
        });
    }
    /*   stdTimezoneOffset(date) {
      var jan = new Date(date.getFullYear(), 0, 1);
      var jul = new Date(date.getFullYear(), 6, 1);
      return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
    } */
    /*  isDstObserved(date) {
      return date.getTimezoneOffset() < this.stdTimezoneOffset(date);
    } */
    /*   dstDifferenceMinues(date) {
      const difference = this.stdTimezoneOffset(date);
      return difference;
    } */
    /* internal API */
    HelperService.prototype.setRefsToAppComponent = function (renderer, el) {
        this.appRenderer = renderer;
        this.appEl = el;
    };
    HelperService.prototype.setLanguage = function (lang) {
        this._store.dispatch(new helperActions.SetActiveLanguage(lang));
    };
    HelperService.prototype.setSupportedLanguages = function (languages) {
        this._translateService.addLangs(languages);
        this._store.dispatch(new helperActions.setSupportedLanguages(languages));
    };
    HelperService.prototype.redirectAfterLogin = function () {
        var _this = this;
        //get selected event ID and its step, if both present go to last selected step after login, otherwise go to homepage
        observableCombineLatest([
            this._store.pipe(select(fromRoot.getSelectedExhibitionId)),
            this._store.pipe(select(fromRoot.getSelectedStep)),
            this._store.pipe(select(fromRoot.isEventSeriesPage)),
            this._store.pipe(select(fromRoot.getBuyerInfo)),
            this._store.pipe(select(fromRoot.getStepsValidity)),
            this._store.pipe(select(fromRoot.getClaimedTicketHash))
        ])
            .pipe(first())
            .subscribe(function (_a) {
            var selectedExhibitionId = _a[0], selectedStep = _a[1], eventSeriesPage = _a[2], buyerInfo = _a[3], validations = _a[4], ticketClaimedHash = _a[5];
            if (!!buyerInfo) {
                var isInputFilledValue = buyerInfo.list.some(function (buyerInfoItem) {
                    return buyerInfoItem.value;
                });
                if (isInputFilledValue) {
                    _this.showModalWindow(isInputFilledValue);
                }
                ;
            }
            var visibleSteps = Object.keys(validations)
                // make sure steps are ordered correctly
                .sort(function (a, b) {
                return validations[a].order - validations[b].order;
            })
                // only navigate to visible routes
                .filter(function (stepKey) {
                return validations[stepKey].visible;
            });
            if (selectedExhibitionId !== null && selectedStep !== null) {
                var queryString = _this._router.url.includes('?');
                var urlBase_1 = _this.isSelfregistration() ? 'self-registration' : 'webshop';
                if (selectedStep === 'payment') {
                    console.log('%c currentStep ', 'background: #D46A6A; color: #fff', "/" + urlBase_1 + "/" + selectedExhibitionId + "/" + selectedStep);
                }
                if (!validations[selectedStep].showInStepNavigation) {
                    var ticketStep = visibleSteps.find(function (step) { return step === 'tickets'; });
                    selectedStep = validations[ticketStep].showInStepNavigation
                        ? ticketStep
                        : (validations[visibleSteps[0]].showInStepNavigation ? visibleSteps[0] : 'tickets');
                    console.log('%c re-navigated URL ', 'background: #6BA099; color: #fff', "/" + urlBase_1 + "/" + selectedExhibitionId + "/" + selectedStep);
                }
                if (queryString) {
                    // Bug 3830 - if CONTINUE is pressed for the first time on personalization step, user is redirected to tickets step instead of confirmation step
                    _this._route.queryParams.first().subscribe(function (params) {
                        _this._router.navigate([urlBase_1 + "/" + selectedExhibitionId + "/" + selectedStep], {
                            queryParams: params
                        });
                    });
                }
                else {
                    _this._router.navigate([
                        urlBase_1 + "/" + selectedExhibitionId + "/" + selectedStep
                    ]);
                }
            }
            else if (!!eventSeriesPage && eventSeriesPage.isEventSeries) {
                _this._router.navigate(["/series/" + eventSeriesPage.eventSeriesId]);
            }
            else if (ticketClaimedHash) {
                _this._router.navigate(["webshop-download/" + selectedExhibitionId], { queryParams: { t: ticketClaimedHash } });
            }
            else {
                _this._router.navigate(['/']);
            }
        });
    };
    HelperService.prototype.voteYesOrNo = function (vote) {
        this.voteYesNoBH$.next(vote);
    };
    HelperService.prototype.showModalWindow = function (show) {
        this.showModalWindowBH$.next(show);
    };
    HelperService.prototype.getListOfCountries = function () {
        return this._http.get("" + environment.protocol + environment.webApiUrl + "/country/preferred"
        //`${environment.apiUrl}/country/preferred`,
        );
    };
    HelperService.prototype.loadCountries = function () {
        return this._http.get("" + environment.protocol + environment.webApiUrl + "/country/list");
    };
    HelperService.prototype.checkZipcodeValidity = function (countryCode, zipCode) {
        if (!zipCode) {
            return new Observable(function (subscriber) {
                return subscriber.next({ isValidZipCode: true });
            });
        }
        return this._http.get("" + environment.protocol + environment.webApiUrl + "/country/" + countryCode + "/check-zip-code/" + zipCode);
    };
    HelperService.prototype.checkHashValidity = function (hash) {
        return this._http.get("" + environment.protocol + environment.webApiUrl + "/hash/status/" + hash);
    };
    HelperService.prototype.loadAndMergeTranslations = function (lang, eventId) {
        var _this = this;
        this.setTranslationsStatus(false);
        return this._http
            .get("" + environment.protocol + environment.webApiUrl + "/event/" + eventId + "/translations/" + lang, { observe: 'response' })
            .pipe(map(function (resp) {
            _this.setTranslationsStatus(true);
            if (resp.body.backend) {
                return Object.assign(resp.body.backend, resp.body.frontend);
            }
            else {
                return resp.body.frontend;
            }
        }), catchError(function (e) {
            _this.setTranslationsStatus(true);
            return _this._errorHandlingService.errorHandler(e);
        }));
    };
    HelperService.prototype.setTranslationsStatus = function (isTranslationsLoaded) {
        this._store.dispatch(new helperActions.SetTranslationsLoaded(isTranslationsLoaded));
    };
    HelperService.prototype.convertDate = function (strDate) {
        // strDate = '03.09.1979';
        var dateParts = strDate.split('.');
        var newDate = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
        return newDate;
    };
    HelperService.prototype.toStringWithoutOffset = function (date) {
        return date.getFullYear() + "-" + ('0' + (date.getMonth() + 1)).slice(-2) + "-" + ('0' + date.getDate()).slice(-2) + "T" + ('0' + date.getHours()).slice(-2) + ":" + ('0' + date.getMinutes()).slice(-2);
    };
    HelperService.prototype.processFormValuesBeforeSave = function (formValues) {
        var _this = this;
        var normalizedData = {};
        Object.keys(formValues).forEach(function (groupKey) {
            normalizedData[groupKey] = formValues[groupKey];
            // if it is an object we are dealing with checkbox set
            if (typeof formValues[groupKey] === 'object') {
                var groupRegex_1 = new RegExp("^" + groupKey + "_");
                var isDateOfBirth = groupKey === 'dateOfBirth';
                if (isDateOfBirth) {
                    var dateOfBirth = formValues[groupKey];
                    if (!!dateOfBirth) {
                        formValues[groupKey] = _this.getUTCdate(dateOfBirth);
                        normalizedData[groupKey] = formValues[groupKey];
                    }
                }
                else {
                    Object.keys(formValues[groupKey]).forEach(function (checkboxId) {
                        var clearedId = checkboxId.replace(groupRegex_1, '');
                        normalizedData[clearedId] = formValues[groupKey][checkboxId];
                    });
                }
            }
        });
        return normalizedData;
    };
    HelperService.prototype.processQuestionnaireValuesBeforeSave = function (formControls) {
        var normalizedQuestionnaire = [];
        formControls.forEach(function (group) {
            if ((group.controlType === 'dropdown' || group.controlType === 'radio') &&
                group.hasOwnProperty('value') &&
                !group.hidden &&
                (group.value || group.value === 0)) {
                normalizedQuestionnaire.push({
                    fieldId: Number(group.key),
                    valueId: Number(group.value),
                    text: null
                });
            }
            else if (group.controlType === 'textbox' &&
                group.hasOwnProperty('value') &&
                !group.hidden &&
                group.value) {
                var keySplited = group.key.split('_');
                normalizedQuestionnaire.push({
                    fieldId: Number(keySplited[0]),
                    valueId: Number(keySplited[1]),
                    text: group.value
                });
            }
            else if (group.controlType === 'checkbox' && !group.hidden) {
                group.options.forEach(function (option) {
                    if (option.value) {
                        normalizedQuestionnaire.push({
                            fieldId: Number(group.key),
                            valueId: Number(option.key),
                            text: null
                        });
                    }
                });
            }
        });
        return normalizedQuestionnaire;
    };
    HelperService.prototype.isNumber = function (n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    };
    HelperService.prototype.getVoucherUrl = function () {
        var voucherURL;
        this._route.queryParams.pipe(first()).subscribe(function (params) {
            Object.keys(params).find(function (key) {
                if (key === "voucher") {
                    return voucherURL = params.voucher;
                }
            });
        });
        return !!voucherURL ? voucherURL : '';
    };
    HelperService.prototype.getTicketParams = function () {
        var ticketParams = [];
        this._route.queryParams.pipe(first()).subscribe(function (params) {
            var counter = 0;
            ticketParams = Object.keys(params).map(function (key) {
                if (key === 'tt' || key === 'pt' || key === 'amt') {
                    return (counter++ === 0 ? '?' : '&') + (key + "=" + params[key]);
                }
            });
        });
        return ticketParams.length > 1 ? ticketParams.join('') : null;
    };
    HelperService.prototype.isMobile = function () {
        if (typeof navigator !== 'undefined') {
            return (navigator.userAgent.match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i) && true);
        }
        return false;
    };
    HelperService.prototype.stepNavigationRendered = function () {
        this.isStepNavigationRendered.next(true);
        return this.isStepNavigationRendered;
    };
    HelperService.prototype.browserInfo = function () {
        var browserInfo = bowser.getParser(window.navigator.userAgent)['parsedResult'];
        var browserName = browserInfo.browser.name + ' version: ' + browserInfo.browser.version;
        var systemInfo = browserInfo.os.name + ' version: ' + browserInfo.os.versionName;
        var platformInfo = browserInfo.platform.type;
        var completeInfo = 'Browser: ' +
            browserName +
            ', SystemInfo: ' +
            systemInfo +
            ', Platform: ' +
            platformInfo;
        return completeInfo;
    };
    HelperService.prototype.triggerCallbackOnceFormValidationIsDone = function (form, callback, isUsedWithoutTimeout) {
        if (form.pending) {
            var interval_1 = setInterval(function () {
                if (!form.pending) {
                    callback();
                    clearInterval(interval_1);
                }
            }, 200);
        }
        else {
            if (isUsedWithoutTimeout) {
                callback();
            }
            else {
                setTimeout(function () {
                    callback();
                }, 200);
            }
        }
    };
    HelperService.prototype.isSelfregistration = function () {
        var isSelfRegistration;
        this._store
            .select(fromRoot.getSelfRegistration)
            .pipe(first())
            .subscribe(function (selfRegistration) {
            isSelfRegistration = selfRegistration;
        });
        return isSelfRegistration;
    };
    HelperService.prototype.isEventSeriesPage = function () {
        var isEventSeriesPage;
        this._store
            .select(fromRoot.isEventSeriesPage)
            .pipe(first())
            .subscribe(function (isEventSeriesPage) {
            isEventSeriesPage = isEventSeriesPage;
        });
        return isEventSeriesPage;
    };
    HelperService.prototype.saveToFileSystem = function (blob, name) {
        /* const contentDispositionHeader: string = response.headers.get('Content-Disposition');
        const parts: string[] = contentDispositionHeader.split(';');
        const filename = parts[1].split('=')[1];
        const blob = new Blob([response._body], { type: 'text/plain' });
        saveAs(blob, filename); */
        saveAs(blob, name);
        this._store.dispatch(new helperActions.SetSpinnerValue(false));
    };
    HelperService.prototype.openIframe = function (url) {
        //this._store.dispatch(new helperActions.SetIframeUrl(url));
        //open in new tab for now
        var win = window.open(url, '_blank');
        win.focus();
    };
    HelperService.prototype.removeQueryParam = function (key, sourceURL) {
        var queryString = sourceURL.indexOf('?') !== -1 ? sourceURL.split('?')[1] : '';
        var rtn = sourceURL.split('?')[0];
        var param;
        var params_arr = [];
        if (queryString !== '') {
            params_arr = queryString.split('&');
            for (var i = params_arr.length - 1; i >= 0; i -= 1) {
                param = params_arr[i].split('=')[0];
                if (param === key) {
                    params_arr.splice(i, 1);
                }
            }
            if (params_arr.length > 0) {
                rtn = rtn + '?';
            }
            rtn = rtn + params_arr.join('&');
        }
        return rtn;
    };
    HelperService.prototype.loadBuyerQuestionnaireViaApi = function (stepName) {
        var _this = this;
        observableCombineLatest([
            this._store.pipe(select(fromRoot.getSelectedExhibitionId)),
            this._store.pipe(select(fromRoot.getLanguage)),
            this._store.pipe(select(fromRoot.getTickets)),
            this._store.pipe(select(fromRoot.getQuestionnaire)),
            this._store.pipe(select(fromRoot.getSelfRegQuestionnaire)),
            this._store.pipe(select(fromRoot.getQuestionnaireTicketPersonIds))
        ])
            .pipe(first())
            .subscribe(function (data) {
            var id = data[0], lang = data[1], tickets = data[2], questionnaire = data[3], selfRegQuestionnaire = data[4], questionnaireTicketPersonIds = data[5];
            var ticketPersonIds = [];
            if (tickets) {
                Object.keys(tickets).forEach(function (ticketUniqueId) {
                    var ticketById = tickets[ticketUniqueId];
                    var ticketPersonId = ticketById.ticketPersonId;
                    if (ticketById.count > 0 && !ticketPersonIds.includes(ticketPersonId)) {
                        ticketPersonIds.push(ticketPersonId);
                    }
                });
            }
            if (!_this.isSelfregistration()) {
                if (questionnaireTicketPersonIds.length === ticketPersonIds.length) {
                    return;
                }
                _this._store.dispatch(new stepsActions.SetQuestionnaireTicketPersonIds(ticketPersonIds));
            }
            _this._store.dispatch(new stepsActions.GetBuyerQuestionnaire({
                eventId: id,
                stepName: stepName,
                lang: lang,
                ticketPersonIds: ticketPersonIds,
                previousQuestionnare: _this.isSelfregistration()
                    ? selfRegQuestionnaire
                    : questionnaire
            }));
        });
    };
    HelperService.prototype.applyFullHeightToAllParentElements = function (startingElement, displayBlock) {
        var parentElement = startingElement.parentElement;
        while (parentElement) {
            parentElement.style.height = '100%';
            if (displayBlock) {
                parentElement.style.display = 'block';
            }
            parentElement = parentElement.parentElement;
        }
    };
    HelperService.prototype.getContingentTicketsFromLocalStorage = function () {
        var storedContingentTickets = getLocalStorageString(AppConstants.contingentTicketsReducer);
        if (!storedContingentTickets) {
            setLocalStorageString(AppConstants.contingentTicketsReducer, '');
        }
        var parsedContingentTickets = storedContingentTickets
            ? JSON.parse(storedContingentTickets)
            : {};
        return parsedContingentTickets;
    };
    HelperService.prototype.getContingentDaysForTicket = function (ticketUniqueId) {
        var parsedContingentTickets = this.getContingentTicketsFromLocalStorage();
        return Object.keys(parsedContingentTickets)
            .filter(function (key) { return key.startsWith(ticketUniqueId); })
            .map(function (key) { return parsedContingentTickets[key]; });
    };
    HelperService.prototype.checkQuestionnairesForDuplicates = function () {
        var _this = this;
        observableCombineLatest([
            this._store.pipe(select(fromRoot.getStepsForms)),
            this._store.pipe(select(fromRoot.getTicketHolderQuestionnaireInputs))
        ])
            .pipe(first())
            .subscribe(function (_a) {
            var stepsForms = _a[0], visitorQuestions = _a[1];
            var stepFormList = stepsForms['personal']['forms']['questionnaire'];
            if (!!stepFormList) {
                _this.buyerQuestionnaire = stepFormList['list'].slice();
                _this.visitorQuestionnaire = visitorQuestions;
            }
            if (_this.buyerQuestionnaire && _this.visitorQuestionnaire) {
                Object.keys(_this.buyerQuestionnaire).forEach(function (buyerKey) {
                    var buyerQuestion = _this.buyerQuestionnaire[buyerKey];
                    Object.keys(_this.visitorQuestionnaire).forEach(function (visitorKey) {
                        var visitorQuestion = _this.visitorQuestionnaire[visitorKey];
                        if (buyerQuestion.key === visitorQuestion.key) {
                            if (visitorQuestion.value === '' || visitorQuestion.value === undefined) {
                                visitorQuestion.value = buyerQuestion.value;
                                visitorQuestion.options.forEach(function (visitorOption) {
                                    var buyerOption = buyerQuestion.options.find(function (buyerOption) { return buyerOption.key === visitorOption.key; });
                                    if (!!buyerOption) {
                                        visitorOption.value = buyerOption.value;
                                    }
                                });
                            }
                        }
                    });
                });
            }
        });
    };
    //date time functions:
    //TODO: consider using moment.js
    HelperService.prototype.getUTCdate = function (date) {
        if (!!date) {
            if (typeof date === 'string' || date instanceof String) {
                date = new Date(date);
            }
            if (date instanceof Date) {
                return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
            }
        }
        return null;
    };
    /**
     * Removes the time part of a date object.
     * @param date
     * @returns Date object without time part.
     */
    HelperService.prototype.truncateTime = function (date) {
        if (!!date && date instanceof Date) {
            date.setHours(0, 0, 0, 0);
            return date;
        }
        return date;
    };
    /**
     * Checks if both dates without their time part are the same.
     * @param date1
     * @param date2
     * @returns True if both dates are the same.
     */
    HelperService.prototype.areDatesSameWOTime = function (date1, date2) {
        if (!date1 && !date2) {
            return true;
        }
        if (!!date1 && !!date2 && this.truncateTime(date1).valueOf() === this.truncateTime(date2).valueOf()) {
            return true;
        }
        return false;
    };
    /**
     * Checks if the first date is greater than the second date.
     * @param date1
     * @param date2
     * @returns True if the first date is greater than the second one.
     */
    HelperService.prototype.isFirstDateGreaterWOTime = function (date1, date2) {
        if (!!date1 && !date2) {
            return true;
        }
        if (!!date1 && !!date2 && this.truncateTime(date1).valueOf() > this.truncateTime(date2).valueOf()) {
            return true;
        }
        return false;
    };
    HelperService.prototype.addMinutesToDate = function (date, minutes) {
        if (!!date) {
            return new Date(date.getTime() + minutes * 60000);
        }
        return date;
    };
    HelperService.prototype.setIsReloadSections = function (isReloadSections) {
        this.isReloadSections = isReloadSections;
    };
    HelperService.prototype.getReloadSections = function () {
        return this.isReloadSections;
    };
    /**
     *
     * @param values array to sort
     * @returns array sorted by values ascending
     */
    HelperService.prototype.sortValuesAsc = function (values) {
        values.sort(function (a, b) {
            if (a < b) {
                return -1;
            }
            if (a > b) {
                return 1;
            }
            return 0;
        });
        return values;
    };
    HelperService.prototype.setOriginalEmailValues = function (emailRequired, verifyEmailRequired) {
        this.emailRequired = emailRequired;
        this.verifyEmailRequired = verifyEmailRequired;
    };
    HelperService.prototype.getOriginalEmailValues = function (value) {
        if (value === 'email') {
            return this.emailRequired;
        }
        else if (value === 'verifyEmail') {
            return this.verifyEmailRequired;
        }
        return false;
    };
    HelperService.prototype.setReloadRequired = function (reloadRequired) {
        this._store.dispatch(new helperActions.SetReloadRequired(reloadRequired));
    };
    HelperService.ngInjectableDef = i0.defineInjectable({ factory: function HelperService_Factory() { return new HelperService(i0.inject(i1.HttpClient), i0.inject(i2.ErrorHandlingService), i0.inject(i3.StatusBarService), i0.inject(i4.Store), i0.inject(i5.TranslateService), i0.inject(i6.Router), i0.inject(i6.ActivatedRoute)); }, token: HelperService, providedIn: "root" });
    return HelperService;
}());
export { HelperService };
