var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import * as colorizerActions from '../../../colorizer/colorizer.actions';
import * as customizationActions from './customization.actions';
import * as fromRoot from '../../../app.reducer';
import * as stepsActions from '../step-forms/steps-forms.actions';
import * as ticketActions from '../tickets/ticket.actions';
import cloneDeep from 'lodash.clonedeep';
import { combineLatest as observableCombineLatest, of } from 'rxjs';
import { catchError, filter, first, map, skip } from 'rxjs/operators';
import { AppConstants } from '../../app-constants';
import { CheckboxInput } from '../../forms/inputs/input-checkbox.class';
import { DropdownInput } from '../../forms/inputs/input-dropdown.class';
import { ErrorHandlingService } from '../../error-handling/error-handling.service';
import { FormGroup } from '@angular/forms';
import { FormsService } from '../../forms/forms.service';
import { HelperService } from '../helpers/helper.service';
import { Http } from '@angular/http';
import { RadioInput } from '../../forms/inputs/input-radio.class';
import { Router } from '@angular/router';
import { StatusBarService } from '../../../status-bar/status-bar.service';
import { select, Store } from '@ngrx/store';
import { TextInput } from '../../forms/inputs/input-text.class';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { generateSkinCss } from './customization.styles';
import { prepareBillingAddressData } from './forms/billing-address-data';
import { prepareBuyerInfoData } from './forms/buyer-info-data';
import { prepareConfirmationCheckboxes } from './forms/confirmation-checkboxes-data';
import { prepareDisclaimerCheckboxes } from './forms/disclaimer-checkboxes-data';
import { consoleLog, getUUID } from '../../app-utils';
import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
import * as i2 from "@angular/router";
import * as i3 from "../../forms/forms.service";
import * as i4 from "../helpers/helper.service";
import * as i5 from "@ngrx/store";
import * as i6 from "../../error-handling/error-handling.service";
import * as i7 from "../../../status-bar/status-bar.service";
import * as i8 from "@angular/http";
import * as i9 from "@ngx-translate/core";
var CustomizationService = /** @class */ (function () {
    function CustomizationService(document, _router, _formsService, _helperService, _store, _errorHandlingService, _statusBarService, _http, _translateService) {
        /* ticket holders forms
           Whenever we have settings and total ticket count changes regenerate new form sets for all ticket holders */
        var _this = this;
        this.document = document;
        this._router = _router;
        this._formsService = _formsService;
        this._helperService = _helperService;
        this._store = _store;
        this._errorHandlingService = _errorHandlingService;
        this._statusBarService = _statusBarService;
        this._http = _http;
        this._translateService = _translateService;
        this.exhibitionSubscriptions = [];
        var selectedTicketSendingOptions$ = this._store.select(fromRoot.getTicketSelectedSendingOptions);
        this.settings$ = this._store.select(fromRoot.getExhibitionSettings);
        this.isLoggedInAsAdmin$ = this._store.select(fromRoot.isLoggedInAsAdmin);
        this.selectedLanguage$ = this._store.select(fromRoot.getLanguage);
        this._store.select(fromRoot.getSendingOptions).subscribe(function (sendingOptions) {
            _this.allSendingOptions = sendingOptions;
        });
        this.isLoggedInAsAdmin$.subscribe(function (isAdmin) {
            _this.isLoggedInAsAdmin = isAdmin;
        });
        // modify ticket holder forms if ticketSendingOption is changed
        selectedTicketSendingOptions$
            .pipe(skip(1)) // we dont want to set it on page reload
            .subscribe(function (selectedTicketSendingOptions) {
            var ticketHolderInputSets = _this.getTicketHolderInputSets(_store);
            if (ticketHolderInputSets && selectedTicketSendingOptions) {
                ticketHolderInputSets.forEach(function (form, i) {
                    // set all forms sending options
                    form.inputSet.list.find(function (input) { return input.key === 'sendingOption'; }).value = selectedTicketSendingOptions;
                    // decide whether to show ticket recipient
                    var sendtoowner = form.inputSet.list.find(function (input) { return input.key === 'sendtoowner'; });
                    var selectedSendingOption = ticketHolderInputSets[0].inputSet.list.find(function (input) { return input.key === "sendingOption"; }).value;
                    var sendToOwner = true;
                    var sendToOwnerHidden = true;
                    // adminclient --> a4 ticket to buyer, ticket selection --> all tickets are sent as a print @ home ticket to buyer
                    var isAllToBuyer = selectedSendingOption === 'allToBuyer';
                    // adminclient --> download link to visitor, ticket selection --> Ticket retrival link sent (to buyer or other recipient)
                    var isTicketRetrivalLink = selectedSendingOption === 'ticketRetrivalLink' &&
                        _this.allSendingOptions.some(function (option) { return option.value === "ticketRetrivalLink" && option.isEnabled; });
                    // adminclient --> mobile ticket & passbook to visitor, ticket selection --> send mobile ticket(s) to buyer or other recipient
                    var isMobilePerOwner = selectedSendingOption === "mobilePerOwner" &&
                        _this.allSendingOptions.some(function (option) { return option.value === "mobilePerOwner" && option.isEnabled; });
                    // adminclient --> a4 ticket to visitor, ticket selection --> print home ticket(s) (a4, shown if:(tickets >= 2))
                    var isNormalPerOwner = selectedSendingOption === "normalPerOwner";
                    if (!isAllToBuyer && !isNormalPerOwner) {
                        sendToOwner = isTicketRetrivalLink || isMobilePerOwner;
                        for (var i_1 = 0; i_1 < _this.allSendingOptions.length; i_1++) {
                            var sendingOption = _this.allSendingOptions[i_1];
                            if (sendingOption.isEnabled) {
                                if (isMobilePerOwner && sendingOption.value === "mobilePerBuyer") {
                                    sendToOwner = sendToOwnerHidden = false;
                                    break;
                                }
                                else if (isTicketRetrivalLink && sendingOption.value === "ticketRetrivalLinkBuyer") {
                                    sendToOwner = sendToOwnerHidden = false;
                                    break;
                                }
                            }
                        }
                        ;
                    }
                    if (isAllToBuyer) {
                        sendToOwner = false;
                    }
                    sendtoowner.options[0].value = sendToOwner;
                    sendtoowner.hidden = sendToOwnerHidden;
                    sendtoowner.options[0].label = "personalize.sending-options." + selectedTicketSendingOptions.toLowerCase();
                    sendtoowner.options[0].icon = selectedTicketSendingOptions;
                    _this._store.dispatch(new stepsActions.SetInputs(form));
                });
            }
        });
        // in case we sending option is changed, based on event settings we need to decide, whether fields mandatory or not
        observableCombineLatest(this.settings$, selectedTicketSendingOptions$).subscribe(function (data) {
            var settings = data[0], selectedTicketSendingOptions = data[1];
            var queryParams = _this.document.location.search.toLowerCase();
            if (settings && selectedTicketSendingOptions && (!queryParams || (!queryParams.includes('reg_mail') && !queryParams.includes('login_user')))) {
                var ticketHolderInputSets = cloneDeep(_this.getTicketHolderInputSets(_store));
                var checkValidityAndRedirect_1 = false;
                ticketHolderInputSets.forEach(function (ticketHolderform) {
                    _this.setRequiredHoldersFiledsBasedOnSendinOption(ticketHolderform, settings, 
                    // selectedTicketSendingOptions,
                    ticketHolderform.inputSet.list.find(function (input) {
                        return input.key === 'sendingOption';
                    }).value, checkValidityAndRedirect_1);
                });
            }
        });
        /*   this._translateService
              .stream('') // stream function is triggered again on language change
              .subscribe(termsTranslation => {}); */
    }
    CustomizationService.prototype.setRequiredHoldersFiledsBasedOnSendinOption = function (ticketHolderform, settings, ticketSendingOptions, checkValidityAndRedirect) {
        var _this = this;
        var checkVerifyEmailValidity = false;
        var isEmailMandatory = (settings.emailIsMandatoryForMobileTicket &&
            ticketSendingOptions === 'mobilePerOwner') ||
            ticketSendingOptions === 'ticketRetrivalLink' ||
            settings.ticketOwnerSettings.fieldSettings.Email.isMandatory;
        var isEmailVerifyMandatory = (settings.emailIsMandatoryForMobileTicket &&
            ticketSendingOptions === 'mobilePerOwner') ||
            ticketSendingOptions === 'ticketRetrivalLink' ||
            settings.ticketOwnerSettings.fieldSettings.MailConfirm.isMandatory;
        //We remove Email field if it is not set visible in Admin and when it is not Mandatory.
        //In case the Email field doesn't exist, we won't show the option for sending Email to ticketHolder.
        var sendtoownerField = ticketHolderform.inputSet.list.find(function (item) { return item.key === 'sendtoowner'; });
        if (!settings.ticketOwnerSettings.fieldSettings.Email.isVisible && !isEmailMandatory) {
            ticketHolderform.inputSet.list = ticketHolderform.inputSet.list.filter(function (item) { return item.key !== 'email'; });
            sendtoownerField.hidden = true;
        }
        //We remove VerifyEmail field if it is not set visible in Admin and when it is not Mandatory.
        if (!settings.ticketOwnerSettings.fieldSettings.MailConfirm.isVisible && !isEmailVerifyMandatory) {
            ticketHolderform.inputSet.list = ticketHolderform.inputSet.list.filter(function (item) { return item.key !== 'verifyEmail'; });
        }
        var isFieldAdded = false;
        //If Email field is mandatory and the field doesn't exist, we add it.
        if (isEmailMandatory && !ticketHolderform.inputSet.list.some(function (item) { return item.key === 'email'; })) {
            ticketHolderform.inputSet.list.push(new TextInput({
                key: 'email',
                label: 'personalize.holder.email',
                order: settings.ticketOwnerSettings.fieldSettings.Email.order,
                translate: true,
                required: true,
                cssClass: 'col-md-6 column-grow-1',
                emailValidation: true,
                type: 'email'
            }));
            isFieldAdded = true;
        }
        //If VerifyEmail field is mandatory and the field doesn't exist, we add it.
        if (isEmailVerifyMandatory && !ticketHolderform.inputSet.list.some(function (item) { return item.key === 'verifyEmail'; })) {
            ticketHolderform.inputSet.list.push(new TextInput({
                key: 'verifyEmail',
                label: 'personalize.holder.email-verify',
                order: settings.ticketOwnerSettings.fieldSettings.MailConfirm.order,
                translate: true,
                required: true,
                cssClass: 'col-md-6 column-grow-1',
                emailValidation: true,
                sameAsValidation: 'email',
                type: 'email'
            }));
            isFieldAdded = true;
        }
        //Sort all fields by ascending order if email and verifyEmail fields were added
        if (isFieldAdded) {
            ticketHolderform.inputSet.list.sort(function (a, b) { return a.order - b.order; });
        }
        ticketHolderform.inputSet.list.forEach(function (input) {
            var inputName = _this.capitalizeFirstLetter(input.key);
            // there is exception that this field does not have same name as corresponding input (Gender must be changed to Salutation)
            if (inputName === 'Gender') {
                inputName = 'Salutation';
            }
            var requiredFieldName = "is" + inputName + "Mandatory";
            if (settings.ticketOwnerSettings.hasOwnProperty(requiredFieldName)) {
                input.required = settings.ticketOwnerSettings[requiredFieldName];
            }
        });
        /* set whether following inputs are required or not based on sending option selected  */
        var updatedInputs = ticketHolderform.inputSet.list.map(function (input) {
            if (input.key === 'email') {
                input.required = isEmailMandatory;
                return input;
            }
            else if (input.key === 'verifyEmail') {
                input.required = isEmailVerifyMandatory;
                if (input.required) {
                    checkValidityAndRedirect = true;
                    checkVerifyEmailValidity = true;
                }
                return input;
            }
            else if (input.key === 'firstName') {
                if (ticketSendingOptions === 'ticketRetrivalLink') {
                    input.required = true;
                    return input;
                }
                else {
                    input.required =
                        settings.ticketOwnerSettings.fieldSettings.FirstName.isMandatory;
                    return input;
                }
            }
            else if (input.key === 'lastName') {
                if (ticketSendingOptions === 'ticketRetrivalLink') {
                    input.required = true;
                    return input;
                }
                else {
                    input.required =
                        settings.ticketOwnerSettings.fieldSettings.LastName.isMandatory;
                    return input;
                }
            }
            else {
                return input;
            }
        });
        //After updating inputs, we set email and verifyEmail required values in setOriginalEmailValues,
        //so we can use them in personalization step when sendtoowner checkbox is checked or unchecked.
        var emailField = ticketHolderform.inputSet.list.find(function (item) { return item.key === 'email'; });
        var verifyEmailField = ticketHolderform.inputSet.list.find(function (item) { return item.key === 'verifyEmail'; });
        var emailRequired = emailField ? emailField.required : false;
        var verifyEmailRequired = verifyEmailField ? verifyEmailField.required : false;
        var isSendToOwnerChecked = sendtoownerField.options[0].value;
        this._helperService.setOriginalEmailValues(emailRequired, verifyEmailRequired);
        //We set email and verifyEmail fields to required when checkbox sendtoowner is checked and visible because
        //we don't want to make them required when 'normal per owner' --> (in code), 'A4 E-ticket to visitor' --> (in admin) is selected in Confirmation step which
        //sets sendtoowner checkbox to checked and hidden, but doesn't want email and verifyEmail fields to be required
        if (!sendtoownerField.hidden && isSendToOwnerChecked && !!emailField) {
            emailField.required = true;
            if (!!verifyEmailField) {
                verifyEmailField.required = true;
            }
        }
        ticketHolderform.inputSet.list = updatedInputs;
        this._store.dispatch(new stepsActions.SetInputs(ticketHolderform));
        var shouldSetValid = !ticketHolderform.inputSet.list.some(function (ticketHolderInputSet) {
            return ticketHolderInputSet.required;
        });
        if (shouldSetValid) {
            this._formsService.setTicketHolderFormValidity(true, ticketHolderform.formInfo);
            //Make the form valid if there isn't any mandatory fields on any of the ticket holders
            this._formsService.setFormValidity(true, null, ticketHolderform.formInfo);
        }
        /**
         * in case we set the required fileds from step after the ticket holder form iteself (confirmation),
         * we need to revalidate the form and in case it is not valid, redirect to fix it
         */
        if (checkValidityAndRedirect) {
            var formControllObject = this._formsService.inputsToFormControl(ticketHolderform.inputSet.list);
            var form_1 = new FormGroup(formControllObject.group);
            form_1.setValidators(formControllObject.globalFormValidators);
            var validationCallback = function () {
                if (!form_1.valid) {
                    if (checkVerifyEmailValidity) {
                        _this._formsService.setFormValidity(false, null, ticketHolderform.formInfo);
                        _this._formsService.setTicketHolderFormValidity(false, ticketHolderform.formInfo);
                    }
                    observableCombineLatest([
                        _this._store.pipe(select(fromRoot.getSelectedExhibitionId)),
                        _this._store.pipe(select(fromRoot.getTicketHolderInputSets)),
                        _this._store.pipe(select(fromRoot.getSelectedStep))
                    ])
                        .pipe(first())
                        .subscribe(function (_a) {
                        var eventId = _a[0], ticketHolderInputSets = _a[1], selectedStep = _a[2];
                        if (selectedStep !== 'tickets') {
                            // Find the index of the choosen ticket Holder and set activeHolderSlideIndex and navigate to it.
                            var currentTicketHolderString_1 = ticketHolderform.formInfo[1];
                            var ticketHolderIndex = ticketHolderInputSets.findIndex(function (holderInputSet) { return holderInputSet.formInfo[1] === currentTicketHolderString_1; });
                            _this._store.dispatch(new ticketActions.SetActiveHolderSlideIndex(ticketHolderIndex));
                            _this._router.navigate(["webshop/" + eventId + "/personal"], {
                                queryParams: { scroll: ticketHolderIndex }
                            });
                        }
                    });
                }
            };
            this._helperService.triggerCallbackOnceFormValidationIsDone(form_1, validationCallback);
        }
    };
    CustomizationService.prototype.capitalizeFirstLetter = function (text) {
        return text.charAt(0).toUpperCase() + text.slice(1);
    };
    CustomizationService.prototype.getTicketHolderInputSets = function (store) {
        var state;
        store
            .select(fromRoot.getTicketHolderInputSets)
            .first()
            .subscribe(function (inputsSets) { return (state = inputsSets); });
        return state;
    };
    CustomizationService.prototype.setShoppingStartTime = function () {
        this._store.dispatch(new customizationActions.SetShoppingStartTime());
    };
    CustomizationService.prototype.resetShoppingStartTime = function () {
        this._store.dispatch(new customizationActions.ResetShoppingStartTime());
    };
    CustomizationService.prototype.triggerOnExhibitionChange = function (eventId) {
        var _this = this;
        // first unsubscribe all subscriptions which were set with event change
        this.exhibitionSubscriptions.forEach(function (subscription) {
            subscription.unsubscribe();
        });
        var orderUUID = getUUID();
        this._store.dispatch(new customizationActions.SetOrderUuid(orderUUID));
        consoleLog("OrderUuid for this session: " + orderUUID);
        // transform custom exhibition data to specific data structures
        // and load exhibition specific data
        this.selectedLanguage$.first().subscribe(function (countryCode) {
            _this._store.dispatch(new customizationActions.GetRandomSponsor({ eventId: eventId, countryCode: countryCode }));
        });
        var exhibitionSettingsSubscription = observableCombineLatest([
            this.settings$,
            this._store.pipe(select(fromRoot.getTitles)),
            this._store.pipe(select(fromRoot.getAllTitles)),
            this._store.pipe(select(fromRoot.getProfessions)),
            this._store.pipe(select(fromRoot.getAllProfessions)),
            this._store.pipe(select(fromRoot.getDepartments)),
            this._store.pipe(select(fromRoot.getAllDepartments)),
            this._store.pipe(select(fromRoot.getOccupationalGroups)),
            this._store.pipe(select(fromRoot.getAllOccupationalGroups))
        ])
            .pipe(filter(function (data) {
            var settings = data[0], titles = data[1], allTitles = data[2], professions = data[3], allProfessions = data[4], departments = data[5], allDepartments = data[6], occupationalGroups = data[7], allOccupationalGroups = data[8];
            return !!settings &&
                !!titles &&
                !!allTitles &&
                !!professions &&
                !!allProfessions &&
                !!departments &&
                !!allDepartments &&
                !!occupationalGroups &&
                !!allOccupationalGroups;
        }), first())
            .subscribe(function (_a) {
            var settings = _a[0], titles = _a[1], allTitles = _a[2], professions = _a[3], allProfessions = _a[4], departments = _a[5], allDepartments = _a[6], occupationalGroups = _a[7], allOccupationalGroups = _a[8];
            /* Buyer info */
            var buyerSettings = settings.buyerSettings, isGoogleAutocompleteEnabled = settings.isGoogleAutocompleteEnabled;
            var buyerInfo = prepareBuyerInfoData(settings, isGoogleAutocompleteEnabled, titles, professions, departments, occupationalGroups);
            var buyerInfoPayload = {
                formInfo: ['personal', 'buyerinfo'],
                inputSet: {
                    rerender: false,
                    list: buyerInfo
                }
            };
            _this._store.dispatch(new stepsActions.SetInputs(buyerInfoPayload));
            /* Billing address info */
            var billingAddress = prepareBillingAddressData(buyerSettings, isGoogleAutocompleteEnabled);
            var billingAddressPayload = {
                formInfo: ['personal', 'billingaddress'],
                inputSet: {
                    rerender: false,
                    list: billingAddress
                }
            };
            _this._store.dispatch(new stepsActions.SetInputs(billingAddressPayload));
            // each time new translations arrive and are available on FE
            var langChangeSubscription = _this._store
                .select(fromRoot.getTranslations)
                .subscribe(function () {
                /* privacy checkbox */
                var privacySubscription = _this._translateService
                    .get([
                    'personalize.privacy-link',
                    'personalize.privacy-link.text',
                    'personalize.privacy-optional',
                    'personalize.privacy-optional.text',
                ]) // stream function is triggered again on language change
                    .subscribe(function (termsTranslations) {
                    _this._store
                        .select(fromRoot.getSteps)
                        .pipe(first())
                        .subscribe(function (steps) {
                        var privacyForm = steps['personal'].forms['privacy'];
                        var privacyPolicyChecked = false;
                        var privacyPolicyOptionalChecked = false;
                        // set privacy policy value
                        if (privacyForm) {
                            var privacyPolicyBase = privacyForm.list.find(function (inputBase) {
                                return inputBase.key === 'disclaimer';
                            });
                            var privacyPolicyOption = privacyPolicyBase &&
                                privacyPolicyBase.options.find(function (option) { return option.key === 'disclaimerConfirmation'; });
                            if (privacyPolicyOption && privacyPolicyOption.value) {
                                privacyPolicyChecked = privacyPolicyOption.value;
                            }
                            var privacyPolicyOptionalBase = privacyForm.list.find(function (inputBase) {
                                return inputBase.key === 'disclaimerOptional';
                            });
                            var privacyPolicyOptionalOption = privacyPolicyOptionalBase &&
                                privacyPolicyOptionalBase.options.find(function (option) { return option.key === 'disclaimerOptionalConfirmation'; });
                            if (privacyPolicyOptionalOption && privacyPolicyOptionalOption.value) {
                                privacyPolicyOptionalChecked = true;
                            }
                            ;
                        }
                        var confirmationCheckboxes = prepareDisclaimerCheckboxes(settings, termsTranslations, _this._helperService.isSelfregistration(), privacyPolicyChecked, privacyPolicyOptionalChecked);
                        var privacyPayload = {
                            formInfo: ['personal', 'privacy'],
                            inputSet: {
                                rerender: true,
                                list: confirmationCheckboxes
                            }
                        };
                        _this._store.dispatch(new stepsActions.SetInputs(privacyPayload));
                    });
                });
                _this.exhibitionSubscriptions.push(privacySubscription);
                /* confirmation checkboxes */
                var termsTranslationSubscription = _this._translateService
                    .stream('confirmation.terms-conditions') // stream function is triggered again on language change
                    .subscribe(function (termsTranslation) {
                    var confirmationCheckboxes = prepareConfirmationCheckboxes(settings, termsTranslation);
                    var confirmationPayload = {
                        formInfo: ['confirmation', 'checkboxes'],
                        inputSet: {
                            rerender: false,
                            list: confirmationCheckboxes
                        }
                    };
                    _this._store.dispatch(new stepsActions.SetInputs(confirmationPayload));
                });
                _this.exhibitionSubscriptions.push(termsTranslationSubscription);
            });
            _this.exhibitionSubscriptions.push(langChangeSubscription);
            /* sending options */
            var ticketSendingOptions = settings.ticketSelectionDeliveryTypes;
            var isAllToBuyerEnabled = !!ticketSendingOptions.find(function (option) { return option.value === 'allToBuyer' && option.isEnabled; });
            var isRetrivalLinkEnabled = !!ticketSendingOptions.find(function (option) { return option.value === 'ticketRetrivalLink' && option.isEnabled; });
            var isMobilePerOwnerEnabled = !!ticketSendingOptions.find(function (option) { return option.value === 'mobilePerOwner' && option.isEnabled; });
            var isMobile = _this._helperService.isMobile();
            var isSendingOptionSelected = false;
            var modifiedTicketSendingOptions = ticketSendingOptions.map(function (option) {
                var sendingOptionObj = __assign({}, option, { isSelected: false });
                if (option.isEnabled && option.isBasic) {
                    if (!isSendingOptionSelected) {
                        if (_this._helperService.isSelfregistration() && option.value === 'allToBuyer') {
                            sendingOptionObj.isSelected = isSendingOptionSelected = true;
                            return sendingOptionObj;
                        }
                        else if (isMobile === null) {
                            if ((option.value === "normalPerOwner" || option.value === "mobilePerOwner") && (isAllToBuyerEnabled || isRetrivalLinkEnabled)) {
                                return sendingOptionObj;
                            }
                            sendingOptionObj.isSelected = isSendingOptionSelected = true;
                        }
                        else {
                            if (option.value === "mobilePerOwner") {
                                sendingOptionObj.isSelected = isSendingOptionSelected = true;
                            }
                            else {
                                sendingOptionObj.isSelected = isSendingOptionSelected = isMobilePerOwnerEnabled ? false : true;
                            }
                        }
                    }
                }
                return sendingOptionObj;
            });
            _this._store.dispatch(new ticketActions.SetSendingOptionsAction(modifiedTicketSendingOptions));
        });
        this.exhibitionSubscriptions.push(exhibitionSettingsSubscription);
        var notLoggedAndLoginMandatorySubscription = this._store
            .select(fromRoot.notLoggedAndLoginMandatory)
            .subscribe(function (notLogged) {
            _this._formsService.setFormValidity(!notLogged, null, [
                'tickets',
                'login'
            ]);
        });
        this.exhibitionSubscriptions.push(notLoggedAndLoginMandatorySubscription);
    };
    CustomizationService.prototype.tansformQuestionnaireIntoInput = function (questions) {
        var _this = this;
        var sortedQuestions = questions.slice(0).map(function (question) {
            var inputBase = {
                key: question.id.toString(),
                label: question.label,
                isQuestionnaire: true,
                required: question.isMandatory,
                translate: true,
                selectionLimit: question.selectionLimit,
                previousValueId: question.previousValueId
            };
            if (question.type === 'dropdown') {
                var sortedOptions = question.values
                    .slice(0)
                    .sort(function (a, b) { return a.order - b.order; });
                inputBase.options = sortedOptions.map(function (option) {
                    return { key: option.id, value: option.text };
                });
                inputBase.cssClass = question.previousValueId ? 'col-lg-7' : 'col-lg-6';
                return new DropdownInput(inputBase);
            }
            else if (question.type === 'text') {
                inputBase.key = question.id;
                inputBase['noTrim'] = true;
                if (question.values.length > 0) {
                    inputBase.key += '_' + question.values[0].id;
                    /* we need to translate the value */
                    _this._translateService
                        .get(question.values[0].text)
                        .pipe(filter(function (translation) { return translation !== AppConstants.MISSING_TRANSLATION; }))
                        .subscribe(function (translation) {
                        inputBase.placeholder = question.values[0].text;
                    });
                }
                inputBase.maxLengthValidation = question.textLength || 150;
                inputBase.cssClass = question.previousValueId ? 'col-lg-7' : 'col-lg-6';
                return new TextInput(inputBase);
            }
            else if (question.type === 'radio') {
                inputBase.cssClass = 'col-lg-12';
                inputBase.options = question.values.map(function (option) {
                    return {
                        key: option.id,
                        value: option.text,
                        cssClass: 'col-lg-4 col-md-6',
                        order: option.order
                    };
                });
                return new RadioInput(inputBase);
            }
            else if (question.type === 'checkbox') {
                inputBase.cssClass = 'col-lg-12';
                inputBase.options = question.values.map(function (option) {
                    return {
                        key: option.id,
                        value: false,
                        label: option.text,
                        cssClass: 'col-lg-4 col-md-6',
                        order: option.order
                    };
                });
                return new CheckboxInput(inputBase);
            }
        });
        return sortedQuestions;
    };
    CustomizationService.prototype.setStyles = function () {
        var _this = this;
        observableCombineLatest(this._store.select(fromRoot.getExhibitionSettings), this._store.select(fromRoot.getOperatorsSettings), this._store.select(fromRoot.isLoggedInAsAdmin))
            .pipe(filter(function (data) {
            return !!data[1];
        }))
            .first()
            .subscribe(function (data) {
            var exhibitionSettings = data[0], operatorsSettings = data[1], isAdmin = data[2];
            if (exhibitionSettings) {
                _this.appendSkinCSS(exhibitionSettings.customStyles, 3, false, exhibitionSettings.customStylesId);
                var queryParams = _this.document.location.search.toLowerCase();
                if (isAdmin && (!queryParams || (!queryParams.includes('reg_mail') && !queryParams.includes('login_user')))) {
                    _this._store.dispatch(new colorizerActions.LoadStyle(exhibitionSettings.customStylesId));
                }
            }
            else if (operatorsSettings.hasOwnProperty('customStyles')) {
                _this.appendSkinCSS(operatorsSettings.customStyles, 2, false, operatorsSettings.customStylesId);
                if (isAdmin) {
                    _this._store.dispatch(new colorizerActions.LoadStyle(operatorsSettings.customStylesId));
                }
            }
        });
    };
    /* skin with higer or same priority rewrite lower or older priority
      1 - default color (red)
      2 - operators settings
      3 - event settings
      4 - voucher settings
      5 - admin editing styles
    */
    CustomizationService.prototype.appendSkinCSS = function (variables, priority, isOperator, skinId) {
        var _this = this;
        this._store
            .select(fromRoot.isLoggedInAsAdmin)
            .pipe(first())
            .subscribe(function (isAdmin) {
            // add styles
            var cssString = generateSkinCss(variables);
            var cssSkin = {
                priority: priority,
                css: cssString,
                variables: variables,
                id: skinId
            };
            if (isOperator) {
                isAdmin = isAdmin || isOperator;
            }
            _this._store.dispatch(new customizationActions.SetExhibitionStyles({ cssSkin: cssSkin, isAdmin: isAdmin }));
        });
    };
    CustomizationService.prototype.setVoucherStyles = function (styles) {
        this.appendSkinCSS({
            BaseThemeColor: styles.BaseThemeColor,
            BaseTxColor: styles.BaseTxColor,
            ButtonBgColor: styles.ButtonBgColor,
            ButtonTxColor: styles.ButtonTxColor,
            AreaBlockBgActive: styles.AreaBlockBgActive
        }, 4, false, null);
    };
    CustomizationService.prototype.setColors = function (code) {
        if (code === 'blue') {
            this.appendSkinCSS({
                BaseThemeColor: '#2569af',
                AreaBlockBgActive: '#174575'
            }, 4, false, null);
        }
        else if (code === 'green') {
            this.appendSkinCSS({
                BaseThemeColor: '#5ca26f',
                AreaBlockBgActive: '#177530'
            }, 4, false, null);
        }
        else if (code === 'red') {
            this.appendSkinCSS({
                BaseThemeColor: '#d12d3d',
                AreaBlockBgActive: '#a90515'
            }, 1, false, null);
        }
    };
    CustomizationService.prototype.addCSS = function (css, id) {
        if (typeof window !== 'undefined') {
            if (this._helperService.appRenderer && this._helperService.appEl) {
                var head = document.head || document.getElementsByTagName('head')[0];
                var style = this._helperService.appRenderer.createElement(this._helperService.appEl, 'style');
                // if new CSS block was added with specific ID, first delete the old CSS block
                if (id) {
                    var oldCSS = document.getElementById(id);
                    if (oldCSS) {
                        head.removeChild(oldCSS);
                    }
                    // add id to the newly created CSS
                    style.id = id;
                }
                style.type = 'text/css';
                if (style.styleSheet) {
                    style.styleSheet.cssText = css;
                }
                else {
                    style.appendChild(document.createTextNode(css));
                }
                head.appendChild(style);
            }
        }
    };
    CustomizationService.prototype.addIframe = function (iframeUrl, id) {
        if (typeof window !== 'undefined') {
            if (this._helperService.appRenderer && this._helperService.appEl) {
                var body = document.body || document.getElementsByTagName('body')[0];
                var iframe = this._helperService.appRenderer.createElement(this._helperService.appEl, 'iframe');
                if (id) {
                    var oldIframe = document.getElementById(id);
                    if (oldIframe) {
                        body.removeChild(oldIframe);
                    }
                    var randomNumber = Math.random() * 10000000000000;
                    iframe.id = id;
                    iframe.src = iframeUrl + randomNumber.toString() + '?';
                    iframe.width = '1';
                    iframe.height = '1';
                    iframe.frameborder = '0';
                    iframe.style.display = 'none';
                }
                body.appendChild(iframe);
            }
        }
    };
    CustomizationService.prototype.injectTrackingScript = function (scriptCode, id, totalTicketsPrice, orderId) {
        if (typeof window !== 'undefined') {
            if (this._helperService.appRenderer && this._helperService.appEl) {
                var scriptSrcs_1 = [];
                var body_1 = document.body || document.getElementsByTagName('body')[0];
                var head_1 = document.head || document.getElementsByTagName('head')[0];
                var scriptsCleaned_1 = '';
                // Extract script if is surrounded by <script> tag
                scriptCode.replace(/<script[^>]*>([\s\S]*?)<\/script>/gi, function () {
                    scriptsCleaned_1 += arguments[1];
                    // if it is a script with src
                    arguments[0].replace(/src="(.*?)"/gi, function () {
                        if (arguments[1]) {
                            scriptSrcs_1.push(arguments[1]);
                        }
                    });
                    return '';
                });
                // If script is not surrounded, then use script directly
                if (!scriptsCleaned_1) {
                    scriptsCleaned_1 = scriptCode;
                }
                // remove line breaks
                scriptsCleaned_1 = scriptsCleaned_1.replace(/(?:\r\n|\r|\n)/gm, '');
                if (id) {
                    var oldScripts = document.getElementById(id);
                    if (oldScripts) {
                        oldScripts.parentElement.removeChild(oldScripts);
                    }
                }
                if (totalTicketsPrice !== null) {
                    scriptsCleaned_1 = scriptsCleaned_1.replace(/\[Revenue\]/gim, totalTicketsPrice);
                }
                if (orderId !== null) {
                    scriptsCleaned_1 = scriptsCleaned_1.replace(/\[OrderID\]/gim, orderId);
                }
                // const scriptWrapper = document.createElement('div');
                // scriptWrapper.id = id;
                // create inline script
                var scriptElement = document.createElement('script');
                scriptElement.setAttribute('type', 'text/javascript');
                scriptElement.id = id;
                scriptElement.innerHTML = scriptsCleaned_1;
                if (id === 'generic-script-all-page') {
                    head_1.appendChild(scriptElement);
                }
                if (id === 'generic-script') {
                    body_1.appendChild(scriptElement);
                }
                //add src scripts
                scriptSrcs_1.forEach(function (src) {
                    var scriptElement = document.createElement('script');
                    scriptElement.setAttribute('type', 'text/javascript');
                    scriptElement.src = src;
                    if (id === 'generic-script-all-page') {
                        head_1.appendChild(scriptElement);
                    }
                    if (id === 'generic-script') {
                        body_1.appendChild(scriptElement);
                    }
                });
                //body.appendChild(scriptWrapper);
            }
        }
    };
    /* JSON API */
    CustomizationService.prototype.getCustomSettings = function (eventId) {
        var _this = this;
        if (eventId == '') {
            return of({});
        }
        var selfReg = '';
        if (this._helperService.isSelfregistration()) {
            selfReg = '?sr=true';
        }
        //if (!this.isLoggedInAsAdmin) {
        return this._http
            .get(
        //`${environment.apiUrl}/event/${eventId}/settings-temp-development`,
        "" + environment.protocol + environment.webApiUrl + "/event/" + eventId + "/settings" + selfReg)
            .pipe(map(function (res) {
            try {
                var settings = res.json() || {};
                // TODO remove this fallback
                if (!settings.hasOwnProperty('isTicketHolderVisible')) {
                    settings.isTicketHolderVisible = true;
                }
                return settings;
            }
            catch (err) {
                _this._statusBarService.setStatus(err, 'error');
            }
        }), catchError(function (e) {
            return _this._errorHandlingService.errorHandler(e);
        }));
        /* }
    
        return this._http
          .get(window.location.origin + '/assets/admin/settings.json')
          .map(resp => {
            return resp.json();
          })
          .catch(e => {
            return this._errorHandlingService.errorHandler(e);
          }); */
    };
    CustomizationService.prototype.getLocalizedImages = function (eventId, countryCode) {
        var _this = this;
        return this._http
            .get("" + environment.protocol + environment.webApiUrl + "/event/" + eventId + "/images/" + countryCode)
            .pipe(map(function (res) {
            try {
                return res.json() || {};
            }
            catch (err) {
                _this._statusBarService.setStatus(err, 'error');
            }
        }), catchError(function (e) {
            return _this._errorHandlingService.errorHandler(e);
        }));
    };
    /*   getCustomLinks(event) {
      return this._http
        .get(
          `${environment.apiUrl}/event-links/${event.exhibitionId}?lang=${event.exhibitionLanguage}`,
        )
        .map(res => {
          try {
            return res.json() || {};
          } catch (err) {
            this._statusBarService.setStatus(err, 'error');
          }
        })
        .catch(e => {
          return this._errorHandlingService.errorHandler(e);
        });
    } */
    CustomizationService.prototype.getRandomSponsor = function (eventId, countryCode) {
        var _this = this;
        return (this._http
            //.get(`${environment.apiUrl}/random-sponsor/${eventId}`)
            .get("" + environment.protocol + environment.webApiUrl + "/event/" + eventId + "/random-sponsor?language=" + countryCode)
            .pipe(map(function (res) {
            try {
                return res.json() || {};
            }
            catch (err) {
                _this._statusBarService.setStatus(err, 'error');
            }
        }), catchError(function (e) {
            return _this._errorHandlingService.errorHandler(e);
        })));
    };
    CustomizationService.prototype.getOperatorSettings = function () {
        var _this = this;
        var selfReg = '';
        if (this._helperService.isSelfregistration()) {
            selfReg = '?sr=true';
        }
        return this._http
            .get("" + environment.protocol + environment.webApiUrl + "/operator/settings" + selfReg)
            .pipe(map(function (res) {
            try {
                return res.json() || {};
            }
            catch (err) {
                _this._statusBarService.setStatus(err, 'error');
            }
        }), catchError(function (e) {
            return _this._errorHandlingService.errorHandler(e);
        }));
    };
    CustomizationService.ngInjectableDef = i0.defineInjectable({ factory: function CustomizationService_Factory() { return new CustomizationService(i0.inject(i1.DOCUMENT), i0.inject(i2.Router), i0.inject(i3.FormsService), i0.inject(i4.HelperService), i0.inject(i5.Store), i0.inject(i6.ErrorHandlingService), i0.inject(i7.StatusBarService), i0.inject(i8.Http), i0.inject(i9.TranslateService)); }, token: CustomizationService, providedIn: "root" });
    return CustomizationService;
}());
export { CustomizationService };
