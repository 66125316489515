/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./exhibition-tile.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../shared/pipes/localized-date/localized-date.pipe";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@angular/common";
import * as i5 from "@ngrx/store";
import * as i6 from "./exhibition-tile.component";
import * as i7 from "../shared/services-with-reducers/exhibition/exhibition.service";
var styles_ExhibitionTileComponent = [i0.styles];
var RenderType_ExhibitionTileComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ExhibitionTileComponent, data: {} });
export { RenderType_ExhibitionTileComponent as RenderType_ExhibitionTileComponent };
function View_ExhibitionTileComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "square-img"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", ""], ["class", "tile-img"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "tile-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "tile-time"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " - ", " "])), i1.ɵpid(0, i2.LocalizedDatePipe, [i3.TranslateService, i4.DatePipe, i5.Store]), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(0, i2.LocalizedDatePipe, [i3.TranslateService, i4.DatePipe, i5.Store]), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.exhibition.eventLogo; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.exhibition.eventName; _ck(_v, 4, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 8).transform(i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_co.exhibition.startDate)))); var currVal_3 = i1.ɵunv(_v, 6, 1, i1.ɵnov(_v, 10).transform(i1.ɵunv(_v, 6, 1, i1.ɵnov(_v, 9).transform(_co.exhibition.endDate)))); _ck(_v, 6, 0, currVal_2, currVal_3); }); }
function View_ExhibitionTileComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "square-img"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", ""], ["class", "tile-img"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "tile-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "tile-time"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " - ", " "])), i1.ɵpid(0, i2.LocalizedDatePipe, [i3.TranslateService, i4.DatePipe, i5.Store]), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(0, i2.LocalizedDatePipe, [i3.TranslateService, i4.DatePipe, i5.Store]), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.exhibitionSerie.logo; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.exhibitionSerie.name; _ck(_v, 4, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 8).transform(i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_co.exhibitionSerie.startDate)))); var currVal_3 = i1.ɵunv(_v, 6, 1, i1.ɵnov(_v, 10).transform(i1.ɵunv(_v, 6, 1, i1.ɵnov(_v, 9).transform(_co.exhibitionSerie.endDate)))); _ck(_v, 6, 0, currVal_2, currVal_3); }); }
export function View_ExhibitionTileComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExhibitionTileComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExhibitionTileComponent_2)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.exhibition; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.exhibitionSerie; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_ExhibitionTileComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-exhibition-tile", [], null, null, null, View_ExhibitionTileComponent_0, RenderType_ExhibitionTileComponent)), i1.ɵdid(1, 49152, null, 0, i6.ExhibitionTileComponent, [i7.ExhibitionService], null, null)], null, null); }
var ExhibitionTileComponentNgFactory = i1.ɵccf("app-exhibition-tile", i6.ExhibitionTileComponent, View_ExhibitionTileComponent_Host_0, { exhibition: "exhibition", exhibitionSerie: "exhibitionSerie" }, {}, []);
export { ExhibitionTileComponentNgFactory as ExhibitionTileComponentNgFactory };
