import { Component, OnInit } from '@angular/core';

import * as fromRoot from '../../app.reducer';
import { ErrorHandlingService } from '../../shared/error-handling/error-handling.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { StatusBarService } from '../../status-bar/status-bar.service';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { filter, first } from 'rxjs/operators';

import * as stepsFormsActions from '../../shared/services-with-reducers/step-forms/steps-forms.actions';

@Component({
  selector: 'app-maintenence-page',
  templateUrl: './maintenence-page.component.html',
  styleUrls: ['./maintenence-page.component.scss']
})
export class MaintenencePageComponent implements OnInit {
  public htmlContent: string = '';
  public isAdmin$: Observable<boolean>;
  public isAdmin: boolean;
  public tabs: Array<any> = [{ id: 'home', title: 'Events Overview Page', text: '' }];
  public activeTab: any = 'home';
  public eventId: string | number;

  constructor(
    private _http: HttpClient,
    private _errorHandlingService: ErrorHandlingService,
    private _translateService: TranslateService,
    private _store: Store<fromRoot.State>,
    private _statusBarService: StatusBarService
  ) {}

  ngOnInit() {
    this.isAdmin$ = this._store.pipe(select(fromRoot.isLoggedInAsAdmin));
    this.isAdmin$.pipe(first()).subscribe(isAdmin => (this.isAdmin = isAdmin));
    this._store
      .pipe(
        select(fromRoot.getSelectedExhibitionId),      
        filter(eventId => !!eventId),
        first()
      )
      .subscribe(eventId => {
        this.eventId = eventId;

        if (!!this.eventId) {
          this.tabs.pop();
          this.tabs.push({ id: 'event', title: 'Event', text: '' });
          this.activeTab = 'event';
        }        
        //we set the selected step on the ticket page because admin doesn't have local storage synchronized and during the refresh this leads to 404 page when admin navigates back
        if (this.isAdmin) {
          this._store.dispatch(
            new stepsFormsActions.SetSelectedStep('tickets')
          );
        }
      });

    this._store.pipe(select(fromRoot.getLanguage)).subscribe(language => {
      this.loadHTML(language, this.eventId);
    });
    // this._translateService.onLangChange.subscribe(data => {
    //   this.loadHTML(data.lang);
    // });
  }

  onTabChange(value) {
    this._store
      .pipe(
        select(fromRoot.getSelectedExhibitionId),      
        filter(eventId => !!eventId),
        first()
      )
      .subscribe(eventId => {
        this.eventId = eventId;
      });
    
    this.tabs.map(tab => {
      if (tab.id !== value) {
        this.activeTab = value;
        this.loadHTML(this._translateService.currentLang, this.eventId);
      }
    });
  }

  loadHTML(lang: string, eventId: string | number = '', tryToLoadOperatorTranslation: boolean = true) {
    this._http
      .get(
        `${environment.protocol}${environment.webApiUrl}/pages/maintenance${eventId}/${lang}`
      )
      .map((res: { body: string }) => {
        try {
          return res.body || '';
        } catch (err) {
          this._statusBarService.setStatus(err, 'error');
        }
      })
      .catch(e => {
        return this._errorHandlingService.errorHandler(e);
      })
      .subscribe((resp: string) => {
        this.htmlContent = resp;

        //In case the event maintenance page is empty, load the operator level maintenance page
        if (!this.htmlContent && !this.isAdmin && tryToLoadOperatorTranslation) {       
          this.loadHTML(lang, '', false);
        }
      });
  }
}
