var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import * as fromRoot from '../../../app.reducer';
import * as stepsActions from './steps-forms.actions';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { filter, first } from 'rxjs/operators';
import { DateTime } from 'luxon';
import { FormsService } from '../../forms/forms.service';
import { HelperService } from '../helpers/helper.service';
import { select, Store } from '@ngrx/store';
import { environment } from '../../../../environments/environment';
import { getTicketHolderData } from '../tickets/ticket-functions';
import { combineLatest as observableCombineLatest } from 'rxjs';
import { ErrorHandlingService } from '../../error-handling/error-handling.service';
import { ExhibitionSettingModel } from '../customization/customization.interfaces';
import { getLocalStorageString } from '../../app-utils';
import { AppConstants } from '../../app-constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common/http";
import * as i3 from "@ngrx/store";
import * as i4 from "../helpers/helper.service";
import * as i5 from "../../forms/forms.service";
import * as i6 from "../../error-handling/error-handling.service";
var StepsFormsService = /** @class */ (function () {
    function StepsFormsService(_router, _http, _store, _helperService, _formsService, _errorHandlingService) {
        var _this = this;
        this._router = _router;
        this._http = _http;
        this._store = _store;
        this._helperService = _helperService;
        this._formsService = _formsService;
        this._errorHandlingService = _errorHandlingService;
        // react on need for legitimation and hide/show the legitimation step
        this._store
            .select(fromRoot.isLegitimationRequired)
            .pipe(filter(function (isLegitimationRequired) {
            if (isLegitimationRequired && !_this._helperService.isSelfregistration()) {
                return true;
            }
        }))
            .subscribe(function (isLegitimationRequired) {
            var visibilityPayload = {
                stepKey: 'legitimation',
                visible: isLegitimationRequired.required
            };
            _this._store.dispatch(new stepsActions.SetStepsVisibility([visibilityPayload]));
        });
        observableCombineLatest([
            this._store.pipe(select(fromRoot.getExhibitionSettings)),
            this._store.pipe(select(fromRoot.doTicketsNeedLegitimation))
        ]).subscribe(function (_a) {
            var settings = _a[0], doTicketsNeedLegitimation = _a[1];
            if (settings) {
                _this.workshopsOnTicketSelection = settings.workshopsOnTicketSelection;
                var orderPayload = {
                    stepKey: 'legitimation',
                    newOrder: settings.goToLegitimationForNewAccount && !doTicketsNeedLegitimation ? 1 : 3
                };
                _this._store.dispatch(new stepsActions.SetStepOrder(orderPayload));
            }
        });
        // react on need for workshops and hide/show the workshop step
        this._store
            .select(fromRoot.doTicketsNeedWorkshops)
            .subscribe(function (doTicketsNeedWorkshops) {
            var visibilityPayload = {
                stepKey: 'workshop',
                visible: _this.workshopsOnTicketSelection ? false : doTicketsNeedWorkshops
            };
            if (!_this._helperService.isSelfregistration()) {
                // as a fast solution we hide workshops on self registration (later this should come from event settings)
                _this._store.dispatch(new stepsActions.SetStepsVisibility([visibilityPayload]));
            }
        });
    }
    StepsFormsService.prototype.sendFinalOrder = function (orderData) {
        var selfReg = '';
        if (this._helperService.isSelfregistration()) {
            selfReg = '?sr=true';
        }
        //clear all previously received errors from the API (so we don't interpret errors that occured earlier in the shopping process):
        this._errorHandlingService.clearAllErrors();
        return this._http.post(
        //`${environment.apiUrl}/buyer-questionnaire/${eventId}`,
        "" + environment.protocol + environment.webApiUrl + "/order/save-order" + selfReg, orderData, { observe: 'response' });
    };
    StepsFormsService.prototype.getBuyerQuestionnaire = function (eventId, args) {
        var selfReg = '';
        if (this._helperService.isSelfregistration()) {
            selfReg = '&sr=true';
        }
        var ticketPersonIds = args.reduce(function (accu, curr) {
            return accu + ("&ticketPersonIds=" + curr);
        }, '');
        return this._http.get("" + environment.protocol + environment.webApiUrl + "/event/" + eventId + "/buyer-questionnaire?" + selfReg + ticketPersonIds);
    };
    StepsFormsService.prototype.navigateRelativeTo = function (x, router) {
        var _this = this;
        observableCombineLatest([
            this._store.pipe(select(fromRoot.getStepsValidity)),
            this._store.pipe(select(fromRoot.getSelectedStep)),
            this._store.pipe(select(fromRoot.getSelectedExhibitionId))
        ])
            .pipe(filter(function (_a) {
            var validations = _a[0], activeStepKey = _a[1], selectedEventId = _a[2];
            return !!validations && !!activeStepKey && selectedEventId !== null;
        }), first())
            .subscribe(function (_a) {
            var validations = _a[0], activeStepKey = _a[1], selectedEventId = _a[2];
            var visibleSteps = Object.keys(validations)
                // make sure steps are ordered correctly
                .sort(function (a, b) {
                return validations[a].order - validations[b].order;
            })
                // only navigate to visible routes
                .filter(function (stepKey) {
                return validations[stepKey].visible;
            });
            var indexOfStep = visibleSteps.indexOf(activeStepKey);
            if ((x < 0 && indexOfStep > 0) || (x > 0 && indexOfStep < visibleSteps.length - 1)) {
                _this.checkAndRedirectToNextStep(validations, visibleSteps, selectedEventId, indexOfStep, x, router, activeStepKey);
            }
        });
    };
    StepsFormsService.prototype.checkAndRedirectToNextStep = function (validations, visibleSteps, selectedEventId, indexOfStep, x, router, activeStepKey) {
        var urlBase = this._helperService.isSelfregistration()
            ? 'self-registration'
            : 'webshop';
        var navigateTo = visibleSteps[indexOfStep + x];
        if (navigateTo === 'payment') {
            console.log('%c currentStep ', 'background: #5AAC56; color: #fff', "/" + urlBase + "/" + selectedEventId + "/" + activeStepKey);
            console.log(x === -1 ? '%c "back button" URL ' : '%c "continue button" URL ', 'background: #D46A6A; color: #fff', "/" + urlBase + "/" + selectedEventId + "/" + navigateTo);
        }
        if (!validations[navigateTo].showInStepNavigation) {
            var ticketStep = visibleSteps.find(function (step) { return step === 'tickets'; });
            navigateTo = validations[ticketStep].showInStepNavigation
                ? ticketStep
                : (validations[visibleSteps[0]].showInStepNavigation ? visibleSteps[0] : 'tickets');
            console.log('%c re-navigated URL ', 'background: #6BA099; color: #fff', "/" + urlBase + "/" + selectedEventId + "/" + navigateTo);
        }
        this._store.dispatch(new stepsActions.SetSelectedStep(navigateTo));
        router.navigate(["/" + urlBase + "/" + selectedEventId + "/" + navigateTo]);
    };
    StepsFormsService.prototype.navigateToLastNotDisabledPage = function () {
        var _this = this;
        observableCombineLatest(this._store.select(fromRoot.getOrderedStepsValidityArray), this._store.select(fromRoot.getSelectedExhibitionId))
            .first()
            .subscribe(function (data) {
            var orderedStepsValidityArray = data[0], eventId = data[1];
            var lastNotDisabledPage = orderedStepsValidityArray
                .slice(0)
                .reverse()
                .find(function (step) {
                return (step.value.disabled === false &&
                    step.value.visible &&
                    step.value &&
                    step.value.showInStepNavigation);
            });
            _this._router.navigate([
                "/webshop/" + eventId + "/" + lastNotDisabledPage.key
            ]);
        });
    };
    //scroll to top
    StepsFormsService.prototype.scrollToTop = function () {
        window.scroll(0, 0);
    };
    /**
     * This function combine together listOfAllTickets (ungroupedTickets), tickets in tree structure and TicketHolders
     *
     * @param {any} ungroupedTickets
     * @param {any} ticketTypes
     * @param {any} ticketHolderInputSets
     * @returns
     * @memberof StepsFormsService
     */
    StepsFormsService.prototype.assignTicketsToHolders = function (ungroupedTickets, ticketTypes, ticketHolderInputSets, ticketHolderAdditionalData) {
        var ticketsWithHolders = JSON.parse(JSON.stringify(ticketTypes)).map(function (ticketGroup) {
            return {
                groupId: ticketGroup.groupId,
                groupName: ticketGroup.groupName,
                packageName: ticketGroup.packageName,
                products: ticketGroup.products,
                packages: [],
                tickets: [],
                articles: []
            };
        });
        Object.keys(ungroupedTickets).forEach(function (ticketUniqueId, index) {
            var ticketById = ungroupedTickets[ticketUniqueId];
            if (ticketById.count) {
                ticketById.holdersIndexes.forEach(function (ticketIndex) {
                    // get a group of tickets (one day ticket, week ticket, etc.) to which this one belong
                    var productGroup = ticketsWithHolders.find(function (ticketType) {
                        if (ticketById.packageNumber) {
                            return ticketType.products.some(function (prod) {
                                if (!!prod.package && prod.package.packageNumber === ticketById.packageNumber) {
                                    return prod.package.contents.some(function (pc) { return pc.packageGroups.some(function (pg) { return pg.ticketTypeId === ticketById.groupId; }); });
                                }
                            });
                        }
                        return ticketType.groupId === ticketById.groupId;
                    });
                    var subGroup;
                    var pkgGroup;
                    var ticketGroup;
                    if (ticketById.packageNumber) {
                        var packageName = productGroup.products.find(function (prod) { return prod.package.packageNumber === ticketById.packageNumber; }).package.name;
                        pkgGroup = productGroup.packages.find(function (pkgGroup) { return pkgGroup.number == ticketById.packageNumber && pkgGroup.index === ticketById.packageIndex; });
                        if (!pkgGroup) {
                            pkgGroup = {
                                name: packageName,
                                number: ticketById.packageNumber,
                                index: ticketById.packageIndex,
                                price: 0,
                                ticketTypeGroup: [],
                                articleSubGroup: []
                            };
                            productGroup.packages.push(pkgGroup);
                        }
                    }
                    ticketGroup = ticketById.packageNumber ? pkgGroup.ticketTypeGroup.find(function (tg) { return tg.groupId === ticketById.groupId; }) : productGroup.tickets.find(function (tg) { return tg.groupId === ticketById.groupId; });
                    if (!ticketGroup) {
                        ticketGroup = {
                            groupId: ticketById.groupId,
                            groupName: ticketById.groupName,
                            subGroup: []
                        };
                        if (pkgGroup) {
                            pkgGroup.ticketTypeGroup.push(ticketGroup);
                        }
                        else {
                            productGroup.tickets.push(ticketGroup);
                        }
                    }
                    subGroup = ticketGroup.subGroup.find(function (subgroup) { return subgroup.uniqueId === ticketById.uniqueId; });
                    // in case this subgroup does not exist yet, create it
                    if (!subGroup) {
                        subGroup = {
                            uniqueId: ticketById.uniqueId,
                            ticketTypeId: ticketById.id,
                            name: ticketById.name,
                            voucherCode: ticketById.voucherCode,
                            tickets: [],
                            ticketTypeOrder: ticketById.ticketTypeOrder
                        };
                        ticketGroup.subGroup.push(subGroup);
                    }
                    // once we are sure the subgroup exists push this particular ticket into it
                    subGroup.tickets.push({
                        ticketName: ticketById.name,
                        price: ticketById.price,
                        ticketHolderAdditionalData: ticketHolderAdditionalData ? ticketHolderAdditionalData[ticketIndex] : null,
                        ticketHolderConfirmation: ticketHolderAdditionalData ? getTicketHolderData(ticketHolderInputSets, ticketIndex) : null
                    });
                    if (pkgGroup) {
                        pkgGroup.price += ticketById.price;
                    }
                });
            }
        });
        // This logic places a horizontal <hr> line between packages and tickets inside BASKET
        var allPkgGroups = ticketsWithHolders.filter(function (x) { return x.packages.length > 0; });
        var lastPkgGrp = allPkgGroups.length > 0 ? allPkgGroups[allPkgGroups.length - 1] : null;
        var lastPkg = lastPkgGrp ? lastPkgGrp.packages[lastPkgGrp.packages.length - 1] : null;
        var isTicketTaken = ticketsWithHolders.some(function (productGroup) { return productGroup.tickets.length > 0; });
        ticketsWithHolders = ticketsWithHolders.map(function (productGroup) {
            // sort tickets by ticketType in packages ticketTypeGroup array
            productGroup.packages.forEach(function (pkg) {
                pkg.addBasketPackageBorder = isTicketTaken || pkg !== lastPkg;
                pkg.ticketTypeGroup.forEach(function (tg) {
                    tg.subGroup.sort(function (a, b) {
                        return a.ticketTypeOrder - b.ticketTypeOrder;
                    });
                });
            });
            // sort tickets by ticketType in tickets array
            productGroup.tickets.forEach(function (t) {
                t.subGroup.sort(function (a, b) {
                    return a.ticketTypeOrder - b.ticketTypeOrder;
                });
            });
            return productGroup;
        });
        // sort products without packages first
        ticketsWithHolders.sort(function (a, b) { return (a.packageName ? 1 : 0) - (b.packageName ? 1 : 0); });
        return ticketsWithHolders;
    };
    /**
     * This function combine together listOfAllTickets (ungroupedTickets), tickets in tree structure and TicketHolders
     *
     * @param {any} ungroupedTickets
     * @param {any} ticketTypes
     * @param {any} ticketHolderInputSets
     * @returns
     * @memberof StepsFormsService
     */
    StepsFormsService.prototype.assignUngroupedTicketsToHolders = function (ungroupedTickets, ticketHolderInputSets, ticketHolderAdditionalData) {
        var ticketsWithHolders = [];
        Object.keys(ungroupedTickets).forEach(function (ticketUniqueId) {
            var ticketById = ungroupedTickets[ticketUniqueId];
            if (ticketById.count) {
                // for each ticket asigned to a ticket holder form
                ticketById.holdersIndexes.forEach(function (ticketIndex) {
                    ticketsWithHolders.push({
                        ticketUniqueId: ticketById.uniqueId,
                        ticketName: ticketById.name,
                        ticketTypeId: ticketById.id,
                        ticketGroupName: ticketById.groupName,
                        ticketPrice: ticketById.price,
                        ticketCount: ticketById.count,
                        ticketHasAllowedWorkshops: ticketById.allowedWorkshops &&
                            ticketById.allowedWorkshops.length > 0,
                        allowedWorkshops: ticketById.allowedWorkshops,
                        ticketHolderAdditionalData: ticketHolderAdditionalData[ticketIndex],
                        ticketHolderConfirmation: getTicketHolderData(ticketHolderInputSets, ticketIndex)
                    });
                });
            }
        });
        return ticketsWithHolders;
    };
    /**
     * This function combine together listOfAllTickets (ungroupedTickets), tickets in tree structure and TicketHolders
     *
     * @param {any} ungroupedTickets
     * @param {any} ticketTypes
     * @param {any} ticketHolderInputSets
     * @returns
     * @memberof StepsFormsService
     */
    StepsFormsService.prototype.assignTicketsToHoldersForSave = function (ungroupedTickets, ticketHolderInputSets, ticketHolderAdditionalData, buyerInfoEmail) {
        var _this = this;
        var ticketsWithHolders = [];
        // US2870 - data being prepared and sent via 'save-order'. we're sending two more parameters inside 'ticketHolder': isBuyerVisitorChecked and buyerVisitorQuestionnaire
        var buyerVisitorCheckedSlideIndex;
        var isBuyerVisitorChecked;
        var buyerVisitorQuestionnaire;
        observableCombineLatest([
            this._store.pipe(select(fromRoot.getTicketHolderQuestionnaireInputs)),
            this._store.pipe(select(fromRoot.getBuyerVisitorCheckbox))
        ])
            .filter(function (data) { return !!data; })
            .pipe(first())
            .subscribe(function (_a) {
            var questionnaire = _a[0], buyerVisitorCheckbox = _a[1];
            if (!!buyerVisitorCheckbox) {
                buyerVisitorCheckedSlideIndex = buyerVisitorCheckbox.buyerVisitorCheckedSlideIndex;
                isBuyerVisitorChecked = buyerVisitorCheckbox.isBuyerVisitorChecked;
                // US2870 - we have to process raw questionnaire and proceed with info needed by 'save-order' which is three parameters: fieldId, valueId and text
                buyerVisitorQuestionnaire = _this._helperService.processQuestionnaireValuesBeforeSave(questionnaire);
            }
        });
        Object.keys(ungroupedTickets).forEach(function (ticketUniqueId, index) {
            var ticketById = ungroupedTickets[ticketUniqueId];
            if (ticketById.count) {
                // get contingent days booked for this ticket type
                var contingentDaysForTicket_1 = _this._helperService.getContingentDaysForTicket(ticketUniqueId);
                // for each ticket counted in the ticket type
                ticketById.holdersIndexes.forEach(function (ticketIndex, index) {
                    var ticketHolder = getTicketHolderData(ticketHolderInputSets, ticketIndex, buyerInfoEmail);
                    // US2870 - only single ticketHolder/visitor in an order will have visitorQuestionnaire answered
                    if (ticketIndex === buyerVisitorCheckedSlideIndex) {
                        ticketHolder.isBuyerAVisitor = isBuyerVisitorChecked;
                        ticketHolder.visitorQuestionnaire = buyerVisitorQuestionnaire;
                    }
                    var contingenTicketData = contingentDaysForTicket_1[index];
                    // contingent tickes should have the index as in array
                    var day = contingenTicketData ? contingenTicketData.day : null;
                    if (ticketHolder) {
                        var ticketHoldersWorkshops = [];
                        var thisTicketHolderIndex = ticketHolder.ticketIndex;
                        if (ticketHolderAdditionalData.hasOwnProperty(thisTicketHolderIndex)) {
                            ticketHoldersWorkshops =
                                ticketHolderAdditionalData[thisTicketHolderIndex].workshops;
                        }
                        var tickerToOwner = ticketHolder['sendtoowner'];
                        var sendingOption = ticketHolder['sendingOption'];
                        var asMobileTicket = ticketHolder['sendingOption'] === 'mobilePerOwner' ||
                            ticketHolder['sendingOption'] === 'mobilePerBuyer'
                            ? true
                            : false;
                        ticketHolder = _this.removeAttributes(ticketHolder, [
                            'ticketIndex',
                            'address',
                            'sendtoowner',
                            'sendingOption'
                        ]);
                        ticketHolder = _this.separateAddress(ticketHolder);
                        var voucherCode = 'voucherCode' in ticketById ? ticketById['voucherCode'] : '';
                        if (sendingOption === 'mobilePerOwner' && !tickerToOwner) {
                            sendingOption = 'mobilePerBuyer';
                        }
                        if (sendingOption === 'ticketRetrivalLink' && !tickerToOwner) {
                            sendingOption = 'ticketRetrivalLinkBuyer';
                        }
                        var ticketHolderData = getTicketHolderData(ticketHolderInputSets, ticketIndex);
                        ticketsWithHolders.push({
                            groupId: ticketById.groupId,
                            ticketTypeId: ticketById.id,
                            // uniqueId: ticketById.uniqueId, // dont send the unique id to backend as it was FE construct
                            ticketToOwner: tickerToOwner,
                            ticketSendingMode: sendingOption,
                            ticketHolder: ticketHolder,
                            asMobileTicket: asMobileTicket,
                            voucherCode: voucherCode,
                            workshops: ticketHoldersWorkshops,
                            ticketPersonId: ticketById.ticketPersonId,
                            packageNumber: ticketById.packageNumber,
                            shoppingCartPkgIndex: ticketById.packageIndex,
                            day: day
                        });
                    }
                });
            }
        });
        return ticketsWithHolders;
    };
    StepsFormsService.prototype.removeAttributes = function (obj, attributes) {
        var clearedObject = Object.assign({}, obj);
        attributes.forEach(function (attr) {
            if (clearedObject.hasOwnProperty(attr)) {
                delete clearedObject[attr];
            }
        });
        return clearedObject;
    };
    StepsFormsService.prototype.renameAttributes = function (obj, attributes) {
        var renamedObject = Object.assign({}, obj);
        attributes.forEach(function (attr) {
            if (renamedObject.hasOwnProperty(attr.from)) {
                renamedObject[attr.to] = renamedObject[attr.from];
                delete renamedObject[attr.from];
            }
        });
        return renamedObject;
    };
    StepsFormsService.prototype.separateAddress = function (obj) {
        var clearedObject = Object.assign({}, obj);
        var toSeparate = [
            'company',
            'street',
            'city',
            'zipCode',
            'country'
        ];
        clearedObject.address = {};
        toSeparate.forEach(function (key) {
            if (clearedObject.hasOwnProperty(key)) {
                clearedObject.address[key] = clearedObject[key];
            }
            else {
                // TODO once BE can accept only data which are really present delete else statement
                clearedObject.address[key] = '';
            }
        });
        clearedObject = this.removeAttributes(clearedObject, toSeparate);
        return clearedObject;
    };
    StepsFormsService.prototype.prepareDataForSaveAndSend = function (ticketsWithHolders, totalCost, anonymousTicket) {
        var _this = this;
        observableCombineLatest([
            this._store.pipe(select(fromRoot.getExhibitionSettings)),
            this._store.pipe(select(fromRoot.getPaymentMethod)),
            this._store.pipe(select(fromRoot.isTicketHolderVisible)),
            this._store.pipe(select(fromRoot.getOrderUuid)),
            this._store.pipe(select(fromRoot.getShoppingStartTime))
        ])
            .pipe(filter(function (data) {
            var settings = data[0], paymentMethod = data[1];
            return anonymousTicket ? !!data : !!settings && paymentMethod !== null;
        }), first())
            .subscribe(function (_a) {
            var settings = _a[0], paymentMethod = _a[1], isTicketHolderVisible = _a[2], uuid = _a[3], shoppingStartTime = _a[4];
            observableCombineLatest([
                _this._store.pipe(select(fromRoot.getSteps)),
                _this._store.pipe(select(fromRoot.getProfile)),
                _this._store.pipe(select(fromRoot.getSelectedExhibitionId)),
                _this._store.pipe(select(fromRoot.getBuyerActiveBillingAddressId)),
                _this._store.pipe(select(fromRoot.getLanguage)),
                _this._store.pipe(select(fromRoot.getTicketSelectedSendingOptions))
            ])
                .pipe(filter(function (data) {
                var steps = data[0], profile = data[1], eventId = data[2];
                return !!steps && !!eventId;
            }), first())
                .subscribe(function (_a) {
                var steps = _a[0], profile = _a[1], eventId = _a[2], billingaddressId = _a[3], language = _a[4], selectedSendingOption = _a[5];
                var dataToSave = {
                    paymentProvider: paymentMethod,
                    userAgent: _this._helperService.browserInfo(),
                    shoppingStartTime: shoppingStartTime,
                    shoppingEndTime: new Date(),
                    order: {
                        userId: profile ? profile.id : null
                    },
                    day: new Date() // xgebi Date should be chosen same as amano parking
                };
                dataToSave.order['eventId'] = eventId;
                dataToSave.order['uuid'] = uuid;
                dataToSave.order['parkingTickets'] = [];
                var storedParkingTickets = getLocalStorageString(AppConstants.parkingTicketsReducer);
                var parkingTicketsDictionary = storedParkingTickets && JSON.parse(storedParkingTickets);
                var parsedParkingTickets = Object.keys(parkingTicketsDictionary).reduce(function (filtered, key) {
                    if (parkingTicketsDictionary[key].hasOwnProperty('price')) {
                        filtered[key] = parkingTicketsDictionary[key];
                    }
                    return filtered;
                }, {});
                for (var ticketKey in parsedParkingTickets) {
                    var _b = parsedParkingTickets[ticketKey], since = _b.since, until = _b.until;
                    var ticketKeyData = ticketKey.split('_');
                    var groupId = Number(ticketKeyData[0]);
                    var ticketTypeId = Number(ticketKeyData[1]);
                    since = _this._helperService.toStringWithoutOffset(new Date(since));
                    until = _this._helperService.toStringWithoutOffset(new Date(until));
                    dataToSave.order['parkingTickets'].push({
                        groupId: groupId,
                        ticketTypeId: ticketTypeId,
                        since: since,
                        until: until
                    });
                }
                for (var stepKey in steps) {
                    var forms = steps[stepKey]
                        ? steps[stepKey].forms
                        : null;
                    if (forms) {
                        for (var formKey in forms) {
                            var form = forms[formKey];
                            if (form && form.list) {
                                // we need to ensure that we get values of all inputs, even those which are disabled
                                form.list.forEach(function (input) {
                                    input.disabled = false;
                                });
                                var formObj = _this._formsService.toFormGroup(form.list, [
                                    formKey
                                ]);
                                // if it is not a ticket holder (ticket holder is comming together with tickets) add to to dataToSave
                                if (formObj &&
                                    !(formKey.substring(0, 13) === 'ticketHolder_')) {
                                    switch (formKey) {
                                        // transform data from buyer questionnaire
                                        case 'questionnaire': {
                                            dataToSave.order.questionnaireFields = _this._helperService.processQuestionnaireValuesBeforeSave(form.list);
                                            break;
                                        }
                                        case 'billingaddress': {
                                            dataToSave.order.billingAddress = _this._helperService.processFormValuesBeforeSave(formObj.value);
                                            break;
                                        }
                                        case 'buyerinfo': {
                                            var buyer = _this._helperService.processFormValuesBeforeSave(formObj.value);
                                            var privacyForm = forms['privacy'];
                                            // set privacy policy value
                                            if (privacyForm) {
                                                var privacyPolicyBase = privacyForm.list.find(function (inputBase) {
                                                    return inputBase.key === 'disclaimer';
                                                });
                                                var privacyPolicyOption = privacyPolicyBase &&
                                                    privacyPolicyBase.options.find(function (option) {
                                                        return option.key === 'disclaimerConfirmation';
                                                    });
                                                if (privacyPolicyOption) {
                                                    buyer['PrivacyPolicyAccepted'] =
                                                        privacyPolicyOption.value;
                                                }
                                                var privacyPolicyOptionalBase = privacyForm.list.find(function (inputBase) {
                                                    return inputBase.key === 'disclaimerOptional';
                                                });
                                                var privacyPolicyOptionalOption = privacyPolicyOptionalBase &&
                                                    privacyPolicyOptionalBase.options.find(function (option) {
                                                        return option.key === 'disclaimerOptionalConfirmation';
                                                    });
                                                if (privacyPolicyOptionalOption) {
                                                    buyer['PrivacyPolicyOptionalAccepted'] =
                                                        privacyPolicyOptionalOption.value;
                                                }
                                                ;
                                            }
                                            buyer = _this.removeAttributes(buyer, [
                                                'password',
                                                'verifyPassword',
                                                'CreateAccount',
                                                'DifferentBillingAddress',
                                                'verifyEmail',
                                                'createAccountButton',
                                                'address'
                                            ]);
                                            buyer = _this.renameAttributes(buyer, [
                                                {
                                                    from: 'FairCatalogue',
                                                    to: 'isFairCatalogChecked'
                                                },
                                                { from: 'Newsletter', to: 'isNewsletterChecked' }
                                            ]);
                                            buyer = _this.separateAddress(buyer);
                                            dataToSave.order['buyer'] = buyer;
                                            break;
                                        }
                                        // privacy policy (value processed in buyer)
                                        case 'privacy':
                                            break;
                                        // terms and conditions
                                        case 'checkboxes':
                                            var termsBase = form.list.find(function (term) { return term.key === 'terms'; });
                                            var tradeConditionOption = termsBase &&
                                                termsBase.options.find(function (option) { return option.key === 'tradeConditions'; });
                                            if (tradeConditionOption) {
                                                dataToSave.order['TermsAndConditionsAccepted'] =
                                                    tradeConditionOption.value;
                                            }
                                            break;
                                        // transform any other data
                                        default: {
                                            dataToSave.order[formKey] = _this._helperService.processFormValuesBeforeSave(formObj.value);
                                            break;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                // check if billing address is needed at all
                _this._store
                    .select(fromRoot.isDifferenBillingAddressUsed)
                    .pipe(first())
                    .subscribe(function (isDifferentBillingAddressUsed) {
                    if (!isDifferentBillingAddressUsed) {
                        delete dataToSave.order['billingAddress'];
                    }
                });
                var clearedTicketsWithHolders = ticketsWithHolders.map(function (ticket) {
                    ticket.ticketHolder.dateOfBirth = _this._helperService.getUTCdate(ticket.ticketHolder.dateOfBirth);
                    ticket.day = DateTime.fromISO(ticket.day).toISODate();
                    if (!isTicketHolderVisible) {
                        return __assign({}, ticket, { ticketHolder: null });
                    }
                    return ticket;
                });
                dataToSave.order.tickets = clearedTicketsWithHolders;
                dataToSave.order.orderPrice = totalCost;
                dataToSave.order.orderTicketSendingMode = selectedSendingOption;
                dataToSave.order.userLanguage = language;
                /* remove unwanted properties */
                if (dataToSave.order.hasOwnProperty('billingAddress')) {
                    delete dataToSave.order['billingAddress']['address'];
                    // in case buyer used billing address from profile of logged in user add it to billing address
                    if (billingaddressId !== null) {
                        dataToSave.order.billingAddress.id = billingaddressId;
                    }
                }
                if (dataToSave.order.hasOwnProperty('buyerinfo')) {
                    delete dataToSave.order['buyerinfo']['address'];
                    delete dataToSave.order['buyerinfo']['createAccountButton'];
                }
                // dataToSave.order.orderPrice = 0;
                if (anonymousTicket) {
                    dataToSave.order.buyer.isNewsletterChecked = false;
                    dataToSave.paymentProvider = 'FREE';
                }
                _this._store.dispatch(new stepsActions.sendOrder(dataToSave));
                console.log(JSON.stringify(dataToSave)
                    .replace(/“([^“]*?)“:/g, '$1:')
                    .replace(/“/g, "'"));
            });
        });
    };
    StepsFormsService.ngInjectableDef = i0.defineInjectable({ factory: function StepsFormsService_Factory() { return new StepsFormsService(i0.inject(i1.Router), i0.inject(i2.HttpClient), i0.inject(i3.Store), i0.inject(i4.HelperService), i0.inject(i5.FormsService), i0.inject(i6.ErrorHandlingService)); }, token: StepsFormsService, providedIn: "root" });
    return StepsFormsService;
}());
export { StepsFormsService };
