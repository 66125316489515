import { FormGroup, FormBuilder } from '@angular/forms';
import { Injectable } from '@angular/core';

import { ProductGroupModel } from './../../shared/services-with-reducers/tickets/ticket.interface';
import { WidgetIframeAttributes } from './widget-models.interface';

@Injectable({
  providedIn: 'root'
})
export class WidgetAdminService {
  constructor(private fb: FormBuilder) {}

  processWidgetSettingsForSave(
    settings: { [key: string]: any },
    saveSettingsProcessor: { [key: string]: Function }
  ) {
    for (const key in settings) {
      const setting = settings[key];

      if (saveSettingsProcessor[key]) {
        saveSettingsProcessor[key](setting, settings);
      }
    }
  }

  processWidgetSettingForAdminForm(
    settings: { [key: string]: any },
    loadSettingsProcessor: { [key: string]: Function },
    form: FormGroup
  ): { [key: string]: any } {
    let processedSettings = {};

    for (const key in settings) {
      const setting = settings[key];

      if (key in form.value) {
        if (loadSettingsProcessor[key]) {
          loadSettingsProcessor[key](setting, processedSettings, settings);
        } else {
          processedSettings[key] = setting;
        }
      }
    }

    return processedSettings;
  }

  setTicketsToFormControls(
    availableTickets: ProductGroupModel[],
    form: FormGroup
  ) {
    const ticketsFormGroup = form.get('tickets') as FormGroup;

    availableTickets.forEach(({ products }) => {
      products.forEach(product => {
        if (product.ticket != null) {
          ticketsFormGroup.addControl(
            String(product.ticket.uniqueId),
            this.fb.control(true)
          );
        }
      });
    });
  }

  createIframeEmbedCode(
    iframeURI: string,
    iframeAttributes: WidgetIframeAttributes
  ): string {
    let iframeAttributesString = '';

    for (const attribute in iframeAttributes) {
      const attributeValue = iframeAttributes[attribute];

      iframeAttributesString += ` ${attribute}="${attributeValue}"`;
    }

    return `<iframe ${iframeAttributesString} src="${iframeURI}"></iframe>`;
  }
}
