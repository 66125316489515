import { Router } from '@angular/router';
import { OnDestroy, OnInit } from '@angular/core';
import { combineLatest as observableCombineLatest, Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { AppService } from '../app.service';
import { FormsService } from '../shared/forms/forms.service';
import { StatusBarService } from '../status-bar/status-bar.service';
import { StepsFormsService } from '../shared/services-with-reducers/step-forms/steps-forms.service';
import { TicketsService } from '../shared/services-with-reducers/tickets/tickets.service';
import { TranslateService } from '@ngx-translate/core';
import { AdditionalServicesService } from './../shared/services-with-reducers/additional-services/additional-services.service';
import { AppConstants } from '../shared/app-constants';
import * as additionalServicesActions from '../shared/services-with-reducers/additional-services/additional-services.actions';
import * as fromRoot from '../app.reducer';
import * as stepsFormsActions from '../shared/services-with-reducers/step-forms/steps-forms.actions';
import * as ticketActions from '../shared/services-with-reducers/tickets/ticket.actions';
import { CustomizationService } from '../shared/services-with-reducers/customization/customization.service';
var CountdownComponent = /** @class */ (function () {
    function CountdownComponent(_store, _translateService, _ticketsService, _router, _statusBarService, _formsService, _stepsFormsService, _appService, _additionalServices, _customizationService) {
        this._store = _store;
        this._translateService = _translateService;
        this._ticketsService = _ticketsService;
        this._router = _router;
        this._statusBarService = _statusBarService;
        this._formsService = _formsService;
        this._stepsFormsService = _stepsFormsService;
        this._appService = _appService;
        this._additionalServices = _additionalServices;
        this._customizationService = _customizationService;
        this.showLoginCountdown = false;
        this.showVoucherCountdown = false;
        this.loggedOutIn = '';
        this.timeToReleaseWorkshop = '';
        this.timeToReleaseTicket = '';
        this.ticketBookingTimeoutModalOpen = false;
        this._subscription = new Subscription();
    }
    CountdownComponent.prototype.getCountdownTime = function (secondsTotal) {
        var minRemaining = Math.floor(secondsTotal / 60);
        var secRemaining = Math.floor(secondsTotal - minRemaining * 60);
        var secRemainingWithTwoDigits = secRemaining < 10 ? "0" + secRemaining : secRemaining;
        return minRemaining + ":" + secRemainingWithTwoDigits;
    };
    CountdownComponent.prototype.ngOnDestroy = function () {
        this._subscription.unsubscribe();
        clearInterval(this.loginInterval);
        clearInterval(this.vouchersInterval);
        clearInterval(this.workshopInterval);
        this.loginInterval = null;
        this.vouchersInterval = null;
    };
    CountdownComponent.prototype.ngOnInit = function () {
        var _this = this;
        /* LOGIN TIMEOUT */
        var showMinutesBefore = 30;
        var authTimespanInMs = AppConstants.TOKEN_VALIDITY_IN_MINUTES * 60 * 1000;
        this._subscription.add(this._store.select(fromRoot.getLoginTimestamp).subscribe(function (timestamp) {
            if (_this.loginInterval) {
                clearInterval(_this.loginInterval);
                _this.loginInterval = null;
            }
            if (timestamp) {
                _this.loginInterval = setInterval(function () {
                    var now = Date.now();
                    if (timestamp + authTimespanInMs - 30 * 60 * 1000 < now) {
                        _this.showLoginCountdown = true;
                        var loggedOutInSecTotal = (timestamp + authTimespanInMs - now) / 1000;
                        _this.loggedOutIn = _this.getCountdownTime(loggedOutInSecTotal);
                    }
                    else {
                        _this.showLoginCountdown = false;
                    }
                }, 1000);
            }
            else {
                _this.showLoginCountdown = false;
            }
        }));
        /* TICKETS COUNTDOWN */
        this._subscription.add(observableCombineLatest(this._store.select(fromRoot.getTicketsBooking), this._store.select(fromRoot.getExhibitionSettings), this._store.select(fromRoot.getSelectedStep))
            .pipe(filter(function (data) { return !!data[0]; }))
            .subscribe(function (data) {
            var bookings = data[0], eventSettings = data[1], selectedStep = data[2];
            if (!eventSettings) {
                clearInterval(_this.ticketInterval);
                _this.ticketInterval = null;
                _this.timeToReleaseTicket = '';
                return;
            }
            if (_this.ticketInterval) {
                clearInterval(_this.ticketInterval);
                _this.ticketInterval = null;
            }
            var latestBookingTimestamp = bookings.timestamp ? bookings.timestamp : 1;
            if (latestBookingTimestamp) {
                var releaseOn_1 = latestBookingTimestamp +
                    eventSettings.ticketReleaseInMinutes * 60 * 1000;
                var noBookings = bookings.bookings.every(function (booking) {
                    return booking.count === 0;
                });
                // if ticket timeout is created and there is workshop timeout we remove it
                clearInterval(_this.workshopInterval);
                _this.workshopInterval = null;
                _this.timeToReleaseWorkshop = '';
                if (noBookings || selectedStep === 'invoice') {
                    clearInterval(_this.ticketInterval);
                    _this.ticketInterval = null;
                    _this.timeToReleaseTicket = '';
                    return;
                }
                _this.ticketInterval = setInterval(function () {
                    var now = Date.now();
                    _this.timeToReleaseTicket = _this.getCountdownTime((releaseOn_1 - now) / 1000);
                    if (releaseOn_1 < now) {
                        // release all tickets. Bookings are released automatically on BE, so we just reset the reducer without user
                        _this.ticketBookingTimeoutModalOpen = true;
                        clearInterval(_this.ticketInterval);
                        _this.ticketInterval = null;
                        _this.timeToReleaseTicket = '';
                        clearInterval(_this.workshopInterval);
                        _this.workshopInterval = null;
                        _this.timeToReleaseWorkshop = '';
                    }
                }, 1000);
            }
        }));
        /* WORKSHOPS COUNTDOWN */
        this._subscription.add(observableCombineLatest(this._store.select(fromRoot.getWorkshopBookings), this._store.select(fromRoot.getExhibitionSettings), this._store.select(fromRoot.getSelectedStep))
            .pipe(filter(function (_a) {
            var workshopBookings = _a[0];
            if (workshopBookings && workshopBookings.bookings.length > 0) {
                return true;
            }
        }))
            .subscribe(function (data) {
            var bookings = data[0], eventSettings = data[1], selectedStep = data[2];
            if (!eventSettings) {
                clearInterval(_this.workshopInterval);
                _this.workshopInterval = null;
                _this.timeToReleaseWorkshop = '';
                // reset bookings on BE
                if (_this.orderId) {
                    _this._store.dispatch(new additionalServicesActions.RemoveMyWorkshopBookings());
                    _this._additionalServices
                        .releaseBookings(_this.orderId)
                        .subscribe();
                    // reset orderId to prevent reset each time
                    _this.orderId = null;
                }
                return;
            }
            _this._store
                .select(fromRoot.getOrderUuid)
                .pipe(first())
                .subscribe(function (uuid) { return (_this.orderId = uuid); });
            if (_this.workshopInterval) {
                clearInterval(_this.workshopInterval);
                _this.workshopInterval = null;
            }
            var latestBookingTimestamp = bookings.timestamp;
            if (latestBookingTimestamp) {
                var releaseOn_2 = latestBookingTimestamp +
                    eventSettings.workshopSeatsReleaseInMinutes * 60 * 1000;
                var noBookings = bookings.bookings.every(function (booking) {
                    return !booking.myBookings.length;
                });
                //if there is a ticket interval we don't need workshop interval !
                if (noBookings ||
                    selectedStep === 'invoice' ||
                    _this.ticketInterval) {
                    clearInterval(_this.workshopInterval);
                    _this.workshopInterval = null;
                    _this.timeToReleaseWorkshop = '';
                    return;
                }
                _this.workshopInterval = setInterval(function () {
                    var now = Date.now();
                    _this.timeToReleaseWorkshop = _this.getCountdownTime((releaseOn_2 - now) / 1000);
                    if (releaseOn_2 < now) {
                        // release all workshops. Bookings are released automatically on BE, so just clear my bookings and remove relations between workshop and
                        bookings.bookings.forEach(function (booking) {
                            booking.myBookings.forEach(function (myBooking) {
                                var data = {
                                    ticketHolderNumber: myBooking.ticketHolderId,
                                    workshopId: booking.workshopId,
                                    isAdded: false,
                                    subject: null
                                };
                                _this._store.dispatch(new stepsFormsActions.assignWorkshopToHolder({
                                    workshopToHolder: data,
                                    workshopLimit: eventSettings.workshopsPerTicket
                                }));
                            });
                        });
                        _this._store.dispatch(new additionalServicesActions.RemoveMyWorkshopBookings());
                        if (eventSettings.isWorkshopsSelectionMandatory) {
                            _this._formsService.setFormValidity(false, null, [
                                'workshop',
                                'validation'
                            ]);
                            _this._stepsFormsService.navigateToLastNotDisabledPage();
                            _this._statusBarService.translateAndSetStatus('workshop-mandatory.timer-ranOut', 'error');
                        }
                        else {
                            _this._statusBarService.translateAndSetStatus('workshop.timer-ranOut', 'info');
                        }
                        clearInterval(_this.workshopInterval);
                        _this.workshopInterval = null;
                        _this.timeToReleaseWorkshop = '';
                    }
                }, 1000);
            }
        }));
        /* VOUCHERS COUNTDOWN */
        this._subscription.add(this._store
            .select(fromRoot.getVoucherTickets)
            .subscribe(function (activeVouchers) {
            clearInterval(_this.vouchersInterval);
            _this.vouchersInterval = null;
            if (activeVouchers.length) {
                // otherwise check if there is interval already, if not, create new one
                _this.vouchersInterval = setInterval(function () {
                    var now = Date.now();
                    var initialValues = {
                        code: '',
                        remaining: Infinity,
                        showTime: ''
                    };
                    _this.nextExpiringVoucher = activeVouchers.reduce(function (result, currVoucher) {
                        // we only relase limited wouchers
                        // Bug - 3271 (iSm): Ticket expire countdown is not visible for single voucher
                        // if (currVoucher.quantity > 0) {
                        var timeWhenEnds = currVoucher.releasedInMinutes * 60 * 1000 +
                            currVoucher.activatedTimestamp;
                        if (timeWhenEnds > now) {
                            // if the voucher is still valid get its time
                            if (timeWhenEnds - now < result.remaining) {
                                result.remaining = timeWhenEnds - now;
                                result.code = currVoucher.voucherCode;
                            }
                            return result;
                        }
                        else {
                            /* At this time the voucher was released by backend, so we need to remove it from FE as well */
                            _this._store
                                .select(fromRoot.getTickets)
                                .pipe(first())
                                .subscribe(function (tickets) {
                                var voucherTicket = _this._ticketsService.getTicketByVoucherCode(currVoucher.voucherCode, tickets);
                                // now remove holder forms related to this voucher ticket if there is any
                                voucherTicket.holdersIndexes.forEach(function (holderIndex) {
                                    _this._ticketsService.removeTicketHolder(holderIndex);
                                });
                                _this._store.dispatch(new ticketActions.RemoveVoucher({
                                    voucherCode: currVoucher.voucherCode,
                                    skipAnonymousChecks: false
                                }));
                                observableCombineLatest(_this._store.select(fromRoot.getSelectedExhibition), _this._store.select(fromRoot.getSelectedStep), _this._store.select(fromRoot.getTicketsCount))
                                    .pipe(filter(function (data) {
                                    return !!data[0] && !!data[1];
                                }), first())
                                    .subscribe(function (data) {
                                    var exhibition = data[0], selectedStep = data[1], ticketsCount = data[2];
                                    if (!ticketsCount && selectedStep !== 'tickets') {
                                        _this._router.navigate([
                                            "webshop/" + exhibition.id + "/tickets"
                                        ]);
                                    }
                                    _this._translateService
                                        .get('voucher.timer-ranOut')
                                        .subscribe(function (translation) {
                                        _this._statusBarService.setStatus(translation, 'error');
                                    });
                                });
                            });
                            return result;
                        }
                        // } else {
                        //   // if the quantity was set to 0, it means we are dealing unlimited voucher and we can skip it
                        //   return result;
                        // }
                    }, initialValues);
                    _this.nextExpiringVoucher.showTime = _this.getCountdownTime(_this.nextExpiringVoucher.remaining / 1000);
                }, 1000);
            }
            else {
                _this.nextExpiringVoucher = null;
            }
        }));
    };
    CountdownComponent.prototype.closeModalWindow = function (event) {
        var _this = this;
        event.preventDefault();
        event.stopPropagation();
        this.ticketBookingTimeoutModalOpen = false;
        this._store.pipe(select(fromRoot.getSelectedExhibitionId), first()).subscribe(function (eventId) {
            _this._customizationService.resetShoppingStartTime();
            // release tickets with day limit
            _this._ticketsService.postReleaseTicketForDay();
            _this._appService.resetReducers().subscribe(function () {
                if (eventId) {
                    window.location.replace("/webshop/" + eventId);
                }
                else {
                    window.location.replace("/");
                }
            });
        });
    };
    CountdownComponent.prototype.closeModalWindowOnRightClick = function (event) {
        var _this = this;
        this._store
            .select(fromRoot.isLoggedInAsAdmin)
            .first()
            .subscribe(function (isAdmin) {
            if (isAdmin) {
                _this.ticketBookingTimeoutModalOpen = false;
            }
        });
    };
    return CountdownComponent;
}());
export { CountdownComponent };
