import * as fromRoot from '../../../app.reducer';
import * as ticketActions from '../../../shared/services-with-reducers/tickets/ticket.actions';
import { PackagesService } from './../../../shared/services-with-reducers/tickets/packages.service';
import { FormsService } from './../../../shared/forms/forms.service';
import { EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
var ERROR_PACKAGE_LIMIT_WARNING = 'packages-selection.warning';
var PackageCounterComponent = /** @class */ (function () {
    function PackageCounterComponent(_packagesService, _formsService, _store) {
        this._packagesService = _packagesService;
        this._formsService = _formsService;
        this._store = _store;
        this.packageCounterChange = new EventEmitter();
        this.packageCounterWarning = new EventEmitter();
        this.isPackageTicketsBookingLoading = false;
        this.isPackageDisabled = false;
        this.subscriptions = new Subscription();
    }
    PackageCounterComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.package.count = this._packagesService.getPackageCount(this.package, this.package.count);
        this.previousValue = this.package.count;
        this._packagesService.enableDisabledPackageEvent.subscribe(function () {
            _this.packageCounterWarning.emit('');
            _this.package.isDisabled = false;
        });
        this.subscriptions.add(this._store.pipe(select(fromRoot.getIsAnonymousTicketTaken), filter(function (anonymousTicketTaken) { return anonymousTicketTaken; }))
            .subscribe(function () {
            if (_this.package.count > 0) {
                _this.onValueChange({ target: { value: 0 } });
            }
        }));
    };
    PackageCounterComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    Object.defineProperty(PackageCounterComponent.prototype, "counter", {
        get: function () {
            return this.counterValue;
        },
        set: function (value) {
            var _this = this;
            this._store.dispatch(new ticketActions.SetIsPackageLoading(true));
            this._formsService.setFormValidity(false, null, ['tickets', 'packageLoading']);
            this.packageCounterWarning.emit('');
            var changeCounterSuccess = function () {
                _this.package.count = _this._packagesService.getPackageCount(_this.package, value);
                _this.counterValue = _this.package.count;
                _this.packageCounterChange.emit({
                    value: _this.counterValue,
                    previousValue: _this.previousValue,
                    decrease: _this.counterValue - _this.previousValue < 0 ? true : false
                });
                _this.previousValue = _this.counterValue;
            };
            changeCounterSuccess();
        },
        enumerable: true,
        configurable: true
    });
    PackageCounterComponent.prototype.onDecrease = function () {
        this.package.isDisabled = false;
        this.onValueChange({
            target: {
                value: this.previousValue > 0 ? this.previousValue - 1 : 0
            }
        });
    };
    PackageCounterComponent.prototype.onIncrease = function () {
        var _a = this._packagesService.disableAddingPackageOnTicketLimit(this.package), isDisabled = _a.isDisabled, disableNext = _a.disableNext;
        if (isDisabled) {
            this.package.isDisabled = isDisabled;
            this.packageCounterWarning.emit(ERROR_PACKAGE_LIMIT_WARNING);
        }
        if (!isDisabled) {
            this.onValueChange({
                target: {
                    value: this.previousValue >= 0 ? this.previousValue + 1 : this.previousValue
                }
            });
        }
        if (disableNext) {
            this.package.isDisabled = disableNext;
            this.packageCounterWarning.emit(ERROR_PACKAGE_LIMIT_WARNING);
        }
    };
    PackageCounterComponent.prototype.onValueChange = function (event) {
        var value = Number(event.target.value);
        this.counterValue = value > 0 ? value : 0;
        this.counter = this.counterValue;
    };
    return PackageCounterComponent;
}());
export { PackageCounterComponent };
