import * as customizationActions from '../customization/customization.actions';
import * as exhibitionActions from './exhibition.actions';
import * as fromRoot from '../../../app.reducer';
import * as ticketActions from '../tickets/ticket.actions';
import { combineLatest as observableCombineLatest } from 'rxjs';
import { filter, first, skip, debounceTime } from 'rxjs/operators';
import { CustomizationService } from '../customization/customization.service';
import { HelperService } from '../helpers/helper.service';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TicketsService } from '../tickets/tickets.service';
import { environment } from '../../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../helpers/helper.service";
import * as i3 from "@ngrx/store";
import * as i4 from "../customization/customization.service";
import * as i5 from "../tickets/tickets.service";
import * as i6 from "@angular/router";
var ExhibitionService = /** @class */ (function () {
    function ExhibitionService(_http, _helpers, _store, _customizationService, _ticketsService, _route) {
        var _this = this;
        this._http = _http;
        this._helpers = _helpers;
        this._store = _store;
        this._customizationService = _customizationService;
        this._ticketsService = _ticketsService;
        this._route = _route;
        this.exhibitionId$ = this._store.pipe(select(fromRoot.getSelectedExhibitionId));
        this.exhibitionLanguage$ = this._store.pipe(select(fromRoot.getLanguage));
        /* we skip initial value or value loaded from localstorage,
           so we load exhibition related data only when we realy change exhibition.
           In the reducer no Exhibition ID is set unless it differ from previous one (either from url or localstorage)
        */
        this.exhibitionId$
            .pipe(skip(1), debounceTime(50))
            .subscribe(function (exhibitionId) {
            if (exhibitionId || exhibitionId === 0) {
                var preferedTicketPersonNr = _this._route.snapshot.queryParamMap.get('pt');
                var preferedTicketGroupNr = _this._route.snapshot.queryParamMap.get('tt');
                _this._store.dispatch(new customizationActions.GetExhibitionSettings(exhibitionId));
                _this._store.dispatch(new ticketActions.GetTicketsTypesAction({
                    eventId: exhibitionId,
                    preferedTicketPersonNr: preferedTicketPersonNr
                        ? Number(preferedTicketPersonNr)
                        : null,
                    preferedTicketGroupNr: preferedTicketGroupNr
                        ? Number(preferedTicketGroupNr)
                        : null
                }));
                _this._store
                    .pipe(select(fromRoot.getSelectedExhibition), filter(function (data) { return !!data; }), first())
                    .subscribe(function (exhibition) {
                    if (!exhibition.isOver) {
                        _this._ticketsService
                            .getSynchronizedBarcoodes(exhibitionId)
                            .pipe(first())
                            .subscribe();
                    }
                });
                _this._customizationService.triggerOnExhibitionChange(exhibitionId);
            }
        });
        this.exhibitionId$.pipe(skip(1)).subscribe(function (exhibitionId) {
            var exhibitionOrOperatorId = exhibitionId ? exhibitionId : -1;
            _this._store.dispatch(new exhibitionActions.GetTitles(exhibitionOrOperatorId));
            _this._store.dispatch(new exhibitionActions.GetProfessions(exhibitionOrOperatorId));
            _this._store.dispatch(new exhibitionActions.GetDepartments(exhibitionOrOperatorId));
            _this._store.dispatch(new exhibitionActions.GetOccupationalGroups(exhibitionOrOperatorId));
        });
        observableCombineLatest([this.exhibitionId$, this.exhibitionLanguage$])
            .pipe(filter(function (data) {
            return !!data[1];
        }))
            .subscribe(function (data) {
            var exhibitionId = data[0], exhibitionLanguage = data[1];
            var exhibitionOrOperatorId = exhibitionId ? exhibitionId : -1;
            // effects are no more triggered in this loop so call it in next one
            setTimeout(function () {
                _this._store.dispatch(new customizationActions.GetLocalizedImages({
                    eventId: Number(exhibitionOrOperatorId),
                    countryCode: exhibitionLanguage
                }));
            });
        });
    }
    ExhibitionService.prototype.getUpcomingExhibitionList = function (langCode) {
        return this._http.get("" + environment.protocol + environment.webApiUrl + "/event/active/" + langCode);
    };
    ExhibitionService.prototype.getAllExhibitionsList = function (langCode) {
        return this._http.get("" + environment.protocol + environment.webApiUrl + "/event/all/" + langCode);
    };
    ExhibitionService.prototype.getDoubleClickScripts = function (eventId) {
        return this._http.get("" + environment.protocol + environment.webApiUrl + "/event/" + eventId + "/double-click-scripts");
    };
    ExhibitionService.prototype.getGenericScripts = function (eventId) {
        return this._http.get("" + environment.protocol + environment.webApiUrl + "/event/" + eventId + "/scripts");
    };
    ExhibitionService.prototype.getExhibitionHistoryList = function () {
        return this._http.get("" + environment.protocol + environment.webApiUrl + "/user/events-history");
    };
    ExhibitionService.prototype.getTitles = function (eventId) {
        var selfReg = '';
        if (this._helpers.isSelfregistration()) {
            selfReg = '?sr=true';
        }
        return this._http.get("" + environment.protocol + environment.webApiUrl + "/event/" + eventId + "/titles" + selfReg);
    };
    ExhibitionService.prototype.getProfessions = function (eventId) {
        var selfReg = '';
        if (this._helpers.isSelfregistration()) {
            selfReg = '?sr=true';
        }
        return this._http.get("" + environment.protocol + environment.webApiUrl + "/event/" + eventId + "/positions" + selfReg);
    };
    ExhibitionService.prototype.getDepartments = function (eventId) {
        var selfReg = '';
        if (this._helpers.isSelfregistration()) {
            selfReg = '?sr=true';
        }
        return this._http.get("" + environment.protocol + environment.webApiUrl + "/event/" + eventId + "/departments" + selfReg);
    };
    ExhibitionService.prototype.getOccupationalGroups = function (eventId) {
        var selfReg = '';
        if (this._helpers.isSelfregistration()) {
            selfReg = '?sr=true';
        }
        return this._http.get("" + environment.protocol + environment.webApiUrl + "/event/" + eventId + "/occupational-groups" + selfReg);
    };
    ExhibitionService.prototype.getAllProfessions = function () {
        return this._http.get("" + environment.protocol + environment.webApiUrl + "/event/positions");
    };
    ExhibitionService.prototype.getAllDepartments = function () {
        return this._http.get("" + environment.protocol + environment.webApiUrl + "/event/departments");
    };
    ExhibitionService.prototype.getAllOccupationalGroups = function () {
        return this._http.get("" + environment.protocol + environment.webApiUrl + "/event/occupational-groups");
    };
    ExhibitionService.prototype.getAllTitles = function () {
        return this._http.get("" + environment.protocol + environment.webApiUrl + "/event/titles");
    };
    ExhibitionService.prototype.getEventSeries = function (seriesId, language) {
        return this._http.get("" + environment.protocol + environment.webApiUrl + "/event-series/" + seriesId + "/" + language);
    };
    ExhibitionService.ngInjectableDef = i0.defineInjectable({ factory: function ExhibitionService_Factory() { return new ExhibitionService(i0.inject(i1.HttpClient), i0.inject(i2.HelperService), i0.inject(i3.Store), i0.inject(i4.CustomizationService), i0.inject(i5.TicketsService), i0.inject(i6.ActivatedRoute)); }, token: ExhibitionService, providedIn: "root" });
    return ExhibitionService;
}());
export { ExhibitionService };
