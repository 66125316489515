/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./billing-address.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../../shared/forms/df-input/df-input.component.ngfactory";
import * as i6 from "../../../shared/forms/df-input/df-input.component";
import * as i7 from "../../../shared/forms/forms.service";
import * as i8 from "../../../shared/services-with-reducers/helpers/helper.service";
import * as i9 from "@ngrx/store";
import * as i10 from "../../../shared/validation/validation.service";
import * as i11 from "../../../shared/pipes/hide-translation/hide-translation.pipe";
import * as i12 from "./billing-address.component";
var styles_BillingAddressComponent = [i0.styles];
var RenderType_BillingAddressComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BillingAddressComponent, data: {} });
export { RenderType_BillingAddressComponent as RenderType_BillingAddressComponent };
function View_BillingAddressComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "option", [], [[8, "selected", 0]], null, null, null, null)), i1.ɵdid(1, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), i1.ɵdid(2, 147456, null, 0, i2.ɵangular_packages_forms_forms_r, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i1.ɵted(3, null, [" ", " - ", " - ", " "]))], function (_ck, _v) { var currVal_1 = _v.context.index; _ck(_v, 1, 0, currVal_1); var currVal_2 = _v.context.index; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit.id == _co.selectedBuyerBillAddressId); _ck(_v, 0, 0, currVal_0); var currVal_3 = _v.context.$implicit.city; var currVal_4 = _v.context.$implicit.street; var currVal_5 = _v.context.$implicit.zipCode; _ck(_v, 3, 0, currVal_3, currVal_4, currVal_5); }); }
function View_BillingAddressComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "input-wrapper form-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "select", [["id", "bilAddressSelect"]], [[2, "hasvalue", null]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onChange($event.target.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "option", [["value", "-1"]], null, null, null, null, null)), i1.ɵdid(3, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), i1.ɵdid(4, 147456, null, 0, i2.ɵangular_packages_forms_forms_r, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BillingAddressComponent_2)), i1.ɵdid(6, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 3, "label", [["class", "placeholder"], ["data-translation", "personalize.buyer-info.billing-address.input-placeholder"], ["for", "bilAddressSelect"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(10, 1)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "-1"; _ck(_v, 3, 0, currVal_1); var currVal_2 = "-1"; _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.billingAddressList; _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.selectedBuyerBillAddressId >= 0); _ck(_v, 1, 0, currVal_0); var currVal_4 = i1.ɵunv(_v, 8, 0, _ck(_v, 10, 0, i1.ɵnov(_v.parent, 0), i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("personalize.buyer-info.billing-address.input-placeholder")))); _ck(_v, 8, 0, currVal_4); }); }
function View_BillingAddressComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-df-input", [], null, null, null, i5.View_DfInputComponent_0, i5.RenderType_DfInputComponent)), i1.ɵdid(1, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "input-disabled": 0 }), i1.ɵdid(3, 4964352, null, 0, i6.DfInputComponent, [i7.FormsService, i8.HelperService, i9.Store, i1.ElementRef, i1.Renderer2, i10.ValidationService], { input: [0, "input"], inputSet: [1, "inputSet"], action: [2, "action"], form: [3, "form"], saveFormCallback: [4, "saveFormCallback"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.cssClass; var currVal_1 = _ck(_v, 2, 0, _v.context.$implicit.hidden); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit; var currVal_3 = _co.inputs; var currVal_4 = _co.BillingAddressActionName; var currVal_5 = _co.form; var currVal_6 = _co.formSaveCallback; _ck(_v, 3, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_BillingAddressComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "form", [["class", "row"], ["novalidate", ""]], [[2, "hide-read-only", null], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "readonly": 0 }), i1.ɵdid(3, 16384, null, 0, i2.ɵangular_packages_forms_forms_bg, [], null, null), i1.ɵdid(4, 540672, null, 0, i2.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.FormGroupDirective]), i1.ɵdid(6, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BillingAddressComponent_4)), i1.ɵdid(8, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_8 = "row"; var currVal_9 = _ck(_v, 2, 0, !_co.isEditEnabled); _ck(_v, 1, 0, currVal_8, currVal_9); var currVal_10 = _co.form; _ck(_v, 4, 0, currVal_10); var currVal_11 = _co.inputs; _ck(_v, 8, 0, currVal_11); }, function (_ck, _v) { var currVal_0 = true; var currVal_1 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 6).ngClassValid; var currVal_6 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_BillingAddressComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i11.HideTranslationPipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BillingAddressComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BillingAddressComponent_3)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.billingAddressList.length; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.form; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_BillingAddressComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-billing-address", [], null, null, null, View_BillingAddressComponent_0, RenderType_BillingAddressComponent)), i1.ɵdid(1, 245760, null, 0, i12.BillingAddressComponent, [i9.Store, i7.FormsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BillingAddressComponentNgFactory = i1.ɵccf("app-billing-address", i12.BillingAddressComponent, View_BillingAddressComponent_Host_0, {}, {}, []);
export { BillingAddressComponentNgFactory as BillingAddressComponentNgFactory };
