<ul class="navigation-steps" id="navigation-steps" *ngIf="stepList">
  <li
    class="step"
    [ngClass]="{
      disabled: step.value.disabled,
      hidden: !step.value.visible || !step.value.showInStepNavigation
    }"
    *ngFor="let step of stepList; let i = index"
    [ngStyle]="{ 'z-index': stepList.length - i }"
  >
    <!--<li class="step" [ngClass]="{'hidden': !step.value.visible}" *ngFor="let step of stepsValidity$ | async">-->
    <a
      class="step-link"
      *ngIf="!step.value.disabled"
      [routerLink]="step.key"
      [routerLinkActive]="['active-link']"
      [ngClass]="{'future': i > currentStepIndex, 'lastStepIndex': step.key === 'invoice'}"
      (click)="stepSelected(step.key)"
    >
      <span [attr.data-translation]="'step-navigation.' + step.key">
        <!-- If anonymous ticket is taken then final step name should be customized -->
        {{ anonymousTicketTaken 
          ? 
          (('step-navigation.' + step.key) === 'step-navigation.invoice') 
            ? 
            ('step-navigation.invoice-anonymous-ticket-taken' | translate | hideTranslation) 
            : 
            ('step-navigation.' + step.key | translate | hideTranslation)
          : 
          'step-navigation.' + step.key | translate | hideTranslation 
        }}
      </span>
      <svg class="step-navigation-icon">
        <use [attr.xlink:href]="'assets/defs.svg#icon-' + step.key"></use>
      </svg>
    </a>

    <div *ngIf="step.value.disabled" class="step-link">
      <span [attr.data-translation]="'step-navigation.' + step.key">
        {{ 'step-navigation.' + step.key | translate | hideTranslation }}
      </span>
      <svg class="step-navigation-icon">
        <use [attr.xlink:href]="'assets/defs.svg#icon-' + step.key"></use>
      </svg>
    </div>
  </li>
</ul>
