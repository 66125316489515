/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./press-news-detail.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./press-news-detail.component";
import * as i4 from "@angular/router";
import * as i5 from "@ngrx/store";
var styles_PressNewsDetailComponent = [i0.styles];
var RenderType_PressNewsDetailComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PressNewsDetailComponent, data: {} });
export { RenderType_PressNewsDetailComponent as RenderType_PressNewsDetailComponent };
export function View_PressNewsDetailComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "detail-wrapper container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["class", "detail-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 5, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "span", [["class", "date"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵppd(10, 2), (_l()(), i1.ɵeld(11, 0, null, null, 0, "div", [["class", "detail-body"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.singleNews.title; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.singleNews.subtitle; _ck(_v, 7, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i1.ɵnov(_v, 0), _co.singleNews.releasedTime, "dd. MMM y")); _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.singleNews.body; _ck(_v, 11, 0, currVal_3); }); }
export function View_PressNewsDetailComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-press-news-detail", [], null, null, null, View_PressNewsDetailComponent_0, RenderType_PressNewsDetailComponent)), i1.ɵdid(1, 245760, null, 0, i3.PressNewsDetailComponent, [i4.ActivatedRoute, i5.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PressNewsDetailComponentNgFactory = i1.ɵccf("app-press-news-detail", i3.PressNewsDetailComponent, View_PressNewsDetailComponent_Host_0, {}, {}, []);
export { PressNewsDetailComponentNgFactory as PressNewsDetailComponentNgFactory };
