import { FormComponent } from './form/form.component';
import * as fromRoot from '../../../app.reducer';
import * as stepsActions from '../../../shared/services-with-reducers/step-forms/steps-forms.actions';
import * as ticketActions from '../../../shared/services-with-reducers/tickets/ticket.actions';

import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ChangeDetectorRef,
  ViewChild,
  Output,
  EventEmitter
} from '@angular/core';
import {
  FormInputsPayloadModel,
  InputsListModel,
  PersonalizationValidationModel,
  TicketHolderAdditionalDataModel
} from '../../../shared/services-with-reducers/step-forms/step.interface';
import {
  Subscription,
  combineLatest,
  Observable,
  BehaviorSubject
} from 'rxjs';
import { filter, first, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { ActivatedRoute } from '@angular/router';
import { AppConstants } from '../../../shared/app-constants';
import { HelperService } from '../../../shared/services-with-reducers/helpers/helper.service';
import { InputBase } from '../../../shared/forms/inputs/input-base.class';
import { select, Store } from '@ngrx/store';
import { EmailData, TicketModel, ValidateDailyTicketPerEmailLimitBody, ValidateDailyTicketPerEmailLimitResult } from '../../../shared/services-with-reducers/tickets/ticket.interface';
import { WindowSizeService } from '../../../shared/window-size/window-size.service';
import { WorkshopModel } from '../../../shared/services-with-reducers/additional-services/additional-services.interface';
import { FormsService } from '../../../shared/forms/forms.service';
import { TicketsService } from '../../../shared/services-with-reducers/tickets/tickets.service';
import { consoleLog } from '../../../shared/app-utils';
import { ExhibitionSettingModel } from '../../../shared/services-with-reducers/customization/customization.interfaces';
import cloneDeep from 'lodash.clonedeep';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup } from '@angular/forms';
import { TextOrDropdownInputTypes } from './../../../shared/services-with-reducers/helpers/helper.interface';

@Component({
  moduleId: module.id,
  selector: 'app-order-tickets',
  templateUrl: './order-tickets.component.html',
  styleUrls: ['./order-tickets.component.scss']
})
export class OrderTicketsComponent implements OnInit, OnDestroy {
  @Input()
  ticketSelectedSendingOption: string;
  @Input()
  isInputChanged$: Observable<boolean> = new Observable<boolean>();
  @Output()
  isVisitorQuestionnaireShown: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild(FormComponent) private formComponent: FormComponent;

  public activeSlideIndex: number;
  public ticketHolderInputSets: FormInputsPayloadModel[];
  public copyAddressChecked = false;
  public ticketHoldersValidity: boolean[];
  public ticketNames: Array<any> = [];
  public checkedSlideIndex: number = null;
  public shouldDisplayCheckbox = true;
  public slideWithBuyerVisitor: number = null;
  public shouldDisableBuyerVisitorCheckbox = false;
  public isBuyerVisitorChecked = false;
  public modalWindowOpen: boolean = false;
  public AppConstants = AppConstants;
  public isNextandPreviousButtonDisabled: boolean = false;
  public uniqueVisitorCheckType: string = null;
  public ticketHolderArray: Array<Record<string, any> & { index: number }> = [];
  public canDeleteTicketInfo: boolean = false;
  public isVisitorQuestionnaireEnabled: boolean = false;
  public visitorQuestionnaireValidation = ['personal', 'visitorQuestionnaire'];

  private _buyerInputs: InputBase<any>[];
  private _subscriptions = new Subscription();
  private _ticketHoldersNumber = 0;
  private coppiedHolderDataIndexes: number[] = [];
  private selectedExhibitionId: number;
  public exhibitionSettings: ExhibitionSettingModel;
  private isSelfRegistrationEnabled: boolean;
  private hasTicketHolderEmailInput: boolean = false;
  private isTicketHolderEmailMandatory: boolean = false;
  private hasBuyerInfoChanged: boolean = false;
  private ticketHoldersFormsValidity: boolean[];
  private isBuyerInfoValid: boolean = false;
  private hasMultipleTicketHolders: boolean = false;

  //duplicates check:
  private needsDuplicatesCheck: boolean = true;
  public showDuplicatesWarning: boolean = false;
  public showDuplicatedWarningAlreadyClosed = false;
  private inputNamesForDuplicateSearch = [['firstName', 'lastName'], ['email']];
  private inputNamesForDuplicatesCheck: Array<string> = [];
  private isDuplicatesCheckDone$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  //tickets over limit check:
  private needsTicketsOverLimitCheck: boolean = true;
  public showTicketLimitWarning: boolean = false;
  public showTicketLimitWarningAlreadyClosed = false;
  private inputNamesForTicketsOverlimitChecks = ['email'];
  private isTicketsOverLimitCheckDone$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  //validity check results:
  private duplicatesList: Array<any> = [];;
  private ticketsOverLimitList: Array<any> = [];

  private prevDataToCheck: PersonalizationValidationModel;
  private triggerValidation$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private _store: Store<fromRoot.State>,
    private _route: ActivatedRoute,
    private _windowSizeService: WindowSizeService,
    private _helperService: HelperService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _formsService: FormsService,
    private _ticketService: TicketsService,
    private _translateService: TranslateService
  ) {
    this._subscriptions.add(
      this._store.pipe(
        select(fromRoot.getSelectedExhibitionId)
      )
        .subscribe(eventId => {
          this.selectedExhibitionId = eventId;
        })
    );

    this._subscriptions.add(
      this._store.pipe(
        select(fromRoot.getExhibitionSettings),
        filter(data => !!data)
      )
        .subscribe(settings => {
          this.exhibitionSettings = settings;
          this.needsTicketsOverLimitCheck = this.exhibitionSettings.ticketLimitPerEmail > 0 && this.inputNamesForTicketsOverlimitChecks.length > 0;
        })
    );

    this.isSelfRegistrationEnabled = this._helperService.isSelfregistration();

    this._subscriptions.add(
      this._store.pipe(select(fromRoot.getCoppiedHoldersIndexes)).subscribe(item => {
        this.coppiedHolderDataIndexes = !!item ? [...item] : [];
      })
    );

    this._subscriptions.add(
      this._store
        .select(fromRoot.getTicketHolderInputSets)
        .pipe(
          // only build the forms when number of tickets change. Form rerender logic is handled in every form itself
          filter((forms: FormInputsPayloadModel[]) => {
            return !!forms && this._ticketHoldersNumber !== forms.length;
          })
        )
        .subscribe((forms: FormInputsPayloadModel[]) => {
          this._ticketHoldersNumber = forms.length;
          this.ticketHolderInputSets = forms;

          this.ticketHolderArray = forms.map((form: FormInputsPayloadModel) => {
            return form.inputSet.list.reduce(
              (acc, input) => {
                acc[input.key] = !!input.value ? input.value : "";
                acc['index'] = form.index;

                //check if ticket holder form has e-mail input:
                if (input.key === 'email') {
                  this.hasTicketHolderEmailInput = true;

                  //check if ticket holder e-mail input is mandatory:
                  if (input.required) {
                    this.isTicketHolderEmailMandatory = true;
                  }
                }

                return acc;
              },
              {} as Record<string, any> & { index: number }
            );
          });
        })
    );

    this._subscriptions.add(
      this._store.pipe(
        select(fromRoot.getTicketHoldersValidity),
        filter(ticketHoldersValidity => !!ticketHoldersValidity),
        distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr))
      )
      .subscribe((ticketHoldersValidity: boolean[]) => {
        //if all ticket holders (and buyer info etc.) are valid the next step will be enabled:
        this.ticketHoldersValidity = ticketHoldersValidity;
      })
    );

    this._subscriptions.add(
      this._store.pipe(
        select(fromRoot.getTicketHoldersFormsValidity),
        filter(ticketHoldersFormsValidity => !!ticketHoldersFormsValidity),
        distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr))
      )
        .subscribe((ticketHoldersFormsValidity: boolean[]) => {
          //this tells us if ticket holder Angular forms (FormGroups) are valid:
          //(if a ticket holder form is valid and it passes all required validations (duplicates, tickets over limit) that ticket holder will be marked as valid)
          this.ticketHoldersFormsValidity = [...ticketHoldersFormsValidity];

          ticketHoldersFormsValidity.forEach((ticketHolderFormValidity, index) => {
            const ticketHolderValidity: boolean = this.ticketHoldersValidity[index];

            if (!this.needsDuplicatesCheck && !this.needsTicketsOverLimitCheck) {
              //as we don't need to check/validate anything we can set ticket holders statuses in correspondence with their forms statuses:
              //(it won't be done in forms.service)
              if (ticketHolderFormValidity !== ticketHolderValidity) {
                this.setTicketHolderValidity(index, ticketHolderFormValidity);
              }
            } else {
              //if there are some invalid ticket holder forms set those ticket holders to not valid:
              if (!ticketHolderFormValidity && ticketHolderValidity) {
                this.setTicketHolderValidity(index, false);
              }
            }
          });
        })
    );

    this._subscriptions.add(
      combineLatest([
        this._store.pipe(select(fromRoot.getTicketHolderInputSets)),
        this.triggerValidation$.filter(triggerValidation => triggerValidation !== null)
      ])
        .pipe(
          filter(([ticketHolderInputSets]) => (this.needsDuplicatesCheck || this.needsTicketsOverLimitCheck) && !!ticketHolderInputSets && !!this.formComponent),
          debounceTime(500)
          //distinctUntilChanged()
        )
        .subscribe(([ticketHolderInputSets]) => {
          //here we get the real value of BehaviorSubject as the value from combineLatest will not be up to date (we filter out null values)
          //true means forced validation will be performed even if no inputs were changed:
          const forceValidation: boolean = this.triggerValidation$.getValue();
          //we don't want this change of BehaviorSubject to null to emit and return back here so we're filtering it in combineLatest function.
          forceValidation && this.triggerValidation$.next(null);
          //perform ticket holder forms validations if needed:
          this.checkTicketHolders(ticketHolderInputSets, forceValidation);
        })
    );

    this._subscriptions.add(
      combineLatest([
        this.isDuplicatesCheckDone$,
        this.isTicketsOverLimitCheckDone$
      ])
        .pipe(
          filter(([isDuplicatesCheckDone, isTicketsOverLimitCheckDone]) => isDuplicatesCheckDone && isTicketsOverLimitCheckDone),
        )
        .subscribe(() => {
          //when all ticket holders checks are done set ticket holders validity (if ticket holders forms are valid):
          this.ticketHoldersFormsValidity.forEach((value, index) => {
            if (!this.ticketHoldersValidity[index] && this.areTicketHolderChecksOk(index)) {
              //if ticket holder's form is valid and all validations have passed set ticket holder as valid:
              this.setTicketHolderValidity(index, true);
            }
          });
        })
    );

    this._subscriptions.add(
      this._store
        .select(fromRoot.getBuyerInfo)
        .pipe(filter(buyerInputs => !!buyerInputs))
        .subscribe((buyerInputs: InputsListModel) => {
          this._buyerInputs = buyerInputs.list;
          this.hasBuyerInfoChanged = false;

          if (this.needsTicketsOverLimitCheck && !!buyerInputs && !!buyerInputs.updatedInputs) {
            this.hasBuyerInfoChanged = buyerInputs.updatedInputs.some(updatedInput => {
              if (this.inputNamesForTicketsOverlimitChecks.includes(updatedInput)) {
                return true;
              }
            });

            if (!this.isTicketHolderEmailMandatory && this.hasBuyerInfoChanged) {
              //if ticket holder e-mail field is not mandatory and buyer info e-mail field has changed revalidate ticket holders:
              //(if a ticket holder e-mail is not provided we have to take the e-mail from the buyer)
              this.triggerValidation$.next(true);
            }
          }
        })
    );

    this._subscriptions.add(
      combineLatest(
        this._store.select(fromRoot.getTickets),
        this._store.select(fromRoot.getTicketHolderAdditionalData),
        this._store.select(fromRoot.getWorkshops)
      )
        .pipe(
          filter(
            (
              data: [
                TicketModel,
                TicketHolderAdditionalDataModel,
                WorkshopModel[]
              ]
            ) => {
              return !!data[0] && !!data[1] && !!data[2];
            }
          )
        )
        .subscribe(data => {
          const [tickets, ticketHolderAdditionalData, workshops] = data;

          /* In order to get ticket names in the same order as ticket holder forms,
         first we need to create an array with objects with only ticketIndex and name,
         then we sort the tickets by ticketIndex.
         Finaly we map it to simple array of names and make it awaylable to template */
          const ticketsIdWithName = [];

          Object.keys(tickets).forEach(key => {
            const ticketById = tickets[key];

            const contingentDaysForTicket = this._helperService.getContingentDaysForTicket(
              key
            );

            ticketById.holdersIndexes.forEach((ticketIndex, index) => {
              const contingentData = contingentDaysForTicket[index] || {};

              ticketsIdWithName.push({
                ticketIndex,
                ticketGroupName: ticketById.groupName,
                ticketName: ticketById.name,
                ticketPersonId: ticketById.ticketPersonId,
                workshopsNames: [],
                ...contingentData
              });
            });
          });

          ticketsIdWithName.sort((a, b) => {
            return a.ticketIndex - b.ticketIndex;
          });

          ticketsIdWithName.forEach(item => {
            let ticketHolder = ticketHolderAdditionalData[item.ticketIndex];
            !!ticketHolder && ticketHolder.workshops &&
              ticketHolder.workshops.forEach(workshopId => {
                let workshop = workshops.find(p => p.workshopId === workshopId);
                item.workshopsNames.push(workshop.workshopName);
              });
          });

          this.hasMultipleTicketHolders = ticketsIdWithName.length > 1;
          this.ticketNames = ticketsIdWithName;
        })
    );

    this._store
      .select(fromRoot.getAddressCheckbox)
      .pipe(
        filter(data => !!data),
        first()
      )
      .subscribe(addressCheckbox => {
        const { checkedSlideIndex, isAddressCopied } = addressCheckbox;
        this.checkedSlideIndex = checkedSlideIndex;
        let checked = this.canDeleteTicketInfo = isAddressCopied;
        // Uncheck "Copy all data from the buyer" and set the checkedSlideIndex to null
        // if ticket holder doesn't have any fields buyer data can be copied into.
        const listLength = this.ticketHolderInputSetsOfSlideIndexLength(this.ticketHolderInputSets, this.checkedSlideIndex);

        if (listLength <= 2) {
          this.copyAddressChecked = false;
          this.checkedSlideIndex = null;
          checked = false;

          this._store.dispatch(
            new stepsActions.SetAddressCheckbox({
              checkedSlideIndex: null,
              isAddressCopied: false
            })
          );
        }

        // if first slide is checked on load, check it straight away
        if (checked && checkedSlideIndex === this.activeSlideIndex) {
          this.copyAddressChecked = true;
        }

        if (checked && checkedSlideIndex !== this.activeSlideIndex) {
          this.shouldDisplayCheckbox = false;
        }
      });

    // show duplicities warning only if enabled in admin client and if firstName and lastName or email inputs are checked as visible
    combineLatest([
      this._store.pipe(select(fromRoot.uniqueVisitorCheckType)),
      this._store.pipe(select(fromRoot.getExhibitionSettings))
    ])
    .first()
    .subscribe(([uniqueVisitorCheckType, exhibitionSettings]) => {
      this.uniqueVisitorCheckType = uniqueVisitorCheckType;
      this.needsDuplicatesCheck = uniqueVisitorCheckType !== 'ignoreDuplicates' && this.inputNamesForDuplicateSearch.length > 0;

      if (this.needsDuplicatesCheck) {
        this.inputNamesForDuplicatesCheck = [];
        const ticketOwnerSettings = exhibitionSettings.ticketOwnerSettings.fieldSettings;
        // create copy so we can iterate through local object and delete data (if needed) in class object
        const inputNamesForDupliSrcCopy: string[][] = [...this.inputNamesForDuplicateSearch];

        for (let i: number = inputNamesForDupliSrcCopy.length - 1; i >= 0; i--) {
          const currInputNameForDupliSrc = inputNamesForDupliSrcCopy[i];

          for (let j: number = 0; j < currInputNameForDupliSrc.length; j++) {
            const inputName = currInputNameForDupliSrc[j];
            // check if there's input key in settings that matches inputName
            const inputKey = Object.keys(ticketOwnerSettings).find(item => item.toLocaleLowerCase() === inputName.toLocaleLowerCase());

            // if there's no input key matched in settings or there is input key but it's not visible, delete array from inputNamesForDuplicateSearch and break inner loop
            if (!inputKey || !ticketOwnerSettings[inputKey].isVisible) {
              this.inputNamesForDuplicateSearch.splice(i, 1);
              break;
            }

            // if all inputs from currInputNameForDupliSrc exist in settings and are visible, add them to inputNamesForDuplicatesCheck
            if (j === currInputNameForDupliSrc.length - 1) {
              currInputNameForDupliSrc.forEach(element => {
                this.inputNamesForDuplicatesCheck.push(element);
              });
            }
          }
        }

        // if there's no input key left in inputNamesForDuplicateSearch set needsDuplicatesCheck to false
        if (this.inputNamesForDuplicateSearch.length === 0) {
          this.needsDuplicatesCheck = false;
        }
      }
    });

    this._subscriptions.add(
      this._store.pipe(select(fromRoot.getActiveHolderSlideIndex))
        .subscribe((index: number) => {
          this.activeSlideIndex = index;
          this.copyAddressChecked = this.checkedSlideIndex === index;
          //Don't display Checkbox if there aren't fields visible to copy the buyer data into.
          const listLength = this.ticketHolderInputSetsOfSlideIndexLength(this.ticketHolderInputSets, this.activeSlideIndex);
          this.shouldDisplayCheckbox = listLength > 2 && (this.copyAddressChecked || this.checkedSlideIndex === null);

          // US2870 - buyerVisitor questionnaire
          if (this.slideWithBuyerVisitor !== null) {
            this.isBuyerVisitorChecked = this.slideWithBuyerVisitor === index;
            this.shouldDisableBuyerVisitorCheckbox  =
              this.isBuyerVisitorChecked || this.slideWithBuyerVisitor === null;

            if (this.activeSlideIndex === this.slideWithBuyerVisitor) {
              this.isVisitorQuestionnaireShown.emit(true);
            } else {
              this.isVisitorQuestionnaireShown.emit(false);
            }
          }


          this.triggerValidation$.next(false);

          if (this.isBuyerInfoValid) {
            //focus on first empty ticket holder input only if buyer info form is valid,
            //otherwise we should focus on first empty buyer info form input:
            this.focusFirstEmptyRequiredInput();
          }
        })
    );

    this._subscriptions.add(
      this._store
        .pipe(
          select(fromRoot.getBuyerInfoValidity),
          distinctUntilChanged((prev, curr) => prev === curr)
        )
        .subscribe(buyerInfoValidity => {
          this.isBuyerInfoValid = buyerInfoValidity;
        })
    );
  }

  ngOnInit() {
    if (this._route.snapshot.queryParams['scroll']) {
      setTimeout(() => {
        const carousel = (this._helperService.appEl.querySelector(
          '#holder-carousel'
        ) as any) as HTMLElement;
        this._windowSizeService.scrollToElement(carousel, 0, 50, 0.3);
      }, 500);
    }

    this._subscriptions.add(
      this._helperService.voteYesNo$.subscribe(item => {
        if (item) {
          if (this.canDeleteTicketInfo) {
            this.copyAddress(false, true);
            this.copyAddressChecked = false;
          }
        }
      })
    );

    this._subscriptions.add(
      this.isInputChanged$.subscribe(item => {
        if (this.canDeleteTicketInfo) {
          this.copyAddress(false, true);
          this.copyAddressChecked = false;
        }
      })
    );

    this._subscriptions.add(
      this._store.pipe(select(fromRoot.getBuyerVisitorCheckbox))
        .pipe(
          filter(data => !!data)
        )
        .subscribe(buyerVisitorCheckbox => {
          const { buyerVisitorCheckedSlideIndex, isBuyerVisitorChecked } = buyerVisitorCheckbox;
          this.slideWithBuyerVisitor = buyerVisitorCheckedSlideIndex;
          this.isBuyerVisitorChecked = isBuyerVisitorChecked;

          if (this.isBuyerVisitorChecked) {
            if (this.activeSlideIndex === this.slideWithBuyerVisitor) {
              this.shouldDisableBuyerVisitorCheckbox = true;
              this.isVisitorQuestionnaireShown.emit(true);
            } else {
              this.shouldDisableBuyerVisitorCheckbox = false;
              this.isVisitorQuestionnaireShown.emit(false);
            }
          }
        })
    );

    this._subscriptions.add(
      this._store.pipe(select(fromRoot.getTicketHolderQuestionnaireInputs))
        .pipe(
          filter(data => !!data)
        )
        .subscribe(ticketHolderQ => {
          this.isVisitorQuestionnaireEnabled = ticketHolderQ !== null && ticketHolderQ.length > 0 && this.ticketSelectedSendingOption === 'ticketRetrivalLink';
        })
    );
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  buyerVisitorAdditionalData(isBuyerVisitor) {
    this.isVisitorQuestionnaireShown.emit(isBuyerVisitor);
    this.modalWindowOpen = false;

    if (isBuyerVisitor) {
      this.isBuyerVisitorChecked = true;
    } else {
      (document.querySelector('input#buyerVisitor') as HTMLInputElement).checked = false;
    }

    this.slideWithBuyerVisitor = isBuyerVisitor ? this.activeSlideIndex : null;
    this._store.dispatch(
      new stepsActions.SetBuyerVisitorCheckbox({
        buyerVisitorCheckedSlideIndex: this.slideWithBuyerVisitor,
        isBuyerVisitorChecked: isBuyerVisitor,
        showVisitorQuestionnaire: this.isBuyerVisitorChecked
      })
    );
  }

  buyerVisitorPopup() {
    if (this.isBuyerVisitorChecked) {
      this.isVisitorQuestionnaireShown.emit(false);
      this._store.dispatch(
        new stepsActions.SetBuyerVisitorCheckbox({
          buyerVisitorCheckedSlideIndex: null,
          isBuyerVisitorChecked: false,
          showVisitorQuestionnaire: false
        })
      );
      this._formsService.removeAllStepValidationFeedbacks(this.visitorQuestionnaireValidation);
      this._formsService.setFormValidity(true, null, this.visitorQuestionnaireValidation);
    } else {
      this.modalWindowOpen = true;
    }
  }

  //#region Event handlers for FormComponent events:
  updatedTicketHolderInput(data: { inputs: Array<InputBase<any>>, currentIndex?: number }, internal: boolean = false) {
    setTimeout(() => {
      const { inputs, currentIndex } = data;

      const index: number = currentIndex !== null
        ? (!internal
          //if current form index came from the form component itself we need to transform that index to our ticket holder index which can be different
          //(see #3926 for repro steps):
          ? this.ticketHolderArray.findIndex(ticketHolder => ticketHolder.index === Number(currentIndex))
          : currentIndex)
        : this.activeSlideIndex;

      const updatedTicketHolderInfo: Object = inputs.reduce((acc, input) => {
        acc[input.key] = !!input.value ? input.value : "";
        return acc;
      }, {});

      const currentTicketHolderInfo = this.ticketHolderArray[index];

      this.ticketHolderArray[index] = {
        ...currentTicketHolderInfo,
        ...updatedTicketHolderInfo
      };
    }, 0);
  }

  handleFormValueChange(formComponent: FormComponent) {
    if (formComponent) {
      const ticketHolderArrayHelper = [...this.ticketHolderArray];
      const { formPath, form } = formComponent;

      const index = ticketHolderArrayHelper.findIndex(
        ticketHolder => ticketHolder.index === Number(formComponent.index)
      );

      ticketHolderArrayHelper[index] = {
        ...form.value,
        index: ticketHolderArrayHelper[index].index
      };

      this.ticketHolderArray = [...ticketHolderArrayHelper];

      if (this.needsDuplicatesCheck || this.needsTicketsOverLimitCheck) {
        //if validations are required and some of the inputs required for those validations are changed we've to invalidate the current ticket holder:
        const needsDuplicatesCheck: boolean = this.needsDuplicatesCheck && this.ticketHolderNeedsRevalidation(index, this.inputNamesForDuplicatesCheck);
        const needsTicketsOverLimitCheck: boolean = this.needsTicketsOverLimitCheck && this.ticketHolderNeedsRevalidation(index, this.inputNamesForTicketsOverlimitChecks);

        if (needsDuplicatesCheck || needsTicketsOverLimitCheck) {
          this.ticketHoldersValidity[index] && this.setTicketHolderValidity(index, false);
        } else {
          if (!form.invalid && !this.ticketHoldersValidity[index] && this.areTicketHolderChecksOk(index, form)) {
            this.setTicketHolderValidity(index, true);
          }
        }
      }
    }
  }
  //#endregion

  //#region Ticket holder validations
  checkTicketHolders(ticketHolderInputSets: FormInputsPayloadModel[], forceValidation: boolean = false) {
    this._helperService.triggerCallbackOnceFormValidationIsDone(
      this.formComponent.form,
      () => {
        //callback after FormGroup validations have completed:
        this._store.pipe(
          select(fromRoot.getTicketHoldersFormsValidity),
          first()
        )
        .subscribe((ticketHoldersFormsValidity) => {
          const data: PersonalizationValidationModel = { ticketHolderInputSets: [...ticketHolderInputSets], ticketHoldersFormsValidity: [...ticketHoldersFormsValidity] };
          const index = this.ticketHolderArray.findIndex(
            ticketHolder => ticketHolder.index === Number(this.formComponent.index)
          );

          data.ticketHoldersFormsValidity[index] = !this.formComponent.form.invalid;
          this.ticketHoldersFormsValidity = [...data.ticketHoldersFormsValidity];

          if (this.needsDuplicatesCheck || this.needsTicketsOverLimitCheck) {
            //define ticketHolderArray based on ticketHolderInputSets:
            let ticketHolderArray: Array<Record<string, any> & { index: number }> = data.ticketHolderInputSets.map((form: FormInputsPayloadModel, index: number) => {
              return form.inputSet.list.reduce(
                (acc, input) => {
                  acc[input.key] = !!input.value ? input.value : "";
                  acc['index'] = form.index;
                  acc['isValid'] = data.ticketHoldersFormsValidity[index];
                  return acc;
                },
                {} as Record<string, any> & { index: number }
              );
            });

            this.ticketHolderArray = [...ticketHolderArray];

            this.isDuplicatesCheckDone$.next(false);
            this.isTicketsOverLimitCheckDone$.next(false);

            //perform duplicates check if needed:
            if (this.needsDuplicatesCheck &&
              (!!forceValidation || this.ticketHoldersNeedCheck(data, this.inputNamesForDuplicatesCheck))) {
              this.checkForDuplicates(ticketHolderArray);
            } else {
              this.isDuplicatesCheckDone$.next(true);
            }

            //perform ticket over limit check if needed:
            if (this.needsTicketsOverLimitCheck &&
              (!!forceValidation || (!this.isTicketHolderEmailMandatory && this.hasBuyerInfoChanged && this.isBuyerInfoValid) || this.ticketHoldersNeedCheck(data, this.inputNamesForTicketsOverlimitChecks))) {
              this.checkForTicketsOverLimit(ticketHolderArray);
            } else {
              this.isTicketsOverLimitCheckDone$.next(true);
            }

            this.hasBuyerInfoChanged = false;
            this.prevDataToCheck = cloneDeep(data);
          }
        });
      }
    );
  }

  // NOTE: Displaying correct duplicity warning is dependable on identical order of combinedHoldersData and ticketHolderArray if it gets sorted at some point, this feature will probably not work
  checkForDuplicates(ticketHolderArray) {
    this.isDuplicatesCheckDone$.next(false);
    let duplicatesList: Array<number> = [];
    let tmpDuplicatesList: Array<any> = [];

    this.inputNamesForDuplicateSearch.forEach(inputNames => {
      const duplicateTranslationKey = this.duplicateKeyName(inputNames);

      // join input values of desired combination to a single string for a comparsion
      const combinedHoldersData: string[] = inputNames.reduce(
        (acc, curr) => {
          const inputsValues: string[] = this.getTicketHolderInputsValue(
            ticketHolderArray,
            curr
          );

          acc = inputsValues.map((value, i) => {
            let finalValue;

            // null if input is not filled
            if (!value || (value && acc[i] === null)) {
              finalValue = null;
            } else if (acc[i]) {
              finalValue = acc[i].toString() + '_' + value.toString();
            } else {
              finalValue = value.toString();
            }

            return !!finalValue ? finalValue.toLocaleLowerCase() : null;
          });

          return acc;
        },
        [] // e.g. [martinlabik, martinlabik]
      );

      combinedHoldersData.forEach((inputVal: any, index) => {
        if (!inputVal) {
          return;
        }

        // Find if there is a duplicate or not
        if (combinedHoldersData.indexOf(inputVal, index + 1) > -1) {
          const duplicateIndexes = combinedHoldersData.reduce((acc, curr, i) => {
            const valuesEqual = this.areStringsEqual(curr, inputVal);

            if (valuesEqual) {
              acc.push(i);
              duplicatesList.push(i);
            }

            return acc;
          }, []);

          const duplicate = {
            [duplicateTranslationKey]: {
              duplicateIndexes: duplicateIndexes
            }
          };

          if (duplicateIndexes.length > 0) {
            this.showDuplicatesWarning = true;
          }

          //add duplicate information to duplicatesList:
          duplicate[duplicateTranslationKey].duplicateIndexes.forEach(duplicateIndex => {
            if (!!tmpDuplicatesList && !!tmpDuplicatesList[duplicateIndex]) {
              tmpDuplicatesList[duplicateIndex] = { ...tmpDuplicatesList[duplicateIndex], ...duplicate };
            } else {
              tmpDuplicatesList[duplicateIndex] = duplicate;
            }

            if (this.uniqueVisitorCheckType === 'mustBeUnique') {
              this.setTicketHolderValidity(duplicateIndex, false);
            }
          });
        }
      });
    });

    this.duplicatesList = [...tmpDuplicatesList];
    this.isDuplicatesCheckDone$.next(true);
  }

  checkForTicketsOverLimit(ticketHolderArray) {
    this.isTicketsOverLimitCheckDone$.next(false);
    const ticketHolderArrayHelper = [...ticketHolderArray];

    let buyerEmail: string;

    //if e-mail isn't mandatory on ticket holders, get the one from the buyer info:
    if (!this.isTicketHolderEmailMandatory && !!this._buyerInputs && !!this._buyerInputs.find(input => input.key === 'email')) {
      buyerEmail = this._buyerInputs.find(input => input.key === 'email').value;
    }

    let ticketsOverLimitChecks = [];
    ticketHolderArrayHelper.forEach((ticketHolder, index) => {
      ticketsOverLimitChecks.push({ index: index, isChecked: false, isInvalid: false });
    });

    if (this.hasTicketHolderEmailInput || !!buyerEmail) {
      let emailDataList: EmailData[] = [];

      ticketHolderArrayHelper.filter(ticketHolder => ticketHolder.isValid).forEach(ticketHolder => {
        const ticket = this.ticketNames.find(ticket => ticket.ticketIndex === ticketHolder.index);
        const index = ticketHolderArrayHelper.findIndex(
          ticketHolderX => ticketHolderX.index === Number(ticketHolder.index)
        );

        if (this.hasTicketHolderEmailInput) {
          //e-mail is present on ticket holder form:
          if (!!ticketHolder.email) {
            //we have ticket holder e-mail:
            emailDataList.push({ index: index, email: ticketHolder.email, ticketPersonId: ticket.ticketPersonId, amount: 1 });
          } else {
            //we don't have ticket holder e-mail
            if (this.isTicketHolderEmailMandatory) {
              //ticket holder e-mail field is mandatory so we have to invalidate this ticket holder:
              this.setTicketHolderValidity(index, false);
            } else if (!!buyerEmail) {
              //ticket holder e-mail field isn't mandatory so we'll take e-mail from the buyer:
              emailDataList.push({ index: index, email: buyerEmail, ticketPersonId: ticket.ticketPersonId, amount: 1 });
            }
          }
        } else if (!!buyerEmail) {
          emailDataList.push({ index: index, email: buyerEmail, ticketPersonId: ticket.ticketPersonId, amount: 1 });
        }
      });

      if (emailDataList.length) {
        const request: ValidateDailyTicketPerEmailLimitBody = {
          eventId: this.selectedExhibitionId,
          isSelfRegistration: this.isSelfRegistrationEnabled,
          items: emailDataList
        };
        consoleLog(`CheckDailyTicketPerEmailLimit request: ${JSON.stringify(request)}`);

        this._ticketService.checkDailyTicketPerEmailLimit(request).subscribe(
          (response: ValidateDailyTicketPerEmailLimitResult) => {
            consoleLog(`CheckDailyTicketPerEmailLimit response: ${JSON.stringify(response)}`);

            if (!response.isValid) {
              //some ticket holders are invalid, over ticket limit:
              this.showTicketLimitWarning = true;

              emailDataList.forEach(ticketHolder => {
                if (!!ticketHolder.email && !!response.errors.find(error => error.values[0] === ticketHolder.email)) {
                  this.setTicketHolderValidity(ticketHolder.index, false);

                  ticketsOverLimitChecks[ticketHolder.index] = {
                    ...ticketsOverLimitChecks[ticketHolder.index],
                    isChecked: true,
                    isInvalid: true
                  };
                } else {
                  ticketsOverLimitChecks[ticketHolder.index] = {
                    ...ticketsOverLimitChecks[ticketHolder.index],
                    isChecked: true,
                    isInvalid: false
                  };
                }
              });
            } else {
              //all ticket holders are valid, none over ticket limit:
              emailDataList.forEach(ticketHolder => {
                ticketsOverLimitChecks[ticketHolder.index] = {
                  ...ticketsOverLimitChecks[ticketHolder.index],
                  isChecked: true,
                  isInvalid: false
                };
              });
            }

            this.ticketsOverLimitList = [...ticketsOverLimitChecks];
            this.isTicketsOverLimitCheckDone$.next(true);
          },
          (err) => {
            consoleLog(`CheckDailyTicketPerEmailLimit error: ${JSON.stringify(err)}`);
            this.ticketsOverLimitList = [...ticketsOverLimitChecks];
            this.isTicketsOverLimitCheckDone$.next(true);
          }
        );

        return;
      }
    }

    this.ticketsOverLimitList = [...ticketsOverLimitChecks];
    this.isTicketsOverLimitCheckDone$.next(true);
  }

  //helper functions:
  ticketHoldersNeedCheck(currentValidationModel: PersonalizationValidationModel, inputNamesForChecks: string[]) {
    if (!this.prevDataToCheck) {
      return true;
    }

    let areDifferent: boolean = false;
    const ticketHolderInputSetsPrev = [...this.prevDataToCheck.ticketHolderInputSets];
    const ticketHolderInputSetsCurr = [...currentValidationModel.ticketHolderInputSets];
    const ticketHoldersValidityPrev = [...this.prevDataToCheck.ticketHoldersFormsValidity];
    const ticketHoldersValidityCurr = [...currentValidationModel.ticketHoldersFormsValidity];

    for (let indexA = 0; indexA < ticketHoldersValidityCurr.length; indexA++) {
      if (ticketHoldersValidityCurr[indexA] && !ticketHoldersValidityPrev[indexA]) {
        //ticket holder form was invalid, now it's valid, validations are needed:
        return true;
      }
    }

    for (let indexA = 0; indexA < ticketHolderInputSetsCurr.length; indexA++) {
      const ticketHolderInputSet = ticketHolderInputSetsCurr[indexA];

      for (let indexB = 0; indexB < inputNamesForChecks.length; indexB++) {
        const inputName = inputNamesForChecks[indexB];
        const currentInput = ticketHolderInputSet.inputSet.list.find(inputs => inputs.key === inputName);
        const previousInput = ticketHolderInputSetsPrev[indexA].inputSet.list.find(inputs => inputs.key === inputName);

        if (currentInput && previousInput && !this.areStringsEqual(currentInput.value, previousInput.value, true)) {
          //relevant ticket holder inputs are changed, validations are needed:
          areDifferent = true;
          break;
        }
      }

      if (areDifferent) {
        return true;
      }
    }

    return false;
  }

  duplicateKeyName(inputsCombination: string[]) {
    return inputsCombination && inputsCombination.join('-');
  }

  getTicketHolderInputsValue(ticketHolderArray, inputName) {
    let ticketHolderInputsArray = [];
    ticketHolderArray.map(array => {
      ticketHolderInputsArray.push(array[inputName]);
    });

    return ticketHolderInputsArray;
  }

  areStringsEqual(string1: string, string2: string, ignoreCase: boolean = false): boolean {
    //convert undefined and null strings into empty strings:
    string1 = !string1 ? '' : string1;
    string2 = !string2 ? '' : string2;

    if (ignoreCase) {
      //if necessary convert strings to lower case:
      string1 = string1.toLocaleLowerCase();
      string2 = string2.toLocaleLowerCase();
    }

    //compare the strings (localeCompare returns 0 if strings are equal):
    return String(string1).localeCompare(string2) === 0;
  }

  areTicketHolderChecksOk(index: number, form: FormGroup = null): boolean {
    const isTicketHolderFormValid: boolean = !!form ? !form.invalid : this.ticketHoldersFormsValidity[index];
    const isTicketHolderDuplicate = this.duplicatesList[index];
    const hasTicketsOverLimit = this.ticketsOverLimitList[index];

    return isTicketHolderFormValid &&
      (!isTicketHolderDuplicate || this.uniqueVisitorCheckType !== 'mustBeUnique') &&
      (!hasTicketsOverLimit || (hasTicketsOverLimit.isChecked && !hasTicketsOverLimit.isInvalid));
  }

  setTicketHolderValidity(index: number, isValid: boolean = false, setFormValidity: boolean = false) {
    const formIndex: number = this.ticketHolderArray[index].index;
    const stepsFormsActionName = ['personal', `ticketHolder_${formIndex}`];

    this.ticketHoldersValidity[index] = isValid;
    this._formsService.setFormValidity(isValid, null, stepsFormsActionName);

    if (setFormValidity && !isValid) {
      this._formsService.setTicketHolderFormValidity(isValid, stepsFormsActionName);
    }
  }

  ticketHolderNeedsRevalidation(index: number, inputNamesForChecks: string[]) {
    if (!this.prevDataToCheck) {
      return true;
    }

    let areDifferent: boolean = false;
    const currentHolder = this.ticketHolderArray[index];

    for (let indexA = 0; indexA < inputNamesForChecks.length; indexA++) {
      const inputName = inputNamesForChecks[indexA];
      const currentInputValue: string = currentHolder[inputName];
      const previousInput = this.prevDataToCheck.ticketHolderInputSets[index].inputSet.list.find(inputs => inputs.key === inputName);

      if (previousInput && !this.areStringsEqual(currentInputValue, previousInput.value, true)) {
        //relevant ticket holder inputs are changed so revalidation is needed:
        areDifferent = true;
        break;
      }
    }

    return areDifferent;
  }
  //#endregion

  copyAddress(copyEverythingIsChecked, buyerInfoChanged?: boolean) {
    const debounceTime = copyEverythingIsChecked ? 500 : 0;

    // US2870 - if 'copy all data' with sending option being 'ticketRetrivalLink' than we immediately know that buyer is also a visitor
    if (copyEverythingIsChecked && this.isVisitorQuestionnaireEnabled && !this.isBuyerVisitorChecked) {
      this.buyerVisitorPopup();
    } else if (!copyEverythingIsChecked && this.isBuyerVisitorChecked) {
      this._store.dispatch(
        new stepsActions.SetBuyerVisitorCheckbox({
          buyerVisitorCheckedSlideIndex: null,
          isBuyerVisitorChecked: false,
          showVisitorQuestionnaire: false
        })
      );

      this._formsService.removeAllStepValidationFeedbacks(this.visitorQuestionnaireValidation);
      this._formsService.setFormValidity(true, null, this.visitorQuestionnaireValidation);
    }

    // get actual state of ticketHolder forms
    this._store
      .pipe(select(fromRoot.getTicketHolderInputSets))
      .debounceTime(debounceTime)
      .pipe(
        filter(data => !!data),
        first()
      )

      // only build the forms when number of tickets change. Form rerender logic is handled in every form itself
      .subscribe((ticketHolderInputSets: FormInputsPayloadModel[]) => {
        this.ticketHolderInputSets = ticketHolderInputSets;

        const addressMap = {
          address: 'address',
          street: 'street',
          country: 'country',
          zipCode: 'zipCode',
          company: 'company',
          city: 'city'
        };

        const userMap = {
          gender: 'gender',
          title: TextOrDropdownInputTypes.Title,
          firstName: 'firstName',
          lastName: 'lastName',
          function: TextOrDropdownInputTypes.Function,
          telephone: 'telephone',
          email: 'email',
          verifyEmail: 'verifyEmail',
          department: TextOrDropdownInputTypes.Department,
          occupationalGroup: TextOrDropdownInputTypes.OccupationalGroup,
          dateOfBirth: 'dateOfBirth'
        };

        const mergedMaps = { ...addressMap, ...userMap };


        let checkedSlideIndex: number = this.checkedSlideIndex;

        this.checkedSlideIndex = copyEverythingIsChecked ? this.activeSlideIndex : null;

        const holderFormId = buyerInfoChanged ? checkedSlideIndex : this.activeSlideIndex;

        const isAddressCopied = !!copyEverythingIsChecked;

        this._store.dispatch(
          new stepsActions.SetAddressCheckbox({
            checkedSlideIndex: this.checkedSlideIndex,
            isAddressCopied: isAddressCopied
          })
        );

        this.canDeleteTicketInfo = isAddressCopied;

        this._store
          .pipe(
            select(fromRoot.getTicketSelectedSendingOptions),
            first()
          )
          .subscribe(selectedTicketSendingOptions => {
            // if ticketOption is selected sending option, copy the inputs to all ticket holders
            if (selectedTicketSendingOptions) {
              // if copyEverythingIsChecked is false, first change data for checked slide index, otherwise validation will stay green because of
              // form.component.ts calls function toFormGroup
              if (!copyEverythingIsChecked) {
                const buyerTicketHolder = ticketHolderInputSets[checkedSlideIndex];

                this.copyBuyerInfoToHolder(
                  mergedMaps,
                  copyEverythingIsChecked,
                  buyerTicketHolder,
                  checkedSlideIndex,
                  checkedSlideIndex,
                  buyerInfoChanged
                );
              }

              ticketHolderInputSets.forEach((el, index) => {
                let map = index === holderFormId ? mergedMaps : addressMap;
                this.copyBuyerInfoToHolder(
                  map,
                  copyEverythingIsChecked,
                  ticketHolderInputSets[index],
                  holderFormId,
                  index,
                  buyerInfoChanged
                );
              });
              // if buyer info changes delete indexes of tickets on which data was copied from buyer info
              if (!copyEverythingIsChecked || buyerInfoChanged) {
                this._store.dispatch(new stepsActions.SetCoppiedHoldersIndexes([]));
                this.shouldDisplayCheckbox = true;
              }
            }
          });
      });
  }

  copyBuyerInfoToHolder(
    map: { [key: string]: string },
    copyEverythingIsChecked: boolean,
    filledHolderForm: FormInputsPayloadModel,
    holderFormId: number,
    index: number,
    buyerInfoChanged?: boolean
  ) {
    let pushedToCoppiedHolderIndexes: boolean = false;
    let coppiedData: boolean = this.coppiedHolderDataIndexes.some(item => item === index);
    let formHasMapValues: boolean = false;

    // skip check if index is same as holder form
    if (index !== holderFormId) {
      // check if any of map values is filled in holder form, if not copy values from buyer
      Object.keys(map).forEach(mapKey => {
        // only check if form does not have its own value
        if (!formHasMapValues) {
          const buyerInputValue = this._buyerInputs.find(buyerInput => {
            if (buyerInput.key === mapKey) {
              return buyerInput.value
            }
          });
          const filledHolderFormVal = filledHolderForm.inputSet.list.find(holder => {
            if (holder.key === mapKey) {
              return holder.value;
            }
          });

          formHasMapValues = buyerInputValue !== filledHolderFormVal && !!filledHolderFormVal;
        }
      });
    }

    this._buyerInputs.forEach(input => {
      if (map.hasOwnProperty(input.key)) {
        const rightHolderInput = filledHolderForm.inputSet.list.find(
          holderInput => {
            return holderInput.key === input.key;
          }
        );

        if (index === holderFormId) {
          if (!!rightHolderInput) {
            rightHolderInput.value = copyEverythingIsChecked ? input.value : '';

            if (rightHolderInput.key === 'email') {
              let verifyEmail = filledHolderForm.inputSet.list.find(item => {
                return item.key === 'verifyEmail';
              });

              if (!!verifyEmail) {
                verifyEmail.value = copyEverythingIsChecked ? input.value : '';
              }
            }
          }
        } else if (buyerInfoChanged) {
          if (coppiedData && !!rightHolderInput) {
            rightHolderInput.value = '';
          }
        } else if (!!rightHolderInput && input.value) {
          if (!copyEverythingIsChecked) {
            if (coppiedData) {
              rightHolderInput.value = '';
            }
          } else {
            if (!!rightHolderInput) {
              if (!formHasMapValues) {
                rightHolderInput.value = input.value;
                if (!this.coppiedHolderDataIndexes.find(item => item === index)) {
                  this.coppiedHolderDataIndexes.push(index);
                  pushedToCoppiedHolderIndexes = true;
                }
              }
            }
          }
        }
      }
    });

    if (pushedToCoppiedHolderIndexes) {
      this._store.dispatch(new stepsActions.SetCoppiedHoldersIndexes([...this.coppiedHolderDataIndexes]));
    }

      filledHolderForm.inputSet.rerender = true;

      // if we're on index which has copy buyer data checked update inputs
      if (index === holderFormId) {
        this.updatedTicketHolderInput({ inputs: filledHolderForm.inputSet.list, currentIndex: index }, true);
      }

    // if copy buyer data is unchecked or buyer info has changed(with buyer info copy checkbox checked) set visitor forms to not valid
    if (!copyEverythingIsChecked || buyerInfoChanged) {
      if (coppiedData || index === holderFormId) {
        this.setTicketHolderValidity(index, false, true);
        this.triggerValidation$.next(true);
      }
    }

      this._store.dispatch(new stepsActions.SetInputs(filledHolderForm));
  }

  goToSlide(index) {
    this.activeSlideIndex = index;
    this._store.dispatch(
      new ticketActions.SetActiveHolderSlideIndex(this.activeSlideIndex)
    );
  }

  previousPage() {
    if (this.activeSlideIndex > 0) {
      this.activeSlideIndex--;
    } else {
      this.activeSlideIndex = this.ticketHolderInputSets.length - 1;
    }

    this._store.dispatch(
      new ticketActions.SetActiveHolderSlideIndex(this.activeSlideIndex)
    );
  }

  nextPage() {
    if (this.activeSlideIndex < this.ticketHolderInputSets.length - 1) {
      this.activeSlideIndex++;
    } else {
      this.activeSlideIndex = 0;
    }

    this._store.dispatch(
      new ticketActions.SetActiveHolderSlideIndex(this.activeSlideIndex)
    );
  }

  focusFirstEmptyRequiredInput() {
    this.isNextandPreviousButtonDisabled = true;
    setTimeout(() => {
      this._store
        .select(fromRoot.getTicketHolderInputSets)
        .pipe(
          filter(data => !!data),
          first()
        )
        .subscribe(ticketHolderInputSets => {
          const ticketHolderInputSet =
            ticketHolderInputSets[this.activeSlideIndex];

          const firstRequiredEmptyInput = ticketHolderInputSet.inputSet.list.find(
            input => {
              return input.required && !input.value;
            }
          );

          if (!!firstRequiredEmptyInput) {
            const targetFirstEmptyRequiredInputId =
              ticketHolderInputSet.formInfo[0] +
              '.' +
              ticketHolderInputSet.formInfo[1] +
              '_' +
              firstRequiredEmptyInput.key;

            const targetFirstRequiredInput = document.getElementById(
              targetFirstEmptyRequiredInputId
            ) as HTMLInputElement;

            //TODO: const options: FocusOptions = { preventScroll: true };
            targetFirstRequiredInput.focus();
          }

          this.isNextandPreviousButtonDisabled = false;
        });
    }, 1100); // 1.1s is transition of the carousel
  }

  closeModalWindow(event, type?: string) {
    event.stopPropagation();

    switch (type) {
      case 'duplicates':
        this.showDuplicatesWarning = false;
        this.showDuplicatedWarningAlreadyClosed = true;
        break;

      case 'ticket-overlimit':
        this.showTicketLimitWarning = false;

        //if we only have one ticket holder always show tickets over limit modal window:
        if (this.hasMultipleTicketHolders) {
          this.showTicketLimitWarningAlreadyClosed = true;
        }
        break;

      default:
        this.showDuplicatesWarning = false;
        this.showDuplicatedWarningAlreadyClosed = true;
        break;
    }
  }

  /**
   *
   * @param ticketHolderInputSets array of holders
   * @param checkedSlideIndex index of holder where copyDataFromBuyer checkbox is checked
   * @returns number of inputs of checked holder if there are any, else it returns 0
   */
  ticketHolderInputSetsOfSlideIndexLength(ticketHolderInputSets: FormInputsPayloadModel[], checkedSlideIndex: number): number {
    if (!!ticketHolderInputSets && checkedSlideIndex != null && checkedSlideIndex >= 0) {
      if (!!ticketHolderInputSets[checkedSlideIndex]) {
        const checkedHolder = ticketHolderInputSets[checkedSlideIndex];

        if (!!checkedHolder.inputSet && !!checkedHolder.inputSet.list) {
          return checkedHolder.inputSet.list.length;
        }
      }
    }

    return 0;
  }
}
