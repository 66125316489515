import { DateTime } from 'luxon';

import * as additionalServicesActions from '../../shared/services-with-reducers/additional-services/additional-services.actions';
import * as fromRoot from '../../app.reducer';
import * as stepsActions from '../../shared/services-with-reducers/step-forms/steps-forms.actions';
import * as stepsFormsActions from '../../shared/services-with-reducers/step-forms/steps-forms.actions';
import * as ticketActions from '../../shared/services-with-reducers/tickets/ticket.actions';
import * as legitimationActions from '../../shared/services-with-reducers/legitimation/legitimation.actions';

import { getLocalStorageObject, getLocalStorageString, highlightDay, setLocalStorageObject } from '../../../app/shared/app-utils';

import {
  AddTicketBookingModel,
  RedeemVoucherModel,
  TicketBookingModel,
  TicketByIdModel,
  TicketModel,
  SectionGroupModel,
  TicketGroupTicketModel,
  WorkshopByDayModel,
  ContingentBookingModel,
  ContingentTicketModel,
  ContingentTicketValidityResponse,
  PackageModel
} from '../../shared/services-with-reducers/tickets/ticket.interface';
import { Component, OnDestroy, OnInit, AfterViewInit, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';

import {
  Observable,
  Subscription,
  combineLatest as observableCombineLatest,
  Subject,
  BehaviorSubject,
} from 'rxjs';
import { WorkshopModel } from '../../shared/services-with-reducers/additional-services/additional-services.interface';
import { filter, first, skip, delay } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AppConstants } from '../../shared/app-constants';
import { ExhibitionSettingModel } from '../../shared/services-with-reducers/customization/customization.interfaces';
import { FormsService } from '../../shared/forms/forms.service';
import { HelperService } from '../../shared/services-with-reducers/helpers/helper.service';
import { select, Store } from '@ngrx/store';
import { ProductGroupModel } from '../../shared/services-with-reducers/tickets/ticket.interface';
import { TicketsService } from '../../shared/services-with-reducers/tickets/tickets.service';
import { TranslateService } from '@ngx-translate/core';
import { getQueryParamsFromLocation } from '../../../app/shared/app-utils';
import { Router, ActivatedRoute } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { ActionTypes } from '../../shared/services-with-reducers/tickets/ticket.actions';
import {
  FormInputsPayloadModel,
  TicketHolderAdditionalDataModel,
  ValidityModel,
  VisibilityPayloadModel
} from '../../shared/services-with-reducers/step-forms/step.interface';
import { StepsFormsService } from '../../shared/services-with-reducers/step-forms/steps-forms.service';
import { CustomizationService } from '../../shared/services-with-reducers/customization/customization.service';
import { GtmService } from '../../shared/gtm/gtmService';
import { PackagesService } from './../../shared/services-with-reducers/tickets/packages.service';

@Component({
  moduleId: module.id,
  selector: 'app-web-shop-tickets',
  templateUrl: './web-shop-tickets.component.html',
  styleUrls: ['./web-shop-tickets.component.scss']
})
export class WebShopTicketsComponent
implements OnInit, OnDestroy, AfterViewInit, AfterContentChecked  {
  public ticketSelectionIsValid: boolean;
  public registrationFormsActionName = ['tickets', 'registration'];
  public clearVoucherInput$: Subject<boolean> = new Subject<boolean>();
  public sholdShowPromoCode$: Observable<boolean>;
  public showLoginOnTicketPage$: Observable<boolean>;
  public showLoginOnTicketAndPersonalPage$: Observable<boolean>;
  public notLoggedAndLoginMandatory$: Observable<boolean>;
  public isNewPackageAdded$: Observable<any>;
  public activeLang = 'en';
  public verifiedAccountRequired: Boolean = false;
  public isAccountVerified: Boolean = false;
  // public activeLang$: Observable<string>;
  public dateToday$: Observable<Date>;
  public isMobile: boolean = false;
  public exhibitionSettings: ExhibitionSettingModel;
  public exhibition: any = {};
  public ticketCounter: number;
  public ungroupedTickets: TicketModel;
  public totalPrice: number = 0;
  public totalParkingPrice = new Subject();
  public totalParkingPriceAmount = 0;
  public totalPriceWithOutParking = 0;
  public numberOfSelectedTickets = 0;
  public numberOfSelectedLimitedVouchers = 0;
  public numberOfSelectedVouchers = 0;
  public numberOfSelectedOneTimeVouchers = 0;
  public dateToday: Date;
  public maxTicketLimit = 0;
  public maxVoucherLimit = 0;
  public maxLimitedVoucherLimit = 0;
  public isSelfRegistrationEnabled: boolean;
  public AppConstants = AppConstants;
  public hasVoucher: boolean;
  public hasAnonymous: boolean = false;
  public isTicketSoldOut$: Observable<{ [key: string]: AddTicketBookingModel }>;
  public origin: string;
  public environment = environment;
  public toggledWorkshop: WorkshopModel;
  public workshops: WorkshopModel[];
  public ticketsWithHolders = new BehaviorSubject([]);
  public showAmountOfAvailableWorkshops: boolean = true;
  public hideWorkshopDate: boolean = true;
  public enableWorkshopSelectionOverlapping: boolean = false;
  public workshopModalWindowYOffset: number = 0;
  public totalWorkshopPrice: number = 0;
  public highlightDay = highlightDay;
  public limitWorkshopPerTicket: number = 1;
  public isWorkshopsSelectionMandatory: boolean;
  public isWorkshopsSelectionMandatoryForZeroPriceTickets: boolean;
  public workshopsOnTicketSelection: boolean;
  public hideDateAndTime: boolean = false;
  public ticketWithNoWorkshopsTaken: string[] = [];
  public checkedSlideIndex: number;
  public invalidParkingTickets: string[] = [];
  public invalidContingentTickets: string[] = [];
  public currentTicketAfterCountChanged: Object;
  public visitorQuestionnaireValidation = ['personal', 'visitorQuestionnaire'];
  public packageCounterWarningString: string;

  public availableTicketsCombined$: Observable<{
    [key: string]: AddTicketBookingModel;
  }>;

  public totalAvailableTickets$: Observable<{
    [key: string]: AddTicketBookingModel;
  }>;

  public percentageOfAvailableTickets$: Observable<{ [key: string]: number }>;
  public isTicketBookingLoading: boolean;
  public isPackageLoading: boolean;
  public workshopsList: { [key: string]: number };

  // used for ticktes structure (group with tickets)
  public productGroups: ProductGroupModel[] = null;
  public sections: SectionGroupModel[] = [];

  public contingentTicketsForm = this.fb.group({});

  public isParkingTicketLoading: boolean = false;
  public parkingTicketsForm = this.fb.group({});

  private subscriptions: Subscription = new Subscription();
  private contingentSubscriptions: Subscription = new Subscription();

  private endDays = {};

  private hasContingentForm: boolean = false;
  private hasParkingForm: boolean = false;

  private isVoucherAddedOrRemoved: boolean = false;

  public hiddenTicketsAfterAnonymous: string[] = [];

  //step validation fields (ticket selection step is valid only if all these fields are valid):
  private isTicketCounterValid: boolean = false;
  private isParkingTicketsValid: boolean = false;
  private isContingentsValid: boolean = false;
  private isWorkshopsMandatoryValid: boolean = false;
  private isWorkshopsZeroPriceMandatoryValid: boolean = false;
  public stepsValidity: ValidityModel;
  public hiddenSteps: string[] = [];

  private needsTicketOverLimitCheck: boolean = true;
  private ticketIdFromQueryParams: string;
  public amount: number;
  private queryParams;
  private queryParamsTicketPerson: string;
  private queryParamsTicketGroup: string;

  public translationsLoaded: boolean = false;
  public packageMessageMobile: string[] = [];
  public showPackageMessage: boolean = false;
  public ticketUniqueIdMessage: string = '';
  private timeoutId: ReturnType<typeof setTimeout>;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _store: Store<fromRoot.State>,
    private _helperService: HelperService,
    private _formsService: FormsService,
    private _ticketsService: TicketsService,
    private _packagesService: PackagesService,
    private _translateService: TranslateService,
    private fb: FormBuilder,
    private _actions$: Actions,
    private _stepsFormsService: StepsFormsService,
    private _customizationService: CustomizationService,
    private _gtmService: GtmService,
    private cdr: ChangeDetectorRef
  ) {
    this.origin = environment.protocol + environment.origin;
    this.origin = this.origin || window.location.origin;
    this.removeValidationFeedbacks();
    this.setValidation(false);
    this.isMobile = this._helperService.isMobile();

    if (!getLocalStorageObject(AppConstants.parkingTicketsReducer)) {
      setLocalStorageObject(AppConstants.parkingTicketsReducer, '');
    }

    if (!getLocalStorageObject(AppConstants.contingentTicketsReducer)) {
      setLocalStorageObject(AppConstants.contingentTicketsReducer, '');
    }

    this.subscriptions.add(
      this._store.pipe(select(fromRoot.getAnonymousHiddenSteps)).subscribe(data => {
        this.hiddenSteps = data;
      })
    );

    this.subscriptions.add(
      this._store.pipe(select(fromRoot.getStepsValidity)).subscribe(data => {
        this.stepsValidity = data;
      })
    );

    // when value changes store parking data to the storage
    this.subscriptions.add(
      this.parkingTicketsForm.valueChanges.subscribe(value => {
        const parsedParkingTickets = this.getParkingTicketsFromLocalStorage();
        this.invalidParkingTickets = [];

        Object.keys(this.parkingTicketsForm.controls).forEach(key => {
          const splitedUniqueId = key.split('_');
          const uniqueId = `${splitedUniqueId[0]}_${splitedUniqueId[1]}`;

          this.invalidParkingTickets.push(uniqueId);

          // update storage with latest data related to parking ticket
          // TODO: REFACTOR!!! WE SHOULD NOT UPDATE FORM value BUT INSTEAD DIRECTLY parsedParkingTickets!!!
          if (parsedParkingTickets[key]) {
            value[key]['price'] = parsedParkingTickets[key]['price'];
            value[key]['name'] = `TicketPerson.${splitedUniqueId[1]}`;
            value[key]['groupName'] = `TicketGroup.${splitedUniqueId[0]}`;
            parsedParkingTickets[key] = value[key];
          }
        });

        setLocalStorageObject(AppConstants.parkingTicketsReducer, value);
        this.setValidation(false, ['parking', '']);
      })
    );

    this.showLoginOnTicketPage$ = this._store.select(
      fromRoot.showLoginOnTicketPage
    );

    this.showLoginOnTicketAndPersonalPage$ = this._store.select(
      fromRoot.showLoginOnTicketAndPersonalPage
    );

    this.notLoggedAndLoginMandatory$ = this._store.select(
      fromRoot.notLoggedAndLoginMandatory
    );

    this.dateToday$ = this._store.select(fromRoot.getLocalTime);

    this.subscriptions.add(
      this._actions$
        .pipe(
          ofType(ActionTypes.ADD_VOUCHER_TICKET, ActionTypes.REMOVE_VOUCHER)
        )
        .subscribe(data => {
          this.isVoucherAddedOrRemoved = true;
          this.loadSections();
        })
    );

    if (!this.ticketIdFromQueryParams) {
      //"tt" is "TicketGroup" ("768")
      //"pt" is "TicketPerson" ("121")
      this.queryParams = getQueryParamsFromLocation();
      this.queryParamsTicketGroup = this.queryParams["tt"];
      this.queryParamsTicketPerson = this.queryParams["pt"];

      if (this.queryParamsTicketGroup) {

        if (this.queryParamsTicketPerson) {
          this.ticketIdFromQueryParams = this.queryParamsTicketGroup + "_" + this.queryParamsTicketPerson;

          if (!this._helperService.isSelfregistration()) {
            this.amount = +this.queryParams["amt"];

            if (!(this.amount > 0)){
              this.amount = 1;
            }
          }
        }

        if (!this._helperService.isSelfregistration()) {
          this._store.pipe(select(fromRoot.getSelectedExhibitionId)).first().subscribe(eventId => {
            this._store.dispatch(
              new ticketActions.GetTicketsTypesAction({
                eventId: +eventId,
                preferedTicketPersonNr: this.queryParamsTicketPerson
                  ? Number(this.queryParamsTicketPerson)
                  : null,
                preferedTicketGroupNr: this.queryParamsTicketGroup
                  ? Number(this.queryParamsTicketGroup)
                  : null
              })
            );
          })
        }
      }
    }

    this.subscriptions.add(
      this._store.pipe(select(fromRoot.getTicketsTypes)).subscribe(() => {
        if (this._helperService.getReloadSections()) {
          this._ticketsService.removeHoldersAndBookings();
          this.loadSections();
          this._helperService.setIsReloadSections(false);
          const hasUngroupedTicket = !!this.ungroupedTickets && !!this.ticketIdFromQueryParams && !!this.ungroupedTickets[this.ticketIdFromQueryParams];
          const ticketIsVisible = hasUngroupedTicket && this.ungroupedTickets[this.ticketIdFromQueryParams].isVisible;

          if (this._helperService.isSelfregistration() && ticketIsVisible) {
            this.selectTicket(this.ungroupedTickets[this.ticketIdFromQueryParams]);
          }
        }
      })
    );

    if (!this.queryParamsTicketGroup) {
      this.loadSections();
    }

    this.subscriptions.add(
      this.dateToday$.subscribe(date => {
        if (!date) return;

        const isoDate = this.addHour(this.addHour(date)).toISOString();
        this.dateToday = new Date(
          isoDate.substring(0, isoDate.lastIndexOf(':'))
        );
      })
    );

    this._store.select(fromRoot.getLanguage).subscribe(lang => {
      this.activeLang = lang;
    });

    this.subscriptions.add(
      this.notLoggedAndLoginMandatory$.subscribe(notLogged => {
        this._formsService.setFormValidity(!notLogged, null, [
          'tickets',
          'login'
        ]);
      })
    );

    this.subscriptions.add(
      this._store.select(fromRoot.getExhibitionSettings).subscribe(settings => {
        if (settings) {
          this.exhibitionSettings = settings;
          this.isWorkshopsSelectionMandatory = settings.isWorkshopsSelectionMandatory;
          this.isWorkshopsSelectionMandatoryForZeroPriceTickets = settings.workshopMandatoryForZeroPriceTickets;
          this.workshopsOnTicketSelection = settings.workshopsOnTicketSelection;
          this.limitWorkshopPerTicket = settings.workshopsPerTicket;
          this.maxTicketLimit = settings.limitBoughtTickets;
          this.maxVoucherLimit = settings.limitPromoCodes;
          this.maxLimitedVoucherLimit = settings.limitLimitedPromocodes;
          this.enableWorkshopSelectionOverlapping =
            settings.enableWorkshopSelectionOverlapping;
          this.showAmountOfAvailableWorkshops =
            settings.showAmountOfAvailableWorkshops;
          this.hideWorkshopDate = settings.hideWorkshopDate;
          this.needsTicketOverLimitCheck = settings.ticketLimitPerEmail > 0;
        }
      })
    );

    this.subscriptions.add(
      this._store
        .select(fromRoot.getWorkshops)
        .filter(data => !!data)
        .subscribe(data => {
          this.workshops = data;
        })
    );

    /**
     * handle visitors and their subscription to workshops
     * */

    this.subscriptions.add(
      observableCombineLatest([
        this._store.pipe(select(fromRoot.getTickets)),
        this._store.pipe(select(fromRoot.getTicketsTypes)),
        this._store.pipe(select(fromRoot.getTicketHolderInputSets)),
        this._store.pipe(select(fromRoot.getTicketHolderAdditionalData)),
        this._store.pipe(select(fromRoot.getTicketsCount))
      ])
        .pipe(
          filter(
            ([
              ungroupedTickets, 
              ticketTypes, 
              ticketHolderInputSets, 
              ticketHolderAdditionalData, 
              ticketCount
            ]) => {
              const ticketHolderInputSetsCount = ticketHolderInputSets.length;
              const ticketHolderAdditionalDataCount = Object.keys(ticketHolderAdditionalData).length;
              const isWorkshopDataValid = ticketHolderInputSetsCount == ticketHolderAdditionalDataCount 
                && ticketCount == ticketHolderAdditionalDataCount;

              return (
                !!ungroupedTickets &&
                !!ticketTypes &&
                !!ticketHolderInputSets &&
                !!ticketHolderAdditionalData &&
                !!isWorkshopDataValid &&
                !!ticketCount
              );
            }
          )
        )
        .subscribe(data => {
          const [
            ungroupedTickets,
            ticketTypes,
            ticketHolderInputSets,
            ticketHolderAdditionalData,
            ticketCount
          ] = data;
          const ticketsWithHolders = this._stepsFormsService.assignUngroupedTicketsToHolders(
            ungroupedTickets,
            ticketHolderInputSets,
            ticketHolderAdditionalData
          );

          let isPricedTicketWorkshopChosen = true;
          let isZeroPricedTicketWorkshopChosen = true;
          this.ticketWithNoWorkshopsTaken = [];

          if (this.workshopsOnTicketSelection) {
            Object.keys(ticketsWithHolders).forEach(data => {
              let ticketHolder = ticketsWithHolders[data];

              if (ticketHolder.allowedWorkshops.length > 0) {
                let ticketHolderAdditionalData = ticketsWithHolders[data].ticketHolderAdditionalData;

                if (this.isWorkshopsSelectionMandatory) {
                  if (ticketHolderAdditionalData == null || ticketHolderAdditionalData.workshops.length < 1) {
                    isPricedTicketWorkshopChosen = false;
                    this.ticketWithNoWorkshopsTaken.push(ticketHolder.ticketUniqueId);
                  }
                }

                if (this.isWorkshopsSelectionMandatoryForZeroPriceTickets) {
                  if (ticketHolder.ticketPrice == 0) {
                    if (ticketHolderAdditionalData == null || ticketHolderAdditionalData.workshops.length < 1) {
                      isZeroPricedTicketWorkshopChosen = false;
                      this.ticketWithNoWorkshopsTaken.push(ticketHolder.ticketUniqueId);
                    }
                  }
                }
              }
            })
          }

          this.setValidation(isPricedTicketWorkshopChosen, ['workshop', 'workshop.not-selected']);
          this.setValidation(!isPricedTicketWorkshopChosen || isZeroPricedTicketWorkshopChosen, ['workshop-zero', 'workshop.not-zero-selected']);

          this.ticketsWithHolders.next(ticketsWithHolders);
          this.updateTotalWorkshopPrice();
        })
    );

    this.subscriptions.add(
      this._store
      .pipe(
        select(fromRoot.getTickets),
        filter(tickets => {
            return !!tickets;
          })
        )
        //.debounceTime(50) // if this is enabled you can add more tickets then the limit when clicking + button very fast
        .subscribe((ungroupedTickets: TicketModel) => {
          // create a deep copy so we dont modify the original object in store
          // TODO: This is a shallow copy...
          this.ungroupedTickets = { ...ungroupedTickets };

          this.checkForSelectedAnonymousTicketAndAdjustSteps();

          const parsedParkingTickets = this.getParkingTicketsFromLocalStorage();
          const parsedContingentTickets = this.getContingentTicketsFromLocalStorage();

          //SteZ: first we have to remove all contingentTicketsForm controls for which we don't have defined tickets anymore
          //otherwise contingent controls for tickets chosen via redeeming a voucher wouldn't be removed when removing a voucher ticket!
          Object.keys(this.contingentTicketsForm.controls).forEach(control => {
            if (!ungroupedTickets[this.getUniqueTicketID(control)]) {
              this.contingentTicketsForm.removeControl(control);
            }
          });

          for (const uniqueId in this.ungroupedTickets) {
            const ungroupedTicket = this.ungroupedTickets[uniqueId];

            this.createAdditionalParkingFormControls(
              ungroupedTicket,
              parsedParkingTickets
            );

            this.createAdditionalDayFormControls(
              ungroupedTicket,
              parsedContingentTickets
            );
          }

          const queryParamsFromLocation = getQueryParamsFromLocation();
          const ticketUniqueId = queryParamsFromLocation['ticket'];

          if (ticketUniqueId) {
            this.selectTicket(this.ungroupedTickets[ticketUniqueId]);
            this._router.navigate(['.'], {
              relativeTo: this._route,
              queryParams: {}
            });
          }

          this.hasParkingForm = !!Object.keys(
            this.parkingTicketsForm.controls
          ).length;

          if (!this.hasParkingForm) {
            this.invalidParkingTickets = [];
            this.setValidation(true, ['parking', '']);
          }

          this.hasContingentForm = !!Object.keys(
            this.contingentTicketsForm.controls
          ).length;

          if (!this.hasContingentForm) {
            this.invalidContingentTickets = [];
            this.setValidation(true, ['contingent', '']);
            this.setValidation(true, ['contingent', 'steps.contingent.sold-out']);
          } else if (this.isSelfRegistrationEnabled || (this.hasVoucher && this.isVoucherAddedOrRemoved)) {
            //SteZ: we have to add this workaround for self-registration as in that mode "counterChange" function (and also "handleContingentTicketChange") won't be called on adding a new ticket
            //(it also won't be called if we redeem a voucher):
            this.handleContingentTicketChange();
          }

          this.isVoucherAddedOrRemoved = false;
        })
    );

    this.subscriptions.add(
      observableCombineLatest([
        this._store.pipe(select(fromRoot.getTickets)),
        this._store.pipe(select(fromRoot.getTicketHolderIndexes))
      ])
        .pipe(
          filter(([tickets, ticketHolderIndexes]) => {
            const areHolderIndexesEmpty = ticketHolderIndexes == null || !Object.keys(ticketHolderIndexes).length;
            const areAllHolderIndexesAssigned = !areHolderIndexesEmpty ? !Object.keys(ticketHolderIndexes)
              .map(index => ticketHolderIndexes[index])
              .filter(indexTicketUniqueId => indexTicketUniqueId == '').length : false;

            return !!tickets && (areHolderIndexesEmpty || areAllHolderIndexesAssigned);
          })
        )
        .subscribe(([ungroupedTickets]) => {
          this.ungroupedTickets = { ...ungroupedTickets };

          // go thru tickets and sum price of selected ones. Also count number of selected tickets
          let numberOfSelectedTickets = 0;
          let numberOfSelectedVouchers = 0;
          let numberOfSelectedLimitedVouchers = 0;
          let numberOfSelectedOneTimeVouchers = 0;
          this.totalPrice = Object.keys(this.ungroupedTickets).reduce(
            (acc, key) => {
              if (this.ungroupedTickets[key].hasOwnProperty('voucherType')) {
                if (this.ungroupedTickets[key].voucherType === 'limitedPromoCode') {
                  numberOfSelectedLimitedVouchers += this.ungroupedTickets[key].count;
                } else if (this.ungroupedTickets[key].voucherType === 'promoCode') {
                  numberOfSelectedVouchers += this.ungroupedTickets[key].count;
                } else if (this.ungroupedTickets[key].voucherType === 'oneTimeVoucher') {
                  numberOfSelectedOneTimeVouchers += this.ungroupedTickets[key].count;
                }
              } else {
                numberOfSelectedTickets += this.ungroupedTickets[key].count;
              }

              const pricePerTicketType: number = this.getPricePerTicketType(this.ungroupedTickets, key);
              const finalPrice = acc + pricePerTicketType;

              return finalPrice;
            },
            0
          );
          this.totalPriceWithOutParking = this.totalPrice;

          this.ticketCounter = Object.keys(this.ungroupedTickets).reduce(
            (acc, key) => {
              return acc + this.ungroupedTickets[key].count;
            },
            0
          );

          const isValid = this.isFormValid(
            numberOfSelectedTickets +
            numberOfSelectedVouchers +
            numberOfSelectedLimitedVouchers +
            numberOfSelectedOneTimeVouchers
          );

          this.setValidation(isValid);

          if (!this.workshopsOnTicketSelection || (!this.isWorkshopsSelectionMandatory && !this.isWorkshopsSelectionMandatoryForZeroPriceTickets) || !this.ticketWithNoWorkshopsTaken.length || !isValid) {
            this.ticketWithNoWorkshopsTaken = [];
            this.setValidation(true, ['workshop', 'workshop.not-selected']);
            this.setValidation(true, ['workshop-zero', 'workshop.not-zero-selected']);
          }

          this.numberOfSelectedTickets = numberOfSelectedTickets;
          this.numberOfSelectedVouchers = numberOfSelectedVouchers;
          this.numberOfSelectedLimitedVouchers = numberOfSelectedLimitedVouchers;
          this.numberOfSelectedOneTimeVouchers = numberOfSelectedOneTimeVouchers;

          if (this._helperService.isSelfregistration()) {
            this._helperService.loadBuyerQuestionnaireViaApi('questionnaire');
          } else {
            this._helperService.loadBuyerQuestionnaireViaApi('personal');
          }

          this.updateTotalWorkshopPrice();
        })
    );

    this.subscriptions.add(
      this._store.pipe(
        select(fromRoot.getIsPackageLoading)
      )
      .subscribe(isPackageLoading => {
          this.isPackageLoading = isPackageLoading;
      }));
  }

  messageFromPackageCounter(ticketUniqueId: string, packageCount: number) {
    this.ticketUniqueIdMessage = ticketUniqueId;
    this.showPackageMessage = true;
    
    clearTimeout(this.timeoutId);

    this.timeoutId = setTimeout(() => {        
      this.showPackageMessage = false;
    }, 3000);
  }

  updateTotalWorkshopPrice() {
    setTimeout(() => {
      this._store
        .select(fromRoot.getTicketHolderAdditionalData)
        .first()
        .subscribe(data => {
          this.totalWorkshopPrice = 0;
          for (const key of Object.keys(data)) {
            let additionalData: any = data[key];
            if (additionalData) {
              additionalData.workshops.forEach(workshopId => {
                let workshop = this.workshops.find(workshop => {
                  return workshop.workshopId === workshopId;
                });
                this.totalWorkshopPrice += workshop.price;
              });
            }
          }
        });
    }, 0);

    return 0;
  }

  updateTotalParkingPrice(that = null) {
    const storedParkingTickets = getLocalStorageString(AppConstants.parkingTicketsReducer);

    const parkingTicketsDictionary =
      storedParkingTickets && JSON.parse(storedParkingTickets);

    const parkingTicketCount: number = !!that && !!that.parkingTicketsForm ? Object.keys(that.parkingTicketsForm.controls).length : Object.keys(this.parkingTicketsForm.controls).length;

    const tempTotalParkingPriceAmount = Object.keys(
      parkingTicketsDictionary
    ).reduce((accu, curr, index) => {
      if (index >= parkingTicketCount) {
        delete parkingTicketsDictionary[curr];
        setLocalStorageObject(AppConstants.parkingTicketsReducer, parkingTicketsDictionary);

        return accu;
      }

      const ticket = parkingTicketsDictionary[curr];

      if (ticket.price) {
        return accu + parseInt(ticket.price, 10);
      }
      return accu;
    }, 0);
    if (that) {
      that.totalParkingPriceAmount = tempTotalParkingPriceAmount;
      that.totalPrice =
        that.totalPriceWithOutParking + that.totalParkingPriceAmount;
    } else {
      this.totalParkingPriceAmount = tempTotalParkingPriceAmount;
      this.totalPrice =
        this.totalPriceWithOutParking + this.totalParkingPriceAmount;
    }
  }

  ngOnDestroy() {
    this._store
      .select(fromRoot.getTickets)
      .pipe(filter(data => !!data))
      .first()
      .subscribe((ungroupedTickets: TicketModel) => {
        let ticketsWithCount: TicketByIdModel[] = [];

        for (const uniqueId in this.ungroupedTickets) {
          const ticket = this.ungroupedTickets[uniqueId];

          if (ticket.count > 0) {
            ticketsWithCount.push(ticket);
          }
        }

        this._gtmService.pushCheckout(ticketsWithCount);
      });

    this.subscriptions.unsubscribe();

    if (this.contingentSubscriptions) {
      this.contingentSubscriptions.unsubscribe();
      this.contingentSubscriptions = null;
    }
  }

  ngOnInit() {
    this.sholdShowPromoCode$ = this._store.select(fromRoot.sholdShowPromoCode);
    this.isSelfRegistrationEnabled = this._helperService.isSelfregistration();
    this._store.dispatch(new stepsFormsActions.SetSelectedStep('tickets'));

    this.subscriptions.add(
      this._store.pipe(select(fromRoot.getTranslationsLoaded))
      .subscribe(
        (getTranslationsLoaded) => {
          this.translationsLoaded = getTranslationsLoaded;
      })
    );

    this.subscriptions.add(
      this._store.select(fromRoot.getAddressCheckbox).subscribe(item => {
        if (!!item) {
          this.checkedSlideIndex = item.checkedSlideIndex;
        }
      })
    );

    this.subscriptions.add(
      this._store
        .select(fromRoot.getSelectedExhibitionId)
        .subscribe(eventId => {
          this._store.dispatch(
            new additionalServicesActions.GetWorkshops(eventId)
          );
        })
    );

    this.subscriptions.add(
      this._store
        .select(fromRoot.getSelectedExhibition)
        .subscribe(exhibition => (this.exhibition = exhibition))
    );

    observableCombineLatest(
      this._store.select(fromRoot.getSelectedExhibitionId),
      this._store.select(fromRoot.getProfile),
      this._store.select(fromRoot.getSelectedExhibition)
    )
      .pipe(
        filter(data => !!data[0] && !!data[1] && !!data[2]),
        first()
      )
      .subscribe(data => {
        const [eventId, profile, exhibition] = data;
        this._store.dispatch(
          new legitimationActions.GetLegitimationStatus({
            userId: profile.id,
            eventId
          })
        );
      });

    this.subscriptions.add(
      this._store
        .select(fromRoot.getLegitimationStatus)
        .pipe(filter(data => !!data))
        .subscribe(legitimation => {
          const legitimationValid = legitimation.status === 'approved' || this.isSelfRegistrationEnabled;
          this._formsService.setFormValidity(legitimationValid, null, ['legitimation', 'validation']);
        })
    );

    this.subscriptions.add(
      observableCombineLatest(
        this._store.select(fromRoot.isLegitimationRequired),
        this._store.select(fromRoot.getExhibitionSettings),
        this._store.select(fromRoot.getProfile)
      ).subscribe(data => {
        this.verifiedAccountRequired = data[1]
          ? data[1]['loginMode'] ===
          'beforeTicketSelectionWithRequiredEmailConfirmation'
          : false;
        this.isAccountVerified = data[2] ? data[2].isEmailVerified : false;
      })
    );

    this.subscriptions.add(
      observableCombineLatest(
        this._store.select(fromRoot.getWorkshops),
        this._store.select(fromRoot.getTickets),
        this._store.select(fromRoot.getTicketsBooking)
      )
        .filter(data => !!data[0].length && !!data[1])
        .map(data => {
          const workshops: WorkshopModel[] = data[0];
          const ungroupedTickets: TicketModel = data[1];
          const ticketsBookings: TicketBookingModel = data[2];

          const seatsAvailableByUniqueId = Object.keys(ungroupedTickets).reduce(
            (acc, ticketId) => {
              // all available workshop seats for this tickets, before booked workshop seats by other tickets are substracted
              const availableSeats = workshops.reduce((acc, workshop) => {
                if (
                  ungroupedTickets[ticketId].allowedWorkshops.includes(
                    workshop.workshopId
                  )
                ) {
                  acc += workshop.seats;
                }

                return acc;
              }, 0);

              const seatsBookingsByOtherTicket: AddTicketBookingModel[] = workshops.reduce(
                (acc, workshop) => {
                  function hasOtherSeats(booking, workshop) {
                    const otherWorkshopsIds = ungroupedTickets[
                      booking.ticketUniqueId
                    ].allowedWorkshops.filter(
                      workshopId => workshopId !== workshop.workshopId
                    );

                    if (!otherWorkshopsIds.length) {
                      return false;
                    }

                    return true;
                    /*
                  const otherWorkshops = workshops.filter(workshop =>
                    otherWorkshopsIds.includes(workshop.workshopId)
                  );

                  const seatsByOtherWOrkshops = otherWorkshops.reduce(
                    (acc, workshop: WorkshopModel) => {
                      acc += workshop.seats;
                      return acc;
                    },
                    0
                  );

                  const bookingsByOtherWorkshops = 0;

                  return !!(seatsByOtherWOrkshops - bookingsByOtherWorkshops); */
                    /* const filteredWorkshops = workshops.find(workshop => allowdWorkshops.icludes(workshop.workshopId))
                console.log(filteredWorkshops) */
                  }

                  const otherBookings = ticketsBookings.bookings.filter(
                    booking => {
                      return (
                        booking.count &&
                        booking.ticketUniqueId !== ticketId &&
                        ungroupedTickets[booking.ticketUniqueId] &&
                        ungroupedTickets[
                          booking.ticketUniqueId
                        ].allowedWorkshops.includes(workshop.workshopId) &&
                        !hasOtherSeats(booking, workshop)
                      );
                    }
                  );

                  acc = [...acc, ...otherBookings];

                  return acc;
                },
                []
              );

              const uniqueBookings = seatsBookingsByOtherTicket.filter(
                (value, index, self) => self.indexOf(value) === index
              );

              const seatsCountBookedByOtherTicket = uniqueBookings.reduce(
                (acc, booking) => {
                  acc += booking.count;
                  return acc;
                },
                0
              );

              acc[ticketId] = availableSeats - seatsCountBookedByOtherTicket;
              return acc;
            },
            {}
          );

          return seatsAvailableByUniqueId;
        })
        .subscribe(data => {
          this.workshopsList = data;
        })
    );

    this.isTicketSoldOut$ = this._store
      .select(fromRoot.getTicketsBooking)
      .map((booking: TicketBookingModel) => {
        this.isTicketBookingLoading = false;
        return booking.bookings.reduce((acc, booking) => {
          acc[booking.ticketUniqueId] =
            booking.isTicketSold && booking.count === 0;
          return acc;
        }, {});
      });

    this.availableTicketsCombined$ = observableCombineLatest([
      this._store.pipe(select(fromRoot.getTicketsBooking)),
      this._store.pipe(select(fromRoot.getTickets))
    ])
      .filter(data => {
        return !!data[1];
      })
      .map(data => {
        const booking: TicketBookingModel = data[0];
        const ungroupedTickets: TicketModel = data[1];

        const availability = Object.keys(ungroupedTickets).reduce(
          (acc, ticketId) => {
            if (booking) {
              const realtimeBooking = booking.bookings.find(booking => ungroupedTickets[ticketId].groupId === booking.groupId && ungroupedTickets[ticketId].id === booking.ticketTypeId && booking.count > 0);
              
              if (realtimeBooking) {
                const tickets = Object.keys(ungroupedTickets).map(ticketUniqueId => ungroupedTickets[ticketUniqueId]);
                const bookedTicket = ungroupedTickets[realtimeBooking.ticketUniqueId];

                if (bookedTicket) {
                  const sharedLimitTicketsBookingsAvailability = tickets.filter(ticket => ticket.groupId === realtimeBooking.groupId && ticket.id === realtimeBooking.ticketTypeId);
                  const sharedLimitTicketsTotalCount = sharedLimitTicketsBookingsAvailability.reduce((total, ticket) => total + ticket.count, 0);
                  const availableTickets =  bookedTicket.availableTickets - sharedLimitTicketsTotalCount; 
  
                  sharedLimitTicketsBookingsAvailability.forEach(ticket => acc[ticket.uniqueId] = availableTickets + ticket.count);
                  return acc;
                }
              }
            }

            acc[ticketId] = ungroupedTickets[ticketId].availableTickets;
            return acc;
          },
          {}
        );

        return availability;
      });

    this.totalAvailableTickets$ = observableCombineLatest([
      this._store.pipe(select(fromRoot.getTicketsBooking)),
      this._store.pipe(select(fromRoot.getTickets))
    ])
      .filter(data => {
        return !!data[1];
      })
      .map(data => {
        const booking: TicketBookingModel = data[0];
        const ungroupedTickets: TicketModel = data[1];
        const defaultLimit = 1000;
        const checkedTicketLimits = [];

        const availability = Object.keys(ungroupedTickets).reduce(
          (acc, ticketId) => {
            acc[ticketId] = ungroupedTickets[ticketId].availableTickets === 0 ? 0 : ungroupedTickets[ticketId].availableTickets || ungroupedTickets[ticketId].ticketLimit || defaultLimit;
            
            if (booking) {
              const realtimeBooking = booking.bookings.find(booking => ungroupedTickets[ticketId].groupId === booking.groupId && ungroupedTickets[ticketId].id === booking.ticketTypeId && booking.count > 0);
              
              if (realtimeBooking) {
                const tickets = Object.keys(ungroupedTickets).map(ticketUniqueId => ungroupedTickets[ticketUniqueId]);
                const bookedTicket = ungroupedTickets[realtimeBooking.ticketUniqueId];

                if (bookedTicket) {
                  const sharedLimitTicketsBookingsAvailability = tickets.filter(ticket => ticket.groupId === realtimeBooking.groupId && ticket.id === realtimeBooking.ticketTypeId);
                  const sharedLimitTicketsTotalCount = sharedLimitTicketsBookingsAvailability.reduce((total, ticket) => total + ticket.count, 0);

                  sharedLimitTicketsBookingsAvailability.forEach(ticket => {
                    const ticketUniqueId = ticket.uniqueId;
                    
                    if (!checkedTicketLimits.includes(ticketUniqueId) && !!acc[ticketUniqueId]) {
                      acc[ticketUniqueId] = acc[ticketId] - sharedLimitTicketsTotalCount;
                      checkedTicketLimits.push(ticketUniqueId);
                    }
                  });

                  return acc;
                }
              } 
            }
            
            return acc;
          },
          {}
        );

        return availability;
      });

    this.percentageOfAvailableTickets$ = observableCombineLatest(
      this._store.select(fromRoot.getTickets),
      this._store.select(fromRoot.getTicketsBooking)
    )
      .filter(data => !!data[0])
      .map(data => {
        const [ungroupedTickets, ticketBooking] = data;

        const ticketAvailability = Object.keys(ungroupedTickets).reduce(
          (acc, ticketKey) => {
            const ticket = ungroupedTickets[ticketKey];
            const uniqueId = ticket.uniqueId;
            acc[uniqueId] = !ticket.ticketLimit
              ? 1
              : 1 -
              (ticket.ticketLimit - ticket.availableTickets) /
              ticket.ticketLimit;
            return acc;
          },
          {}
        );

        const bookingsAvailability = ticketBooking.bookings.reduce(
          (acc, booking) => {
            const uniqueId = booking.ticketUniqueId;
            const ticket = ungroupedTickets[uniqueId];
            acc[uniqueId] =
              !booking.ticketLimit || !ticket || !ticket.availableTickets
                ? 1
                : 1 -
                (booking.ticketLimit -
                  ticket.availableTickets +
                  booking.count) /
                booking.ticketLimit;

            return acc;
          },
          {}
        );

        return { ...ticketAvailability, ...bookingsAvailability };
      });

      this.subscriptions.add(
        this._store.pipe(select(fromRoot.getTranslations))
          .first()
          .subscribe(() => {
          this._translateService
            .stream([
              `packages.package-counter-message`
            ])
            .subscribe(translations => {
              this.packageMessageMobile = [];
              this.packageMessageMobile.push(translations['packages.package-counter-message']);
            });
        })
      );
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  releaseVoucher(code, voucherTicket: TicketByIdModel) {
    observableCombineLatest([
      this._store.pipe(select(fromRoot.getSelectedExhibitionId)),
      this._store.pipe(select(fromRoot.getOrderUuid)),
      this._store.pipe(select(fromRoot.getTicketsBooking))
    ])
      .pipe(first())
      .subscribe(data => {
        const [id, uuid, ticketBooking] = data;
        const releaseVoucher: RedeemVoucherModel = {
          eventId: id,
          voucherCode: code,
          countryCode: this._translateService.currentLang,
          uuid,
          ticketPersonId: voucherTicket.ticketPersonId
        };

        this.clearVoucherInput$.next(true);

        //when we release voucher we also remove the voucher from ticketBooking
        this._store.dispatch(new ticketActions.AddTicketBooking(null));
        ticketBooking.bookings.map((booking: AddTicketBookingModel) => {
          if (booking.ticketUniqueId == voucherTicket.uniqueId) {
            //When releasing vouchers, we find the voucher ticket in bookings,
            //set available Tickets to default, and return the count to 0
            booking.availableTickets = booking.availableTickets + booking.count;
            booking.count = 0;
            booking.isTicketSold = booking.availableTickets > 0 ? false : true;

            this._store.dispatch(new ticketActions.AddTicketBooking(booking));
          }
        });

        // Bug 3921 (Support task 3900)
        if (!this.isSelfRegistrationEnabled) {
          this.counterChange({value: 0, decrease: true}, voucherTicket.uniqueId);
        }

        this._store.dispatch(new ticketActions.ReleaseVoucher(releaseVoucher));
        // now remove holder forms related to this voucher ticket if there is any
        // Bug 3921 (Support task 3900)
        // voucherTicket.holdersIndexes.forEach(holderIndex => {
        //   this._ticketsService.removeTicketHolder(holderIndex);
        // });
      });

    this.currentTicketAfterCountChanged = null;

    this.isVoucherAddedOrRemoved = true;

    // as we are dispatching new ticket bookings above - skip initial value and wait for the update so we can release contingent day tickets
    this._store
      .select(fromRoot.getTickets)
      .pipe(
        skip(1),
        first()
      )
      .subscribe(() => {
        if (voucherTicket.hasDaySellLimit) {
          this.handleContingentTicketChange();
        }
      });
  }

  /**
   * count prices of ticket per type;
   * based on ticket count of that type and number of sales available for this ticket type
   * in case there are more ticket than sales, make sum of prices of reduced tickets and full price tickets
   *
   * @param {TicketModel} ungroupedTickets
   * @param {string} ticketUniqueId
   * @returns {number}
   * @memberof WebShopTicketsComponent
   */
  getPricePerTicketType(
    ungroupedTickets: TicketModel,
    ticketUniqueId: string
  ): number {
    const ticketWithCount = ungroupedTickets[ticketUniqueId];
    const ticketCount = ticketWithCount.count;
    let fullTicketsPrice: number = 0;

    // we dont calculate parking ticket price.. the calculation is done differently
    if (!ungroupedTickets[ticketUniqueId].hasOwnProperty('parking')) {
      fullTicketsPrice = ticketCount * ticketWithCount.price;
    }

    return fullTicketsPrice;
  }

  /**
   * tell whether form is valid (at least one ticket is selected)
   *
   * @param {any} numberOfSelectedTickets
   * @returns
   * @memberof WebShopTicketsComponent
   */
  isFormValid(numberOfSelectedTickets) {
    return numberOfSelectedTickets ? true : false;
  }

  removeValidationFeedbacks() {
    const stepsFormsActionName = ['tickets', 'ticketSelection'];
    this._formsService.removeAllStepValidationFeedbacks(stepsFormsActionName);
    this._formsService.setFormValidity(false, null, stepsFormsActionName);
  }

  setValidation(
    isValid: boolean,
    validationFeedback: [string, string] = ['counters', 'steps.missing-input.ticket-counter']
  ) {
    const stepsFormsActionName = ['tickets', 'ticketSelection'];
    let [key, messageTranslationKey] = validationFeedback;

    switch (key) {
      case 'counters':
        this.isTicketCounterValid = isValid;
        break;

      case 'parking':
        this.isParkingTicketsValid = isValid;
        break;

      case 'contingent':
        this.isContingentsValid = isValid;
        break;

      case 'workshop':
        this.isWorkshopsMandatoryValid = isValid;
        break;

      case 'workshop-zero':
        this.isWorkshopsZeroPriceMandatoryValid = isValid;
        break;

      default:
        break;
    }

    const allStepValidationFieldsValid: boolean = this.isTicketCounterValid && this.isParkingTicketsValid && this.isContingentsValid && this.isWorkshopsMandatoryValid && this.isWorkshopsZeroPriceMandatoryValid;

    if (isValid) {
      if (key === 'counters' || key === 'parking' || (key === 'contingent' && !messageTranslationKey)) {
        //parking and contingent tickets share the same validation/translation key as the ticket counter ('counters'):
        if (this.isTicketCounterValid && this.isParkingTicketsValid && this.isContingentsValid) {
          this._formsService.removeStepValidationFeedback(stepsFormsActionName, 'counters');
        }
      } else {
        //other steps (i.e. currently only workshops) have their own key:
        this._formsService.removeStepValidationFeedback(stepsFormsActionName, key);
      }
    } else {
      if (key === 'parking' || (key === 'contingent' && !messageTranslationKey)) {
        key = 'counters'; messageTranslationKey = 'steps.missing-input.ticket-counter';
      }

      this._formsService.addStepValidationFeedback(stepsFormsActionName, key, messageTranslationKey);
    }

    //even though we've received an info that the ticket selection is valid it can only be valid if all step validation fields are valid:
    if (isValid && !allStepValidationFieldsValid) {
      isValid = false;
    }

    this._formsService.setFormValidity(isValid, null, stepsFormsActionName);
    this.ticketSelectionIsValid = isValid;
  }

  selectTicket(ticket: TicketByIdModel) {
    this.releaseContingentTickets();
    this._ticketsService.selectTicket(ticket);
    this._customizationService.setShoppingStartTime();
  }

  /**
   *
   *
   * @param {{value: number; decrease: number}} change can be either 1 or -1, depending whether ticket was added or removed
   * @param {any} ticketUniqueId
   * @memberof WebShopTicketsComponent
   */
  counterChange(data: { value: number; decrease: boolean, isTicketAddedByPackageCounter?: boolean }, uniqueId: string) {
    const { value, decrease, isTicketAddedByPackageCounter } = data;
    this.clearVoucherInput$.next(true);
    //if ticket count is increased and if workshop selection is mandatory we set the step not valid
    if (!decrease && this.exhibitionSettings.isWorkshopsSelectionMandatory && !this.workshopsOnTicketSelection) {
      this._formsService.setFormValidity(false, null, ['workshop', 'validation']);
    }

    // User Story 3466: When we remove ticket if checked slide index is same as value we are decreasing to we uncheck it
    if (decrease) {
      this._store.dispatch(
        new stepsActions.SetAddressCheckbox({
          checkedSlideIndex: null,
          isAddressCopied: false
        })
      );

      this._store.dispatch(
        new stepsActions.SetBuyerVisitorCheckbox({
          buyerVisitorCheckedSlideIndex: null,
          isBuyerVisitorChecked: false,
          showVisitorQuestionnaire: false
        })
      );
      this._formsService.removeAllStepValidationFeedbacks(this.visitorQuestionnaireValidation);
      this._formsService.setFormValidity(true, null, this.visitorQuestionnaireValidation);
    }

    this.currentTicketAfterCountChanged = { uniqueId, value };

    if (this.needsTicketOverLimitCheck) {
      //due to tickets over limit validation on personalization we have to invalidate buyerinfo form here
      //as otherwise if we change tickets on ticket selection the personalization step could remain valid so the user could be able to skip personalization revalidation and go directly to confirmation:
      this._formsService.setFormValidity(false, null, ['personal', 'buyerinfo']);
    }

    this._ticketsService.ticketCounterChanged(uniqueId, value, decrease);
    if (!isTicketAddedByPackageCounter) {
      this.handleContingentTicketChange();
    }
  }

  /**
   * @param {{value: number; previousValue: number; decrease: number}} change can be either 1 or -1, depending whether ticket was added or removed
   * @param {any} PackageModel
   * @memberof PackageCounterComponent
   */
  packageCounterChange(data: { value: number; previousValue: number; decrease: boolean }, changedPackage: PackageModel) {
    const { value, previousValue, decrease } = data;

    const isFirstPackageAlreadyAdded =  previousValue >= 1;
    const firstPackageAdded = !isFirstPackageAlreadyAdded && value >= 1;
    const removeAllPackages = isFirstPackageAlreadyAdded && value == 0;

    const changedPackagesDifferenceCount = Math.abs(value - previousValue);
    const isFirstPackageChanged = firstPackageAdded || removeAllPackages;
    const additionalPackagesToChangeCount = isFirstPackageChanged ? changedPackagesDifferenceCount - 1 : changedPackagesDifferenceCount;

    if (firstPackageAdded) {
      this.showPackageMessage = false;
      clearTimeout(this.timeoutId);
    }

    if (!decrease) {
      const packageMinimalTicketCount = this._packagesService.getPackageMinimalTicketCount(changedPackage);
      this._store.dispatch(new ticketActions.AddTicketHolderIndexes(packageMinimalTicketCount));
    }

    const parsedContingentTickets = this.getContingentTicketsFromLocalStorage();
    if (isFirstPackageChanged) {
      const firstPackageContents = changedPackage.contents[0];

      if (!decrease) {
        this._store.pipe(
          select(fromRoot.getTickets),
          first()
        )
        .subscribe(tickets => {
          const flatTickets = {...tickets};
          
          firstPackageContents.packageGroups.forEach(packageGroup => {
              packageGroup.products.forEach(product => {
                flatTickets[product.ticket.uniqueId].count = this._packagesService.setPackageTicketAmount(product.ticket.packageSettings, 0);
                this.createAdditionalDayFormControls(
                  flatTickets[product.ticket.uniqueId],
                  parsedContingentTickets
                );
              });
            });

          setTimeout(() => {
            this._store.dispatch(new ticketActions.SetTickets(flatTickets));
          }, 0);
        });
      }

      this._packagesService.packageContentCountChanged(firstPackageContents, decrease);
    }

    if (additionalPackagesToChangeCount >= 1) {
      this._packagesService.updatePackageContents(changedPackage, 1, decrease)
        .pipe(
          delay(0),
          filter(tickets => !!tickets), 
          first()
        )
        .subscribe(tickets => {
          if (decrease) {
            Object.keys(this.contingentTicketsForm.controls).forEach(control => {
              if (!tickets[this.getUniqueTicketID(control)]) {
                this.contingentTicketsForm.removeControl(control);
              }
            });
          }
        });
    }
    
    this._store.pipe(
      select(fromRoot.getTicketHolderIndexes),
      filter(indexes => !!indexes && !Object.keys(indexes).some(index => indexes[index] == '')),
      first()
    )
    .subscribe(() => {
      this.handleContingentTicketChange();
    });
  }

  /**
   * When counter changes and number of tickets is less than amount of additional FormGroups > remove those FormControls
   */
  removeAdditionalTicketFormControls = (
    formWithAdditionalControls: FormGroup,
    ungroupedTicket: TicketByIdModel,
    afterUpdateCallback?: Function
  ) => {
    const ticketCountArray = this.getArrayFromTicketCount(
      ungroupedTicket.count
    );

    // get only those which belong to current ticket (e.g. Ticket_1234 > FormGroup_1)
    const formGroupNames = Object.keys(
      formWithAdditionalControls.controls
    ).filter(key => this.getUniqueTicketID(key) === ungroupedTicket.uniqueId);

    // check if tickets were removed
    if (ticketCountArray.length < formGroupNames.length) {
      // how many were removed
      const difference = formGroupNames.length - ticketCountArray.length;

      formGroupNames.reverse().forEach((formControlName, i) => {
        if (i + 1 <= difference) {
          formWithAdditionalControls.removeControl(formControlName);
        }
      });

      afterUpdateCallback && afterUpdateCallback(this);
    }
  };

  releaseContingentTickets() {
    if (this.contingentSubscriptions) {
      this.contingentSubscriptions.unsubscribe();
      this.contingentSubscriptions = null;

      this._ticketsService.postReleaseTicketForDay();
    }
  }

  handleContingentTicketChange() {
    // if the contingents form doesnt have controls (there is none of the tickets with day limit selected) perform reset
    // this part of code inside condition is basically called each time a ticket bookings got updated - should probably be refactored
    if (!Object.keys(this.contingentTicketsForm.controls).length) {
      setLocalStorageObject(AppConstants.contingentTicketsReducer, '');

      this.releaseContingentTickets();

      Object.keys(this.endDays).forEach(key => {
        this.endDays[key].length = 0;
      });

      this.setValidation(true, ['contingent', '']);
      this.setValidation(true, ['contingent', 'steps.contingent.sold-out']);
      this.invalidContingentTickets = [];
      return;
    }

    //TODO: we should probably also release contingents when removing tickets (but while we still have some contingents left on the form)

    this.setValidation(false, ['contingent', '']);

    setLocalStorageObject(AppConstants.contingentTicketsReducer, this.contingentTicketsForm.value);

    observableCombineLatest(
      this._store.select(fromRoot.getOrderUuid),
      this._store.select(fromRoot.getTickets),
      this._store.select(fromRoot.getSelectedExhibitionId)
    )
      //added delay(0) here to prevent "ExpressionChangedAfterItHasBeenCheckedError" errors in the console:
      .pipe(delay(0), filter(data => !!data[1]), first())
      .subscribe(([uuid, bookedTickets, eventId]) => {
        const allTickets = Object.keys(this.contingentTicketsForm.value).map(
          formGroupName => {
            const uniqueId = this.getUniqueTicketID(formGroupName);
            const dayFormGroup = this.contingentTicketsForm.get(
              `${formGroupName}`
            );

            const ticketPersonId = bookedTickets[uniqueId].ticketPersonId;
            const ticketDay = dayFormGroup.value.day;

            return {
              ticketPersonId,
              day: ticketDay ? DateTime.fromJSDate(ticketDay).toISODate() : null,
              uniqueId: uniqueId,
              ticketIndex: parseInt(this.getTicketIndex(formGroupName))
            };
          }
        );
          
        const tickets: ContingentTicketModel[] = allTickets.filter(ticket => ticket.day);

        Object.keys(this.endDays).forEach(key => {
          if (bookedTickets[key] && bookedTickets[key]['holdersIndexes'].length !== this.endDays[key].length) {
            this.endDays[key].length = bookedTickets[key]['holdersIndexes'].length;
          }
        });

        this.hasContingentForm = !!Object.keys(
          this.contingentTicketsForm.controls
        ).length;
        const hasTickets = !!tickets.length;

        // //remove previous contingent dates so they won't be displayed until we get feedback from the backend:
        // tickets.forEach(ticket => {
        //   if (this.endDays[ticket.uniqueId] && this.endDays[ticket.uniqueId].length) {
        //     this.endDays[ticket.uniqueId].length = 0;
        //   }
        // });

        this.invalidContingentTickets = [];

        allTickets.forEach(ticket => {
          this.invalidContingentTickets.push(ticket.uniqueId);
        });

        const data: ContingentBookingModel = {
          uuid: uuid,
          eventId: eventId,
          tickets
        };

        if (this.hasContingentForm && hasTickets) {
          if (this.contingentSubscriptions) {
            this.contingentSubscriptions.unsubscribe();
            this.contingentSubscriptions = null;
          }

          this.contingentSubscriptions =
            this._ticketsService.postBookTicketForDay(data).subscribe(
              (bookings: ContingentTicketValidityResponse[]) => {
                this.updateTicketsWithSetDay(data.tickets, bookings);
              },
              () => {
                this.setValidation(false, ['contingent', 'steps.contingent.sold-out']);
              }
            );
        } else {
          //release contingents:
          this.releaseContingentTickets();
        }
      });
  }

  /**
   * Returns all parts of a unique ticket ID bar the last one (usually ticket index)
   * @param ticketIDWithIndex Input string, ticket ID with index, elements should be separated by '_'
   */
  getUniqueTicketID(ticketIDWithIndex: string): string {
    const splitID: string[] = ticketIDWithIndex.split('_');
    let result: string = '';

    if (splitID.length > 1) {
      for (let index = 0; index < splitID.length - 1; index++) {
        result += splitID[index] + '_';
      }
    } else {
      return ticketIDWithIndex;
    }

    if (result.endsWith('_')) {
      result = result.substr(0, result.length - 1);
    }

    return result;
  }

  /**
   * Returns only the last part of a unique ticket ID, usually ticket index
   * @param ticketIDWithIndex Input string, ticket ID with index, elements should be separated by '_'
   */
  getTicketIndex(ticketIDWithIndex: string): string {
    const splitID: string[] = ticketIDWithIndex.split('_');

    return splitID.length > 1 ? splitID[splitID.length - 1] : ticketIDWithIndex;
  }

  updateTicketsWithSetDay(reservedTickets: ContingentTicketModel[], bookings: ContingentTicketValidityResponse[]) {
    this._store
      .pipe(
        select(fromRoot.getTickets),
        filter(data => !!data), 
        first()
      )
      .subscribe((tickets: TicketModel) => {
        this.endDays = {};
        const ticketKeys = Object.keys(tickets);
        const contingentsData = this.contingentTicketsForm.value;
        const validatedTicketIndexes = {}; 

        for (let i = 0; i < ticketKeys.length; i++) {
          this.endDays[ticketKeys[i]] = [];
        }

        for (let i = 0; i < reservedTickets.length; i++) {
          for (let j = 0; j < ticketKeys.length; j++) {
            const currentKey: string = ticketKeys[j];
            const currentTicket: TicketByIdModel = tickets[currentKey];
            const currentReservedTicket = reservedTickets[i];
            const currentReservedTicketIndex = currentReservedTicket.ticketIndex;

            if (currentTicket.ticketPersonId === currentReservedTicket.ticketPersonId &&
              currentTicket.uniqueId === currentReservedTicket.uniqueId) {
              const startDate: Date = new Date(currentReservedTicket.day);
              const reservedDate: DateTime = DateTime.fromJSDate(
                startDate
              );
              let endDate: Date =
                currentTicket.durationInDays > 1
                  ? reservedDate
                    .plus({ days: currentTicket.durationInDays - 1 })
                    .toJSDate()
                  : startDate;
              let duration = currentTicket.durationInDays;
              let isValid = null;
              
              const contingentTicketValidityResponse = bookings.filter(({ticketPersonId, ticketIndex}) => ticketPersonId == currentTicket.ticketPersonId && ticketIndex == currentReservedTicketIndex);
              if (!!contingentTicketValidityResponse) {
                if (validatedTicketIndexes[currentTicket.ticketPersonId] == undefined) {
                  validatedTicketIndexes[currentTicket.ticketPersonId] = {};
                }

                if (validatedTicketIndexes[currentTicket.ticketPersonId][currentReservedTicketIndex] == undefined) {
                  validatedTicketIndexes[currentTicket.ticketPersonId][currentReservedTicketIndex] = 0;
                }

                isValid = contingentTicketValidityResponse[validatedTicketIndexes[currentTicket.ticketPersonId][currentReservedTicketIndex]].isValid;
                validatedTicketIndexes[currentTicket.ticketPersonId][currentReservedTicketIndex] = validatedTicketIndexes[currentTicket.ticketPersonId][currentReservedTicketIndex] + 1;
              }

              const ticketValidTill: Date = !!currentTicket.validTill ? new Date(currentTicket.validTill) : null;

              if (!!ticketValidTill && this._helperService.isFirstDateGreaterWOTime(endDate, ticketValidTill)) {
                endDate = ticketValidTill;
              }

              if (this._helperService.isFirstDateGreaterWOTime(endDate, this.exhibition.endDate)) {
                endDate = this.exhibition.endDate;
              }

              if (duration > 1 && this._helperService.areDatesSameWOTime(endDate, startDate)) {
                duration = 1;
              }

              //SteZ: we have to add empty items into endDays list for those tickets without a set contingent date
              //as otherwise the application wouldn't apply the already set and approved dates to the correct tickets
              //(e.g. if you buy two tickets and set a date on the second one that date would be applied to the first ticket index):
              while (this.endDays[currentKey].length < currentReservedTicketIndex) {
                const contingentDaysEmptyData = {
                  start: null,
                  end: null,
                  duration: null,
                  isValid: null
                };

                this.endDays[currentKey].push(contingentDaysEmptyData);
              }

              const contingentDaysData = {
                start: startDate,
                end: endDate,
                duration: duration,
                isValid: isValid
              };

              contingentsData[`${currentKey}_${currentReservedTicketIndex}`] = {
                ...contingentsData[`${currentKey}_${currentReservedTicketIndex}`],
                ...contingentDaysData
              };

              this.endDays[currentKey].push(contingentDaysData);

              const index = this.invalidContingentTickets.indexOf(currentKey);
              if (index > -1 && isValid) {
                this.invalidContingentTickets.splice(index, 1);
              }
            }
          }
        }

        if (!this.invalidContingentTickets.length && this.contingentTicketsForm.valid) {
          this.setValidation(true, ['contingent', '']);
          this.setValidation(true, ['contingent', 'steps.contingent.sold-out']);
        }

        setLocalStorageObject(AppConstants.contingentTicketsReducer, contingentsData);
      });
  }

  handleParkingChange(since: Date, until: Date, uniqueId: string) {
    const untilForm = this.parkingTicketsForm.get(`${uniqueId}.until`);
    const sinceForm = this.parkingTicketsForm.get(`${uniqueId}.since`);

    if (!since) {
      untilForm.setValue(null);
      return;
    }

    if (since < this.dateToday) {
      since = this.addHour(this.dateToday);
      sinceForm.setValue(since);
    }

    const splitedUniqueId = uniqueId.split('_');
    const uniqueTicket = `${splitedUniqueId[0]}_${splitedUniqueId[1]}`;

    //do this only if we have a current ticket and if current parking ticket form is valid:
    //(createAdditionalParkingFormControls function will iterate through all parking tickets and request a parking fee for each ticket from the API)
    if (!!this.ungroupedTickets && !!this.ungroupedTickets[uniqueTicket] && this.parkingTicketsForm.get(`${uniqueId}`).valid) {
      this.createAdditionalParkingFormControls(
        this.ungroupedTickets[uniqueTicket],
        this.getParkingTicketsFromLocalStorage()
      );
    }
  }

  createAdditionalParkingFormControls(
    ungroupedTicket: TicketByIdModel,
    initialParkingTicketsValues: Record<string, any>
  ) {
    if (ungroupedTicket.parking) {
      this.setValidation(false, ['parking', '']);

      this.removeAdditionalTicketFormControls(
        this.parkingTicketsForm,
        ungroupedTicket,
        this.updateTotalParkingPrice
      );

      const ticketUniqueId = ungroupedTicket.uniqueId;
      const ticketCountArray = this.getArrayFromTicketCount(
        ungroupedTicket.count
      );

      ticketCountArray.forEach(countIndex => {
        const newFormControlName = `${ticketUniqueId}_${countIndex}`;
        const hasSinceInStore =
          initialParkingTicketsValues &&
          initialParkingTicketsValues[newFormControlName] &&
          initialParkingTicketsValues[newFormControlName].since;
        const hasUntilInStore =
          initialParkingTicketsValues &&
          initialParkingTicketsValues[newFormControlName] &&
          initialParkingTicketsValues[newFormControlName].until;

        const since = hasSinceInStore
          ? new Date(initialParkingTicketsValues[newFormControlName].since)
          : null;
        const until = hasUntilInStore
          ? new Date(initialParkingTicketsValues[newFormControlName].until)
          : null;

        const parkingDatesFormGroup = this.fb.group({
          since: [since, Validators.required],
          until: [until, Validators.required]
        });

        this.parkingTicketsForm.addControl(
          newFormControlName,
          parkingDatesFormGroup
        );

        this.isParkingFormValid(
          newFormControlName,
          initialParkingTicketsValues,
          since,
          until
        );
      });
    }
  }

  isParkingFormValid(formId, parkingTickets, since, until) {
    const ticketKeyData = formId.split('_');
    const ticketGroup = Number(ticketKeyData[0]);
    const ticketPerson = Number(ticketKeyData[1]);
    const untilForm = this.parkingTicketsForm.get(`${formId}.until`);

    if (this.parkingTicketsForm.get(`${formId}`).valid) {
      if (until <= since) {
        until = this.addHour(since);
        untilForm.setValue(until);
      }

      const validFormId = formId;
      const uniqueId = `${ticketGroup}_${ticketPerson}`;

      this.isParkingTicketLoading = true;

      this._ticketsService
        .getParkingTicketFee(
          this.exhibitionSettings.eventId,
          ticketGroup,
          ticketPerson,
          this._helperService.toStringWithoutOffset(since),
          this._helperService.toStringWithoutOffset(until),
          validFormId
        )
        .subscribe(
          (response: any) => {
            const ticketPrice = response.body;

            parkingTickets[validFormId]['price'] = ticketPrice;
            parkingTickets[validFormId]['since'] = since;
            parkingTickets[validFormId]['until'] = until;

            setLocalStorageObject(AppConstants.parkingTicketsReducer, parkingTickets);

            const index = this.invalidParkingTickets.indexOf(uniqueId);
            if (index > -1) {
              this.invalidParkingTickets.splice(index, 1);
            }

            if (!this.invalidParkingTickets.length && this.parkingTicketsForm.valid) {
              this.setValidation(true, ['parking', '']);
            }

            this.isParkingTicketLoading = false;
            this.updateTotalParkingPrice();
          },
          () => {
            this.setValidation(false, ['parking', '']);

            this.isParkingTicketLoading = false;
            this.parkingTicketsForm.get(`${formId}.since`).reset();
            this.parkingTicketsForm.get(`${formId}.until`).reset();
          }
        );
    }
  }

  createAdditionalDayFormControls(
    ungroupedTicket: TicketByIdModel,
    initialDayTicketsValues: Record<string, any>
  ) {
    if (ungroupedTicket.days) {
      this.removeAdditionalTicketFormControls(
        this.contingentTicketsForm,
        ungroupedTicket
      );

      const ticketUniqueId = ungroupedTicket.uniqueId;
      const ticketCountArray = this.getArrayFromTicketCount(
        ungroupedTicket.count
      );

      ticketCountArray.forEach(countIndex => {
        const newFormControlName = `${ticketUniqueId}_${countIndex}`;
        const hasDayInStore =
          initialDayTicketsValues &&
          initialDayTicketsValues[newFormControlName] &&
          initialDayTicketsValues[newFormControlName].day;

        let day = hasDayInStore
          ? new Date(initialDayTicketsValues[newFormControlName].day)
          : null;

        if (ungroupedTicket.hasDaySellLimit && !ungroupedTicket.shouldCalendarBeDisplayed) {
          const today = new Date();
          const validFromDate = new Date(ungroupedTicket.validFrom);
          day = validFromDate < today ? today : validFromDate;
        }

        const bookingDaysFormGroup = this.fb.group({
          day: [day, Validators.required]
        });

        this.contingentTicketsForm.addControl(
          newFormControlName,
          bookingDaysFormGroup
        );
      });
    }
  }

  addHour(date: Date) {
    if (!date) return null;
    const copiedDate = new Date(date.getTime());
    copiedDate.setHours(copiedDate.getHours() + 1);
    return copiedDate;
  }

  toggleSectionAccordian(event, section: SectionGroupModel) {
    const element = event.target;
    if (element.classList.contains('accordion')) {
      element.classList.toggle('active');
    }

    section.expanded = !section.expanded;
  }

  toggleWorkshopAccordian(event, workshop: WorkshopByDayModel) {
    workshop.expanded = !workshop.expanded;
  }

  toggleTicketAccordian(event, ticketVersion) {
    const element = event.target;
    if (element.classList.contains('accordion')) {
      element.classList.toggle('active');
    }

    if (
      ticketVersion.infoExpanded != true &&
      ticketVersion.infoExpanded != false
    ) {
      ticketVersion.infoExpanded =
        this._translateService.instant(ticketVersion.infoExpanded) == 'true';
    }

    ticketVersion.infoExpanded = !ticketVersion.infoExpanded;
  }

  canToggle(ticketVersion): boolean {
    return (
      ticketVersion.info &&
      this._translateService.instant(ticketVersion.info) !== AppConstants.MISSING_TRANSLATION
    );
  }

  ticketLoading(ticketLoading) {
    this.isTicketBookingLoading = ticketLoading;
  }

  getParkingTicketsFromLocalStorage() {
    const storedParkingTickets = getLocalStorageString(AppConstants.parkingTicketsReducer);

    if (!storedParkingTickets) {
      setLocalStorageObject(AppConstants.parkingTicketsReducer, '');
    }

    const parsedParkingTickets =
      storedParkingTickets && JSON.parse(storedParkingTickets);

    return parsedParkingTickets;
  }

  getContingentTicketsFromLocalStorage() {
    const storedContingentTickets = getLocalStorageString(AppConstants.contingentTicketsReducer);

    if (!storedContingentTickets) {
      setLocalStorageObject(AppConstants.contingentTicketsReducer, '');
    }

    const parsedContingentTickets =
      storedContingentTickets && JSON.parse(storedContingentTickets);

    return parsedContingentTickets;
  }

  getArrayFromTicketCount(count: number) {
    return Array.from({ length: count }, (val, i) => i);
  }

  isSectionVisible(section: SectionGroupModel): boolean {
    return section.productGroups.some(product =>
      this.isTicketGroupVisible(product)
    );
  }

  isTicketGroupVisible(product: ProductGroupModel) {
    return product.products.some(product => { 
      if (!!product.ticket) {
        return this.isTicketVisible(product.ticket);
      }

      if (!!product.package && !this.isSelfRegistrationEnabled) {
        return product.package.contents.some(packageContent =>
          packageContent.packageGroups.some(packageGroup => 
            packageGroup.products.some(product => this.isTicketVisible(product.ticket))
          )
        );
      }
    });
  }

  isTicketVisible(ticket: TicketGroupTicketModel): boolean {
    if (!ticket) return false;

    if (this.isSelfRegistrationEnabled && this.hasVoucher) {
      return !!ticket.voucherCode;
    } else {
      if (ticket.isVisible) return true;
      if (ticket.isVoucher && ticket.voucherCode) return true;

      return false;
    }
  }

  loadSections() {
    this._store
      .pipe(
        select(fromRoot.getTicketsTypes), 
        filter(data => !!data)
      )
      .first()
      .subscribe((data: ProductGroupModel[]) => {
        const ticketGroups = data.map(ticketGroup => {
          return ticketGroup;
        });

        this.hasVoucher = ticketGroups.some(group => {
          return group.products.some(p => {
            if (p.ticket == null) {
              return false;
            }

            return !!p.ticket.voucherCode;
          });
        });

        this.productGroups = [...ticketGroups];

        this.sections = this.productGroups.reduce((r, { section }) => {
          if (!r.some(o => o.sectionIndex === section.sectionIndex)) {
            r.push({
              sectionId: section.sectionId,
              sectionIndex: section.sectionIndex,
              sectionName: section.sectionName,
              groupDescription: section.groupDescription,
              expanded: section.expanded,
              productGroups: this.productGroups.filter(
                v => v.section.sectionIndex === section.sectionIndex
              )
            });
          }
          return r;
        }, []);
      });
  }

  setStepsVisibility(isVisible) {
    let stepsArray: string[] = [];
    if (!isVisible) {
      Object.keys(this.stepsValidity).forEach(key => {
        if (this.stepsValidity[key].visible
          && key !== 'tickets' && key !== 'invoice') {
          stepsArray.push(key);
        }
      });
    } else {
      stepsArray = [...this.hiddenSteps];
    }

    if (this.hiddenSteps.length < 1) {
      this._store.dispatch(
        new stepsActions.SetAnonymousHiddenSteps(stepsArray)
      );
    }

    const visibilityPayload: VisibilityPayloadModel[] = stepsArray.map(
      (step): VisibilityPayloadModel => {
        return {
          stepKey: step,
          visible: isVisible
        };
      }
    );

    this._store.dispatch(
      new stepsActions.SetStepsVisibility(visibilityPayload)
    );
  }

  checkForSelectedAnonymousTicketAndAdjustSteps() {
    if (!!this.ungroupedTickets) {

      // checking if anonymous ticket is taken
      this.hasAnonymous = this._ticketsService.checkIfAnonymousTicketTaken(this.ungroupedTickets);
      this.setStepsVisibility(!this.hasAnonymous);
    }
  }

  toggleDetail(activeWorkshopId?: number): void {
    this.toggledWorkshop = this.workshops.find(
      workshop => workshop.workshopId === activeWorkshopId
    );

    this.workshopModalWindowYOffset = window.pageYOffset + 100;
  }

  convertFromDate(text: string): Date {
    let dateString = `${text}T00:00:00.000Z`;
    return new Date(dateString);
  }

  ngAfterViewInit(): void {
    this._gtmService.pushProductDetail();
    this.handleContingentTicketChange();
  }

  getParkingTicketPrice(ticketVersion): number {
    const parsedParkingTickets = this.getParkingTicketsFromLocalStorage();

    let price = 0;
    if (parsedParkingTickets) {
      Object.keys(parsedParkingTickets).forEach(key => {
        const ticket = parsedParkingTickets[key];

        if (key.startsWith(ticketVersion.uniqueId) && ticket.price) {
          price += ticket.price;
        }
      });
    }

    return price;
  }

  packageCounterWarningDefinition(data, productPackage) {
    this.packageCounterWarningString = data;

    if (!!data) {
      productPackage.warning = true;
    } else {
      this.productGroups.forEach(pg => {
        pg.products.forEach(pgProduct => {
          if (pgProduct.package) {
            delete pgProduct.package.warning;
          }
        })
      })
    }
  }
}
