<div class="loading-wrapper">
    <div class="loader-container">
        <div class="initial-loader">
            <div class="initial-loader-dot"></div>
            <div class="initial-loader-dot"></div>
            <div class="initial-loader-dot"></div>
            <div class="initial-loader-dot"></div>
            <div class="initial-loader-dot"></div>
            <div>
                <svg class="loading-logo">
                    <use xlink:href="assets/defs.svg#svg-axess-logo"></use>
                </svg>
            </div>
        </div>
    </div>
</div>
