/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./form.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/forms/df-input/df-input.component.ngfactory";
import * as i3 from "../../../../shared/forms/df-input/df-input.component";
import * as i4 from "../../../../shared/forms/forms.service";
import * as i5 from "../../../../shared/services-with-reducers/helpers/helper.service";
import * as i6 from "@ngrx/store";
import * as i7 from "../../../../shared/validation/validation.service";
import * as i8 from "@angular/forms";
import * as i9 from "@angular/common";
import * as i10 from "./form.component";
var styles_FormComponent = [i0.styles];
var RenderType_FormComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FormComponent, data: {} });
export { RenderType_FormComponent as RenderType_FormComponent };
function View_FormComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-df-input", [], [[8, "className", 0]], null, null, i2.View_DfInputComponent_0, i2.RenderType_DfInputComponent)), i1.ɵdid(1, 4964352, null, 0, i3.DfInputComponent, [i4.FormsService, i5.HelperService, i6.Store, i1.ElementRef, i1.Renderer2, i7.ValidationService], { input: [0, "input"], inputSet: [1, "inputSet"], action: [2, "action"], form: [3, "form"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _v.context.$implicit; var currVal_2 = _co.inputs; var currVal_3 = _co.formPath; var currVal_4 = _co.form; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.cssClass; _ck(_v, 0, 0, currVal_0); }); }
function View_FormComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "ticket-form-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "form", [["class", "row"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i8.ɵangular_packages_forms_forms_bg, [], null, null), i1.ɵdid(3, 540672, null, 0, i8.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i8.ControlContainer, null, [i8.FormGroupDirective]), i1.ɵdid(5, 16384, null, 0, i8.NgControlStatusGroup, [[4, i8.ControlContainer]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormComponent_2)), i1.ɵdid(7, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.form; _ck(_v, 3, 0, currVal_7); var currVal_8 = _co.inputs; _ck(_v, 7, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 5).ngClassValid; var currVal_5 = i1.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 5).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_FormComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormComponent_1)), i1.ɵdid(1, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.form; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FormComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-form", [], null, null, null, View_FormComponent_0, RenderType_FormComponent)), i1.ɵdid(1, 245760, null, 0, i10.FormComponent, [i4.FormsService, i6.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormComponentNgFactory = i1.ɵccf("app-form", i10.FormComponent, View_FormComponent_Host_0, { formPath: "formPath" }, { updatedTicketHolderInputs: "updatedTicketHolderInputs", handleFormValueChange: "handleFormValueChange" }, []);
export { FormComponentNgFactory as FormComponentNgFactory };
