/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./home.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../_layout/age-rating/age-rating.component.ngfactory";
import * as i4 from "../../_layout/age-rating/age-rating.component";
import * as i5 from "@angular/router";
import * as i6 from "../../exhibition-tile/exhibition-tile.component.ngfactory";
import * as i7 from "../../exhibition-tile/exhibition-tile.component";
import * as i8 from "../../shared/services-with-reducers/exhibition/exhibition.service";
import * as i9 from "@ngx-translate/core";
import * as i10 from "../../loader/loader.component.ngfactory";
import * as i11 from "../../loader/loader.component";
import * as i12 from "../../shared/pipes/hide-translation/hide-translation.pipe";
import * as i13 from "./home.component";
import * as i14 from "@ngrx/store";
import * as i15 from "../../shared/services-with-reducers/customization/customization.service";
import * as i16 from "../../app.service";
var styles_HomeComponent = [i0.styles];
var RenderType_HomeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HomeComponent, data: {} });
export { RenderType_HomeComponent as RenderType_HomeComponent };
function View_HomeComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", ""], ["class", "banner-img"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.localizedImages.banner; _ck(_v, 0, 0, currVal_0); }); }
function View_HomeComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "container-fluid nopadding-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "hide-link": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HomeComponent_3)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 3, 0, !((_co.operatorSettings == null) ? null : _co.operatorSettings.isBannerLinkVisible)); _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.localizedImages.banner; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", ((_co.operatorSettings == null) ? null : _co.operatorSettings.bannerLink), ""); _ck(_v, 1, 0, currVal_0); }); }
function View_HomeComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-age-rating", [], null, null, null, i3.View_AgeRatingComponent_0, i3.RenderType_AgeRatingComponent)), i1.ɵdid(1, 114688, null, 0, i4.AgeRatingComponent, [], { rating: [0, "rating"], adjustPositions: [1, "adjustPositions"] }, null), i1.ɵpod(2, { top: 0, right: 1 })], function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.ageRating; var currVal_1 = _ck(_v, 2, 0, "25px", "25px"); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_HomeComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(2, 2), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-exhibition-tile", [], null, null, null, i6.View_ExhibitionTileComponent_0, i6.RenderType_ExhibitionTileComponent)), i1.ɵdid(4, 49152, null, 0, i7.ExhibitionTileComponent, [i8.ExhibitionService], { exhibition: [0, "exhibition"] }, null)], function (_ck, _v) { var currVal_2 = _ck(_v, 2, 0, "webshop", _v.parent.parent.context.$implicit.id); _ck(_v, 1, 0, currVal_2); var currVal_3 = _v.parent.parent.context.$implicit; _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_HomeComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "tile-col col-sm-6 col-md-4 col-lg-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "tile"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HomeComponent_6)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HomeComponent_7)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.ageRating; _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit; _ck(_v, 5, 0, currVal_1); }, null); }
function View_HomeComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_HomeComponent_5)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_v.context.$implicit.isVisible && !_v.context.$implicit.isOver) || i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.isLoggedInAsAdmin$))); _ck(_v, 2, 0, currVal_0); }, null); }
function View_HomeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "homepage-banner-wrap main-bg"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_HomeComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "choose-exhibition"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "container-fluid"], ["data-translation", "home.exhibition-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), i1.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(9, 1), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "exhibition-tiles"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 3, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "row tiles-row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HomeComponent_4)), i1.ɵdid(14, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.localizedImages$)); _ck(_v, 3, 0, currVal_0); var currVal_2 = _co.exhibitions; _ck(_v, 14, 0, currVal_2); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 7, 0, _ck(_v, 9, 0, i1.ɵnov(_v.parent, 0), i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("home.exhibition-title")))); _ck(_v, 7, 0, currVal_1); }); }
function View_HomeComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loader", [], null, null, null, i10.View_LoaderComponent_0, i10.RenderType_LoaderComponent)), i1.ɵdid(1, 49152, null, 0, i11.LoaderComponent, [i1.ElementRef], null, null)], null, null); }
export function View_HomeComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i12.HideTranslationPipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HomeComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["loading", 2]], null, 0, null, View_HomeComponent_8))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loaded; var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_HomeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-home", [], null, null, null, View_HomeComponent_0, RenderType_HomeComponent)), i1.ɵdid(1, 245760, null, 0, i13.HomeComponent, [i14.Store, i15.CustomizationService, i16.AppService, i5.ActivatedRoute, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HomeComponentNgFactory = i1.ɵccf("app-home", i13.HomeComponent, View_HomeComponent_Host_0, {}, {}, []);
export { HomeComponentNgFactory as HomeComponentNgFactory };
