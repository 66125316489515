import {
  combineLatest as observableCombineLatest,
  Subscription,
  of
} from 'rxjs';

import { first, filter, throttleTime } from 'rxjs/operators';
import * as fromRoot from '../../../app.reducer';
import * as ticketActions from '../../../shared/services-with-reducers/tickets/ticket.actions';

import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';

import { FormGroup } from '@angular/forms';
import { FormsService } from '../../../shared/forms/forms.service';
import { HelperService } from '../../../shared/services-with-reducers/helpers/helper.service';
import { InputBase } from '../../../shared/forms/inputs/input-base.class';
import { Store } from '@ngrx/store';

@Component({
  moduleId: module.id,
  selector: 'app-ticket-holder-questionnaire',
  templateUrl: './ticket-holder-questionnaire.component.html',
  styleUrls: ['./ticket-holder-questionnaire.component.scss']
})
export class TicketHolderQuestionnaireComponent implements OnInit, OnDestroy {
  @Output()
  isValid = new EventEmitter<Object>();

  public form: FormGroup;
  public inputs: InputBase<any>[];
  public customAction = ['ticketHolderQuestionaireForm'];
  public subscriptions = new Subscription();

  constructor(
    private _store: Store<fromRoot.State>,
    private _formsService: FormsService,
    private _helperService: HelperService
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      observableCombineLatest(
        this._store.select(fromRoot.getTicketHolderQuestionnaireInputs),
        this._store.select(fromRoot.getSelectedExhibitionId),
        this._store.select(fromRoot.getLanguage)
      )
        .pipe(
          filter(data => {
            const [questionaireInputs, eventId, lang] = data;
            if (
              (!!questionaireInputs && !questionaireInputs.length) ||
              (Number(eventId) === 90 && lang === 'en')
            ) {
              this.isValid.emit({
                formName: 'questionnaire',
                valid: true,
                inputs: null,
                form: null
              });

              this.inputs = null;
              return false;
            }
            return !!questionaireInputs && !!questionaireInputs.length;
          })
        )
        .subscribe((data: [InputBase<any>[], number, string]) => {
          const [questionaireInputs, eventId, lang] = data;

          this.inputs = this._formsService.updateInputs(
            this.inputs,
            questionaireInputs
          );

          this.setupForm();
        })
    );
  }

  setInputsVisibility() {
    this.inputs.map((input: InputBase<any>) => {
      if (input.previousValueId) {
        let element = this.inputs.find(
          p =>
            p.value == input.previousValueId ||
            (p.controlType === 'checkbox' &&
              p.options.find(
                (option: { value: boolean; key: number }) =>
                  option.value === true && option.key === input.previousValueId
              ))
        );

        input.hidden = !element;
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public inputsChanged = (inputs: InputBase<any>[], rerender: boolean) => {
    // get updated inputs, now we need to everwrite the old set with updated ones

    this.inputs = this._formsService.updateInputs(this.inputs, inputs);

    this._store.dispatch(
      new ticketActions.SetTicketHolderQuestionnaire(this.inputs)
    );

    this.setupForm();
  };

  setupForm() {
    this.setInputsVisibility();

    const visibleInputs = this.inputs.filter(p => !p.hidden);
    this.form = this._formsService.toFormGroup(visibleInputs);

    this._helperService.triggerCallbackOnceFormValidationIsDone(
      this.form,
      this.validationCallback
    );

    this.subscriptions.add(
      this.form.statusChanges.subscribe(status => {
        this.validationCallback();
      })
    );
  }

  validationCallback = () => {
    this.isValid.emit({
      formName: 'questionnaire',
      valid: !this.form.invalid,
      inputs: this.inputs,
      form: this.form
    });
  };
}
