import * as fromRoot from '../app.reducer';

import { ActivatedRoute, Router } from '@angular/router';
import {
  Component,
  EventEmitter,
  OnInit,
  Output
} from '@angular/core';
import {
  Observable,
  Subscription,
  combineLatest as observableCombineLatest
} from 'rxjs';
import {
  ProductsConfirmationModel,
  TicketModel
} from '../shared/services-with-reducers/tickets/ticket.interface';
import { debounceTime, filter } from 'rxjs/operators';

import { ExhibitionSettingModel } from '../shared/services-with-reducers/customization/customization.interfaces';
import { FormInputsPayloadModel } from '../shared/services-with-reducers/step-forms/step.interface';
import { StepsFormsService } from '../shared/services-with-reducers/step-forms/steps-forms.service';
import { Store } from '@ngrx/store';
import { ProductGroupModel } from '../shared/services-with-reducers/tickets/ticket.interface';
import { getLocalStorageString } from '../shared/app-utils';
import { AppConstants } from '../shared/app-constants';

@Component({
  selector: 'app-basket',
  templateUrl: './basket.component.html',
  styleUrls: ['./basket.component.scss']
})
export class BasketComponent implements OnInit {
  @Output()
  closeBasket = new EventEmitter<Boolean>();

  public ticketCount$: Observable<number>;
  public ungroupedTickets$: Observable<TicketModel>;
  public ticketTypes$: Observable<ProductGroupModel[]>;
  public ticketHolderInputSets$: Observable<FormInputsPayloadModel[]>;
  public ticketHolderInputSets: FormInputsPayloadModel[];
  public ticketsWithHolders: ProductsConfirmationModel[] = [];
  public totalCost = 0;
  public selectedEventId: number;
  public isNextButtonDisabled = true;
  public selectedStepName = '';
  public settings: ExhibitionSettingModel;
  public hasRegularTickets: boolean = false;
  public hasPackageTickets: boolean = false;
  public hasParkingTickets: boolean = false;
  public parsedParkingTickets: any;

  private subscriptions = new Subscription();

  constructor(
    private _store: Store<fromRoot.State>,
    private _router: Router,
    private _route: ActivatedRoute,
    private _stepsFormsService: StepsFormsService
  ) {}

  gotoTicketSelection() {
    this.closeBasket.emit(true);
    this._router.navigate([`/webshop/${this.selectedEventId}/tickets`]);
  }

  toNextStep() {
    this.closeBasket.emit(true);
    this._stepsFormsService.navigateToLastNotDisabledPage();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngOnInit() {
    this.ticketCount$ = this._store.select(fromRoot.getTicketsCount);
    this.ungroupedTickets$ = this._store.select(fromRoot.getTickets);
    this.ticketTypes$ = this._store.select(fromRoot.getTicketsTypes);
    this.ticketHolderInputSets$ = this._store.select(
      fromRoot.getTicketHolderInputSets
    );

    this.subscriptions.add(
      this._store
        .select(fromRoot.getExhibitionSettings)
        .pipe(filter(data => !!data))
        .subscribe(settings => {
          this.settings = settings;
        })
    );

    this.subscriptions.add(
      this._store.select(fromRoot.getSelectedStep).subscribe(stepName => {
        this.selectedStepName = stepName;
      })
    );

    this.subscriptions.add(
      this._store
        .select(fromRoot.getSelectedExhibitionId)
        .subscribe(eventId => {
          this.selectedEventId = eventId;
        })
    );

    this.subscriptions.add(
      observableCombineLatest(
        this._store.select(fromRoot.getStepsValidity),
        this._store.select(fromRoot.getSelectedStep)
      )
        .pipe(
          filter(data => !!data[0] && !!data[1]),
          debounceTime(100)
        )
        .subscribe(data => {
          const [validations, selectedStepKey] = data;
          this.isNextButtonDisabled = !validations[selectedStepKey].valid;
        })
    );

    this.subscriptions.add(
      observableCombineLatest(
        this.ungroupedTickets$,
        this.ticketTypes$,
        this.ticketHolderInputSets$
      )
        .pipe(
          filter(data => {
            return data[0] && data[1] && !!data[2];
          })
        )
        .subscribe(data => {
          const [ungroupedTickets, ticketTypes, ticketHolderInputSets] = data;

          this.ticketHolderInputSets = ticketHolderInputSets;
          this.ticketsWithHolders = this._stepsFormsService.assignTicketsToHolders(ungroupedTickets, ticketTypes, ticketHolderInputSets, null);

          this.hasRegularTickets = false;
          this.hasPackageTickets = false;

          Object.keys(ungroupedTickets).forEach(ungroupedTicket => {
            const singleProduct = ungroupedTickets[ungroupedTicket];
            if (singleProduct.classification === 'normal' && singleProduct.packageSettings === null && singleProduct.count > 0) {
              this.hasRegularTickets = true;
            }

            if (singleProduct.classification === 'normal' && singleProduct.packageSettings !== null && singleProduct.count > 0) {
              this.hasPackageTickets = true;
            }
          })

          const storedParkingTickets = getLocalStorageString(AppConstants.parkingTicketsReducer);

          const parkingTicketsDictionary =
            storedParkingTickets && JSON.parse(storedParkingTickets);

          this.parsedParkingTickets = Object.keys(
            parkingTicketsDictionary
          ).reduce(function(filtered, key) {
            if (parkingTicketsDictionary[key].hasOwnProperty('price')) {
              filtered[key] = parkingTicketsDictionary[key];
            }
            return filtered;
          }, {});

          this.hasParkingTickets =
            Object.keys(this.parsedParkingTickets).length > 0;

          const parkingTicketPrice = Object.keys(this.parsedParkingTickets)
            .reduce((accu, curr) => {
              const ticket = this.parsedParkingTickets[curr];

              if (ticket.price) {
                return accu + parseInt(ticket.price, 10);
              }
              return accu;
            }, 0)
            .toFixed(2);

          this._store.select(fromRoot.getTotalPrice).subscribe(price => {
            this.totalCost = (price * 100 + parseInt(parkingTicketPrice, 10) * 100) / 100;
          });
        })
    );
  }
}
