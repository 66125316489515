import {
  Directive,
  ElementRef,
  Input,
  HostListener,
  AfterViewInit,
  OnChanges
} from '@angular/core';

@Directive({
  selector: '[showPassword]'
})
export class ShowPasswordDirective implements AfterViewInit, OnChanges {
  @Input() showPassword: HTMLElement;

  private _shown = false;
  private firstLoad = true;

  constructor(private el: ElementRef) {}

  @HostListener('change') ngOnChanges() {
    this.toggleIfNecessary();
  }

  @HostListener('input') onInput() {
    this.toggleIfNecessary();
  }

  toggleIfNecessary() {
    if (!this.firstLoad) return;

    if (this.el.nativeElement.value.length > 0) {
      this._shown = true;
      this.firstLoad = false;
      this.toggle();
    }
  }

  ngAfterViewInit() {
    this.setup();
  }

  toggle() {
    this._shown = !this._shown;
    if (this._shown) {
      this.el.nativeElement.setAttribute('type', 'text');
    } else {
      this.el.nativeElement.setAttribute('type', 'password');
    }
  }

  setup() {
    this.showPassword.style.cursor = 'pointer';
    this.showPassword.addEventListener('click', () => {
      this.toggle();
    });
  }
}
