import { Actions, ActionTypes } from './press-news.actions';

import { PressNewsListModel } from './press-news.interface';
import { createSelector } from 'reselect';

export interface State {
  ids: number[];
  entities: { [id: number]: PressNewsListModel };
  selectedPressNewsId: number | null;
  pressNewsDetail: {};
}

export const initialState: State = {
  ids: [],
  entities: {},
  selectedPressNewsId: null,
  pressNewsDetail: {}
};

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.SELECT_PRESS_NEWS_ID:
      const pressNewsID = action.payload;

      if (state.selectedPressNewsId === pressNewsID) {
        return state;
      }

      return {
        ids: state.ids,
        entities: state.entities,
        selectedPressNewsId: pressNewsID,
        pressNewsDetail: state.pressNewsDetail
      };

    case ActionTypes.SET_PRESS_NEWS_DETAIL:
      const pressNewsDetail = action.payload;
      return {
        ids: state.ids,
        entities: state.entities,
        selectedPressNewsId: state.selectedPressNewsId,
        pressNewsDetail: pressNewsDetail
      };

    case ActionTypes.ADD_PRESS_NEWS_TO_LIST: {
      const pressNews = action.payload.pressReleases;
      if (pressNews) {
        const newPressNews = pressNews.filter(news => !state.entities[news.id]);

        const newPressNewsIds = newPressNews.map(news => news.id);
        const newPressNewsEntities = newPressNews.reduce(
          (
            entities: { [id: number]: PressNewsListModel },
            news: PressNewsListModel
          ) => {
            return Object.assign(entities, {
              [news.id]: news
            });
          },
          {}
        );

        return {
          ids: [...state.ids, ...newPressNewsIds],
          entities: Object.assign({}, state.entities, newPressNewsEntities),
          selectedPressNewsId: state.selectedPressNewsId,
          pressNewsDetail: state.pressNewsDetail
        };
      } else {
        return state;
      }
    }

    default: {
      return state;
    }
  }
}

export const getEntities = (state: State) => state.entities;
export const getIds = (state: State) => state.ids;
export const getSelectedPressNewsId = (state: State) =>
  state.selectedPressNewsId;
export const getPressNewsDetail = (state: State) => state.pressNewsDetail;

export const getAll = createSelector(getEntities, getIds, (entities, ids) => {
  return ids.map(id => entities[id]);
});
