<div *ngIf="billingAddressList?.length">
  <div class="input-wrapper">
    <select
      class="select-address"
      (change)="onChange($event)"
      [(ngModel)]="selectedAddressId"
    >
      <option
        *ngFor="let address of billingAddressList; let i = index"
        [value]="i"
        [selected]="address.id === selectedAddressId"
      >
        {{ address.city }} - {{ address.street }} - {{ address.zipCode }}
      </option>
    </select>
  </div>
  <form
    *ngIf="form && inputs?.length"
    [formGroup]="form"
    [ngClass]="{ readonly: !isEditEnabled }"
    class="row"
  >
    <app-df-input
      *ngFor="let input of inputs"
      [input]="input"
      [class]="input.cssClass"
      [inputSet]="inputs"
      [form]="form"
      [action]="editAccountAction"
      [saveFormCallback]="formSaveCallback"
    ></app-df-input>
  </form>

  <div class="row controls-row" *ngIf="isEditEnabled">
    <div class="col-md-4 col-sm-6">
      <button class="button" (click)="deleteBillingAddress(selectedAddressId)">
        <span data-translation="profile.delete-billing-address">{{
          'profile.delete-billing-address' | translate | hideTranslation
        }}</span>
      </button>
    </div>
    <div class="col-md-4 col-sm-6">
      <button
        class="button"
        (click)="saveBillingAddress(selectedAddressId)"
        [disabled]="!form.valid"
      >
        <span data-translation="profile.save-billing-address">{{
          'profile.save-billing-address' | translate | hideTranslation
        }}</span>
      </button>
    </div>
    <div class="col-md-4 col-sm-6">
      <button class="button" (click)="cancelEdit()">
        <span data-translation="profile.cancel-billing-address">{{
          'profile.cancel-billing-address' | translate | hideTranslation
        }}</span>
      </button>
    </div>
  </div>
  <div class="row controls-row" *ngIf="!isEditEnabled">
    <div class="col-md-8"></div>
    <div class="col-md-4">
      <button class="button" (click)="beginEdit()">
        <span data-translation="profile.edit-billing-address">{{
          'profile.edit-billing-address' | translate | hideTranslation
        }}</span>
      </button>
    </div>
  </div>
</div>
