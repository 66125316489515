import { AfterViewInit, Component, ElementRef } from '@angular/core';

import { HelperService } from '../../shared/services-with-reducers/helpers/helper.service';

@Component({
  moduleId: module.id,
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements AfterViewInit {
  public year = { year: new Date().getFullYear() };
  public appVersion: string;

  constructor(private _helperService: HelperService, private el: ElementRef) {
    const appVersionEl: HTMLElement = document.getElementById('app-version');

    if (!!appVersionEl && !!appVersionEl.attributes['app-version']) {
      const appVersion: string = appVersionEl.attributes['app-version'].value;
      
      if (!!appVersion && appVersion.toLowerCase().startsWith('v')) {
        this.appVersion = appVersion;
      }
    }
  }

  ngAfterViewInit() {
    const links = this.el.nativeElement.querySelectorAll('a');
    if (links.length) {
      for (let i = 0; i < links.length; i++) {
        // because of IE doesnt know what is forEach
        links[i].addEventListener('click', e => {
          e.preventDefault();
          this._helperService.openIframe(e.target.href);
        });
      }
      // links.forEach(link => {
      //   link.addEventListener('click', e => {
      //     e.preventDefault();
      //     this._helperService.openIframe(e.target.href);
      //   });
      // });
    }
  }
}
