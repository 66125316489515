<div
    class="cookie-popup"
    *ngIf="!hideDataProtectionOnHomePage && !eventId"
>
    <div
        data-translation="cookies.popup.text"
        class="info-text"
        [innerHTML]="textWithLink"
        (click)="clickText($event)"
    ></div>

    <button class="close" (click)="close()">
        <span data-translation="cookies.popup.agree">{{
            'cookies.popup.agree' | translate
        }}</span>
    </button>
</div>

<div
    class="cookie-popup"
    *ngIf="!hideDataProtectionOnEventPage && eventId"
>
    <div
        data-translation="cookies.popup.text"
        class="info-text"
        [innerHTML]="textWithLink"
        (click)="clickText($event)"
    ></div>

    <button class="close" (click)="close()">
        <span data-translation="cookies.popup.agree">{{
            'cookies.popup.agree' | translate
        }}</span>
    </button>
</div>
