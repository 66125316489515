/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./walk-thru-info.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/skeleton-loader/skeleton-loader.component.ngfactory";
import * as i3 from "../../../shared/skeleton-loader/skeleton-loader.component";
import * as i4 from "../../../shared/directives/rich-text/rich-text.directive";
import * as i5 from "@ngx-translate/core";
import * as i6 from "@angular/common";
import * as i7 from "../../../shared/pipes/hide-translation/hide-translation.pipe";
import * as i8 from "./walk-thru-info.component";
import * as i9 from "@ngrx/store";
var styles_WalkThruInfoComponent = [i0.styles];
var RenderType_WalkThruInfoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WalkThruInfoComponent, data: {} });
export { RenderType_WalkThruInfoComponent as RenderType_WalkThruInfoComponent };
function View_WalkThruInfoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-skeleton-loader", [], null, null, null, i2.View_SkeletonLoaderComponent_0, i2.RenderType_SkeletonLoaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.SkeletonLoaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_WalkThruInfoComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "h4", [["class", "title"], ["data-translation", "step-walktrough.title"], ["data-translation-includes-children", ""]], null, null, null, null, null)), i1.ɵdid(1, 540672, null, 0, i4.RichTextDirective, [i1.Renderer2, i1.ElementRef], { value: [0, "value"] }, null), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(3, 1)], function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("step-walktrough.title")))); _ck(_v, 1, 0, currVal_0); }, null); }
function View_WalkThruInfoComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-skeleton-loader", [], null, null, null, i2.View_SkeletonLoaderComponent_0, i2.RenderType_SkeletonLoaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.SkeletonLoaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_WalkThruInfoComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "black"], ["data-translation-includes-children", ""]], [[1, "data-translation", 0]], null, null, null, null)), i1.ɵdid(1, 540672, null, 0, i4.RichTextDirective, [i1.Renderer2, i1.ElementRef], { value: [0, "value"] }, null), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(3, 1)], function (_ck, _v) { var currVal_1 = (((_v.parent.context.index + 1) + ". ") + i1.ɵunv(_v, 1, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent, 0), i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(("step-walktrough." + _v.parent.context.$implicit)))))); _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = ("step-walktrough." + _v.parent.context.$implicit); _ck(_v, 0, 0, currVal_0); }); }
function View_WalkThruInfoComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "span", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WalkThruInfoComponent_4)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 3, null, View_WalkThruInfoComponent_5)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(6, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.translationsLoaded; _ck(_v, 2, 0, currVal_0); var currVal_1 = (!!i1.ɵunv(_v, 4, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent, 0), i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(("step-walktrough." + _v.context.$implicit))))) && _co.translationsLoaded); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_WalkThruInfoComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i7.HideTranslationPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WalkThruInfoComponent_1)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WalkThruInfoComponent_2)), i1.ɵdid(5, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WalkThruInfoComponent_3)), i1.ɵdid(8, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.translationsLoaded; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.translationsLoaded; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.shopingStepList; _ck(_v, 8, 0, currVal_2); }, null); }
export function View_WalkThruInfoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-walk-thru-info", [], null, null, null, View_WalkThruInfoComponent_0, RenderType_WalkThruInfoComponent)), i1.ɵdid(1, 245760, null, 0, i8.WalkThruInfoComponent, [i9.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WalkThruInfoComponentNgFactory = i1.ɵccf("app-walk-thru-info", i8.WalkThruInfoComponent, View_WalkThruInfoComponent_Host_0, {}, {}, []);
export { WalkThruInfoComponentNgFactory as WalkThruInfoComponentNgFactory };
