import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';

import * as fromRoot from '../../app.reducer';

function getState(store) {
  let state;

  store.select(fromRoot.isLoggedInAsAdmin).subscribe(s => (state = s));
  return state;
}

@Injectable()
export class LoginGuardAdmin implements CanActivate {
  constructor(private _router: Router, private _store: Store<fromRoot.State>) {}

  canActivate(): boolean {
    const isAdmin = getState(this._store);

    if (isAdmin) {
      return isAdmin;
    }

    this._router.navigate(['/']);
    return false;
  }
}
