import * as customizationActions from '../../shared/services-with-reducers/customization/customization.actions';
import * as exhibitionActions from '../../shared/services-with-reducers/exhibition/exhibition.actions';
import * as fromRoot from '../../app.reducer';
import * as helperActions from '../../shared/services-with-reducers/helpers/helper.actions';
import * as stepsActions from '../../shared/services-with-reducers/step-forms/steps-forms.actions';
import { ActivatedRoute } from '@angular/router';
import { OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { Subscription, combineLatest as observableCombineLatest } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { AppService } from '../../app.service';
import { CustomizationService } from '../../shared/services-with-reducers/customization/customization.service';
import { select, Store } from '@ngrx/store';
var HomeComponent = /** @class */ (function () {
    function HomeComponent(_store, _customizationService, _appService, route, cdr) {
        this._store = _store;
        this._customizationService = _customizationService;
        this._appService = _appService;
        this.route = route;
        this.cdr = cdr;
        this.localizedImages = null;
        this.loaded = false;
        this._subscriptions = new Subscription();
        this.exhibitionId$ = this._store.select(fromRoot.getSelectedExhibitionId);
        this.exhibitionLanguage$ = this._store.select(fromRoot.getLanguage);
    }
    HomeComponent.prototype.ngOnInit = function () {
        //?Ev=80&Lng=de&Code=BMM18T3st4Us!
        var _this = this;
        this._subscriptions.add(this.route.queryParams.subscribe(function (params) {
            var newUrl = '/webshop/';
            var optionalParams = [];
            if (params.Ev) {
                //https://expo-demo.teamaxess.com/webshop/200/tickets?voucher=AXGS95
                newUrl += params.Ev + "/tickets";
                if (params.Code) {
                    optionalParams.push("voucher=" + params.Code);
                }
                if (params.Lng) {
                    optionalParams.push("lang=" + params.Lng);
                }
                if (optionalParams.length) {
                    newUrl += "?" + optionalParams.join('&');
                }
                window.location.href = window.location.origin + newUrl;
            }
        }));
        this.isLoggedInAsAdmin$ = this._store.select(fromRoot.isLoggedInAsAdmin);
        // RESET reducers
        this._subscriptions.add(this._appService.resetReducers().subscribe(function () {
            _this.loaded = true;
            _this.cdr.detectChanges();
        }));
        // load new settings
        this._subscriptions.add(this._store
            .select(fromRoot.getAllExhibitionsCollection)
            .subscribe(function (data) {
            _this.exhibitions = data.sort(function (a, b) {
                var aStartDate = a.startDate;
                var bStartDate = b.startDate;
                return aStartDate.getTime() - bStartDate.getTime(); // date to timestamp
            });
        }));
        //removing custom scripts from an event
        var scripts = document.getElementById('generic-script');
        var allPageScripts = document.getElementById('generic-script-all-page');
        if (!!scripts) {
            scripts.remove();
        }
        if (!!allPageScripts) {
            allPageScripts.remove();
        }
        // load new events list
        this._subscriptions.add(this._store
            .select(fromRoot.getLanguage)
            .pipe(filter(function (lang) { return !!lang; }))
            .subscribe(function (lang) {
            _this._store.dispatch(new exhibitionActions.GetListOfAllExhibitions(lang));
        }));
        //if url route parameter 'sr' is true we enable the selfregistration (home url ending with '?sr=true')
        // we can disable the self registration with route parameter false or just home url without parameter
        this._subscriptions.add(this.route.queryParams.subscribe(function (params) {
            if (params.sr && params.sr === 'true') {
                _this._store.dispatch(new helperActions.SetSelfRegistration(true));
            }
            else if (!params.sr || params.sr === 'false') {
                _this._store.dispatch(new helperActions.SetSelfRegistration(false));
            }
        }));
        //if we come to home page we reset information about event series page
        this._store.dispatch(new helperActions.SetEventSeriesPage(null));
        this._store
            .select(fromRoot.getSelfRegistration)
            .pipe(first())
            .subscribe(function (selfRegistration) {
            _this.setStepsVisibility(!selfRegistration);
        });
        // set default red environment
        this._customizationService.setColors('red');
        this._subscriptions.add(observableCombineLatest([
            this.exhibitionId$,
            this.exhibitionLanguage$
        ])
            .pipe(filter(function (_a) {
            var exhibitionId = _a[0], exhibitionLanguage = _a[1];
            return !!exhibitionLanguage;
        }))
            .subscribe(function (_a) {
            var exhibitionId = _a[0], exhibitionLanguage = _a[1];
            var exhibitionOrOperatorId = exhibitionId ? exhibitionId : -1;
            // effects are no more triggered in this loop so call it in next one
            setTimeout(function () {
                _this._store.dispatch(new customizationActions.GetLocalizedImages({
                    eventId: Number(exhibitionOrOperatorId),
                    countryCode: exhibitionLanguage
                }));
                _this._store.dispatch(new exhibitionActions.GetTitles(exhibitionOrOperatorId));
                _this._store.dispatch(new exhibitionActions.GetProfessions(exhibitionOrOperatorId));
                _this._store.dispatch(new exhibitionActions.GetDepartments(exhibitionOrOperatorId));
                _this._store.dispatch(new exhibitionActions.GetOccupationalGroups(exhibitionOrOperatorId));
                _this.localizedImages$ = _this._store.select(fromRoot.getLocalizedImages);
                _this._subscriptions.add(_this.localizedImages$
                    .pipe(filter(function (images) { return !!images; }))
                    .subscribe(function (images) {
                    _this.localizedImages = images;
                }));
            });
        }));
        this._store.pipe(select(fromRoot.getOperatorsSettings), first(function (operatorSettings) { return !!operatorSettings; })).subscribe(function (operatorSettings) {
            _this.operatorSettings = operatorSettings;
        });
        setTimeout(function () {
            _this.cdr.detectChanges();
        }, 0);
    };
    HomeComponent.prototype.ngOnDestroy = function () {
        this._subscriptions.unsubscribe();
    };
    HomeComponent.prototype.setStepsVisibility = function (isVisible) {
        var stepsArray = ['legitimation', 'confirmation', 'payment', 'invoice'];
        var visibilityPayload = stepsArray.map(function (step) {
            return {
                stepKey: step,
                visible: isVisible
            };
        });
        visibilityPayload.push({ stepKey: 'recipe', visible: !isVisible });
        console.log(visibilityPayload);
        this._store.dispatch(new stepsActions.SetStepsVisibility(visibilityPayload));
    };
    return HomeComponent;
}());
export { HomeComponent };
