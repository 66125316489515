import * as legitimationActions from './legitimation.actions';

import { Actions, Effect, ofType } from '@ngrx/effects';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import {
  LegitimationFaxInfoModel,
  LegitimationListModel,
  LegitimationPostModel,
  LegitimationStatusModel
} from './legitimation.interface';
import { Observable, EMPTY as empty, of } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';

import { Action } from '@ngrx/store';
import { LegitimationService } from './legitimation.service';

export const LEGITIMATION_DEBOUNCE = new InjectionToken<number>(
  'Legitimation Debounce'
);

@Injectable()
export class LegitimationEffect {
  @Effect()
  loadLegitimationList$: Observable<Action> = this.actions$.pipe(
    ofType<legitimationActions.GetLegitimationList>(
      legitimationActions.ActionTypes.GET_LEGITIMATION_LIST
    ),
    debounceTime(this.debounce),
    switchMap((data: any) => {
      const userId = data.payload;
      return this.legitimationService.getLegitimationList().pipe(
        map(
          (legitimationList: LegitimationListModel[]) =>
            new legitimationActions.AddLegitimationToList(legitimationList)
        ),
        catchError(() => of(new legitimationActions.AddLegitimationToList([])))
      );
    })
  );

  @Effect()
  loadLegitimationStatus$: Observable<Action> = this.actions$.pipe(
    ofType<legitimationActions.GetLegitimationStatus>(
      legitimationActions.ActionTypes.GET_LEGITIMATION_STATUS
    ),
    debounceTime(this.debounce),
    switchMap((data: any) => {
      const { userId, eventId } = data.payload;

      if (eventId === '' || userId === '') {
        return Observable.empty();
      }

      return this.legitimationService
        .getLegitimationStatus(eventId)
        .pipe(
          map(
            (legitimationStatus: LegitimationStatusModel) =>
              new legitimationActions.SetLegitimationStatus(legitimationStatus)
          ),
          catchError(() =>
            of(
              new legitimationActions.SetLegitimationStatus({
                status: 'otherStatus'
              })
            )
          )
        );
    })
  );

  @Effect()
  loadLegitimationFaxId$: Observable<Action> = this.actions$.pipe(
    ofType<legitimationActions.GetLegitimationFaxId>(
      legitimationActions.ActionTypes.GET_LEGITIMATION_FAX_ID
    ),
    debounceTime(this.debounce),
    switchMap((data: any) => {
      // { eventId: number; userId: number }
      const { eventId, userId } = data.payload;
      if (eventId === null) {
        return Observable.empty();
      }

      return this.legitimationService
        .getLegitimationFaxId(eventId, userId)
        .pipe(
          map(
            (legitimationFaxInfo: LegitimationFaxInfoModel) =>
              new legitimationActions.SetLegitimationFaxId(
                legitimationFaxInfo.faxId
              )
          ),
          catchError(() =>
            of(new legitimationActions.SetLegitimationFaxId(null))
          )
        );
    })
  );

  @Effect()
  postLegitimationInfo$: Observable<Action> = this.actions$.pipe(
    ofType<legitimationActions.PostLegitimationInfo>(
      legitimationActions.ActionTypes.POST_LEGITIMATION_INFO
    ),
    debounceTime(this.debounce),
    switchMap((data: any) => {
      if (!data.payload) {
        return Observable.empty();
      }

      const { feed, userId, eventId } = data.payload;

      return this.legitimationService
        .saveLegitimationInfo(feed, userId, eventId)
        .pipe(
          map(
            (LegitimationData: LegitimationPostModel) =>
              new legitimationActions.SetLegitimationInfoResponse(true)
          ),
          catchError(() =>
            of(new legitimationActions.SetLegitimationInfoResponse(false))
          )
        );
    })
  );

  constructor(
    private actions$: Actions,
    private legitimationService: LegitimationService,
    @Optional()
    @Inject(LEGITIMATION_DEBOUNCE)
    private debounce: number = 50
  ) {}
}
