import { of as observableOf, from as observableFrom, Subject, BehaviorSubject, Observable } from 'rxjs';

import { mergeMap, map, catchError, debounceTime, first } from 'rxjs/operators';

import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';

import { HelperService } from '../services-with-reducers/helpers/helper.service';
import { Injectable } from '@angular/core';
import { RegexPatterns } from './validation-patterns.const';
import {
  PhoneValidationErrors,
  EmailValidationErrors,
  ZipValidationErrors,
  ValueComparsionValidationErrors,
  RequiredValidationError
} from './validation-errors.model';
import { select, Store } from '@ngrx/store';
import * as fromRoot from '../../app.reducer';
import * as helperActions from '../../shared/services-with-reducers/helpers/helper.actions';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {
  private zipLast: {
    value: any;
    country: any;
    validation: ZipValidationErrors;
    formPath: string;
    zipCodeCities: string[]
  } = {
    value: null,
    country: null,
    validation: null,
    formPath: null,
    zipCodeCities: []
  };
  private zipValidationSubject = new Subject();
  private zipValidationPromise: Promise<ZipValidationErrors>;
  private formCountryInputChanged: boolean = false;
  public formCountryInputChangedBH$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public formCountryInputChanged$: Observable<string> = this.formCountryInputChangedBH$.asObservable();

  constructor(private _helperService: HelperService, private _store: Store<fromRoot.State>) {
    this.resetZipValidationPromise();
  }

  static emailValidator(isRequired: boolean): ValidatorFn {
    return (control: AbstractControl): EmailValidationErrors => {
      const controlVal = control.value;

      return this.emailValidatorFn(controlVal, isRequired);
    };
  }

  static emailValidatorFn(
    controlVal: string,
    isRequired: boolean
  ): EmailValidationErrors {
    if (!controlVal) {
      return null;
    }

    const emailCanContainRegex = new RegExp('^(' + RegexPatterns.email + ')*$');
    // additional invalid characters
    const emailCannotContainRegex = new RegExp('[ßæ¥þ‰¼><=]+');
    const umlautRegex = new RegExp('[äöüëïöüÿÄÖÜËÏÖÜŸ]+');
    const matchPattern = controlVal.match(emailCanContainRegex);
    const containsAdditional = emailCannotContainRegex.test(controlVal);
    const nonAsciiChars = new RegExp('[^\x00-\x7F]+').test(controlVal);

    let validationError: EmailValidationErrors = null;

    if (umlautRegex.test(controlVal)) {
      validationError = { umlautCharacter: true };
    } else if (!matchPattern || containsAdditional || controlVal.length > 100 || nonAsciiChars) {
      validationError = { invalidEmailAddress: true };
    } else if (matchPattern) {
      validationError = null;
    } else if (!controlVal && !isRequired) {
      validationError = null;
    }

    return validationError;
  }

  static phoneValidator(): ValidatorFn {
    return (control: AbstractControl): PhoneValidationErrors => {
      const value = control.value;

      return this.phoneValidatorFn(value);
    };
  }

  static phoneValidatorFn(value: string | number): PhoneValidationErrors {
    const testMinSix = new RegExp(RegexPatterns.phoneLength);
    const valueAsString = value && value.toString();

    let validationError: PhoneValidationErrors = { invalidPhoneNumber: true };
    // const testSpaces = new RegExp(RegexPatterns.phoneSpaces);

    // it need to be either empty or fit to patern
    if (
      !value ||
      testMinSix.test(valueAsString.replace(/\s/g, '')) //&& testSpaces.test(value))
    ) {
      validationError = null;
    }

    return validationError;
  }

  static checkboxGroupValidator(form: FormGroup) {
    const isAnyChecked = Object.keys(form.controls)
      .map(function(key) {
        return form.controls[key].value;
      })
      .reduce((aggr, current) => {
        return aggr || current;
      }, false);

    return isAnyChecked ? null : { noneChecked: true };
  }

  static equalValueValidator(
    controlNameOne: string,
    controlNameTwo: string,
    caseInSensitive: boolean
  ): ValidatorFn {
    return (group: FormGroup): ValueComparsionValidationErrors => {
      const controlOne = group.controls[controlNameOne];
      const controlTwo = group.controls[controlNameTwo];

      let isMatching: boolean;
      let validationError: ValueComparsionValidationErrors = null;

      if (!!controlOne && !!controlTwo && controlOne.value && controlTwo.value) {
        // only validate if both inputs have value
        if (caseInSensitive) {
          isMatching =
            controlOne.value.toLowerCase() === controlTwo.value.toLowerCase();
        } else {
          isMatching = controlOne.value === controlTwo.value;
        }

        // se the propper messages for the two controls
        if (!isMatching && controlOne.valid && controlTwo.valid) {
          const message = `${controlNameOne} != ${controlNameTwo}`;

          controlTwo.setErrors({ equalValue: controlNameTwo });

          validationError = { equalValue: message };
        }
        if (isMatching && controlTwo.hasError('equalValue')) {
          controlTwo.setErrors(null);
        }
      }

      return validationError;
    };
  }

  static compareValidator(
    controllNameOne: string,
    controllNameTwo: string
  ): ValidatorFn {
    return (control: AbstractControl): ValueComparsionValidationErrors => {
      let result: ValueComparsionValidationErrors = null;

      if (control.parent) {
        const newValue = control.parent.controls[controllNameOne].value;
        const repeatValue = control.parent.controls[controllNameTwo].value;

        if (newValue !== repeatValue) {
          result = { different: true };
        }

        if (newValue === '' || repeatValue === '') {
          result = null;
        }
      } else {
        result = { different: true };
      }
      return result;
    };
  }

  resetZipValidationPromise() {
    this.zipValidationPromise = this.zipValidationSubject
      .pipe(
        debounceTime(500),
        mergeMap(
          (data: { selectedCountryCode: string; inputValue: string, formPath?: string, isCountryChange?: boolean }) => {
            const { selectedCountryCode, inputValue, formPath, isCountryChange } = data;

            let wasDataFoundInStore: boolean = false;
            // find if we already have data with same parameters in store
            this._store.pipe(select(fromRoot.getAllCitiesByZipCode), first()).subscribe(zipCodeCities => {
              if (!!zipCodeCities) {
                // set wasDataFoundInStore if same values were found
                wasDataFoundInStore = zipCodeCities.some(zipCodeCity => {
                  if (selectedCountryCode === zipCodeCity.countryCode && inputValue.length !== zipCodeCity.zipCode.length && zipCodeCity.zipCode !== "" && !zipCodeCity.isZipCodeInvalid && zipCodeCity.zipCodeHasValidation) {
                    this.zipLast.validation = { invalidZipCode: true };
                    this._store.dispatch(new helperActions.SetCitiesWithSameZipcode({ 
                      cities: [],
                      zipCode: inputValue,
                      countryCode: selectedCountryCode,
                      formPath: formPath,
                      isZipCodeInvalid: true,
                      zipCodeHasValidation: zipCodeCity.zipCodeHasValidation
                    }));
                    return true;
                  } 
                  // if we already have saved results with same zipcode and countrycode set zipLast values
                  if (!!zipCodeCity.countryCode && zipCodeCity.zipCode === inputValue && zipCodeCity.countryCode === selectedCountryCode) {
                    this.zipLast.value = inputValue;
                    this.zipLast.country = selectedCountryCode;
                    this.zipLast.zipCodeCities = zipCodeCity.cities;
                    this.zipLast.formPath = '';
                    this.zipLast.validation = zipCodeCity.isZipCodeInvalid ? { invalidZipCode: true } : null;
                    
                    // set values for current form
                    this._store.dispatch(new helperActions.SetCitiesWithSameZipcode({
                      cities: zipCodeCity.cities,
                      zipCode: inputValue,
                      countryCode: selectedCountryCode,
                      formPath: formPath,
                      isZipCodeInvalid: zipCodeCity.isZipCodeInvalid,
                      zipCodeHasValidation: zipCodeCity.zipCodeHasValidation
                    }));
                    
                    // if data was found return true and exit loop
                    return true; 
                  }
                });
              }
            });
            // if data was found, we don't have to check our database for data because we already have it in store
            // Set zipLast.validation to null and return empty observable
            if (wasDataFoundInStore) {
              if (isCountryChange) {
                this.formCountryInputChanged = false;
                this.formCountryInputChangedBH$.next(formPath);
              }
              return observableOf(this.zipLast.validation);
            }

            return this._helperService
              .checkZipcodeValidity(selectedCountryCode, inputValue)
              .pipe(
                map(response => {
                  if (!!response.zipCodeCities && response.zipCodeCities.length > 0) {
                    this._store.dispatch(new helperActions.SetCitiesWithSameZipcode({
                      cities: response.zipCodeCities,
                      zipCode: inputValue,
                      countryCode: selectedCountryCode,
                      formPath: formPath,
                      isZipCodeInvalid: response.isValidZipCode ? false : true,
                      zipCodeHasValidation: response.zipCodeHasValidation
                    }));
                  } else {
                    this._store.dispatch(new helperActions.SetCitiesWithSameZipcode({ 
                      cities: [],
                      zipCode: inputValue,
                      countryCode: selectedCountryCode,
                      formPath: formPath,
                      isZipCodeInvalid: response.isValidZipCode ? false : true,
                      zipCodeHasValidation: response.zipCodeHasValidation 
                    }));
                  }
                  if (response.isValidZipCode) {
                    this.zipLast.validation = null;
                  } else {
                    this.zipLast.validation = { invalidZipCode: true };
                  }

                  this.zipLast.value = inputValue;
                  this.zipLast.country = selectedCountryCode;
                  this.zipLast.formPath = formPath;
                  this.zipLast.zipCodeCities = response.zipCodeCities || [];
                  if (isCountryChange) {
                    this.formCountryInputChanged = false;
                    this.formCountryInputChangedBH$.next(formPath);
                  }
                  return this.zipLast.validation;
                }),
                catchError((error: Response) => {
                  console.error(error);
                  return observableFrom([{ invalidZipCode: true }]);
                })
              );
          }
        ),
        first()
      )
      .toPromise();

    this.zipValidationPromise.then(data => {
      this.resetZipValidationPromise();
    });
  }

  zipCodeValidator(
    selectedCountryCode: string,
    isRequired: boolean,
    formPath?: string
  ): ValidatorFn {
    return (control: AbstractControl): Promise<ZipValidationErrors> => {
      const value: string = control.value;

      return this.zipCodeValidatorFn(value, selectedCountryCode, isRequired, formPath);
    };
  }

  zipCodeValidatorFn(
    value: string,
    selectedCountryCode: string,
    isRequired: boolean,
    formPath?: string, 
    isCountryChange?: boolean
  ): Promise<ZipValidationErrors> {
    let validationError: Promise<{
      invalidZipCode: boolean;
    } | null> = observableOf(null).toPromise();

    if (!!isCountryChange) {
      this.formCountryInputChanged = true;
    }
    if (selectedCountryCode) {
      if (
        this.zipLast &&
        this.zipLast.value === value &&
        this.zipLast.country === selectedCountryCode && 
        (!!formPath ? this.zipLast.formPath === formPath : true)
      ) {
       validationError = observableOf(this.zipLast.validation).toPromise();
      }
      this.zipValidationSubject.next({
        selectedCountryCode,
        inputValue: value,
        formPath: formPath,
        isCountryChange: this.formCountryInputChanged ? true : false
      });

      validationError = this.zipValidationPromise;
    } else {
      this.zipLast.validation = null;
    }

    return validationError;
  }

  static emptySpacesValidator(): ValidatorFn {
    return (control: AbstractControl): RequiredValidationError => {
      return this.emptySpacesValidatorFn(control.value);
    };
  }

  static emptySpacesValidatorFn(value: string): RequiredValidationError {
    const noSpaceRegex = new RegExp('^\\s+$');

    let validationError: RequiredValidationError = null;

    if (noSpaceRegex.test(value)) {
      validationError = { required: true };
    }

    return validationError;
  }
}
