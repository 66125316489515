import { filter, first } from 'rxjs/operators';
import * as fromRoot from '../app.reducer';
import * as userActions from '../shared/services-with-reducers/user/user.actions';

import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import {
  Component,
  Input,
  OnDestroy,
  OnInit} from '@angular/core';
import {
  LoginModel,
  UserModel,
  UserProfileModel
} from '../shared/services-with-reducers/user/user.interface';
import { Observable, Subscription } from 'rxjs/Rx';

import { ActivatedRoute, Params, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../login/login.service';
import { UserService } from '../shared/services-with-reducers/user/user.service';
import { environment } from '../../environments/environment';
import { HelperService } from '../shared/services-with-reducers/helpers/helper.service';

@Component({
  moduleId: module.id,
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnDestroy, OnInit {
  @Input()
  validationStepName: Array<string>;
  @Input()
  activeTab: 'login' | 'registration';

  loginForm: FormGroup;
  email: AbstractControl;
  password: AbstractControl;
  public user$: Observable<UserModel>;
  public tabs: Array<any> = [];
  public availableSocialLogins: Array<string> = [];
  private _subscriptions = new Subscription();
  public userUrlFormData: object;

  constructor(
    private _formBuilder: FormBuilder,
    private _router: Router,
    private _store: Store<fromRoot.State>,
    private _translateService: TranslateService,
    private _loginService: LoginService,
    private _userService: UserService,
    private _helperService: HelperService,
    public route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.loginForm = this._formBuilder.group({
      email: ['', Validators.minLength(3)],
      password: ['', Validators.minLength(1)]
    });

    this._userService
      .getListOfSocialLogins()
      .pipe(
        filter(data => !!data),
        first()
      )
      .subscribe((socialLogins: Array<string>) => {
        this.availableSocialLogins = socialLogins;
      });

    this.email = this.loginForm.controls['email'];
    this.password = this.loginForm.controls['password'];

    this._subscriptions.add(
      this.route.queryParams.subscribe(params => {
        const loginUserKey: string = Object.keys(params).find(key => key.toLowerCase() === 'login_user');
        
        if (loginUserKey) {
          this.email.setValue(params[loginUserKey]);
        } else if (Object.keys(params).some(key => key.toLowerCase() === 'reg_mail')) {
          this.createUserInfoFromUrlData(params);
        }
      })
    );

    this._subscriptions.add(
      this._store.pipe(select(fromRoot.getTranslations)).subscribe(() => {
        this._translateService
          .stream([
            `login.tabs.login`,
            `login.tabs.login.text`,
            `login.tabs.register`,
            `login.tabs.register.text`
          ])
          .subscribe(translations => {
            this.tabs = [
              {
                id: 'login',
                title: translations[`login.tabs.login`],
                text: translations[`login.tabs.login.text`]
              },
              {
                id: 'registration',
                title: translations[`login.tabs.register`],
                text: translations[`login.tabs.register.text`]
              }
            ];
          });
      })
    );

    this._store
      .select(fromRoot.isContinueAsGuest)
      .pipe(first())
      .subscribe(isContinueAsGuest => {
        if (
          isContinueAsGuest &&
          ((this.validationStepName &&
            (this.validationStepName[0] === 'personal' &&
              this.validationStepName[1] === 'registration')) ||
            (this.validationStepName &&
              this.validationStepName[0] === 'tickets' &&
              this.validationStepName[1] === 'registration'))
        ) {
          this.activeTab = 'registration';
        } else if (!this.userUrlFormData) {
          this.activeTab = 'login';
        }
      });

    this._subscriptions.add(
      this._loginService.activeTab$.subscribe(tab => {
        this.activeTab = tab;
      })
    );

    this.setEmailFromBuyerInfo();
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  onTabChange(value) {
    this.activeTab = value;
  }

  onSubmit(values) {
    let { email, password } = values;
    email = email.toLowerCase();
    const credentials: LoginModel = { email, password };
    this._store.dispatch(new userActions.LogIn(credentials));
    this.afterLogin();
  }

  setEmailFromBuyerInfo() {
    this._subscriptions.add(
      this._store
        .select(fromRoot.getBuyerInfoFromURL)
        .pipe(
          filter(parameters => !!parameters),
          first()
        )
        .subscribe(parameters => {
          Object.keys(parameters).forEach(parameter => {
            if (
              parameters[parameter].key === 'email' &&
              parameters[parameter].value
            ) {
              this.email.setValue(parameters[parameter].value);
              return;
            }
          });
        })
    );
  }

  afterLogin() {
    this._store
      .select(fromRoot.getProfile)
      .pipe(
        filter(profile => !!profile),
        first()
      )
      .subscribe((profile: UserProfileModel) => {
        if (profile) {
          this._helperService.redirectAfterLogin();
        }
      });
  }

  loginWith(socialNetwork) {
    let domain = environment.protocol + environment.origin;
    domain = domain || window.location.origin;

    window.open(
      `${environment.protocol}${environment.webApiUrl}/user/external-login?provider=${socialNetwork}&returnUrl=${domain}`,
      //`http://localhost:3000/api/user/external-login?provider=facebook&returnUrl=http%3A//localhost%3A4200`,
      'Social Login',
      'width=500,height=500'
    );
    // Create IE + others compatible event handler
    const eventMethod = window.addEventListener
      ? 'addEventListener'
      : 'attachEvent';
    const messageEvent =
      eventMethod === 'attachEvent' ? 'onmessage' : 'message';
    // Listen to message from child window
    window[eventMethod](
      messageEvent,
      e => {
        // Check if origin is proper
        if (e.origin !== domain) {
          return;
        } else if ('authToken' in e.data && 'id' in e.data) {
          const user = e.data;
          this._store.dispatch(new userActions.GetProfile(user.id));
          this._store.dispatch(new userActions.SetLoginTimestamp(Date.now()));
          this._store.dispatch(new userActions.SetUser(user));

          if (e.data.hasOwnProperty('firstLogin') && e.data.firstLogin) {
            this._store
              .pipe(
                select(fromRoot.getProfile),
                filter(profile => !!profile),
                first()
              )
              .subscribe(() => {
                this._router.navigate([`/profile`]);
              });
          } else {
            this.afterLogin();
          }
        }
      },
      false
    );
  }
  
  createUserInfoFromUrlData(params: Params) {
    this.userUrlFormData = {};

    Object.keys(params).forEach(key => {
      const keyWithLowerCase: string = key.toLowerCase();
      
      if (keyWithLowerCase.includes('reg_')) {
        let urlParamKey: string;

        switch (keyWithLowerCase) {
          case 'reg_mail':
            urlParamKey = 'email';
            break;
          case 'reg_fn':
            urlParamKey = 'firstName';
            break;
          case 'reg_ln':
            urlParamKey = 'lastName';
        }

        if (urlParamKey) {
          this.userUrlFormData[urlParamKey] = params[key];

          if (urlParamKey === 'email') {
            this.userUrlFormData['verify-email'] = params[key];
          }
        }
      }
    });

    this.onTabChange('registration');
  }
}
