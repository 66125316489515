import { startWith, first } from 'rxjs/operators';
import * as fromRoot from '../../app.reducer';
import * as userActions from '../../shared/services-with-reducers/user/user.actions';

import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { CustomizationService } from '../../shared/services-with-reducers/customization/customization.service';
import { FormGroup } from '@angular/forms';
import { FormsService } from '../../shared/forms/forms.service';
import { InputBase } from '../../shared/forms/inputs/input-base.class';
import { Observable } from 'rxjs/Rx';
import { Store } from '@ngrx/store';

@Component({
  moduleId: module.id,
  selector: 'app-registration-questionnaire',
  templateUrl: './registration-questionnaire.component.html',
  styleUrls: ['./registration-questionnaire.component.scss']
})
export class RegistrationQuestionnaireComponent implements OnInit {
  @Output() isValid = new EventEmitter<boolean>();
  @Output() questionnaireChanged = new EventEmitter<any>();

  public form: FormGroup = null;
  public inputs: InputBase<any>[] = [];
  public customAction = ['registrationQuestionaireForm'];

  constructor(
    private _store: Store<fromRoot.State>,
    private _formsService: FormsService
  ) {
    this._store
      .select(fromRoot.getSelectedExhibitionId)
      .pipe(first())
      .subscribe(eventId => {
        // if eventId is null then it loads operator, otherwise it loads events questionnaire
        if (eventId === null) {
          eventId = -1;
        }
        this._store.dispatch(
          new userActions.GetRegistrationQuestionnaire(eventId)
        );
      });
  }

  inputChangeCallback = (inputs: InputBase<any>[], rerender: boolean) => {
    // once the form is changed get the updated input sets and build new form from it
    this.inputs = this._formsService.updateInputs(this.inputs, inputs);

    this.questionnaireChanged.emit(this.inputs);
  };

  ngOnInit() {
    this._store
      .select(fromRoot.getRegistrationQuestionnaire)
      .subscribe(questionaireInputs => {
        if (questionaireInputs && questionaireInputs.length) {
          this.form = this._formsService.toFormGroup(questionaireInputs);
          this.inputs = questionaireInputs;

          this.form.statusChanges
            .pipe(startWith(this.form.valid))
            .subscribe(status => {
              this.isValid.emit(this.form.valid);
            });
        } else {
          this.form = null;
          this.inputs = [];
          this.isValid.emit(true);
        }

        // emit the questionnaire values after load, so in case the user dont edit questionnaire and submit registration, the registration still know about it
        this.questionnaireChanged.emit(this.inputs);
      });
  }
}
