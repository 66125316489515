import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { filter, first, map } from "rxjs/operators";
import { Observable } from 'rxjs';

import * as fromRoot from './../../../app.reducer';

@Pipe({
  name: 'localizedDate',
  pure: false
})
export class LocalizedDatePipe implements PipeTransform {
  constructor(
    private translateService: TranslateService,
    private _datePipe: DatePipe,
    private _store: Store<fromRoot.State>
  ) {}

  //combine this pipe with async pipe to transform with observable
  transform(
    value: any,
    customPattern?: string,
    forceFormat?: 'default',
    timeZone: string = null
  ): Observable<string> | string {
    const currentLang = this.translateService.currentLang;

    let pattern = customPattern || 'MMM d, yyyy';

    if (customPattern || forceFormat === 'default') {
      return this._datePipe.transform(value, pattern, timeZone, currentLang);
    }

    return this._store.select(fromRoot.getOperatorsSettings).pipe(
      filter(data => !!data && !!data["dateFormats"]),
      map(({ dateFormats }) => {
        const patternFromSettings = dateFormats[currentLang] || pattern;

        // this is needed to avoid IE11 bug with date format
        if (value !== 'Invalid Date') {
          return this._datePipe.transform(
            value,
            patternFromSettings,
            timeZone,
            currentLang
          );
        } else {
          return 'Invalid date';
        }
      })
    );
  }
}
