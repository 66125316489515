var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import * as stepsActions from './steps-forms.actions';
import { Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { FormInputsPayloadModel, OrderResponseModel, VisibilityPayloadModel, InputsListModel } from './step.interface';
import { InjectionToken } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';
import { CustomizationService } from '../customization/customization.service';
import { StepsFormsService } from './steps-forms.service';
import { HelperService } from '../helpers/helper.service';
import { ErrorHandlingService } from '../../error-handling/error-handling.service';
export var USER_DEBOUNCE = new InjectionToken('User Debounce');
var StepsFormsEffect = /** @class */ (function () {
    function StepsFormsEffect(actions$, _stepsFormsService, debounce, _customizationService, _store, _helperService, _errorHandlingService) {
        if (debounce === void 0) { debounce = 50; }
        var _this = this;
        this.actions$ = actions$;
        this._stepsFormsService = _stepsFormsService;
        this.debounce = debounce;
        this._customizationService = _customizationService;
        this._store = _store;
        this._helperService = _helperService;
        this._errorHandlingService = _errorHandlingService;
        this.buyerQuestionnaire$ = this.actions$.pipe(ofType(stepsActions.ActionTypes.GET_BUYER_QUESTIONNAIRE), debounceTime(this.debounce), switchMap(function (data) {
            var _a = data.payload, eventId = _a.eventId, stepName = _a.stepName, lang = _a.lang, ticketPersonIds = _a.ticketPersonIds, previousQuestionnare = _a.previousQuestionnare;
            if (eventId === null) {
                return Observable.empty();
            }
            var questionnairePayload = {
                formInfo: [stepName, 'questionnaire'],
                inputSet: {
                    rerender: false,
                    list: []
                }
            };
            return _this._stepsFormsService
                .getBuyerQuestionnaire(eventId, ticketPersonIds)
                .pipe(map(function (buyerQuestionnaire) {
                /* Questionnaire */
                var questionnaires = _this._customizationService.tansformQuestionnaireIntoInput(buyerQuestionnaire);
                questionnairePayload.inputSet.list = questionnaires;
                _this.mergeQuestionnares(previousQuestionnare, questionnairePayload.inputSet);
                var visibilityPayload = {
                    stepKey: 'questionnaire',
                    visible: questionnairePayload.inputSet.list.length > 0 &&
                        _this._helperService.isSelfregistration()
                };
                _this._store.dispatch(new stepsActions.SetStepsVisibility([visibilityPayload]));
                return new stepsActions.SetInputs(questionnairePayload);
            }), catchError(function () { return of(new stepsActions.SetInputs(questionnairePayload)); }));
        }));
        this.sendOrder$ = this.actions$.pipe(ofType(stepsActions.ActionTypes.SEND_ORDER), debounceTime(this.debounce), switchMap(function (data) {
            if (!data.payload) {
                return Observable.empty();
            }
            var orderData = data.payload;
            return _this._stepsFormsService.sendFinalOrder(orderData).pipe(map(function (orderResponseData) {
                var orderResponse = __assign({}, orderResponseData.body, { orderPaid: false });
                if (!orderResponse.success) {
                    _this._errorHandlingService.errorHandler(orderResponse);
                }
                _this._customizationService.resetShoppingStartTime();
                return new stepsActions.SetOrderResponse(orderResponse);
            }), catchError(function () {
                return of(new stepsActions.SetOrderResponse({ error: 'fail' }));
            }));
        }));
    }
    StepsFormsEffect.prototype.mergeQuestionnares = function (previousQuestionnare, currentQuestionnare) {
        if (!previousQuestionnare || !previousQuestionnare.list) {
            return;
        }
        currentQuestionnare.list.map(function (current) {
            var previousElement = previousQuestionnare.list.find(function (previous) { return previous.key === current.key; });
            if (!previousElement)
                return;
            if (current.controlType === 'checkbox' &&
                previousElement.controlType === 'checkbox') {
                previousElement.options.map(function (previousOption) {
                    var currentOption = current.options.find(function (currentOption) { return currentOption.key === previousOption.key; });
                    if (!currentOption)
                        return;
                    currentOption.value = previousOption.value;
                });
            }
            current.value = previousElement.value;
        });
    };
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], StepsFormsEffect.prototype, "buyerQuestionnaire$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], StepsFormsEffect.prototype, "sendOrder$", void 0);
    return StepsFormsEffect;
}());
export { StepsFormsEffect };
