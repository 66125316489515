import * as fromRoot from '../../app.reducer';

import { Component, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import {
  InputsListModel
} from '../../shared/services-with-reducers/step-forms/step.interface';
import {
  Observable,
  Subscription,
  combineLatest as observableCombineLatest,
  Subject
} from 'rxjs';
import { filter, first } from 'rxjs/operators';

import { ExhibitionSettingModel } from '../../shared/services-with-reducers/customization/customization.interfaces';
import { FormGroup } from '@angular/forms';
import { FormsService } from '../../shared/forms/forms.service';
import { HelperService } from '../../shared/services-with-reducers/helpers/helper.service';
import { InputBase } from '../../shared/forms/inputs/input-base.class';
import { select, Store } from '@ngrx/store';
import { GtmService } from '../../shared/gtm/gtmService';
import { TranslateService } from '@ngx-translate/core';
import { FilterPlaceholderPipe } from '../../shared/pipes/filter-placeholder/filter-placeholder.pipe';
import * as ticketActions from '../../shared/services-with-reducers/tickets/ticket.actions';

@Component({
  moduleId: module.id,
  selector: 'app-web-shop-personalize',
  templateUrl: './web-shop-personalize.component.html',
  styleUrls: ['./web-shop-personalize.component.scss']
})
export class WebShopPersonalizeComponent
  implements OnInit, OnDestroy, AfterViewInit {
  public settings$: Observable<ExhibitionSettingModel>;

  public checkboxesInputs: InputBase<any>[];
  public checkboxesForm: FormGroup;
  public checkboxesFormsActionName = ['personal', 'privacy'];
  public registrationFormsActionName = ['personal', 'registration'];
  public visitorQuestionnaireValidation = ['personal', 'visitorQuestionnaire'];
  public isSelfRegistrationEnabled: boolean;
  public buyerStyle = null;
  public ticketHolderInputsNotVisible: boolean = false;
  public modalWindowOpen: boolean = false;
  public exhibitionSettings: ExhibitionSettingModel;
  public selectedSendingOption$: Observable<string>;
  public selectedSendingOption: string;
  public questionnaireLoaded = false;
  public continueAsGuest: boolean;
  public showLoginOnPersonalPage$: Observable<boolean>;
  public showLoginOnTicketAndPersonalPage$: Observable<boolean>;
  public isTicketHolderVisible$: Observable<boolean>;
  public showLoginOnTicketPage$: Observable<boolean>;
  public modalUrl: string;
  public modalTitle: string;
  public modalTranslateTitle: boolean;
  public isInputChanged$: Subject<boolean> = new Subject<boolean>();
  public isConfirmedEmailRequiredOnPersonalization: boolean;
  public showVisitorQuestionnaire: boolean = false;
  private _subscriptions = new Subscription();
  public feedbackControlObject: Object = {};

  constructor(
    private _store: Store<fromRoot.State>,
    private _formsService: FormsService,
    private _helperService: HelperService,
    private _gtmService: GtmService,
    private _translateService: TranslateService,
    private _filterPlaceholderPipe: FilterPlaceholderPipe
  ) {}

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  ngOnInit() {
    this.isTicketHolderVisible$ = this._store.select(
      fromRoot.isTicketHolderVisible
    );

    this._subscriptions.add(
      observableCombineLatest(
        this._store.select(fromRoot.isConfirmedEmailRequiredOnPersonalization),
        this._store.select(fromRoot.showLoginOnPersonalPage),
        this._store.select(fromRoot.showLoginOnTicketAndPersonalPage)
      ).subscribe(([isConfirmedEmailRequiredOnPersonalization, showLoginOnPersonalPage, showLoginOnTicketAndPersonalPage]) => {
        this.isConfirmedEmailRequiredOnPersonalization = isConfirmedEmailRequiredOnPersonalization;
        const showLogin = isConfirmedEmailRequiredOnPersonalization || showLoginOnPersonalPage || showLoginOnTicketAndPersonalPage;

        if (showLogin) {
          this._formsService.removeAllStepValidationFeedbacks(this.registrationFormsActionName);

          if (!isConfirmedEmailRequiredOnPersonalization) {
            this._formsService.addStepValidationFeedback(this.registrationFormsActionName, 'anyBuyerMessageHidden', 'personalize.any.buyer.message.hidden');
          }
        } else {
          this._formsService.removeStepValidationFeedback(this.registrationFormsActionName, 'anyBuyerMessageHidden');
        }

        this._formsService.setFormValidity(!showLogin, null, this.registrationFormsActionName);
      })
    );

    this._subscriptions.add(
      observableCombineLatest([
        this._store.pipe(select(fromRoot.getSelectedExhibitionId)),
        this._store.pipe(select(fromRoot.getTicketSelectedSendingOptions))
      ])
        .subscribe(
          ([currentEventId, sendingOption]) => {

            if (sendingOption === 'ticketRetrivalLink') {
              this._store.pipe(
                select(fromRoot.getTicketHolderQuestionnaireInputs),
                first()
              )
              .subscribe(data => {
                if (data === null || !data.length) {
                  this._store.dispatch(
                    new ticketActions.GetTicketHolderQuestionnaire({
                      eventId: currentEventId,
                      ticketPersonIds: []
                    })
                  );
                }
              });
            }
          }
        )
    );

    observableCombineLatest([
      this._store.pipe(select(fromRoot.getTicketHolderInputSets)),
      this.isTicketHolderVisible$,
      this._store.pipe(select(fromRoot.getExhibitionSettings))
    ])
      .pipe(first())
      .subscribe(
        ([ticketHolderInputSets, isTicketHolderVisible, settings]) => {

          if (!isTicketHolderVisible && ticketHolderInputSets.length) {
            this._formsService.setStepValid(ticketHolderInputSets[0].formInfo);
          }

          if (ticketHolderInputSets && ticketHolderInputSets.length === 0) {
            this.ticketHolderInputsNotVisible = true;
            return;
          }

          this.ticketHolderInputsNotVisible = Object.keys(
            settings.ticketOwnerSettings.fieldSettings
          ).every((ticketHolderInputSetting: string) => {
            return (
              settings.ticketOwnerSettings.fieldSettings[
                ticketHolderInputSetting
              ].isVisible === false
            );
          });

          // Make ticketHolderInputs Visible if email or verifyEmail Fields are mandatory on any of the ticket holders
          if (this.ticketHolderInputsNotVisible) {
            this.ticketHolderInputsNotVisible = !ticketHolderInputSets.some(element => {
              if (!!element.inputSet && !!element.inputSet.list) {
                const holderList = element.inputSet.list;
                const emailField = holderList.find(item => item.key === 'email');
                const verifyEmailField = holderList.find(item => item.key === 'verifyEmail');
                const emailRequired = !!emailField ? emailField.required : false;
                const verifyEmailRequired = !!verifyEmailField ? verifyEmailField.required : false;

                return (emailRequired || verifyEmailRequired);
              }
            });
          }
        }
      );

    this.isSelfRegistrationEnabled = this._helperService.isSelfregistration();

    this._subscriptions.add(
      this._store
        .select(fromRoot.isContinueAsGuest)
        .subscribe(isContinueAsGuest => {
          this.continueAsGuest = isContinueAsGuest;
        })
    );

    this._subscriptions.add(
      this._store
        .select(fromRoot.getQuestionnaire)
        .pipe(
          filter((data: InputsListModel) => {
            // if there are no data for questionnaire, load them
            if (data.list === null) {
              this._helperService.loadBuyerQuestionnaireViaApi('personal');
              return false;
            }

            const questionnaireLoaded = data.list.length > 0;
            if (
              !questionnaireLoaded ||
              this._helperService.isSelfregistration()
            ) {
              /* this._formsService.setFormValidity(true, null, [
              'personal',
              'questionnaire'
            ]);*/
              this._formsService.setStepValid(['personal', 'questionnaire']);
            }

            return questionnaireLoaded;
          })
        )
        .subscribe(() => {
          this.questionnaireLoaded = true;
        })
    );

    this._subscriptions.add(
      observableCombineLatest(
        this._store.select(fromRoot.getPrivacyInput),
        this._store.select(fromRoot.isConfirmedEmailRequiredOnPersonalization),
        this._store.select(fromRoot.showLoginOnPersonalPage),
        this._store.select(fromRoot.showLoginOnTicketAndPersonalPage)
      ).subscribe(([checkboxes, isConfirmedEmailRequiredOnPersonalization, showLoginOnPersonalPage, showLoginOnTicketAndPersonalPage]) => {
          if (!isConfirmedEmailRequiredOnPersonalization && !showLoginOnPersonalPage && !showLoginOnTicketAndPersonalPage && checkboxes && checkboxes.list.length) {
            if (checkboxes || !this.checkboxesForm) {
              this.checkboxesInputs = checkboxes.list.slice(0);
              this.checkboxesForm = this._formsService.toFormGroup(
                checkboxes.list,
                this.checkboxesFormsActionName
              );
            }
          } else {
            // if there is no privacy input to show we need to mark the form as valid
            this._formsService.setFormValidity(
              true,
              null,
              this.checkboxesFormsActionName
            );
          }
        })
    );

    this.settings$ = this._store.select(fromRoot.getExhibitionSettings);
    this.showLoginOnPersonalPage$ = this._store.select(
      fromRoot.showLoginOnPersonalPage
    );
    this.showLoginOnTicketAndPersonalPage$ = this._store.select(
      fromRoot.showLoginOnTicketAndPersonalPage
    );

    this.showLoginOnTicketPage$ = this._store.select(
      fromRoot.showLoginOnTicketPage
    );

    this.settings$
      .pipe(
        filter(settings => {
          return !!settings;
        }),
        first()
      )
      .subscribe(settings => {
        this.exhibitionSettings = settings;
      });

    this.selectedSendingOption$ = this._store.select(
      fromRoot.getTicketSelectedSendingOptions
    );
    this.selectedSendingOption$.subscribe((selectedOption: string) => {
      this.selectedSendingOption = selectedOption;
    });

    this._subscriptions.add(
      observableCombineLatest(
        this.showLoginOnPersonalPage$,
        this.showLoginOnTicketAndPersonalPage$,
        this._store.select(fromRoot.isUserLoggedIn)
      ).subscribe(data => {
        const [
          showLoginOnPersonalPage,
          showLoginOnTicketAndPersonalPage,
          isLogged
        ] = data;
        if (
          !isLogged &&
          (showLoginOnPersonalPage || showLoginOnTicketAndPersonalPage)
        ) {
          this.buyerStyle = { display: 'none' };
        } else {
          this.buyerStyle = null;
        }
      })
    );

    // US2870 - set initial
    this._subscriptions.add(
      this._store.pipe(select(fromRoot.getBuyerVisitorCheckbox))
        .pipe(
          filter(data => !!data)
        )
        .subscribe(buyerVisitorCheckbox => {
          this.showVisitorQuestionnaire = buyerVisitorCheckbox.showVisitorQuestionnaire;
        })
    );
  }

  setVisitorQuestionnaireValidity(validityData) {
    const { formName, valid, inputs, form } = validityData;

    this._formsService.setFormValidity(
      valid,
      form,
      this.visitorQuestionnaireValidation
    );

    if (inputs && form) {
      // update object holding invalid inputs
      this.feedbackControlObject = this._formsService.generateValidationFeedback(
        inputs,
        form,
        formName,
        this.feedbackControlObject
      );

      // US2870 - first remove all previous feedback messages
      this._formsService.removeAllStepValidationFeedbacks(this.visitorQuestionnaireValidation);

      // US2870 - add feedback messages for those fields which are required
      Object.keys(this.feedbackControlObject).forEach(key => {
        const feedbackObject = this.feedbackControlObject[key];

        this._formsService.addStepValidationFeedback(
          this.visitorQuestionnaireValidation,
          feedbackObject.label,
          feedbackObject.label
          );
        }
      );
    }
  }

  allowAdditionalDataToShow(isAdditionalData) {
    this.showVisitorQuestionnaire = isAdditionalData;
    if (isAdditionalData) {
      this._helperService.checkQuestionnairesForDuplicates();
    }
  }

  inputChanged(event) {
    this.isInputChanged$.next(event);
  }

  openIframe($event, url) {
    $event.preventDefault();
    this._helperService.openIframe(url);
  }

  openPrivacyModalWindow(url: string) {
    const translatedValue = this._translateService.instant(
      'personalize.privacy-link'
    );
    const title = this._filterPlaceholderPipe.transform(translatedValue);

    this.openModalWindow(url, title, false);
  }

  openModalWindow(url: string, title: string, translateTitle: boolean = true) {
    this.modalWindowOpen = true;

    this.modalUrl = url;
    this.modalTitle = title;
    this.modalTranslateTitle = translateTitle;
  }

  closeModalWindow(event) {
    event.stopPropagation();
    this.modalWindowOpen = false;
  }
  closeModalWindowOnRightClick(event) {
    event.stopPropagation();
    this.modalWindowOpen = false;
  }

  ngAfterViewInit() {
    this._gtmService.pushPersonalization();
  }
}
