<div class="message-body">
    <div class="message-header" data-translation="validation.feedback-message.title">{{ 'validation.feedback-message.title' | translate | hideTranslation }}</div>
    <ul class="message-list">
        <li class="message-list-item" *ngFor="let feedbackMessage of feedbackMessages | slice:0:3">
            <span *ngIf="feedbackMessage.translate" [attr.data-translation]="feedbackMessage.label">{{ feedbackMessage.label | translate | hideTranslation }}</span>
            <span *ngIf="!feedbackMessage.translate">{{ feedbackMessage.label }}</span>

        </li>
        <li class="message-list-item" *ngIf="feedbackMessages.length > 3">
            <span>...</span>
        </li>
    </ul>
</div>
