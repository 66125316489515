<h2 *ngIf="isAnonymousTicketLoading" class="anonymous-ticket-message">
  <span 
    data-translation="ticket-selection.anonymous-ticket-finishing-order" 
    [appRichText]="'ticket-selection.anonymous-ticket-finishing-order' | translate | hideTranslation">
    Finishing order
  </span>
  <app-loader ></app-loader>
</h2>
<div *ngIf="!isAnonymousTicketLoading" class="page-padding-wrapper">
  <div class="basic-header black">
    <h2
      class="basic-title"
      data-translation="invoice.title"
      [appRichText]="'invoice.title' | translate | hideTranslation"
    ></h2>

    <div
      *ngIf="
        selectedSendingOption === 'allToBuyer' && !allTicketsTicketRetrievalLink &&
        !('invoice.subtitle.summary' | translate | isHiddenTranslation)
      "
      data-translation="invoice.subtitle.summary"
      data-translation-includes-children
      [appRichText]="'invoice.subtitle.summary' | translate"
    ></div>
    <div
      *ngIf="
        selectedSendingOption === 'mobilePerOwner' && !isAnonymous &&
        !('invoice.subtitle.mobile' | translate | isHiddenTranslation)
      "
      data-translation="invoice.subtitle.mobile"
      data-translation-includes-children
      [appRichText]="'invoice.subtitle.mobile' | translate"
    ></div>
    <div
      *ngIf="
        selectedSendingOption === 'mobilePerOwner' && isAnonymous &&
        !('invoice.subtitle.anonymous' | translate | isHiddenTranslation)
      "
      data-translation="invoice.subtitle.anonymous"
      data-translation-includes-children
      [appRichText]="'invoice.subtitle.anonymous' | translate"
    ></div>
    <div
      *ngIf="
        selectedSendingOption === 'normalPerOwner' && !allTicketsTicketRetrievalLink &&
        !('invoice.subtitle.multiple' | translate | isHiddenTranslation)
      "
      data-translation="invoice.subtitle.multiple"
      data-translation-includes-children
      [appRichText]="'invoice.subtitle.multiple' | translate"
    ></div>
    <div
      *ngIf="
        selectedSendingOption === 'ticketRetrivalLink' &&
        !('invoice.subtitle.download-link' | translate | isHiddenTranslation)
      "
      data-translation="invoice.subtitle.download-link"
      data-translation-includes-children
      [appRichText]="'invoice.subtitle.download-link' | translate"
    ></div>
  </div>

  <div class="invoice-button-wrapper">
      <a
      [href]="ticketsDownloadUrl"
      target="_blank"
      class="button"
      *ngIf="
        ((!isAnonymous || !isMobile) &&
        ticketsDownloadUrl && isDownloadTicketButtonVisible
        )"
    >
      <span data-translation="invoice.buttons.ticket">{{
        'invoice.buttons.ticket' | translate | hideTranslation
      }}</span>
    </a>

    <a
      [href]="ticketsMobileDownloadUrl"
      target="_blank"
      class="button"
      *ngIf="
        ((!isAnonymous || isMobile) &&
        ticketsMobileDownloadUrl && hasMobileTicket && isDownloadTicketButtonVisible
        )"
    >
      <span data-translation="invoice.buttons.ticket">{{
        'invoice.buttons.mobile-ticket' | translate | hideTranslation
      }}</span>
    </a>

    <a
      [href]="invoiceDownloadUrl"
      target="_blank"
      class="button"
      *ngIf="invoiceDownloadUrl"
    >
      <span data-translation="invoice.buttons.invoice">{{
        'invoice.buttons.invoice' | translate | hideTranslation
      }}</span>
    </a>
    <button class="button button-option" (click)="onResetShop(false)">
      <span data-translation="invoice.buttons.reset">{{
        'invoice.buttons.reset' | translate | hideTranslation
      }}</span>
    </button>
  </div>


  <app-sponsor-list></app-sponsor-list>

  <hr />

  <div appAdjustSideHeadline>
    <div class="section-headline">
      <h2 data-translation="invoice.share-title">
        {{ 'invoice.share-title' | translate | hideTranslation }}
      </h2>
      <div class="section-headline-side" data-translation="invoice.share-title">
        {{ 'invoice.share-title' | translate | hideTranslation }}
      </div>
    </div>

    <div class="section-body">
      <div class="icons-wrapper">
        <div
          *ngFor="let socialShare of socialMediaShares"
          (click)="socialmediaShare(socialShare.toLowerCase())"
        >
          <svg
            class="social-icon"
            [ngClass]="'icon-' + socialShare.toLowerCase()"
          >
            <use
              [attr.xlink:href]="
                'assets/defs.svg#icon-' + socialShare.toLowerCase()
              "
            ></use>
          </svg>
        </div>
      </div>
    </div>
  </div>
</div>
