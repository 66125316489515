import * as customizationActions from '../../../shared/services-with-reducers/customization/customization.actions';
import * as fromRoot from '../../../app.reducer';
import * as ticketActions from '../../../shared/services-with-reducers/tickets/ticket.actions';
import { StatusBarService } from '../../../../app/status-bar/status-bar.service';
import { FormBuilder, Validators } from '@angular/forms';
import { OnDestroy, OnInit } from '@angular/core';
import { Subscription, combineLatest as observableCombineLatest, Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { CustomizationService } from '../../../shared/services-with-reducers/customization/customization.service';
import { HelperService } from '../../../shared/services-with-reducers/helpers/helper.service';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { first } from 'rxjs/operators';
import { TicketsService } from '../../../shared/services-with-reducers/tickets/tickets.service';
var ERROR_TICKET_WARNING = 'ticket-selection.warning';
var VoucherComponent = /** @class */ (function () {
    function VoucherComponent(route, _customizationService, _store, _translateService, _helperService, _formBuilder, _ticketsService, _statusBarService) {
        this.route = route;
        this._customizationService = _customizationService;
        this._store = _store;
        this._translateService = _translateService;
        this._helperService = _helperService;
        this._formBuilder = _formBuilder;
        this._ticketsService = _ticketsService;
        this._statusBarService = _statusBarService;
        this.clearVoucherInput$ = new Observable();
        this.voucherTickets = [];
        this.loading = false;
        this._subscriptions = new Subscription();
        this.barcode = '';
        this.voucherTypingActive = false;
        this.lastPressedKey = null;
        this.ticketLimitWarning = '';
        this.isAnonymousAndMandatory = false;
        this.isAnonymousWithPrice = false;
        this.currentVoucherTypeMandatory = false;
        this.isAnonymousTicketTaken = false;
        this.showVoucherWarning = false;
        this.invalidVoucherWarning = '';
        this.scanImageUrl = 'assets/scan.png';
    }
    Object.defineProperty(VoucherComponent.prototype, "numberOfAllTickets", {
        set: function (value) {
            this.numberOfSelectedTickets = value;
            if (value < this.maxTicketLimit) {
                this.ticketLimitWarning = '';
            }
        },
        enumerable: true,
        configurable: true
    });
    /*   scan(e) {
      //The scanner sends first CTL-B and at the end CTRL-J
      //"17 66 65 65 17 74" or: "ctrl B A A ctrl J"
  
      const code = e.keyCode ? e.keyCode : e.which;
  
      if (code == 16) return; // ignore shift
  
      if (code === 66 && this.lastPressedKey === 17) {
        this.voucherTypingActive = true;
      } else if (code === 74 && this.lastPressedKey === 17) {
        this.voucherTypingActive = false;
        this.voucherCode = this.barcode.substr(0, this.barcode.length - 1);
        this.onRedeem(this.voucherCode);
        this.barcode = '';
      } else if (this.voucherTypingActive) {
        this.barcode = this.barcode + String.fromCharCode(code);
      }
  
      this.lastPressedKey = code;
    } */
    VoucherComponent.prototype.scan = function (e) {
        var code = e.keyCode ? e.keyCode : e.which;
        this.isAnonymousAndMandatory = false;
        this.isAnonymousWithPrice = false;
        this.showVoucherWarning = false;
        if (code === 16 || code === 17)
            return; // don't add shift && ctrl to barcode!
        if (e.ctrlKey && code === 66) {
            this.voucherTypingActive = true;
        }
        else if (e.ctrlKey && code === 74) {
            this.voucherCode = this.barcode;
            this.barcode = '';
            this.voucherTypingActive = false;
            this.onRedeem(this.voucherCode);
        }
        else if (this.voucherTypingActive) {
            if (e.shiftKey) {
                this.barcode = this.barcode + String.fromCharCode(code).toUpperCase();
            }
            else {
                this.barcode = this.barcode + String.fromCharCode(code).toLowerCase();
            }
        }
    };
    VoucherComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.clearVoucherInput$.subscribe(function (item) {
            if (_this.showVoucherWarning) {
                _this.showVoucherWarning = false;
                _this.voucherCode = '';
            }
        });
        this.voucherForm = this._formBuilder.group({
            voucherInput: ['', Validators.maxLength(50)]
        });
        this.voucherInput = this.voucherForm.controls['voucherInput'];
        if (!this.showVoucherWarning) {
            this.showVoucherWarning = false;
            this.voucherCode = '';
        }
        this._subscriptions.add(this.voucherInput.valueChanges.subscribe(function () {
            if (!(_this.isAnonymousTicketTaken && _this.currentVoucherTypeMandatory)) {
                _this.isAnonymousAndMandatory = false;
                _this.isAnonymousWithPrice = false;
                _this.showVoucherWarning = false;
            }
        }));
        this._subscriptions.add(this._store
            .select(fromRoot.getVoucherTickets)
            .subscribe(function (voucherTickets) {
            _this.voucherTickets = voucherTickets;
            if (!_this.showVoucherWarning) {
                _this.showVoucherWarning = false;
                _this.voucherCode = '';
            }
            _this.loading = false;
            if (voucherTickets.length &&
                voucherTickets[0].hasOwnProperty('sponsors') &&
                voucherTickets[0].sponsors.length) {
                if ('styles' in voucherTickets[0].sponsors[0] &&
                    voucherTickets[0].sponsors[0]['sponsors']) {
                    _this._customizationService.setVoucherStyles(voucherTickets[0].sponsors[0].styles);
                }
                // set new sponsor banner
                if ('sponsorBanner' in voucherTickets[0].sponsors[0]) {
                    if (voucherTickets[0].sponsors[0].sponsorBanner) {
                        _this._store
                            .select(fromRoot.getSponsorBanner)
                            .pipe(first())
                            .subscribe(function (banner) {
                            var newBanner = Object.assign({}, banner, {
                                sponsorBanner: voucherTickets[0].sponsors[0].sponsorBanner
                            });
                            _this._store.dispatch(new customizationActions.SetRandomSponsor(newBanner));
                        });
                    }
                }
                // set new exhibition banner
                if ('eventBanner' in voucherTickets[0].sponsors[0]) {
                    if (voucherTickets[0].sponsors[0].eventBanner) {
                        _this._store
                            .select(fromRoot.getExhibitionSettings)
                            .pipe(first())
                            .subscribe(function (settings) {
                            var modifiedSettings = Object.assign({}, settings, {
                                eventBanner: voucherTickets[0].sponsors[0].eventBanner
                            });
                            _this._store.dispatch(new customizationActions.SetExhibitionSettings(modifiedSettings));
                        });
                    }
                }
            }
        }));
        // check if a voucher came by URL as get param
        this._subscriptions.add(this.route.queryParams.subscribe(function (params) {
            if (params.voucher) {
                _this.onRedeem(params.voucher);
                /*         this._store
                .select(fromRoot.isUserLoggedIn)
                .first()
                .subscribe(isUserLogged => {
                  const actionPayload = {
                    formInfo: ['tickets', 'login'],
                    valid: isUserLogged
                  };
      
                  this._store.dispatch(
                    new stepsActions.SetFormValidity(actionPayload)
                  );
                }); */
            }
            _this.isSelfRegistrationEnabled = _this._helperService.isSelfregistration();
        }));
        this._subscriptions.add(this._store
            .select(fromRoot.getSelectedExhibitionId)
            .subscribe(function (eventId) {
            _this.scanImageUrl =
                environment.protocol +
                    environment.webApiUrl +
                    '/event/' +
                    eventId +
                    '/scan-image';
        }));
        this._subscriptions.add(this._translateService
            .stream('response.voucherNotValid')
            .subscribe(function (translatedInvalidVoucherWarning) { return _this.invalidVoucherWarning = translatedInvalidVoucherWarning; }));
    };
    VoucherComponent.prototype.ngOnDestroy = function () {
        this._subscriptions.unsubscribe();
    };
    VoucherComponent.prototype.showInvalidVoucherStatusMessage = function () {
        var tzOffset = (new Date()).getTimezoneOffset() * 60000;
        var localISOTime = (new Date(Date.now() - tzOffset)).toISOString().slice(0, -1);
        var dateTime = localISOTime.substring(0, localISOTime.indexOf('.')).replace('T', ' ');
        this._statusBarService.setStatus(this.invalidVoucherWarning, 'error', null, "[" + dateTime + "]");
    };
    VoucherComponent.prototype.checkIfAnonVouchTakenAndSelTickMandatory = function (voucherTicketRaw, ungroupedTickets, productGroups) {
        // set current voucher ticket id
        var voucherTicketRawId = voucherTicketRaw['groupId'] + "_" + voucherTicketRaw['ticketTypeId'];
        var voucherProductGroups = !!productGroups && productGroups.filter(function (productGroup) { return productGroup.groupId === voucherTicketRaw['groupId']; });
        if (!voucherProductGroups) {
            this.showInvalidVoucherStatusMessage();
            return true;
        }
        var voucherProductGroup = voucherProductGroups.find(function (productGroup) { return productGroup.products.some(function (product) {
            if (product.ticket != null) {
                return product.ticket.personTypeId == voucherTicketRaw['ticketTypeId'] && product.ticket.isVoucher;
            }
        }); });
        if (!voucherProductGroup) {
            this.showInvalidVoucherStatusMessage();
            return true;
        }
        // get tickets and ticketTypes so we can know if anonymous voucher is taken, and what kind of voucher we're processing now
        this.isAnonymousTicketTaken = this._ticketsService.checkIfAnonymousTicketTaken(ungroupedTickets);
        // if there is anonymous ticket taken check with what kind of voucher are we dealing right now
        if (this.isAnonymousTicketTaken) {
            this.currentVoucherTypeMandatory = voucherProductGroup.products.find(function (productGroup) { return productGroup.ticket.uniqueId === voucherTicketRawId; }).ticket.personalizationType === "mandatory";
        }
        // if there is anonymous ticket taken with price
        var product = voucherProductGroup.products.find(function (productGroup) { return productGroup.ticket.uniqueId === voucherTicketRawId && productGroup.ticket.price > 0; });
        if (!!product) {
            this.isAnonymousWithPrice = product.ticket.personalizationType === "anonymous";
        }
        if (this.isAnonymousWithPrice) {
            this.showVoucherWarning = true;
            return this.isAnonymousWithPrice = true;
        }
        // if there is an anonymous ticket taken and if current voucher that is being redeemed is mandatory, return true 
        if ((this.isAnonymousTicketTaken && this.currentVoucherTypeMandatory)) {
            this.showVoucherWarning = true;
            return this.isAnonymousAndMandatory = true;
        }
        return false;
    };
    VoucherComponent.prototype.onRedeem = function (code) {
        var _this = this;
        if (code) {
            if (this.maxTicketLimit !== undefined && this.numberOfSelectedTickets !== undefined &&
                this.numberOfSelectedTickets === this.maxTicketLimit) {
                this.ticketLimitWarning = ERROR_TICKET_WARNING;
                return;
            }
            code = code.trim();
            // in case the voucher is not active yet
            if (!this.voucherTickets.find(function (voucher) { return voucher.voucherCode === code; })) {
                this.loading = true;
                setTimeout(function () {
                    _this.loading = false;
                }, 2000);
                observableCombineLatest([
                    this._store.pipe(select(fromRoot.getSelectedExhibitionId)),
                    this._store.pipe(select(fromRoot.getOrderUuid)),
                    this._store.pipe(select(fromRoot.getTickets)),
                    this._store.pipe(select(fromRoot.getTicketsTypes))
                ])
                    .pipe(first())
                    .subscribe(function (_a) {
                    var eventId = _a[0], uuid = _a[1], tickets = _a[2], ticketTypes = _a[3];
                    var releaseVouchers = [];
                    if (_this.isSelfRegistrationEnabled &&
                        !!_this.voucherTickets &&
                        _this.voucherTickets.length) {
                        _this.voucherTickets.forEach(function (voucherTicket) {
                            var releaseVoucher = {
                                eventId: eventId,
                                voucherCode: voucherTicket.voucherCode,
                                countryCode: _this._translateService.currentLang,
                                uuid: uuid,
                                ticketPersonId: voucherTicket.ticketPersonId
                            };
                            releaseVouchers.push(releaseVoucher);
                            /* this._store.dispatch(
                              new ticketActions.ReleaseVoucher(releaseVoucher)
                            ); */
                        });
                    }
                    var redeemVoucher = {
                        eventId: eventId,
                        voucherCode: code,
                        countryCode: _this._translateService.currentLang,
                        uuid: uuid,
                        ticketPersonId: null
                    };
                    _this._ticketsService.getVoucherDetails(redeemVoucher).subscribe(function (voucherDetails) {
                        if (_this.checkIfAnonVouchTakenAndSelTickMandatory(voucherDetails, tickets, ticketTypes)) {
                            _this.loading = false;
                            return;
                        }
                        //check if current voucher is for anonymous ticket:
                        var voucherTicketId = voucherDetails['groupId'] + "_" + voucherDetails['ticketTypeId'];
                        var voucherProductGroup = ticketTypes.find(function (productGroup) {
                            return productGroup.products.some(function (product) {
                                if (product.ticket != null) {
                                    return product.ticket.uniqueId == voucherTicketId;
                                }
                            });
                        });
                        if (voucherProductGroup != null) {
                            var voucherTicket = voucherProductGroup.products.find(function (product) { return product.ticket.uniqueId === voucherTicketId; }).ticket;
                            if (!voucherTicket) {
                                _this.showInvalidVoucherStatusMessage();
                                return;
                            }
                            if (!!voucherTicket && voucherTicket.personalizationType === 'anonymous') {
                                _this._store.dispatch(new ticketActions.RemoveTicketBookings());
                                Object.keys(tickets).forEach(function (id) {
                                    var ticket = tickets[id];
                                    if (ticket.personalizationType !== 'anonymous') {
                                        if (ticket.count > 0) {
                                            _this._ticketsService.ticketCounterChanged(ticket.uniqueId, 0, true);
                                        }
                                        if (ticket.voucherCode) {
                                            releaseVouchers.push({
                                                eventId: eventId,
                                                voucherCode: ticket.voucherCode,
                                                countryCode: _this._translateService.currentLang,
                                                uuid: uuid,
                                                ticketPersonId: ticket.ticketPersonId
                                            });
                                        }
                                    }
                                });
                            }
                        }
                        _this._store.dispatch(new ticketActions.GetVoucher({
                            redeemVoucher: redeemVoucher,
                            releaseVouchers: releaseVouchers
                        }));
                    }, function () {
                        _this.loading = false;
                    });
                });
            }
        }
    };
    return VoucherComponent;
}());
export { VoucherComponent };
