import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appAdjustSideHeadline]'
})
export class AdjustSideHeadlineDirective implements AfterViewInit {
  constructor(private el: ElementRef) {}
  ngAfterViewInit() {
    const sectionSideTitle = this.el.nativeElement.querySelector(
      '.section-headline-side'
    );
    const sectionBody = this.el.nativeElement.querySelector('.section-body');
    if (sectionSideTitle && sectionBody) {
      sectionBody.style.minHeight = sectionSideTitle.offsetWidth + 30 + 'px';
    }
  }
}
