import { AppConstants } from '../app-constants';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StatusBarService } from '../../status-bar/status-bar.service';
import { TranslateService } from '@ngx-translate/core';
import { throwError as observableThrowError, BehaviorSubject } from 'rxjs';

import * as helperActions from '../../shared/services-with-reducers/helpers/helper.actions';
import * as fromRoot from '../../app.reducer';
import { Store } from '@ngrx/store';
import { consoleLog } from '../app-utils';

export interface TranslatedErrorMessage {
  status: number;
  key: string;
  message: string;
}

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingService {
  translatedErrorMessage = new BehaviorSubject<TranslatedErrorMessage>(null);

  constructor(
    private _statusBarService: StatusBarService,
    private _translateService: TranslateService,
    private _store: Store<fromRoot.State>
  ) {}

  errorHandler(error: any): any {
    consoleLog(JSON.stringify(error));

    let errMsg: string;

    if (error instanceof HttpErrorResponse || (error.hasOwnProperty('errors') && error.errors.length > 0)) {
      let errorCode: string[] = [];
      let errorKeyExists: boolean = false;
      try {
        if (error.error.hasOwnProperty('errorCode')) {
          errorCode.push(error.error.errorCode);
        } else if (error.error.hasOwnProperty('ErrorCode')) {
          errorCode.push(error.error.ErrorCode);
        } else if (error.hasOwnProperty('errors')) {
           if (!!error.errors) {
             error.errors.forEach((element, index) => {
               errorCode.push(element.code);
               if (!errorKeyExists && index > 0) {
                 errorKeyExists = errorCode.some(item => item === element.code);
               }
             });
           }
        }

        if (!!errorCode && errorCode.length > 0) {
          errorCode.forEach((currentError, index) => {
            this._translateService
             // multipleErrors is key when there are multiple same error keys in errorCode array, else take currentError key and get it's translation if available
            .get('response.' + (errorKeyExists ? 'multipleErrors' : currentError))
            .subscribe((translation: string) => {
              errMsg = translation;

              if (error.error.hasOwnProperty('invalidFields')) {
                const fields = error.error['invalidFields'].join(', ');
                translation += `: ${fields}`;
              }

              // in case the error translation was not found.. respond with backend message
              if (
                 // if errors are in response object and there are multiple errors with same error code set errors to their message in error.errors array
                translation === AppConstants.MISSING_TRANSLATION && !!error.errors &&
                error.errors.length > 1) {
                translation = error.errors[index].message;
              } else if (
                translation === AppConstants.MISSING_TRANSLATION &&
                error.error.hasOwnProperty('errorMessage')
              ) {
                translation = error.error.errorMessage;
              } else if (
                translation === AppConstants.MISSING_TRANSLATION &&
                error.error.hasOwnProperty('ErrorMessage')
              ) {
                translation = error.error.ErrorMessage;
              } else if (translation === AppConstants.MISSING_TRANSLATION) {
                translation = 'Server Error';
              }

              const translatedMessage = {
                status: error.status,
                key: currentError,
                message: translation
              };

              this.translatedErrorMessage.next(translatedMessage);

              let isMissingSeries =
                translation.startsWith('Event Serie with identifier') &&
                translation.endsWith('does not exists.');

              if (!isMissingSeries) {
                const dateTime: string = error.error.hasOwnProperty('serverTime') ? `[${error.error['serverTime']}]` : `<${new Date().toISOString()}>`;
                this._statusBarService.setStatus(translation, 'error', null, dateTime);
              }
            });
          });
        }
      } catch (err) {
        errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        const clientTime: string = `<${new Date().toISOString()}>`;
        this._statusBarService.setStatus(errMsg, 'error', null, clientTime);
      }
    } else {
      errMsg = error.message ? error.message : error.toString();
      const clientTime: string = `<${new Date().toISOString()}>`;
      this._statusBarService.setStatus(errMsg, 'error', null, clientTime);
    }

    this._store.dispatch(new helperActions.SetSpinnerValue(false));
    return observableThrowError(errMsg);
  }

  clearAllErrors() {
    this.translatedErrorMessage.next(null);
  }
}