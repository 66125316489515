<div class="download-ticket" appAdjustSideHeadline>
  <div class="section-headline">
    <h2 data-translation="profile.tickets.title">
      {{ 'profile.tickets.title' | translate | hideTranslation }}
    </h2>
    <div class="section-headline-side" data-translation="profile.tickets.headline">
      {{ 'profile.tickets.headline' | translate | hideTranslation }}
    </div>
  </div>

  <div class="section-body min-height">
    <div *ngIf="!eventWithTickets.length" class="anouncement-info row">
      <svg class="icon-info">
        <use xlink:href="assets/defs.svg#icon-info"></use>
      </svg>
      <strong data-translation="profile.tickets.info-title">
        {{ 'profile.tickets.info-title' | translate | hideTranslation }}:&nbsp;
      </strong>
      <span data-translation="profile.tickets.info-message">
        {{ 'profile.tickets.info-message' | translate | hideTranslation }}
      </span>
    </div>

    <div *ngIf="eventWithTickets?.length">
      <mat-accordion>
        <mat-expansion-panel *ngFor="let event of eventWithTickets" [expanded]="event.isAccordionOpen">
          <mat-expansion-panel-header style="min-height: 50px;" [collapsedHeight]="isMobile ? '100%' : '50px'">
            <mat-panel-title class="panel-title">
              <div class="ticket-calendar">
                <a [href]="event.eventCalendar" target="_blank" *ngIf="event.eventCalendar">
                  <svg class="icon-calendar">
                    <use xlink:href="assets/defs.svg#icon-download-calendar"></use>
                  </svg>
                </a>
              </div>
              <span class="ticket-event-name">
                {{ event.eventName }}
              </span>
            </mat-panel-title>
            <mat-panel-description class="panel-description">
              <span>
                {{ event.eventStartDate | localizedDate: 'MMM d, yyyy' }} -
                {{ event.eventEndDate | localizedDate: 'MMM d, yyyy' }}
              </span>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <mat-accordion>
            <mat-expansion-panel *ngFor="let orders of event.orders">
              <mat-expansion-panel-header style="min-height: 50px;" [collapsedHeight]="isMobile ? '100%' : '50px'">
                <mat-panel-title>
                  <span data-translation="profile.tickets.order.created">{{
                    'profile.tickets.order.created'
                    | translate
                    | hideTranslation
                    }}:&#160;
                  </span>
                  {{
                  orders.createdAt | localizedDate: 'MMM d, yyyy'
                  }}
                </mat-panel-title>
                <mat-panel-description class="panel-description">
                  {{ 'profile.legitimation.status' | translate }}:
                  {{ 'order-status.' + orders.status | translate }}
                </mat-panel-description>
              </mat-expansion-panel-header>
              
              <div 
                *ngFor="let package of orders.packages"
                class="package-border"
              >
                <div class="package-topbar">
                  <h2>{{ package.packageName }}</h2>
                  
                  <div
                    *ngIf="!isMobile"
                    class="ticket-download visibility-hidden"
                  >
                    <svg class="icon-download">
                      <use xlink:href="assets/defs.svg#icon-invoice"></use>
                    </svg>
                    <span>** THIS IS DUMMY DIV **</span>
                  </div>
                  
                  <div
                    *ngIf="!isMobile"
                    class="ticket-download visibility-hidden"
                  >
                    <svg class="icon-download">
                      <use xlink:href="assets/defs.svg#icon-invoice"></use>
                    </svg>
                    <span>** THIS IS DUMMY DIV **</span>
                  </div>
                  
                  <div
                    *ngIf="!isMobile"
                    class="ticket-download visibility-hidden"
                  >
                    <svg class="icon-download">
                      <use xlink:href="assets/defs.svg#icon-invoice"></use>
                    </svg>
                    <span>** THIS IS DUMMY DIV **</span>
                  </div>

                  <!-- Refund package download button. Hidden if not refunded -->
                  <div 
                    *ngIf="package.allowDownloadRefundReceipt"
                    class="ticket-download"
                    (click)="downloadTicket(orders.id, 'refundPackageReceipt', null, package.packageIndex)"
                    [attr.disabled]="showLoader"
                    [class.disabled-button]="showLoader"
                  >
                    <svg class="icon-download">
                      <use xlink:href="assets/defs.svg#icon-invoice"></use>
                    </svg>
                    <span class="invoice-label" data-translation="download-ticket.download-refund-receipt-button">
                      {{ 'download-ticket.download-refund-receipt-button' | translate | hideTranslation }}
                    </span>
                  </div>
                  <!-- Refund package button. Disabled if refunded -->
                  <div 
                    *ngIf="!package.allowDownloadRefundReceipt"
                    class="ticket-download cancel-color" 
                    style="margin-right: 0; margin-bottom: 1rem;"
                    (click)="!package.allowRefund ? null : refundPackagePopup(orders.id, package.packageIndex, package.entryTickets)"
                    [attr.disabled]="!package.allowRefund || showLoader"
                    [class.disabled-button]="!package.allowRefund || showLoader"
                  >
                    <svg class="icon-download">
                      <use xlink:href="assets/defs.svg#icon-cross"></use>
                    </svg>
                    <span data-translation="download-ticket.refund-single-ticket-button">
                      {{ 'download-ticket.refund-single-ticket-button' | translate | hideTranslation }}
                    </span>
                  </div>
                </div>

                <div *ngFor="let ticket of package.entryTickets">

                  <div class="ticket-holder-row">
                    <div 
                      class="ticket-holder"
                      [class.ticket-holder-on-mobile]="this.isMobile"
                    >
                      <div>
                        <b data-translation="personalize.holder.firstName-lastName">
                          {{
                          'personalize.holder.firstName-lastName'
                          | translate
                          | hideTranslation
                          }}:
                        </b>
                        <span>
                          {{ ticket.firstName ? ticket.firstName : '--' }}
                        </span>
                      </div>
                      <div>
                        <b data-translation="personalize.holder.last-name">{{
                          'personalize.holder.last-name'
                          | translate
                          | hideTranslation
                          }}:
                        </b>
                        <span>
                          {{ ticket.lastName ? ticket.lastName : '--' }}
                        </span>
                      </div>
                      <div>
                        <b data-translation="personalize.holder.email">{{
                          'personalize.holder.email'
                          | translate
                          | hideTranslation
                          }}:
                        </b>
                        <span>
                          {{ ticket.email ? ticket.email : '--' }}
                        </span>
                      </div>
                    </div>
  
                    <div class="download-tickets">
                      <div 
                        class="ticket-download" 
                        (click)="ticket.allowDownload && downloadTicket(ticket.id, 'normalTicket')"
                        [attr.disabled]="!ticket.allowDownload || showLoader" 
                        [class.disabled-button]="!ticket.allowDownload || showLoader">
                        <svg class="icon-download">
                          <use xlink:href="assets/defs.svg#icon-download2"></use>
                        </svg>
                        <span data-translation="download-ticket.claim-ticket.button">
                          {{
                          'download-ticket.claim-ticket.button'
                          | translate
                          | hideTranslation
                          }}
                        </span>
                      </div>
  
                      <div 
                        class="ticket-download" 
                        (click)="ticket.allowDownload && downloadTicket(ticket.id, 'passBook')"
                        [attr.disabled]="!ticket.allowDownload || showLoader"
                        [class.disabled-button]="!ticket.allowDownload || showLoader">
                        <svg class="icon-download">
                          <use xlink:href="assets/defs.svg#icon-download2"></use>
                        </svg>
                        <span data-translation="download-passBook.claim-ticket.button">
                          {{ 'download-passBook.claim-ticket.button' | translate | hideTranslation }}
                        </span>
                      </div>

                      <div 
                        class="ticket-download" 
                        (click)="ticket.allowEdit && closeAllTicketsExcept(ticket)"
                        [attr.disabled]="!ticket.allowEdit || showLoader"
                        [class.disabled-button]="!ticket.allowEdit || showLoader">
                        <svg class="icon-download">
                          <use xlink:href="assets/defs.svg#icon-pencil"></use>
                        </svg>
                        <span data-translation="profile.edit">
                          {{ 'profile.edit' | translate | hideTranslation }}
                        </span>
                      </div>

                      <!-- Icon for refunding single ticket. When ticket is refunded, icon becomes disabled -->
                      <!-- <div 
                        *ngIf="ticket.allowRefund"
                        class="ticket-download cancel-color" 
                        (click)="!ticket.allowRefund ? null : refundTicketPopup(orders.id, ticket.id)"
                        [attr.disabled]="!ticket.allowRefund || showLoader"
                        [class.disabled-button]="!ticket.allowRefund || showLoader">
                        <svg class="icon-download">
                          <use xlink:href="assets/defs.svg#icon-cross"></use>
                        </svg>
                        <span data-translation="download-ticket.refund-single-ticket-button">
                          {{ 'download-ticket.refund-single-ticket-button' | translate | hideTranslation }}
                        </span>
                      </div> -->

                      <!-- Icon for refund receipt of a single ticket, it becomes enabled when refund of a single ticket is finished -->
                      <!-- <div 
                        *ngIf="ticket.allowRefundRecipeDownload" 
                        class="ticket-download"
                        (click)="downloadTicket(orders.id, 'refundSingleTicketReceipt', ticket.id)"
                        [attr.disabled]="showLoader"
                        [class.disabled-button]="showLoader"
                      >
                        <svg class="icon-download">
                          <use xlink:href="assets/defs.svg#icon-invoice"></use>
                        </svg>
                        <span class="invoice-label" data-translation="download-ticket.download-refund-receipt-button">
                          {{ 'download-ticket.download-refund-receipt-button' | translate | hideTranslation }}
                        </span>
                      </div> -->

                      <div
                        *ngIf="!isMobile"
                        class="ticket-download visibility-hidden"
                      >
                        <svg class="icon-download">
                          <use xlink:href="assets/defs.svg#icon-invoice"></use>
                        </svg>
                        <span>** THIS IS DUMMY DIV **</span>
                      </div>

                      <div
                        *ngIf="!isMobile"
                        class="ticket-download visibility-hidden"
                      >
                        <svg class="icon-download">
                          <use xlink:href="assets/defs.svg#icon-invoice"></use>
                        </svg>
                        <span>** THIS IS DUMMY DIV **</span>
                      </div>
                    </div>
  
                  </div>
                
                  <div>
                    <ng-container *ngIf="ticket.expanded">
                      <app-download-ticket-holder-profile-edit [ticket]="ticket">
                      </app-download-ticket-holder-profile-edit>
                    </ng-container>
                  </div>

                </div>
              </div>

              <div *ngFor="let ticket of orders.entryTickets" class="separate-components">
                <div class="ticket-holder-row">
                  <div 
                    class="ticket-holder"
                    [class.ticket-holder-on-mobile]="this.isMobile"
                  >
                    <div>
                      <b data-translation="personalize.holder.firstName-lastName">
                        {{
                        'personalize.holder.firstName-lastName'
                        | translate
                        | hideTranslation
                        }}:
                      </b>
                      <span>
                        {{ ticket.firstName ? ticket.firstName : '--' }}
                      </span>
                    </div>
                    <div>
                      <b data-translation="personalize.holder.last-name">{{
                        'personalize.holder.last-name'
                        | translate
                        | hideTranslation
                        }}:
                      </b>
                      <span>
                        {{ ticket.lastName ? ticket.lastName : '--' }}
                      </span>
                    </div>
                    <div>
                      <b data-translation="personalize.holder.email">{{
                        'personalize.holder.email'
                        | translate
                        | hideTranslation
                        }}:
                      </b>
                      <span>
                        {{ ticket.email ? ticket.email : '--' }}
                      </span>
                    </div>
                  </div>

                  <div class="download-tickets">
                    <div 
                      class="ticket-download" 
                      (click)="ticket.allowDownload && downloadTicket(ticket.id, 'normalTicket')"
                      [attr.disabled]="!ticket.allowDownload || showLoader" 
                      [class.disabled-button]="!ticket.allowDownload || showLoader">
                      <svg class="icon-download">
                        <use xlink:href="assets/defs.svg#icon-download2"></use>
                      </svg>
                      <span data-translation="download-ticket.claim-ticket.button">
                        {{
                        'download-ticket.claim-ticket.button'
                        | translate
                        | hideTranslation
                        }}
                      </span>
                    </div>

                    <div 
                      class="ticket-download" 
                      (click)="ticket.allowDownload && downloadTicket(ticket.id, 'passBook')"
                      [attr.disabled]="!ticket.allowDownload || showLoader"
                      [class.disabled-button]="!ticket.allowDownload || showLoader">
                      <svg class="icon-download">
                        <use xlink:href="assets/defs.svg#icon-download2"></use>
                      </svg>
                      <span data-translation="download-passBook.claim-ticket.button">
                        {{ 'download-passBook.claim-ticket.button' | translate | hideTranslation }}
                      </span>
                    </div>

                    <div 
                      class="ticket-download" 
                      (click)="ticket.allowEdit && closeAllTicketsExcept(ticket)"
                      [attr.disabled]="!ticket.allowEdit || showLoader"
                      [class.disabled-button]="!ticket.allowEdit || showLoader">
                      <svg class="icon-download">
                        <use xlink:href="assets/defs.svg#icon-pencil"></use>
                      </svg>
                      <span data-translation="profile.edit">
                        {{ 'profile.edit' | translate | hideTranslation }}
                      </span>
                    </div>

                    <!-- Icon for refunding single ticket. When ticket is refunded, icon becomes disabled -->
                    <div 
                      *ngIf="!ticket.allowRefundRecipeDownload" 
                      class="ticket-download cancel-color" 
                      (click)="!ticket.allowRefund ? null : refundTicketPopup(orders.id, ticket.id)"
                      [attr.disabled]="!ticket.allowRefund || showLoader"
                      [class.disabled-button]="!ticket.allowRefund || showLoader">
                      <svg class="icon-download">
                        <use xlink:href="assets/defs.svg#icon-cross"></use>
                      </svg>
                      <span data-translation="download-ticket.refund-single-ticket-button">
                        {{ 'download-ticket.refund-single-ticket-button' | translate | hideTranslation }}
                      </span>
                    </div>

                    <!-- Icon for refund receipt of a single ticket, it becomes enabled when refund of a single ticket is finished -->
                    <div 
                      *ngIf="ticket.allowRefundRecipeDownload" 
                      class="ticket-download"
                      (click)="downloadTicket(orders.id, 'refundSingleTicketReceipt', ticket.id, null)"
                      [attr.disabled]="showLoader"
                      [class.disabled-button]="showLoader"
                    >
                      <svg class="icon-download">
                        <use xlink:href="assets/defs.svg#icon-invoice"></use>
                      </svg>
                      <span class="invoice-label" data-translation="download-ticket.download-refund-receipt-button">
                        {{ 'download-ticket.download-refund-receipt-button' | translate | hideTranslation }}
                      </span>
                    </div>

                    <!-- This dummy <div> have a purpose for aligning icons properly in download section -->
                    <div
                      *ngIf="!isMobile"
                      class="ticket-download visibility-hidden"
                    >
                    <!-- This dummy <div> is part of disableing "Change date" functionality through 3688. 
                      When 3688 is reverted so the buttons are visible once again, then we need to 
                      uncomment this part below and comment part before -->
                    <!-- <div
                      *ngIf="!isMobile && !ticket.day"
                      class="ticket-download visibility-hidden"
                    > -->
                      <svg class="icon-download">
                        <use xlink:href="assets/defs.svg#icon-invoice"></use>
                      </svg>
                      <span>** THIS IS DUMMY DIV **</span>
                    </div>

                    <!-- 3688 - Temporarily disable "Change date" function in the Webshop so we would prevent possible issues with End Users -->
                    <!-- <div 
                      *ngIf="ticket.day && ticket.allowDayChange"
                      class="ticket-download" 
                      (click)="ticket.allowEdit && closeAllTicketsDayChangeExcept(ticket) "
                      [attr.disabled]="!ticket.allowEdit || showLoader"
                      [class.disabled-button]="!ticket.allowEdit || showLoader" 
                      >
                      <svg class="icon-download">
                        <use xlink:href="assets/defs.svg#icon-pencil"></use>
                      </svg>
                      <span data-translation="profile.edit-day">
                        {{ 'profile.edit-day' | translate | hideTranslation }}
                      </span>
                    </div> -->
                  </div>
                </div>
                
                
                <div>
                  <ng-container *ngIf="ticket.expanded">
                    <app-download-ticket-holder-profile-edit [ticket]="ticket">
                    </app-download-ticket-holder-profile-edit>
                  </ng-container>
                </div>
                <!-- 3688 - Temporarily disable "Change date" function in the Webshop so we would prevent possible issues with End Users -->
                <!-- <div>
                  <ng-container *ngIf="ticket.changeDateExpanded && ticket.allowDayChange">
                    <app-change-ticket-day [ticket]="ticket" [event]="event"></app-change-ticket-day>
                  </ng-container>
                </div> -->
              </div>

              <div *ngFor="let ticket of orders.parkingTickets">
                <div class="ticket-holder-row">
                  <div class="ticket-holder">
                    <div>
                      <b data-translation="personalize.holder.firstName-lastName">
                        {{
                        'personalize.holder.firstName-lastName'
                        | translate
                        | hideTranslation
                        }}:
                      </b>
                      <span>
                        {{ ticket.firstName ? ticket.firstName : '--' }}
                      </span>
                    </div>
                    <div>
                      <b data-translation="personalize.holder.last-name">{{
                        'personalize.holder.last-name'
                        | translate
                        | hideTranslation
                        }}:
                      </b>
                      <span>
                        {{ ticket.lastName ? ticket.lastName : '--' }}
                      </span>
                    </div>
                    <div>
                      <b data-translation="personalize.holder.email">{{
                        'personalize.holder.email'
                        | translate
                        | hideTranslation
                        }}:
                      </b>
                      <span>
                        {{ ticket.email ? ticket.email : '--' }}
                      </span>
                    </div>
                  </div>

                  <div class="download-tickets">
                    <div 
                      class="ticket-download"
                      (click)="orders.status === 'cancelled' ? null : downloadTicket(ticket.id, 'parkingTicket')"
                      [attr.disabled]="!ticket.allowDownload || showLoader"
                      [class.disabled-button]="!ticket.allowDownload || showLoader"
                    >
                      <svg class="icon-download">
                        <use xlink:href="assets/defs.svg#icon-download2"></use>
                      </svg>
                      <span data-translation="download-ticket.claim-ticket.button">
                        {{
                        'download-ticket.claim-ticket.button'
                        | translate
                        | hideTranslation
                        }}
                      </span>
                    </div>

                    <div 
                    *ngIf="!ticket.allowRefundRecipeDownload"
                    class="ticket-download cancel-color" 
                    (click)="!ticket.allowRefund ? null : refundParkingPopup(orders.id, ticket.id)"
                    [attr.disabled]="(!ticket.allowRefund || !ticket.allowDownload) || showLoader"
                    [class.disabled-button]="(!ticket.allowRefund || !ticket.allowDownload) || showLoader">
                      <svg class="icon-download">
                        <use xlink:href="assets/defs.svg#icon-cross"></use>
                      </svg>
                      <span data-translation="download-ticket.refund-parking-ticket-button">
                        {{ 'download-ticket.refund-parking-ticket-button' | translate | hideTranslation }}
                      </span>
                    </div>

                    <div 
                      *ngIf="ticket.allowRefundRecipeDownload" 
                      class="ticket-download"
                      (click)="downloadTicket(orders.id, 'parkingRefundReceipt', ticket.id)"
                      [attr.disabled]="showLoader"
                      [class.disabled-button]="showLoader"
                    >
                      <svg class="icon-download">
                        <use xlink:href="assets/defs.svg#icon-invoice"></use>
                      </svg>
                      <span class="invoice-label" data-translation="download-ticket.download-refund-receipt-button">
                        {{ 'download-ticket.download-refund-receipt-button' | translate | hideTranslation }}
                      </span>
                    </div>

                    <!-- This dummy <div> have a purpose for aligning icons properly in download section -->
                    <div
                      *ngIf="ticket.allowRefund && !isMobile"
                      class="ticket-download visibility-hidden"
                    >
                      <svg class="icon-download">
                        <use xlink:href="assets/defs.svg#icon-invoice"></use>
                      </svg>
                      <span>** THIS IS DUMMY DIV **</span>
                    </div>

                    <!-- This dummy <div> have a purpose for aligning icons properly in download section -->
                    <div
                      *ngIf="ticket.allowRefund && !isMobile"
                      class="ticket-download visibility-hidden"
                    >
                      <svg class="icon-download">
                        <use xlink:href="assets/defs.svg#icon-invoice"></use>
                      </svg>
                      <span>** THIS IS DUMMY DIV **</span>
                    </div>

                    <!-- This dummy <div> have a purpose for aligning icons properly in download section -->
                    <div
                      *ngIf="ticket.allowRefund && !isMobile"
                      class="ticket-download visibility-hidden"
                    >
                      <svg class="icon-download">
                        <use xlink:href="assets/defs.svg#icon-invoice"></use>
                      </svg>
                      <span>** THIS IS DUMMY DIV **</span>
                    </div>

                    <!-- This dummy <div> have a purpose for aligning icons properly in download section -->
                    <div
                      *ngIf="!ticket.allowRefund && !isMobile"
                      class="ticket-download visibility-hidden"
                    >
                      <svg class="icon-download">
                        <use xlink:href="assets/defs.svg#icon-invoice"></use>
                      </svg>
                      <span>** THIS IS DUMMY DIV **</span>
                    </div>

                    <!-- This dummy <div> have a purpose for aligning icons properly in download section -->
                    <div
                      *ngIf="!ticket.allowRefund && !isMobile"
                      class="ticket-download visibility-hidden"
                    >
                      <svg class="icon-download">
                        <use xlink:href="assets/defs.svg#icon-invoice"></use>
                      </svg>
                      <span>** THIS IS DUMMY DIV **</span>
                    </div>

                    <!-- This dummy <div> have a purpose for aligning icons properly in download section -->
                    <div
                      *ngIf="!ticket.allowRefund && !isMobile"
                      class="ticket-download visibility-hidden"
                    >
                      <svg class="icon-download">
                        <use xlink:href="assets/defs.svg#icon-invoice"></use>
                      </svg>
                      <span>** THIS IS DUMMY DIV **</span>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="orders.allowDownloadInvoice" class="ticket-download invoice-download"
                (click)="orders.status === 'cancelled' ? null : downloadTicket(orders.id, 'invoiceTicket')" 
                [attr.disabled]="orders.status === 'cancelled' || showLoader"
                [class.disabled-button]="orders.status === 'cancelled' || showLoader"
              >
                <svg class="icon-download">
                  <use xlink:href="assets/defs.svg#icon-invoice"></use>
                </svg>
                <span class="invoice-label" data-translation="download-ticket.download-invoice-button">
                  {{ 'download-ticket.download-invoice-button' | translate | hideTranslation }}
                </span>
              </div>

              <div 
                *ngIf="orders.allowDownloadRefundReceipt" 
                class="ticket-download refund-receipt-download"
                (click)="downloadTicket(orders.id, 'refundReceipt')"
                [attr.disabled]="showLoader"
                [class.disabled-button]="showLoader"
              >
                <svg class="icon-download">
                  <use xlink:href="assets/defs.svg#icon-invoice"></use>
                </svg>
                <span class="invoice-label" data-translation="download-ticket.download-refund-receipt-button">
                  {{ 'download-ticket.download-refund-receipt-button' | translate | hideTranslation }}
                </span>
              </div>

              <div 
                *ngIf="orders.allowRefund && !showLoader" 
                class="ticket-download cancel-refund"
                (click)="refundOrderPopup(orders.id)"
              >
                <svg class="icon-download">
                  <use xlink:href="assets/defs.svg#icon-cross"></use>
                </svg>
                <span class="invoice-label" data-translation="download-ticket.refund-button">
                  {{ 'download-ticket.refund-button' | translate | hideTranslation }}
                </span>
              </div>
             
              <div *ngIf="showLoader" class="refund-in-progress">
                <span data-translation="download-ticket.refund-in-progress">
                  {{ 'download-ticket.refund-in-progress' | translate | hideTranslation }}
                </span>
                
                <app-loader class="loader"></app-loader>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>

<app-modal-window
  [modalWindowActive]="modalWindowOpen"
  translateTitle="true"
  [modalWindowTitle]="isSingleTicketRefund 
    ? (isParkingTicketRefund ? 'download-ticket.refund-parking-ticket-question' : 'download-ticket.refund-ticket-question')
    : isPackageRefund ? 'download-ticket.refund-package-question' : 'download-ticket.refund-order-question'"
  >
  <button
    class="button"
    modalWindow-submit
    (click)="isSingleTicketRefund 
      ? (isParkingTicketRefund ? refundParkingTicket(true) : refundTicket(true))
      : isPackageRefund ? refundPackage(true) : refundOrder(true)"
  >
    <span data-translation="download-ticket.refund-order-yes">{{
      'download-ticket.refund-order-yes' | translate | hideTranslation
    }}</span>
  </button>
  <button
    class="button button-option"
    modalWindow-close
    (click)="isSingleTicketRefund 
    ? (isParkingTicketRefund ? refundParkingTicket(false) : refundTicket(false))
    : isPackageRefund ? refundPackage(false) : refundOrder(false)"
  >
    <span data-translation="download-ticket.refund-order-no">{{
      'download-ticket.refund-order-no' | translate | hideTranslation
    }}</span>
  </button>
</app-modal-window>