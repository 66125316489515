import * as additionalServicesActions from '../additional-services/additional-services.actions';
import * as fromRoot from '../../../app.reducer';
import * as stepsActions from '../step-forms/steps-forms.actions';
import * as ticketActions from './ticket.actions';

import { HttpClient } from '@angular/common/http';
import {
  Subscription,
  combineLatest as observableCombineLatest,
  of
} from 'rxjs';
import {
  ContingentBookingModel,
  PostTicketBookingModel,
  RedeemVoucherModel,
  TicketByIdModel,
  TicketModel,
  ValidateDailyTicketPerEmailLimitBody,
  VisitorFormModel
} from './ticket.interface';
import { catchError, debounceTime, filter, first, map } from 'rxjs/operators';

import { CustomizationService } from '../customization/customization.service';
import { ErrorHandlingService } from '../../error-handling/error-handling.service';
import { FormInputsPayloadModel } from '../step-forms/step.interface';
import { FormsService } from '../../forms/forms.service';
import { HelperService } from '../helpers/helper.service';
import { Injectable } from '@angular/core';
import { StatusBarService } from '../../../status-bar/status-bar.service';
import { select, Store } from '@ngrx/store';
import { TicketHolderAdditionalDataModel } from '../step-forms/step.interface';
import { TranslateService } from '@ngx-translate/core';
import { WorkshopBookingPostModel } from '../additional-services/additional-services.interface';
import { environment } from '../../../../environments/environment';
import { prepareTicketHolderData } from '../customization/forms/tickets-holder-data';
import { setTimeout } from 'core-js/library/web/timers';
import cloneDeep from 'lodash.clonedeep';
import { GtmService } from '../../gtm/gtmService';
import { ExhibitionSettingModel } from '../customization/customization.interfaces';
import { SelectOption } from '../exhibition/exhibition.interface';
import { consoleLog, getLocalStorageString, setLocalStorageObject } from '../../app-utils';
import _ from 'lodash';
import { AppConstants } from '../../app-constants';

@Injectable({
  providedIn: 'root'
})
export class TicketsService {
  constructor(
    private _http: HttpClient,
    private _store: Store<fromRoot.State>,
    private _formsService: FormsService,
    private _statusBarService: StatusBarService,
    private _errorHandlingService: ErrorHandlingService,
    private _helperService: HelperService,
    private _translateService: TranslateService,
    private _gtmService: GtmService
  ) {
    // once new voucher code was added and its count was set to 1 in reducer, we need to also create ticket holder for the ticket

    observableCombineLatest(
      this._store.select(fromRoot.getLastAddedVoucherTicket),
      this._store.select(fromRoot.getExhibitionSettings),
      this._store.select(fromRoot.getTickets)
    )
      .pipe(
        filter(data => !!data[0] && !!data[1] && !!data[2]),
        debounceTime(50)
      )
      .subscribe(data => {
        this._store.dispatch(new ticketActions.RemoveLastVoucherAdded());
        const [lastVoucherTicket, settings, ungroupedTickets] = data;

        if (this._helperService.isSelfregistration()) {
          this.selectTicket(lastVoucherTicket);
        } else {
          // go thru tickets and sum price of selected ones.
          let numberOfSelectedVouchers = 0;
          let numberOfSelectedLimitedVouchers = 0;

          Object.keys(ungroupedTickets).forEach(key => {
            if (ungroupedTickets[key].hasOwnProperty('voucherType')) {
              numberOfSelectedVouchers += ungroupedTickets[key].count;
              if (ungroupedTickets[key].voucherType === 'limitedPromoCode') {
                numberOfSelectedLimitedVouchers += ungroupedTickets[key].count;
              }
            }
          });

          if (
            lastVoucherTicket.voucherType === 'oneTimeVoucher' || // there is no limitation for one time vouchers
            (settings.limitPromoCodes > numberOfSelectedVouchers &&
              lastVoucherTicket.voucherType === 'promoCode') ||
            (settings.limitLimitedPromocodes >
              numberOfSelectedLimitedVouchers &&
              lastVoucherTicket.voucherType === 'limitedPromoCode')
          ) {
            this.ticketCounterChanged(lastVoucherTicket.uniqueId, 1, false);
          } else {
            this.ticketCounterChanged(lastVoucherTicket.uniqueId, 0, false);
          }
        }
      });
  }

  getVoucherTicket(voucherRedeem: RedeemVoucherModel) {
    const getParamsArray = [];

    if (this._helperService.isSelfregistration()) {
      getParamsArray.push('sr=true');
    }
    getParamsArray.push(`uuid=${voucherRedeem.uuid}`);
    const getParams = '?' + getParamsArray.join('&');

    return this._http.get(
      `${environment.protocol}${environment.webApiUrl}/voucher/redeem-voucher/${voucherRedeem.eventId}/${voucherRedeem.voucherCode}${getParams}`
      //`${environment.apiUrl}/voucher/redeem-voucher/${voucherRedeem.eventId}/${voucherRedeem.voucherCode}`,
    );
  }

  getVoucherDetails(voucherRedeem: RedeemVoucherModel) {
    const getParamsArray = [];

    if (this._helperService.isSelfregistration()) {
      getParamsArray.push('sr=true');
    }

    const getParams = '?' + getParamsArray.join('&');

    return this._http.get(
      `${environment.protocol}${environment.webApiUrl}/voucher/voucher-details/${voucherRedeem.eventId}/${voucherRedeem.voucherCode}${getParams}`
    );
  }

  postTicketBooking(booking: PostTicketBookingModel) { 
    if (!booking.orderTicketBooking) {
      return of({
        groupId: booking.groupId,
        ticketTypeId: booking.ticketTypeId,
        ticketUniqueId: booking.uniqueId,
        availableTickets: 0,
        count: booking.count,
        ticketLimit: 0,
        isTicketSold: false,
        ticketPersonId: booking.ticketPersonId,
        sharedLimitTicketCount: booking.sharedLimitTicketCount 
      });
    }

    booking.count = booking.count + booking.sharedLimitTicketCount;
    return this._http.post(
      `${environment.protocol}${environment.webApiUrl}/order/ticket-booking`,
      //`${environment.apiUrl}/order/ticket-booking`,
      booking
    );
  }

  releaseAllVouchersAndTickets() {
    // before we reset reducers, if there are any claimed vouchers nad tickets, we need to release them

    observableCombineLatest([
      this._store.pipe(select(fromRoot.getSelectedExhibitionId)),
      this._store.pipe(select(fromRoot.getVoucherTickets)),
      this._store.pipe(select(fromRoot.getOrderUuid)),
      this._store.pipe(select(fromRoot.getTickets))
    ])
      .filter(data => {
        return !!data[0];
      })
      .first()
      .subscribe(data => {
        const [id, voucherTickets, uuid, tickets] = data;

        // release vouchers
        if (voucherTickets) {
          voucherTickets.forEach(voucherTicket => {
            const releaseVoucher: RedeemVoucherModel = {
              eventId: id,
              voucherCode: voucherTicket.voucherCode,
              countryCode: this._translateService.currentLang,
              uuid: uuid,
              ticketPersonId: voucherTicket.ticketPersonId
            };
            this._store.dispatch(
              new ticketActions.ReleaseVoucher(releaseVoucher)
            );
          });
        }

        // release tickets
        if (tickets) {
          const releasedTicketIds = [];
          const releaseTicketIds = Object.keys(tickets)
            .map(key => tickets[key])
            .reduce((releaseTicketIds, ticket) => {
              const ticketUniqueId = tickets[ticket.uniqueId].hasOwnProperty('voucherCode') ? ticket.uniqueId : `${ticket.groupId}_${ticket.id}`;
              
              if (!releaseTicketIds.includes(ticketUniqueId) && ticket.count > 0) {
                releaseTicketIds.push(ticketUniqueId);
              }
              
              return releaseTicketIds;
            }, []);

          Object.keys(tickets).forEach(key => {
            const ticket = tickets[key];
            const ticketUniqueId = ticket.hasOwnProperty('voucherCode') ? ticket.uniqueId : `${ticket.groupId}_${ticket.id}`;
            const releaseCurrentTicket = releaseTicketIds.includes(ticketUniqueId);
            const isCurrentTicketNotReleased = !releasedTicketIds.includes(ticketUniqueId);

            if (ticket.count || releaseCurrentTicket) {
              let postTicketBooking: PostTicketBookingModel = {
                eventId: Number(id),
                uuid: uuid,
                count: 0,
                groupId: ticket.groupId,
                ticketTypeId: ticket.id,
                ticketPersonId: ticket.ticketPersonId,
                uniqueId: ticket.uniqueId,
                sharedLimitTicketCount: 0,
                orderTicketBooking: releaseCurrentTicket && isCurrentTicketNotReleased
              };

              releasedTicketIds.push(ticketUniqueId);

              let voucherCode = '';
              if (ticket.hasOwnProperty('voucherCode')) {
                voucherCode = ticket.voucherCode;
              }
              
              this._store.dispatch(
                new ticketActions.PostTicketBooking({
                  postTicketBooking: postTicketBooking,
                  voucherCode: voucherCode,
                  ticketLimit: ticket.ticketLimit,
                  successCallback: () => {}
                })
              );
            }
          });
        }
      });
  }

  releaseVoucherCode(voucherRedeem: RedeemVoucherModel) {
    let selfReg = '';
    if (this._helperService.isSelfregistration()) {
      selfReg = '&sr=true';
    }

    let ticketPersonId = "";
    if (!!voucherRedeem.ticketPersonId) {
      ticketPersonId = `&ticketPersonId=${voucherRedeem.ticketPersonId}`;
    }

    return this._http
      .post(
        `${environment.protocol}${environment.webApiUrl}/voucher/reset-event-voucher/${voucherRedeem.eventId}/${voucherRedeem.voucherCode}?uuid=${voucherRedeem.uuid}${selfReg}${ticketPersonId}`,
        {}
      )
      .pipe(
        map(res => {
          return true;
        })
      );
  }

  getTicketTypes(
    eventId: number,
    preferedTicketPersonNr?: number,
    preferedTicketGroupNr?: number
  ) {
    let selfReg = '';
    if (this._helperService.isSelfregistration()) {
      selfReg = '&sr=true';
    }
    return this._http.get(
      `${environment.protocol}${environment.webApiUrl}/tickets/${eventId}/grouped-by-section?preferredTicketPersonNr=${preferedTicketPersonNr}&preferredTicketGroupNr=${preferedTicketGroupNr}${selfReg}`
    );
  }

  getVisitorFieldsForPersonalization(ticketId: number) {
    return this._http.get(
      `${environment.protocol}${environment.webApiUrl}/user/entry-tickets/${ticketId}`
    );
  }

  postVisitorFieldsFromPersonalization(ticketId: number, data: any) {
    return this._http.post(
      `${environment.protocol}${environment.webApiUrl}/user/entry-tickets/${ticketId}`,
      data
    );
  }

  postChangeTicketContingentDay(ticketId: number, day: any) {
    return this._http.post(
      `${environment.protocol}${environment.webApiUrl}/user/entry-tickets/${ticketId}/change-day-booking`,
      {
        day: day
      }
    );
  }

  getParkingTicketFee(
    eventId,
    ticketGroup,
    ticketPerson,
    since,
    until,
    validFormId
  ) {
    return this._http
      .get(
        `${environment.protocol}${environment.webApiUrl}/event/${eventId}/ticket-groups/${ticketGroup}/ticket-persons/${ticketPerson}/parking-fee?since=${since}&until=${until}`,
        { observe: 'response' }
      )
      .pipe(
        map(res => {
          return res;
        }),
        catchError(e => {
          const storedParkingTickets = getLocalStorageString(AppConstants.parkingTicketsReducer);
          let parsedParkingTickets =
            storedParkingTickets && JSON.parse(storedParkingTickets);

          parsedParkingTickets[validFormId] = {
            since: null,
            until: null,
            price: 0
          };

          parsedParkingTickets = { ...parsedParkingTickets };
          setLocalStorageObject(AppConstants.parkingTicketsReducer, parsedParkingTickets);
          return this._errorHandlingService.errorHandler(e);
        })
      );
  }

  postBookTicketForDay(data: ContingentBookingModel) {
    return this._http.post(
      `${environment.protocol}${environment.webApiUrl}/order/day-booking`,
      data
    );
  }

  postReleaseTicketForDay() {
    return this._store.pipe(
        select(fromRoot.getOrderUuid), 
        filter(data => !!data),
        first(),
      ).subscribe(uuid => 
      this._http.post(
        `${environment.protocol}${environment.webApiUrl}/order/day-booking/${uuid}/release`,
        {}
      ).subscribe(() => {
        consoleLog(`Releasing contingent tickets for uuid: ${uuid}`);
      })
    );
  }

  getSynchronizedBarcoodes(eventId) {
    return this._http
      .get(
        `${environment.protocol}${environment.webApiUrl}/tickets/${eventId}/sync-barcodes`,
        { observe: 'response' }
      )
      .pipe(
        map(res => {
          try {
            return res.status;
          } catch (err) {
            this._statusBarService.setStatus(err, 'error');
          }
        }),
        catchError(e => {
          return this._errorHandlingService.errorHandler(e);
        })
      );
  }

  postTicketHolderForm(data: VisitorFormModel) {
    let selfReg = '';
    if (this._helperService.isSelfregistration()) {
      selfReg = '?sr=true';
    }
    return this._http.post(
      `${environment.protocol}${environment.webApiUrl}/person/visitors-form${selfReg}`,
      data
    );
  }

  saveTicketHolderQuestionnaire(questionnarireData, userId, eventId) {
    return this._http.post(
      `${environment.protocol}${environment.webApiUrl}/user/${userId}/event/${eventId}/visitor-questionnaire`,
      questionnarireData
    );
  }

  getTicketByHolder(hash) {
    return this._http.get(
      `${environment.protocol}${environment.webApiUrl}/person/ticket-by-holder/${hash}`
    );
  }

  downloadTicket(hash) {
    return this._http.get(
      `${environment.protocol}${environment.webApiUrl}/person/download-ticket/${hash}`,
      {
        responseType: 'blob'
      }
    );
  }

  downloadMobileTicket(hash) {
    return this._http.get(
      `${environment.protocol}${environment.webApiUrl}/person/download-mobile-ticket/${hash}`,
      {
        responseType: 'blob'
      }
    );
  }

  downloadPassBook(hash) {
    return this._http.get(
      `${environment.protocol}${environment.webApiUrl}/person/download-passbook/${hash}`,
      {
        responseType: 'blob'
      }
    );
  }

  getTicketHolderQuestionnaire(eventId, args: number[]) {
    let selfReg = '';
    if (this._helperService.isSelfregistration()) {
      selfReg = '&sr=true';
    }

    const ticketPersonIds = args.reduce((accu, curr) => {
      return accu + `&ticketPersonIds=${curr}`;
    }, '');

    return this._http.get(
      `${environment.protocol}${environment.webApiUrl}/event/${eventId}/visitor-questionnaire?${selfReg}${ticketPersonIds}`
    );
  }

  checkDailyTicketPerEmailLimit(validateDailyTicketPerEmailLimitBody: ValidateDailyTicketPerEmailLimitBody) {
    return this._http.post(
      `${environment.protocol}${environment.webApiUrl}/order/validators/daily-ticket-per-email-limit`,
      validateDailyTicketPerEmailLimitBody
    );
  }

  ticketCounterChanged(
    ticketUniqueId: string,
    count: number,
    decrease: boolean
  ) {
    observableCombineLatest([
      this._store.pipe(select(fromRoot.getTickets)),
      this._store.pipe(select(fromRoot.getTicketHolderIndexes))
    ])
      .pipe(first())
      .subscribe(data => {
        const [flatTickets, ticketHolderIndexes] = data;
        
        const previousCountValue = flatTickets[ticketUniqueId].count;
        const newCountValue = count;
        const ticketDifference: number = newCountValue - previousCountValue;

        const numberOfAlreadyAssignedHolderIndexes = ticketHolderIndexes != null ? Object.keys(ticketHolderIndexes)
          .map(index => ticketHolderIndexes[index])
          .filter(indexTicketUniqueId => indexTicketUniqueId == ticketUniqueId).length : 0;

        const isTicketAddedByPackageCounter = numberOfAlreadyAssignedHolderIndexes == 0 && ticketDifference == 0 && !decrease;
        const ticketsAdded = isTicketAddedByPackageCounter ? newCountValue : ticketDifference;
 
        let holdersIndexes = this.getHolderIndexesByTicketUniqueId(
          flatTickets,
          ticketUniqueId
        );

        const ticket = flatTickets[ticketUniqueId];

        if (!decrease) {
          this._gtmService.pushAddToCart(ticket, newCountValue);
        } else {
          this._gtmService.pushRemoveFromCart(ticket, newCountValue);
        }

        if (!flatTickets[ticketUniqueId].parking) {
          // there is ticket removed (ticket count decreased)
          if (decrease || ticketsAdded < 0) {
            // for each ticket removed
            Array.from(Array(Math.abs(ticketsAdded)).keys()).forEach(() => {
              const toBeRemovedTicketIndex = holdersIndexes.pop();

              //as there are various reducers interactions when removing ticket, we need to do it one by one in different redux cycles
              setTimeout(
                ([removedIndex]) => {
                  this._store.dispatch(new ticketActions.RemoveTicketHolderIndex(ticketUniqueId));
                  this.removeTicketHolder(removedIndex);
                },
                0,
                [toBeRemovedTicketIndex]
              );
            });
            // there is new ticket added (ticket count increased)
          } else {
            // for each ticket added
            Array.from(Array(Math.abs(ticketsAdded)).keys()).forEach(() => {
              //as there are various reducers interactions when removing ticket, we need to do it one by one in different redux cycles
              setTimeout(
                () => {
                  this._store.dispatch(new ticketActions.SetTicketHolderIndex(ticketUniqueId));
                },
                0,
              );
            });
          }
        }

        this.updateTicketsCount(
          flatTickets,
          ticketUniqueId,
          holdersIndexes,
          count
        );
      });
  }

  getTicketByVoucherCode(
    voucherCode: string,
    flatTickets: TicketModel
  ): TicketByIdModel {
    const ticketUniqueId = Object.keys(flatTickets).find(
      (ticketUniqueId: string) => {
        return flatTickets[ticketUniqueId].voucherCode === voucherCode;
      }
    );

    return flatTickets[ticketUniqueId];
  }

  getHolderIndexesByTicketUniqueId(
    flatTickets: TicketModel,
    ticketUniqueId: string
  ) {
    return [...flatTickets[ticketUniqueId].holdersIndexes];
  }

  updateTicketsCount(
    flatTickets: TicketModel,
    ticketUniqueId: string,
    holderIndexes: Array<number>,
    value
  ) {
    // update the tickets flat object with new counts of the tickets, as comming from the counter component
    const newValue = {};

    newValue[ticketUniqueId] = {
      ...flatTickets[ticketUniqueId],
      count: value,
      holdersIndexes: holderIndexes
    };

    const newticketCounts: TicketModel = Object.assign(
      {},
      flatTickets,
      newValue
    );

    this._store.dispatch(new ticketActions.SetTickets(newticketCounts));
  }

  addTicketHolder(addedTicketIndex: number) {
    observableCombineLatest([
      this._store.pipe(select(fromRoot.getTicketsCount)),
      this._store.pipe(select(fromRoot.getExhibitionSettings)),
      this._store.pipe(select(fromRoot.getTicketSelectedSendingOptions)),
      this._store.pipe(select(fromRoot.getTitles)),
      this._store.pipe(select(fromRoot.getProfessions)),
      this._store.pipe(select(fromRoot.getDepartments)),
      this._store.pipe(select(fromRoot.getOccupationalGroups))
    ])
      .pipe(
          filter((data: [
            number,
            ExhibitionSettingModel,
            string,
            SelectOption[],
            SelectOption[],
            SelectOption[],
            SelectOption[]
          ]) => {
            const [
              ticketsCount,
              settings,
              selectedTicketSendingOptions,
              titles,
              professions,
              departments,
              occupationalGroups
            ] = data;

          return ticketsCount !== null && 
            !!settings && 
            !!selectedTicketSendingOptions && 
            !!titles && 
            !!professions && 
            !!departments && 
            !!occupationalGroups
        }),
        first()
      )
      .subscribe(([
        ticketsCount,
        settings,
        selectedTicketSendingOptions,
        titles,
        professions,
        departments,
        occupationalGroups
      ]) => {

        /* we need to avoid circular sore triggers (read > write > read > ...),
        thats why we load two following data sets from inside and not as part of combined observable */

        const ticketHolderAdditionalData = this.getTicketHolderAdditionalData();

        // create new object with additional data for workshops and menus
        ticketHolderAdditionalData[addedTicketIndex] = {
          workshops: [],
          menus: []
        };

        this._store.dispatch(
          new stepsActions.SetTicketHolderAdditionalData(
            ticketHolderAdditionalData
          )
        );

        // new forms to be created (create new ticket holder)
        const inputSet = prepareTicketHolderData(
          settings,
          selectedTicketSendingOptions,
          titles,
          professions,
          departments,
          occupationalGroups
        );
        const stepsFormsActionName = [
          'personal',
          `ticketHolder_${addedTicketIndex}`
        ];
        const buyerHolderPayload: FormInputsPayloadModel = {
          formInfo: stepsFormsActionName,
          index: addedTicketIndex,
          inputSet: {
            rerender: false,
            list: inputSet
          }
        };

        this._store.dispatch(new stepsActions.SetInputs(buyerHolderPayload));
        this._store.dispatch(new stepsActions.SetTicketHolderFormValidity({ formInfo: stepsFormsActionName, valid: false }));

        let formValid = false;

        if (
          this._helperService.isSelfregistration() ||
          !settings.isTicketHolderVisible ||
          (ticketsCount === 1 &&
            selectedTicketSendingOptions === 'mobilePerOwner')
        ) {
          formValid = true;
        }

        const anyFieldRequired =
          inputSet.find(input => {
            return input.required && !input.hidden && !input.disabled;
          }) || selectedTicketSendingOptions === 'ticketRetrivalLink';

        formValid = !anyFieldRequired || formValid;

        this._formsService.setFormValidity(
          formValid,
          null,
          stepsFormsActionName
        ); 
      });
  }

  checkIfAnonymousTicketTaken(ungroupedTickets: TicketModel): boolean {
    return !!ungroupedTickets && Object.keys(ungroupedTickets).some(ticket => {
      const ungroupedTicket = ungroupedTickets[ticket];
      if (ungroupedTicket.personalizationType === 'anonymous' && ungroupedTicket.voucherCode) {
        return true;
      }
    });
  }

  removeTicketHolder(toBeRemovedTicketIndex: number) {
    observableCombineLatest(
      this._store.select(fromRoot.getTicketHolderAdditionalData),
      this._store.select(fromRoot.getSelectedExhibitionId),
      this._store.select(fromRoot.getOrderUuid)
    )
      .pipe(
        filter(data => {
          return !!data[0] && !!data[1] && !!data[2];
        }),
        first()
      )
      .subscribe(data => {
        const [ticketHolderAdditionalData, eventId, orderUuid] = data;
        const stepsFormsActionName = [
          'personal',
          `ticketHolder_${toBeRemovedTicketIndex}`
        ];

        // remove the form and its input sets
        this._store.dispatch(new stepsActions.RemoveForm(stepsFormsActionName));
        this._store.dispatch(new stepsActions.RemoveTicketHolderForm(stepsFormsActionName));

        if (
          ticketHolderAdditionalData[toBeRemovedTicketIndex] &&
          ticketHolderAdditionalData[toBeRemovedTicketIndex].workshops
        ) {
          ticketHolderAdditionalData[toBeRemovedTicketIndex].workshops.map(
            workshopID => {
              let postData: WorkshopBookingPostModel = {
                eventId: eventId,
                workshopId: workshopID,
                seats: -1, // remo the booking
                uuid: orderUuid,
                ticketHolderId: toBeRemovedTicketIndex
              };

              this._store.dispatch(
                new additionalServicesActions.PostWorkshopBooking(
                  postData,
                  null
                )
              );
            }
          );
        }
        // just set the validity of the removed form set to be valid (true)
        this._formsService.removeStepValidation(stepsFormsActionName);

        // remove the addition (workshops and menu) data related to the ticketIndex and its ticket holder
        delete ticketHolderAdditionalData[toBeRemovedTicketIndex];

        this._store.dispatch(
          new stepsActions.SetTicketHolderAdditionalData(
            ticketHolderAdditionalData
          )
        );

        /* be sure after removing a ticket to start the carousel on position one
           oterwise it can happen that it want start on slide which no longer exist */
        this._store.dispatch(new ticketActions.SetActiveHolderSlideIndex(0));
      });
  }

  getTicketHolderAdditionalData() {
    let state;
    this._store
      .pipe(
        select(fromRoot.getTicketHolderAdditionalData), 
        first()
      )
      .subscribe(
        (data: TicketHolderAdditionalDataModel) => (state = data || {})
      );
    return state;
  }

  selectTicket(ticket: TicketByIdModel) {
    this._store
      .select(fromRoot.getTickets)
      .pipe(
        filter(tickets => {
          return !!tickets;
        }),
        first()
      )
      .subscribe((ungroupedTickets: TicketModel) => {
        // create a deep copy so we dont modify the original object in store
        ungroupedTickets = cloneDeep(ungroupedTickets);
        if (ticket.count) {
          this.ticketCounterChanged(ticket.uniqueId, 0, true);
        } else {
          Object.keys(ungroupedTickets).forEach(uniqueId => {
            if (
              ungroupedTickets[uniqueId] &&
              ungroupedTickets[uniqueId].count
            ) {
              //we have to comment out the setTimeout function as otherwise adding a newly selected ticket at SR would be executed before removing the previously selected ticket
              //which would mess up the lastTicketIndex:
              //setTimeout(() => {
                this.ticketCounterChanged(uniqueId, 0, true);
              //});
            }
          });
          setTimeout(() => {
            let ungroupedTicket = ungroupedTickets[ticket.uniqueId];

            if (ungroupedTicket && ungroupedTicket.availableTickets > 0) {
              this.ticketCounterChanged(ticket.uniqueId, 1, false);
            }
          });
        }
      });
  }

  removeHoldersAndBookings() {
    this._store.pipe(select(fromRoot.getTicketHolderInputSets)).first().subscribe(ticketHolders =>
      ticketHolders.forEach(ticketHolder => this.removeTicketHolder(ticketHolder.index))
    );

    this._store.dispatch(new ticketActions.RemoveTicketBookings());

    this._store.dispatch(
      new stepsActions.SetAddressCheckbox({
        checkedSlideIndex: null,
        isAddressCopied: false
      })
    );

    this._store.dispatch(
      new stepsActions.SetBuyerVisitorCheckbox({
        buyerVisitorCheckedSlideIndex: null,
        isBuyerVisitorChecked: false,
        showVisitorQuestionnaire: false
      })
    );
  }

}
