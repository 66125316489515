<!-- <div class="section-subtitle">
    <h3 data-translation="personalize.buyer-info.billing-address.section-title">{{ 'personalize.buyer-info.billing-address.section-title' | translate | hideTranslation }}</h3>
</div> -->
<!-- (ngSubmit)="onSubmit()" -->
<div class="input-wrapper form-wrapper"
     *ngIf="billingAddressList.length">
    <select (change)="onChange($event.target.value)"
        id="bilAddressSelect"
        [class.hasvalue]="selectedBuyerBillAddressId >= 0"
    >
        <option value="-1"></option>
        <option *ngFor="let address of billingAddressList; let i = index"
            [value]="i"
            [selected]="address.id == selectedBuyerBillAddressId"
        >
            {{ address.city }} - {{ address.street }} - {{ address.zipCode }}
        </option>
    </select>
    <label class="placeholder"
           for="bilAddressSelect"
           data-translation="personalize.buyer-info.billing-address.input-placeholder">{{
        'personalize.buyer-info.billing-address.input-placeholder' | translate | hideTranslation }}</label>
</div>

<form [formGroup]="form"
      class="row"
      novalidate
      *ngIf="form"
      [ngClass]="{'readonly': !isEditEnabled}"
      [class.hide-read-only]="true">
    <app-df-input *ngFor="let input of inputs"
                  [input]="input"
                  [class]="input.cssClass"
                  [inputSet]="inputs"
                  [action]="BillingAddressActionName"
                  [form]="form"
                  [saveFormCallback]="formSaveCallback"
                  [ngClass]="{'input-disabled': input.hidden}">
    </app-df-input>
</form>
