import { catchError, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ErrorHandlingService } from '../../shared/error-handling/error-handling.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../shared/error-handling/error-handling.service";
import * as i3 from "@ngx-translate/core";
var TranslationsService = /** @class */ (function () {
    function TranslationsService(_http, _errorHandlingService, _translateService) {
        this._http = _http;
        this._errorHandlingService = _errorHandlingService;
        this._translateService = _translateService;
    }
    /* rest api */
    TranslationsService.prototype.getDefaultTranslations = function () {
        var origin = environment.protocol + environment.origin;
        //origin = origin || window.location.origin;
        //let origin = window.location.origin;
        return (this._http
            // `${environment.protocol}${environment.origin}/assets/i18n/default.json`,
            .get(origin + '/assets/i18n/default.json'));
    };
    TranslationsService.prototype.getLangTranslations = function (lang, eventId) {
        var _this = this;
        var event = -1;
        if (eventId !== 'default') {
            event = eventId;
        }
        return this._http
            .get("" + environment.protocol + environment.webApiUrl + "/event/" + event + "/translations/admin/" + lang, { observe: 'response' })
            .pipe(map(function (resp) {
            return resp.body.frontend;
        }), catchError(function (e) {
            return _this._errorHandlingService.errorHandler(e);
        }));
    };
    TranslationsService.prototype.saveTranslations = function (activeLang, eventId, translations) {
        var event = -1;
        if (eventId !== 'default') {
            event = eventId;
        }
        return this._http.put("" + environment.protocol + environment.webApiUrl + "/event/" + event + "/translations/" + activeLang, translations);
    };
    /**
     * Get translation for position Dropdown and handle it with callback
     * @param input - InputBase holding option settings
     * @param positionData - select option data
     * @param handleCallback - function handling translation
     */
    TranslationsService.prototype.getPositionTranslationForOption = function (input, positionData, handleCallback) {
        var translationKey = positionData.value;
        if (translationKey) {
            this._translateService.get(translationKey).subscribe(function (translation) {
                handleCallback(translation);
            });
        }
        else {
            handleCallback(positionData.value);
        }
    };
    TranslationsService.ngInjectableDef = i0.defineInjectable({ factory: function TranslationsService_Factory() { return new TranslationsService(i0.inject(i1.HttpClient), i0.inject(i2.ErrorHandlingService), i0.inject(i3.TranslateService)); }, token: TranslationsService, providedIn: "root" });
    return TranslationsService;
}());
export { TranslationsService };
