<div class="container-fluid">
    <div class="page-content">
        <div class="page-padding-wrapper" appAdjustSideHeadline>
            <div class="section-headline">
                <h2 data-translation="registration.title"> {{ 'registration.title' | translate | hideTranslation }}</h2>
                <div class="section-headline-side" data-translation="registration.title">{{'registration.title' | translate | hideTranslation }}</div>

            </div>
            <div class="section-body">
                <app-edit-account-form [formType]="'new-account'"></app-edit-account-form>
            </div>
        </div>
    </div>
</div>