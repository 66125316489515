/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./widget-banner-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/widget-banner/widget-banner.component.ngfactory";
import * as i3 from "../../components/widget-banner/widget-banner.component";
import * as i4 from "../../../app/shared/services-with-reducers/helpers/helper.service";
import * as i5 from "@angular/common";
import * as i6 from "./widget-banner-page.component";
import * as i7 from "@angular/router";
import * as i8 from "@ngrx/store";
import * as i9 from "../../../app/shared/services-with-reducers/widget/widget.service";
var styles_WidgetBannerPage = [i0.styles];
var RenderType_WidgetBannerPage = i1.ɵcrt({ encapsulation: 0, styles: styles_WidgetBannerPage, data: {} });
export { RenderType_WidgetBannerPage as RenderType_WidgetBannerPage };
function View_WidgetBannerPage_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-widget-banner", [], null, null, null, i2.View_WidgetBannerComponent_0, i2.RenderType_WidgetBannerComponent)), i1.ɵdid(1, 114688, null, 0, i3.WidgetBannerComponent, [i4.HelperService], { widgetSettings: [0, "widgetSettings"], backgroundImage: [1, "backgroundImage"], link: [2, "link"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.widgetSettings; var currVal_1 = _co.backgroundImage; var currVal_2 = _co.link; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_WidgetBannerPage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_WidgetBannerPage_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.widgetSettings; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_WidgetBannerPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-widget-banner-page", [], null, null, null, View_WidgetBannerPage_0, RenderType_WidgetBannerPage)), i1.ɵdid(1, 245760, null, 0, i6.WidgetBannerPage, [i7.ActivatedRoute, i8.Store, i9.WidgetService, i4.HelperService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WidgetBannerPageNgFactory = i1.ɵccf("app-widget-banner-page", i6.WidgetBannerPage, View_WidgetBannerPage_Host_0, {}, {}, []);
export { WidgetBannerPageNgFactory as WidgetBannerPageNgFactory };
