<a #banner class="widget-banner" [ngStyle]="{'background-image': 'url(' + backgroundImage + ')'}" [href]="link"
    target="_blank">
    <div class="widget-banner-content">
        <p [ngStyle]="{color: widgetSettings.titleColor}" class="widget-banner-title"> {{widgetSettings.title}}</p>

        <p [ngStyle]="{color: widgetSettings.descriptionColor}" class="widget-banner-description">
            {{widgetSettings.description}}</p>
    </div>

    <div class="feedback-button">
        <button type="button"
            [ngStyle]="{backgroundColor: widgetSettings.button?.color, color: widgetSettings.button?.color}">
            <span [ngStyle]="{color: widgetSettings.button?.textColor}"
                class="continue-button-text">{{widgetSettings.button?.text}}</span>
        </button>
    </div>
</a>