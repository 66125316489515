import { Actions, ActionTypes } from './helper.actions';

import {
  TranslationsModel,
  EventSeriesModel,
  ZipCodeCities
} from './helper.interface';

import cloneDeep from 'lodash.clonedeep';

export interface State {
  allCountries: Array<string>;
  supportedLanguages: Array<string>;
  activeLanguage: string;
  apiCallResult: any;
  translations: TranslationsModel;
  selfRegistration: boolean;
  isEventSeriesPage: EventSeriesModel;
  modalIframeUrl: string;
  spinnerValue: boolean;
  translationsLoaded: boolean;
  citiesByZipcode: ZipCodeCities[];
  reloadRequired: boolean;
}

export const initialState: State = {
  allCountries: null,
  supportedLanguages: [],
  activeLanguage: 'de',
  apiCallResult: null,
  translations: null,
  selfRegistration: false,
  isEventSeriesPage: null,
  modalIframeUrl: null,
  spinnerValue: false,
  translationsLoaded: false,
  citiesByZipcode: [],
  reloadRequired: false
};

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.ADD_ALL_COUNTRIES_TO_LIST:
      const allCountries = action.payload;
      return {
        ...state,
        allCountries: allCountries
      };

    case ActionTypes.SET_SELFREGISTRATION: {
      const registration = action.payload;
      return {
        ...state,
        selfRegistration: registration
      };
    }

    case ActionTypes.SET_EVENTSERIESPAGE: {
      const eventSeriesPage = action.payload;
      return {
        ...state,
        isEventSeriesPage: eventSeriesPage
      };
    }

    case ActionTypes.SET_API_CALL_RESULT:
      const apiCallResult = action.payload;
      return {
        ...state,
        apiCallResult: apiCallResult
      };

    case ActionTypes.SET_ACTIVE_LANGUAGE:
      const activeLanguage = action.payload;
      return {
        ...state,
        activeLanguage: activeLanguage
      };

    case ActionTypes.SET_SUPPORTED_LANGUAGES:
      const supportedLanguages = action.payload;
      return {
        ...state,
        supportedLanguages: supportedLanguages
      };

    case ActionTypes.SET_TRANSLATIONS:
      const translations = action.payload;
      return {
        ...state,
        translations
      };
    case ActionTypes.SET_IFRAME_URL:
      const modalIframeUrl = action.payload;
      return {
        ...state,
        modalIframeUrl
      };

    case ActionTypes.SET_SPINNER_VALUE:
      const spinnerValue = action.payload;
      return {
        ...state,
        spinnerValue
      };

    case ActionTypes.SET_TRANSLATIONS_LOADED:
      const translationsLoaded = action.payload;
      return {
        ...state,
        translationsLoaded
      };

    case ActionTypes.SET_CITIES_WITH_SAME_ZIPCODE:
      const storeCitiesByZipcode = cloneDeep(state.citiesByZipcode);
      const payload: ZipCodeCities = action.payload;
      const indexOfFormToUpdate: number = storeCitiesByZipcode.findIndex(item => item.formPath === payload.formPath);
      
      // if values with form path exist in store, update that value else push new value to store
      if (indexOfFormToUpdate !== -1) {
        storeCitiesByZipcode[indexOfFormToUpdate] = payload;
      } else {
        storeCitiesByZipcode.push(payload);
      }
      
      return {
        ...state,
        citiesByZipcode: storeCitiesByZipcode
      };

    case ActionTypes.RESET_ZIPCODES_CITIES:
      return {
        ...state,
        citiesByZipcode: []
      }
    
    case ActionTypes.SET_RELOAD_REQUIRED:
      const reloadRequired = action.payload;
      return {
        ...state,
        reloadRequired
      }

    case ActionTypes.RESET_REDUCER:
      return initialState;

    default: {
      return state;
    }
  }
}

/**
 * Because the data structure is defined within the reducer it is optimal to
 * locate our selector functions at this level. If store is to be thought of
 * as a database, and reducers the tables, selectors can be considered the
 * queries into said database. Remember to keep your selectors small and
 * focused so they can be combined and composed to fit each particular
 * use-case.
 */

export const getAllCountries = (state: State) => state.allCountries;
export const getLanguage = (state: State) => state.activeLanguage;
export const getSupportedLanguages = (state: State) => state.supportedLanguages;
export const getTranslations = (state: State) => state.translations;
export const getSelfRegistration = (state: State) => state.selfRegistration;
export const isEventSeriesPage = (state: State) => state.isEventSeriesPage;
export const getModalIframeUrl = (state: State) => state.modalIframeUrl;
export const getSpinnerValue = (state: State) => state.spinnerValue;
export const getTranslationsLoaded = (state: State) => state.translationsLoaded;
export const getAllCitiesByZipCode = (state: State) => state.citiesByZipcode;
export const getReloadRequired = (state: State) => state.reloadRequired;