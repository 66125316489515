var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import * as fromRoot from '../../../app.reducer';
import * as ticketActions from '../../../shared/services-with-reducers/tickets/ticket.actions';
import { EventEmitter } from '@angular/core';
import * as TicketCounterEventEmitter from 'events';
import { combineLatest, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
var PackagesService = /** @class */ (function () {
    function PackagesService(_store) {
        this._store = _store;
        this.packageContentCountChangedEvent = new TicketCounterEventEmitter();
        this.enableDisabledPackageEvent = new EventEmitter();
    }
    PackagesService.prototype.packageContentCountChanged = function (packageContent, decrease) {
        var _this = this;
        packageContent.packageGroups.forEach(function (packageGroup) {
            packageGroup.products.forEach(function (product) { return _this.packageContentCountChangedEvent.emit("" + product.ticket.uniqueId, packageContent, decrease); });
        });
    };
    PackagesService.prototype.enableDisabledPackage = function () {
        this.enableDisabledPackageEvent.emit();
    };
    PackagesService.prototype.updatePackageContents = function (changedPackage, count, decrease) {
        var _this = this;
        combineLatest([
            this._store.pipe(select(fromRoot.getTickets)),
            this._store.pipe(select(fromRoot.getTicketsTypes)),
            this._store.pipe(select(fromRoot.getLastPackageIndex))
        ])
            .filter(function (_a) {
            var flatTickets = _a[0], ticketTypes = _a[1];
            return !!flatTickets && !!ticketTypes;
        })
            .first()
            .subscribe(function (_a) {
            var flatTickets = _a[0], ticketTypes = _a[1], lastPackageIndex = _a[2];
            var existingPackagesLastIndex = lastPackageIndex;
            var updatedFlatTickets = __assign({}, flatTickets);
            var updatedTicketTypes = ticketTypes.slice();
            var ticketTypeToUpdate = updatedTicketTypes.filter(function (ticketType) {
                return ticketType.groupId == 0 && ticketType.products.find(function (ticketTypeProduct) {
                    return ticketTypeProduct.package.packageNumber == changedPackage.packageNumber;
                });
            })[0];
            var _loop_1 = function (i) {
                var packageContents = changedPackage.contents;
                var lastPackageContent = packageContents[packageContents.length - 1];
                if (!decrease) {
                    setTimeout(function () {
                        existingPackagesLastIndex++;
                        var newPackageContent = {
                            packageIndex: existingPackagesLastIndex,
                            packageGroups: [],
                            total: 0
                        };
                        lastPackageContent.packageGroups.forEach(function (packageGroup) {
                            if (packageGroup.products.length) {
                                var newPackageGroup_1 = __assign({}, packageGroup);
                                newPackageGroup_1.products = [];
                                packageGroup.products.forEach(function (product) {
                                    if (!!product.ticket) {
                                        var newProduct = __assign({}, product);
                                        var newTicket = __assign({}, newProduct.ticket);
                                        var newUniqueId = newTicket.uniqueId.replace("PI" + lastPackageContent.packageIndex, "PI" + existingPackagesLastIndex);
                                        newTicket.uniqueId = newUniqueId;
                                        newProduct.ticket = newTicket;
                                        newPackageGroup_1.products.push(newProduct);
                                        var newFlatTicket = __assign({}, updatedFlatTickets[product.ticket.uniqueId]);
                                        newFlatTicket.uniqueId = newUniqueId;
                                        newFlatTicket.count = _this.setPackageTicketAmount(newFlatTicket.packageSettings, 0);
                                        newFlatTicket.packageSettings = newTicket.packageSettings;
                                        newFlatTicket.holdersIndexes = [];
                                        newFlatTicket.packageIndex = existingPackagesLastIndex;
                                        if (newFlatTicket.hasOwnProperty('days')) {
                                            newFlatTicket.days = [];
                                        }
                                        updatedFlatTickets[newUniqueId] = newFlatTicket;
                                    }
                                });
                                newPackageContent.packageGroups.push(newPackageGroup_1);
                            }
                        });
                        ticketTypeToUpdate.products.forEach(function (ticketTypeProduct) {
                            ticketTypeProduct.package.contents.push(newPackageContent);
                        });
                        _this._store.dispatch(new ticketActions.SetLastPackageIndex(existingPackagesLastIndex));
                    }, 0);
                }
                else {
                    _this.packageContentCountChanged(lastPackageContent, decrease);
                    lastPackageContent.packageGroups.forEach(function (packageGroup) { return packageGroup.products.forEach(function (product) {
                        if (product.ticket != null && updatedFlatTickets[product.ticket.uniqueId] != null) {
                            delete updatedFlatTickets[product.ticket.uniqueId];
                        }
                    }); });
                    packageContents.pop();
                }
                i++;
                out_i_1 = i;
            };
            var out_i_1;
            for (var i = 0; i < count;) {
                _loop_1(i);
                i = out_i_1;
            }
            setTimeout(function () {
                _this._store.dispatch(new ticketActions.SetTicketsTypesAction(updatedTicketTypes, true));
                _this._store.dispatch(new ticketActions.SetTickets(updatedFlatTickets));
            }, 0);
            _this.tickets = updatedFlatTickets;
        });
        return of(this.tickets);
    };
    PackagesService.prototype.getPackageCount = function (changedPackage, count) {
        var packageContentSize = changedPackage.contents.length;
        if (packageContentSize) {
            var isPackageTicketAddedToCart_1 = false;
            if (packageContentSize == 1 && count == 1) {
                this._store.pipe(select(fromRoot.getTickets))
                    .filter(function (data) { return !!data; })
                    .first()
                    .subscribe(function (flatTickets) {
                    isPackageTicketAddedToCart_1 = changedPackage.contents
                        .find(function (product) { return typeof product !== 'undefined'; }).packageGroups
                        .some(function (packageGroup) { return packageGroup.products
                        .some(function (product) { return flatTickets[product.ticket.uniqueId].count > 0; }); });
                });
            }
            if (!isPackageTicketAddedToCart_1 && count == 0) {
                return 0;
            }
            return count;
        }
        return 0;
    };
    PackagesService.prototype.getPackageMinimalTicketCount = function (changedPackage) {
        var minimalTicketCount = 0;
        if (changedPackage != null) {
            if (changedPackage.contents.length) {
                changedPackage.contents[0].packageGroups.forEach(function (packageGroup) {
                    if (packageGroup.products.length) {
                        packageGroup.products.forEach(function (product) {
                            if (!product.ticket.isVoucher) {
                                var minAmount = product.ticket.packageSettings.minAmount;
                                minimalTicketCount += minAmount != null ? minAmount : product.ticket.packageSettings.amount;
                            }
                        });
                    }
                });
            }
        }
        return minimalTicketCount;
    };
    PackagesService.prototype.disableAddingPackageOnTicketLimit = function (changedPackage) {
        var _this = this;
        var packageValidation = {
            isDisabled: false,
            disableNext: false
        };
        combineLatest([
            this._store.pipe(select(fromRoot.getTickets)),
            this._store.pipe(select(fromRoot.getTicketsBooking)),
            this._store.pipe(select(fromRoot.getExhibitionSettings))
        ])
            .first()
            .subscribe(function (_a) {
            var tickets = _a[0], ticketsBooking = _a[1], exhibitionSettings = _a[2];
            if (changedPackage != null) {
                if (changedPackage.contents.length) {
                    changedPackage.contents[0].packageGroups.forEach(function (packageGroup) {
                        if (packageGroup.products.length) {
                            packageGroup.products.forEach(function (product) {
                                if (!product.ticket.isVoucher) {
                                    var ticket = tickets[product.ticket.uniqueId];
                                    var amount = ticket.packageSettings.fixedAmount ? ticket.packageSettings.amount : ticket.packageSettings.minAmount;
                                    if (amount == 0 || packageValidation.isDisabled) {
                                        return;
                                    }
                                    var bookedTicketsCount = ticketsBooking.bookings
                                        .filter(function (booking) { return booking.groupId === product.ticket.ticketTypeId && booking.ticketTypeId === product.ticket.personTypeId; })
                                        .reduce(function (totalCount, booking) { return totalCount + booking.count; }, 0);
                                    packageValidation.isDisabled = ticket.availableTickets == 0 || bookedTicketsCount + amount > ticket.availableTickets;
                                    packageValidation.disableNext = packageValidation.disableNext || ticket.availableTickets == 0 || bookedTicketsCount + amount * 2 > ticket.availableTickets;
                                }
                            });
                        }
                    });
                }
            }
            if (!packageValidation.isDisabled && exhibitionSettings && exhibitionSettings.limitBoughtTickets != null) {
                var ticketLimit = exhibitionSettings.limitBoughtTickets;
                var minimalTicketCount = _this.getPackageMinimalTicketCount(changedPackage);
                var alreadyBookedTicketsCount = Object.keys(tickets).map(function (ticketUniqueId) { return tickets[ticketUniqueId]; }).reduce(function (bookingsTotal, booking) { return bookingsTotal + booking.count; }, 0);
                var minimalTotalTicketCount = alreadyBookedTicketsCount + minimalTicketCount;
                packageValidation.isDisabled = ticketLimit < minimalTotalTicketCount;
                packageValidation.disableNext = packageValidation.disableNext || ticketLimit < minimalTotalTicketCount + minimalTicketCount;
            }
        });
        return packageValidation;
    };
    PackagesService.prototype.setPackageTicketAmount = function (packageSettings, previousValue) {
        var minAmount = packageSettings.minAmount;
        var maxAmount = packageSettings.maxAmount;
        var defaultAmount = minAmount != null ? minAmount : packageSettings.amount;
        var amount = previousValue != null && previousValue ? previousValue : defaultAmount;
        var shouldSetMinTicketAmount = minAmount != null && minAmount > amount;
        var shouldSetMaxTicketAmount = maxAmount != null && maxAmount < amount;
        var ticketAmount = amount;
        if (shouldSetMaxTicketAmount) {
            ticketAmount = maxAmount;
        }
        if (shouldSetMinTicketAmount) {
            ticketAmount = minAmount;
        }
        return ticketAmount;
    };
    PackagesService.ngInjectableDef = i0.defineInjectable({ factory: function PackagesService_Factory() { return new PackagesService(i0.inject(i1.Store)); }, token: PackagesService, providedIn: "root" });
    return PackagesService;
}());
export { PackagesService };
