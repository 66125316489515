import { debounceTime, distinctUntilChanged, filter, first, takeUntil } from 'rxjs/operators';
import * as fromRoot from '../../../app.reducer';

import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2
} from '@angular/core';

import { AppConstants } from '../../app-constants';
import { FormGroup } from '@angular/forms';
import { FormInputsPayloadModel } from '../../services-with-reducers/step-forms/step.interface';
import { FormsService } from '../forms.service';
import { HelperService } from '../../services-with-reducers/helpers/helper.service';
import { InputBase } from '../inputs/input-base.class';
import { select, Store } from '@ngrx/store';
import cloneDeep from 'lodash.clonedeep';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { getLocaleDateFormat } from '@angular/common';
import { TextOrDropdownInputTypes, ZipCodeCities } from '../../services-with-reducers/helpers/helper.interface';
import { ValidationService } from '../../validation/validation.service';
import { TextInput } from '../inputs/input-text.class';
declare var google: any;

@Component({
  moduleId: module.id,
  selector: 'app-df-input',
  templateUrl: './df-input.component.html',
  styleUrls: ['./df-input.component.scss']
})
export class DfInputComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  input: InputBase<any>;
  @Input()
  inputSet: InputBase<any>[];
  @Input()
  action: string[];
  @Input()
  form: FormGroup;
  @Input()
  saveFormCallback: Function;
  @Input()
  autoFillEmail: boolean;
  @Input()
  showCalendarButton: boolean = true;
  @Output()
  onSubmit = new EventEmitter<string>();
  @Output()
  onInputChanged = new EventEmitter<string>();

  public formPath: string;
  public isLoading = false;
  private addressFocused: { input: InputBase<any>; value: string } = null;
  private countries;
  private valueChanged$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _unsubscribe = new Subject<void>();
  private isDateInput: boolean;
  private dateFormat: string;
  public minYearRange: number = 1900;
  public maxYearRange: number = new Date().getFullYear();
  public minDate = new Date(this.minYearRange, 0, 1);
  public maxDate = new Date();
  public datePlaceHolder: string;
  public datePickerUniqueClass: string;
  public zipCodeUniqueClass: string;
  public zipCodeCities: string[] = [];
  public showZipCodeCities: boolean = false;
  public zipCode: string;
  private zipCodeCitiesByFormPath: string;
  private numberOfIntervalReps: number = 20;
  private subscriptions: Subscription = new Subscription();
  public hasTextOrDropdownTypeOption: boolean = false;

  /*
    NOTE:
    If you experience unexpected value, status, validation changes,
    in most cases input and inputSet is MUTATED in the parent component but
    input / inputSet (properties) in some cases is replaced by IMMUTABLE object (in df-input.component / parent component)
  */

  constructor(
    private _formsService: FormsService,
    private _helperService: HelperService,
    private _store: Store<fromRoot.State>,
    private el: ElementRef,
    private renderer: Renderer2,
    private _validationService: ValidationService
  ) {}

  ngOnInit() {
    this.formPath = this.action.join('.');
    this.hasTextOrDropdownTypeOption = Object.keys(TextOrDropdownInputTypes)
      .map(typeKey => TextOrDropdownInputTypes[typeKey])
      .includes(this.input.key);

    this.datePickerUniqueClass = this.action.length > 1 ? this.action.join('_') : this.action[0];
    this.isDateInput = this.input.controlType === 'dateInput';
    if (this.isDateInput) {
      this._store.pipe(select(fromRoot.getLanguage)).subscribe(activeLanguage => {
        this.dateFormat = activeLanguage === 'en' ? 'dd.mm.yy' : getLocaleDateFormat(activeLanguage, 0).toLowerCase();
      });
    }

    this.getCountriesAndTranslate();

    this.valueChanged$
      .pipe(
        takeUntil(this._unsubscribe),
        filter(data => !!data && !!data.target),
        distinctUntilChanged((_prevValue, currValue) => {
          return currValue.target.value === this.input.value;
        })
      )
      .subscribe(newValue => {
        this.onInputChanged.emit(this.input.key);
      });

    this.valueChanged$
      .pipe(
        takeUntil(this._unsubscribe),
        filter(data => !!data && !!data.target),
        debounceTime(1000),
        distinctUntilChanged((_prevValue, currValue) => {
          return currValue.target.value === this.input.value;
        })
      )
      .subscribe(newValue => {
        this.lostFocus(this.input.controlType, this.input.key, null, newValue);
      });

    // execute code only if we're on zipcode input and if we have country input in current form
    if (this.input.key === 'zipCode' && this.inputSet.find(item => item.key === 'country')) {
      // needed for carousel, without this dropdown will be cut off by carousel css (see function changeVisibilityOfCarouselElements)
      this.zipCodeUniqueClass = this.action.length > 1 ? `${this.action.join('_')}_${this.input.key}` : this.action[0];
      // set zipcode cities dropdown, but don't set value if only 1 value is found
      this.setCityBasedOnZip(true);

      // subscribe to this form country input changes and set city if needed 
      this.subscriptions.add(
        this._validationService.formCountryInputChanged$.filter(item => !!item).subscribe(item => {
          if (item === this.zipCodeCitiesByFormPath) {
            this.setCityBasedOnZip(false);
            this._validationService.formCountryInputChangedBH$.next(null);
          }
        })
      );
    }
  }

  ngOnChanges() {
    if (
      this.input.key === 'country' &&
      this.input['options'].length === 0 &&
      this.countries
    ) {
      this.input['options'] = cloneDeep(this.countries);
    }
  }

  getCountriesAndTranslate() {
    if (this.input.key === 'country') {
      this._store
        .select(fromRoot.getAllCountriesList)
        .pipe(first())
        .subscribe(list => {
          this._formsService.translateCountries(list).subscribe(subs => {
            subs.subscribe(countries => {
              this.countries = countries;
              this.input['options'] = cloneDeep(this.countries);
            });
          });
        });
    }
  }

  selectClicked($event) {
    if (
      this.input.key === 'country' &&
      this.input['options'].length === 0 &&
      this.countries
    ) {
      this.input['options'] = cloneDeep(this.countries);
      $event.target.blur();
      $event.target.focus();
    }
  }

  ngOnDestroy() {
    //if address autocomplete field was focused when the page was thrown away, save the last value
    if (this.addressFocused) {
      this.newInputValue(
        this.addressFocused.input.controlType,
        this.addressFocused.input.key,
        null,
        this.addressFocused.value
      );
    }

    this._unsubscribe.next();
    this._unsubscribe.complete();
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit() {
    if (this.formPath === 'personal.privacy') {
      const privacyPolicyLink = this.el.nativeElement.querySelector('a');
      if (privacyPolicyLink) {
        privacyPolicyLink.addEventListener('click', e => {
          e.preventDefault();
          this._helperService.openIframe(e.target.href);
        });
      }
    }
  }

  lostFocus(type, inputId, optionId, event) {
    if (!this.isDateInput && event.target.value !== this.input.value) {
      this.newInputValue(type, inputId, optionId, event.target.value);
    } else if (this.isDateInput) {
      this.newInputValue(type, inputId, optionId, event)
    }
  }

  checkCheckboxLimit(inputId, optionId, event) {
    const value = this.form.controls[inputId].value;
    const countSelectedOptions = Object.keys(value).filter(
      option => value[option]
    ).length;

    if (
      !value[`${inputId}_${optionId}`] &&
      this.input.selectionLimit &&
      countSelectedOptions >= this.input.selectionLimit
    ) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  newInputValue(type, inputId, optionId, val?) {
    const value = val || this.form.controls[inputId].value;
    const updatedInput = Object.assign({}, this.input);

    if (type === 'checkbox') {
      const countSelectedOptions = Object.keys(value).filter(
        option => value[option]
      ).length;
      const updatedOptions = updatedInput.options.map(option => {
        const updatedOption = Object.assign({}, option);

        if (
          !updatedInput.selectionLimit ||
          countSelectedOptions <= updatedInput.selectionLimit
        ) {
          // if updated input doesnt have selection limit
          if (optionId !== null && updatedOption.key === optionId) {
            updatedOption.value = !updatedOption.value;
          }
        }

        return updatedOption;
      });

      updatedInput.options = updatedOptions;
    } else if (
      (updatedInput.key === 'email' ||
        updatedInput.key === 'verifyEmail' ||
        updatedInput.key === 'verify-email') &&
      value
    ) {
      this.form.controls[inputId].setValue(value.replace(/\s/g, ''));
      updatedInput.value = value.toLowerCase().replace(/\s/g, '');
    } else {
      const date = this.isDateInput ? value : null;
      if (!!date && !(value instanceof Date)) {
          updatedInput.value = null;
      } else {
        updatedInput.value = value;
      }
    }

    let updatedInputSet = this.inputSet.map((input: InputBase<any>) => {
      if (this.input.key === input.key) {
        return updatedInput;
      } else {
        return input;
      }
    });

    if (updatedInput.key === 'telephone' && value) {
      updatedInput.value = value.replace(/\s/g, ''); //remove space characters in phone input
    }

    if (updatedInput.key === 'zipCode') {
      if (!value) { // if there's no value, hide dropdown, and remove cities from zipCodeCities
        this.showZipCodeCitiesDropdown(false, true);
      } else {
        this.setCityBasedOnZip(false, true);
      }
    }

    if (updatedInput.key === 'country' && value) {
      const telephone = this.inputSet.find(input => {
        return input.key === 'telephone';
      });
      if (telephone) {
        const prevCountry = this.inputSet.find(input => {
          return input.key === 'country';
        }).value;

        const newCountry = updatedInput.value;

        let prevCountryPrecall = '';
        if (prevCountry) {
          prevCountryPrecall = AppConstants.PHONE_PRECALL[prevCountry];
        }
        const newCountryPrecall = AppConstants.PHONE_PRECALL[newCountry];
        // if there is telephone input field
        if (
          !telephone.hasOwnProperty('value') ||
          telephone.value === '' ||
          telephone.value === prevCountryPrecall
        ) {
          updatedInputSet = this.updateAddressInput(
            updatedInputSet,
            'telephone',
            newCountryPrecall
          );
        }
      }
      const zipCode = this.inputSet.find(item => item.key === 'zipCode');
      // if there is zipCode input in this form and it has value
      if (!!zipCode && zipCode.value) {

        // if citiesByZipCodeFromPath is not yet set, set it to this forms zipCode input path
        if (!this.zipCodeCitiesByFormPath) {
          this.findZipCodesByFormPath([], zipCode);
        }

        // set zipCode validator to value so it fires store action
        this._validationService.zipCodeValidatorFn(zipCode.value, value, null, this.zipCodeCitiesByFormPath, true);
      }
    }

    //In case the sendtoowner field is updated to checked, we make email and verifyEmail fields required.
    //If it is unchecked, we set the original requirements for email and verifyEmail fields.
    //Checkbox sendtoowner won't appear if email field is not visible
    if (updatedInput.key === 'sendtoowner') {
      const isSendToOwnerChecked = this.form.controls['sendtoowner'].value['sendtoowner_toowner'];
      const email = this.inputSet.find(input => input.key === 'email');
      const verifyEmail = this.inputSet.find(input => input.key === 'verifyEmail');
      const originalEmailRequired = this._helperService.getOriginalEmailValues('email');
      const originalVerifyEmailRequired = this._helperService.getOriginalEmailValues('verifyEmail');

      //In case isSendToOwnerChecked and email field exists and it isn't yet required,
      //we set email field to required
      if (!!isSendToOwnerChecked && !!email && (!originalEmailRequired || !originalVerifyEmailRequired)) {
        email.required = true;

        //If verifyEmail field exists, we also make it required.
        if (!!verifyEmail) {
          verifyEmail.required = true;
        }
        //If isSendToOwnerChecked is unchecked, and email exists, we set it to the original value.
      } else if (!isSendToOwnerChecked && !!email) {
        email.required = originalEmailRequired;

        //If verifyEmail field exists, we set it to original value.
        if (!!verifyEmail) {
          verifyEmail.required = originalVerifyEmailRequired;
        }
      }
    }

    const shouldPrerender =
      ['textbox' /* , 'checkbox' */].indexOf(type) >= 0 ? false : true;
    const actionPayload: FormInputsPayloadModel = {
      formInfo: this.action,
      inputSet: {
        rerender: shouldPrerender,
        list: updatedInputSet,
        updatedInputs: [ updatedInput.key ]
      }
    };

    if (this.saveFormCallback) {
      actionPayload.callback = this.saveFormCallback;
    }
    this._formsService.mergeInputsBeforeSave(actionPayload);
  }

  /**
   * Sets zipcode cities, if 1 city with given zipcode is found, automatically set country input, else show dropdown with cities
   * @param skipSettingCityAndZipCode Skip setting zipcode if only 1 city per is found (needed for ngOnInit)
   * @param showZipCodeCities show or hide zipcode cities dropdown
   */
  setCityBasedOnZip(skipSettingCityAndZipCode?: boolean, showZipCodeCities?: boolean) {
    this.subscriptions.add(
      this._store.pipe(select(fromRoot.getAllCitiesByZipCode), filter(item => !!item), first())
      .subscribe(zipCodeCities => {
        const foundByFormPath: ZipCodeCities = this.findZipCodesByFormPath(zipCodeCities, this.input);
        const isCountryInputFilled: InputBase<any> = this.inputSet.find(input => input.key === 'country' && !!input.value);
        const isCityFieldActive: boolean = this.inputSet.some(input => input.key === 'city');

        if (!!foundByFormPath && !!isCountryInputFilled && isCityFieldActive) {

          const countryCodeShort: string = isCountryInputFilled ? isCountryInputFilled.value : '';
          const inputValue: string = this.form.controls[this.input.key].value;

          let intervalCounter: number = this.numberOfIntervalReps;

          // interval is needed because after refresh on profile component, google is not defined for a while
          let interval = setInterval(() => {
            const isGoogleLoaded: boolean = !!window["googleMapsLoaded"];

            //reduce intervalCounter each time interval fires
            intervalCounter--;

            // if google is loaded or intervalCounter reached zero, clear interval
            // interval reaches zero if interval fired 20 times which equals to 2000ms
            // if interval is cleared, set intervalCounter to its default value
            if (isGoogleLoaded || intervalCounter === 0) {
              clearInterval(interval);
            }

            if (countryCodeShort && !!inputValue && isGoogleLoaded) {
              let geocoder = new google.maps.Geocoder();
              geocoder.geocode(
                {
                  componentRestrictions: {
                    country: countryCodeShort,
                    postalCode: inputValue
                  }
                },
              (result, status) => {
                if ((status === 'OK' && result.length) || !!foundByFormPath.cities.length) {
                  if (status === 'OK' && result.length) {
                    const addressComponents = result[0].address_components;
                    let zipcodeFound = false;
                    let city = '';

                    for (let i = 0; i < addressComponents.length; i++) {
                      const addressType = addressComponents[i].types[0];

                      if (
                        addressType === 'postal_code' &&
                        inputValue
                        .replace(' ', '')
                          .toUpperCase() ===
                          addressComponents[i].long_name
                          .replace(' ', '').toUpperCase()
                      ) {
                        zipcodeFound = true;
                      }

                      if (
                        addressType === 'locality' ||
                        addressType === 'administrative_area_level_2'
                      ) {
                        city = addressComponents[i]['long_name'];
                      }

                      if (!city && addressType === 'administrative_area_level_1') {
                        city = addressComponents[i]['long_name'];
                      }
                    }

                    if (zipcodeFound && city) {
                      // if we got cities from store(database)
                      if (!!foundByFormPath.cities.length) {
                        this.zipCodeCities = [...foundByFormPath.cities];
                      } else {
                        // make dropdown visible, but remove zipCodes, which will make dropdown invisible until zipCodes are filled
                        this.showZipCodeCitiesDropdown(true, true);
                      }

                      // push city from geocoder if it's not in zipCodeCities already, also sort array after city is pushed
                      this.zipCodeCities.some(item => item === city) ? null : this.zipCodeCities.push(city), this.zipCodeCities = this._helperService.sortValuesAsc(this.zipCodeCities);

                       // if there's only one value in zipCodeCities automatically update city input
                      if (this.zipCodeCities.length === 1 && this.inputSet.find(item => item.key === 'city').value !== this.zipCodeCities[0] && !skipSettingCityAndZipCode) {
                        this.setCityAndZipCode(city, inputValue);
                      }
                    } else {
                      // if city does not exists, means user entered something like this "43000," geocoder will return value for 43000, but API won't so we empty dropdown values
                      this.zipCodeCities = [];
                      showZipCodeCities = false;
                      this.zipCode = null;
                    }
                  } else {
                    this.zipCodeCities = [...foundByFormPath.cities];
                  }
                  // sets zipCode which is displayed in dropdown
                  this.zipCode = foundByFormPath.zipCode;
                  if (!!showZipCodeCities) {
                    this.showZipCodeCities = showZipCodeCities;
                  }
                } else {
                  this.showZipCodeCitiesDropdown(false, true);
                }
              });
            } else {
              if (isGoogleLoaded) {
                this.showZipCodeCitiesDropdown(false, true);
              }
            }
          }, 100);
        }
      })
    );
  }

  updateAddressInput(
    updatedInputSet: InputBase<any>[],
    inputID: string | number,
    newInputValue: string
  ): InputBase<any>[] {
    return updatedInputSet.map((input: InputBase<any>) => {
      if (inputID === input.key) {
        return {
          ...input,
          value: newInputValue
        };
      } else {
        return input;
      }
    });
  }

  addressKeyup(input, e) {
    this.addressFocused = { input, value: e.target.value };
  }

  addressChange(place, keyOfTriggeredInput: string) {
    this.addressFocused = null;
    let updatedInputSet = this.inputSet;
    // in case we got some data from google places API
    // keyOfTriggeredInput is either 'address' or 'company'

    if (typeof place === 'object') {
      if (keyOfTriggeredInput === 'address') {
        updatedInputSet = this.updateAddressInput(
          updatedInputSet,
          keyOfTriggeredInput,
          place.formatted_address
        );
      } else if (keyOfTriggeredInput === 'company') {
        updatedInputSet = this.updateAddressInput(
          updatedInputSet,
          keyOfTriggeredInput,
          place.name
        );
      }

      let streetNumber = '';
      let street = '';
      let city = '';
      let zip = '';
      let country = ''; // ensure that 0 is none in selectbox (empty option)

      let premise: string = '';
      let neighborhood: string = '';
      let sublocalityLevel1: string = '';
      let administrativeAreaLevel1: string = '';
      let administrativeAreaLevel2: string = '';

      if (place.hasOwnProperty('address_components')) {
        for (let i = 0; i < place.address_components.length; i++) {
          const addressType = place.address_components[i].types[0];

          if (addressType === 'street_number') {
            streetNumber = place.address_components[i]['short_name'];
          } else if (addressType === 'premise') {
            premise = place.address_components[i]['short_name'];
          } else if (addressType === 'route') {
            street = place.address_components[i]['long_name'];
          } else if (addressType === 'neighborhood') {
            neighborhood = place.address_components[i]['long_name'];
          } else if (addressType === 'sublocality_level_1') {
            sublocalityLevel1 = place.address_components[i]['long_name'];
          } else if (addressType === 'locality') {
            city = place.address_components[i]['long_name'];
          } else if (addressType === 'administrative_area_level_2') {
            administrativeAreaLevel2 = place.address_components[i]['long_name'];
          } else if (addressType === 'administrative_area_level_1') {
            administrativeAreaLevel1 = place.address_components[i]['long_name'];
          } else if (addressType === 'postal_code') {
            zip = place.address_components[i]['short_name'].replace(' ', '');
          } else if (addressType === 'country') {
            country = place.address_components[i]['short_name'];
          }
        }
      }

      if (!streetNumber && premise) {
          //workaround for exotic Czech addresses - no street_number address type (bug #2743):
          //(search for company: Jizba Luhacovice)
          streetNumber = premise;
      }

      if (!city) {
        if (neighborhood) {
          //workaround for exotic US addresses - no locality address type (bug #2743):
          //(search for company: Astoria Park, Astoria NY)
          city = neighborhood;
        } else if (sublocalityLevel1) {
          //workaround for exotic Czech addresses - no locality and neighborhood address types (bug #2743):
          //(search for company: Jizba Luhacovice)
          city = sublocalityLevel1;
        }
      }

      // if address is filled by google company autocomplete, hide address field
      const fullAddress = streetNumber && street && city && zip && country;
      const address = updatedInputSet.find(input => input.key === 'address');
      if (address) {
        if (fullAddress && keyOfTriggeredInput === 'company') {
          address.hidden = true;
          // just uncomment this if you want to remove the empty space after the input (it can cause form breaking into differnt layout)
          // address.cssClass = address.cssClass + ' col-hidden';
        } else {
          address.hidden = false;
          // just uncomment this if you want to remove the empty space after the input (it can cause form breaking into differnt layout)
          // address.cssClass = address.cssClass.replace(' col-hidden', '');
        }
      }

      street = !!streetNumber ? `${street} ${streetNumber}` : street; 

      updatedInputSet = this.updateAddressInput(
        updatedInputSet,
        'street',
        street
      );
      updatedInputSet = this.updateAddressInput(updatedInputSet, 'city', city);
      updatedInputSet = this.updateAddressInput(
        updatedInputSet,
        'zipCode',
        zip
      );
      updatedInputSet = this.updateAddressInput(
        updatedInputSet,
        'country',
        country
      );
    } else {
      // it is string from changed address without google help
      updatedInputSet = this.updateAddressInput(
        updatedInputSet,
        keyOfTriggeredInput,
        place
      );
    }

    const actionPayload: FormInputsPayloadModel = {
      formInfo: this.action,
      inputSet: {
        rerender: true,
        list: updatedInputSet
      }
    };
    if (this.saveFormCallback) {
      actionPayload.callback = this.saveFormCallback;
    }

    this._formsService.mergeInputsBeforeSave(actionPayload);
  }

  formSubmitButton(key) {
    this.onSubmit.emit(key);
  }

  toggleDatePickerLabel(addOrRemoveClass: boolean, element: string): void {
    const datePickerLabel: HTMLLabelElement = document.querySelector(`.datePickerLabel.${this.datePickerUniqueClass}`);
    const datePlaceHolderClass = 'datePlaceHolder';

    this.changeVisibilityOfCarouselElements(addOrRemoveClass, element);

    if (addOrRemoveClass) {
      datePickerLabel.classList.add(datePlaceHolderClass);
      this.datePlaceHolder = this.dateFormat;
    }  else if (!addOrRemoveClass && !this.form.controls[this.input.key].value) {
      datePickerLabel.classList.remove(datePlaceHolderClass);
      this.datePlaceHolder = '';
    }
  }

  triggerDatePicker(): void {
    const datePickerInput: HTMLInputElement = document.querySelector(`.${this.datePickerUniqueClass} .ui-calendar > input`);
    datePickerInput.click();
    datePickerInput.focus();
  }

  changeVisibilityOfCarouselElements(addOrRemoveClass: boolean, element: string): void {
    const focusedInput: HTMLInputElement = document.querySelector(`.${element}`);

    if (!!focusedInput) {
      const carousel = focusedInput.closest('.carousel');

      if (!!carousel && typeof(carousel) != 'undefined') {
        const carouselPlaceHolder = Array.from(document.querySelectorAll('.carousel-placeholder'));

        if (addOrRemoveClass) {
          this.renderer.setStyle(carousel, 'overflow', 'visible');
          this.renderer.setStyle(carousel, 'overflow-x', 'clip');
          carouselPlaceHolder.forEach(element => {
            this.renderer.setStyle(element, 'visibility', 'hidden');
          });
        } else {
          this.renderer.removeStyle(carousel, 'overflow');
          this.renderer.removeStyle(carousel, 'overflow-x');
          carouselPlaceHolder.forEach(element => {
            this.renderer.setStyle(element, 'visibility', 'visible');
          });
        }
      }
    }
  }

  /**
   * Sets inputs of city and zipcode if values are given
   * @param cityValue Value to set city input
   * @param zipCodeValue Value to set zipcode input
   */
  setCityAndZipCode(cityValue?, zipCodeValue?): void {
    let updatedInputSet;
    if (!!cityValue) {
      // if city name length is higher than 50, reduce length to 50 because of max length validation
      if (cityValue.length > 50) {
        cityValue = cityValue.substring(0, 50);
      }

      updatedInputSet = this.updateAddressInput(
        this.inputSet,
        'city',
        cityValue
      );

      this.showZipCodeCitiesDropdown(false); // if we're setting citiy it means we either clicked on dropdown, or there's only one city in current zipCode
    }

    if (!!zipCodeValue) {
      updatedInputSet = this.updateAddressInput(
        !!updatedInputSet ? updatedInputSet : this.inputSet,
        'zipCode',
        zipCodeValue
      );
    }

    const actionPayload: FormInputsPayloadModel = {
      formInfo: this.action,
      inputSet: {
        rerender: true,
        list: updatedInputSet,
        updatedInputs: [ this.input.key ]
      }
    };

    if (this.saveFormCallback) {
      actionPayload.callback = this.saveFormCallback;
    }

    this._formsService.mergeInputsBeforeSave(actionPayload);
  }

  /**
   *
   * @param city Value to set city form input with
   */
  setSelectedCity(city: string): void {
    this.setCityAndZipCode(city);
  }

  /**
   * Shows or hides zipcode cities dropdown and empties zipCodeCities array if removeZipCodeCities is true
   * @param show Hides or shows dropdown if removeZipCodeCities is false and if zipCodeCities is not empty
   * @param removeZipCodeCities Removes zipCodeCities if true
   * @param chngCarouselElVisib Changes carousel elements visibility so we can see whole zipcode cities dropdown
   * @param childOfCarouselClass Class of zipcode input to pop infront of carousel
   */
  showZipCodeCitiesDropdown(show: boolean, removeZipCodeCities?: boolean, chngCarouselElVisib?: boolean, childOfCarouselClass?: string): void {
    // if function was called from html
    if (show && !!chngCarouselElVisib) {
        this.setCityBasedOnZip(true, show);
    }

    if (!show) {
      this.showZipCodeCities = show;
    }

    if (removeZipCodeCities) {
      this.zipCodeCities = [];
    }

    if (chngCarouselElVisib !== undefined) {
      this.changeVisibilityOfCarouselElements(chngCarouselElVisib, childOfCarouselClass);
    }
  }

/**
 * Sets zipCodeCitiesByFormPath and returns zipcodes which are found by zipCodeCitiesTag from zipCodeCities array
 * @param zipCodeCities all zipcode cities
 * @param input input which contains zipCodeCitiesTag
 * @returns zipcode cities for current form if there are any, else returns null
 */
  findZipCodesByFormPath(zipCodeCities: ZipCodeCities[], input: InputBase<any>): ZipCodeCities {
    let ticketHolder: string;

    if (!!this.action[1] && this.action[1].includes('ticketHolder')) {
      ticketHolder = this.action[1];
    }

    const { zipCodeCitiesTag }  = input as TextInput;
    this.zipCodeCitiesByFormPath = zipCodeCitiesTag.includes('holder') && !!ticketHolder ? zipCodeCitiesTag.replace('holder', ticketHolder) : zipCodeCitiesTag;

    return zipCodeCities.length ? zipCodeCities.find(item => item.formPath === this.zipCodeCitiesByFormPath) : null;
  }

}
