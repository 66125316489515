<ngx-skeleton-loader 
  count="1" 
  appearance="line" 
  animation="progress" 
  [theme]="{
	'background-color': '#ccc',
	'height': '20px',
	'border-radius': 10
  }"
>
</ngx-skeleton-loader>