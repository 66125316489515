import {
  combineLatest as observableCombineLatest,
  Observable,
  Subscription
} from 'rxjs';

import { filter, first, debounceTime, switchMap } from 'rxjs/operators';
import * as colorizerActions from './colorizer.actions';
import * as fromRoot from './../app.reducer';

import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  CssSkinModel,
  ExhibitionCustomStyles
} from '../shared/services-with-reducers/customization/customization.interfaces';

import { ColorizerService } from './colorizer.service';
import { CustomizationService } from '../shared/services-with-reducers/customization/customization.service';
import { select, Store } from '@ngrx/store';
import { StyleTemplateModel } from './colorizer.interface';
import { TranslateService } from '@ngx-translate/core';
import { generateSkinCss } from '../shared/services-with-reducers/customization/customization.styles';
import { styleList } from '../shared/services-with-reducers/customization/customization.styles';

@Component({
  selector: 'app-colorizer',
  templateUrl: './colorizer.component.html',
  styleUrls: ['./colorizer.component.scss']
})
export class ColorizerComponent implements OnInit, OnDestroy {
  public styleList = styleList;
  public allSkinVariables: any;
  public expanded = false;
  public modalWindowOpen: boolean = false;
  public modalWindowType: string = '';
  public selectedTemplateID: number;
  public allStyleTemplates$: Observable<StyleTemplateModel[]>;
  public modalTitle: string = '';
  public templateTitle: string = '';
  public activeLang: string;
  public selectedTemplateTitle: string;
  public eventType: string;
  public transaltionEventTitle = '';
  public preselectedTemplate: StyleTemplateModel = null;
  public themeDefault = require('../../assets/admin/theme-default.json');

  private subscriptions = new Subscription();

  constructor(
    private _store: Store<fromRoot.State>,
    private _customizationService: CustomizationService,
    private _colorizerService: ColorizerService,
    private _translateService: TranslateService
  ) {}

  ngOnInit() {
    this.activeLang = this._translateService.currentLang;
    this._store.dispatch(new colorizerActions.GetAllStyles());

    this.subscriptions.add(
      this._store.select(fromRoot.getStyleId).subscribe((styleId: number) => {
        this.selectedTemplateID = styleId;
      })
    );

    this.allStyleTemplates$ = this._store.select(fromRoot.getAllStyles);

    this.subscriptions.add(
      this._store.select(fromRoot.getSelectedExhibition).subscribe(event => {
        if (event && event !== null) {
          this.transaltionEventTitle = event.eventName;
        } else {
          this.transaltionEventTitle = 'operator';
        }
      })
    );

    this.subscriptions.add(
      this._store
        .select(fromRoot.getSelectedExhibitionId)
        .subscribe(eventId => {
          if (eventId !== null) {
            this.eventType = 'event';
          } else {
            this.eventType = 'operator';
          }
        })
    );

    this.subscriptions.add(
      this._store
        .select(fromRoot.getSelectedStyle)
        .subscribe((skin: CssSkinModel) => {
          if (skin && skin.variables) {
            const allVariables = this.generateSkinVariables(skin);
            this.allSkinVariables = allVariables;
            this._customizationService.appendSkinCSS(
              this.allSkinVariables,
              5,
              false,
              this.selectedTemplateID
            );
          }
        })
    );

    // when we load the colorizer tool, check which style is active

    this.subscriptions.add(
      observableCombineLatest([
        this._store.pipe(select(fromRoot.getExhibitionStyles)),
        this._store.pipe(select(fromRoot.getAllStyles)),
        this._store.pipe(select(fromRoot.getOperatorsSettings))
      ])
        .pipe(
          filter(([exhibitionStyles, styleTemplates, operatorSettings]) => {
            return !!exhibitionStyles
              && exhibitionStyles.hasOwnProperty('variables')
              && !!styleTemplates
              && !!operatorSettings
          }),
          debounceTime(100)
        )
        .subscribe(([exhibitionStyles, styleTemplates, operatorSettings]) => {

          // set the variables of currently selected template into inputs
          this.allSkinVariables = this.generateSkinVariables(exhibitionStyles);

          if (
            exhibitionStyles.hasOwnProperty('id') &&
            (exhibitionStyles.id || exhibitionStyles.id === 0)
          ) {
            this.selectedTemplateID = exhibitionStyles.id;

            styleTemplates.forEach(styleTemplate => {
              if (this.selectedTemplateID === styleTemplate.id) {
                this.selectedTemplateTitle = styleTemplate.title;
              }
            });
          } else if (operatorSettings.customStylesId) {
            this.selectedTemplateID = operatorSettings.customStylesId;

            styleTemplates.forEach(styleTemplate => {
              if (this.selectedTemplateID === styleTemplate.id) {
                this.selectedTemplateTitle = styleTemplate.title;
              }
            });
          } else {
            console.log('id not present');
          }
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  togglePicker(e) {
    const designToolWrapper = document.querySelector('.style-list');
    designToolWrapper.classList.toggle('no-scroll');
  }

  inputChanged(styleVariableKey, value) {
    this.allSkinVariables[styleVariableKey] = value;
    this.generateAndApplyStyles();
  }

  generateAndApplyStyles() {
    if (this.allSkinVariables) {
      // add styles
      const cssString = generateSkinCss(this.allSkinVariables);
      const cssSkin: CssSkinModel = {
        priority: 5,
        css: cssString,
        variables: this.allSkinVariables
      };

      this._store.dispatch(new colorizerActions.SetSelectedStyle(cssSkin));

      this._customizationService.appendSkinCSS(
        this.allSkinVariables,
        5,
        false,
        this.selectedTemplateID
      );
    }
  }

  mouseOver(variableKey) {
    const css = this.generateHighlightCss(variableKey);

    this._customizationService.addCSS(css, 'adminHighlight');
  }
  mouseLeave() {
    this._customizationService.addCSS('', 'adminHighlight');
  }

  generateSkinVariables(skin: CssSkinModel) {
    let allVariables = {};

    Object.keys(styleList).forEach(styleKey => {
      const containsVariables = skin && skin.variables;
      const fallbackStyle = this.themeDefault[styleKey] || null;

      if (containsVariables) {
        allVariables[styleKey] = skin.variables[styleKey] || fallbackStyle;
      } else {
        allVariables[styleKey] = fallbackStyle;
      }
    });

    return allVariables;
  }

  generateHighlightCss(variableKey) {
    let cssString = '';
    if (styleList.hasOwnProperty(variableKey)) {
      cssString = styleList[variableKey].list.reduce(
        (allStyles, currentSelector) => {
          return (
            allStyles +
            `${currentSelector.selector} {
  outline: 1px dashed black !important;
}
`
          );
        },
        cssString
      );
    }

    return cssString;
  }

  checkIfEmpty(event, variableKey) {
    if (!event.target.value) {
      this.inputChanged(variableKey, '');
    }
  }

  openModalWindow(type) {
    this.modalWindowType = type;
    this.modalWindowOpen = true;
    switch (type) {
      case 'load':
        this.translateTitle(type, '');
        break;

      case 'update':
        this.translateTitle(type, '');
        break;

      case 'create':
        this.translateTitle(type, '');
        break;

      case 'save':
        this.translateTitle(type, `.${this.eventType}`);
        break;

      default:
        break;
    }
  }

  translateTitle(type, eventType) {
    this._store
      .select(fromRoot.getLanguage)
      .pipe(
        switchMap(currentLang => {
          return this._translateService.get(
            `modalWindow.colorizer.${type}-title${eventType}`,
            {
              lang: currentLang.toUpperCase()
            }
          );
        })
      )
      .subscribe((title: string) => {
        this.modalTitle = title;
      });
  }

  submitModalWindow(event) {
    event.stopPropagation();
    this.modalWindowOpen = false;

    const templateID = this.selectedTemplateID;

    switch (this.modalWindowType) {
      case 'load':
        this.selectedTemplateID = this.preselectedTemplate.id;
        this.selectedTemplateTitle = this.preselectedTemplate.title;
        this.preselectedTemplate = null;
        this._store.dispatch(
          new colorizerActions.LoadStyle(this.selectedTemplateID)
        );
        break;

      case 'update':
        this._store.dispatch(
          new colorizerActions.UpdateStyle({
            title:
              templateID != null ? this.selectedTemplateTitle : '* Default *',
            styles: this.allSkinVariables,
            styleId: templateID != null ? templateID : -1
          })
        );
        break;

      case 'save':
        this._store
          .select(fromRoot.getSelectedExhibitionId)
          .pipe(first())
          .subscribe(eventId => {
            eventId = eventId ? Number(eventId) : null;

            this._store.dispatch(
              new colorizerActions.SaveEventStyles({
                styleId: this.selectedTemplateID,
                eventId: eventId
              })
            );
          });
        break;

      case 'create':
        this._store.dispatch(
          new colorizerActions.CreateStyle({
            title: this.templateTitle,
            styles: this.allSkinVariables
          })
        );
        this.templateTitle = '';
        break;

      default:
        break;
    }
  }

  closeModalWindow(e) {
    event.stopPropagation();
    this.modalWindowOpen = false;
    this.preselectedTemplate = null;
  }

  preselectTile(template) {
    this.preselectedTemplate = template;
  }
}
