/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./maintenence-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../app-shared/navigation-tabs/navigation-tabs.component.ngfactory";
import * as i3 from "../../../app-shared/navigation-tabs/navigation-tabs.component";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "@ngx-translate/core";
import * as i7 from "../../shared/pipes/html-injector/html-injector.pipe";
import * as i8 from "@angular/platform-browser";
import * as i9 from "./maintenence-page.component";
import * as i10 from "@angular/common/http";
import * as i11 from "../../shared/error-handling/error-handling.service";
import * as i12 from "@ngrx/store";
import * as i13 from "../../status-bar/status-bar.service";
var styles_MaintenencePageComponent = [i0.styles];
var RenderType_MaintenencePageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MaintenencePageComponent, data: {} });
export { RenderType_MaintenencePageComponent as RenderType_MaintenencePageComponent };
function View_MaintenencePageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "navigation-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-navigation-tabs", [["class", "navigation-tabs"], ["type", "normal"]], null, [[null, "activeTabChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("activeTabChanged" === en)) {
        var pd_0 = (_co.onTabChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_NavigationTabsComponent_0, i2.RenderType_NavigationTabsComponent)), i1.ɵdid(2, 49152, null, 0, i3.NavigationTabsComponent, [], { tabs: [0, "tabs"], type: [1, "type"], activeTab: [2, "activeTab"] }, { activeTabChanged: "activeTabChanged" }), (_l()(), i1.ɵeld(3, 0, null, null, 6, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "span", [["class", "edit"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "a", [["class", "button"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "span", [["data-translation", "page.maintenance.edit"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tabs; var currVal_1 = "normal"; var currVal_2 = _co.activeTab; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_5 = i1.ɵinlineInterpolate(1, "/admin/maintenance/", _co.activeTab, ""); _ck(_v, 6, 0, currVal_5); }, function (_ck, _v) { var currVal_3 = i1.ɵnov(_v, 6).target; var currVal_4 = i1.ɵnov(_v, 6).href; _ck(_v, 5, 0, currVal_3, currVal_4); var currVal_6 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("page.maintenance.edit")); _ck(_v, 8, 0, currVal_6); }); }
export function View_MaintenencePageComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i7.HtmlInjectorPipe, [i8.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "container-fluid h-100-p"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "page-content h-100-p"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "page-wrapper h-100-p"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_MaintenencePageComponent_1)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 1, "section", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(8, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.isAdmin$)); _ck(_v, 5, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v, 0), _co.htmlContent)); _ck(_v, 7, 0, currVal_1); }); }
export function View_MaintenencePageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-maintenence-page", [], null, null, null, View_MaintenencePageComponent_0, RenderType_MaintenencePageComponent)), i1.ɵdid(1, 114688, null, 0, i9.MaintenencePageComponent, [i10.HttpClient, i11.ErrorHandlingService, i6.TranslateService, i12.Store, i13.StatusBarService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MaintenencePageComponentNgFactory = i1.ɵccf("app-maintenence-page", i9.MaintenencePageComponent, View_MaintenencePageComponent_Host_0, {}, {}, []);
export { MaintenencePageComponentNgFactory as MaintenencePageComponentNgFactory };
