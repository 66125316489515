import {
  LegitimationFaxInfoModel,
  LegitimationListModel,
  LegitimationPostModel,
  LegitimationStatusModel
} from './legitimation.interface';
import { catchError, map } from 'rxjs/operators';

import { ErrorHandlingService } from '../../error-handling/error-handling.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StatusBarService } from '../../../status-bar/status-bar.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LegitimationService {
  constructor(
    private _http: HttpClient,
    private _errorHandlingService: ErrorHandlingService,
    private _statusBarService: StatusBarService
  ) {}

  saveLegitimationInfo(
    data: LegitimationPostModel,
    userId,
    eventId
  ): Observable<LegitimationPostModel> {
    return <Observable<LegitimationPostModel>>(
      this._http.post(
        `${environment.protocol}${
          environment.webApiUrl
        }/event/${eventId}/legitimation/${userId}`,
        data
      )
    );
  }

  getLegitimationList(): Observable<LegitimationListModel[]> {
    return <Observable<LegitimationListModel[]>>(
      this._http.get(
        `${environment.protocol}${environment.webApiUrl}/user/legitimation-history`
      )
    );
  }

  getLegitimationStatus(eventId): Observable<LegitimationStatusModel> {
    return <Observable<LegitimationStatusModel>>(
      this._http.get(
        `${environment.protocol}${
          environment.webApiUrl
        }/user/event/${eventId}/legitimation-status`
      )
    );
  }

  getLegitimationFaxId(
    eventId: number,
    userId: number
  ): Observable<LegitimationFaxInfoModel> {
    return <Observable<LegitimationFaxInfoModel>>(
      this._http.get(
        `${environment.protocol}${
          environment.webApiUrl
        }/event/${eventId}/fax-legitimation-id/${userId}`
      )
    );
  }
}
