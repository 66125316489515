<div class="step-navigation-button-wrap">


    <!--Because firefox doesnt trigger events on disabled buttons we removed the disabled attribute from the button and added one parent div before it, to handle the click event. -->
    <div class="feedback-button" [class.payment]="payment && paymentValue" (click)="fixFirstInvalidInput()">
        <button [ngClass]="{'feedback-button-disabled': isButtonDisabled}" *ngIf="!hideButton && !hasAlternativeButton" (click)="onContinueClick($event)"
            (mouseenter)="loadNextInvalidTicketHolderForm()" class="continue-button button" type="button">
            <span class="continue-button-text" data-translation="step-navigation.continue-button">{{ 'step-navigation.continue-button' | translate | hideTranslation }}</span>
        </button>
        <div class="feedback-text" *ngIf="isButtonDisabled && feedbackMessages && feedbackMessages.length">
            <app-feedback-message [feedbackMessages]="feedbackMessages" class="feedback-messages"></app-feedback-message>
        </div>
        <div class="alternative-button">
            <div #alternativeSubmit>
                <ng-content select="[alternative-submit]"></ng-content>
            </div>

            <div *ngIf="alternativeSubmit.children.length !== 0 && isButtonDisabled && feedbackMessages && feedbackMessages.length">
                <app-feedback-message [feedbackMessages]="feedbackMessages" class="feedback-messages"></app-feedback-message>
            </div>
        </div>
    </div>

    <!-- normally this one would be second in parent but this one has float left to be first,
        until the feedback button has payment type, the it is also float left and will go before this one -->
    <button class="back-button button button-option" type="button" (click)="onBackClick()" *ngIf="showBackButton">
        <span data-translation="step-navigation.back-button">{{ 'step-navigation.back-button' | translate | hideTranslation }}</span>
    </button>
</div>