import { catchError, map } from 'rxjs/operators';
import * as fromRoot from '../app.reducer';
import * as pressNewsActions from './press-news.actions';
import { ErrorHandlingService } from '../shared/error-handling/error-handling.service';
import { Http } from '@angular/http';
import { StatusBarService } from '../status-bar/status-bar.service';
import { Store } from '@ngrx/store';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/http";
import * as i2 from "../shared/error-handling/error-handling.service";
import * as i3 from "../status-bar/status-bar.service";
import * as i4 from "@ngrx/store";
var PressNewsService = /** @class */ (function () {
    function PressNewsService(_http, _errorHandlingService, _statusBarService, _store) {
        var _this = this;
        this._http = _http;
        this._errorHandlingService = _errorHandlingService;
        this._statusBarService = _statusBarService;
        this._store = _store;
        this.pressNewsId$ = this._store.select(fromRoot.getSelectedPressNewsId);
        this.pressNewsId$.subscribe(function (pressNewsId) {
            _this._store.dispatch(new pressNewsActions.GetPressNewsById(pressNewsId));
        });
    }
    PressNewsService.prototype.getPressNewsList = function (eventId) {
        var _this = this;
        return this._http
            .get(environment.webApiUrl + "/event/" + eventId + "/completed-page")
            .pipe(map(function (res) {
            try {
                return res.json() || {};
            }
            catch (err) {
                _this._statusBarService.setStatus(err, 'error');
            }
        }), catchError(function (e) {
            return _this._errorHandlingService.errorHandler(e);
        }));
    };
    PressNewsService.prototype.getPressNews = function (eventId) {
        var _this = this;
        return this._http
            .get(environment.webApiUrl + "/press-release/" + eventId)
            .pipe(map(function (res) {
            try {
                return res.json() || {};
            }
            catch (err) {
                _this._statusBarService.setStatus(err, 'error');
            }
        }), catchError(function (e) {
            return _this._errorHandlingService.errorHandler(e);
        }));
    };
    PressNewsService.ngInjectableDef = i0.defineInjectable({ factory: function PressNewsService_Factory() { return new PressNewsService(i0.inject(i1.Http), i0.inject(i2.ErrorHandlingService), i0.inject(i3.StatusBarService), i0.inject(i4.Store)); }, token: PressNewsService, providedIn: "root" });
    return PressNewsService;
}());
export { PressNewsService };
