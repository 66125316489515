import { ActionTypes, Actions } from './user.actions';

import {
  BillingAddressModel,
  UserModel,
  UserProfileModel,
  VerifyEmailResponseModel
} from './user.interface';
import { TicketRecordModel } from '../tickets/ticket.interface';

import { InputBase } from '../../forms/inputs/input-base.class';
import { createSelector } from 'reselect';
import cloneDeep from 'lodash.clonedeep';
import { AppConstants } from '../../app-constants';

export interface State {
  user: UserModel | null;
  profile: UserProfileModel | null;
  language: string;
  billingAddress: BillingAddressModel[] | null;
  selectedBillingAddressId: number;
  registrationQuestionnaire: InputBase<any>[];
  ticketsHitory: TicketRecordModel[] | null;
  eventsEmailUnsubscriptions: InputBase<any>[];
  passRecoverySuccess: boolean;
  passChangeStatus: number;
  emailChangeStatus: number;
  emailVerifyStatus: VerifyEmailResponseModel;
  loginTimestamp: number;
  profileLoading: boolean;
}

export const initialState: State = {
  user: null,
  profile: null,
  language: '',
  billingAddress: [],
  selectedBillingAddressId: null,
  registrationQuestionnaire: [],
  ticketsHitory: [],
  eventsEmailUnsubscriptions: [],
  passRecoverySuccess: null,
  passChangeStatus: null,
  emailChangeStatus: null,
  loginTimestamp: null,
  emailVerifyStatus: null,
  profileLoading: false
};

export function reducer(state = initialState, action: Actions): State {
  const clonedInitialState: State = cloneDeep(initialState);

  switch (action.type) {
    case ActionTypes.SET_USER: {
      const user = action.payload;
      return {
        ...state,
        user: user
      };
    }

    case ActionTypes.SET_LOGIN_TIMESTAMP: {
      const loginTimestamp = action.payload;

      return {
        ...state,
        loginTimestamp
      };
    }

    case ActionTypes.PROFILE_LOADING: {
      const profileLoading = action.payload;

      return {
        ...state,
        profileLoading
      };
    }

    case ActionTypes.SET_PROFILE: {
      const profile = action.payload;

      return {
        ...state,
        profile,
        profileLoading: false
      };
    }

    case ActionTypes.SET_LANGUAGE: {
      const language = action.payload;

      return {
        ...state,
        language
      };
    }

    case ActionTypes.SET_PROFILE_BILLING_ADDRESS: {
      const billingAddress = action.payload;

      return {
        ...state,
        billingAddress
      };
    }

    case ActionTypes.SET_SELECTED_BILLING_ADDRESS: {
      const selectedBillingAddressId = action.payload;

      return {
        ...state,
        selectedBillingAddressId
      };
    }

    case ActionTypes.UPDATE_PROFILE_BILLING_ADDRESS: {
      const updatedAddress = action.payload;

      if (updatedAddress) {
        const oldAddresses = state.billingAddress;
        const billingAddress = oldAddresses.map(address => {
          if (address.id === updatedAddress.id) {
            return updatedAddress;
          } else {
            return address;
          }
        });

        return {
          ...state,
          billingAddress
        };
      }

      return state;
    }

    case ActionTypes.SET_REGISTRATION_QUESTIONNAIRE: {
      const registrationQuestionnaire = action.payload;

      return {
        ...state,
        registrationQuestionnaire
      };
    }

    case ActionTypes.SET_TICKETS_HISTORY_BY_USER: {
      const ticketsHistory = action.payload;

      return {
        ...state,
        ticketsHitory: ticketsHistory
      };
    }

    case ActionTypes.SET_EVENTS_EMAIL_UNSUBSCRIPTIONS: {
      const eventsEmailUnsubscriptions = action.payload;

      return {
        ...state,
        eventsEmailUnsubscriptions
      };
    }

    case ActionTypes.SET_PROFILES_EMAIL: {
      const email = action.payload;

      let profile = state.profile;

      profile.email = email;

      return {
        ...state,
        profile
      };
    }

    case ActionTypes.PASSWORD_RECOVERY_RESULT: {
      const passRecoverySuccess = action.payload;

      return {
        ...state,
        passRecoverySuccess
      };
    }

    case ActionTypes.SET_PASSWORD_CHANGED: {
      const passChangeStatus = action.payload;

      return {
        ...state,
        passChangeStatus
      };
    }

    case ActionTypes.SET_EMAIL_CHANGED: {
      const emailChangeStatus = action.payload;

      return {
        ...state,
        emailChangeStatus
      };
    }

    case ActionTypes.SET_EMAIL_VERIFIED: {
      const response = action.payload;

      if (response) {
        return {
          ...state,
          emailVerifyStatus: {
            statusCode: response.statusCode,
            body: response.body
          }
        };
      } else {
        return {
          ...state,
          emailVerifyStatus: null
        };
      }
    }

    case ActionTypes.RESET_REDUCER:
      return clonedInitialState;

    default: {
      return state;
    }
  }
}

/**
 * Because the data structure is defined within the reducer it is optimal to
 * locate our selector functions at this level. If store is to be thought of
 * as a database, and reducers the tables, selectors can be considered the
 * queries into said database. Remember to keep your selectors small and
 * focused so they can be combined and composed to fit each particular
 * use-case.
 */

export const getUser = (state: State) => state.user;
export const getLoginTimestamp = (state: State) => state.loginTimestamp;
export const isProfileLoading = (state: State) => state.profileLoading;
export const getProfile = (state: State) => state.profile;
export const getLanguage = (state: State) => state.language;
export const getProfileBillingAddress = (state: State) => state.billingAddress;
export const getSelectedBillingAddressId = (state: State) =>
  state.selectedBillingAddressId;
export const getRegistrationQuestionnaire = (state: State) =>
  state.registrationQuestionnaire;
export const getTicketsHistory = (state: State) => state.ticketsHitory;
export const getPassRecoverySuccess = (state: State) =>
  state.passRecoverySuccess;
export const passChangeStatus = (state: State) => state.passChangeStatus;
export const emailChangeStatus = (state: State) => state.emailChangeStatus;
export const emailVerifyStatus = (state: State) => state.emailVerifyStatus;
export const getEventsEmailUnsubscriptions = (state: State) =>
  state.eventsEmailUnsubscriptions;

export const isUserLoggedIn = createSelector(
  getProfile,
  (user: UserProfileModel) => {
    if (user && user.id) {
      return true;
    } else {
      return false;
    }
  }
);

export const isLoggedInAsAdmin = createSelector(
  getUser,
  (user: UserModel) => {
    return user && AppConstants.getUserTypeFromJwt(user) === 'admin';
  }
);
