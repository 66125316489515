import { CheckboxInput } from '../../../forms/inputs/input-checkbox.class';
import { InputBase } from '../../../forms/inputs/input-base.class';
import { EventsEmailUnsubscriptionsModle } from '../../user/user.interface';

export const transformeventsEmailUnsubscriptions = (
  unsubscriptions: EventsEmailUnsubscriptionsModle[]
): InputBase<any>[] => {
  const inputBase: any = {
    key: 'unsubscriptions',
    label: '',
    order: 1,
    required: false,
    translate: false,
    value: true,
    options: [],
    cssClass: 'col-sm-12'
  };

  unsubscriptions.slice(0).forEach(unsubscription => {
    inputBase.options.push({
      key: unsubscription.id,
      label: unsubscription.eventName,
      value: true,
      required: false,
      cssClass: 'col-sm-12'
    });
  });

  return [new CheckboxInput(inputBase)];
};
