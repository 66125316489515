import { access } from 'fs';

import { Actions, ActionTypes } from './additional-services.actions';
import { timestamp } from 'rxjs/operator/timestamp';
import cloneDeep from 'lodash.clonedeep';
import {
  AddWorkshopBookingModel,
  MenuModel,
  WorkshopBookingModel,
  WorkshopBookingsModel,
  WorkshopFullModel,
  WorkshopModel
} from './additional-services.interface';

function isNumber(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export interface State {
  menuOptions: MenuModel[];
  workshops: WorkshopModel[];
  workshopBookings: WorkshopBookingsModel;
  workshopBookingsFull: WorkshopFullModel;
}

export const initialState: State = {
  menuOptions: [],
  workshops: [],
  workshopBookings: { bookings: [], timestamp: null },
  workshopBookingsFull: {
    isFull: false,
    workshopId: null
  }
};

export function reducer(state = initialState, action: Actions): State {
  const clonedInitialState: State = cloneDeep(initialState);

  switch (action.type) {
    case ActionTypes.SET_MENU:
      const menuOptionList = action.payload;
      return {
        ...state,
        menuOptions: menuOptionList
      };

    case ActionTypes.SET_WORKSHOPS:
      const workshopList = action.payload;
      return {
        ...state,
        workshops: workshopList
      };

    case ActionTypes.REMOVE_MY_WORKSHOP_BOOKINGS:
      const cleanedBookings = state.workshopBookings.bookings.map(booking => {
        const seats = booking.bookingsNumber + booking.myBookings.length;

        return {
          ...booking,
          seats,
          bookingsNumber: seats,
          myBookings: []
        };
      });

      return {
        ...state,
        workshopBookings: { bookings: cleanedBookings, timestamp: null }
      };

    case ActionTypes.ADD_WORKSHOP_BOOKING_FULL:
      const workshopBookingsFull: WorkshopFullModel = action.payload;

      return {
        ...state,
        workshopBookingsFull: workshopBookingsFull
      };

    case ActionTypes.ADD_WORKSHOP_BOOKING:
      const payload: AddWorkshopBookingModel =
        action.payload.addWorkshopBookingModel;

      let workshopBookings: WorkshopBookingModel[] = [];

      if (!payload) {
        return state;
      } else if (payload.added) {
        // we are adding
        //console.log('payload.added');
        const alreadyExist = state.workshopBookings.bookings.find(workshop => {
          return workshop.workshopId === payload.workshopId;
        });

        const myBooking = {
          timestamp: Date.now(),
          ticketHolderId: payload.ticketHolderId
        };
        if (alreadyExist) {
          workshopBookings = state.workshopBookings.bookings.map(workshop => {
            if (workshop.workshopId === payload.workshopId) {
              workshop.bookingsNumber = payload.seats;
              workshop.seats = payload.seats;
              workshop.myBookings.push(myBooking);
            }
            return workshop;
          });
        } else {
          workshopBookings = [...state.workshopBookings.bookings];
          workshopBookings.push({
            bookingsNumber: payload.seats,
            workshopId: payload.workshopId,
            seats: payload.seats,
            myBookings: [myBooking]
          });
        }
      } else {
        //console.log('payload.removed');
        // we are removing

        workshopBookings = state.workshopBookings.bookings.reduce(
          (acc, workshop) => {
            if (workshop.workshopId === payload.workshopId) {
              workshop.seats = payload.seats;
            }
            if (payload.timestamps) {
              // removing because of time out
              workshop.bookingsNumber = 0;
              workshop.myBookings = workshop.myBookings.filter(booking => {
                return !payload.timestamps.includes(booking.timestamp);
              });
            }
            if (isNumber(payload.ticketHolderId)) {
              workshop.bookingsNumber = payload.seats;
              workshop.myBookings = workshop.myBookings.filter(booking => {
                return payload.ticketHolderId !== booking.ticketHolderId;
              });
            }
            acc.push(workshop);
            return acc;
          },
          []
        );
      }

      //console.log('reducer', workshopBookings);

      return {
        ...state,
        workshopBookings: { bookings: workshopBookings, timestamp: Date.now() }
      };

    case ActionTypes.RESET_REDUCER:
      return clonedInitialState;

    default: {
      return state;
    }
  }
}

export const menuOptions = (state: State) => state.menuOptions;
export const workshops = (state: State) => state.workshops;
export const getWorkshopBookings = (state: State) => state.workshopBookings;
export const isWorkshopBookingsFull = (state: State) =>
  state.workshopBookingsFull;
