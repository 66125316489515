import { AppConstants } from '../../../app-constants';
import { CheckboxInput } from '../../../forms/inputs/input-checkbox.class';
import { ExhibitionSettingModel, CheckboxOptions } from '../customization.interfaces';
import { InputBase } from '../../../forms/inputs/input-base.class';

export const prepareDisclaimerCheckboxes = (
  settings: ExhibitionSettingModel,
  privacyTranslations: string[],
  isSelfRegistration: boolean,
  privacyPolicyChecked: boolean,
  privacyPolicyOptionalChecked: boolean,
): InputBase<any>[] => {
  const inputs = [];

  //if privacy policy link doesnt exist on back end admin you use front end data protection page
  function setPrivacyUrl(url: string): string {
    if (url) {
      return url;
    };

    const dataProtectionUrl = `${window.location.origin}/data-protection`;

    return !!settings.eventId ? `${dataProtectionUrl}/${settings.eventId}` : dataProtectionUrl;
  };

  function setCheckboxLabelText(translation: string, url: string): string {
    let labelText = translation;

    if (!labelText) {
      return AppConstants.MISSING_TRANSLATION +
      ' USE &lt;Privacy Policy&gt; in the translation as placeholder for a link';
    }
    
    const replacingText = labelText.match(/<.*?>/);
    
    if (replacingText) {
      const linkTextOnly = replacingText[0];
      const cleanedLinkTextOnly = linkTextOnly.slice(1, -1);
      const newText = isSelfRegistration ? 
        cleanedLinkTextOnly : 
        `<a href="${setPrivacyUrl(url)}" target="_blank">${cleanedLinkTextOnly}</a>`;
      
      labelText = labelText.replace(
        linkTextOnly,
        newText,
      );
    };
    
    return `<span data-translation="personalize.privacy-link">${labelText}</span>`;
  };
  
  function addPrivacyDisclaimer(checkboxOptions: CheckboxOptions): void {
    const { checkboxKey, optionKey, value, label, isRequired, text } = checkboxOptions;

    inputs.push(
      new CheckboxInput({
        key: checkboxKey,
        label: '',
        order: 2,
        translate: false,
        required: isRequired,
        options: [
          {
            key: optionKey,
            value: value,
            label: label,
            cssClass: 'col-sm-12',
            required: isRequired,
          },
        ],
        cssClass: 'col-sm-12',
        text: text,
      }),
    );
  };

  if (settings.isPrivacyLinkVisible) {
    const label: string = setCheckboxLabelText(privacyTranslations['personalize.privacy-link'], settings.privacyUrl);
    const checkboxOptions: CheckboxOptions = {
      checkboxKey: 'disclaimer',
      optionKey: 'disclaimerConfirmation',
      value: privacyPolicyChecked,
      label: label,
      isRequired: settings.isPrivacyLinkMandatory,
      text: {
        value: privacyTranslations['personalize.privacy-link.text'],
        translation: 'personalize.privacy-link.text',
        position: 'prepend',
      },
    };

    addPrivacyDisclaimer(checkboxOptions);
  };

  if (settings.isPrivacyOptionalVisible) {
    const label: string = setCheckboxLabelText(privacyTranslations['personalize.privacy-optional'], settings.privacyOptionalUrl);
    const checkboxOptions: CheckboxOptions = {
      checkboxKey: 'disclaimerOptional',
      optionKey: 'disclaimerOptionalConfirmation',
      value: privacyPolicyOptionalChecked,
      label: label,
      isRequired: settings.isPrivacyOptionalMandatory,
      text: {
        value: privacyTranslations['personalize.privacy-optional.text'],
        translation: 'personalize.privacy-optional.text',
        position: 'append',
      },
    };

    addPrivacyDisclaimer(checkboxOptions);
  };

  return inputs;
};
