import * as colorizerActions from './colorizer.actions';
import * as fromRoot from '../app.reducer';
import { Subscription } from 'rxjs';
import { skip } from 'rxjs/operators';
import { ErrorHandlingService } from '../shared/error-handling/error-handling.service';
import { HttpClient } from '@angular/common/http';
import { StatusBarService } from '../status-bar/status-bar.service';
import { Store } from '@ngrx/store';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../shared/error-handling/error-handling.service";
import * as i3 from "../status-bar/status-bar.service";
import * as i4 from "@ngrx/store";
var ColorizerService = /** @class */ (function () {
    function ColorizerService(_http, _errorHandlingService, _statusBarService, _store) {
        var _this = this;
        this._http = _http;
        this._errorHandlingService = _errorHandlingService;
        this._statusBarService = _statusBarService;
        this._store = _store;
        this.styleLoadSubscription = [];
        // each time user (admin is important for us) is logged out reset colorizer reducer
        this._store
            .select(fromRoot.getProfile)
            .pipe(skip(1))
            .subscribe(function (user) {
            if (!user) {
                _this._store.dispatch(new colorizerActions.ResetReducer());
            }
        });
    }
    ColorizerService.prototype.loadAllEventStyles = function () {
        return this._http.get("" + environment.protocol + environment.webApiUrl + "/style");
    };
    ColorizerService.prototype.loadEventStyle = function (styleId) {
        return this._http.get("" + environment.protocol + environment.webApiUrl + "/style/" + styleId);
    };
    ColorizerService.prototype.updateEventStyle = function (data, styleId) {
        return this._http.put("" + environment.protocol + environment.webApiUrl + "/style/" + styleId, data);
    };
    ColorizerService.prototype.createEventStyle = function (data) {
        return this._http.post("" + environment.protocol + environment.webApiUrl + "/style", data);
    };
    ColorizerService.prototype.saveEventStyles = function (data) {
        return this._http.post("" + environment.protocol + environment.webApiUrl + "/style/apply-style", data);
    };
    ColorizerService.prototype.saveOperatorStyles = function (data) {
        return this._http.put("" + environment.protocol + environment.webApiUrl + "/operator/styles", data);
    };
    ColorizerService.ngInjectableDef = i0.defineInjectable({ factory: function ColorizerService_Factory() { return new ColorizerService(i0.inject(i1.HttpClient), i0.inject(i2.ErrorHandlingService), i0.inject(i3.StatusBarService), i0.inject(i4.Store)); }, token: ColorizerService, providedIn: "root" });
    return ColorizerService;
}());
export { ColorizerService };
