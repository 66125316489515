import {
  ErrorHandlingService,
  TranslatedErrorMessage
} from './../../../shared/error-handling/error-handling.service';
import { combineLatest as observableCombineLatest } from 'rxjs';

import * as fromRoot from '../../../app.reducer';
import * as stepsActions from '../../../shared/services-with-reducers/step-forms/steps-forms.actions';
import * as ticketActions from '../../../shared/services-with-reducers/tickets/ticket.actions';
import { first, filter } from 'rxjs/operators';

import { Component, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { FormsService } from '../../../shared/forms/forms.service';
import { OrderResponseModel } from '../../../shared/services-with-reducers/step-forms/step.interface';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { getLocalStorageString, removeLocalStorageItem, setLocalStorageObject } from '../../../shared/app-utils';
import { AppConstants } from '../../../shared/app-constants';

@Component({
  selector: 'app-payment-landing-page',
  templateUrl: './payment-landing-page.component.html',
  styleUrls: ['./payment-landing-page.component.scss']
})
export class PaymentLandingPageComponent implements OnInit {
  public response: string;
  public orderId: string = '';
  public translatedErrorMessage: TranslatedErrorMessage;

  private getSelectedExhibitionId: number;

  constructor(
    private _route: ActivatedRoute,
    public _router: Router,
    private _formsService: FormsService,
    private _store: Store<fromRoot.State>,
    private _errorHandlingService: ErrorHandlingService
  ) {
    this._store.select(fromRoot.getSelectedExhibitionId).subscribe(eventId => {
      this.getSelectedExhibitionId = eventId;
    });
  }

  ngOnInit() {
    observableCombineLatest(this._route.params, this._route.queryParams)
      .pipe(first())
      .subscribe(data => {
        const [params, queryParams] = data;
        this.orderId = queryParams.orderid;

        if (params.response) {
          this.response = params.response;
          // If it is fail we stay on this page and show the failure to a user in template

          // Oterwise we set the hidden payment step to be valid and send user to invoice route
          this._store
            .select(fromRoot.getOrderResponse)
            .pipe(first())
            .subscribe((orderResponse: OrderResponseModel) => {
              if (this.response !== 'fail' && this.response !== 'error') {
                orderResponse = orderResponse || {
                  hash: this.response,
                  szCode: this.orderId,
                  orderPaid: true
                };

                /*
                 We have the hash from the order, but just for sure.. for case that we lost localStorage data by redirect,
                 we set the hash again to be the one taken from url. Also we set 'orderPaid' to true.
                 Then we redirect to invoice page for downloads
              */
                orderResponse.hash = this.response;
                orderResponse.szCode = this.orderId;
                orderResponse.orderPaid = true;

                this._store.dispatch(
                  new stepsActions.SetOrderResponse(orderResponse)
                );

                // there is invisible payment step, set it now to valid so we can skip it and go to invoice page
                this._formsService.setStepValid(['payment', 'validation']);

                this._router.navigate([
                  `webshop/${this.getSelectedExhibitionId}/invoice`
                ]);
              }

              if (!Object.keys(queryParams).length) {
                let errorMessageKey = '';
                if (typeof orderResponse.errorCode === 'number') {
                  this.removeParkingTickets(orderResponse.invalidTickets);
                  errorMessageKey = orderResponse.errorCode.toString();
                } else {
                  errorMessageKey = orderResponse.errorCode;
                }

                this.translatedErrorMessage = {
                  status: 200,
                  key: errorMessageKey,
                  message: orderResponse.errorMessage
                };
              }
            });

          // if there was an error from BE previously, take it from queryParams
          if (Object.keys(queryParams).length) {
            this.translatedErrorMessage = {
              ...(queryParams as TranslatedErrorMessage)
            };
          }
        }
      });

    // if there was error with status 409 || 404, use this error instad default and store message to queryParams (preserve it on reload)
    this._errorHandlingService.translatedErrorMessage
      .pipe(
        filter(translatedErrorMessage => {
          const statusCode =
            translatedErrorMessage && translatedErrorMessage.status;

          if (statusCode === 409 || statusCode === 404) {
            return true;
          }
        }),
        first()
      )
      .subscribe(translatedErrorMessage => {
        this._router.navigate(['.'], {
          relativeTo: this._route,
          queryParams: { ...translatedErrorMessage }
        });

        this.translatedErrorMessage = translatedErrorMessage;
      });
  }

  removeParkingTickets(invalidTickets) {
    const storedParkingTickets = getLocalStorageString(AppConstants.parkingTicketsReducer);
    let parsedParkingTickets =
      storedParkingTickets && JSON.parse(storedParkingTickets);
    const parsedParkingTicketsLength = Object.keys(parsedParkingTickets).length;
    invalidTickets.forEach((invalidTicket, index) => {
      delete parsedParkingTickets[
        invalidTicket.ticketGroupNr +
          '_' +
          invalidTicket.ticketPersonNr +
          '_' +
          (parsedParkingTicketsLength - (index + 1))
      ];
    });

    removeLocalStorageItem(AppConstants.parkingTicketsReducer);
    setLocalStorageObject(AppConstants.parkingTicketsReducer, parsedParkingTickets);

    this._store
      .select(fromRoot.getTickets)
      .pipe(first())
      .subscribe(tickets => {
        for (const uniqueId in tickets) {
          if (tickets[uniqueId].parking) {
            tickets[uniqueId].count = Object.keys(parsedParkingTickets).length;
          }
        }
        this._store.dispatch(new ticketActions.SetTickets({ ...tickets }));
      });
  }

  back() {
    this._store
      .select(fromRoot.getTickets)
      .pipe(first())
      .subscribe(tickets => {
        const ticketsCount: number = Object.keys(tickets).reduce(
          (accu, curr) => {
            const ticket = tickets[curr];
            return accu + ticket.count;
          },
          0
        );

        if (ticketsCount > 0) {
          this._router.navigate([
            `webshop/${this.getSelectedExhibitionId}/confirmation`
          ]);
        } else {
          this._router.navigate([
            `webshop/${this.getSelectedExhibitionId}/tickets`
          ]);
        }
      });
  }
}
