import * as additionalServicesActions from '../../shared/services-with-reducers/additional-services/additional-services.actions';
import * as fromRoot from '../../app.reducer';
import * as stepsFormsActions from '../../shared/services-with-reducers/step-forms/steps-forms.actions';

import {
  AssignWorkshopToHolderModel,
  FormInputsPayloadModel,
  TicketHolderAdditionalDataModel
} from '../../shared/services-with-reducers/step-forms/step.interface';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  Renderer
} from '@angular/core';

import {
  Observable,
  Subscription,
  combineLatest as observableCombineLatest,
  fromEvent as observableFromEvent,
  of as observableOf,
  Subject,
  BehaviorSubject
} from 'rxjs';
import { Store } from '@ngrx/store';
import { filter, first, throttleTime, debounceTime } from 'rxjs/operators';
import cloneDeep from 'lodash.clonedeep';

import { AppConstants } from '../../shared/app-constants';
import { CustomizationService } from '../../shared/services-with-reducers/customization/customization.service';
import { ExhibitionSettingModel } from '../../shared/services-with-reducers/customization/customization.interfaces';
import { FormsService } from '../../shared/forms/forms.service';
import { HelperService } from '../../shared/services-with-reducers/helpers/helper.service';
import { StepsFormsService } from '../../shared/services-with-reducers/step-forms/steps-forms.service';
import { TicketConfirmationModel } from '../../shared/services-with-reducers/tickets/ticket.interface';
import { ProductGroupModel } from '../../shared/services-with-reducers/tickets/ticket.interface';
import { TicketModel } from '../../shared/services-with-reducers/tickets/ticket.interface';
import {
  WorkshopBookingModel,
  WorkshopBookingsModel
} from '../../shared/services-with-reducers/additional-services/additional-services.interface';
import {
  WorkshopByDateModel,
  WorkshopDayBorderHoursModel,
  WorkshopModel,
  WorkshopRoomModel,
  WorkshopsTodayModel
} from '../../shared/services-with-reducers/additional-services/additional-services.interface';
import { WorkshopTimelineItem } from './../../shared/services-with-reducers/additional-services/additional-services.interface';
import { WindowSizeService } from '../../shared/window-size/window-size.service';
import { environment } from '../../../environments/environment';
import { tick } from '@angular/core/src/render3';
import { elementStart } from '@angular/core/src/render3/instructions';

@Component({
  moduleId: module.id,
  selector: 'app-web-shop-workshop',
  templateUrl: './web-shop-workshop.component.html',
  styleUrls: ['./web-shop-workshop.component.scss']
})
export class WebShopWorkshopComponent
  implements OnInit, OnDestroy, AfterViewInit {
  public origin: string;
  public workshopsList$: Observable<WorkshopModel[]>;
  public workshopsByDates: WorkshopByDateModel = {};
  public workshopsByDatesArray: WorkshopsTodayModel[] = [];
  public workshopsLoaded = false;
  public hourHeighInPixels = 160;
  public hourMinHeightInPixels = 120;
  public viewport: number;
  public breakpoints: {};
  public mediumBreakpoint: number;
  public result;
  public activeWorkshopId = null;
  public ticketsWithHolders = new BehaviorSubject([]);
  public environment = environment;
  public workshops: WorkshopModel[];
  public exhibitionSettings: ExhibitionSettingModel;
  public workshopsOverlap: boolean;
  public stepsFormsActionName = ['workshop', 'validation'];

  public isWorkshopMandatory: boolean = false;
  public limitWorkshopPerTicket: number = 1;
  public isWorkshopLimitPerTicketReached: boolean = false;
  public workshopMandatoryForZeroPriceTickets: boolean = false;

  public isWorkshopSelected: boolean = false;
  public hideDateAndTime: boolean = false;
  public toggleDetailWindow: boolean = false;
  public toggledWorkshop: any;
  public isMobile: boolean = false;
  public elementHasEventListener: boolean = false;
  public roomWidth: number = 220;
  public showFakeScrollBar: boolean = false;
  public settings: ExhibitionSettingModel;
  public workshopBookings: WorkshopBookingModel[] = [];
  public showAmountOfAvailableWorkshops: boolean = true;
  public hideWorkshopDate: boolean = true;
  public enableWorkshopSelectionOverlapping: boolean = false;

  private scrollLeft: Subject<{
    fakeScrollLeft: number;
    el: HTMLElement;
  }> = new Subject();

  private _subscriptions = new Subscription();

  constructor(
    private _store: Store<fromRoot.State>,
    private _customizationService: CustomizationService,
    private _formsService: FormsService,
    private _windowSizeService: WindowSizeService,
    private _stepsFormsService: StepsFormsService,
    private _helperService: HelperService,
    private _renderer: Renderer
  ) {
    this.origin = environment.protocol + environment.origin;
    this.origin = this.origin || window.location.origin;
  }

  ngOnInit() {
    this.workshopsLoaded = false;

    this._subscriptions.add(
      this._store
        .select(fromRoot.getExhibitionSettings)
        .pipe(filter(data => !!data))
        .subscribe(settings => {
          this.settings = settings;
          this.enableWorkshopSelectionOverlapping =
            settings.enableWorkshopSelectionOverlapping;
          this.showAmountOfAvailableWorkshops =
            settings.showAmountOfAvailableWorkshops;
          this.hideWorkshopDate = settings.hideWorkshopDate;
        })
    );

    this.scrollLeft.pipe(debounceTime(100)).subscribe(scroll => {
      scroll.el.scrollLeft = scroll.fakeScrollLeft;
    });
    //this._formsService.setFormValidity(true, null, ['workshop', 'validation']);
    this._formsService.setStepValid(['workshop', 'validation']);

    // in case we are in angular universal
    if (typeof window !== 'undefined') {
      const resize = observableFromEvent(window, 'resize');
      this.result = resize.pipe(throttleTime(100));
    } else {
      this.result = observableOf(null);
    }

    this._subscriptions.add(
      this._store
        .select(fromRoot.getWorkshopBookings)
        .pipe(filter(booking => !!booking))
        .subscribe((bookings: WorkshopBookingsModel) => {
          this.workshopBookings = bookings.bookings;
        })
    );

    /**
     * CREATE THE RIGHT STRUCTURE OF DATA TO BE SHOWN
     **/

    this._subscriptions.add(
      this._store
        .select(fromRoot.getSelectedExhibitionId)
        .subscribe(eventId => {
          this._store.dispatch(
            new additionalServicesActions.GetWorkshops(eventId)
          );
          if (!!eventId) {
            if (eventId.toString() === '99') {
              this.hideDateAndTime = true;
            }
          }

          this.workshopsList$ = this._store.select(fromRoot.getWorkshops);

          this._store
            .select(fromRoot.getExhibitionSettings)
            .pipe(
              first(),
              filter(data => !!data)
            )
            .subscribe(settings => {
              this.isWorkshopMandatory = settings.isWorkshopsSelectionMandatory;
              this.limitWorkshopPerTicket = settings.workshopsPerTicket;
              this.workshopMandatoryForZeroPriceTickets =
                settings.workshopMandatoryForZeroPriceTickets;
            });

          observableCombineLatest(
            this.workshopsList$,
            this._store.select(fromRoot.idsOfWorkshopsToShowInWorkshopScreen)
          )
            .pipe(first(data => !!data[0].length && !!data[1]))
            .subscribe((data: [WorkshopModel[], Array<number>]) => {
              const [workshops, idsOfWorkshopsToShowInWorkshopScreen] = data;
              this.workshops = workshops;

              const allSchedulesByTime: WorkshopModel[] = this.workshops.sort(
                (a, b) => {
                  return (
                    new Date(a.start).getTime() - new Date(b.start).getTime()
                  );
                }
              );

              let workshopsByDates: WorkshopByDateModel = {};

              allSchedulesByTime.forEach(workshop => {
                workshop.disabled = !idsOfWorkshopsToShowInWorkshopScreen.includes(
                  workshop.workshopId
                );

                // check if the workshop starts befor it ends (avoid bugs in BE)
                if (
                  new Date(workshop.start).getTime() <
                  new Date(workshop.end).getTime()
                ) {
                  //
                  const day = new Date(workshop.date).toLocaleDateString(
                    'en-US'
                  );

                  // this dayString is used as key of workshopsByDates variable, also serves for showing date in template
                  workshopsByDates = this.addWorkshopToDay(
                    workshopsByDates,
                    day,
                    workshop
                  );
                }
              });

              for (const dayKey in workshopsByDates) {
                if (workshopsByDates.hasOwnProperty(dayKey)) {
                  const day = workshopsByDates[dayKey];

                  day.todayWorkshops = day.todayWorkshops.filter(workshop => {
                    return (
                      !workshop.disabled ||
                      (workshop.disabled &&
                        !this.settings.showWorkshopsForSelectedTicketsOnly)
                    );
                  });

                  const dayBorderHours: WorkshopDayBorderHoursModel = this.getDayBorderHours(
                    day.todayWorkshops
                  );

                  let workshopTimeLineItems: WorkshopTimelineItem[] = [];

                  for (
                    let hour = dayBorderHours.earliest;
                    hour < dayBorderHours.latest;
                    hour++
                  ) {
                    if (this.isAnyWorkshopInHour(hour, day)) {
                      workshopTimeLineItems.push({
                        hourStart: hour,
                        hourEnd: null,
                        hourHeight: this.hourHeighInPixels,
                        displayEndTime: false
                      });
                    } else {
                      let last = workshopTimeLineItems.slice(-1)[0];
                      if (!last.hourEnd) {
                        workshopTimeLineItems.push({
                          hourStart: hour,
                          hourEnd: hour,
                          hourHeight: this.hourHeighInPixels,
                          displayEndTime: false
                        });
                      } else {
                        last.hourEnd = last.hourEnd + 1;
                        last.displayEndTime = true;
                      }
                    }
                  }

                  day.timeLineList = workshopTimeLineItems;

                  // + 60 for header
                  day.timeLineWrapHeight = day.timeLineList.reduce(
                    (wrapHeight, timeLineItem) => {
                      return wrapHeight + timeLineItem.hourHeight;
                    },
                    60
                  );

                  // add position styles to workshops
                  day.todayWorkshops = day.todayWorkshops.map(
                    (workshop): WorkshopModel => {
                      const positionStyles = this.positionEvent(
                        dayBorderHours.earliest,
                        workshop.start,
                        workshop.end,
                        day.todayWorkshops,
                        day.timeLineList
                      );

                      return {
                        ...workshop,
                        styles: positionStyles
                      };
                    }
                  );

                  // workshops can overlap this will reposition workshops and adjust the hour line
                  this.repositionOverlappingWorkshops(day);

                  day.rooms = day.todayWorkshops
                    .map(workshop => workshop.roomId) // map to roomIds
                    .filter((value, index, self) => {
                      // returns list of unique roomIds
                      return self.indexOf(value) === index;
                    })
                    .map(
                      (roomId): WorkshopRoomModel => {
                        const roomName = this.getRoomName(workshops, roomId);
                        return {
                          roomId: roomId,
                          roomName: roomName,
                          roomSortOrder: day.todayWorkshops.find(
                            workshop => workshop.roomId === roomId
                          ).roomSortOrder,
                          workshops: day.todayWorkshops.filter(
                            workshop => workshop.roomId === roomId
                          )
                        };
                      }
                    )
                    .filter(room => {
                      return room.workshops.length > 0;
                    })
                    // first sort by number
                    .sort((a, b) => {
                      if (a.roomName.toLowerCase() < b.roomName.toLowerCase())
                        return -1;
                      if (a.roomName.toLowerCase() > b.roomName.toLowerCase())
                        return 1;
                      return 0;
                    })
                    // adjust sorting by sort order
                    .sort((a, b) => {
                      return a.roomSortOrder - b.roomSortOrder;
                    });
                }
              }

              this.workshopsByDates = workshopsByDates;
              this.workshopsByDatesArray = [];

              Object.keys(workshopsByDates).forEach((key, index) => {
                workshopsByDates[key].expanded = true;
                this.workshopsByDatesArray.push(workshopsByDates[key]);
              });

              this.workshopsByDatesArray = this.workshopsByDatesArray
                .filter(p => p.todayWorkshops.length > 0)
                .sort((a, b) => {
                  return (
                    new Date(a.date).getTime() - new Date(b.date).getTime()
                  );
                });
              this.workshopsLoaded = true;
              this.setWorkshopsSidePosition(null);
            });
        })
    );

    this.breakpoints = AppConstants.BREAKPOINTS;
    this.mediumBreakpoint = this.breakpoints['sm'];
    this.result.startWith(null).subscribe(() => {
      this.viewport = this._windowSizeService.viewportWidth();
    });

    /**
     * handle visitors and their subscription to workshops
     * */

    this._subscriptions.add(
      observableCombineLatest(
        this._store.select(fromRoot.getTickets),
        this._store.select(fromRoot.getTicketsTypes),
        this._store.select(fromRoot.getTicketHolderInputSets),
        this._store.select(fromRoot.getTicketHolderAdditionalData),
        this._store.select(fromRoot.getTicketsCount)
      )
        .pipe(
          filter(
            (
              data: [
                TicketModel,
                ProductGroupModel[],
                FormInputsPayloadModel[],
                TicketHolderAdditionalDataModel,
                number
              ]
            ) => {
              // TODO: REFACTOR!!!!
              return (
                !!data[0] &&
                !!data[1] &&
                !!data[2] &&
                data[2].length &&
                !!data[3] &&
                !!data[4]
              );
            }
          )
        )
        .subscribe(data => {
          const [
            ungroupedTickets,
            ticketTypes,
            ticketHolderInputSets,
            ticketHolderAdditionalData,
            ticketCount
          ] = data;

          const ticketsWithHolders = this._stepsFormsService.assignUngroupedTicketsToHolders(
            ungroupedTickets,
            ticketHolderInputSets,
            ticketHolderAdditionalData
          );

          this.ticketsWithHolders.next(ticketsWithHolders);

          this.isWorkshopSelected = Object.keys(ticketsWithHolders)
            .map(item => {
              let ticket = ticketsWithHolders[item];

              if (ticket.ticketCount === 0) return true;
              if (!ticket.ticketHasAllowedWorkshops) return true;

              return ticket.ticketHolderAdditionalData.workshops.length > 0;
            })
            .every(array => {
              if (array) {
                return true;
              }
            });

          let isWorkshopSelectedForFreeTickets = Object.keys(ticketsWithHolders)
            .map(item => {
              let ticket = ticketsWithHolders[item];

              if (ticket.ticketCount === 0) return true;
              if (!ticket.ticketHasAllowedWorkshops) return true;
              if (ticket.ticketPrice > 0) return true;

              return ticket.ticketHolderAdditionalData.workshops.length > 0;
            })
            .every(array => {
              if (array) {
                return true;
              }
            });

          if (
            this.isWorkshopMandatory ||
            this.workshopMandatoryForZeroPriceTickets
          ) {
            const mandatoryValid =
              !this.isWorkshopMandatory ||
              (this.isWorkshopMandatory && this.isWorkshopSelected);
            const zeroPriceValid =
              !this.workshopMandatoryForZeroPriceTickets ||
              (this.workshopMandatoryForZeroPriceTickets &&
                isWorkshopSelectedForFreeTickets);

            let validationMessage = !mandatoryValid
              ? 'workshop.not-selected'
              : !zeroPriceValid
              ? 'workshop.not-zero-selected'
              : null;

            this.setValidation(
              mandatoryValid && zeroPriceValid,
              validationMessage
            );
          }
        })
    );
  }

  ngAfterViewInit() {
    Object.keys(this.workshopsByDates).forEach((day, index) => {
      this.scrollHorizontaly(index);
    });
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  getRoomName(workshops: WorkshopModel[], roomId: number): string {
    return workshops.find(workshop => {
      return workshop.roomId === roomId;
    }).roomName;
  }

  addWorkshopToDay(
    workshops: WorkshopByDateModel,
    date: string,
    workshop: WorkshopModel
  ): WorkshopByDateModel {
    if (workshops.hasOwnProperty(date)) {
      workshops[date].todayWorkshops.push(workshop);
    } else {
      workshops[date] = {
        date: new Date(workshop.date).toISOString(),
        todayWorkshops: [workshop],
        timeLineWrapHeight: 0,
        timeLineList: [],
        rooms: []
      };
    }
    return workshops;
  }

  getDayBorderHours = (workshops): WorkshopDayBorderHoursModel => {
    return workshops
      .map(workshop => {
        const start = new Date(workshop.start);
        const end = new Date(workshop.end);

        return {
          start: start.getHours(),
          end: end.getHours() + 1 // +1 so we cover also hour only expressed by minutes
        } as any;
      })
      .reduce(
        (acc, workshop) => {
          return {
            earliest:
              acc.earliest < workshop.start ? acc.earliest : workshop.start,
            latest: acc.latest > workshop.end ? acc.latest : workshop.end
          };
        },
        {
          earliest: 24,
          latest: 0
        }
      );
  };

  isAnyWorkshopInHour(hour: number, workshops: WorkshopsTodayModel): boolean {
    return workshops.todayWorkshops.some(workshop => {
      const start = new Date(workshop.start);
      const end = new Date(workshop.end);

      return start.getHours() <= hour && end.getHours() >= hour;
    });
  }

  positionEvent(
    earliestWorkshop,
    workshopStart,
    workshopEnd,
    todayWorkshops: WorkshopModel[],
    timelineList: WorkshopTimelineItem[]
  ) {
    const earliestMinutes = earliestWorkshop * 60;
    const start = new Date(workshopStart);
    const end = new Date(workshopEnd);

    const workshopStartMinutes = start.getHours() * 60 + start.getMinutes();
    const workshopEndMinutes = end.getHours() * 60 + end.getMinutes();

    const workshopGridGap = 14;

    let workshopTopOffset = this.getWorkshopTopOffset(
      start.getHours(),
      workshopStartMinutes,
      earliestMinutes,
      timelineList
    );
    let workshopHeightOffset = this.getRelativeHourHeight(
      workshopEndMinutes - workshopStartMinutes
    );

    if (workshopHeightOffset < this.hourMinHeightInPixels) {
      workshopTopOffset = workshopTopOffset;

      // if (start.getHours() == timelineList[0].hour && ) {
      //   workshopTopOffset = workshopTopOffset + 400;
      // }
    }

    if (workshopHeightOffset < this.hourMinHeightInPixels) {
      workshopHeightOffset = this.hourMinHeightInPixels + workshopGridGap;
    }
    // this.hourMinHeightInPixels;

    const top = 60 + workshopTopOffset + workshopGridGap / 2 + 'px';
    const height = workshopHeightOffset - workshopGridGap + 'px';

    return { top, minHeight: height };
  }

  repositionOverlappingWorkshops(
    dayData: WorkshopsTodayModel
  ): WorkshopsTodayModel {
    const gap = 26;
    let rerun = true;

    while (rerun) {
      rerun = false;

      let additionalOffset = 0;
      let timesOfIncrease = 0;
      let overlappingHour = null;
      let overlappingInRoom = null;
      for (let i = 0; i < dayData.todayWorkshops.length; i++) {
        let prevWorkshop = dayData.todayWorkshops[i];
        for (let j = 0; j < dayData.todayWorkshops.length; j++) {
          let currWorkshop = dayData.todayWorkshops[j];

          const inSameRoom = prevWorkshop.roomId == currWorkshop.roomId;
          const notSameWorkshop =
            prevWorkshop.workshopId != currWorkshop.workshopId;
          const prevWorkshopStyles = prevWorkshop.styles;
          const previousWorkshopTopPosition = parseInt(prevWorkshopStyles.top);
          const prevWorkshopBottomPosition =
            previousWorkshopTopPosition +
            parseInt(prevWorkshopStyles.minHeight);
          const currWorkshopStyles = currWorkshop.styles;
          const currWorkshopTopPosition = parseInt(currWorkshopStyles.top);
          const currWorkshopBottomPosition =
            currWorkshopTopPosition + parseInt(currWorkshopStyles.minHeight);
          const workshopOverlap =
            prevWorkshopBottomPosition > currWorkshopTopPosition &&
            previousWorkshopTopPosition < currWorkshopBottomPosition;

          if (notSameWorkshop && inSameRoom && workshopOverlap) {
            const overlapArea =
              prevWorkshopBottomPosition - currWorkshopTopPosition;

            if (timesOfIncrease >= 1) {
              currWorkshop.styles.top = `${currWorkshopTopPosition +
                additionalOffset +
                gap +
                overlapArea +
                1}px`;
            } else {
              currWorkshop.styles.top = `${currWorkshopTopPosition +
                overlapArea +
                1}px`;
            }

            currWorkshop.styles.top = `${currWorkshopTopPosition +
              overlapArea +
              1}px`;

            additionalOffset = parseInt(currWorkshopStyles.minHeight) - gap;

            overlappingHour = new Date(currWorkshop.start).getHours();
            overlappingInRoom = currWorkshop.roomId;
            timesOfIncrease += 1;
          }
        }

        if (additionalOffset) {
          additionalOffset = additionalOffset / timesOfIncrease;
          dayData.todayWorkshops.forEach((currWorkshop, currIndex) => {
            const inSameRoom = overlappingInRoom === currWorkshop.roomId;
            const notSameWorkshop =
              prevWorkshop.workshopId != currWorkshop.workshopId;
            const workshopHour = new Date(currWorkshop.start).getHours();
            const sameHour = overlappingHour == workshopHour;
            const nextWorkshopHour = workshopHour > overlappingHour;

            const prevDuration =
              Number(new Date(prevWorkshop.end)) -
              Number(new Date(prevWorkshop.start));
            const currDuration =
              Number(new Date(currWorkshop.end)) -
              Number(new Date(currWorkshop.start));

            if (notSameWorkshop && additionalOffset && nextWorkshopHour) {
              currWorkshop.styles.top = `${parseInt(currWorkshop.styles.top) +
                additionalOffset +
                1}px`;
            }

            if (
              notSameWorkshop &&
              !inSameRoom &&
              additionalOffset &&
              sameHour &&
              prevDuration <= currDuration
            ) {
              currWorkshop.styles.minHeight = `${parseInt(
                currWorkshop.styles.minHeight
              ) +
                additionalOffset +
                1}px`;
            }

            const currentWorkshopEnd = new Date(currWorkshop.end);
            const exceedsOverlappingHour =
              currentWorkshopEnd.getHours() > overlappingHour;

            if (
              notSameWorkshop &&
              additionalOffset &&
              sameHour &&
              exceedsOverlappingHour
            ) {
              const exceedingMinutes = currentWorkshopEnd.getMinutes();
              const exceedingDelta = (160 * exceedingMinutes) / 60;

              additionalOffset = additionalOffset - exceedingDelta;
            }
          });

          dayData.timeLineList.find((hourLine, index) => {
            if (hourLine.hourStart === overlappingHour) {
              hourLine.hourHeight = hourLine.hourHeight + additionalOffset;
              dayData.timeLineList[index] = hourLine;

              return true;
            }
          });

          dayData.timeLineWrapHeight =
            dayData.timeLineWrapHeight + additionalOffset;
          rerun = true;
          break;
        }
      }
    }

    return dayData;
  }

  getWorkshopTopOffset(
    workshopStartHour: number,
    workshopStartMinutes: number,
    earliestMinutes: number,
    timeLineList: WorkshopTimelineItem[]
  ): number {
    let topOffset = workshopStartMinutes - earliestMinutes;

    for (let i = 0; i < timeLineList.length; i++) {
      let timeLineItem: WorkshopTimelineItem = timeLineList[i];
      if (timeLineItem.hourStart === workshopStartHour) break;

      if (timeLineItem.hourEnd) {
        topOffset =
          topOffset - (timeLineItem.hourEnd - timeLineItem.hourStart) * 60;
      }
    }

    return (topOffset / 60) * this.hourHeighInPixels;
  }

  getRelativeHourHeight(delta: number): number {
    return (delta / 60) * this.hourHeighInPixels; // divede by sixty to get hour then turn it to pixels
  }

  getWorkshopSidePosition(workshop, rooms, side: 'left' | 'right') {
    const noOfRoomsThisDay = rooms.length;

    let response = '0';

    rooms.forEach((room, index) => {
      room.workshops.forEach(workshopInDay => {
        if (workshopInDay === workshop) {
          if (side === 'left') {
            response = this.roomWidth * index + 5 + 'px';
          } else if (side === 'right') {
            response = this.roomWidth * (noOfRoomsThisDay - index - 1) + 'px';
          }
        }
      });
    });

    return response;
  }

  setWorkshopsSidePosition(activeWorkshopId) {
    for (const dayKey in this.workshopsByDates) {
      if (this.workshopsByDates.hasOwnProperty(dayKey)) {
        const day = this.workshopsByDates[dayKey];
        this.isMobile = this._helperService.isMobile();

        day.todayWorkshops.forEach(workshop => {
          if (
            activeWorkshopId !== null &&
            activeWorkshopId === workshop.workshopId &&
            this.isMobile
          ) {
            workshop.styles.left = 0;
            workshop.styles.right = 0;
            workshop.styles.zIndex = 23;
            //workshop.styles.minHeight = '300px'; commented out because it caused smaller images to scale according to minHeight
            workshop.styles.maxWidth = '100%';
          } else {
            workshop.styles.left = this.getWorkshopSidePosition(
              workshop,
              day.rooms,
              'left'
            );
            workshop.styles.right = this.getWorkshopSidePosition(
              workshop,
              day.rooms,
              'right'
            );

            workshop.styles.zIndex = 22;
            //workshop.styles.minHeight = workshop.styles.height;
            workshop.styles.width = '200px';
          }
        });
      }
    }
  }

  scrollHorizontaly(index) {
    let workshopWrapper = document.getElementById('workshopWrapper' + index);
    let workshopRoom = document.getElementById('rooms' + index);
    let fakeScrollBarWrapper = document.getElementById(
      'fakeScrollbarWrapper' + index
    );
    let fakeScrollBar = document.getElementById('fakeScrollbar' + index);

    if (!workshopWrapper) return;

    const timeColumnWidth = 50; //Configured width in css
    const timeColumnWidthOffset =
      workshopWrapper.offsetWidth - workshopRoom.offsetWidth; // Real rendered width
    if (
      workshopRoom.scrollWidth > workshopRoom.offsetWidth &&
      !this.isMobile &&
      !this.elementHasEventListener
    ) {
      if (!this._helperService.isMobile()) {
        fakeScrollBar.style.display = 'block';
      }

      let fakeScrollBarChildDiv = fakeScrollBar.children[0];
      this._renderer.setElementStyle(
        fakeScrollBarChildDiv,
        'width',
        (workshopRoom.scrollWidth - timeColumnWidth).toString() + 'px'
      );
      // Move fake scrollbar right to be same width as content scrollbar due to bar synchronization
      this._renderer.setElementStyle(
        fakeScrollBarWrapper,
        'margin-left',
        timeColumnWidthOffset.toString() + 'px'
      );

      workshopRoom.addEventListener(
        'mousewheel',
        event => {
          const delta = Math.max(
            -1,
            Math.min(1, event.wheelDelta || -event.detail)
          );
          workshopRoom.scrollLeft -= delta * 50;
          fakeScrollBar.scrollLeft = workshopRoom.scrollLeft;
          event.preventDefault();
        },
        false
      );

      workshopRoom.addEventListener(
        'scroll',
        event => {
          fakeScrollBar.scrollLeft = workshopRoom.scrollLeft;
          event.preventDefault();
        },
        false
      );
      this.elementHasEventListener = true;
    }
  }

  scrollWorkshopRoomWithFakeScrollbar(fakeScrollElement, workshopRoom) {
    this.scrollLeft.next({
      fakeScrollLeft: fakeScrollElement.scrollLeft,
      el: workshopRoom
    });
  }

  scrollFakeScrollBar(fakeScrollElement, workshopRoom) {
    this.scrollLeft.next({
      fakeScrollLeft: workshopRoom.scrollLeft,
      el: fakeScrollElement
    });
  }

  setValidation(isValid: boolean, validationMessage: string) {
    if (isValid) {
      this._formsService.removeStepValidationFeedback(
        this.stepsFormsActionName,
        'workshop'
      );
    } else {
      this._formsService.addStepValidationFeedback(
        this.stepsFormsActionName,
        'workshop',
        validationMessage
      );
    }

    this._formsService.setFormValidity(
      isValid,
      null,
      this.stepsFormsActionName
    );
  }

  toggleDetail(activeWorkshopId) {
    this.toggledWorkshop = this.workshops.find(
      workshop => workshop.workshopId === activeWorkshopId
    );

    if (!!activeWorkshopId) {
      this.toggledWorkshop.top = window.pageYOffset + 100;
    }

    this.activeWorkshopId =
      this.activeWorkshopId === null ? activeWorkshopId : null;
    this.setWorkshopsSidePosition(this.activeWorkshopId);
    this.toggleDetailWindow = !this.toggleDetailWindow;
  }

  toggleWorkshopAccordian(event, workshop: any) {
    workshop.expanded = !workshop.expanded;
  }

  navigateToDay(day: number) {
    const element = document.getElementById(this.generateDayId(day));

    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    });
  }

  generateDayId(day: number) {
    return `day${day}`;
  }

  getScrollY() {
    return window.pageYOffset || document.body.scrollTop;
  }

  scrollDown() {
    let target =
      this.getScrollY() +
      Math.max(
        document.documentElement.clientHeight,
        window.innerHeight || 250
      );

    this.scrollToTarget(target);
  }

  scrollUp() {
    let target =
      this.getScrollY() -
      Math.max(
        document.documentElement.clientHeight,
        window.innerHeight || 250
      );

    this.scrollToTarget(target);
  }

  scrollToTarget(target: number) {
    const supportsNativeSmoothScroll =
      'scrollBehavior' in document.documentElement.style;

    if (supportsNativeSmoothScroll) {
      window.scrollTo({
        top: target,
        behavior: 'smooth'
      });
    } else {
      window.scroll(window.pageXOffset, target);
    }
  }

  convertFromTime(date: any): Date {
    if (date instanceof Date) {
      let text = `${('0' + date.getHours()).substr(-2)}:${(
        '0' + date.getMinutes()
      ).substr(-2)}`;
      let dateString = `2020-01-01T${text}:00.000Z`;
      return new Date(dateString);
    }

    return new Date(`${date}.000Z`);
  }
}
