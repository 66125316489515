/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./skeleton-loader.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/ngx-skeleton-loader/ngx-skeleton-loader.ngfactory";
import * as i3 from "ngx-skeleton-loader";
import * as i4 from "./skeleton-loader.component";
var styles_SkeletonLoaderComponent = [i0.styles];
var RenderType_SkeletonLoaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SkeletonLoaderComponent, data: {} });
export { RenderType_SkeletonLoaderComponent as RenderType_SkeletonLoaderComponent };
export function View_SkeletonLoaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ngx-skeleton-loader", [["animation", "progress"], ["appearance", "line"], ["count", "1"]], null, null, null, i2.View_NgxSkeletonLoaderComponent_0, i2.RenderType_NgxSkeletonLoaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.NgxSkeletonLoaderComponent, [], { count: [0, "count"], appearance: [1, "appearance"], animation: [2, "animation"], theme: [3, "theme"] }, null), i1.ɵpod(2, { "background-color": 0, "height": 1, "border-radius": 2 })], function (_ck, _v) { var currVal_0 = "1"; var currVal_1 = "line"; var currVal_2 = "progress"; var currVal_3 = _ck(_v, 2, 0, "#ccc", "20px", 10); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_SkeletonLoaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-skeleton-loader", [], null, null, null, View_SkeletonLoaderComponent_0, RenderType_SkeletonLoaderComponent)), i1.ɵdid(1, 114688, null, 0, i4.SkeletonLoaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SkeletonLoaderComponentNgFactory = i1.ɵccf("app-skeleton-loader", i4.SkeletonLoaderComponent, View_SkeletonLoaderComponent_Host_0, {}, {}, []);
export { SkeletonLoaderComponentNgFactory as SkeletonLoaderComponentNgFactory };
