import { filter, first } from 'rxjs/operators';
import * as fromRoot from '../../../app.reducer';
import * as ticketActions from '../../../shared/services-with-reducers/tickets/ticket.actions';
import * as stepsActions from '../../../shared/services-with-reducers/step-forms/steps-forms.actions';
import { OnChanges, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormsService } from '../../../shared/forms/forms.service';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs/Subscription';
import { combineLatest as observableCombineLatest } from 'rxjs';
import { TicketsService } from '../../../shared/services-with-reducers/tickets/tickets.service';
var SendingOptionsComponent = /** @class */ (function () {
    function SendingOptionsComponent(_store, _formsService, _ticketService, _router, _location) {
        var _this = this;
        this._store = _store;
        this._formsService = _formsService;
        this._ticketService = _ticketService;
        this._router = _router;
        this._location = _location;
        this.ticketSendingOptions = [];
        this.selectedSendingOption = '';
        this.showSendingOptions = true;
        this.subscription = new Subscription();
        this.wasAnonymousTicketTaken = false;
        this.optionSelectedBeforeAnonim = '';
        this.changedUrl = '';
        this.subscription.add(observableCombineLatest([
            this._store.pipe(select(fromRoot.getSendingOptions)),
            this._store.pipe(select(fromRoot.getTickets))
        ]).pipe(filter(function (_a) {
            var getSendingOptions = _a[0], getTickets = _a[1];
            return (!!getSendingOptions &&
                !!getTickets);
        }))
            .subscribe(function (_a) {
            var getSendingOptions = _a[0], getTickets = _a[1];
            var sendingOptions = getSendingOptions;
            var ungroupedTickets = getTickets;
            var anonymousTicketTaken = _this._ticketService.checkIfAnonymousTicketTaken(ungroupedTickets);
            // delete query params, change URL
            if (!(_this._router.url.includes("utm_source") || _this._router.url.includes("utm_medium") || _this._router.url.includes("utm_campaign") || _this._router.url.includes("utm_term") || _this._router.url.includes("utm_content"))) {
                _this.changedUrl = _this._router.url.split('?')[0];
                _this._location.replaceState(_this.changedUrl);
            }
            if (!anonymousTicketTaken && _this.wasAnonymousTicketTaken) {
                var anonyMobilePerOwner = sendingOptions.find(function (option) { return option.value === 'mobilePerOwner'; });
                if (!!anonyMobilePerOwner) {
                    anonyMobilePerOwner.isSelected = _this.wasAnonymousTicketTaken = false;
                }
                var allToBuyer = sendingOptions.find(function (option) { return option.value === _this.optionSelectedBeforeAnonim; });
                if (!!allToBuyer) {
                    allToBuyer.isSelected = true;
                }
            }
            sendingOptions.forEach(function (option) {
                if (anonymousTicketTaken) {
                    if (option.value === 'mobilePerOwner') {
                        _this.selectedSendingOption = 'mobilePerOwner';
                        option.isSelected = _this.wasAnonymousTicketTaken = true;
                        _this.showSendingOptions = false;
                    }
                    else {
                        option.isSelected = false;
                    }
                }
                else {
                    if (option.isSelected) {
                        _this.optionSelectedBeforeAnonim = option.value;
                        _this.selectedSendingOption = option.value;
                    }
                }
            });
            if (_this.selectedSendingOption === '') {
                var selectedSendingOption = sendingOptions.find(function (option) { return option.isEnabled; });
                if (!!selectedSendingOption) {
                    selectedSendingOption.isSelected = true;
                    _this.selectedSendingOption = selectedSendingOption.value;
                }
            }
            _this.ticketSendingOptions = _this.addOptionVisibility(sendingOptions);
            if (!anonymousTicketTaken) {
                _this.showSendingOptions = _this.ticketSendingOptions.filter(function (option) {
                    return option.isEnabled && option.isVisible && option.isBasic;
                }).length > 1;
            }
            _this.setSendingOptionTicketName();
            if (_this.selectedSendingOption === 'ticketRetrivalLink') {
                _this._store
                    .select(fromRoot.getTicketHolderInputSets)
                    .pipe(first())
                    .subscribe(function (holders) {
                    holders.forEach(function (holder) {
                        var invalid = holder.inputSet.list.reduce(function (acc, curr) {
                            return (acc ||
                                (['email', 'firstName', 'lastNmae'].includes(curr.key) &&
                                    !curr.value));
                        }, false);
                        if (invalid) {
                            _this._formsService.setFormValidity(false, null, holder.formInfo);
                        }
                    });
                });
            }
            if (_this.selectedSendingOption === 'mobilePerOwner' &&
                !!_this.exhibitionSettings && _this.exhibitionSettings.emailIsMandatoryForMobileTicket) {
                _this._store
                    .select(fromRoot.getTicketHolderInputSets)
                    .first()
                    .subscribe(function (holders) {
                    holders.forEach(function (holder) {
                        var invalid = holder.inputSet.list.reduce(function (acc, curr) {
                            return (acc ||
                                (['verifyEmail', 'email'].includes(curr.key) &&
                                    !curr.value));
                        }, false);
                        if (invalid) {
                            _this._formsService.setFormValidity(false, null, holder.formInfo);
                        }
                    });
                });
            }
        }));
    }
    SendingOptionsComponent.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
    };
    SendingOptionsComponent.prototype.ngOnChanges = function (ticketCount) {
        if (this.ticketSendingOptions.length) {
            this.setSendingOptions();
        }
    };
    SendingOptionsComponent.prototype.addOptionVisibility = function (sendingOptions) {
        var optionsWithVisibility = sendingOptions.map(function (option) {
            option.isVisible = option.isEnabled;
            return option;
        });
        return optionsWithVisibility;
    };
    SendingOptionsComponent.prototype.setSendingOptionTicketName = function () {
        switch (this.selectedSendingOption) {
            case 'normalPerOwner':
                this.sendingOptionTicketName = 'Print@Home';
                break;
            case 'mobilePerOwner':
                this.sendingOptionTicketName = 'Mobile';
                break;
            case 'ticketRetrivalLink':
                this.sendingOptionTicketName = 'Print@Home';
                break;
        }
    };
    SendingOptionsComponent.prototype.sendingOptionChanged = function () {
        var _this = this;
        var updatedSendingOptions = this.ticketSendingOptions.map(function (option) {
            if (option.value === _this.selectedSendingOption) {
                option.isSelected = true;
            }
            else {
                option.isSelected = false;
            }
            return option;
        });
        this.setSendingOptionTicketName();
        this._store.dispatch(new ticketActions.SetSendingOptionsAction(updatedSendingOptions));
        if (this.selectedSendingOption !== 'ticketRetrivalLink') {
            var visitorQuestionnaireValidation = ['personal', 'visitorQuestionnaire'];
            this._store.dispatch(new stepsActions.SetBuyerVisitorCheckbox({
                buyerVisitorCheckedSlideIndex: null,
                isBuyerVisitorChecked: false,
                showVisitorQuestionnaire: false
            }));
            this._formsService.removeAllStepValidationFeedbacks(visitorQuestionnaireValidation);
            this._formsService.setFormValidity(true, null, visitorQuestionnaireValidation);
        }
    };
    SendingOptionsComponent.prototype.setSendingOptions = function () {
        this._store.dispatch(new ticketActions.SetSendingOptionsAction(this.ticketSendingOptions));
    };
    return SendingOptionsComponent;
}());
export { SendingOptionsComponent };
