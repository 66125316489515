import { AppConstants } from '../app-constants';
import { HttpErrorResponse } from '@angular/common/http';
import { StatusBarService } from '../../status-bar/status-bar.service';
import { TranslateService } from '@ngx-translate/core';
import { throwError as observableThrowError, BehaviorSubject } from 'rxjs';
import * as helperActions from '../../shared/services-with-reducers/helpers/helper.actions';
import { Store } from '@ngrx/store';
import { consoleLog } from '../app-utils';
import * as i0 from "@angular/core";
import * as i1 from "../../status-bar/status-bar.service";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@ngrx/store";
var ErrorHandlingService = /** @class */ (function () {
    function ErrorHandlingService(_statusBarService, _translateService, _store) {
        this._statusBarService = _statusBarService;
        this._translateService = _translateService;
        this._store = _store;
        this.translatedErrorMessage = new BehaviorSubject(null);
    }
    ErrorHandlingService.prototype.errorHandler = function (error) {
        var _this = this;
        consoleLog(JSON.stringify(error));
        var errMsg;
        if (error instanceof HttpErrorResponse || (error.hasOwnProperty('errors') && error.errors.length > 0)) {
            var errorCode_1 = [];
            var errorKeyExists_1 = false;
            try {
                if (error.error.hasOwnProperty('errorCode')) {
                    errorCode_1.push(error.error.errorCode);
                }
                else if (error.error.hasOwnProperty('ErrorCode')) {
                    errorCode_1.push(error.error.ErrorCode);
                }
                else if (error.hasOwnProperty('errors')) {
                    if (!!error.errors) {
                        error.errors.forEach(function (element, index) {
                            errorCode_1.push(element.code);
                            if (!errorKeyExists_1 && index > 0) {
                                errorKeyExists_1 = errorCode_1.some(function (item) { return item === element.code; });
                            }
                        });
                    }
                }
                if (!!errorCode_1 && errorCode_1.length > 0) {
                    errorCode_1.forEach(function (currentError, index) {
                        _this._translateService
                            // multipleErrors is key when there are multiple same error keys in errorCode array, else take currentError key and get it's translation if available
                            .get('response.' + (errorKeyExists_1 ? 'multipleErrors' : currentError))
                            .subscribe(function (translation) {
                            errMsg = translation;
                            if (error.error.hasOwnProperty('invalidFields')) {
                                var fields = error.error['invalidFields'].join(', ');
                                translation += ": " + fields;
                            }
                            // in case the error translation was not found.. respond with backend message
                            if (
                            // if errors are in response object and there are multiple errors with same error code set errors to their message in error.errors array
                            translation === AppConstants.MISSING_TRANSLATION && !!error.errors &&
                                error.errors.length > 1) {
                                translation = error.errors[index].message;
                            }
                            else if (translation === AppConstants.MISSING_TRANSLATION &&
                                error.error.hasOwnProperty('errorMessage')) {
                                translation = error.error.errorMessage;
                            }
                            else if (translation === AppConstants.MISSING_TRANSLATION &&
                                error.error.hasOwnProperty('ErrorMessage')) {
                                translation = error.error.ErrorMessage;
                            }
                            else if (translation === AppConstants.MISSING_TRANSLATION) {
                                translation = 'Server Error';
                            }
                            var translatedMessage = {
                                status: error.status,
                                key: currentError,
                                message: translation
                            };
                            _this.translatedErrorMessage.next(translatedMessage);
                            var isMissingSeries = translation.startsWith('Event Serie with identifier') &&
                                translation.endsWith('does not exists.');
                            if (!isMissingSeries) {
                                var dateTime = error.error.hasOwnProperty('serverTime') ? "[" + error.error['serverTime'] + "]" : "<" + new Date().toISOString() + ">";
                                _this._statusBarService.setStatus(translation, 'error', null, dateTime);
                            }
                        });
                    });
                }
            }
            catch (err) {
                errMsg = error.status + " - " + (error.statusText || '') + " " + err;
                var clientTime = "<" + new Date().toISOString() + ">";
                this._statusBarService.setStatus(errMsg, 'error', null, clientTime);
            }
        }
        else {
            errMsg = error.message ? error.message : error.toString();
            var clientTime = "<" + new Date().toISOString() + ">";
            this._statusBarService.setStatus(errMsg, 'error', null, clientTime);
        }
        this._store.dispatch(new helperActions.SetSpinnerValue(false));
        return observableThrowError(errMsg);
    };
    ErrorHandlingService.prototype.clearAllErrors = function () {
        this.translatedErrorMessage.next(null);
    };
    ErrorHandlingService.ngInjectableDef = i0.defineInjectable({ factory: function ErrorHandlingService_Factory() { return new ErrorHandlingService(i0.inject(i1.StatusBarService), i0.inject(i2.TranslateService), i0.inject(i3.Store)); }, token: ErrorHandlingService, providedIn: "root" });
    return ErrorHandlingService;
}());
export { ErrorHandlingService };
