/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./step-navigation-buttons.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../feedback-message/feedback-message.component.ngfactory";
import * as i5 from "../feedback-message/feedback-message.component";
import * as i6 from "../shared/pipes/hide-translation/hide-translation.pipe";
import * as i7 from "./step-navigation-buttons.component";
import * as i8 from "@angular/router";
import * as i9 from "@ngrx/store";
import * as i10 from "../shared/forms/forms.service";
import * as i11 from "../shared/window-size/window-size.service";
import * as i12 from "../shared/services-with-reducers/step-forms/steps-forms.service";
import * as i13 from "../shared/services-with-reducers/helpers/helper.service";
import * as i14 from "../login/login.service";
var styles_StepNavigationButtonsComponent = [i0.styles];
var RenderType_StepNavigationButtonsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StepNavigationButtonsComponent, data: {} });
export { RenderType_StepNavigationButtonsComponent as RenderType_StepNavigationButtonsComponent };
function View_StepNavigationButtonsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "button", [["class", "continue-button button"], ["type", "button"]], null, [[null, "click"], [null, "mouseenter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onContinueClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (_co.loadNextInvalidTicketHolderForm() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "feedback-button-disabled": 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 3, "span", [["class", "continue-button-text"], ["data-translation", "step-navigation.continue-button"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(6, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "continue-button button"; var currVal_1 = _ck(_v, 2, 0, _co.isButtonDisabled); _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 4, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent, 0), i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("step-navigation.continue-button")))); _ck(_v, 4, 0, currVal_2); }); }
function View_StepNavigationButtonsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "feedback-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-feedback-message", [["class", "feedback-messages"]], null, null, null, i4.View_FeedbackMessageComponent_0, i4.RenderType_FeedbackMessageComponent)), i1.ɵdid(2, 49152, null, 0, i5.FeedbackMessageComponent, [], { feedbackMessages: [0, "feedbackMessages"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.feedbackMessages; _ck(_v, 2, 0, currVal_0); }, null); }
function View_StepNavigationButtonsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-feedback-message", [["class", "feedback-messages"]], null, null, null, i4.View_FeedbackMessageComponent_0, i4.RenderType_FeedbackMessageComponent)), i1.ɵdid(2, 49152, null, 0, i5.FeedbackMessageComponent, [], { feedbackMessages: [0, "feedbackMessages"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.feedbackMessages; _ck(_v, 2, 0, currVal_0); }, null); }
function View_StepNavigationButtonsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "back-button button button-option"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onBackClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "span", [["data-translation", "step-navigation.back-button"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(4, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("step-navigation.back-button")))); _ck(_v, 2, 0, currVal_0); }); }
export function View_StepNavigationButtonsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i6.HideTranslationPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "step-navigation-button-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [["class", "feedback-button"]], [[2, "payment", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.fixFirstInvalidInput() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StepNavigationButtonsComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StepNavigationButtonsComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "alternative-button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, [["alternativeSubmit", 1]], null, 1, "div", [], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StepNavigationButtonsComponent_3)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StepNavigationButtonsComponent_4)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (!_co.hideButton && !_co.hasAlternativeButton); _ck(_v, 4, 0, currVal_1); var currVal_2 = ((_co.isButtonDisabled && _co.feedbackMessages) && _co.feedbackMessages.length); _ck(_v, 6, 0, currVal_2); var currVal_3 = ((((i1.ɵnov(_v, 8).children.length !== 0) && _co.isButtonDisabled) && _co.feedbackMessages) && _co.feedbackMessages.length); _ck(_v, 11, 0, currVal_3); var currVal_4 = _co.showBackButton; _ck(_v, 13, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.payment && _co.paymentValue); _ck(_v, 2, 0, currVal_0); }); }
export function View_StepNavigationButtonsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-step-navigation-buttons", [], null, null, null, View_StepNavigationButtonsComponent_0, RenderType_StepNavigationButtonsComponent)), i1.ɵdid(1, 180224, null, 0, i7.StepNavigationButtonsComponent, [i8.ActivatedRoute, i8.Router, i9.Store, i10.FormsService, i11.WindowSizeService, i12.StepsFormsService, i13.HelperService, i14.LoginService], null, null)], null, null); }
var StepNavigationButtonsComponentNgFactory = i1.ɵccf("app-step-navigation-buttons", i7.StepNavigationButtonsComponent, View_StepNavigationButtonsComponent_Host_0, { payment: "payment", hideButton: "hideButton", paymentValue: "paymentValue", hasAlternativeButton: "hasAlternativeButton", feedbackChanged: "feedbackChanged" }, {}, ["[alternative-submit]"]);
export { StepNavigationButtonsComponentNgFactory as StepNavigationButtonsComponentNgFactory };
