import { Injectable } from '@angular/core';
import { StatusStackModel } from './status-stack.interface';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class StatusBarService {
  public statusStack: StatusStackModel[] = [];
  private DEFAULT_TIMEOUT = 15000;
  private customTimeOut: number;

  constructor(private _translateService: TranslateService) {}

  removeFirstStatusInArray() {
    setTimeout(() => {
      this.statusStack.shift();
    }, this.getTimeOut());
  }

  getTimeOut() {
    return this.customTimeOut ? this.customTimeOut : this.DEFAULT_TIMEOUT;
  }

  setStatus(status, type, timeInMilisecond?: number, dateTime?: string) {
    if (timeInMilisecond) {
      this.customTimeOut = timeInMilisecond;
    }

    this.statusStack.push({ message: status, type: type, dateTime: dateTime });
    this.removeFirstStatusInArray();
  }

  setServerStatus(status, type, timeInMilisecond?: number, dateTime?: string) {
    if (timeInMilisecond) {
      this.customTimeOut = timeInMilisecond;
    }

    this._translateService.get('error.server').subscribe((res: any) => {
      this.statusStack.push({ message: `${res}: ${status}`, type: type, dateTime: dateTime });
      this.removeFirstStatusInArray();
    });
  }

  translateAndSetStatus(translationKey: string, status: string, dateTime?: string) {
    this._translateService.get(translationKey).subscribe((translation: any) => {
      this.setStatus(translation, status, null, dateTime);
    });
  }
}
