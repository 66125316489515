var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
export function isDefined(value) {
    return typeof value !== 'undefined' && value !== null;
}
var TranslateParser = /** @class */ (function () {
    function TranslateParser() {
    }
    return TranslateParser;
}());
export { TranslateParser };
var CustomParser = /** @class */ (function (_super) {
    __extends(CustomParser, _super);
    function CustomParser() {
        var _this = _super.call(this) || this;
        _this.templateMatcher = /{{\s?([^{}\s]*)\s?}}/g;
        CustomParser.translationWithInterpolation = _this.interpolate.bind(_this);
        return _this;
    }
    CustomParser.prototype.interpolate = function (expr, params) {
        var result;
        if (typeof expr === 'string') {
            result = this.interpolateString(expr, params);
        }
        else if (typeof expr === 'function') {
            result = this.interpolateFunction(expr, params);
        }
        else {
            // this should not happen, but an unrelated TranslateService test depends on it
            result = expr;
        }
        return result;
    };
    CustomParser.prototype.getValue = function (target, key) {
        var keys = key.split('.');
        key = '';
        do {
            key += keys.shift();
            if (isDefined(target) &&
                isDefined(target[key]) &&
                (typeof target[key] === 'object' || !keys.length)) {
                target = target[key];
                key = '';
            }
            else if (!keys.length) {
                target = undefined;
            }
            else {
                key += '.';
            }
        } while (keys.length);
        return target;
    };
    CustomParser.prototype.interpolateFunction = function (fn, params) {
        return fn(params);
    };
    CustomParser.prototype.interpolateString = function (expr, params) {
        var _this = this;
        if (!params) {
            return expr;
        }
        return expr.replace(this.templateMatcher, function (substring, b) {
            var r = _this.getValue(params, b);
            return isDefined(r) ? r : substring;
        });
    };
    return CustomParser;
}(TranslateParser));
export { CustomParser };
