import * as bowser from 'bowser';
import * as fromRoot from '../app.reducer';

import { Component, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';

@Component({
  selector: 'app-browser-check',
  templateUrl: './browser-check.component.html',
  styleUrls: ['./browser-check.component.scss']
})
export class BrowserCheckComponent implements OnInit {
  public browserModalOpened = false;
  public browserModalCloseVisible = true;

  constructor(private _store: Store<fromRoot.State>) {}

  ngOnInit() {
    const browser = bowser.getParser(window.navigator.userAgent);

    //close button should be hidden in Internet Explorer:
    this.browserModalCloseVisible = browser.getBrowserName() !== 'Internet Explorer';

    const isValidBrowser = browser.satisfies({
      // declare browsers per OS
      windows: {
        // #3863 - IE not supported
        'internet explorer': '<1',
        'Microsoft Edge': '>1'
      },
      macos: {
        safari: '>10'
      },
      // per platform (mobile, desktop or tablet)
      mobile: {
        safari: '>10',
        samsung_internet: '>8.0.00.90',
        'android browser': '>3.10'
      },
      chrome: '>58',
      firefox: '>51',
      opera: '>45',
      samsung_internet: '>8.0.00.90'
    });

    // #3863 - IE not supported
    this.browserModalOpened =
      // !localStorage.getItem('browser-check-passed') && 
      !isValidBrowser;
  }

  downloadBrowser(href) {
    window.open(href, "_blank");
  }

  closeModalWindow(event) {
    event.preventDefault();
    event.stopPropagation();
    this.browserModalOpened = false;
    // #3863 - IE not supported
    // localStorage.setItem('browser-check-passed', 'passed');
  }

  closeModalWindowOnRightClick(event) {
    this._store
      .select(fromRoot.isLoggedInAsAdmin)
      .first()
      .subscribe(isAdmin => {
        if (isAdmin) {
          this.browserModalOpened = false;
        }
      });
  }
}
