import {
  AddWorkshopBookingModel,
  MenuModel,
  WorkshopBookingPostModel,
  WorkshopFullModel,
  WorkshopModel,
  AddWorkshopBookingWithHolderModel
} from './additional-services.interface';

import { Action } from '@ngrx/store';
import { AssignWorkshopToHolderModel } from '../step-forms/step.interface';

export enum ActionTypes {
  SET_MENU = '[AdditionalServices] Set menu options',
  GET_MENU_OPTIONS = '[AdditionalServices] Get menu options from API',
  SET_WORKSHOPS = '[AdditionalServices] Set workshops ',
  GET_WORKSHOPS = '[AdditionalServices] Get workshops form API',
  POST_WORKSHOP_BOOKING = '[AdditionalServices] Post workshop booking',
  ADD_WORKSHOP_BOOKING = '[AdditionalServices] Add workshop booking',
  ADD_WORKSHOP_BOOKING_FULL = '[AdditionalServices] Add workshop booking full',
  REMOVE_MY_WORKSHOP_BOOKINGS = '[AdditionalServices] Remove workshop booking',
  RESET_REDUCER = '[AdditionalServices] reset reducer',
  AFTER_ADD_WORKSHOP_BOOKING = '[AdditionalServices] After add workshop booking'
}

export class SetMenu implements Action {
  readonly type = ActionTypes.SET_MENU;
  constructor(public payload: MenuModel[]) {}
}

export class GetMenuOptions implements Action {
  readonly type = ActionTypes.GET_MENU_OPTIONS;
  constructor(public payload: number) {}
}

export class SetWorkshops implements Action {
  readonly type = ActionTypes.SET_WORKSHOPS;
  constructor(public payload: WorkshopModel[]) {}
}

export class GetWorkshops implements Action {
  readonly type = ActionTypes.GET_WORKSHOPS;
  constructor(public payload: number) {}
}

export class AddWorkshopBooking implements Action {
  readonly type = ActionTypes.ADD_WORKSHOP_BOOKING;
  constructor(public payload: AddWorkshopBookingWithHolderModel) {}
}

export class AfterAddWorkshopBooking implements Action {
  readonly type = ActionTypes.AFTER_ADD_WORKSHOP_BOOKING;
  constructor(public payload: AssignWorkshopToHolderModel) {}
}

export class RemoveMyWorkshopBookings implements Action {
  readonly type = ActionTypes.REMOVE_MY_WORKSHOP_BOOKINGS;
}

export class PostWorkshopBooking implements Action {
  readonly type = ActionTypes.POST_WORKSHOP_BOOKING;
  constructor(
    public payload: WorkshopBookingPostModel,
    public assignWorkshopToHolderModel: AssignWorkshopToHolderModel
  ) {}
}
export class AddWorkshopBookingFull implements Action {
  readonly type = ActionTypes.ADD_WORKSHOP_BOOKING_FULL;
  constructor(public payload: WorkshopFullModel) {}
}

export class ResetReducer implements Action {
  readonly type = ActionTypes.RESET_REDUCER;
}

export type Actions =
  | SetMenu
  | SetWorkshops
  | GetMenuOptions
  | GetWorkshops
  | ResetReducer
  | AddWorkshopBooking
  | RemoveMyWorkshopBookings
  | PostWorkshopBooking
  | AddWorkshopBookingFull
  | AfterAddWorkshopBooking;
