import * as exhibitionActions from '../shared/services-with-reducers/exhibition/exhibition.actions';
import * as fromRoot from '../app.reducer';
import * as helperActions from '../shared/services-with-reducers/helpers/helper.actions';
import * as legitimationActions from '../shared/services-with-reducers/legitimation/legitimation.actions';
import * as stepsFormsActions from '../shared/services-with-reducers/step-forms/steps-forms.actions';

import {
  ActivatedRoute,
  Router,
  ParamMap,
  convertToParamMap
} from '@angular/router';
import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Subscription, combineLatest as observableCombineLatest } from 'rxjs';
import { debounceTime, filter, first } from 'rxjs/operators';

import { AppService } from '../app.service';
import { CustomizationService } from '../shared/services-with-reducers/customization/customization.service';
import { FormsService } from '../shared/forms/forms.service';
import { Location } from '@angular/common';
import { select, Store } from '@ngrx/store';
import { HelperService } from '../shared/services-with-reducers/helpers/helper.service';

@Component({
  moduleId: module.id,
  selector: 'app-web-shop',
  templateUrl: './web-shop.component.html',
  styleUrls: ['./web-shop.component.scss']
})
export class WebShopComponent implements OnInit, OnDestroy {
  @HostBinding('class')
  hostClasses = 'flex-grow-column';
  subscriptions = new Subscription();
  eventIsOver = false;
  isLoading = true;  
  public modalWindowOpen: boolean = false;
  private exhibitionId: number;
  public isSelfRegistrationEnabled: boolean = false;

  constructor(
    public route: ActivatedRoute,
    private _router: Router,
    private _store: Store<fromRoot.State>,
    private _customizationService: CustomizationService,
    private _appService: AppService,
    private _formsService: FormsService,
    private _location: Location,
    private _helperService: HelperService
  ) {}

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this._helperService.showModalWindow(false);
  }
  ngOnInit() {
    this.subscriptions.add(
      this._helperService.showModalWindow$.subscribe(item => {
        if (item) {
          this.modalWindowOpen = true;
        }
      })
    );
    this.subscriptions.add(
      observableCombineLatest(
        this._store.select(fromRoot.getProfile),
        this._store.select(fromRoot.getSelectedExhibitionId)
      )
        .pipe(filter(data => !!data[0] && data[1] !== null))
        .subscribe(data => {
          const [user, eventId] = data;
          this.exhibitionId = eventId;

          this._store.dispatch(
            new legitimationActions.GetLegitimationStatus({
              userId: user.id,
              eventId
            })
          );
        })
    );
    
    // #3863 - IE not supported
    this._store.pipe(select(fromRoot.getSelfRegistration))
      .pipe(first())
      .subscribe((selfRegistration: boolean) => {
        this.isSelfRegistrationEnabled = selfRegistration;
      });

    this.subscriptions.add(
      this.route.queryParamMap.subscribe(data => {
        let buyerInfoFromUrl = this.createBuyerInfoFromUrl(data);
        this._store.dispatch(
          new stepsFormsActions.SetBuyerInfoFromURL(buyerInfoFromUrl)
        );
      })
    );

    let oldExhibitionId = null;
    this._store.select(fromRoot.getSelectedExhibitionId).subscribe(id => {
      oldExhibitionId = id;
    });

    this.isLoading = true;
    this.subscriptions.add(
      this.route.params.subscribe(data => {
        // not using strict compare because id is stored as number but its a string in queryParamz
        if (data.id != oldExhibitionId) {
          this._appService.resetReducers(true, false).subscribe(() => {
            this._store.dispatch(new exhibitionActions.SelectAction(data.id));
            this._store.dispatch(
              new exhibitionActions.GetDoubleClickScripts(data.id)
            );
            this._store.dispatch(
              new exhibitionActions.GetGenericScripts(data.id)
            );
            this.goToTickets(); // when we change event via url we go to ticket page always;

            this.setStyles();
            this.isLoading = false;
          });
        } else {
          this.isLoading = false;
        }
      })
    );

    this.subscriptions.add(
      observableCombineLatest(
        this._store.select(fromRoot.getSelectedExhibition),
        this._store.select(fromRoot.isFirstLoad),
        this._store.select(fromRoot.isLoggedInAsAdmin)
      )
        .pipe(
          filter(data => {
            const hasSelectedExhibition = data[0];
            const anyFirstLoadValue = data[1] || !data[1]; // because isFirstLoad is a boolean and typescript throws error for unknown type

            return hasSelectedExhibition && anyFirstLoadValue;
          })
        )
        .subscribe(data => {
          this.eventIsOver = false;
          const [exhibition, isFirstLoad, isAdmin] = data;
          const locationAtExhibitionID = '/webshop/' + exhibition.id;

          // when browser back button is pressed on ticket page we redirect user to homepage
          window.addEventListener('popstate', () => {
            if (this._location.path() === locationAtExhibitionID) {
              this.navigateToRoot();
            }
          });

          // when programatically navigated back on ticket page we redirect user to homepage
          this._location.subscribe(location => {
            if (location.pop) {
              if (location.url === locationAtExhibitionID) {
                this.navigateToRoot();
              }
            }
          });

          if ((!exhibition || exhibition.isOver) && !isAdmin) {
            this.eventIsOver = true;
            setTimeout(() => {
              this._router.navigate(['./is-over'], {
                relativeTo: this.route
              });
            }, 200);
          } else {
            // if you come to webshop page without any subpage decision to which step to go is made based on backoffice settings
            if (!this.route.children.length || isFirstLoad) {
              this.getSettingsAndRedirect();
            }

            this.route.queryParams.subscribe(params => {
              if (params.theme) {
                this._customizationService.setColors(params.theme);
              }

              if (params.reset) {
                this.getSettingsAndRedirect();
              }
            });
          }
        })
    );
  }

  modalWindowVote(copyDataYesNo: boolean) {
    this.modalWindowOpen = false;
    if (copyDataYesNo) {      
      this._router.navigate([
        `webshop/${this.exhibitionId}/personal`
      ]);
    }

    this._helperService.voteYesOrNo(copyDataYesNo);
  }

  // Possible parameters:
  // email, gender, title, firstName, lastName, company, function, country, street, city, telephone, zipCode
  createBuyerInfoFromUrl(params: ParamMap) {
    let translations = new Map<string, string>();
    let buyerInfoUrlModel = [];
    // DE
    translations.set('besucher_firma', 'company');
    translations.set('besucher_br_anrede_id', 'gender');
    translations.set('besucher_titel', 'title');
    translations.set('besucher_vorname', 'firstName');
    translations.set('besucher_name', 'lastName');
    translations.set('besucher_funktion', 'function');
    translations.set('besucher_strasse', 'street');
    translations.set('besucher_plz', 'zipCode');
    translations.set('besucher_ort', 'city');
    translations.set('besucher_country', 'country');
    translations.set('besucher_email', 'email');
    translations.set('besucher_telefon', 'telephone');

    params.keys.forEach(parameterKey => {
      if (!translations.has(parameterKey)) return;
      if (translations.get(parameterKey) === 'gender') {
        buyerInfoUrlModel.push({
          key: translations.get(parameterKey),
          value: params.get(parameterKey) === '0' ? 'male' 
                  : '1' ? 'female'
                    : '2' ? 'diverse'
                      : '3' ? 'notSpecified'
                        : null
        });
      } else {
        if (translations.get(parameterKey) === 'email') {
          buyerInfoUrlModel.push({
            key: 'verify-email',
            value: params.get(parameterKey)
          });
        }

        buyerInfoUrlModel.push({
          key: translations.get(parameterKey),
          value: params.get(parameterKey)
        });
      }
    });
    return buyerInfoUrlModel;
  }

  setStyles() {
    this.subscriptions.add(
      this._store
        .select(fromRoot.getExhibitionSettings)
        .pipe(filter(settings => !!settings))
        .subscribe(settings => {
          this._customizationService.setStyles();
        })
    );
  }

  goToLegitimation() {
    this._store.dispatch(new stepsFormsActions.SetSelectedStep('legitimation'));
    this._router.navigate(['./legitimation'], {
      relativeTo: this.route,
      preserveQueryParams: true
    });
  }

  goToTickets() {
    this._store.dispatch(new stepsFormsActions.SetSelectedStep('tickets'));
    //if the tickets are not in url yet, navigate there

    if (
      !(
        this.route.snapshot.firstChild &&
        this.route.snapshot.firstChild.url &&
        this.route.snapshot.firstChild.url[0].path === 'tickets'
      )
    ) {
      return this._router.navigate(['./tickets'], {
        relativeTo: this.route,
        queryParams: this.route.snapshot.queryParams
      });
    }
  }

  getSettingsAndRedirect() {
    this.subscriptions.add(
      observableCombineLatest(
        this._store.select(fromRoot.getExhibitionSettings),
        this._store.select(fromRoot.isLegitimationRequired),
        this._store.select(fromRoot.isFirstLoad)
      )
        .pipe(
          filter(data => {
            const hasSettings = data[0];
            const hasLegitimation = data[1];
            const anyFirstLoadValue = data[2] || !data[2]; // because isFirstLoad is a boolean and typescript throws error for unknown type

            return hasSettings && hasLegitimation && !this.isSelfRegistrationEnabled && anyFirstLoadValue;
          }),
          first()
        )
        .subscribe(data => {
          const [settings, isLegitimationRequired, isFirstLoad] = data;
          if (
            settings.goToLegitimationForNewAccount &&
            isLegitimationRequired.required
          ) {
            this._formsService.setFormValidity(false, null, [
              'legitimation',
              'validation'
            ]);
            this.goToLegitimation();
          } else if (
            isFirstLoad &&
            this.route.snapshot.firstChild &&
            this.route.snapshot.firstChild.url[0].path !== 'invoice' // if local storage is disabled for IE and we come from payment page to invoice page we need this condition
          ) {
            this.goToTickets();
          } else if (!this.route.snapshot.firstChild) {
            this.goToTickets();
          }
          this._store.dispatch(new helperActions.SetEventSeriesPage(null)); // when we come to event reset event page info
          this._store.dispatch(new exhibitionActions.FirstLoadDone());
        })
    );
  }

  navigateToRoot() {
    window.location.replace('/');
  }
}
