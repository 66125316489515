import { ActivatedRoute, Router } from '@angular/router';

import { Component } from '@angular/core';
import { OnInit } from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'app-four-o-four',
  templateUrl: './four-o-four.component.html',
  styleUrls: ['./four-o-four.component.scss']
})
export class FourOFourComponent implements OnInit {
  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    const urlPath = this.route.snapshot.url;

    if (
      urlPath.length === 2 &&
      urlPath[0].path === 'Shop' &&
      urlPath[1].path === 'Go'
    ) {
      this.route.queryParams.subscribe(params => {
        let newUrl = '/webshop/';
        const optionalParams = [];

        if (params.Ev) {
          //https://expo-demo.teamaxess.com/webshop/200/tickets?voucher=AXGS95
          newUrl += `${params.Ev}/tickets`;

          if (params.Code) {
            optionalParams.push(`voucher=${params.Code}`);
          }

          if (params.Lng) {
            optionalParams.push(`lang=${params.Lng}`);
          }

          if (optionalParams.length) {
            newUrl += `?` + optionalParams.join('&');
          }

          window.location.href = window.location.origin + newUrl;
        }
      });
    }
  }
}
