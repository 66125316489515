import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'app-navigation-tabs',
  templateUrl: './navigation-tabs.component.html',
  styleUrls: ['./navigation-tabs.component.scss']
})
export class NavigationTabsComponent {
  @Input()
  tabs;
  @Input()
  title?;
  @Input()
  type: 'wide' | 'normal';
  @Input()
  activeTab: string;
  @Output()
  activeTabChanged = new EventEmitter();

  constructor() {}

  changeTabTo(tab) {
    this.activeTabChanged.emit(tab);
  }
}
