import { InputBase } from './input-base.class';

export class TextInput extends InputBase<string> {
  controlType = 'textbox';
  type: string;
  maxLengthValidation: number;
  minLengthValidation: number;
  emailValidation: boolean;
  numberValidation: boolean;
  zipcodeValidation: boolean;
  phoneValidation: boolean;
  sameAsValidation: string;
  autocomplete: boolean;
  emptySpacesValidation: boolean;
  noTrim: boolean;
  zipCodeCitiesTag: string;

  constructor(options: {} = {}) {
    super(options);
    this.type = options['type'] || '';
    this.maxLengthValidation = options['maxLengthValidation'] || 0;
    this.minLengthValidation = options['minLengthValidation'] || 0;
    this.emailValidation = options['emailValidation'] || false;
    this.zipcodeValidation = options['zipcodeValidation'] || false;
    this.numberValidation = options['numberValidation'] || false;
    this.phoneValidation = options['phoneValidation'] || false;
    this.sameAsValidation = options['sameAsValidation'] || '';
    this.autocomplete = options.hasOwnProperty('autocomplete')
      ? options['autocomplete']
      : true;
    this.emptySpacesValidation = options['emptySpacesValidation'] || false;
    this.noTrim = options['noTrim'] || false;
    this.zipCodeCitiesTag = options['zipCodeCitiesTag'] || '';
  }
}
