import { TicketsService } from './../../../shared/services-with-reducers/tickets/tickets.service';
import { PackagesService } from './../../../shared/services-with-reducers/tickets/packages.service';
import { PackageModel, PackageContentModel, PackageSettings, TicketModel } from './../../../shared/services-with-reducers/tickets/ticket.interface';
import { Component, EventEmitter, Input, OnInit, Output, OnChanges, OnDestroy } from '@angular/core';

import { Subscription, combineLatest as observableCombineLatest } from 'rxjs';
import { select, Store } from '@ngrx/store';

import { PostTicketBookingModel } from '../../../shared/services-with-reducers/tickets/ticket.interface';

import * as fromRoot from '../../../app.reducer';
import * as ticketActions from '../../../shared/services-with-reducers/tickets/ticket.actions';
import { CustomizationService } from '../../../shared/services-with-reducers/customization/customization.service';

const ERROR_TICKET_WARNING = 'ticket-selection.warning';
const ERROR_VOUCHER_WARNING = 'ticket-selection.voucher-warning';
const VOUCHER_LIMITED_WARNING = 'ticket-selection.limited-voucher-warning';
const VOUCHER_ONETIME_WARNING = 'ticket-selection.one-time-voucher-warning';

const TICKET_NORMAL = 'normal';
const TICKET_PARKING = 'parking';
const TICKET_PROMOCODE = 'promoCode';
const TICKET_PROMOCODE_LIMITED = 'limitedPromoCode';
const TICKET_VOUCHER_ONETIME = 'oneTimeVoucher';

@Component({
  moduleId: module.id,
  selector: 'app-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.scss']
})
export class CounterComponent implements OnInit, OnChanges, OnDestroy {
  @Output()
  counterChange = new EventEmitter<{
    value: number;
    decrease: boolean;
    isTicketAddedByPackageCounter?: boolean;
  }>();

  @Output()
  ticketLoading = new EventEmitter<Boolean>();
  @Input()
  counterValue: number;

  @Input()
  ticketUniqueId: string;
  @Input()
  maxLimit: number;
  @Input()
  maxLimitedVoucherLimit: number;
  @Input()
  maxVoucherLimit: number;

  @Input()
  currentMaxLimit: number;
  @Input()
  voucherLimit: number;
  @Input()
  ticketType: string;
  @Input()
  hasWorkshops: boolean;

  @Input()
  numberOfSelectedLimitedVouchers: number;
  @Input()
  numberOfSelectedVouchers: number;
  @Input()
  numberOfSelectedOneTimeVouchers: number;
  @Input()
  workshopsSoldOut: boolean;
  @Input()
  workshopSeatsAvailable: number;
  @Input()
  ticketSoldOut: boolean;
  @Input()
  numOfAvailableTickets: number;
  @Input()
  totalAvailableTickets: number;
  @Input()
  percentageOfAvailableTickets: number;
  @Input()
  isTicketBookingLoading: boolean;
  @Input()
  workshopsMandatory: boolean;
  @Input()
  amount: number;
  @Input()
  package: PackageModel;

  @Input()
  set numberOfAllTickets(value: number) {
    this.numberOfSelectedTickets = value;

    if (this.numberOfSelectedTickets < this.previousNumberOfSelectedTickets) {
      this.ticketLimitWarning = '';
    }

    this.previousNumberOfSelectedTickets = value;
  }

  public ticketLimitWarning = '';
  public wasCounterTouched: boolean;
  public errors = {
    ERROR_TICKET_WARNING,
    ERROR_VOUCHER_WARNING,
    VOUCHER_LIMITED_WARNING,
    VOUCHER_ONETIME_WARNING
  };
  public tickets = {
    TICKET_NORMAL,
    TICKET_PARKING,
    TICKET_PROMOCODE,
    TICKET_PROMOCODE_LIMITED,
    TICKET_VOUCHER_ONETIME
  };

  /**
   * numberOfAllTickets === numberOfSelectedTickets is the total number of all selected tickets
   */
  private numberOfSelectedTickets: number;
  private previousNumberOfSelectedTickets: number = 0;
  
  private previousValue: number;
  private packageSettings: PackageSettings = null;
  private disableOnPackageMaxAmount: boolean;
  private disableOnPackageMinAmount: boolean;
  private isTicketAddedByPackageCounter: boolean = false;

  constructor(
    private _store: Store<fromRoot.State>,
    private _customizationService: CustomizationService,
    private _packagesService: PackagesService,
    private _ticketsService: TicketsService
  ) {}

  ngOnInit() { 
    this.previousValue = this.counterValue || 0;
    // US 3512 - changed functionality, this.wasCounterTouched needed to be true if 'promoCode' is used

    if (this.amount > 0) {
      this.onValueChange({
        target: {
          value: this.amount
        }
      });
    }

    this.wasCounterTouched = this.ticketType === TICKET_PROMOCODE;

    if (this.package != null) {
      const changedPackage = this.package.contents.find(content => content.packageGroups.some(packageGroup => packageGroup.products.some(product => product.ticket.uniqueId == this.ticketUniqueId)));
      this.setPackageSettings(changedPackage);
      this.setPackageTriggers(changedPackage);
    }
  }

  ngOnChanges() {
    const disableEnabledSoldOutCounter = this.totalAvailableTickets == 0 && !this.ticketSoldOut && this.counterValue == 0;
    const enableDisabledSoldOutCounter = this.totalAvailableTickets > 0 && this.ticketSoldOut && this.counterValue == this.previousValue;

    if (disableEnabledSoldOutCounter) {
      this.ticketSoldOut = true;
    } else if (enableDisabledSoldOutCounter) {
      this.ticketSoldOut = false;
    }

    this.disableOnPackageMaxAmount = this.isDisabledOnPackageMaxAmount(this.counterValue);
  }

  ngOnDestroy() {
    this._packagesService.packageContentCountChangedEvent.removeAllListeners(`${this.ticketUniqueId}`);
  }

  get counter() {
    return this.counterValue;
  }

  set counter(value) {
    const changeCounterSuccess = () => {
      this.counterValue = value;
      const decrease =
        this.counterValue - this.previousValue < 0 ? true : false;

      if (decrease) {
        this._packagesService.enableDisabledPackage();
      }
      
      this.counterChange.emit({
        value: this.counterValue,
        decrease,
        isTicketAddedByPackageCounter: this.isTicketAddedByPackageCounter
      });
      this.previousValue = value;
    };

    this.ticketLoading.emit(true);

    // after we update the count we post to backend information about booked tickets
    observableCombineLatest([
      this._store.pipe(select(fromRoot.getSelectedExhibitionId)),
      this._store.pipe(select(fromRoot.getTickets)),
      this._store.pipe(select(fromRoot.getOrderUuid)),
      this._store.pipe(select(fromRoot.getTicketHolderIndexes))
    ])
      .first()
      .subscribe(data => {
        const [eventId, tickets, orderUuid, ticketHolderIndexes] = data;
        const ticketToBeBooked = tickets[this.ticketUniqueId];
        const sharedLimitTicketCount = Object.keys(tickets)
          .map(ticketUniqueId => tickets[ticketUniqueId])
          .filter(ticket => ticketToBeBooked.groupId === ticket.groupId && ticketToBeBooked.id === ticket.id && ticketToBeBooked.uniqueId != ticket.uniqueId)
          .reduce((totalCount, ticket) => totalCount + ticket.count, 0);
        let voucherCode = '';

        let postTicketBooking: PostTicketBookingModel = {
          eventId: Number(eventId),
          uuid: orderUuid,
          count: value,
          groupId: ticketToBeBooked.groupId,
          ticketTypeId: ticketToBeBooked.id,
          ticketPersonId: ticketToBeBooked.ticketPersonId,
          uniqueId: ticketToBeBooked.uniqueId,
          sharedLimitTicketCount: sharedLimitTicketCount,
          orderTicketBooking: true
        };

        if (ticketToBeBooked.hasOwnProperty('voucherCode')) {
          voucherCode = ticketToBeBooked.voucherCode;
        }

        if (this.package != null) {
          this.isTicketAddedByPackageCounter = !!Object.keys(ticketHolderIndexes)
            .map(index => ticketHolderIndexes[index])
            .filter(indexTicketUniqueId => indexTicketUniqueId == '').length;

          this.calculatePackagePrice(tickets, value);
        }

        this._store.dispatch(
          new ticketActions.PostTicketBooking({
            postTicketBooking: postTicketBooking,
            voucherCode: voucherCode,
            ticketLimit: ticketToBeBooked.ticketLimit,
            successCallback: changeCounterSuccess
          })
        );       
      });
    }

  onDecrease() {
    this.onValueChange({
      target: {
        value: this.previousValue > 0 ? this.previousValue - 1 : 0
      }
    });
  }

  onIncrease() {
    this.onValueChange({
      target: {
        value: this.previousValue + 1,
        isAddedByPackageCounter: false
      }
    });
  }

  onValueChange(event) {
    this.wasCounterTouched = true;
    let value = Number(event.target.value);

    if (value <= 0) {
      if (this.package && this.package.count > 0) {
        if (event.target.isPackageRemoved) {
          this.counter = 0;
        } else {
          this.setPackageValidation(value);
  
          if (this.packageSettings.fixedAmount) {
            this.counter = this.packageSettings.amount;
          }
  
          if (this.disableOnPackageMinAmount) {
            this.counter = this.packageSettings.minAmount;
          }
        }
      } else {
        this.counter = 0;
      }
    } else {
      let counter = value;
      let counterOverLimit = false;
      this.ticketLimitWarning = '';
      let ticketsDiff = value - this.previousValue;

      //TICKET_PROMOCODE
      if (!counterOverLimit && this.ticketType === TICKET_PROMOCODE &&
        this.numberOfSelectedVouchers + ticketsDiff > this.maxVoucherLimit) {
        counterOverLimit = true;

        counter = Math.min(
          this.maxVoucherLimit - (this.numberOfSelectedVouchers - this.previousValue),
          counter
        );

        counter = counter > 0 ? counter : 0;
        ticketsDiff = counter - this.previousValue;

        //consoleLog(`ŠTEF: onValueChange: ticketType=${this.ticketType}, numberOfSelectedVouchers=${this.numberOfSelectedVouchers}, ticketsDiff=${ticketsDiff}, maxVoucherLimit=${this.maxVoucherLimit}; counter=${counter}`);

        this.ticketLimitWarning = ERROR_VOUCHER_WARNING;
        this.currentMaxLimit = this.maxVoucherLimit;
      }

      //TICKET_PROMOCODE_LIMITED
      if (!counterOverLimit && this.ticketType === TICKET_PROMOCODE_LIMITED &&
        this.numberOfSelectedLimitedVouchers + ticketsDiff > this.maxLimitedVoucherLimit) {
        counterOverLimit = true;

        counter = Math.min(
          this.maxLimitedVoucherLimit - (this.numberOfSelectedLimitedVouchers - this.previousValue),
          counter
        );

        counter = counter > 0 ? counter : 0;
        ticketsDiff = counter - this.previousValue;

        //consoleLog(`ŠTEF: onValueChange: ticketType=${this.ticketType}, numberOfSelectedLimitedVouchers=${this.numberOfSelectedLimitedVouchers}, ticketsDiff=${ticketsDiff}, maxLimitedVoucherLimit=${this.maxLimitedVoucherLimit}; counter=${counter}`);

        this.ticketLimitWarning = VOUCHER_LIMITED_WARNING;
        this.currentMaxLimit = this.maxLimitedVoucherLimit;
      }

      //TICKET_VOUCHER_ONETIME
      if (!counterOverLimit && this.ticketType === TICKET_VOUCHER_ONETIME &&
        this.numberOfSelectedOneTimeVouchers + ticketsDiff > 1) {
        counterOverLimit = true;

        counter = 1;

        ticketsDiff = counter - this.previousValue;

        //consoleLog(`ŠTEF: onValueChange: ticketType=${this.ticketType}, numberOfSelectedOneTimeVouchers=${this.numberOfSelectedOneTimeVouchers}, ticketsDiff=${ticketsDiff}; counter=${counter}`);

        this.currentMaxLimit = 1;
        this.ticketLimitWarning = VOUCHER_ONETIME_WARNING;
      }

      //workshops
      //(we must test this even if we already set counterOverLimit to true as this limitation may be even stricter)
      if (this.workshopsMandatory && this.hasWorkshops &&
        (this.previousValue + ticketsDiff > this.workshopSeatsAvailable || this.workshopsSoldOut)) {
        counterOverLimit = true;

        if (this.workshopsSoldOut) {
          counter = 0;
        } else {
          counter = Math.min(
            this.workshopSeatsAvailable,
            counter
          );
        }

        counter = counter > 0 ? counter : 0;
        ticketsDiff = counter - this.previousValue;

        //consoleLog(`ŠTEF: onValueChange: ticketType=${this.ticketType}-WorkShops, numberOfSelectedTickets=${this.numberOfSelectedTickets}, ticketsDiff=${ticketsDiff}, workshopSeatsAvailable=${this.workshopSeatsAvailable}; counter=${counter}`);

        this.ticketLimitWarning = ERROR_TICKET_WARNING;
        this.currentMaxLimit = counter;
      }

      //maxLimit (total of all tickets) && numOfAvailableTickets (of the current ticket)
      //(we must test this even if we already set counterOverLimit to true as this limitation may be even stricter)
      if (
        //testing the total number of selected tickets upon the max ticket limit for all tickets:
        this.numberOfSelectedTickets + ticketsDiff > this.maxLimit ||
          //testing the ticket count difference from the previous selection upon the number of available (currently chosen) tickets:
          this.previousValue + ticketsDiff > this.numOfAvailableTickets) {
        counterOverLimit = true;

        const availabilityLimit = Math.min(
          this.maxLimit,
          this.numOfAvailableTickets
        );

        counter = Math.min(
          availabilityLimit,
          this.maxLimit - (this.numberOfSelectedTickets - this.previousValue)
        );

        counter = counter > 0 ? counter : 0;
        ticketsDiff = counter - this.previousValue;

        //consoleLog(`ŠTEF: onValueChange: ticketType=${this.ticketType}, numberOfSelectedTickets=${this.numberOfSelectedTickets}, ticketsDiff=${ticketsDiff}, maxLimit=${this.maxLimit}, numOfAvailableTickets=${this.numOfAvailableTickets}; counter=${counter}`);

        this.ticketLimitWarning = ERROR_TICKET_WARNING;
        this.currentMaxLimit = availabilityLimit;
      }

      //valueHigherThanVoucherLimit
      //TODO: SteZ: try to figure out what this does and if it does it well:
      if (counter > this.voucherLimit && this.voucherLimit !== 0) {
        counterOverLimit = true;

        const isCurrentLimitLessThanVoucherLimit = this.currentMaxLimit < this.voucherLimit;
        const limit = isCurrentLimitLessThanVoucherLimit ? this.currentMaxLimit : this.voucherLimit;

        counter = limit;

        counter = counter > 0 ? counter : 0;
        ticketsDiff = counter - this.previousValue;

        //consoleLog(`ŠTEF: onValueChange: ticketType=${this.ticketType}-CheckVouchers, counterValue=${this.counterValue}, voucherLimit=${this.voucherLimit}, isCurrentLimitLessThanVoucherLimit=${isCurrentLimitLessThanVoucherLimit}, limit=${limit}; counter=${counter}`);

        this.ticketLimitWarning = VOUCHER_LIMITED_WARNING;
        this.currentMaxLimit = limit;
      }

      if (this.packageSettings != null) {
        this.setPackageValidation(counter);
        
        if (this.packageSettings.fixedAmount) {
          counter = this.packageSettings.amount;
          
          if (!this.workshopsSoldOut || this.numberOfSelectedTickets < this.maxLimit) {
            counterOverLimit = false;
          }
        }
        
        if (this.disableOnPackageMaxAmount) {
          counter = this.packageSettings.maxAmount;
          
          if (!this.workshopsSoldOut || this.numberOfSelectedTickets < this.maxLimit) {
            counterOverLimit = false;
          }
        }

        if (this.disableOnPackageMinAmount) {
          counter = this.packageSettings.minAmount;
        }

        if (!event.target.isAddedByPackageCounter) {
          value = counter;
        }
      }

      if (!counterOverLimit) {
        this.counter = value;
        this.ticketLimitWarning = '';
      } else {
        this.counter = counter > 0 ? counter : 0;
      }
    }

    this.startMeasuring();
  }

  startMeasuring(): void {
    this._customizationService.setShoppingStartTime();
  }

  packageTriggerCounter(packageContent: PackageContentModel, decrease: boolean) {
    const currentPackageTicketTypeGroup = packageContent.packageGroups.find(packageGroup => packageGroup.products.some(product => product.ticket.uniqueId == this.ticketUniqueId));
    if (!!currentPackageTicketTypeGroup) {
      const currentTicketCounterInAddedPackage = currentPackageTicketTypeGroup.products.find(product => product.ticket.uniqueId == this.ticketUniqueId);
      if (!!currentTicketCounterInAddedPackage) {
        if (decrease) {
          this._ticketsService.ticketCounterChanged(currentTicketCounterInAddedPackage.ticket.uniqueId, 0, true);
          this.onValueChange({
            target: {
              value: 0,
              isPackageRemoved: true
            }
          });
        } else {
          const settings = currentTicketCounterInAddedPackage.ticket.packageSettings;
          this.onValueChange({
            target: {
              value: this._packagesService.setPackageTicketAmount(settings, this.previousValue),
              isAddedByPackageCounter: true
            }
          });
        }
      }
    }
  }

  setPackageSettings(changedPackage: PackageContentModel) {
    const packageGroup = changedPackage.packageGroups.find(packageGroup => packageGroup.products.some(product => product.ticket.uniqueId == this.ticketUniqueId));
    const currentProduct = packageGroup.products.find(product => product.ticket.uniqueId == this.ticketUniqueId);

    if (!!currentProduct) {
      this.packageSettings = currentProduct.ticket.packageSettings;
    }
  }

  setPackageTriggers(changedPackage: PackageContentModel) {
    if (this.package.count > 0) {
      this.packageTriggerCounter(changedPackage, false);
    }
    
    this._packagesService.packageContentCountChangedEvent.removeAllListeners(`${this.ticketUniqueId}`);
    this._packagesService.packageContentCountChangedEvent.on(`${this.ticketUniqueId}`, (changedPackage, decrease) => {
      this.packageTriggerCounter(changedPackage, decrease);
    });
  }

  isDisabledOnPackageMaxAmount(value: number) {
    if (this.ticketSoldOut) {
      return true;
    }
    
    if (!!this.packageSettings) {
      const { amount, maxAmount, fixedAmount } = this.packageSettings;
      
      if (fixedAmount && amount == value){
        return true;
      }

      if (maxAmount != null && maxAmount <= value) {
        return true;
      }
    }

    return false;
  }

  isDisabledOnPackageMinAmount(value: number) {
    if (this.ticketSoldOut && value == 0) {
      return true;
    }

    if (!!this.packageSettings) {
      const { amount, minAmount, fixedAmount } = this.packageSettings;

      if (fixedAmount && amount == value){
        return true;
      }

      if (minAmount != null && minAmount >= value) {
        return true;
      }
    }

    return false;
  }

  setPackageValidation(value) {
    this.disableOnPackageMaxAmount = this.isDisabledOnPackageMaxAmount(value);
    this.disableOnPackageMinAmount = this.isDisabledOnPackageMinAmount(value);
  }

  calculatePackagePrice(tickets: TicketModel, value: number) {
    const changedPackageContent = this.package.contents.find(content => content.packageGroups.some(packageGroup => packageGroup.products.some(product => product.ticket.uniqueId == this.ticketUniqueId)));
    if (changedPackageContent != null) {
      const changedPackageGroup = changedPackageContent.packageGroups.find(packageGroup => packageGroup.products.some(product => product.ticket.uniqueId == this.ticketUniqueId))
      if (changedPackageGroup != null) {
        const changedTicket = changedPackageGroup.products.find(product => product.ticket.uniqueId == this.ticketUniqueId).ticket;
        if (changedTicket != null) {
          const ticket = tickets[changedTicket.uniqueId];
          const packageCount = this.package.count;
          const previousTicketCount = this.previousValue;
          const isTicketRemoved = previousTicketCount != 0 && value == 0 && previousTicketCount >= value;
          const areAllPackagesRemoved = isTicketRemoved && packageCount == 0;
          
          let changedContentTotal = 0;
          let contentPriceDifference = 0
          
          if (isTicketRemoved) {
            changedContentTotal = changedPackageContent.total - previousTicketCount * ticket.price;
            contentPriceDifference = changedContentTotal - changedPackageContent.total;
          } else if (!areAllPackagesRemoved) {
            const currentTicketCount = ticket && ticket.count > 0 ? ticket.count : 0;
            const changedTicketCount = currentTicketCount != value ? value : currentTicketCount;
            const changedTicketPrice = changedTicketCount * ticket.price;
            const isCountChangedByTicketCounter = currentTicketCount != changedTicketCount;
            const isCountChangedByPackageCounter = !isCountChangedByTicketCounter && this.isTicketAddedByPackageCounter;

            if (isCountChangedByTicketCounter) {
              const currentTicketPrice = currentTicketCount * ticket.price;
              const ticketPriceDifference = changedTicketPrice - currentTicketPrice;
              changedContentTotal = changedPackageContent.total + ticketPriceDifference;
              contentPriceDifference = changedContentTotal - changedPackageContent.total;
            } else if (isCountChangedByPackageCounter) {
              changedContentTotal = changedPackageContent.total + changedTicketPrice;
              contentPriceDifference = changedContentTotal - changedPackageContent.total;
            } else {
              changedContentTotal = changedPackageContent.total;
              contentPriceDifference = 0;
            }
          }

          const changedPackageTotalPrice = this.package.total + contentPriceDifference;

          changedPackageContent.total = packageCount > 0 ? +changedContentTotal.toFixed(2) : 0;
          this.package.total = packageCount > 0 ? +changedPackageTotalPrice.toFixed(2) : 0;
        }
      }
    }
  }
}
