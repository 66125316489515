import { debounceTime, startWith, buffer } from 'rxjs/operators';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  Output,
  Renderer
} from '@angular/core';

import { Subject } from 'rxjs';

@Directive({
  selector: '[appAddressAutocomplete]'
})
export class AddressAutocompleteDirective implements AfterViewInit {
  @Output() appAddressAutocomplete = new EventEmitter<any>();
  @Output() isAddressAutocompleteLoading = new EventEmitter<boolean>();
  @Input() searchType: string;

  private changeSubject = new Subject();

  constructor(
    private el: ElementRef,
    private rd: Renderer,
    private zone: NgZone
  ) {
    this.changeSubject
      .pipe(buffer(this.changeSubject.pipe(startWith(''), debounceTime(500))))
      .subscribe((values: Array<string | Object>) => {
        if (values.length) {
          /*  If there is something in the buffer, try to keep the latest object (result of google autocomplete)
              Otherwise return whatever else (string)
          */
          const latestValue = values.reduce((aggr, value) => {
            if (typeof value === 'object') {
              return value;
            } else if (typeof aggr !== 'object') {
              return value;
            } else {
              return aggr;
            }
          }, '');
          this.appAddressAutocomplete.emit(latestValue);
          this.isAddressAutocompleteLoading.emit(false);
        } else {
          this.isAddressAutocompleteLoading.emit(false);
        }
      });
  }

  /*  If user click the autocompete, it could happen what the string value from 'change' listener could shadow the 'place_changed' listener
      We need to solve the behavior
  */

  ngAfterViewInit() {
    this.initAutocomplete();

    // we need to pass even normal value whn user simply dont use the autocomplete
    this.el.nativeElement.addEventListener('change', event => {
      this.changeSubject.next(event.target.value);
    });
  }

  initAutocomplete() {
    // Create the autocomplete object, restricting the search to geographical
    // location types.
    let retriesLeft: number = 20;

    let retryInterval = setInterval(() => {
      --retriesLeft;
      const isGoogleLoaded: boolean = !!window["googleMapsLoaded"];

      if (isGoogleLoaded || retriesLeft === 0) {
        clearInterval(retryInterval);

        if (window.hasOwnProperty('google')) {
          const autocomplete = new (window as any).google.maps.places
            .Autocomplete(this.el.nativeElement, { types: [this.searchType] });
    
          // When the user selects an address from the dropdown, populate the address
          // fields in the form.
          autocomplete.addListener('place_changed', () => {
            this.isAddressAutocompleteLoading.emit(true);
            this.zone.run(() => {
              const place = autocomplete.getPlace();
              this.changeSubject.next(place);
            });
          });
        } else {
          console.log('Google address autocomplete not loaded!');
        }
      }
    }, 200);
  }
}
