import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';

import {
  TranslateModule,
  TranslateParser,
  MissingTranslationHandler
} from '@ngx-translate/core';
import { CustomParser } from '../app/shared/translate-parser/translate-parser';
import { MissingTranslation } from '../app/shared/missing-translation/missing-translation';
import { MissingTranslationService } from '../app/shared/missing-translation/missing-translation.service';

import { NavigationTabsComponent } from './navigation-tabs/navigation-tabs.component';
import { BannerComponent } from './../app/banner/banner.component';
import { InfoBlockComponent } from './../app/info-block/info-block.component';
import { CounterComponent } from './../app/_pages/web-shop-tickets/counter/counter.component';
import { SendingOptionsComponent } from './../app/_pages/web-shop-tickets/sending-options/sending-options.component';
import { LoaderComponent } from './../app/loader/loader.component';
import { HtmlInjectorPipe } from '../app/shared/pipes/html-injector/html-injector.pipe';
import { HideTranslationPipe } from './../app/shared/pipes/hide-translation/hide-translation.pipe';
import { IsHiddenTranslationPipe } from './../app/shared/pipes/is-hidden-translation/is-hidden-translation.pipe';
import { CurrencySymbolGapPipe } from './../app/shared/pipes/currency-symbol-gap/currency-symbol-gap.pipe';
import { LocalizedDatePipe } from './../app/shared/pipes/localized-date/localized-date.pipe';
import { SafeResourcePipe } from '../app/shared/pipes/safe-resource/safe-resource.pipe';
import { ColorPickerModule } from 'ngx-color-picker';
import { FilterPlaceholderPipe } from '../app/shared/pipes/filter-placeholder/filter-placeholder.pipe';
import { MatTooltipModule } from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ColorPickerModule,
    MatExpansionModule,
    TranslateModule.forChild({
      parser: { provide: TranslateParser, useClass: CustomParser },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MissingTranslation,
        deps: [MissingTranslationService]
      }
    }),
    MatTooltipModule
  ],
  declarations: [
    BannerComponent,
    NavigationTabsComponent,
    InfoBlockComponent,
    CounterComponent,
    SendingOptionsComponent,
    LoaderComponent,
    HtmlInjectorPipe,
    HideTranslationPipe,
    IsHiddenTranslationPipe,
    CurrencySymbolGapPipe,
    LocalizedDatePipe,
    SafeResourcePipe,
    FilterPlaceholderPipe
  ],
  providers: [],
  exports: [
    TranslateModule,
    ColorPickerModule,
    MatExpansionModule,
    BannerComponent,
    NavigationTabsComponent,
    InfoBlockComponent,
    CounterComponent,
    SendingOptionsComponent,
    LoaderComponent,
    HtmlInjectorPipe,
    HideTranslationPipe,
    IsHiddenTranslationPipe,
    CurrencySymbolGapPipe,
    LocalizedDatePipe,
    SafeResourcePipe,
    FilterPlaceholderPipe,
    MatTooltipModule
  ]
})
export class SharedModule {}
