var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { combineLatest as observableCombineLatest, Subscription } from 'rxjs';
import { first, filter } from 'rxjs/operators';
import * as fromRoot from '../../app.reducer';
import * as userActions from '../../shared/services-with-reducers/user/user.actions';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { getEmailChangeForm, getPasswordChangeForm } from '../form-inputs.edit-account';
import { FormsService } from '../../shared/forms/forms.service';
import { StatusBarService } from '../../status-bar/status-bar.service';
import { Store } from '@ngrx/store';
import { WindowSizeService } from '../../shared/window-size/window-size.service';
import { Router } from '@angular/router';
import { UserService } from '../../shared/services-with-reducers/user/user.service';
var ChangeCredentialsComponent = /** @class */ (function () {
    function ChangeCredentialsComponent(_store, _formBuilder, _formsService, _statusBarService, _windowSizeService, _userService, _router) {
        var _this = this;
        this._store = _store;
        this._formBuilder = _formBuilder;
        this._formsService = _formsService;
        this._statusBarService = _statusBarService;
        this._windowSizeService = _windowSizeService;
        this._userService = _userService;
        this._router = _router;
        this.changeEmailAction = ['changeEmailForm'];
        this.changePasswordAction = ['changePasswordForm'];
        this.emailChangeActive = false;
        this.passwordChangeActive = false;
        this.subscriptions = new Subscription();
        this.emailFormSaveCallback = function (inputs) {
            _this.emailInputs = _this._formsService.updateInputs(_this.emailInputs, inputs);
            //this.emailForm = this._formsService.toFormGroup(this.emailInputs);
        };
        this.passwordFormSaveCallback = function (inputs) {
            _this.passwordInputs = _this._formsService.updateInputs(_this.passwordInputs, inputs);
            //this.passwordForm = this._formsService.toFormGroup(this.passwordInputs);
        };
        // set password status change to null so we can react on it later when we got real response
        this._store.dispatch(new userActions.PasswordChanged(null));
    }
    ChangeCredentialsComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (!this.profile.email) {
            var emailBlock_1 = document.querySelector('.form-block');
            this._windowSizeService.scrollToElement(emailBlock_1, 50, 50, 0.02);
            setTimeout(function () {
                emailBlock_1.focus();
                _this.emailChangeActive = true;
            }, 100);
        }
    };
    ChangeCredentialsComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    ChangeCredentialsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.add(this._store.select(fromRoot.passChangeStatus).subscribe(function (statusCode) {
            if (statusCode === 204) {
                _this._statusBarService.setStatus('Password changed successfully', 'success');
            }
            if (statusCode === 200) {
                _this.profile['isPasswordChangePending'] = true;
            }
        }));
        this.subscriptions.add(this._store.select(fromRoot.getProfile).subscribe(function (data) {
            _this.profile = __assign({}, data);
            //setTimeout(() => {
            _this.emailInputs = getEmailChangeForm();
            _this.emailForm = _this._formsService.toFormGroup(_this.emailInputs, _this.changeEmailAction);
            //}, 200);
        }));
        this.passwordInputs = getPasswordChangeForm();
        this.passwordForm = this._formsService.toFormGroup(this.passwordInputs, this.changePasswordAction);
    };
    ChangeCredentialsComponent.prototype.submitEmailChange = function () {
        var _this = this;
        setTimeout(function () {
            observableCombineLatest(_this._store.select(fromRoot.getProfile), _this._store.select(fromRoot.getLanguage))
                .pipe(filter(function (data) {
                return !!data[0] && !!data[1];
            }), first())
                .subscribe(function (data) {
                var user = data[0], language = data[1];
                _this._store.dispatch(new userActions.PatchEmail({
                    email: _this.emailInputs[0].value,
                    password: _this.emailInputs[2].value,
                    userId: user.id,
                    language: language
                }));
                _this._store.select(fromRoot.emailChangeStatus).subscribe(function (code) {
                    if (code && code >= 200 && code < 300) {
                        _this.profile['isEmailChangePending'] = true;
                        // dont show new email, because it is not approved yet
                        /* this._store.dispatch(
                          new userActions.SetProfilesEmail(this.emailInputs[0].value)
                        ); */
                    }
                });
                _this.emailChangeActive = false;
            });
        }, 100);
    };
    ChangeCredentialsComponent.prototype.submitPasswordChange = function () {
        var _this = this;
        observableCombineLatest(this._store.select(fromRoot.getProfile), this._store.select(fromRoot.getLanguage))
            .pipe(filter(function (data) {
            return !!data[0] && !!data[1];
        }), first())
            .subscribe(function (data) {
            var user = data[0], language = data[1];
            _this.profile['isPasswordChangePending'] = true;
            _this._store.dispatch(new userActions.PatchPassword({
                password: _this.passwordInputs[0].value,
                userId: user.id,
                language: language
            }));
            _this.passwordChangeActive = false;
        });
    };
    return ChangeCredentialsComponent;
}());
export { ChangeCredentialsComponent };
