import { TicketHolderAdditionalDataModel } from '../step-forms/step.interface';

import { QuestionnaireSaveModel } from '../user/user.interface';

export enum ItemType {
  Package = 'package',
  Article = 'article',
  Tariff = 'tariff'
}

export interface SectionGroup {
  sectionId?: number;
  groupName?: string;
  groupId?: number;
  items: Array<ProductGroup>;
  expanded: boolean;
  groupDescription: string;
}

export interface ProductGroup {
  article: ArticleModel,
  itemType: ItemType,
  package: PackageGroup,
  ticketType: TicketTypeGroup
}

export interface ProductModel {
  ticket: TicketGroupTicketModel;
  package: PackageModel;
  article?: ArticleModel;
}
export interface PackageTicketTypeGroupModel {
  products: ProductModel[];
  ticketTypeId?: number;
  ticketTypeName?: string;
}

export interface PackageContentModel {
  packageIndex: number;
  total: number;
  packageGroups:  Array<PackageTicketTypeGroupModel>;
}

export interface PackageModel {
  packageNumber: number;
  name: string;
  tariff: number;
  variablePrice: boolean;
  packageTariffSheetNumber: number;
  packageTariffSheetValidNumber: number;
  info: string;
  contents: Array<PackageContentModel>;
  count: number;
  total: number;
  isDisabled: boolean;
  warning?: boolean;
}

export interface SectionGroupModel {
  sectionId?: number;
  sectionIndex: number;
  sectionName?: string;
  groupDescription: string;
  expanded: boolean;
  productGroups?: Array<ProductGroupModel>
}

export interface ArticleModel {
  articleNumber: number,
  name: string,
  tariff: number,
  provision: number,
  provisionPercent: number,
  vatPercent: number,
  variablePrice: boolean,
  tarriffSheetNumber: number,
  tariffSheetValidNumber: number,
  info: string,
  packageSettings: PackageSettings
}

export interface TicketTypeGroup {
  tickets: Array<TicketGroupTicketModel>;
  ticketTypeId?: number;
  preferredTicketType: boolean;
  ticketTypeName?: string;
}

export interface ProductGroupModel {
  groupId: number;
  groupName?: string;
  packageName?: string;
  section?: SectionGroupModel;
  products: Array<ProductModel>;
  preferredTicketType: boolean;
}

export interface PackageGroup {
  info: string;
  items: Array<ProductGroup>;
  name: string;
  packageNumber: number;
  packageTariffSheetNumber: number;
  packageTariffSheetValidNumber: number;
  tariff: any;
  variablePrice: boolean;
}

export interface PackageSettings {
  itemFromPackage: boolean,
  useTariffSheetPrice: boolean,
  fixedAmount: boolean,
  amount: number,
  minAmount: number,
  maxAmount: number,
  packageItemPrice: number
}

export interface TicketGroupTicketModel {
  personTypeId: number;
  ticketTypeId: number;
  uniqueId: string; // this is composed manually after fetch from API form ticketTypeId and groupId
  ticketName: string;
  price: number;
  requiresLegitimation?: boolean;
  allowedWorkshops?: Array<number>;
  allowedWorkshopsFull?: boolean;
  availableTickets?: number;
  ticketLimit?: number;
  ticketTypeOrder: number;
  classification?: 'normal' | 'parking' | 'selfRegistrationOnly';
  ticketPersonId: number;
  personalizationType: string;
  info: string;
  infoExpanded: string;
  isVisible: boolean;
  isVoucher: boolean;
  // following are only used for tickets comming after applying for a voucher
  voucherCode?: string;
  workshopsByDay: Array<WorkshopsByDay>;
  hasDaySellLimit: boolean;
  validFrom?: Date;
  validTill?: Date;
  shouldCalendarBeDisplayed: boolean;
  tageOffset: number;
  preferredTicket?: boolean;
  packageSettings?: PackageSettings;
  durationInDays: number;
  days?: Array<Date>;
  groupName: string;
}

export interface WorkshopsByDay {
  day: Date;
  workshops: WorkshopByDayModel[];
}

export interface WorkshopByDayModel {
  capacity: number;
  description: string;
  end: string;
  image: string;
  language: string;
  name: string;
  pdf: string;
  price: number;
  start: string;
  id: number; 
  expanded: boolean;
}

export interface TicketModel {
  [key: string]: TicketByIdModel;
}

export interface TicketByIdModel {
  id: number;
  uniqueId: string;
  holdersIndexes: Array<number>;
  name: string;
  groupName: string;
  groupId: number;
  count: number;
  price: number;
  requiresLegitimation: boolean;
  allowedWorkshops?: Array<number>;
  allowedWorkshopsFull?: boolean;
  availableTickets?: number;
  ticketLimit?: number;
  ticketTypeOrder: number;
  ticketPersonId: number;
  quantity?: number;
  releasedInMinutes?: number;
  voucherCode?: string;
  voucherType?: string;
  activatedTimestamp?: number;
  sponsors?: Array<VoucherSponsor>;
  info: string;
  infoExpanded: string;
  classification: string;
  isVisible: boolean;
  isVoucher: boolean;
  parking?: Array<{
    since: string;
    until: string;
  }>;
  hasDaySellLimit?: boolean;
  days?: Array<string>;
  workshopsByDay: Array<WorkshopsByDay>;
  durationInDays: number;
  personalizationType: string;
  validFrom?: Date;
  validTill?: Date;
  shouldCalendarBeDisplayed: boolean;
  tageOffset: number;
  preferredTicket?: boolean;
  packageSettings?: PackageSettings;
  packageNumber? : number;
  packageIndex?: number;
}

export interface ContingentDataModel {
  day: number;
  start: Date;
  end: Date;
  duration: number;
}

export interface ContingentTicketModel {
  day: string;
  ticketIndex: number;
  ticketPersonId: number;
  uniqueId: string;
}

export interface ContingentBookingModel {
  uuid: string; 
  eventId: number;
  tickets: ContingentTicketModel[];
}

export interface ContingentTicketValidityResponse {
  availableTickets: number;
  day: string;
  isValid: boolean;
  sellLimit: number;
  ticketIndex: number;
  ticketPersonId: number;
}

export interface VisitorFormModel {
  hash: string;
  ticketHolder: TicketHolderModel;
  questionnaire: QuestionnaireSaveModel;
  language: string;
}

export interface TicketHolderModel {
  id?: number;
  gender: string;
  title: string | number;
  lastName: string;
  firstName: string;
  dateOfBirth: Date;
  company: string;
  function: string | number;
  department: string | number;
  occupationalGroup: string | number;
  country: string;
  street: string;
  address: string;
  telephone: string;
  zipCode: string;
  city: string;
  email: string;
  isQuestionnaireRequired: boolean;
  hasNewsLetter: boolean;
  tickets: Array<any>;
}

export interface TicketConfirmationModel {
  groupId: number;
  groupName: string;
  subGroup?: Array<TicketSubgroupModel>;
}

export interface ProductsConfirmationModel {
  groupId: number;
  groupName: string;
  packageName: string;
  products?: Array<ProductModel>;

  tickets?: Array<TicketConfirmationModel>;
  packages?: Array<PackageConfirmationGroupModel>;
  articles?: Array<ArticlesConfirmationModel>;
}

export interface ArticlesConfirmationModel {
 // TODO: add when doing articles
}

export interface TicketSubgroupModel {
  name: string;
  voucherCode?: string;
  ticketTypeId: number;
  uniqueId: string;
  ticketTypeOrder: number;
  tickets: Array<{
    ticketName: string;
    price: number;
    ticketHolderAdditionalData?: TicketHolderAdditionalDataModel;
    ticketHolderConfirmation?: {
      [key: string]: string;
    };
  }>;
}

export interface PackageConfirmationGroupModel {
  name?: string;
  index?: number;
  price?: number;
  number?: number;
  addBasketPackageBorder?: boolean;
  ticketTypeGroup: Array<TicketConfirmationModel>;
  articleSubGroup: Array<ArticlesConfirmationModel>;
}

export interface TicketRecordModel {
  eventId: number;
  eventName: string;
  eventStartDate: Date | string;
  eventEndDate: Date | string;
  eventCalendar?: string;
  isAccordionOpen?: boolean;
  orders: Array<TicketRecordOrdersModel>;
  durationInDays: number;
}

export interface TicketRecordOrdersModel {
  id: number;
  createdAt: string;
  entryTickets: Array<TicketRecordOrdersTicketModel>;
  parkingTickets: Array<TicketRecordOrdersTicketModel>;
  allowDownloadInvoice: boolean;
  allowDownloadRefundReceipt: boolean;
  allowRefund: boolean;
  status: string;
  packages: Array<PackageRecordModel>;
}

export interface PackageRecordModel {
  allowDownloadRefundReceipt: boolean;
  allowRefund: boolean;
  packageNumber: number;
  packageIndex: number;
  packageName: string;
  entryTickets: Array<TicketRecordOrdersTicketModel>;
}

export interface CancelOrderBody {
  reason: string;
  ids: Array<number>;
}

export interface TicketRecordOrdersTicketModel {
  email: string;
  firstName: string;
  id: number;
  lastName: string;
  ticketGroupName: string;
  ticketPersonName: string;
  allowDownload: boolean;
  allowEdit: boolean;
  expanded: boolean;
  day: string;
  changeDateExpanded: boolean;
  durationInDays?: number;
  allowDayChange?: boolean;
  allowRefundRecipeDownload: boolean;
  allowRefund: boolean;
}

export interface SendingOptionModel {
  value: string;
  isSelected?: boolean;
  isVisible?: boolean;
  isEnabled?: boolean;
  isBasic?: boolean;
  isBuyer?: boolean;
}

export interface TicketVoucherModel {
  id: number;
  ticketTypeId: number;
  ticketName: string;
  groupId: number;
  groupName?: string;
  uniqueId?: string;
  price: number;
  requiresLegitimation: boolean;
  allowedWorkshops?: Array<number>;
  allowedWorkshopsFull?: boolean;
  availableTickets?: number;
  quantity: number;
  releasedInMinutes: number;
  voucherCode: string;
  voucherType: string;
  activatedTimestamp: number;
  sponsors: Array<VoucherSponsor>;
  ticketTypeOrder: number;
  ticketLimit?: number;
  sectionId?: number;
  info: string;
  infoExpanded: string;
  classification: string;
  ticketPersonId: number;
  sectionDescription: string;
  sectionName: string;
}

export interface RedeemVoucherModel {
  eventId: number;
  voucherCode: string;
  countryCode: string;
  uuid: string;
  ticketPersonId: number;
  skipAnonymousChecks?: boolean;
}

export interface VoucherSponsor {
  name: string;
  logo: string;
  link: string;
  eventBanner?: string;
  sponsorBanner?: string;
  styles?: {
    BaseThemeColor: string;
    BaseTxColor: string;
    ButtonBgColor: string;
    ButtonTxColor: string;
    AreaBlockBgActive: string;
  };
}

export interface TicketBookingModel {
  bookings: AddTicketBookingModel[];
  timestamp: number;
}

export interface PostTicketBookingModel {
  eventId: number;
  groupId: number;
  ticketTypeId: number;
  count: number;
  uuid: string;
  ticketPersonId: number;
  uniqueId: string;
  sharedLimitTicketCount: number;
  orderTicketBooking: boolean;
}

export interface DayTicketBookingModel {
  uuid: string;
  eventId: number;
  tickets: Array<{
    ticketPersonId: number;
    day: string;
  }>;
}

export interface AddTicketBookingModel {
  groupId: number;
  ticketTypeId: number;
  ticketUniqueId: string;
  availableTickets: number;
  count: number;
  isTicketSold: boolean;
  ticketLimit: number;
  ticketPersonId: number;
}

export interface TicketHolderIndexes {
  [index: string]: string;
}

export interface ValidateDailyTicketPerEmailLimitBody {
  eventId: number;
  isSelfRegistration: boolean;
  /**
   * Items to be validated. Duplicates are supported.
   */
  items: EmailData[];
}

export interface EmailData {
  index: number;
  email: string;
  ticketPersonId: number;
  amount: number;
}

export interface ValidateDailyTicketPerEmailLimitResult {
  isValid: boolean;
  errors: Array<BackendError>;
}

export interface BackendError {
  code: string;
  message: string;
  values: Array<any>;
}
