/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./bottom-sticky.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../cookies-popup/cookies-popup.component.ngfactory";
import * as i3 from "../../cookies-popup/cookies-popup.component";
import * as i4 from "@angular/router";
import * as i5 from "@ngrx/store";
import * as i6 from "@angular/common";
import * as i7 from "./bottom-sticky.component";
import * as i8 from "@ngx-translate/core";
var styles_BottomStickyComponent = [i0.styles];
var RenderType_BottomStickyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BottomStickyComponent, data: {} });
export { RenderType_BottomStickyComponent as RenderType_BottomStickyComponent };
function View_BottomStickyComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cookies-popup", [], null, null, null, i2.View_CookiesPopupComponent_0, i2.RenderType_CookiesPopupComponent)), i1.ɵdid(1, 245760, null, 0, i3.CookiesPopupComponent, [i4.Router, i5.Store], { textWithLink: [0, "textWithLink"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.textWithLink; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_BottomStickyComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "bottom-sticky"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BottomStickyComponent_1)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isCookiePopupEnabled; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_BottomStickyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-bottom-sticky", [], null, null, null, View_BottomStickyComponent_0, RenderType_BottomStickyComponent)), i1.ɵdid(1, 245760, null, 0, i7.BottomStickyComponent, [i5.Store, i8.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BottomStickyComponentNgFactory = i1.ɵccf("app-bottom-sticky", i7.BottomStickyComponent, View_BottomStickyComponent_Host_0, {}, {}, []);
export { BottomStickyComponentNgFactory as BottomStickyComponentNgFactory };
