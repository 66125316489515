export const environment = {
  serverRendered: false,
  production: true,
  origin: '',
  apiUrl: 'https://private-8c271-axesswebshop.apiary-mock.com/WebShop/api',
  webApiUrl: 'webapi/api',
  protocol: '',
  currency: 'EUR',
  appInsightsToken: '{ApplicationInsightsInstrumentationKey}'
  //protocol: window.location.protocol
  //webApiUrl: 'https://private-8c271-axesswebshop.apiary-mock.com/WebShop/api'
};
