/**
 * Method names for handlers
 */
export class hubMethods {
  static maintenaceOperatorStarted = 'OperatorMaintenanceStarted';
  static maintenaceEventStarted = 'EventMaintenanceStarted';
  static reloadRequired = 'ReloadRequired';
}

/**
 * Method names for invocations.
 */
export class hubSignals {
  // place method names for connection.invoke() HERE
}
