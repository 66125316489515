<div class="dropdown select"
     [ngClass]="{'show' : opened }"
     appClickOutside
     (clickOutside)="opened = false;">
    <button class="dropdown-button btn dropdown-toggle"
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            (click)="opened = !opened">
        <svg class="dropdown-icon">
            <use [attr.xlink:href]="'assets/defs.svg#icon-' + selectedIcon"></use>
        </svg>
        <span class="dropdown-item-text"
              [attr.data-translation]="'confirmation.sending-options.' + selectedSendingOption | lowercase">{{ 'confirmation.sending-options.' + selectedSendingOption | lowercase | translate | hideTranslation }}</span>
    </button>
    <div class="dropdown-menu">
        <ul class="dropdown-menu-list"
            aria-labelledby="dropdownMenuButton">
            <li class="dropdown-item-wrap"
                *ngFor="let option of confirmationOptions">
                <div *ngIf="option.value !== selectedSendingOption"
                     class="dropdown-item"
                     (click)="dropdownItemClicked(option.value)">
                    <svg class="dropdown-icon">
                        <use [attr.xlink:href]="'assets/defs.svg#icon-' + option.value"></use>
                    </svg>
                    <span class="dropdown-item-text"
                          [attr.data-translation]="'confirmation.sending-options.' + option.value | lowercase">
                        {{ 'confirmation.sending-options.' + option.value | lowercase | translate | hideTranslation }}
                    </span>
                </div>
            </li>
        </ul>
    </div>
</div>