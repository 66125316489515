import { Component, OnInit, OnDestroy } from '@angular/core';

import * as fromRoot from '../../../app.reducer';
import * as userActions from '../../../shared/services-with-reducers/user/user.actions';

import { combineLatest as observableCombineLatest, Subscription } from 'rxjs';
import { ExhibitionModel } from '../../../shared/services-with-reducers/exhibition/exhibition.interface';
import {
  CancelOrderBody,
  TicketRecordModel,
  TicketRecordOrdersTicketModel
} from '../../../shared/services-with-reducers/tickets/ticket.interface';
import { filter, first, take } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { UserService } from '../../../shared/services-with-reducers/user/user.service';
import { environment } from '../../../../environments/environment';
import { tick } from '@angular/core/src/render3';
import { HelperService } from '../../../shared/services-with-reducers/helpers/helper.service';

@Component({
  selector: 'app-tickets-download',
  templateUrl: './tickets-download.component.html',
  styleUrls: ['./tickets-download.component.scss']
})
export class TicketsDownloadComponent implements OnInit, OnDestroy {
  public eventWithTickets: TicketRecordModel[] = [];
  public allowDownloadInvoice: boolean;
  public modalWindowOpen: boolean = false;
  public refundOrderId: number;
  public refundTicketId: number;
  public refundPackageOrderId: number;
  public refundPackageIndex: number;
  public refundPackageEntryTickets: any;
  public showLoader: boolean = false;
  public isSingleTicketRefund: boolean = false;
  public isParkingTicketRefund: boolean = false;
  public isPackageRefund: boolean = false;
  public isMobile: boolean = false;

  private _subscriptions: Subscription = new Subscription();

  constructor(
    private _store: Store<fromRoot.State>,
    public _userService: UserService,
    private _helperService: HelperService
  ) {
    this.isMobile = this._helperService.isMobile();
   }

  ngOnInit() {
    this._subscriptions.add(
      observableCombineLatest(
        this._store.select(fromRoot.getTicketsHistory),
        this._store.select(fromRoot.getSelectedExhibition)
      )
        .pipe(
          filter(data => {
            return !!data[0] && data.length > 0;
          })
        )
        .subscribe((data: [Array<TicketRecordModel>, ExhibitionModel]) => {
          const [tickets, selectedExhibition] = data;

          this.eventWithTickets = tickets.map(event => {
            event.eventCalendar = `${environment.protocol}${environment.webApiUrl}/event/${event.eventId}/calendar${event.eventId}.ics`;
            event.isAccordionOpen = !!selectedExhibition
              ? event.eventId === selectedExhibition.id
              : false;
            return event;
          });
        })
    );
  }

  refundPackagePopup(orderId, packageIndex, packageEntryTickets) {
    this.refundPackageOrderId = orderId;
    this.refundPackageIndex = packageIndex;
    this.refundPackageEntryTickets = packageEntryTickets;
    this.isPackageRefund = true;
    this.modalWindowOpen = true;
  }

  refundPackage(isPackageRefund: boolean) {
    this.modalWindowOpen = false;

    if (isPackageRefund) {
      this.showLoader = true;

      let refundPackageBody: CancelOrderBody = {
        reason: 'Refund',
        ids: []
      };
  
      Object.keys(this.refundPackageEntryTickets).forEach(item => {
        const singleTicket = this.refundPackageEntryTickets[item];
        refundPackageBody.ids.push(singleTicket.id);
      });

      this._userService.refundPackage(this.refundPackageOrderId, this.refundPackageIndex, refundPackageBody).subscribe(() => {
        // After package refund, call back ticket history
        this._store.dispatch(
          new userActions.GetTicketsHistoryByUser(666)
        );
        this.showLoader = false;
      },
        error => {
          // if there is an error in order package cancellation, loader should stop his pittyful existence
          this.showLoader = false;
        });
    }

    this.isPackageRefund = false;
    this.refundPackageOrderId = null;
  }

  refundOrderPopup(orderId) {
    this.refundOrderId = orderId;
    this.modalWindowOpen = true;
  }

  refundOrder(isCancelOrder: boolean) {
    this.modalWindowOpen = false;
    // if on modal window user press affirmative answer then the logic is as below
    if (isCancelOrder) {
      this.showLoader = true;
      this._userService.refundOrder(this.refundOrderId).subscribe(() => {
        // After order refund, call back ticket history
        this._store.dispatch(
          new userActions.GetTicketsHistoryByUser(666)
        );
        this.showLoader = false;
      },
        error => {
          // if there is an error in order cancellation, loader should stop his pittyful existence
          this.showLoader = false;
        });
    }

    this.refundOrderId = null;
  }

  refundTicketPopup(orderId, ticketId) {
    this.refundOrderId = orderId;
    this.refundTicketId = ticketId;
    this.isSingleTicketRefund = true;
    this.modalWindowOpen = true;
  }

  refundTicket(isRefundTicket: boolean) {
    this.modalWindowOpen = false;
    // if on modal window user press affirmative answer then the logic is as below
    if (isRefundTicket) {
      this.showLoader = true;
      this._userService.refundSingleTicket(this.refundOrderId, this.refundTicketId).subscribe(() => {
        // After every ticket refund, call back ticket history
        this._store.dispatch(
          new userActions.GetTicketsHistoryByUser(666)
        );
        this.showLoader = false;
      },
        error => {
          // if there is an error in ticket cancellation, loader should stop his pittyful existence
          this.showLoader = false;
        });
    }

    this.isSingleTicketRefund = false;
    this.refundOrderId = null;
    this.refundTicketId = null;
  }

  refundParkingPopup(orderId, ticketId) {
    this.refundOrderId = orderId;
    this.refundTicketId = ticketId;
    this.isSingleTicketRefund = true;
    this.isParkingTicketRefund = true;
    this.modalWindowOpen = true;
  }

  refundParkingTicket(isRefundTicket: boolean) {
    this.modalWindowOpen = false;
    // if on modal window user press affirmative answer then the logic is as below
    if (isRefundTicket) {
      this.showLoader = true;
      this._userService.refundParkingTicket(this.refundOrderId, this.refundTicketId).subscribe(() => {
        // After parking ticket refund, call back ticket history
        this._store.dispatch(
          new userActions.GetTicketsHistoryByUser(666)
        );
        this.showLoader = false;
      },
        error => {
          // if there is an error in ticket cancellation, loader should stop his pittyful existence
          this.showLoader = false;
        });
    }

    this.isSingleTicketRefund = false;
    this.isParkingTicketRefund = false;
    this.refundOrderId = null;
    this.refundTicketId = null;
  }

  downloadTicket(id: number, type: string, ticketId?: number, packageIndex?: number) {
    this._store.dispatch(new userActions.DownloadTicketById({ id, type, ticketId, packageIndex }));
  }

  closeAllTicketsExcept(ticket: TicketRecordOrdersTicketModel): void {
    this.eventWithTickets.forEach(event => {
      event.orders.forEach(order => {
        order.packages.forEach(orderPackage => {
          orderPackage.entryTickets.forEach(packageTicket => {
            packageTicket.expanded = false;
            packageTicket.changeDateExpanded = false;
          });
        });
        order.entryTickets.forEach(ticket => {
          ticket.expanded = false;
          ticket.changeDateExpanded = false;
        });
      });
    });

    ticket.expanded = ticket.expanded !== true ? true : false;
  }

  closeAllTicketsDayChangeExcept(ticket: TicketRecordOrdersTicketModel): void {
    this.eventWithTickets.forEach(event => {
      event.orders.forEach(order => {
        order.entryTickets.forEach(ticket => {
          ticket.expanded = false;
          ticket.changeDateExpanded = false;
        });
      });
    });

    ticket.changeDateExpanded = ticket.changeDateExpanded !== true ? true : false;
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
