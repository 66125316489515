/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./reset-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../shared/pipes/hide-translation/hide-translation.pipe";
import * as i4 from "@angular/common";
import * as i5 from "./reset-button.component";
import * as i6 from "@ngrx/store";
import * as i7 from "../app.service";
var styles_ResetButtonComponent = [i0.styles];
var RenderType_ResetButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ResetButtonComponent, data: {} });
export { RenderType_ResetButtonComponent as RenderType_ResetButtonComponent };
function View_ResetButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [["class", "navigation-content"], ["data-translation", "reset.button.text"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.resetShop() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", "\n"])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(3, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("reset.button.text")))); _ck(_v, 1, 0, currVal_0); }); }
export function View_ResetButtonComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.HideTranslationPipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResetButtonComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.userActive; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ResetButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-reset-button", [], null, null, null, View_ResetButtonComponent_0, RenderType_ResetButtonComponent)), i1.ɵdid(1, 114688, null, 0, i5.ResetButtonComponent, [i6.Store, i7.AppService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ResetButtonComponentNgFactory = i1.ɵccf("app-reset-button", i5.ResetButtonComponent, View_ResetButtonComponent_Host_0, {}, {}, []);
export { ResetButtonComponentNgFactory as ResetButtonComponentNgFactory };
