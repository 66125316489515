import { InputBase } from './input-base.class';

export class DropdownInput extends InputBase<string> {
  controlType = 'dropdown';
  options: { key: string; value: string; translate?: boolean }[] = [];

  constructor(options: {} = {}) {
    super(options);
    this.options = options['options'] || [];
  }
}
