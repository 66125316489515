<div class="container-fluid h-100-p">
    <div class="page-content h-100-p">
        <div class="page-padding-wrapper h-100-p">
            <h4 class="title">
                {{ 'widget.chooseWidgetTitle' | translate }}
            </h4>

            <app-navigation-tabs [tabs]="tabs"
                                 (activeTabChanged)="onTabChange($event)"
                                 type="normal"
                                 [activeTab]="activeTab"></app-navigation-tabs>

            <app-loader *ngIf="!eventId; else hasEvent"></app-loader>

            <ng-template #hasEvent>
                <ng-container *ngIf="activeTab === 'ticketsWidget'">
                    <app-tickets-widget-admin [eventId]="eventId"
                                              [availableTickets]="availableTickets">
                    </app-tickets-widget-admin>
                </ng-container>

                <ng-container *ngIf="activeTab === 'bannerWidget'">
                    <app-banner-widget-admin-component [widgetSettings]="widgetSettings?.bannerWidget"
                                                       [eventWidgets]="eventWidgets"
                                                       [availableTickets]="availableTickets"
                                                       [loading]="loading"
                                                       (createNewWidget)="createNewWidget($event)"
                                                       (updateWidget)="updateWidget($event)"
                                                       (loadWidget)="loadWidgetSettings($event)">
                    </app-banner-widget-admin-component>
                </ng-container>
            </ng-template>
        </div>
    </div>
</div>
