import {
  of as observableOf,
  fromEvent as observableFromEvent,
  Observable,
  Subscription
} from 'rxjs';

import { filter, throttleTime, delay } from 'rxjs/operators';

import * as customizationActions from '../../shared/services-with-reducers/customization/customization.actions';
import * as fromRoot from '../../app.reducer';

import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppConstants } from '../../shared/app-constants';
import { CustomizationService } from '../../shared/services-with-reducers/customization/customization.service';
import { HelperService } from '../../shared/services-with-reducers/helpers/helper.service';
import { ExhibitionSettingModel, OperatorsSettingsModel } from '../../shared/services-with-reducers/customization/customization.interfaces';
import { Router, NavigationEnd } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { UserProfileModel } from '../../shared/services-with-reducers/user/user.interface';
import { UserService } from '../../shared/services-with-reducers/user/user.service';
import { WindowSizeService } from '../../shared/window-size/window-size.service';
import { LegitimationStatusModel } from '../../shared/services-with-reducers/legitimation/legitimation.interface';

import { environment } from '../../../environments/environment';

@Component({
  moduleId: module.id,
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription = new Subscription();

  public env: any = environment;

  public breakpoints: {};
  public mediumBreakpoint: {};
  public isMenuOpen: boolean;
  public isLangMenuOpen: boolean;
  public viewport: number;
  public modalWindowOpen = false;
  public isEventSelected: boolean = null;
  public activeUser: any;
  public currentLanguage: string;
  public isLoggedIn$: Observable<boolean>;
  public isLoggedInAsAdmin$: Observable<boolean>;
  public isLoggedInAsAdmin: boolean;
  public profile$: Observable<UserProfileModel>;
  public legitimationStatus$: Observable<LegitimationStatusModel>;
  public isLegitimationRequired$: Observable<{ required: boolean }>;
  public language$: Observable<string>;
  public supportedLanguages$: Observable<string[]>;
  public profile: UserProfileModel;
  public getOperatorsSettings: OperatorsSettingsModel;
  public ticketCount$: Observable<number>;
  public ticketCount: number;
  public basketOpened = false;
  public hideTopBarLogin$: Observable<boolean>;
  public ageRating: number;
  public logoUrl: string;
  public isSelfRegistrationEnabled: boolean;
  public isEventSeriesPage: boolean = false;

  public displayBackToEvent = false;
  public displayOnMaintenancePage = false;
  public displayBactToSelfReg = false;
  public selectedStep: string;
  public isMobile: boolean = false;

  private getSelectedExhibitionId: number;
  private windowResize: {};
  private result;
  public exhibitionSettings: ExhibitionSettingModel;

  constructor(
    public _router: Router,
    public _userService: UserService,
    public _store: Store<fromRoot.State>,
    private _windowSizeService: WindowSizeService,
    private _customizationService: CustomizationService,
    private _helperService: HelperService
  ) {
    this.isMobile = this._helperService.isMobile();

    // in case we are in angular universal
    if (typeof window !== 'undefined') {
      const resize = observableFromEvent(window, 'resize');
      this.result = resize.pipe(throttleTime(100));
    } else {
      this.result = observableOf(null);
    }

    this._subscriptions.add(
      this._router.events.subscribe(routerEvent => {
        if (routerEvent instanceof NavigationEnd) {
          const currentUrl = this._router.url;

          this.displayBackToEvent = this.shouldDisplayBackToEvent(currentUrl);
          this.displayOnMaintenancePage = this.shouldDisplayOnMaintenancePage(
            currentUrl
          );
          this.displayBactToSelfReg = this.shouldDisplayBactToSelfReg(
            currentUrl
          );
        }
      })
    );

    this._subscriptions.add(
      this._store
        .select(fromRoot.getSelfRegistration)
        .subscribe(selfRegistration => {
          this.isSelfRegistrationEnabled = selfRegistration;
        })
    );

    this._subscriptions.add(
      this._store
        .select(fromRoot.isEventSeriesPage)
        .subscribe(eventSeriesPage => {
          if (!!eventSeriesPage) {
            this.isEventSeriesPage = eventSeriesPage.isEventSeries;
          } else {
            this.isEventSeriesPage = false;
          }
        })
    );

    this.breakpoints = AppConstants.BREAKPOINTS;
    this.mediumBreakpoint = this.breakpoints['md'];
    this.ticketCount$ = this._store.select(fromRoot.getTicketsCount);
    this.isLoggedIn$ = this._store.select(fromRoot.isUserLoggedIn);
    this.legitimationStatus$ = this._store.select(
      fromRoot.getLegitimationStatus
    );
    this.isLegitimationRequired$ = this._store.select(
      fromRoot.isLegitimationRequired
    );
    this.isLoggedInAsAdmin$ = this._store.select(fromRoot.isLoggedInAsAdmin);
    this.profile$ = this._store.select(fromRoot.getProfile);
    this.language$ = this._store.select(fromRoot.getLanguage);
    this.supportedLanguages$ = this._store.select(
      fromRoot.getSupportedLanguages
    );
    this.hideTopBarLogin$ = this._store.select(fromRoot.hideTopBarLogin);
    this._store
      .select(fromRoot.ageRating)
      .pipe(delay(0))
      .subscribe(ageRating => (this.ageRating = ageRating));

    this._subscriptions.add(
      this._store.select(fromRoot.getSelectedStep).subscribe(selectedStep => {
        this.selectedStep = selectedStep;
      })
    );

    // ensure that any page with top bar has operator settings
    this._store.dispatch(new customizationActions.GetOperatorsSettings());

    this._subscriptions.add(
      this._store
        .select(fromRoot.getSelectedExhibitionId)
        .subscribe(eventId => {
          this.getSelectedExhibitionId = eventId;
          this.isEventSelected = eventId && true;
        })
    );

    this._subscriptions.add(
      this._store
        .select(fromRoot.getLocalizedImages)
        .pipe(filter(images => !!images))
        .subscribe(images => {
          this.logoUrl = images.logo;
        })
    );

    this._subscriptions.add(
      this.ticketCount$.subscribe(ticketCount => {
        this.ticketCount = ticketCount;
      })
    );

    this._subscriptions.add(
      this._store
        .select(fromRoot.getOperatorsSettings)
        .pipe(filter(settings => !!settings))
        .subscribe(operatorsSettings => {
          _customizationService.setStyles();
          this.getOperatorsSettings = operatorsSettings;
        })
    );

    this._subscriptions.add(
      this.profile$.subscribe(profile => {
        this.profile = profile;
      })
    );

    this._subscriptions.add(
      this.isLoggedInAsAdmin$.subscribe(isAdmin => {
        this.isLoggedInAsAdmin = isAdmin;
        if (!isAdmin) {
          this._helperService.appEl.classList.remove('admin');
          return false;
        }
        this._helperService.appEl.classList.add('admin');
      })
    );

    this._subscriptions.add(
      this.language$.pipe(filter(lang => !!lang)).subscribe(lang => {
        this.currentLanguage = lang;
      })
    );

    this._subscriptions.add(
      this.supportedLanguages$
        .pipe(filter(langs => !!langs))
        .subscribe(langs => {})
    );
  }

  ngOnInit() {
    this.isMenuOpen = false;
    this.isLangMenuOpen = false;
    this.windowResize = this.result.startWith(null).subscribe(() => {
      this.viewport = this._windowSizeService.viewportWidth();
    });

    this._subscriptions.add(
      this._store.pipe(select(fromRoot.getExhibitionSettings)).subscribe(exhibitionSettings => {
        this.exhibitionSettings = exhibitionSettings;
      })
    );
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  closeBasket(closeed: Boolean) {
    this.basketOpened = !closeed;
  }

  toggleBasket() {
    this.basketOpened = !this.basketOpened;
    if (this.basketOpened) {
      this.isLangMenuOpen = false;
    }
  }

  toggleMenuButton() {
    return (this.isMenuOpen = !this.isMenuOpen);
  }

  togleSubmenuButton() {
    return (this.isLangMenuOpen = !this.isLangMenuOpen);
  }

  closeMenu() {
    return (this.isMenuOpen = false);
  }

  shouldDisplayOnMaintenancePage(currentUrl) {
    const webshopUrlReg = '/maintenance';

    if (currentUrl !== '/') {
      if (!this.isLoggedInAsAdmin) {
        const urlMatch = currentUrl.match(webshopUrlReg);
        return urlMatch && true;
      } else {
        return false;
      }
    }
  }

  shouldDisplayBackToEvent(currentUrl) {
    const webshopUrlReg = '/webshop.+';

    if (currentUrl !== '/') {
      const urlMatch = currentUrl.match(webshopUrlReg);
      return urlMatch && true;
    }
  }

  shouldDisplayBactToSelfReg(currentUrl) {
    const webshopUrlReg = '/self-registration.+';
    const urlMatch = !currentUrl.match(webshopUrlReg);
    return urlMatch && true;
  }

  changeLanguage(lang) {
    if (this.currentLanguage !== lang) {
      this._userService.changePreferredLanguage(lang);
      this._helperService.setLanguage(lang);
    }

    this.isLangMenuOpen = false;
  }

  openModalWindow() {
    this.modalWindowOpen = true;
  }

  backToShop() {
    this._router.navigate([
      `webshop/${this.getSelectedExhibitionId}/${!!this.selectedStep ? this.selectedStep : 'tickets'}`
    ]);
  }

  backToSelfReg() {
    this._router.navigate([
      `self-registration/${this.getSelectedExhibitionId}/${this.selectedStep}`
    ]);
  }

  submitModalWindow(event) {
    event.stopPropagation();
    this._router.navigate(['/']);
    this.modalWindowOpen = false;
  }

  closeModalWindow(event) {
    event.stopPropagation();
    this.modalWindowOpen = false;
  }
  closeModalWindowOnRightClick(event) {
    this.modalWindowOpen = false;
  }
}
