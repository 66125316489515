import * as fromRoot from '../app.reducer';

import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  RedeemVoucherModel,
  TicketByIdModel,
  VoucherSponsor
} from '../shared/services-with-reducers/tickets/ticket.interface';

import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-sponsor-list',
  templateUrl: './sponsor-list.component.html',
  styleUrls: ['./sponsor-list.component.scss']
})
export class SponsorListComponent implements OnInit, OnDestroy {
  public voucherTickets$: Observable<TicketByIdModel[]>;
  private _subsctiptions = new Subscription();
  public hasSponsors = false;

  constructor(private _store: Store<fromRoot.State>) {}

  ngOnInit() {
    this.voucherTickets$ = this._store.select(fromRoot.getVoucherTickets);
    this._subsctiptions.add(
      this.voucherTickets$.subscribe((tickets: TicketByIdModel[]) => {
        this.hasSponsors = tickets.some(ticket => {
          return !!ticket.sponsors.length;
        });
      })
    );
  }

  ngOnDestroy() {
    this._subsctiptions.unsubscribe();
  }
}
