type PrivacyPolicyText = { 
  value: string, 
  translation: string, 
  position: 'prepend' | 'append',
};

export class InputBase<T> {
  value: T;
  key: string;
  label: string;
  isQuestionnaire: boolean;
  required: boolean;
  order: number;
  controlType: string;
  disabled: boolean;
  options: any;
  cssClass: string;
  translate: boolean;
  maxLengthValidation: number;
  minLengthValidation: number;
  emailValidation: boolean;
  zipcodeValidation: boolean;
  numberValidation: boolean;
  phoneValidation: boolean;
  sameAsValidation: string;
  hidden: boolean;
  caseInsensitive: boolean;
  placeholder: string;
  autocomplete: boolean;
  selectionLimit: number;
  noTrim: boolean;
  previousValueId?: number;
  text?: PrivacyPolicyText;

  constructor(
    options: {
      value?: T;
      key?: string;
      label?: string;
      isQuestionnaire?: boolean;
      required?: boolean;
      order?: number;
      controlType?: string;
      disabled?: boolean;
      options?: any;
      cssClass?: string;
      translate?: boolean;
      maxLengthValidation?: number;
      minLengthValidation?: number;
      emailValidation?: boolean;
      zipcodeValidation?: boolean;
      numberValidation?: boolean;
      phoneValidation?: boolean;
      sameAsValidation?: string;
      hidden?: boolean;
      caseInsensitive?: boolean;
      placeholder?: string;
      autocomplete?: boolean;
      selectionLimit?: number;
      noTrim?: boolean;
      previousValueId?: number;
      text?: PrivacyPolicyText;
    } = {}
  ) {
    this.value = options.value;
    this.key = options.key || '';
    this.label = options.label || '';
    this.isQuestionnaire = options.isQuestionnaire || false;
    this.required = !!options.required;
    this.order = options.order === undefined ? 1 : options.order;
    this.controlType = options.controlType || '';
    this.disabled = options.disabled || false;
    this.cssClass = options.cssClass || '';
    this.maxLengthValidation = options.maxLengthValidation || 0;
    this.minLengthValidation = options.minLengthValidation || 0;
    this.emailValidation = options.emailValidation || false;
    this.zipcodeValidation = options.zipcodeValidation || false;
    this.numberValidation = options.numberValidation || false;
    this.phoneValidation = options.phoneValidation || false;
    this.sameAsValidation = options.sameAsValidation || '';
    this.translate = options.translate || false;
    this.options = options.options || [];
    this.hidden = options.hidden || false;
    this.caseInsensitive = options.caseInsensitive || false;
    this.placeholder = options.placeholder || '';
    this.autocomplete = options.hasOwnProperty('autocomplete')
      ? options['autocomplete']
      : true;
    this.selectionLimit = options.selectionLimit || 0;
    this.noTrim = options['noTrim'] || false;
    this.previousValueId = options.previousValueId;
    this.text = options.text;
  }
}
