import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { WidgetSettingType } from './../../../_pages/widget-admin/widget-models.interface';
import { HttpClient } from '@angular/common/http';

import { createFormData } from '../../app-utils';

@Injectable({
  providedIn: 'root'
})
export class WidgetService {
  constructor(private _http: HttpClient) {}

  createWidgetForEvent(eventId: number, widgetSettings: WidgetSettingType) {
    const formData = createFormData(widgetSettings);

    return this._http.post(
      `${environment.protocol}${
        environment.webApiUrl
      }/event/${eventId}/link-widgets`,
      formData
    );
  }

  updateWidgetSettings(widgetId: number, widgetSettings: WidgetSettingType) {
    const formData = createFormData(widgetSettings);

    return this._http.put(
      `${environment.protocol}${
        environment.webApiUrl
      }/widget/links/${widgetId}`,
      formData
    );
  }

  removeWidgetById(widgetId: number) {
    return this._http.delete(
      `${environment.protocol}${environment.webApiUrl}/widget/links/${widgetId}`
    );
  }

  getWidgetsForEvent(eventId: number) {
    return this._http.get(
      `${environment.protocol}${
        environment.webApiUrl
      }/event/${eventId}/link-widgets`
    );
  }

  getWidgetById(widgetId: number) {
    return this._http.get(
      `${environment.protocol}${environment.webApiUrl}/widget/links/${widgetId}`
    );
  }

  getBackgroundFile(fileId: number) {
    return this._http.get(
      `${environment.protocol}${environment.webApiUrl}/file/${fileId}/download`,
      { responseType: 'blob' }
    );
  }

  processWidgetSettingsLoad(
    settings: { [key: string]: any },
    loadSettingsProcessor: { [key: string]: Function }
  ): { [key: string]: any } {
    let processedSettings = {};

    for (const key in settings) {
      const setting = settings[key];

      if (loadSettingsProcessor[key]) {
        loadSettingsProcessor[key](setting, processedSettings, settings);
      } else {
        processedSettings[key] = setting;
      }
    }

    return processedSettings;
  }
}
