import { Action } from '@ngrx/store';

export enum ActionTypes {
  SELECT_PRESS_NEWS_ID = '[PressNews] Set active',
  GET_LIST_OF_PRESS_NEWS = '[PressNews] Get list of press news from API',
  GET_PRESS_NEWS_BY_ID = '[PressNews] Get specific press news from API',
  ADD_PRESS_NEWS_TO_LIST = '[PressNews] Add to list',
  SET_PRESS_NEWS_DETAIL = '[PressNews] Set press news detail'
}

export class GetListOfPressNews implements Action {
  readonly type = ActionTypes.GET_LIST_OF_PRESS_NEWS;
  constructor(public payload: number) {}
}

export class SelectPressNewsId implements Action {
  readonly type = ActionTypes.SELECT_PRESS_NEWS_ID;
  constructor(public payload) {}
}

export class GetPressNewsById implements Action {
  readonly type = ActionTypes.GET_PRESS_NEWS_BY_ID;
  constructor(public payload) {}
}

export class AddPressNewsToListAction implements Action {
  readonly type = ActionTypes.ADD_PRESS_NEWS_TO_LIST;
  constructor(public payload) {}
}

export class SetPressNewsDetail implements Action {
  readonly type = ActionTypes.SET_PRESS_NEWS_DETAIL;
  constructor(public payload) {}
}

export type Actions =
  | GetListOfPressNews
  | SelectPressNewsId
  | GetPressNewsById
  | AddPressNewsToListAction
  | SetPressNewsDetail;
