<div appAdjustSideHeadline *ngIf="inputs && inputs.length && stepsFormsActionName">
    <div class="section-headline">
        <h2 data-translation="personalize.extra-data.section-title">{{ 'personalize.extra-data.section-title' | translate | hideTranslation }}</h2>
        <div class="section-headline-side" data-translation="personalize.extra-data.headline">{{'personalize.extra-data.headline' | translate | hideTranslation }}</div>
    </div>
    <div class="section-body">
        <form [formGroup]="form" class="row">
            <app-df-input 
              *ngFor="let input of inputs" 
              [input]="input" 
              [class]="input.cssClass" 
              [inputSet]="inputs" 
              [action]="stepsFormsActionName"
              [form]="form">
            </app-df-input>
        </form>
    </div>
</div>
