/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./event-series.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "../../exhibition-tile/exhibition-tile.component.ngfactory";
import * as i5 from "../../exhibition-tile/exhibition-tile.component";
import * as i6 from "../../shared/services-with-reducers/exhibition/exhibition.service";
import * as i7 from "../../shared/pipes/hide-translation/hide-translation.pipe";
import * as i8 from "./event-series.component";
import * as i9 from "@angular/router";
import * as i10 from "@ngrx/store";
import * as i11 from "../../app.service";
var styles_EventSeriesComponent = [i0.styles];
var RenderType_EventSeriesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EventSeriesComponent, data: {} });
export { RenderType_EventSeriesComponent as RenderType_EventSeriesComponent };
function View_EventSeriesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", ""], ["class", "exhibition-img"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.localizedImages.banner; _ck(_v, 0, 0, currVal_0); }); }
function View_EventSeriesComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "event-series-more-events"], ["data-translation", "eventseries.more-events"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform("eventseries.more-events")); _ck(_v, 0, 0, currVal_0); }); }
function View_EventSeriesComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "event-series-no-events"], ["data-translation", "eventseries.no-events"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(2, 0, null, null, 4, "button", [["class", "button "], ["modalWindow-submit", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.backToHomePage($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "span", [["data-translation", "navigation.back-to-event"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(6, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform("eventseries.no-events")); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent.parent, 0), i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("navigation.back-to-event")))); _ck(_v, 4, 0, currVal_1); }); }
function View_EventSeriesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "page-padding-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [["class", "event-series-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "event-series-description"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventSeriesComponent_3)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventSeriesComponent_4)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.moreThanOneActiveEvents; _ck(_v, 6, 0, currVal_2); var currVal_3 = (!_co.futureEvents.length && !_co.activeEvents.length); _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.eventSeries.name; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.eventSeries.description; _ck(_v, 4, 0, currVal_1); }); }
function View_EventSeriesComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "page-padding-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "event-series-no-events"], ["data-translation", "eventseries.no-events"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 4, "button", [["class", "button "], ["modalWindow-submit", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.backToHomePage($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "span", [["data-translation", "navigation.back-to-event"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(7, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("eventseries.no-events")); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, _ck(_v, 7, 0, i1.ɵnov(_v.parent, 0), i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("navigation.back-to-event")))); _ck(_v, 5, 0, currVal_1); }); }
function View_EventSeriesComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["class", "exhibition-tile"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToEvent(_v.parent.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-exhibition-tile", [], null, null, null, i4.View_ExhibitionTileComponent_0, i4.RenderType_ExhibitionTileComponent)), i1.ɵdid(2, 49152, null, 0, i5.ExhibitionTileComponent, [i6.ExhibitionService], { exhibitionSerie: [0, "exhibitionSerie"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_EventSeriesComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "tile-col col-sm-6 col-md-4 col-lg-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "tile"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventSeriesComponent_8)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 4, 0, currVal_0); }, null); }
function View_EventSeriesComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "row tiles-row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventSeriesComponent_7)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.activeEvents; _ck(_v, 2, 0, currVal_0); }, null); }
function View_EventSeriesComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["class", "exhibition-tile"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToEvent(_v.parent.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-exhibition-tile", [], null, null, null, i4.View_ExhibitionTileComponent_0, i4.RenderType_ExhibitionTileComponent)), i1.ɵdid(2, 49152, null, 0, i5.ExhibitionTileComponent, [i6.ExhibitionService], { exhibition: [0, "exhibition"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_EventSeriesComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "tile-col col-sm-6 col-md-4 col-lg-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "tile"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventSeriesComponent_11)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 4, 0, currVal_0); }, null); }
function View_EventSeriesComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "row tiles-row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventSeriesComponent_10)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.futureEvents; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_EventSeriesComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i7.HideTranslationPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "page-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "img-side"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventSeriesComponent_1)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventSeriesComponent_2)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventSeriesComponent_5)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 5, "div", [["class", "exhibition-tiles"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 4, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventSeriesComponent_6)), i1.ɵdid(13, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventSeriesComponent_9)), i1.ɵdid(15, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !!_co.localizedImages; _ck(_v, 5, 0, currVal_0); var currVal_1 = !!_co.eventSeries; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.noActiveEvents; _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.moreThanOneActiveEvents; _ck(_v, 13, 0, currVal_3); var currVal_4 = !_co.moreThanOneActiveEvents; _ck(_v, 15, 0, currVal_4); }, null); }
export function View_EventSeriesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-event-series", [], null, null, null, View_EventSeriesComponent_0, RenderType_EventSeriesComponent)), i1.ɵdid(1, 245760, null, 0, i8.EventSeriesComponent, [i9.ActivatedRoute, i10.Store, i9.Router, i11.AppService, i6.ExhibitionService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EventSeriesComponentNgFactory = i1.ɵccf("app-event-series", i8.EventSeriesComponent, View_EventSeriesComponent_Host_0, {}, {}, []);
export { EventSeriesComponentNgFactory as EventSeriesComponentNgFactory };
