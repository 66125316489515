<ng-container *ngIf="loaded; else loading">
  <div class="homepage-banner-wrap main-bg">
    <div
      class="container-fluid nopadding-container"
      *ngIf="localizedImages$ | async"
    >
    <a 
      href="{{ operatorSettings?.bannerLink }}"
      target="_blank"
      [ngClass]="{'hide-link': !operatorSettings?.isBannerLinkVisible}">
      <img
        *ngIf="localizedImages.banner"
        class="banner-img"
        [src]="localizedImages.banner"
        alt=""
      />
    </a>
    </div>
  </div>
  <div class="choose-exhibition">
    <div class="container-fluid" data-translation="home.exhibition-title">
      {{ 'home.exhibition-title' | translate | hideTranslation }}
    </div>
  </div>
  <div class="exhibition-tiles">
    <div class="container-fluid">
      <div class="row tiles-row">
        <ng-container *ngFor="let exhibition of exhibitions">
          <div
            class="tile-col col-sm-6 col-md-4 col-lg-3"
            *ngIf="
              (exhibition.isVisible && !exhibition.isOver) ||
              (isLoggedInAsAdmin$ | async)
            "
          >
            <div class="tile">
              <app-age-rating
                *ngIf="exhibition.ageRating"
                [rating]="exhibition.ageRating"
                [adjustPositions]="{ top: '25px', right: '25px' }"
              ></app-age-rating>

              <a *ngIf="exhibition" [routerLink]="['webshop', exhibition.id]">
                <app-exhibition-tile
                  [exhibition]="exhibition"
                ></app-exhibition-tile>
              </a>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #loading>
  <app-loader></app-loader>
</ng-template>
