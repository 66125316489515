import { Component, OnDestroy, OnInit } from '@angular/core';

import { select, Store } from '@ngrx/store';

import {
  Observable,
  combineLatest as observableCombineLatest,
  Subject
} from 'rxjs';
import { WorkshopModel } from '../../../app/shared/services-with-reducers/additional-services/additional-services.interface';
import { filter, takeUntil, map, first } from 'rxjs/operators';

import { ExhibitionSettingModel } from '../../../app/shared/services-with-reducers/customization/customization.interfaces';
import { FormsService } from '../../../app/shared/forms/forms.service';
import { ProductGroupModel } from '../../../app/shared/services-with-reducers/tickets/ticket.interface';
import { TicketsService } from '../../../app/shared/services-with-reducers/tickets/tickets.service';

import {
  AddTicketBookingModel,
  TicketBookingModel,
  TicketByIdModel,
  TicketModel
} from '../../../app/shared/services-with-reducers/tickets/ticket.interface';

import * as additionalServicesActions from '../../../app/shared/services-with-reducers/additional-services/additional-services.actions';
import * as fromRoot from '../../../app/app.reducer';
import * as stepsActions from '../../../app/shared/services-with-reducers/step-forms/steps-forms.actions';
import { setLocalStorageObject } from '../../../app/shared/app-utils';

@Component({
  selector: 'app-widget-ticket-select',
  templateUrl: './widget-ticket-select.component.html',
  styleUrls: ['./widget-ticket-select.component.scss']
})
export class WidgetTicketSelectComponent implements OnInit, OnDestroy {
  public ticketSelectionIsValid: boolean;
  public exhibitionSettings: ExhibitionSettingModel;
  public ticketCounter: number;
  public ungroupedTickets: TicketModel;
  public numberOfSelectedTickets = 0;
  public maxTicketLimit = 0;
  public totalPrice: number = 0;
  public isTicketBookingLoading: boolean = false;
  public workshopsList: { [key: string]: number };
  public eventId: number;
  // used for ticktes structure (group with tickets)
  public productGroups: ProductGroupModel[] = null;

  public totalAvailableTickets$: Observable<{
    [key: string]: AddTicketBookingModel;
  }>;

  public isTicketSoldOut$: Observable<{ [key: string]: AddTicketBookingModel }>;
  public sholdShowPromoCode$: Observable<boolean>;
  public percentageOfAvailableTickets$: Observable<{ [key: string]: number }>;
  public availableTicketsCombined$: Observable<{
    [key: string]: AddTicketBookingModel;
  }>;

  private unsubscribe = new Subject();

  constructor(
    private _store: Store<fromRoot.State>,
    private _formsService: FormsService,
    private _ticketsService: TicketsService
  ) {
    // create ticket groups and order tickets and gropus by priority
    observableCombineLatest([
      this._store.pipe(select(fromRoot.getTicketsTypes)),
      this._store.pipe(select(fromRoot.getWidgetSettings))
    ])
      .pipe(
        filter(([productGroups, widgetSettings]) => {
          const ticketWidgetSettings =
            widgetSettings && widgetSettings.ticketsWidget;

          if (productGroups && ticketWidgetSettings) {
            return true;
          }
        }),
        takeUntil(this.unsubscribe)
      )
      .subscribe(([productGroups, widgetSettings]) => {
        const ticketWidgetSettings = widgetSettings.ticketsWidget;
        const allowedTickets = ticketWidgetSettings.tickets.split(',');

        let updatedProductGroups = productGroups.map(ticketGroup => {
          let updatedProductGroup = { ...ticketGroup };

          if (!updatedProductGroup.products) {
            return ticketGroup;
          }

          // filter out tickets that should not be displayed in widget
          updatedProductGroup.products = updatedProductGroup.products.filter(
            product => {
              if (product.ticket != null) {
                const ticketId = String(product.ticket.uniqueId);

                if (allowedTickets.indexOf(ticketId) !== -1) {
                  return true;
                }
              }
            }
          );

          // return null for group without products
          if (updatedProductGroup.products.length === 0) {
            return null;
          }

          return updatedProductGroup;
        });

        // filter out nulled groups
        updatedProductGroups = updatedProductGroups.filter(
          updatedTicketGroup => updatedTicketGroup
        );
        
        
        this.productGroups = [...updatedProductGroups];
      });

    // set exhibition settings and max limits for tickets
    this._store
      .select(fromRoot.getExhibitionSettings)
      .pipe(
        filter(settings => {
          if (settings) {
            return true;
          }
        }),
        takeUntil(this.unsubscribe)
      )
      .subscribe(settings => {
        this.exhibitionSettings = settings;
        this.maxTicketLimit = settings.limitBoughtTickets;
      });

    // calculate total price and set validation of a step
    this._store
      .select(fromRoot.getTickets)
      .pipe(
        filter(tickets => {
          return !!tickets;
        }),
        takeUntil(this.unsubscribe)
      )
      .subscribe((ungroupedTickets: TicketModel) => {
        // create a deep copy so we dont modify the original object in store
        this.ungroupedTickets = { ...ungroupedTickets };

        // go through tickets and sum price of selected ones. Also count number of selected tickets
        let numberOfSelectedTickets = 0;

        this.totalPrice = Object.keys(this.ungroupedTickets).reduce(
          (acc, key) => {
            numberOfSelectedTickets += this.ungroupedTickets[key].count;

            const pricePerTicketType: number = this.getPricePerTicketType(
              this.ungroupedTickets,
              key
            );
            const finalPrice = acc + pricePerTicketType;

            return finalPrice;
          },
          0
        );

        this.ticketCounter = Object.keys(this.ungroupedTickets).reduce(
          (acc, key) => {
            return acc + this.ungroupedTickets[key].count;
          },
          0
        );

        const isValid = this.isFormValid(numberOfSelectedTickets);

        this.setValidation(isValid);
        this.numberOfSelectedTickets = numberOfSelectedTickets;
        this.ticketSelectionIsValid = isValid;
      });
  }

  ngOnInit() {
    this.sholdShowPromoCode$ = this._store.select(fromRoot.sholdShowPromoCode);

    this._store
      .select(fromRoot.getSelectedExhibitionId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(eventId => {
        this.eventId = eventId;

        // get current worksops for tickets that are available only for workshop
        this._store.dispatch(
          new additionalServicesActions.GetWorkshops(eventId)
        );
      });

    // check how many tickets are available for tickets with workshops
    observableCombineLatest(
      this._store.select(fromRoot.getWorkshops),
      this._store.select(fromRoot.getTickets),
      this._store.select(fromRoot.getTicketsBooking)
    )
      .pipe(
        filter(([workshops, tickets]) => {
          if (workshops.length && tickets) {
            return true;
          }
        }),
        map(
          ([workshops, ungroupedTickets, ticketsBookings]: [
            WorkshopModel[],
            TicketModel,
            TicketBookingModel
          ]) => {
            const seatsAvailableByUniqueId = Object.keys(
              ungroupedTickets
            ).reduce((acc, ticketId) => {
              // all available workshop seats for this tickets, before booked workshop seats by other tickets are substracted
              const availableSeats = workshops.reduce((acc, workshop) => {
                if (
                  ungroupedTickets[ticketId].allowedWorkshops.includes(
                    workshop.workshopId
                  )
                ) {
                  acc += workshop.seats;
                }

                return acc;
              }, 0);

              const seatsBookingsByOtherTicket: AddTicketBookingModel[] = workshops.reduce(
                (acc, workshop) => {
                  function hasOtherSeats(booking, workshop) {
                    const otherWorkshopsIds = ungroupedTickets[
                      booking.ticketUniqueId
                    ].allowedWorkshops.filter(
                      workshopId => workshopId !== workshop.workshopId
                    );

                    let hasOtherSeats = true;

                    if (!otherWorkshopsIds.length) {
                      hasOtherSeats = false;
                    }

                    return hasOtherSeats;
                  }

                  const otherBookings = ticketsBookings.bookings.filter(
                    booking => {
                      return (
                        booking.count &&
                        booking.ticketUniqueId !== ticketId &&
                        ungroupedTickets[
                          booking.ticketUniqueId
                        ].allowedWorkshops.includes(workshop.workshopId) &&
                        !hasOtherSeats(booking, workshop)
                      );
                    }
                  );

                  acc = [...acc, ...otherBookings];

                  return acc;
                },
                []
              );

              const uniqueBookings = seatsBookingsByOtherTicket.filter(
                (value, index, self) => self.indexOf(value) === index
              );

              const seatsCountBookedByOtherTicket = uniqueBookings.reduce(
                (acc, booking) => {
                  acc += booking.count;

                  return acc;
                },
                0
              );

              acc[ticketId] = availableSeats - seatsCountBookedByOtherTicket;

              return acc;
            }, {});

            return seatsAvailableByUniqueId;
          }
        ),
        takeUntil(this.unsubscribe)
      )
      .subscribe(data => {
        this.workshopsList = data;
      });

    this.isTicketSoldOut$ = this._store.select(fromRoot.getTicketsBooking).pipe(
      map((booking: TicketBookingModel) => {
        this.isTicketBookingLoading = false;

        return booking.bookings.reduce((acc, booking) => {
          acc[booking.ticketUniqueId] = booking.isTicketSold;

          return acc;
        }, {});
      })
    );

    this.availableTicketsCombined$ = observableCombineLatest(
      this._store.select(fromRoot.getTickets),
      this._store.select(fromRoot.getTicketsBooking)
    ).pipe(
      filter(([ungroupedTickets]) => {
        if (ungroupedTickets) {
          return true;
        }
      }),
      map(([ungroupedTickets, ticketsBookings]) => {
        const availability = Object.keys(ungroupedTickets).reduce(
          (acc, ticketId) => {
            acc[ticketId] = ungroupedTickets[ticketId].availableTickets;

            if (ticketsBookings) {
              const realtimeBooking = ticketsBookings.bookings.find(booking => {
                return booking.ticketUniqueId === ticketId;
              });

              if (realtimeBooking) {
                acc[ticketId] = realtimeBooking.availableTickets;
              }
            }

            return acc;
          },
          {}
        );

        return availability;
      })
    );

    this.totalAvailableTickets$ = observableCombineLatest(
      this._store.select(fromRoot.getTicketsBooking),
      this._store.select(fromRoot.getTickets)
    )
      .filter(data => {
        return !!data[1];
      })
      .map(data => {
        const booking: TicketBookingModel = data[0];
        const ungroupedTickets: TicketModel = data[1];
        const defaultLimit = 1000;

        const availability = Object.keys(ungroupedTickets).reduce(
          (acc, ticketId) => {
            acc[ticketId] =
              ungroupedTickets[ticketId].availableTickets ||
              ungroupedTickets[ticketId].ticketLimit ||
              defaultLimit;

            if (booking) {
              const realtimeBooking = booking.bookings.find(booking => {
                return booking.ticketUniqueId === ticketId;
              });

              if (realtimeBooking) {
                acc[ticketId] = acc[ticketId] - realtimeBooking.count;
              }
            }
            return acc;
          },
          {}
        );

        return availability;
      });

    this.percentageOfAvailableTickets$ = observableCombineLatest(
      this._store.select(fromRoot.getTickets),
      this._store.select(fromRoot.getTicketsBooking)
    ).pipe(
      filter(([ungroupedTickets]) => {
        if (ungroupedTickets) {
          return true;
        }
      }),
      map(([ungroupedTickets, ticketBooking]) => {
        const ticketAvailability = Object.keys(ungroupedTickets).reduce(
          (acc, ticketKey) => {
            const ticket = ungroupedTickets[ticketKey];
            const uniqueId = ticket.uniqueId;
            const uniqueTicket = ungroupedTickets[uniqueId];

            acc[uniqueId] =
              uniqueTicket.availableTickets / uniqueTicket.ticketLimit;

            return acc;
          },
          {}
        );

        const bookingsAvailability = ticketBooking.bookings.reduce(
          (acc, booking) => {
            const uniqueId = booking.ticketUniqueId;

            acc[uniqueId] = booking.availableTickets / booking.ticketLimit;

            return acc;
          },
          {}
        );

        return { ...ticketAvailability, ...bookingsAvailability };
      })
    );
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  /**
   * count prices of ticket per type;
   * based on ticket count of that type and number of sales available for this ticket type
   * in case there are more ticket than sales, make sum of prices of reduced tickets and full price tickets
   *
   * @param {TicketModel} ungroupedTickets
   * @param {string} ticketUniqueId
   * @returns {number}
   * @memberof WebShopTicketsComponent
   */
  getPricePerTicketType(
    ungroupedTickets: TicketModel,
    ticketUniqueId: string
  ): number {
    const ticketWithCount = ungroupedTickets[ticketUniqueId];
    const ticketCount = ticketWithCount.count;
    const fullTicketsPrice: number = ticketCount * ticketWithCount.price;

    return fullTicketsPrice;
  }

  /**
   * tell whether form is valid (at least one ticket is selected)
   *
   * @param {any} numberOfSelectedTickets
   * @returns
   * @memberof WebShopTicketsComponent
   */
  isFormValid(numberOfSelectedTickets: any) {
    return numberOfSelectedTickets ? true : false;
  }

  setValidation(isValid) {
    const stepsFormsActionName = ['tickets', 'ticketSelection'];

    if (isValid) {
      this._formsService.removeStepValidationFeedback(
        stepsFormsActionName,
        'counters'
      );
    } else {
      this._formsService.addStepValidationFeedback(
        stepsFormsActionName,
        'counters',
        'steps.missing-input.ticket-counter'
      );
    }

    this._formsService.setFormValidity(isValid, null, stepsFormsActionName);
  }

  selectTicket(ticket: TicketByIdModel) {
    this._ticketsService.selectTicket(ticket);
  }

  /**
   *
   *
   * @param {{value: number; decrease: number}} change can be either 1 or -1, depending whether ticket was added or removed
   * @param {any} ticketUniqueId
   * @memberof WebShopTicketsComponent
   */
  counterChange(data: { value: number; decrease: boolean }, uniqueId: string) {
    const { value, decrease } = data;

    //if ticket count is increased and if workshop selection is mandatory we set the step not valid
    if (!decrease && this.exhibitionSettings.isWorkshopsSelectionMandatory) {
      this._formsService.setFormValidity(false, null, [
        'workshop',
        'validation'
      ]);
    }

    //when we change ticket to different one and if address coppied is checked we uncheck it

    this._store.dispatch(
      new stepsActions.SetAddressCheckbox({
        checkedSlideIndex: null,
        isAddressCopied: false
      })
    );

    this._ticketsService.ticketCounterChanged(uniqueId, value, decrease);
  }

  ticketLoading(ticketLoading) {
    this.isTicketBookingLoading = ticketLoading;
  }

  onContinueClick(event: Event) {
    const webshopOriginURL = `${window.location.protocol}//${window.location.host}`;
    const webshopPagePathname = `/webshop/${this.eventId}/tickets`;
    const toWebshop = webshopOriginURL + webshopPagePathname;

    this._store.pipe(first()).subscribe(store => {
      try {
        for (const key in store) {
          const storeItem = store[key];

          setLocalStorageObject(key, storeItem);
        }
      } catch (error) {
        console.log(error);
      }
    });

    window.open(toWebshop);
  }
}
