import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { createFormData } from '../../app-utils';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var WidgetService = /** @class */ (function () {
    function WidgetService(_http) {
        this._http = _http;
    }
    WidgetService.prototype.createWidgetForEvent = function (eventId, widgetSettings) {
        var formData = createFormData(widgetSettings);
        return this._http.post("" + environment.protocol + environment.webApiUrl + "/event/" + eventId + "/link-widgets", formData);
    };
    WidgetService.prototype.updateWidgetSettings = function (widgetId, widgetSettings) {
        var formData = createFormData(widgetSettings);
        return this._http.put("" + environment.protocol + environment.webApiUrl + "/widget/links/" + widgetId, formData);
    };
    WidgetService.prototype.removeWidgetById = function (widgetId) {
        return this._http.delete("" + environment.protocol + environment.webApiUrl + "/widget/links/" + widgetId);
    };
    WidgetService.prototype.getWidgetsForEvent = function (eventId) {
        return this._http.get("" + environment.protocol + environment.webApiUrl + "/event/" + eventId + "/link-widgets");
    };
    WidgetService.prototype.getWidgetById = function (widgetId) {
        return this._http.get("" + environment.protocol + environment.webApiUrl + "/widget/links/" + widgetId);
    };
    WidgetService.prototype.getBackgroundFile = function (fileId) {
        return this._http.get("" + environment.protocol + environment.webApiUrl + "/file/" + fileId + "/download", { responseType: 'blob' });
    };
    WidgetService.prototype.processWidgetSettingsLoad = function (settings, loadSettingsProcessor) {
        var processedSettings = {};
        for (var key in settings) {
            var setting = settings[key];
            if (loadSettingsProcessor[key]) {
                loadSettingsProcessor[key](setting, processedSettings, settings);
            }
            else {
                processedSettings[key] = setting;
            }
        }
        return processedSettings;
    };
    WidgetService.ngInjectableDef = i0.defineInjectable({ factory: function WidgetService_Factory() { return new WidgetService(i0.inject(i1.HttpClient)); }, token: WidgetService, providedIn: "root" });
    return WidgetService;
}());
export { WidgetService };
