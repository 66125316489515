<header *ngIf="displayHeader" class="header">
    <div class="header-wrapper">
        <div class="logo-wrapper">
            <div style="position: relative;">
                <img
                    class="operator-logo"
                    *ngIf="logoUrl"
                    [src]="logoUrl"
                    alt="{{ env.friedrichshafen }}"
                />

                <app-age-rating
                    *ngIf="ageRating$ | async"
                    [rating]="ageRating$ | async"
                    [adjustPositions]="{ top: '10px', right: '25px' }"
                ></app-age-rating>
            </div>
        </div>

        <div
            class="navigation-wrapper menu-button-wrapper"
            *ngIf="viewport <= mediumBreakpoin"
        >
            <div class="menu-button" (click)="toggleMenuButton()">
                <span class="menu-button_text">{{
                    'navigation.menu' | translate | hideTranslation
                }}</span>
                <div class="nav-icon" [class.open]="isMenuOpen">
                    <span></span> <span></span> <span></span> <span></span>
                </div>
            </div>
        </div>

        <nav class="navigation-wrapper menu-content-wrapper">
            <a
                (click)="togleSubmenuButton()"
                class="navigation-content language"
                [class.open]="isLangMenuOpen"
            >
                <svg class="icon icon-earth">
                    <use
                        xlink:href="assets/defs.svg#icon-earth"
                        href="assets/defs.svg#icon-earth"
                    ></use>
                </svg>
                <span class="toggle-button">{{ currentLanguage }}</span>
            </a>

            <div [class.open]="isLangMenuOpen" class="lang-submenu-wrapper">
                <ul>
                    <li
                        *ngFor="let lang of supportedLanguages$ | async"
                        (click)="changeLanguage(lang); closeMenu()"
                    >
                        <span>{{ lang }}</span>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</header>
