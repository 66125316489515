import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-event-passed',
  templateUrl: './event-passed.component.html',
  styleUrls: ['./event-passed.component.scss']
})
export class EventPassedComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
