/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cofirmation-workshop.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/pipes/localized-date/localized-date.pipe";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@angular/common";
import * as i5 from "@ngrx/store";
import * as i6 from "../../../shared/pipes/hide-translation/hide-translation.pipe";
import * as i7 from "./cofirmation-workshop.component";
var styles_CofirmationWorkshopComponent = [i0.styles];
var RenderType_CofirmationWorkshopComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CofirmationWorkshopComponent, data: {} });
export { RenderType_CofirmationWorkshopComponent as RenderType_CofirmationWorkshopComponent };
function View_CofirmationWorkshopComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " | "])), i1.ɵpid(0, i2.LocalizedDatePipe, [i3.TranslateService, i4.DatePipe, i5.Store])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.workshop.date, "MMM d, yyyy")); _ck(_v, 1, 0, currVal_0); }); }
function View_CofirmationWorkshopComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "name"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(4, 1), (_l()(), i1.ɵeld(5, 0, null, null, 8, "div", [["class", "dates"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " | "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(8, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CofirmationWorkshopComponent_2)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(11, null, [" ", " - ", " "])), i1.ɵpid(0, i2.LocalizedDatePipe, [i3.TranslateService, i4.DatePipe, i5.Store]), i1.ɵpid(0, i2.LocalizedDatePipe, [i3.TranslateService, i4.DatePipe, i5.Store])], function (_ck, _v) { var _co = _v.component; var currVal_2 = !_co.hideWorkshopDate; _ck(_v, 10, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.workshop.workshopName)))); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 6, 0, _ck(_v, 8, 0, i1.ɵnov(_v.parent, 0), i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_co.workshop.roomName)))); _ck(_v, 6, 0, currVal_1); var currVal_3 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform(_co.workshop.start, "shortTime")); var currVal_4 = i1.ɵunv(_v, 11, 1, i1.ɵnov(_v, 13).transform(_co.workshop.end, "shortTime")); _ck(_v, 11, 0, currVal_3, currVal_4); }); }
export function View_CofirmationWorkshopComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i6.HideTranslationPipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CofirmationWorkshopComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.workshop; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CofirmationWorkshopComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cofirmation-workshop", [], null, null, null, View_CofirmationWorkshopComponent_0, RenderType_CofirmationWorkshopComponent)), i1.ɵdid(1, 245760, null, 0, i7.CofirmationWorkshopComponent, [i5.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CofirmationWorkshopComponentNgFactory = i1.ɵccf("app-cofirmation-workshop", i7.CofirmationWorkshopComponent, View_CofirmationWorkshopComponent_Host_0, { workshops: "workshops", workshopId: "workshopId" }, {}, []);
export { CofirmationWorkshopComponentNgFactory as CofirmationWorkshopComponentNgFactory };
