<div
    class="navigation-tabs"
    [class.wide]="type === 'wide'"
>
    <h1
        class="title"
        *ngIf="title"
        [attr.data-translation]="title"
    >
        {{ title }}
    </h1>
    <div class="navigation-tabs-group">
        <div
            class="navigation-tabs-item"
            *ngFor='let tab of tabs'
            (click)="changeTabTo(tab.id)"
            [class.active]="activeTab === tab.id"
            [attr.data-translation]="tab.title"
        >
            {{ tab.title }}
            <div
                class="navigation-tabs-item-text"
                *ngIf="tab.text"
            >
                {{ tab.text }}
            </div>
        </div>
    </div>
</div>
