/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./verify-email.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "../../shared/pipes/hide-translation/hide-translation.pipe";
import * as i5 from "./verify-email.component";
import * as i6 from "@angular/router";
import * as i7 from "@ngrx/store";
import * as i8 from "../../shared/services-with-reducers/helpers/helper.service";
var styles_VerifyEmailComponent = [i0.styles];
var RenderType_VerifyEmailComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VerifyEmailComponent, data: {} });
export { RenderType_VerifyEmailComponent as RenderType_VerifyEmailComponent };
function View_VerifyEmailComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "verify-email-success-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "reset-email-fail-text"], ["data-translation", "verify-email.text-sending"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(4, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("verify-email.text-sending")))); _ck(_v, 2, 0, currVal_0); }); }
function View_VerifyEmailComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "verify-email-success-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "verify-email-success-text"], ["data-translation", "verify-email.text-send"]], null, null, null, null, null)), i1.ɵdid(2, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"], translateParams: [1, "translateParams"] }, null), i1.ɵpod(3, { assignedTickets: 0, assignedOrders: 1 }), (_l()(), i1.ɵeld(4, 0, null, null, 4, "button", [["class", "button"], ["data-translation", "verify-email.akcept"], ["type", "akcept-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.verifyEmail() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(8, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "verify-email.text-send"; var currVal_1 = _ck(_v, 3, 0, _co.assignedTickets, _co.assignedOrders); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 6, 0, _ck(_v, 8, 0, i1.ɵnov(_v.parent.parent, 0), i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("verify-email.akcept")))); _ck(_v, 6, 0, currVal_2); }); }
function View_VerifyEmailComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "verify-email-success-text"], ["data-translation", "verify-email.text-timeout"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(4, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent.parent, 0), i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("verify-email.text-timeout")))); _ck(_v, 2, 0, currVal_0); }); }
function View_VerifyEmailComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "verify-email-success-text"], ["data-translation", "verify-email.text-fail"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(3, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent.parent, 0), i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("verify-email.text-fail")))); _ck(_v, 1, 0, currVal_0); }); }
function View_VerifyEmailComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "verify-email-success-body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_VerifyEmailComponent_5)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_VerifyEmailComponent_6)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 4, "button", [["class", "button back-button"], ["data-translation", "verify-email.back"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateHome() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(11, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.verifyEmailResponse)) === 408); _ck(_v, 2, 0, currVal_0); var currVal_1 = (i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.verifyEmailResponse)) !== 408); _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 9, 0, _ck(_v, 11, 0, i1.ɵnov(_v.parent.parent, 0), i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("verify-email.back")))); _ck(_v, 9, 0, currVal_2); }); }
function View_VerifyEmailComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_VerifyEmailComponent_3)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_VerifyEmailComponent_4)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.verifyEmailResponse || (i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.verifyEmailResponse)) === 200)); _ck(_v, 2, 0, currVal_0); var currVal_1 = (!!_co.verifyEmailResponse && (i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.verifyEmailResponse)) !== 200)); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_VerifyEmailComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.HideTranslationPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "verify-email-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "verify-email-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "span", [["data-translation", "verify-email.title"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(6, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VerifyEmailComponent_1)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VerifyEmailComponent_2)), i1.ɵdid(10, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.hashStatus === "waiting"); _ck(_v, 8, 0, currVal_1); var currVal_2 = (_co.hashStatus !== "waiting"); _ck(_v, 10, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 4, 0, _ck(_v, 6, 0, i1.ɵnov(_v, 0), i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("verify-email.title")))); _ck(_v, 4, 0, currVal_0); }); }
export function View_VerifyEmailComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-verify-email", [], null, null, null, View_VerifyEmailComponent_0, RenderType_VerifyEmailComponent)), i1.ɵdid(1, 245760, null, 0, i5.VerifyEmailComponent, [i6.Router, i6.ActivatedRoute, i7.Store, i8.HelperService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VerifyEmailComponentNgFactory = i1.ɵccf("app-verify-email", i5.VerifyEmailComponent, View_VerifyEmailComponent_Host_0, {}, {}, []);
export { VerifyEmailComponentNgFactory as VerifyEmailComponentNgFactory };
