import * as additionalServicesActions from './additional-services.actions';
import * as fromRoot from '../../../app.reducer';

import { Action, Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  AddWorkshopBookingModel,
  MenuModel,
  WorkshopBookingPostModel,
  WorkshopModel,
  AddWorkshopBookingWithHolderModel
} from './additional-services.interface';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Observable, EMPTY as empty, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  map,
  mergeMap,
  switchMap
} from 'rxjs/operators';

import { AdditionalServicesService } from './additional-services.service';

export const ADDITIONAL_SERVICES_DEBOUNCE = new InjectionToken<number>(
  'Additional Services Debounce'
);

@Injectable()
export class AdditionalServicesEffect {
  @Effect()
  loadMenuOptions$: Observable<Action> = this.actions$.pipe(
    ofType<additionalServicesActions.GetMenuOptions>(
      additionalServicesActions.ActionTypes.GET_MENU_OPTIONS
    ),
    debounceTime(this.debounce),
    switchMap((data: any) => {
      const eventId = data.payload;
      if (!eventId) {
        return Observable.empty();
      }

      return this.additionalServicesService.getMenuOptions(eventId).pipe(
        map(
          (menuOptions: MenuModel[]) =>
            new additionalServicesActions.SetMenu(menuOptions)
        ),
        catchError(error => {
          console.log(error);
          return of(new additionalServicesActions.SetMenu([]));
        })
      );
    })
  );

  @Effect()
  loadWorkshops$: Observable<Action> = this.actions$.pipe(
    ofType<additionalServicesActions.GetWorkshops>(
      additionalServicesActions.ActionTypes.GET_WORKSHOPS
    ),
    debounceTime(this.debounce),
    switchMap((data: any) => {
      const eventId = data.payload;
      if (!eventId) {
        return Observable.empty();
      }

      return this.additionalServicesService.getWorkshopList(eventId).pipe(
        map(
          (workshopList: WorkshopModel[]) =>
            new additionalServicesActions.SetWorkshops(workshopList)
        ),
        catchError(error => {
          console.log(error);
          return of(new additionalServicesActions.SetWorkshops([]));
        })
      );
    })
  );

  // debounceTime to prevent "out of order" responses (can cause full workshop appearing as not full)
  @Effect()
  postWorkshopBooking$: Observable<Action> = this.actions$.pipe(
    ofType<additionalServicesActions.PostWorkshopBooking>(
      additionalServicesActions.ActionTypes.POST_WORKSHOP_BOOKING
    ),
    debounceTime(this.debounce),
    mergeMap((data: any) => {
      // TODO update interface WorkshopBookingPostModel
      const payload = data.payload;

      if (!payload) {
        return Observable.empty();
      }

      const ticketHolderId = payload.ticketHolderId;
      delete payload.ticketHolderId;

      return this.additionalServicesService.postWorkshopBooking(payload).pipe(
        map((bookings: AddWorkshopBookingModel) => {
          bookings.added = payload.seats >= 0 ? true : false;
          bookings.ticketHolderId = ticketHolderId;
          this._store.dispatch(
            new additionalServicesActions.AddWorkshopBookingFull({
              isFull: false,
              workshopId: payload.workshopId
            })
          );

          let model = new AddWorkshopBookingWithHolderModel();
          model.addWorkshopBookingModel = bookings;
          model.assignWorkshopToHolderModel = data.assignWorkshopToHolderModel;

          return new additionalServicesActions.AddWorkshopBooking(model);
        }),
        catchError(error => {
          console.log(error);
          this._store.dispatch(
            new additionalServicesActions.AddWorkshopBookingFull({
              isFull: true,
              workshopId: payload.workshopId
            })
          );
          return of(new additionalServicesActions.AddWorkshopBooking(null));
        })
      );
    })
  );

  @Effect({ dispatch: false })
  afterAddWorkshopBooking$: Observable<void> = this.actions$.pipe(
    ofType<additionalServicesActions.AddWorkshopBooking>(
      additionalServicesActions.ActionTypes.ADD_WORKSHOP_BOOKING
    ),
    switchMap(data =>
      of(
        this._store.dispatch(
          new additionalServicesActions.AfterAddWorkshopBooking(
            data.payload.assignWorkshopToHolderModel
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private additionalServicesService: AdditionalServicesService,
    private _store: Store<fromRoot.State>,
    @Optional()
    @Inject(ADDITIONAL_SERVICES_DEBOUNCE)
    private debounce: number = 50
  ) {}
}
