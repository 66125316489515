var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import * as pressNewsActions from './press-news.actions';
import { Actions, Effect } from '@ngrx/effects';
import { InjectionToken } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';
import { PressNewsService } from './press-news.service';
export var PRESS_NEWS_DEBOUNCE = new InjectionToken('Press News Debounce');
var PressNewsEffect = /** @class */ (function () {
    function PressNewsEffect(actions$, pressNewsService, debounce) {
        if (debounce === void 0) { debounce = 50; }
        var _this = this;
        this.actions$ = actions$;
        this.pressNewsService = pressNewsService;
        this.debounce = debounce;
        this.loadPressNews$ = this.actions$
            .ofType(pressNewsActions.ActionTypes.GET_LIST_OF_PRESS_NEWS)
            .pipe(debounceTime(this.debounce), switchMap(function (data) {
            var eventId = data.payload;
            return _this.pressNewsService
                .getPressNewsList(eventId)
                .pipe(map(function (pressNewsList) {
                return new pressNewsActions.AddPressNewsToListAction(pressNewsList);
            }), catchError(function () {
                return of(new pressNewsActions.AddPressNewsToListAction([]));
            }));
        }));
        this.loadPressNewsByID$ = this.actions$
            .ofType(pressNewsActions.ActionTypes.GET_PRESS_NEWS_BY_ID)
            .pipe(debounceTime(this.debounce), switchMap(function (data) {
            var eventId = data.payload;
            if (!eventId) {
                return Observable.empty();
            }
            return _this.pressNewsService
                .getPressNews(eventId)
                .pipe(map(function (pressNewsById) {
                return new pressNewsActions.SetPressNewsDetail(pressNewsById);
            }), catchError(function () { return of(new pressNewsActions.SetPressNewsDetail(null)); }));
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], PressNewsEffect.prototype, "loadPressNews$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], PressNewsEffect.prototype, "loadPressNewsByID$", void 0);
    return PressNewsEffect;
}());
export { PressNewsEffect };
