import { combineLatest as observableCombineLatest, Subscription } from 'rxjs';
import { filter, first, debounceTime, switchMap } from 'rxjs/operators';
import * as colorizerActions from './colorizer.actions';
import * as fromRoot from './../app.reducer';
import { OnDestroy, OnInit } from '@angular/core';
import { CssSkinModel } from '../shared/services-with-reducers/customization/customization.interfaces';
import { ColorizerService } from './colorizer.service';
import { CustomizationService } from '../shared/services-with-reducers/customization/customization.service';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { generateSkinCss } from '../shared/services-with-reducers/customization/customization.styles';
import { styleList } from '../shared/services-with-reducers/customization/customization.styles';
var ColorizerComponent = /** @class */ (function () {
    function ColorizerComponent(_store, _customizationService, _colorizerService, _translateService) {
        this._store = _store;
        this._customizationService = _customizationService;
        this._colorizerService = _colorizerService;
        this._translateService = _translateService;
        this.styleList = styleList;
        this.expanded = false;
        this.modalWindowOpen = false;
        this.modalWindowType = '';
        this.modalTitle = '';
        this.templateTitle = '';
        this.transaltionEventTitle = '';
        this.preselectedTemplate = null;
        this.themeDefault = require('../../assets/admin/theme-default.json');
        this.subscriptions = new Subscription();
    }
    ColorizerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.activeLang = this._translateService.currentLang;
        this._store.dispatch(new colorizerActions.GetAllStyles());
        this.subscriptions.add(this._store.select(fromRoot.getStyleId).subscribe(function (styleId) {
            _this.selectedTemplateID = styleId;
        }));
        this.allStyleTemplates$ = this._store.select(fromRoot.getAllStyles);
        this.subscriptions.add(this._store.select(fromRoot.getSelectedExhibition).subscribe(function (event) {
            if (event && event !== null) {
                _this.transaltionEventTitle = event.eventName;
            }
            else {
                _this.transaltionEventTitle = 'operator';
            }
        }));
        this.subscriptions.add(this._store
            .select(fromRoot.getSelectedExhibitionId)
            .subscribe(function (eventId) {
            if (eventId !== null) {
                _this.eventType = 'event';
            }
            else {
                _this.eventType = 'operator';
            }
        }));
        this.subscriptions.add(this._store
            .select(fromRoot.getSelectedStyle)
            .subscribe(function (skin) {
            if (skin && skin.variables) {
                var allVariables = _this.generateSkinVariables(skin);
                _this.allSkinVariables = allVariables;
                _this._customizationService.appendSkinCSS(_this.allSkinVariables, 5, false, _this.selectedTemplateID);
            }
        }));
        // when we load the colorizer tool, check which style is active
        this.subscriptions.add(observableCombineLatest([
            this._store.pipe(select(fromRoot.getExhibitionStyles)),
            this._store.pipe(select(fromRoot.getAllStyles)),
            this._store.pipe(select(fromRoot.getOperatorsSettings))
        ])
            .pipe(filter(function (_a) {
            var exhibitionStyles = _a[0], styleTemplates = _a[1], operatorSettings = _a[2];
            return !!exhibitionStyles
                && exhibitionStyles.hasOwnProperty('variables')
                && !!styleTemplates
                && !!operatorSettings;
        }), debounceTime(100))
            .subscribe(function (_a) {
            var exhibitionStyles = _a[0], styleTemplates = _a[1], operatorSettings = _a[2];
            // set the variables of currently selected template into inputs
            _this.allSkinVariables = _this.generateSkinVariables(exhibitionStyles);
            if (exhibitionStyles.hasOwnProperty('id') &&
                (exhibitionStyles.id || exhibitionStyles.id === 0)) {
                _this.selectedTemplateID = exhibitionStyles.id;
                styleTemplates.forEach(function (styleTemplate) {
                    if (_this.selectedTemplateID === styleTemplate.id) {
                        _this.selectedTemplateTitle = styleTemplate.title;
                    }
                });
            }
            else if (operatorSettings.customStylesId) {
                _this.selectedTemplateID = operatorSettings.customStylesId;
                styleTemplates.forEach(function (styleTemplate) {
                    if (_this.selectedTemplateID === styleTemplate.id) {
                        _this.selectedTemplateTitle = styleTemplate.title;
                    }
                });
            }
            else {
                console.log('id not present');
            }
        }));
    };
    ColorizerComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    ColorizerComponent.prototype.togglePicker = function (e) {
        var designToolWrapper = document.querySelector('.style-list');
        designToolWrapper.classList.toggle('no-scroll');
    };
    ColorizerComponent.prototype.inputChanged = function (styleVariableKey, value) {
        this.allSkinVariables[styleVariableKey] = value;
        this.generateAndApplyStyles();
    };
    ColorizerComponent.prototype.generateAndApplyStyles = function () {
        if (this.allSkinVariables) {
            // add styles
            var cssString = generateSkinCss(this.allSkinVariables);
            var cssSkin = {
                priority: 5,
                css: cssString,
                variables: this.allSkinVariables
            };
            this._store.dispatch(new colorizerActions.SetSelectedStyle(cssSkin));
            this._customizationService.appendSkinCSS(this.allSkinVariables, 5, false, this.selectedTemplateID);
        }
    };
    ColorizerComponent.prototype.mouseOver = function (variableKey) {
        var css = this.generateHighlightCss(variableKey);
        this._customizationService.addCSS(css, 'adminHighlight');
    };
    ColorizerComponent.prototype.mouseLeave = function () {
        this._customizationService.addCSS('', 'adminHighlight');
    };
    ColorizerComponent.prototype.generateSkinVariables = function (skin) {
        var _this = this;
        var allVariables = {};
        Object.keys(styleList).forEach(function (styleKey) {
            var containsVariables = skin && skin.variables;
            var fallbackStyle = _this.themeDefault[styleKey] || null;
            if (containsVariables) {
                allVariables[styleKey] = skin.variables[styleKey] || fallbackStyle;
            }
            else {
                allVariables[styleKey] = fallbackStyle;
            }
        });
        return allVariables;
    };
    ColorizerComponent.prototype.generateHighlightCss = function (variableKey) {
        var cssString = '';
        if (styleList.hasOwnProperty(variableKey)) {
            cssString = styleList[variableKey].list.reduce(function (allStyles, currentSelector) {
                return (allStyles +
                    (currentSelector.selector + " {\n  outline: 1px dashed black !important;\n}\n"));
            }, cssString);
        }
        return cssString;
    };
    ColorizerComponent.prototype.checkIfEmpty = function (event, variableKey) {
        if (!event.target.value) {
            this.inputChanged(variableKey, '');
        }
    };
    ColorizerComponent.prototype.openModalWindow = function (type) {
        this.modalWindowType = type;
        this.modalWindowOpen = true;
        switch (type) {
            case 'load':
                this.translateTitle(type, '');
                break;
            case 'update':
                this.translateTitle(type, '');
                break;
            case 'create':
                this.translateTitle(type, '');
                break;
            case 'save':
                this.translateTitle(type, "." + this.eventType);
                break;
            default:
                break;
        }
    };
    ColorizerComponent.prototype.translateTitle = function (type, eventType) {
        var _this = this;
        this._store
            .select(fromRoot.getLanguage)
            .pipe(switchMap(function (currentLang) {
            return _this._translateService.get("modalWindow.colorizer." + type + "-title" + eventType, {
                lang: currentLang.toUpperCase()
            });
        }))
            .subscribe(function (title) {
            _this.modalTitle = title;
        });
    };
    ColorizerComponent.prototype.submitModalWindow = function (event) {
        var _this = this;
        event.stopPropagation();
        this.modalWindowOpen = false;
        var templateID = this.selectedTemplateID;
        switch (this.modalWindowType) {
            case 'load':
                this.selectedTemplateID = this.preselectedTemplate.id;
                this.selectedTemplateTitle = this.preselectedTemplate.title;
                this.preselectedTemplate = null;
                this._store.dispatch(new colorizerActions.LoadStyle(this.selectedTemplateID));
                break;
            case 'update':
                this._store.dispatch(new colorizerActions.UpdateStyle({
                    title: templateID != null ? this.selectedTemplateTitle : '* Default *',
                    styles: this.allSkinVariables,
                    styleId: templateID != null ? templateID : -1
                }));
                break;
            case 'save':
                this._store
                    .select(fromRoot.getSelectedExhibitionId)
                    .pipe(first())
                    .subscribe(function (eventId) {
                    eventId = eventId ? Number(eventId) : null;
                    _this._store.dispatch(new colorizerActions.SaveEventStyles({
                        styleId: _this.selectedTemplateID,
                        eventId: eventId
                    }));
                });
                break;
            case 'create':
                this._store.dispatch(new colorizerActions.CreateStyle({
                    title: this.templateTitle,
                    styles: this.allSkinVariables
                }));
                this.templateTitle = '';
                break;
            default:
                break;
        }
    };
    ColorizerComponent.prototype.closeModalWindow = function (e) {
        event.stopPropagation();
        this.modalWindowOpen = false;
        this.preselectedTemplate = null;
    };
    ColorizerComponent.prototype.preselectTile = function (template) {
        this.preselectedTemplate = template;
    };
    return ColorizerComponent;
}());
export { ColorizerComponent };
