<div class="detail-wrapper container-fluid">
    <div class="detail-header">
        <h2>{{ singleNews.title }}</h2>
        <h4>
            <span class="subtitle">{{ singleNews.subtitle }}</span>
            <span class="date">{{ singleNews.releasedTime | date:"dd. MMM y" }}</span>
        </h4>
    </div>
    <div [innerHTML]="singleNews.body" class="detail-body"></div>
</div>
