import { Component, OnInit, OnDestroy } from '@angular/core';
import * as fromRoot from '../../app.reducer';
import { select, Store } from '@ngrx/store';
import { Subscription, combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-bottom-sticky',
  templateUrl: './bottom-sticky.component.html',
  styleUrls: ['./bottom-sticky.component.scss']
})
export class BottomStickyComponent implements OnInit, OnDestroy {
  public textWithLink: string = '';
  public isCookiePopupEnabled: boolean = false;
  private isLoggedInAsAdmin: boolean = false;
  private checkCookiePopupStatus: boolean = true;
  private _subscriptions: Subscription = new Subscription();

  constructor(private _store: Store<fromRoot.State>, private _translateService: TranslateService) {
    this._subscriptions.add(
      combineLatest([
        this._store.pipe(select(fromRoot.isLoggedInAsAdmin)),
        this._store.pipe(select(fromRoot.getSelfRegistration))
      ]).subscribe(([isLoggedInAsAdmin, isSelfRegistrationEnabled]) => {
        this.checkCookiePopupStatus = !isLoggedInAsAdmin && !isSelfRegistrationEnabled;
        this.isLoggedInAsAdmin = isLoggedInAsAdmin;
      })
    );
  }
  
  ngOnInit() {
    if (this.checkCookiePopupStatus) {
      this._subscriptions.add(
        combineLatest([
          this._store.pipe(select(fromRoot.isLoggedInAsAdmin)),
          this._store.pipe(select(fromRoot.getSelectedExhibitionId))
        ])
        .subscribe(() => {
          this.isCookiePopupEnabled = false;
        })
      );

      this._subscriptions.add(
        this._store
          .pipe(
            select(fromRoot.getTranslations),
            filter(translation => !!translation)
          )
          .subscribe(translation => {
            if (!this.isLoggedInAsAdmin) {
              let cookiePopupText = translation.values['cookies.popup.text'];

              if (!cookiePopupText) {
                this._translateService
                  .get('cookies.popup.text')
                  .subscribe(defaultCookiePopupText => {
                    cookiePopupText = defaultCookiePopupText;
                  });
              }

              this.isCookiePopupEnabled = !!cookiePopupText ? !cookiePopupText.startsWith('-notshown-') : false;

              if (this.isCookiePopupEnabled) {
                const linkTextOnly = cookiePopupText.match(/<.*?>/);

                if (linkTextOnly) {
                  const link = `<a href="#" target="_blank">${linkTextOnly[0].slice(
                    1,
                    -1
                  )}</a>`;
                  this.textWithLink = cookiePopupText.replace(
                    linkTextOnly[0],
                    link
                  );
                } else {
                  this.textWithLink = cookiePopupText;
                }
              }
            }
          })
      );
    }
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
