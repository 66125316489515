import * as fromRoot from '../../../app.reducer';
import * as helperActions from '../helpers/helper.actions';
import * as ticketActions from './ticket.actions';

import { Action, select, Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  AddTicketBookingModel,
  TicketHolderModel,
  TicketModel,
  TicketVoucherModel,
  ItemType,
  ProductGroupModel,
  PackageModel,
  SectionGroup,
  TicketGroupTicketModel,
  TicketByIdModel,
  PackageContentModel,
  PackageTicketTypeGroupModel,
} from './ticket.interface';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { EMPTY, Observable, of, combineLatest } from 'rxjs';
import {
  catchError,
  debounceTime,
  first,
  map,
  mergeMap,
  switchMap
} from 'rxjs/operators';

import { CustomizationService } from '../customization/customization.service';
import { HelperService } from '../helpers/helper.service';
import { Location } from '@angular/common';
import { QuestionnaireDataSection } from '../customization/customization.interfaces';
import { StatusBarService } from '../../../status-bar/status-bar.service';
import { FormsService } from './../../forms/forms.service';
import { TicketsService } from './tickets.service';
import { TranslateService } from '@ngx-translate/core';
import * as customizationActions from '../customization/customization.actions';
import _ from 'lodash';

export const USER_DEBOUNCE = new InjectionToken<number>('User Debounce');

@Injectable()
export class TicketEffect {

  @Effect()
  loadTicketTypes$: Observable<Action> = this.actions$.pipe(
    ofType<ticketActions.GetTicketsTypesAction>(
      ticketActions.ActionTypes.GET_TICKETS_TYPES
    ),
    switchMap((data: any) => {
      const eventId = data.payload.eventId;
      if (eventId === '') {
        return Observable.empty();
      }

      return this._ticketsService
        .getTicketTypes(
          eventId,
          data.payload.preferedTicketPersonNr,
          data.payload.preferedTicketGroupNr
        )
        .pipe(
          map((sectionGroups: SectionGroup[]) => {
            /*  normally we would just:
              return new userActions.SetTicketsTypesAction(ticketTypes);

              but as we also want to set in redux a flat structure with all tickets without groups,
              the new structer will be an object with ids of tickets as keys
           */

            let packageIndex: number = 0;
            const ticketTypes: ProductGroupModel[] = [];
            const flatTickets: TicketModel = {};

            sectionGroups.forEach((sectionGroup, sectionIndex) => {
              const section = {
                sectionIndex,
                sectionId: sectionGroup.groupId,
                sectionName: sectionGroup.groupName,
                expanded: sectionGroup.expanded,
                groupDescription: sectionGroup.groupDescription
              }

              sectionGroup.items.forEach(product => {
                  const productGroup: ProductGroupModel = {
                    groupId: 0,
                    groupName: null,
                    packageName: null,
                    products: [],
                    preferredTicketType: false,
                    section
                  };

                  switch (product.itemType) {
                    case ItemType.Tariff:
                      const ticketType = product.ticketType;
                      const voucherProductGroup: ProductGroupModel = {
                        groupId: ticketType.ticketTypeId,
                        groupName: ticketType.ticketTypeName,
                        packageName: null,
                        products: [],
                        preferredTicketType: ticketType.preferredTicketType,
                        section: {...section, sectionIndex: -1}
                      };

                      productGroup.groupId = ticketType.ticketTypeId;
                      productGroup.groupName = ticketType.ticketTypeName;
                      productGroup.preferredTicketType = ticketType.preferredTicketType;

                      ticketType.tickets.forEach(ticket => {
                        if (!!ticket.validFrom) {
                          ticket.validFrom = new Date(ticket.validFrom + (!ticket.validFrom.toString().endsWith('Z') ? 'Z' : ''));
                        }
                        
                        if (!!ticket.validTill) {
                          ticket.validTill = new Date(ticket.validTill + (!ticket.validTill.toString().endsWith('Z') ? 'Z' : ''));
                        }
                        
                        if (ticket.isVoucher) {
                          voucherProductGroup.products.push({ ticket, package: null, article: null });
                        } else {
                          productGroup.products.push({ ticket, package: null, article: null });
                        }
                      });                                       
                      
                      if (productGroup.products.length) {
                        ticketTypes.push(productGroup);
                      }

                      if (voucherProductGroup.products.length) {
                        ticketTypes.push(voucherProductGroup);
                      }
                      break;
                    case ItemType.Package:
                      const currentPackage = product.package;
                      productGroup.packageName = currentPackage.info;               
                      
                      const newPackage: PackageModel = {
                        packageNumber: currentPackage.packageNumber,
                        name: currentPackage.name,
                        tariff: currentPackage.tariff,
                        variablePrice: currentPackage.variablePrice,
                        packageTariffSheetNumber: currentPackage.packageTariffSheetNumber,
                        packageTariffSheetValidNumber: currentPackage.packageTariffSheetValidNumber,
                        info: currentPackage.info,
                        contents: [],
                        count: 0,
                        total: 0,
                        isDisabled: false
                      }
                      
                      const packageContent: PackageContentModel = {
                        packageIndex,
                        packageGroups: [],
                        total: 0
                      }

                      currentPackage.items.forEach(item => {         
                        if (item.itemType == ItemType.Tariff) {
                          const ticketType = item.ticketType;                 
                          const packageVoucherProductGroup: ProductGroupModel = {
                            groupId: item.ticketType.ticketTypeId,
                            groupName: item.ticketType.ticketTypeName,
                            packageName: null,
                            products: [],
                            preferredTicketType: ticketType.preferredTicketType,
                            section: {...section, sectionIndex: -1}
                          };
                          
                          ticketType.tickets.forEach(ticket => {
                            ticket.ticketTypeId = ticketType.ticketTypeId;

                            if (!!ticket.validFrom) {
                              ticket.validFrom = new Date(ticket.validFrom + (!ticket.validFrom.toString().endsWith('Z') ? 'Z' : ''));
                            }
          
                            if (!!ticket.validTill) {
                              ticket.validTill = new Date(ticket.validTill + (!ticket.validTill.toString().endsWith('Z') ? 'Z' : ''));
                            }

                            if (ticket.isVoucher) {
                              packageVoucherProductGroup.products.push({ ticket, package: null, article: null });
                            } else {
                              const currentPackageGroup = packageContent.packageGroups.find(group => group.ticketTypeId == ticket.ticketTypeId);
                              if (!!currentPackageGroup) {
                                currentPackageGroup.products.push({ ticket, package: null, article: null })
                              } else {
                                const packageGroup: PackageTicketTypeGroupModel = {
                                  ticketTypeId: ticketType.ticketTypeId,
                                  ticketTypeName: ticketType.ticketTypeName,
                                  products: []
                                }
  
                                packageGroup.products.push({ ticket, package: null, article: null });
                                packageContent.packageGroups.push(packageGroup);
                              }
                            }
                          });

                          if (packageVoucherProductGroup.products.length) {
                            ticketTypes.push(packageVoucherProductGroup);
                          }
                        }
                      });
                      
                      if (packageContent.packageGroups.length) {
                        newPackage.contents.push(packageContent);
                        productGroup.products.push({ ticket: null, package: newPackage, article: null });
                        ticketTypes.push(productGroup);
                        packageIndex++;
                      }
                      break;
                  }
              });
            });
            
            ticketTypes.forEach(productGroup => {
              productGroup.products.forEach(product => {
                if (product.ticket != null) {
                  const ticket = product.ticket;
                  
                  if (this._helperService.isSelfregistration()) {
                    ticket.allowedWorkshops = [];
                  }

                  ticket.uniqueId = `${productGroup.groupId}_${ticket.personTypeId}`;
                  ticket.shouldCalendarBeDisplayed = ticket.hasDaySellLimit && ticket.tageOffset === 31;

                  flatTickets[ticket.uniqueId] = this.mapTicket(ticket, productGroup.groupName, productGroup.groupId);
  
                  if (ticket.classification === 'parking') {
                    flatTickets[ticket.uniqueId]['parking'] = [];
                  }
  
                  if (ticket.hasDaySellLimit) {
                    flatTickets[ticket.uniqueId]['days'] = [];
                  }
                } else if (product.package != null) {
                  const currentPackage = product.package;

                  if (currentPackage.contents.length) {
                    currentPackage.contents.forEach(packageContent => {
                      packageContent.packageGroups.forEach(packageGroup => {
                        if (packageGroup.products.length) {
                          packageGroup.products.forEach(packageProduct => {                 
                            if (packageProduct.ticket != null) {
                              const packageTicket = packageProduct.ticket;
                              packageTicket.uniqueId = `${packageTicket.ticketTypeId}_${packageTicket.personTypeId}_PN${currentPackage.packageNumber}_PI${packageContent.packageIndex}`;                 
                              packageTicket.shouldCalendarBeDisplayed = packageTicket.hasDaySellLimit && packageTicket.tageOffset === 31;                       

                              flatTickets[packageTicket.uniqueId] = this.mapTicket(packageTicket, packageGroup.ticketTypeName, packageGroup.ticketTypeId);
                              flatTickets[packageTicket.uniqueId].packageNumber = currentPackage.packageNumber;
                              flatTickets[packageTicket.uniqueId].packageIndex = packageContent.packageIndex;
              
                              if (packageTicket.classification === 'parking') {
                                flatTickets[packageTicket.uniqueId]['parking'] = [];
                              }
              
                              if (packageTicket.hasDaySellLimit) {
                                flatTickets[packageTicket.uniqueId]['days'] = [];
                              }
                            }
                          });
                        }
                      });
                    });
                  }
                }
              });
            });
    
            if (!!data.payload.preferedTicketGroupNr) {
              if (!!data.payload.preferedTicketPersonNr) {
                const ticketUniqueId = `${data.payload.preferedTicketGroupNr}_${data.payload.preferedTicketPersonNr}`;
                
                if (!!flatTickets[ticketUniqueId] && flatTickets[ticketUniqueId].isVisible) {
                  flatTickets[ticketUniqueId].preferredTicket = true;
                }
              }
              
              this._helperService.setIsReloadSections(true);
            }

            this._store.dispatch(new ticketActions.SetLastPackageIndex(packageIndex - 1));
            this._store.dispatch(new ticketActions.SetTickets(flatTickets));
            return new ticketActions.SetTicketsTypesAction(ticketTypes);
          }),
          catchError(error => {
            console.log(error);
            return of(new ticketActions.SetTicketsTypesAction([]));
          })
        );
    })
  );

  @Effect()
  postTicketHolderQuestionnaire$: Observable<Action> = this.actions$.pipe(
    ofType<ticketActions.PostTicketHolderQuestionnaire>(
      ticketActions.ActionTypes.POST_TICKET_HOLDER_QUESTIONNAIRE
    ),
    debounceTime(this.debounce),
    switchMap((data: any) => {
      if (!data.payload) {
        return Observable.empty();
      }

      const { questionnaire, userId, eventId } = data.payload;

      return this._ticketsService
        .saveTicketHolderQuestionnaire(questionnaire, userId, eventId)
        .pipe(
          map(
            (code: number) =>
              new helperActions.SetApiCallResult({
                code,
                message: 'post ticketholder questionnaire success'
              })
          ),
          catchError(() =>
            of(
              new helperActions.SetApiCallResult({
                code: 0,
                message: 'post ticketholder questionnaire fail'
              })
            )
          )
        );
    })
  );

  @Effect()
  getClaimingTicketHolder$: Observable<Action> = this.actions$.pipe(
    ofType<ticketActions.LoadTicketHolder>(
      ticketActions.ActionTypes.LOAD_CLAIMING_TICKET_HOLDER
    ),
    debounceTime(this.debounce),
    switchMap((data: any) => {
      const hash = data.payload;
      if (!hash) {
        return Observable.empty();
      }

      return this._ticketsService.getTicketByHolder(hash).pipe(
        map((ticketHolder: TicketHolderModel) => {
          // if we load the user
          if (ticketHolder.hasOwnProperty('id')) {
            this._store.dispatch(
              new ticketActions.SetTicketClaimedHashValid(true)
            );
            return new ticketActions.SetTicketHolder(ticketHolder);
          } else {
            // else if the has is not valid, we need to give some feedback
            this._store.dispatch(
              new ticketActions.SetTicketClaimedHashValid(false)
            );

            return new ticketActions.SetTicketHolder(null);
          }
        }),
        catchError(() => {
          this._store.dispatch(
            new ticketActions.SetTicketClaimedHashValid(false)
          );
          return of(new ticketActions.SetTicketHolder(null));
        })
      );
    })
  );

  @Effect()
  downloadTicket$: Observable<Action> = this.actions$.pipe(
    ofType<ticketActions.DownloadTicket>(
      ticketActions.ActionTypes.DOWNLOAD_TICKET
    ),
    debounceTime(this.debounce),
    switchMap((data: any) => {
      const hash = data.payload;
      if (!hash) {
        return Observable.empty();
      }

      return this._ticketsService.downloadTicket(hash).pipe(
        map((blob: any) => {
          this._helperService.saveToFileSystem(blob, 'ticket.pdf');

          // if we load the user
          return new ticketActions.SetTicketHolder(null);
        }),
        catchError(() => {
          return of(new ticketActions.SetTicketHolder(null));
        })
      );
    })
  );

  @Effect()
  downloadMobileTicket$: Observable<Action> = this.actions$.pipe(
    ofType<ticketActions.DownloadMobileTicket>(
      ticketActions.ActionTypes.DOWNLOAD_MOBILE_TICKET
    ),
    debounceTime(this.debounce),
    switchMap((data: any) => {
      const hash = data.payload;
      if (!hash) {
        return Observable.empty();
      }

      return this._ticketsService.downloadMobileTicket(hash).pipe(
        map((blob: any) => {
          this._helperService.saveToFileSystem(blob, 'MobileTicket.pdf');

          // if we load the user
          return new ticketActions.SetTicketHolder(null);
        }),
        catchError(() => {
          return of(new ticketActions.SetTicketHolder(null));
        })
      );
    })
  );

  @Effect()
  downloadPassBook$: Observable<Action> = this.actions$.pipe(
    ofType<ticketActions.DownloadPassBook>(
      ticketActions.ActionTypes.DOWNLOAD_PASS_BOOK
    ),
    debounceTime(this.debounce),
    switchMap((data: any) => {
      const hash = data.payload;
      if (!hash) {
        return Observable.empty();
      }

      return this._ticketsService.downloadPassBook(hash).pipe(
        map((blob: any) => {
          this._helperService.saveToFileSystem(blob, 'Passbook.pkpass');

          // if we load the user
          return new ticketActions.SetTicketHolder(null);
        }),
        catchError(() => {
          return of(new ticketActions.SetTicketHolder(null));
        })
      );
    })
  );

  @Effect()
  ticketHolderQuestionnaire$: Observable<Action> = this.actions$.pipe(
    ofType<ticketActions.GetTicketHolderQuestionnaire>(
      ticketActions.ActionTypes.GET_TICKET_HOLDER_QUESTIONNAIRE
    ),
    debounceTime(this.debounce),
    switchMap((data: any) => {
      const eventId = data.payload.eventId;
      const ticketPersonIds = data.payload.ticketPersonIds;

      if (eventId === null) {
        return Observable.empty();
      }

      return this._ticketsService
        .getTicketHolderQuestionnaire(eventId, ticketPersonIds)
        .pipe(
          map((ticketHolderQuestionnaire: QuestionnaireDataSection[]) => {
            const questionnaireInputs = this._customizationService.tansformQuestionnaireIntoInput(
              ticketHolderQuestionnaire
            );

            return new ticketActions.SetTicketHolderQuestionnaire(
              questionnaireInputs
            );
          }),
          catchError(() =>
            of(new ticketActions.SetTicketHolderQuestionnaire([]))
          )
        );
    })
  );

  @Effect()
  postTicketHolderForm$: Observable<Action> = this.actions$.pipe(
    ofType<ticketActions.PostTicketHolderForm>(
      ticketActions.ActionTypes.POST_TICKET_HOLDER_FORM
    ),
    debounceTime(this.debounce),
    switchMap((data: any) => {
      // TODO: fix interface VisitorFormModel
      if (data.payload === null) {
        return Observable.empty();
      }

      return this._ticketsService.postTicketHolderForm(data.payload).pipe(
        map(() => {
          return new ticketActions.SetPostTicketHolderFormResult(true);
        }),
        catchError(() =>
          of(new ticketActions.SetPostTicketHolderFormResult(false))
        )
      );
    })
  );

  @Effect()
  loadVoucher$: Observable<Action> = this.actions$.pipe(
    ofType<ticketActions.GetVoucher>(ticketActions.ActionTypes.GET_VOUCHER),
    debounceTime(this.debounce),
    switchMap((data: any) => {
      const { redeemVoucher, releaseVouchers } = data.payload;
      if (!redeemVoucher) {
        return Observable.empty();
      }

      return this._ticketsService.getVoucherTicket(redeemVoucher).pipe(
        map((voucherTicketRaw: any) => {
          const { groupId, ticketTypeId, voucherCode, ticketCount } = voucherTicketRaw;
          //Create ticketUniqueId for voucher ticket, and set count to the ticket count returned from the API redeem-voucher
          voucherTicketRaw.ticketUniqueId = `${groupId}_${ticketTypeId}_${voucherCode}`;
          voucherTicketRaw.count = ticketCount;

          //Add voucher to ticket booking
          this._store.dispatch(new ticketActions.AddTicketBooking(voucherTicketRaw));

          releaseVouchers.forEach(releaseVoucher => {
            this._store.dispatch(
              new ticketActions.ReleaseVoucher({ ...releaseVoucher, skipAnonymousChecks: true })
            );
          });

          /* REMOVE FOLLOWING ONCE CORRECT DATA ARE COMMING FROM API */
          if (voucherTicketRaw.hasOwnProperty('afterSale')) {
            voucherTicketRaw.price = voucherTicketRaw.afterSale;
          }

          delete voucherTicketRaw.sortNr;
          delete voucherTicketRaw.voucherMaxUsings;
          delete voucherTicketRaw.voucherMaxUsedNumbers;
          delete voucherTicketRaw.ticketToOwner;
          delete voucherTicketRaw.asMobileTicket;
          delete voucherTicketRaw.ticketHolder;
          delete voucherTicketRaw.ticketSendingMode;
          delete voucherTicketRaw.bocUId;
          delete voucherTicketRaw.unicodeNr;
          delete voucherTicketRaw.posNr;
          delete voucherTicketRaw.serialNr;
          delete voucherTicketRaw.validFrom;
          delete voucherTicketRaw.validTill;

          /* END: REMOVE FOLLOWING ONCE CORRECT DATA ARE COMMING FROM API */

          const voucherTicket = voucherTicketRaw as TicketVoucherModel;

          if (
            voucherTicket.hasOwnProperty('allowedWorkshopsFull') &&
            voucherTicket.allowedWorkshopsFull
          ) {
            this._translateService
              .get('voucher.workshops.soldout')
              .subscribe((translation: string) => {
                this._statusBarService.setStatus(translation, 'error');
              });
            return new ticketActions.AddVoucherTicket(null);
          }

          // transform JSON result to object with keys as ticketGroup Ids and values as ticketSales

          // set timestamp when the voucher was activated
          voucherTicket.activatedTimestamp = Date.now();

          // set unique ticketId

          voucherTicket.uniqueId = `${groupId}_${ticketTypeId}`;

          this._store.dispatch(new customizationActions.SetShoppingStartTime());

          return new ticketActions.AddVoucherTicket({
            voucherTicket: voucherTicket,
            selfRegistration: this._helperService.isSelfregistration()
          });
        }),
        catchError(error => {
          console.log(error);
          return of(new ticketActions.AddVoucherTicket(null));
        })
      );
    })
  );

  @Effect()
  releaseVoucher$: Observable<Action> = this.actions$.pipe(
    ofType<ticketActions.ReleaseVoucher>(
      ticketActions.ActionTypes.RELEASE_VOUCHER
    ),
    mergeMap((data: any) => {
      const voucherRedeemData = data.payload;

      if (!voucherRedeemData) {
        return Observable.empty();
      }

      return this._ticketsService.releaseVoucherCode(voucherRedeemData).pipe(
        map((success: boolean) => {
          // transform JSON result to object with keys as ticketGroup Ids and values as ticketSales
          return new ticketActions.RemoveVoucher({ voucherCode: voucherRedeemData.voucherCode, skipAnonymousChecks: voucherRedeemData.skipAnonymousChecks });
        }),
        catchError(error => {
          console.log(error);
          return of(new ticketActions.RemoveVoucher(null));
        })
      );
    })
  );

  @Effect()
  postTicketBooking$: Observable<Action> = this.actions$.pipe(
    ofType<ticketActions.PostTicketBooking>(
      ticketActions.ActionTypes.POST_TICKET_BOOKING
    ),
    mergeMap(data => {
      if (!data.payload) {
        return Observable.empty();
      }
      return this._ticketsService
        .postTicketBooking(data.payload.postTicketBooking)
        .map((bookings: AddTicketBookingModel) => {
          bookings.count = data.payload.postTicketBooking.count - data.payload.postTicketBooking.sharedLimitTicketCount;
          bookings.ticketUniqueId = data.payload.postTicketBooking.uniqueId;
          bookings.ticketLimit = data.payload.ticketLimit;

          // if availableTickets is not set = null we never set ticket to be sold out
          if (bookings.availableTickets === null) {
            bookings.isTicketSold = false;
          } else {
            bookings.isTicketSold = bookings.availableTickets ? false : true;
          }

          data.payload.successCallback();

          return new ticketActions.AddTicketBooking(bookings);
        })
        .catch(error => {
          console.log(error);
          const ticketAlreadySold: AddTicketBookingModel = {
            groupId: data.payload.postTicketBooking.groupId,
            ticketTypeId: data.payload.postTicketBooking.ticketTypeId,
            ticketPersonId: data.payload.postTicketBooking.ticketPersonId,
            ticketUniqueId: data.payload.postTicketBooking.uniqueId,
            availableTickets: 0,
            count: 0,
            isTicketSold: true,
            ticketLimit: data.payload.ticketLimit
          };
          return of(new ticketActions.AddTicketBooking(ticketAlreadySold));
        });
    })
  );

  @Effect()
  setTicketIndex$: Observable<Action> = this.actions$.pipe(
    ofType<ticketActions.SetTicketHolderIndex>(
      ticketActions.ActionTypes.ADD_TICKET_HOLDER_INDEXES,
      ticketActions.ActionTypes.SET_TICKET_HOLDER_INDEX
    ),
    first(),
    switchMap(() => {
      combineLatest([
        this._store.pipe(select(fromRoot.getTicketHolderIndexes)),
        this._store.pipe(select(fromRoot.getTickets))
      ])
        .filter(([ticketHolderIndexes, tickets]) => {
          if (ticketHolderIndexes == null) {
            return false;
          }

          for (const ticketIndex in ticketHolderIndexes) {
            if (ticketHolderIndexes[ticketIndex] == '') {
              return false;
            }
          }

          for (const uniqueId in tickets) {
            const numberOfIndexes = Object.keys(ticketHolderIndexes).filter(index => ticketHolderIndexes[index] === uniqueId).length;
            if (numberOfIndexes == 0) {
              continue;
            }

            if (tickets[uniqueId].count != numberOfIndexes) {
              return false;
            }
          }

          return true;
        })
        .debounceTime(this.debounce)
        .subscribe(([ticketHolderIndexes, tickets]) => {
          this._store.pipe(select(fromRoot.getTicketHolderInputSets))
            .subscribe((ticketHolderInputSets) => {
              const newFlatTickets = {...tickets};
              const ticketUniqueIds = Object.keys(newFlatTickets);

              for (let i = 0; i < ticketUniqueIds.length;) {
                const uniqueId = ticketUniqueIds[i];
                newFlatTickets[uniqueId].holdersIndexes = Object.keys(ticketHolderIndexes)
                  .filter(index => ticketHolderIndexes[index] === uniqueId)
                  .map(index => +index);

                for (const ticketIndex of newFlatTickets[uniqueId].holdersIndexes) {
                  setTimeout(() => {
                    if (!ticketHolderInputSets) {
                      this._ticketsService.addTicketHolder(ticketIndex);
                    } else {
                      const hasTicketInputSet = ticketHolderInputSets.some(ticketHolderInputSet => ticketHolderInputSet.index == ticketIndex);
                      if (!hasTicketInputSet) {
                        this._ticketsService.addTicketHolder(ticketIndex);
                      }
                    }
                  }, 0);
                }
                
                i++;
              }

              this._formsService.setFormValidity(true, null, ['tickets', 'packageLoading']);
              this._store.dispatch(new ticketActions.SetIsPackageLoading(false));
              return of(new ticketActions.SetTickets(newFlatTickets));
            })
            .unsubscribe();
          });

      return EMPTY;
    })
  );

  @Effect()
  removeTicketIndex$: Observable<Action> = this.actions$.pipe(
    ofType<ticketActions.RemoveTicketHolderIndex>(
      ticketActions.ActionTypes.REMOVE_TICKET_HOLDER_INDEX
    ),
    first(),
    switchMap(() => {
      this._store.pipe(select(fromRoot.getTicketHolderIndexes))
        .debounceTime(this.debounce)
        .subscribe(() => {
          this._store.dispatch(new ticketActions.SetIsPackageLoading(false));
        });

      return EMPTY;
    }))

  constructor(
    private actions$: Actions,
    private _ticketsService: TicketsService,
    private _formsService: FormsService,
    private _location: Location,
    @Optional()
    @Inject(USER_DEBOUNCE)
    private debounce: number = 50,
    private _customizationService: CustomizationService,
    private _store: Store<fromRoot.State>,
    private _helperService: HelperService,
    private _statusBarService: StatusBarService,
    private _translateService: TranslateService
  ) { }

  mapTicket(ticket: TicketGroupTicketModel, groupName: string, groupId: number): TicketByIdModel {
    return {
      id: ticket.personTypeId,
      uniqueId: ticket.uniqueId,
      holdersIndexes: [],
      name: ticket.ticketName,
      groupName: groupName,
      groupId: groupId,
      count: 0,
      price: ticket.price,
      requiresLegitimation: ticket.requiresLegitimation,
      allowedWorkshops: ticket.allowedWorkshops,
      allowedWorkshopsFull: ticket.allowedWorkshopsFull,
      ticketTypeOrder: ticket.ticketTypeOrder,
      availableTickets: ticket.availableTickets,
      ticketLimit: ticket.ticketLimit,
      info: ticket.info,
      infoExpanded: ticket.infoExpanded,
      classification: ticket.classification,
      ticketPersonId: ticket.ticketPersonId,
      isVisible: ticket.isVisible,
      isVoucher: ticket.isVoucher,
      workshopsByDay: ticket.workshopsByDay,
      hasDaySellLimit: ticket.hasDaySellLimit,
      durationInDays: ticket.durationInDays,
      personalizationType: ticket.personalizationType,
      validFrom: ticket.validFrom,
      validTill: ticket.validTill,
      shouldCalendarBeDisplayed: ticket.shouldCalendarBeDisplayed,
      tageOffset: ticket.tageOffset,
      packageSettings: ticket.packageSettings,
    } 
  }
}
