import * as fromRoot from '../../app.reducer';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
var MissingTranslationService = /** @class */ (function () {
    function MissingTranslationService(_store) {
        this._store = _store;
    }
    MissingTranslationService.prototype.getLanguage = function () {
        var activeLanguage;
        this._store
            .select(fromRoot.getLanguage)
            .pipe(filter(function (data) { return !!data; }))
            .subscribe(function (language) {
            activeLanguage = language;
        });
        return activeLanguage;
    };
    MissingTranslationService.prototype.getSupportedLanguages = function () {
        var supportedLanguages;
        this._store
            .select(fromRoot.getSupportedLanguages)
            .pipe(filter(function (languages) { return languages && languages.length > 0; }))
            .subscribe(function (languages) {
            supportedLanguages = languages;
        });
        return supportedLanguages;
    };
    MissingTranslationService.ngInjectableDef = i0.defineInjectable({ factory: function MissingTranslationService_Factory() { return new MissingTranslationService(i0.inject(i1.Store)); }, token: MissingTranslationService, providedIn: "root" });
    return MissingTranslationService;
}());
export { MissingTranslationService };
