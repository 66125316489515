/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./workshop-visitors-count.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "../../../shared/pipes/hide-translation/hide-translation.pipe";
import * as i5 from "./workshop-visitors-count.component";
import * as i6 from "@ngrx/store";
var styles_WorkshopVisitorsCountComponent = [i0.styles];
var RenderType_WorkshopVisitorsCountComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WorkshopVisitorsCountComponent, data: {} });
export { RenderType_WorkshopVisitorsCountComponent as RenderType_WorkshopVisitorsCountComponent };
function View_WorkshopVisitorsCountComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [["class", "availability-text"], ["data-translation", "workshop.sold"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(3, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("workshop.sold")))); _ck(_v, 1, 0, currVal_0); }); }
function View_WorkshopVisitorsCountComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " ", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(3, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.workshopSeats; var currVal_1 = i1.ɵunv(_v, 1, 1, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent, 0), i1.ɵunv(_v, 1, 1, i1.ɵnov(_v, 2).transform("workshop.available")))); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_WorkshopVisitorsCountComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "availability-text"], ["data-translation", "workshop.available"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WorkshopVisitorsCountComponent_3)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showAmountOfAvailableWorkshops; _ck(_v, 2, 0, currVal_0); }, null); }
function View_WorkshopVisitorsCountComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [["class", "availability-text"], ["data-translation", "workshop.not-accessible"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(3, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("workshop.not-accessible")))); _ck(_v, 1, 0, currVal_0); }); }
function View_WorkshopVisitorsCountComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "visitors-count"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.noOfMyAssignedVisitors; _ck(_v, 2, 0, currVal_0); }); }
export function View_WorkshopVisitorsCountComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.HideTranslationPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "workshop-available"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "sold-out": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WorkshopVisitorsCountComponent_1)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WorkshopVisitorsCountComponent_2)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WorkshopVisitorsCountComponent_4)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WorkshopVisitorsCountComponent_5)), i1.ɵdid(11, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "workshop-available"; var currVal_1 = _ck(_v, 3, 0, ((_co.noSeatsLeft && !_co.lastSeatWithSingleAssignedTicket) || _co.workshop.disabled)); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = (_co.noSeatsLeft && !_co.lastSeatWithSingleAssignedTicket); _ck(_v, 5, 0, currVal_2); var currVal_3 = ((!_co.noSeatsLeft && !_co.workshop.disabled) || (_co.noSeatsLeft && _co.lastSeatWithSingleAssignedTicket)); _ck(_v, 7, 0, currVal_3); var currVal_4 = ((!_co.noSeatsLeft || (_co.noSeatsLeft && _co.lastSeatWithSingleAssignedTicket)) && _co.workshop.disabled); _ck(_v, 9, 0, currVal_4); var currVal_5 = _co.noOfMyAssignedVisitors; _ck(_v, 11, 0, currVal_5); }, null); }
export function View_WorkshopVisitorsCountComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-workshop-visitors-count", [], null, null, null, View_WorkshopVisitorsCountComponent_0, RenderType_WorkshopVisitorsCountComponent)), i1.ɵdid(1, 245760, null, 0, i5.WorkshopVisitorsCountComponent, [i6.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WorkshopVisitorsCountComponentNgFactory = i1.ɵccf("app-workshop-visitors-count", i5.WorkshopVisitorsCountComponent, View_WorkshopVisitorsCountComponent_Host_0, { workshopBookings: "workshopBookings", workshop: "workshop", ticketsWithHolders: "ticketsWithHolders", showAmountOfAvailableWorkshops: "showAmountOfAvailableWorkshops" }, {}, []);
export { WorkshopVisitorsCountComponentNgFactory as WorkshopVisitorsCountComponentNgFactory };
