var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { DateTime } from 'luxon';
import * as additionalServicesActions from '../../shared/services-with-reducers/additional-services/additional-services.actions';
import * as fromRoot from '../../app.reducer';
import * as stepsActions from '../../shared/services-with-reducers/step-forms/steps-forms.actions';
import * as stepsFormsActions from '../../shared/services-with-reducers/step-forms/steps-forms.actions';
import * as ticketActions from '../../shared/services-with-reducers/tickets/ticket.actions';
import * as legitimationActions from '../../shared/services-with-reducers/legitimation/legitimation.actions';
import { getLocalStorageObject, getLocalStorageString, highlightDay, setLocalStorageObject } from '../../../app/shared/app-utils';
import { OnDestroy, OnInit, AfterViewInit, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subscription, combineLatest as observableCombineLatest, Subject, BehaviorSubject, } from 'rxjs';
import { filter, first, skip, delay } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AppConstants } from '../../shared/app-constants';
import { FormsService } from '../../shared/forms/forms.service';
import { HelperService } from '../../shared/services-with-reducers/helpers/helper.service';
import { select, Store } from '@ngrx/store';
import { TicketsService } from '../../shared/services-with-reducers/tickets/tickets.service';
import { TranslateService } from '@ngx-translate/core';
import { getQueryParamsFromLocation } from '../../../app/shared/app-utils';
import { Router, ActivatedRoute } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { ActionTypes } from '../../shared/services-with-reducers/tickets/ticket.actions';
import { ValidityModel, VisibilityPayloadModel } from '../../shared/services-with-reducers/step-forms/step.interface';
import { StepsFormsService } from '../../shared/services-with-reducers/step-forms/steps-forms.service';
import { CustomizationService } from '../../shared/services-with-reducers/customization/customization.service';
import { GtmService } from '../../shared/gtm/gtmService';
import { PackagesService } from './../../shared/services-with-reducers/tickets/packages.service';
var WebShopTicketsComponent = /** @class */ (function () {
    function WebShopTicketsComponent(_router, _route, _store, _helperService, _formsService, _ticketsService, _packagesService, _translateService, fb, _actions$, _stepsFormsService, _customizationService, _gtmService, cdr) {
        var _this = this;
        this._router = _router;
        this._route = _route;
        this._store = _store;
        this._helperService = _helperService;
        this._formsService = _formsService;
        this._ticketsService = _ticketsService;
        this._packagesService = _packagesService;
        this._translateService = _translateService;
        this.fb = fb;
        this._actions$ = _actions$;
        this._stepsFormsService = _stepsFormsService;
        this._customizationService = _customizationService;
        this._gtmService = _gtmService;
        this.cdr = cdr;
        this.registrationFormsActionName = ['tickets', 'registration'];
        this.clearVoucherInput$ = new Subject();
        this.activeLang = 'en';
        this.verifiedAccountRequired = false;
        this.isAccountVerified = false;
        this.isMobile = false;
        this.exhibition = {};
        this.totalPrice = 0;
        this.totalParkingPrice = new Subject();
        this.totalParkingPriceAmount = 0;
        this.totalPriceWithOutParking = 0;
        this.numberOfSelectedTickets = 0;
        this.numberOfSelectedLimitedVouchers = 0;
        this.numberOfSelectedVouchers = 0;
        this.numberOfSelectedOneTimeVouchers = 0;
        this.maxTicketLimit = 0;
        this.maxVoucherLimit = 0;
        this.maxLimitedVoucherLimit = 0;
        this.AppConstants = AppConstants;
        this.hasAnonymous = false;
        this.environment = environment;
        this.ticketsWithHolders = new BehaviorSubject([]);
        this.showAmountOfAvailableWorkshops = true;
        this.hideWorkshopDate = true;
        this.enableWorkshopSelectionOverlapping = false;
        this.workshopModalWindowYOffset = 0;
        this.totalWorkshopPrice = 0;
        this.highlightDay = highlightDay;
        this.limitWorkshopPerTicket = 1;
        this.hideDateAndTime = false;
        this.ticketWithNoWorkshopsTaken = [];
        this.invalidParkingTickets = [];
        this.invalidContingentTickets = [];
        this.visitorQuestionnaireValidation = ['personal', 'visitorQuestionnaire'];
        // used for ticktes structure (group with tickets)
        this.productGroups = null;
        this.sections = [];
        this.contingentTicketsForm = this.fb.group({});
        this.isParkingTicketLoading = false;
        this.parkingTicketsForm = this.fb.group({});
        this.subscriptions = new Subscription();
        this.contingentSubscriptions = new Subscription();
        this.endDays = {};
        this.hasContingentForm = false;
        this.hasParkingForm = false;
        this.isVoucherAddedOrRemoved = false;
        this.hiddenTicketsAfterAnonymous = [];
        //step validation fields (ticket selection step is valid only if all these fields are valid):
        this.isTicketCounterValid = false;
        this.isParkingTicketsValid = false;
        this.isContingentsValid = false;
        this.isWorkshopsMandatoryValid = false;
        this.isWorkshopsZeroPriceMandatoryValid = false;
        this.hiddenSteps = [];
        this.needsTicketOverLimitCheck = true;
        this.translationsLoaded = false;
        this.packageMessageMobile = [];
        this.showPackageMessage = false;
        this.ticketUniqueIdMessage = '';
        /**
         * When counter changes and number of tickets is less than amount of additional FormGroups > remove those FormControls
         */
        this.removeAdditionalTicketFormControls = function (formWithAdditionalControls, ungroupedTicket, afterUpdateCallback) {
            var ticketCountArray = _this.getArrayFromTicketCount(ungroupedTicket.count);
            // get only those which belong to current ticket (e.g. Ticket_1234 > FormGroup_1)
            var formGroupNames = Object.keys(formWithAdditionalControls.controls).filter(function (key) { return _this.getUniqueTicketID(key) === ungroupedTicket.uniqueId; });
            // check if tickets were removed
            if (ticketCountArray.length < formGroupNames.length) {
                // how many were removed
                var difference_1 = formGroupNames.length - ticketCountArray.length;
                formGroupNames.reverse().forEach(function (formControlName, i) {
                    if (i + 1 <= difference_1) {
                        formWithAdditionalControls.removeControl(formControlName);
                    }
                });
                afterUpdateCallback && afterUpdateCallback(_this);
            }
        };
        this.origin = environment.protocol + environment.origin;
        this.origin = this.origin || window.location.origin;
        this.removeValidationFeedbacks();
        this.setValidation(false);
        this.isMobile = this._helperService.isMobile();
        if (!getLocalStorageObject(AppConstants.parkingTicketsReducer)) {
            setLocalStorageObject(AppConstants.parkingTicketsReducer, '');
        }
        if (!getLocalStorageObject(AppConstants.contingentTicketsReducer)) {
            setLocalStorageObject(AppConstants.contingentTicketsReducer, '');
        }
        this.subscriptions.add(this._store.pipe(select(fromRoot.getAnonymousHiddenSteps)).subscribe(function (data) {
            _this.hiddenSteps = data;
        }));
        this.subscriptions.add(this._store.pipe(select(fromRoot.getStepsValidity)).subscribe(function (data) {
            _this.stepsValidity = data;
        }));
        // when value changes store parking data to the storage
        this.subscriptions.add(this.parkingTicketsForm.valueChanges.subscribe(function (value) {
            var parsedParkingTickets = _this.getParkingTicketsFromLocalStorage();
            _this.invalidParkingTickets = [];
            Object.keys(_this.parkingTicketsForm.controls).forEach(function (key) {
                var splitedUniqueId = key.split('_');
                var uniqueId = splitedUniqueId[0] + "_" + splitedUniqueId[1];
                _this.invalidParkingTickets.push(uniqueId);
                // update storage with latest data related to parking ticket
                // TODO: REFACTOR!!! WE SHOULD NOT UPDATE FORM value BUT INSTEAD DIRECTLY parsedParkingTickets!!!
                if (parsedParkingTickets[key]) {
                    value[key]['price'] = parsedParkingTickets[key]['price'];
                    value[key]['name'] = "TicketPerson." + splitedUniqueId[1];
                    value[key]['groupName'] = "TicketGroup." + splitedUniqueId[0];
                    parsedParkingTickets[key] = value[key];
                }
            });
            setLocalStorageObject(AppConstants.parkingTicketsReducer, value);
            _this.setValidation(false, ['parking', '']);
        }));
        this.showLoginOnTicketPage$ = this._store.select(fromRoot.showLoginOnTicketPage);
        this.showLoginOnTicketAndPersonalPage$ = this._store.select(fromRoot.showLoginOnTicketAndPersonalPage);
        this.notLoggedAndLoginMandatory$ = this._store.select(fromRoot.notLoggedAndLoginMandatory);
        this.dateToday$ = this._store.select(fromRoot.getLocalTime);
        this.subscriptions.add(this._actions$
            .pipe(ofType(ActionTypes.ADD_VOUCHER_TICKET, ActionTypes.REMOVE_VOUCHER))
            .subscribe(function (data) {
            _this.isVoucherAddedOrRemoved = true;
            _this.loadSections();
        }));
        if (!this.ticketIdFromQueryParams) {
            //"tt" is "TicketGroup" ("768")
            //"pt" is "TicketPerson" ("121")
            this.queryParams = getQueryParamsFromLocation();
            this.queryParamsTicketGroup = this.queryParams["tt"];
            this.queryParamsTicketPerson = this.queryParams["pt"];
            if (this.queryParamsTicketGroup) {
                if (this.queryParamsTicketPerson) {
                    this.ticketIdFromQueryParams = this.queryParamsTicketGroup + "_" + this.queryParamsTicketPerson;
                    if (!this._helperService.isSelfregistration()) {
                        this.amount = +this.queryParams["amt"];
                        if (!(this.amount > 0)) {
                            this.amount = 1;
                        }
                    }
                }
                if (!this._helperService.isSelfregistration()) {
                    this._store.pipe(select(fromRoot.getSelectedExhibitionId)).first().subscribe(function (eventId) {
                        _this._store.dispatch(new ticketActions.GetTicketsTypesAction({
                            eventId: +eventId,
                            preferedTicketPersonNr: _this.queryParamsTicketPerson
                                ? Number(_this.queryParamsTicketPerson)
                                : null,
                            preferedTicketGroupNr: _this.queryParamsTicketGroup
                                ? Number(_this.queryParamsTicketGroup)
                                : null
                        }));
                    });
                }
            }
        }
        this.subscriptions.add(this._store.pipe(select(fromRoot.getTicketsTypes)).subscribe(function () {
            if (_this._helperService.getReloadSections()) {
                _this._ticketsService.removeHoldersAndBookings();
                _this.loadSections();
                _this._helperService.setIsReloadSections(false);
                var hasUngroupedTicket = !!_this.ungroupedTickets && !!_this.ticketIdFromQueryParams && !!_this.ungroupedTickets[_this.ticketIdFromQueryParams];
                var ticketIsVisible = hasUngroupedTicket && _this.ungroupedTickets[_this.ticketIdFromQueryParams].isVisible;
                if (_this._helperService.isSelfregistration() && ticketIsVisible) {
                    _this.selectTicket(_this.ungroupedTickets[_this.ticketIdFromQueryParams]);
                }
            }
        }));
        if (!this.queryParamsTicketGroup) {
            this.loadSections();
        }
        this.subscriptions.add(this.dateToday$.subscribe(function (date) {
            if (!date)
                return;
            var isoDate = _this.addHour(_this.addHour(date)).toISOString();
            _this.dateToday = new Date(isoDate.substring(0, isoDate.lastIndexOf(':')));
        }));
        this._store.select(fromRoot.getLanguage).subscribe(function (lang) {
            _this.activeLang = lang;
        });
        this.subscriptions.add(this.notLoggedAndLoginMandatory$.subscribe(function (notLogged) {
            _this._formsService.setFormValidity(!notLogged, null, [
                'tickets',
                'login'
            ]);
        }));
        this.subscriptions.add(this._store.select(fromRoot.getExhibitionSettings).subscribe(function (settings) {
            if (settings) {
                _this.exhibitionSettings = settings;
                _this.isWorkshopsSelectionMandatory = settings.isWorkshopsSelectionMandatory;
                _this.isWorkshopsSelectionMandatoryForZeroPriceTickets = settings.workshopMandatoryForZeroPriceTickets;
                _this.workshopsOnTicketSelection = settings.workshopsOnTicketSelection;
                _this.limitWorkshopPerTicket = settings.workshopsPerTicket;
                _this.maxTicketLimit = settings.limitBoughtTickets;
                _this.maxVoucherLimit = settings.limitPromoCodes;
                _this.maxLimitedVoucherLimit = settings.limitLimitedPromocodes;
                _this.enableWorkshopSelectionOverlapping =
                    settings.enableWorkshopSelectionOverlapping;
                _this.showAmountOfAvailableWorkshops =
                    settings.showAmountOfAvailableWorkshops;
                _this.hideWorkshopDate = settings.hideWorkshopDate;
                _this.needsTicketOverLimitCheck = settings.ticketLimitPerEmail > 0;
            }
        }));
        this.subscriptions.add(this._store
            .select(fromRoot.getWorkshops)
            .filter(function (data) { return !!data; })
            .subscribe(function (data) {
            _this.workshops = data;
        }));
        /**
         * handle visitors and their subscription to workshops
         * */
        this.subscriptions.add(observableCombineLatest([
            this._store.pipe(select(fromRoot.getTickets)),
            this._store.pipe(select(fromRoot.getTicketsTypes)),
            this._store.pipe(select(fromRoot.getTicketHolderInputSets)),
            this._store.pipe(select(fromRoot.getTicketHolderAdditionalData)),
            this._store.pipe(select(fromRoot.getTicketsCount))
        ])
            .pipe(filter(function (_a) {
            var ungroupedTickets = _a[0], ticketTypes = _a[1], ticketHolderInputSets = _a[2], ticketHolderAdditionalData = _a[3], ticketCount = _a[4];
            var ticketHolderInputSetsCount = ticketHolderInputSets.length;
            var ticketHolderAdditionalDataCount = Object.keys(ticketHolderAdditionalData).length;
            var isWorkshopDataValid = ticketHolderInputSetsCount == ticketHolderAdditionalDataCount
                && ticketCount == ticketHolderAdditionalDataCount;
            return (!!ungroupedTickets &&
                !!ticketTypes &&
                !!ticketHolderInputSets &&
                !!ticketHolderAdditionalData &&
                !!isWorkshopDataValid &&
                !!ticketCount);
        }))
            .subscribe(function (data) {
            var ungroupedTickets = data[0], ticketTypes = data[1], ticketHolderInputSets = data[2], ticketHolderAdditionalData = data[3], ticketCount = data[4];
            var ticketsWithHolders = _this._stepsFormsService.assignUngroupedTicketsToHolders(ungroupedTickets, ticketHolderInputSets, ticketHolderAdditionalData);
            var isPricedTicketWorkshopChosen = true;
            var isZeroPricedTicketWorkshopChosen = true;
            _this.ticketWithNoWorkshopsTaken = [];
            if (_this.workshopsOnTicketSelection) {
                Object.keys(ticketsWithHolders).forEach(function (data) {
                    var ticketHolder = ticketsWithHolders[data];
                    if (ticketHolder.allowedWorkshops.length > 0) {
                        var ticketHolderAdditionalData_1 = ticketsWithHolders[data].ticketHolderAdditionalData;
                        if (_this.isWorkshopsSelectionMandatory) {
                            if (ticketHolderAdditionalData_1 == null || ticketHolderAdditionalData_1.workshops.length < 1) {
                                isPricedTicketWorkshopChosen = false;
                                _this.ticketWithNoWorkshopsTaken.push(ticketHolder.ticketUniqueId);
                            }
                        }
                        if (_this.isWorkshopsSelectionMandatoryForZeroPriceTickets) {
                            if (ticketHolder.ticketPrice == 0) {
                                if (ticketHolderAdditionalData_1 == null || ticketHolderAdditionalData_1.workshops.length < 1) {
                                    isZeroPricedTicketWorkshopChosen = false;
                                    _this.ticketWithNoWorkshopsTaken.push(ticketHolder.ticketUniqueId);
                                }
                            }
                        }
                    }
                });
            }
            _this.setValidation(isPricedTicketWorkshopChosen, ['workshop', 'workshop.not-selected']);
            _this.setValidation(!isPricedTicketWorkshopChosen || isZeroPricedTicketWorkshopChosen, ['workshop-zero', 'workshop.not-zero-selected']);
            _this.ticketsWithHolders.next(ticketsWithHolders);
            _this.updateTotalWorkshopPrice();
        }));
        this.subscriptions.add(this._store
            .pipe(select(fromRoot.getTickets), filter(function (tickets) {
            return !!tickets;
        }))
            //.debounceTime(50) // if this is enabled you can add more tickets then the limit when clicking + button very fast
            .subscribe(function (ungroupedTickets) {
            // create a deep copy so we dont modify the original object in store
            // TODO: This is a shallow copy...
            _this.ungroupedTickets = __assign({}, ungroupedTickets);
            _this.checkForSelectedAnonymousTicketAndAdjustSteps();
            var parsedParkingTickets = _this.getParkingTicketsFromLocalStorage();
            var parsedContingentTickets = _this.getContingentTicketsFromLocalStorage();
            //SteZ: first we have to remove all contingentTicketsForm controls for which we don't have defined tickets anymore
            //otherwise contingent controls for tickets chosen via redeeming a voucher wouldn't be removed when removing a voucher ticket!
            Object.keys(_this.contingentTicketsForm.controls).forEach(function (control) {
                if (!ungroupedTickets[_this.getUniqueTicketID(control)]) {
                    _this.contingentTicketsForm.removeControl(control);
                }
            });
            for (var uniqueId in _this.ungroupedTickets) {
                var ungroupedTicket = _this.ungroupedTickets[uniqueId];
                _this.createAdditionalParkingFormControls(ungroupedTicket, parsedParkingTickets);
                _this.createAdditionalDayFormControls(ungroupedTicket, parsedContingentTickets);
            }
            var queryParamsFromLocation = getQueryParamsFromLocation();
            var ticketUniqueId = queryParamsFromLocation['ticket'];
            if (ticketUniqueId) {
                _this.selectTicket(_this.ungroupedTickets[ticketUniqueId]);
                _this._router.navigate(['.'], {
                    relativeTo: _this._route,
                    queryParams: {}
                });
            }
            _this.hasParkingForm = !!Object.keys(_this.parkingTicketsForm.controls).length;
            if (!_this.hasParkingForm) {
                _this.invalidParkingTickets = [];
                _this.setValidation(true, ['parking', '']);
            }
            _this.hasContingentForm = !!Object.keys(_this.contingentTicketsForm.controls).length;
            if (!_this.hasContingentForm) {
                _this.invalidContingentTickets = [];
                _this.setValidation(true, ['contingent', '']);
                _this.setValidation(true, ['contingent', 'steps.contingent.sold-out']);
            }
            else if (_this.isSelfRegistrationEnabled || (_this.hasVoucher && _this.isVoucherAddedOrRemoved)) {
                //SteZ: we have to add this workaround for self-registration as in that mode "counterChange" function (and also "handleContingentTicketChange") won't be called on adding a new ticket
                //(it also won't be called if we redeem a voucher):
                _this.handleContingentTicketChange();
            }
            _this.isVoucherAddedOrRemoved = false;
        }));
        this.subscriptions.add(observableCombineLatest([
            this._store.pipe(select(fromRoot.getTickets)),
            this._store.pipe(select(fromRoot.getTicketHolderIndexes))
        ])
            .pipe(filter(function (_a) {
            var tickets = _a[0], ticketHolderIndexes = _a[1];
            var areHolderIndexesEmpty = ticketHolderIndexes == null || !Object.keys(ticketHolderIndexes).length;
            var areAllHolderIndexesAssigned = !areHolderIndexesEmpty ? !Object.keys(ticketHolderIndexes)
                .map(function (index) { return ticketHolderIndexes[index]; })
                .filter(function (indexTicketUniqueId) { return indexTicketUniqueId == ''; }).length : false;
            return !!tickets && (areHolderIndexesEmpty || areAllHolderIndexesAssigned);
        }))
            .subscribe(function (_a) {
            var ungroupedTickets = _a[0];
            _this.ungroupedTickets = __assign({}, ungroupedTickets);
            // go thru tickets and sum price of selected ones. Also count number of selected tickets
            var numberOfSelectedTickets = 0;
            var numberOfSelectedVouchers = 0;
            var numberOfSelectedLimitedVouchers = 0;
            var numberOfSelectedOneTimeVouchers = 0;
            _this.totalPrice = Object.keys(_this.ungroupedTickets).reduce(function (acc, key) {
                if (_this.ungroupedTickets[key].hasOwnProperty('voucherType')) {
                    if (_this.ungroupedTickets[key].voucherType === 'limitedPromoCode') {
                        numberOfSelectedLimitedVouchers += _this.ungroupedTickets[key].count;
                    }
                    else if (_this.ungroupedTickets[key].voucherType === 'promoCode') {
                        numberOfSelectedVouchers += _this.ungroupedTickets[key].count;
                    }
                    else if (_this.ungroupedTickets[key].voucherType === 'oneTimeVoucher') {
                        numberOfSelectedOneTimeVouchers += _this.ungroupedTickets[key].count;
                    }
                }
                else {
                    numberOfSelectedTickets += _this.ungroupedTickets[key].count;
                }
                var pricePerTicketType = _this.getPricePerTicketType(_this.ungroupedTickets, key);
                var finalPrice = acc + pricePerTicketType;
                return finalPrice;
            }, 0);
            _this.totalPriceWithOutParking = _this.totalPrice;
            _this.ticketCounter = Object.keys(_this.ungroupedTickets).reduce(function (acc, key) {
                return acc + _this.ungroupedTickets[key].count;
            }, 0);
            var isValid = _this.isFormValid(numberOfSelectedTickets +
                numberOfSelectedVouchers +
                numberOfSelectedLimitedVouchers +
                numberOfSelectedOneTimeVouchers);
            _this.setValidation(isValid);
            if (!_this.workshopsOnTicketSelection || (!_this.isWorkshopsSelectionMandatory && !_this.isWorkshopsSelectionMandatoryForZeroPriceTickets) || !_this.ticketWithNoWorkshopsTaken.length || !isValid) {
                _this.ticketWithNoWorkshopsTaken = [];
                _this.setValidation(true, ['workshop', 'workshop.not-selected']);
                _this.setValidation(true, ['workshop-zero', 'workshop.not-zero-selected']);
            }
            _this.numberOfSelectedTickets = numberOfSelectedTickets;
            _this.numberOfSelectedVouchers = numberOfSelectedVouchers;
            _this.numberOfSelectedLimitedVouchers = numberOfSelectedLimitedVouchers;
            _this.numberOfSelectedOneTimeVouchers = numberOfSelectedOneTimeVouchers;
            if (_this._helperService.isSelfregistration()) {
                _this._helperService.loadBuyerQuestionnaireViaApi('questionnaire');
            }
            else {
                _this._helperService.loadBuyerQuestionnaireViaApi('personal');
            }
            _this.updateTotalWorkshopPrice();
        }));
        this.subscriptions.add(this._store.pipe(select(fromRoot.getIsPackageLoading))
            .subscribe(function (isPackageLoading) {
            _this.isPackageLoading = isPackageLoading;
        }));
    }
    WebShopTicketsComponent.prototype.messageFromPackageCounter = function (ticketUniqueId, packageCount) {
        var _this = this;
        this.ticketUniqueIdMessage = ticketUniqueId;
        this.showPackageMessage = true;
        clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(function () {
            _this.showPackageMessage = false;
        }, 3000);
    };
    WebShopTicketsComponent.prototype.updateTotalWorkshopPrice = function () {
        var _this = this;
        setTimeout(function () {
            _this._store
                .select(fromRoot.getTicketHolderAdditionalData)
                .first()
                .subscribe(function (data) {
                _this.totalWorkshopPrice = 0;
                for (var _i = 0, _a = Object.keys(data); _i < _a.length; _i++) {
                    var key = _a[_i];
                    var additionalData = data[key];
                    if (additionalData) {
                        additionalData.workshops.forEach(function (workshopId) {
                            var workshop = _this.workshops.find(function (workshop) {
                                return workshop.workshopId === workshopId;
                            });
                            _this.totalWorkshopPrice += workshop.price;
                        });
                    }
                }
            });
        }, 0);
        return 0;
    };
    WebShopTicketsComponent.prototype.updateTotalParkingPrice = function (that) {
        if (that === void 0) { that = null; }
        var storedParkingTickets = getLocalStorageString(AppConstants.parkingTicketsReducer);
        var parkingTicketsDictionary = storedParkingTickets && JSON.parse(storedParkingTickets);
        var parkingTicketCount = !!that && !!that.parkingTicketsForm ? Object.keys(that.parkingTicketsForm.controls).length : Object.keys(this.parkingTicketsForm.controls).length;
        var tempTotalParkingPriceAmount = Object.keys(parkingTicketsDictionary).reduce(function (accu, curr, index) {
            if (index >= parkingTicketCount) {
                delete parkingTicketsDictionary[curr];
                setLocalStorageObject(AppConstants.parkingTicketsReducer, parkingTicketsDictionary);
                return accu;
            }
            var ticket = parkingTicketsDictionary[curr];
            if (ticket.price) {
                return accu + parseInt(ticket.price, 10);
            }
            return accu;
        }, 0);
        if (that) {
            that.totalParkingPriceAmount = tempTotalParkingPriceAmount;
            that.totalPrice =
                that.totalPriceWithOutParking + that.totalParkingPriceAmount;
        }
        else {
            this.totalParkingPriceAmount = tempTotalParkingPriceAmount;
            this.totalPrice =
                this.totalPriceWithOutParking + this.totalParkingPriceAmount;
        }
    };
    WebShopTicketsComponent.prototype.ngOnDestroy = function () {
        var _this = this;
        this._store
            .select(fromRoot.getTickets)
            .pipe(filter(function (data) { return !!data; }))
            .first()
            .subscribe(function (ungroupedTickets) {
            var ticketsWithCount = [];
            for (var uniqueId in _this.ungroupedTickets) {
                var ticket = _this.ungroupedTickets[uniqueId];
                if (ticket.count > 0) {
                    ticketsWithCount.push(ticket);
                }
            }
            _this._gtmService.pushCheckout(ticketsWithCount);
        });
        this.subscriptions.unsubscribe();
        if (this.contingentSubscriptions) {
            this.contingentSubscriptions.unsubscribe();
            this.contingentSubscriptions = null;
        }
    };
    WebShopTicketsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.sholdShowPromoCode$ = this._store.select(fromRoot.sholdShowPromoCode);
        this.isSelfRegistrationEnabled = this._helperService.isSelfregistration();
        this._store.dispatch(new stepsFormsActions.SetSelectedStep('tickets'));
        this.subscriptions.add(this._store.pipe(select(fromRoot.getTranslationsLoaded))
            .subscribe(function (getTranslationsLoaded) {
            _this.translationsLoaded = getTranslationsLoaded;
        }));
        this.subscriptions.add(this._store.select(fromRoot.getAddressCheckbox).subscribe(function (item) {
            if (!!item) {
                _this.checkedSlideIndex = item.checkedSlideIndex;
            }
        }));
        this.subscriptions.add(this._store
            .select(fromRoot.getSelectedExhibitionId)
            .subscribe(function (eventId) {
            _this._store.dispatch(new additionalServicesActions.GetWorkshops(eventId));
        }));
        this.subscriptions.add(this._store
            .select(fromRoot.getSelectedExhibition)
            .subscribe(function (exhibition) { return (_this.exhibition = exhibition); }));
        observableCombineLatest(this._store.select(fromRoot.getSelectedExhibitionId), this._store.select(fromRoot.getProfile), this._store.select(fromRoot.getSelectedExhibition))
            .pipe(filter(function (data) { return !!data[0] && !!data[1] && !!data[2]; }), first())
            .subscribe(function (data) {
            var eventId = data[0], profile = data[1], exhibition = data[2];
            _this._store.dispatch(new legitimationActions.GetLegitimationStatus({
                userId: profile.id,
                eventId: eventId
            }));
        });
        this.subscriptions.add(this._store
            .select(fromRoot.getLegitimationStatus)
            .pipe(filter(function (data) { return !!data; }))
            .subscribe(function (legitimation) {
            var legitimationValid = legitimation.status === 'approved' || _this.isSelfRegistrationEnabled;
            _this._formsService.setFormValidity(legitimationValid, null, ['legitimation', 'validation']);
        }));
        this.subscriptions.add(observableCombineLatest(this._store.select(fromRoot.isLegitimationRequired), this._store.select(fromRoot.getExhibitionSettings), this._store.select(fromRoot.getProfile)).subscribe(function (data) {
            _this.verifiedAccountRequired = data[1]
                ? data[1]['loginMode'] ===
                    'beforeTicketSelectionWithRequiredEmailConfirmation'
                : false;
            _this.isAccountVerified = data[2] ? data[2].isEmailVerified : false;
        }));
        this.subscriptions.add(observableCombineLatest(this._store.select(fromRoot.getWorkshops), this._store.select(fromRoot.getTickets), this._store.select(fromRoot.getTicketsBooking))
            .filter(function (data) { return !!data[0].length && !!data[1]; })
            .map(function (data) {
            var workshops = data[0];
            var ungroupedTickets = data[1];
            var ticketsBookings = data[2];
            var seatsAvailableByUniqueId = Object.keys(ungroupedTickets).reduce(function (acc, ticketId) {
                // all available workshop seats for this tickets, before booked workshop seats by other tickets are substracted
                var availableSeats = workshops.reduce(function (acc, workshop) {
                    if (ungroupedTickets[ticketId].allowedWorkshops.includes(workshop.workshopId)) {
                        acc += workshop.seats;
                    }
                    return acc;
                }, 0);
                var seatsBookingsByOtherTicket = workshops.reduce(function (acc, workshop) {
                    function hasOtherSeats(booking, workshop) {
                        var otherWorkshopsIds = ungroupedTickets[booking.ticketUniqueId].allowedWorkshops.filter(function (workshopId) { return workshopId !== workshop.workshopId; });
                        if (!otherWorkshopsIds.length) {
                            return false;
                        }
                        return true;
                        /*
                      const otherWorkshops = workshops.filter(workshop =>
                        otherWorkshopsIds.includes(workshop.workshopId)
                      );
    
                      const seatsByOtherWOrkshops = otherWorkshops.reduce(
                        (acc, workshop: WorkshopModel) => {
                          acc += workshop.seats;
                          return acc;
                        },
                        0
                      );
    
                      const bookingsByOtherWorkshops = 0;
    
                      return !!(seatsByOtherWOrkshops - bookingsByOtherWorkshops); */
                        /* const filteredWorkshops = workshops.find(workshop => allowdWorkshops.icludes(workshop.workshopId))
                    console.log(filteredWorkshops) */
                    }
                    var otherBookings = ticketsBookings.bookings.filter(function (booking) {
                        return (booking.count &&
                            booking.ticketUniqueId !== ticketId &&
                            ungroupedTickets[booking.ticketUniqueId] &&
                            ungroupedTickets[booking.ticketUniqueId].allowedWorkshops.includes(workshop.workshopId) &&
                            !hasOtherSeats(booking, workshop));
                    });
                    acc = acc.concat(otherBookings);
                    return acc;
                }, []);
                var uniqueBookings = seatsBookingsByOtherTicket.filter(function (value, index, self) { return self.indexOf(value) === index; });
                var seatsCountBookedByOtherTicket = uniqueBookings.reduce(function (acc, booking) {
                    acc += booking.count;
                    return acc;
                }, 0);
                acc[ticketId] = availableSeats - seatsCountBookedByOtherTicket;
                return acc;
            }, {});
            return seatsAvailableByUniqueId;
        })
            .subscribe(function (data) {
            _this.workshopsList = data;
        }));
        this.isTicketSoldOut$ = this._store
            .select(fromRoot.getTicketsBooking)
            .map(function (booking) {
            _this.isTicketBookingLoading = false;
            return booking.bookings.reduce(function (acc, booking) {
                acc[booking.ticketUniqueId] =
                    booking.isTicketSold && booking.count === 0;
                return acc;
            }, {});
        });
        this.availableTicketsCombined$ = observableCombineLatest([
            this._store.pipe(select(fromRoot.getTicketsBooking)),
            this._store.pipe(select(fromRoot.getTickets))
        ])
            .filter(function (data) {
            return !!data[1];
        })
            .map(function (data) {
            var booking = data[0];
            var ungroupedTickets = data[1];
            var availability = Object.keys(ungroupedTickets).reduce(function (acc, ticketId) {
                if (booking) {
                    var realtimeBooking_1 = booking.bookings.find(function (booking) { return ungroupedTickets[ticketId].groupId === booking.groupId && ungroupedTickets[ticketId].id === booking.ticketTypeId && booking.count > 0; });
                    if (realtimeBooking_1) {
                        var tickets = Object.keys(ungroupedTickets).map(function (ticketUniqueId) { return ungroupedTickets[ticketUniqueId]; });
                        var bookedTicket = ungroupedTickets[realtimeBooking_1.ticketUniqueId];
                        if (bookedTicket) {
                            var sharedLimitTicketsBookingsAvailability = tickets.filter(function (ticket) { return ticket.groupId === realtimeBooking_1.groupId && ticket.id === realtimeBooking_1.ticketTypeId; });
                            var sharedLimitTicketsTotalCount = sharedLimitTicketsBookingsAvailability.reduce(function (total, ticket) { return total + ticket.count; }, 0);
                            var availableTickets_1 = bookedTicket.availableTickets - sharedLimitTicketsTotalCount;
                            sharedLimitTicketsBookingsAvailability.forEach(function (ticket) { return acc[ticket.uniqueId] = availableTickets_1 + ticket.count; });
                            return acc;
                        }
                    }
                }
                acc[ticketId] = ungroupedTickets[ticketId].availableTickets;
                return acc;
            }, {});
            return availability;
        });
        this.totalAvailableTickets$ = observableCombineLatest([
            this._store.pipe(select(fromRoot.getTicketsBooking)),
            this._store.pipe(select(fromRoot.getTickets))
        ])
            .filter(function (data) {
            return !!data[1];
        })
            .map(function (data) {
            var booking = data[0];
            var ungroupedTickets = data[1];
            var defaultLimit = 1000;
            var checkedTicketLimits = [];
            var availability = Object.keys(ungroupedTickets).reduce(function (acc, ticketId) {
                acc[ticketId] = ungroupedTickets[ticketId].availableTickets === 0 ? 0 : ungroupedTickets[ticketId].availableTickets || ungroupedTickets[ticketId].ticketLimit || defaultLimit;
                if (booking) {
                    var realtimeBooking_2 = booking.bookings.find(function (booking) { return ungroupedTickets[ticketId].groupId === booking.groupId && ungroupedTickets[ticketId].id === booking.ticketTypeId && booking.count > 0; });
                    if (realtimeBooking_2) {
                        var tickets = Object.keys(ungroupedTickets).map(function (ticketUniqueId) { return ungroupedTickets[ticketUniqueId]; });
                        var bookedTicket = ungroupedTickets[realtimeBooking_2.ticketUniqueId];
                        if (bookedTicket) {
                            var sharedLimitTicketsBookingsAvailability = tickets.filter(function (ticket) { return ticket.groupId === realtimeBooking_2.groupId && ticket.id === realtimeBooking_2.ticketTypeId; });
                            var sharedLimitTicketsTotalCount_1 = sharedLimitTicketsBookingsAvailability.reduce(function (total, ticket) { return total + ticket.count; }, 0);
                            sharedLimitTicketsBookingsAvailability.forEach(function (ticket) {
                                var ticketUniqueId = ticket.uniqueId;
                                if (!checkedTicketLimits.includes(ticketUniqueId) && !!acc[ticketUniqueId]) {
                                    acc[ticketUniqueId] = acc[ticketId] - sharedLimitTicketsTotalCount_1;
                                    checkedTicketLimits.push(ticketUniqueId);
                                }
                            });
                            return acc;
                        }
                    }
                }
                return acc;
            }, {});
            return availability;
        });
        this.percentageOfAvailableTickets$ = observableCombineLatest(this._store.select(fromRoot.getTickets), this._store.select(fromRoot.getTicketsBooking))
            .filter(function (data) { return !!data[0]; })
            .map(function (data) {
            var ungroupedTickets = data[0], ticketBooking = data[1];
            var ticketAvailability = Object.keys(ungroupedTickets).reduce(function (acc, ticketKey) {
                var ticket = ungroupedTickets[ticketKey];
                var uniqueId = ticket.uniqueId;
                acc[uniqueId] = !ticket.ticketLimit
                    ? 1
                    : 1 -
                        (ticket.ticketLimit - ticket.availableTickets) /
                            ticket.ticketLimit;
                return acc;
            }, {});
            var bookingsAvailability = ticketBooking.bookings.reduce(function (acc, booking) {
                var uniqueId = booking.ticketUniqueId;
                var ticket = ungroupedTickets[uniqueId];
                acc[uniqueId] =
                    !booking.ticketLimit || !ticket || !ticket.availableTickets
                        ? 1
                        : 1 -
                            (booking.ticketLimit -
                                ticket.availableTickets +
                                booking.count) /
                                booking.ticketLimit;
                return acc;
            }, {});
            return __assign({}, ticketAvailability, bookingsAvailability);
        });
        this.subscriptions.add(this._store.pipe(select(fromRoot.getTranslations))
            .first()
            .subscribe(function () {
            _this._translateService
                .stream([
                "packages.package-counter-message"
            ])
                .subscribe(function (translations) {
                _this.packageMessageMobile = [];
                _this.packageMessageMobile.push(translations['packages.package-counter-message']);
            });
        }));
    };
    WebShopTicketsComponent.prototype.ngAfterContentChecked = function () {
        this.cdr.detectChanges();
    };
    WebShopTicketsComponent.prototype.releaseVoucher = function (code, voucherTicket) {
        var _this = this;
        observableCombineLatest([
            this._store.pipe(select(fromRoot.getSelectedExhibitionId)),
            this._store.pipe(select(fromRoot.getOrderUuid)),
            this._store.pipe(select(fromRoot.getTicketsBooking))
        ])
            .pipe(first())
            .subscribe(function (data) {
            var id = data[0], uuid = data[1], ticketBooking = data[2];
            var releaseVoucher = {
                eventId: id,
                voucherCode: code,
                countryCode: _this._translateService.currentLang,
                uuid: uuid,
                ticketPersonId: voucherTicket.ticketPersonId
            };
            _this.clearVoucherInput$.next(true);
            //when we release voucher we also remove the voucher from ticketBooking
            _this._store.dispatch(new ticketActions.AddTicketBooking(null));
            ticketBooking.bookings.map(function (booking) {
                if (booking.ticketUniqueId == voucherTicket.uniqueId) {
                    //When releasing vouchers, we find the voucher ticket in bookings,
                    //set available Tickets to default, and return the count to 0
                    booking.availableTickets = booking.availableTickets + booking.count;
                    booking.count = 0;
                    booking.isTicketSold = booking.availableTickets > 0 ? false : true;
                    _this._store.dispatch(new ticketActions.AddTicketBooking(booking));
                }
            });
            // Bug 3921 (Support task 3900)
            if (!_this.isSelfRegistrationEnabled) {
                _this.counterChange({ value: 0, decrease: true }, voucherTicket.uniqueId);
            }
            _this._store.dispatch(new ticketActions.ReleaseVoucher(releaseVoucher));
            // now remove holder forms related to this voucher ticket if there is any
            // Bug 3921 (Support task 3900)
            // voucherTicket.holdersIndexes.forEach(holderIndex => {
            //   this._ticketsService.removeTicketHolder(holderIndex);
            // });
        });
        this.currentTicketAfterCountChanged = null;
        this.isVoucherAddedOrRemoved = true;
        // as we are dispatching new ticket bookings above - skip initial value and wait for the update so we can release contingent day tickets
        this._store
            .select(fromRoot.getTickets)
            .pipe(skip(1), first())
            .subscribe(function () {
            if (voucherTicket.hasDaySellLimit) {
                _this.handleContingentTicketChange();
            }
        });
    };
    /**
     * count prices of ticket per type;
     * based on ticket count of that type and number of sales available for this ticket type
     * in case there are more ticket than sales, make sum of prices of reduced tickets and full price tickets
     *
     * @param {TicketModel} ungroupedTickets
     * @param {string} ticketUniqueId
     * @returns {number}
     * @memberof WebShopTicketsComponent
     */
    WebShopTicketsComponent.prototype.getPricePerTicketType = function (ungroupedTickets, ticketUniqueId) {
        var ticketWithCount = ungroupedTickets[ticketUniqueId];
        var ticketCount = ticketWithCount.count;
        var fullTicketsPrice = 0;
        // we dont calculate parking ticket price.. the calculation is done differently
        if (!ungroupedTickets[ticketUniqueId].hasOwnProperty('parking')) {
            fullTicketsPrice = ticketCount * ticketWithCount.price;
        }
        return fullTicketsPrice;
    };
    /**
     * tell whether form is valid (at least one ticket is selected)
     *
     * @param {any} numberOfSelectedTickets
     * @returns
     * @memberof WebShopTicketsComponent
     */
    WebShopTicketsComponent.prototype.isFormValid = function (numberOfSelectedTickets) {
        return numberOfSelectedTickets ? true : false;
    };
    WebShopTicketsComponent.prototype.removeValidationFeedbacks = function () {
        var stepsFormsActionName = ['tickets', 'ticketSelection'];
        this._formsService.removeAllStepValidationFeedbacks(stepsFormsActionName);
        this._formsService.setFormValidity(false, null, stepsFormsActionName);
    };
    WebShopTicketsComponent.prototype.setValidation = function (isValid, validationFeedback) {
        if (validationFeedback === void 0) { validationFeedback = ['counters', 'steps.missing-input.ticket-counter']; }
        var stepsFormsActionName = ['tickets', 'ticketSelection'];
        var key = validationFeedback[0], messageTranslationKey = validationFeedback[1];
        switch (key) {
            case 'counters':
                this.isTicketCounterValid = isValid;
                break;
            case 'parking':
                this.isParkingTicketsValid = isValid;
                break;
            case 'contingent':
                this.isContingentsValid = isValid;
                break;
            case 'workshop':
                this.isWorkshopsMandatoryValid = isValid;
                break;
            case 'workshop-zero':
                this.isWorkshopsZeroPriceMandatoryValid = isValid;
                break;
            default:
                break;
        }
        var allStepValidationFieldsValid = this.isTicketCounterValid && this.isParkingTicketsValid && this.isContingentsValid && this.isWorkshopsMandatoryValid && this.isWorkshopsZeroPriceMandatoryValid;
        if (isValid) {
            if (key === 'counters' || key === 'parking' || (key === 'contingent' && !messageTranslationKey)) {
                //parking and contingent tickets share the same validation/translation key as the ticket counter ('counters'):
                if (this.isTicketCounterValid && this.isParkingTicketsValid && this.isContingentsValid) {
                    this._formsService.removeStepValidationFeedback(stepsFormsActionName, 'counters');
                }
            }
            else {
                //other steps (i.e. currently only workshops) have their own key:
                this._formsService.removeStepValidationFeedback(stepsFormsActionName, key);
            }
        }
        else {
            if (key === 'parking' || (key === 'contingent' && !messageTranslationKey)) {
                key = 'counters';
                messageTranslationKey = 'steps.missing-input.ticket-counter';
            }
            this._formsService.addStepValidationFeedback(stepsFormsActionName, key, messageTranslationKey);
        }
        //even though we've received an info that the ticket selection is valid it can only be valid if all step validation fields are valid:
        if (isValid && !allStepValidationFieldsValid) {
            isValid = false;
        }
        this._formsService.setFormValidity(isValid, null, stepsFormsActionName);
        this.ticketSelectionIsValid = isValid;
    };
    WebShopTicketsComponent.prototype.selectTicket = function (ticket) {
        this.releaseContingentTickets();
        this._ticketsService.selectTicket(ticket);
        this._customizationService.setShoppingStartTime();
    };
    /**
     *
     *
     * @param {{value: number; decrease: number}} change can be either 1 or -1, depending whether ticket was added or removed
     * @param {any} ticketUniqueId
     * @memberof WebShopTicketsComponent
     */
    WebShopTicketsComponent.prototype.counterChange = function (data, uniqueId) {
        var value = data.value, decrease = data.decrease, isTicketAddedByPackageCounter = data.isTicketAddedByPackageCounter;
        this.clearVoucherInput$.next(true);
        //if ticket count is increased and if workshop selection is mandatory we set the step not valid
        if (!decrease && this.exhibitionSettings.isWorkshopsSelectionMandatory && !this.workshopsOnTicketSelection) {
            this._formsService.setFormValidity(false, null, ['workshop', 'validation']);
        }
        // User Story 3466: When we remove ticket if checked slide index is same as value we are decreasing to we uncheck it
        if (decrease) {
            this._store.dispatch(new stepsActions.SetAddressCheckbox({
                checkedSlideIndex: null,
                isAddressCopied: false
            }));
            this._store.dispatch(new stepsActions.SetBuyerVisitorCheckbox({
                buyerVisitorCheckedSlideIndex: null,
                isBuyerVisitorChecked: false,
                showVisitorQuestionnaire: false
            }));
            this._formsService.removeAllStepValidationFeedbacks(this.visitorQuestionnaireValidation);
            this._formsService.setFormValidity(true, null, this.visitorQuestionnaireValidation);
        }
        this.currentTicketAfterCountChanged = { uniqueId: uniqueId, value: value };
        if (this.needsTicketOverLimitCheck) {
            //due to tickets over limit validation on personalization we have to invalidate buyerinfo form here
            //as otherwise if we change tickets on ticket selection the personalization step could remain valid so the user could be able to skip personalization revalidation and go directly to confirmation:
            this._formsService.setFormValidity(false, null, ['personal', 'buyerinfo']);
        }
        this._ticketsService.ticketCounterChanged(uniqueId, value, decrease);
        if (!isTicketAddedByPackageCounter) {
            this.handleContingentTicketChange();
        }
    };
    /**
     * @param {{value: number; previousValue: number; decrease: number}} change can be either 1 or -1, depending whether ticket was added or removed
     * @param {any} PackageModel
     * @memberof PackageCounterComponent
     */
    WebShopTicketsComponent.prototype.packageCounterChange = function (data, changedPackage) {
        var _this = this;
        var value = data.value, previousValue = data.previousValue, decrease = data.decrease;
        var isFirstPackageAlreadyAdded = previousValue >= 1;
        var firstPackageAdded = !isFirstPackageAlreadyAdded && value >= 1;
        var removeAllPackages = isFirstPackageAlreadyAdded && value == 0;
        var changedPackagesDifferenceCount = Math.abs(value - previousValue);
        var isFirstPackageChanged = firstPackageAdded || removeAllPackages;
        var additionalPackagesToChangeCount = isFirstPackageChanged ? changedPackagesDifferenceCount - 1 : changedPackagesDifferenceCount;
        if (firstPackageAdded) {
            this.showPackageMessage = false;
            clearTimeout(this.timeoutId);
        }
        if (!decrease) {
            var packageMinimalTicketCount = this._packagesService.getPackageMinimalTicketCount(changedPackage);
            this._store.dispatch(new ticketActions.AddTicketHolderIndexes(packageMinimalTicketCount));
        }
        var parsedContingentTickets = this.getContingentTicketsFromLocalStorage();
        if (isFirstPackageChanged) {
            var firstPackageContents_1 = changedPackage.contents[0];
            if (!decrease) {
                this._store.pipe(select(fromRoot.getTickets), first())
                    .subscribe(function (tickets) {
                    var flatTickets = __assign({}, tickets);
                    firstPackageContents_1.packageGroups.forEach(function (packageGroup) {
                        packageGroup.products.forEach(function (product) {
                            flatTickets[product.ticket.uniqueId].count = _this._packagesService.setPackageTicketAmount(product.ticket.packageSettings, 0);
                            _this.createAdditionalDayFormControls(flatTickets[product.ticket.uniqueId], parsedContingentTickets);
                        });
                    });
                    setTimeout(function () {
                        _this._store.dispatch(new ticketActions.SetTickets(flatTickets));
                    }, 0);
                });
            }
            this._packagesService.packageContentCountChanged(firstPackageContents_1, decrease);
        }
        if (additionalPackagesToChangeCount >= 1) {
            this._packagesService.updatePackageContents(changedPackage, 1, decrease)
                .pipe(delay(0), filter(function (tickets) { return !!tickets; }), first())
                .subscribe(function (tickets) {
                if (decrease) {
                    Object.keys(_this.contingentTicketsForm.controls).forEach(function (control) {
                        if (!tickets[_this.getUniqueTicketID(control)]) {
                            _this.contingentTicketsForm.removeControl(control);
                        }
                    });
                }
            });
        }
        this._store.pipe(select(fromRoot.getTicketHolderIndexes), filter(function (indexes) { return !!indexes && !Object.keys(indexes).some(function (index) { return indexes[index] == ''; }); }), first())
            .subscribe(function () {
            _this.handleContingentTicketChange();
        });
    };
    WebShopTicketsComponent.prototype.releaseContingentTickets = function () {
        if (this.contingentSubscriptions) {
            this.contingentSubscriptions.unsubscribe();
            this.contingentSubscriptions = null;
            this._ticketsService.postReleaseTicketForDay();
        }
    };
    WebShopTicketsComponent.prototype.handleContingentTicketChange = function () {
        var _this = this;
        // if the contingents form doesnt have controls (there is none of the tickets with day limit selected) perform reset
        // this part of code inside condition is basically called each time a ticket bookings got updated - should probably be refactored
        if (!Object.keys(this.contingentTicketsForm.controls).length) {
            setLocalStorageObject(AppConstants.contingentTicketsReducer, '');
            this.releaseContingentTickets();
            Object.keys(this.endDays).forEach(function (key) {
                _this.endDays[key].length = 0;
            });
            this.setValidation(true, ['contingent', '']);
            this.setValidation(true, ['contingent', 'steps.contingent.sold-out']);
            this.invalidContingentTickets = [];
            return;
        }
        //TODO: we should probably also release contingents when removing tickets (but while we still have some contingents left on the form)
        this.setValidation(false, ['contingent', '']);
        setLocalStorageObject(AppConstants.contingentTicketsReducer, this.contingentTicketsForm.value);
        observableCombineLatest(this._store.select(fromRoot.getOrderUuid), this._store.select(fromRoot.getTickets), this._store.select(fromRoot.getSelectedExhibitionId))
            //added delay(0) here to prevent "ExpressionChangedAfterItHasBeenCheckedError" errors in the console:
            .pipe(delay(0), filter(function (data) { return !!data[1]; }), first())
            .subscribe(function (_a) {
            var uuid = _a[0], bookedTickets = _a[1], eventId = _a[2];
            var allTickets = Object.keys(_this.contingentTicketsForm.value).map(function (formGroupName) {
                var uniqueId = _this.getUniqueTicketID(formGroupName);
                var dayFormGroup = _this.contingentTicketsForm.get("" + formGroupName);
                var ticketPersonId = bookedTickets[uniqueId].ticketPersonId;
                var ticketDay = dayFormGroup.value.day;
                return {
                    ticketPersonId: ticketPersonId,
                    day: ticketDay ? DateTime.fromJSDate(ticketDay).toISODate() : null,
                    uniqueId: uniqueId,
                    ticketIndex: parseInt(_this.getTicketIndex(formGroupName))
                };
            });
            var tickets = allTickets.filter(function (ticket) { return ticket.day; });
            Object.keys(_this.endDays).forEach(function (key) {
                if (bookedTickets[key] && bookedTickets[key]['holdersIndexes'].length !== _this.endDays[key].length) {
                    _this.endDays[key].length = bookedTickets[key]['holdersIndexes'].length;
                }
            });
            _this.hasContingentForm = !!Object.keys(_this.contingentTicketsForm.controls).length;
            var hasTickets = !!tickets.length;
            // //remove previous contingent dates so they won't be displayed until we get feedback from the backend:
            // tickets.forEach(ticket => {
            //   if (this.endDays[ticket.uniqueId] && this.endDays[ticket.uniqueId].length) {
            //     this.endDays[ticket.uniqueId].length = 0;
            //   }
            // });
            _this.invalidContingentTickets = [];
            allTickets.forEach(function (ticket) {
                _this.invalidContingentTickets.push(ticket.uniqueId);
            });
            var data = {
                uuid: uuid,
                eventId: eventId,
                tickets: tickets
            };
            if (_this.hasContingentForm && hasTickets) {
                if (_this.contingentSubscriptions) {
                    _this.contingentSubscriptions.unsubscribe();
                    _this.contingentSubscriptions = null;
                }
                _this.contingentSubscriptions =
                    _this._ticketsService.postBookTicketForDay(data).subscribe(function (bookings) {
                        _this.updateTicketsWithSetDay(data.tickets, bookings);
                    }, function () {
                        _this.setValidation(false, ['contingent', 'steps.contingent.sold-out']);
                    });
            }
            else {
                //release contingents:
                _this.releaseContingentTickets();
            }
        });
    };
    /**
     * Returns all parts of a unique ticket ID bar the last one (usually ticket index)
     * @param ticketIDWithIndex Input string, ticket ID with index, elements should be separated by '_'
     */
    WebShopTicketsComponent.prototype.getUniqueTicketID = function (ticketIDWithIndex) {
        var splitID = ticketIDWithIndex.split('_');
        var result = '';
        if (splitID.length > 1) {
            for (var index = 0; index < splitID.length - 1; index++) {
                result += splitID[index] + '_';
            }
        }
        else {
            return ticketIDWithIndex;
        }
        if (result.endsWith('_')) {
            result = result.substr(0, result.length - 1);
        }
        return result;
    };
    /**
     * Returns only the last part of a unique ticket ID, usually ticket index
     * @param ticketIDWithIndex Input string, ticket ID with index, elements should be separated by '_'
     */
    WebShopTicketsComponent.prototype.getTicketIndex = function (ticketIDWithIndex) {
        var splitID = ticketIDWithIndex.split('_');
        return splitID.length > 1 ? splitID[splitID.length - 1] : ticketIDWithIndex;
    };
    WebShopTicketsComponent.prototype.updateTicketsWithSetDay = function (reservedTickets, bookings) {
        var _this = this;
        this._store
            .pipe(select(fromRoot.getTickets), filter(function (data) { return !!data; }), first())
            .subscribe(function (tickets) {
            _this.endDays = {};
            var ticketKeys = Object.keys(tickets);
            var contingentsData = _this.contingentTicketsForm.value;
            var validatedTicketIndexes = {};
            for (var i = 0; i < ticketKeys.length; i++) {
                _this.endDays[ticketKeys[i]] = [];
            }
            for (var i = 0; i < reservedTickets.length; i++) {
                var _loop_1 = function (j) {
                    var currentKey = ticketKeys[j];
                    var currentTicket = tickets[currentKey];
                    var currentReservedTicket = reservedTickets[i];
                    var currentReservedTicketIndex = currentReservedTicket.ticketIndex;
                    if (currentTicket.ticketPersonId === currentReservedTicket.ticketPersonId &&
                        currentTicket.uniqueId === currentReservedTicket.uniqueId) {
                        var startDate = new Date(currentReservedTicket.day);
                        var reservedDate = DateTime.fromJSDate(startDate);
                        var endDate = currentTicket.durationInDays > 1
                            ? reservedDate
                                .plus({ days: currentTicket.durationInDays - 1 })
                                .toJSDate()
                            : startDate;
                        var duration = currentTicket.durationInDays;
                        var isValid = null;
                        var contingentTicketValidityResponse = bookings.filter(function (_a) {
                            var ticketPersonId = _a.ticketPersonId, ticketIndex = _a.ticketIndex;
                            return ticketPersonId == currentTicket.ticketPersonId && ticketIndex == currentReservedTicketIndex;
                        });
                        if (!!contingentTicketValidityResponse) {
                            if (validatedTicketIndexes[currentTicket.ticketPersonId] == undefined) {
                                validatedTicketIndexes[currentTicket.ticketPersonId] = {};
                            }
                            if (validatedTicketIndexes[currentTicket.ticketPersonId][currentReservedTicketIndex] == undefined) {
                                validatedTicketIndexes[currentTicket.ticketPersonId][currentReservedTicketIndex] = 0;
                            }
                            isValid = contingentTicketValidityResponse[validatedTicketIndexes[currentTicket.ticketPersonId][currentReservedTicketIndex]].isValid;
                            validatedTicketIndexes[currentTicket.ticketPersonId][currentReservedTicketIndex] = validatedTicketIndexes[currentTicket.ticketPersonId][currentReservedTicketIndex] + 1;
                        }
                        var ticketValidTill = !!currentTicket.validTill ? new Date(currentTicket.validTill) : null;
                        if (!!ticketValidTill && _this._helperService.isFirstDateGreaterWOTime(endDate, ticketValidTill)) {
                            endDate = ticketValidTill;
                        }
                        if (_this._helperService.isFirstDateGreaterWOTime(endDate, _this.exhibition.endDate)) {
                            endDate = _this.exhibition.endDate;
                        }
                        if (duration > 1 && _this._helperService.areDatesSameWOTime(endDate, startDate)) {
                            duration = 1;
                        }
                        //SteZ: we have to add empty items into endDays list for those tickets without a set contingent date
                        //as otherwise the application wouldn't apply the already set and approved dates to the correct tickets
                        //(e.g. if you buy two tickets and set a date on the second one that date would be applied to the first ticket index):
                        while (_this.endDays[currentKey].length < currentReservedTicketIndex) {
                            var contingentDaysEmptyData = {
                                start: null,
                                end: null,
                                duration: null,
                                isValid: null
                            };
                            _this.endDays[currentKey].push(contingentDaysEmptyData);
                        }
                        var contingentDaysData = {
                            start: startDate,
                            end: endDate,
                            duration: duration,
                            isValid: isValid
                        };
                        contingentsData[currentKey + "_" + currentReservedTicketIndex] = __assign({}, contingentsData[currentKey + "_" + currentReservedTicketIndex], contingentDaysData);
                        _this.endDays[currentKey].push(contingentDaysData);
                        var index = _this.invalidContingentTickets.indexOf(currentKey);
                        if (index > -1 && isValid) {
                            _this.invalidContingentTickets.splice(index, 1);
                        }
                    }
                };
                for (var j = 0; j < ticketKeys.length; j++) {
                    _loop_1(j);
                }
            }
            if (!_this.invalidContingentTickets.length && _this.contingentTicketsForm.valid) {
                _this.setValidation(true, ['contingent', '']);
                _this.setValidation(true, ['contingent', 'steps.contingent.sold-out']);
            }
            setLocalStorageObject(AppConstants.contingentTicketsReducer, contingentsData);
        });
    };
    WebShopTicketsComponent.prototype.handleParkingChange = function (since, until, uniqueId) {
        var untilForm = this.parkingTicketsForm.get(uniqueId + ".until");
        var sinceForm = this.parkingTicketsForm.get(uniqueId + ".since");
        if (!since) {
            untilForm.setValue(null);
            return;
        }
        if (since < this.dateToday) {
            since = this.addHour(this.dateToday);
            sinceForm.setValue(since);
        }
        var splitedUniqueId = uniqueId.split('_');
        var uniqueTicket = splitedUniqueId[0] + "_" + splitedUniqueId[1];
        //do this only if we have a current ticket and if current parking ticket form is valid:
        //(createAdditionalParkingFormControls function will iterate through all parking tickets and request a parking fee for each ticket from the API)
        if (!!this.ungroupedTickets && !!this.ungroupedTickets[uniqueTicket] && this.parkingTicketsForm.get("" + uniqueId).valid) {
            this.createAdditionalParkingFormControls(this.ungroupedTickets[uniqueTicket], this.getParkingTicketsFromLocalStorage());
        }
    };
    WebShopTicketsComponent.prototype.createAdditionalParkingFormControls = function (ungroupedTicket, initialParkingTicketsValues) {
        var _this = this;
        if (ungroupedTicket.parking) {
            this.setValidation(false, ['parking', '']);
            this.removeAdditionalTicketFormControls(this.parkingTicketsForm, ungroupedTicket, this.updateTotalParkingPrice);
            var ticketUniqueId_1 = ungroupedTicket.uniqueId;
            var ticketCountArray = this.getArrayFromTicketCount(ungroupedTicket.count);
            ticketCountArray.forEach(function (countIndex) {
                var newFormControlName = ticketUniqueId_1 + "_" + countIndex;
                var hasSinceInStore = initialParkingTicketsValues &&
                    initialParkingTicketsValues[newFormControlName] &&
                    initialParkingTicketsValues[newFormControlName].since;
                var hasUntilInStore = initialParkingTicketsValues &&
                    initialParkingTicketsValues[newFormControlName] &&
                    initialParkingTicketsValues[newFormControlName].until;
                var since = hasSinceInStore
                    ? new Date(initialParkingTicketsValues[newFormControlName].since)
                    : null;
                var until = hasUntilInStore
                    ? new Date(initialParkingTicketsValues[newFormControlName].until)
                    : null;
                var parkingDatesFormGroup = _this.fb.group({
                    since: [since, Validators.required],
                    until: [until, Validators.required]
                });
                _this.parkingTicketsForm.addControl(newFormControlName, parkingDatesFormGroup);
                _this.isParkingFormValid(newFormControlName, initialParkingTicketsValues, since, until);
            });
        }
    };
    WebShopTicketsComponent.prototype.isParkingFormValid = function (formId, parkingTickets, since, until) {
        var _this = this;
        var ticketKeyData = formId.split('_');
        var ticketGroup = Number(ticketKeyData[0]);
        var ticketPerson = Number(ticketKeyData[1]);
        var untilForm = this.parkingTicketsForm.get(formId + ".until");
        if (this.parkingTicketsForm.get("" + formId).valid) {
            if (until <= since) {
                until = this.addHour(since);
                untilForm.setValue(until);
            }
            var validFormId_1 = formId;
            var uniqueId_1 = ticketGroup + "_" + ticketPerson;
            this.isParkingTicketLoading = true;
            this._ticketsService
                .getParkingTicketFee(this.exhibitionSettings.eventId, ticketGroup, ticketPerson, this._helperService.toStringWithoutOffset(since), this._helperService.toStringWithoutOffset(until), validFormId_1)
                .subscribe(function (response) {
                var ticketPrice = response.body;
                parkingTickets[validFormId_1]['price'] = ticketPrice;
                parkingTickets[validFormId_1]['since'] = since;
                parkingTickets[validFormId_1]['until'] = until;
                setLocalStorageObject(AppConstants.parkingTicketsReducer, parkingTickets);
                var index = _this.invalidParkingTickets.indexOf(uniqueId_1);
                if (index > -1) {
                    _this.invalidParkingTickets.splice(index, 1);
                }
                if (!_this.invalidParkingTickets.length && _this.parkingTicketsForm.valid) {
                    _this.setValidation(true, ['parking', '']);
                }
                _this.isParkingTicketLoading = false;
                _this.updateTotalParkingPrice();
            }, function () {
                _this.setValidation(false, ['parking', '']);
                _this.isParkingTicketLoading = false;
                _this.parkingTicketsForm.get(formId + ".since").reset();
                _this.parkingTicketsForm.get(formId + ".until").reset();
            });
        }
    };
    WebShopTicketsComponent.prototype.createAdditionalDayFormControls = function (ungroupedTicket, initialDayTicketsValues) {
        var _this = this;
        if (ungroupedTicket.days) {
            this.removeAdditionalTicketFormControls(this.contingentTicketsForm, ungroupedTicket);
            var ticketUniqueId_2 = ungroupedTicket.uniqueId;
            var ticketCountArray = this.getArrayFromTicketCount(ungroupedTicket.count);
            ticketCountArray.forEach(function (countIndex) {
                var newFormControlName = ticketUniqueId_2 + "_" + countIndex;
                var hasDayInStore = initialDayTicketsValues &&
                    initialDayTicketsValues[newFormControlName] &&
                    initialDayTicketsValues[newFormControlName].day;
                var day = hasDayInStore
                    ? new Date(initialDayTicketsValues[newFormControlName].day)
                    : null;
                if (ungroupedTicket.hasDaySellLimit && !ungroupedTicket.shouldCalendarBeDisplayed) {
                    var today = new Date();
                    var validFromDate = new Date(ungroupedTicket.validFrom);
                    day = validFromDate < today ? today : validFromDate;
                }
                var bookingDaysFormGroup = _this.fb.group({
                    day: [day, Validators.required]
                });
                _this.contingentTicketsForm.addControl(newFormControlName, bookingDaysFormGroup);
            });
        }
    };
    WebShopTicketsComponent.prototype.addHour = function (date) {
        if (!date)
            return null;
        var copiedDate = new Date(date.getTime());
        copiedDate.setHours(copiedDate.getHours() + 1);
        return copiedDate;
    };
    WebShopTicketsComponent.prototype.toggleSectionAccordian = function (event, section) {
        var element = event.target;
        if (element.classList.contains('accordion')) {
            element.classList.toggle('active');
        }
        section.expanded = !section.expanded;
    };
    WebShopTicketsComponent.prototype.toggleWorkshopAccordian = function (event, workshop) {
        workshop.expanded = !workshop.expanded;
    };
    WebShopTicketsComponent.prototype.toggleTicketAccordian = function (event, ticketVersion) {
        var element = event.target;
        if (element.classList.contains('accordion')) {
            element.classList.toggle('active');
        }
        if (ticketVersion.infoExpanded != true &&
            ticketVersion.infoExpanded != false) {
            ticketVersion.infoExpanded =
                this._translateService.instant(ticketVersion.infoExpanded) == 'true';
        }
        ticketVersion.infoExpanded = !ticketVersion.infoExpanded;
    };
    WebShopTicketsComponent.prototype.canToggle = function (ticketVersion) {
        return (ticketVersion.info &&
            this._translateService.instant(ticketVersion.info) !== AppConstants.MISSING_TRANSLATION);
    };
    WebShopTicketsComponent.prototype.ticketLoading = function (ticketLoading) {
        this.isTicketBookingLoading = ticketLoading;
    };
    WebShopTicketsComponent.prototype.getParkingTicketsFromLocalStorage = function () {
        var storedParkingTickets = getLocalStorageString(AppConstants.parkingTicketsReducer);
        if (!storedParkingTickets) {
            setLocalStorageObject(AppConstants.parkingTicketsReducer, '');
        }
        var parsedParkingTickets = storedParkingTickets && JSON.parse(storedParkingTickets);
        return parsedParkingTickets;
    };
    WebShopTicketsComponent.prototype.getContingentTicketsFromLocalStorage = function () {
        var storedContingentTickets = getLocalStorageString(AppConstants.contingentTicketsReducer);
        if (!storedContingentTickets) {
            setLocalStorageObject(AppConstants.contingentTicketsReducer, '');
        }
        var parsedContingentTickets = storedContingentTickets && JSON.parse(storedContingentTickets);
        return parsedContingentTickets;
    };
    WebShopTicketsComponent.prototype.getArrayFromTicketCount = function (count) {
        return Array.from({ length: count }, function (val, i) { return i; });
    };
    WebShopTicketsComponent.prototype.isSectionVisible = function (section) {
        var _this = this;
        return section.productGroups.some(function (product) {
            return _this.isTicketGroupVisible(product);
        });
    };
    WebShopTicketsComponent.prototype.isTicketGroupVisible = function (product) {
        var _this = this;
        return product.products.some(function (product) {
            if (!!product.ticket) {
                return _this.isTicketVisible(product.ticket);
            }
            if (!!product.package && !_this.isSelfRegistrationEnabled) {
                return product.package.contents.some(function (packageContent) {
                    return packageContent.packageGroups.some(function (packageGroup) {
                        return packageGroup.products.some(function (product) { return _this.isTicketVisible(product.ticket); });
                    });
                });
            }
        });
    };
    WebShopTicketsComponent.prototype.isTicketVisible = function (ticket) {
        if (!ticket)
            return false;
        if (this.isSelfRegistrationEnabled && this.hasVoucher) {
            return !!ticket.voucherCode;
        }
        else {
            if (ticket.isVisible)
                return true;
            if (ticket.isVoucher && ticket.voucherCode)
                return true;
            return false;
        }
    };
    WebShopTicketsComponent.prototype.loadSections = function () {
        var _this = this;
        this._store
            .pipe(select(fromRoot.getTicketsTypes), filter(function (data) { return !!data; }))
            .first()
            .subscribe(function (data) {
            var ticketGroups = data.map(function (ticketGroup) {
                return ticketGroup;
            });
            _this.hasVoucher = ticketGroups.some(function (group) {
                return group.products.some(function (p) {
                    if (p.ticket == null) {
                        return false;
                    }
                    return !!p.ticket.voucherCode;
                });
            });
            _this.productGroups = ticketGroups.slice();
            _this.sections = _this.productGroups.reduce(function (r, _a) {
                var section = _a.section;
                if (!r.some(function (o) { return o.sectionIndex === section.sectionIndex; })) {
                    r.push({
                        sectionId: section.sectionId,
                        sectionIndex: section.sectionIndex,
                        sectionName: section.sectionName,
                        groupDescription: section.groupDescription,
                        expanded: section.expanded,
                        productGroups: _this.productGroups.filter(function (v) { return v.section.sectionIndex === section.sectionIndex; })
                    });
                }
                return r;
            }, []);
        });
    };
    WebShopTicketsComponent.prototype.setStepsVisibility = function (isVisible) {
        var _this = this;
        var stepsArray = [];
        if (!isVisible) {
            Object.keys(this.stepsValidity).forEach(function (key) {
                if (_this.stepsValidity[key].visible
                    && key !== 'tickets' && key !== 'invoice') {
                    stepsArray.push(key);
                }
            });
        }
        else {
            stepsArray = this.hiddenSteps.slice();
        }
        if (this.hiddenSteps.length < 1) {
            this._store.dispatch(new stepsActions.SetAnonymousHiddenSteps(stepsArray));
        }
        var visibilityPayload = stepsArray.map(function (step) {
            return {
                stepKey: step,
                visible: isVisible
            };
        });
        this._store.dispatch(new stepsActions.SetStepsVisibility(visibilityPayload));
    };
    WebShopTicketsComponent.prototype.checkForSelectedAnonymousTicketAndAdjustSteps = function () {
        if (!!this.ungroupedTickets) {
            // checking if anonymous ticket is taken
            this.hasAnonymous = this._ticketsService.checkIfAnonymousTicketTaken(this.ungroupedTickets);
            this.setStepsVisibility(!this.hasAnonymous);
        }
    };
    WebShopTicketsComponent.prototype.toggleDetail = function (activeWorkshopId) {
        this.toggledWorkshop = this.workshops.find(function (workshop) { return workshop.workshopId === activeWorkshopId; });
        this.workshopModalWindowYOffset = window.pageYOffset + 100;
    };
    WebShopTicketsComponent.prototype.convertFromDate = function (text) {
        var dateString = text + "T00:00:00.000Z";
        return new Date(dateString);
    };
    WebShopTicketsComponent.prototype.ngAfterViewInit = function () {
        this._gtmService.pushProductDetail();
        this.handleContingentTicketChange();
    };
    WebShopTicketsComponent.prototype.getParkingTicketPrice = function (ticketVersion) {
        var parsedParkingTickets = this.getParkingTicketsFromLocalStorage();
        var price = 0;
        if (parsedParkingTickets) {
            Object.keys(parsedParkingTickets).forEach(function (key) {
                var ticket = parsedParkingTickets[key];
                if (key.startsWith(ticketVersion.uniqueId) && ticket.price) {
                    price += ticket.price;
                }
            });
        }
        return price;
    };
    WebShopTicketsComponent.prototype.packageCounterWarningDefinition = function (data, productPackage) {
        this.packageCounterWarningString = data;
        if (!!data) {
            productPackage.warning = true;
        }
        else {
            this.productGroups.forEach(function (pg) {
                pg.products.forEach(function (pgProduct) {
                    if (pgProduct.package) {
                        delete pgProduct.package.warning;
                    }
                });
            });
        }
    };
    return WebShopTicketsComponent;
}());
export { WebShopTicketsComponent };
