import { filter, first, takeUntil } from 'rxjs/operators';
import * as fromRoot from '../../../../app.reducer';

import { FormGroup } from '@angular/forms';
import {
  Component,
  Input,
  Output,
  OnDestroy,
  OnInit,
  EventEmitter
} from '@angular/core';
import { FormInputsPayloadModel } from '../../../../shared/services-with-reducers/step-forms/step.interface';

import { FormsService } from '../../../../shared/forms/forms.service';
import { InputBase } from '../../../../shared/forms/inputs/input-base.class';
import { select, Store } from '@ngrx/store';
import { Subscription, Subject, combineLatest } from 'rxjs';

@Component({
  moduleId: module.id,
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, OnDestroy {
  @Input()
  formPath: string[];
  @Output()
  updatedTicketHolderInputs = new EventEmitter<{ inputs: InputBase<any>[], currentIndex: number }>();
  @Output()
  handleFormValueChange = new EventEmitter<any>();

  public inputs: InputBase<any>[];
  public form: FormGroup;
  private _unsubscribe = new Subject<void>();
  private _formChangeSubscribe: Subscription;
  public countries;
  public index: number;

  constructor(
    private _formsService: FormsService,
    private _store: Store<fromRoot.State>
  ) { }

  ngOnInit() {
    this.index = !!this.formPath && this.formPath.length > 1 && this.formPath[1].includes('_') 
      ? parseInt(this.formPath[1].split('_')[1]) 
      : 0;

    this._store
      .pipe(select(fromRoot.getAllCountriesList), first()).subscribe(list => {
        this._formsService.translateCountries(list).subscribe(subs => {
          subs.subscribe(countries => {
            this.countries = countries;
          });
        });
      });

    combineLatest(
      this._store.select(fromRoot.getTicketHolderInputSets),
      this._store.select(fromRoot.uniqueVisitorCheckType),
      this._store.select(fromRoot.getSkipHoldersCheck)
    )
      .pipe(
        takeUntil(this._unsubscribe),
        filter(
          ([ticketHolderInputSets, uniqueVisitorCheckType, skipHoldersCheck]) =>
            !!ticketHolderInputSets && !!uniqueVisitorCheckType && !skipHoldersCheck
        )
      )
      .subscribe(
        ([ticketHolderInputSets]: [FormInputsPayloadModel[], string, boolean]) => {
          const thisFormInputs = ticketHolderInputSets.find(inputSet => {
            return inputSet.formInfo.join('.') === this.formPath.join('.');
          });

          if (
            thisFormInputs &&
            thisFormInputs.inputSet &&
            thisFormInputs.inputSet.list.length
          ) {

            if (!!this.countries) {
              thisFormInputs.inputSet.list.forEach(item => {
                if (item.key === "country") {
                  item.options = this.countries;
                }
              });
            }

            this.inputs = this._formsService.updateInputs(
              this.inputs,
              thisFormInputs.inputSet.list
            );

            this.updatedTicketHolderInputs.emit({inputs: this.inputs, currentIndex: this.index});
            if (thisFormInputs.inputSet.rerender || !this.form) {
              this.form = this._formsService.toFormGroup(
                thisFormInputs.inputSet.list,
                this.formPath
              );
            }
          }

          if (this._formChangeSubscribe) {
            this._formChangeSubscribe.unsubscribe();
            this._formChangeSubscribe = null;
          }

          this._formChangeSubscribe = this.form.valueChanges
            .pipe(takeUntil(this._unsubscribe))
            .subscribe(() => {
              this.handleFormValueChange.emit(this);
            });
        }
      );
  }

  ngOnDestroy() {
    // once removed from screen, remove the validation
    this._formsService.removeFormsValidationFeedback(this.formPath);
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
