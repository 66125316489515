import { OnInit, OnDestroy } from '@angular/core';
import * as fromRoot from '../../app.reducer';
import { select, Store } from '@ngrx/store';
import { Subscription, combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
var BottomStickyComponent = /** @class */ (function () {
    function BottomStickyComponent(_store, _translateService) {
        var _this = this;
        this._store = _store;
        this._translateService = _translateService;
        this.textWithLink = '';
        this.isCookiePopupEnabled = false;
        this.isLoggedInAsAdmin = false;
        this.checkCookiePopupStatus = true;
        this._subscriptions = new Subscription();
        this._subscriptions.add(combineLatest([
            this._store.pipe(select(fromRoot.isLoggedInAsAdmin)),
            this._store.pipe(select(fromRoot.getSelfRegistration))
        ]).subscribe(function (_a) {
            var isLoggedInAsAdmin = _a[0], isSelfRegistrationEnabled = _a[1];
            _this.checkCookiePopupStatus = !isLoggedInAsAdmin && !isSelfRegistrationEnabled;
            _this.isLoggedInAsAdmin = isLoggedInAsAdmin;
        }));
    }
    BottomStickyComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.checkCookiePopupStatus) {
            this._subscriptions.add(combineLatest([
                this._store.pipe(select(fromRoot.isLoggedInAsAdmin)),
                this._store.pipe(select(fromRoot.getSelectedExhibitionId))
            ])
                .subscribe(function () {
                _this.isCookiePopupEnabled = false;
            }));
            this._subscriptions.add(this._store
                .pipe(select(fromRoot.getTranslations), filter(function (translation) { return !!translation; }))
                .subscribe(function (translation) {
                if (!_this.isLoggedInAsAdmin) {
                    var cookiePopupText_1 = translation.values['cookies.popup.text'];
                    if (!cookiePopupText_1) {
                        _this._translateService
                            .get('cookies.popup.text')
                            .subscribe(function (defaultCookiePopupText) {
                            cookiePopupText_1 = defaultCookiePopupText;
                        });
                    }
                    _this.isCookiePopupEnabled = !!cookiePopupText_1 ? !cookiePopupText_1.startsWith('-notshown-') : false;
                    if (_this.isCookiePopupEnabled) {
                        var linkTextOnly = cookiePopupText_1.match(/<.*?>/);
                        if (linkTextOnly) {
                            var link = "<a href=\"#\" target=\"_blank\">" + linkTextOnly[0].slice(1, -1) + "</a>";
                            _this.textWithLink = cookiePopupText_1.replace(linkTextOnly[0], link);
                        }
                        else {
                            _this.textWithLink = cookiePopupText_1;
                        }
                    }
                }
            }));
        }
    };
    BottomStickyComponent.prototype.ngOnDestroy = function () {
        this._subscriptions.unsubscribe();
    };
    return BottomStickyComponent;
}());
export { BottomStickyComponent };
