var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { ActionTypes } from './exhibition.actions';
import { createSelector } from 'reselect';
export var initialState = {
    allExhibitions: [],
    selectedExhibitionId: null,
    allCountries: [],
    exhibitionHistoryList: [],
    doubleClickUrls: null,
    genericScripts: null,
    firstLoadDone: false,
    titles: null,
    professions: null,
    departments: null,
    occupationalGroups: null,
    allTitles: null,
    allProfessions: null,
    allDepartments: null,
    allOccupationalGroups: null
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ActionTypes.SELECT:
            var exhibitionID = !!action.payload && !isNaN(Number(action.payload)) ? Number(action.payload) : null;
            // dont trigger new state if the id is the same as previous one
            if (state.selectedExhibitionId === exhibitionID) {
                return state;
            }
            return __assign({}, state, { selectedExhibitionId: exhibitionID });
        case ActionTypes.ADD_ALL_EXHIBITIONS_TO_LIST: {
            var exhibitions = action.payload;
            return __assign({}, state, { allExhibitions: exhibitions });
        }
        case ActionTypes.SET_EXHIBITION_HISTORY_LIST: {
            return __assign({}, state, { exhibitionHistoryList: action.payload });
        }
        case ActionTypes.FIRST_LOAD_DONE: {
            return __assign({}, state, { firstLoadDone: true });
        }
        case ActionTypes.SET_DOUBLE_CLICK_SCRIPTS: {
            var doubleClickUrls = action.payload;
            return __assign({}, state, { doubleClickUrls: doubleClickUrls });
        }
        case ActionTypes.SET_GENERIC_SCRIPTS: {
            var genericScripts = action.payload;
            return __assign({}, state, { genericScripts: genericScripts });
        }
        case ActionTypes.SET_TITLES: {
            var titles = action.payload;
            return __assign({}, state, { titles: titles });
        }
        case ActionTypes.SET_PROFESSIONS: {
            var professions = action.payload;
            return __assign({}, state, { professions: professions });
        }
        case ActionTypes.SET_DEPARTMENTS: {
            var departments = action.payload;
            return __assign({}, state, { departments: departments });
        }
        case ActionTypes.SET_OCCUPATIONAL_GROUPS: {
            var occupationalGroups = action.payload;
            return __assign({}, state, { occupationalGroups: occupationalGroups });
        }
        case ActionTypes.SET_ALL_TITLES: {
            var allTitles = action.payload;
            return __assign({}, state, { allTitles: allTitles });
        }
        case ActionTypes.SET_ALL_PROFESSIONS: {
            var allProfessions = action.payload;
            return __assign({}, state, { allProfessions: allProfessions });
        }
        case ActionTypes.SET_ALL_DEPARTMENTS: {
            var allDepartments = action.payload;
            return __assign({}, state, { allDepartments: allDepartments });
        }
        case ActionTypes.SET_ALL_OCCUPATIONAL_GROUPS: {
            var allOccupationalGroups = action.payload;
            return __assign({}, state, { allOccupationalGroups: allOccupationalGroups });
        }
        case ActionTypes.RESET_REDUCER:
            return initialState;
        case ActionTypes.PARTIAL_RESET_REDUCER:
            return __assign({}, initialState, { allExhibitions: state.allExhibitions, allTitles: state.allTitles, allProfessions: state.allProfessions, allDepartments: state.allDepartments, allOccupationalGroups: state.allOccupationalGroups, doubleClickUrls: state.doubleClickUrls });
        default: {
            return state;
        }
    }
}
/**
 * Because the data structure is defined within the reducer it is optimal to
 * locate our selector functions at this level. If store is to be thought of
 * as a database, and reducers the tables, selectors can be considered the
 * queries into said database. Remember to keep your selectors small and
 * focused so they can be combined and composed to fit each particular
 * use-case.
 */
export var getAllExhibitions = function (state) { return state.allExhibitions; };
export var getDoubleClickScripts = function (state) { return state.doubleClickUrls; };
export var getGenericScripts = function (state) { return state.genericScripts; };
export var isFirstLoad = function (state) { return !state.firstLoadDone; };
export var getSelectedExhibitionId = function (state) {
    return state.selectedExhibitionId;
};
export var getExhibitionHistoryList = function (state) {
    return state.exhibitionHistoryList;
};
export var getSelected = createSelector(getAllExhibitions, getSelectedExhibitionId, function (allExhibitions, selectedId) {
    return allExhibitions.find(function (exhibition) {
        return exhibition.id === Number(selectedId);
    });
});
export var getAllCountries = function (state) { return state.allCountries; };
export var getTitles = function (state) { return state.titles; };
export var getProfessions = function (state) { return state.professions; };
export var getDepartments = function (state) { return state.departments; };
export var getOccupationalGroups = function (state) { return state.occupationalGroups; };
export var getAllTitles = function (state) { return state.allTitles; };
export var getAllProfessions = function (state) { return state.allProfessions; };
export var getAllDepartments = function (state) { return state.allDepartments; };
export var getAllOccupationalGroups = function (state) { return state.allOccupationalGroups; };
