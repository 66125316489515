<div class="style-list-wrap" [class.expanded]="expanded">
  <div class="puller" (click)="expanded = !expanded">
    <span class="arrow"></span>
  </div>
  <ul class="style-list">
    <div class="style-list-title">{{ selectedTemplateTitle }}</div>
    <li
      class="list-item"
      *ngFor="let styleItem of styleList | keys"
      (mouseenter)="mouseOver(styleItem.key)"
      (mouseleave)="mouseLeave()"
    >
      <div class="style-key">{{ styleItem.key }}</div>
      <div *ngIf="allSkinVariables">
        <input
          *ngIf="styleItem.value.inputType === 'color'"
          class="style-input"
          [colorPicker]="allSkinVariables[styleItem.key]"
          (colorPickerChange)="inputChanged(styleItem.key, $event)"
          (cpToggleChange)="togglePicker($event)"
          [style.background]="allSkinVariables[styleItem.key]"
          [value]="allSkinVariables[styleItem.key]"
          [cpPosition]="'left'"
          (change)="checkIfEmpty($event, styleItem.key)"
          [cpOutputFormat]="'rgba'"
          [cpAlphaChannel]="'always'"
          [cpOKButton]="true"
          [cpOKButtonText]="'OK'"
          [cpOKButtonClass]="'color-picker-ok-button'"
          [cpCancelButton]="true"
          [cpCancelButtonText]="'Cancel'"
          [cpCancelButtonClass]="'color-picker-cancel-button'"
          readonly="readonly"
        />
        <input
          *ngIf="styleItem.value.inputType === 'text'"
          class="style-input"
          (change)="inputChanged(styleItem.key, $event.target.value)"
          [value]="allSkinVariables[styleItem.key]"
        />
      </div>
    </li>
  </ul>
  <div class="admin-control">
    <button
      class="admin-button"
      (click)="openModalWindow('update')"
      [attr.data-translation]="'translations.button.update'"
    >
      {{ 'translations.button.update' | translate | hideTranslation }}
    </button>
    <button
      class="admin-button"
      (click)="openModalWindow('load')"
      [attr.data-translation]="'translations.button.load'"
    >
      {{ 'translations.button.load' | translate | hideTranslation }}
    </button>
  </div>
  <div class="admin-control" *ngIf="eventType">
    <button
      class="admin-button"
      (click)="openModalWindow('create')"
      [attr.data-translation]="'translations.button.create'"
    >
      {{ 'translations.button.create' | translate | hideTranslation }}
    </button>
    <button
      class="admin-button"
      (click)="openModalWindow('save')"
      [attr.data-translation]="'translations.button.save.' + eventType"
    >
      {{
        'translations.button.save.' + eventType | translate | hideTranslation
      }}
    </button>
  </div>
  <app-modal-window
    [(modalWindowActive)]="modalWindowOpen"
    [modalWindowTitle]="modalTitle"
    [translateTitle]="false"
  >
    <div modalWindow-body *ngIf="modalWindowType === 'load'">
      <div
        class="tile"
        *ngFor="let template of allStyleTemplates$ | async"
        (click)="preselectTile(template)"
        [ngClass]="{
          highlight: preselectedTemplate?.id === template.id
        }"
      >
        <div class="tile-title">{{ template.title }}</div>
      </div>
    </div>
    <div modalWindow-body *ngIf="modalWindowType === 'create'">
      <input
        class="create-template-input"
        [(ngModel)]="templateTitle"
        type="text"
        name="text"
        placeholder="Name of the template"
        required
      />
    </div>
    <div modalWindow-body *ngIf="modalWindowType === 'save'">
      <span
        class="save-template-warning"
        [attr.data-translation]="'modalWindow.colorizer.warning'"
        [translate]="'modalWindow.colorizer.warning'"
        [translateParams]="{
          transaltionEventTitle: transaltionEventTitle,
          templateTitle: selectedTemplateTitle
        }"
      >
      </span>
    </div>
    <button
      *ngIf="modalWindowType === 'load'"
      modalWindow-submit
      (click)="submitModalWindow($event)"
      class="button"
      [disabled]="!preselectedTemplate"
    >
      <span [attr.data-translation]="'modalWindow.' + modalWindowType">{{
        'modalWindow.' + modalWindowType | translate | hideTranslation
      }}</span>
    </button>
    <button
      *ngIf="modalWindowType === 'create'"
      modalWindow-submit
      (click)="submitModalWindow($event)"
      class="button"
      [disabled]="!templateTitle"
    >
      <span [attr.data-translation]="'modalWindow.' + modalWindowType">{{
        'modalWindow.' + modalWindowType | translate | hideTranslation
      }}</span>
    </button>
    <button
      *ngIf="modalWindowType !== 'create' && modalWindowType !== 'load'"
      modalWindow-submit
      (click)="submitModalWindow($event)"
      class="button"
    >
      <span [attr.data-translation]="'modalWindow.' + modalWindowType">{{
        'modalWindow.' + modalWindowType | translate | hideTranslation
      }}</span>
    </button>
    <button
      modalWindow-close
      (click)="closeModalWindow($event)"
      class="button button-option"
      style="color:currentColor;"
    >
      <span data-translation="modalWindow.close">{{
        'modalWindow.close' | translate | hideTranslation
      }}</span>
    </button>
  </app-modal-window>
</div>
