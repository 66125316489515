<app-banner></app-banner>

<div class="container-fluid">
  <div class="page-content">
    <app-loader *ngIf="!productGroups"></app-loader>

    <div class="page-padding-wrapper" *ngIf="productGroups && exhibitionSettings">
      <ng-container>
        <!-- <div
                    *ngIf="
                        !(
                            'ticket-selection.top-info-text'
                            | translate
                            | isHiddenTranslation
                        )
                    "
                    class="top-info-box"
                    data-translation="ticket-selection.top-info-text"
                    data-translation-includes-children
                    [innerHTML]="'ticket-selection.top-info-text' | translate"
                ></div> -->

        <div class="ticket-section">
          <div>
            <div *ngFor="let productGroup of productGroups" appAdjustSideHeadline>
              <div class="section-headline">
                <h2>
                  {{ productGroup.groupName | translate | hideTranslation }}
                </h2>

                <div class="section-headline-side">←</div>
              </div>

              <div
                class="section-body"
                [ngClass]="{
                  'ng-custom-invalid': !ticketSelectionIsValid
                }"
                *ngIf="ungroupedTickets"
              >
                <div
                  class="ticket-type"
                  *ngFor="let product of  productGroup.products"
                >
                  <div *ngIf="product.ticket != null" class="ticket-info row col-sm-8">
                    <div class="ticket-text col-sm-6">
                      <span class="title black">
                        {{
                          product.ticket.ticketName | translate | hideTranslation
                        }}
                      </span>
                    </div>

                    <div class="ticket-text col-sm-6 col-lg-6">
                      <span
                        *ngIf="
                          exhibitionSettings.currencySettings.currencyCode ===
                          'CHF'
                        "
                        class="price"
                      >
                        {{
                          product.ticket.price
                            | currencySymbolGap
                              : exhibitionSettings.currencySettings
                        }}
                      </span>

                      <span
                        *ngIf="
                          exhibitionSettings.currencySettings.currencyCode !==
                          'CHF'
                        "
                        class="price"
                      >
                        {{
                          product.ticket.price.toFixed(2)
                            | currencySymbolGap
                              : exhibitionSettings.currencySettings
                        }}
                      </span>
                    </div>
                  </div>

                  <app-counter
                    class="col-sm-4"
                    *ngIf="ungroupedTickets[product.ticket.uniqueId]"
                    ticketType="normal"
                    [ticketUniqueId]="product.ticket.uniqueId"
                    [numberOfAllTickets]="numberOfSelectedTickets"
                    [maxLimit]="maxTicketLimit"
                    [counterValue]="
                      ungroupedTickets[product.ticket.uniqueId].count
                    "
                    (counterChange)="
                      counterChange($event, product.ticket.uniqueId)
                    "
                    (ticketLoading)="ticketLoading($event)"
                    [workshopsSoldOut]="
                      ungroupedTickets[product.ticket.uniqueId]
                        .allowedWorkshopsFull
                    "
                    [workshopSeatsAvailable]="
                      workshopsList && workshopsList[product.ticket.uniqueId]
                    "
                    [ticketSoldOut]="
                      (isTicketSoldOut$ | async)[product.ticket.uniqueId] ||
                      (!ungroupedTickets[product.ticket.uniqueId]
                        .availableTickets &&
                        ungroupedTickets[product.ticket.uniqueId]
                          .availableTickets !== null)
                    "
                    [numOfAvailableTickets]="
                      (availableTicketsCombined$ | async) &&
                      (availableTicketsCombined$ | async)[
                        product.ticket.uniqueId
                      ]
                    "
                    [totalAvailableTickets]="
                      (totalAvailableTickets$ | async) &&
                      (totalAvailableTickets$ | async)[product.ticket.uniqueId]
                    "
                    [percentageOfAvailableTickets]="
                      (percentageOfAvailableTickets$ | async) &&
                      (percentageOfAvailableTickets$ | async)[
                        product.ticket.uniqueId
                      ]
                    "
                    [isTicketBookingLoading]="isTicketBookingLoading"
                    [workshopsMandatory]="
                      exhibitionSettings.isWorkshopsSelectionMandatory
                    "
                    [hasWorkshops]="
                      ungroupedTickets[product.ticket.uniqueId].allowedWorkshops
                        .length > 0
                    "
                  ></app-counter>
                </div>
              </div>
            </div>

            <hr />

            <div class="confirmation-total">
              <h3 class="total black" data-translation="ticket-selection.total">
                {{ 'ticket-selection.total' | translate | hideTranslation }}:
                <span class="price">
                  {{
                    totalPrice.toFixed(2)
                      | currencySymbolGap: exhibitionSettings.currencySettings
                  }}
                </span>
              </h3>
            </div>

            <!-- <app-sending-options
                            [ticketCounter]="ticketCounter"
                            [exhibitionSettings]="exhibitionSettings"
                        ></app-sending-options> -->

            <div class="feedback-button">
              <button
                [ngClass]="{
                  'feedback-button-disabled': isButtonDisabled
                }"
                (click)="onContinueClick($event)"
                class="continue-button button"
                type="button"
              >
                <span
                  class="continue-button-text"
                  data-translation="step-navigation.continue-button"
                  >{{
                    'step-navigation.continue-button'
                      | translate
                      | hideTranslation
                  }}</span
                >
              </button>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
