<div class="counter">
  <div class="counter-wrapper">
    <button
      class="counter-button"
      type="button"
      (click)="onDecrease()"
      [ngClass]="{ isPackageLoading: isPackageLoading }"
      [disabled]="!package.count"
    >
      <span>-</span>
    </button>
    <div class="selector-value">
      <div *ngIf="isPackageLoading" class="button-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <input
        *ngIf="!isPackageLoading"
        appNumbersOnly
        type="number"
        [(ngModel)]="package.count"
        (change)="onValueChange($event)"
        class="counter-input"
        [disabled]="true"
      />
    </div>
    <button
      class="counter-button"
      type="button"
      (click)="onIncrease()"
      [ngClass]="{ isPackageLoading: isPackageLoading }"
      [disabled]="package.isDisabled"
    >
      <span>+</span>
    </button>
  </div>
</div>