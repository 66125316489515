import * as fromRoot from '../../app.reducer';

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private _store: Store<fromRoot.State>) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let authToken = null;

    combineLatest(
      this._store.select(fromRoot.getUser),
      this._store.select(fromRoot.getProfile)
    )
      .filter(data => {
        return !!data[0] || (data[1] && data[1].hasOwnProperty('authToken'));
      })
      .first()
      .subscribe(data => {
        const [user, profile] = data;
        if (user) {
          authToken = user.authToken;
        } else if (profile.hasOwnProperty('authToken')) {
          authToken = profile.authToken;
        }
      });

    if (authToken) {
      const authReq = req.clone({
        headers: req.headers.set('Authorization', authToken)
      });
      return next.handle(authReq);
    }

    return next.handle(req);
  }
}
