var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { debounceTime, distinctUntilChanged, filter, first, takeUntil } from 'rxjs/operators';
import * as fromRoot from '../../../app.reducer';
import { AfterViewInit, ElementRef, EventEmitter, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { AppConstants } from '../../app-constants';
import { FormGroup } from '@angular/forms';
import { FormsService } from '../forms.service';
import { HelperService } from '../../services-with-reducers/helpers/helper.service';
import { InputBase } from '../inputs/input-base.class';
import { select, Store } from '@ngrx/store';
import cloneDeep from 'lodash.clonedeep';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { getLocaleDateFormat } from '@angular/common';
import { TextOrDropdownInputTypes } from '../../services-with-reducers/helpers/helper.interface';
import { ValidationService } from '../../validation/validation.service';
var DfInputComponent = /** @class */ (function () {
    /*
      NOTE:
      If you experience unexpected value, status, validation changes,
      in most cases input and inputSet is MUTATED in the parent component but
      input / inputSet (properties) in some cases is replaced by IMMUTABLE object (in df-input.component / parent component)
    */
    function DfInputComponent(_formsService, _helperService, _store, el, renderer, _validationService) {
        this._formsService = _formsService;
        this._helperService = _helperService;
        this._store = _store;
        this.el = el;
        this.renderer = renderer;
        this._validationService = _validationService;
        this.showCalendarButton = true;
        this.onSubmit = new EventEmitter();
        this.onInputChanged = new EventEmitter();
        this.isLoading = false;
        this.addressFocused = null;
        this.valueChanged$ = new BehaviorSubject(null);
        this._unsubscribe = new Subject();
        this.minYearRange = 1900;
        this.maxYearRange = new Date().getFullYear();
        this.minDate = new Date(this.minYearRange, 0, 1);
        this.maxDate = new Date();
        this.zipCodeCities = [];
        this.showZipCodeCities = false;
        this.numberOfIntervalReps = 20;
        this.subscriptions = new Subscription();
        this.hasTextOrDropdownTypeOption = false;
    }
    DfInputComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.formPath = this.action.join('.');
        this.hasTextOrDropdownTypeOption = Object.keys(TextOrDropdownInputTypes)
            .map(function (typeKey) { return TextOrDropdownInputTypes[typeKey]; })
            .includes(this.input.key);
        this.datePickerUniqueClass = this.action.length > 1 ? this.action.join('_') : this.action[0];
        this.isDateInput = this.input.controlType === 'dateInput';
        if (this.isDateInput) {
            this._store.pipe(select(fromRoot.getLanguage)).subscribe(function (activeLanguage) {
                _this.dateFormat = activeLanguage === 'en' ? 'dd.mm.yy' : getLocaleDateFormat(activeLanguage, 0).toLowerCase();
            });
        }
        this.getCountriesAndTranslate();
        this.valueChanged$
            .pipe(takeUntil(this._unsubscribe), filter(function (data) { return !!data && !!data.target; }), distinctUntilChanged(function (_prevValue, currValue) {
            return currValue.target.value === _this.input.value;
        }))
            .subscribe(function (newValue) {
            _this.onInputChanged.emit(_this.input.key);
        });
        this.valueChanged$
            .pipe(takeUntil(this._unsubscribe), filter(function (data) { return !!data && !!data.target; }), debounceTime(1000), distinctUntilChanged(function (_prevValue, currValue) {
            return currValue.target.value === _this.input.value;
        }))
            .subscribe(function (newValue) {
            _this.lostFocus(_this.input.controlType, _this.input.key, null, newValue);
        });
        // execute code only if we're on zipcode input and if we have country input in current form
        if (this.input.key === 'zipCode' && this.inputSet.find(function (item) { return item.key === 'country'; })) {
            // needed for carousel, without this dropdown will be cut off by carousel css (see function changeVisibilityOfCarouselElements)
            this.zipCodeUniqueClass = this.action.length > 1 ? this.action.join('_') + "_" + this.input.key : this.action[0];
            // set zipcode cities dropdown, but don't set value if only 1 value is found
            this.setCityBasedOnZip(true);
            // subscribe to this form country input changes and set city if needed 
            this.subscriptions.add(this._validationService.formCountryInputChanged$.filter(function (item) { return !!item; }).subscribe(function (item) {
                if (item === _this.zipCodeCitiesByFormPath) {
                    _this.setCityBasedOnZip(false);
                    _this._validationService.formCountryInputChangedBH$.next(null);
                }
            }));
        }
    };
    DfInputComponent.prototype.ngOnChanges = function () {
        if (this.input.key === 'country' &&
            this.input['options'].length === 0 &&
            this.countries) {
            this.input['options'] = cloneDeep(this.countries);
        }
    };
    DfInputComponent.prototype.getCountriesAndTranslate = function () {
        var _this = this;
        if (this.input.key === 'country') {
            this._store
                .select(fromRoot.getAllCountriesList)
                .pipe(first())
                .subscribe(function (list) {
                _this._formsService.translateCountries(list).subscribe(function (subs) {
                    subs.subscribe(function (countries) {
                        _this.countries = countries;
                        _this.input['options'] = cloneDeep(_this.countries);
                    });
                });
            });
        }
    };
    DfInputComponent.prototype.selectClicked = function ($event) {
        if (this.input.key === 'country' &&
            this.input['options'].length === 0 &&
            this.countries) {
            this.input['options'] = cloneDeep(this.countries);
            $event.target.blur();
            $event.target.focus();
        }
    };
    DfInputComponent.prototype.ngOnDestroy = function () {
        //if address autocomplete field was focused when the page was thrown away, save the last value
        if (this.addressFocused) {
            this.newInputValue(this.addressFocused.input.controlType, this.addressFocused.input.key, null, this.addressFocused.value);
        }
        this._unsubscribe.next();
        this._unsubscribe.complete();
        this.subscriptions.unsubscribe();
    };
    DfInputComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (this.formPath === 'personal.privacy') {
            var privacyPolicyLink = this.el.nativeElement.querySelector('a');
            if (privacyPolicyLink) {
                privacyPolicyLink.addEventListener('click', function (e) {
                    e.preventDefault();
                    _this._helperService.openIframe(e.target.href);
                });
            }
        }
    };
    DfInputComponent.prototype.lostFocus = function (type, inputId, optionId, event) {
        if (!this.isDateInput && event.target.value !== this.input.value) {
            this.newInputValue(type, inputId, optionId, event.target.value);
        }
        else if (this.isDateInput) {
            this.newInputValue(type, inputId, optionId, event);
        }
    };
    DfInputComponent.prototype.checkCheckboxLimit = function (inputId, optionId, event) {
        var value = this.form.controls[inputId].value;
        var countSelectedOptions = Object.keys(value).filter(function (option) { return value[option]; }).length;
        if (!value[inputId + "_" + optionId] &&
            this.input.selectionLimit &&
            countSelectedOptions >= this.input.selectionLimit) {
            event.preventDefault();
            event.stopPropagation();
        }
    };
    DfInputComponent.prototype.newInputValue = function (type, inputId, optionId, val) {
        var _this = this;
        var value = val || this.form.controls[inputId].value;
        var updatedInput = Object.assign({}, this.input);
        if (type === 'checkbox') {
            var countSelectedOptions_1 = Object.keys(value).filter(function (option) { return value[option]; }).length;
            var updatedOptions = updatedInput.options.map(function (option) {
                var updatedOption = Object.assign({}, option);
                if (!updatedInput.selectionLimit ||
                    countSelectedOptions_1 <= updatedInput.selectionLimit) {
                    // if updated input doesnt have selection limit
                    if (optionId !== null && updatedOption.key === optionId) {
                        updatedOption.value = !updatedOption.value;
                    }
                }
                return updatedOption;
            });
            updatedInput.options = updatedOptions;
        }
        else if ((updatedInput.key === 'email' ||
            updatedInput.key === 'verifyEmail' ||
            updatedInput.key === 'verify-email') &&
            value) {
            this.form.controls[inputId].setValue(value.replace(/\s/g, ''));
            updatedInput.value = value.toLowerCase().replace(/\s/g, '');
        }
        else {
            var date = this.isDateInput ? value : null;
            if (!!date && !(value instanceof Date)) {
                updatedInput.value = null;
            }
            else {
                updatedInput.value = value;
            }
        }
        var updatedInputSet = this.inputSet.map(function (input) {
            if (_this.input.key === input.key) {
                return updatedInput;
            }
            else {
                return input;
            }
        });
        if (updatedInput.key === 'telephone' && value) {
            updatedInput.value = value.replace(/\s/g, ''); //remove space characters in phone input
        }
        if (updatedInput.key === 'zipCode') {
            if (!value) { // if there's no value, hide dropdown, and remove cities from zipCodeCities
                this.showZipCodeCitiesDropdown(false, true);
            }
            else {
                this.setCityBasedOnZip(false, true);
            }
        }
        if (updatedInput.key === 'country' && value) {
            var telephone = this.inputSet.find(function (input) {
                return input.key === 'telephone';
            });
            if (telephone) {
                var prevCountry = this.inputSet.find(function (input) {
                    return input.key === 'country';
                }).value;
                var newCountry = updatedInput.value;
                var prevCountryPrecall = '';
                if (prevCountry) {
                    prevCountryPrecall = AppConstants.PHONE_PRECALL[prevCountry];
                }
                var newCountryPrecall = AppConstants.PHONE_PRECALL[newCountry];
                // if there is telephone input field
                if (!telephone.hasOwnProperty('value') ||
                    telephone.value === '' ||
                    telephone.value === prevCountryPrecall) {
                    updatedInputSet = this.updateAddressInput(updatedInputSet, 'telephone', newCountryPrecall);
                }
            }
            var zipCode = this.inputSet.find(function (item) { return item.key === 'zipCode'; });
            // if there is zipCode input in this form and it has value
            if (!!zipCode && zipCode.value) {
                // if citiesByZipCodeFromPath is not yet set, set it to this forms zipCode input path
                if (!this.zipCodeCitiesByFormPath) {
                    this.findZipCodesByFormPath([], zipCode);
                }
                // set zipCode validator to value so it fires store action
                this._validationService.zipCodeValidatorFn(zipCode.value, value, null, this.zipCodeCitiesByFormPath, true);
            }
        }
        //In case the sendtoowner field is updated to checked, we make email and verifyEmail fields required.
        //If it is unchecked, we set the original requirements for email and verifyEmail fields.
        //Checkbox sendtoowner won't appear if email field is not visible
        if (updatedInput.key === 'sendtoowner') {
            var isSendToOwnerChecked = this.form.controls['sendtoowner'].value['sendtoowner_toowner'];
            var email = this.inputSet.find(function (input) { return input.key === 'email'; });
            var verifyEmail = this.inputSet.find(function (input) { return input.key === 'verifyEmail'; });
            var originalEmailRequired = this._helperService.getOriginalEmailValues('email');
            var originalVerifyEmailRequired = this._helperService.getOriginalEmailValues('verifyEmail');
            //In case isSendToOwnerChecked and email field exists and it isn't yet required,
            //we set email field to required
            if (!!isSendToOwnerChecked && !!email && (!originalEmailRequired || !originalVerifyEmailRequired)) {
                email.required = true;
                //If verifyEmail field exists, we also make it required.
                if (!!verifyEmail) {
                    verifyEmail.required = true;
                }
                //If isSendToOwnerChecked is unchecked, and email exists, we set it to the original value.
            }
            else if (!isSendToOwnerChecked && !!email) {
                email.required = originalEmailRequired;
                //If verifyEmail field exists, we set it to original value.
                if (!!verifyEmail) {
                    verifyEmail.required = originalVerifyEmailRequired;
                }
            }
        }
        var shouldPrerender = ['textbox' /* , 'checkbox' */].indexOf(type) >= 0 ? false : true;
        var actionPayload = {
            formInfo: this.action,
            inputSet: {
                rerender: shouldPrerender,
                list: updatedInputSet,
                updatedInputs: [updatedInput.key]
            }
        };
        if (this.saveFormCallback) {
            actionPayload.callback = this.saveFormCallback;
        }
        this._formsService.mergeInputsBeforeSave(actionPayload);
    };
    /**
     * Sets zipcode cities, if 1 city with given zipcode is found, automatically set country input, else show dropdown with cities
     * @param skipSettingCityAndZipCode Skip setting zipcode if only 1 city per is found (needed for ngOnInit)
     * @param showZipCodeCities show or hide zipcode cities dropdown
     */
    DfInputComponent.prototype.setCityBasedOnZip = function (skipSettingCityAndZipCode, showZipCodeCities) {
        var _this = this;
        this.subscriptions.add(this._store.pipe(select(fromRoot.getAllCitiesByZipCode), filter(function (item) { return !!item; }), first())
            .subscribe(function (zipCodeCities) {
            var foundByFormPath = _this.findZipCodesByFormPath(zipCodeCities, _this.input);
            var isCountryInputFilled = _this.inputSet.find(function (input) { return input.key === 'country' && !!input.value; });
            var isCityFieldActive = _this.inputSet.some(function (input) { return input.key === 'city'; });
            if (!!foundByFormPath && !!isCountryInputFilled && isCityFieldActive) {
                var countryCodeShort_1 = isCountryInputFilled ? isCountryInputFilled.value : '';
                var inputValue_1 = _this.form.controls[_this.input.key].value;
                var intervalCounter_1 = _this.numberOfIntervalReps;
                // interval is needed because after refresh on profile component, google is not defined for a while
                var interval_1 = setInterval(function () {
                    var isGoogleLoaded = !!window["googleMapsLoaded"];
                    //reduce intervalCounter each time interval fires
                    intervalCounter_1--;
                    // if google is loaded or intervalCounter reached zero, clear interval
                    // interval reaches zero if interval fired 20 times which equals to 2000ms
                    // if interval is cleared, set intervalCounter to its default value
                    if (isGoogleLoaded || intervalCounter_1 === 0) {
                        clearInterval(interval_1);
                    }
                    if (countryCodeShort_1 && !!inputValue_1 && isGoogleLoaded) {
                        var geocoder = new google.maps.Geocoder();
                        geocoder.geocode({
                            componentRestrictions: {
                                country: countryCodeShort_1,
                                postalCode: inputValue_1
                            }
                        }, function (result, status) {
                            if ((status === 'OK' && result.length) || !!foundByFormPath.cities.length) {
                                if (status === 'OK' && result.length) {
                                    var addressComponents = result[0].address_components;
                                    var zipcodeFound = false;
                                    var city_1 = '';
                                    for (var i = 0; i < addressComponents.length; i++) {
                                        var addressType = addressComponents[i].types[0];
                                        if (addressType === 'postal_code' &&
                                            inputValue_1
                                                .replace(' ', '')
                                                .toUpperCase() ===
                                                addressComponents[i].long_name
                                                    .replace(' ', '').toUpperCase()) {
                                            zipcodeFound = true;
                                        }
                                        if (addressType === 'locality' ||
                                            addressType === 'administrative_area_level_2') {
                                            city_1 = addressComponents[i]['long_name'];
                                        }
                                        if (!city_1 && addressType === 'administrative_area_level_1') {
                                            city_1 = addressComponents[i]['long_name'];
                                        }
                                    }
                                    if (zipcodeFound && city_1) {
                                        // if we got cities from store(database)
                                        if (!!foundByFormPath.cities.length) {
                                            _this.zipCodeCities = foundByFormPath.cities.slice();
                                        }
                                        else {
                                            // make dropdown visible, but remove zipCodes, which will make dropdown invisible until zipCodes are filled
                                            _this.showZipCodeCitiesDropdown(true, true);
                                        }
                                        // push city from geocoder if it's not in zipCodeCities already, also sort array after city is pushed
                                        _this.zipCodeCities.some(function (item) { return item === city_1; }) ? null : _this.zipCodeCities.push(city_1), _this.zipCodeCities = _this._helperService.sortValuesAsc(_this.zipCodeCities);
                                        // if there's only one value in zipCodeCities automatically update city input
                                        if (_this.zipCodeCities.length === 1 && _this.inputSet.find(function (item) { return item.key === 'city'; }).value !== _this.zipCodeCities[0] && !skipSettingCityAndZipCode) {
                                            _this.setCityAndZipCode(city_1, inputValue_1);
                                        }
                                    }
                                    else {
                                        // if city does not exists, means user entered something like this "43000," geocoder will return value for 43000, but API won't so we empty dropdown values
                                        _this.zipCodeCities = [];
                                        showZipCodeCities = false;
                                        _this.zipCode = null;
                                    }
                                }
                                else {
                                    _this.zipCodeCities = foundByFormPath.cities.slice();
                                }
                                // sets zipCode which is displayed in dropdown
                                _this.zipCode = foundByFormPath.zipCode;
                                if (!!showZipCodeCities) {
                                    _this.showZipCodeCities = showZipCodeCities;
                                }
                            }
                            else {
                                _this.showZipCodeCitiesDropdown(false, true);
                            }
                        });
                    }
                    else {
                        if (isGoogleLoaded) {
                            _this.showZipCodeCitiesDropdown(false, true);
                        }
                    }
                }, 100);
            }
        }));
    };
    DfInputComponent.prototype.updateAddressInput = function (updatedInputSet, inputID, newInputValue) {
        return updatedInputSet.map(function (input) {
            if (inputID === input.key) {
                return __assign({}, input, { value: newInputValue });
            }
            else {
                return input;
            }
        });
    };
    DfInputComponent.prototype.addressKeyup = function (input, e) {
        this.addressFocused = { input: input, value: e.target.value };
    };
    DfInputComponent.prototype.addressChange = function (place, keyOfTriggeredInput) {
        this.addressFocused = null;
        var updatedInputSet = this.inputSet;
        // in case we got some data from google places API
        // keyOfTriggeredInput is either 'address' or 'company'
        if (typeof place === 'object') {
            if (keyOfTriggeredInput === 'address') {
                updatedInputSet = this.updateAddressInput(updatedInputSet, keyOfTriggeredInput, place.formatted_address);
            }
            else if (keyOfTriggeredInput === 'company') {
                updatedInputSet = this.updateAddressInput(updatedInputSet, keyOfTriggeredInput, place.name);
            }
            var streetNumber = '';
            var street = '';
            var city = '';
            var zip = '';
            var country = ''; // ensure that 0 is none in selectbox (empty option)
            var premise = '';
            var neighborhood = '';
            var sublocalityLevel1 = '';
            var administrativeAreaLevel1 = '';
            var administrativeAreaLevel2 = '';
            if (place.hasOwnProperty('address_components')) {
                for (var i = 0; i < place.address_components.length; i++) {
                    var addressType = place.address_components[i].types[0];
                    if (addressType === 'street_number') {
                        streetNumber = place.address_components[i]['short_name'];
                    }
                    else if (addressType === 'premise') {
                        premise = place.address_components[i]['short_name'];
                    }
                    else if (addressType === 'route') {
                        street = place.address_components[i]['long_name'];
                    }
                    else if (addressType === 'neighborhood') {
                        neighborhood = place.address_components[i]['long_name'];
                    }
                    else if (addressType === 'sublocality_level_1') {
                        sublocalityLevel1 = place.address_components[i]['long_name'];
                    }
                    else if (addressType === 'locality') {
                        city = place.address_components[i]['long_name'];
                    }
                    else if (addressType === 'administrative_area_level_2') {
                        administrativeAreaLevel2 = place.address_components[i]['long_name'];
                    }
                    else if (addressType === 'administrative_area_level_1') {
                        administrativeAreaLevel1 = place.address_components[i]['long_name'];
                    }
                    else if (addressType === 'postal_code') {
                        zip = place.address_components[i]['short_name'].replace(' ', '');
                    }
                    else if (addressType === 'country') {
                        country = place.address_components[i]['short_name'];
                    }
                }
            }
            if (!streetNumber && premise) {
                //workaround for exotic Czech addresses - no street_number address type (bug #2743):
                //(search for company: Jizba Luhacovice)
                streetNumber = premise;
            }
            if (!city) {
                if (neighborhood) {
                    //workaround for exotic US addresses - no locality address type (bug #2743):
                    //(search for company: Astoria Park, Astoria NY)
                    city = neighborhood;
                }
                else if (sublocalityLevel1) {
                    //workaround for exotic Czech addresses - no locality and neighborhood address types (bug #2743):
                    //(search for company: Jizba Luhacovice)
                    city = sublocalityLevel1;
                }
            }
            // if address is filled by google company autocomplete, hide address field
            var fullAddress = streetNumber && street && city && zip && country;
            var address = updatedInputSet.find(function (input) { return input.key === 'address'; });
            if (address) {
                if (fullAddress && keyOfTriggeredInput === 'company') {
                    address.hidden = true;
                    // just uncomment this if you want to remove the empty space after the input (it can cause form breaking into differnt layout)
                    // address.cssClass = address.cssClass + ' col-hidden';
                }
                else {
                    address.hidden = false;
                    // just uncomment this if you want to remove the empty space after the input (it can cause form breaking into differnt layout)
                    // address.cssClass = address.cssClass.replace(' col-hidden', '');
                }
            }
            street = !!streetNumber ? street + " " + streetNumber : street;
            updatedInputSet = this.updateAddressInput(updatedInputSet, 'street', street);
            updatedInputSet = this.updateAddressInput(updatedInputSet, 'city', city);
            updatedInputSet = this.updateAddressInput(updatedInputSet, 'zipCode', zip);
            updatedInputSet = this.updateAddressInput(updatedInputSet, 'country', country);
        }
        else {
            // it is string from changed address without google help
            updatedInputSet = this.updateAddressInput(updatedInputSet, keyOfTriggeredInput, place);
        }
        var actionPayload = {
            formInfo: this.action,
            inputSet: {
                rerender: true,
                list: updatedInputSet
            }
        };
        if (this.saveFormCallback) {
            actionPayload.callback = this.saveFormCallback;
        }
        this._formsService.mergeInputsBeforeSave(actionPayload);
    };
    DfInputComponent.prototype.formSubmitButton = function (key) {
        this.onSubmit.emit(key);
    };
    DfInputComponent.prototype.toggleDatePickerLabel = function (addOrRemoveClass, element) {
        var datePickerLabel = document.querySelector(".datePickerLabel." + this.datePickerUniqueClass);
        var datePlaceHolderClass = 'datePlaceHolder';
        this.changeVisibilityOfCarouselElements(addOrRemoveClass, element);
        if (addOrRemoveClass) {
            datePickerLabel.classList.add(datePlaceHolderClass);
            this.datePlaceHolder = this.dateFormat;
        }
        else if (!addOrRemoveClass && !this.form.controls[this.input.key].value) {
            datePickerLabel.classList.remove(datePlaceHolderClass);
            this.datePlaceHolder = '';
        }
    };
    DfInputComponent.prototype.triggerDatePicker = function () {
        var datePickerInput = document.querySelector("." + this.datePickerUniqueClass + " .ui-calendar > input");
        datePickerInput.click();
        datePickerInput.focus();
    };
    DfInputComponent.prototype.changeVisibilityOfCarouselElements = function (addOrRemoveClass, element) {
        var _this = this;
        var focusedInput = document.querySelector("." + element);
        if (!!focusedInput) {
            var carousel = focusedInput.closest('.carousel');
            if (!!carousel && typeof (carousel) != 'undefined') {
                var carouselPlaceHolder = Array.from(document.querySelectorAll('.carousel-placeholder'));
                if (addOrRemoveClass) {
                    this.renderer.setStyle(carousel, 'overflow', 'visible');
                    this.renderer.setStyle(carousel, 'overflow-x', 'clip');
                    carouselPlaceHolder.forEach(function (element) {
                        _this.renderer.setStyle(element, 'visibility', 'hidden');
                    });
                }
                else {
                    this.renderer.removeStyle(carousel, 'overflow');
                    this.renderer.removeStyle(carousel, 'overflow-x');
                    carouselPlaceHolder.forEach(function (element) {
                        _this.renderer.setStyle(element, 'visibility', 'visible');
                    });
                }
            }
        }
    };
    /**
     * Sets inputs of city and zipcode if values are given
     * @param cityValue Value to set city input
     * @param zipCodeValue Value to set zipcode input
     */
    DfInputComponent.prototype.setCityAndZipCode = function (cityValue, zipCodeValue) {
        var updatedInputSet;
        if (!!cityValue) {
            // if city name length is higher than 50, reduce length to 50 because of max length validation
            if (cityValue.length > 50) {
                cityValue = cityValue.substring(0, 50);
            }
            updatedInputSet = this.updateAddressInput(this.inputSet, 'city', cityValue);
            this.showZipCodeCitiesDropdown(false); // if we're setting citiy it means we either clicked on dropdown, or there's only one city in current zipCode
        }
        if (!!zipCodeValue) {
            updatedInputSet = this.updateAddressInput(!!updatedInputSet ? updatedInputSet : this.inputSet, 'zipCode', zipCodeValue);
        }
        var actionPayload = {
            formInfo: this.action,
            inputSet: {
                rerender: true,
                list: updatedInputSet,
                updatedInputs: [this.input.key]
            }
        };
        if (this.saveFormCallback) {
            actionPayload.callback = this.saveFormCallback;
        }
        this._formsService.mergeInputsBeforeSave(actionPayload);
    };
    /**
     *
     * @param city Value to set city form input with
     */
    DfInputComponent.prototype.setSelectedCity = function (city) {
        this.setCityAndZipCode(city);
    };
    /**
     * Shows or hides zipcode cities dropdown and empties zipCodeCities array if removeZipCodeCities is true
     * @param show Hides or shows dropdown if removeZipCodeCities is false and if zipCodeCities is not empty
     * @param removeZipCodeCities Removes zipCodeCities if true
     * @param chngCarouselElVisib Changes carousel elements visibility so we can see whole zipcode cities dropdown
     * @param childOfCarouselClass Class of zipcode input to pop infront of carousel
     */
    DfInputComponent.prototype.showZipCodeCitiesDropdown = function (show, removeZipCodeCities, chngCarouselElVisib, childOfCarouselClass) {
        // if function was called from html
        if (show && !!chngCarouselElVisib) {
            this.setCityBasedOnZip(true, show);
        }
        if (!show) {
            this.showZipCodeCities = show;
        }
        if (removeZipCodeCities) {
            this.zipCodeCities = [];
        }
        if (chngCarouselElVisib !== undefined) {
            this.changeVisibilityOfCarouselElements(chngCarouselElVisib, childOfCarouselClass);
        }
    };
    /**
     * Sets zipCodeCitiesByFormPath and returns zipcodes which are found by zipCodeCitiesTag from zipCodeCities array
     * @param zipCodeCities all zipcode cities
     * @param input input which contains zipCodeCitiesTag
     * @returns zipcode cities for current form if there are any, else returns null
     */
    DfInputComponent.prototype.findZipCodesByFormPath = function (zipCodeCities, input) {
        var _this = this;
        var ticketHolder;
        if (!!this.action[1] && this.action[1].includes('ticketHolder')) {
            ticketHolder = this.action[1];
        }
        var zipCodeCitiesTag = input.zipCodeCitiesTag;
        this.zipCodeCitiesByFormPath = zipCodeCitiesTag.includes('holder') && !!ticketHolder ? zipCodeCitiesTag.replace('holder', ticketHolder) : zipCodeCitiesTag;
        return zipCodeCities.length ? zipCodeCities.find(function (item) { return item.formPath === _this.zipCodeCitiesByFormPath; }) : null;
    };
    return DfInputComponent;
}());
export { DfInputComponent };
