<div class="forgotten-password-wrapper">
    <div class="forgotten-password-header">
        <span *ngIf="!isLinkSuccessfullySent" data-translation="forgotten-password.title">{{ 'forgotten-password.title' | translate | hideTranslation }}</span>
        <span *ngIf="isLinkSuccessfullySent" data-translation="forgotten-password.title-sent">{{ 'forgotten-password.title-sent' | translate | hideTranslation }}</span>
    </div>

    <div class="forgotten-password-describtion" *ngIf="!isLinkSuccessfullySent">
        <span>{{'forgotten-password.describtion' | translate | hideTranslation }}</span>
    </div>
    <form *ngIf="!isLinkSuccessfullySent" [formGroup]="forgottenPasswordForm" (ngSubmit)="onSubmit(forgottenPasswordForm.value)"
        class="forgotten-password-body">

        <div class="forgotten-password-input-wrapper">
            <svg class="icon icon-key">
                <use xlink:href="assets/defs.svg#icon-at"></use>
            </svg>
            <input class="forgotten-password-input" type="email" name="email" placeholder="{{'forgotten-password.email' | translate | hideTranslation }}"
                data-translation="forgotten-password.email" [formControl]="email" required />
        </div>
        <div class="forgotten-password-input-error-message" *ngIf="email.errors && email.touched">
            <small *ngIf="email.errors.invalidEmailAddress" data-translation="forgotten-password.email-validation">{{ 'forgotten-password.email-validation' | translate | hideTranslation }}</small>
            <small *ngIf="email.errors.maxlength" data-translation="forgotten-password.email-maxlength" [translate]="'forgotten-password.email-maxlength'"
                [translateParams]="{emailMaxLength: email.errors.maxlength['requiredLength']}"> </small>
        </div>

        <div *ngIf="alsoName" class="forgotten-password-input-wrapper">
            <svg class="icon icon-user">
                <use xlink:href="assets/defs.svg#icon-user"></use>
            </svg>
            <input class="forgotten-password-input" type="text" name="name" placeholder="{{'forgotten-password.name' | translate | hideTranslation }}"
                data-translation="forgotten-password.name" [formControl]="name" required />
        </div>

        <div class="no-user-error" *ngIf="emailNotSend" data-translation="forgotten-password.no-user">{{ 'forgotten-password.no-user' | translate | hideTranslation }}</div>


        <div class="forgotten-password-button-wrapper">
            <button class="button back-button button-option" type="button" (click)="navigateBack()" data-translation="forgotten-password.back">
                <span> {{ 'forgotten-password.back' | translate | hideTranslation }} </span>
            </button>
            <button class="button submit-button" type="submit" [disabled]="!forgottenPasswordForm.valid" data-translation="forgotten-password.submit">
                <span>{{ 'forgotten-password.submit' | translate | hideTranslation }}</span>
            </button>
        </div>
    </form>
    <div *ngIf="isLinkSuccessfullySent" class="forgotten-password-success-body">
        <div class="forgotten-password-success-text" data-translation="forgotten-password.text-sent">
            {{ 'forgotten-password.text-sent' | translate | hideTranslation }}
        </div>
        <button class="button" type="akcept-button" (click)="navigateHome()" routerLink="/login" data-translation="forgotten-password.akcept">
            <span>{{ 'forgotten-password.akcept' | translate | hideTranslation }}</span>
        </button>
    </div>
</div>
