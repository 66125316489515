import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { WidgetAdminService } from '../widget-admin.service';
import { WidgetService } from './../../../shared/services-with-reducers/widget/widget.service';

import {
  WidgetIframeAttributes,
  BannerWidgetSettings
} from '../widget-models.interface';
import { ProductGroupModel } from './../../../shared/services-with-reducers/tickets/ticket.interface';
import {
  BannerWidgetPostSettings,
  WidgetSettingTypeKeyname,
  WidgetSettingType,
  EventWidgetListItem
} from './../widget-models.interface';

import cloneDeep from 'lodash.clonedeep';
import { pairwise, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-banner-widget-admin-component',
  templateUrl: './banner-widget-admin.component.html',
  styleUrls: ['./banner-widget-admin.component.scss']
})
export class BannerWidgetAdminComponent implements OnChanges {
  @Input()
  widgetSettings: BannerWidgetSettings;
  @Input()
  eventWidgets: EventWidgetListItem[];
  @Input()
  availableTickets: ProductGroupModel[];
  @Input()
  loading: boolean;

  @Output('createNewWidget')
  createWidgetSettings: EventEmitter<{
    type: WidgetSettingTypeKeyname;
    settings: WidgetSettingType;
  }> = new EventEmitter();

  @Output('updateWidget')
  updateWidgetSettings: EventEmitter<{
    type: WidgetSettingTypeKeyname;
    settings: WidgetSettingType;
  }> = new EventEmitter();

  @Output()
  loadWidget: EventEmitter<{
    type: WidgetSettingTypeKeyname;
    widgetId: string;
  }> = new EventEmitter();

  public settingsForm: FormGroup = this.fb.group({
    width: [null],
    height: [null],
    title: [null],
    titleColor: [null],
    description: [null],
    descriptionColor: [null],
    file: [null],
    button: this.fb.group({
      text: ['Buy Tickets'],
      color: [null],
      textColor: [null]
    }),
    preSelectedTicket: [null],
    preSelectedVoucher: [null],
    language: ['en']
  });

  public iframeEmbedCode: string = null;
  public webshopOriginURL = `${window.location.protocol}//${window.location.host}`;
  public iframePath = '/widget/banner';
  public iframeURI: string = this.webshopOriginURL + this.iframePath;
  public iframeAttributes: WidgetIframeAttributes = {
    width: '500',
    height: '200'
  };
  public baseSettingsFormValue: BannerWidgetSettings;

  private saveSettingsProcessor = {
    preSelectedTicket: (ticketUniqueId: string, settings) => {
      const [ticketGroupNr = null, ticketPersonNr = null] = ticketUniqueId
        ? ticketUniqueId.split('_')
        : [];

      settings['preSelectedTicket'] = { ticketGroupNr, ticketPersonNr };
    }
  };

  private loadSettingsProcessor = {
    width: (width: string) => (this.iframeAttributes['width'] = width || '500'),
    height: (height: string) =>
      (this.iframeAttributes['height'] = height || '200')
  };

  private loadSettingsToFormProcessor = {
    preSelectedTicket: (
      setting: BannerWidgetPostSettings['preSelectedTicket'],
      processedSettings
    ) => {
      const { ticketGroupNr, ticketPersonNr } = setting;

      if (ticketGroupNr && ticketPersonNr) {
        processedSettings[
          'preSelectedTicket'
        ] = `${ticketGroupNr}_${ticketPersonNr}`;
      }
    }
  };

  constructor(
    private fb: FormBuilder,
    private widgetAdminService: WidgetAdminService,
    private widgetService: WidgetService
  ) {
    this.settingsForm.valueChanges
      .pipe(
        startWith(null),
        pairwise()
      )
      .subscribe(([prevValue, currentValue]) => {
        const prevValueString = JSON.stringify(prevValue);
        const currValueString = JSON.stringify(currentValue);
        const baseSettingsString = JSON.stringify(this.baseSettingsFormValue);

        setTimeout(() => {
          if (
            prevValueString !== currValueString &&
            this.settingsForm.touched
          ) {
            this.settingsForm.markAsDirty();
          }

          if (baseSettingsString === currValueString) {
            this.settingsForm.markAsPristine();
          }
        });
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.widgetSettings && !changes.widgetSettings.isFirstChange()) {
      this.loadSavedSettings();
    }
  }

  readFile(event: any) {
    const background = event.target.files[0];
    const fileControl = this.settingsForm.get('file');

    fileControl.patchValue(background);
    fileControl.updateValueAndValidity();
    fileControl.markAsDirty();
  }

  processWidgetSettingsForSave(value?: BannerWidgetSettings) {
    const formValue = value || this.settingsForm.value;
    const clonedFormValue: BannerWidgetSettings = cloneDeep(formValue);

    if (formValue) {
      this.widgetAdminService.processWidgetSettingsForSave(
        clonedFormValue,
        this.saveSettingsProcessor
      );

      return clonedFormValue;
    }
  }

  createNewWidget() {
    const settings = this.processWidgetSettingsForSave();

    this.createWidgetSettings.emit({
      type: 'bannerWidget',
      settings
    });
  }

  updateWidget(formValue?) {
    const settings = this.processWidgetSettingsForSave(formValue);

    this.updateWidgetSettings.emit({ type: 'bannerWidget', settings });
  }

  loadSavedSettings() {
    const bannerWidget = this.widgetSettings;
    const processedLoadedSettingsForForm = this.widgetAdminService.processWidgetSettingForAdminForm(
      bannerWidget,
      this.loadSettingsToFormProcessor,
      this.settingsForm
    );

    this.widgetService.processWidgetSettingsLoad(
      this.widgetSettings,
      this.loadSettingsProcessor
    );

    // reset URI to force reload iframe in next change cycle
    this.iframeURI = null;

    this.settingsForm.reset();
    this.settingsForm.get('language').setValue('en');

    // merge settings with emptied settingsForm.value to prevent "Missing control" errors
    this.settingsForm.patchValue({
      ...processedLoadedSettingsForForm
    });

    // store base loaded settings for comparsion (because it was very important to hide Update button if the settings havent changed)
    this.baseSettingsFormValue = this.settingsForm
      .value as BannerWidgetSettings;

    const eventId = bannerWidget.id;
    const iframePathWithEventId = `${this.iframePath}/${eventId}`;

    // force update iframe in next change
    setTimeout(() => {
      this.iframeURI = this.webshopOriginURL + iframePathWithEventId;

      this.iframeEmbedCode = this.widgetAdminService.createIframeEmbedCode(
        this.iframeURI,
        this.iframeAttributes
      );
    });
  }

  onWidgetChange(widgetId: string) {
    if (widgetId == '-1') {
      this.settingsForm.reset();
      return;
    }

    this.loadWidget.emit({ type: 'bannerWidget', widgetId });
  }

  colorPickerChange(event: string, controlPath: string) {
    if (event == '') {
      event = null;
    }

    this.settingsForm.get(controlPath).setValue(event);
  }

  deselectRadio(formControlName: string, value: any) {
    const radioFormControl = this.settingsForm.get(formControlName);
    const currentControlValue = radioFormControl && radioFormControl.value;

    if (currentControlValue == value) {
      radioFormControl.setValue(null);

      this.settingsForm.markAsTouched();
    }

    this.settingsForm.get('preSelectedTicket').setValue(value);
  }
}
