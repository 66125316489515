var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import * as legitimationActions from './legitimation.actions';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { InjectionToken } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';
import { LegitimationService } from './legitimation.service';
export var LEGITIMATION_DEBOUNCE = new InjectionToken('Legitimation Debounce');
var LegitimationEffect = /** @class */ (function () {
    function LegitimationEffect(actions$, legitimationService, debounce) {
        if (debounce === void 0) { debounce = 50; }
        var _this = this;
        this.actions$ = actions$;
        this.legitimationService = legitimationService;
        this.debounce = debounce;
        this.loadLegitimationList$ = this.actions$.pipe(ofType(legitimationActions.ActionTypes.GET_LEGITIMATION_LIST), debounceTime(this.debounce), switchMap(function (data) {
            var userId = data.payload;
            return _this.legitimationService.getLegitimationList().pipe(map(function (legitimationList) {
                return new legitimationActions.AddLegitimationToList(legitimationList);
            }), catchError(function () { return of(new legitimationActions.AddLegitimationToList([])); }));
        }));
        this.loadLegitimationStatus$ = this.actions$.pipe(ofType(legitimationActions.ActionTypes.GET_LEGITIMATION_STATUS), debounceTime(this.debounce), switchMap(function (data) {
            var _a = data.payload, userId = _a.userId, eventId = _a.eventId;
            if (eventId === '' || userId === '') {
                return Observable.empty();
            }
            return _this.legitimationService
                .getLegitimationStatus(eventId)
                .pipe(map(function (legitimationStatus) {
                return new legitimationActions.SetLegitimationStatus(legitimationStatus);
            }), catchError(function () {
                return of(new legitimationActions.SetLegitimationStatus({
                    status: 'otherStatus'
                }));
            }));
        }));
        this.loadLegitimationFaxId$ = this.actions$.pipe(ofType(legitimationActions.ActionTypes.GET_LEGITIMATION_FAX_ID), debounceTime(this.debounce), switchMap(function (data) {
            // { eventId: number; userId: number }
            var _a = data.payload, eventId = _a.eventId, userId = _a.userId;
            if (eventId === null) {
                return Observable.empty();
            }
            return _this.legitimationService
                .getLegitimationFaxId(eventId, userId)
                .pipe(map(function (legitimationFaxInfo) {
                return new legitimationActions.SetLegitimationFaxId(legitimationFaxInfo.faxId);
            }), catchError(function () {
                return of(new legitimationActions.SetLegitimationFaxId(null));
            }));
        }));
        this.postLegitimationInfo$ = this.actions$.pipe(ofType(legitimationActions.ActionTypes.POST_LEGITIMATION_INFO), debounceTime(this.debounce), switchMap(function (data) {
            if (!data.payload) {
                return Observable.empty();
            }
            var _a = data.payload, feed = _a.feed, userId = _a.userId, eventId = _a.eventId;
            return _this.legitimationService
                .saveLegitimationInfo(feed, userId, eventId)
                .pipe(map(function (LegitimationData) {
                return new legitimationActions.SetLegitimationInfoResponse(true);
            }), catchError(function () {
                return of(new legitimationActions.SetLegitimationInfoResponse(false));
            }));
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], LegitimationEffect.prototype, "loadLegitimationList$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], LegitimationEffect.prototype, "loadLegitimationStatus$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], LegitimationEffect.prototype, "loadLegitimationFaxId$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], LegitimationEffect.prototype, "postLegitimationInfo$", void 0);
    return LegitimationEffect;
}());
export { LegitimationEffect };
