import { OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, combineLatest as observableCombineLatest } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { CustomizationService } from '../shared/services-with-reducers/customization/customization.service';
import * as customizationActions from '../shared/services-with-reducers/customization/customization.actions';
import * as fromRoot from '../app.reducer';
import { pageRunningInIframe } from '../shared/app-utils';
var BannerComponent = /** @class */ (function () {
    function BannerComponent(_store, _route, _customizationService) {
        var _this = this;
        this._store = _store;
        this._route = _route;
        this._customizationService = _customizationService;
        this.subscriptions = new Subscription();
        this.displayBanner = false;
        this.pageRunnungInIframe = false;
        this.subscriptions.add(observableCombineLatest(this._store.select(fromRoot.getSelectedExhibitionId), this._store.select(fromRoot.getLanguage))
            .pipe(filter(function (_a) {
            var eventId = _a[0], language = _a[1];
            if (eventId && language) {
                return true;
            }
        }))
            .subscribe(function (_a) {
            var eventId = _a[0], language = _a[1];
            _this._customizationService
                .getRandomSponsor(eventId, language)
                .subscribe(function (banner) {
                _this._store.dispatch(new customizationActions.SetRandomSponsor(banner));
            });
        }));
        this.subscriptions.add(this._store
            .select(fromRoot.getLocalizedImages)
            .pipe(filter(function (images) { return !!images; }))
            .subscribe(function (images) {
            _this.localizedImages = images;
        }));
        this.subscriptions.add(this._store
            .select(fromRoot.getSponsorBanner)
            .subscribe(function (banner) {
            if (banner) {
                _this.sponsorBanner = banner.sponsorBanner;
            }
        }));
        this.exhibitionSettings$ = this._store.select(fromRoot.getExhibitionSettings);
        this.exhibitions$ = this._store.select(fromRoot.getAllExhibitionsCollection);
        var params = this._route.params;
        var queryParams = this._route.queryParams;
        observableCombineLatest(params, queryParams)
            .filter(function (_a) {
            var params = _a[0], queryParams = _a[1];
            if (params || queryParams) {
                return true;
            }
        })
            .subscribe(function (_a) {
            var params = _a[0], queryParams = _a[1];
            var eventId = params.id || queryParams.eventId;
            _this.routeId = Number(eventId);
        });
        this.exhibitionSettings$.subscribe(function (exhibitionSettings) {
            _this.exhibitionSettings = exhibitionSettings;
        });
        this.exhibitions$.subscribe(function (exhibitions) {
            exhibitions.map(function (exhibition) {
                if (exhibition.id === _this.routeId) {
                    _this.exhibition = exhibition;
                }
            });
        });
    }
    BannerComponent.prototype.ngOnInit = function () {
        this.pageRunnungInIframe = pageRunningInIframe();
        if (this.pageRunnungInIframe) {
            this.loadWidgetSettings();
        }
    };
    BannerComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    BannerComponent.prototype.loadWidgetSettings = function () {
        var _this = this;
        this._store
            .select(fromRoot.getWidgetSettings)
            .pipe(filter(function (widgetSettings) {
            if (widgetSettings && widgetSettings.ticketsWidget) {
                return true;
            }
        }), first())
            .subscribe(function (_a) {
            var ticketsWidget = _a.ticketsWidget;
            _this.widgetSettings = ticketsWidget;
            _this.displayBanner = Boolean(ticketsWidget.banner);
        });
    };
    return BannerComponent;
}());
export { BannerComponent };
