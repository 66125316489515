var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import * as exhibitionActions from './exhibition.actions';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { InjectionToken } from '@angular/core';
import { Observable, of, EMPTY } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';
import { ExhibitionService } from './exhibition.service';
export var EXHIBITION_DEBOUNCE = new InjectionToken('Exhibition Debounce');
var ExhibitionEffect = /** @class */ (function () {
    function ExhibitionEffect(actions$, exhibitionService, debounce) {
        if (debounce === void 0) { debounce = 50; }
        var _this = this;
        this.actions$ = actions$;
        this.exhibitionService = exhibitionService;
        this.debounce = debounce;
        this.loadAllExhibitionsList$ = this.actions$.pipe(ofType(exhibitionActions.ActionTypes.GET_LIST_OF_ALL_EXHIBITIONS), debounceTime(this.debounce), switchMap(function (data) {
            var langCode = data.payload;
            return _this.exhibitionService.getAllExhibitionsList(langCode).pipe(map(function (allExhibitionsList) {
                var ListOfExhibitions = allExhibitionsList;
                function getUTCdate(date) {
                    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
                }
                // TODO remove once comming from clicks with time
                ListOfExhibitions.forEach(function (exhibition) {
                    var endDate = exhibition.endDate;
                    if (navigator.userAgent.indexOf('MSIE') !== -1 ||
                        navigator.appVersion.indexOf('Trident/') > 0) {
                        endDate = endDate.replace('00:00:00', '11:59:59'); // "Z" stands for UTC
                        var endDateObj = new Date(endDate);
                        exhibition.endDate = endDateObj;
                    }
                    else {
                        endDate = endDate.replace('00:00:00', '11:59:59Z'); // "Z" stands for UTC
                        var endDateObj = new Date(endDate);
                        exhibition.endDate = endDateObj;
                    }
                    var startDate = exhibition.startDate;
                    if (navigator.userAgent.indexOf('MSIE') !== -1 ||
                        navigator.appVersion.indexOf('Trident/') > 0) {
                        startDate = startDate.replace('00:00:00', '12:00:00');
                        var startDateObj = new Date(startDate);
                        //console.log(startDateObj);
                        exhibition.startDate = startDateObj;
                    }
                    else {
                        startDate = startDate.replace('00:00:00', '12:00:00Z');
                        var startDateObj = new Date(startDate);
                        exhibition.startDate = startDateObj;
                    }
                });
                return new exhibitionActions.AddAllExhibitionsToListAction(ListOfExhibitions);
            }), catchError(function (error) {
                console.log(error);
                return of(new exhibitionActions.AddAllExhibitionsToListAction([]));
            }));
        }));
        this.loadExhibitionHistoryList$ = this.actions$.pipe(ofType(exhibitionActions.ActionTypes.GET_EXHIBITION_HISTORY_LIST), debounceTime(this.debounce), switchMap(function (data) {
            var userId = data.payload;
            if (!userId) {
                return EMPTY;
            }
            return _this.exhibitionService.getExhibitionHistoryList().pipe(map(function (exhibitionHistoryList) {
                return new exhibitionActions.SetExhibitionHistoryList(exhibitionHistoryList);
            }), catchError(function (error) {
                console.log(error);
                return of(new exhibitionActions.SetExhibitionHistoryList([]));
            }));
        }));
        this.getDoubleClickScripts$ = this.actions$.pipe(ofType(exhibitionActions.ActionTypes.GET_DOUBLE_CLICK_SCRIPTS), debounceTime(this.debounce), switchMap(function (data) {
            var eventId = data.payload;
            if (eventId === null) {
                return EMPTY;
            }
            return _this.exhibitionService.getDoubleClickScripts(eventId).pipe(map(function (scripts) {
                // transform JSON result to object with keys as ticketGroup Ids and values as ticketSales
                return new exhibitionActions.SetDoubleClickScripts(scripts);
            }), catchError(function (error) {
                console.log(error);
                return of(new exhibitionActions.SetDoubleClickScripts(null));
            }));
        }));
        this.getGenericScripts$ = this.actions$.pipe(ofType(exhibitionActions.ActionTypes.GET_GENERIC_SCRIPTS), debounceTime(this.debounce), switchMap(function (data) {
            var eventId = data.payload;
            if (eventId === null) {
                return EMPTY;
            }
            return _this.exhibitionService.getGenericScripts(eventId).pipe(map(function (scripts) {
                // transform JSON result to object with keys as ticketGroup Ids and values as ticketSales
                return new exhibitionActions.SetGenericScripts(scripts);
            }), catchError(function (error) {
                console.log(error);
                return of(new exhibitionActions.SetGenericScripts(null));
            }));
        }));
        this.getProfessions$ = this.actions$.pipe(ofType(exhibitionActions.ActionTypes.GET_PROFESSIONS), debounceTime(this.debounce), switchMap(function (eventId) {
            if (eventId === null) {
                return EMPTY;
            }
            return _this.exhibitionService
                .getProfessions(eventId.payload)
                .map(function (selectOptions) {
                var professionsOptions = selectOptions
                    .slice(0)
                    .sort(function (a, b) { return a.order - b.order; })
                    .map(function (option) {
                    return {
                        key: option.id,
                        value: option.text,
                        translate: true
                    };
                });
                return new exhibitionActions.SetProfessions(professionsOptions);
            })
                .catch(function (error) {
                console.log(error);
                return of(new exhibitionActions.SetProfessions(null));
            });
        }));
        this.getDepartments$ = this.actions$.pipe(ofType(exhibitionActions.ActionTypes.GET_DEPARTMENTS), debounceTime(this.debounce), switchMap(function (eventId) {
            if (eventId === null) {
                return EMPTY;
            }
            return _this.exhibitionService
                .getDepartments(eventId.payload)
                .map(function (selectOptions) {
                var departmentOptions = selectOptions
                    .slice(0)
                    .sort(function (a, b) { return a.order - b.order; })
                    .map(function (option) {
                    return {
                        key: option.id,
                        value: option.text,
                        translate: true
                    };
                });
                return new exhibitionActions.SetDepartments(departmentOptions);
            })
                .catch(function (error) {
                console.log(error);
                return of(new exhibitionActions.SetDepartments(null));
            });
        }));
        this.getOccupationalGroups$ = this.actions$.pipe(ofType(exhibitionActions.ActionTypes.GET_OCCUPATIONAL_GROUPS), debounceTime(this.debounce), switchMap(function (eventId) {
            if (eventId === null) {
                return EMPTY;
            }
            return _this.exhibitionService
                .getOccupationalGroups(eventId.payload)
                .map(function (selectOptions) {
                var occupationalGroupsOptions = selectOptions
                    .slice(0)
                    .sort(function (a, b) { return a.order - b.order; })
                    .map(function (option) {
                    return {
                        key: option.id,
                        value: option.text,
                        translate: true
                    };
                });
                return new exhibitionActions.SetOccupationalGroups(occupationalGroupsOptions);
            })
                .catch(function (error) {
                console.log(error);
                return of(new exhibitionActions.SetOccupationalGroups(null));
            });
        }));
        this.getTitles$ = this.actions$.pipe(ofType(exhibitionActions.ActionTypes.GET_TITLES), debounceTime(this.debounce), switchMap(function (eventId) {
            if (eventId === null) {
                return EMPTY;
            }
            return _this.exhibitionService
                .getTitles(eventId.payload)
                .map(function (selectOptions) {
                var titleOptions = selectOptions
                    .slice(0)
                    .sort(function (a, b) { return a.order - b.order; })
                    .map(function (option) {
                    return {
                        key: option.id,
                        value: option.text,
                        translate: true
                    };
                });
                return new exhibitionActions.SetTitles(titleOptions);
            })
                .catch(function (error) {
                console.log(error);
                return of(new exhibitionActions.SetTitles(null));
            });
        }));
        this.getAllProfessions$ = this.actions$.pipe(ofType(exhibitionActions.ActionTypes.GET_ALL_PROFESSIONS), debounceTime(this.debounce), switchMap(function () {
            return _this.exhibitionService
                .getAllProfessions()
                .map(function (selectOptions) {
                var professionsOptions = selectOptions
                    .slice(0)
                    .sort(function (a, b) { return a.order - b.order; });
                // transform JSON result to object with keys as ticketGroup Ids and values as ticketSales
                return new exhibitionActions.SetAllProfessions(professionsOptions);
            })
                .catch(function (error) {
                console.log(error);
                return of(new exhibitionActions.SetAllProfessions(null));
            });
        }));
        this.getAllDepartments$ = this.actions$.pipe(ofType(exhibitionActions.ActionTypes.GET_ALL_DEPARTMENTS), debounceTime(this.debounce), switchMap(function () {
            return _this.exhibitionService
                .getAllDepartments()
                .map(function (selectOptions) {
                var departmentsOptions = selectOptions
                    .slice(0)
                    .sort(function (a, b) { return a.order - b.order; });
                // transform JSON result to object with keys as ticketGroup Ids and values as ticketSales
                return new exhibitionActions.SetAllDepartments(departmentsOptions);
            })
                .catch(function (error) {
                console.log(error);
                return of(new exhibitionActions.SetAllDepartments(null));
            });
        }));
        this.getAllOccupationalGroups$ = this.actions$.pipe(ofType(exhibitionActions.ActionTypes.GET_ALL_OCCUPATIONAL_GROUPS), debounceTime(this.debounce), switchMap(function () {
            return _this.exhibitionService
                .getAllOccupationalGroups()
                .map(function (selectOptions) {
                var occupationalGroupsOptions = selectOptions
                    .slice(0)
                    .sort(function (a, b) { return a.order - b.order; });
                // transform JSON result to object with keys as ticketGroup Ids and values as ticketSales
                return new exhibitionActions.SetAllOccupationalGroups(occupationalGroupsOptions);
            })
                .catch(function (error) {
                console.log(error);
                return of(new exhibitionActions.SetAllOccupationalGroups(null));
            });
        }));
        this.getAllTitles$ = this.actions$.pipe(ofType(exhibitionActions.ActionTypes.GET_ALL_TITLES), debounceTime(this.debounce), switchMap(function () {
            return _this.exhibitionService
                .getAllTitles()
                .map(function (selectOptions) {
                var allTitlesOptions = selectOptions
                    .slice(0)
                    .sort(function (a, b) { return a.order - b.order; });
                return new exhibitionActions.SetAllTitles(allTitlesOptions);
            })
                .catch(function (error) {
                console.log(error);
                return of(new exhibitionActions.SetAllTitles(null));
            });
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], ExhibitionEffect.prototype, "loadAllExhibitionsList$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], ExhibitionEffect.prototype, "loadExhibitionHistoryList$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], ExhibitionEffect.prototype, "getDoubleClickScripts$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], ExhibitionEffect.prototype, "getGenericScripts$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], ExhibitionEffect.prototype, "getProfessions$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], ExhibitionEffect.prototype, "getDepartments$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], ExhibitionEffect.prototype, "getOccupationalGroups$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], ExhibitionEffect.prototype, "getTitles$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], ExhibitionEffect.prototype, "getAllProfessions$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], ExhibitionEffect.prototype, "getAllDepartments$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], ExhibitionEffect.prototype, "getAllOccupationalGroups$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], ExhibitionEffect.prototype, "getAllTitles$", void 0);
    return ExhibitionEffect;
}());
export { ExhibitionEffect };
