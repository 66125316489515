import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterPlaceholder'
})
export class FilterPlaceholderPipe implements PipeTransform {
  transform(value: string, args?: any): any {
    const linkTextOnly = value.match(/<.*?>/);

    if (linkTextOnly) {
      return linkTextOnly[0].slice(1, -1);
    } else {
      return value;
    }
  }
}
