import { TranslationsService } from './../../_pages/translations/translations.service';
import * as fromRoot from '../../app.reducer';
import * as stepsActions from '../services-with-reducers/step-forms/steps-forms.actions';

import {
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {
  FormGroupValidationModel,
  FormInputsPayloadModel,
  FormStatusPayloadModel
} from '../services-with-reducers/step-forms/step.interface';
import { Observable, Subject, from, BehaviorSubject, Subscriber } from 'rxjs';
import {
  buffer,
  debounceTime,
  first,
  groupBy,
  map,
  mergeMap,
  startWith,
  toArray
} from 'rxjs/operators';

import { HelperService } from '../services-with-reducers/helpers/helper.service';
import { Injectable } from '@angular/core';
import { InputBase } from './inputs/input-base.class';
import { QuestionnaireDataInput } from '../services-with-reducers/customization/customization.interfaces';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { UserProfileModel } from '../services-with-reducers/user/user.interface';
import { ValidationService } from '../validation/validation.service';
import { SelectOption } from '../services-with-reducers/exhibition/exhibition.interface';
import cloneDeep from 'lodash.clonedeep';
import { TextInput } from './inputs/input-text.class';
import { TextOrDropdownInputTypes } from '../services-with-reducers/helpers/helper.interface';

@Injectable({
  providedIn: 'root'
})
export class FormsService {
  public inputsValidity: Object = {};
  /**
   * SUBJECT is used here as aggregator of synchronous data to stream.
   * it accepts changed input fields (InputBase)
   *
   * @private
   * @memberof FormsService
   */

  private subject = new Subject();
  private removeFormSubject = new Subject();
  private counter: number = 0;
  private formValidationCallbacks: FormGroupValidationModel[] = [];
  private validationInterval = null;
  
  //this should fire whenever we modify this.inputsValidity object (step-navigation-buttons component is subscribed to it):
  public validationFeedbacksUpdated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private _store: Store<fromRoot.State>,
    private _validationService: ValidationService,
    private _helperService: HelperService,
    private _translateService: TranslateService,
    private _translationsService: TranslationsService
  ) {
    /* FOLLOWING CODE CATCH REMOVING FORMS VALIDATIONS AND CONCENTRATE THEM TO BATCH REMOVALS  */

    this.removeFormSubject
      .pipe(
        buffer(
          this.removeFormSubject.pipe(
            startWith([]),
            debounceTime(100)
          )
        )
      )
      .subscribe((formsToRemove: FormStatusPayloadModel[]) => {
        this._store.dispatch(
          new stepsActions.RemoveFormValidity(formsToRemove)
        );
      });

    /* FOLLOWING CODE CATCH FORM CHANGES AND CONCENTRATE THEM TO BATCH CHANGES  */

    // this.subject accepts changed form ( mostly with only one input field changed )
    const subscription = this.subject
      .pipe(
        /**
         * group is needed so it cannot happend that someone edit two different forms within 100ms and mix thir data together
         * streams are grouped to substreams based on uniq form ID (item.formInfo)
         */
        groupBy((item: FormInputsPayloadModel) => {
          return item.formInfo.join('.');
        }),
        /* flatmap handles, that substreams splited by form name
         * (once streamed forms are merged into a single one with latest values)
         * are again joined back to one final stream.
         */
        mergeMap(observableGroupByInputName => {
          return observableGroupByInputName.pipe(
            /* collect forms in a stream into an array.
             * Buffer() accepts a stream (the same stream we observe in this case),
             * based on which it recognizes, when to stop buffering streamed data into array
             * and pass it further (into map() function in our case)
             */
            buffer(
              this.subject.pipe(
                startWith({}),
                debounceTime(100)
              )
            ),
            // use map() to turn the array of forms into one form with latest values
            map((arrayOfForms: FormInputsPayloadModel[]) => {
              if (!arrayOfForms.length) {
                return null;
              } else if (arrayOfForms.length === 1) {
                // if only one was buffered, send it separately
                return arrayOfForms[0];
              } else {
                // if more inputs changed at a time reduce the array into final form object
                const mergedResult = arrayOfForms.reduce(
                  (
                    acc: FormInputsPayloadModel,
                    curent: FormInputsPayloadModel
                  ) => {
                    const merged: FormInputsPayloadModel = {
                      formInfo: acc.formInfo,
                      inputSet: { ...acc.inputSet, updatedInputs: null },
                      callback: acc.callback
                    };

                    /*
                     * Mass fill (automated filling form fields by browser based on prevously filled forms elsewehre)
                     * only add values (never remove ones), so we only replace old values with new existing oned
                     */

                    curent.inputSet.list.forEach((input, index) => {
                      //SteZ_20201116:
                      //if we're coming from newInputValue function update only inputs from FormInputsPayloadModel.inputSet.updatedInputs property. if that property isn't defined do the usual magic inside those ifs.
                      //this is important in the following testing scenario:
                      //1. choose an event and a ticket,
                      //2. go to personalization, change the first name and immediately click on some dynamic checkbox.
                      //in this scenario we'd get two forms (the first one with the first name changed, the second one with the checkbox changed) and while merging the second form we'd lose the first name i.e. we'd return it to the previous value.
                      if (curent.inputSet.updatedInputs === undefined || curent.inputSet.updatedInputs.includes(input.key)) {
                        if (
                          curent.inputSet.list[index].hasOwnProperty('value') &&
                          curent.inputSet.list[index].value
                        ) {
                          merged.inputSet.list[index].value =
                            curent.inputSet.list[index].value;
                        }

                        if (curent.inputSet.list[index].options.length) {
                          merged.inputSet.list[index].options =
                            curent.inputSet.list[index].options;
                        }

                        if (curent.callback) {
                          merged.callback = curent.callback;
                        }
                      }
                    });
                    return merged;
                  }
                );
                // In case there are more merged inputs, prerender anyway.
                mergedResult.inputSet.rerender = true;
                return mergedResult;
              }
            })
          );
        })
      )
      /* at the end we have a stream of forms debunced by 100ms,
       * we can now save it without wory about flodding http requests with form save request
       */
      .subscribe((mergedInputs: FormInputsPayloadModel) => {
        if (mergedInputs) {
          // in case that formInfo has two parts and it has no callback it is saved to steps-forms reducer
          if (mergedInputs.formInfo.length === 2 && !mergedInputs.callback) {
            this._store.dispatch(new stepsActions.SetInputs(mergedInputs));
          }
          /** in case it has a callback, it is not steps form
           * and the form save need to be handled separately in the callback
           */
          if (mergedInputs.callback) {
            mergedInputs.callback(
              mergedInputs.inputSet.list,
              mergedInputs.inputSet.rerender
            );
          }
        }
        this.counter = 0;
      });
  }

  /** as values comes from single inputs, we want to save the form just once, after all inputs has changed
   *  It must be done this way as autofill could change multiple inputs at once. If merge save is not introduced here,
   *  all input values except the last one would be lost
   */

  mergeInputsBeforeSave(actionPayload: FormInputsPayloadModel) {
    if (++this.counter == 1) {
      this.subject.next(actionPayload);
    } else {
      setTimeout(() => {
        this.subject.next(actionPayload);
      }, 300);
    }
  }

  updateInputs(
    inputsToUpdate: InputBase<any>[],
    inputsWithNewValues: InputBase<any>[]
  ) {
    if (!inputsWithNewValues.length) {
      return [];
    }
    if (inputsToUpdate) {
      inputsToUpdate.forEach((input: InputBase<any>, index) => {
        if (inputsWithNewValues[index]) {
          if (
            input.controlType === 'textbox' &&
            inputsWithNewValues[index].value
          ) {
            let newValue = inputsWithNewValues[index].value;

            if (!input.noTrim) {
              newValue = newValue.trim();
            }

            inputsWithNewValues[index].value = newValue;
          }

          input.value = inputsWithNewValues[index].value || '';
          input.disabled = inputsWithNewValues[index].disabled || false;
          input.options = inputsWithNewValues[index].options;
          input.hidden = inputsWithNewValues[index].hidden;
        }
      });
    } else {
      inputsToUpdate = cloneDeep(inputsWithNewValues);
    }

    return inputsToUpdate;
  }

  getFunctionTextValue(
    profile: UserProfileModel,
    input: InputBase<any>,
    inputTitles: SelectOption[],
    allTitles: QuestionnaireDataInput[],
    inputProfessions: SelectOption[],
    allProfessions: QuestionnaireDataInput[],
    inputDepartments: SelectOption[],
    allDepartments: QuestionnaireDataInput[],
    inputOccupationalGroups: SelectOption[],
    allOccupationalGroups: QuestionnaireDataInput[]
  ) {
    let value = profile[input.key];

    const isTitleInput = input.key === TextOrDropdownInputTypes.Title;
    const isProfessionInput = input.key === TextOrDropdownInputTypes.Function;
    const isDepartmentInput = input.key === TextOrDropdownInputTypes.Department;
    const isOccupationalGroupInputInput = input.key === TextOrDropdownInputTypes.OccupationalGroup;

    if (
      !isNaN(<number>profile[input.key]) && (
        (isTitleInput && !inputTitles.length && allTitles.length) ||
        (isProfessionInput && !inputProfessions.length && allProfessions.length) ||
        (isDepartmentInput && !inputDepartments.length && allDepartments.length) ||
        (isOccupationalGroupInputInput && !inputOccupationalGroups.length && allOccupationalGroups.length)
      ) && input.options && input.options.length
    ) {
      if (isTitleInput) {
        value = this.dataInput(allTitles, profile, input);
      }
      else if (isProfessionInput) {
        value = this.dataInput(allProfessions, profile, input);
      } 
      else if (isDepartmentInput) {
        value = this.dataInput(allDepartments, profile, input);
      } 
      else if (isOccupationalGroupInputInput) {
        value = this.dataInput(allOccupationalGroups, profile, input);
      }
    }

    return value;
  }

  dataInput(allInputs, profile, input) {
    let value = profile[input.key];

    const data = allInputs.find(
      (data: QuestionnaireDataInput) => {
        return data.id === Number(profile[input.key]);
      }
    );

    if (data) {
      this._translateService.get(data.text).subscribe(translation => {
        value = translation;
      });
    }

    return value;
  }

  /**
   * This function will reset the Function (Position) value if its current value doesn't exists in settings.
   * NOTE: Be aware when mixing this function with synchronous code because "reset" is done inside subscription (asynchronously)
   */
  resetInvalidFunctionValue(
    input: InputBase<any>,
    availableTitles: SelectOption[],
    availableProfessions: SelectOption[],
    availableDepartments: SelectOption[],
    availableOccupationalGroups: SelectOption[],
    updateCb: Function,
    updateAlways?: boolean
  ) {
    const isTitleInput = input.key === TextOrDropdownInputTypes.Title;
    const isFunctionInput = input.key === TextOrDropdownInputTypes.Function;
    const isDepartmentInput = input.key === TextOrDropdownInputTypes.Department;
    const isOccupationalGroupInput = input.key === TextOrDropdownInputTypes.OccupationalGroup;

    if (isTitleInput) {
      const availableTitlesTranslationSource = from(
        availableTitles
      ).pipe(
        mergeMap((titles: SelectOption) =>
          new Observable((subscriber: Subscriber<string>) => {
            this._translationsService.getPositionTranslationForOption(
              input,
              titles,
              (translatedText: string) => {
                subscriber.next(translatedText);
                subscriber.complete();
              }
            );
          })
        ),
        toArray()
      );
      

      availableTitlesTranslationSource.subscribe(
        (availableTitles: string[]) => {
          const titlesInList = availableTitles.find(
            titlesText => titlesText === input.value
          );
          const invalidTitles =
            !titlesInList && availableTitles.length;

          if (updateAlways || invalidTitles) {
            updateCb(input, invalidTitles);
          }
        }
      );
    } else if (isFunctionInput) {
      const availableProfessionsTranslationSource = from(
        availableProfessions
      ).pipe(
        mergeMap((profession: SelectOption) =>
          new Observable((subscriber: Subscriber<string>) => {
            this._translationsService.getPositionTranslationForOption(
              input,
              profession,
              (translatedText: string) => {
                subscriber.next(translatedText);
                subscriber.complete();
              }
            );
          })
        ),
        toArray()
      );

      availableProfessionsTranslationSource.subscribe(
        (availableProfessions: string[]) => {
          const professionExistInList = availableProfessions.find(
            professionText => professionText === input.value
          );
          const invalidProfession =
            !professionExistInList && availableProfessions.length;

          if (updateAlways || invalidProfession) {
            updateCb(input, invalidProfession);
          }
        }
      );
    } else if (isDepartmentInput) {
      const availableDepartmentsTranslationSource = from(
        availableDepartments
      ).pipe(
        mergeMap((department: SelectOption) =>
          new Observable((subscriber: Subscriber<string>) => {
            this._translationsService.getPositionTranslationForOption(
              input,
              department,
              (translatedText: string) => {
                subscriber.next(translatedText);
                subscriber.complete();
              }
            );
          })
        ),
        toArray()
      );

      availableDepartmentsTranslationSource.subscribe(
        (availableDepartments: string[]) => {
          const departmentExistInList = availableDepartments.find(
            departmentText => departmentText === input.value
          );
          const invalidDepartment =
            !departmentExistInList && availableDepartments.length;

          if (updateAlways || invalidDepartment) {
            updateCb(input, invalidDepartment);
          }
        }
      );
    } else if (isOccupationalGroupInput) {
      const availableOccupationalGroupsTranslationSource = from(
        availableOccupationalGroups
      ).pipe(
        mergeMap((occupationalGroup: SelectOption) =>
          new Observable((subscriber: Subscriber<string>) => {
            this._translationsService.getPositionTranslationForOption(
              input,
              occupationalGroup,
              (translatedText: string) => {
                subscriber.next(translatedText);
                subscriber.complete();
              }
            );
          })
        ),
        toArray()
      );

      availableOccupationalGroupsTranslationSource.subscribe(
        (availableOccupationalGroups: string[]) => {
          const occupationalGroupInList = availableOccupationalGroups.find(
            occupationalGroupText => occupationalGroupText === input.value
          );
          const invalidOccupationalGroup =
            !occupationalGroupInList && availableOccupationalGroups.length;

          if (updateAlways || invalidOccupationalGroup) {
            updateCb(input, invalidOccupationalGroup);
          }
        }
      );
    }
  }

  inputsToFormControl(inputs: InputBase<any>[], formPath?: string) {
    const group: any = {};
    let selectedCountryCode = '';
    let globalFormValidators: ValidatorFn[] = [];

    if (inputs) {
      inputs
        .filter(input => {
          return input.key === 'country' && input.value;
        })
        .map(input => {
          selectedCountryCode = input.value;
        });

      inputs.forEach(input => {
        if (input.controlType === 'checkbox') {
          const checkboxesGroup: any = {};

          input.options.forEach((option, index) => {
            const validators = [];

            if (option.required && !input.hidden) {
              validators.push(Validators.required);
            }

            checkboxesGroup[input.key + '_' + option.key] = new FormControl(
              {
                value: option.value || false,
                disabled: option.disabled || false
              },
              validators
            );
          });

          let chckboxValidator = null;
          if (input.required && !input.hidden) {
            chckboxValidator = ValidationService.checkboxGroupValidator;
          }
          const fg = new FormGroup(checkboxesGroup, chckboxValidator);

          group[input.key] = fg;
        } else {
          /* SETUP VALIDATORS */
          let validators = [];
          let asyncValidators = [];

          if (input.required) {
            validators.push(Validators.required);
          }

          if (input.maxLengthValidation) {
            validators.push(Validators.maxLength(input.maxLengthValidation));
          }

          if (input.minLengthValidation) {
            validators.push(Validators.minLength(input.minLengthValidation));
          }

          if (input.emailValidation) {
            validators.push(ValidationService.emailValidator(input.required));
          }

          if (input.zipcodeValidation) {
            // construct form path from input zipCodeCitiesTag so we know on which form we made changes to zip code input
            const  { zipCodeCitiesTag } = input as TextInput;
            const _formPath: string = zipCodeCitiesTag.includes('holder') ? zipCodeCitiesTag.replace('holder', formPath) : zipCodeCitiesTag;
            asyncValidators.push(
              this._validationService.zipCodeValidator(
                selectedCountryCode,
                input.required,
                _formPath
              )
            );
          }

          if (input.phoneValidation) {
            validators.push(ValidationService.phoneValidator());
          }

          if (input.sameAsValidation) {
            globalFormValidators.push(
              ValidationService.equalValueValidator(
                input.key,
                input.sameAsValidation,
                input.caseInsensitive
              )
            );
          }

          if (input.controlType === 'textbox') {
            validators.push(ValidationService.emptySpacesValidator());
          }

          if (input.hidden) {
            validators = [];
            asyncValidators = [];
          }

          group[input.key] = new FormControl(
            {
              value: input.controlType === 'dateInput' ? (!!input.value ? new Date(input.value) : null) : input.value || '',
              disabled: input.disabled || input.hidden || false
            },
            validators,
            asyncValidators
          );
        }
      });

      return { group, globalFormValidators };
    }
  }

  removeFormsValidationFeedback(stepsFormsActionName?: string[]) {
    if (stepsFormsActionName && stepsFormsActionName.length === 2) {
      this._store
        .select(fromRoot.getSteps)
        .first()
        .subscribe(steps => {
          const step =
            steps[stepsFormsActionName[0]].forms[stepsFormsActionName[1]];

          if (step) {
            const inputs =
              steps[stepsFormsActionName[0]].forms[stepsFormsActionName[1]]
                .list;

            const validationObjectCopy = cloneDeep(
              this.inputsValidity[stepsFormsActionName[0]]
            );

            inputs.forEach((input: InputBase<any>) => {
              delete validationObjectCopy[
                stepsFormsActionName[1] + '_' + input.key
              ];
            });

            this.inputsValidity[stepsFormsActionName[0]] = validationObjectCopy;
            this.validationFeedbacksUpdated.next(true);
          }
        });
    }
  }

  toFormGroup(inputs: InputBase<any>[], stepsFormsActionName?: string[]) {
    if (inputs) {
      const formControls = this.inputsToFormControl(inputs, !!stepsFormsActionName && stepsFormsActionName.length > 1 ? stepsFormsActionName[1] : null);
      const stepsForm = new FormGroup(formControls.group);

      stepsForm.setValidators(formControls.globalFormValidators);

      // // as there is a bug in AG4 forms, formgroup with only required checkboxes
      // // is marked as valid on init, so we need to wait and recheck validity 

      // // In case form uses async validators we are not able to get the validity when created
      // // The solution is to wait until the form is not pending anymore
      
      //SteZ: but do it in the order of arrival, not randomly
      //(as then the first form validation could be executed last which could mean the form would be set as valid even though it really isn't):
      this.formValidationCallbacks.push({ formActionName: stepsFormsActionName, form: stepsForm });
      this.processFormValidationQueue();

      if (stepsFormsActionName && stepsFormsActionName.length === 2) {
        // recheck validity on any form status(validity) change
        stepsForm.statusChanges.subscribe(status => {
          this.handleFormValidityChange(!stepsForm.invalid, stepsForm, stepsFormsActionName);
        });
      }

      return stepsForm;
    }
  }

  /**
   * This function will process form validation callbacks in FIFO fashion but it will call setFormValidity function only for the last form validation callback of one kind i.e. one formActionName.
   * If a form is still in pending status the function will wait for it to exit that status. 
   */
  processFormValidationQueue() {
    //only proceed if validationInterval is not set or not running:
    if (this.validationInterval === null || this.validationInterval.state !== 'scheduled') {
      //set validationInterval to run every 100ms:
      this.validationInterval = setInterval(() => {
        //proceed only if we have formValidationCallbacks to process:
        if (!!this.formValidationCallbacks && this.formValidationCallbacks.length) {
          while (!!this.formValidationCallbacks && this.formValidationCallbacks.length) {
            //get the first form validation callback in the queue:
            const firstCallback: FormGroupValidationModel = this.formValidationCallbacks[0];

            //check if we have more form validation callbacks of the same formActionName:
            const allCallbacksSameAsFirst: FormGroupValidationModel[] = this.formValidationCallbacks.filter(
              callback => !!callback.formActionName && !!firstCallback.formActionName && 
                          callback.formActionName.toString() === firstCallback.formActionName.toString()
            );

            if (!!allCallbacksSameAsFirst && allCallbacksSameAsFirst.length > 1) {
              //if we have more form validation callbacks of the same formActionName skip the current one (process only the last one):
              this.formValidationCallbacks.splice(0, 1);
            } else {
              //only process the last form validation callback of the same formActionName:
              if (!firstCallback.form.pending) {
                //the form is no longer in pending status so we can call setFormValidity:
                this.handleFormValidityChange(!firstCallback.form.invalid, firstCallback.form, firstCallback.formActionName);

                //remove the already processed form validation callback:
                this.formValidationCallbacks.splice(0, 1);
              } else {
                //the form is in pending status so we have to wait a little bit longer:
                break;
              }
            }
          }
        } else {
          //we have no more form validation callbacks to process so we have to cancel the periodic execution of validationInterval:
          clearInterval(this.validationInterval);
        }
      }, 100);
    }
  }

  handleFormValidityChange(valid: boolean, form: FormGroup, stepsFormsActionName: string[]) {
    if (stepsFormsActionName && stepsFormsActionName.length === 2) {
      //personal: ticketHolder and buyerinfo forms have their own validation rules so we shouldn't just call SetFormValidity here:
      if (stepsFormsActionName[0] === 'personal') {
        if (stepsFormsActionName[1].startsWith('ticketHolder')) {
          this.setTicketHolderFormValidity(valid, stepsFormsActionName);
          this.updateValidationFeedbacks(form, stepsFormsActionName);
          return;
        } else if (stepsFormsActionName[1].startsWith('buyerinfo')) {
          this._store.dispatch(new stepsActions.SetBuyerinfoFormValidity(valid));
          this.updateValidationFeedbacks(form, stepsFormsActionName);
          return;
        }
      }
    }

    //otherwise just call setFormValidity:
    this.setFormValidity(valid, form, stepsFormsActionName);
  }

  // write down status(valid === true, invalid === false) of current form into the steps forms store
  setFormValidity(
    valid: boolean,
    form: FormGroup,
    stepsFormsActionName?: string[]
  ) {
    const actionPayload: FormStatusPayloadModel = {
      formInfo: stepsFormsActionName,
      valid: valid
    };

    this.updateValidationFeedbacks(form, stepsFormsActionName);

    if (stepsFormsActionName && stepsFormsActionName.length === 2) {
      /*       if (valid && !form) {
        if (this.inputsValidity.hasOwnProperty(stepsFormsActionName[0])) {
          Object.keys(
            this.inputsValidity[stepsFormsActionName[0]]
          ).forEach(key => {
            if (key.startsWith(stepsFormsActionName[1])) {
              delete this.inputsValidity[stepsFormsActionName[0]][key];
            }
          });
        }
      } */

      this._store.dispatch(new stepsActions.SetFormValidity(actionPayload));
    }
  }

  updateValidationFeedbacks(form: FormGroup, stepsFormsActionName?: string[]) {
    if (stepsFormsActionName && stepsFormsActionName.length === 2 && form) {
      this._store
        .select(fromRoot.getSteps)
        .pipe(first())
        .subscribe(steps => {
          this.inputsValidity[stepsFormsActionName[0]] =
            this.inputsValidity[stepsFormsActionName[0]] || {};

          const step =
            steps[stepsFormsActionName[0]].forms[stepsFormsActionName[1]];

          if (step) {
            const inputs =
              steps[stepsFormsActionName[0]].forms[stepsFormsActionName[1]]
                .list;

            this.inputsValidity[
              stepsFormsActionName[0]
            ] = this.generateValidationFeedback(
              inputs,
              form,
              stepsFormsActionName[1],
              this.inputsValidity[stepsFormsActionName[0]]
            );
            this.validationFeedbacksUpdated.next(true);
          }
        });
    }
  }

  // write down status(valid === true, invalid === false) of current form into the steps forms store
  removeFormValidity(stepsFormsActionName?: string[]) {
    const actionPayload: FormStatusPayloadModel = {
      formInfo: stepsFormsActionName,
      valid: false
    };
    if (stepsFormsActionName && stepsFormsActionName.length === 2) {
      this.removeFormSubject.next(actionPayload);
    }
  }

  setTicketHolderFormValidity(
    valid: boolean,
    stepsFormsActionName: string[]
  ) {
    this._store.dispatch(new stepsActions.SetTicketHolderFormValidity({ formInfo: stepsFormsActionName, valid: valid }));
  }

  generateValidationFeedback(
    inputs: InputBase<any>[],
    form: FormGroup,
    formName: string,
    validationObject: Object
  ) {
    const validationObjectCopy = cloneDeep(validationObject);

    if (!!inputs) {
      inputs.forEach((input: InputBase<any>) => {
        if (
          form.controls[input.key] &&
          form.controls[input.key].invalid &&
          !input.hidden
        ) {
          let label = input.label || input.options[0].label;
          validationObjectCopy[formName + '_' + input.key] = {
            translate: input.translate,
            label:
              label && label.length > 0
                ? label.replace(/<(?:.|\n)*?>/gm, '')
                : label // this one avoid html markup to be shown in the message
          };
        } else {
          delete validationObjectCopy[formName + '_' + input.key];
        }
      });
    }

    return validationObjectCopy;
  }

  addStepValidationFeedback(
    stepsFormsActionName: string[],
    key: string,
    messageTranslationKey: string
  ) {
    if (key == ''){
      this.inputsValidity = {};
      this.validationFeedbacksUpdated.next(true);
    } else {
      this.inputsValidity[stepsFormsActionName[0]] =
        this.inputsValidity[stepsFormsActionName[0]] || {};
  
      this.inputsValidity[stepsFormsActionName[0]][
        stepsFormsActionName[1] + '_' + key
      ] = {
        translate: true,
        label: messageTranslationKey
      };
      this.validationFeedbacksUpdated.next(true);
    }
  }

  removeStepValidationFeedback(stepsFormsActionName: string[], key: string) {
    if (
      key &&
      this.inputsValidity.hasOwnProperty(stepsFormsActionName[0]) &&
      this.inputsValidity[stepsFormsActionName[0]].hasOwnProperty([
        stepsFormsActionName[1] + '_' + key
      ])
    ) {
      delete this.inputsValidity[stepsFormsActionName[0]][
        stepsFormsActionName[1] + '_' + key
      ];
      this.validationFeedbacksUpdated.next(true);
    }
  }

  removeAllStepValidationFeedbacks(stepsFormsActionName: string[]) {
    if (this.inputsValidity.hasOwnProperty(stepsFormsActionName[0])) {
      // delete validation inputs when form is removed from the flow (now used after ticketHolder form is removed, or billing address unchecked)
      Object.keys(this.inputsValidity[stepsFormsActionName[0]]).forEach(
        (inputValidationKey: string) => {
          if (inputValidationKey.indexOf(stepsFormsActionName[1]) >= 0) {
            delete this.inputsValidity[stepsFormsActionName[0]][
              inputValidationKey
            ];
            this.validationFeedbacksUpdated.next(true);
          }
        }
      );
    }
  }

  setStepValid(stepsFormsActionName: string[]) {
    // set the whole form valid in reducer
    this.setFormValidity(true, null, stepsFormsActionName);
    this.removeAllStepValidationFeedbacks(stepsFormsActionName);
  }

  removeStepValidation(stepsFormsActionName: string[]) {
    // set the whole form valid in reducer
    this.removeFormValidity(stepsFormsActionName);
    this.removeAllStepValidationFeedbacks(stepsFormsActionName);
  }

  checkStepValidationFeedbacks(stepsFormsActionName: string[]) {
    if (this.inputsValidity.hasOwnProperty(stepsFormsActionName[0]) && Object.keys(this.inputsValidity[stepsFormsActionName[0]]).length) {
      return false;
    }

    return true;
  }

  translateCountries(preferredList) {
    if (!preferredList) {
      return from([]);
    }

    return this._helperService.loadCountries().pipe(
      map((p: any) => {
        const translationMap = p.map(country => `country.${country.shortCode}`);

        let sortedCountries = [];

        return this._translateService.stream(translationMap).pipe(
          map(termsTranslation => {
            return (sortedCountries = Object.keys(termsTranslation)
              .map(key => {
                return {
                  key: key.replace('country.', ''),
                  value: termsTranslation[key],
                  translate: false
                };
              })
              // first full preferred to top
              .sort((a, b) => {
                const preferredIndexA: number = preferredList.indexOf(a.key);
                const preferredIndexB: number = preferredList.indexOf(b.key);

                if (preferredIndexA > -1 && preferredIndexB > -1) {
                  //if both countries are in preferred countries list:
                  if (preferredIndexA < preferredIndexB) {
                    return -1;
                  } else {
                    return 1;
                  }
                }

                if (preferredIndexA > -1) {
                  return -1;
                }

                if (preferredIndexB > -1) {
                  return 1;
                } else {
                  return a.value.localeCompare(b.value);
                }
              }));
          })
        );
      })
    );
  }

  resetInputsValidity() {
    this.inputsValidity = {};
    this.validationFeedbacksUpdated.next(true);
  }
}
