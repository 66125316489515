var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { first, filter, delay } from 'rxjs/operators';
import { TopBarComponent } from '../top-bar.component';
import { UserService } from '../../../shared/services-with-reducers/user/user.service';
import { WindowSizeService } from '../../../shared/window-size/window-size.service';
import { CustomizationService } from '../../../shared/services-with-reducers/customization/customization.service';
import { HelperService } from '../../../shared/services-with-reducers/helpers/helper.service';
import * as fromRoot from '../../../app.reducer';
var TopBarWidgetComponent = /** @class */ (function (_super) {
    __extends(TopBarWidgetComponent, _super);
    function TopBarWidgetComponent(_router, _userService, _store, _windowSizeService, _customizationService, _helperService) {
        var _this = _super.call(this, _router, _userService, _store, _windowSizeService, _customizationService, _helperService) || this;
        _this.displayHeader = false;
        return _this;
    }
    TopBarWidgetComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._store
            .select(fromRoot.getWidgetSettings)
            .pipe(filter(function (widgetSettings) {
            if (widgetSettings && widgetSettings.ticketsWidget) {
                return true;
            }
        }), delay(0), first())
            .subscribe(function (_a) {
            var ticketsWidget = _a.ticketsWidget;
            _this.widgetSettings = ticketsWidget;
            _this.displayHeader = Boolean(ticketsWidget.header);
        });
    };
    return TopBarWidgetComponent;
}(TopBarComponent));
export { TopBarWidgetComponent };
