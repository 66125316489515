import { first, filter } from 'rxjs/operators';
import * as fromRoot from '../../app.reducer';
import * as stepsActions from '../../shared/services-with-reducers/step-forms/steps-forms.actions';
import * as ticketActions from '../../shared/services-with-reducers/tickets/ticket.actions';
import { OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { AppService } from '../../app.service';
import { HelperService } from '../../shared/services-with-reducers/helpers/helper.service';
import { combineLatest, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { UserService } from '../../shared/services-with-reducers/user/user.service';
import { environment } from '../../../environments/environment';
import { GtmService } from '../../shared/gtm/gtmService';
import { StepsFormsService } from '../../shared/services-with-reducers/step-forms/steps-forms.service';
import { getLocalStorageString } from '../../shared/app-utils';
import { AppConstants } from '../../shared/app-constants';
var WebShopInvoiceComponent = /** @class */ (function () {
    function WebShopInvoiceComponent(_appService, _helperService, _store, _userService, _gtmService, _stepsFormsService) {
        var _this = this;
        this._appService = _appService;
        this._helperService = _helperService;
        this._store = _store;
        this._userService = _userService;
        this._gtmService = _gtmService;
        this._stepsFormsService = _stepsFormsService;
        this.shareOn = {};
        this.socialMediaShares = [];
        this._subscription = new Subscription();
        this.isAnonymousTicketLoading = false;
        this.isAnonymous = false;
        this.allTicketsTicketRetrievalLink = false;
        var voucherURL = this._helperService.getVoucherUrl();
        var ticketParams = this._helperService.getTicketParams();
        !!voucherURL && this.onResetShop(true, voucherURL);
        !!ticketParams && this.onResetShop(true, null, ticketParams);
        combineLatest([
            this._store.pipe(select(fromRoot.getTickets)),
            this._store.pipe(select(fromRoot.getTicketsTypes)),
            this._store.pipe(select(fromRoot.getTicketHolderInputSets)),
            this._store.pipe(select(fromRoot.getTicketHolderAdditionalData)),
            this._store.pipe(select(fromRoot.getIsAnonymousTicketTaken)),
            this._store.pipe(select(fromRoot.getAreTicketsSent))
        ])
            .pipe(filter(function (_a) {
            var ungroupedTickets = _a[0], ticketTypes = _a[1], ticketHolderInputSets = _a[2], ticketHolderAdditionalData = _a[3];
            return (!!ungroupedTickets &&
                !!ticketTypes &&
                !!ticketHolderInputSets &&
                !!ticketHolderAdditionalData);
        }), first())
            .subscribe(function (_a) {
            var ungroupedTickets = _a[0], ticketTypes = _a[1], ticketHolderInputSets = _a[2], ticketHolderAdditionalData = _a[3], isAnonymousTicketTaken = _a[4], ticketsSent = _a[5];
            _this.isAnonymous = isAnonymousTicketTaken;
            if (isAnonymousTicketTaken && !ticketsSent) {
                _this._store.dispatch(new ticketActions.SetAreTicketsSent(true));
                _this.isAnonymousTicketLoading = true;
                var ungroupedTicketsWithHolders = _this._stepsFormsService.assignTicketsToHoldersForSave(ungroupedTickets, ticketHolderInputSets, ticketHolderAdditionalData, '');
                _this._stepsFormsService.prepareDataForSaveAndSend(ungroupedTicketsWithHolders, 0, isAnonymousTicketTaken);
            }
        });
        this.exhibitionId$ = this._store.select(fromRoot.getSelectedExhibitionId);
        var visibilityPayload = [
            {
                stepKey: 'tickets',
                visible: false
            },
            {
                stepKey: 'personal',
                visible: false
            },
            {
                stepKey: 'workshop',
                visible: false
            },
            {
                stepKey: 'menu',
                visible: false
            },
            {
                stepKey: 'legitimation',
                visible: false
            },
            {
                stepKey: 'confirmation',
                visible: false
            }
        ];
        this._store.dispatch(new stepsActions.SetMultipleStepsVisibility(visibilityPayload));
    }
    WebShopInvoiceComponent.prototype.ngOnInit = function () {
        var _this = this;
        // we bought the tickets, remove the booking
        this._store.dispatch(new ticketActions.RemoveTicketBookings());
        setInterval(function () {
            _this.isMobile = _this._helperService.isMobile();
        }, 0);
        this._subscription.add(this._store
            .select(fromRoot.getTicketHolderInputSets)
            .subscribe(function (holders) {
            _this.hasMobileTicket = holders.some(function (holder) {
                var sendingOption = holder.inputSet.list.find(function (input) { return input.key === 'sendingOption'; });
                return sendingOption.value === 'mobilePerOwner' || sendingOption.value === 'ticketRetrivalLink';
            });
            //check if there are any parking tickets as then we need to show the ticket download button even if all other tickets are ticketRetrievalLink:
            var storedParkingTickets = getLocalStorageString(AppConstants.parkingTicketsReducer);
            var parkingTicketsDictionary = storedParkingTickets && JSON.parse(storedParkingTickets);
            var parkingTickets = Object.keys(parkingTicketsDictionary).reduce(function (filtered, key) {
                if (parkingTicketsDictionary[key].hasOwnProperty('price')) {
                    filtered[key] = parkingTicketsDictionary[key];
                }
                return filtered;
            }, {});
            var hasParkingTickets = Object.keys(parkingTickets).length > 0;
            //check if all tickets are ticketRetrievalLink but only if we don't have any parking tickets:
            _this.allTicketsTicketRetrievalLink = !hasParkingTickets && holders.length > 0 && !holders.some(function (ticketHolder) {
                var sendingOption = ticketHolder.inputSet.list.find(function (input) { return input.key === 'sendingOption'; });
                return !!sendingOption && sendingOption.value !== 'ticketRetrivalLink';
            });
        }));
        this._userService
            .getListOfSocialShares()
            .pipe(filter(function (data) { return !!data; }), first())
            .subscribe(function (shares) {
            _this.socialMediaShares = shares;
        });
        this.exhibitionId$.pipe(first()).subscribe(function () {
            _this._store.dispatch(new stepsActions.SetSelectedStep('invoice'));
        });
        this._store
            .select(fromRoot.getTicketSelectedSendingOptions)
            .pipe(first())
            .subscribe(function (sendingOption) {
            _this.selectedSendingOption = sendingOption;
        });
        combineLatest([
            this._store.pipe(select(fromRoot.isDownloadTicketButtonVisible)),
            this._store.pipe(select(fromRoot.getOrderResponse))
        ])
            .pipe(first())
            .subscribe(function (_a) {
            var isDownloadTicketButtonVisible = _a[0], orderResponse = _a[1];
            _this.isDownloadTicketButtonVisible = (_this.allTicketsTicketRetrievalLink && !orderResponse.hasValidVisitorQuestionnaire) ? false : isDownloadTicketButtonVisible;
        });
        this._store
            .select(fromRoot.getOrderResponse)
            .pipe(filter(function (data) { return !!data; }), first())
            .subscribe(function (orderResponse) {
            _this.invoiceDownloadUrl = (orderResponse.showInvoiceDownload === null || orderResponse.showInvoiceDownload) && orderResponse.hash
                ? "" + environment.protocol + environment.webApiUrl + "/person/download-invoice/" + orderResponse.hash
                : '';
            _this.ticketsDownloadUrl = orderResponse.hash
                ? "" + environment.protocol + environment.webApiUrl + "/person/download-all-tickets/" + orderResponse.hash
                : '';
            _this.ticketsMobileDownloadUrl = orderResponse.hash
                ? "" + environment.protocol + environment.webApiUrl + "/person/download-all-mobile-tickets/" + orderResponse.hash
                : '';
            _this.isAnonymousTicketLoading = false;
        });
    };
    WebShopInvoiceComponent.prototype.ngOnDestroy = function () {
        this._subscription.unsubscribe();
    };
    WebShopInvoiceComponent.prototype.urlEncoding = function (url) {
        return encodeURIComponent(url);
    };
    WebShopInvoiceComponent.prototype.socialmediaShare = function (socialMedia) {
        var _this = this;
        var domain = environment.protocol + environment.origin;
        domain = domain || window.location.origin;
        this.exhibitionId$.pipe(first()).subscribe(function (exhibitionId) {
            var encodedUrl = _this.urlEncoding(domain) + "/webshop/" + exhibitionId;
            var url = '';
            switch (socialMedia) {
                case 'facebook':
                    url = "https://www.facebook.com/sharer/sharer.php?display=popup&u=" + encodedUrl + "&quote=I will attend this event";
                    break;
                case 'twitter':
                    url = "https://twitter.com/intent/tweet?url=" + encodedUrl + "&text=I will attend this event";
                    break;
                case 'linkedin':
                    url = "https://www.linkedin.com/shareArticle?mini=true&url=" + encodedUrl + "&title=I will attend this event";
                    break;
                case 'xing':
                    url = "https://www.xing.com/spi/shares/new?url=" + encodedUrl;
                    break;
                case 'vkontakte':
                    url = "https://vk.com/share.php?url=" + encodedUrl;
                    break;
                case 'whatsapp':
                    url = "https://api.whatsapp.com/send?text=I will attend this event " + encodedUrl;
                    break;
            }
            window.open(url, '', 'height=700,width=700,scrollbars=yes,toolbar=no,status=yes');
        });
    };
    WebShopInvoiceComponent.prototype.onResetShop = function (releaseAllVouchersAndTickets, voucherId, ticketParams) {
        var _this = this;
        if (releaseAllVouchersAndTickets === void 0) { releaseAllVouchersAndTickets = true; }
        this._store
            .select(fromRoot.getSelectedExhibitionId)
            .pipe(first())
            .subscribe(function (exhibitionId) {
            _this._appService
                .resetReducers(releaseAllVouchersAndTickets)
                .subscribe(function () {
                window.location.replace("/webshop/" + exhibitionId + (!!voucherId ? "/tickets?voucher=" + voucherId : '') + (!!ticketParams ? "/tickets" + ticketParams : ''));
            });
        });
    };
    WebShopInvoiceComponent.prototype.ngAfterViewInit = function () {
        this._gtmService.pushPurchase();
    };
    return WebShopInvoiceComponent;
}());
export { WebShopInvoiceComponent };
