import { AppConstants } from '../app-constants';
import { HelperService } from '../services-with-reducers/helpers/helper.service';
import * as i0 from "@angular/core";
import * as i1 from "../services-with-reducers/helpers/helper.service";
var WindowSizeService = /** @class */ (function () {
    function WindowSizeService(_helperService) {
        this._helperService = _helperService;
        this.devices = {
            xs: [0, AppConstants.BREAKPOINTS.sm],
            sm: [AppConstants.BREAKPOINTS.sm, AppConstants.BREAKPOINTS.md],
            md: [AppConstants.BREAKPOINTS.md, AppConstants.BREAKPOINTS.lg],
            lg: [AppConstants.BREAKPOINTS.lg, 10000]
        };
    }
    WindowSizeService.prototype.viewportWidth = function () {
        // in case we are in angular universal
        if (typeof window !== 'undefined') {
            var e = window;
            var a = 'inner';
            if (!window.innerWidth) {
                a = 'client';
                e = document.documentElement || document.body;
            }
            return e[a + 'Width'];
        }
        else {
            return '100%';
        }
    };
    WindowSizeService.prototype.viewportHeight = function () {
        // in case we are in angular universal
        if (typeof window !== 'undefined') {
            var e = window;
            var a = 'inner';
            if (!window.innerHeight) {
                a = 'client';
                e = document.documentElement || document.body;
            }
            return e[a + 'Height'];
        }
        else {
            return '100%';
        }
    };
    WindowSizeService.prototype.deviceSize = function () {
        var devices = {};
        var clientWidth = this.viewportWidth();
        for (var device in this.devices) {
            if (this.devices[device][0] <= clientWidth &&
                this.devices[device][1] > clientWidth) {
                devices[device] = true;
            }
            else {
                devices[device] = false;
            }
        }
        return devices;
    };
    WindowSizeService.prototype.currentSize = function () {
        var size;
        var devices = this.deviceSize();
        for (var device in devices) {
            if (devices[device] === true) {
                size = device;
            }
        }
        return size;
    };
    // animationTime in seconds
    WindowSizeService.prototype.scrollToElement = function (element, offsetTop, offsetBottom, animationTime) {
        var _this = this;
        var frameRate = 200; // 100 frames per second
        var timePerFrame = 1000 / frameRate;
        // scroll up if the element is above the top fold
        if (element.getBoundingClientRect().top < offsetTop) {
            var yScroll_1 = window.pageYOffset;
            var minimumFrameDistance = 10;
            var initialDistance = element.getBoundingClientRect().top + offsetTop;
            var frameDistance_1 = initialDistance / (frameRate * animationTime);
            if (Math.abs(frameDistance_1) <= minimumFrameDistance) {
                frameDistance_1 = minimumFrameDistance;
            }
            var interval_1 = setInterval(function () {
                yScroll_1 -= Math.abs(frameDistance_1);
                window.scroll(0, yScroll_1);
                // stop scrolling once top edge of element is in screen.. or once you try to scroll out of screen
                if (element.getBoundingClientRect().top >= offsetTop ||
                    window.pageYOffset <= 0) {
                    clearInterval(interval_1);
                }
            }, timePerFrame);
            // scroll down if the elemnent is below the bottom fold
        }
        else if (element.getBoundingClientRect().bottom >
            this.viewportHeight() - offsetBottom) {
            var yScroll_2 = window.pageYOffset;
            var initialDistance = element.getBoundingClientRect().bottom - offsetBottom;
            var frameDistance_2 = initialDistance / (frameRate * animationTime);
            var appScrollEl_1 = this._helperService.appEl.querySelector('.app-page');
            var interval_2 = setInterval(function () {
                yScroll_2 += Math.abs(frameDistance_2);
                window.scroll(0, yScroll_2);
                // stop scrolling once the bottom edge (+ bottom offset) of the element is
                // scrolled into view.. or once you try to scroll out of screen
                if (element.getBoundingClientRect().bottom <=
                    _this.viewportHeight() - offsetBottom ||
                    window.innerHeight + window.pageYOffset >= appScrollEl_1.offsetHeight) {
                    clearInterval(interval_2);
                }
            }, timePerFrame);
        }
    };
    WindowSizeService.ngInjectableDef = i0.defineInjectable({ factory: function WindowSizeService_Factory() { return new WindowSizeService(i0.inject(i1.HelperService)); }, token: WindowSizeService, providedIn: "root" });
    return WindowSizeService;
}());
export { WindowSizeService };
