/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./four-o-four.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/pipes/hide-translation/hide-translation.pipe";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./four-o-four.component";
import * as i5 from "@angular/router";
var styles_FourOFourComponent = [i0.styles];
var RenderType_FourOFourComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FourOFourComponent, data: {} });
export { RenderType_FourOFourComponent as RenderType_FourOFourComponent };
export function View_FourOFourComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.HideTranslationPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "title-404"], ["data-translation", "four-o-four.title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", "\n"])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(4, 1), (_l()(), i1.ɵeld(5, 0, null, null, 3, "p", [["class", "comment-404"], ["data-translation", "four-o-four.message"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", "\n"])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(8, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 4, 0, i1.ɵnov(_v, 0), i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("four-o-four.title")))); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 6, 0, _ck(_v, 8, 0, i1.ɵnov(_v, 0), i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("four-o-four.message")))); _ck(_v, 6, 0, currVal_1); }); }
export function View_FourOFourComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-four-o-four", [], null, null, null, View_FourOFourComponent_0, RenderType_FourOFourComponent)), i1.ɵdid(1, 114688, null, 0, i4.FourOFourComponent, [i5.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FourOFourComponentNgFactory = i1.ɵccf("app-four-o-four", i4.FourOFourComponent, View_FourOFourComponent_Host_0, {}, {}, []);
export { FourOFourComponentNgFactory as FourOFourComponentNgFactory };
