import { SignalrService } from './../signalr/signalr.service';
import { combineLatest as observableCombineLatest, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { CustomizationService } from '../services-with-reducers/customization/customization.service';
import { NgZone, OnDestroy } from '@angular/core';
import { hubMethods } from '../signalr/signalr-signals';
import { HelperService } from '../../shared/services-with-reducers/helpers/helper.service';
import { HomeComponent } from '../../_pages/home/home.component';
import { first, takeUntil } from 'rxjs/operators';
import { AppConstants } from '../app-constants';
import { getAppVersion } from '../app-utils';
var MaintenancePageGuard = /** @class */ (function () {
    function MaintenancePageGuard(_ngZone, _router, _customizationService, _signalrService, _helperService) {
        var _this = this;
        this._ngZone = _ngZone;
        this._router = _router;
        this._customizationService = _customizationService;
        this._signalrService = _signalrService;
        this._helperService = _helperService;
        this._name = this.constructor.name;
        this._canActivateSubject$ = new Subject();
        this._reloadRequiredSubject$ = new Subject();
        this._unsubscribe = new Subject();
        // listen to maintenace change from signalr
        this.handleOperatorMaintenace();
        this.handleEventMaintenace();
        this.handleReloadRequired();
        observableCombineLatest([
            this._canActivateSubject$,
            this._reloadRequiredSubject$
        ])
            .pipe(takeUntil(this._unsubscribe))
            .subscribe(function (_a) {
            var canActivate = _a[0], reloadRequired = _a[1];
            if (!canActivate) {
                _this.toMaintenacePage();
            }
            if (canActivate && reloadRequired) {
                _this._helperService.setReloadRequired(true);
            }
        });
    }
    MaintenancePageGuard.prototype.ngOnDestroy = function () {
        this._unsubscribe.next();
        this._unsubscribe.complete();
    };
    MaintenancePageGuard.prototype.canActivate = function (route, state) {
        var _this = this;
        var canActivatePromise = new Promise(function (resolve) {
            var eventIdFromUrl = state.url.split('/')[2];
            if (eventIdFromUrl) {
                _this.eventId = eventIdFromUrl;
            }
            else {
                _this.eventId = null;
            }
            // get maintenace data from api
            _this.handleAlreadyRunningMaintenace();
            observableCombineLatest([
                _this._canActivateSubject$,
                _this._reloadRequiredSubject$
            ])
                .pipe(first())
                .subscribe(function (_a) {
                var canActivate = _a[0], reloadRequired = _a[1];
                if (canActivate && reloadRequired) {
                    canActivate = route.component === HomeComponent;
                }
                resolve(canActivate);
            });
        });
        return canActivatePromise;
    };
    MaintenancePageGuard.prototype.handleOperatorMaintenace = function () {
        var _this = this;
        this._signalrService.registerHandler(this._name, hubMethods.maintenaceOperatorStarted, function () {
            _this._canActivateSubject$.next(false);
        });
    };
    MaintenancePageGuard.prototype.handleEventMaintenace = function () {
        var _this = this;
        this._signalrService.registerHandler(this._name, hubMethods.maintenaceEventStarted, function (maintenaceData) {
            if (_this.eventId == maintenaceData) {
                _this._canActivateSubject$.next(false);
            }
        });
    };
    MaintenancePageGuard.prototype.handleReloadRequired = function () {
        var _this = this;
        this._signalrService.registerHandler(this._name, hubMethods.reloadRequired, function () {
            _this._reloadRequiredSubject$.next(true);
        });
    };
    MaintenancePageGuard.prototype.handleAlreadyRunningMaintenace = function () {
        var _this = this;
        return observableCombineLatest(this._customizationService.getOperatorSettings(), this._customizationService.getCustomSettings(this.eventId)).subscribe(function (data) {
            var operatorSettings = data[0], eventSettings = data[1];
            //in case there are no operator settings or event settings we show maintenence page
            if (operatorSettings.maintenance.isPresent || eventSettings.maintenance.isPresent) {
                _this._canActivateSubject$.next(false);
            }
            else {
                _this._canActivateSubject$.next(true);
            }
            //a page reload is required if:
            //-there's a new version of the application (this is checked here),
            //-or the ReloadRequired message is received via the SignalR communication.
            var version = getAppVersion();
            var oldAppVersion = localStorage.getItem(AppConstants.appVersionReducer);
            if (!(oldAppVersion && oldAppVersion === version)) {
                _this._reloadRequiredSubject$.next(true);
            }
            else {
                _this._reloadRequiredSubject$.next(false);
            }
        });
    };
    MaintenancePageGuard.prototype.toMaintenacePage = function () {
        var _this = this;
        return this._ngZone.run(function () { return _this._router.navigate(['/maintenance']); });
    };
    return MaintenancePageGuard;
}());
export { MaintenancePageGuard };
