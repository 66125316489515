import {
  combineLatest as observableCombineLatest,
  Subscription
} from 'rxjs';

import { filter, first } from 'rxjs/operators';
import * as fromRoot from '../../../app.reducer';
import * as stepsActions from '../../../shared/services-with-reducers/step-forms/steps-forms.actions';
import * as userActions from '../../../shared/services-with-reducers/user/user.actions';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormInputsPayloadModel } from '../../../shared/services-with-reducers/step-forms/step.interface';

import { FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import cloneDeep from 'lodash.clonedeep';
import { FormsService } from '../../../shared/forms/forms.service';
import { InputBase } from '../../../shared/forms/inputs/input-base.class';
import { InputsListModel } from '../../../shared/services-with-reducers/step-forms/step.interface';
import { UserProfileModel } from '../../../shared/services-with-reducers/user/user.interface';

@Component({
  moduleId: module.id,
  selector: 'app-billing-address',
  templateUrl: './billing-address.component.html',
  styleUrls: ['./billing-address.component.scss']
})
export class BillingAddressComponent implements OnDestroy, OnInit {
  public inputs: InputBase<any>[];
  public form: FormGroup;
  public BillingAddressActionName = ['personal', 'billingaddress'];
  public billingAddressList = [];
  public selectedBuyerBillAddressId = -1;

  private _subscriptions = new Subscription();
  private isEditEnabled = true;
  private isLoggedIn: boolean;

  constructor(
    private _store: Store<fromRoot.State>,
    private _formsService: FormsService
  ) {}

  ngOnInit() {
    this._subscriptions.add(
      this._store.pipe(select(fromRoot.getBuyerActiveBillingAddressId)).subscribe(selectedBuyerBillAddressId => {
        this.selectedBuyerBillAddressId = selectedBuyerBillAddressId;
      })
    );

    this._subscriptions.add(
      this._store.select(fromRoot.isUserLoggedIn).subscribe(isLoggedIn => {
        this.isLoggedIn = isLoggedIn;
      })
    );

    this._store
      .select(fromRoot.getProfile)
      .pipe(
        filter(data => !!data),
        first()
      )
      .subscribe((user: UserProfileModel) => {
        this.isLoggedIn = true;
        this._store.dispatch(new userActions.GetProfileBillingAddress(user.id));
      });
    // Load list of billing addresses via API request
    this._store
      .select(fromRoot.getProfileBillingAddress)
      .subscribe(billingAddressList => {
        this.billingAddressList = billingAddressList;
      });

    // once Addresses are loaded via API we can subscribe to them from redux here
    this._subscriptions.add(
      observableCombineLatest(
        this._store.select(fromRoot.getBillingAddressForm),
        this._store.select(fromRoot.isUserLoggedIn)
      )
        .pipe(
          filter(data => {
            return !!data[0];
          })
        )
        .subscribe((data: [InputsListModel, boolean]) => {
          const [billingAddress, isLoggedIn] = data;

          if (billingAddress.list.length) {
            const input = billingAddress.list.find(
              input => input.key === 'save-address'
            );
            input.hidden = !isLoggedIn;

            if (!isLoggedIn) {
              input.options[0].value = false;
            }
            // update is needed for changes like browser address autocomplete or google address API autocomplete

            if (!this.form) {
              this.inputs = this._formsService.updateInputs(
                this.inputs,
                billingAddress.list
              );
              const indexOfSelectedBillingAddress: number = Object.keys(this.billingAddressList)
                .findIndex(key => this.billingAddressList[key].id === this.selectedBuyerBillAddressId);

              this.inputs.some(item => !!item.value) ? null : this.onChange(indexOfSelectedBillingAddress);
              this.rerenderForm(this.inputs);
            }
          }
        })
    );
  }

  public formSaveCallback = (inputs: InputBase<any>[], rerender: boolean) => {
    this.inputs = this._formsService.updateInputs(this.inputs, inputs);

    const billingAddressPayload: FormInputsPayloadModel = {
      formInfo: this.BillingAddressActionName,
      inputSet: {
        rerender: true,
        list: this.inputs
      }
    };

    this._store.dispatch(new stepsActions.SetInputs(billingAddressPayload, true));

    if (rerender) {
      this.form = this._formsService.toFormGroup(
        this.inputs,
        this.BillingAddressActionName
      );
    }
  };

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  onChange(value) {
    let selectedBillingAddressId = null;
    if (value >= 0) {
      selectedBillingAddressId = this.billingAddressList[value].id;

      // Change values and readonly attribute of billing address after selecting saved address
      this.inputs.forEach(input => {
        input.value = this.billingAddressList[value][input.key];
        input.disabled = true;
        if (input.key === 'save-address') {
          input.hidden = true;
          input.options[0].value = false;
        }
      });
      this.isEditEnabled = false;
    } else {
      this.inputs.forEach(input => {
        input.value = undefined;
        input.disabled = false;
        if (input.key === 'save-address') {
          input.hidden = false;
        }
      });
      this.isEditEnabled = true;
    }

    this._store.dispatch(new stepsActions.SetBuyerActiveBillingAddressId(selectedBillingAddressId));

    this.rerenderForm(this.inputs);
  }

  rerenderForm(inputs) {
    //make sure we are not prerendering with references
    const clonedInputs = cloneDeep(inputs);

    this.form = this._formsService.toFormGroup(
      clonedInputs,
      this.BillingAddressActionName
    );

    this.inputs = clonedInputs;
  }
}
