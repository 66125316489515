<div class="page-padding-wrapper">
  <div *ngIf="isConfirmedEmailRequiredOnPersonalization">
    <app-web-shop-account-verification></app-web-shop-account-verification>
  </div>

  <div
    class="ticket-login"
    *ngIf="
      (showLoginOnPersonalPage$ | async) ||
      (showLoginOnTicketAndPersonalPage$ | async)
    "
  >
    <app-login
      class="login-body"
      [validationStepName]="registrationFormsActionName"
    ></app-login>
  </div>

  <ng-container
    *ngIf="!isConfirmedEmailRequiredOnPersonalization && !(showLoginOnPersonalPage$ | async) && !(showLoginOnTicketAndPersonalPage$ | async)"
  >
    <div
      appAdjustSideHeadline
      *ngIf="
        !continueAsGuest ||
        ((showLoginOnTicketPage$ | async) && continueAsGuest) ||
        continueAsGuest
      "
      [ngStyle]="buyerStyle"
    >
      <div class="section-headline">
        <h2
          [appRichText]="
            'personalize.buyer-info.section-title' | translate | hideTranslation
          "
          data-translation="personalize.buyer-info.section-title"
        ></h2>
        <div
          class="section-headline-side"
          data-translation="personalize.buyer-info.headline"
        >
          {{ 'personalize.buyer-info.headline' | translate | hideTranslation }}
        </div>
      </div>
      <div class="section-body">
        <app-buyer-info 
          [areTicketHoldersVisible]="
            !isSelfRegistrationEnabled &&
            (isTicketHolderVisible$ | async) &&
            !ticketHolderInputsNotVisible
          " 
          (isInputChanged)="inputChanged($event)"
        ></app-buyer-info>
      </div>
    </div>
    
    <ng-container
      *ngIf="
        (!(showLoginOnPersonalPage$ | async) &&
          !(showLoginOnTicketAndPersonalPage$ | async)) ||
        continueAsGuest
      "
    >
      <!-- BUYER QUESTIONNAIRE -->
      <app-extra-data
        *ngIf="questionnaireLoaded && !isSelfRegistrationEnabled"
        [stepsFormsActionName]="['personal', 'questionnaire']"
      ></app-extra-data>

      <app-order-tickets
        [isInputChanged$]="isInputChanged$"
        [ticketSelectedSendingOption]="selectedSendingOption"
        (isVisitorQuestionnaireShown)="allowAdditionalDataToShow($event)"
        *ngIf="
          !isSelfRegistrationEnabled &&
          (isTicketHolderVisible$ | async) &&
          !ticketHolderInputsNotVisible
        "
      ></app-order-tickets>

      <!-- VISITOR QUESTIONNAIRE -->
      <app-ticket-holder-questionnaire
        *ngIf="showVisitorQuestionnaire && selectedSendingOption === 'ticketRetrivalLink'"
        (isValid)="setVisitorQuestionnaireValidity($event)"
      ></app-ticket-holder-questionnaire>
      
    </ng-container>

    <div
      *ngIf="checkboxesInputs && checkboxesInputs.length"
      class="privacy-policy"
    >
      <form
        [formGroup]="checkboxesForm"
        class="row no-group-margin hide-input-validation"
      >
        <app-df-input
          *ngFor="let input of checkboxesInputs"
          [input]="input"
          [class]="input.cssClass"
          [inputSet]="checkboxesInputs"
          [action]="checkboxesFormsActionName"
          [form]="checkboxesForm"
        >
        </app-df-input>
      </form>
    </div>

    <div *ngIf="exhibitionSettings" class="external-links">
      <div
        *ngIf="
          exhibitionSettings.isPrivacyLinkVisible && isSelfRegistrationEnabled
        "
        class="privacy-link"
      >
        <svg class="icon-link">
          <use xlink:href="assets/defs.svg#icon-link"></use>
        </svg>
        <span
          class="clickable"
          (click)="openPrivacyModalWindow(exhibitionSettings.privacyUrl)"
        >
          {{
            'personalize.privacy-link'
              | translate
              | filterPlaceholder
              | hideTranslation
          }}
        </span>
      </div>

      <div *ngIf="exhibitionSettings.isDisclaimerVisible" class="disclaimer-link">
        <svg class="icon-link">
          <use xlink:href="assets/defs.svg#icon-link"></use>
        </svg>
        <a
          *ngIf="!isSelfRegistrationEnabled"
          target="_blank"
          (click)="openIframe($event, exhibitionSettings.disclaimerUrl)"
          href="{{ exhibitionSettings.disclaimerUrl }}"
          data-translation="personalize.disclaimer-link"
          >{{ 'personalize.disclaimer-link' | translate | hideTranslation }}</a
        >

        <span
          class="clickable"
          *ngIf="isSelfRegistrationEnabled"
          (click)="
            openModalWindow(
              exhibitionSettings.disclaimerUrl,
              'personalize.disclaimer-link'
            )
          "
        >
          {{ 'personalize.disclaimer-link' | translate | hideTranslation }}
        </span>
      </div>
      <div *ngIf="exhibitionSettings.isImprintVisible" class="imprint-link">
        <svg class="icon-link">
          <use xlink:href="assets/defs.svg#icon-link"></use>
        </svg>
        <a
          *ngIf="!isSelfRegistrationEnabled"
          target="_blank"
          (click)="openIframe($event, exhibitionSettings.imprintUrl)"
          href=" {{ exhibitionSettings.imprintUrl }}
                  "
          data-translation="personalize.imprint-link "
          >{{ 'personalize.imprint-link' | translate | hideTranslation }}</a
        >

        <span
          class="clickable"
          *ngIf="isSelfRegistrationEnabled"
          (click)="
            openModalWindow(
              exhibitionSettings.imprintUrl,
              'personalize.imprint-link'
            )
          "
        >
          {{ 'personalize.imprint-link' | translate | hideTranslation }}
        </span>
      </div>
    </div>
  </ng-container>

  <app-step-navigation-buttons></app-step-navigation-buttons>

  <app-modal-window
    [modalWindowActive]="modalWindowOpen"
    (contextmenu)="closeModalWindowOnRightClick($event)"
    [translateTitle]="modalTranslateTitle"
    [modalWindowTitle]="modalTitle"
    [fullWidth]="true"
  >
    <div modalWindow-body>
      <iframe [src]="modalUrl | safeUrl" class="iframe"></iframe>
    </div>

    <button
      class="button button-option"
      modalWindow-close
      (click)="closeModalWindow($event)"
    >
      <span data-translation="modalWindow.close">{{
        'modalWindow.close' | translate | hideTranslation
      }}</span>
    </button>
  </app-modal-window>
</div>