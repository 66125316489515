import {
  BillingAddressModel,
  EventsEmailUnsubscriptionsModle,
  LoginModel,
  QuestionnaireSaveModel,
  UserModel,
  UserProfileModel,
  VerifyEmailResponseModel,
  UserProfileUpdateModel
} from './user.interface';
import {
  SendingOptionModel,
  TicketModel,
  TicketRecordModel
} from '../tickets/ticket.interface';

import { Action } from '@ngrx/store';
import { InputBase } from '../../forms/inputs/input-base.class';

export enum ActionTypes {
  SET_USER = '[User] Set user',
  LOGIN = '[User] Login',
  SET_LOGIN_TIMESTAMP = '[User] Set time of logging in as timestamp',
  PROFILE_LOADING = '[User] Set status of profile loading',
  GET_PROFILE = '[User] Get user profile',
  SET_PROFILE = '[User] Set user profile',
  PUT_PROFILE = '[User] Update user profile by PUT',
  PUT_PROFILE_WITHOUT_VALIDATION = '[User] Update user profile by PUT, without validation',
  POST_PROFILE = '[User] Create user profile by POST',
  SET_LANGUAGE = '[User] Set user language',
  GET_PROFILE_BILLING_ADDRESS = '[User] Get billing address',
  PUT_PROFILE_BILLING_ADDRESS = '[User] Put billing address',
  SET_PROFILE_BILLING_ADDRESS = '[User] Set billing address',
  DELETE_PROFILE_BILLING_ADDRESS = '[User] Delete billing address',
  UPDATE_PROFILE_BILLING_ADDRESS = '[User] Update billing address',
  SET_SELECTED_BILLING_ADDRESS = '[User] Set selected billing address id',
  GET_REGISTRATION_QUESTIONNAIRE = '[User] Get Registration Questionnaire',
  SET_REGISTRATION_QUESTIONNAIRE = '[User] Set Registration Questionnaire',
  POST_REGISTRATION_QUESTIONNAIRE_PROFILE = '[User] Post Registration Questionnaire via API',
  GET_TICKETS_HISTORY_BY_USER = '[User] Get Tickets History By User',
  SET_TICKETS_HISTORY_BY_USER = '[User] Set Tickets History By User',
  GET_EVENTS_EMAIL_UNSUBSCRIPTIONS = '[User] Get Events Email Unsubscriptions',
  SET_EVENTS_EMAIL_UNSUBSCRIPTIONS = '[User] Set Events Email Unsubscriptions',
  PATCH_EMAIL = '[User] patch email',
  SET_PROFILES_EMAIL = '[User] set profiles email',
  PATCH_PASSWORD = '[User] patch password',
  RESET_PASSWORD = '[User] reset password',
  SET_PASSWORD_CHANGED = '[User] password changed',
  SET_EMAIL_CHANGED = '[User] email changed',
  POST_PASSWORD_RECOVERY = '[User] password recovery',
  PASSWORD_RECOVERY_RESULT = '[User] password recovery result',
  RESET_REDUCER = '[User] reset reducer',
  VERIFY_EMAIL = '[User] verify email',
  SET_EMAIL_VERIFIED = '[User] email verified result (status code)',
  CHANGE_PASSWORD = '[User] change password',
  CHANGE_EMAIL = '[User] change e-mail',
  DOWNLOAD_TICKET_BY_ID = '[User] download ticket by id'
}
/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions.
 *
 * See Discriminated Unions: https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions
 */

export class LogIn implements Action {
  readonly type = ActionTypes.LOGIN;
  constructor(public payload: LoginModel) {}
}

export class SetLoginTimestamp implements Action {
  readonly type = ActionTypes.SET_LOGIN_TIMESTAMP;
  constructor(public payload: number) {}
}

export class ProfileLoading implements Action {
  readonly type = ActionTypes.PROFILE_LOADING;
  constructor(public payload: boolean) {}
}

export class SetUser implements Action {
  readonly type = ActionTypes.SET_USER;
  constructor(public payload: UserModel) {}
}

export class GetProfile implements Action {
  readonly type = ActionTypes.GET_PROFILE;
  constructor(public payload: number) {}
}

export class SetProfile implements Action {
  readonly type = ActionTypes.SET_PROFILE;
  constructor(public payload: UserProfileModel) {}
}

export class UpdateProfile implements Action {
  readonly type = ActionTypes.PUT_PROFILE;
  constructor(public payload: UserProfileUpdateModel) {}
}

export class UpdateProfileWithoutValidation implements Action {
  readonly type = ActionTypes.PUT_PROFILE_WITHOUT_VALIDATION;
  constructor(public payload: UserProfileUpdateModel) {}
}

export class CreateProfile implements Action {
  readonly type = ActionTypes.POST_PROFILE;
  constructor(public payload: UserProfileModel) {}
}

export class SetLanguage implements Action {
  readonly type = ActionTypes.SET_LANGUAGE;
  constructor(public payload: string) {}
}

export class GetProfileBillingAddress implements Action {
  readonly type = ActionTypes.GET_PROFILE_BILLING_ADDRESS;
  constructor(public payload: number) {}
}

export class PutProfileBillingAddress implements Action {
  readonly type = ActionTypes.PUT_PROFILE_BILLING_ADDRESS;
  constructor(
    public payload: {
      userId: number;
      addressId: number;
      data: BillingAddressModel;
    }
  ) {}
}

export class DeleteProfileBillingAddress implements Action {
  readonly type = ActionTypes.DELETE_PROFILE_BILLING_ADDRESS;
  constructor(
    public payload: {
      userId: number;
      addressId: number;
    }
  ) {}
}
export class SetProfileBillingAddress implements Action {
  readonly type = ActionTypes.SET_PROFILE_BILLING_ADDRESS;
  constructor(public payload: BillingAddressModel[]) {}
}
export class UpdateProfileBillingAddress implements Action {
  readonly type = ActionTypes.UPDATE_PROFILE_BILLING_ADDRESS;
  constructor(public payload: BillingAddressModel) {}
}

export class SetSelectedBillingAddress implements Action {
  readonly type = ActionTypes.SET_SELECTED_BILLING_ADDRESS;
  constructor(public payload: number) {}
}
export class GetRegistrationQuestionnaire implements Action {
  readonly type = ActionTypes.GET_REGISTRATION_QUESTIONNAIRE;
  constructor(public payload: number) {}
}
export class PostRegistrationQuestionnaire implements Action {
  readonly type = ActionTypes.POST_REGISTRATION_QUESTIONNAIRE_PROFILE;
  constructor(
    public payload: {
      questionnaire: QuestionnaireSaveModel;
      userId: number;
    }
  ) {}
}
export class SetRegistrationQuestionnaire implements Action {
  readonly type = ActionTypes.SET_REGISTRATION_QUESTIONNAIRE;
  constructor(public payload: InputBase<any>[]) {}
}

export class GetTicketsHistoryByUser implements Action {
  readonly type = ActionTypes.GET_TICKETS_HISTORY_BY_USER;
  constructor(public payload: number) {}
}
export class SetTicketsHistoryByUser implements Action {
  readonly type = ActionTypes.SET_TICKETS_HISTORY_BY_USER;
  constructor(public payload: TicketRecordModel[]) {}
}
export class GetEventsEmailUnsubscriptions implements Action {
  readonly type = ActionTypes.GET_EVENTS_EMAIL_UNSUBSCRIPTIONS;
  constructor(public payload: number) {}
}

export class SetEventsEmailUnsubscriptions implements Action {
  readonly type = ActionTypes.SET_EVENTS_EMAIL_UNSUBSCRIPTIONS;
  constructor(public payload: InputBase<any>[]) {}
}

export class PatchEmail implements Action {
  readonly type = ActionTypes.PATCH_EMAIL;
  constructor(
    public payload: {
      email: string;
      password: string;
      userId: number;
      language: string;
    }
  ) {}
}

export class ResetPassword implements Action {
  readonly type = ActionTypes.RESET_PASSWORD;
  constructor(public payload: { password: string; hash: string; crmHash: string }) {}
}

export class VerifyEmail implements Action {
  readonly type = ActionTypes.VERIFY_EMAIL;
  constructor(public payload: { hash: string; crmHash: string }) {}
}

export class EmailVerified implements Action {
  readonly type = ActionTypes.SET_EMAIL_VERIFIED;
  constructor(public payload: VerifyEmailResponseModel) {}
}

export class ChangePassword implements Action {
  readonly type = ActionTypes.CHANGE_PASSWORD;
  constructor(public payload: { hash: string, crmHash: string }) {}
}

export class ChangeEmail implements Action {
  readonly type = ActionTypes.CHANGE_EMAIL;
  constructor(public payload: { hash: string, crmHash: string }) {}
}

export class PatchPassword implements Action {
  readonly type = ActionTypes.PATCH_PASSWORD;
  constructor(
    public payload: { password: string; userId: number; language: string }
  ) {}
}
export class SetProfilesEmail implements Action {
  readonly type = ActionTypes.SET_PROFILES_EMAIL;
  constructor(public payload: string) {}
}
export class PasswordChanged implements Action {
  readonly type = ActionTypes.SET_PASSWORD_CHANGED;
  constructor(public payload: number) {}
}

export class EmailChanged implements Action {
  readonly type = ActionTypes.SET_EMAIL_CHANGED;
  constructor(public payload: number) {}
}

export class RequestPasswordRecovery implements Action {
  readonly type = ActionTypes.POST_PASSWORD_RECOVERY;
  constructor(
    public payload: {
      email: string;
      lastName: string;
      lang: string;
      eventId: number;
    }
  ) {}
}
export class RequestPasswordRecoveryResult implements Action {
  readonly type = ActionTypes.PASSWORD_RECOVERY_RESULT;
  constructor(public payload: boolean) {}
}

export class DownloadTicketById implements Action {
  readonly type = ActionTypes.DOWNLOAD_TICKET_BY_ID;
  constructor(public payload: { id: number; type: string, ticketId?: number, packageIndex?: number }) {}
}

export class ResetReducer implements Action {
  readonly type = ActionTypes.RESET_REDUCER;
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Actions =
  | SetUser
  | LogIn
  | SetLoginTimestamp
  | ProfileLoading
  | GetProfile
  | SetProfile
  | CreateProfile
  | UpdateProfile
  | SetLanguage
  | GetProfileBillingAddress
  | PutProfileBillingAddress
  | SetProfileBillingAddress
  | UpdateProfileBillingAddress
  | DeleteProfileBillingAddress
  | SetSelectedBillingAddress
  | GetRegistrationQuestionnaire
  | PostRegistrationQuestionnaire
  | SetRegistrationQuestionnaire
  | GetTicketsHistoryByUser
  | SetTicketsHistoryByUser
  | GetEventsEmailUnsubscriptions
  | SetEventsEmailUnsubscriptions
  | PatchEmail
  | ResetPassword
  | VerifyEmail
  | EmailVerified
  | ChangePassword
  | ChangeEmail
  | PatchPassword
  | SetProfilesEmail
  | PasswordChanged
  | EmailChanged
  | RequestPasswordRecovery
  | RequestPasswordRecoveryResult
  | DownloadTicketById
  | ResetReducer
  | UpdateProfileWithoutValidation;
