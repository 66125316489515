import { filter } from 'rxjs/operators';
import * as fromRoot from '../app.reducer';

import { Component, OnInit } from '@angular/core';

import { FormsService } from '../shared/forms/forms.service';
import { HelperService } from '../shared/services-with-reducers/helpers/helper.service';
import { InputsListModel } from '../shared/services-with-reducers/step-forms/step.interface';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-web-shop-buyer-questionnaire',
  templateUrl: './web-shop-buyer-questionnaire.component.html',
  styleUrls: ['./web-shop-buyer-questionnaire.component.scss']
})
export class WebShopBuyerQuestionnaireComponent implements OnInit {
  private _subscriptions = new Subscription();
  public questionnaireLoaded = false;

  constructor(
    private _formsService: FormsService,
    private _store: Store<fromRoot.State>,
    private _helperService: HelperService
  ) {}

  ngOnInit() {
    this._subscriptions.add(
      this._store
        .select(fromRoot.getSelfRegQuestionnaire)
        .pipe(
          filter((data: InputsListModel) => {
            // if there are no data for questionnaire, load them
            if (data.list === null) {
              this._helperService.loadBuyerQuestionnaireViaApi('questionnaire');
              return false;
            }

            const questionnaireLoaded = data.list.length > 0;
            if (!questionnaireLoaded) {
              this._formsService.setStepValid([
                'questionnaire',
                'questionnaire'
              ]);
            }

            return questionnaireLoaded;
          })
        )
        .subscribe(questionnaire => {
          this.questionnaireLoaded = true;
        })
    );
  }
}
