/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./footer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../shared/pipes/is-hidden-translation/is-hidden-translation.pipe";
import * as i4 from "@angular/common";
import * as i5 from "./footer.component";
import * as i6 from "../../shared/services-with-reducers/helpers/helper.service";
var styles_FooterComponent = [i0.styles];
var RenderType_FooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
function View_FooterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "inner-footer"], ["data-translation", "footer.title"], ["data-translation-includes-children", ""]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform("footer.title", _co.year)); _ck(_v, 0, 0, currVal_0); }); }
function View_FooterComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "inner-footer float-right"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.appVersion; _ck(_v, 0, 0, currVal_0); }); }
export function View_FooterComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.IsHiddenTranslationPipe, []), (_l()(), i1.ɵand(16777216, null, null, 3, null, View_FooterComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(4, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_2)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !i1.ɵunv(_v, 2, 0, _ck(_v, 4, 0, i1.ɵnov(_v, 0), i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("footer.title")))); _ck(_v, 2, 0, currVal_0); var currVal_1 = !!_co.appVersion; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_FooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-footer", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i1.ɵdid(1, 4243456, null, 0, i5.FooterComponent, [i6.HelperService, i1.ElementRef], null, null)], null, null); }
var FooterComponentNgFactory = i1.ɵccf("app-footer", i5.FooterComponent, View_FooterComponent_Host_0, {}, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };
