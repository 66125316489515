import { of as observableOf, from as observableFrom, Subject, BehaviorSubject } from 'rxjs';
import { mergeMap, map, catchError, debounceTime, first } from 'rxjs/operators';
import { HelperService } from '../services-with-reducers/helpers/helper.service';
import { RegexPatterns } from './validation-patterns.const';
import { select, Store } from '@ngrx/store';
import * as fromRoot from '../../app.reducer';
import * as helperActions from '../../shared/services-with-reducers/helpers/helper.actions';
import * as i0 from "@angular/core";
import * as i1 from "../services-with-reducers/helpers/helper.service";
import * as i2 from "@ngrx/store";
var ValidationService = /** @class */ (function () {
    function ValidationService(_helperService, _store) {
        this._helperService = _helperService;
        this._store = _store;
        this.zipLast = {
            value: null,
            country: null,
            validation: null,
            formPath: null,
            zipCodeCities: []
        };
        this.zipValidationSubject = new Subject();
        this.formCountryInputChanged = false;
        this.formCountryInputChangedBH$ = new BehaviorSubject(null);
        this.formCountryInputChanged$ = this.formCountryInputChangedBH$.asObservable();
        this.resetZipValidationPromise();
    }
    ValidationService.emailValidator = function (isRequired) {
        var _this = this;
        return function (control) {
            var controlVal = control.value;
            return _this.emailValidatorFn(controlVal, isRequired);
        };
    };
    ValidationService.emailValidatorFn = function (controlVal, isRequired) {
        if (!controlVal) {
            return null;
        }
        var emailCanContainRegex = new RegExp('^(' + RegexPatterns.email + ')*$');
        // additional invalid characters
        var emailCannotContainRegex = new RegExp('[ßæ¥þ‰¼><=]+');
        var umlautRegex = new RegExp('[äöüëïöüÿÄÖÜËÏÖÜŸ]+');
        var matchPattern = controlVal.match(emailCanContainRegex);
        var containsAdditional = emailCannotContainRegex.test(controlVal);
        var nonAsciiChars = new RegExp('[^\x00-\x7F]+').test(controlVal);
        var validationError = null;
        if (umlautRegex.test(controlVal)) {
            validationError = { umlautCharacter: true };
        }
        else if (!matchPattern || containsAdditional || controlVal.length > 100 || nonAsciiChars) {
            validationError = { invalidEmailAddress: true };
        }
        else if (matchPattern) {
            validationError = null;
        }
        else if (!controlVal && !isRequired) {
            validationError = null;
        }
        return validationError;
    };
    ValidationService.phoneValidator = function () {
        var _this = this;
        return function (control) {
            var value = control.value;
            return _this.phoneValidatorFn(value);
        };
    };
    ValidationService.phoneValidatorFn = function (value) {
        var testMinSix = new RegExp(RegexPatterns.phoneLength);
        var valueAsString = value && value.toString();
        var validationError = { invalidPhoneNumber: true };
        // const testSpaces = new RegExp(RegexPatterns.phoneSpaces);
        // it need to be either empty or fit to patern
        if (!value ||
            testMinSix.test(valueAsString.replace(/\s/g, '')) //&& testSpaces.test(value))
        ) {
            validationError = null;
        }
        return validationError;
    };
    ValidationService.checkboxGroupValidator = function (form) {
        var isAnyChecked = Object.keys(form.controls)
            .map(function (key) {
            return form.controls[key].value;
        })
            .reduce(function (aggr, current) {
            return aggr || current;
        }, false);
        return isAnyChecked ? null : { noneChecked: true };
    };
    ValidationService.equalValueValidator = function (controlNameOne, controlNameTwo, caseInSensitive) {
        return function (group) {
            var controlOne = group.controls[controlNameOne];
            var controlTwo = group.controls[controlNameTwo];
            var isMatching;
            var validationError = null;
            if (!!controlOne && !!controlTwo && controlOne.value && controlTwo.value) {
                // only validate if both inputs have value
                if (caseInSensitive) {
                    isMatching =
                        controlOne.value.toLowerCase() === controlTwo.value.toLowerCase();
                }
                else {
                    isMatching = controlOne.value === controlTwo.value;
                }
                // se the propper messages for the two controls
                if (!isMatching && controlOne.valid && controlTwo.valid) {
                    var message = controlNameOne + " != " + controlNameTwo;
                    controlTwo.setErrors({ equalValue: controlNameTwo });
                    validationError = { equalValue: message };
                }
                if (isMatching && controlTwo.hasError('equalValue')) {
                    controlTwo.setErrors(null);
                }
            }
            return validationError;
        };
    };
    ValidationService.compareValidator = function (controllNameOne, controllNameTwo) {
        return function (control) {
            var result = null;
            if (control.parent) {
                var newValue = control.parent.controls[controllNameOne].value;
                var repeatValue = control.parent.controls[controllNameTwo].value;
                if (newValue !== repeatValue) {
                    result = { different: true };
                }
                if (newValue === '' || repeatValue === '') {
                    result = null;
                }
            }
            else {
                result = { different: true };
            }
            return result;
        };
    };
    ValidationService.prototype.resetZipValidationPromise = function () {
        var _this = this;
        this.zipValidationPromise = this.zipValidationSubject
            .pipe(debounceTime(500), mergeMap(function (data) {
            var selectedCountryCode = data.selectedCountryCode, inputValue = data.inputValue, formPath = data.formPath, isCountryChange = data.isCountryChange;
            var wasDataFoundInStore = false;
            // find if we already have data with same parameters in store
            _this._store.pipe(select(fromRoot.getAllCitiesByZipCode), first()).subscribe(function (zipCodeCities) {
                if (!!zipCodeCities) {
                    // set wasDataFoundInStore if same values were found
                    wasDataFoundInStore = zipCodeCities.some(function (zipCodeCity) {
                        if (selectedCountryCode === zipCodeCity.countryCode && inputValue.length !== zipCodeCity.zipCode.length && zipCodeCity.zipCode !== "" && !zipCodeCity.isZipCodeInvalid && zipCodeCity.zipCodeHasValidation) {
                            _this.zipLast.validation = { invalidZipCode: true };
                            _this._store.dispatch(new helperActions.SetCitiesWithSameZipcode({
                                cities: [],
                                zipCode: inputValue,
                                countryCode: selectedCountryCode,
                                formPath: formPath,
                                isZipCodeInvalid: true,
                                zipCodeHasValidation: zipCodeCity.zipCodeHasValidation
                            }));
                            return true;
                        }
                        // if we already have saved results with same zipcode and countrycode set zipLast values
                        if (!!zipCodeCity.countryCode && zipCodeCity.zipCode === inputValue && zipCodeCity.countryCode === selectedCountryCode) {
                            _this.zipLast.value = inputValue;
                            _this.zipLast.country = selectedCountryCode;
                            _this.zipLast.zipCodeCities = zipCodeCity.cities;
                            _this.zipLast.formPath = '';
                            _this.zipLast.validation = zipCodeCity.isZipCodeInvalid ? { invalidZipCode: true } : null;
                            // set values for current form
                            _this._store.dispatch(new helperActions.SetCitiesWithSameZipcode({
                                cities: zipCodeCity.cities,
                                zipCode: inputValue,
                                countryCode: selectedCountryCode,
                                formPath: formPath,
                                isZipCodeInvalid: zipCodeCity.isZipCodeInvalid,
                                zipCodeHasValidation: zipCodeCity.zipCodeHasValidation
                            }));
                            // if data was found return true and exit loop
                            return true;
                        }
                    });
                }
            });
            // if data was found, we don't have to check our database for data because we already have it in store
            // Set zipLast.validation to null and return empty observable
            if (wasDataFoundInStore) {
                if (isCountryChange) {
                    _this.formCountryInputChanged = false;
                    _this.formCountryInputChangedBH$.next(formPath);
                }
                return observableOf(_this.zipLast.validation);
            }
            return _this._helperService
                .checkZipcodeValidity(selectedCountryCode, inputValue)
                .pipe(map(function (response) {
                if (!!response.zipCodeCities && response.zipCodeCities.length > 0) {
                    _this._store.dispatch(new helperActions.SetCitiesWithSameZipcode({
                        cities: response.zipCodeCities,
                        zipCode: inputValue,
                        countryCode: selectedCountryCode,
                        formPath: formPath,
                        isZipCodeInvalid: response.isValidZipCode ? false : true,
                        zipCodeHasValidation: response.zipCodeHasValidation
                    }));
                }
                else {
                    _this._store.dispatch(new helperActions.SetCitiesWithSameZipcode({
                        cities: [],
                        zipCode: inputValue,
                        countryCode: selectedCountryCode,
                        formPath: formPath,
                        isZipCodeInvalid: response.isValidZipCode ? false : true,
                        zipCodeHasValidation: response.zipCodeHasValidation
                    }));
                }
                if (response.isValidZipCode) {
                    _this.zipLast.validation = null;
                }
                else {
                    _this.zipLast.validation = { invalidZipCode: true };
                }
                _this.zipLast.value = inputValue;
                _this.zipLast.country = selectedCountryCode;
                _this.zipLast.formPath = formPath;
                _this.zipLast.zipCodeCities = response.zipCodeCities || [];
                if (isCountryChange) {
                    _this.formCountryInputChanged = false;
                    _this.formCountryInputChangedBH$.next(formPath);
                }
                return _this.zipLast.validation;
            }), catchError(function (error) {
                console.error(error);
                return observableFrom([{ invalidZipCode: true }]);
            }));
        }), first())
            .toPromise();
        this.zipValidationPromise.then(function (data) {
            _this.resetZipValidationPromise();
        });
    };
    ValidationService.prototype.zipCodeValidator = function (selectedCountryCode, isRequired, formPath) {
        var _this = this;
        return function (control) {
            var value = control.value;
            return _this.zipCodeValidatorFn(value, selectedCountryCode, isRequired, formPath);
        };
    };
    ValidationService.prototype.zipCodeValidatorFn = function (value, selectedCountryCode, isRequired, formPath, isCountryChange) {
        var validationError = observableOf(null).toPromise();
        if (!!isCountryChange) {
            this.formCountryInputChanged = true;
        }
        if (selectedCountryCode) {
            if (this.zipLast &&
                this.zipLast.value === value &&
                this.zipLast.country === selectedCountryCode &&
                (!!formPath ? this.zipLast.formPath === formPath : true)) {
                validationError = observableOf(this.zipLast.validation).toPromise();
            }
            this.zipValidationSubject.next({
                selectedCountryCode: selectedCountryCode,
                inputValue: value,
                formPath: formPath,
                isCountryChange: this.formCountryInputChanged ? true : false
            });
            validationError = this.zipValidationPromise;
        }
        else {
            this.zipLast.validation = null;
        }
        return validationError;
    };
    ValidationService.emptySpacesValidator = function () {
        var _this = this;
        return function (control) {
            return _this.emptySpacesValidatorFn(control.value);
        };
    };
    ValidationService.emptySpacesValidatorFn = function (value) {
        var noSpaceRegex = new RegExp('^\\s+$');
        var validationError = null;
        if (noSpaceRegex.test(value)) {
            validationError = { required: true };
        }
        return validationError;
    };
    ValidationService.ngInjectableDef = i0.defineInjectable({ factory: function ValidationService_Factory() { return new ValidationService(i0.inject(i1.HelperService), i0.inject(i2.Store)); }, token: ValidationService, providedIn: "root" });
    return ValidationService;
}());
export { ValidationService };
