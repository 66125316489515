import {
  combineLatest as observableCombineLatest,
  Observable,
  Subscription
} from 'rxjs';

import { first, filter } from 'rxjs/operators';
import * as fromRoot from '../../app.reducer';
import * as userActions from '../../shared/services-with-reducers/user/user.actions';

import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  getEmailChangeForm,
  getPasswordChangeForm
} from '../form-inputs.edit-account';

import { FormsService } from '../../shared/forms/forms.service';
import { InputBase } from '../../shared/forms/inputs/input-base.class';
import { StatusBarService } from '../../status-bar/status-bar.service';
import { Store } from '@ngrx/store';
import { WindowSizeService } from '../../shared/window-size/window-size.service';
import { Router } from '@angular/router';
import { UserService } from '../../shared/services-with-reducers/user/user.service';

@Component({
  selector: 'app-change-credentials',
  templateUrl: './change-credentials.component.html',
  styleUrls: ['./change-credentials.component.scss']
})
export class ChangeCredentialsComponent
  implements OnInit, OnDestroy, AfterViewInit {
  public emailForm: FormGroup;
  public emailInputs: InputBase<any>[];
  public passwordForm: FormGroup;
  public passwordInputs: InputBase<any>[];
  public changeEmailAction = ['changeEmailForm'];
  public changePasswordAction = ['changePasswordForm'];
  public emailChangeActive = false;
  public passwordChangeActive = false;
  public profile: any;
  private subscriptions = new Subscription();

  constructor(
    private _store: Store<fromRoot.State>,
    private _formBuilder: FormBuilder,
    private _formsService: FormsService,
    private _statusBarService: StatusBarService,
    private _windowSizeService: WindowSizeService,
    public _userService: UserService,
    public _router: Router
  ) {
    // set password status change to null so we can react on it later when we got real response
    this._store.dispatch(new userActions.PasswordChanged(null));
  }
  ngAfterViewInit() {
    if (!this.profile.email) {
      const emailBlock = document.querySelector('.form-block') as HTMLElement;

      this._windowSizeService.scrollToElement(emailBlock, 50, 50, 0.02);

      setTimeout(() => {
        emailBlock.focus();
        this.emailChangeActive = true;
      }, 100);
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngOnInit() {
    this.subscriptions.add(
      this._store.select(fromRoot.passChangeStatus).subscribe(statusCode => {
        if (statusCode === 204) {
          this._statusBarService.setStatus(
            'Password changed successfully',
            'success'
          );
        }
        if (statusCode === 200) {
          this.profile['isPasswordChangePending'] = true;
        }
      })
    );

    this.subscriptions.add(
      this._store.select(fromRoot.getProfile).subscribe(data => {
        this.profile = { ...data };
        //setTimeout(() => {

        this.emailInputs = getEmailChangeForm();
        this.emailForm = this._formsService.toFormGroup(
          this.emailInputs,
          this.changeEmailAction
        );
        //}, 200);
      })
    );

    this.passwordInputs = getPasswordChangeForm();
    this.passwordForm = this._formsService.toFormGroup(
      this.passwordInputs,
      this.changePasswordAction
    );
  }

  public emailFormSaveCallback = (inputs: InputBase<any>[]) => {
    this.emailInputs = this._formsService.updateInputs(
      this.emailInputs,
      inputs
    );

    //this.emailForm = this._formsService.toFormGroup(this.emailInputs);
  };

  public passwordFormSaveCallback = (inputs: InputBase<any>[]) => {
    this.passwordInputs = this._formsService.updateInputs(
      this.passwordInputs,
      inputs
    );

    //this.passwordForm = this._formsService.toFormGroup(this.passwordInputs);
  };

  submitEmailChange() {
    setTimeout(() => {
      observableCombineLatest(
        this._store.select(fromRoot.getProfile),
        this._store.select(fromRoot.getLanguage)
      )
        .pipe(
          filter(data => {
            return !!data[0] && !!data[1];
          }),
          first()
        )
        .subscribe(data => {
          const [user, language] = data;
          this._store.dispatch(
            new userActions.PatchEmail({
              email: this.emailInputs[0].value,
              password: this.emailInputs[2].value,
              userId: user.id,
              language
            })
          );

          this._store.select(fromRoot.emailChangeStatus).subscribe(code => {
            if (code && code >= 200 && code < 300) {
              this.profile['isEmailChangePending'] = true;

              // dont show new email, because it is not approved yet
              /* this._store.dispatch(
                new userActions.SetProfilesEmail(this.emailInputs[0].value)
              ); */
            }
          });
          this.emailChangeActive = false;
        });
    }, 100);
  }

  submitPasswordChange() {
    observableCombineLatest(
      this._store.select(fromRoot.getProfile),
      this._store.select(fromRoot.getLanguage)
    )
      .pipe(
        filter(data => {
          return !!data[0] && !!data[1];
        }),
        first()
      )
      .subscribe(data => {
        const [user, language] = data;
        this.profile['isPasswordChangePending'] = true;

        this._store.dispatch(
          new userActions.PatchPassword({
            password: this.passwordInputs[0].value,
            userId: user.id,
            language
          })
        );
        this.passwordChangeActive = false;
      });
  }
}
