import { ActionTypes } from './press-news.actions';
import { createSelector } from 'reselect';
export var initialState = {
    ids: [],
    entities: {},
    selectedPressNewsId: null,
    pressNewsDetail: {}
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ActionTypes.SELECT_PRESS_NEWS_ID:
            var pressNewsID = action.payload;
            if (state.selectedPressNewsId === pressNewsID) {
                return state;
            }
            return {
                ids: state.ids,
                entities: state.entities,
                selectedPressNewsId: pressNewsID,
                pressNewsDetail: state.pressNewsDetail
            };
        case ActionTypes.SET_PRESS_NEWS_DETAIL:
            var pressNewsDetail = action.payload;
            return {
                ids: state.ids,
                entities: state.entities,
                selectedPressNewsId: state.selectedPressNewsId,
                pressNewsDetail: pressNewsDetail
            };
        case ActionTypes.ADD_PRESS_NEWS_TO_LIST: {
            var pressNews = action.payload.pressReleases;
            if (pressNews) {
                var newPressNews = pressNews.filter(function (news) { return !state.entities[news.id]; });
                var newPressNewsIds = newPressNews.map(function (news) { return news.id; });
                var newPressNewsEntities = newPressNews.reduce(function (entities, news) {
                    var _a;
                    return Object.assign(entities, (_a = {},
                        _a[news.id] = news,
                        _a));
                }, {});
                return {
                    ids: state.ids.concat(newPressNewsIds),
                    entities: Object.assign({}, state.entities, newPressNewsEntities),
                    selectedPressNewsId: state.selectedPressNewsId,
                    pressNewsDetail: state.pressNewsDetail
                };
            }
            else {
                return state;
            }
        }
        default: {
            return state;
        }
    }
}
export var getEntities = function (state) { return state.entities; };
export var getIds = function (state) { return state.ids; };
export var getSelectedPressNewsId = function (state) {
    return state.selectedPressNewsId;
};
export var getPressNewsDetail = function (state) { return state.pressNewsDetail; };
export var getAll = createSelector(getEntities, getIds, function (entities, ids) {
    return ids.map(function (id) { return entities[id]; });
});
