import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef
} from '@angular/core';

import { Store } from '@ngrx/store';
import { first, filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { WidgetService } from './../../shared/services-with-reducers/widget/widget.service';

import { ProductGroupModel } from '../../shared/services-with-reducers/tickets/ticket.interface';
import {
  WidgetSettings,
  WidgetSettingType,
  WidgetSettingTypeKeyname,
  EventWidgetListItem
} from './widget-models.interface';

import * as fromRoot from '../../app.reducer';
import { getLocalStorageObject, setLocalStorageObject } from '../../shared/app-utils';
import { AppConstants } from '../../shared/app-constants';

@Component({
  selector: 'app-widget-admin',
  templateUrl: './widget-admin.component.html',
  styleUrls: ['./widget-admin.component.scss']
})
export class WidgetAdminComponent implements OnInit, OnDestroy {
  @ViewChild('iframe')
  iframe: ElementRef;

  public eventId = null;
  public availableTickets: ProductGroupModel[] = [];
  public widgetSettings: WidgetSettings;
  public eventWidgets: EventWidgetListItem[];
  public tabs = [
    {
      id: 'ticketsWidget',
      title: 'Tickets'
    },
    {
      id: 'bannerWidget',
      title: 'Banner'
    }
  ];
  public activeTab = getLocalStorageObject(AppConstants.activeWidgetTabReducer);
  public loading: boolean = false;

  private unsubscribe = new Subject();

  constructor(
    private _store: Store<fromRoot.State>,
    private widgetService: WidgetService
  ) {}

  ngOnInit() {
    this.setCurrentEventId();
    this.getAllWidgets();
    this.getTickets();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  createNewWidget(settings: {
    type: WidgetSettingTypeKeyname;
    settings: WidgetSettingType;
  }) {
    this.loading = true;

    this.widgetService
      .createWidgetForEvent(this.eventId, settings.settings)
      .subscribe(
        res => {
          this.widgetSettings = {
            ...this.widgetSettings,
            [settings.type]: res
          };

          this.loading = false;
        },
        err => (this.loading = false)
      );
  }

  updateWidget(settings: {
    type: WidgetSettingTypeKeyname;
    settings: WidgetSettingType;
  }) {
    if (settings.type !== 'ticketsWidget') {
      this.loading = true;

      this.widgetService
        .updateWidgetSettings(
          this.widgetSettings[settings.type].id,
          settings.settings
        )
        .subscribe(
          res => {
            this.widgetSettings = {
              ...this.widgetSettings,
              [settings.type]: res
            };

            this.loading = false;
          },
          err => (this.loading = false)
        );
    }
  }

  loadWidgetSettings(eventData: {
    type: WidgetSettingTypeKeyname;
    widgetId: string;
  }) {
    const widgetId = Number(eventData.widgetId);

    this.loading = true;

    this.widgetService.getWidgetById(widgetId).subscribe(
      res => {
        this.widgetSettings = { ...this.widgetSettings, [eventData.type]: res };

        this.loading = false;
      },
      err => (this.loading = false)
    );
  }

  getAllWidgets() {
    this.widgetService
      .getWidgetsForEvent(this.eventId)
      .subscribe((eventWidgets: EventWidgetListItem[]) => {
        this.eventWidgets = eventWidgets;
      });
  }

  getTickets() {
    this._store
      .select(fromRoot.getTicketsTypes)
      .pipe(
        filter(ticketGroups => {
          if (ticketGroups) {
            return true;
          }
        }),
        takeUntil(this.unsubscribe)
      )
      .subscribe(ticketGroups => {
        this.availableTickets = ticketGroups;
      });
  }

  setCurrentEventId() {
    this._store
      .select(fromRoot.getSelectedExhibitionId)
      .pipe(
        filter(eventId => {
          if (eventId) {
            return true;
          }
        }),
        first()
      )
      .subscribe(eventId => {
        this.eventId = eventId;
      });
  }

  onTabChange(tabName) {
    this.activeTab = tabName;
    setLocalStorageObject(AppConstants.activeWidgetTabReducer, tabName);
  }
}
