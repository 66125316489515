import { Component, HostBinding, Input } from '@angular/core';

import {
  ExhibitionModel,
  ExhibitionSeriesModel
} from '../shared/services-with-reducers/exhibition/exhibition.interface';
import { ExhibitionService } from '../shared/services-with-reducers/exhibition/exhibition.service';

@Component({
  moduleId: module.id,
  selector: 'app-exhibition-tile',
  templateUrl: './exhibition-tile.component.html',
  styleUrls: ['./exhibition-tile.component.scss']
})
export class ExhibitionTileComponent {
  @Input()
  exhibition: ExhibitionModel;
  @Input()
  exhibitionSerie: ExhibitionSeriesModel;

  constructor(private _exhibitionService: ExhibitionService) {}
}
