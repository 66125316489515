import * as colorizerActions from './colorizer.actions';
import * as fromRoot from '../app.reducer';

import { Observable, Subscription } from 'rxjs';
import { catchError, map, skip } from 'rxjs/operators';

import { ErrorHandlingService } from '../shared/error-handling/error-handling.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StatusBarService } from '../status-bar/status-bar.service';
import { Store } from '@ngrx/store';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ColorizerService {
  styleLoadSubscription: Subscription[] = [];

  constructor(
    private _http: HttpClient,
    private _errorHandlingService: ErrorHandlingService,
    private _statusBarService: StatusBarService,
    private _store: Store<fromRoot.State>
  ) {
    // each time user (admin is important for us) is logged out reset colorizer reducer
    this._store
      .select(fromRoot.getProfile)
      .pipe(skip(1))
      .subscribe(user => {
        if (!user) {
          this._store.dispatch(new colorizerActions.ResetReducer());
        }
      });
  }

  loadAllEventStyles() {
    return this._http.get(
      `${environment.protocol}${environment.webApiUrl}/style`
    );
  }

  loadEventStyle(styleId) {
    return this._http.get(
      `${environment.protocol}${environment.webApiUrl}/style/${styleId}`
    );
  }

  updateEventStyle(data, styleId) {
    return this._http.put(
      `${environment.protocol}${environment.webApiUrl}/style/${styleId}`,
      data
    );
  }

  createEventStyle(data) {
    return this._http.post(
      `${environment.protocol}${environment.webApiUrl}/style`,
      data
    );
  }

  saveEventStyles(data) {
    return this._http.post(
      `${environment.protocol}${environment.webApiUrl}/style/apply-style`,
      data
    );
  }

  saveOperatorStyles(data) {
    return this._http.put(
      `${environment.protocol}${environment.webApiUrl}/operator/styles`,
      data
    );
  }
}
