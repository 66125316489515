var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { ActionTypes } from './steps-forms.actions';
import { createSelector } from 'reselect';
import cloneDeep from 'lodash.clonedeep';
export var initialState = {
    tickets: {
        forms: null
    },
    personal: {
        forms: {
            buyerinfo: {
                rerender: true,
                list: []
            },
            billingaddress: {
                rerender: true,
                list: []
            },
            questionnaire: {
                rerender: true,
                list: null
            },
            privacy: {
                rerender: true,
                list: []
            }
        }
    },
    questionnaire: {
        forms: {
            questionnaire: {
                rerender: true,
                list: null
            }
        }
    },
    workshop: {
        forms: null
    },
    menu: {
        forms: null
    },
    legitimation: {
        forms: null
    },
    confirmation: {
        forms: {
            checkboxes: {
                rerender: true,
                list: []
            }
        }
    },
    recipe: {
        forms: null
    },
    payment: {
        forms: null
    },
    invoice: {
        forms: null
    },
    validity: {
        tickets: {
            valid: true,
            disabled: false,
            forms: {
                login: false,
                ticketSelection: false
            },
            order: 2,
            visible: true,
            showInStepNavigation: true
        },
        legitimation: {
            valid: false,
            disabled: true,
            order: 4,
            visible: true,
            forms: {
                validation: false
            },
            showInStepNavigation: true
        },
        personal: {
            valid: false,
            disabled: true,
            forms: {
                buyerinfo: false,
                billingaddress: false,
                questionnaire: false,
                privacy: false
            },
            order: 8,
            visible: true,
            showInStepNavigation: true
        },
        questionnaire: {
            valid: false,
            disabled: true,
            forms: {
                questionnaire: false
            },
            order: 9,
            visible: false,
            showInStepNavigation: true
        },
        workshop: {
            valid: false,
            disabled: true,
            order: 10,
            visible: false,
            forms: {
                validation: false
            },
            showInStepNavigation: true
        },
        menu: {
            valid: true,
            disabled: false,
            order: 12,
            visible: false,
            showInStepNavigation: true
        },
        confirmation: {
            valid: false,
            disabled: true,
            order: 14,
            visible: true,
            forms: {
                checkboxes: false
            },
            showInStepNavigation: true
        },
        recipe: {
            valid: false,
            disabled: true,
            order: 15,
            visible: false,
            showInStepNavigation: true
        },
        payment: {
            valid: false,
            disabled: true,
            order: 16,
            visible: true,
            forms: {
                validation: false
            },
            showInStepNavigation: false
        },
        invoice: {
            valid: false,
            disabled: true,
            order: 17,
            visible: true,
            showInStepNavigation: true
        }
    },
    ticketHoldersFormsValidity: [],
    isBuyerInfoFormValid: false,
    selectedStep: null,
    addressCheckbox: null,
    buyerVisitorCheckbox: null,
    coppiedHoldersDataIndexes: [],
    ticketHolderAdditionalData: {},
    buyerActiveBillingAddressId: null,
    isContinueAsGuest: false,
    orderResponse: null,
    paymentMethod: null,
    buyerInfoFromURL: null,
    anonymousHiddenSteps: [],
    skipHoldersCheck: false,
    questionnaireTicketPersonIds: []
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    var _a, _b, _c;
    var clonedInitialState = cloneDeep(initialState);
    switch (action.type) {
        case ActionTypes.SET_SELECTED_STEP:
            return __assign({}, state, { selectedStep: action.payload });
        case ActionTypes.SET_FORM_INPUTS: {
            var inputsPayload = action.payload;
            var skipHoldersCheck = action.skipHoldersCheck || false;
            if (inputsPayload) {
                var stepClone = __assign({}, state[inputsPayload.formInfo[0]]);
                stepClone.forms[inputsPayload.formInfo[1]] = inputsPayload.inputSet;
                return __assign({}, state, (_a = {}, _a[inputsPayload.formInfo[0]] = stepClone, _a.skipHoldersCheck = skipHoldersCheck, _a));
            }
            else {
                return state;
            }
        }
        case ActionTypes.REMOVE_FORM: {
            var formActionName = action.payload;
            var stepClone = cloneDeep(state[formActionName[0]]);
            delete stepClone.forms[formActionName[1]];
            return __assign({}, state, (_b = {}, _b[formActionName[0]] = stepClone, _b));
        }
        case ActionTypes.SET_STEP_VISIBILITY: {
            var visibilityPayload = action.payload;
            var stateValidityClone_1 = cloneDeep(state.validity);
            visibilityPayload.map(function (item) {
                stateValidityClone_1[item.stepKey].visible = item.visible;
            });
            return __assign({}, state, { validity: stateValidityClone_1 });
        }
        case ActionTypes.SET_MULTIPLE_STEPS_VISIBILITY: {
            var visibilityPayload = action.payload;
            var stateValidityClone_2 = cloneDeep(state.validity);
            visibilityPayload.forEach(function (step, index) {
                stateValidityClone_2[step.stepKey].visible = step.visible;
            });
            return __assign({}, state, { validity: stateValidityClone_2 });
        }
        case ActionTypes.SET_STEP_ORDER: {
            var orderPayload = action.payload;
            var stateValidityClone = cloneDeep(state.validity);
            stateValidityClone[orderPayload.stepKey].order = orderPayload.newOrder;
            return __assign({}, state, { validity: stateValidityClone });
        }
        case ActionTypes.SET_FORM_VALIDITY: {
            var validityPayload_1 = action.payload;
            var validityClone_1 = cloneDeep(state.validity);
            validityClone_1[validityPayload_1.formInfo[0]].forms[validityPayload_1.formInfo[1]] = validityPayload_1.valid;
            var wholeFormValid = Object.keys(validityClone_1[validityPayload_1.formInfo[0]].forms)
                .map(function (formName) {
                return validityClone_1[validityPayload_1.formInfo[0]].forms[formName];
            })
                .reduce(function (agr, current) {
                return current && agr;
            });
            validityClone_1[validityPayload_1.formInfo[0]].valid = wholeFormValid;
            // once validity is set for all steps, we can tell which steps are disabled
            Object.keys(validityClone_1)
                .sort(function (a, b) {
                return validityClone_1[a].order - validityClone_1[b].order;
            })
                .filter(function (stepKey) {
                return validityClone_1[stepKey].visible;
            })
                .reduce(function (lastStep, currentKey) {
                validityClone_1[currentKey].disabled =
                    lastStep.disabled || !lastStep.valid;
                return {
                    valid: validityClone_1[currentKey].valid,
                    disabled: validityClone_1[currentKey].disabled
                };
            }, { valid: true, disabled: false });
            return __assign({}, state, { validity: validityClone_1 });
        }
        case ActionTypes.REMOVE_FORMS_VALIDITY: {
            var validityPayloads = action.payload;
            if (validityPayloads.length) {
                var validityClone_2 = cloneDeep(state.validity);
                validityPayloads.forEach(function (validityPayload) {
                    delete validityClone_2[validityPayload.formInfo[0]].forms[validityPayload.formInfo[1]];
                });
                var validityPayloadFormName_1 = validityPayloads[0].formInfo[0];
                var wholeFormValid = Object.keys(validityClone_2[validityPayloadFormName_1].forms)
                    .map(function (formName) {
                    return validityClone_2[validityPayloadFormName_1].forms[formName];
                })
                    .reduce(function (agr, current) {
                    return current && agr;
                });
                validityClone_2[validityPayloadFormName_1].valid = wholeFormValid;
                // once validity is set for all steps, we can tell which steps are disabled
                Object.keys(validityClone_2)
                    .sort(function (a, b) {
                    return validityClone_2[a].order - validityClone_2[b].order;
                })
                    .filter(function (stepKey) {
                    return validityClone_2[stepKey].visible;
                })
                    .reduce(function (lastStep, currentKey) {
                    validityClone_2[currentKey].disabled =
                        lastStep.disabled || !lastStep.valid;
                    return {
                        valid: validityClone_2[currentKey].valid,
                        disabled: validityClone_2[currentKey].disabled
                    };
                }, { valid: true, disabled: false });
                return __assign({}, state, { validity: validityClone_2 });
            }
            else {
                return state;
            }
        }
        case ActionTypes.RESET_BUYERINFO_FROM_URL:
            return __assign({}, state, { buyerInfoFromURL: [] });
        case ActionTypes.SET_BUYERINFO_FROM_URL:
            var buyerInfoURL = action.payload;
            if (Array.isArray(buyerInfoURL) && buyerInfoURL.length === 0) {
                return state;
            }
            return __assign({}, state, { buyerInfoFromURL: buyerInfoURL });
        case ActionTypes.SET_ADDRESS_CHECBOX:
            var addressCheckbox = action.payload;
            return __assign({}, state, { addressCheckbox: addressCheckbox });
        case ActionTypes.SET_BUYERVISITOR_CHECKBOX:
            var buyerVisitorCheckbox = action.payload;
            return __assign({}, state, { buyerVisitorCheckbox: buyerVisitorCheckbox });
        case ActionTypes.SET_COPPIED_HOLDERS_INDEXES:
            var coppiedHoldersDataIndexes = action.payload;
            return __assign({}, state, { coppiedHoldersDataIndexes: coppiedHoldersDataIndexes });
        case ActionTypes.ASSIGN_WORKSHOP_TO_HOLDER: {
            var payload_1 = action.payload.workshopToHolder;
            var workshopLimit = action.payload.workshopLimit;
            // make a copy
            var ticketHolderAdditionalData = Object.assign({}, state.ticketHolderAdditionalData);
            if (!payload_1)
                return state;
            var holder = ticketHolderAdditionalData[payload_1.ticketHolderNumber];
            //if not there add it, otherwise remove it
            if (holder.workshops) {
                if (!holder.workshops.includes(payload_1.workshopId)) {
                    holder.workshops = holder.workshops.concat([payload_1.workshopId]);
                    holder.workshopLimitReached =
                        holder.workshops.length >= workshopLimit;
                }
                else {
                    var workshops = holder.workshops.filter(function (workshopId) {
                        return workshopId !== payload_1.workshopId;
                    });
                    holder.workshopLimitReached = false;
                    holder.workshops = workshops;
                }
            }
            //console.log(holder.workshopLimitReached);
            return __assign({}, state, { ticketHolderAdditionalData: ticketHolderAdditionalData });
        }
        case ActionTypes.SET_TICKET_HOLDER_ADDITIONAL_DATA: {
            var status_1 = action.payload;
            return __assign({}, state, { ticketHolderAdditionalData: status_1 });
        }
        case ActionTypes.SET_BUYER_ACTIVE_BILLINGADDRESS_ID:
            var activeId = action.payload;
            return __assign({}, state, { buyerActiveBillingAddressId: activeId });
        case ActionTypes.SET_CONTINUE_AS_GUEST:
            var isContinueAsGuest_1 = action.payload;
            return __assign({}, state, { isContinueAsGuest: isContinueAsGuest_1 });
        case ActionTypes.SET_ORDER_RESPONSE:
            var orderResponse = action.payload;
            return __assign({}, state, { orderResponse: orderResponse });
        case ActionTypes.SET_PAYMENT_METHOD:
            var paymentMethod = action.payload;
            return __assign({}, state, { paymentMethod: paymentMethod });
        case ActionTypes.SET_TICKETHOLDER_FORM_VALIDITY:
            var formValidity = action.payload;
            return __assign({}, state, { ticketHoldersFormsValidity: __assign({}, state.ticketHoldersFormsValidity, (_c = {}, _c[formValidity.formInfo[1]] = formValidity.valid, _c)) });
        case ActionTypes.REMOVE_TICKETHOLDER_FORM:
            var formInfo = action.payload;
            var formsValidityClone = cloneDeep(state.ticketHoldersFormsValidity);
            !!formsValidityClone && delete formsValidityClone[formInfo[1]];
            return __assign({}, state, { ticketHoldersFormsValidity: formsValidityClone });
        case ActionTypes.SET_BUYERINFO_FORM_VALIDITY:
            var isValid = action.payload;
            return __assign({}, state, { isBuyerInfoFormValid: isValid });
        case ActionTypes.RESET_REDUCER:
            return clonedInitialState;
        case ActionTypes.PARTIAL_RESET_REDUCER:
            return __assign({}, clonedInitialState, { buyerInfoFromURL: state.buyerInfoFromURL });
        case ActionTypes.SET_ANONYMOUS_HIDDEN_STEPS: {
            return __assign({}, state, { anonymousHiddenSteps: action.payload });
        }
        case ActionTypes.SET_QUESTIONNAIRE_TICKET_PERSON_IDS: {
            var questionnaireTicketPersonIds = action.payload;
            return __assign({}, state, { questionnaireTicketPersonIds: questionnaireTicketPersonIds });
        }
        default: {
            return state;
        }
    }
}
/**
 * Because the data structure is defined within the reducer it is optimal to
 * locate our selector functions at this level. If store is to be thought of
 * as a database, and reducers the tables, selectors can be considered the
 * queries into said database. Remember to keep your selectors small and
 * focused so they can be combined and composed to fit each particular
 * use-case.
 */
export var getTicketsStep = function (state) { return state.tickets; };
export var getPersonalStep = function (state) { return state.personal; };
export var getWorkshopStep = function (state) { return state.workshop; };
export var getMenuStep = function (state) { return state.menu; };
export var getlLegitimationStep = function (state) { return state.legitimation; };
export var getConfirmationStep = function (state) { return state.confirmation; };
export var getRecipeStep = function (state) { return state.recipe; };
export var getPaymentStep = function (state) { return state.payment; };
export var getInvoiceStep = function (state) { return state.invoice; };
export var getQuestionnaireStep = function (state) { return state.questionnaire; };
export var getSelectedStep = function (state) { return state.selectedStep; };
export var getStepsValidity = function (state) { return state.validity; };
export var getQuestionnaire = function (state) {
    return state.personal.forms.questionnaire;
};
export var getSelfRegQuestionnaire = function (state) {
    return state.questionnaire.forms.questionnaire;
};
export var getBuyerInfo = function (state) { return state.personal.forms.buyerinfo; };
export var getBuyerInfoFromURL = function (state) { return state.buyerInfoFromURL; };
export var getConfirmationCheckboxesInputs = function (state) {
    return state.confirmation.forms.checkboxes;
};
export var getPrivacyInput = function (state) { return state.personal.forms.privacy; };
export var getAddressCheckbox = function (state) { return state.addressCheckbox; };
export var getBuyerVisitorCheckbox = function (state) { return state.buyerVisitorCheckbox; };
export var getCoppiedHoldersIndexes = function (state) { return state.coppiedHoldersDataIndexes; };
export var getBillingAddressForm = function (state) {
    return state.personal.forms.billingaddress;
};
export var getTicketHolderAdditionalData = function (state) {
    return state.ticketHolderAdditionalData;
};
export var getBuyerActiveBillingAddressId = function (state) {
    return state.buyerActiveBillingAddressId;
};
export var isContinueAsGuest = function (state) { return state.isContinueAsGuest; };
export var getOrderResponse = function (state) { return state.orderResponse; };
export var getPaymentMethod = function (state) { return state.paymentMethod; };
export var getTicketHoldersFormsValidityData = function (state) { return state.ticketHoldersFormsValidity; };
export var isBuyerInfoFormValid = function (state) { return state.isBuyerInfoFormValid; };
export var getAnonymousHiddenSteps = function (state) { return state.anonymousHiddenSteps; };
export var getSkipHoldersCheck = function (state) { return state.skipHoldersCheck; };
export var getQuestionnaireTicketPersonIds = function (state) { return state.questionnaireTicketPersonIds; };
export var getTicketHolderInputSets = createSelector(getPersonalStep, function (personal) {
    var sets = Object.keys(personal.forms)
        .filter(function (setKey) {
        return setKey.match(/^ticketHolder_/);
    })
        .map(function (setKey) {
        var index = parseInt(setKey.replace('ticketHolder_', ''));
        return {
            index: index,
            formInfo: ['personal', setKey],
            inputSet: personal.forms[setKey]
        };
    });
    return sets;
});
export var isDifferenBillingAddressUsed = createSelector(getPersonalStep, function (personal) {
    var billingAddressUsed = personal.forms.buyerinfo.list.find(function (x) { return x.key === 'different-billing-address'; });
    if (billingAddressUsed) {
        return billingAddressUsed.options[0].value;
    }
    else {
        false;
    }
});
export var getTicketHoldersValidity = createSelector(getTicketHolderInputSets, getStepsValidity, function (getTicketHolderInputSetsData, getStepsValidityData) {
    return getTicketHolderInputSetsData.map(function (input) {
        return getStepsValidityData.personal.forms[input.formInfo[1]];
    });
});
export var getTicketHoldersFormsValidity = createSelector(getTicketHolderInputSets, getTicketHoldersFormsValidityData, function (getTicketHolderInputSetsData, getTicketHoldersFormsValidityData) {
    return getTicketHolderInputSetsData.map(function (input) {
        return getTicketHoldersFormsValidityData[input.formInfo[1]];
    });
});
export var getBuyerInfoValidity = createSelector(getStepsValidity, function (stepsValidity) {
    return !!stepsValidity && !!stepsValidity['personal'] && !!stepsValidity['personal'].forms && !!stepsValidity['personal'].forms['buyerinfo'];
});
export var getSteps = createSelector(getTicketsStep, getPersonalStep, getWorkshopStep, getMenuStep, getlLegitimationStep, getConfirmationStep, getRecipeStep, getPaymentStep, getInvoiceStep, getBuyerInfoFromURL, getQuestionnaireStep, function (tickets, personal, workshop, menu, legitimation, confirmation, recipe, payment, invoice, buyerInfoFromUrl, questionnaire) {
    var allSteps = {
        tickets: tickets,
        personal: personal,
        workshop: workshop,
        menu: menu,
        legitimation: legitimation,
        confirmation: confirmation,
        recipe: recipe,
        payment: payment,
        invoice: invoice,
        buyerInfoFromUrl: buyerInfoFromUrl,
        questionnaire: questionnaire
    };
    return allSteps;
});
