import { TicketsService } from './../../../shared/services-with-reducers/tickets/tickets.service';
import { PackagesService } from './../../../shared/services-with-reducers/tickets/packages.service';
import { EventEmitter, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { combineLatest as observableCombineLatest } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as fromRoot from '../../../app.reducer';
import * as ticketActions from '../../../shared/services-with-reducers/tickets/ticket.actions';
import { CustomizationService } from '../../../shared/services-with-reducers/customization/customization.service';
var ERROR_TICKET_WARNING = 'ticket-selection.warning';
var ERROR_VOUCHER_WARNING = 'ticket-selection.voucher-warning';
var VOUCHER_LIMITED_WARNING = 'ticket-selection.limited-voucher-warning';
var VOUCHER_ONETIME_WARNING = 'ticket-selection.one-time-voucher-warning';
var TICKET_NORMAL = 'normal';
var TICKET_PARKING = 'parking';
var TICKET_PROMOCODE = 'promoCode';
var TICKET_PROMOCODE_LIMITED = 'limitedPromoCode';
var TICKET_VOUCHER_ONETIME = 'oneTimeVoucher';
var CounterComponent = /** @class */ (function () {
    function CounterComponent(_store, _customizationService, _packagesService, _ticketsService) {
        this._store = _store;
        this._customizationService = _customizationService;
        this._packagesService = _packagesService;
        this._ticketsService = _ticketsService;
        this.counterChange = new EventEmitter();
        this.ticketLoading = new EventEmitter();
        this.ticketLimitWarning = '';
        this.errors = {
            ERROR_TICKET_WARNING: ERROR_TICKET_WARNING,
            ERROR_VOUCHER_WARNING: ERROR_VOUCHER_WARNING,
            VOUCHER_LIMITED_WARNING: VOUCHER_LIMITED_WARNING,
            VOUCHER_ONETIME_WARNING: VOUCHER_ONETIME_WARNING
        };
        this.tickets = {
            TICKET_NORMAL: TICKET_NORMAL,
            TICKET_PARKING: TICKET_PARKING,
            TICKET_PROMOCODE: TICKET_PROMOCODE,
            TICKET_PROMOCODE_LIMITED: TICKET_PROMOCODE_LIMITED,
            TICKET_VOUCHER_ONETIME: TICKET_VOUCHER_ONETIME
        };
        this.previousNumberOfSelectedTickets = 0;
        this.packageSettings = null;
        this.isTicketAddedByPackageCounter = false;
    }
    Object.defineProperty(CounterComponent.prototype, "numberOfAllTickets", {
        set: function (value) {
            this.numberOfSelectedTickets = value;
            if (this.numberOfSelectedTickets < this.previousNumberOfSelectedTickets) {
                this.ticketLimitWarning = '';
            }
            this.previousNumberOfSelectedTickets = value;
        },
        enumerable: true,
        configurable: true
    });
    CounterComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.previousValue = this.counterValue || 0;
        // US 3512 - changed functionality, this.wasCounterTouched needed to be true if 'promoCode' is used
        if (this.amount > 0) {
            this.onValueChange({
                target: {
                    value: this.amount
                }
            });
        }
        this.wasCounterTouched = this.ticketType === TICKET_PROMOCODE;
        if (this.package != null) {
            var changedPackage = this.package.contents.find(function (content) { return content.packageGroups.some(function (packageGroup) { return packageGroup.products.some(function (product) { return product.ticket.uniqueId == _this.ticketUniqueId; }); }); });
            this.setPackageSettings(changedPackage);
            this.setPackageTriggers(changedPackage);
        }
    };
    CounterComponent.prototype.ngOnChanges = function () {
        var disableEnabledSoldOutCounter = this.totalAvailableTickets == 0 && !this.ticketSoldOut && this.counterValue == 0;
        var enableDisabledSoldOutCounter = this.totalAvailableTickets > 0 && this.ticketSoldOut && this.counterValue == this.previousValue;
        if (disableEnabledSoldOutCounter) {
            this.ticketSoldOut = true;
        }
        else if (enableDisabledSoldOutCounter) {
            this.ticketSoldOut = false;
        }
        this.disableOnPackageMaxAmount = this.isDisabledOnPackageMaxAmount(this.counterValue);
    };
    CounterComponent.prototype.ngOnDestroy = function () {
        this._packagesService.packageContentCountChangedEvent.removeAllListeners("" + this.ticketUniqueId);
    };
    Object.defineProperty(CounterComponent.prototype, "counter", {
        get: function () {
            return this.counterValue;
        },
        set: function (value) {
            var _this = this;
            var changeCounterSuccess = function () {
                _this.counterValue = value;
                var decrease = _this.counterValue - _this.previousValue < 0 ? true : false;
                if (decrease) {
                    _this._packagesService.enableDisabledPackage();
                }
                _this.counterChange.emit({
                    value: _this.counterValue,
                    decrease: decrease,
                    isTicketAddedByPackageCounter: _this.isTicketAddedByPackageCounter
                });
                _this.previousValue = value;
            };
            this.ticketLoading.emit(true);
            // after we update the count we post to backend information about booked tickets
            observableCombineLatest([
                this._store.pipe(select(fromRoot.getSelectedExhibitionId)),
                this._store.pipe(select(fromRoot.getTickets)),
                this._store.pipe(select(fromRoot.getOrderUuid)),
                this._store.pipe(select(fromRoot.getTicketHolderIndexes))
            ])
                .first()
                .subscribe(function (data) {
                var eventId = data[0], tickets = data[1], orderUuid = data[2], ticketHolderIndexes = data[3];
                var ticketToBeBooked = tickets[_this.ticketUniqueId];
                var sharedLimitTicketCount = Object.keys(tickets)
                    .map(function (ticketUniqueId) { return tickets[ticketUniqueId]; })
                    .filter(function (ticket) { return ticketToBeBooked.groupId === ticket.groupId && ticketToBeBooked.id === ticket.id && ticketToBeBooked.uniqueId != ticket.uniqueId; })
                    .reduce(function (totalCount, ticket) { return totalCount + ticket.count; }, 0);
                var voucherCode = '';
                var postTicketBooking = {
                    eventId: Number(eventId),
                    uuid: orderUuid,
                    count: value,
                    groupId: ticketToBeBooked.groupId,
                    ticketTypeId: ticketToBeBooked.id,
                    ticketPersonId: ticketToBeBooked.ticketPersonId,
                    uniqueId: ticketToBeBooked.uniqueId,
                    sharedLimitTicketCount: sharedLimitTicketCount,
                    orderTicketBooking: true
                };
                if (ticketToBeBooked.hasOwnProperty('voucherCode')) {
                    voucherCode = ticketToBeBooked.voucherCode;
                }
                if (_this.package != null) {
                    _this.isTicketAddedByPackageCounter = !!Object.keys(ticketHolderIndexes)
                        .map(function (index) { return ticketHolderIndexes[index]; })
                        .filter(function (indexTicketUniqueId) { return indexTicketUniqueId == ''; }).length;
                    _this.calculatePackagePrice(tickets, value);
                }
                _this._store.dispatch(new ticketActions.PostTicketBooking({
                    postTicketBooking: postTicketBooking,
                    voucherCode: voucherCode,
                    ticketLimit: ticketToBeBooked.ticketLimit,
                    successCallback: changeCounterSuccess
                }));
            });
        },
        enumerable: true,
        configurable: true
    });
    CounterComponent.prototype.onDecrease = function () {
        this.onValueChange({
            target: {
                value: this.previousValue > 0 ? this.previousValue - 1 : 0
            }
        });
    };
    CounterComponent.prototype.onIncrease = function () {
        this.onValueChange({
            target: {
                value: this.previousValue + 1,
                isAddedByPackageCounter: false
            }
        });
    };
    CounterComponent.prototype.onValueChange = function (event) {
        this.wasCounterTouched = true;
        var value = Number(event.target.value);
        if (value <= 0) {
            if (this.package && this.package.count > 0) {
                if (event.target.isPackageRemoved) {
                    this.counter = 0;
                }
                else {
                    this.setPackageValidation(value);
                    if (this.packageSettings.fixedAmount) {
                        this.counter = this.packageSettings.amount;
                    }
                    if (this.disableOnPackageMinAmount) {
                        this.counter = this.packageSettings.minAmount;
                    }
                }
            }
            else {
                this.counter = 0;
            }
        }
        else {
            var counter = value;
            var counterOverLimit = false;
            this.ticketLimitWarning = '';
            var ticketsDiff = value - this.previousValue;
            //TICKET_PROMOCODE
            if (!counterOverLimit && this.ticketType === TICKET_PROMOCODE &&
                this.numberOfSelectedVouchers + ticketsDiff > this.maxVoucherLimit) {
                counterOverLimit = true;
                counter = Math.min(this.maxVoucherLimit - (this.numberOfSelectedVouchers - this.previousValue), counter);
                counter = counter > 0 ? counter : 0;
                ticketsDiff = counter - this.previousValue;
                //consoleLog(`ŠTEF: onValueChange: ticketType=${this.ticketType}, numberOfSelectedVouchers=${this.numberOfSelectedVouchers}, ticketsDiff=${ticketsDiff}, maxVoucherLimit=${this.maxVoucherLimit}; counter=${counter}`);
                this.ticketLimitWarning = ERROR_VOUCHER_WARNING;
                this.currentMaxLimit = this.maxVoucherLimit;
            }
            //TICKET_PROMOCODE_LIMITED
            if (!counterOverLimit && this.ticketType === TICKET_PROMOCODE_LIMITED &&
                this.numberOfSelectedLimitedVouchers + ticketsDiff > this.maxLimitedVoucherLimit) {
                counterOverLimit = true;
                counter = Math.min(this.maxLimitedVoucherLimit - (this.numberOfSelectedLimitedVouchers - this.previousValue), counter);
                counter = counter > 0 ? counter : 0;
                ticketsDiff = counter - this.previousValue;
                //consoleLog(`ŠTEF: onValueChange: ticketType=${this.ticketType}, numberOfSelectedLimitedVouchers=${this.numberOfSelectedLimitedVouchers}, ticketsDiff=${ticketsDiff}, maxLimitedVoucherLimit=${this.maxLimitedVoucherLimit}; counter=${counter}`);
                this.ticketLimitWarning = VOUCHER_LIMITED_WARNING;
                this.currentMaxLimit = this.maxLimitedVoucherLimit;
            }
            //TICKET_VOUCHER_ONETIME
            if (!counterOverLimit && this.ticketType === TICKET_VOUCHER_ONETIME &&
                this.numberOfSelectedOneTimeVouchers + ticketsDiff > 1) {
                counterOverLimit = true;
                counter = 1;
                ticketsDiff = counter - this.previousValue;
                //consoleLog(`ŠTEF: onValueChange: ticketType=${this.ticketType}, numberOfSelectedOneTimeVouchers=${this.numberOfSelectedOneTimeVouchers}, ticketsDiff=${ticketsDiff}; counter=${counter}`);
                this.currentMaxLimit = 1;
                this.ticketLimitWarning = VOUCHER_ONETIME_WARNING;
            }
            //workshops
            //(we must test this even if we already set counterOverLimit to true as this limitation may be even stricter)
            if (this.workshopsMandatory && this.hasWorkshops &&
                (this.previousValue + ticketsDiff > this.workshopSeatsAvailable || this.workshopsSoldOut)) {
                counterOverLimit = true;
                if (this.workshopsSoldOut) {
                    counter = 0;
                }
                else {
                    counter = Math.min(this.workshopSeatsAvailable, counter);
                }
                counter = counter > 0 ? counter : 0;
                ticketsDiff = counter - this.previousValue;
                //consoleLog(`ŠTEF: onValueChange: ticketType=${this.ticketType}-WorkShops, numberOfSelectedTickets=${this.numberOfSelectedTickets}, ticketsDiff=${ticketsDiff}, workshopSeatsAvailable=${this.workshopSeatsAvailable}; counter=${counter}`);
                this.ticketLimitWarning = ERROR_TICKET_WARNING;
                this.currentMaxLimit = counter;
            }
            //maxLimit (total of all tickets) && numOfAvailableTickets (of the current ticket)
            //(we must test this even if we already set counterOverLimit to true as this limitation may be even stricter)
            if (
            //testing the total number of selected tickets upon the max ticket limit for all tickets:
            this.numberOfSelectedTickets + ticketsDiff > this.maxLimit ||
                //testing the ticket count difference from the previous selection upon the number of available (currently chosen) tickets:
                this.previousValue + ticketsDiff > this.numOfAvailableTickets) {
                counterOverLimit = true;
                var availabilityLimit = Math.min(this.maxLimit, this.numOfAvailableTickets);
                counter = Math.min(availabilityLimit, this.maxLimit - (this.numberOfSelectedTickets - this.previousValue));
                counter = counter > 0 ? counter : 0;
                ticketsDiff = counter - this.previousValue;
                //consoleLog(`ŠTEF: onValueChange: ticketType=${this.ticketType}, numberOfSelectedTickets=${this.numberOfSelectedTickets}, ticketsDiff=${ticketsDiff}, maxLimit=${this.maxLimit}, numOfAvailableTickets=${this.numOfAvailableTickets}; counter=${counter}`);
                this.ticketLimitWarning = ERROR_TICKET_WARNING;
                this.currentMaxLimit = availabilityLimit;
            }
            //valueHigherThanVoucherLimit
            //TODO: SteZ: try to figure out what this does and if it does it well:
            if (counter > this.voucherLimit && this.voucherLimit !== 0) {
                counterOverLimit = true;
                var isCurrentLimitLessThanVoucherLimit = this.currentMaxLimit < this.voucherLimit;
                var limit = isCurrentLimitLessThanVoucherLimit ? this.currentMaxLimit : this.voucherLimit;
                counter = limit;
                counter = counter > 0 ? counter : 0;
                ticketsDiff = counter - this.previousValue;
                //consoleLog(`ŠTEF: onValueChange: ticketType=${this.ticketType}-CheckVouchers, counterValue=${this.counterValue}, voucherLimit=${this.voucherLimit}, isCurrentLimitLessThanVoucherLimit=${isCurrentLimitLessThanVoucherLimit}, limit=${limit}; counter=${counter}`);
                this.ticketLimitWarning = VOUCHER_LIMITED_WARNING;
                this.currentMaxLimit = limit;
            }
            if (this.packageSettings != null) {
                this.setPackageValidation(counter);
                if (this.packageSettings.fixedAmount) {
                    counter = this.packageSettings.amount;
                    if (!this.workshopsSoldOut || this.numberOfSelectedTickets < this.maxLimit) {
                        counterOverLimit = false;
                    }
                }
                if (this.disableOnPackageMaxAmount) {
                    counter = this.packageSettings.maxAmount;
                    if (!this.workshopsSoldOut || this.numberOfSelectedTickets < this.maxLimit) {
                        counterOverLimit = false;
                    }
                }
                if (this.disableOnPackageMinAmount) {
                    counter = this.packageSettings.minAmount;
                }
                if (!event.target.isAddedByPackageCounter) {
                    value = counter;
                }
            }
            if (!counterOverLimit) {
                this.counter = value;
                this.ticketLimitWarning = '';
            }
            else {
                this.counter = counter > 0 ? counter : 0;
            }
        }
        this.startMeasuring();
    };
    CounterComponent.prototype.startMeasuring = function () {
        this._customizationService.setShoppingStartTime();
    };
    CounterComponent.prototype.packageTriggerCounter = function (packageContent, decrease) {
        var _this = this;
        var currentPackageTicketTypeGroup = packageContent.packageGroups.find(function (packageGroup) { return packageGroup.products.some(function (product) { return product.ticket.uniqueId == _this.ticketUniqueId; }); });
        if (!!currentPackageTicketTypeGroup) {
            var currentTicketCounterInAddedPackage = currentPackageTicketTypeGroup.products.find(function (product) { return product.ticket.uniqueId == _this.ticketUniqueId; });
            if (!!currentTicketCounterInAddedPackage) {
                if (decrease) {
                    this._ticketsService.ticketCounterChanged(currentTicketCounterInAddedPackage.ticket.uniqueId, 0, true);
                    this.onValueChange({
                        target: {
                            value: 0,
                            isPackageRemoved: true
                        }
                    });
                }
                else {
                    var settings = currentTicketCounterInAddedPackage.ticket.packageSettings;
                    this.onValueChange({
                        target: {
                            value: this._packagesService.setPackageTicketAmount(settings, this.previousValue),
                            isAddedByPackageCounter: true
                        }
                    });
                }
            }
        }
    };
    CounterComponent.prototype.setPackageSettings = function (changedPackage) {
        var _this = this;
        var packageGroup = changedPackage.packageGroups.find(function (packageGroup) { return packageGroup.products.some(function (product) { return product.ticket.uniqueId == _this.ticketUniqueId; }); });
        var currentProduct = packageGroup.products.find(function (product) { return product.ticket.uniqueId == _this.ticketUniqueId; });
        if (!!currentProduct) {
            this.packageSettings = currentProduct.ticket.packageSettings;
        }
    };
    CounterComponent.prototype.setPackageTriggers = function (changedPackage) {
        var _this = this;
        if (this.package.count > 0) {
            this.packageTriggerCounter(changedPackage, false);
        }
        this._packagesService.packageContentCountChangedEvent.removeAllListeners("" + this.ticketUniqueId);
        this._packagesService.packageContentCountChangedEvent.on("" + this.ticketUniqueId, function (changedPackage, decrease) {
            _this.packageTriggerCounter(changedPackage, decrease);
        });
    };
    CounterComponent.prototype.isDisabledOnPackageMaxAmount = function (value) {
        if (this.ticketSoldOut) {
            return true;
        }
        if (!!this.packageSettings) {
            var _a = this.packageSettings, amount = _a.amount, maxAmount = _a.maxAmount, fixedAmount = _a.fixedAmount;
            if (fixedAmount && amount == value) {
                return true;
            }
            if (maxAmount != null && maxAmount <= value) {
                return true;
            }
        }
        return false;
    };
    CounterComponent.prototype.isDisabledOnPackageMinAmount = function (value) {
        if (this.ticketSoldOut && value == 0) {
            return true;
        }
        if (!!this.packageSettings) {
            var _a = this.packageSettings, amount = _a.amount, minAmount = _a.minAmount, fixedAmount = _a.fixedAmount;
            if (fixedAmount && amount == value) {
                return true;
            }
            if (minAmount != null && minAmount >= value) {
                return true;
            }
        }
        return false;
    };
    CounterComponent.prototype.setPackageValidation = function (value) {
        this.disableOnPackageMaxAmount = this.isDisabledOnPackageMaxAmount(value);
        this.disableOnPackageMinAmount = this.isDisabledOnPackageMinAmount(value);
    };
    CounterComponent.prototype.calculatePackagePrice = function (tickets, value) {
        var _this = this;
        var changedPackageContent = this.package.contents.find(function (content) { return content.packageGroups.some(function (packageGroup) { return packageGroup.products.some(function (product) { return product.ticket.uniqueId == _this.ticketUniqueId; }); }); });
        if (changedPackageContent != null) {
            var changedPackageGroup = changedPackageContent.packageGroups.find(function (packageGroup) { return packageGroup.products.some(function (product) { return product.ticket.uniqueId == _this.ticketUniqueId; }); });
            if (changedPackageGroup != null) {
                var changedTicket = changedPackageGroup.products.find(function (product) { return product.ticket.uniqueId == _this.ticketUniqueId; }).ticket;
                if (changedTicket != null) {
                    var ticket = tickets[changedTicket.uniqueId];
                    var packageCount = this.package.count;
                    var previousTicketCount = this.previousValue;
                    var isTicketRemoved = previousTicketCount != 0 && value == 0 && previousTicketCount >= value;
                    var areAllPackagesRemoved = isTicketRemoved && packageCount == 0;
                    var changedContentTotal = 0;
                    var contentPriceDifference = 0;
                    if (isTicketRemoved) {
                        changedContentTotal = changedPackageContent.total - previousTicketCount * ticket.price;
                        contentPriceDifference = changedContentTotal - changedPackageContent.total;
                    }
                    else if (!areAllPackagesRemoved) {
                        var currentTicketCount = ticket && ticket.count > 0 ? ticket.count : 0;
                        var changedTicketCount = currentTicketCount != value ? value : currentTicketCount;
                        var changedTicketPrice = changedTicketCount * ticket.price;
                        var isCountChangedByTicketCounter = currentTicketCount != changedTicketCount;
                        var isCountChangedByPackageCounter = !isCountChangedByTicketCounter && this.isTicketAddedByPackageCounter;
                        if (isCountChangedByTicketCounter) {
                            var currentTicketPrice = currentTicketCount * ticket.price;
                            var ticketPriceDifference = changedTicketPrice - currentTicketPrice;
                            changedContentTotal = changedPackageContent.total + ticketPriceDifference;
                            contentPriceDifference = changedContentTotal - changedPackageContent.total;
                        }
                        else if (isCountChangedByPackageCounter) {
                            changedContentTotal = changedPackageContent.total + changedTicketPrice;
                            contentPriceDifference = changedContentTotal - changedPackageContent.total;
                        }
                        else {
                            changedContentTotal = changedPackageContent.total;
                            contentPriceDifference = 0;
                        }
                    }
                    var changedPackageTotalPrice = this.package.total + contentPriceDifference;
                    changedPackageContent.total = packageCount > 0 ? +changedContentTotal.toFixed(2) : 0;
                    this.package.total = packageCount > 0 ? +changedPackageTotalPrice.toFixed(2) : 0;
                }
            }
        }
    };
    return CounterComponent;
}());
export { CounterComponent };
