import { Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { combineLatest as observableCombineLatest, Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import { AppService } from '../app.service';
import { FormsService } from '../shared/forms/forms.service';
import { StatusBarService } from '../status-bar/status-bar.service';
import { StepsFormsService } from '../shared/services-with-reducers/step-forms/steps-forms.service';
import { TicketsService } from '../shared/services-with-reducers/tickets/tickets.service';
import { TranslateService } from '@ngx-translate/core';
import { AdditionalServicesService } from './../shared/services-with-reducers/additional-services/additional-services.service';

import { AssignWorkshopToHolderModel } from '../shared/services-with-reducers/step-forms/step.interface';
import { ExhibitionSettingModel } from '../shared/services-with-reducers/customization/customization.interfaces';
import { WorkshopBookingsModel } from '../shared/services-with-reducers/additional-services/additional-services.interface';
import {
  TicketBookingModel,
  TicketByIdModel,
  TicketModel
} from '../shared/services-with-reducers/tickets/ticket.interface';

import { AppConstants } from '../shared/app-constants';
import * as additionalServicesActions from '../shared/services-with-reducers/additional-services/additional-services.actions';
import * as fromRoot from '../app.reducer';
import * as stepsFormsActions from '../shared/services-with-reducers/step-forms/steps-forms.actions';
import * as ticketActions from '../shared/services-with-reducers/tickets/ticket.actions';
import { CustomizationService } from '../shared/services-with-reducers/customization/customization.service';

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss']
})
export class CountdownComponent implements OnInit, OnDestroy {
  public showLoginCountdown = false;
  public showVoucherCountdown = false;
  public loggedOutIn = '';
  public timeToReleaseWorkshop = '';
  public timeToReleaseTicket = '';

  public nextExpiringVoucher: {
    code: string;
    remaining: number;
    showTime: string;
  };
  public ticketBookingTimeoutModalOpen: boolean = false;

  private _subscription: Subscription = new Subscription();
  private loginInterval;
  private vouchersInterval;
  private workshopInterval;
  private ticketInterval;
  private orderId: string;

  constructor(
    private _store: Store<fromRoot.State>,
    private _translateService: TranslateService,
    private _ticketsService: TicketsService,
    private _router: Router,
    private _statusBarService: StatusBarService,
    private _formsService: FormsService,
    private _stepsFormsService: StepsFormsService,
    private _appService: AppService,
    private _additionalServices: AdditionalServicesService,
    private _customizationService: CustomizationService
  ) {}

  getCountdownTime(secondsTotal) {
    const minRemaining = Math.floor(secondsTotal / 60);
    const secRemaining = Math.floor(secondsTotal - minRemaining * 60);
    const secRemainingWithTwoDigits =
      secRemaining < 10 ? `0${secRemaining}` : secRemaining;

    return `${minRemaining}:${secRemainingWithTwoDigits}`;
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
    clearInterval(this.loginInterval);
    clearInterval(this.vouchersInterval);
    clearInterval(this.workshopInterval);

    this.loginInterval = null;
    this.vouchersInterval = null;
  }

  ngOnInit() {
    /* LOGIN TIMEOUT */
    const showMinutesBefore = 30;

    const authTimespanInMs = AppConstants.TOKEN_VALIDITY_IN_MINUTES * 60 * 1000;

    this._subscription.add(
      this._store.select(fromRoot.getLoginTimestamp).subscribe(timestamp => {
        if (this.loginInterval) {
          clearInterval(this.loginInterval);
          this.loginInterval = null;
        }

        if (timestamp) {
          this.loginInterval = setInterval(() => {
            const now = Date.now();
            if (timestamp + authTimespanInMs - 30 * 60 * 1000 < now) {
              this.showLoginCountdown = true;
              const loggedOutInSecTotal =
                (timestamp + authTimespanInMs - now) / 1000;

              this.loggedOutIn = this.getCountdownTime(loggedOutInSecTotal);
            } else {
              this.showLoginCountdown = false;
            }
          }, 1000);
        } else {
          this.showLoginCountdown = false;
        }
      })
    );

    /* TICKETS COUNTDOWN */

    this._subscription.add(
      observableCombineLatest(
        this._store.select(fromRoot.getTicketsBooking),
        this._store.select(fromRoot.getExhibitionSettings),
        this._store.select(fromRoot.getSelectedStep)
      )
        .pipe(filter(data => !!data[0]))
        .subscribe(
          (data: [TicketBookingModel, ExhibitionSettingModel, string]) => {
            const [bookings, eventSettings, selectedStep] = data;

            if (!eventSettings) {
              clearInterval(this.ticketInterval);

              this.ticketInterval = null;
              this.timeToReleaseTicket = '';

              return;
            }

            if (this.ticketInterval) {
              clearInterval(this.ticketInterval);
              this.ticketInterval = null;
            }

            const latestBookingTimestamp = bookings.timestamp ? bookings.timestamp : 1;

            if (latestBookingTimestamp) {
              const releaseOn =
                latestBookingTimestamp +
                eventSettings.ticketReleaseInMinutes * 60 * 1000;

              const noBookings = bookings.bookings.every(booking => {
                return booking.count === 0;
              });

              // if ticket timeout is created and there is workshop timeout we remove it
              clearInterval(this.workshopInterval);
              this.workshopInterval = null;
              this.timeToReleaseWorkshop = '';

              if (noBookings || selectedStep === 'invoice') {
                clearInterval(this.ticketInterval);
                this.ticketInterval = null;
                this.timeToReleaseTicket = '';
                return;
              }

              this.ticketInterval = setInterval(() => {
                const now = Date.now();
                this.timeToReleaseTicket = this.getCountdownTime(
                  (releaseOn - now) / 1000
                );
                if (releaseOn < now) {
                  // release all tickets. Bookings are released automatically on BE, so we just reset the reducer without user
                  this.ticketBookingTimeoutModalOpen = true;

                  clearInterval(this.ticketInterval);
                  this.ticketInterval = null;
                  this.timeToReleaseTicket = '';

                  clearInterval(this.workshopInterval);
                  this.workshopInterval = null;
                  this.timeToReleaseWorkshop = '';
                }
              }, 1000);
            }
          }
        )
    );

    /* WORKSHOPS COUNTDOWN */
    this._subscription.add(
      observableCombineLatest(
        this._store.select(fromRoot.getWorkshopBookings),
        this._store.select(fromRoot.getExhibitionSettings),
        this._store.select(fromRoot.getSelectedStep)
      )
        .pipe(
          filter(([workshopBookings]) => {
            if (workshopBookings && workshopBookings.bookings.length > 0) {
              return true;
            }
          })
        )
        .subscribe(
          (data: [WorkshopBookingsModel, ExhibitionSettingModel, string]) => {
            const [bookings, eventSettings, selectedStep] = data;

            if (!eventSettings) {
              clearInterval(this.workshopInterval);

              this.workshopInterval = null;
              this.timeToReleaseWorkshop = '';

              // reset bookings on BE
              if (this.orderId) {
                this._store.dispatch(
                  new additionalServicesActions.RemoveMyWorkshopBookings()
                );

                this._additionalServices
                  .releaseBookings(this.orderId)
                  .subscribe();

                // reset orderId to prevent reset each time
                this.orderId = null;
              }

              return;
            }

            this._store
              .select(fromRoot.getOrderUuid)
              .pipe(first())
              .subscribe(uuid => (this.orderId = uuid));

            if (this.workshopInterval) {
              clearInterval(this.workshopInterval);

              this.workshopInterval = null;
            }

            const latestBookingTimestamp = bookings.timestamp;

            if (latestBookingTimestamp) {
              const releaseOn =
                latestBookingTimestamp +
                eventSettings.workshopSeatsReleaseInMinutes * 60 * 1000;

              const noBookings = bookings.bookings.every(booking => {
                return !booking.myBookings.length;
              });

              //if there is a ticket interval we don't need workshop interval !
              if (
                noBookings ||
                selectedStep === 'invoice' ||
                this.ticketInterval
              ) {
                clearInterval(this.workshopInterval);

                this.workshopInterval = null;
                this.timeToReleaseWorkshop = '';

                return;
              }

              this.workshopInterval = setInterval(() => {
                const now = Date.now();

                this.timeToReleaseWorkshop = this.getCountdownTime(
                  (releaseOn - now) / 1000
                );

                if (releaseOn < now) {
                  // release all workshops. Bookings are released automatically on BE, so just clear my bookings and remove relations between workshop and

                  bookings.bookings.forEach(booking => {
                    booking.myBookings.forEach(myBooking => {
                      const data: AssignWorkshopToHolderModel = {
                        ticketHolderNumber: myBooking.ticketHolderId,
                        workshopId: booking.workshopId,
                        isAdded: false,
                        subject: null
                      };

                      this._store.dispatch(
                        new stepsFormsActions.assignWorkshopToHolder({
                          workshopToHolder: data,
                          workshopLimit: eventSettings.workshopsPerTicket
                        })
                      );
                    });
                  });

                  this._store.dispatch(
                    new additionalServicesActions.RemoveMyWorkshopBookings()
                  );

                  if (eventSettings.isWorkshopsSelectionMandatory) {
                    this._formsService.setFormValidity(false, null, [
                      'workshop',
                      'validation'
                    ]);

                    this._stepsFormsService.navigateToLastNotDisabledPage();

                    this._statusBarService.translateAndSetStatus(
                      'workshop-mandatory.timer-ranOut',
                      'error'
                    );
                  } else {
                    this._statusBarService.translateAndSetStatus(
                      'workshop.timer-ranOut',
                      'info'
                    );
                  }

                  clearInterval(this.workshopInterval);

                  this.workshopInterval = null;
                  this.timeToReleaseWorkshop = '';
                }
              }, 1000);
            }
          }
        )
    );

    /* VOUCHERS COUNTDOWN */
    this._subscription.add(
      this._store
        .select(fromRoot.getVoucherTickets)
        .subscribe((activeVouchers: TicketByIdModel[]) => {
          clearInterval(this.vouchersInterval);
          this.vouchersInterval = null;

          if (activeVouchers.length) {
            // otherwise check if there is interval already, if not, create new one
            this.vouchersInterval = setInterval(() => {
              const now = Date.now();
              let initialValues = {
                code: '',
                remaining: Infinity,
                showTime: ''
              };
              this.nextExpiringVoucher = activeVouchers.reduce(
                (result, currVoucher) => {
                  // we only relase limited wouchers
                  // Bug - 3271 (iSm): Ticket expire countdown is not visible for single voucher
                  // if (currVoucher.quantity > 0) {
                    const timeWhenEnds =
                      currVoucher.releasedInMinutes * 60 * 1000 +
                      currVoucher.activatedTimestamp;
                    if (timeWhenEnds > now) {
                      // if the voucher is still valid get its time
                      if (timeWhenEnds - now < result.remaining) {
                        result.remaining = timeWhenEnds - now;
                        result.code = currVoucher.voucherCode;
                      }
                      return result;
                    } else {
                      /* At this time the voucher was released by backend, so we need to remove it from FE as well */
                      this._store
                      .select(fromRoot.getTickets)
                      .pipe(first())
                      .subscribe((tickets: TicketModel) => {
                        const voucherTicket = this._ticketsService.getTicketByVoucherCode(
                          currVoucher.voucherCode,
                          tickets
                        );

                        // now remove holder forms related to this voucher ticket if there is any
                        voucherTicket.holdersIndexes.forEach(holderIndex => {
                          this._ticketsService.removeTicketHolder(
                            holderIndex
                          );
                        });

                        this._store.dispatch(
                          new ticketActions.RemoveVoucher({
                            voucherCode: currVoucher.voucherCode,
                            skipAnonymousChecks: false
                          })
                        );

                        observableCombineLatest(
                          this._store.select(fromRoot.getSelectedExhibition),
                          this._store.select(fromRoot.getSelectedStep),
                          this._store.select(fromRoot.getTicketsCount)
                        )
                          .pipe(
                            filter(data => {
                              return !!data[0] && !!data[1];
                            }),
                            first()
                          )
                          .subscribe(data => {
                            const [
                              exhibition,
                              selectedStep,
                              ticketsCount
                            ] = data;
                            if (!ticketsCount && selectedStep !== 'tickets') {
                              this._router.navigate([
                                `webshop/${exhibition.id}/tickets`
                              ]);
                            }

                            this._translateService
                              .get('voucher.timer-ranOut')
                              .subscribe((translation: any) => {
                                this._statusBarService.setStatus(
                                  translation,
                                  'error'
                                );
                              });
                          });
                      });

                      return result;
                    }
                  // } else {
                  //   // if the quantity was set to 0, it means we are dealing unlimited voucher and we can skip it
                  //   return result;
                  // }
                },
                initialValues
              );

              this.nextExpiringVoucher.showTime = this.getCountdownTime(
                this.nextExpiringVoucher.remaining / 1000
              );
            }, 1000);
          } else {
            this.nextExpiringVoucher = null;
          }
        })
    );
  }

  closeModalWindow(event) {
    event.preventDefault();
    event.stopPropagation();
    this.ticketBookingTimeoutModalOpen = false;
    this._store.pipe(
      select(fromRoot.getSelectedExhibitionId),
      first()
    ).subscribe(eventId => {
      this._customizationService.resetShoppingStartTime();

      // release tickets with day limit
      this._ticketsService.postReleaseTicketForDay();

      this._appService.resetReducers().subscribe(() => {
        if (eventId) {
          window.location.replace(`/webshop/${eventId}`);
        } else {
          window.location.replace(`/`);
        }
      });
    });
  }

  closeModalWindowOnRightClick(event) {
    this._store
      .select(fromRoot.isLoggedInAsAdmin)
      .first()
      .subscribe(isAdmin => {
        if (isAdmin) {
          this.ticketBookingTimeoutModalOpen = false;
        }
      });
  }
}
