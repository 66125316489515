/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./login-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../login/login.component.ngfactory";
import * as i4 from "../../login/login.component";
import * as i5 from "@angular/forms";
import * as i6 from "@angular/router";
import * as i7 from "@ngrx/store";
import * as i8 from "../../login/login.service";
import * as i9 from "../../shared/services-with-reducers/user/user.service";
import * as i10 from "../../shared/services-with-reducers/helpers/helper.service";
import * as i11 from "../../loader/loader.component.ngfactory";
import * as i12 from "../../loader/loader.component";
import * as i13 from "../../shared/pipes/hide-translation/hide-translation.pipe";
import * as i14 from "@angular/common";
import * as i15 from "./login-page.component";
import * as i16 from "../../app.service";
import * as i17 from "../../shared/services-with-reducers/customization/customization.service";
var styles_LoginPageComponent = [i0.styles];
var RenderType_LoginPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginPageComponent, data: {} });
export { RenderType_LoginPageComponent as RenderType_LoginPageComponent };
function View_LoginPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "login-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "login-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "span", [["data-translation", "login.title"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(5, 1), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-login", [["class", "login-body"]], null, null, null, i3.View_LoginComponent_0, i3.RenderType_LoginComponent)), i1.ɵdid(7, 245760, null, 0, i4.LoginComponent, [i5.FormBuilder, i6.Router, i7.Store, i2.TranslateService, i8.LoginService, i9.UserService, i10.HelperService, i6.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 7, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent, 0), i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("login.title")))); _ck(_v, 3, 0, currVal_0); }); }
function View_LoginPageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loader", [], null, null, null, i11.View_LoaderComponent_0, i11.RenderType_LoaderComponent)), i1.ɵdid(1, 49152, null, 0, i12.LoaderComponent, [i1.ElementRef], null, null)], null, null); }
export function View_LoginPageComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i13.HideTranslationPipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoginPageComponent_1)), i1.ɵdid(2, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoginPageComponent_2)), i1.ɵdid(4, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.hideLogin; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.hideLogin; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_LoginPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-login-page", [], null, null, null, View_LoginPageComponent_0, RenderType_LoginPageComponent)), i1.ɵdid(1, 180224, null, 0, i15.LoginPageComponent, [i7.Store, i6.ActivatedRoute, i16.AppService, i17.CustomizationService], null, null)], null, null); }
var LoginPageComponentNgFactory = i1.ɵccf("app-login-page", i15.LoginPageComponent, View_LoginPageComponent_Host_0, {}, {}, []);
export { LoginPageComponentNgFactory as LoginPageComponentNgFactory };
