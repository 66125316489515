<div class="modal-wrap" *ngIf="modalWindowActive">
  <div
    class="modal-container"
    (click)="preventClick($event)"
    [class.full-width]="fullWidth"
  >
    <div class="modal-title">
      <span *ngIf="translateTitle && !isTitleWithParamTranslation" [attr.data-translation]="modalWindowTitle">
        {{ modalWindowTitle | translate | hideTranslation }}
      </span>
      <span *ngIf="!translateTitle && !isTitleWithParamTranslation"> {{ modalWindowTitle }} </span>
      <ng-content *ngIf="isTitleWithParamTranslation" select="[modalWindow-titleWithParamTranslation]"></ng-content>
    </div>
    <ng-content select="[modalWindow-body]"></ng-content>
    <div class="modal-buttons">
      <div class="button-wrap">
        <ng-content select="[modalWindow-submit]"></ng-content>
      </div>
      <div class="button-wrap modal-window-close">
        <ng-content select="[modalWindow-close]"></ng-content>
      </div>
    </div>
  </div>
</div>
