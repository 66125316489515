/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./iframe-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../shared/pipes/safe-resource/safe-resource.pipe";
import * as i4 from "@angular/platform-browser";
import * as i5 from "../shared/pipes/hide-translation/hide-translation.pipe";
import * as i6 from "@angular/common";
import * as i7 from "./iframe-modal.component";
import * as i8 from "@ngrx/store";
var styles_IframeModalComponent = [i0.styles];
var RenderType_IframeModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IframeModalComponent, data: {} });
export { RenderType_IframeModalComponent as RenderType_IframeModalComponent };
function View_IframeModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "modal-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "modal-container"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.preventClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "iframe", [["class", "iframe"], ["frameborder", "0"]], [[8, "src", 5]], null, null, null, null)), i1.ɵppd(3, 1), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "modal-buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "button-wrap modal-window-close"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "button", [["class", "button button-option"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModalWindow($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "span", [["data-translation", "modalWindow.close"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(10, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), _co.iframeModalUrl)); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 8, 0, _ck(_v, 10, 0, i1.ɵnov(_v.parent, 1), i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("modalWindow.close")))); _ck(_v, 8, 0, currVal_1); }); }
export function View_IframeModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.SafeResourcePipe, [i4.DomSanitizer]), i1.ɵpid(0, i5.HideTranslationPipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IframeModalComponent_1)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.iframeModalUrl; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_IframeModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-iframe-modal", [], null, null, null, View_IframeModalComponent_0, RenderType_IframeModalComponent)), i1.ɵdid(1, 114688, null, 0, i7.IframeModalComponent, [i8.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IframeModalComponentNgFactory = i1.ɵccf("app-iframe-modal", i7.IframeModalComponent, View_IframeModalComponent_Host_0, {}, {}, []);
export { IframeModalComponentNgFactory as IframeModalComponentNgFactory };
