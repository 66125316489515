import { catchError, map } from 'rxjs/operators';
import * as fromRoot from '../app.reducer';
import * as pressNewsActions from './press-news.actions';

import { ErrorHandlingService } from '../shared/error-handling/error-handling.service';
import { Http } from '@angular/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StatusBarService } from '../status-bar/status-bar.service';
import { Store } from '@ngrx/store';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PressNewsService {
  public pressNewsId$: Observable<number>;

  constructor(
    private _http: Http,
    private _errorHandlingService: ErrorHandlingService,
    private _statusBarService: StatusBarService,
    private _store: Store<fromRoot.State>
  ) {
    this.pressNewsId$ = this._store.select(fromRoot.getSelectedPressNewsId);
    this.pressNewsId$.subscribe(pressNewsId => {
      this._store.dispatch(new pressNewsActions.GetPressNewsById(pressNewsId));
    });
  }

  getPressNewsList(eventId) {
    return this._http
      .get(`${environment.webApiUrl}/event/${eventId}/completed-page`)
      .pipe(
        map(res => {
          try {
            return res.json() || {};
          } catch (err) {
            this._statusBarService.setStatus(err, 'error');
          }
        }),
        catchError(e => {
          return this._errorHandlingService.errorHandler(e);
        })
      );
  }

  getPressNews(eventId) {
    return this._http
      .get(`${environment.webApiUrl}/press-release/${eventId}`)
      .pipe(
        map(res => {
          try {
            return res.json() || {};
          } catch (err) {
            this._statusBarService.setStatus(err, 'error');
          }
        }),
        catchError(e => {
          return this._errorHandlingService.errorHandler(e);
        })
      );
  }
}
