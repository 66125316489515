import {
  BuyerSettings,
  ExhibitionSettingModel
} from '../customization.interfaces';
import { SelectOption } from './../../exhibition/exhibition.interface';

import { CheckboxInput } from '../../../forms/inputs/input-checkbox.class';
import { DropdownInput } from '../../../forms/inputs/input-dropdown.class';
import { FormButton } from '../../../forms/inputs/form-button.class';
import { InputBase } from '../../../forms/inputs/input-base.class';
import { RadioInput } from '../../../forms/inputs/input-radio.class';
import { TextInput } from '../../../forms/inputs/input-text.class';
import { DateInput } from '../../../forms/inputs/input-date.class';
import { TextOrDropdownInputTypes } from '../../helpers/helper.interface';

export const prepareBuyerInfoData = (
  settings: ExhibitionSettingModel,
  isGoogleAutocompleteEnabled: boolean,
  titles: SelectOption[],
  professions: SelectOption[],
  departments: SelectOption[],
  occupationalGroups: SelectOption[]
): InputBase<any>[] => {
  const buyerSettings: BuyerSettings = settings.buyerSettings;
  const addressAutocompleteSettings = isGoogleAutocompleteEnabled;

  const inputs: Array<TextInput | FormButton | DropdownInput | RadioInput | DateInput> = [];

  if (buyerSettings.fieldSettings.Salutation.isVisible) {
    inputs.push(
      new DropdownInput({
        key: 'gender',
        label: 'personalize.buyer-info.salutation',
        options: [
          { key: 'male', value: 'gender.male', translate: true },
          { key: 'female', value: 'gender.female', translate: true },
          { key: 'diverse', value: 'gender.diverse', translate: true },
          { key: 'notSpecified', value: 'gender.notSpecified', translate: true },
        ],
        translate: true,
        order: buyerSettings.fieldSettings.Salutation.order,
        required: buyerSettings.fieldSettings.Salutation.isMandatory, // quick fix for the client... in the future the value should come from BE
        cssClass: 'col-md-3 col-sm-6 col-lg-2 column-grow-1'
      })
    );
  }

  if (buyerSettings.fieldSettings.PersonTitle.isVisible) {
    if (titles.length) {
      inputs.push(
        new DropdownInput({
          key: TextOrDropdownInputTypes.Title,
          label: 'personalize.buyer-info.title',
          order: buyerSettings.fieldSettings.PersonTitle.order,
          translate: true,
          required: buyerSettings.fieldSettings.PersonTitle.isMandatory,
          cssClass: 'col-lg-2 col-md-2 col-sm-6 column-grow-1',
          options: titles
        })
      );
    } else {
      inputs.push(
        new TextInput({
          key: TextOrDropdownInputTypes.Title,
          label: 'personalize.buyer-info.title',
          translate: true,
          order: buyerSettings.fieldSettings.PersonTitle.order,
          required: buyerSettings.fieldSettings.PersonTitle.isMandatory,
          cssClass: 'col-lg-2 col-md-2 col-sm-6 column-grow-1',
          maxLengthValidation: 50
        })
      );
    }
  }

  if (buyerSettings.fieldSettings.FirstName.isVisible) {
    inputs.push(
      new TextInput({
        key: 'firstName',
        label: 'personalize.buyer-info.first-name',
        order: buyerSettings.fieldSettings.FirstName.order,
        translate: true,
        required: buyerSettings.fieldSettings.FirstName.isMandatory,
        cssClass: 'col-md-3 col-sm-6 col-lg-4 column-grow-1',
        //minLengthValidation: 3,
        maxLengthValidation: 50
      })
    );
  }

  if (buyerSettings.fieldSettings.LastName.isVisible) {
    inputs.push(
      new TextInput({
        key: 'lastName',
        label: 'personalize.buyer-info.last-name',
        order: buyerSettings.fieldSettings.LastName.order,
        translate: true,
        required: buyerSettings.fieldSettings.LastName.isMandatory,
        cssClass: 'col-md-4 col-sm-6 column-grow-1',
        maxLengthValidation: 50
      })
    );
  }

  if (buyerSettings.fieldSettings.Company.isVisible) {
    inputs.push(
      new TextInput({
        key: 'company',
        label: 'personalize.buyer-info.company',
        order: buyerSettings.fieldSettings.Company.order,
        translate: true,
        required: buyerSettings.fieldSettings.Company.isMandatory,
        cssClass: 'col-sm-6 column-grow-1',
        maxLengthValidation: 50
      })
    );
  }

  if (buyerSettings.fieldSettings.PersonFunction.isVisible) {
    if (professions.length) {
      inputs.push(
        new DropdownInput({
          key: TextOrDropdownInputTypes.Function,
          label: 'personalize.buyer-info.position',
          order: buyerSettings.fieldSettings.PersonFunction.order,
          translate: true,
          required: buyerSettings.fieldSettings.PersonFunction.isMandatory,
          cssClass: 'col-sm-5 column-grow-1',
          options: professions
        })
      );
    } else {
      inputs.push(
        new TextInput({
          key: TextOrDropdownInputTypes.Function,
          label: 'personalize.buyer-info.position',
          order: buyerSettings.fieldSettings.PersonFunction.order,
          translate: true,
          required: buyerSettings.fieldSettings.PersonFunction.isMandatory,
          cssClass: 'col-sm-5 column-grow-1',
          maxLengthValidation: 100
        })
      );
    }
  }

  if (buyerSettings.fieldSettings.Department.isVisible) {
    if (departments.length) {
      inputs.push(
        new DropdownInput({
          key: TextOrDropdownInputTypes.Department,
          label: 'personalize.buyer-info.department',
          order: buyerSettings.fieldSettings.Department.order,
          translate: true,
          required: buyerSettings.fieldSettings.Department.isMandatory,
          cssClass: 'col-sm-5 column-grow-1',
          options: departments
        })
      );
    } else {
      inputs.push(
        new TextInput({
          key: TextOrDropdownInputTypes.Department,
          label: 'personalize.buyer-info.department',
          order: buyerSettings.fieldSettings.Department.order,
          translate: true,
          required: buyerSettings.fieldSettings.Department.isMandatory,
          cssClass: 'col-sm-5 column-grow-1',
          maxLengthValidation: 100
        })
      );
    }
  }

  if (buyerSettings.fieldSettings.OccupationalGroup.isVisible) {
    if (occupationalGroups.length) {
      inputs.push(
        new DropdownInput({
          key: TextOrDropdownInputTypes.OccupationalGroup,
          label: 'personalize.buyer-info.occupationalGroup',
          order: buyerSettings.fieldSettings.OccupationalGroup.order,
          translate: true,
          required: buyerSettings.fieldSettings.OccupationalGroup.isMandatory,
          cssClass: 'col-sm-5 column-grow-1',
          options: occupationalGroups
        })
      );
    } else {
      inputs.push(
        new TextInput({
          key: TextOrDropdownInputTypes.OccupationalGroup,
          label: 'personalize.buyer-info.occupationalGroup',
          order: buyerSettings.fieldSettings.OccupationalGroup.order,
          translate: true,
          required: buyerSettings.fieldSettings.OccupationalGroup.isMandatory,
          cssClass: 'col-sm-5 column-grow-1',
          maxLengthValidation: 100
        })
      );
    }
  }

  if (buyerSettings.fieldSettings.Country.isVisible) {
    inputs.push(
      new DropdownInput({
        key: 'country',
        label: 'personalize.buyer-info.country',
        options: [],
        order: buyerSettings.fieldSettings.Country.order,
        translate: true,
        required: buyerSettings.fieldSettings.Country.isMandatory,
        cssClass: 'col-sm-6 column-grow-1'
      })
    );
  }

  if (buyerSettings.fieldSettings.Street.isVisible) {
    inputs.push(
      new TextInput({
        key: 'street',
        label: 'personalize.buyer-info.street',
        order: buyerSettings.fieldSettings.Street.order,
        translate: true,
        required: buyerSettings.fieldSettings.Street.isMandatory,
        cssClass: 'col-sm-7 column-grow-1',
        maxLengthValidation: 50
      })
    );
  }

  if (buyerSettings.fieldSettings.ZipCode.isVisible) {
    inputs.push(
      new TextInput({
        key: 'zipCode',
        label: 'personalize.buyer-info.zip-code',
        order: buyerSettings.fieldSettings.ZipCode.order,
        translate: true,
        required: buyerSettings.fieldSettings.ZipCode.isMandatory,
        cssClass: 'col-sm-4 column-grow-1',
        zipcodeValidation: true,
        maxLengthValidation: 12,
        zipCodeCitiesTag: 'forms.buyer-info-data.prepare-buyer-info-data'
      })
    );
  }

  if (buyerSettings.fieldSettings.City.isVisible) {
    inputs.push(
      new TextInput({
        key: 'city',
        label: 'personalize.buyer-info.city',
        order: buyerSettings.fieldSettings.City.order,
        translate: true,
        required: buyerSettings.fieldSettings.City.isMandatory,
        cssClass: 'col-sm-7 column-grow-1',
        maxLengthValidation: 50
      })
    );
  }

  if (buyerSettings.fieldSettings.Phone.isVisible) {
    inputs.push(
      new TextInput({
        key: 'telephone',
        label: 'personalize.buyer-info.phone',
        order: buyerSettings.fieldSettings.Phone.order,
        translate: true,
        required: buyerSettings.fieldSettings.Phone.isMandatory,
        cssClass: 'col-md-4 col-sm-12 column-grow-1',
        phoneValidation: !settings.isTelephoneValidationDisabled
      })
    );
  }

  if (buyerSettings.fieldSettings.VatNumber.isVisible) {
    inputs.push(
      new TextInput({
        key: 'vatNumber',
        label: 'personalize.buyer-info.vat',
        order: buyerSettings.fieldSettings.VatNumber.order,
        translate: true,
        required: buyerSettings.fieldSettings.VatNumber.isMandatory,
        cssClass: 'col-md-4 col-sm-12 column-grow-1',
        maxLengthValidation: 30
      })
    );
  }

  if (buyerSettings.fieldSettings.MailConfirm.isVisible) {
    inputs.push(
      new TextInput({
        key: 'verifyEmail',
        label: 'personalize.buyer-info.email-verify',
        order: buyerSettings.fieldSettings.MailConfirm.order,
        translate: true,
        required: buyerSettings.fieldSettings.MailConfirm.isMandatory,
        cssClass: 'col-md-4 col-sm-6 column-grow-1',
        emailValidation: true,
        sameAsValidation: 'email',
        caseInsensitive: true,
        type: 'email'
      })
    );
  }

  if (
    addressAutocompleteSettings &&
    buyerSettings.fieldSettings.SearchAddress &&
    buyerSettings.fieldSettings.SearchAddress.isVisible
  ) {
    inputs.push(
      new TextInput({
        key: 'address',
        label: 'personalize.buyer-info.address',
        order: buyerSettings.fieldSettings.SearchAddress.order,
        translate: true,
        required: buyerSettings.fieldSettings.SearchAddress.isMandatory,
        cssClass: 'col-sm-6 column-grow-1'
      })
    );
  }

  inputs.push(
    new TextInput({
      key: 'email',
      label: 'personalize.buyer-info.email',
      order: buyerSettings.fieldSettings.Email.order,
      translate: true,
      required: true,
      cssClass: 'col-md-4 col-sm-6 column-grow-1',
      emailValidation: true,
      caseInsensitive: true,
      type: 'email'
    })
  );

  if (buyerSettings.fieldSettings.DateOfBirth.isVisible) {
    inputs.push(
      new DateInput({
        key: 'dateOfBirth',
        label: 'personalize.buyer-info.dateOfBirth',
        order: buyerSettings.fieldSettings.DateOfBirth.order,
        translate: true,
        required: buyerSettings.fieldSettings.DateOfBirth.isMandatory,
        cssClass: 'col-sm-5 column-grow-1'
      })
    );
  }

  // get highest order number from already pushed input fields to inputs array, after that we can safely push all fields that do not get order from API
  let highestOrder = inputs.length > 0 ? inputs.reduce((max, input) => max.order > input.order ? max : input).order : 0;

  inputs.push(
    new TextInput({
      key: 'password',
      type: 'password',
      label: 'personalize.buyer-info.password',
      order: ++highestOrder,
      translate: true,
      required: true,
      hidden: true,
      cssClass: 'col-sm-6 column-grow-1',
      passwordValidation: true,
      minLengthValidation: 6
    })
  );

  inputs.push(
    new TextInput({
      key: 'verifyPassword',
      type: 'password',
      label: 'personalize.buyer-info.password-verify',
      order: ++highestOrder,
      translate: true,
      required: true,
      hidden: true,
      cssClass: 'col-sm-6 column-grow-1',
      passwordValidation: true,
      sameAsValidation: 'password'
    })
  );

  if (buyerSettings.showCreateAccountCheckbox) {
    inputs.push(
      new CheckboxInput({
        key: 'createAccount',
        label: '',
        order: ++highestOrder,
        translate: true,
        required: false,
        options: [
          {
            key: 'CreateAccount',
            value: false,
            label: 'personalize.buyer-info.create-account-checkbox',
            cssClass: 'col-sm-12',
            required: false
          }
        ],
        cssClass: 'col-sm-12'
      })
    );
  }

  const newsletterInputType = buyerSettings.newsletterInputType;
  if (newsletterInputType !== 'hidden') {
    if (
      newsletterInputType === 'checkboxUncheckedByDefault' ||
      newsletterInputType === 'checkboxCheckedByDefault'
    ) {
      inputs.push(
        new CheckboxInput({
          key: 'newsletter',
          label: '',
          order: ++highestOrder,
          translate: true,
          required: false,
          options: [
            {
              key: 'Newsletter',
              value: newsletterInputType === 'checkboxCheckedByDefault',
              label: 'personalize.buyer-info.newsletter',
              cssClass: 'col-sm-12',
              required: false
            }
          ],
          cssClass: 'col-sm-12'
        })
      );
    } else if (newsletterInputType === 'radioButtonUnselected') {
      inputs.push(
        new RadioInput({
          key: 'Newsletter',
          label: 'profile.newsletters.headline',
          order: ++highestOrder,
          translate: true,
          required: true,
          options: [
            {
              key: 'true',
              value: 'personalize.buyer-info.newsletter',
              cssClass: 'col-sm-12'
            },
            {
              key: 'false',
              value: 'personalize.buyer-info.no-newsletter',
              cssClass: 'col-sm-12'
            }
          ],
          cssClass: 'col-sm-12'
        })
      );
    }
  }

  inputs.push(
    new FormButton({
      key: 'createAccountButton',
      type: 'submit',
      label: 'personalize.buyer-info.create-account-button',
      order: ++highestOrder,
      translate: true,
      hidden: true,
      cssClass: 'col-sm-12 col-md-6'
    })
  );


  if (buyerSettings.showFairCatalogCheckbox) {
    inputs.push(
      new CheckboxInput({
        key: 'fairCatalogue',
        label: '',
        order: ++highestOrder,
        translate: true,
        required: false,
        options: [
          {
            key: 'FairCatalogue',
            value: buyerSettings.fairCatalogDefaultValue,
            label: 'personalize.buyer-info.fair-catalogue',
            cssClass: 'col-sm-12',
            required: false
          }
        ],
        cssClass: 'col-sm-12'
      })
    );
  }

  if (buyerSettings.enableDifferentBillingAddress) {
    inputs.push(
      new CheckboxInput({
        key: 'different-billing-address',
        label: '',
        order: ++highestOrder,
        translate: true,
        required: false,
        options: [
          {
            key: 'DifferentBillingAddress',
            value: false,
            label: 'personalize.buyer-info.different-billing-address',
            cssClass: 'col-sm-12'
          }
        ],
        cssClass: 'col-sm-12'
      })
    );
  }

  return inputs.sort((a, b) => a.order - b.order);
};
