<div *ngIf="showLoginCountdown" class="countdown" [attr.data-translation]="'countdown.login-expiration'" [translate]="'countdown.login-expiration'"
    [translateParams]="{logoutCountdown: loggedOutIn}">
</div>
<div *ngIf="nextExpiringVoucher && nextExpiringVoucher.code" class="countdown" [attr.data-translation]="'countdown.voucher-expiration'"
    [translate]="'countdown.voucher-expiration'" [translateParams]="{voucherCode: nextExpiringVoucher.code, expiringCountdown: nextExpiringVoucher.showTime}">
</div>
<div *ngIf="timeToReleaseWorkshop" class="countdown" [attr.data-translation]="'countdown.workshop-expiration'" [translate]="'countdown.workshop-expiration'"
    [translateParams]="{workshopExpiringCountdown: timeToReleaseWorkshop}">

</div>
<div *ngIf="timeToReleaseTicket" class="countdown" [attr.data-translation]="'countdown.ticket-expiration'" [translate]="'countdown.ticket-expiration'"
    [translateParams]="{ticketExpiringCountdown: timeToReleaseTicket}">
</div>
<app-modal-window [modalWindowActive]="ticketBookingTimeoutModalOpen" translateTitle="true" [modalWindowTitle]="'ticket.timeout.modalWindow.title'"
    (contextmenu)="closeModalWindowOnRightClick($event)">
    <button class="button button-option" modalWindow-close (click)="closeModalWindow($event)">
        <span data-translation="ticket.timeout.modalWindow.close">{{ 'ticket.timeout.modalWindow.close' | translate | hideTranslation }}</span>
    </button>
</app-modal-window>