/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./logout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../shared/pipes/hide-translation/hide-translation.pipe";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./logout.component";
import * as i5 from "@angular/router";
var styles_LogoutComponent = [i0.styles];
var RenderType_LogoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LogoutComponent, data: {} });
export { RenderType_LogoutComponent as RenderType_LogoutComponent };
export function View_LogoutComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.HideTranslationPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "logout-message"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["data-translation", "logout.message"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(5, 1), (_l()(), i1.ɵeld(6, 0, null, null, 4, "button", [["class", "button "]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.backToHomePage($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "span", [["data-translation", "navigation.select-event"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(10, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 5, 0, i1.ɵnov(_v, 0), i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("logout.message")))); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 8, 0, _ck(_v, 10, 0, i1.ɵnov(_v, 0), i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("navigation.select-event")))); _ck(_v, 8, 0, currVal_1); }); }
export function View_LogoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-logout", [], null, null, null, View_LogoutComponent_0, RenderType_LogoutComponent)), i1.ɵdid(1, 114688, null, 0, i4.LogoutComponent, [i5.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LogoutComponentNgFactory = i1.ɵccf("app-logout", i4.LogoutComponent, View_LogoutComponent_Host_0, {}, {}, []);
export { LogoutComponentNgFactory as LogoutComponentNgFactory };
