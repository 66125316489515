var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { InputBase } from './input-base.class';
var TextInput = /** @class */ (function (_super) {
    __extends(TextInput, _super);
    function TextInput(options) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, options) || this;
        _this.controlType = 'textbox';
        _this.type = options['type'] || '';
        _this.maxLengthValidation = options['maxLengthValidation'] || 0;
        _this.minLengthValidation = options['minLengthValidation'] || 0;
        _this.emailValidation = options['emailValidation'] || false;
        _this.zipcodeValidation = options['zipcodeValidation'] || false;
        _this.numberValidation = options['numberValidation'] || false;
        _this.phoneValidation = options['phoneValidation'] || false;
        _this.sameAsValidation = options['sameAsValidation'] || '';
        _this.autocomplete = options.hasOwnProperty('autocomplete')
            ? options['autocomplete']
            : true;
        _this.emptySpacesValidation = options['emptySpacesValidation'] || false;
        _this.noTrim = options['noTrim'] || false;
        _this.zipCodeCitiesTag = options['zipCodeCitiesTag'] || '';
        return _this;
    }
    return TextInput;
}(InputBase));
export { TextInput };
