/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./countdown.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../shared/pipes/hide-translation/hide-translation.pipe";
import * as i4 from "@angular/common";
import * as i5 from "../shared/modal-window/modal-window.component.ngfactory";
import * as i6 from "../shared/modal-window/modal-window.component";
import * as i7 from "./countdown.component";
import * as i8 from "@ngrx/store";
import * as i9 from "../shared/services-with-reducers/tickets/tickets.service";
import * as i10 from "@angular/router";
import * as i11 from "../status-bar/status-bar.service";
import * as i12 from "../shared/forms/forms.service";
import * as i13 from "../shared/services-with-reducers/step-forms/steps-forms.service";
import * as i14 from "../app.service";
import * as i15 from "../shared/services-with-reducers/additional-services/additional-services.service";
import * as i16 from "../shared/services-with-reducers/customization/customization.service";
var styles_CountdownComponent = [i0.styles];
var RenderType_CountdownComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CountdownComponent, data: {} });
export { RenderType_CountdownComponent as RenderType_CountdownComponent };
function View_CountdownComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "countdown"]], [[1, "data-translation", 0]], null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"], translateParams: [1, "translateParams"] }, null), i1.ɵpod(2, { logoutCountdown: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = "countdown.login-expiration"; var currVal_2 = _ck(_v, 2, 0, _co.loggedOutIn); _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = "countdown.login-expiration"; _ck(_v, 0, 0, currVal_0); }); }
function View_CountdownComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "countdown"]], [[1, "data-translation", 0]], null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"], translateParams: [1, "translateParams"] }, null), i1.ɵpod(2, { voucherCode: 0, expiringCountdown: 1 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = "countdown.voucher-expiration"; var currVal_2 = _ck(_v, 2, 0, _co.nextExpiringVoucher.code, _co.nextExpiringVoucher.showTime); _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = "countdown.voucher-expiration"; _ck(_v, 0, 0, currVal_0); }); }
function View_CountdownComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "countdown"]], [[1, "data-translation", 0]], null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"], translateParams: [1, "translateParams"] }, null), i1.ɵpod(2, { workshopExpiringCountdown: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = "countdown.workshop-expiration"; var currVal_2 = _ck(_v, 2, 0, _co.timeToReleaseWorkshop); _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = "countdown.workshop-expiration"; _ck(_v, 0, 0, currVal_0); }); }
function View_CountdownComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "countdown"]], [[1, "data-translation", 0]], null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"], translateParams: [1, "translateParams"] }, null), i1.ɵpod(2, { ticketExpiringCountdown: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = "countdown.ticket-expiration"; var currVal_2 = _ck(_v, 2, 0, _co.timeToReleaseTicket); _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = "countdown.ticket-expiration"; _ck(_v, 0, 0, currVal_0); }); }
export function View_CountdownComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.HideTranslationPipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CountdownComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CountdownComponent_2)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CountdownComponent_3)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CountdownComponent_4)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 6, "app-modal-window", [["translateTitle", "true"]], null, [[null, "contextmenu"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("contextmenu" === en)) {
        var pd_0 = (_co.closeModalWindowOnRightClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ModalWindowComponent_0, i5.RenderType_ModalWindowComponent)), i1.ɵdid(10, 49152, null, 0, i6.ModalWindowComponent, [], { modalWindowActive: [0, "modalWindowActive"], modalWindowTitle: [1, "modalWindowTitle"], translateTitle: [2, "translateTitle"] }, null), (_l()(), i1.ɵeld(11, 0, null, 3, 4, "button", [["class", "button button-option"], ["modalWindow-close", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModalWindow($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 3, "span", [["data-translation", "ticket.timeout.modalWindow.close"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(15, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showLoginCountdown; _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.nextExpiringVoucher && _co.nextExpiringVoucher.code); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.timeToReleaseWorkshop; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.timeToReleaseTicket; _ck(_v, 8, 0, currVal_3); var currVal_4 = _co.ticketBookingTimeoutModalOpen; var currVal_5 = "ticket.timeout.modalWindow.title"; var currVal_6 = "true"; _ck(_v, 10, 0, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_7 = i1.ɵunv(_v, 13, 0, _ck(_v, 15, 0, i1.ɵnov(_v, 0), i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform("ticket.timeout.modalWindow.close")))); _ck(_v, 13, 0, currVal_7); }); }
export function View_CountdownComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-countdown", [], null, null, null, View_CountdownComponent_0, RenderType_CountdownComponent)), i1.ɵdid(1, 245760, null, 0, i7.CountdownComponent, [i8.Store, i2.TranslateService, i9.TicketsService, i10.Router, i11.StatusBarService, i12.FormsService, i13.StepsFormsService, i14.AppService, i15.AdditionalServicesService, i16.CustomizationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CountdownComponentNgFactory = i1.ɵccf("app-countdown", i7.CountdownComponent, View_CountdownComponent_Host_0, {}, {}, []);
export { CountdownComponentNgFactory as CountdownComponentNgFactory };
