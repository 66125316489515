import { PackageModel, PackageContentModel, TicketModel, PackageSettings } from './ticket.interface';
import * as fromRoot from '../../../app.reducer';
import * as ticketActions from '../../../shared/services-with-reducers/tickets/ticket.actions';

import { EventEmitter, Injectable } from '@angular/core';
import * as TicketCounterEventEmitter from 'events';

import {
  Observable,
  combineLatest,
  of
} from 'rxjs';
import { select, Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class PackagesService {
  public packageContentCountChangedEvent = new TicketCounterEventEmitter();
  public enableDisabledPackageEvent = new EventEmitter();
  private tickets: TicketModel;

  constructor(private _store: Store<fromRoot.State>) {}

  public packageContentCountChanged(packageContent: PackageContentModel, decrease: boolean) {
    packageContent.packageGroups.forEach(packageGroup => {
      packageGroup.products.forEach(product => this.packageContentCountChangedEvent.emit(`${product.ticket.uniqueId}`, packageContent, decrease));
    });
  }

  public enableDisabledPackage() {
    this.enableDisabledPackageEvent.emit();
  }

  public updatePackageContents(changedPackage: PackageModel, count: number, decrease: boolean): Observable<TicketModel> {
    combineLatest([
      this._store.pipe(select(fromRoot.getTickets)),
      this._store.pipe(select(fromRoot.getTicketsTypes)),
      this._store.pipe(select(fromRoot.getLastPackageIndex))
    ])
    .filter(([flatTickets, ticketTypes]) => !!flatTickets && !!ticketTypes)
    .first()
    .subscribe(([flatTickets, ticketTypes, lastPackageIndex]) => {
      let existingPackagesLastIndex = lastPackageIndex;

      const updatedFlatTickets = {...flatTickets};
      const updatedTicketTypes = [...ticketTypes];
      const ticketTypeToUpdate = updatedTicketTypes.filter(ticketType => 
        ticketType.groupId == 0 && ticketType.products.find(ticketTypeProduct => 
          ticketTypeProduct.package.packageNumber == changedPackage.packageNumber
          ))[0];
          
      for (let i = 0; i < count;) {
        const packageContents = changedPackage.contents;
        const lastPackageContent = packageContents[packageContents.length - 1];
        
        if (!decrease) {
          setTimeout(() => {
            existingPackagesLastIndex++;
            const newPackageContent: PackageContentModel = {
              packageIndex: existingPackagesLastIndex,
              packageGroups: [],
              total: 0
            };
            
            lastPackageContent.packageGroups.forEach(packageGroup => {
              if (packageGroup.products.length) {
                const newPackageGroup = {...packageGroup};
                newPackageGroup.products = [];

                packageGroup.products.forEach(product => {
                  if (!!product.ticket) {
                    const newProduct = {...product};
                    const newTicket = {...newProduct.ticket};
                    const newUniqueId = newTicket.uniqueId.replace(`PI${lastPackageContent.packageIndex}`, `PI${existingPackagesLastIndex}`);
                    
                    newTicket.uniqueId = newUniqueId;
                    newProduct.ticket = newTicket;
                    newPackageGroup.products.push(newProduct);
                    
                    const newFlatTicket = {...updatedFlatTickets[product.ticket.uniqueId]};          
                    newFlatTicket.uniqueId = newUniqueId;
                    newFlatTicket.count = this.setPackageTicketAmount(newFlatTicket.packageSettings, 0);
                    newFlatTicket.packageSettings = newTicket.packageSettings;
                    newFlatTicket.holdersIndexes = [];
                    newFlatTicket.packageIndex = existingPackagesLastIndex;
                    if (newFlatTicket.hasOwnProperty('days')) {
                      newFlatTicket.days = [];
                    }
                    
                    updatedFlatTickets[newUniqueId] = newFlatTicket;
                  }
                });

                newPackageContent.packageGroups.push(newPackageGroup);
              }
            });
            
            ticketTypeToUpdate.products.forEach(ticketTypeProduct => {
              ticketTypeProduct.package.contents.push(newPackageContent);
            });

            this._store.dispatch(new ticketActions.SetLastPackageIndex(existingPackagesLastIndex));
          }, 0);
        } else {
          this.packageContentCountChanged(lastPackageContent, decrease);

          lastPackageContent.packageGroups.forEach(packageGroup => packageGroup.products.forEach(product => {
            if (product.ticket != null && updatedFlatTickets[product.ticket.uniqueId] != null){
              delete updatedFlatTickets[product.ticket.uniqueId];
            }
          }));

          packageContents.pop();
        }

        i++;
      }
      
      setTimeout(() => {
        this._store.dispatch(new ticketActions.SetTicketsTypesAction(updatedTicketTypes, true));
        this._store.dispatch(new ticketActions.SetTickets(updatedFlatTickets));
      }, 0);

      this.tickets = updatedFlatTickets;
    });
    
    return of(this.tickets);
  }

  public getPackageCount(changedPackage: PackageModel, count: number) {
    const packageContentSize = changedPackage.contents.length;
    
    if (packageContentSize) {
      let isPackageTicketAddedToCart = false

      if (packageContentSize == 1 && count == 1) {
        this._store.pipe(
          select(fromRoot.getTickets)
        )
        .filter(data => !!data)
        .first()
        .subscribe(flatTickets => {
          isPackageTicketAddedToCart = changedPackage.contents
            .find(product => typeof product !== 'undefined').packageGroups
            .some(packageGroup => packageGroup.products
            .some(product => flatTickets[product.ticket.uniqueId].count > 0));
        });
      }

      if (!isPackageTicketAddedToCart && count == 0) {
        return 0;
      }

      return count;
    }

    return 0;
  }

  public getPackageMinimalTicketCount(changedPackage: PackageModel): number {
    let minimalTicketCount = 0;
    
    if (changedPackage != null) {
      if (changedPackage.contents.length) {
        changedPackage.contents[0].packageGroups.forEach(packageGroup => {
          if (packageGroup.products.length) {
            packageGroup.products.forEach(product => {
              if (!product.ticket.isVoucher) {
                const minAmount = product.ticket.packageSettings.minAmount;
                minimalTicketCount += minAmount != null ? minAmount : product.ticket.packageSettings.amount;
              }
            });
          }  
        });
      }
    }

    return minimalTicketCount;
  }

  public disableAddingPackageOnTicketLimit(changedPackage: PackageModel): { isDisabled: boolean, disableNext: boolean } {
    const packageValidation = {
      isDisabled: false,
      disableNext: false
    };

    combineLatest([
      this._store.pipe(select(fromRoot.getTickets)),
      this._store.pipe(select(fromRoot.getTicketsBooking)),
      this._store.pipe(select(fromRoot.getExhibitionSettings))
    ])
    .first()
    .subscribe(([tickets, ticketsBooking, exhibitionSettings]) => {
      if (changedPackage != null) {
        if (changedPackage.contents.length) {
          changedPackage.contents[0].packageGroups.forEach(packageGroup => {
            if (packageGroup.products.length) {
              packageGroup.products.forEach(product => {
                if (!product.ticket.isVoucher) {
                  const ticket = tickets[product.ticket.uniqueId];
                  const amount = ticket.packageSettings.fixedAmount ? ticket.packageSettings.amount : ticket.packageSettings.minAmount;
                  
                  if (amount == 0 || packageValidation.isDisabled) {
                    return;
                  }
                  
                  const bookedTicketsCount = ticketsBooking.bookings
                    .filter(booking => booking.groupId === product.ticket.ticketTypeId && booking.ticketTypeId === product.ticket.personTypeId)
                    .reduce((totalCount, booking) => totalCount + booking.count, 0);

                  packageValidation.isDisabled = ticket.availableTickets == 0 || bookedTicketsCount + amount > ticket.availableTickets;
                  packageValidation.disableNext = packageValidation.disableNext || ticket.availableTickets == 0 || bookedTicketsCount + amount * 2 > ticket.availableTickets;
                }       
              });
            } 
          });
        }
      }

      if (!packageValidation.isDisabled && exhibitionSettings && exhibitionSettings.limitBoughtTickets != null) {
        const ticketLimit = exhibitionSettings.limitBoughtTickets;
        const minimalTicketCount = this.getPackageMinimalTicketCount(changedPackage);     
        const alreadyBookedTicketsCount = Object.keys(tickets).map(ticketUniqueId => tickets[ticketUniqueId]).reduce((bookingsTotal, booking) => bookingsTotal + booking.count, 0);
        const minimalTotalTicketCount = alreadyBookedTicketsCount + minimalTicketCount;

        packageValidation.isDisabled =  ticketLimit < minimalTotalTicketCount;
        packageValidation.disableNext = packageValidation.disableNext || ticketLimit < minimalTotalTicketCount + minimalTicketCount;
      }
    });

    return packageValidation;
  }

  public setPackageTicketAmount(packageSettings: PackageSettings, previousValue: number) {
    const minAmount = packageSettings.minAmount;
    const maxAmount = packageSettings.maxAmount;
    const defaultAmount =  minAmount != null ? minAmount : packageSettings.amount;
    const amount = previousValue != null && previousValue ? previousValue : defaultAmount;
    const shouldSetMinTicketAmount = minAmount != null && minAmount > amount;
    const shouldSetMaxTicketAmount = maxAmount != null && maxAmount < amount;
  
    let ticketAmount = amount;

    if (shouldSetMaxTicketAmount) {
      ticketAmount = maxAmount;
    }

    if (shouldSetMinTicketAmount) {
      ticketAmount = minAmount;
    }

    return ticketAmount;
  }
}
