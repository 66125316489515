import { Component, Input } from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'app-info-block',
  templateUrl: './info-block.component.html',
  styleUrls: ['./info-block.component.scss']
})
export class InfoBlockComponent {
  @Input() infoText: string;
  @Input() translateVariables: { ticketType: string };
}
