import * as customizationActions from '../customization/customization.actions';
import * as exhibitionActions from './exhibition.actions';
import * as fromRoot from '../../../app.reducer';
import * as ticketActions from '../tickets/ticket.actions';

import { Observable, combineLatest as observableCombineLatest } from 'rxjs';
import {
  filter,
  first,
  skip,
  debounceTime
} from 'rxjs/operators';

import { CustomizationService } from '../customization/customization.service';
import { ExhibitionModel } from './exhibition.interface';
import { HelperService } from '../helpers/helper.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TicketsService } from '../tickets/tickets.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExhibitionService {
  public exhibitionId$: Observable<number>;
  public exhibitionLanguage$: Observable<string>;

  constructor(
    private _http: HttpClient,
    private _helpers: HelperService,
    private _store: Store<fromRoot.State>,
    private _customizationService: CustomizationService,
    private _ticketsService: TicketsService,
    private _route: ActivatedRoute
  ) {
    this.exhibitionId$ = this._store.pipe(select(fromRoot.getSelectedExhibitionId));
    this.exhibitionLanguage$ = this._store.pipe(select(fromRoot.getLanguage));
    /* we skip initial value or value loaded from localstorage,
       so we load exhibition related data only when we realy change exhibition.
       In the reducer no Exhibition ID is set unless it differ from previous one (either from url or localstorage)
    */

    this.exhibitionId$
      .pipe(
        skip(1),
        debounceTime(50)
      )
      .subscribe(exhibitionId => {
        if (exhibitionId || exhibitionId === 0) {
          const preferedTicketPersonNr = this._route.snapshot.queryParamMap.get(
            'pt'
          );
          const preferedTicketGroupNr = this._route.snapshot.queryParamMap.get(
            'tt'
          );

          this._store.dispatch(
            new customizationActions.GetExhibitionSettings(exhibitionId)
          );
          this._store.dispatch(
            new ticketActions.GetTicketsTypesAction({
              eventId: exhibitionId,
              preferedTicketPersonNr: preferedTicketPersonNr
                ? Number(preferedTicketPersonNr)
                : null,
              preferedTicketGroupNr: preferedTicketGroupNr
                ? Number(preferedTicketGroupNr)
                : null
            })
          );
          this._store
            .pipe(
              select(fromRoot.getSelectedExhibition),
              filter(data => !!data),
              first()
            )
            .subscribe(exhibition => {
              if (!exhibition.isOver) {
                this._ticketsService
                  .getSynchronizedBarcoodes(exhibitionId)
                  .pipe(first())
                  .subscribe();
              }
            });

          this._customizationService.triggerOnExhibitionChange(exhibitionId);
        }
      });

    this.exhibitionId$.pipe(skip(1)).subscribe(exhibitionId => {
      const exhibitionOrOperatorId = exhibitionId ? exhibitionId : -1;

      this._store.dispatch(
        new exhibitionActions.GetTitles(exhibitionOrOperatorId)
      );
      this._store.dispatch(
        new exhibitionActions.GetProfessions(exhibitionOrOperatorId)
      );
      this._store.dispatch(
        new exhibitionActions.GetDepartments(exhibitionOrOperatorId)
      );
      this._store.dispatch(
        new exhibitionActions.GetOccupationalGroups(exhibitionOrOperatorId)
      );
    });

    observableCombineLatest([this.exhibitionId$, this.exhibitionLanguage$])
      .pipe(
        filter(data => {
          return !!data[1];
        })
      )
      .subscribe(data => {
        const [exhibitionId, exhibitionLanguage] = data;
        const exhibitionOrOperatorId = exhibitionId ? exhibitionId : -1;

        // effects are no more triggered in this loop so call it in next one
        setTimeout(() => {
          this._store.dispatch(
            new customizationActions.GetLocalizedImages({
              eventId: Number(exhibitionOrOperatorId),
              countryCode: exhibitionLanguage
            })
          );
        });
      });
  }

  getUpcomingExhibitionList(langCode: string) {
    return this._http.get(
      `${environment.protocol}${environment.webApiUrl}/event/active/${langCode}`
    );
  }

  getAllExhibitionsList(langCode: string) {
    return this._http.get<ExhibitionModel[]>(
      `${environment.protocol}${environment.webApiUrl}/event/all/${langCode}`
    );
  }

  getDoubleClickScripts(eventId: number) {
    return this._http.get(
      `${environment.protocol}${environment.webApiUrl}/event/${eventId}/double-click-scripts`
    );
  }

  getGenericScripts(eventId: number) {
    return this._http.get(
      `${environment.protocol}${environment.webApiUrl}/event/${eventId}/scripts`
    );
  }
  
  getExhibitionHistoryList() {
    return this._http.get(
      `${environment.protocol}${environment.webApiUrl}/user/events-history`
    );
  }

  getTitles(eventId: number) {
    let selfReg = '';
    if (this._helpers.isSelfregistration()) {
      selfReg = '?sr=true';
    }
    
    return this._http.get(
      `${environment.protocol}${environment.webApiUrl}/event/${eventId}/titles${selfReg}`
    );
  }
  
  getProfessions(eventId: number) {
    let selfReg = '';
    if (this._helpers.isSelfregistration()) {
      selfReg = '?sr=true';
    }

    return this._http.get(
      `${environment.protocol}${environment.webApiUrl}/event/${eventId}/positions${selfReg}`
    );
  }

  getDepartments(eventId: number) {
    let selfReg = '';
    if (this._helpers.isSelfregistration()) {
      selfReg = '?sr=true';
    }
    
    return this._http.get(
      `${environment.protocol}${environment.webApiUrl}/event/${eventId}/departments${selfReg}`
    );
  }

  getOccupationalGroups(eventId: number) {
    let selfReg = '';
    if (this._helpers.isSelfregistration()) {
      selfReg = '?sr=true';
    }

    return this._http.get(
      `${environment.protocol}${environment.webApiUrl}/event/${eventId}/occupational-groups${selfReg}`
    );
  }

  getAllProfessions() {
    return this._http.get(
      `${environment.protocol}${environment.webApiUrl}/event/positions`
    );
  }

  getAllDepartments() {
    return this._http.get(
      `${environment.protocol}${environment.webApiUrl}/event/departments`
    );
  }

  getAllOccupationalGroups() {
    return this._http.get(
      `${environment.protocol}${environment.webApiUrl}/event/occupational-groups`
    );
  }

  getAllTitles() {
    return this._http.get(
      `${environment.protocol}${environment.webApiUrl}/event/titles`
    );
  }

  getEventSeries(seriesId: number, language: string) {
    return this._http.get(
      `${environment.protocol}${environment.webApiUrl}/event-series/${seriesId}/${language}`
    );
  }
}
