import {
  CssSkinModel,
  ExhibitionCustomStyles
} from '../shared/services-with-reducers/customization/customization.interfaces';

import { Action } from '@ngrx/store';
import { StyleTemplateModel } from './colorizer.interface';

export enum ActionTypes {
  SAVE_OPERATOR_STYLES = '[Colorizer] save operator styles via API',
  SAVE_EVENT_STYLES = '[Colorizer] save event styles via API',
  SAVE_SUCCESS = '[Colorizer] save success',
  GET_ALL_STYLES = '[Colorizer] get all style templates',
  SET_ALL_STYLES = '[Colorizer] set all style templates',
  LOAD_STYLE = '[Colorizer] load selected style template',
  SET_STYLE = '[Colorizer] set selected style template',
  SET_STYLE_ID = '[Colorizer] set selected style template id',
  ADD_STYLE_TO_LIST = '[Colorizer] add style to list after new style POST success',
  UPDATE_STYLE = '[Colorizer] update selected style template via API',
  CREATE_STYLE = '[Colorizer] create style via api',
  RESET_REDUCER = '[Colorizer] reset reducer'
}
/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions.
 *
 * See Discriminated Unions: https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions
 */

export class SaveOperatorStyles implements Action {
  readonly type = ActionTypes.SAVE_OPERATOR_STYLES;
  constructor(public payload: Object) {}
}
export class SaveEventStyles implements Action {
  readonly type = ActionTypes.SAVE_EVENT_STYLES;
  constructor(public payload: { styleId: number; eventId: number }) {}
}
export class SaveSuccess implements Action {
  readonly type = ActionTypes.SAVE_SUCCESS;
  constructor(public payload: Boolean) {}
}

export class GetAllStyles implements Action {
  readonly type = ActionTypes.GET_ALL_STYLES;
}

export class SetAllStyles implements Action {
  readonly type = ActionTypes.SET_ALL_STYLES;
  constructor(public payload: StyleTemplateModel[]) {}
}

export class LoadStyle implements Action {
  readonly type = ActionTypes.LOAD_STYLE;
  constructor(public payload: number) {}
}

export class SetStyleId implements Action {
  readonly type = ActionTypes.SET_STYLE_ID;
  constructor(public payload: number) {}
}

export class SetSelectedStyle implements Action {
  readonly type = ActionTypes.SET_STYLE;
  constructor(public payload: CssSkinModel) {}
}

export class AddStyleToList implements Action {
  readonly type = ActionTypes.ADD_STYLE_TO_LIST;
  constructor(public payload: StyleTemplateModel) {}
}

export class UpdateStyle implements Action {
  readonly type = ActionTypes.UPDATE_STYLE;
  constructor(
    public payload: {
      styles: StyleTemplateModel;
      styleId: number;
      title: string;
    }
  ) {}
}

export class CreateStyle implements Action {
  readonly type = ActionTypes.CREATE_STYLE;
  constructor(public payload: { title: string; styles: StyleTemplateModel }) {}
}

export class ResetReducer implements Action {
  readonly type = ActionTypes.RESET_REDUCER;
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Actions =
  | SaveOperatorStyles
  | SaveEventStyles
  | SaveEventStyles
  | GetAllStyles
  | SetAllStyles
  | LoadStyle
  | AddStyleToList
  | SetStyleId
  | SetSelectedStyle
  | UpdateStyle
  | CreateStyle
  | ResetReducer;
