import { Component, Input, OnInit, OnDestroy } from '@angular/core';

import { WorkshopModel } from '../../../shared/services-with-reducers/additional-services/additional-services.interface';
import { filter } from 'rxjs/operators';
import * as fromRoot from '../../../app.reducer';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-cofirmation-workshop',
  templateUrl: './cofirmation-workshop.component.html',
  styleUrls: ['./cofirmation-workshop.component.scss']
})
export class CofirmationWorkshopComponent implements OnInit, OnDestroy {
  @Input() workshops: WorkshopModel[];
  @Input() workshopId: number;

  public workshop: WorkshopModel;
  public hideWorkshopDate: boolean = true;

  private _subscriptions = new Subscription();

  constructor(private _store: Store<fromRoot.State>) {}

  ngOnInit() {
    this.workshop = this.workshops.find(workshop => {
      return workshop.workshopId === this.workshopId;
    });

    this._subscriptions.add(
      this._store
        .select(fromRoot.getExhibitionSettings)
        .pipe(filter(data => !!data))
        .subscribe(settings => {
          this.hideWorkshopDate = settings.hideWorkshopDate;
        })
    );
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
