<app-modal-window
    [modalWindowActive]="browserModalOpened"
    translateTitle="true"
    [modalWindowTitle]="'browsercheck.modalWindow.title'"
    (contextmenu)="closeModalWindowOnRightClick($event)"
>
  <!-- <div modalWindow-body>
      <span data-translation="browsercheck.modalWindow.close">
          {{ 'browsercheck.modalWindow.text' | translate | hideTranslation }}
      </span>
  </div> -->

  <!-- #3863 - IE not supported -->
  <div modalWindow-body>
    <div class="browser-icons">
      <div class="browser-icon" (click)='downloadBrowser("https://www.google.com/chrome/")'>
        <span>Chrome</span>
        <img src="../../assets/images/browsers/chrome.png"/>
      </div>
      <div class="browser-icon" (click)='downloadBrowser("https://www.mozilla.org/en-US/firefox/new/")'>
        <span>Firefox</span>
        <img src="../../assets/images/browsers/firefox.png"/>
      </div>
      <div class="browser-icon" (click)='downloadBrowser("https://www.microsoft.com/en-us/edge")'>
        <span>Edge</span>
        <img src="../../assets/images/browsers/edge.png"/>
      </div>
      <div class="browser-icon" (click)='downloadBrowser("https://support.apple.com/downloads/safari")'>
        <span>Safari</span>
        <img src="../../assets/images/browsers/safari.png"/>
      </div>
    </div>
  </div>

  <button
    *ngIf="browserModalCloseVisible"
    class="button button-option"
    modalWindow-close
    (click)="closeModalWindow($event)"
  >
    <span data-translation="browsercheck.modalWindow.close">
        {{ 'browsercheck.modalWindow.close' | translate | hideTranslation }}
    </span>
  </button>
</app-modal-window>
