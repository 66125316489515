import * as fromRoot from '../../../app.reducer';
import * as stepsActions from '../../../shared/services-with-reducers/step-forms/steps-forms.actions';
import * as userActions from '../../../shared/services-with-reducers/user/user.actions';

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  Observable,
  Subscription,
  combineLatest as observableCombineLatest,
  BehaviorSubject
} from 'rxjs';
import { distinctUntilChanged, filter, first } from 'rxjs/operators';

import { FormGroup } from '@angular/forms';
import { FormInputsPayloadModel } from '../../../shared/services-with-reducers/step-forms/step.interface';
import { FormsService } from '../../../shared/forms/forms.service';
import { HelperService } from '../../../shared/services-with-reducers/helpers/helper.service';
import { InputBase } from '../../../shared/forms/inputs/input-base.class';
import { InputsListModel } from '../../../shared/services-with-reducers/step-forms/step.interface';
import { select, Store } from '@ngrx/store';
import { UserProfileModel } from '../../../shared/services-with-reducers/user/user.interface';
import {
  readOnlyModelFactory,
  buyerInfoReadOnlyModel
} from './buyer-info.read-only-model';
import { setTimeout } from 'core-js/library/web/timers';
import { TranslateService } from '@ngx-translate/core';
import { ExhibitionSettingModel, QuestionnaireDataInput } from '../../../shared/services-with-reducers/customization/customization.interfaces';
import * as stepsFormsActions from '../../../shared/services-with-reducers/step-forms/steps-forms.actions';
import { EmailData, ValidateDailyTicketPerEmailLimitBody, ValidateDailyTicketPerEmailLimitResult } from '../../../shared/services-with-reducers/tickets/ticket.interface';
import { TicketsService } from '../../../shared/services-with-reducers/tickets/tickets.service';
import { consoleLog } from '../../../shared/app-utils';
import { SelectOption } from '../../../shared/services-with-reducers/exhibition/exhibition.interface';
import cloneDeep from 'lodash.clonedeep';
import { TextOrDropdownInputTypes } from './../../../shared/services-with-reducers/helpers/helper.interface';

@Component({
  moduleId: module.id,
  selector: 'app-buyer-info',
  templateUrl: './buyer-info.component.html',
  styleUrls: ['./buyer-info.component.scss']
})
export class BuyerInfoComponent implements OnInit, OnDestroy {
  @Input() areTicketHoldersVisible: boolean;
  @Output() isInputChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  public buyerInfo$: Observable<InputsListModel>;
  public inputs: InputBase<any>[];
  public form: FormGroup;
  public buyerInfoActionName = ['personal', 'buyerinfo'];
  public billingaddressActionName = ['personal', 'billingaddress'];
  public isReadonlyBuyer$: Observable<boolean>;
  public formLoadedAndValid = false;
  public differentBillingAddressCheckbox: boolean = false;
  public profile$: Observable<UserProfileModel>;
  public isFormReady = false;
  public anyDisabled = true;
  public anyEnabled = true;
  public allTitlesForReadOnly = [];
  public allProfessionsForReadOnly = [];
  public allDepartmentsForReadOnly = [];
  public allOccupationalGroupsForReadOnly = [];
  public readOnlyAddress: buyerInfoReadOnlyModel[] = readOnlyModelFactory();
  public confirmationCheckboxes = [
    'newsletter',
    'different-billing-address',
    'fairCatalogue'
  ];

  public modalWindowOpen: boolean = false;

  private subscription: Subscription = new Subscription();
  private buyerFormAlreadyFilled = false;
  private firstTimeRender = true;
  private isLoggedInOnInitLoad = false;
  private buyerInfo: InputsListModel;
  private isReadonlyBuyer: boolean; 
  private profile: UserProfileModel; 
  private inputTitles: SelectOption[];
  private allTitles: QuestionnaireDataInput[];
  private inputProfessions: SelectOption[];
  private allProfessions: QuestionnaireDataInput[];
  private inputDepartments: SelectOption[];
  private allDepartments: QuestionnaireDataInput[];
  private inputOccupationalGroups: SelectOption[];
  private allOccupationalGroups: QuestionnaireDataInput[];
  private copyDataYesNo: boolean = null;

  private isFormValidationDone$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  private selectedExhibitionId: number;
  private ticketNames: Array<any> = [];
  private isSelfRegistrationEnabled: boolean;
  private hasOnlyParkingTickets: boolean = false;
  private isBuyerInfoFormValid: boolean = false;

  //tickets over limit check:
  private needsTicketsOverLimitCheck: boolean = true;
  public showTicketLimitWarning: boolean = false;
  public showTicketLimitWarningAlreadyClosed = false;
  public exhibitionSettings: ExhibitionSettingModel;
  private isTicketsOverLimitCheckDone$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private ticketsOverLimitRequests: Array<Date> = [];

  //validity check results:
  private ticketsOverLimitCheckResult: boolean = false;
  private isFairCatalogueCheckedByDefault: boolean = false;

  constructor(
    private _store: Store<fromRoot.State>,
    private _formsService: FormsService,
    private _helperService: HelperService,
    private _ticketService: TicketsService,
    private _translateService: TranslateService
  ) {
    this.isReadonlyBuyer$ = this._store.select(fromRoot.isReadonlyBuyer);
    this.buyerInfo$ = this._store.select(fromRoot.getBuyerInfo);
    this.profile$ = this._store.select(fromRoot.getProfile);
  }

  ngOnInit() {
    
    this.subscription.add(
      this._store
        .pipe(
          select(fromRoot.getExhibitionSettings),
          filter(data => !!data)
        )
        .subscribe(settings => {
          this.exhibitionSettings = settings;
          this.needsTicketsOverLimitCheck = settings.ticketLimitPerEmail > 0;
          this.isFairCatalogueCheckedByDefault = settings.buyerSettings.fairCatalogDefaultValue;
        })
    );
    
    this.subscription.add(
      this._store.pipe(select(fromRoot.getLanguage)).subscribe(() => {
        if (!!this.inputs) {
          this.form = this._formsService.toFormGroup(
            this.inputs,
            this.buyerInfoActionName
          );
        }
      })
    );

    this.profile$.pipe(first()).subscribe(profile => {
      this.isLoggedInOnInitLoad = !!profile;
    });

    this.isSelfRegistrationEnabled = this._helperService.isSelfregistration();

    this.subscription.add(
      observableCombineLatest([
        this.buyerInfo$,
        this.isReadonlyBuyer$,
        this.profile$,
        this._store.pipe(select(fromRoot.getTitles)),
        this._store.pipe(select(fromRoot.getAllTitles)),
        this._store.pipe(select(fromRoot.getProfessions)),
        this._store.pipe(select(fromRoot.getAllProfessions)),
        this._store.pipe(select(fromRoot.getDepartments)),
        this._store.pipe(select(fromRoot.getAllDepartments)),
        this._store.pipe(select(fromRoot.getOccupationalGroups)),
        this._store.pipe(select(fromRoot.getAllOccupationalGroups))
      ])
        .pipe(
          filter((data: [
            InputsListModel, 
            boolean, 
            UserProfileModel,
            SelectOption[], 
            QuestionnaireDataInput[],
            SelectOption[], 
            QuestionnaireDataInput[],
            SelectOption[], 
            QuestionnaireDataInput[],
            SelectOption[], 
            QuestionnaireDataInput[]
          ]) => {
            const [
              buyerInfo,
              isReadOnlyBuyer,
              profile,
              titles,
              allTitles,
              professions,
              allProfessions,
              departments,
              allDepartments,
              occupationalGroups,
              allOccupationalGroups
            ] = data;
            
            return !!buyerInfo 
              && isReadOnlyBuyer !== null 
              && !!titles
              && !!allTitles
              && !!professions 
              && !!allProfessions 
              && !!departments 
              && !!allDepartments 
              && !!occupationalGroups 
              && !!allOccupationalGroups;
          })
        )
        .subscribe(([
          buyerInfo, 
          isReadonlyBuyer, 
          profile, 
          inputTitles,
          allTitles,
          inputProfessions, 
          allProfessions, 
          inputDepartments, 
          allDepartments, 
          inputOccupationalGroups, 
          allOccupationalGroups
        ]) => {
            
          this.buyerInfo = buyerInfo;
          this.isReadonlyBuyer = isReadonlyBuyer;
          this.profile = profile;
          this.inputTitles = inputTitles;
          this.allTitles = allTitles;
          this.inputProfessions = inputProfessions;
          this.allProfessions = allProfessions;
          this.inputDepartments = inputDepartments;
          this.allDepartments = allDepartments;
          this.inputOccupationalGroups = inputOccupationalGroups;
          this.allOccupationalGroups = allOccupationalGroups;

          this.fillBuyerInfoWithProfileData();
        })
    );

    this.subscription.add(
      observableCombineLatest([
        this._helperService.voteYesNo$,
        this.isFormValidationDone$,
        this.profile$
      ])
        .pipe(
          filter(([voteYesNo, isFormValidationDone, profile]) => {
            return !!voteYesNo && isFormValidationDone && !!profile;
          })
        )
        .subscribe(([voteYesNo, isFormValidationDone, profile]) => {
          this.copyDataYesNo = voteYesNo;
          this.profile = profile;

          if (!!this.copyDataYesNo && isFormValidationDone) {
            if (this.isReadonlyBuyer) {
              //make read only address disappear by making all values empty:
              this.readOnlyAddress = readOnlyModelFactory();
            }

            this.fillBuyerInfoWithProfileData();
          }
        })
    );

    this.subscription.add(
      this._store
        .select(fromRoot.getSelectedExhibitionId)
        .subscribe(eventId => {
          this.selectedExhibitionId = eventId;
        })
    );

    this.subscription.add(
      this._store
        .pipe(
          select(fromRoot.getTickets),
          filter(tickets => !!tickets)
        )
        .subscribe(tickets => {
          //In order to get ticket names in the same order as ticket holder forms,
          //first we need to create an array with objects with only ticketIndex and name,
          //then we sort the tickets by ticketIndex.
          //Finaly we map it to simple array of names and make it awaylable to template
          //(code taken from order-tickets.component and modified)
          const ticketsIdWithName = [];
          let hasParkingTickets: boolean = false;

          Object.keys(tickets).forEach(key => {
            const ticketById = tickets[key];

            if (ticketById.count > 0 && !ticketById.holdersIndexes.length && !!ticketById.parking) {
              hasParkingTickets = true;
            }

            ticketById.holdersIndexes.forEach((ticketIndex, index) => {
              ticketsIdWithName.push({
                ticketIndex,
                ticketGroupName: ticketById.groupName,
                ticketName: ticketById.name,
                ticketPersonId: ticketById.ticketPersonId
              });
            });
          });

          ticketsIdWithName.sort((a, b) => {
            return a.ticketIndex - b.ticketIndex;
          });

          this.hasOnlyParkingTickets = !ticketsIdWithName.length && hasParkingTickets;
          this.ticketNames = ticketsIdWithName;
        })
    );

    this.subscription.add(
      observableCombineLatest([
        this.buyerInfo$,
        this._store.pipe(
          select(fromRoot.isBuyerInfoFormValid)
        )
      ])
        .pipe(
          filter(([buyerInfo]) => !!buyerInfo && !!buyerInfo.list && !!buyerInfo.list.find(item => item.key === 'email')),
          distinctUntilChanged((prev, curr) => {
            const [prevBuyerInfo, prevIsBuyerInfoFormValid] = prev;
            const [currBuyerInfo, currIsBuyerInfoFormValid] = curr;
            const prevEmail: string = prevBuyerInfo.list.find(item => item.key === 'email').value;
            const currEmail: string = currBuyerInfo.list.find(item => item.key === 'email').value;

            //only go inside if there was a change of the buyer e-mail or a change in buyer info form validity
            return prevEmail === currEmail && prevIsBuyerInfoFormValid === currIsBuyerInfoFormValid;
          })
        )
        .subscribe(([, isBuyerInfoFormValid]) => {
          this.isBuyerInfoFormValid = isBuyerInfoFormValid;

          //if the buyer info form is invalid or we don't have to validate tickets over limit here, set buyer info form validity
          //(it will not be done inside of the forms.service):
          if (!this.needsTicketsOverLimitCheck || this.areTicketHoldersVisible || !isBuyerInfoFormValid || this.hasOnlyParkingTickets) {
            this._formsService.setFormValidity(isBuyerInfoFormValid, this.form, this.buyerInfoActionName);
            return;
          }

          //otherwise invalidate the form and check for tickets over limit:
          this._formsService.setFormValidity(false, this.form, this.buyerInfoActionName);
          this.checkForTicketsOverLimit();
        })
    );

    this.subscription.add(
      this.isTicketsOverLimitCheckDone$
        .pipe(
          filter((isTicketsOverLimitCheckDone) => isTicketsOverLimitCheckDone)
        )
        .subscribe(() => {
          //when all checks are done set buyer info validity:
          this._formsService.setFormValidity(this.isBuyerInfoFormValid && this.ticketsOverLimitCheckResult, this.form, this.buyerInfoActionName);
        })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.inputs = [];
    this.form = null;

    this.formLoadedAndValid = false;
    this.differentBillingAddressCheckbox = false;
    this.firstTimeRender = true;

    this.isLoggedInOnInitLoad = false;
  }

  fillBuyerInfoWithUrlParameters(buyerInfo: any) {
    this._store
      .select(fromRoot.getBuyerInfoFromURL)
      .pipe(
        filter(parameters => !!parameters),
        first()
      )
      .subscribe(parameters => {
        Object.keys(parameters).forEach(function (parameter) {
          let buyerInfoParameter = buyerInfo.find(
            (p: { key: string }) => p.key === parameters[parameter].key
          );

          if (buyerInfoParameter && !buyerInfoParameter.value) {
            buyerInfoParameter.value = parameters[parameter].value;
          }
        });
      });

    this._store.dispatch(new stepsFormsActions.ResetBuyerInfoFromURL());
    this.saveForm(false);
  }

  fillBuyerInfoWithProfileData() {
    this.allTitlesForReadOnly = this.allTitles;
    this.allProfessionsForReadOnly = this.allProfessions;
    this.allDepartmentsForReadOnly = this.allDepartments;
    this.allOccupationalGroupsForReadOnly = this.allOccupationalGroups;

    if (!!this.inputs) {
      for (let i = 0; i < this.buyerInfo.list.length; i++) {
        const buyerInfoData = this.buyerInfo.list[i];
        const inputDataValue = this.inputs[i].value;
        const inputControlType = buyerInfoData.controlType.toLowerCase();
        if (inputControlType !== "checkbox" && inputControlType !== "radio" && inputControlType !== "formButton" && inputControlType !== "dateinput") {        

          if (inputDataValue !== buyerInfoData.value){
            this.isInputChanged.emit(true);
            break;
          }
        }
      }
    };

    if (this.buyerInfo && this.buyerInfo.list.length) {
      this.buyerInfo.list.forEach(buyerOption => {
          if (buyerOption.key === 'fairCatalogue' && buyerOption.options.length > 0) {
            buyerOption.value = buyerOption.options[0].value;
          }
        });
      this.inputs = this._formsService.updateInputs(
        this.inputs,
        this.buyerInfo.list
      );

      if (this.firstTimeRender && this.isReadonlyBuyer && this.profile) {
        //first enable al disabled inputs, so we can again decide whether to disable them based on their validity
        this.inputs = this.inputs.map(input => {
          input.disabled = false;
          return input;
        });
      };

      const createAccountCheckbox = this.inputs.find(
        x => x.key === 'createAccount'
      );
      let isCreateAccountChecked = false;

      if (createAccountCheckbox) {
        isCreateAccountChecked = createAccountCheckbox.options[0].value;
      }

      //when user logs in, all buyer info input fields are filled with profile data,
      //create account checkbox, button and password input fields are hidden and not required.
      if (!!this.profile) {
        // check if the values are empty
        if (this.copyDataYesNo) {
          this.buyerFormAlreadyFilled = false;
        } else {
          this.buyerFormAlreadyFilled = this.inputs.some(buyerInfoItem => {
            return buyerInfoItem.key === 'fairCatalogue' ? !this.isFairCatalogueCheckedByDefault && buyerInfoItem.value : buyerInfoItem.value;
          });
        }

        // In case the buyer form is empty prefill data from profile
        if (!this.modalWindowOpen && this.profile && !this.buyerFormAlreadyFilled) {
          let verifyEmail = '';

          this.inputs.forEach(buyerInfoItem => {
            let updatedBuyerInfoItem;
            if (this.profile.hasOwnProperty(buyerInfoItem.key)) {
              if (this.copyDataYesNo) {
                if (this.profile[buyerInfoItem.key] === null || this.profile[buyerInfoItem.key] === ''){
                  this.profile[buyerInfoItem.key] = buyerInfoItem.value;
                }
              }
              
              updatedBuyerInfoItem = {
                ...buyerInfoItem,
                value: this._formsService.getFunctionTextValue(
                  this.profile,
                  buyerInfoItem,
                  this.inputTitles,
                  this.allTitles,
                  this.inputProfessions,
                  this.allProfessions,
                  this.inputDepartments,
                  this.allDepartments,
                  this.inputOccupationalGroups,
                  this.allOccupationalGroups
                )
              };

              this._formsService.resetInvalidFunctionValue(
                updatedBuyerInfoItem,
                this.inputTitles,
                this.inputProfessions,
                this.inputDepartments,
                this.inputOccupationalGroups,
                (input: InputBase<any>) => {
                  updatedBuyerInfoItem = {
                    ...input,
                    value: null
                  };
                }
              );
            } else {
              updatedBuyerInfoItem = {
                ...buyerInfoItem,
                value: ''
              };

              
              if (
                buyerInfoItem.key === 'Newsletter' &&
                (buyerInfoItem.controlType === 'checkbox' || buyerInfoItem.controlType === 'radio')
                ) {
                  if (buyerInfoItem.value === 'false') {
                    this.profile.newsletterChecked = false;
                  } else if (buyerInfoItem.value === 'true') {
                    this.profile.newsletterChecked = true;
                  }
                updatedBuyerInfoItem = {
                  ...buyerInfoItem,
                  value: this.profile.newsletterChecked.toString()
                };

              }
            }
            const listOfInputKeys = [updatedBuyerInfoItem.key];

            // if we filled in an email with some value also prefill it to verify email

            if (
              buyerInfoItem.key === 'email' &&
              this.profile[buyerInfoItem.key]
            ) {
              verifyEmail = updatedBuyerInfoItem.value;
            }

            this.setInputsPropertyValues(
              this.inputs,
              listOfInputKeys,
              'value',
              updatedBuyerInfoItem.value
            );
          });

          if (verifyEmail) {
            this.setInputsPropertyValues(
              this.inputs,
              ['verifyEmail'],
              'value',
              verifyEmail
            );
          }
        }

        this.setInputsPropertyValues(
          this.inputs,
          ['createAccount'],
          'hidden',
          true
        );
        this.setInputsPropertyValues(
          this.inputs,
          ['createAccount', 'password', 'verifyPassword'],
          'required',
          false
        );

        if (createAccountCheckbox) {
          isCreateAccountChecked = createAccountCheckbox.options[0].value = false;
        }
      }

      // depending if create account checkbox is checked or not we display/hide the password input fields (requirement) and create account button
      this.setInputsPropertyValues(
        this.inputs,
        ['createAccountButton', 'password', 'verifyPassword'],
        'hidden',
        !isCreateAccountChecked
      );
      this.setInputsPropertyValues(
        this.inputs,
        ['password', 'verifyPassword'],
        'required',
        isCreateAccountChecked
      );

      this.setBillAddressFormValid(this.inputs);

      if (
        this.copyDataYesNo ||
        this.buyerInfo.rerender ||
        !this.form ||
        (!this.isLoggedInOnInitLoad && !!this.profile)
      ) {
        setTimeout(() => {
          // ensure that the above condition will not pass again next time
          if (!this.isLoggedInOnInitLoad && !!this.profile) {
            this.isLoggedInOnInitLoad = true;
          }

          this.fillBuyerInfoWithUrlParameters(this.inputs);

          this.form = this._formsService.toFormGroup(
            this.inputs,
            this.buyerInfoActionName
          );

          // we need to check manually if the form is valid or not in case 'readOnlyMode' is set in backoffice
          // if it is set and form is not valid, we need to enable edit temporarily
          if (this.firstTimeRender && this.isReadonlyBuyer && !!this.profile) {
            this.firstTimeRender = false;

            const validationCallback = () => {
              this.readOnlyFormReady(!!this.profile, this.allTitles, this.allProfessions, this.allDepartments, this.allOccupationalGroups);
            };

            this._helperService.triggerCallbackOnceFormValidationIsDone(
              this.form,
              validationCallback
            );
          } else {
            this.isFormReady = true;
            this.anyDisabled = this.checkIfAnyInputIsDisabled();
            this.anyEnabled = this.checkIfAnyInputIsEnabled();
            if (!this.buyerFormAlreadyFilled && !!this.profile) {
              this.saveForm(true);
            }
          }
      
          this._helperService.triggerCallbackOnceFormValidationIsDone(
            this.form,
            () => this.isFormValidationDone$.next(true)
          );
        }, 0);
      } else {
        this.anyDisabled = this.checkIfAnyInputIsDisabled();
        this.anyEnabled = this.checkIfAnyInputIsEnabled();
      }
    }
    
    if (!!this.copyDataYesNo) {
      this.copyDataYesNo = null;
      this._helperService.voteYesOrNo(null);
    }
  }

  setInputsPropertyValues(
    inputs: InputBase<any>[],
    keys: Array<string>,
    propName: string,
    value: boolean | string
  ) {
    inputs.forEach((input: InputBase<any>) => {
      if (keys.includes(input.key)) {
        input[propName] = value;
      }
    });
  }

  readOnlyFormReady(
    isLoggedIn: boolean,
    allTitles: QuestionnaireDataInput[],
    allProfessions: QuestionnaireDataInput[],
    allDepartments: QuestionnaireDataInput[],
    allOccupationalGroups: QuestionnaireDataInput[]
  ) {
    const allTextOrDropdownInputTypes = {
      [TextOrDropdownInputTypes.Title]: allTitles,
      [TextOrDropdownInputTypes.Function]: allProfessions,
      [TextOrDropdownInputTypes.Department]: allDepartments,
      [TextOrDropdownInputTypes.OccupationalGroup]: allOccupationalGroups
    };
    const textOrDropdownInputTypes: string[] = Object.keys(TextOrDropdownInputTypes)
      .map(typeKey => TextOrDropdownInputTypes[typeKey]);
    
    this.isFormReady = true;
    this.inputs = this.inputs.map(input => {
      let inputIsDisabled = false;
      if (this.form.controls[input.key].valid) {
        inputIsDisabled = true;
      }

      // don't disable checkboxes in buyer info
      /*  if (input.controlType === 'checkbox') {
        input.options = input.options.map((option, index) => {
          option.disabled = inputIsDisabled;
          return option;
        });
      } */
      this.readOnlyAddress.forEach((line: buyerInfoReadOnlyModel) => {
        const index = line.contains.indexOf(input.key);
        if (index >= 0 && inputIsDisabled) {
          line.data[index] = {};

          if (input.key === 'gender') {
            if (input.value) {
              line.data[index].title = `gender.${input.value}`;
              line.data[index].translate = true;
            } else {
              line.data[index].title = null;
            }
          } else if (input.key === 'country') {
            line.data[index].title = `country.${input.value}`;
            line.data[index].translate = true;
          } else if (textOrDropdownInputTypes.includes((input.key))) {
            const inputType = input.key;

            if (
              !isNaN(<number>input.value) && 
              !!input.value && 
              allTextOrDropdownInputTypes[inputType].length
            ) {
              const inputData = allTextOrDropdownInputTypes[inputType].find((type: QuestionnaireDataInput) => type.id === Number(input.value))
              
              if (inputData) {
                this.subscription.add(
                  this._translateService
                    .get(inputData.text)
                    .subscribe(translation => {
                      line.data[index][inputType] = translation;
                    })
                );
              }
            }
          } else {
            line.data[index].title = input.value;
            line.data[index].translate = false;
          }

          const containsMultiple: boolean = line.contains.length > 1;
          const hideLabel: boolean =
            !input.required &&
            !input.value &&
            !line.alwaysVisible &&
            !containsMultiple;

          // when input is not required and empty we don't need to show it in template;
          if (hideLabel) {
            line.label = '';
          }
        }
      });

      if (this.confirmationCheckboxes.includes(input.key)) {
        input.disabled = false;
      } else {
        input.disabled = inputIsDisabled;
      }

      return input;
    });

    this.anyDisabled = this.checkIfAnyInputIsDisabled();
    this.anyEnabled = this.checkIfAnyInputIsEnabled();

    /* rerender the form to see disabled fields */

    this.form = this._formsService.toFormGroup(
      this.inputs,
      this.buyerInfoActionName
    );

    // we filled the data so we need to save the form to redux store
    if (!this.buyerFormAlreadyFilled && isLoggedIn) {
      this.saveForm(true);
    }
  }

  checkIfAnyInputIsDisabled() {
    return this.inputs.some(input => {
      return (
        input.disabled &&
        !input.hidden &&
        !this.confirmationCheckboxes.includes(input.key)
      );
    });
  }

  checkIfAnyInputIsEnabled() {
    return this.inputs.some(input => {
      return (
        !input.disabled &&
        !input.hidden &&
        !this.confirmationCheckboxes.includes(input.key)
      );
    });
  }

  editReadOnly() {
    if (this.anyDisabled) {
      this.inputs = JSON.parse(
        JSON.stringify(
          this.inputs.map(input => {
            input.disabled = false;
            return input;
          })
        )
      );

      // make read only address dissapeared by making all values empty
      this.readOnlyAddress = readOnlyModelFactory();

      this.form = this._formsService.toFormGroup(
        this.inputs,
        this.buyerInfoActionName
      );

      // we filled the data so we need to save the form to redux store
      this.saveForm(true);
    }
  }

  saveEditReadOnly() {
    /* if user edited input and clicked to Confirm button straight away,
    we need first save the change (input is updated to redux).
    Then we can rebuild the read only info. Thats why we need the setTimeout here */
    setTimeout(() => {
      // set buyerFormAlreadyFilled to false so we enforce save of the form and its complete reload with propper disabled inputs
      this.buyerFormAlreadyFilled = false;
      this.readOnlyFormReady(true, this.allTitles, this.allProfessionsForReadOnly, this.allDepartmentsForReadOnly, this.allOccupationalGroupsForReadOnly);
    }, 300);
  }

  saveForm(rerender: boolean) {
    const clonedBuyerInputs: InputBase<any>[] = cloneDeep(this.inputs);

    const buyerInfoPayload: FormInputsPayloadModel = {
      formInfo: ['personal', 'buyerinfo'],
      inputSet: {
        rerender: rerender,
        list: clonedBuyerInputs
      }
    };

    this._store.dispatch(new stepsActions.SetInputs(buyerInfoPayload));
  }

  // If billing address is not shown, set billing address form to valid
  setBillAddressFormValid(buyerInputs: InputBase<any>[]) {
    const differentBillingAddressInput = buyerInputs.find(
      x => x.key === 'different-billing-address'
    );
    if (differentBillingAddressInput) {
      this.differentBillingAddressCheckbox =
        differentBillingAddressInput.options[0].value;
    } else {
      // if there is no billing address enabled from backoffice, then mark it for disabling the validation
      this.differentBillingAddressCheckbox = false;
    }

    // if no different billing address is used set the form and inputs to be valid
    if (!this.differentBillingAddressCheckbox) {
      this._formsService.setStepValid(this.billingaddressActionName);
    }
  }

  formSubmited(inputKey) {
    this._store.pipe(select(fromRoot.getUserLanguage)).subscribe(language => {
      if (inputKey === 'createAccountButton') {
        let userData: any = this._helperService.processFormValuesBeforeSave(this.form.value);
        delete userData.address;
        delete userData['verifyEmail'];
        delete userData['verifyPassword'];

        userData.language = language;

        this._store.dispatch(new userActions.CreateProfile(userData));
      }
    });
  }

  inputChanged(input) {
    if (this.needsTicketsOverLimitCheck && !this.areTicketHoldersVisible && this.isBuyerInfoFormValid && !this.hasOnlyParkingTickets && input === 'email') {
      this.ticketsOverLimitCheckResult = false;
      this._formsService.setFormValidity(false, this.form, this.buyerInfoActionName);
    }
  }

  checkForTicketsOverLimit() {
    this.isTicketsOverLimitCheckDone$.next(false);
    this.ticketsOverLimitCheckResult = false;

    let emailDataList: EmailData[] = [];
    const buyerEMail: string = this.inputs.find(input => input.key === 'email').value;

    if (!buyerEMail) {
      //e-mail is mandatory and so is the check for daily ticket limit per e-mail, as we don't have an e-mail we have to invalidate buyer info form:
      this._formsService.setFormValidity(false, null, this.buyerInfoActionName);
      return;
    }

    this.ticketNames.forEach(ticket => {
      emailDataList.push({ index: 0, email: buyerEMail, ticketPersonId: ticket.ticketPersonId, amount: 1 });
    });

    if (emailDataList.length) {
      const request: ValidateDailyTicketPerEmailLimitBody = {
        eventId: this.selectedExhibitionId,
        isSelfRegistration: this.isSelfRegistrationEnabled,
        items: emailDataList
      };

      const thisRequestTime: Date = new Date();
      this.ticketsOverLimitRequests.push(thisRequestTime);
      consoleLog(`CheckDailyTicketPerEmailLimit: requestTime=${thisRequestTime.toISOString()}, request=${JSON.stringify(request)}`);

      this._ticketService.checkDailyTicketPerEmailLimit(request).subscribe(
        (response: ValidateDailyTicketPerEmailLimitResult) => {
          consoleLog(`CheckDailyTicketPerEmailLimit: requestTime=${thisRequestTime.toISOString()}, response=${JSON.stringify(response)}`);
          const findIndex: number = this.ticketsOverLimitRequests.indexOf(thisRequestTime);

          if (findIndex > -1) {
            if (findIndex === this.ticketsOverLimitRequests.length - 1) {
              if (!response.isValid) {
                //the buyer is over ticket limit:
                this.ticketsOverLimitCheckResult = false;
                this.showTicketLimitWarning = true;
              } else {
                //the buyer isn't over ticket limit:
                this.ticketsOverLimitCheckResult = true;
              }

              this.isTicketsOverLimitCheckDone$.next(true);
            } else {
              consoleLog(`CheckDailyTicketPerEmailLimit: skipping response from requestTime=${thisRequestTime.toISOString()}...`);
            }

            this.ticketsOverLimitRequests.splice(findIndex, 1);
          }
        },
        (err) => {
          consoleLog(`CheckDailyTicketPerEmailLimit: requestTime=${thisRequestTime.toISOString()}, ERROR=${JSON.stringify(err)}`);
          const findIndex: number = this.ticketsOverLimitRequests.indexOf(thisRequestTime);

          if (findIndex > -1) {
            if (findIndex === this.ticketsOverLimitRequests.length - 1) {
              this.ticketsOverLimitCheckResult = false;
              this.isTicketsOverLimitCheckDone$.next(true);
            }

            this.ticketsOverLimitRequests.splice(findIndex, 1);
          }
        }
      );
    }
  }

  closeModalWindow(event) {
    event.stopPropagation();

    this.showTicketLimitWarning = false;
    //this.showTicketLimitWarningAlreadyClosed = true;
  }
}