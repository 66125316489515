<div class="change-password-wrapper">
    <div class="change-password-header">
        <span data-translation="change-password.title">{{ 'change-password.title' | translate | hideTranslation }}</span>
    </div>

    <div *ngIf="!submitedForm && hashStatus === 'ok'" class="change-password-body">
        <div class="change-password-success-body" data-translation="change-password.text-confirm">
            {{ 'change-password.text-confirm' | translate | hideTranslation }}
        </div>
        <div class="change-password-button-wrapper">
            <button class="button" type="akcept-button" (click)="changePassword()" data-translation="change-password.confirm">
                {{ 'change-password.confirm' | translate | hideTranslation }}
            </button>
        </div>
    </div>

    <div *ngIf="submitedForm || hashStatus !== 'ok'" class="change-password-body">
        <div *ngIf="resetLink === null || hashStatus === 'waiting'" class="change-password-success-body">
            <div class="change-password-fail-text" data-translation="change-password.text-sending">
                {{ 'change-password.text-sending' | translate | hideTranslation }}
            </div>
        </div>
        <div *ngIf="resetLink === false" class="change-password-success-body">
            <div class="change-password-fail-text" data-translation="change-password.text-fail">
                {{ 'change-password.text-fail' | translate | hideTranslation }}
            </div>
        </div>
        <div *ngIf="resetLink" class="change-password-success-body">
            <div class="change-password-success-text" data-translation="change-password.text-success">
                {{ 'change-password.text-success' | translate | hideTranslation }}
            </div>
        </div>
        <div *ngIf="resetLink || resetLink === false" class="change-password-button-wrapper">
            <button class="button back-button" type="button" (click)="navigateHome()" data-translation="change-password.button">
                <span>{{ 'change-password.button' | translate | hideTranslation }}</span>
            </button>
        </div>
    </div>
</div>