<ng-container *ngIf="loaded; else loading">
  <form [formGroup]="form" class="row" novalidate *ngIf="form">
    <app-df-input
      *ngFor="let input of inputs"
      [saveFormCallback]="inputsChanged"
      [input]="input"
      [class]="input.cssClass"
      [inputSet]="inputs"
      [action]="actionName"
      [form]="form"
    >
    </app-df-input>

    <ng-container *ngIf="ticket">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-4">
            <button
              (click)="cancelProfileEdit()"
              class="button reject-button"
              data-translation="profile.cancel"
            >
              {{ 'profile.cancel' | translate | hideTranslation }}
            </button>
          </div>
          <div class="col-sm-12 col-md-4 offset-md-4">
            <button
              [disabled]="!form.valid"
              (click)="sumbitProfileUpdate()"
              class="button"
            >
              <span data-translation="profile.save">{{
                'profile.save' | translate | hideTranslation
              }}</span>
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </form>
</ng-container>
<ng-template #loading>
  <app-loader></app-loader>
</ng-template>
