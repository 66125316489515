var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { combineLatest as observableCombineLatest } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import { Store, ActionsSubject, select } from '@ngrx/store';
import { FormsService } from '../shared/forms/forms.service';
import { HelperService } from '../shared/services-with-reducers/helpers/helper.service';
import { ofType } from '@ngrx/effects';
import { getNewAccountForm, getProfileEditForm } from './form-inputs.edit-account';
import * as fromRoot from '../app.reducer';
import * as stepsActions from '../shared/services-with-reducers/step-forms/steps-forms.actions';
import * as helperActions from '../shared/services-with-reducers/helpers/helper.actions';
import * as userActions from '../shared/services-with-reducers/user/user.actions';
import { RegistrationQuestionnaireComponent } from './registration-questionnaire/registration-questionnaire.component';
var EditAccountFormComponent = /** @class */ (function () {
    function EditAccountFormComponent(_store, _helperService, _formsService, _actionSubject) {
        var _this = this;
        this._store = _store;
        this._helperService = _helperService;
        this._formsService = _formsService;
        this._actionSubject = _actionSubject;
        this.isProfileUpdated = new EventEmitter();
        this.isEditEnabled = false;
        this.validRegistrationQuestionnaire = true; // set questionare validity to true.. it is updated once added to page
        this.editAccountAction = ['editAccountForm'];
        this.processingSubmit = false;
        this.feedbackMessages = [];
        this.questionnaireDataToSave = null;
        this.unsubscribe = new Subject();
        this.buyerInfoActionName = ['personal', 'buyerinfo'];
        this.formSaveCallback = function (inputs, rerender) {
            var newInProfile = {};
            inputs.forEach(function (input) {
                newInProfile[input.key] = input.value;
                if (input.controlType === 'checkbox') {
                    input.options.forEach(function (option) {
                        newInProfile[option.key] = option.value;
                    });
                }
            });
            //TODO: REMOVE????
            if (_this.formType === 'edit-account') {
                var mergedProfile = Object.assign({}, _this.userProfile, newInProfile);
            }
            if (_this.formType === 'new-account' && rerender) {
                _this.generateNewAccountForm(newInProfile, _this.listOfAllCountries);
            }
            _this.inputs = _this._formsService.updateInputs(_this.inputs, inputs);
            //because of google autocomplete we need to rebuild the form with autocomplete results
            if (rerender) {
                _this.form = _this._formsService.toFormGroup(_this.inputs);
                _this.calculateFeedbackMessages();
            }
        };
        this._store.dispatch(new helperActions.SetSpinnerValue(false));
    }
    EditAccountFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.$settings = this._store.select(fromRoot.getExhibitionSettings);
        if (this.formType === 'edit-account') {
            // when new data for profile from API is loaded process it
            observableCombineLatest([
                this._store.pipe(select(fromRoot.isProfileLoading)),
                this._store.pipe(select(fromRoot.getProfile))
            ])
                .pipe(filter(function (_a) {
                var isProfileLoading = _a[0], profile = _a[1];
                return isProfileLoading === false && !!profile;
            }), first())
                .subscribe(function () {
                _this.getProfile();
            });
        }
        this._store
            .select(fromRoot.isContinueAsGuest)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(function (isContinueAsGuest) {
            _this.isContinueAsGuest = isContinueAsGuest;
            // only hide inputs on personalize page
            if (_this.validationStepName &&
                _this.validationStepName[0] === 'personal' &&
                _this.validationStepName[1] === 'registration') {
                _this.hideFormInputs = isContinueAsGuest;
                if (_this.inputs) {
                    _this.inputs = _this.enablePasswordInForm(!isContinueAsGuest, _this.inputs).slice();
                }
            }
            else if (_this.validationStepName &&
                _this.validationStepName[0] === 'tickets' &&
                _this.validationStepName[1] === 'registration') {
                if (_this.inputs) {
                    _this.inputs = _this.enablePasswordInForm(!isContinueAsGuest, _this.inputs).slice();
                }
            }
            _this.form = _this._formsService.toFormGroup(_this.inputs);
            _this.calculateFeedbackMessages();
        });
        observableCombineLatest(this._store.select(fromRoot.getAllCountriesList), this._store.select(fromRoot.getBuyerInfoFromURL))
            .pipe(filter(function (data) {
            return !!data[0];
        }), first())
            .takeUntil(this.unsubscribe)
            .subscribe(function (data) {
            var allCountriesList = data[0], buyerInfoFromURL = data[1];
            _this.listOfAllCountries = allCountriesList;
            var prefilledValues = {};
            if (buyerInfoFromURL) {
                Object.keys(buyerInfoFromURL).forEach(function (parameter) {
                    prefilledValues[buyerInfoFromURL[parameter].key] =
                        buyerInfoFromURL[parameter].value;
                });
            }
            if (_this.userUrlFormData && (!buyerInfoFromURL || Object.keys(buyerInfoFromURL).length < 1)) {
                Object.keys(_this.userUrlFormData).forEach(function (key) {
                    prefilledValues[key] = _this.userUrlFormData[key];
                });
            }
            if (_this.formType === 'new-account') {
                _this.isEditEnabled = true;
                _this.generateNewAccountForm(prefilledValues, allCountriesList);
            }
        });
        this._actionSubject
            .pipe(ofType(userActions.ActionTypes.POST_PROFILE))
            .subscribe(function (data) {
            setTimeout(function () {
                _this.processingSubmit = false;
            }, 3000);
        });
        this._store.pipe(select(fromRoot.getLanguage)).subscribe(function () {
            if (!!_this.inputs) {
                _this.form = _this._formsService.toFormGroup(_this.inputs);
            }
        });
        if (this.form) {
            this.feedbackMessages = [];
            this.setAccountFormFeedbackMessages();
            this.setQuestionnaireFeedbackMessages();
            if (this.feedbackMessages.length === 0) {
                this.validRegistrationQuestionnaire = true;
            }
            this.calculateFeedbackMessages();
        }
        this.isSpinnerActive$ = this._store.pipe(select(fromRoot.getSpinnerValue));
    };
    EditAccountFormComponent.prototype.isButtonDisabled = function () {
        return (this.processingSubmit ||
            !this.form.valid ||
            !this.validRegistrationQuestionnaire);
    };
    EditAccountFormComponent.prototype.calculateFeedbackMessages = function (questionnaireChanged) {
        var _this = this;
        if (questionnaireChanged === void 0) { questionnaireChanged = false; }
        if (!this.form)
            return;
        if (questionnaireChanged) {
            this.feedbackMessages = [];
            this.setAccountFormFeedbackMessages();
            this.setQuestionnaireFeedbackMessages();
            if (this.feedbackMessages.length === 0) {
                this.validRegistrationQuestionnaire = true;
            }
        }
        else {
            this.form.valueChanges.subscribe(function () {
                _this.feedbackMessages = [];
                _this.setAccountFormFeedbackMessages();
                _this.setQuestionnaireFeedbackMessages();
                if (_this.feedbackMessages.length === 0) {
                    _this.validRegistrationQuestionnaire = true;
                }
            });
        }
    };
    EditAccountFormComponent.prototype.setAccountFormFeedbackMessages = function () {
        var _this = this;
        Object.keys(this.form.controls).forEach(function (key) {
            var controlErrors = _this.form.get(key).errors;
            if (controlErrors != null) {
                var control_1 = _this.inputs.filter(function (p) { return p.key === key; })[0];
                Object.keys(controlErrors).forEach(function (keyError) {
                    _this.feedbackMessages.push({
                        key: key,
                        label: control_1.label,
                        translate: control_1.translate
                    });
                });
            }
        });
    };
    EditAccountFormComponent.prototype.setQuestionnaireFeedbackMessages = function () {
        var _this = this;
        if (!this.registrationQuestionnaireComponent ||
            this.validRegistrationQuestionnaire) {
            return;
        }
        var questionnaireForm = this.registrationQuestionnaireComponent.form;
        Object.keys(questionnaireForm.controls).forEach(function (key) {
            var controlErrors = questionnaireForm.get(key).errors;
            if (controlErrors != null) {
                var control_2 = _this.registrationQuestionnaireComponent.inputs.filter(function (p) { return p.key === key; })[0];
                if (control_2.hidden) {
                    return;
                }
                Object.keys(controlErrors).forEach(function (keyError) {
                    _this.feedbackMessages.push({
                        key: key,
                        label: control_2.label,
                        translate: control_2.translate
                    });
                });
            }
        });
    };
    EditAccountFormComponent.prototype.ngOnDestroy = function () {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    };
    EditAccountFormComponent.prototype.enableOrDisableInputs = function () {
        var disabled = !this.isEditEnabled;
        this.inputs.forEach(function (input) {
            if (input.controlType === 'dateInput') {
                setTimeout(function () {
                    input.disabled = disabled;
                }, 0);
            }
            else {
                input.disabled = disabled;
            }
        });
    };
    EditAccountFormComponent.prototype.getProfile = function () {
        var _this = this;
        observableCombineLatest([
            this._store.pipe(select(fromRoot.getExhibitionSettings)),
            this._store.pipe(select(fromRoot.getProfile)),
            this._store.pipe(select(fromRoot.getOperatorsSettings)),
            this._store.pipe(select(fromRoot.getAllCountriesList)),
            this._store.pipe(select(fromRoot.getTitles)),
            this._store.pipe(select(fromRoot.getAllTitles)),
            this._store.pipe(select(fromRoot.getProfessions)),
            this._store.pipe(select(fromRoot.getAllProfessions)),
            this._store.pipe(select(fromRoot.getDepartments)),
            this._store.pipe(select(fromRoot.getAllDepartments)),
            this._store.pipe(select(fromRoot.getOccupationalGroups)),
            this._store.pipe(select(fromRoot.getAllOccupationalGroups))
        ])
            .pipe(filter(function (data) {
            var exhibitionSettings = data[0], profile = data[1], operatorSettings = data[2], allCountries = data[3], titles = data[4], allTitles = data[5], professions = data[6], allProfessions = data[7], departments = data[8], allDepartments = data[9], occupationalGroups = data[10], allOccupationalGroups = data[11];
            return !!profile
                && !!operatorSettings
                && !!allCountries
                && !!allTitles
                && !!allProfessions
                && !!allDepartments
                && !!allOccupationalGroups;
        }), takeUntil(this.unsubscribe))
            .subscribe(function (_a) {
            var settings = _a[0], profile = _a[1], operatorsSettings = _a[2], allCountries = _a[3], titles = _a[4], allTitles = _a[5], professions = _a[6], allProfessions = _a[7], departments = _a[8], allDepartments = _a[9], occupationalGroups = _a[10], allOccupationalGroups = _a[11];
            _this.userProfile = profile;
            var inputTitles = titles ? titles : [];
            var inputProfessions = professions ? professions : [];
            var inputDepartments = departments ? departments : [];
            var inputOccupationalGroups = occupationalGroups ? occupationalGroups : [];
            if (profile && operatorsSettings) {
                var profileEditForm = [];
                var isGoogleAutocompleteEnabled = false;
                //workaround for bug #2744 ""Find address" isn't visible in your profile despite being set up in admin":
                //in case we're editing the profile from the operator level we wouldn't get the event level settings and also the isGoogleAutocompleteEnabled property
                //but that property is fixed, permanently set to true in web API code so I think it should be fine if we interpret it as true on the operator level by default.
                //isGoogleAutocompleteEnabled property only influences the visibility of the SearchAddress field which has its own isVisible property defined in operator level settings anyway.
                if (settings === null || (settings.isGoogleAutocompleteEnabled)) {
                    isGoogleAutocompleteEnabled = true;
                }
                profileEditForm = getProfileEditForm(settings, operatorsSettings, isGoogleAutocompleteEnabled, inputTitles, inputProfessions, inputDepartments, inputOccupationalGroups);
                var updatedProfileEditForm = profileEditForm.map(function (profileItem) {
                    var updatedItem;
                    if (profile.hasOwnProperty(profileItem.key) &&
                        (profile[profileItem.key] || profile[profileItem.key] === 0)) {
                        updatedItem = __assign({}, profileItem, { value: _this._formsService.getFunctionTextValue(profile, profileItem, inputTitles, allTitles, inputProfessions, allProfessions, inputDepartments, allDepartments, inputOccupationalGroups, allOccupationalGroups) });
                        _this._formsService.resetInvalidFunctionValue(updatedItem, inputTitles, inputProfessions, inputDepartments, inputOccupationalGroups, function (input) {
                            updatedItem = __assign({}, input, { value: null });
                        });
                    }
                    else {
                        updatedItem = __assign({}, profileItem, { value: profileItem.key === 'newsletterChecked' ? 'false' : '' });
                    }
                    if (profileItem.controlType === 'checkbox') {
                        updatedItem.options.forEach(function (option) {
                            if (profile.hasOwnProperty(option.key)) {
                                option.value = profile[option.key];
                            }
                        });
                    }
                    return updatedItem;
                });
                _this.inputs = _this._formsService.updateInputs(_this.inputs, updatedProfileEditForm);
                _this.addCountriesToDropdown(_this.inputs, allCountries);
                _this.form = _this._formsService.toFormGroup(updatedProfileEditForm);
                _this.calculateFeedbackMessages();
                /* if profile is not valid on load, make it editable straight away */
                var validationCallback = function () {
                    if (!!_this.form && !_this.form.valid) {
                        _this.isEditEnabled = true;
                        Object.keys(_this.form.controls).forEach(function (key) {
                            _this.form.controls[key].markAsTouched();
                        });
                        _this.enableOrDisableInputs();
                    }
                };
                _this._helperService.triggerCallbackOnceFormValidationIsDone(_this.form, validationCallback, true);
                /* END: if profile is not valid on load, make it editable straight away */
                _this.enableOrDisableInputs();
            }
        });
    };
    EditAccountFormComponent.prototype.generateNewAccountForm = function (prefilledValues, listOfAllCountries) {
        var _this = this;
        observableCombineLatest([
            this._store.pipe(select(fromRoot.getExhibitionSettings)),
            this._store.pipe(select(fromRoot.getOperatorsSettings)),
            this._store.pipe(select(fromRoot.isContinueAsGuest)),
            this._store.pipe(select(fromRoot.getTitles)),
            this._store.pipe(select(fromRoot.getProfessions)),
            this._store.pipe(select(fromRoot.getDepartments)),
            this._store.pipe(select(fromRoot.getOccupationalGroups))
        ])
            .pipe(filter(function (data) { return !!data; }), takeUntil(this.unsubscribe))
            .subscribe(function (_a) {
            var settings = _a[0], operatorsSettings = _a[1], isContinueAsGuest = _a[2], titles = _a[3], professions = _a[4], departments = _a[5], occupationalGroups = _a[6];
            var newAccountForm = getNewAccountForm(settings, operatorsSettings, titles, professions, departments, occupationalGroups);
            var updatedNewAccountForm = newAccountForm.map(function (profileItem) {
                var updatedItem;
                if (prefilledValues.hasOwnProperty(profileItem.key) &&
                    (prefilledValues[profileItem.key] ||
                        prefilledValues[profileItem.key] === 0)) {
                    updatedItem = __assign({}, profileItem, { value: prefilledValues[profileItem.key] });
                }
                else {
                    updatedItem = __assign({}, profileItem, { value: '' });
                }
                if (profileItem.controlType === 'checkbox') {
                    updatedItem.options.forEach(function (option) {
                        if (prefilledValues.hasOwnProperty(option.key)) {
                            option.value = prefilledValues[option.key];
                        }
                    });
                }
                return updatedItem;
            });
            var updatedInputs = _this.enablePasswordInForm(!isContinueAsGuest, updatedNewAccountForm);
            _this.inputs = _this._formsService.updateInputs(_this.inputs, updatedInputs);
            _this.addCountriesToDropdown(_this.inputs, listOfAllCountries);
            _this.form = _this._formsService.toFormGroup(_this.inputs);
            _this.calculateFeedbackMessages();
            _this.enableOrDisableInputs();
        });
    };
    EditAccountFormComponent.prototype.enablePasswordInForm = function (enable, updatedNewAccountForm) {
        updatedNewAccountForm.forEach(function (input) {
            if (input.key.endsWith('password')) {
                input.hidden = !enable;
                input.required = enable;
            }
        });
        return updatedNewAccountForm;
    };
    EditAccountFormComponent.prototype.sumbitProfileUpdate = function () {
        this.isEditEnabled = false;
        this.enableOrDisableInputs();
        var userData = this._helperService.processFormValuesBeforeSave(this.form.value);
        userData = this.profileUpdateWithAdditionalProps(userData);
        this._store.dispatch(new userActions.UpdateProfile(userData));
        this.isProfileUpdated.emit(true);
    };
    EditAccountFormComponent.prototype.setIsAsGuest = function (event) {
        var _this = this;
        this._store.dispatch(new stepsActions.SetContinueAsGuest(event.target.checked));
        setTimeout(function () {
            _this.form.updateValueAndValidity({ onlySelf: false, emitEvent: true });
            var questionnaireForm = _this.registrationQuestionnaireComponent ? _this.registrationQuestionnaireComponent.form : null;
            if (questionnaireForm) {
                questionnaireForm.updateValueAndValidity({
                    onlySelf: false,
                    emitEvent: true
                });
            }
        }, 0);
    };
    EditAccountFormComponent.prototype.submitNewAccount = function () {
        var _this = this;
        if (this.processingSubmit ||
            !this.form.valid ||
            !this.validRegistrationQuestionnaire) {
            return;
        }
        this._store.dispatch(new helperActions.SetSpinnerValue(true));
        this.processingSubmit = true;
        observableCombineLatest([
            this._store.pipe(select(fromRoot.getLanguage)),
            this._store.pipe(select(fromRoot.getSelectedExhibitionId))
        ])
            .pipe(first())
            .subscribe(function (_a) {
            var language = _a[0], exhibitionId = _a[1];
            var userData = _this._helperService.processFormValuesBeforeSave(_this.form.value);
            //userData.userName = '';
            delete userData.address;
            delete userData['verify-email'];
            delete userData['verify-password'];
            if (!userData.hasOwnProperty('provider')) {
                userData['provider'] = '';
            }
            if (!userData.hasOwnProperty('providerKey')) {
                userData['providerKey'] = '';
            }
            if (_this.questionnaireDataToSave) {
                userData['questionnaire'] = _this.questionnaireDataToSave;
            }
            userData.language = language;
            userData.EventId = exhibitionId;
            userData.isGuest = _this.isContinueAsGuest;
            _this._store.dispatch(new userActions.CreateProfile(userData));
            //If we have the validation step names object from parent, copy data to buyer
            if (_this.validationStepName && _this.isContinueAsGuest && _this.inputs) {
                _this._store
                    .select(fromRoot.getBuyerInfo)
                    .pipe(first())
                    .subscribe(function (buyerInfo) {
                    // overwrite the buyer form with updated data from the registratin form
                    var that = _this;
                    buyerInfo.list.forEach(function (element) {
                        var sameInput = that.inputs.find(function (input) {
                            return input.value && input.key === element.key;
                        });
                        if (sameInput) {
                            element.value = sameInput.value;
                        }
                    });
                    var buyerInfoPayload = {
                        formInfo: _this.buyerInfoActionName,
                        inputSet: {
                            rerender: true,
                            list: buyerInfo.list
                        }
                    };
                    _this._store.dispatch(new stepsActions.SetInputs(buyerInfoPayload));
                });
            }
        });
    };
    EditAccountFormComponent.prototype.registrationQuestionnaireIsValid = function (isValid) {
        this.validRegistrationQuestionnaire = isValid;
    };
    EditAccountFormComponent.prototype.questionnaireDataChanged = function (formInputs) {
        var _this = this;
        if (formInputs) {
            formInputs.map(function (input) {
                if (input.previousValueId) {
                    var element = formInputs.find(function (p) {
                        return p.value == input.previousValueId ||
                            (p.controlType === 'checkbox' &&
                                p.options.find(function (option) {
                                    return option.value === true &&
                                        option.key === input.previousValueId;
                                }));
                    });
                    input.hidden = !element;
                }
            });
            var questionnaireValues_1 = this._helperService.processQuestionnaireValuesBeforeSave(formInputs);
            if (questionnaireValues_1.length) {
                this._store
                    .select(fromRoot.getSelectedExhibitionId)
                    .pipe(first())
                    .subscribe(function (id) {
                    if (id === null) {
                        id = -1;
                    }
                    _this.questionnaireDataToSave = {
                        eventId: id,
                        values: questionnaireValues_1
                    };
                });
            }
            this.calculateFeedbackMessages(true);
        }
    };
    EditAccountFormComponent.prototype.onProfileEdit = function () {
        this.isEditEnabled = true;
        this.enableOrDisableInputs();
    };
    EditAccountFormComponent.prototype.cancelProfileEdit = function () {
        // this._store.dispatch(new userActions.GetProfile(this.userProfile.id));
        this.isEditEnabled = false;
        this.enableOrDisableInputs();
    };
    EditAccountFormComponent.prototype.addCountriesToDropdown = function (inputs, countryList) {
        var _this = this;
        inputs.map(function (input) {
            if (input.key === 'country') {
                _this._formsService
                    .translateCountries(countryList)
                    .pipe(takeUntil(_this.unsubscribe))
                    .subscribe(function (subs) {
                    subs.subscribe(function (countries) {
                        input.options = countries;
                    });
                });
            }
        });
    };
    EditAccountFormComponent.prototype.profileUpdateWithAdditionalProps = function (userData) {
        var updatedData = __assign({}, userData);
        observableCombineLatest(this._store.select(fromRoot.getSelectedExhibitionId), this._store.select(fromRoot.getLanguage), this._store.select(fromRoot.getSelfRegistration))
            .filter(function (_a) {
            var EventId = _a[0], language = _a[1];
            if (EventId && language) {
                return true;
            }
        })
            .pipe(first())
            .subscribe(function (_a) {
            var EventId = _a[0], language = _a[1], IsSelfRegistration = _a[2];
            updatedData = __assign({}, updatedData, { EventId: EventId, language: language, IsSelfRegistration: IsSelfRegistration });
        });
        updatedData.id = this.userProfile.id;
        delete updatedData.address;
        return updatedData;
    };
    EditAccountFormComponent.prototype.profileCreateWithAdditionalProps = function (userData) {
        var updatedData = __assign({}, userData);
    };
    return EditAccountFormComponent;
}());
export { EditAccountFormComponent };
