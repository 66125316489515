<div class="logout-message">
    <div data-translation="logout.message">
        {{ 'logout.message' | translate | hideTranslation }}
    </div>
    <button class="button " (click)="backToHomePage($event)">
        <span data-translation="navigation.select-event">{{
            'navigation.select-event' | translate | hideTranslation
        }}</span>
    </button>
</div>
