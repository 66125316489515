import { CheckboxInput } from '../../../forms/inputs/input-checkbox.class';
import { ExhibitionSettingModel } from '../customization.interfaces';
import { InputBase } from '../../../forms/inputs/input-base.class';

export const prepareConfirmationCheckboxes = (
  settings: ExhibitionSettingModel,
  termsTranslation: string
): InputBase<any>[] => {
  const linkTextOnly = termsTranslation.match(/<.*?>/);
  let finalText = `<span data-translation="confirmation.terms-conditions">${termsTranslation}</span>`;

  if (linkTextOnly) {
    const link = `<a href="${
      settings.termsUrl
    }" target="_blank">${linkTextOnly[0].slice(1, -1)}</a>`;
    finalText = `<span data-translation="confirmation.terms-conditions">${termsTranslation.replace(
      linkTextOnly[0],
      link
    )}</span>`;
  }

  const inputs = [
    new CheckboxInput({
      key: 'email',
      label: '',
      order: 1,
      translate: true,
      required: false,
      options: [
        {
          key: 'emailConfirmation',
          value: false,
          label: 'confirmation.email-confirmation',
          cssClass: 'col-sm-12',
          required: true
        }
      ],
      cssClass: 'col-sm-12'
    })
  ];

  if (settings.isTermsVisible) {
    inputs.push(
      new CheckboxInput({
        key: 'terms',
        label: '',
        order: 2,
        translate: false,
        required: false,
        options: [
          {
            key: 'tradeConditions',
            value: false,
            label: finalText,
            cssClass: 'col-sm-12',
            required: settings.isTermsMandatory
          }
        ],
        cssClass: 'col-sm-12'
      })
    );
  }

  return inputs;
};
