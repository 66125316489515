<div>
  <app-skeleton-loader *ngIf="!translationsLoaded"></app-skeleton-loader>
  <h4
    *ngIf="translationsLoaded"
    class="title"
    data-translation-includes-children
    data-translation="step-walktrough.title"
    [appRichText]="'step-walktrough.title' | translate | hideTranslation"
  ></h4>

  <div class="list">
    <span *ngFor="let step of shopingStepList; let index = index">
      <app-skeleton-loader *ngIf="!translationsLoaded"></app-skeleton-loader>
      <div *ngIf="!!('step-walktrough.' + step | translate | hideTranslation) && translationsLoaded"
          class="black"
          data-translation-includes-children
          [attr.data-translation]="'step-walktrough.' + step"
          [appRichText]="index + 1 + '. ' + ('step-walktrough.' + step | translate | hideTranslation)"
      ></div>
    </span>
  </div>
</div>
