import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { WidgetPage } from './pages/widget-page.component';
import { WidgetTicketSelectComponent } from './pages/widgets-ticket-select/widget-ticket-select.component';
import { WidgetBannerPage } from './pages/widget-banner-page/widget-banner-page.component';

export const widgetRoutes: Routes = [
  {
    path: 'widget',
    component: WidgetPage,
    children: [
      { path: '', redirectTo: 'tickets', pathMatch: 'full' },
      {
        path: 'tickets',
        component: WidgetTicketSelectComponent
      },
      {
        path: 'banner/:id',
        component: WidgetBannerPage
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(widgetRoutes)],
  exports: [RouterModule]
})
export class WidgetRoutingModule {}

export const WidgetComponentsWithRoutes = [
  WidgetPage,
  WidgetTicketSelectComponent,
  WidgetBannerPage
];
