import { catchError, map } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import { ErrorHandlingService } from '../../shared/error-handling/error-handling.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

import { InputBase } from './../../shared/forms/inputs/input-base.class';
import { SelectOption } from './../../shared/services-with-reducers/exhibition/exhibition.interface';

@Injectable({
  providedIn: 'root'
})
export class TranslationsService {
  public activeTranslation: string;

  constructor(
    private _http: HttpClient,
    private _errorHandlingService: ErrorHandlingService,
    private _translateService: TranslateService
  ) {}

  /* rest api */

  getDefaultTranslations(): Observable<any> {
    let origin = environment.protocol + environment.origin;
    //origin = origin || window.location.origin;

    //let origin = window.location.origin;

    return (
      this._http
        // `${environment.protocol}${environment.origin}/assets/i18n/default.json`,
        .get(origin + '/assets/i18n/default.json')
    );
  }

  getLangTranslations(lang, eventId): Observable<any> {
    let event = -1;
    if (eventId !== 'default') {
      event = eventId;
    }

    return this._http
      .get(
        `${environment.protocol}${environment.webApiUrl}/event/${event}/translations/admin/${lang}`,
        { observe: 'response' }
      )
      .pipe(
        map((resp: any) => {
          return resp.body.frontend;
        }),
        catchError(e => {
          return this._errorHandlingService.errorHandler(e);
        })
      );
  }

  saveTranslations(activeLang, eventId, translations) {
    let event = -1;
    if (eventId !== 'default') {
      event = eventId;
    }

    return this._http.put(
      `${environment.protocol}${environment.webApiUrl}/event/${event}/translations/${activeLang}`,
      translations
    );
  }

  /**
   * Get translation for position Dropdown and handle it with callback
   * @param input - InputBase holding option settings
   * @param positionData - select option data
   * @param handleCallback - function handling translation
   */
  getPositionTranslationForOption(
    input: InputBase<any>,
    positionData: SelectOption,
    handleCallback: Function
  ) {
    let translationKey: string = positionData.value;

    if (translationKey) {
      this._translateService.get(translationKey).subscribe(translation => {
        handleCallback(translation);
      });
    } else {
      handleCallback(positionData.value);
    }
  }
}
