import { filter, first, takeUntil } from 'rxjs/operators';
import * as fromRoot from '../../../../app.reducer';
import { OnDestroy, OnInit, EventEmitter } from '@angular/core';
import { FormsService } from '../../../../shared/forms/forms.service';
import { select, Store } from '@ngrx/store';
import { Subject, combineLatest } from 'rxjs';
var FormComponent = /** @class */ (function () {
    function FormComponent(_formsService, _store) {
        this._formsService = _formsService;
        this._store = _store;
        this.updatedTicketHolderInputs = new EventEmitter();
        this.handleFormValueChange = new EventEmitter();
        this._unsubscribe = new Subject();
    }
    FormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.index = !!this.formPath && this.formPath.length > 1 && this.formPath[1].includes('_')
            ? parseInt(this.formPath[1].split('_')[1])
            : 0;
        this._store
            .pipe(select(fromRoot.getAllCountriesList), first()).subscribe(function (list) {
            _this._formsService.translateCountries(list).subscribe(function (subs) {
                subs.subscribe(function (countries) {
                    _this.countries = countries;
                });
            });
        });
        combineLatest(this._store.select(fromRoot.getTicketHolderInputSets), this._store.select(fromRoot.uniqueVisitorCheckType), this._store.select(fromRoot.getSkipHoldersCheck))
            .pipe(takeUntil(this._unsubscribe), filter(function (_a) {
            var ticketHolderInputSets = _a[0], uniqueVisitorCheckType = _a[1], skipHoldersCheck = _a[2];
            return !!ticketHolderInputSets && !!uniqueVisitorCheckType && !skipHoldersCheck;
        }))
            .subscribe(function (_a) {
            var ticketHolderInputSets = _a[0];
            var thisFormInputs = ticketHolderInputSets.find(function (inputSet) {
                return inputSet.formInfo.join('.') === _this.formPath.join('.');
            });
            if (thisFormInputs &&
                thisFormInputs.inputSet &&
                thisFormInputs.inputSet.list.length) {
                if (!!_this.countries) {
                    thisFormInputs.inputSet.list.forEach(function (item) {
                        if (item.key === "country") {
                            item.options = _this.countries;
                        }
                    });
                }
                _this.inputs = _this._formsService.updateInputs(_this.inputs, thisFormInputs.inputSet.list);
                _this.updatedTicketHolderInputs.emit({ inputs: _this.inputs, currentIndex: _this.index });
                if (thisFormInputs.inputSet.rerender || !_this.form) {
                    _this.form = _this._formsService.toFormGroup(thisFormInputs.inputSet.list, _this.formPath);
                }
            }
            if (_this._formChangeSubscribe) {
                _this._formChangeSubscribe.unsubscribe();
                _this._formChangeSubscribe = null;
            }
            _this._formChangeSubscribe = _this.form.valueChanges
                .pipe(takeUntil(_this._unsubscribe))
                .subscribe(function () {
                _this.handleFormValueChange.emit(_this);
            });
        });
    };
    FormComponent.prototype.ngOnDestroy = function () {
        // once removed from screen, remove the validation
        this._formsService.removeFormsValidationFeedback(this.formPath);
        this._unsubscribe.next();
        this._unsubscribe.complete();
    };
    return FormComponent;
}());
export { FormComponent };
