import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams
} from '@ngx-translate/core';

import { MissingTranslationService } from './missing-translation.service';
import { CustomParser } from '../translate-parser/translate-parser';
import { AppConstants } from '../app-constants';

export class MissingTranslation implements MissingTranslationHandler {
  public activeLanguage: string = null;
  public supportedLanguages: string[] = null;

  constructor(private missingTranslationService: MissingTranslationService) {}

  handle(params: MissingTranslationHandlerParams): string {
    let deafaultTranslations: Object = require('../../../assets/i18n/en.json');

    this.activeLanguage = this.missingTranslationService.getLanguage();
    this.supportedLanguages =
      this.missingTranslationService.getSupportedLanguages() || [];

    const useActiveLanguage =
      this.supportedLanguages.indexOf(this.activeLanguage) !== -1;

    if (useActiveLanguage) {
      try {
        deafaultTranslations = require('../../../assets/i18n/' +
          this.activeLanguage +
          '.json');
      } catch (error) {
        console.log(error);
      }
    }

    if (!deafaultTranslations[params.key]) {
      deafaultTranslations[params.key] = AppConstants.MISSING_TRANSLATION;
    }

    if (params.interpolateParams) {
      return CustomParser.translationWithInterpolation(
        deafaultTranslations[params.key],
        params.interpolateParams
      );
    } else {
      return deafaultTranslations[params.key];
    }
  }
}
