import {
  Directive,
  Input,
  OnInit,
  Renderer2,
  ElementRef,
  AfterViewInit,
  SimpleChanges,
  OnChanges
} from '@angular/core';

@Directive({
  selector: '[appRichText]'
})
export class RichTextDirective implements OnChanges {
  @Input('appRichText') private value: string;

  private addQlEditorClass: boolean;

  constructor(private renderer: Renderer2, private hostElement: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    for (let propName in changes) {
      if (propName === 'value') {
        this.addQlEditorClass = this.value.startsWith('<');

        if (this.addQlEditorClass) {
          this.hostElement.nativeElement.classList.add(
            'rendered-quill-content',
            'ql-editor'
          );
        }

        this.renderer.setProperty(
          this.hostElement.nativeElement,
          'innerHTML',
          this.value
        );
      }
    }
  }
}
