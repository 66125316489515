<ng-template #ticketTemplate let-ticket="ticket" let-package="package">
  <div style="position: relative;"
    *ngIf="isTicketVisible(ticket)"
    [class.has-voucher]="ticket.voucherCode"
    [class.has-ticketAddedByUrl]="!ticket.voucherCode && ungroupedTickets[ticket.uniqueId]?.preferredTicket && ungroupedTickets[ticket.uniqueId].count > 0"
    [class.parking-ticket]="ticket.classification === 'parking' || ticket.hasDaySellLimit"
    [ngClass]="{
      'ng-custom-invalid': ticketWithNoWorkshopsTaken.includes(ticket.uniqueId) || (hasParkingForm && invalidParkingTickets.includes(ticket.uniqueId)) || (hasContingentForm && invalidContingentTickets.includes(ticket.uniqueId)),
      'package-ticket-type': !!package,
      'ticket-type': !package
    }"
    (click)="(isMobile && package && package.count === 0) ? messageFromPackageCounter(ticket.uniqueId, package.count) : null"
  >
    <!-- ID: {{ticketVersion.uniqueId}}
    TS: {{ticketSelectionIsValid}}
    TC: {{isTicketCounterValid}}
    WS: {{!(ticketWithNoWorkshopsTaken.includes(ticketVersion.uniqueId))}}
    PT: {{!(hasParkingForm && invalidParkingTickets.includes(ticketVersion.uniqueId))}}
    CT: {{!(hasContingentForm && invalidContingentTickets.includes(ticketVersion.uniqueId))}} -->
    <ng-container *ngIf="isMobile">
      <div class="ticket-text">
        <span class="ticket-accordion-icon" *ngIf="ticket.info && (ticket.info | translate) !== AppConstants.MISSING_TRANSLATION">
          <svg class="icon" (click)="toggleTicketAccordian($event, ticket)">
            <use xlink:href="assets/defs.svg#icon-info"></use>
          </svg>
        </span>
        <span
          class="title black"
          [ngClass]="{ 'package-title-black': !!package }"
          [class.clickable-ticketVersion]="ticket.info && (ticket.info | translate) !== AppConstants.MISSING_TRANSLATION"
          (click)="canToggle(ticket) && toggleTicketAccordian($event, ticket)"
        >
          {{ ticket.ticketName | translate | hideTranslation }}
        </span>
        <svg class="workshops-icon" *ngIf="ticket.allowedWorkshops?.length">
          <use [attr.xlink:href]="'assets/defs.svg#icon-workshop'"></use>
        </svg>
      </div>
    
      <div class="row col-sm-12 ticket-description" *ngIf="ticket.infoExpanded == true || (ticket.infoExpanded | translate) == 'true'">
        <app-skeleton-loader *ngIf="!translationsLoaded"></app-skeleton-loader>
        <span *ngIf="translationsLoaded" [appRichText]="ticket.info | translate | hideTranslation"></span>
      </div>
    </ng-container>

    <app-skeleton-loader *ngIf="!translationsLoaded"></app-skeleton-loader>

    <div class="ticket-type-row" *ngIf="translationsLoaded">
      <div class="ticket-info">
        <div class="ticket-text" style="flex-grow: 1;" *ngIf="!isMobile">
          <span class="ticket-accordion-icon" *ngIf="ticket.info && (ticket.info | translate) !== AppConstants.MISSING_TRANSLATION">
            <svg class="icon" (click)="toggleTicketAccordian($event, ticket)">
              <use xlink:href="assets/defs.svg#icon-info"></use>
            </svg>
          </span>
          <span
            class="title black"
            [ngClass]="{ 'package-title-black': !!package }"
            [class.clickable-ticketVersion]="ticket.info && (ticket.info | translate) !== AppConstants.MISSING_TRANSLATION"
            (click)="canToggle(ticket) && toggleTicketAccordian($event,ticket)"
          >
            {{ ticket.ticketName | translate | hideTranslation }}
          </span>
          <svg class="workshops-icon" *ngIf="ticket.allowedWorkshops?.length">
            <use [attr.xlink:href]="'assets/defs.svg#icon-workshop'"></use>
          </svg>
        </div>
        <div class="ticket-text ticket-price">
          <span *ngIf="exhibitionSettings.currencySettings.currencyCode === 'CHF'" class="price">
            <span *ngIf="ticket.classification === 'parking'">
              {{ getParkingTicketPrice(ticket).toFixed(2) | currencySymbolGap : exhibitionSettings.currencySettings }}
            </span>
            <span *ngIf="ticket.classification !== 'parking'">
              {{ ticket.price | currencySymbolGap : exhibitionSettings.currencySettings }}
            </span>
          </span>
          <span *ngIf="exhibitionSettings.currencySettings.currencyCode !== 'CHF'"class="price">
            <span *ngIf="ticket.classification === 'parking'">
              {{ getParkingTicketPrice(ticket).toFixed(2) | currencySymbolGap: exhibitionSettings.currencySettings }}
            </span>
            <span *ngIf="ticket.classification !== 'parking'">
              {{ ticket.price.toFixed(2) | currencySymbolGap : exhibitionSettings.currencySettings }}
            </span>
          </span>
          <div class="voucher-list" *ngIf="ticket.voucherCode">
            <span class="voucher">
              {{ ticket.voucherCode }}
              <svg class="remove-voucher" (click)="releaseVoucher(ticket.voucherCode, ungroupedTickets[ticket.uniqueId])">
                <use :xlink:href="assets/defs.svg#icon-cross"href="assets/defs.svg#icon-cross"></use>
              </svg>
            </span>
          </div>
        </div>
      </div>

      <app-counter
        #triggerCounter
        *ngIf="ungroupedTickets[ticket.uniqueId]"
        [class.counter-disabled]="!!toggledWorkshop"
        [ticketType]="ungroupedTickets[ticket.uniqueId].voucherType || ticket.classification"
        [ticketUniqueId]="ticket.uniqueId"
        [numberOfAllTickets]="numberOfSelectedTickets + numberOfSelectedVouchers + numberOfSelectedLimitedVouchers + numberOfSelectedOneTimeVouchers"
        [numberOfSelectedVouchers]="numberOfSelectedVouchers"
        [numberOfSelectedLimitedVouchers]="numberOfSelectedLimitedVouchers"
        [numberOfSelectedOneTimeVouchers]="numberOfSelectedOneTimeVouchers"
        [maxLimit]="maxTicketLimit"
        [maxVoucherLimit]="maxVoucherLimit"
        [maxLimitedVoucherLimit]="maxLimitedVoucherLimit"
        [voucherLimit]="ungroupedTickets[ticket.uniqueId].quantity"
        [counterValue]="ungroupedTickets[ticket.uniqueId].count"
        (counterChange)="counterChange($event, ticket.uniqueId)"
        (ticketLoading)="ticketLoading($event)"
        [workshopsSoldOut]="ungroupedTickets[ticket.uniqueId].allowedWorkshopsFull"
        [workshopSeatsAvailable]="workshopsList && workshopsList[ticket.uniqueId]"
        [ticketSoldOut]="
          (isTicketSoldOut$ | async)[
            ticket.uniqueId
          ] ||
          (!ungroupedTickets[ticket.uniqueId]
            .availableTickets &&
            ungroupedTickets[ticket.uniqueId]
              .availableTickets !== null)
        "
        [totalAvailableTickets]="(totalAvailableTickets$ | async) && (totalAvailableTickets$ | async)[ticket.uniqueId]"
        [numOfAvailableTickets]="(availableTicketsCombined$ | async) && (availableTicketsCombined$ | async)[ticket.uniqueId]"
        [percentageOfAvailableTickets]="(percentageOfAvailableTickets$ | async) && (percentageOfAvailableTickets$ | async)[ticket.uniqueId]"
        [isTicketBookingLoading]="isPackageLoading || isTicketBookingLoading"
        [workshopsMandatory]="exhibitionSettings.isWorkshopsSelectionMandatory"
        [hasWorkshops]="ungroupedTickets[ticket.uniqueId].allowedWorkshops.length > 0"
        [amount]="ungroupedTickets[ticket.uniqueId].preferredTicket ? amount : 0"
        [package]="package"
        [matTooltip]="(package && package.count === 0 ? 'packages.package-counter-message' : '') | translate | hideTranslation"
      ></app-counter>

      <div *ngIf="isMobile && !!package && showPackageMessage && ticket.uniqueId === ticketUniqueIdMessage" class="message-body">
        <div *ngFor="let feedbackMessage of packageMessageMobile" class="message-list">
          <span>{{ feedbackMessage }}</span>
        </div>
      </div>

    </div>

    <ng-container *ngIf="ticket.hasDaySellLimit && ticket.classification !== 'parking'" [formGroup]="contingentTicketsForm">
      <div 
        *ngFor="let ticketIndex of getArrayFromTicketCount(ungroupedTickets && ungroupedTickets[ticket.uniqueId] ? ungroupedTickets[ticket.uniqueId].count : 0)"
        [formGroupName]="ticket.uniqueId + '_' + ticketIndex"
        style="margin-top: 15px; padding-bottom: 15px; margin-left: -17px;"
      >
        <div class="container contingent-container">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12">
              <div class="input-wrapper form-wrapper">
                <div
                  class="contingent-information"
                  *ngIf="
                    endDays[ticket.uniqueId] &&
                    endDays[ticket.uniqueId][ticketIndex] &&
                    endDays[ticket.uniqueId][ticketIndex].start &&
                    endDays[ticket.uniqueId][ticketIndex].isValid
                    else contingentElseBlock
                  "
                >
                  <div>
                    <p-calendar
                      #day
                      formControlName="day"
                      [disabled]="!ticket.shouldCalendarBeDisplayed"
                      [minDate]="exhibition?.startDate > dateToday ? exhibition.startDate: dateToday"
                      [defaultDate]="exhibition?.startDate > dateToday ? exhibition.startDate: dateToday"
                      [maxDate]="exhibition?.endDate"
                      [showIcon]="true"
                      [selectOtherMonths]="true"
                      [readonlyInput]="true"
                      calendarCustomDefaults
                      id="day{{ [ticket.uniqueId] }}_{{ [ticketIndex] }}"
                      (onSelect)="handleContingentTicketChange()"
                      class="hasvalue"
                    >
                      <ng-template pTemplate="date" let-date>
                        <span [class.contingent-highlighted]="highlightDay(
                          date, 
                          endDays[ticket.uniqueId][ticketIndex].start, 
                          endDays[ticket.uniqueId][ticketIndex].end)
                        ">
                          {{ date.day }}
                        </span>
                      </ng-template>
                    </p-calendar>
                
                    <label
                      class="placeholder contingent-start-label"
                      data-translation="ticket.start-day"
                      for="day{{ ticketIndex }}"
                      [innerHTML]="'ticket.start-day' | translate"
                    ></label>
                  </div>
                
                  <div *ngIf="endDays[ticket.uniqueId][ticketIndex]">
                    <div *ngIf="endDays[ticket.uniqueId][ticketIndex].duration === 1">
                      <span data-translation="ticket.end-one-day" [innerHTML]="'ticket.end-one-day' | translate : { day: (endDays[ticket.uniqueId][ticketIndex].end | localizedDate: 'dd.MM.y') }"></span>
                    </div>
                    <div *ngIf="endDays[ticket.uniqueId][ticketIndex].duration > 1">
                      <span
                        data-translation="ticket.end-multi-day"
                        [innerHTML]="'ticket.end-multi-day' | translate : { 
                          start: (endDays[ticket.uniqueId][ticketIndex].start | localizedDate: 'dd.MM.y'), 
                          end: (endDays[ticket.uniqueId][ticketIndex].end| localizedDate: 'dd.MM.y')
                        }"
                      ></span>
                    </div>
                  </div>
                </div>
                <ng-template #contingentElseBlock >
                  <div class="contingent-information">
                    <div>
                      <p-calendar
                        #day
                        formControlName="day"
                        [disabled]="!ticket.shouldCalendarBeDisplayed"
                        [minDate]="exhibition?.startDate > dateToday ? exhibition.startDate : dateToday"
                        [defaultDate]="exhibition?.startDate > dateToday ? exhibition.startDate : dateToday"
                        [maxDate]="exhibition?.endDate"
                        [showIcon]="true"
                        [selectOtherMonths]="true"
                        calendarCustomDefaults
                        id="day{{ [ticket.uniqueId] }}_{{ [ticketIndex] }}"
                        (onSelect)="handleContingentTicketChange()"
                        class="hasvalue"
                        [readonlyInput]="true"
                      >
                      </p-calendar>
  
                      <label
                        class="placeholder contingent-start-label"
                        data-translation="ticket.start-day"
                        for="day{{ ticketIndex }}"
                        [innerHTML]="'ticket.start-day' | translate"
                      ></label>
                    </div>
                    <div *ngIf="endDays[ticket.uniqueId]">
                      <div *ngIf="endDays[ticket.uniqueId][ticketIndex] && endDays[ticket.uniqueId][ticketIndex].isValid != null">
                        <span
                            data-translation="ticket.invalid"
                            [innerHTML]="'ticket.invalid' | translate"
                        ></span>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="ticket.classification === 'parking'" [formGroup]="parkingTicketsForm">
      <div 
        *ngFor="let parkingTicketIndex of getArrayFromTicketCount(ungroupedTickets && ungroupedTickets[ticket.uniqueId] ? ungroupedTickets[ticket.uniqueId].count : 0)"
        [formGroupName]="ticket.uniqueId +'_' + parkingTicketIndex"
        style="margin-top: 15px; padding-bottom: 15px; margin-left: -17px;"
      >
        <div class="container">
          <div class="row">
            <label class="col-12 placeholder" data-translation="ticket.parking">
              {{ 'ticket.parking' | translate }}
              {{ parkingTicketIndex + 1 }}.
            </label>
          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-6">
              <div class="input-wrapper form-wrapper">
                <div class="position-relative">
                  <p-calendar
                    #since
                    formControlName="since"
                    [minDate]="dateToday"
                    [maxDate]="exhibition?.endDate"
                    [readonlyInput]="true"
                    [showIcon]="true"
                    [selectOtherMonths]="true"
                    [showTime]="true"
                    [utc]="true"
                    calendarCustomDefaults
                    (onSelect)="handleParkingChange(since.value, until.value,ticket.uniqueId + '_' + parkingTicketIndex)"
                    id="since{{ parkingTicketIndex }}"
                    class="hasvalue"
                  >
                  </p-calendar>

                  <label
                    class="placeholder"
                    data-translation="ticket.parking.since"
                    for="since{{ parkingTicketIndex }}"
                  >
                    {{'ticket.parking.since' | translate}}
                  </label>
                </div>
              </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6">
              <div class="input-wrapper form-wrapper">
                <div class="position-relative">
                  <p-calendar
                    #until
                    formControlName="until"
                    [showIcon]="true"
                    calendarCustomDefaults
                    minToday="true"
                    [minDate]="since.value"
                    [maxDate]="exhibition?.endDate"
                    [disabled]="!since.value"
                    [selectOtherMonths]="true"
                    [showTime]="true"
                    [readonlyInput]="true"
                    [utc]="true"
                    (onSelect)="handleParkingChange(since.value, until.value, ticket.uniqueId + '_' + parkingTicketIndex)"
                    id="until{{ parkingTicketIndex }}"
                    class="hasvalue"
                  >
                  </p-calendar>

                  <label
                    class="placeholder"
                    data-translation="ticket.parking.until"
                    for="until{{ parkingTicketIndex }}"
                  >
                    {{ 'ticket.parking.until' | translate }}
                  </label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="row col-sm-12 ticket-description" *ngIf="!isMobile && (ticket.infoExpanded == true || (ticket.infoExpanded | translate) == 'true')">
      <app-skeleton-loader *ngIf="!translationsLoaded"></app-skeleton-loader>
      <span *ngIf="translationsLoaded" [appRichText]="ticket.info | translate | hideTranslation"></span>
    </div>

    <ng-container *ngIf="exhibitionSettings.workshopsOnTicketSelection">
      <app-skeleton-loader *ngIf="!translationsLoaded"></app-skeleton-loader>
      <ng-container *ngIf="translationsLoaded">
        <h3 *ngIf="ticket.workshopsByDay && ticket.workshopsByDay.length > 0" data-translation="confirmation.workshops">
          {{ 'confirmation.workshops' | translate }}:
        </h3>
        <div class="table-responsive workshop-table" *ngFor="let workshopByDay of ticket.workshopsByDay">
          <table class="table" style="width: 97%;">
            <thead class="workshop-table-header" (click)="toggleWorkshopAccordian($event, workshopByDay)">
              <tr>
                <th class="column-big workshop-day" [class.workshop-day-expanded]="workshopByDay.expanded">{{ convertFromDate(workshopByDay.day) | localizedDate : 'mediumDate' : null: 'GMT' }}</th>
                <ng-container *ngIf="workshopByDay.expanded">
                  <th data-translation="workshop-start" class="column-small column-centered">{{ 'workshop-start' | translate }}</th>
                  <th data-translation="workshop-end" class="column-small column-centered">{{ 'workshop-end' | translate }}</th>
                  <th *ngIf="!isMobile" data-translation="workshop-capacity" class="column-small column-centered">{{ 'workshop-capacity' | translate }}</th>
                  <th *ngIf="!isMobile" data-translation="workshop-language" class="column-small column-centered">{{ 'workshop-language' | translate }}</th>
                  <th *ngIf="!exhibitionSettings.hideWorkshopPrice" data-translation="workshop-price" class="column-small column-centered">{{ 'workshop-price' | translate }}</th>
                </ng-container>
              </tr>
            </thead>
            <tbody *ngIf="workshopByDay.expanded">
              <tr
                class="workshop-row"
                (click)="toggleDetail(workshop.id)"
                *ngFor="let workshop of workshopByDay.workshops"
              >
                <td>
                  <img *ngIf="!isMobile && workshop.image" class="img-responsive work-shop-image" [src]="origin + '/webapi' + workshop.image" />
                  <div *ngIf="!workshop.image" [class.image-placeholder]="!isMobile"></div>
                  <svg class="workshops-link" *ngIf="isMobile">
                    <use xlink:href="assets/defs.svg#icon-zoom-in"></use>
                  </svg>
                  <p> {{ workshop.name | translate }}</p>
                </td>
                <td class="column-centered">{{ workshop.start }}</td>
                <td class="column-centered">{{ workshop.end }}</td>
                <td *ngIf="!isMobile" class="column-centered">{{ workshop.capacity > 99 ? '99+' : workshop.capacity }}</td>
                <td *ngIf="!isMobile" class="column-centered">{{ workshop.language }}</td>
                <td class="column-centered" *ngIf="!exhibitionSettings.hideWorkshopPrice ">
                  <ng-container *ngIf="isMobile">{{ workshop.price.toFixed(2) }}</ng-container>
                  <ng-container *ngIf="!isMobile">{{ workshop.price.toFixed(2) | currencySymbolGap : exhibitionSettings.currencySettings }}</ng-container>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #selfRegistrationTicketTemplate let-ticket="ticket">
  <div
    *ngIf="isTicketVisible(ungroupedTickets[ticket.uniqueId])"
    class="ticket-type ticket-type-self-reg"
    [ngClass]="{
      selected:
        ungroupedTickets[ticket.uniqueId]?.count,
      'has-voucher': ticket.voucherCode,
      'has-ticketAddedByUrl': !ticket.voucherCode && ungroupedTickets[ticket.uniqueId].preferredTicket && ungroupedTickets[ticket.uniqueId]?.count,
      'ng-custom-invalid': ticketWithNoWorkshopsTaken.includes(ticket.uniqueId) || (hasParkingForm && invalidParkingTickets.includes(ticket.uniqueId)) || (hasContingentForm && invalidContingentTickets.includes(ticket.uniqueId))
    }"
  >
    <!-- ID: {{ticketVersion.uniqueId}}
    TS: {{ticketSelectionIsValid}}
    TC: {{isTicketCounterValid}}
    WS: {{!(ticketWithNoWorkshopsTaken.includes(ticketVersion.uniqueId))}}
    PT: {{!(hasParkingForm && invalidParkingTickets.includes(ticketVersion.uniqueId))}}
    CT: {{!(hasContingentForm && invalidContingentTickets.includes(ticketVersion.uniqueId))}} -->
    <div
      class="ticket-info ticket-info-self-reg"
      (click)="selectTicket(ungroupedTickets[ticket.uniqueId])"
    >
      <div class="row">
        <div
          class="ticket-text"
          [class.col-sm-9]="!ticket.voucherCode"
          [class.col-sm-7]="ticket.voucherCode"
        >
          <div class="gap-10"></div>

          <svg class="icon-checked icon" *ngIf="ungroupedTickets[ticket.uniqueId]?.count">
            <use xlink:href="assets/defs.svg#icon-checkmark"></use>
          </svg>
          <svg class="icon-add icon" *ngIf="!ungroupedTickets[ticket.uniqueId]?.count">
            <use xlink:href="assets/defs.svg#icon-add" [ngClass]="{'sr-sold-out': (isTicketSoldOut$ | async)[ticket.uniqueId] ||
            (!ungroupedTickets[ticket.uniqueId].availableTickets && ungroupedTickets[ticket.uniqueId].availableTickets !== null)}">
            </use>
          </svg>
          <span class="title black">
            {{ ticket.ticketName | translate | hideTranslation }}
          </span>
          <svg class="workshops-icon" *ngIf="ticket.allowedWorkshops?.length">
            <use [attr.xlink:href]="'assets/defs.svg#icon-workshop'"></use>
          </svg>
          <div
            *ngIf="(isTicketSoldOut$ | async)[ticket.uniqueId] || (!ungroupedTickets[ticket.uniqueId].availableTickets && ungroupedTickets[ticket.uniqueId].availableTickets !== null)"
            class="counter-info sold-out"
            data-translation="ticket.counter.ticket.slodout"
          >
            {{ 'ticket.counter.ticket.slodout' | translate }}
          </div>
        </div>
        <div
          class="ticket-text text-right"
          [class.col-sm-3]="!ticket.voucherCode"
          [class.col-sm-5]="ticket.voucherCode"
        >
          <span *ngIf="exhibitionSettings.currencySettings.currencyCode === 'CHF'" class="price">
            {{ ticket.price | currencySymbolGap: exhibitionSettings.currencySettings }}
          </span>
          <span *ngIf="exhibitionSettings.currencySettings.currencyCode !== 'CHF'" class="price">
            {{ ticket.price.toFixed(2) | currencySymbolGap : exhibitionSettings.currencySettings }}
          </span>
          <div class="voucher-list" *ngIf="ticket.voucherCode">
            <span class="voucher">
              {{ ticket.voucherCode }}
              <svg class="remove-voucher" (click)="releaseVoucher(ticket.voucherCode, ungroupedTickets[ticket.uniqueId])">
                <use :xlink:href="assets/defs.svg#icon-cross"href="assets/defs.svg#icon-cross"></use>
              </svg>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="ticket.hasDaySellLimit && ticket.classification !== 'parking'" [formGroup]="contingentTicketsForm">
    <div 
      *ngFor="let ticketIndex of getArrayFromTicketCount(ungroupedTickets && ungroupedTickets[ticket.uniqueId] ? ungroupedTickets[ticket.uniqueId].count : 0)"
      [formGroupName]="ticket.uniqueId + '_' + ticketIndex"
      style="margin-top: 15px; padding-bottom: 15px; margin-left: -17px;"
    >
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12">
            <div class="input-wrapper form-wrapper">
              <div *ngIf="endDays[ticket.uniqueId] && 
                endDays[ticket.uniqueId][ticketIndex] && 
                endDays[ticket.uniqueId][ticketIndex].start &&
                endDays[ticket.uniqueId][ticketIndex].isValid
                else selfRegistrationContingentElseBlock">
                <div class="position-relative">
                  <p-calendar
                    #day
                    formControlName="day"
                    [disabled]="!ticket.shouldCalendarBeDisplayed"
                    [minDate]="exhibition?.startDate > dateToday ? exhibition.startDate : dateToday"
                    [defaultDate]="exhibition?.startDate > dateToday ? exhibition.startDate : dateToday"
                    [maxDate]="exhibition?.endDate"
                    [showIcon]="true"
                    [selectOtherMonths]="true"
                    [utc]="true"
                    calendarCustomDefaults
                    id="day{{ ticketIndex }}"
                    (onSelect)="handleContingentTicketChange()"
                    class="hasvalue"
                    [readonlyInput]="true"
                  >
                    <ng-template pTemplate="date" let-date>
                      <span [class.contingent-highlighted]="highlightDay(
                          date, 
                          endDays[ticket.uniqueId][ticketIndex].start, 
                          endDays[ticket.uniqueId][ticketIndex].end)
                      ">
                        {{ date.day }}
                      </span>
                    </ng-template>
                  </p-calendar>
              
                  <label
                    class="placeholder"
                    data-translation="ticket.start-day"
                    for="day{{ ticketIndex }}"
                    [innerHTML]="'ticket.start-day' | translate"
                  ></label>
                </div>
                <div *ngIf="endDays[ticket.uniqueId][ticketIndex]">
                  <div *ngIf="endDays[ticket.uniqueId][ticketIndex].duration === 1">
                    <span data-translation="ticket.end-one-day" [innerHTML]="'ticket.end-one-day' | translate : { day: (endDays[ticket.uniqueId][ticketIndex].end | localizedDate: 'dd.MM.y') }"></span>
                  </div>
                  <div *ngIf="endDays[ticket.uniqueId][ticketIndex].duration > 1">
                    <span data-translation="ticket.end-multi-day" [innerHTML]="'ticket.end-multi-day' | translate: { 
                        start: (endDays[ticket.uniqueId][ticketIndex].start | localizedDate: 'dd.MM.y'),
                        end: (endDays[ticket.uniqueId][ticketIndex].end | localizedDate: 'dd.MM.y')
                      }"
                    ></span>
                  </div>
                </div>
              </div>
              <ng-template #selfRegistrationContingentElseBlock>
                <div class="position-relative">
                  <div>   
                    <p-calendar
                      #day
                      formControlName="day"
                      [disabled]="!ticket.shouldCalendarBeDisplayed"
                      [maxDate]="exhibition?.endDate"
                      [minDate]="exhibition?.startDate > dateToday ? exhibition.startDate : dateToday"
                      [defaultDate]="exhibition?.startDate > dateToday ? exhibition.startDate : dateToday"
                      [showIcon]="true"
                      [selectOtherMonths]="true"
                      [readonlyInput]="true"
                      [utc]="true"
                      calendarCustomDefaults
                      id="day{{ ticketIndex }}"
                      (onSelect)="handleContingentTicketChange()"
                      class="hasvalue"
                    >
                    </p-calendar>
  
                    <label
                      class="placeholder"
                      data-translation="ticket.start-day"
                      for="day{{ ticketIndex }}"
                      [innerHTML]="'ticket.start-day' | translate"
                    ></label>
                  </div>
                  <div *ngIf="endDays[ticket.uniqueId]">
                    <div *ngIf="endDays[ticket.uniqueId][ticketIndex] && endDays[ticket.uniqueId][ticketIndex].isValid != null">
                      <span
                          data-translation="ticket.invalid"
                          [innerHTML]="'ticket.invalid' | translate"
                      ></span>
                    </div>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>

<app-loader *ngIf="!productGroups"></app-loader>
<div class="page-padding-wrapper" *ngIf="productGroups && exhibitionSettings">
  <div class="ticket-login" *ngIf="hasAnonymous ? false : (showLoginOnTicketPage$ | async) || (showLoginOnTicketAndPersonalPage$ | async) || (notLoggedAndLoginMandatory$ | async)">
    <div
      class="login-message"
      *ngIf="notLoggedAndLoginMandatory$ | async"
      data-translation="ticket-selection.login-message"
      data-translation-includes-children
      [appRichText]="'ticket-selection.login-message' | translate | hideTranslation"
    ></div>
    <app-login
      class="login-body"
      [validationStepName]="registrationFormsActionName"
    >
    </app-login>
  </div>

  <div *ngIf="hasAnonymous ? false : verifiedAccountRequired && !isAccountVerified">
    <app-web-shop-account-verification></app-web-shop-account-verification>
  </div>

  <ng-container *ngIf="hasAnonymous || !(notLoggedAndLoginMandatory$ | async) && !(verifiedAccountRequired && !isAccountVerified)">
    <app-walk-thru-info class="step-walkthrough-wrap" *ngIf="!isSelfRegistrationEnabled"></app-walk-thru-info>

    <div
        *ngIf="!('ticket-selection.top-info-text' | translate | isHiddenTranslation) && !isSelfRegistrationEnabled"
        class="top-info-box"
        data-translation="ticket-selection.top-info-text"
        data-translation-includes-children
        [appRichText]="'ticket-selection.top-info-text' | translate"
    ></div>

    <div class="ticket-section" *ngIf="hasAnonymous || (!(notLoggedAndLoginMandatory$ | async))">
      <div *ngIf="!isSelfRegistrationEnabled">
        <div class="voucher-wrap" *ngIf="sholdShowPromoCode$ | async">
          <app-voucher
            type="normal"
            [maxTicketLimit]="maxTicketLimit"
            [numberOfAllTickets]="numberOfSelectedTickets + numberOfSelectedVouchers + numberOfSelectedLimitedVouchers + numberOfSelectedOneTimeVouchers"
            [clearVoucherInput$]="clearVoucherInput$"
          ></app-voucher>
        </div>

        <ng-container *ngFor="let section of sections">
          <div *ngIf="isSectionVisible(section)" [class.section-collapsed]="section.sectionName && !section.expanded">
            <div
              class="section-header"
              *ngIf="section.sectionName"
              (click)="toggleSectionAccordian($event, section)"
            >
              <div class="section-header-text">
                <app-skeleton-loader *ngIf="!translationsLoaded"></app-skeleton-loader>                              
                <h2 *ngIf="translationsLoaded" class="section-header-name">
                  {{ section.sectionName | translate | hideTranslation }}
                </h2>
              </div>
              <div class="section-icons">
                <svg class="icon" *ngIf="section.expanded">
                  <use xlink:href="assets/defs.svg#icon-arrow-down"></use>
                </svg>
                <svg class="icon" *ngIf="!section.expanded">
                  <use xlink:href="assets/defs.svg#icon-arrow-right2"></use>
                </svg>
              </div>
            </div>
          </div>

          <div
            (click)="toggleSectionAccordian($event, section)"
            class="section-description"
            *ngIf="section.groupDescription | translate"
          >
            <app-skeleton-loader *ngIf="!translationsLoaded"></app-skeleton-loader>
            <span *ngIf="translationsLoaded" [appRichText]="section.groupDescription | translate"></span>
          </div>

          <div *ngIf="!section.sectionName || section.expanded" [ngClass]="{ 'ticket-section-block': section.sectionName }">
            <ng-container *ngFor="let sectionProductGroup of section.productGroups;">
              <div appAdjustSideHeadline *ngIf="isTicketGroupVisible(sectionProductGroup)">
                <div [ngClass]="{'ticket-section-block-last': section.sectionName}">
                  <div class="section-headline section-headline-no-border" 
                    [class.section-headline-with-margin]="section.sectionName" 
                    *ngIf="sectionProductGroup.groupName"
                  >
                    <h2 class="ticket-group-name" 
                      [ngClass]="{'translation-loading': !translationsLoaded, 'ng-custom-invalid': !isTicketCounterValid}" 
                      innerText="{{ (translationsLoaded ? sectionProductGroup.groupName : 'translations.loading.ticket-group') | translate | hideTranslation }}"
                    ></h2>
                    <app-skeleton-loader *ngIf="!translationsLoaded"></app-skeleton-loader>
                  </div>

                  <div class="section-body section-body-no-border" [ngClass]="{ 'section-group-inside-package': section.sectionId !== null && sectionProductGroup.packageName }" *ngIf="ungroupedTickets">
                    <ng-container *ngFor="let product of sectionProductGroup.products;">
                      <ng-container *ngIf="product.ticket">
                        <ng-template *ngTemplateOutlet="ticketTemplate; context: { ticket: product.ticket }"></ng-template>
                      </ng-container> 
                      <ng-container *ngIf="product.package">
                        <div class="package">
                          <div class="package-header">
                            <!-- <div
                              class="package-header__info"
                              *ngIf="product.package.info | translate"
                            >
                              <app-skeleton-loader *ngIf="!translationsLoaded"></app-skeleton-loader>
                              <span *ngIf="translationsLoaded" [appRichText]="product.package.info | translate"></span>
                            </div> -->
                            <div class="package-header__package-name">
                              <h2 [ngClass]="{'translation-loading': !translationsLoaded, 'ng-custom-invalid': !isTicketCounterValid}"
                                innerText="{{ (translationsLoaded ? sectionProductGroup.packageName : 'translations.loading.package-name') | translate | hideTranslation }}"
                              ></h2>
                              <app-skeleton-loader *ngIf="!translationsLoaded"></app-skeleton-loader>
                            </div>

                            <app-package-counter
                              *ngIf="translationsLoaded"
                              class="package-header__counter"
                              [package]="product.package"
                              (packageCounterChange)="packageCounterChange($event, product.package)"
                              [isPackageLoading]="isTicketBookingLoading || isPackageLoading"
                              [maxLimit]="maxTicketLimit"
                              (packageCounterWarning)="packageCounterWarningDefinition($event, product.package)"
                            ></app-package-counter>
                          </div>
                          <div
                            *ngIf="product.package.warning"
                            class="ticket-selection-warning package-warning"
                            [attr.data-translation]="packageCounterWarningString"
                            [translate]="packageCounterWarningString"
                          ></div>
                          <div class="package-content">
                            <ng-container *ngFor="let packageContent of product.package.contents">
                              <div class="package-content__groups">
                                <ng-container *ngFor="let packageGroup of packageContent.packageGroups">
                                  <div class="package-content__group">
                                    <div>
                                      <h3 class="package-content__group-name" 
                                        [ngClass]="{'translation-loading': !translationsLoaded}"
                                        innerText="{{ (translationsLoaded ? packageGroup.ticketTypeName : 'translations.loading.package-group') | translate | hideTranslation }}"
                                      ></h3>
                                      <app-skeleton-loader *ngIf="!translationsLoaded" class="package-content__group-name"></app-skeleton-loader>
                                    </div>
                                    <div class="package-content__group-products">
                                      <ng-container *ngFor="let packageProduct of packageGroup.products">
                                        <div class="package-content__group-tickets">
                                          <ng-container *ngIf="packageProduct.ticket">
                                            <ng-template class="package-content__group-ticket" *ngTemplateOutlet="ticketTemplate; context: { ticket: packageProduct.ticket, package: product.package }"></ng-template>
                                          </ng-container>
                                        </div>
                                      </ng-container>
                                    </div>
                                  </div>
                                </ng-container>
                              </div>
                              <div *ngIf="product.package.count > 1" class="package-content__price">
                                <div>
                                  <span class="package-content__price-price">
                                    {{ packageContent.total.toFixed(2) | currencySymbolGap : exhibitionSettings.currencySettings }}
                                  </span>
                                </div>
                                <div>
                                </div>
                              </div>
                            </ng-container>
                            <div *ngIf="translationsLoaded" class="package-content__subtotal">
                              <div></div>
                              <div>
                                <span class="package-content__subtotal-text" data-translation="packages.total">
                                  {{ 'packages.total' | translate | hideTranslation }}
                                </span>
                                <span class="package-content__subtotal-price">
                                  {{ product.package.total.toFixed(2) | currencySymbolGap : exhibitionSettings.currencySettings }}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
        
        <hr />

        <app-info-block
          infoText="ticket-selection.anonymous-ticket-message"
          *ngIf="hasAnonymous"
        ></app-info-block>

        <div class="confirmation-total" *ngIf="!!('ticket-selection.total' | translate | hideTranslation)">
          <h3 class="total black" data-translation="ticket-selection.total">
            {{ 'ticket-selection.total' | translate | hideTranslation }}:
            <span class="price">{{ (totalPrice + totalWorkshopPrice).toFixed(2) | currencySymbolGap: exhibitionSettings.currencySettings}}</span>
          </h3>
        </div>

        <app-sending-options
          [ticketCounter]="ticketCounter"
          [exhibitionSettings]="exhibitionSettings"
        >
        </app-sending-options>
        <app-step-navigation-buttons></app-step-navigation-buttons>
      </div>
      <div *ngIf="isSelfRegistrationEnabled">
        <div
          class="ticket-info-text"
          data-translation="ticket.selfregistration.info-text"
          data-translation-includes-children
          [appRichText]="'ticket.selfregistration.info-text' | translate"
        ></div>
        <div class="row">
          <div class="col-md-5 border-right" *ngIf="sholdShowPromoCode$ | async">
            <h2 class="subtitle" data-translation="ticket.selfregistration.voucher.title">
              {{ 'ticket.selfregistration.voucher.title' | translate | hideTranslation }}
            </h2>
            <app-voucher
              type="selfRegistration"
              class="group-body"
              [maxTicketLimit]="maxTicketLimit"
              [numberOfAllTickets]=" numberOfSelectedTickets + numberOfSelectedVouchers + numberOfSelectedLimitedVouchers + numberOfSelectedOneTimeVouchers"
            ></app-voucher>
          </div>
          <div [ngClass]="{ 'col-md-7': sholdShowPromoCode$ | async, 'col-md-12': (sholdShowPromoCode$ | async) == false }">
            <h2 class="subtitle" data-translation="ticket.selfregistration.ticket.title">
              {{ 'ticket.selfregistration.ticket.title' | translate | hideTranslation }}
            </h2>
            <ng-container *ngFor="let selfRegistrationProductGroup of productGroups">
              <div appAdjustSideHeadline *ngIf="isTicketGroupVisible(selfRegistrationProductGroup)">
                <div class="group-headline">
                  <app-skeleton-loader *ngIf="!translationsLoaded"></app-skeleton-loader>
                  <h2 *ngIf="translationsLoaded" [ngClass]="{'ng-custom-invalid': !isTicketCounterValid}">
                    {{ selfRegistrationProductGroup.groupName | translate | hideTranslation }}
                  </h2>
                </div>

                <div class="group-body" *ngIf="ungroupedTickets">
                  <ng-container *ngFor="let product of selfRegistrationProductGroup.products">
                    <ng-container *ngIf="product.ticket">
                      <ng-template *ngTemplateOutlet="selfRegistrationTicketTemplate; context: { ticket: product.ticket }"></ng-template>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <app-step-navigation-buttons></app-step-navigation-buttons>
      </div>
    </div>
  </ng-container>
</div>

<!-- workshop detail for wide screens -->
<div
  *ngIf="toggledWorkshop"
  class="workshop-detail-window"
  [style.top]="workshopModalWindowYOffset + 'px'"
>
  <div class="workshop-details">
    <span class="workshop-name">
      {{ toggledWorkshop.workshopName | translate | hideTranslation }}
    </span>
    <span class="price" *ngIf="toggledWorkshop.price > 0  && !exhibitionSettings.hideWorkshopPrice">
      {{ toggledWorkshop.price.toFixed(2) | currencySymbolGap: exhibitionSettings.currencySettings }}
    </span>

    <div class="workshop-time-wrap">
      <span *ngIf="!hideDateAndTime" class="workshop-time">
        {{ toggledWorkshop.start | localizedDate: 'shortTime' }}
        - {{ toggledWorkshop.end | localizedDate: 'shortTime' }}
      </span>
      <span class="workshop-toggle" (click)="toggleDetail()">
        <button class="button desktop-close-button">
          <span data-translation="workshop.close">
            {{ 'workshop.close' | translate | hideTranslation }}
          </span>
        </button>
      </span>
    </div>
  </div>
  <div class="row m-auto">
    <div class="col-4" *ngIf="toggledWorkshop.imagePath">
      <img
        class="img-responsive schedule-image"
        (click)="toggleDetail(toggledWorkshop.workshopId)"
        [src]="origin + '/webapi' + toggledWorkshop.imagePath"
      />
    </div>
    <div class="col">
      <div class="schedule-text">
        <div class="schedule-text-inner">
          <span
            class="word-breakage"
            *ngIf="toggledWorkshop.description"
            [innerHTML]="toggledWorkshop.description | translate"
          ></span>
        </div>
        <a
          target="_blank"
          *ngIf="toggledWorkshop.pdfPath"
          href="{{ origin }}/webapi{{ toggledWorkshop.pdfPath }}"
          class="pdf-download"
          data-translation="toggledWorkshop.pdf"
        >
          {{ 'workshop.pdf' | translate | hideTranslation }}
        </a>
      </div>
    </div>
  </div>
  <app-workshop-visitors-count
    #visitorsCount
    [class.border-bottom]="!(toggledWorkshop && toggledWorkshop.seats && ticketsWithHolders | async)"
    (click)="toggleDetail(toggledWorkshop.workshopId)"
    [workshop]="toggledWorkshop"
    [ticketsWithHolders]="ticketsWithHolders"
    [showAmountOfAvailableWorkshops]="showAmountOfAvailableWorkshops"
  >
  </app-workshop-visitors-count>
  <app-workshops-visitors
    [class.border-bottom]="toggledWorkshop && toggledWorkshop.seats && ticketsWithHolders | async"
    *ngIf="toggledWorkshop && ticketsWithHolders && !toggledWorkshop.disabled"
    [workshop]="toggledWorkshop"
    [workshopsList]="workshops"
    [limitWorkshopPerTicket]="limitWorkshopPerTicket"
    [ticketsWithHolders]="ticketsWithHolders | async"
    [enableWorkshopSelectionOverlapping]="enableWorkshopSelectionOverlapping"
  >
  </app-workshops-visitors>
</div>
