import * as fromRoot from '../../app.reducer';
import { OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ErrorHandlingService } from '../../shared/error-handling/error-handling.service';
import { HttpClient } from '@angular/common/http';
import { Subscription, combineLatest } from 'rxjs';
import { StatusBarService } from '../../status-bar/status-bar.service';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { filter, first } from 'rxjs/operators';
import { CustomizationService } from '../../shared/services-with-reducers/customization/customization.service';
import { HelperService } from '../../shared/services-with-reducers/helpers/helper.service';
import * as customizationActions from '../../shared/services-with-reducers/customization/customization.actions';
var DataProtectionPageComponent = /** @class */ (function () {
    function DataProtectionPageComponent(_http, _errorHandlingService, _translateService, _store, _statusBarService, _activatedRoute, _customizationService, _helperService) {
        this._http = _http;
        this._errorHandlingService = _errorHandlingService;
        this._translateService = _translateService;
        this._store = _store;
        this._statusBarService = _statusBarService;
        this._activatedRoute = _activatedRoute;
        this._customizationService = _customizationService;
        this._helperService = _helperService;
        this._subscriptions = new Subscription();
        this.browserLanguage = '';
        this.currentLanguage = '';
        this.pageLanguage = '';
        this.userLanguage = '';
        this.htmlContent = '';
        this.activeTab = '';
        this.tabs = [
            { id: 'home', title: 'Events Overview Page', text: '' }
        ];
    }
    DataProtectionPageComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._subscriptions.add(this._store.pipe(select(fromRoot.getUserLanguage)).subscribe(function (userLanguage) {
            _this.userLanguage = userLanguage;
        }));
        this.browserLanguage = this._translateService.getBrowserLang();
        this._subscriptions.add(this._activatedRoute.params.subscribe(function (params) {
            _this.paramEventId = +params.id;
        }));
        this._subscriptions.add(this._activatedRoute.url.subscribe(function (url) {
            if (url[0].path == "translations") {
                _this.isAdmin$ = _this._store.pipe(select(fromRoot.isLoggedInAsAdmin));
                _this.isAdmin$.pipe(first()).subscribe(function (isAdmin) { return (_this.isAdmin = isAdmin); });
            }
            else {
                _this.isAdmin = false;
            }
        }));
        // We have to get the event settings because we don't have them while clicking on data-privacy link and opening a new window
        if (!!this.paramEventId) {
            this._store.dispatch(new customizationActions.GetExhibitionSettings(+this.paramEventId));
        }
        this._store.dispatch(new customizationActions.GetOperatorsSettings());
        this._subscriptions.add(this._translateService.onLangChange.subscribe(function () {
            _this.loadHTML();
        }));
        this._subscriptions.add(this._store
            .pipe(select(fromRoot.getSelectedExhibitionId), filter(function (eventId) { return !!eventId; }))
            .subscribe(function (eventId) {
            _this.eventId = eventId;
        }));
        this._subscriptions.add(this._store
            .pipe(select(fromRoot.getLanguage), filter(function (data) { return !!data; }))
            .subscribe(function () {
            _this.loadHTML();
        }));
    };
    DataProtectionPageComponent.prototype.ngOnDestroy = function () {
        this._subscriptions.unsubscribe();
    };
    DataProtectionPageComponent.prototype.loadHTML = function () {
        var lang = this.userLanguage || this.browserLanguage;
        if (!!this.paramEventId) {
            this.eventId = this.paramEventId;
        }
        // In case the active tab is 'home', we don't want to go back to event level 
        // data-protection because we were already redirected to operator level data-protection page.
        if (this.activeTab === 'home') {
            this.eventId = '';
        }
        if (lang !== this.currentLanguage && lang !== this.pageLanguage) {
            this.loadOperatorDataProtectionPage(lang, this.eventId);
        }
        else if (!!this.pageLanguage) {
            // We have to set the top-bar language to the already loaded one in case there is no data-protection page for the requested language
            this._helperService.setLanguage(this.pageLanguage);
        }
    };
    DataProtectionPageComponent.prototype.changeActiveTab = function (eventId) {
        if (!!eventId && this.activeTab != 'event') {
            this.tabs.pop();
            this.tabs.push({ id: 'event', title: 'Event', text: '' });
            this.activeTab = 'event';
        }
        else if (!eventId && this.activeTab != 'home') {
            this.tabs.pop();
            this.tabs.push({ id: 'home', title: 'Home', text: '' });
            this.activeTab = 'home';
        }
    };
    DataProtectionPageComponent.prototype.loadOperatorDataProtectionPage = function (lang, eventId, tryToLoadOperatorTranslation) {
        var _this = this;
        if (eventId === void 0) { eventId = ''; }
        if (tryToLoadOperatorTranslation === void 0) { tryToLoadOperatorTranslation = true; }
        this.currentLanguage = lang;
        this.changeActiveTab(eventId);
        combineLatest([
            this._customizationService.getOperatorSettings(),
            this._customizationService.getCustomSettings(eventId)
        ])
            .first()
            .subscribe(function (_a) {
            var operatorSettings = _a[0], eventSettings = _a[1];
            var eventOrOperatorLanguages = tryToLoadOperatorTranslation && !!eventId ? eventSettings.languages : operatorSettings.languages;
            var supportedLanguages = !_this.isAdmin ? eventOrOperatorLanguages : [lang];
            var languages = supportedLanguages.reduce(function (accu, curr) {
                return accu + ("&languages=" + curr);
            }, '');
            if (!_this.isAdmin) {
                _this._helperService.setSupportedLanguages(supportedLanguages);
            }
            _this._http
                .get("" + environment.protocol + environment.webApiUrl + "/pages/data-protection/data-protection" + eventId + "/" + lang + "?" + languages)
                .map(function (res) {
                try {
                    return res;
                }
                catch (err) {
                    _this._statusBarService.setStatus(err, 'error');
                }
            })
                .catch(function (e) {
                return _this._errorHandlingService.errorHandler(e);
            })
                .subscribe(function (resp) {
                var body = resp.body, pageLanguage = resp.pageLanguage, requestedLanguage = resp.requestedLanguage;
                // We have to check is the currentLanguage the same as the requestedLanguage sent to the API,
                // because we can get the default language response later because of the size of it and then set the page to the wrong API response
                if (_this.currentLanguage == requestedLanguage && _this.pageLanguage !== pageLanguage || !!_this.isAdmin) {
                    _this.pageLanguage = pageLanguage;
                    _this.htmlContent = body || "";
                    _this._helperService.setLanguage(pageLanguage);
                }
                // Admin needs to load data-protection page even if it is not defined. In case a regular user is on event level, event level data-protection loads.
                // In case the event data protection is empty load operator data protection for regular user and for Admin in case he came on data-protection via the link
                if (!_this.htmlContent && !_this.isAdmin && tryToLoadOperatorTranslation) {
                    _this.loadOperatorDataProtectionPage(lang, '', false);
                }
            });
        });
    };
    return DataProtectionPageComponent;
}());
export { DataProtectionPageComponent };
