import {
  merge as observableMerge,
  Observable,
  fromEvent,
  Subscription
} from 'rxjs';

import { tap, first, debounceTime } from 'rxjs/operators';
import * as fromRoot from '../app.reducer';

import { Component, OnInit } from '@angular/core';

import { AppService } from '../app.service';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-reset-button',
  templateUrl: './reset-button.component.html',
  styleUrls: ['./reset-button.component.scss']
})
export class ResetButtonComponent implements OnInit {
  public userActive: boolean = true;
  private _subscription: Subscription = new Subscription();

  constructor(
    private _store: Store<fromRoot.State>,
    private _appService: AppService
  ) {}

  ngOnInit() {
    const documentClicks = fromEvent(document, 'click');
    const documentKeypress = fromEvent(document, 'keypress');

    this._subscription.add(
      observableMerge(documentClicks, documentKeypress)
        .pipe(
          tap(event => {
            this.userActive = true;
          }),
          debounceTime(30000)
        )
        .subscribe(e => {
          this.userActive = false;
        })
    );
  }

  resetShop() {
    this._store
      .select(fromRoot.getSelectedExhibitionId)
      .pipe(first())
      .subscribe(exhibitionId => {
        this._appService.resetReducersWithUser().subscribe(() => {
          window.location.replace(`/self-registration/${exhibitionId}`);
        });
      });
  }
}
