/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./data-protection-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../app-shared/navigation-tabs/navigation-tabs.component.ngfactory";
import * as i3 from "../../../app-shared/navigation-tabs/navigation-tabs.component";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "@ngx-translate/core";
import * as i7 from "../../shared/pipes/html-injector/html-injector.pipe";
import * as i8 from "@angular/platform-browser";
import * as i9 from "./data-protection-page.component";
import * as i10 from "@angular/common/http";
import * as i11 from "../../shared/error-handling/error-handling.service";
import * as i12 from "@ngrx/store";
import * as i13 from "../../status-bar/status-bar.service";
import * as i14 from "../../shared/services-with-reducers/customization/customization.service";
import * as i15 from "../../shared/services-with-reducers/helpers/helper.service";
var styles_DataProtectionPageComponent = [i0.styles];
var RenderType_DataProtectionPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DataProtectionPageComponent, data: {} });
export { RenderType_DataProtectionPageComponent as RenderType_DataProtectionPageComponent };
function View_DataProtectionPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "navigation-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-navigation-tabs", [["class", "navigation-tabs"], ["type", "normal"]], null, null, null, i2.View_NavigationTabsComponent_0, i2.RenderType_NavigationTabsComponent)), i1.ɵdid(2, 49152, null, 0, i3.NavigationTabsComponent, [], { tabs: [0, "tabs"], type: [1, "type"], activeTab: [2, "activeTab"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 6, "h1", [["class", "navigation-edit"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "span", [["class", "edit"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "a", [["class", "button"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "span", [["data-translation", "page.data-protection.edit"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tabs; var currVal_1 = "normal"; var currVal_2 = _co.activeTab; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_5 = i1.ɵinlineInterpolate(1, "/admin/data-protection/", _co.activeTab, ""); _ck(_v, 6, 0, currVal_5); }, function (_ck, _v) { var currVal_3 = i1.ɵnov(_v, 6).target; var currVal_4 = i1.ɵnov(_v, 6).href; _ck(_v, 5, 0, currVal_3, currVal_4); var currVal_6 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("page.data-protection.edit")); _ck(_v, 8, 0, currVal_6); }); }
export function View_DataProtectionPageComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i7.HtmlInjectorPipe, [i8.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "container-fluid h-100-p"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "page-content h-100-p"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "page-wrapper h-100-p"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_DataProtectionPageComponent_1)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 1, "section", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(8, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.isAdmin$)); _ck(_v, 5, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v, 0), _co.htmlContent)); _ck(_v, 7, 0, currVal_1); }); }
export function View_DataProtectionPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-data-protection-page", [], null, null, null, View_DataProtectionPageComponent_0, RenderType_DataProtectionPageComponent)), i1.ɵdid(1, 245760, null, 0, i9.DataProtectionPageComponent, [i10.HttpClient, i11.ErrorHandlingService, i6.TranslateService, i12.Store, i13.StatusBarService, i4.ActivatedRoute, i14.CustomizationService, i15.HelperService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DataProtectionPageComponentNgFactory = i1.ɵccf("app-data-protection-page", i9.DataProtectionPageComponent, View_DataProtectionPageComponent_Host_0, {}, {}, []);
export { DataProtectionPageComponentNgFactory as DataProtectionPageComponentNgFactory };
