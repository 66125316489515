import { combineLatest as observableCombineLatest } from 'rxjs';

import { first, filter, debounceTime } from 'rxjs/operators';
import * as fromRoot from '../../app.reducer';
import * as stepsActions from '../../shared/services-with-reducers/step-forms/steps-forms.actions';

import * as ticketActions from '../../shared/services-with-reducers/tickets/ticket.actions';

import { Component, OnInit } from '@angular/core';
import {
  FormInputsPayloadModel,
  TicketHolderAdditionalDataModel,
  VisibilityPayloadModel
} from '../../shared/services-with-reducers/step-forms/step.interface';

import { AppService } from '../../app.service';
import { StepsFormsService } from '../../shared/services-with-reducers/step-forms/steps-forms.service';
import { Store } from '@ngrx/store';
import { TicketModel } from '../../shared/services-with-reducers/tickets/ticket.interface';
import { HelperService } from '../../shared/services-with-reducers/helpers/helper.service';

@Component({
  selector: 'app-web-shop-recipe',
  templateUrl: './webshop-recipe.component.html',
  styleUrls: ['./webshop-recipe.component.scss']
})
export class WebShopRecipeComponent implements OnInit {
  public loading = true;
  public price: number;
  public printed: boolean = null;
  public serverError = false;

  constructor(
    private _store: Store<fromRoot.State>,
    private _appService: AppService,
    private _stepsFormsService: StepsFormsService,
    private _helperService: HelperService
  ) {
    const ticketParams: string = this._helperService.getTicketParams();
    !!ticketParams && this.resetShop(true, ticketParams);
  }

  ngOnInit() {
    // remove the order response if there is an
    this._store.dispatch(new stepsActions.SetOrderResponse(null));
    this._store.dispatch(new stepsActions.setPaymentMethod("0"));

    observableCombineLatest(
      this._store.select(fromRoot.getTickets),
      this._store.select(fromRoot.getTicketHolderAdditionalData),
      this._store.select(fromRoot.getTicketHolderInputSets)
    )
      .pipe(
        filter(
          (
            data: [
              TicketModel,
              TicketHolderAdditionalDataModel,
              FormInputsPayloadModel[]
            ]
          ) => {
            return !!data[0] && !!data[1] && !!data[2];
          }
        ),
        debounceTime(500), // wait for late data in reducer (there is an issue when leaving page and google autocomplete is being filled)
        first()
      )
      .subscribe(data => {
        const [
          ungroupedTickets,
          ticketHolderAdditionalData,
          ticketHolderInputSets
        ] = data;

        const selectedTicketsKey = Object.keys(ungroupedTickets).find(
          ticketKey => {
            return !!ungroupedTickets[ticketKey].count;
          }
        );
        this.price = ungroupedTickets[selectedTicketsKey].price;

        const ungroupedTicketsWithHolders = this._stepsFormsService.assignTicketsToHoldersForSave(
          ungroupedTickets,
          ticketHolderInputSets,
          ticketHolderAdditionalData
        );

        this._stepsFormsService.prepareDataForSaveAndSend(
          ungroupedTicketsWithHolders,
          this.price
        );
      });

    observableCombineLatest(
      this._store.select(fromRoot.getOrderResponse),
      this._store.select(fromRoot.getBuyerInfo),
      this._store.select(fromRoot.getLanguage)
    )
      .pipe(
        filter(data => {
          return !!data[0] && !!data[1];
        }),
        first()
      )
      .subscribe(data => {
        const [orderResponse, buyer, language] = data;
        if (
          orderResponse.hasOwnProperty('error') &&
          orderResponse.error !== 'Ok!' &&
          !orderResponse.success
        ) {
          this.serverError = true;
        }
        this.loading = false;

        if (!this.serverError) {
          const paymentType = this.price ? 2 : 1;

          const cPrintData = {
            nType: paymentType, // 1 (1 = Ticket.. tickets cost zero, 2 = Order .. you need to pay)
            szFirstname: buyer.list.find(input => input.key === 'firstName')
              .value,
            szLastname: buyer.list.find(input => input.key === 'lastName')
              .value,
            szCode: orderResponse.szCode,
            nSerialNr: orderResponse.nSerialNr,
            nProjNr: orderResponse.nProjNr,
            nPosNr: orderResponse.nPosNr,
            szLang: language,
            szTicketUrl: orderResponse.selfRegistrationTicketDownloadUrl
          };

          const event = new MessageEvent('CouponPrint', {
            data: JSON.stringify(cPrintData)
          });
          document.dispatchEvent(event);
        }
      });

    const visibilityPayload: VisibilityPayloadModel[] = [
      'tickets',
      'personal',
      'workshop',
      'menu',
      'questionnaire'
    ].map(stepKey => {
      return {
        stepKey,
        visible: false
      };
    });

    this._store.dispatch(
      new stepsActions.SetMultipleStepsVisibility(visibilityPayload)
    );
  }

  resetShop(releaseAllVouchersAndTickets: boolean = true, ticketParams?: string) {
    this._store.dispatch(new ticketActions.RemoveTicketBookings());
    this._store
      .select(fromRoot.getSelectedExhibitionId)
      .pipe(first())
      .subscribe(exhibitionId => {
        this._appService
          .resetReducersWithUser(releaseAllVouchersAndTickets)
          .subscribe(() => {
            window.location.replace(`/self-registration/${exhibitionId}` + (!!ticketParams ? `/tickets${ticketParams}` : ''));
            if (
              !!(window as any).MSInputMethodContext &&
              !!(document as any).documentMode
            ) {
              window.localStorage.clear();
              window.sessionStorage.clear();
            }
          });
      });
  }
}
