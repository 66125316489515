import * as exhibitionActions from './exhibition.actions';

import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  DoubleClickModel,
  ExhibitionHistoryListModel,
  ExhibitionModel,
  SelectOption
} from './exhibition.interface';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Observable, of, EMPTY } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';

import { Action } from '@ngrx/store';
import { ExhibitionService } from './exhibition.service';
import { QuestionnaireDataInput } from '../customization/customization.interfaces';

export const EXHIBITION_DEBOUNCE = new InjectionToken<number>(
  'Exhibition Debounce'
);

@Injectable()
export class ExhibitionEffect {
  @Effect()
  loadAllExhibitionsList$: Observable<Action> = this.actions$.pipe(
    ofType<exhibitionActions.GetListOfAllExhibitions>(
      exhibitionActions.ActionTypes.GET_LIST_OF_ALL_EXHIBITIONS
    ),
    debounceTime(this.debounce),
    switchMap((data: any) => {
      const langCode = data.payload;

      return this.exhibitionService.getAllExhibitionsList(langCode).pipe(
        map((allExhibitionsList: ExhibitionModel[]) => {
          const ListOfExhibitions: ExhibitionModel[] = allExhibitionsList;

          function getUTCdate(date) {
            return new Date(
              date.getUTCFullYear(),
              date.getUTCMonth(),
              date.getUTCDate(),
              date.getUTCHours(),
              date.getUTCMinutes(),
              date.getUTCSeconds()
            );
          }

          // TODO remove once comming from clicks with time
          ListOfExhibitions.forEach(exhibition => {
            let endDate = <string>exhibition.endDate;

            if (
              navigator.userAgent.indexOf('MSIE') !== -1 ||
              navigator.appVersion.indexOf('Trident/') > 0
            ) {
              endDate = endDate.replace('00:00:00', '11:59:59'); // "Z" stands for UTC
              let endDateObj = new Date(endDate);
              exhibition.endDate = endDateObj;
            } else {
              endDate = endDate.replace('00:00:00', '11:59:59Z'); // "Z" stands for UTC
              let endDateObj = new Date(endDate);
              exhibition.endDate = endDateObj;
            }

            let startDate = <string>exhibition.startDate;

            if (
              navigator.userAgent.indexOf('MSIE') !== -1 ||
              navigator.appVersion.indexOf('Trident/') > 0
            ) {
              startDate = startDate.replace('00:00:00', '12:00:00');

              let startDateObj = new Date(startDate);
              //console.log(startDateObj);
              exhibition.startDate = startDateObj;
            } else {
              startDate = startDate.replace('00:00:00', '12:00:00Z');

              let startDateObj = new Date(startDate);
              exhibition.startDate = startDateObj;
            }
          });

          return new exhibitionActions.AddAllExhibitionsToListAction(
            ListOfExhibitions
          );
        }),
        catchError(error => {
          console.log(error);
          return of(new exhibitionActions.AddAllExhibitionsToListAction([]));
        })
      );
    })
  );

  @Effect()
  loadExhibitionHistoryList$: Observable<Action> = this.actions$.pipe(
    ofType<exhibitionActions.GetExhibitionHistoryList>(
      exhibitionActions.ActionTypes.GET_EXHIBITION_HISTORY_LIST
    ),
    debounceTime(this.debounce),
    switchMap((data: any) => {
      const userId = data.payload;
      if (!userId) {
        return EMPTY;
      }

      return this.exhibitionService.getExhibitionHistoryList().pipe(
        map(
          (exhibitionHistoryList: ExhibitionHistoryListModel[]) =>
            new exhibitionActions.SetExhibitionHistoryList(
              exhibitionHistoryList
            )
        ),
        catchError(error => {
          console.log(error);
          return of(new exhibitionActions.SetExhibitionHistoryList([]));
        })
      );
    })
  );

  @Effect()
  getDoubleClickScripts$: Observable<Action> = this.actions$.pipe(
    ofType<exhibitionActions.GetDoubleClickScripts>(
      exhibitionActions.ActionTypes.GET_DOUBLE_CLICK_SCRIPTS
    ),
    debounceTime(this.debounce),
    switchMap((data: any) => {
      const eventId = data.payload;
      if (eventId === null) {
        return EMPTY;
      }

      return this.exhibitionService.getDoubleClickScripts(eventId).pipe(
        map((scripts: DoubleClickModel) => {
          // transform JSON result to object with keys as ticketGroup Ids and values as ticketSales
          return new exhibitionActions.SetDoubleClickScripts(scripts);
        }),
        catchError(error => {
          console.log(error);
          return of(new exhibitionActions.SetDoubleClickScripts(null));
        })
      );
    })
  );

  @Effect()
  getGenericScripts$: Observable<Action> = this.actions$.pipe(
    ofType<exhibitionActions.GetGenericScripts>(
      exhibitionActions.ActionTypes.GET_GENERIC_SCRIPTS
    ),
    debounceTime(this.debounce),
    switchMap((data: any) => {
      const eventId = data.payload;
      if (eventId === null) {
        return EMPTY;
      }

      return this.exhibitionService.getGenericScripts(eventId).pipe(
        map((scripts: DoubleClickModel) => {
          // transform JSON result to object with keys as ticketGroup Ids and values as ticketSales
          return new exhibitionActions.SetGenericScripts(scripts);
        }),
        catchError(error => {
          console.log(error);
          return of(new exhibitionActions.SetGenericScripts(null));
        })
      );
    })
  );

  @Effect()
  getProfessions$: Observable<Action> = this.actions$.pipe(
    ofType<exhibitionActions.GetProfessions>(
      exhibitionActions.ActionTypes.GET_PROFESSIONS
    ),
    debounceTime(this.debounce),
    switchMap(eventId => {
      if (eventId === null) {
        return EMPTY;
      }

      return this.exhibitionService
        .getProfessions(eventId.payload)
        .map((selectOptions: QuestionnaireDataInput[]) => {
          const professionsOptions: SelectOption[] = selectOptions
            .slice(0)
            .sort((a, b) => a.order - b.order)
            .map(
              (option): SelectOption => {
                return {
                  key: option.id,
                  value: option.text,
                  translate: true
                };
              }
            );
          return new exhibitionActions.SetProfessions(professionsOptions);
        })
        .catch(error => {
          console.log(error);
          return of(new exhibitionActions.SetProfessions(null));
        });
    })
  );

  @Effect()
  getDepartments$: Observable<Action> = this.actions$.pipe(
    ofType<exhibitionActions.GetDepartments>(
      exhibitionActions.ActionTypes.GET_DEPARTMENTS
    ),
    debounceTime(this.debounce),
    switchMap(eventId => {
      if (eventId === null) {
        return EMPTY;
      }

      return this.exhibitionService
        .getDepartments(eventId.payload)
        .map((selectOptions: QuestionnaireDataInput[]) => {
          const departmentOptions: SelectOption[] = selectOptions
            .slice(0)
            .sort((a, b) => a.order - b.order)
            .map(
              (option): SelectOption => {
                return {
                  key: option.id,
                  value: option.text,
                  translate: true
                };
              }
            );
          return new exhibitionActions.SetDepartments(departmentOptions);
        })
        .catch(error => {
          console.log(error);
          return of(new exhibitionActions.SetDepartments(null));
        });
    })
  );

  @Effect()
  getOccupationalGroups$: Observable<Action> = this.actions$.pipe(
    ofType<exhibitionActions.GetOccupationalGroups>(
      exhibitionActions.ActionTypes.GET_OCCUPATIONAL_GROUPS
    ),
    debounceTime(this.debounce),
    switchMap(eventId => {
      if (eventId === null) {
        return EMPTY;
      }

      return this.exhibitionService
        .getOccupationalGroups(eventId.payload)
        .map((selectOptions: QuestionnaireDataInput[]) => {
          const occupationalGroupsOptions: SelectOption[] = selectOptions
            .slice(0)
            .sort((a, b) => a.order - b.order)
            .map(
              (option): SelectOption => {
                return {
                  key: option.id,
                  value: option.text,
                  translate: true
                };
              }
            );
          return new exhibitionActions.SetOccupationalGroups(occupationalGroupsOptions);
        })
        .catch(error => {
          console.log(error);
          return of(new exhibitionActions.SetOccupationalGroups(null));
        });
    })
  );

  @Effect()
  getTitles$: Observable<Action> = this.actions$.pipe(
    ofType<exhibitionActions.GetTitles>(
      exhibitionActions.ActionTypes.GET_TITLES
    ),
    debounceTime(this.debounce),
    switchMap(eventId => {
      if (eventId === null) {
        return EMPTY;
      }

      return this.exhibitionService
        .getTitles(eventId.payload)
        .map((selectOptions: QuestionnaireDataInput[]) => {
          const titleOptions: SelectOption[] = selectOptions
            .slice(0)
            .sort((a, b) => a.order - b.order)
            .map(
              (option): SelectOption => {
                return {
                  key: option.id,
                  value: option.text,
                  translate: true
                };
              }
            );
            
          return new exhibitionActions.SetTitles(titleOptions);
        })
        .catch(error => {
          console.log(error);
          return of(new exhibitionActions.SetTitles(null));
        });
    })
  );

  @Effect()
  getAllProfessions$: Observable<Action> = this.actions$.pipe(
    ofType<exhibitionActions.GetAllProfessions>(
      exhibitionActions.ActionTypes.GET_ALL_PROFESSIONS
    ),
    debounceTime(this.debounce),
    switchMap(() => {
      return this.exhibitionService
        .getAllProfessions()
        .map((selectOptions: QuestionnaireDataInput[]) => {
          const professionsOptions = selectOptions
            .slice(0)
            .sort((a, b) => a.order - b.order);

          // transform JSON result to object with keys as ticketGroup Ids and values as ticketSales
          return new exhibitionActions.SetAllProfessions(professionsOptions);
        })
        .catch(error => {
          console.log(error);
          return of(new exhibitionActions.SetAllProfessions(null));
        });
    })
  );

  @Effect()
  getAllDepartments$: Observable<Action> = this.actions$.pipe(
    ofType<exhibitionActions.GetAllDepartments>(
      exhibitionActions.ActionTypes.GET_ALL_DEPARTMENTS
    ),
    debounceTime(this.debounce),
    switchMap(() => {
      return this.exhibitionService
        .getAllDepartments()
        .map((selectOptions: QuestionnaireDataInput[]) => {
          const departmentsOptions = selectOptions
            .slice(0)
            .sort((a, b) => a.order - b.order);

          // transform JSON result to object with keys as ticketGroup Ids and values as ticketSales
          return new exhibitionActions.SetAllDepartments(departmentsOptions);
        })
        .catch(error => {
          console.log(error);
          return of(new exhibitionActions.SetAllDepartments(null));
        });
    })
  );
  
  @Effect()
  getAllOccupationalGroups$: Observable<Action> = this.actions$.pipe(
    ofType<exhibitionActions.GetAllOccupationalGroups>(
      exhibitionActions.ActionTypes.GET_ALL_OCCUPATIONAL_GROUPS
    ),
    debounceTime(this.debounce),
    switchMap(() => {
      return this.exhibitionService
        .getAllOccupationalGroups()
        .map((selectOptions: QuestionnaireDataInput[]) => {
          const occupationalGroupsOptions = selectOptions
            .slice(0)
            .sort((a, b) => a.order - b.order);

          // transform JSON result to object with keys as ticketGroup Ids and values as ticketSales
          return new exhibitionActions.SetAllOccupationalGroups(occupationalGroupsOptions);
        })
        .catch(error => {
          console.log(error);
          return of(new exhibitionActions.SetAllOccupationalGroups(null));
        });
    })
  );

  @Effect()
  getAllTitles$: Observable<Action> = this.actions$.pipe(
    ofType<exhibitionActions.GetAllTitles>(
      exhibitionActions.ActionTypes.GET_ALL_TITLES
    ),
    debounceTime(this.debounce),
    switchMap(() => {
      return this.exhibitionService
        .getAllTitles()
        .map((selectOptions: QuestionnaireDataInput[]) => {
          const allTitlesOptions = selectOptions
            .slice(0)
            .sort((a, b) => a.order - b.order);

          return new exhibitionActions.SetAllTitles(allTitlesOptions);
        })
        .catch(error => {
          console.log(error);
          return of(new exhibitionActions.SetAllTitles(null));
        });
    })
  );

  constructor(
    private actions$: Actions,
    private exhibitionService: ExhibitionService,
    @Optional()
    @Inject(EXHIBITION_DEBOUNCE)
    private debounce: number = 50
  ) {}
}
