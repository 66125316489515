<div *ngIf="ticketSendingOptions && showSendingOptions" appAdjustSideHeadline>
  <div class="section-headline section-headline-no-border">
    <h2
      data-translation="ticket-selection.sending-options.title"
      class="ticket-selection-header-text"
    >
      {{
        'ticket-selection.sending-options.title' | translate | hideTranslation
      }}
    </h2>
  </div>
  <div class="section-body min-height">
    <div class="radio-button-row">
      <div
        class="ticket-send-option black"
        *ngFor="let option of ticketSendingOptions"
      >
        <label
          class="decorated-radio-button"
          *ngIf="option.isVisible && option.isEnabled && option.isBasic"
        >
          <input
            type="radio"
            name="ticket-sending-option"
            [(ngModel)]="selectedSendingOption"
            [value]="option.value"
            (change)="sendingOptionChanged()"
          />
          <span class="radio-button"></span>
          <svg class="sending-option-icon">
            <use
              [attr.xlink:href]="'assets/defs.svg#icon-' + option.value"
            ></use>
          </svg>
          <span
            class="radio-label"
            [attr.data-translation]="
              'ticket-selection.sending-options.' + [option.value] | lowercase
            "
          >
            {{
              'ticket-selection.sending-options.' + [option.value]
                | lowercase
                | translate
            }}</span
          >
        </label>
      </div>
    </div>
    <app-info-block
      *ngIf="
        selectedSendingOption === 'normalPerOwner' &&
        !('ticket-selection.info-box.normal' | translate | isHiddenTranslation)
      "
      [infoText]="'ticket-selection.info-box.normal'"
      [translateVariables]="{ ticketType: sendingOptionTicketName }"
    ></app-info-block>
    <app-info-block
      *ngIf="
        selectedSendingOption === 'mobilePerOwner' &&
        !('ticket-selection.info-box.mobile' | translate | isHiddenTranslation)
      "
      [infoText]="'ticket-selection.info-box.mobile'"
      [translateVariables]="{ ticketType: sendingOptionTicketName }"
    ></app-info-block>
  </div>
</div>