import { Component, HostListener, Input } from '@angular/core';

import { HelperService } from '../shared/services-with-reducers/helpers/helper.service';
import { WindowSizeService } from '../shared/window-size/window-size.service';

@Component({
  moduleId: module.id,
  selector: 'app-feedback-message',
  templateUrl: './feedback-message.component.html',
  styleUrls: ['./feedback-message.component.scss']
})
export class FeedbackMessageComponent {
  @Input() feedbackMessages: Array<any>;
}
