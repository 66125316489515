import { Inject, Injectable } from '@angular/core';

import { AppConstants } from '../app-constants';
import { DOCUMENT } from '@angular/platform-browser';
import { HelperService } from '../services-with-reducers/helpers/helper.service';

@Injectable({
  providedIn: 'root'
})
export class WindowSizeService {
  private devices = {
    xs: [0, AppConstants.BREAKPOINTS.sm],
    sm: [AppConstants.BREAKPOINTS.sm, AppConstants.BREAKPOINTS.md],
    md: [AppConstants.BREAKPOINTS.md, AppConstants.BREAKPOINTS.lg],
    lg: [AppConstants.BREAKPOINTS.lg, 10000]
  };

  constructor(private _helperService: HelperService) {}

  viewportWidth() {
    // in case we are in angular universal
    if (typeof window !== 'undefined') {
      let e: Object = window;
      let a = 'inner';

      if (!window.innerWidth) {
        a = 'client';
        e = document.documentElement || document.body;
      }

      return e[a + 'Width'];
    } else {
      return '100%';
    }
  }

  viewportHeight() {
    // in case we are in angular universal
    if (typeof window !== 'undefined') {
      let e: Object = window;
      let a = 'inner';

      if (!window.innerHeight) {
        a = 'client';
        e = document.documentElement || document.body;
      }

      return e[a + 'Height'];
    } else {
      return '100%';
    }
  }

  deviceSize() {
    const devices = {};
    const clientWidth = this.viewportWidth();

    for (const device in this.devices) {
      if (
        this.devices[device][0] <= clientWidth &&
        this.devices[device][1] > clientWidth
      ) {
        devices[device] = true;
      } else {
        devices[device] = false;
      }
    }

    return devices;
  }

  currentSize() {
    let size;
    const devices = this.deviceSize();

    for (const device in devices) {
      if (devices[device] === true) {
        size = device;
      }
    }

    return size;
  }

  // animationTime in seconds
  scrollToElement(element, offsetTop, offsetBottom, animationTime) {
    const frameRate = 200; // 100 frames per second
    const timePerFrame = 1000 / frameRate;

    // scroll up if the element is above the top fold
    if (element.getBoundingClientRect().top < offsetTop) {
      let yScroll = window.pageYOffset;
      const minimumFrameDistance = 10;
      const initialDistance = element.getBoundingClientRect().top + offsetTop;
      let frameDistance = initialDistance / (frameRate * animationTime);
      if (Math.abs(frameDistance) <= minimumFrameDistance) {
        frameDistance = minimumFrameDistance;
      }

      const interval = setInterval(() => {
        yScroll -= Math.abs(frameDistance);
        window.scroll(0, yScroll);

        // stop scrolling once top edge of element is in screen.. or once you try to scroll out of screen
        if (
          element.getBoundingClientRect().top >= offsetTop ||
          window.pageYOffset <= 0
        ) {
          clearInterval(interval);
        }
      }, timePerFrame);
      // scroll down if the elemnent is below the bottom fold
    } else if (
      element.getBoundingClientRect().bottom >
      this.viewportHeight() - offsetBottom
    ) {
      let yScroll = window.pageYOffset;

      const initialDistance =
        element.getBoundingClientRect().bottom - offsetBottom;

      const frameDistance = initialDistance / (frameRate * animationTime);

      const appScrollEl = (this._helperService.appEl.querySelector(
        '.app-page'
      ) as any) as HTMLElement;

      const interval = setInterval(() => {
        yScroll += Math.abs(frameDistance);
        window.scroll(0, yScroll);
        // stop scrolling once the bottom edge (+ bottom offset) of the element is
        // scrolled into view.. or once you try to scroll out of screen
        if (
          element.getBoundingClientRect().bottom <=
            this.viewportHeight() - offsetBottom ||
          window.innerHeight + window.pageYOffset >= appScrollEl.offsetHeight
        ) {
          clearInterval(interval);
        }
      }, timePerFrame);
    }
  }
}
