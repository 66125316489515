import { TranslateService } from '@ngx-translate/core';
import { select, Store } from '@ngrx/store';
import { filter, first } from 'rxjs/operators';
import * as fromRoot from '../../app.reducer';
import { combineLatest } from 'rxjs';
import cloneDeep from 'lodash.clonedeep';
import * as ticketActions from '../../shared/services-with-reducers/tickets/ticket.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@ngrx/store";
var EventName;
(function (EventName) {
    EventName["AddToCart"] = "addToCart";
    EventName["RemoveFromCart"] = "removeFromCart";
    EventName["ProductDetail"] = "productDetail";
    EventName["Checkout"] = "checkout";
    EventName["BeginCheckout"] = "begin_checkout";
    EventName["CheckoutOption"] = "checkoutOption";
    EventName["Purchase"] = "purchase";
})(EventName || (EventName = {}));
var GtmService = /** @class */ (function () {
    function GtmService(_translateService, _store) {
        this._translateService = _translateService;
        this._store = _store;
        this.uaPrefix = 'eec';
        this.ga4Prefix = 'eecga4';
    }
    GtmService.prototype.pushProductDetail = function () {
        var dataUA = {
            event: this.uaPrefix + "." + EventName.ProductDetail,
            ecommerce: {
                detail: {
                    actionField: {
                        list: 'Ticketauswahl'
                    }
                }
            }
        };
        this.pushToDataLayer(dataUA);
        var dataGA4 = {
            event: this.ga4Prefix + "." + EventName.ProductDetail,
            ecommerce: {
                item_list_name: 'Ticketauswahl',
                action: 'detail'
            }
        };
        this.pushToDataLayer(dataGA4);
    };
    GtmService.prototype.pushAddToCart = function (ticket, quantity) {
        var _this = this;
        this._store
            .pipe(select(fromRoot.getExhibitionSettings), filter(function (data) { return !!data; }), first())
            .subscribe(function (settings) {
            var dataUA = {
                event: _this.uaPrefix + "." + EventName.AddToCart,
                ecommerce: {
                    add: {
                        actionField: {
                            list: 'Shopping cart'
                        },
                        products: _this.ticketToProducts(settings.eventId, ticket, quantity)
                    }
                }
            };
            _this.pushToDataLayer(dataUA);
            var dataGA4 = {
                event: _this.ga4Prefix + "." + EventName.AddToCart,
                ecommerce: {
                    item_list_name: 'Shopping cart',
                    items: _this.ticketToItems(settings.eventId, ticket, quantity)
                }
            };
            _this.pushToDataLayer(dataGA4);
        });
    };
    GtmService.prototype.pushRemoveFromCart = function (ticket, quantity) {
        var _this = this;
        this._store
            .pipe(select(fromRoot.getExhibitionSettings), filter(function (data) { return !!data; }), first())
            .subscribe(function (settings) {
            var dataUA = {
                event: _this.uaPrefix + "." + EventName.RemoveFromCart,
                ecommerce: {
                    remove: {
                        actionField: {
                            list: 'Shopping cart'
                        },
                        products: _this.ticketToProducts(settings.eventId, ticket, quantity)
                    }
                }
            };
            _this.pushToDataLayer(dataUA);
            var dataGA4 = {
                event: _this.ga4Prefix + "." + EventName.RemoveFromCart,
                ecommerce: {
                    item_list_name: 'Shopping cart',
                    items: _this.ticketToItems(settings.eventId, ticket, quantity)
                }
            };
            _this.pushToDataLayer(dataGA4);
        });
    };
    GtmService.prototype.pushCheckout = function (tickets) {
        var _this = this;
        this._store
            .pipe(select(fromRoot.getExhibitionSettings), filter(function (data) { return !!data; }), first())
            .subscribe(function (settings) {
            var dataUA = {
                event: _this.uaPrefix + "." + EventName.Checkout,
                ecommerce: {
                    checkout: {
                        actionField: {
                            step: 1
                        },
                        products: _this.ticketsToProducts(settings.eventId, tickets)
                    }
                }
            };
            _this.pushToDataLayer(dataUA);
            var dataGA4 = {
                event: _this.ga4Prefix + "." + EventName.BeginCheckout,
                ecommerce: {
                    step: 1,
                    items: _this.ticketsToItems(settings.eventId, tickets)
                }
            };
            _this.pushToDataLayer(dataGA4);
        });
    };
    GtmService.prototype.pushPersonalization = function () {
        var dataUA = {
            event: this.uaPrefix + "." + EventName.Checkout,
            ecommerce: {
                checkout: {
                    actionField: {
                        step: 2
                    }
                }
            }
        };
        this.pushToDataLayer(dataUA);
        var dataGA4 = {
            event: this.ga4Prefix + "." + EventName.BeginCheckout,
            ecommerce: {
                step: 2
            }
        };
        this.pushToDataLayer(dataGA4);
    };
    GtmService.prototype.pushConfirmation = function () {
        var _this = this;
        combineLatest([
            this._store.pipe(select(fromRoot.getExhibitionSettings)),
            this._store.pipe(select(fromRoot.getTickets)),
            this._store.pipe(select(fromRoot.getOrderUuid))
        ])
            .pipe(filter(function (data) { return !!data[0] && !!data[1] && !!data[2]; }), first())
            .subscribe(function (data) {
            var exhibitionSettings = data[0], tickets = data[1], orderUuid = data[2];
            var ticketsWithCount = _this.getTicketsWithCount(tickets);
            var products = _this.ticketsToProducts(exhibitionSettings.eventId, ticketsWithCount);
            var revenue = products.reduce(function (revenue, product) { return revenue + product.price * 100 * product.quantity; }, 0) / 100;
            var dataUA = {
                event: _this.uaPrefix + "." + EventName.Checkout,
                ecommerce: {
                    currencyCode: exhibitionSettings.currencySettings.currencyCode,
                    checkout: {
                        actionField: {
                            id: orderUuid,
                            revenue: revenue,
                            step: 3
                        },
                        products: products
                    }
                }
            };
            _this.pushToDataLayer(dataUA);
            var dataGA4 = {
                event: _this.ga4Prefix + "." + EventName.BeginCheckout,
                ecommerce: {
                    transaction_id: orderUuid,
                    value: revenue,
                    currency: exhibitionSettings.currencySettings.currencyCode,
                    step: 3,
                    items: _this.ticketsToItems(exhibitionSettings.eventId, ticketsWithCount)
                }
            };
            _this.pushToDataLayer(dataGA4);
        });
    };
    GtmService.prototype.pushCheckoutOption = function (paymentOption) {
        var dataUA = {
            event: this.uaPrefix + "." + EventName.CheckoutOption,
            ecommerce: {
                checkout_option: {
                    actionField: {
                        step: 3,
                        option: paymentOption
                    }
                }
            }
        };
        this.pushToDataLayer(dataUA);
        var dataGA4 = {
            event: this.ga4Prefix + "." + EventName.CheckoutOption,
            ecommerce: {
                step: 3,
                action: 'checkout_option',
                option: paymentOption
            }
        };
        this.pushToDataLayer(dataGA4);
    };
    GtmService.prototype.pushPurchase = function () {
        var _this = this;
        combineLatest([
            this._store.pipe(select(fromRoot.getExhibitionSettings)),
            this._store.pipe(select(fromRoot.getTickets)),
            this._store.pipe(select(fromRoot.getOrderUuid))
        ])
            .pipe(filter(function (data) { return !!data[0] && !!data[1] && !!data[2]; }), first())
            .subscribe(function (data) {
            var exhibitionSettings = data[0], tickets = data[1], orderUuid = data[2];
            var ticketsWithCount = _this.getTicketsWithCount(tickets);
            var products = _this.ticketsToProducts(exhibitionSettings.eventId, ticketsWithCount);
            var revenue = products.reduce(function (revenue, product) { return revenue + product.price * 100 * product.quantity; }, 0) / 100;
            var dataUA = {
                event: _this.uaPrefix + "." + EventName.Purchase,
                ecommerce: {
                    currencyCode: exhibitionSettings.currencySettings.currencyCode,
                    checkout: {
                        actionField: {
                            id: orderUuid,
                            revenue: revenue
                        },
                        products: products
                    }
                }
            };
            _this.pushToDataLayer(dataUA);
            var dataGA4 = {
                event: _this.ga4Prefix + "." + EventName.Purchase,
                ecommerce: {
                    transaction_id: orderUuid,
                    value: revenue,
                    currency: exhibitionSettings.currencySettings.currencyCode,
                    items: _this.ticketsToItems(exhibitionSettings.eventId, ticketsWithCount)
                }
            };
            _this.pushToDataLayer(dataGA4);
            // if we get to this page payment was successfull and we remove the vouchers
            _this._store
                .pipe(select(fromRoot.getVoucherTickets), filter(function (data) { return !!data; }), first())
                .subscribe(function (voucher) {
                voucher.map(function (item) {
                    _this._store.dispatch(new ticketActions.RemoveVoucher({ voucherCode: item.voucherCode, skipAnonymousChecks: false, skipSettingIsAnonyTicketTaken: true }));
                });
            });
        });
    };
    GtmService.prototype.ticketToProducts = function (eventId, ticket, quantity) {
        ticket = cloneDeep(ticket);
        ticket.count = quantity;
        return this.ticketsToProducts(eventId, [ticket]);
    };
    GtmService.prototype.ticketsToProducts = function (eventId, tickets) {
        var _this = this;
        return tickets.map(function (ticket) {
            var name = _this._translateService.instant(ticket.name);
            var category = ticket.groupName ? _this._translateService.instant(ticket.groupName) : '';
            return {
                id: ticket.id,
                name: name,
                category: category,
                quantity: ticket.count,
                brand: eventId,
                coupon: ticket.voucherCode,
                price: ticket.price
            };
        });
    };
    GtmService.prototype.ticketToItems = function (eventId, ticket, quantity) {
        ticket = cloneDeep(ticket);
        ticket.count = quantity;
        return this.ticketsToItems(eventId, [ticket]);
    };
    GtmService.prototype.ticketsToItems = function (eventId, tickets) {
        var _this = this;
        return tickets.map(function (ticket) {
            var name = _this._translateService.instant(ticket.name);
            var category = ticket.groupName ? _this._translateService.instant(ticket.groupName) : '';
            return {
                item_id: ticket.id,
                item_name: name,
                item_category: category,
                quantity: ticket.count,
                item_brand: eventId,
                coupon: ticket.voucherCode,
                price: ticket.price
            };
        });
    };
    GtmService.prototype.getTicketsWithCount = function (tickets) {
        var ticketsWithCount = [];
        for (var uniqueId in tickets) {
            var ticket = tickets[uniqueId];
            if (ticket.count > 0) {
                ticketsWithCount.push(ticket);
            }
        }
        return ticketsWithCount;
    };
    GtmService.prototype.pushToDataLayer = function (data) {
        window['dataLayer'] = window['dataLayer'] || [];
        //clearing the ecommerce object
        //it's recommended that you use the following command to clear the ecommerce object prior to pushing an ecommerce event to the data layer. Clearing the object will prevent multiple ecommerce events on a page from affecting each other:
        window['dataLayer'].push({ ecommerce: null });
        //push new data:
        window['dataLayer'].push(data);
    };
    GtmService.ngInjectableDef = i0.defineInjectable({ factory: function GtmService_Factory() { return new GtmService(i0.inject(i1.TranslateService), i0.inject(i2.Store)); }, token: GtmService, providedIn: "root" });
    return GtmService;
}());
export { GtmService };
