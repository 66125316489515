import {
  SendingOptionModel
} from '../tickets/ticket.interface';
import { Actions, ActionTypes } from './ticket.actions';
import {
  AddTicketBookingModel, ProductGroupModel, TicketBookingModel,
  TicketByIdModel, TicketHolderIndexes, TicketHolderModel,
  TicketModel,
  TicketVoucherModel
} from './ticket.interface';

import { createSelector } from 'reselect';
import { InputBase } from '../../forms/inputs/input-base.class';
import _ from 'lodash';
import cloneDeep from 'lodash.clonedeep';

export interface State {
  ticketHolder: TicketHolderModel | null;
  ticketHolderInputs: InputBase<any>[];
  ticketHolderQuestionnaireInputs: InputBase<any>[];
  claimedTicketHash: string;
  claimedTicketHashValid: boolean;
  tickets: TicketModel | null;
  parkingTickets: Object | null;
  ticketHolderSubmitResult: boolean;
  ticketTypes: ProductGroupModel[];
  initialTicketTypes: ProductGroupModel[];
  ticketSendingOptions: SendingOptionModel[];
  activeHolderSlideIndex: number;
  lastVoucherTicket: TicketByIdModel;
  ticketBookings: TicketBookingModel;
  lastPackageIndex: number;
  isPackageLoading: boolean;
  ticketHolderIndexes: TicketHolderIndexes;
  isAnonymousTicketTaken: boolean;
  areTicketsSent: boolean;
}

export const initialState: State = {
  ticketHolder: null,
  ticketHolderInputs: [],
  ticketHolderQuestionnaireInputs: [],
  claimedTicketHash: '',
  claimedTicketHashValid: null, // this must be null in the beginnig as we cannot say if it is valid or not
  tickets: null,
  parkingTickets: null,
  ticketHolderSubmitResult: false,
  ticketTypes: null,
  initialTicketTypes: null,
  ticketSendingOptions: [],
  activeHolderSlideIndex: 0,
  lastVoucherTicket: null,
  ticketBookings: { bookings: [], timestamp: null },
  lastPackageIndex: null,
  isPackageLoading: false,
  ticketHolderIndexes: null,
  isAnonymousTicketTaken: false,
  areTicketsSent: false
};

export function reducer(state = initialState, action: Actions): State {
  const clonedInitialState: State = cloneDeep(initialState);

  switch (action.type) {
    case ActionTypes.SET_SELECTED_TICKETS:
      const tickets = action.payload;
      return {
        ...state,
        tickets: tickets
      };

    case ActionTypes.SET_SELECTED_PARKING_TICKETS:
      const parkingTickets = action.payload;
      return {
        ...state,
        parkingTickets
      };

    case ActionTypes.ADD_VOUCHER_TICKET:
      if (action.payload) {
        const voucherTicket: TicketVoucherModel = action.payload.voucherTicket;

        if (voucherTicket) {
          const uniqueId = `${voucherTicket.uniqueId}_${voucherTicket.voucherCode}`;

          const allTickets = state.tickets;
          let ticketWithVoucher: TicketByIdModel = cloneDeep(
            allTickets[voucherTicket.uniqueId]
          );
          ticketWithVoucher.uniqueId = uniqueId;
          ticketWithVoucher.voucherCode = voucherTicket.voucherCode;
          ticketWithVoucher.voucherType = voucherTicket.voucherType;
          ticketWithVoucher.sponsors = voucherTicket.sponsors;
          ticketWithVoucher.releasedInMinutes = voucherTicket.releasedInMinutes;
          /*Commented out because availableTickets in allTickets is used as a connection between ticket
          limit and consumed tickets for that particular voucher, but API returns (available - ticket count)*/
          //ticketWithVoucher.availableTickets = voucherTicket.availableTickets;
          ticketWithVoucher.activatedTimestamp =
            voucherTicket.activatedTimestamp;
          ticketWithVoucher.allowedWorkshopsFull =
            voucherTicket.allowedWorkshopsFull;
          ticketWithVoucher.quantity = voucherTicket.quantity;
          allTickets[uniqueId] = ticketWithVoucher;

          const productGroups = state.ticketTypes;
          const productGroupsWithVoucherIndex = Object.keys(productGroups).find(productGroupIndex => {
            const productGroup: ProductGroupModel = productGroups[productGroupIndex];
            const productGroupProducts = productGroup.products;
            const voucherTicketProductIndex = productGroupProducts.findIndex(product => {
              if (product.ticket != null) {
                return product.ticket.uniqueId == voucherTicket.uniqueId
              }
            });

            if (voucherTicketProductIndex != -1) {
              const product: any = cloneDeep(productGroupProducts[voucherTicketProductIndex]);
              const newVoucherTicket: TicketVoucherModel = cloneDeep(product.ticket);
              newVoucherTicket.uniqueId = uniqueId;
              newVoucherTicket.voucherCode = voucherTicket.voucherCode;
              newVoucherTicket.voucherType = voucherTicket.voucherType;
              newVoucherTicket.sponsors = voucherTicket.sponsors;
              newVoucherTicket.releasedInMinutes = voucherTicket.releasedInMinutes;
              newVoucherTicket.availableTickets = voucherTicket.availableTickets;
              newVoucherTicket.allowedWorkshopsFull = voucherTicket.allowedWorkshopsFull;
              newVoucherTicket.quantity = voucherTicket.quantity;
              product.ticket = newVoucherTicket;
              productGroup.products.unshift(product);
              return true;
            }
          });

          const productGroup: ProductGroupModel = productGroups[productGroupsWithVoucherIndex];
          productGroup.section.expanded = true;

          productGroups.splice(productGroups.indexOf(productGroup), 1);
          productGroups.unshift(productGroup);

          const isAnonymousTicket = ticketWithVoucher.personalizationType === 'anonymous';

          if (isAnonymousTicket) {
            productGroups.forEach(productGroup => {
              productGroup.products.forEach(product => {
                if (product.ticket != null) {
                  product.ticket.isVisible = product.ticket.personalizationType === 'anonymous' && !!product.ticket.voucherCode;
                } else if (!!product.package) {
                  product.package.contents.forEach(content => {
                    content.packageGroups.forEach(packageGroup => {
                      packageGroup.products.forEach(packageProduct => {
                        if (packageProduct.ticket) {
                          packageProduct.ticket.isVisible = packageProduct.ticket.personalizationType === 'anonymous' && !!packageProduct.ticket.voucherCode;
                        } 
                      });
                    });
                  });
                }
              });
            });
          }

          return {
            ...state,
            tickets: allTickets,
            ticketTypes: productGroups,
            lastVoucherTicket: ticketWithVoucher,
            isAnonymousTicketTaken: isAnonymousTicket
          };
        } else {
          return state;
        }
      } else {
        return state;
      }

    case ActionTypes.REMOVE_LAST_VOUCHER_ADDED:
      return {
        ...state,
        lastVoucherTicket: null
      };

    case ActionTypes.REMOVE_TICKET_BOOKINGS:
      return {
        ...state,
        ticketBookings: { bookings: [], timestamp: null }
      };

    case ActionTypes.ADD_TICKET_BOOKING:
      const payload: AddTicketBookingModel = action.payload;
      let ticketBookings: AddTicketBookingModel[] = [];
      let timestamp = null;

      if (!payload) {
        return state;
      } else {
        const bookingAlreadyExist = state.ticketBookings.bookings.find(
          ticket => {
            return ticket.ticketUniqueId === payload.ticketUniqueId;
          }
        );

        if (!bookingAlreadyExist) {
          ticketBookings = [...state.ticketBookings.bookings];
          ticketBookings.push({
            ticketTypeId: payload.ticketTypeId,
            groupId: payload.groupId,
            count: payload.count,
            availableTickets: payload.availableTickets,
            isTicketSold: payload.isTicketSold,
            ticketUniqueId: payload.ticketUniqueId,
            ticketLimit: payload.ticketLimit,
            ticketPersonId: payload.ticketPersonId
          });
        } else {
          ticketBookings = state.ticketBookings.bookings.map(ticket => {
            if (ticket.ticketUniqueId === payload.ticketUniqueId) {
              ticket.availableTickets = payload.availableTickets;
              ticket.count = payload.count;
              ticket.groupId = payload.groupId;
              ticket.ticketTypeId = payload.ticketTypeId;
              ticket.isTicketSold = payload.isTicketSold;
              ticket.ticketUniqueId = payload.ticketUniqueId;
              ticket.ticketLimit = payload.ticketLimit;
              ticket.ticketPersonId = payload.ticketPersonId;
            }
            return ticket;
          });
        }
        if (payload.availableTickets !== null) {
          timestamp = Date.now();
        }
      }

      return {
        ...state,
        ticketBookings: { bookings: ticketBookings, timestamp: timestamp }
      };

    case ActionTypes.SET_ACTIVE_HOLDER_SLIDE_INDEX:
      const activeSlide = action.payload;
      return {
        ...state,
        activeHolderSlideIndex: activeSlide
      };

    case ActionTypes.SET_TICKET_HOLDER: {
      const ticketHolder = action.payload;
      return {
        ...state,
        ticketHolder: ticketHolder
      };
    }

    case ActionTypes.SET_TICKET_HOLDER_INPUTS: {
      const ticketHolderInputs = action.payload;
      return {
        ...state,
        ticketHolderInputs: ticketHolderInputs
      };
    }

    case ActionTypes.SET_TICKET_HOLDER_QUESTIONNAIRE: {
      const questionnaireInputs = action.payload;
      return {
        ...state,
        ticketHolderQuestionnaireInputs: questionnaireInputs
      };
    }

    case ActionTypes.SET_POST_TICKET_HOLDER_FORM_RESULT: {
      const ticketHolderSubmitResult = action.payload;
      return {
        ...state,
        ticketHolderSubmitResult
      };
    }

    case ActionTypes.SET_CLAIMED_TICKET_HASH: {
      const hash = action.payload;
      return {
        ...state,
        claimedTicketHash: hash
      };
    }

    case ActionTypes.SET_CLAIMED_TICKET_HASH_VALID: {
      const valid = action.payload;
      return {
        ...state,
        claimedTicketHashValid: valid
      };
    }

    case ActionTypes.SET_TICKETS_TYPES:
      const ticketTypeList = action.payload;
      return {
        ...state,
        ticketTypes: ticketTypeList,
        initialTicketTypes: action.skipInitialTicketTypesUpdate ? state.initialTicketTypes : cloneDeep(ticketTypeList)
      };

    case ActionTypes.SET_SENDING_OPTIONS:
      const ticketSendingOptions = action.payload;
      return {
        ...state,
        ticketSendingOptions: ticketSendingOptions
      };

    case ActionTypes.REMOVE_VOUCHER: {
      const voucherCode: string = action.payload.voucherCode;
      const skipAnonymousChecks: boolean = action.payload.skipAnonymousChecks;
      const skipSettingIsAnonyTicketTaken: boolean = !!action.payload.skipSettingIsAnonyTicketTaken;

      /*
        It can happen that we release voucher when hitting Homepage
        and reducers were already reseted to initial state
        In that case we skip removing the voucherTickets from store
      */
      if (state.ticketTypes && state.ticketTypes.length) {
        const updatedProductGroupsTickets = state.ticketTypes.map(
          productGroup => {
            if (productGroup) {
              // return only tickets without the removed voucher ticket
              const updatedProducts = productGroup.products.filter(product => {
                if (product.ticket != null){
                  return product.ticket.voucherCode !== voucherCode;
                }

                return true;
              });
              return { ...productGroup, products: updatedProducts };
            } else {
              return { ...productGroup };
            }
          }
        );

        // now check if the group still have some tickets, if not remove even the group itself
        const updatedProductGroups = updatedProductGroupsTickets.filter(
          productGroup => {
            return productGroup.products.length;
          }
        );

        let wasAnonymousTicketTaken: boolean = false;
        let isAnonymousTicketTaken: boolean = false;

        const updatedTickets = Object.keys(state.tickets).reduce(
          (acc, currKey) => {
            if (state.tickets[currKey].voucherCode !== voucherCode) {
              acc[currKey] = state.tickets[currKey];
            } else if (state.tickets[currKey].personalizationType === 'anonymous') {
              wasAnonymousTicketTaken = true;
            }
            return acc;
          },
          {}
        );

        //if RemoveVoucher action was called from GetVoucher action/effects then we have to skip these checks and visibility changes:
        if (!skipAnonymousChecks) {
          // Check for anonymous

          Object.keys(updatedTickets).forEach(id => {
            const ticket = updatedTickets[id];
            if (ticket.personalizationType === 'anonymous' && ticket.count > 0) {
              isAnonymousTicketTaken = true;
            }
          });
        }

        return {
          ...state,
          ticketTypes: !isAnonymousTicketTaken && wasAnonymousTicketTaken ? cloneDeep(state.initialTicketTypes) : updatedProductGroups,
          tickets: updatedTickets,
          activeHolderSlideIndex: initialState.activeHolderSlideIndex,
          isAnonymousTicketTaken: !skipSettingIsAnonyTicketTaken && !skipAnonymousChecks ? isAnonymousTicketTaken : state.isAnonymousTicketTaken
        };
      } else {
        return state;
      }
    }

    case ActionTypes.SET_IS_PACKAGE_LOADING:
      const isLoading = action.payload;
      return {
        ...state,
        isPackageLoading: isLoading
      };

    case ActionTypes.SET_LAST_PACKAGE_INDEX:
      const packageIndex = action.payload;
      return {
        ...state,
        lastPackageIndex: packageIndex
      };

    case ActionTypes.ADD_TICKET_HOLDER_INDEXES:
      const ticketHolderIndexesToAdd = action.payload;

      if (ticketHolderIndexesToAdd != null) {
        const ticketHolderIndexes = {...state.ticketHolderIndexes};
        const indexes = Object.keys(ticketHolderIndexes);
        const lastIndex = indexes.length > 0 ? +indexes[indexes.length - 1] : -1;
  
        for (let i = 1; i <= ticketHolderIndexesToAdd; i++) {
          ticketHolderIndexes[`${lastIndex + i}`] = '';
        }
  
        return {
          ...state,
          ticketHolderIndexes
        };
      }

      return state;

    case ActionTypes.SET_TICKET_HOLDER_INDEX:
      const addTicketUniqueId = action.payload;

      if (addTicketUniqueId != null) {
        const ticketHolderIndexesToUpdate = {...state.ticketHolderIndexes};
        const firstEmptyTicketIndex = _.findKey(ticketHolderIndexesToUpdate, (ticketUniqueId: string) => ticketUniqueId == '');

        if (firstEmptyTicketIndex === undefined) {
          const ticketHolderIndexes = Object.keys(ticketHolderIndexesToUpdate);
          const newTicketHolderIndex = ticketHolderIndexes.length > 0 ? +ticketHolderIndexes[ticketHolderIndexes.length - 1] + 1 : 0;
          ticketHolderIndexesToUpdate[newTicketHolderIndex] = addTicketUniqueId;
        } else {
          ticketHolderIndexesToUpdate[firstEmptyTicketIndex] = addTicketUniqueId;
        }
  
        return {
          ...state,
          ticketHolderIndexes: ticketHolderIndexesToUpdate
        };
      }

      return state;
    
    case ActionTypes.REMOVE_TICKET_HOLDER_INDEX:
      const removeTicketUniqueId = action.payload;

      if (removeTicketUniqueId != null) {
        const ticketHolderIndexesToDelete = {...state.ticketHolderIndexes};
        const ticketHolderIndex = _.findLastKey(ticketHolderIndexesToDelete, (ticketUniqueId: string) => ticketUniqueId == removeTicketUniqueId);
        delete ticketHolderIndexesToDelete[ticketHolderIndex];
      
        return {
          ...state,
          ticketHolderIndexes: ticketHolderIndexesToDelete
        };
      }

      return state;

    case ActionTypes.SET_IS_ANONYMOUS_TICKET_TAKEN:
      return {
        ...state,
        isAnonymousTicketTaken: action.payload
      };

    case ActionTypes.SET_ARE_TICKETS_SENT:
      return {
        ...state,
        areTicketsSent: action.payload
      };

    case ActionTypes.RESET_REDUCER:
      return clonedInitialState;

    default: {
      return state;
    }
  }
}

/**
 * Because the data structure is defined within the reducer it is optimal to
 * locate our selector functions at this level. If store is to be thought of
 * as a database, and reducers the tables, selectors can be considered the
 * queries into said database. Remember to keep your selectors small and
 * focused so they can be combined and composed to fit each particular
 * use-case.
 */

export const getTickets = (state: State) => state.tickets;
export const getParkingTickets = (state: State) => state.parkingTickets;
export const getTicketHolder = (state: State) => state.ticketHolder;
export const getTicketHolderSubmitResult = (state: State) =>
  state.ticketHolderSubmitResult;
export const getTicketHolderQuestionnaireInputs = (state: State) =>
  state.ticketHolderQuestionnaireInputs;
export const getClaimedTicketHash = (state: State) => state.claimedTicketHash;
export const getClaimedTicketHashValid = (state: State) =>
  state.claimedTicketHashValid;
export const getTicketHolderInputs = (state: State) => state.ticketHolderInputs;

export const ticketTypes = (state: State) => state.ticketTypes;
export const ticketsBooking = (state: State) => state.ticketBookings;
export const getSendingOptions = (state: State) => state.ticketSendingOptions;
export const getActiveHolderSlideIndex = (state: State) =>
  state.activeHolderSlideIndex;
export const getLastAddedVoucherTicket = (state: State) =>
  state.lastVoucherTicket;
export const getLastPackageIndex = (state: State) =>
  state.lastPackageIndex;
export const getIsPackageLoading = (state: State) =>
  state.isPackageLoading;
export const getTicketHolderIndexes = (state: State) => 
  state.ticketHolderIndexes;
export const getIsAnonymousTicketTaken = (state: State) => state.isAnonymousTicketTaken;
export const getAreTicketsSent = (state: State) => state.areTicketsSent;

export const getVoucherTickets = createSelector(
  getTickets,
  tickets => {
    let ticketsById: TicketByIdModel[];
    if (tickets) {
      ticketsById = Object.keys(tickets)
        .filter((keyUniqueId: string) => {
          return (
            tickets[keyUniqueId].hasOwnProperty('voucherCode') &&
            tickets[keyUniqueId].voucherCode
          );
        })
        .map(keyUniqueId => {
          return tickets[keyUniqueId];
        });
    } else {
      ticketsById = [];
    }
    return ticketsById;
  }
);

export const getTicketSelectedSendingOptions = createSelector(
  getSendingOptions,
  sendingOptions => {
    return sendingOptions
      .filter(option => {
        return option.isSelected;
      })
      .map(option => {
        return option.value;
      })[0];
  }
);

export const getTotalPrice = createSelector(
  getTickets,
  tickets => {
    if (tickets) {
      const normalTicketPrice = parseFloat(
        Object.keys(tickets)
          .reduce((accu, curr) => {
            const ticket = tickets[curr];
            if (ticket.hasOwnProperty('parking')) {
              return accu;
            }
            return accu + ticket.count * ticket.price;
          }, 0)
          .toFixed(2)
      );

      return normalTicketPrice;
    } else {
      return 0;
    }
  }
);

export const doTicketsNeedLegitimation = createSelector(
  getTickets,
  (tickets: TicketModel) => {
    if (tickets) {
      return Object.keys(tickets).reduce((aggr, key) => {
        return (
          (tickets[key].requiresLegitimation && tickets[key].count > 0) || aggr
        );
      }, false);
    }
    return false;
  }
);

export const doTicketsNeedWorkshops = createSelector(
  getTickets,
  (tickets: TicketModel) => {
    if (tickets) {
      return Object.keys(tickets).reduce((aggr, key) => {
        if (
          tickets[key].hasOwnProperty('allowedWorkshops') &&
          tickets[key].allowedWorkshops &&
          Array.isArray(tickets[key].allowedWorkshops)
        ) {
          return (
            (tickets[key].allowedWorkshops.length && tickets[key].count > 0) ||
            aggr
          );
        } else {
          return aggr;
        }
      }, false);
    }
    return false;
  }
);

export const idsOfWorkshopsToShowInWorkshopScreen = createSelector(
  getTickets,
  (tickets: TicketModel) => {
    if (tickets) {
      return Object.keys(tickets).reduce((aggr: Array<number>, key) => {
        if (
          tickets[key].hasOwnProperty('allowedWorkshops') &&
          Array.isArray(tickets[key].allowedWorkshops)
        ) {
          if (tickets[key].allowedWorkshops.length && tickets[key].count > 0) {
            aggr.push(...tickets[key].allowedWorkshops);
          }
          return aggr;
        } else {
          return aggr;
        }
      }, []);
    }
    return [];
  }
);
