import { InputBase } from './input-base.class';

export class FormButton extends InputBase<string> {
  controlType = 'formButton';
  type: string;

  constructor(options: {} = {}) {
    super(options);
    this.type = options['type'] || '';
  }
}
