/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./web-shop.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../loader/loader.component.ngfactory";
import * as i3 from "../loader/loader.component";
import * as i4 from "../step-navigation/step-navigation.component.ngfactory";
import * as i5 from "../step-navigation/step-navigation.component";
import * as i6 from "@ngrx/store";
import * as i7 from "../shared/services-with-reducers/customization/customization.service";
import * as i8 from "../shared/services-with-reducers/tickets/tickets.service";
import * as i9 from "../shared/services-with-reducers/helpers/helper.service";
import * as i10 from "@angular/router";
import * as i11 from "../browser-check/browser-check.component.ngfactory";
import * as i12 from "../browser-check/browser-check.component";
import * as i13 from "../shared/pipes/hide-translation/hide-translation.pipe";
import * as i14 from "../banner/banner.component.ngfactory";
import * as i15 from "../banner/banner.component";
import * as i16 from "@angular/common";
import * as i17 from "../shared/modal-window/modal-window.component.ngfactory";
import * as i18 from "../shared/modal-window/modal-window.component";
import * as i19 from "@ngx-translate/core";
import * as i20 from "./web-shop.component";
import * as i21 from "../app.service";
import * as i22 from "../shared/forms/forms.service";
var styles_WebShopComponent = [i0.styles];
var RenderType_WebShopComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WebShopComponent, data: {} });
export { RenderType_WebShopComponent as RenderType_WebShopComponent };
function View_WebShopComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loader", [], null, null, null, i2.View_LoaderComponent_0, i2.RenderType_LoaderComponent)), i1.ɵdid(1, 49152, null, 0, i3.LoaderComponent, [i1.ElementRef], null, null)], null, null); }
function View_WebShopComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-step-navigation", [], null, null, null, i4.View_StepNavigationComponent_0, i4.RenderType_StepNavigationComponent)), i1.ɵdid(1, 180224, null, 0, i5.StepNavigationComponent, [i6.Store, i1.ElementRef, i7.CustomizationService, i8.TicketsService, i9.HelperService], null, null)], null, null); }
function View_WebShopComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i10.RouterOutlet, [i10.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_WebShopComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-browser-check", [], null, null, null, i11.View_BrowserCheckComponent_0, i11.RenderType_BrowserCheckComponent)), i1.ɵdid(1, 114688, null, 0, i12.BrowserCheckComponent, [i6.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_WebShopComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i13.HideTranslationPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-banner", [], null, null, null, i14.View_BannerComponent_0, i14.RenderType_BannerComponent)), i1.ɵdid(2, 245760, null, 0, i15.BannerComponent, [i6.Store, i10.ActivatedRoute, i7.CustomizationService], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 7, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "page-content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WebShopComponent_1)), i1.ɵdid(6, 16384, null, 0, i16.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WebShopComponent_2)), i1.ɵdid(8, 16384, null, 0, i16.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WebShopComponent_3)), i1.ɵdid(10, 16384, null, 0, i16.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WebShopComponent_4)), i1.ɵdid(12, 16384, null, 0, i16.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 11, "app-modal-window", [["translateTitle", "true"]], null, null, null, i17.View_ModalWindowComponent_0, i17.RenderType_ModalWindowComponent)), i1.ɵdid(14, 49152, null, 0, i18.ModalWindowComponent, [], { modalWindowActive: [0, "modalWindowActive"], modalWindowTitle: [1, "modalWindowTitle"], translateTitle: [2, "translateTitle"] }, null), (_l()(), i1.ɵeld(15, 0, null, 2, 4, "button", [["class", "button"], ["modalWindow-submit", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modalWindowVote(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 3, "span", [["data-translation", "personalize.buyer-info.buyer-form-copy-yes"]], null, null, null, null, null)), (_l()(), i1.ɵted(17, null, ["", ""])), i1.ɵpid(131072, i19.TranslatePipe, [i19.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(19, 1), (_l()(), i1.ɵeld(20, 0, null, 3, 4, "button", [["class", "button button-option"], ["modalWindow-close", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modalWindowVote(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 3, "span", [["data-translation", "personalize.buyer-info.buyer-form-copy-no"]], null, null, null, null, null)), (_l()(), i1.ɵted(22, null, ["", ""])), i1.ɵpid(131072, i19.TranslatePipe, [i19.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(24, 1)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_0 = (!_co.route.children.length || _co.isLoading); _ck(_v, 6, 0, currVal_0); var currVal_1 = ((_co.route.children.length && !_co.eventIsOver) && !_co.isLoading); _ck(_v, 8, 0, currVal_1); var currVal_2 = !_co.isLoading; _ck(_v, 10, 0, currVal_2); var currVal_3 = !_co.isSelfRegistrationEnabled; _ck(_v, 12, 0, currVal_3); var currVal_4 = _co.modalWindowOpen; var currVal_5 = "personalize.buyer-info.buyer-form-filled"; var currVal_6 = "true"; _ck(_v, 14, 0, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_7 = i1.ɵunv(_v, 17, 0, _ck(_v, 19, 0, i1.ɵnov(_v, 0), i1.ɵunv(_v, 17, 0, i1.ɵnov(_v, 18).transform("personalize.buyer-info.buyer-form-copy-yes")))); _ck(_v, 17, 0, currVal_7); var currVal_8 = i1.ɵunv(_v, 22, 0, _ck(_v, 24, 0, i1.ɵnov(_v, 0), i1.ɵunv(_v, 22, 0, i1.ɵnov(_v, 23).transform("personalize.buyer-info.buyer-form-copy-no")))); _ck(_v, 22, 0, currVal_8); }); }
export function View_WebShopComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-web-shop", [], [[8, "className", 0]], null, null, View_WebShopComponent_0, RenderType_WebShopComponent)), i1.ɵdid(1, 245760, null, 0, i20.WebShopComponent, [i10.ActivatedRoute, i10.Router, i6.Store, i7.CustomizationService, i21.AppService, i22.FormsService, i16.Location, i9.HelperService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).hostClasses; _ck(_v, 0, 0, currVal_0); }); }
var WebShopComponentNgFactory = i1.ɵccf("app-web-shop", i20.WebShopComponent, View_WebShopComponent_Host_0, {}, {}, []);
export { WebShopComponentNgFactory as WebShopComponentNgFactory };
