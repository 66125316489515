import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  activeTab$: Subject<'login' | 'registration'> = new Subject();

  setActiveTab(tab) {
    this.activeTab$.next(tab);
  }
}
