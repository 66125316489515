var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { TranslationsService } from './../../_pages/translations/translations.service';
import * as fromRoot from '../../app.reducer';
import * as stepsActions from '../services-with-reducers/step-forms/steps-forms.actions';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject, from, BehaviorSubject } from 'rxjs';
import { buffer, debounceTime, first, groupBy, map, mergeMap, startWith, toArray } from 'rxjs/operators';
import { HelperService } from '../services-with-reducers/helpers/helper.service';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ValidationService } from '../validation/validation.service';
import cloneDeep from 'lodash.clonedeep';
import { TextOrDropdownInputTypes } from '../services-with-reducers/helpers/helper.interface';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "../validation/validation.service";
import * as i3 from "../services-with-reducers/helpers/helper.service";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../_pages/translations/translations.service";
var FormsService = /** @class */ (function () {
    function FormsService(_store, _validationService, _helperService, _translateService, _translationsService) {
        /* FOLLOWING CODE CATCH REMOVING FORMS VALIDATIONS AND CONCENTRATE THEM TO BATCH REMOVALS  */
        var _this = this;
        this._store = _store;
        this._validationService = _validationService;
        this._helperService = _helperService;
        this._translateService = _translateService;
        this._translationsService = _translationsService;
        this.inputsValidity = {};
        /**
         * SUBJECT is used here as aggregator of synchronous data to stream.
         * it accepts changed input fields (InputBase)
         *
         * @private
         * @memberof FormsService
         */
        this.subject = new Subject();
        this.removeFormSubject = new Subject();
        this.counter = 0;
        this.formValidationCallbacks = [];
        this.validationInterval = null;
        //this should fire whenever we modify this.inputsValidity object (step-navigation-buttons component is subscribed to it):
        this.validationFeedbacksUpdated = new BehaviorSubject(false);
        this.removeFormSubject
            .pipe(buffer(this.removeFormSubject.pipe(startWith([]), debounceTime(100))))
            .subscribe(function (formsToRemove) {
            _this._store.dispatch(new stepsActions.RemoveFormValidity(formsToRemove));
        });
        /* FOLLOWING CODE CATCH FORM CHANGES AND CONCENTRATE THEM TO BATCH CHANGES  */
        // this.subject accepts changed form ( mostly with only one input field changed )
        var subscription = this.subject
            .pipe(
        /**
         * group is needed so it cannot happend that someone edit two different forms within 100ms and mix thir data together
         * streams are grouped to substreams based on uniq form ID (item.formInfo)
         */
        groupBy(function (item) {
            return item.formInfo.join('.');
        }), 
        /* flatmap handles, that substreams splited by form name
         * (once streamed forms are merged into a single one with latest values)
         * are again joined back to one final stream.
         */
        mergeMap(function (observableGroupByInputName) {
            return observableGroupByInputName.pipe(
            /* collect forms in a stream into an array.
             * Buffer() accepts a stream (the same stream we observe in this case),
             * based on which it recognizes, when to stop buffering streamed data into array
             * and pass it further (into map() function in our case)
             */
            buffer(_this.subject.pipe(startWith({}), debounceTime(100))), 
            // use map() to turn the array of forms into one form with latest values
            map(function (arrayOfForms) {
                if (!arrayOfForms.length) {
                    return null;
                }
                else if (arrayOfForms.length === 1) {
                    // if only one was buffered, send it separately
                    return arrayOfForms[0];
                }
                else {
                    // if more inputs changed at a time reduce the array into final form object
                    var mergedResult = arrayOfForms.reduce(function (acc, curent) {
                        var merged = {
                            formInfo: acc.formInfo,
                            inputSet: __assign({}, acc.inputSet, { updatedInputs: null }),
                            callback: acc.callback
                        };
                        /*
                         * Mass fill (automated filling form fields by browser based on prevously filled forms elsewehre)
                         * only add values (never remove ones), so we only replace old values with new existing oned
                         */
                        curent.inputSet.list.forEach(function (input, index) {
                            //SteZ_20201116:
                            //if we're coming from newInputValue function update only inputs from FormInputsPayloadModel.inputSet.updatedInputs property. if that property isn't defined do the usual magic inside those ifs.
                            //this is important in the following testing scenario:
                            //1. choose an event and a ticket,
                            //2. go to personalization, change the first name and immediately click on some dynamic checkbox.
                            //in this scenario we'd get two forms (the first one with the first name changed, the second one with the checkbox changed) and while merging the second form we'd lose the first name i.e. we'd return it to the previous value.
                            if (curent.inputSet.updatedInputs === undefined || curent.inputSet.updatedInputs.includes(input.key)) {
                                if (curent.inputSet.list[index].hasOwnProperty('value') &&
                                    curent.inputSet.list[index].value) {
                                    merged.inputSet.list[index].value =
                                        curent.inputSet.list[index].value;
                                }
                                if (curent.inputSet.list[index].options.length) {
                                    merged.inputSet.list[index].options =
                                        curent.inputSet.list[index].options;
                                }
                                if (curent.callback) {
                                    merged.callback = curent.callback;
                                }
                            }
                        });
                        return merged;
                    });
                    // In case there are more merged inputs, prerender anyway.
                    mergedResult.inputSet.rerender = true;
                    return mergedResult;
                }
            }));
        }))
            /* at the end we have a stream of forms debunced by 100ms,
             * we can now save it without wory about flodding http requests with form save request
             */
            .subscribe(function (mergedInputs) {
            if (mergedInputs) {
                // in case that formInfo has two parts and it has no callback it is saved to steps-forms reducer
                if (mergedInputs.formInfo.length === 2 && !mergedInputs.callback) {
                    _this._store.dispatch(new stepsActions.SetInputs(mergedInputs));
                }
                /** in case it has a callback, it is not steps form
                 * and the form save need to be handled separately in the callback
                 */
                if (mergedInputs.callback) {
                    mergedInputs.callback(mergedInputs.inputSet.list, mergedInputs.inputSet.rerender);
                }
            }
            _this.counter = 0;
        });
    }
    /** as values comes from single inputs, we want to save the form just once, after all inputs has changed
     *  It must be done this way as autofill could change multiple inputs at once. If merge save is not introduced here,
     *  all input values except the last one would be lost
     */
    FormsService.prototype.mergeInputsBeforeSave = function (actionPayload) {
        var _this = this;
        if (++this.counter == 1) {
            this.subject.next(actionPayload);
        }
        else {
            setTimeout(function () {
                _this.subject.next(actionPayload);
            }, 300);
        }
    };
    FormsService.prototype.updateInputs = function (inputsToUpdate, inputsWithNewValues) {
        if (!inputsWithNewValues.length) {
            return [];
        }
        if (inputsToUpdate) {
            inputsToUpdate.forEach(function (input, index) {
                if (inputsWithNewValues[index]) {
                    if (input.controlType === 'textbox' &&
                        inputsWithNewValues[index].value) {
                        var newValue = inputsWithNewValues[index].value;
                        if (!input.noTrim) {
                            newValue = newValue.trim();
                        }
                        inputsWithNewValues[index].value = newValue;
                    }
                    input.value = inputsWithNewValues[index].value || '';
                    input.disabled = inputsWithNewValues[index].disabled || false;
                    input.options = inputsWithNewValues[index].options;
                    input.hidden = inputsWithNewValues[index].hidden;
                }
            });
        }
        else {
            inputsToUpdate = cloneDeep(inputsWithNewValues);
        }
        return inputsToUpdate;
    };
    FormsService.prototype.getFunctionTextValue = function (profile, input, inputTitles, allTitles, inputProfessions, allProfessions, inputDepartments, allDepartments, inputOccupationalGroups, allOccupationalGroups) {
        var value = profile[input.key];
        var isTitleInput = input.key === TextOrDropdownInputTypes.Title;
        var isProfessionInput = input.key === TextOrDropdownInputTypes.Function;
        var isDepartmentInput = input.key === TextOrDropdownInputTypes.Department;
        var isOccupationalGroupInputInput = input.key === TextOrDropdownInputTypes.OccupationalGroup;
        if (!isNaN(profile[input.key]) && ((isTitleInput && !inputTitles.length && allTitles.length) ||
            (isProfessionInput && !inputProfessions.length && allProfessions.length) ||
            (isDepartmentInput && !inputDepartments.length && allDepartments.length) ||
            (isOccupationalGroupInputInput && !inputOccupationalGroups.length && allOccupationalGroups.length)) && input.options && input.options.length) {
            if (isTitleInput) {
                value = this.dataInput(allTitles, profile, input);
            }
            else if (isProfessionInput) {
                value = this.dataInput(allProfessions, profile, input);
            }
            else if (isDepartmentInput) {
                value = this.dataInput(allDepartments, profile, input);
            }
            else if (isOccupationalGroupInputInput) {
                value = this.dataInput(allOccupationalGroups, profile, input);
            }
        }
        return value;
    };
    FormsService.prototype.dataInput = function (allInputs, profile, input) {
        var value = profile[input.key];
        var data = allInputs.find(function (data) {
            return data.id === Number(profile[input.key]);
        });
        if (data) {
            this._translateService.get(data.text).subscribe(function (translation) {
                value = translation;
            });
        }
        return value;
    };
    /**
     * This function will reset the Function (Position) value if its current value doesn't exists in settings.
     * NOTE: Be aware when mixing this function with synchronous code because "reset" is done inside subscription (asynchronously)
     */
    FormsService.prototype.resetInvalidFunctionValue = function (input, availableTitles, availableProfessions, availableDepartments, availableOccupationalGroups, updateCb, updateAlways) {
        var _this = this;
        var isTitleInput = input.key === TextOrDropdownInputTypes.Title;
        var isFunctionInput = input.key === TextOrDropdownInputTypes.Function;
        var isDepartmentInput = input.key === TextOrDropdownInputTypes.Department;
        var isOccupationalGroupInput = input.key === TextOrDropdownInputTypes.OccupationalGroup;
        if (isTitleInput) {
            var availableTitlesTranslationSource = from(availableTitles).pipe(mergeMap(function (titles) {
                return new Observable(function (subscriber) {
                    _this._translationsService.getPositionTranslationForOption(input, titles, function (translatedText) {
                        subscriber.next(translatedText);
                        subscriber.complete();
                    });
                });
            }), toArray());
            availableTitlesTranslationSource.subscribe(function (availableTitles) {
                var titlesInList = availableTitles.find(function (titlesText) { return titlesText === input.value; });
                var invalidTitles = !titlesInList && availableTitles.length;
                if (updateAlways || invalidTitles) {
                    updateCb(input, invalidTitles);
                }
            });
        }
        else if (isFunctionInput) {
            var availableProfessionsTranslationSource = from(availableProfessions).pipe(mergeMap(function (profession) {
                return new Observable(function (subscriber) {
                    _this._translationsService.getPositionTranslationForOption(input, profession, function (translatedText) {
                        subscriber.next(translatedText);
                        subscriber.complete();
                    });
                });
            }), toArray());
            availableProfessionsTranslationSource.subscribe(function (availableProfessions) {
                var professionExistInList = availableProfessions.find(function (professionText) { return professionText === input.value; });
                var invalidProfession = !professionExistInList && availableProfessions.length;
                if (updateAlways || invalidProfession) {
                    updateCb(input, invalidProfession);
                }
            });
        }
        else if (isDepartmentInput) {
            var availableDepartmentsTranslationSource = from(availableDepartments).pipe(mergeMap(function (department) {
                return new Observable(function (subscriber) {
                    _this._translationsService.getPositionTranslationForOption(input, department, function (translatedText) {
                        subscriber.next(translatedText);
                        subscriber.complete();
                    });
                });
            }), toArray());
            availableDepartmentsTranslationSource.subscribe(function (availableDepartments) {
                var departmentExistInList = availableDepartments.find(function (departmentText) { return departmentText === input.value; });
                var invalidDepartment = !departmentExistInList && availableDepartments.length;
                if (updateAlways || invalidDepartment) {
                    updateCb(input, invalidDepartment);
                }
            });
        }
        else if (isOccupationalGroupInput) {
            var availableOccupationalGroupsTranslationSource = from(availableOccupationalGroups).pipe(mergeMap(function (occupationalGroup) {
                return new Observable(function (subscriber) {
                    _this._translationsService.getPositionTranslationForOption(input, occupationalGroup, function (translatedText) {
                        subscriber.next(translatedText);
                        subscriber.complete();
                    });
                });
            }), toArray());
            availableOccupationalGroupsTranslationSource.subscribe(function (availableOccupationalGroups) {
                var occupationalGroupInList = availableOccupationalGroups.find(function (occupationalGroupText) { return occupationalGroupText === input.value; });
                var invalidOccupationalGroup = !occupationalGroupInList && availableOccupationalGroups.length;
                if (updateAlways || invalidOccupationalGroup) {
                    updateCb(input, invalidOccupationalGroup);
                }
            });
        }
    };
    FormsService.prototype.inputsToFormControl = function (inputs, formPath) {
        var _this = this;
        var group = {};
        var selectedCountryCode = '';
        var globalFormValidators = [];
        if (inputs) {
            inputs
                .filter(function (input) {
                return input.key === 'country' && input.value;
            })
                .map(function (input) {
                selectedCountryCode = input.value;
            });
            inputs.forEach(function (input) {
                if (input.controlType === 'checkbox') {
                    var checkboxesGroup_1 = {};
                    input.options.forEach(function (option, index) {
                        var validators = [];
                        if (option.required && !input.hidden) {
                            validators.push(Validators.required);
                        }
                        checkboxesGroup_1[input.key + '_' + option.key] = new FormControl({
                            value: option.value || false,
                            disabled: option.disabled || false
                        }, validators);
                    });
                    var chckboxValidator = null;
                    if (input.required && !input.hidden) {
                        chckboxValidator = ValidationService.checkboxGroupValidator;
                    }
                    var fg = new FormGroup(checkboxesGroup_1, chckboxValidator);
                    group[input.key] = fg;
                }
                else {
                    /* SETUP VALIDATORS */
                    var validators = [];
                    var asyncValidators = [];
                    if (input.required) {
                        validators.push(Validators.required);
                    }
                    if (input.maxLengthValidation) {
                        validators.push(Validators.maxLength(input.maxLengthValidation));
                    }
                    if (input.minLengthValidation) {
                        validators.push(Validators.minLength(input.minLengthValidation));
                    }
                    if (input.emailValidation) {
                        validators.push(ValidationService.emailValidator(input.required));
                    }
                    if (input.zipcodeValidation) {
                        // construct form path from input zipCodeCitiesTag so we know on which form we made changes to zip code input
                        var zipCodeCitiesTag = input.zipCodeCitiesTag;
                        var _formPath = zipCodeCitiesTag.includes('holder') ? zipCodeCitiesTag.replace('holder', formPath) : zipCodeCitiesTag;
                        asyncValidators.push(_this._validationService.zipCodeValidator(selectedCountryCode, input.required, _formPath));
                    }
                    if (input.phoneValidation) {
                        validators.push(ValidationService.phoneValidator());
                    }
                    if (input.sameAsValidation) {
                        globalFormValidators.push(ValidationService.equalValueValidator(input.key, input.sameAsValidation, input.caseInsensitive));
                    }
                    if (input.controlType === 'textbox') {
                        validators.push(ValidationService.emptySpacesValidator());
                    }
                    if (input.hidden) {
                        validators = [];
                        asyncValidators = [];
                    }
                    group[input.key] = new FormControl({
                        value: input.controlType === 'dateInput' ? (!!input.value ? new Date(input.value) : null) : input.value || '',
                        disabled: input.disabled || input.hidden || false
                    }, validators, asyncValidators);
                }
            });
            return { group: group, globalFormValidators: globalFormValidators };
        }
    };
    FormsService.prototype.removeFormsValidationFeedback = function (stepsFormsActionName) {
        var _this = this;
        if (stepsFormsActionName && stepsFormsActionName.length === 2) {
            this._store
                .select(fromRoot.getSteps)
                .first()
                .subscribe(function (steps) {
                var step = steps[stepsFormsActionName[0]].forms[stepsFormsActionName[1]];
                if (step) {
                    var inputs = steps[stepsFormsActionName[0]].forms[stepsFormsActionName[1]]
                        .list;
                    var validationObjectCopy_1 = cloneDeep(_this.inputsValidity[stepsFormsActionName[0]]);
                    inputs.forEach(function (input) {
                        delete validationObjectCopy_1[stepsFormsActionName[1] + '_' + input.key];
                    });
                    _this.inputsValidity[stepsFormsActionName[0]] = validationObjectCopy_1;
                    _this.validationFeedbacksUpdated.next(true);
                }
            });
        }
    };
    FormsService.prototype.toFormGroup = function (inputs, stepsFormsActionName) {
        var _this = this;
        if (inputs) {
            var formControls = this.inputsToFormControl(inputs, !!stepsFormsActionName && stepsFormsActionName.length > 1 ? stepsFormsActionName[1] : null);
            var stepsForm_1 = new FormGroup(formControls.group);
            stepsForm_1.setValidators(formControls.globalFormValidators);
            // // as there is a bug in AG4 forms, formgroup with only required checkboxes
            // // is marked as valid on init, so we need to wait and recheck validity 
            // // In case form uses async validators we are not able to get the validity when created
            // // The solution is to wait until the form is not pending anymore
            //SteZ: but do it in the order of arrival, not randomly
            //(as then the first form validation could be executed last which could mean the form would be set as valid even though it really isn't):
            this.formValidationCallbacks.push({ formActionName: stepsFormsActionName, form: stepsForm_1 });
            this.processFormValidationQueue();
            if (stepsFormsActionName && stepsFormsActionName.length === 2) {
                // recheck validity on any form status(validity) change
                stepsForm_1.statusChanges.subscribe(function (status) {
                    _this.handleFormValidityChange(!stepsForm_1.invalid, stepsForm_1, stepsFormsActionName);
                });
            }
            return stepsForm_1;
        }
    };
    /**
     * This function will process form validation callbacks in FIFO fashion but it will call setFormValidity function only for the last form validation callback of one kind i.e. one formActionName.
     * If a form is still in pending status the function will wait for it to exit that status.
     */
    FormsService.prototype.processFormValidationQueue = function () {
        var _this = this;
        //only proceed if validationInterval is not set or not running:
        if (this.validationInterval === null || this.validationInterval.state !== 'scheduled') {
            //set validationInterval to run every 100ms:
            this.validationInterval = setInterval(function () {
                //proceed only if we have formValidationCallbacks to process:
                if (!!_this.formValidationCallbacks && _this.formValidationCallbacks.length) {
                    var _loop_1 = function () {
                        //get the first form validation callback in the queue:
                        var firstCallback = _this.formValidationCallbacks[0];
                        //check if we have more form validation callbacks of the same formActionName:
                        var allCallbacksSameAsFirst = _this.formValidationCallbacks.filter(function (callback) { return !!callback.formActionName && !!firstCallback.formActionName &&
                            callback.formActionName.toString() === firstCallback.formActionName.toString(); });
                        if (!!allCallbacksSameAsFirst && allCallbacksSameAsFirst.length > 1) {
                            //if we have more form validation callbacks of the same formActionName skip the current one (process only the last one):
                            _this.formValidationCallbacks.splice(0, 1);
                        }
                        else {
                            //only process the last form validation callback of the same formActionName:
                            if (!firstCallback.form.pending) {
                                //the form is no longer in pending status so we can call setFormValidity:
                                _this.handleFormValidityChange(!firstCallback.form.invalid, firstCallback.form, firstCallback.formActionName);
                                //remove the already processed form validation callback:
                                _this.formValidationCallbacks.splice(0, 1);
                            }
                            else {
                                return "break";
                            }
                        }
                    };
                    while (!!_this.formValidationCallbacks && _this.formValidationCallbacks.length) {
                        var state_1 = _loop_1();
                        if (state_1 === "break")
                            break;
                    }
                }
                else {
                    //we have no more form validation callbacks to process so we have to cancel the periodic execution of validationInterval:
                    clearInterval(_this.validationInterval);
                }
            }, 100);
        }
    };
    FormsService.prototype.handleFormValidityChange = function (valid, form, stepsFormsActionName) {
        if (stepsFormsActionName && stepsFormsActionName.length === 2) {
            //personal: ticketHolder and buyerinfo forms have their own validation rules so we shouldn't just call SetFormValidity here:
            if (stepsFormsActionName[0] === 'personal') {
                if (stepsFormsActionName[1].startsWith('ticketHolder')) {
                    this.setTicketHolderFormValidity(valid, stepsFormsActionName);
                    this.updateValidationFeedbacks(form, stepsFormsActionName);
                    return;
                }
                else if (stepsFormsActionName[1].startsWith('buyerinfo')) {
                    this._store.dispatch(new stepsActions.SetBuyerinfoFormValidity(valid));
                    this.updateValidationFeedbacks(form, stepsFormsActionName);
                    return;
                }
            }
        }
        //otherwise just call setFormValidity:
        this.setFormValidity(valid, form, stepsFormsActionName);
    };
    // write down status(valid === true, invalid === false) of current form into the steps forms store
    FormsService.prototype.setFormValidity = function (valid, form, stepsFormsActionName) {
        var actionPayload = {
            formInfo: stepsFormsActionName,
            valid: valid
        };
        this.updateValidationFeedbacks(form, stepsFormsActionName);
        if (stepsFormsActionName && stepsFormsActionName.length === 2) {
            /*       if (valid && !form) {
              if (this.inputsValidity.hasOwnProperty(stepsFormsActionName[0])) {
                Object.keys(
                  this.inputsValidity[stepsFormsActionName[0]]
                ).forEach(key => {
                  if (key.startsWith(stepsFormsActionName[1])) {
                    delete this.inputsValidity[stepsFormsActionName[0]][key];
                  }
                });
              }
            } */
            this._store.dispatch(new stepsActions.SetFormValidity(actionPayload));
        }
    };
    FormsService.prototype.updateValidationFeedbacks = function (form, stepsFormsActionName) {
        var _this = this;
        if (stepsFormsActionName && stepsFormsActionName.length === 2 && form) {
            this._store
                .select(fromRoot.getSteps)
                .pipe(first())
                .subscribe(function (steps) {
                _this.inputsValidity[stepsFormsActionName[0]] =
                    _this.inputsValidity[stepsFormsActionName[0]] || {};
                var step = steps[stepsFormsActionName[0]].forms[stepsFormsActionName[1]];
                if (step) {
                    var inputs = steps[stepsFormsActionName[0]].forms[stepsFormsActionName[1]]
                        .list;
                    _this.inputsValidity[stepsFormsActionName[0]] = _this.generateValidationFeedback(inputs, form, stepsFormsActionName[1], _this.inputsValidity[stepsFormsActionName[0]]);
                    _this.validationFeedbacksUpdated.next(true);
                }
            });
        }
    };
    // write down status(valid === true, invalid === false) of current form into the steps forms store
    FormsService.prototype.removeFormValidity = function (stepsFormsActionName) {
        var actionPayload = {
            formInfo: stepsFormsActionName,
            valid: false
        };
        if (stepsFormsActionName && stepsFormsActionName.length === 2) {
            this.removeFormSubject.next(actionPayload);
        }
    };
    FormsService.prototype.setTicketHolderFormValidity = function (valid, stepsFormsActionName) {
        this._store.dispatch(new stepsActions.SetTicketHolderFormValidity({ formInfo: stepsFormsActionName, valid: valid }));
    };
    FormsService.prototype.generateValidationFeedback = function (inputs, form, formName, validationObject) {
        var validationObjectCopy = cloneDeep(validationObject);
        if (!!inputs) {
            inputs.forEach(function (input) {
                if (form.controls[input.key] &&
                    form.controls[input.key].invalid &&
                    !input.hidden) {
                    var label = input.label || input.options[0].label;
                    validationObjectCopy[formName + '_' + input.key] = {
                        translate: input.translate,
                        label: label && label.length > 0
                            ? label.replace(/<(?:.|\n)*?>/gm, '')
                            : label // this one avoid html markup to be shown in the message
                    };
                }
                else {
                    delete validationObjectCopy[formName + '_' + input.key];
                }
            });
        }
        return validationObjectCopy;
    };
    FormsService.prototype.addStepValidationFeedback = function (stepsFormsActionName, key, messageTranslationKey) {
        if (key == '') {
            this.inputsValidity = {};
            this.validationFeedbacksUpdated.next(true);
        }
        else {
            this.inputsValidity[stepsFormsActionName[0]] =
                this.inputsValidity[stepsFormsActionName[0]] || {};
            this.inputsValidity[stepsFormsActionName[0]][stepsFormsActionName[1] + '_' + key] = {
                translate: true,
                label: messageTranslationKey
            };
            this.validationFeedbacksUpdated.next(true);
        }
    };
    FormsService.prototype.removeStepValidationFeedback = function (stepsFormsActionName, key) {
        if (key &&
            this.inputsValidity.hasOwnProperty(stepsFormsActionName[0]) &&
            this.inputsValidity[stepsFormsActionName[0]].hasOwnProperty([
                stepsFormsActionName[1] + '_' + key
            ])) {
            delete this.inputsValidity[stepsFormsActionName[0]][stepsFormsActionName[1] + '_' + key];
            this.validationFeedbacksUpdated.next(true);
        }
    };
    FormsService.prototype.removeAllStepValidationFeedbacks = function (stepsFormsActionName) {
        var _this = this;
        if (this.inputsValidity.hasOwnProperty(stepsFormsActionName[0])) {
            // delete validation inputs when form is removed from the flow (now used after ticketHolder form is removed, or billing address unchecked)
            Object.keys(this.inputsValidity[stepsFormsActionName[0]]).forEach(function (inputValidationKey) {
                if (inputValidationKey.indexOf(stepsFormsActionName[1]) >= 0) {
                    delete _this.inputsValidity[stepsFormsActionName[0]][inputValidationKey];
                    _this.validationFeedbacksUpdated.next(true);
                }
            });
        }
    };
    FormsService.prototype.setStepValid = function (stepsFormsActionName) {
        // set the whole form valid in reducer
        this.setFormValidity(true, null, stepsFormsActionName);
        this.removeAllStepValidationFeedbacks(stepsFormsActionName);
    };
    FormsService.prototype.removeStepValidation = function (stepsFormsActionName) {
        // set the whole form valid in reducer
        this.removeFormValidity(stepsFormsActionName);
        this.removeAllStepValidationFeedbacks(stepsFormsActionName);
    };
    FormsService.prototype.checkStepValidationFeedbacks = function (stepsFormsActionName) {
        if (this.inputsValidity.hasOwnProperty(stepsFormsActionName[0]) && Object.keys(this.inputsValidity[stepsFormsActionName[0]]).length) {
            return false;
        }
        return true;
    };
    FormsService.prototype.translateCountries = function (preferredList) {
        var _this = this;
        if (!preferredList) {
            return from([]);
        }
        return this._helperService.loadCountries().pipe(map(function (p) {
            var translationMap = p.map(function (country) { return "country." + country.shortCode; });
            var sortedCountries = [];
            return _this._translateService.stream(translationMap).pipe(map(function (termsTranslation) {
                return (sortedCountries = Object.keys(termsTranslation)
                    .map(function (key) {
                    return {
                        key: key.replace('country.', ''),
                        value: termsTranslation[key],
                        translate: false
                    };
                })
                    // first full preferred to top
                    .sort(function (a, b) {
                    var preferredIndexA = preferredList.indexOf(a.key);
                    var preferredIndexB = preferredList.indexOf(b.key);
                    if (preferredIndexA > -1 && preferredIndexB > -1) {
                        //if both countries are in preferred countries list:
                        if (preferredIndexA < preferredIndexB) {
                            return -1;
                        }
                        else {
                            return 1;
                        }
                    }
                    if (preferredIndexA > -1) {
                        return -1;
                    }
                    if (preferredIndexB > -1) {
                        return 1;
                    }
                    else {
                        return a.value.localeCompare(b.value);
                    }
                }));
            }));
        }));
    };
    FormsService.prototype.resetInputsValidity = function () {
        this.inputsValidity = {};
        this.validationFeedbacksUpdated.next(true);
    };
    FormsService.ngInjectableDef = i0.defineInjectable({ factory: function FormsService_Factory() { return new FormsService(i0.inject(i1.Store), i0.inject(i2.ValidationService), i0.inject(i3.HelperService), i0.inject(i4.TranslateService), i0.inject(i5.TranslationsService)); }, token: FormsService, providedIn: "root" });
    return FormsService;
}());
export { FormsService };
