import * as colorizerActions from '../../../colorizer/colorizer.actions';
import * as customizationActions from './customization.actions';
import * as fromRoot from '../../../app.reducer';
import * as stepsActions from '../step-forms/steps-forms.actions';
import * as ticketActions from '../tickets/ticket.actions';
import cloneDeep from 'lodash.clonedeep';

import {
  CssSkinModel,
  ExhibitionSettingModel,
  OperatorsSettingsModel,
  QuestionnaireDataInput
} from './customization.interfaces';
import {
  FormInputsPayloadModel} from '../step-forms/step.interface';
import { Inject, Injectable } from '@angular/core';
import {
  Observable,
  Subscription,
  combineLatest as observableCombineLatest,
  of
} from 'rxjs';
import {
  QuestionnaireDataSection} from './customization.interfaces';
import { catchError, filter, first, map, skip } from 'rxjs/operators';

import { AppConstants } from '../../app-constants';
import { CheckboxInput } from '../../forms/inputs/input-checkbox.class';
import { DOCUMENT } from '@angular/platform-browser';
import { DropdownInput } from '../../forms/inputs/input-dropdown.class';
import { ErrorHandlingService } from '../../error-handling/error-handling.service';
import { FormGroup } from '@angular/forms';
import { FormsService } from '../../forms/forms.service';
import { HelperService } from '../helpers/helper.service';
import { Http } from '@angular/http';
import { InputBase } from '../../forms/inputs/input-base.class';
import { RadioInput } from '../../forms/inputs/input-radio.class';
import { Router } from '@angular/router';
import { SendingOptionModel } from '../tickets/ticket.interface';
import { StatusBarService } from '../../../status-bar/status-bar.service';
import { select, Store } from '@ngrx/store';
import { TextInput } from '../../forms/inputs/input-text.class';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { generateSkinCss } from './customization.styles';
import { prepareBillingAddressData } from './forms/billing-address-data';
import { prepareBuyerInfoData } from './forms/buyer-info-data';
import { prepareConfirmationCheckboxes } from './forms/confirmation-checkboxes-data';
import { prepareDisclaimerCheckboxes } from './forms/disclaimer-checkboxes-data';
import { SelectOption } from '../exhibition/exhibition.interface';
import { consoleLog, getUUID } from '../../app-utils';

@Injectable({
  providedIn: 'root'
})
export class CustomizationService {
  public exhibitionSubscriptions: Subscription[] = [];
  public settings$: Observable<ExhibitionSettingModel>;
  private isLoggedInAsAdmin$: Observable<boolean>;
  private selectedLanguage$: Observable<string>;
  private isLoggedInAsAdmin: boolean;
  private allSendingOptions: SendingOptionModel[];

  constructor(
    @Inject(DOCUMENT) private document: any,
    private _router: Router,
    private _formsService: FormsService,
    private _helperService: HelperService,
    private _store: Store<fromRoot.State>,
    private _errorHandlingService: ErrorHandlingService,
    private _statusBarService: StatusBarService,
    private _http: Http,
    private _translateService: TranslateService
  ) {
    /* ticket holders forms
       Whenever we have settings and total ticket count changes regenerate new form sets for all ticket holders */

    const selectedTicketSendingOptions$ = this._store.select(
      fromRoot.getTicketSelectedSendingOptions
    );

    this.settings$ = this._store.select(fromRoot.getExhibitionSettings);
    this.isLoggedInAsAdmin$ = this._store.select(fromRoot.isLoggedInAsAdmin);
    this.selectedLanguage$ = this._store.select(fromRoot.getLanguage);
    this._store.select(fromRoot.getSendingOptions).subscribe(sendingOptions => {
      this.allSendingOptions = sendingOptions;
    })
    this.isLoggedInAsAdmin$.subscribe(isAdmin => {
      this.isLoggedInAsAdmin = isAdmin;
    });

    // modify ticket holder forms if ticketSendingOption is changed
    selectedTicketSendingOptions$
      .pipe(skip(1)) // we dont want to set it on page reload
      .subscribe(selectedTicketSendingOptions => {
        const ticketHolderInputSets = this.getTicketHolderInputSets(_store);
        if (ticketHolderInputSets && selectedTicketSendingOptions) {
          ticketHolderInputSets.forEach(
            (form: FormInputsPayloadModel, i: number) => {
              // set all forms sending options
              form.inputSet.list.find(
                input => input.key === 'sendingOption'
              ).value = selectedTicketSendingOptions;
              // decide whether to show ticket recipient
              const sendtoowner = form.inputSet.list.find(
                input => input.key === 'sendtoowner'
              );

              let selectedSendingOption: string = ticketHolderInputSets[0].inputSet.list.find(input => input.key === "sendingOption").value;
              let sendToOwner: boolean = true;
              let sendToOwnerHidden: boolean = true;
              // adminclient --> a4 ticket to buyer, ticket selection --> all tickets are sent as a print @ home ticket to buyer
              const isAllToBuyer: boolean = selectedSendingOption === 'allToBuyer';
              // adminclient --> download link to visitor, ticket selection --> Ticket retrival link sent (to buyer or other recipient)
              const isTicketRetrivalLink: boolean = selectedSendingOption === 'ticketRetrivalLink' &&
                this.allSendingOptions.some(option => option.value === "ticketRetrivalLink" && option.isEnabled);
              // adminclient --> mobile ticket & passbook to visitor, ticket selection --> send mobile ticket(s) to buyer or other recipient
              const isMobilePerOwner: boolean = selectedSendingOption === "mobilePerOwner" &&
                this.allSendingOptions.some(option => option.value === "mobilePerOwner" && option.isEnabled);
              // adminclient --> a4 ticket to visitor, ticket selection --> print home ticket(s) (a4, shown if:(tickets >= 2))
              const isNormalPerOwner: boolean = selectedSendingOption === "normalPerOwner";

              if (!isAllToBuyer && !isNormalPerOwner) {

                sendToOwner = isTicketRetrivalLink || isMobilePerOwner;

                for (let i = 0; i < this.allSendingOptions.length; i++) {
                  const sendingOption = this.allSendingOptions[i];
                  if (sendingOption.isEnabled) {
                    if (isMobilePerOwner && sendingOption.value === "mobilePerBuyer") {
                      sendToOwner = sendToOwnerHidden = false;
                      break;
                    }
                    else if (isTicketRetrivalLink && sendingOption.value === "ticketRetrivalLinkBuyer") {
                      sendToOwner = sendToOwnerHidden = false;
                      break;
                    }
                  }
                };
              }

              if (isAllToBuyer) {
                sendToOwner = false;
              }

              sendtoowner.options[0].value = sendToOwner;
              sendtoowner.hidden = sendToOwnerHidden;
              sendtoowner.options[0].label = `personalize.sending-options.${selectedTicketSendingOptions.toLowerCase()}`;
              sendtoowner.options[0].icon = selectedTicketSendingOptions;

              this._store.dispatch(new stepsActions.SetInputs(form));
            }
          );
        }
      });

    // in case we sending option is changed, based on event settings we need to decide, whether fields mandatory or not

    observableCombineLatest(
      this.settings$,
      selectedTicketSendingOptions$
    ).subscribe(data => {
      const [settings, selectedTicketSendingOptions] = data;
      const queryParams: string = this.document.location.search.toLowerCase();
      
      if (settings && selectedTicketSendingOptions && (!queryParams || (!queryParams.includes('reg_mail') && !queryParams.includes('login_user')))) {
        const ticketHolderInputSets: FormInputsPayloadModel[] = cloneDeep(this.getTicketHolderInputSets(_store));
        const checkValidityAndRedirect = false;
        ticketHolderInputSets.forEach(ticketHolderform => {
          this.setRequiredHoldersFiledsBasedOnSendinOption(
            ticketHolderform,
            settings,
            // selectedTicketSendingOptions,
            ticketHolderform.inputSet.list.find((input: InputBase<any>) => {
              return input.key === 'sendingOption';
            }).value,
            checkValidityAndRedirect
          );
        });
      }
    });

    /*   this._translateService
          .stream('') // stream function is triggered again on language change
          .subscribe(termsTranslation => {}); */
  }

  setRequiredHoldersFiledsBasedOnSendinOption(
    ticketHolderform: FormInputsPayloadModel,
    settings: ExhibitionSettingModel,
    ticketSendingOptions: string,
    checkValidityAndRedirect: boolean
  ) {

    let checkVerifyEmailValidity = false;
    const isEmailMandatory =
      (settings.emailIsMandatoryForMobileTicket &&
        ticketSendingOptions === 'mobilePerOwner') ||
      ticketSendingOptions === 'ticketRetrivalLink' ||
      settings.ticketOwnerSettings.fieldSettings.Email.isMandatory;

    const isEmailVerifyMandatory =
      (settings.emailIsMandatoryForMobileTicket &&
        ticketSendingOptions === 'mobilePerOwner') ||
      ticketSendingOptions === 'ticketRetrivalLink' ||
      settings.ticketOwnerSettings.fieldSettings.MailConfirm.isMandatory;

    //We remove Email field if it is not set visible in Admin and when it is not Mandatory.
    //In case the Email field doesn't exist, we won't show the option for sending Email to ticketHolder.
    const sendtoownerField = ticketHolderform.inputSet.list.find(item => item.key === 'sendtoowner');
    if (!settings.ticketOwnerSettings.fieldSettings.Email.isVisible && !isEmailMandatory) {
      ticketHolderform.inputSet.list = ticketHolderform.inputSet.list.filter(item => item.key !== 'email');
      sendtoownerField.hidden = true;
    }

    //We remove VerifyEmail field if it is not set visible in Admin and when it is not Mandatory.
    if (!settings.ticketOwnerSettings.fieldSettings.MailConfirm.isVisible && !isEmailVerifyMandatory) {
      ticketHolderform.inputSet.list = ticketHolderform.inputSet.list.filter(item => item.key !== 'verifyEmail');
    }

    let isFieldAdded: boolean = false;
    //If Email field is mandatory and the field doesn't exist, we add it.
    if (isEmailMandatory && !ticketHolderform.inputSet.list.some(item => item.key === 'email')) {
      ticketHolderform.inputSet.list.push(
        new TextInput({
          key: 'email',
          label: 'personalize.holder.email',
          order: settings.ticketOwnerSettings.fieldSettings.Email.order,
          translate: true,
          required: true,
          cssClass: 'col-md-6 column-grow-1',
          emailValidation: true,
          type: 'email'
        })
      );
      isFieldAdded = true;
    }

    //If VerifyEmail field is mandatory and the field doesn't exist, we add it.
    if (isEmailVerifyMandatory && !ticketHolderform.inputSet.list.some(item => item.key === 'verifyEmail')) {
      ticketHolderform.inputSet.list.push(
        new TextInput({
          key: 'verifyEmail',
          label: 'personalize.holder.email-verify',
          order: settings.ticketOwnerSettings.fieldSettings.MailConfirm.order,
          translate: true,
          required: true,
          cssClass: 'col-md-6 column-grow-1',
          emailValidation: true,
          sameAsValidation: 'email',
          type: 'email'
        })
      );
      isFieldAdded = true;
    }

    //Sort all fields by ascending order if email and verifyEmail fields were added
    if (isFieldAdded) {
      ticketHolderform.inputSet.list.sort((a, b) => a.order - b.order);
    }

    ticketHolderform.inputSet.list.forEach(input => {
      let inputName = this.capitalizeFirstLetter(input.key);
      // there is exception that this field does not have same name as corresponding input (Gender must be changed to Salutation)
      if (inputName === 'Gender') {
        inputName = 'Salutation';
      }

      const requiredFieldName = `is${inputName}Mandatory`;
      if (settings.ticketOwnerSettings.hasOwnProperty(requiredFieldName)) {
        input.required = settings.ticketOwnerSettings[requiredFieldName];
      }
    });

    /* set whether following inputs are required or not based on sending option selected  */
    const updatedInputs = ticketHolderform.inputSet.list.map(input => {
      if (input.key === 'email') {
        input.required = isEmailMandatory;
        return input;
      } else if (input.key === 'verifyEmail') {
        input.required = isEmailVerifyMandatory;
        if (input.required) {
          checkValidityAndRedirect = true;
          checkVerifyEmailValidity = true;
        }
        return input;
      } else if (input.key === 'firstName') {
        if (ticketSendingOptions === 'ticketRetrivalLink') {
          input.required = true;
          return input;
        } else {
          input.required =
            settings.ticketOwnerSettings.fieldSettings.FirstName.isMandatory;
          return input;
        }
      } else if (input.key === 'lastName') {
        if (ticketSendingOptions === 'ticketRetrivalLink') {
          input.required = true;
          return input;
        } else {
          input.required =
            settings.ticketOwnerSettings.fieldSettings.LastName.isMandatory;
          return input;
        }
      } else {
        return input;
      }
    });

    //After updating inputs, we set email and verifyEmail required values in setOriginalEmailValues,
    //so we can use them in personalization step when sendtoowner checkbox is checked or unchecked.
    const emailField = ticketHolderform.inputSet.list.find(item => item.key === 'email');
    const verifyEmailField = ticketHolderform.inputSet.list.find(item => item.key === 'verifyEmail');
    const emailRequired = emailField ? emailField.required : false;
    const verifyEmailRequired = verifyEmailField ? verifyEmailField.required : false;
    const isSendToOwnerChecked = sendtoownerField.options[0].value;
    this._helperService.setOriginalEmailValues(emailRequired, verifyEmailRequired);

    //We set email and verifyEmail fields to required when checkbox sendtoowner is checked and visible because
    //we don't want to make them required when 'normal per owner' --> (in code), 'A4 E-ticket to visitor' --> (in admin) is selected in Confirmation step which
    //sets sendtoowner checkbox to checked and hidden, but doesn't want email and verifyEmail fields to be required
    if (!sendtoownerField.hidden && isSendToOwnerChecked && !!emailField) {
      emailField.required = true;
      if (!!verifyEmailField) {
        verifyEmailField.required = true;
      }
    }

    ticketHolderform.inputSet.list = updatedInputs;
    this._store.dispatch(new stepsActions.SetInputs(ticketHolderform));

    const shouldSetValid = !ticketHolderform.inputSet.list.some(ticketHolderInputSet => {
      return ticketHolderInputSet.required;
    });

    if (shouldSetValid) {
      this._formsService.setTicketHolderFormValidity(true, ticketHolderform.formInfo);
      //Make the form valid if there isn't any mandatory fields on any of the ticket holders
      this._formsService.setFormValidity(true, null, ticketHolderform.formInfo);
    }

    /**
     * in case we set the required fileds from step after the ticket holder form iteself (confirmation),
     * we need to revalidate the form and in case it is not valid, redirect to fix it
     */

    if (checkValidityAndRedirect) {
      const formControllObject = this._formsService.inputsToFormControl(
        ticketHolderform.inputSet.list
      );
      const form = new FormGroup(formControllObject.group);

      form.setValidators(formControllObject.globalFormValidators);

      const validationCallback = () => {
        if (!form.valid) {
          if (checkVerifyEmailValidity) {
            this._formsService.setFormValidity(false, null, ticketHolderform.formInfo);
            this._formsService.setTicketHolderFormValidity(false, ticketHolderform.formInfo);
          }
          observableCombineLatest([
            this._store.pipe(select(fromRoot.getSelectedExhibitionId)),
            this._store.pipe(select(fromRoot.getTicketHolderInputSets)),
            this._store.pipe(select(fromRoot.getSelectedStep))
          ])
          .pipe(first())
          .subscribe(([eventId, ticketHolderInputSets, selectedStep]) => {
            if (selectedStep !== 'tickets') {
              // Find the index of the choosen ticket Holder and set activeHolderSlideIndex and navigate to it.
              const currentTicketHolderString = ticketHolderform.formInfo[1];
              const ticketHolderIndex = ticketHolderInputSets.findIndex(holderInputSet => holderInputSet.formInfo[1] === currentTicketHolderString);

              this._store.dispatch(
                new ticketActions.SetActiveHolderSlideIndex(
                  ticketHolderIndex
                )
              );
              
              this._router.navigate([`webshop/${eventId}/personal`], {
                queryParams: { scroll: ticketHolderIndex }
              });
            }
          });
        }
      };

      this._helperService.triggerCallbackOnceFormValidationIsDone(
        form,
        validationCallback
      );
    }
  }

  capitalizeFirstLetter(text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  getTicketHolderInputSets(store) {
    let state;
    store
      .select(fromRoot.getTicketHolderInputSets)
      .first()
      .subscribe(inputsSets => (state = inputsSets));
    return state;
  }

  setShoppingStartTime() {
    this._store.dispatch(new customizationActions.SetShoppingStartTime());
  }

  resetShoppingStartTime() {
    this._store.dispatch(new customizationActions.ResetShoppingStartTime());
  }

  triggerOnExhibitionChange(eventId) {
    // first unsubscribe all subscriptions which were set with event change
    this.exhibitionSubscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });

    const orderUUID = getUUID();
    this._store.dispatch(
      new customizationActions.SetOrderUuid(orderUUID)
    );
    consoleLog("OrderUuid for this session: " + orderUUID);

    // transform custom exhibition data to specific data structures
    // and load exhibition specific data
    this.selectedLanguage$.first().subscribe(countryCode => {
      this._store.dispatch(
        new customizationActions.GetRandomSponsor({ eventId, countryCode })
      );
    });

    const exhibitionSettingsSubscription = observableCombineLatest([
      this.settings$,
      this._store.pipe(select(fromRoot.getTitles)),
      this._store.pipe(select(fromRoot.getAllTitles)),
      this._store.pipe(select(fromRoot.getProfessions)),
      this._store.pipe(select(fromRoot.getAllProfessions)),
      this._store.pipe(select(fromRoot.getDepartments)),
      this._store.pipe(select(fromRoot.getAllDepartments)),
      this._store.pipe(select(fromRoot.getOccupationalGroups)),
      this._store.pipe(select(fromRoot.getAllOccupationalGroups))
    ])
      .pipe(
        filter((data: [
          ExhibitionSettingModel,
          SelectOption[],
          QuestionnaireDataInput[],
          SelectOption[],
          QuestionnaireDataInput[],
          SelectOption[],
          QuestionnaireDataInput[],
          SelectOption[],
          QuestionnaireDataInput[]
        ]) => {
          const [
            settings,
            titles,
            allTitles,
            professions,
            allProfessions,
            departments,
            allDepartments,
            occupationalGroups,
            allOccupationalGroups,
          ] = data;

          return !!settings && 
            !!titles && 
            !!allTitles && 
            !!professions && 
            !!allProfessions && 
            !!departments && 
            !!allDepartments && 
            !!occupationalGroups && 
            !!allOccupationalGroups;
        }),
        first()
      )
      .subscribe(([
        settings,
        titles,
        allTitles,
        professions,
        allProfessions,
        departments,
        allDepartments,
        occupationalGroups,
        allOccupationalGroups
      ]) => {

        /* Buyer info */
        const { buyerSettings, isGoogleAutocompleteEnabled } = settings;
        const buyerInfo = prepareBuyerInfoData(
          settings,
          isGoogleAutocompleteEnabled,
          titles,
          professions,
          departments,
          occupationalGroups
        );

        const buyerInfoPayload: FormInputsPayloadModel = {
          formInfo: ['personal', 'buyerinfo'],
          inputSet: {
            rerender: false,
            list: buyerInfo
          }
        };

        this._store.dispatch(new stepsActions.SetInputs(buyerInfoPayload));

        /* Billing address info */
        const billingAddress = prepareBillingAddressData(
          buyerSettings,
          isGoogleAutocompleteEnabled,
        );
        const billingAddressPayload: FormInputsPayloadModel = {
          formInfo: ['personal', 'billingaddress'],
          inputSet: {
            rerender: false,
            list: billingAddress
          }
        };

        this._store.dispatch(new stepsActions.SetInputs(billingAddressPayload));

        // each time new translations arrive and are available on FE
        const langChangeSubscription = this._store
          .select(fromRoot.getTranslations)
          .subscribe(() => {
            /* privacy checkbox */

            const privacySubscription = this._translateService
              .get([
                'personalize.privacy-link',
                'personalize.privacy-link.text',
                'personalize.privacy-optional',
                'personalize.privacy-optional.text',
              ]) // stream function is triggered again on language change
              .subscribe(termsTranslations => {
                this._store
                  .select(fromRoot.getSteps)
                  .pipe(first())
                  .subscribe(steps => {
                    const privacyForm = steps['personal'].forms['privacy'];

                    let privacyPolicyChecked = false;
                    let privacyPolicyOptionalChecked = false;

                    // set privacy policy value
                    if (privacyForm) {
                      const privacyPolicyBase = privacyForm.list.find(
                        (inputBase: InputBase<any>) =>
                          inputBase.key === 'disclaimer'
                      );

                      const privacyPolicyOption =
                        privacyPolicyBase &&
                        privacyPolicyBase.options.find(
                          option => option.key === 'disclaimerConfirmation'
                        );

                      if (privacyPolicyOption && privacyPolicyOption.value) {
                        privacyPolicyChecked = privacyPolicyOption.value;
                      }

                      const privacyPolicyOptionalBase = privacyForm.list.find(
                        (inputBase: InputBase<any>) =>
                          inputBase.key === 'disclaimerOptional'
                      );

                      const privacyPolicyOptionalOption =
                        privacyPolicyOptionalBase &&
                        privacyPolicyOptionalBase.options.find(
                          option => option.key === 'disclaimerOptionalConfirmation'
                        );

                      if (privacyPolicyOptionalOption && privacyPolicyOptionalOption.value) {
                        privacyPolicyOptionalChecked = true;
                      };
                    }

                    const confirmationCheckboxes = prepareDisclaimerCheckboxes(
                      settings,
                      termsTranslations,
                      this._helperService.isSelfregistration(),
                      privacyPolicyChecked,
                      privacyPolicyOptionalChecked,
                    );
                    const privacyPayload: FormInputsPayloadModel = {
                      formInfo: ['personal', 'privacy'],
                      inputSet: {
                        rerender: true,
                        list: confirmationCheckboxes
                      }
                    };
                    this._store.dispatch(
                      new stepsActions.SetInputs(privacyPayload)
                    );
                  });
              });

            this.exhibitionSubscriptions.push(privacySubscription);

            /* confirmation checkboxes */

            const termsTranslationSubscription = this._translateService
              .stream('confirmation.terms-conditions') // stream function is triggered again on language change
              .subscribe(termsTranslation => {
                const confirmationCheckboxes = prepareConfirmationCheckboxes(
                  settings,
                  termsTranslation
                );
                const confirmationPayload: FormInputsPayloadModel = {
                  formInfo: ['confirmation', 'checkboxes'],
                  inputSet: {
                    rerender: false,
                    list: confirmationCheckboxes
                  }
                };

                this._store.dispatch(
                  new stepsActions.SetInputs(confirmationPayload)
                );
              });
            this.exhibitionSubscriptions.push(termsTranslationSubscription);
          });

        this.exhibitionSubscriptions.push(langChangeSubscription);

        /* sending options */

        const ticketSendingOptions: SendingOptionModel[] =
          settings.ticketSelectionDeliveryTypes;

        const isAllToBuyerEnabled = !!ticketSendingOptions.find(
          option => option.value === 'allToBuyer' && option.isEnabled
        );

        const isRetrivalLinkEnabled = !!ticketSendingOptions.find(
          option => option.value === 'ticketRetrivalLink' && option.isEnabled
        );

        const isMobilePerOwnerEnabled = !!ticketSendingOptions.find(
          option => option.value === 'mobilePerOwner' && option.isEnabled
        );

        const isMobile = this._helperService.isMobile();

        let isSendingOptionSelected: boolean = false;


        const modifiedTicketSendingOptions: SendingOptionModel[] = ticketSendingOptions.map(
          (option: SendingOptionModel) => {

            let sendingOptionObj: SendingOptionModel = { ...option, isSelected: false };

            if (option.isEnabled && option.isBasic) {
              if (!isSendingOptionSelected) {
                if(this._helperService.isSelfregistration() && option.value === 'allToBuyer'){
                  sendingOptionObj.isSelected = isSendingOptionSelected = true;
                  return sendingOptionObj;
                } else if (isMobile === null) {
                  if ((option.value === "normalPerOwner" || option.value === "mobilePerOwner") && (isAllToBuyerEnabled || isRetrivalLinkEnabled)) {
                    return sendingOptionObj;
                  }
                  sendingOptionObj.isSelected = isSendingOptionSelected = true;
                } else {
                  if (option.value === "mobilePerOwner") {
                    sendingOptionObj.isSelected = isSendingOptionSelected = true;
                  } else {
                    sendingOptionObj.isSelected = isSendingOptionSelected = isMobilePerOwnerEnabled ? false : true;
                  }
                }
              }
            }
            return sendingOptionObj
          }
        );

        this._store.dispatch(
          new ticketActions.SetSendingOptionsAction(
            modifiedTicketSendingOptions
          )
        );
      });

    this.exhibitionSubscriptions.push(exhibitionSettingsSubscription);

    const notLoggedAndLoginMandatorySubscription = this._store
      .select(fromRoot.notLoggedAndLoginMandatory)
      .subscribe(notLogged => {
        this._formsService.setFormValidity(!notLogged, null, [
          'tickets',
          'login'
        ]);
      });

    this.exhibitionSubscriptions.push(notLoggedAndLoginMandatorySubscription);
  }

  tansformQuestionnaireIntoInput(
    questions: QuestionnaireDataSection[]
  ): InputBase<any>[] {
    const sortedQuestions = questions.slice(0).map(question => {
      const inputBase: any = {
        key: question.id.toString(),
        label: question.label,
        isQuestionnaire: true,
        required: question.isMandatory,
        translate: true,
        selectionLimit: question.selectionLimit,
        previousValueId: question.previousValueId
      };

      if (question.type === 'dropdown') {
        const sortedOptions = question.values
          .slice(0)
          .sort((a, b) => a.order - b.order);

        inputBase.options = sortedOptions.map(option => {
          return { key: option.id, value: option.text };
        });

        inputBase.cssClass = question.previousValueId ? 'col-lg-7' : 'col-lg-6';
        return new DropdownInput(inputBase);
      } else if (question.type === 'text') {
        inputBase.key = question.id;
        inputBase['noTrim'] = true;

        if (question.values.length > 0) {
          inputBase.key += '_' + question.values[0].id;

          /* we need to translate the value */
          this._translateService
            .get(question.values[0].text)
            .pipe(
              filter(
                translation => translation !== AppConstants.MISSING_TRANSLATION
              )
            )
            .subscribe((translation: string) => {
              inputBase.placeholder = question.values[0].text;
            });
        }
        inputBase.maxLengthValidation = question.textLength || 150;
        inputBase.cssClass = question.previousValueId ? 'col-lg-7' : 'col-lg-6';
        return new TextInput(inputBase);
      } else if (question.type === 'radio') {
        inputBase.cssClass = 'col-lg-12';
        inputBase.options = question.values.map(option => {
          return {
            key: option.id,
            value: option.text,
            cssClass: 'col-lg-4 col-md-6',
            order: option.order
          };
        });
        return new RadioInput(inputBase);
      } else if (question.type === 'checkbox') {
        inputBase.cssClass = 'col-lg-12';
        inputBase.options = question.values.map(option => {
          return {
            key: option.id,
            value: false,
            label: option.text,
            cssClass: 'col-lg-4 col-md-6',
            order: option.order
          };
        });
        return new CheckboxInput(inputBase);
      }
    });

    return sortedQuestions;
  }

  setStyles() {
    observableCombineLatest(
      this._store.select(fromRoot.getExhibitionSettings),
      this._store.select(fromRoot.getOperatorsSettings),
      this._store.select(fromRoot.isLoggedInAsAdmin)
    )
      .pipe(
        filter(data => {
          return !!data[1];
        })
      )
      .first()
      .subscribe(
        (data: [ExhibitionSettingModel, OperatorsSettingsModel, boolean]) => {
          const [exhibitionSettings, operatorsSettings, isAdmin] = data;

          if (exhibitionSettings) {
            this.appendSkinCSS(
              exhibitionSettings.customStyles,
              3,
              false,
              exhibitionSettings.customStylesId
            );

            const queryParams: string = this.document.location.search.toLowerCase();
            
            if (isAdmin && (!queryParams || (!queryParams.includes('reg_mail') && !queryParams.includes('login_user')))) {
              this._store.dispatch(
                new colorizerActions.LoadStyle(
                  exhibitionSettings.customStylesId
                )
              );
            }
          } else if (operatorsSettings.hasOwnProperty('customStyles')) {
            this.appendSkinCSS(
              operatorsSettings.customStyles,
              2,
              false,
              operatorsSettings.customStylesId
            );

            if (isAdmin) {
              this._store.dispatch(
                new colorizerActions.LoadStyle(operatorsSettings.customStylesId)
              );
            }
          }
        }
      );
  }

  /* skin with higer or same priority rewrite lower or older priority
    1 - default color (red)
    2 - operators settings
    3 - event settings
    4 - voucher settings
    5 - admin editing styles
  */
  appendSkinCSS(variables, priority: number, isOperator: boolean, skinId) {
    this._store
      .select(fromRoot.isLoggedInAsAdmin)
      .pipe(first())
      .subscribe(isAdmin => {
        // add styles
        const cssString = generateSkinCss(variables);
        const cssSkin: CssSkinModel = {
          priority: priority,
          css: cssString,
          variables,
          id: skinId
        };

        if (isOperator) {
          isAdmin = isAdmin || isOperator;
        }

        this._store.dispatch(
          new customizationActions.SetExhibitionStyles({ cssSkin, isAdmin })
        );
      });
  }

  setVoucherStyles(styles) {
    this.appendSkinCSS(
      {
        BaseThemeColor: styles.BaseThemeColor,
        BaseTxColor: styles.BaseTxColor,
        ButtonBgColor: styles.ButtonBgColor,
        ButtonTxColor: styles.ButtonTxColor,
        AreaBlockBgActive: styles.AreaBlockBgActive
      },
      4,
      false,
      null
    );
  }

  setColors(code) {
    if (code === 'blue') {
      this.appendSkinCSS(
        {
          BaseThemeColor: '#2569af',
          AreaBlockBgActive: '#174575'
        },
        4,
        false,
        null
      );
    } else if (code === 'green') {
      this.appendSkinCSS(
        {
          BaseThemeColor: '#5ca26f',
          AreaBlockBgActive: '#177530'
        },
        4,
        false,
        null
      );
    } else if (code === 'red') {
      this.appendSkinCSS(
        {
          BaseThemeColor: '#d12d3d',
          AreaBlockBgActive: '#a90515'
        },
        1,
        false,
        null
      );
    }
  }

  addCSS(css: string, id: string) {
    if (typeof window !== 'undefined') {
      if (this._helperService.appRenderer && this._helperService.appEl) {
        const head = document.head || document.getElementsByTagName('head')[0];
        const style = this._helperService.appRenderer.createElement(
          this._helperService.appEl,
          'style'
        );

        // if new CSS block was added with specific ID, first delete the old CSS block
        if (id) {
          const oldCSS = document.getElementById(id);
          if (oldCSS) {
            head.removeChild(oldCSS);
          }
          // add id to the newly created CSS
          style.id = id;
        }

        style.type = 'text/css';

        if (style.styleSheet) {
          style.styleSheet.cssText = css;
        } else {
          style.appendChild(document.createTextNode(css));
        }

        head.appendChild(style);
      }
    }
  }

  addIframe(iframeUrl: string, id: string) {
    if (typeof window !== 'undefined') {
      if (this._helperService.appRenderer && this._helperService.appEl) {
        const body = document.body || document.getElementsByTagName('body')[0];
        const iframe = this._helperService.appRenderer.createElement(
          this._helperService.appEl,
          'iframe'
        );

        if (id) {
          const oldIframe = document.getElementById(id);

          if (oldIframe) {
            body.removeChild(oldIframe);
          }

          const randomNumber = Math.random() * 10000000000000;
          iframe.id = id;

          iframe.src = iframeUrl + randomNumber.toString() + '?';
          iframe.width = '1';
          iframe.height = '1';
          iframe.frameborder = '0';
          iframe.style.display = 'none';
        }

        body.appendChild(iframe);
      }
    }
  }

  injectTrackingScript(
    scriptCode: string,
    id: string,
    totalTicketsPrice?: string,
    orderId?: string
  ) {
    if (typeof window !== 'undefined') {
      if (this._helperService.appRenderer && this._helperService.appEl) {
        const scriptSrcs = [];
        const body = document.body || document.getElementsByTagName('body')[0];
        const head = document.head || document.getElementsByTagName('head')[0];

        let scriptsCleaned = '';
        // Extract script if is surrounded by <script> tag
        scriptCode.replace(/<script[^>]*>([\s\S]*?)<\/script>/gi, function () {
          scriptsCleaned += arguments[1];
          // if it is a script with src
          arguments[0].replace(/src="(.*?)"/gi, function () {
            if (arguments[1]) {
              scriptSrcs.push(arguments[1]);
            }
          });

          return '';
        });
        // If script is not surrounded, then use script directly
        if (!scriptsCleaned) {
          scriptsCleaned = scriptCode;
        }
        // remove line breaks
        scriptsCleaned = scriptsCleaned.replace(/(?:\r\n|\r|\n)/gm, '');

        if (id) {
          const oldScripts = document.getElementById(id);
          if (oldScripts) {
            oldScripts.parentElement.removeChild(oldScripts);
          }
        }
        if (totalTicketsPrice !== null) {
          scriptsCleaned = scriptsCleaned.replace(
            /\[Revenue\]/gim,
            totalTicketsPrice
          );
        }
        if (orderId !== null) {
          scriptsCleaned = scriptsCleaned.replace(/\[OrderID\]/gim, orderId);
        }

        // const scriptWrapper = document.createElement('div');
        // scriptWrapper.id = id;

        // create inline script
        const scriptElement = document.createElement('script');
        scriptElement.setAttribute('type', 'text/javascript');
        scriptElement.id = id;
        scriptElement.innerHTML = scriptsCleaned;
        if (id === 'generic-script-all-page') {
          head.appendChild(scriptElement);
        }
        if (id === 'generic-script') {
          body.appendChild(scriptElement);
        }

        //add src scripts

        scriptSrcs.forEach(src => {
          const scriptElement = document.createElement('script');
          scriptElement.setAttribute('type', 'text/javascript');
          scriptElement.src = src;
          if (id === 'generic-script-all-page') {
            head.appendChild(scriptElement);
          }
          if (id === 'generic-script') {
            body.appendChild(scriptElement);
          }
        });

        //body.appendChild(scriptWrapper);
      }
    }
  }

  /* JSON API */
  getCustomSettings(eventId) {
    if (eventId == '') {
      return of({});
    }
    
    let selfReg = '';
    if (this._helperService.isSelfregistration()) {
      selfReg = '?sr=true';
    }
    //if (!this.isLoggedInAsAdmin) {
    return this._http
      .get(
        //`${environment.apiUrl}/event/${eventId}/settings-temp-development`,
        `${environment.protocol}${environment.webApiUrl}/event/${eventId}/settings${selfReg}`
      )
      .pipe(
        map(res => {
          try {
            const settings = res.json() || {};

            // TODO remove this fallback
            if (!settings.hasOwnProperty('isTicketHolderVisible')) {
              settings.isTicketHolderVisible = true;
            }

            return settings;
          } catch (err) {
            this._statusBarService.setStatus(err, 'error');
          }
        }),
        catchError(e => {
          return this._errorHandlingService.errorHandler(e);
        })
      );
    /* }

    return this._http
      .get(window.location.origin + '/assets/admin/settings.json')
      .map(resp => {
        return resp.json();
      })
      .catch(e => {
        return this._errorHandlingService.errorHandler(e);
      }); */
  }

  getLocalizedImages(eventId: number, countryCode: string) {
    return this._http
      .get(
        `${environment.protocol}${environment.webApiUrl}/event/${eventId}/images/${countryCode}`
      )
      .pipe(
        map(res => {
          try {
            return res.json() || {};
          } catch (err) {
            this._statusBarService.setStatus(err, 'error');
          }
        }),
        catchError(e => {
          return this._errorHandlingService.errorHandler(e);
        })
      );
  }

  /*   getCustomLinks(event) {
    return this._http
      .get(
        `${environment.apiUrl}/event-links/${event.exhibitionId}?lang=${event.exhibitionLanguage}`,
      )
      .map(res => {
        try {
          return res.json() || {};
        } catch (err) {
          this._statusBarService.setStatus(err, 'error');
        }
      })
      .catch(e => {
        return this._errorHandlingService.errorHandler(e);
      });
  } */

  getRandomSponsor(eventId, countryCode?: string) {
    return (
      this._http
        //.get(`${environment.apiUrl}/random-sponsor/${eventId}`)
        .get(
          `${environment.protocol}${environment.webApiUrl}/event/${eventId}/random-sponsor?language=${countryCode}`
        )
        .pipe(
          map(res => {
            try {
              return res.json() || {};
            } catch (err) {
              this._statusBarService.setStatus(err, 'error');
            }
          }),
          catchError(e => {
            return this._errorHandlingService.errorHandler(e);
          })
        )
    );
  }

  getOperatorSettings() {
    let selfReg = '';
    if (this._helperService.isSelfregistration()) {
      selfReg = '?sr=true';
    }
    return this._http
      .get(
        `${environment.protocol}${environment.webApiUrl}/operator/settings${selfReg}`
      )
      .pipe(
        map(res => {
          try {
            return res.json() || {};
          } catch (err) {
            this._statusBarService.setStatus(err, 'error');
          }
        }),
        catchError(e => {
          return this._errorHandlingService.errorHandler(e);
        })
      );
  }
}
