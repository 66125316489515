import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';

import { ErrorHandlingService } from '../../shared/error-handling/error-handling.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../services-with-reducers/user/user.service';
import { catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  //private errorCollector: Subject<string> = new Subject();

  constructor(private _errorHandlingService: ErrorHandlingService) {
    /* this.errorCollector
      // split stream to multiple streams based on error name, so we dont show the same multiple times within debounce time
/      .groupBy((err: string) => {
        return err;
      })
      .flatMap(errNames => {
        // debounce each error code separately
        return errNames.debounceTime(1000);
      })

      .subscribe(error => {
this._errorHandlingService.errorHandler(error);
      }); */
  }

  /*
     this interceptor is responsible for showing error messages from server
  */

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {},
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            this._errorHandlingService.errorHandler(err);
          }
        }
      )
      //catchError((error, caught) => {
      //   //intercept the respons error and displace it to the console
      //   this._errorHandlingService.errorHandler(error);
      //   //this.errorCollector.next(error)
      //   console.log('ERRRRRRROR', error);
      //   return of(error);
      // }) as any);
    );
  }
}
