var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { combineLatest as observableCombineLatest, Subject } from 'rxjs';
import { filter, first, skip, takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CustomizationService } from '../../shared/services-with-reducers/customization/customization.service';
import { FormsService } from '../../shared/forms/forms.service';
import { HelperService } from '../../shared/services-with-reducers/helpers/helper.service';
import { prepareDisclaimerCheckboxes } from '../../shared/services-with-reducers/customization/forms/disclaimer-checkboxes-data';
import * as exhibitionActions from '../../shared/services-with-reducers/exhibition/exhibition.actions';
import * as fromRoot from '../../app.reducer';
import * as stepsActions from '../../shared/services-with-reducers/step-forms/steps-forms.actions';
import * as ticketActions from '../../shared/services-with-reducers/tickets/ticket.actions';
import * as helperActions from '../../shared/services-with-reducers/helpers/helper.actions';
var WebShopDownloadComponent = /** @class */ (function () {
    function WebShopDownloadComponent(route, _formsService, _location, _customizationService, _store, _translate, _helperService) {
        var _this = this;
        this.route = route;
        this._formsService = _formsService;
        this._location = _location;
        this._customizationService = _customizationService;
        this._store = _store;
        this._translate = _translate;
        this._helperService = _helperService;
        this.checkboxesFormsActionName = ['ticket-download-policy'];
        this.allFormsValid = false;
        this.feedbackControlObject = {};
        this.feedbackMessages = [{ translate: false, label: 'helo' }];
        this.hasValidTicketHash = false;
        this.loading = true;
        this.isMobile = false;
        this.isQuestionnaireRequired = true;
        this.downloadTicketButton = '';
        this.childForms = {};
        this.validationControll = {
            policy: false,
            questionnaire: false,
            ticketholder: false
        };
        this.unsubscribe = new Subject();
        this.setPolicy = function (inputs, rerender) {
            // get updated inputs, now we need to everwrite the old set with updated ones
            if (!_this.exhibitorSettings) {
                return;
            }
            _this.checkboxesInputs = inputs;
            var arePrivacyPolicyInputsValid = _this.checkboxesInputs.filter(function (input) {
                return (input.options[0].value &&
                    input.required) ||
                    !input.required;
            }).length === _this.checkboxesInputs.length;
            var validityData = {
                formName: 'policy',
                valid: arePrivacyPolicyInputsValid,
                inputs: _this.checkboxesInputs,
                form: _this.checkboxesForm,
            };
            _this.setValidity(validityData);
        };
        this.isMobile = this._helperService.isMobile();
        // small reset on page reload
        this._store.dispatch(new exhibitionActions.PartialResetReducer());
        this._store.dispatch(new ticketActions.SetTicketHolder(null));
        this._store.dispatch(new helperActions.SetSpinnerValue(false));
        observableCombineLatest(this.route.params, this.route.queryParams, this._store.select(fromRoot.getTickets))
            .pipe(filter(function (_a) {
            var params = _a[0];
            if (params) {
                return true;
            }
        }), takeUntil(this.unsubscribe))
            .subscribe(function (_a) {
            var params = _a[0], queryParams = _a[1], tickets = _a[2];
            _this._store.dispatch(new exhibitionActions.SelectAction(params.id));
            _this.eventId = params.id;
            if (queryParams.t) {
                _this.loading = true;
                // set validity of hash to null as we cannot say now whether it is valid or not
                _this._store.dispatch(new ticketActions.SetTicketClaimedHashValid(null));
                // load the ticket holder based on the hash comming in URL
                _this._store.dispatch(new ticketActions.LoadTicketHolder(queryParams.t));
                _this._store.dispatch(new ticketActions.SetClaimedTicketHash(queryParams.t));
                _this._location.replaceState('/webshop-download/' + params.id);
            }
        });
        // ensure we have right translation for privacy checkbox
        this._store
            .select(fromRoot.getExhibitionSettings)
            .pipe(filter(function (settings) {
            return !!settings /* && !!data[1] */;
        }), first())
            .subscribe(function (settings) {
            _this.exhibitorSettings = settings;
            _this._customizationService.setStyles();
            var privacySubscription = _this._translate
                .stream([
                'personalize.privacy-link',
                'personalize.privacy-link.text',
                'personalize.privacy-optional',
                'personalize.privacy-optional.text',
            ]) // stream function is triggered again on language change
                .subscribe(function (termsTranslations) {
                var confirmationCheckboxes = prepareDisclaimerCheckboxes(settings, termsTranslations, _this._helperService.isSelfregistration(), false, false);
                var privacyPayload = {
                    formInfo: ['personal', 'privacy'],
                    inputSet: {
                        rerender: false,
                        list: confirmationCheckboxes
                    }
                };
                // trigger initial validation for privacy and policy
                _this.setPolicy(confirmationCheckboxes, false);
                _this._store.dispatch(new stepsActions.SetInputs(privacyPayload));
            });
        });
    }
    WebShopDownloadComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._store
            .select(fromRoot.getClaimedTicketHash)
            .pipe(filter(function (hash) { return !!hash; }), takeUntil(this.unsubscribe))
            .subscribe(function (hash) {
            _this._store.dispatch(new ticketActions.LoadTicketHolder(hash));
            _this._store
                .select(fromRoot.getTicketHolder)
                .pipe(filter(function (holder) { return !!holder; }))
                .subscribe(function (holder) {
                _this.isQuestionnaireRequired = holder.isQuestionnaireRequired;
                // perform reset only if questionnaire is not required - as it is asynchronous, sometimes it can reset filled questionnaire and therefore user cannot download ticket
                if (!holder.isQuestionnaireRequired) {
                    _this.setValidity({
                        formName: 'questionnaire',
                        valid: true,
                        inputs: null,
                        form: null
                    });
                }
                else {
                    _this._store.pipe(select(fromRoot.getTicketHolderQuestionnaireInputs), first())
                        .subscribe(function (data) {
                        if (data === null || !data.length) {
                            var ticketPersonIds = holder.tickets.map(function (p) {
                                return p.ticketPersonId;
                            });
                            _this._store.dispatch(new ticketActions.GetTicketHolderQuestionnaire({
                                eventId: _this.eventId,
                                ticketPersonIds: ticketPersonIds
                            }));
                        }
                    });
                }
            });
        });
        this.spinnerValue$ = this._store.select(fromRoot.getSpinnerValue);
        this._store
            .select(fromRoot.getClaimedTicketHashValid)
            .pipe(filter(function (isValidHash) {
            return isValidHash !== null;
        }), takeUntil(this.unsubscribe))
            .subscribe(function (isValidHash) {
            _this.loading = false;
            _this.hasValidTicketHash = isValidHash;
        });
        this._store
            .select(fromRoot.getPrivacyInput)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(function (checkboxes) {
            if (checkboxes && checkboxes.list.length) {
                if (checkboxes || !_this.checkboxesForm) {
                    _this.checkboxesInputs = checkboxes.list.slice(0);
                    _this.checkboxesForm = _this._formsService.toFormGroup(checkboxes.list);
                    // for validation set the policy value to false as well
                    _this.checkboxesInputs.map(function (item) { return item; });
                    if (_this.checkboxesInputs[0].required === false) {
                        _this.validationControll.policy = true;
                    }
                    else {
                        var validationCallback = function () {
                            _this.setPolicy(checkboxes.list, false);
                        };
                        _this._helperService.triggerCallbackOnceFormValidationIsDone(_this.checkboxesForm, validationCallback);
                    }
                }
            }
            else {
                // if checkbox for privacy and policy is disabled in backoffice, mark it as valid
                _this.validationControll.policy = true;
            }
        });
    };
    WebShopDownloadComponent.prototype.ngOnDestroy = function () {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    };
    WebShopDownloadComponent.prototype.setValidity = function (validityData) {
        var _this = this;
        var formName = validityData.formName, valid = validityData.valid, inputs = validityData.inputs, form = validityData.form;
        this.childForms[formName] = validityData;
        if (inputs && form) {
            // update object holding invalid inputs
            this.feedbackControlObject = this._formsService.generateValidationFeedback(inputs, form, formName, this.feedbackControlObject);
            this.feedbackMessages = Object.keys(this.feedbackControlObject).map(function (key) { return _this.feedbackControlObject[key]; });
        }
        // set form validity and then check if all fotsm in page are valid
        this.validationControll[formName] = valid;
        this.allFormsValid = Object.keys(this.validationControll).reduce(function (acc, curr) {
            return acc && _this.validationControll[curr];
        }, true);
    };
    WebShopDownloadComponent.prototype.downloadTiket = function (ticketType) {
        var _this = this;
        this.downloadTicketButton = ticketType;
        this._store.dispatch(new ticketActions.SetPostTicketHolderFormResult(false));
        this._store.dispatch(new helperActions.SetSpinnerValue(true));
        // wait for response but ignore the first one already in store
        this._store
            .select(fromRoot.getTicketHolderSubmitResult)
            .pipe(skip(1), first())
            .subscribe(function (success) {
            if (success) {
                _this._store
                    .select(fromRoot.getClaimedTicketHash)
                    .pipe(first())
                    .subscribe(function (hash) {
                    if (ticketType === 'normalTicket') {
                        _this._store.dispatch(new ticketActions.DownloadTicket(hash));
                    }
                    if (ticketType === 'mobileTicket') {
                        _this._store.dispatch(new ticketActions.DownloadMobileTicket(hash));
                    }
                    if (ticketType === 'passBook') {
                        _this._store.dispatch(new ticketActions.DownloadPassBook(hash));
                    }
                });
            }
        });
        observableCombineLatest(this._store.select(fromRoot.getClaimedTicketHash), this._store.select(fromRoot.getSelectedExhibitionId), this._store.select(fromRoot.getLanguage), this._store.select(fromRoot.getProfile), function (hash, eventId, language, profile) {
            return { hash: hash, eventId: eventId, language: language, profile: profile };
        })
            .pipe(first())
            .subscribe(function (data) {
            var hash = data.hash, eventId = data.eventId, language = data.language, profile = data.profile;
            var ticketHolderValues;
            // if there is a newsletter check box we use that value
            // if there is no newsletter checkbox and there is profile take the value from there
            // if nothing from above we just use false value
            if (_this.childForms.ticketholder.form.value.newsletter) {
                var value = _this.childForms.ticketholder.form.value.newsletter;
                var newsletterObjectValue = value.newsletter_Newsletter;
                if (newsletterObjectValue !== undefined && value !== null) {
                    value = newsletterObjectValue;
                }
                ticketHolderValues = __assign({}, _this.childForms.ticketholder.form.value, { hasNewsletter: value });
            }
            else if (!!profile) {
                ticketHolderValues = __assign({}, _this.childForms.ticketholder.form.value, { hasNewsletter: profile.newsletterChecked });
            }
            else {
                ticketHolderValues = __assign({}, _this.childForms.ticketholder.form.value, { hasNewsletter: false });
            }
            var ticketHolderEmail = _this.childForms.ticketholder.inputs.find(function (input) { return input.key === 'email'; });
            if (ticketHolderEmail) {
                ticketHolderValues['email'] = ticketHolderEmail.value;
            }
            var policyForm = _this.childForms.policy;
            var policyInput = policyForm &&
                policyForm.inputs.find(function (input) { return input.key === 'disclaimer'; });
            var privacyPolicyOption = policyInput &&
                policyInput.options.find(function (option) { return option.key === 'disclaimerConfirmation'; });
            if (privacyPolicyOption) {
                ticketHolderValues['PrivacyPolicyAccepted'] =
                    privacyPolicyOption.value;
            }
            var policyOptionalInput = policyForm &&
                policyForm.inputs.find(function (input) { return input.key === 'disclaimerOptional'; });
            var privacyPolicyOptionalOption = policyOptionalInput &&
                policyOptionalInput.options.find(function (option) { return option.key === 'disclaimerOptionalConfirmation'; });
            if (privacyPolicyOptionalOption) {
                ticketHolderValues['PrivacyPolicyOptionalAccepted'] =
                    privacyPolicyOptionalOption.value;
            }
            delete ticketHolderValues['newsletter'];
            var questionnaireFormInputs = _this.childForms.questionnaire.inputs;
            var questionnaire = {
                eventId: eventId,
                values: []
            };
            if (_this.childForms.questionnaire.inputs) {
                questionnaire.values = _this._helperService.processQuestionnaireValuesBeforeSave(questionnaireFormInputs);
            }
            ticketHolderValues.dateOfBirth = _this._helperService.getUTCdate(ticketHolderValues.dateOfBirth);
            var dataToSave = {
                hash: hash,
                ticketHolder: ticketHolderValues,
                questionnaire: questionnaire,
                language: language
            };
            _this._store.dispatch(new ticketActions.PostTicketHolderForm(dataToSave));
        });
    };
    return WebShopDownloadComponent;
}());
export { WebShopDownloadComponent };
