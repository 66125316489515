import * as fromRoot from '../../../app.reducer';
import * as ticketActions from '../../../shared/services-with-reducers/tickets/ticket.actions';

import { PackagesService } from './../../../shared/services-with-reducers/tickets/packages.service';
import { FormsService } from './../../../shared/forms/forms.service';
import { PackageModel } from './../../../shared/services-with-reducers/tickets/ticket.interface';
import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

const ERROR_PACKAGE_LIMIT_WARNING = 'packages-selection.warning';

@Component({
  selector: 'app-package-counter',
  templateUrl: './package-counter.component.html',
  styleUrls: ['./package-counter.component.scss']
})
export class PackageCounterComponent implements OnInit, OnDestroy {
  @Output()
  packageCounterChange = new EventEmitter<{
    value: number;
    previousValue: number;
    decrease: boolean;
  }>();

  @Output()
  packageCounterWarning: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  maxLimit: number;
  @Input()
  isPackageLoading: boolean;
  @Input()
  package: PackageModel;
  
  public isPackageTicketsBookingLoading: boolean = false;
  public isPackageDisabled: boolean = false;
  public wasPackageCounterTouched: boolean;
  private previousValue: number;
  private counterValue: number;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private _packagesService: PackagesService,
    private _formsService: FormsService,
    private _store: Store<fromRoot.State>
  ) { }

  ngOnInit() {
    this.package.count = this._packagesService.getPackageCount(this.package, this.package.count)
    this.previousValue = this.package.count;

    this._packagesService.enableDisabledPackageEvent.subscribe(() => { 
      this.packageCounterWarning.emit('');
      this.package.isDisabled = false;
    });

    this.subscriptions.add(
      this._store.pipe(
        select(fromRoot.getIsAnonymousTicketTaken),
        filter(anonymousTicketTaken => anonymousTicketTaken)
      )
      .subscribe(() => {
        if (this.package.count > 0) {
          this.onValueChange({ target: { value: 0 } });
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  get counter() {
    return this.counterValue;
  }

  set counter(value) {
    this._store.dispatch(new ticketActions.SetIsPackageLoading(true));
    this._formsService.setFormValidity(false, null, ['tickets', 'packageLoading']);

    this.packageCounterWarning.emit('');

    const changeCounterSuccess = () => {
      this.package.count = this._packagesService.getPackageCount(this.package, value);
      this.counterValue = this.package.count;
      
      this.packageCounterChange.emit({
        value: this.counterValue,
        previousValue: this.previousValue,
        decrease: this.counterValue - this.previousValue < 0 ? true : false
      });

      this.previousValue = this.counterValue;
    };

    changeCounterSuccess();
  }

  onDecrease() {
    this.package.isDisabled = false;

    this.onValueChange({
      target: {
        value: this.previousValue > 0 ? this.previousValue - 1 : 0
      }
    });
  }

  onIncrease() {
    const { isDisabled, disableNext } = this._packagesService.disableAddingPackageOnTicketLimit(this.package);
    

    if (isDisabled) {
      this.package.isDisabled = isDisabled;
      this.packageCounterWarning.emit(ERROR_PACKAGE_LIMIT_WARNING);
    }
    
    if (!isDisabled) {
      this.onValueChange({
        target: {
          value: this.previousValue >= 0 ? this.previousValue + 1 : this.previousValue
        }
      });
    }

    if (disableNext) {
      this.package.isDisabled = disableNext;
      this.packageCounterWarning.emit(ERROR_PACKAGE_LIMIT_WARNING);
    }
  }

  onValueChange(event) {
    const value = Number(event.target.value);

    this.counterValue = value > 0 ? value : 0;
    this.counter = this.counterValue;
  }
}
