<app-banner></app-banner>
<div class="container-fluid">
    <div class="page-content">
        <app-loader *ngIf="!route.children.length || isLoading"></app-loader>
        <app-step-navigation *ngIf="route.children.length && !eventIsOver && !isLoading"></app-step-navigation>
        <router-outlet *ngIf="!isLoading"></router-outlet>
    </div>
</div>

<!-- #3863 - IE not supported -->
<app-browser-check *ngIf="!isSelfRegistrationEnabled">
</app-browser-check>

<app-modal-window
  [modalWindowActive]="modalWindowOpen"
  translateTitle="true"
  [modalWindowTitle]="'personalize.buyer-info.buyer-form-filled'"
  >
  <button
    class="button"
    modalWindow-submit
    (click)="modalWindowVote(true)"
  >
    <span data-translation="personalize.buyer-info.buyer-form-copy-yes">{{
      'personalize.buyer-info.buyer-form-copy-yes' | translate | hideTranslation
    }}</span>
  </button>
  <button
    class="button button-option"
    modalWindow-close
    (click)="modalWindowVote(false)"
  >
    <span data-translation="personalize.buyer-info.buyer-form-copy-no">{{
      'personalize.buyer-info.buyer-form-copy-no' | translate | hideTranslation
    }}</span>
  </button>
</app-modal-window>