var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { ActionTypes } from './colorizer.actions';
import cloneDeep from 'lodash.clonedeep';
export var themeDefault = require('../../assets/admin/theme-default.json');
export var initialState = {
    styleTemplateId: null,
    styleTemplates: null,
    selectedStyleTemplateStyles: {
        priority: null,
        css: null,
        variables: null
    }
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    var clonedInitialState = cloneDeep(initialState);
    switch (action.type) {
        case ActionTypes.SET_ALL_STYLES:
            return __assign({}, state, { styleTemplates: action.payload });
        case ActionTypes.SET_STYLE:
            var stylesVariables = action.payload ? action.payload.variables : {};
            return __assign({}, state, { selectedStyleTemplateStyles: __assign({}, action.payload, { variables: __assign({}, themeDefault, stylesVariables) }) });
        case ActionTypes.SET_STYLE_ID:
            var styleID = action.payload;
            // dont trigger new state if the id is the same as previous one
            if (state.styleTemplateId === styleID) {
                return state;
            }
            return __assign({}, state, { styleTemplateId: styleID });
        case ActionTypes.ADD_STYLE_TO_LIST:
            var style = action.payload;
            return __assign({}, state, { styleTemplates: state.styleTemplates.concat([style]), styleTemplateId: style.id });
        case ActionTypes.RESET_REDUCER:
            return clonedInitialState;
        default: {
            return state;
        }
    }
}
export var allStyleTemplates = function (state) { return state.styleTemplates; };
export var getStyle = function (state) { return state.selectedStyleTemplateStyles; };
export var getStyleId = function (state) { return state.styleTemplateId; };
