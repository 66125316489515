import { Component, OnInit, OnDestroy } from '@angular/core';
import * as fromRoot from '../../app.reducer';
import * as stepsFormsActions from '../../shared/services-with-reducers/step-forms/steps-forms.actions';
import * as helperActions from '../../shared/services-with-reducers/helpers/helper.actions';

import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ExhibitionSeriesModel } from '../../shared/services-with-reducers/exhibition/exhibition.interface';
import { LocalizedImagesModel } from '../../shared/services-with-reducers/customization/customization.interfaces';

import { AppService } from '../../app.service';
import { ExhibitionService } from '../../shared/services-with-reducers/exhibition/exhibition.service';
import { filter, first } from 'rxjs/operators';
import {
  Observable,
  Subscription,
  combineLatest as observableCombineLatest
} from 'rxjs';

@Component({
  selector: 'app-event-series',
  templateUrl: './event-series.component.html',
  styleUrls: ['./event-series.component.scss']
})
export class EventSeriesComponent implements OnInit, OnDestroy {
  public localizedImages: LocalizedImagesModel;

  public eventSeries: ExhibitionSeriesModel;
  public language: string;
  public activeEvents = [];
  public futureEvents = [];
  public noActiveEvents = false;
  public moreThanOneActiveEvents = false;
  private _subscriptions = new Subscription();

  constructor(
    public _route: ActivatedRoute,
    private _store: Store<fromRoot.State>,
    private _router: Router,
    private _appService: AppService,
    private _exhibitionService: ExhibitionService
  ) {
    this._appService.resetReducers();

    this._subscriptions.add(
      this._store
        .select(fromRoot.getLocalizedImages)
        .pipe(filter(data => !!data))
        .subscribe(image => {
          this.localizedImages = image;
        })
    );

    this._subscriptions.add(
      Observable.combineLatest(
        this._route.params,
        this._store.select(fromRoot.getLanguage)
      ).subscribe(data => {
        const [params, language] = data;

        this._store.dispatch(
          new helperActions.SetEventSeriesPage({
            isEventSeries: true,
            eventSeriesId: params.id
          })
        );

        this._exhibitionService
          .getEventSeries(params.id, language)
          .pipe(filter(eventSeries => !!eventSeries))
          .subscribe(
            (eventSeries: ExhibitionSeriesModel) => {
              this.eventSeries = eventSeries;
              this.activeEvents = this.eventSeries.events.filter(
                event =>
                  new Date(event.startDate).getTime() < Date.now() &&
                  Date.now() < new Date(event.endDate).getTime()
              );
              this.futureEvents = this.eventSeries.events.filter(
                event => new Date(event.startDate).getTime() > Date.now()
              );
              if (this.activeEvents.length === 1) {
                this._store.dispatch(
                  new stepsFormsActions.SetSelectedStep('tickets')
                );
                this.activeEvents.map(event => {
                  this._router.navigate([`webshop/${event.id}`]);
                });
              } else if (this.activeEvents.length > 1) {
                this.moreThanOneActiveEvents = true;
              }
            },
            () => {
              this.noActiveEvents = true;
            }
          );
      })
    );
  }

  ngOnInit() {}

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  goToEvent(eventId) {
    //this._store.dispatch(new stepsFormsActions.SetSelectedStep('tickets'));
    this._router.navigate([`webshop/${eventId}/tickets`]);
  }

  backToHomePage() {
    this._router.navigate(['/']);
  }
}
