import * as fromRoot from '../../app.reducer';
import { OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { Subscription, combineLatest as observableCombineLatest, Subject } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { FormsService } from '../../shared/forms/forms.service';
import { HelperService } from '../../shared/services-with-reducers/helpers/helper.service';
import { select, Store } from '@ngrx/store';
import { GtmService } from '../../shared/gtm/gtmService';
import { TranslateService } from '@ngx-translate/core';
import { FilterPlaceholderPipe } from '../../shared/pipes/filter-placeholder/filter-placeholder.pipe';
import * as ticketActions from '../../shared/services-with-reducers/tickets/ticket.actions';
var WebShopPersonalizeComponent = /** @class */ (function () {
    function WebShopPersonalizeComponent(_store, _formsService, _helperService, _gtmService, _translateService, _filterPlaceholderPipe) {
        this._store = _store;
        this._formsService = _formsService;
        this._helperService = _helperService;
        this._gtmService = _gtmService;
        this._translateService = _translateService;
        this._filterPlaceholderPipe = _filterPlaceholderPipe;
        this.checkboxesFormsActionName = ['personal', 'privacy'];
        this.registrationFormsActionName = ['personal', 'registration'];
        this.visitorQuestionnaireValidation = ['personal', 'visitorQuestionnaire'];
        this.buyerStyle = null;
        this.ticketHolderInputsNotVisible = false;
        this.modalWindowOpen = false;
        this.questionnaireLoaded = false;
        this.isInputChanged$ = new Subject();
        this.showVisitorQuestionnaire = false;
        this._subscriptions = new Subscription();
        this.feedbackControlObject = {};
    }
    WebShopPersonalizeComponent.prototype.ngOnDestroy = function () {
        this._subscriptions.unsubscribe();
    };
    WebShopPersonalizeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isTicketHolderVisible$ = this._store.select(fromRoot.isTicketHolderVisible);
        this._subscriptions.add(observableCombineLatest(this._store.select(fromRoot.isConfirmedEmailRequiredOnPersonalization), this._store.select(fromRoot.showLoginOnPersonalPage), this._store.select(fromRoot.showLoginOnTicketAndPersonalPage)).subscribe(function (_a) {
            var isConfirmedEmailRequiredOnPersonalization = _a[0], showLoginOnPersonalPage = _a[1], showLoginOnTicketAndPersonalPage = _a[2];
            _this.isConfirmedEmailRequiredOnPersonalization = isConfirmedEmailRequiredOnPersonalization;
            var showLogin = isConfirmedEmailRequiredOnPersonalization || showLoginOnPersonalPage || showLoginOnTicketAndPersonalPage;
            if (showLogin) {
                _this._formsService.removeAllStepValidationFeedbacks(_this.registrationFormsActionName);
                if (!isConfirmedEmailRequiredOnPersonalization) {
                    _this._formsService.addStepValidationFeedback(_this.registrationFormsActionName, 'anyBuyerMessageHidden', 'personalize.any.buyer.message.hidden');
                }
            }
            else {
                _this._formsService.removeStepValidationFeedback(_this.registrationFormsActionName, 'anyBuyerMessageHidden');
            }
            _this._formsService.setFormValidity(!showLogin, null, _this.registrationFormsActionName);
        }));
        this._subscriptions.add(observableCombineLatest([
            this._store.pipe(select(fromRoot.getSelectedExhibitionId)),
            this._store.pipe(select(fromRoot.getTicketSelectedSendingOptions))
        ])
            .subscribe(function (_a) {
            var currentEventId = _a[0], sendingOption = _a[1];
            if (sendingOption === 'ticketRetrivalLink') {
                _this._store.pipe(select(fromRoot.getTicketHolderQuestionnaireInputs), first())
                    .subscribe(function (data) {
                    if (data === null || !data.length) {
                        _this._store.dispatch(new ticketActions.GetTicketHolderQuestionnaire({
                            eventId: currentEventId,
                            ticketPersonIds: []
                        }));
                    }
                });
            }
        }));
        observableCombineLatest([
            this._store.pipe(select(fromRoot.getTicketHolderInputSets)),
            this.isTicketHolderVisible$,
            this._store.pipe(select(fromRoot.getExhibitionSettings))
        ])
            .pipe(first())
            .subscribe(function (_a) {
            var ticketHolderInputSets = _a[0], isTicketHolderVisible = _a[1], settings = _a[2];
            if (!isTicketHolderVisible && ticketHolderInputSets.length) {
                _this._formsService.setStepValid(ticketHolderInputSets[0].formInfo);
            }
            if (ticketHolderInputSets && ticketHolderInputSets.length === 0) {
                _this.ticketHolderInputsNotVisible = true;
                return;
            }
            _this.ticketHolderInputsNotVisible = Object.keys(settings.ticketOwnerSettings.fieldSettings).every(function (ticketHolderInputSetting) {
                return (settings.ticketOwnerSettings.fieldSettings[ticketHolderInputSetting].isVisible === false);
            });
            // Make ticketHolderInputs Visible if email or verifyEmail Fields are mandatory on any of the ticket holders
            if (_this.ticketHolderInputsNotVisible) {
                _this.ticketHolderInputsNotVisible = !ticketHolderInputSets.some(function (element) {
                    if (!!element.inputSet && !!element.inputSet.list) {
                        var holderList = element.inputSet.list;
                        var emailField = holderList.find(function (item) { return item.key === 'email'; });
                        var verifyEmailField = holderList.find(function (item) { return item.key === 'verifyEmail'; });
                        var emailRequired = !!emailField ? emailField.required : false;
                        var verifyEmailRequired = !!verifyEmailField ? verifyEmailField.required : false;
                        return (emailRequired || verifyEmailRequired);
                    }
                });
            }
        });
        this.isSelfRegistrationEnabled = this._helperService.isSelfregistration();
        this._subscriptions.add(this._store
            .select(fromRoot.isContinueAsGuest)
            .subscribe(function (isContinueAsGuest) {
            _this.continueAsGuest = isContinueAsGuest;
        }));
        this._subscriptions.add(this._store
            .select(fromRoot.getQuestionnaire)
            .pipe(filter(function (data) {
            // if there are no data for questionnaire, load them
            if (data.list === null) {
                _this._helperService.loadBuyerQuestionnaireViaApi('personal');
                return false;
            }
            var questionnaireLoaded = data.list.length > 0;
            if (!questionnaireLoaded ||
                _this._helperService.isSelfregistration()) {
                /* this._formsService.setFormValidity(true, null, [
                'personal',
                'questionnaire'
              ]);*/
                _this._formsService.setStepValid(['personal', 'questionnaire']);
            }
            return questionnaireLoaded;
        }))
            .subscribe(function () {
            _this.questionnaireLoaded = true;
        }));
        this._subscriptions.add(observableCombineLatest(this._store.select(fromRoot.getPrivacyInput), this._store.select(fromRoot.isConfirmedEmailRequiredOnPersonalization), this._store.select(fromRoot.showLoginOnPersonalPage), this._store.select(fromRoot.showLoginOnTicketAndPersonalPage)).subscribe(function (_a) {
            var checkboxes = _a[0], isConfirmedEmailRequiredOnPersonalization = _a[1], showLoginOnPersonalPage = _a[2], showLoginOnTicketAndPersonalPage = _a[3];
            if (!isConfirmedEmailRequiredOnPersonalization && !showLoginOnPersonalPage && !showLoginOnTicketAndPersonalPage && checkboxes && checkboxes.list.length) {
                if (checkboxes || !_this.checkboxesForm) {
                    _this.checkboxesInputs = checkboxes.list.slice(0);
                    _this.checkboxesForm = _this._formsService.toFormGroup(checkboxes.list, _this.checkboxesFormsActionName);
                }
            }
            else {
                // if there is no privacy input to show we need to mark the form as valid
                _this._formsService.setFormValidity(true, null, _this.checkboxesFormsActionName);
            }
        }));
        this.settings$ = this._store.select(fromRoot.getExhibitionSettings);
        this.showLoginOnPersonalPage$ = this._store.select(fromRoot.showLoginOnPersonalPage);
        this.showLoginOnTicketAndPersonalPage$ = this._store.select(fromRoot.showLoginOnTicketAndPersonalPage);
        this.showLoginOnTicketPage$ = this._store.select(fromRoot.showLoginOnTicketPage);
        this.settings$
            .pipe(filter(function (settings) {
            return !!settings;
        }), first())
            .subscribe(function (settings) {
            _this.exhibitionSettings = settings;
        });
        this.selectedSendingOption$ = this._store.select(fromRoot.getTicketSelectedSendingOptions);
        this.selectedSendingOption$.subscribe(function (selectedOption) {
            _this.selectedSendingOption = selectedOption;
        });
        this._subscriptions.add(observableCombineLatest(this.showLoginOnPersonalPage$, this.showLoginOnTicketAndPersonalPage$, this._store.select(fromRoot.isUserLoggedIn)).subscribe(function (data) {
            var showLoginOnPersonalPage = data[0], showLoginOnTicketAndPersonalPage = data[1], isLogged = data[2];
            if (!isLogged &&
                (showLoginOnPersonalPage || showLoginOnTicketAndPersonalPage)) {
                _this.buyerStyle = { display: 'none' };
            }
            else {
                _this.buyerStyle = null;
            }
        }));
        // US2870 - set initial
        this._subscriptions.add(this._store.pipe(select(fromRoot.getBuyerVisitorCheckbox))
            .pipe(filter(function (data) { return !!data; }))
            .subscribe(function (buyerVisitorCheckbox) {
            _this.showVisitorQuestionnaire = buyerVisitorCheckbox.showVisitorQuestionnaire;
        }));
    };
    WebShopPersonalizeComponent.prototype.setVisitorQuestionnaireValidity = function (validityData) {
        var _this = this;
        var formName = validityData.formName, valid = validityData.valid, inputs = validityData.inputs, form = validityData.form;
        this._formsService.setFormValidity(valid, form, this.visitorQuestionnaireValidation);
        if (inputs && form) {
            // update object holding invalid inputs
            this.feedbackControlObject = this._formsService.generateValidationFeedback(inputs, form, formName, this.feedbackControlObject);
            // US2870 - first remove all previous feedback messages
            this._formsService.removeAllStepValidationFeedbacks(this.visitorQuestionnaireValidation);
            // US2870 - add feedback messages for those fields which are required
            Object.keys(this.feedbackControlObject).forEach(function (key) {
                var feedbackObject = _this.feedbackControlObject[key];
                _this._formsService.addStepValidationFeedback(_this.visitorQuestionnaireValidation, feedbackObject.label, feedbackObject.label);
            });
        }
    };
    WebShopPersonalizeComponent.prototype.allowAdditionalDataToShow = function (isAdditionalData) {
        this.showVisitorQuestionnaire = isAdditionalData;
        if (isAdditionalData) {
            this._helperService.checkQuestionnairesForDuplicates();
        }
    };
    WebShopPersonalizeComponent.prototype.inputChanged = function (event) {
        this.isInputChanged$.next(event);
    };
    WebShopPersonalizeComponent.prototype.openIframe = function ($event, url) {
        $event.preventDefault();
        this._helperService.openIframe(url);
    };
    WebShopPersonalizeComponent.prototype.openPrivacyModalWindow = function (url) {
        var translatedValue = this._translateService.instant('personalize.privacy-link');
        var title = this._filterPlaceholderPipe.transform(translatedValue);
        this.openModalWindow(url, title, false);
    };
    WebShopPersonalizeComponent.prototype.openModalWindow = function (url, title, translateTitle) {
        if (translateTitle === void 0) { translateTitle = true; }
        this.modalWindowOpen = true;
        this.modalUrl = url;
        this.modalTitle = title;
        this.modalTranslateTitle = translateTitle;
    };
    WebShopPersonalizeComponent.prototype.closeModalWindow = function (event) {
        event.stopPropagation();
        this.modalWindowOpen = false;
    };
    WebShopPersonalizeComponent.prototype.closeModalWindowOnRightClick = function (event) {
        event.stopPropagation();
        this.modalWindowOpen = false;
    };
    WebShopPersonalizeComponent.prototype.ngAfterViewInit = function () {
        this._gtmService.pushPersonalization();
    };
    return WebShopPersonalizeComponent;
}());
export { WebShopPersonalizeComponent };
