import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';

import {
  combineLatest as observableCombineLatest,
  Observable,
  Subject
} from 'rxjs';

import { filter, first, skip, takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { CustomizationService } from '../../shared/services-with-reducers/customization/customization.service';
import { FormsService } from '../../shared/forms/forms.service';
import { HelperService } from '../../shared/services-with-reducers/helpers/helper.service';

import { prepareDisclaimerCheckboxes } from '../../shared/services-with-reducers/customization/forms/disclaimer-checkboxes-data';
import { InputBase } from '../../shared/forms/inputs/input-base.class';
import { ExhibitionSettingModel } from '../../shared/services-with-reducers/customization/customization.interfaces';
import { FormInputsPayloadModel } from '../../shared/services-with-reducers/step-forms/step.interface';
import { InputsListModel } from '../../shared/services-with-reducers/step-forms/step.interface';
import {
  TicketHolderModel
} from '../../shared/services-with-reducers/tickets/ticket.interface';
import * as exhibitionActions from '../../shared/services-with-reducers/exhibition/exhibition.actions';
import * as fromRoot from '../../app.reducer';
import * as stepsActions from '../../shared/services-with-reducers/step-forms/steps-forms.actions';
import * as ticketActions from '../../shared/services-with-reducers/tickets/ticket.actions';
import * as helperActions from '../../shared/services-with-reducers/helpers/helper.actions';

@Component({
  moduleId: module.id,
  selector: 'app-web-shop-download',
  templateUrl: './web-shop-download.component.html',
  styleUrls: ['./web-shop-download.component.scss']
})
export class WebShopDownloadComponent implements OnInit, OnDestroy {
  public checkboxesInputs: InputBase<any>[];
  public checkboxesForm: FormGroup;
  public checkboxesFormsActionName = ['ticket-download-policy'];
  public allFormsValid = false;
  public feedbackControlObject: Object = {};
  public feedbackMessages = [{ translate: false, label: 'helo' }];
  public hasValidTicketHash = false;
  public loading = true;
  public isMobile: boolean = false;
  public ticketHolderData: TicketHolderModel;
  public isQuestionnaireRequired = true;
  public spinnerValue$: Observable<boolean>;
  public eventId: number;
  public exhibitorSettings: ExhibitionSettingModel;
  public downloadTicketButton: string = '';

  private childForms: {
    ticketholder?: any;
    questionnaire?: any;
    policy?: any;
  } = {};

  private validationControll = {
    policy: false,
    questionnaire: false,
    ticketholder: false
  };

  private unsubscribe = new Subject();

  constructor(
    public route: ActivatedRoute,
    private _formsService: FormsService,
    private _location: Location,
    private _customizationService: CustomizationService,
    private _store: Store<fromRoot.State>,
    private _translate: TranslateService,
    private _helperService: HelperService
  ) {
    this.isMobile = this._helperService.isMobile();

    // small reset on page reload
    this._store.dispatch(new exhibitionActions.PartialResetReducer());
    this._store.dispatch(new ticketActions.SetTicketHolder(null));
    this._store.dispatch(new helperActions.SetSpinnerValue(false));

    observableCombineLatest(
      this.route.params,
      this.route.queryParams,
      this._store.select(fromRoot.getTickets)
    )
      .pipe(
        filter(([params]) => {
          if (params) {
            return true;
          }
        }),
        takeUntil(this.unsubscribe)
      )
      .subscribe(([params, queryParams, tickets]) => {
        this._store.dispatch(new exhibitionActions.SelectAction(params.id));
        this.eventId = params.id;

        if (queryParams.t) {
          this.loading = true;

          // set validity of hash to null as we cannot say now whether it is valid or not
          this._store.dispatch(
            new ticketActions.SetTicketClaimedHashValid(null)
          );
          // load the ticket holder based on the hash comming in URL
          this._store.dispatch(
            new ticketActions.LoadTicketHolder(queryParams.t)
          );
          this._store.dispatch(
            new ticketActions.SetClaimedTicketHash(queryParams.t)
          );

          this._location.replaceState('/webshop-download/' + params.id);
        }
      });

    // ensure we have right translation for privacy checkbox

    this._store
      .select(fromRoot.getExhibitionSettings)
      .pipe(
        filter(settings => {
          return !!settings /* && !!data[1] */;
        }),
        first()
      )
      .subscribe(settings => {
        this.exhibitorSettings = settings;

        this._customizationService.setStyles();

        const privacySubscription = this._translate
          .stream([
            'personalize.privacy-link',
            'personalize.privacy-link.text',
            'personalize.privacy-optional',
            'personalize.privacy-optional.text',
          ]) // stream function is triggered again on language change
          .subscribe(termsTranslations => {
            const confirmationCheckboxes = prepareDisclaimerCheckboxes(
              settings,
              termsTranslations,
              this._helperService.isSelfregistration(),
              false,
              false
            );
            const privacyPayload: FormInputsPayloadModel = {
              formInfo: ['personal', 'privacy'],
              inputSet: {
                rerender: false,
                list: confirmationCheckboxes
              }
            };
            // trigger initial validation for privacy and policy
            this.setPolicy(confirmationCheckboxes, false);

            this._store.dispatch(new stepsActions.SetInputs(privacyPayload));
          });
      });
  }

  ngOnInit() {
    this._store
      .select(fromRoot.getClaimedTicketHash)
      .pipe(
        filter(hash => !!hash),
        takeUntil(this.unsubscribe)
      )
      .subscribe(hash => {
        this._store.dispatch(new ticketActions.LoadTicketHolder(hash));

        this._store
          .select(fromRoot.getTicketHolder)
          .pipe(filter(holder => !!holder))
          .subscribe((holder: TicketHolderModel) => {
            this.isQuestionnaireRequired = holder.isQuestionnaireRequired;

            // perform reset only if questionnaire is not required - as it is asynchronous, sometimes it can reset filled questionnaire and therefore user cannot download ticket
            if (!holder.isQuestionnaireRequired) {
              this.setValidity({
                formName: 'questionnaire',
                valid: true,
                inputs: null,
                form: null
              });
            } else {
              this._store.pipe(
                select(fromRoot.getTicketHolderQuestionnaireInputs),
                first()
              )
              .subscribe(data => {
                if (data === null || !data.length) {
                  const ticketPersonIds: number[] = holder.tickets.map(p => {
                    return p.ticketPersonId;
                  });
    
                  this._store.dispatch(
                    new ticketActions.GetTicketHolderQuestionnaire({
                      eventId: this.eventId,
                      ticketPersonIds: ticketPersonIds
                    })
                  );
                }
              });
            }
          });
      });

    this.spinnerValue$ = this._store.select(fromRoot.getSpinnerValue);

    this._store
      .select(fromRoot.getClaimedTicketHashValid)
      .pipe(
        filter(isValidHash => {
          return isValidHash !== null;
        }),
        takeUntil(this.unsubscribe)
      )
      .subscribe((isValidHash: boolean) => {
        this.loading = false;
        this.hasValidTicketHash = isValidHash;
      });

    this._store
      .select(fromRoot.getPrivacyInput)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((checkboxes: InputsListModel) => {
        if (checkboxes && checkboxes.list.length) {
          if (checkboxes || !this.checkboxesForm) {
            this.checkboxesInputs = checkboxes.list.slice(0);
            this.checkboxesForm = this._formsService.toFormGroup(
              checkboxes.list
            );
            // for validation set the policy value to false as well
            this.checkboxesInputs.map(item => item);
            if (this.checkboxesInputs[0].required === false) {
              this.validationControll.policy = true;
            } else {
              const validationCallback = () => {
                this.setPolicy(checkboxes.list, false);
              };

              this._helperService.triggerCallbackOnceFormValidationIsDone(
                this.checkboxesForm,
                validationCallback
              );
            }
          }
        } else {
          // if checkbox for privacy and policy is disabled in backoffice, mark it as valid
          this.validationControll.policy = true;
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public setPolicy = (inputs: InputBase<any>[], rerender: boolean) => {
    // get updated inputs, now we need to everwrite the old set with updated ones

    if (!this.exhibitorSettings) {
      return;
    }
    
    this.checkboxesInputs = inputs;

    const arePrivacyPolicyInputsValid = this.checkboxesInputs.filter(input => 
      (input.options[0].value && 
        input.required) ||
        !input.required
    ).length === this.checkboxesInputs.length;

    const validityData = {
      formName: 'policy',
      valid: arePrivacyPolicyInputsValid,
      inputs: this.checkboxesInputs,
      form: this.checkboxesForm,
    };

    this.setValidity(validityData);
  };

  setValidity(validityData) {
    const { formName, valid, inputs, form } = validityData;

    this.childForms[formName] = validityData;

    if (inputs && form) {
      // update object holding invalid inputs
      this.feedbackControlObject = this._formsService.generateValidationFeedback(
        inputs,
        form,
        formName,
        this.feedbackControlObject
      );

      this.feedbackMessages = Object.keys(this.feedbackControlObject).map(
        key => this.feedbackControlObject[key]
      );
    }

    // set form validity and then check if all fotsm in page are valid
    this.validationControll[formName] = valid;

    this.allFormsValid = Object.keys(this.validationControll).reduce(
      (acc, curr) => {
        return acc && this.validationControll[curr];
      },
      true
    );
  }

  downloadTiket(ticketType) {
    this.downloadTicketButton = ticketType;
    this._store.dispatch(
      new ticketActions.SetPostTicketHolderFormResult(false)
    );
    this._store.dispatch(new helperActions.SetSpinnerValue(true));

    // wait for response but ignore the first one already in store
    this._store
      .select(fromRoot.getTicketHolderSubmitResult)
      .pipe(
        skip(1),
        first()
      )
      .subscribe((success: boolean) => {
        if (success) {
          this._store
            .select(fromRoot.getClaimedTicketHash)
            .pipe(first())
            .subscribe(hash => {
              if (ticketType === 'normalTicket') {
                this._store.dispatch(new ticketActions.DownloadTicket(hash));
              }
              if (ticketType === 'mobileTicket') {
                this._store.dispatch(
                  new ticketActions.DownloadMobileTicket(hash)
                );
              }
              if (ticketType === 'passBook') {
                this._store.dispatch(new ticketActions.DownloadPassBook(hash));
              }
            });
        }
      });

    observableCombineLatest(
      this._store.select(fromRoot.getClaimedTicketHash),
      this._store.select(fromRoot.getSelectedExhibitionId),
      this._store.select(fromRoot.getLanguage),
      this._store.select(fromRoot.getProfile),
      (hash, eventId, language, profile) => {
        return { hash, eventId, language, profile };
      }
    )
      .pipe(first())
      .subscribe(data => {
        const { hash, eventId, language, profile } = data;
        let ticketHolderValues: TicketHolderModel;

        // if there is a newsletter check box we use that value
        // if there is no newsletter checkbox and there is profile take the value from there
        // if nothing from above we just use false value

        if (this.childForms.ticketholder.form.value.newsletter) {
          let value = this.childForms.ticketholder.form.value.newsletter;
          let newsletterObjectValue = value.newsletter_Newsletter;

          if (newsletterObjectValue !== undefined && value !== null) {
            value = newsletterObjectValue;
          }
          ticketHolderValues = {
            ...this.childForms.ticketholder.form.value,
            hasNewsletter: value
          };
        } else if (!!profile) {
          ticketHolderValues = {
            ...this.childForms.ticketholder.form.value,
            hasNewsletter: profile.newsletterChecked
          };
        } else {
          ticketHolderValues = {
            ...this.childForms.ticketholder.form.value,
            hasNewsletter: false
          };
        }

        let ticketHolderEmail = this.childForms.ticketholder.inputs.find(
          input => input.key === 'email'
        );

        if (ticketHolderEmail) {
          ticketHolderValues['email'] = ticketHolderEmail.value;
        }

        const policyForm = this.childForms.policy;
        const policyInput =
          policyForm &&
          policyForm.inputs.find(input => input.key === 'disclaimer');

        const privacyPolicyOption =
          policyInput &&
          policyInput.options.find(
            option => option.key === 'disclaimerConfirmation'
          );
        
        if (privacyPolicyOption) {
          ticketHolderValues['PrivacyPolicyAccepted'] =
            privacyPolicyOption.value;
        }

        const policyOptionalInput =
          policyForm &&
          policyForm.inputs.find(input => input.key === 'disclaimerOptional');

        const privacyPolicyOptionalOption =
          policyOptionalInput &&
          policyOptionalInput.options.find(
            option => option.key === 'disclaimerOptionalConfirmation'
          );

        if (privacyPolicyOptionalOption) {
          ticketHolderValues['PrivacyPolicyOptionalAccepted'] =
          privacyPolicyOptionalOption.value;
        }

        delete ticketHolderValues['newsletter'];

        const questionnaireFormInputs = this.childForms.questionnaire.inputs;

        let questionnaire = {
          eventId: eventId,
          values: []
        };

        if (this.childForms.questionnaire.inputs) {
          questionnaire.values = this._helperService.processQuestionnaireValuesBeforeSave(
            questionnaireFormInputs
          );
        }

        ticketHolderValues.dateOfBirth = this._helperService.getUTCdate(ticketHolderValues.dateOfBirth);

        let dataToSave = {
          hash,
          ticketHolder: ticketHolderValues,
          questionnaire,
          language
        };

        this._store.dispatch(
          new ticketActions.PostTicketHolderForm(dataToSave)
        );
      });
  }
}
