import * as fromRoot from '../../../app.reducer';
import * as helperActions from '../../../shared/services-with-reducers/helpers/helper.actions';
import * as ticketActions from '../../../shared/services-with-reducers/tickets/ticket.actions';
import { EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { filter, first } from 'rxjs/operators';
import { CustomizationService } from '../../../shared/services-with-reducers/customization/customization.service';
import { FormsService } from '../../../shared/forms/forms.service';
import { HelperService } from '../../../shared/services-with-reducers/helpers/helper.service';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest as observableCombineLatest, Subscription } from 'rxjs';
import { prepareTicketHolderDownloadData, prepareTicketHolderPersonalizationData } from './form-inputs.ticket-holder-download';
import { TicketsService } from '../../../shared/services-with-reducers/tickets/tickets.service';
var DownloadTicketHolderProfileEditComponent = /** @class */ (function () {
    function DownloadTicketHolderProfileEditComponent(_store, _customizationService, _formsService, _helperService, _translateService, _ticketService) {
        var _this = this;
        this._store = _store;
        this._customizationService = _customizationService;
        this._formsService = _formsService;
        this._helperService = _helperService;
        this._translateService = _translateService;
        this._ticketService = _ticketService;
        this.isValid = new EventEmitter();
        this.subscriptions = new Subscription();
        this.actionName = ['ticket-holder-download-data'];
        this.loaded = false;
        this.inputsChanged = function (inputs, rerender) {
            _this.inputs = _this._formsService.updateInputs(_this.inputs, inputs);
            _this._store.dispatch(new ticketActions.SetTicketHolderInputs(_this.inputs));
            _this.isValid.emit({
                formName: 'ticketholder',
                valid: !_this.form.invalid,
                inputs: _this.inputs,
                form: _this.form
            });
            if (rerender) {
                _this.form = _this._formsService.toFormGroup(inputs, _this.actionName);
            }
        };
        this._store.dispatch(new helperActions.GetAllCountriesAction());
    }
    DownloadTicketHolderProfileEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        observableCombineLatest([
            this._store.pipe(select(fromRoot.getExhibitionSettings)),
            this._store.pipe(select(fromRoot.getTicketHolder)),
            this._store.pipe(select(fromRoot.getTitles)),
            this._store.pipe(select(fromRoot.getAllTitles)),
            this._store.pipe(select(fromRoot.getProfessions)),
            this._store.pipe(select(fromRoot.getAllProfessions)),
            this._store.pipe(select(fromRoot.getDepartments)),
            this._store.pipe(select(fromRoot.getAllDepartments)),
            this._store.pipe(select(fromRoot.getOccupationalGroups)),
            this._store.pipe(select(fromRoot.getAllOccupationalGroups)),
        ])
            .pipe(filter(function (data) {
            var settings = data[0], ticketHolder = data[1], titles = data[2], allTitles = data[3], professions = data[4], allProfessions = data[5], departments = data[6], allDepartments = data[7], occupationalGroups = data[8], allOccupationalGroups = data[9];
            return !!titles
                && !!allTitles
                && !!professions
                && !!allProfessions
                && !!departments
                && !!allDepartments
                && !!occupationalGroups
                && !!allOccupationalGroups;
        }), first())
            .subscribe(function (_a) {
            var settings = _a[0], ticketHolder = _a[1], titles = _a[2], allTitles = _a[3], professions = _a[4], allProfessions = _a[5], departments = _a[6], allDepartments = _a[7], occupationalGroups = _a[8], allOccupationalGroups = _a[9];
            if (!_this.ticket) {
                var $inputs = prepareTicketHolderDownloadData(settings, ticketHolder, titles, allTitles, professions, allProfessions, departments, allDepartments, occupationalGroups, allOccupationalGroups, _this._translateService);
                $inputs.subscribe(function (inputs) {
                    _this._store.dispatch(new ticketActions.SetTicketHolderInputs(inputs));
                    _this.loaded = true;
                });
            }
            else {
                _this.subscriptions.add(_this._ticketService
                    .getVisitorFieldsForPersonalization(_this.ticket.id)
                    .subscribe(function (result) {
                    var $inputs = prepareTicketHolderPersonalizationData(result.fieldSettings, titles, allTitles, professions, allProfessions, departments, allDepartments, occupationalGroups, allOccupationalGroups, _this._translateService);
                    $inputs.subscribe(function (inputs) {
                        _this.form = _this._formsService.toFormGroup(inputs, _this.actionName);
                        // check the validity on first time and set it
                        var validationCallback = function () {
                            _this.isValid.emit({
                                formName: 'ticketholder',
                                valid: !_this.form.invalid,
                                inputs: _this.inputs,
                                form: _this.form
                            });
                        };
                        _this._helperService.triggerCallbackOnceFormValidationIsDone(_this.form, validationCallback);
                        _this._store.dispatch(new ticketActions.SetTicketHolderInputs(inputs));
                        _this.loaded = true;
                    });
                }));
            }
        });
        this.subscriptions.add(this._store
            .select(fromRoot.getTicketHolderInputs)
            .subscribe(function (inputs) {
            _this.inputs = inputs;
            _this.form = _this._formsService.toFormGroup(inputs, _this.actionName);
            // check the validity on first time and set it
            var validationCallback = function () {
                _this.isValid.emit({
                    formName: 'ticketholder',
                    valid: !_this.form.invalid,
                    inputs: _this.inputs,
                    form: _this.form
                });
            };
            _this._helperService.triggerCallbackOnceFormValidationIsDone(_this.form, validationCallback);
        }));
    };
    DownloadTicketHolderProfileEditComponent.prototype.cancelProfileEdit = function () {
        this.ticket.expanded = false;
    };
    DownloadTicketHolderProfileEditComponent.prototype.sumbitProfileUpdate = function () {
        var _this = this;
        var data = {
            ticketId: this.ticket.id
        };
        for (var field in this.form.controls) {
            if (field === 'dateOfBirth' && !!this.form.get(field).value) {
                this.form.controls[field].setValue(this._helperService.getUTCdate(this.form.get(field).value));
            }
            var control = this.form.get(field);
            data[field] = control.value;
        }
        this.subscriptions.add(this._ticketService
            .postVisitorFieldsFromPersonalization(this.ticket.id, data)
            .subscribe(function () {
            _this.ticket.expanded = false;
            _this.ticket.firstName = _this.form.get('firstName').value;
            _this.ticket.lastName = _this.form.get('lastName').value;
            _this.ticket.email = _this.form.get('email').value;
        }));
    };
    DownloadTicketHolderProfileEditComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    return DownloadTicketHolderProfileEditComponent;
}());
export { DownloadTicketHolderProfileEditComponent };
