import * as additionalServicesActions from './shared/services-with-reducers/additional-services/additional-services.actions';
import * as customizationActions from './shared/services-with-reducers/customization/customization.actions';
import * as exhibitionActions from './shared/services-with-reducers/exhibition/exhibition.actions';
import * as fromRoot from './app.reducer';
import * as legitimationActions from './shared/services-with-reducers/legitimation/legitimation.actions';
import * as stepsFormsActions from './shared/services-with-reducers/step-forms/steps-forms.actions';
import * as ticketActions from './shared/services-with-reducers/tickets/ticket.actions';
import * as userActions from './shared/services-with-reducers/user/user.actions';
import * as helperActions from './shared/services-with-reducers/helpers/helper.actions';
import { combineLatest as observableCombineLatest, fromEvent as observableFromEvent, merge as observableMerge, of as observableOf } from 'rxjs';
import { Store } from '@ngrx/store';
import { TicketsService } from './shared/services-with-reducers/tickets/tickets.service';
import { mapTo } from 'rxjs/operators';
import { CustomizationService } from './shared/services-with-reducers/customization/customization.service';
import { consoleLog, setLocalStorageObject } from './shared/app-utils';
import { ErrorHandlingService } from './shared/error-handling/error-handling.service';
import { FormsService } from './shared/forms/forms.service';
import { AppConstants } from './shared/app-constants';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "./shared/services-with-reducers/tickets/tickets.service";
import * as i3 from "./shared/services-with-reducers/customization/customization.service";
import * as i4 from "./shared/error-handling/error-handling.service";
import * as i5 from "./shared/forms/forms.service";
var AppService = /** @class */ (function () {
    function AppService(_store, _ticketsService, _customizationService, _errorHandlingService, _formsService) {
        this._store = _store;
        this._ticketsService = _ticketsService;
        this._customizationService = _customizationService;
        this._errorHandlingService = _errorHandlingService;
        this._formsService = _formsService;
        if (typeof navigator !== 'undefined') {
            this.online$ = observableMerge(observableOf(navigator.onLine), observableFromEvent(window, 'online').pipe(mapTo(true)), observableFromEvent(window, 'offline').pipe(mapTo(false)));
        }
        else {
            this.online$ = observableOf(true);
        }
    }
    AppService.prototype.resetReducersWithCallback = function (releaseAllVouchersAndTickets, callback) {
        if (releaseAllVouchersAndTickets) {
            this._ticketsService.releaseAllVouchersAndTickets();
        }
        var timeOut = setTimeout(function () {
            callback();
        }, 5000);
        var sharedObservable = observableCombineLatest(this._store.select(fromRoot.getVoucherTickets), this._store.select(fromRoot.getTicketsBooking))
            .filter(function (data) {
            var vouchers = data[0], ticketBookings = data[1];
            ticketBookings = ticketBookings || { bookings: [], timestamp: null };
            var noOfTicketsBooked = ticketBookings.bookings.reduce(function (acc, curr) {
                return acc + (curr.count || 0);
            }, 0) || 0;
            var noOfVouchersBooked = vouchers.reduce(function (acc, curr) {
                return acc + (curr.count || 0);
            }, 0) || 0;
            var allBookedCount = noOfTicketsBooked + noOfVouchersBooked;
            if (!allBookedCount) {
                clearTimeout(timeOut);
            }
            return !allBookedCount;
        })
            .first()
            .share();
        sharedObservable.subscribe(function () {
            callback();
        });
        return sharedObservable;
    };
    AppService.prototype.resetReducers = function (releaseAllVouchersAndTickets, releaseContingents) {
        var _this = this;
        if (releaseAllVouchersAndTickets === void 0) { releaseAllVouchersAndTickets = true; }
        if (releaseContingents === void 0) { releaseContingents = true; }
        consoleLog("Reset reducers");
        //clear all previously received errors from the API (so we don't interpret errors that occurred in a previous shopping process):
        this._errorHandlingService.clearAllErrors();
        this._formsService.resetInputsValidity();
        if (releaseContingents) {
            this._ticketsService.postReleaseTicketForDay();
        }
        //we have to make this operation synchronous as otherwise setStyles method would potentially be called before the store state has been changed
        //(e.g. the customization template wouldn't be changed):
        return this.resetReducersWithCallback(releaseAllVouchersAndTickets, function () {
            new Promise(function (resolve) {
                _this._store.dispatch(new customizationActions.PartialResetReducer()); // customization reducer keeps localized images and operator settings
                _this._store.dispatch(new exhibitionActions.PartialResetReducer());
                _this._store.dispatch(new stepsFormsActions.PartialResetReducer());
                _this._store.dispatch(new additionalServicesActions.ResetReducer());
                _this._store.dispatch(new ticketActions.ResetReducer());
                _this._store.dispatch(new legitimationActions.ResetReducer());
                _this._store.dispatch(new helperActions.ResetZipCodesCities());
                setLocalStorageObject(AppConstants.parkingTicketsReducer, '');
                setLocalStorageObject(AppConstants.contingentTicketsReducer, '');
                resolve();
            }).then(function () {
                _this._customizationService.setStyles();
            });
        });
    };
    AppService.prototype.resetReducersWithUser = function (releaseAllVouchersAndTickets, releaseContingents) {
        var _this = this;
        if (releaseAllVouchersAndTickets === void 0) { releaseAllVouchersAndTickets = true; }
        if (releaseContingents === void 0) { releaseContingents = true; }
        consoleLog("Reset event including user");
        //clear all previously received errors from the API (so we don't interpret errors that occurred in a previous shopping process):
        this._errorHandlingService.clearAllErrors();
        this._formsService.resetInputsValidity();
        if (releaseContingents) {
            this._ticketsService.postReleaseTicketForDay();
        }
        // customization reducer is kept on logout
        return this.resetReducersWithCallback(releaseAllVouchersAndTickets, function () {
            new Promise(function (resolve) {
                _this._store.dispatch(new customizationActions.ResetShoppingStartTime());
                _this._store.dispatch(new userActions.ResetReducer());
                _this._store.dispatch(new exhibitionActions.PartialResetReducer());
                _this._store.dispatch(new stepsFormsActions.ResetReducer());
                _this._store.dispatch(new additionalServicesActions.ResetReducer());
                _this._store.dispatch(new ticketActions.ResetReducer());
                _this._store.dispatch(new legitimationActions.ResetReducer());
                _this._store.dispatch(new helperActions.ResetZipCodesCities());
                setLocalStorageObject(AppConstants.parkingTicketsReducer, '');
                setLocalStorageObject(AppConstants.contingentTicketsReducer, '');
                resolve();
            }).then(function () {
                _this._customizationService.setStyles();
            });
        });
    };
    AppService.ngInjectableDef = i0.defineInjectable({ factory: function AppService_Factory() { return new AppService(i0.inject(i1.Store), i0.inject(i2.TicketsService), i0.inject(i3.CustomizationService), i0.inject(i4.ErrorHandlingService), i0.inject(i5.FormsService)); }, token: AppService, providedIn: "root" });
    return AppService;
}());
export { AppService };
