import { filter, first, debounceTime } from 'rxjs/operators';
import * as fromRoot from '../../../app.reducer';
import * as ticketActions from '../../../shared/services-with-reducers/tickets/ticket.actions';
import * as stepsActions from '../../../shared/services-with-reducers/step-forms/steps-forms.actions';

import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { FormsService } from '../../../shared/forms/forms.service';
import { SendingOptionModel, TicketModel } from '../../../shared/services-with-reducers/tickets/ticket.interface';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs/Subscription';
import { ExhibitionSettingModel } from '../../../shared/services-with-reducers/customization/customization.interfaces';
import { combineLatest as observableCombineLatest } from 'rxjs';
import { TicketsService } from '../../../shared/services-with-reducers/tickets/tickets.service';

@Component({
  moduleId: module.id,
  selector: 'app-sending-options',
  templateUrl: './sending-options.component.html',
  styleUrls: ['./sending-options.component.scss']
})
export class SendingOptionsComponent implements OnChanges, OnDestroy {
  @Input()
  ticketCounter: number;
  @Input()
  exhibitionSettings: ExhibitionSettingModel;

  public ticketSendingOptions: SendingOptionModel[] = [];
  public selectedSendingOption = '';
  private sendingOptionTicketName: string;
  public showSendingOptions: boolean = true;
  private subscription: Subscription = new Subscription();
  public wasAnonymousTicketTaken: boolean = false;
  public optionSelectedBeforeAnonim: string = '';
  public changedUrl: string = '';

  constructor(
    private _store: Store<fromRoot.State>,
    private _formsService: FormsService,
    private _ticketService: TicketsService,
    private _router: Router,
    private _location: Location
  ) {
    this.subscription.add(
      observableCombineLatest([
        this._store.pipe(select(fromRoot.getSendingOptions)),
        this._store.pipe(select(fromRoot.getTickets))

      ]).pipe(
        filter(
          (
            [
              getSendingOptions,
              getTickets
            ]
          ) => {
            return (
              !!getSendingOptions &&
              !!getTickets
            );
          }
        ))
        .subscribe(([getSendingOptions, getTickets]) => {
          const sendingOptions: SendingOptionModel[] = getSendingOptions;
          const ungroupedTickets: TicketModel = getTickets;
          const anonymousTicketTaken = this._ticketService.checkIfAnonymousTicketTaken(ungroupedTickets);

          // delete query params, change URL
          if (!(this._router.url.includes("utm_source") || this._router.url.includes("utm_medium") || this._router.url.includes("utm_campaign") || this._router.url.includes("utm_term") || this._router.url.includes("utm_content"))) {
            this.changedUrl = this._router.url.split('?')[0];
            this._location.replaceState(this.changedUrl);
          }

          if (!anonymousTicketTaken && this.wasAnonymousTicketTaken) {
            const anonyMobilePerOwner = sendingOptions.find(option => option.value === 'mobilePerOwner');
            if (!!anonyMobilePerOwner) {
              anonyMobilePerOwner.isSelected = this.wasAnonymousTicketTaken = false;
            }
            const allToBuyer = sendingOptions.find(option => option.value === this.optionSelectedBeforeAnonim);
            if (!!allToBuyer){
              allToBuyer.isSelected = true;
            }
          }
          sendingOptions.forEach(option => {
            if (anonymousTicketTaken) {
              if (option.value === 'mobilePerOwner') {
                this.selectedSendingOption = 'mobilePerOwner';
                option.isSelected = this.wasAnonymousTicketTaken = true;
                this.showSendingOptions = false;
              } else {
                option.isSelected = false;
              }
            } else {
              if (option.isSelected) {
                this.optionSelectedBeforeAnonim = option.value;
                this.selectedSendingOption = option.value;
              }
            }
          });


          if (this.selectedSendingOption === '') {
            let selectedSendingOption = sendingOptions.find(option => option.isEnabled);
            if (!!selectedSendingOption) {
              selectedSendingOption.isSelected = true;
              this.selectedSendingOption = selectedSendingOption.value;
            }
          }

          this.ticketSendingOptions = this.addOptionVisibility(
            sendingOptions
          );

          if (!anonymousTicketTaken) {
            this.showSendingOptions = this.ticketSendingOptions.filter(option =>
              option.isEnabled && option.isVisible && option.isBasic
            ).length > 1;
          }


          this.setSendingOptionTicketName();

          if (this.selectedSendingOption === 'ticketRetrivalLink') {
            this._store
              .select(fromRoot.getTicketHolderInputSets)
              .pipe(first())
              .subscribe(holders => {
                holders.forEach(holder => {
                  const invalid = holder.inputSet.list.reduce((acc, curr) => {
                    return (
                      acc ||
                      (['email', 'firstName', 'lastNmae'].includes(curr.key) &&
                        !curr.value)
                    );
                  }, false);
                  if (invalid) {
                    this._formsService.setFormValidity(
                      false,
                      null,
                      holder.formInfo
                    );
                  }
                });
              });
          }

          if (
            this.selectedSendingOption === 'mobilePerOwner' &&
            !!this.exhibitionSettings && this.exhibitionSettings.emailIsMandatoryForMobileTicket
          ) {
            this._store
              .select(fromRoot.getTicketHolderInputSets)
              .first()
              .subscribe(holders => {
                holders.forEach(holder => {
                  const invalid = holder.inputSet.list.reduce((acc, curr) => {
                    return (
                      acc ||
                      (['verifyEmail', 'email'].includes(curr.key) &&
                        !curr.value)
                    );
                  }, false);

                  if (invalid) {
                    this._formsService.setFormValidity(
                      false,
                      null,
                      holder.formInfo
                    );
                  }
                });
              });
          }
        })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngOnChanges(ticketCount) {
    if (this.ticketSendingOptions.length) {
      this.setSendingOptions();
    }
  }

  addOptionVisibility(sendingOptions) {
    const optionsWithVisibility = sendingOptions.map(option => {
      option.isVisible = option.isEnabled;
      return option;
    });
    return optionsWithVisibility;
  }

  setSendingOptionTicketName() {
    switch (this.selectedSendingOption) {
      case 'normalPerOwner':
        this.sendingOptionTicketName = 'Print@Home';
        break;
      case 'mobilePerOwner':
        this.sendingOptionTicketName = 'Mobile';
        break;
      case 'ticketRetrivalLink':
        this.sendingOptionTicketName = 'Print@Home';
        break;
    }
  }

  sendingOptionChanged() {
    const updatedSendingOptions = this.ticketSendingOptions.map(option => {
      if (option.value === this.selectedSendingOption) {
        option.isSelected = true;
      } else {
        option.isSelected = false;
      }
      return option;
    });

    this.setSendingOptionTicketName();
    this._store.dispatch(
      new ticketActions.SetSendingOptionsAction(updatedSendingOptions)
    );

    if (this.selectedSendingOption !== 'ticketRetrivalLink') {
      const visitorQuestionnaireValidation = ['personal', 'visitorQuestionnaire'];

      this._store.dispatch(
        new stepsActions.SetBuyerVisitorCheckbox({
          buyerVisitorCheckedSlideIndex: null,
          isBuyerVisitorChecked: false,
          showVisitorQuestionnaire: false
        })
      );
      
      this._formsService.removeAllStepValidationFeedbacks(visitorQuestionnaireValidation);
      this._formsService.setFormValidity(true, null, visitorQuestionnaireValidation);
    }
  }

  setSendingOptions() {
    this._store.dispatch(
      new ticketActions.SetSendingOptionsAction(this.ticketSendingOptions)
    );
  }
}
