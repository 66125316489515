<app-banner></app-banner>
<div class="container-fluid">
  <div class="page-content">
    <div class="page-padding-wrapper" *ngIf="loading">
      <app-loader></app-loader>
    </div>

    <div class="page-padding-wrapper" *ngIf="!hasValidTicketHash && !loading">
      <div class="basic-header black">
        <h3 class="basic-title" data-translation="download-ticket.noticket">
          {{ 'download-ticket.noticket' | translate | hideTranslation }}
        </h3>
      </div>
    </div>

    <div
      class="page-padding-wrapper"
      *ngIf="hasValidTicketHash && !loading"
      appAdjustSideHeadline
    >
      <div class="basic-header black">
        <h3
          class="basic-title"
          data-translation="download-ticket.fill-data.title"
        >
          {{ 'download-ticket.fill-data.title' | translate | hideTranslation }}
        </h3>
        <p data-translation="download-ticket.fill-data.message">
          {{
            'download-ticket.fill-data.message' | translate | hideTranslation
          }}
        </p>
      </div>

      <div class="section-headline">
        <h2 data-translation="download-ticket.claim-ticket.title">
          {{
            'download-ticket.claim-ticket.title' | translate | hideTranslation
          }}
        </h2>
        <div
          class="section-headline-side"
          data-translation="download-ticket.claim-ticket.side-title"
        >
          {{
            'download-ticket.claim-ticket.side-title'
              | translate
              | hideTranslation
          }}
        </div>
      </div>
      <div class="section-body">
        <app-download-ticket-holder
          (isValid)="setValidity($event)"
        ></app-download-ticket-holder>
        <app-ticket-holder-questionnaire
          *ngIf="isQuestionnaireRequired"
          (isValid)="setValidity($event)"
        ></app-ticket-holder-questionnaire>
        <div class="submit-row-wrap">
          <div *ngIf="checkboxesInputs && checkboxesInputs.length">
            <form
              [formGroup]="checkboxesForm"
              class="row no-group-margin hide-input-validation"
            >
              <app-df-input
                *ngFor="let input of checkboxesInputs"
                [input]="input"
                [class]="input.cssClass"
                [inputSet]="checkboxesInputs"
                [action]="checkboxesFormsActionName"
                [saveFormCallback]="setPolicy"
                [form]="checkboxesForm"
              >
              </app-df-input>
            </form>
          </div>
        </div>
        <div class="button-wrap">

          <button
            class="button feedback-button"
            [disabled]="!allFormsValid || (spinnerValue$ | async)"
            (click)="downloadTiket('normalTicket')"
          >
            <app-spinner class="download-spinner" *ngIf="(spinnerValue$ | async) && this.downloadTicketButton === 'normalTicket'">
            </app-spinner>
            <span data-translation="download-ticket.claim-ticket.button">
              {{
                'download-ticket.claim-ticket.button'
                  | translate
                  | hideTranslation
              }}
            </span>
            <div
              *ngIf="
                !allFormsValid && feedbackMessages && feedbackMessages.length
              "
            >
              <app-feedback-message
                [feedbackMessages]="feedbackMessages"
                class="feedback-messages"
              ></app-feedback-message>
            </div>
          </button>

          <button
            class="button feedback-button"
            [disabled]="!allFormsValid || (spinnerValue$ | async)"
            (click)="downloadTiket('mobileTicket')"
          >
            <app-spinner class="download-spinner" *ngIf="(spinnerValue$ | async) && this.downloadTicketButton === 'mobileTicket'">
            </app-spinner>
            <span data-translation="download-mobile-ticket.claim-ticket.button">
              {{
                'download-mobile-ticket.claim-ticket.button'
                  | translate
                  | hideTranslation
              }}
            </span>
            <div
              *ngIf="
                !allFormsValid && feedbackMessages && feedbackMessages.length
              "
            >
              <app-feedback-message
                [feedbackMessages]="feedbackMessages"
                class="feedback-messages"
              ></app-feedback-message>
            </div>
          </button>
          <button
            class="button feedback-button"
            [disabled]="!allFormsValid || (spinnerValue$ | async)"
            (click)="downloadTiket('passBook')"
          >
            <app-spinner class="download-spinner" *ngIf="(spinnerValue$ | async) && this.downloadTicketButton === 'passBook'">
            </app-spinner>
            <span data-translation="download-passBook.claim-ticket.button">
              {{
                'download-passBook.claim-ticket.button'
                  | translate
                  | hideTranslation
              }}
            </span>
            <div
              *ngIf="
                !allFormsValid && feedbackMessages && feedbackMessages.length
              "
            >
              <app-feedback-message
                [feedbackMessages]="feedbackMessages"
                class="feedback-messages"
              ></app-feedback-message>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
