var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest as observableCombineLatest, Subject } from 'rxjs';
import { filter, takeUntil, map, first } from 'rxjs/operators';
import { FormsService } from '../../../app/shared/forms/forms.service';
import { TicketsService } from '../../../app/shared/services-with-reducers/tickets/tickets.service';
import * as additionalServicesActions from '../../../app/shared/services-with-reducers/additional-services/additional-services.actions';
import * as fromRoot from '../../../app/app.reducer';
import * as stepsActions from '../../../app/shared/services-with-reducers/step-forms/steps-forms.actions';
import { setLocalStorageObject } from '../../../app/shared/app-utils';
var WidgetTicketSelectComponent = /** @class */ (function () {
    function WidgetTicketSelectComponent(_store, _formsService, _ticketsService) {
        var _this = this;
        this._store = _store;
        this._formsService = _formsService;
        this._ticketsService = _ticketsService;
        this.numberOfSelectedTickets = 0;
        this.maxTicketLimit = 0;
        this.totalPrice = 0;
        this.isTicketBookingLoading = false;
        // used for ticktes structure (group with tickets)
        this.productGroups = null;
        this.unsubscribe = new Subject();
        // create ticket groups and order tickets and gropus by priority
        observableCombineLatest([
            this._store.pipe(select(fromRoot.getTicketsTypes)),
            this._store.pipe(select(fromRoot.getWidgetSettings))
        ])
            .pipe(filter(function (_a) {
            var productGroups = _a[0], widgetSettings = _a[1];
            var ticketWidgetSettings = widgetSettings && widgetSettings.ticketsWidget;
            if (productGroups && ticketWidgetSettings) {
                return true;
            }
        }), takeUntil(this.unsubscribe))
            .subscribe(function (_a) {
            var productGroups = _a[0], widgetSettings = _a[1];
            var ticketWidgetSettings = widgetSettings.ticketsWidget;
            var allowedTickets = ticketWidgetSettings.tickets.split(',');
            var updatedProductGroups = productGroups.map(function (ticketGroup) {
                var updatedProductGroup = __assign({}, ticketGroup);
                if (!updatedProductGroup.products) {
                    return ticketGroup;
                }
                // filter out tickets that should not be displayed in widget
                updatedProductGroup.products = updatedProductGroup.products.filter(function (product) {
                    if (product.ticket != null) {
                        var ticketId = String(product.ticket.uniqueId);
                        if (allowedTickets.indexOf(ticketId) !== -1) {
                            return true;
                        }
                    }
                });
                // return null for group without products
                if (updatedProductGroup.products.length === 0) {
                    return null;
                }
                return updatedProductGroup;
            });
            // filter out nulled groups
            updatedProductGroups = updatedProductGroups.filter(function (updatedTicketGroup) { return updatedTicketGroup; });
            _this.productGroups = updatedProductGroups.slice();
        });
        // set exhibition settings and max limits for tickets
        this._store
            .select(fromRoot.getExhibitionSettings)
            .pipe(filter(function (settings) {
            if (settings) {
                return true;
            }
        }), takeUntil(this.unsubscribe))
            .subscribe(function (settings) {
            _this.exhibitionSettings = settings;
            _this.maxTicketLimit = settings.limitBoughtTickets;
        });
        // calculate total price and set validation of a step
        this._store
            .select(fromRoot.getTickets)
            .pipe(filter(function (tickets) {
            return !!tickets;
        }), takeUntil(this.unsubscribe))
            .subscribe(function (ungroupedTickets) {
            // create a deep copy so we dont modify the original object in store
            _this.ungroupedTickets = __assign({}, ungroupedTickets);
            // go through tickets and sum price of selected ones. Also count number of selected tickets
            var numberOfSelectedTickets = 0;
            _this.totalPrice = Object.keys(_this.ungroupedTickets).reduce(function (acc, key) {
                numberOfSelectedTickets += _this.ungroupedTickets[key].count;
                var pricePerTicketType = _this.getPricePerTicketType(_this.ungroupedTickets, key);
                var finalPrice = acc + pricePerTicketType;
                return finalPrice;
            }, 0);
            _this.ticketCounter = Object.keys(_this.ungroupedTickets).reduce(function (acc, key) {
                return acc + _this.ungroupedTickets[key].count;
            }, 0);
            var isValid = _this.isFormValid(numberOfSelectedTickets);
            _this.setValidation(isValid);
            _this.numberOfSelectedTickets = numberOfSelectedTickets;
            _this.ticketSelectionIsValid = isValid;
        });
    }
    WidgetTicketSelectComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.sholdShowPromoCode$ = this._store.select(fromRoot.sholdShowPromoCode);
        this._store
            .select(fromRoot.getSelectedExhibitionId)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(function (eventId) {
            _this.eventId = eventId;
            // get current worksops for tickets that are available only for workshop
            _this._store.dispatch(new additionalServicesActions.GetWorkshops(eventId));
        });
        // check how many tickets are available for tickets with workshops
        observableCombineLatest(this._store.select(fromRoot.getWorkshops), this._store.select(fromRoot.getTickets), this._store.select(fromRoot.getTicketsBooking))
            .pipe(filter(function (_a) {
            var workshops = _a[0], tickets = _a[1];
            if (workshops.length && tickets) {
                return true;
            }
        }), map(function (_a) {
            var workshops = _a[0], ungroupedTickets = _a[1], ticketsBookings = _a[2];
            var seatsAvailableByUniqueId = Object.keys(ungroupedTickets).reduce(function (acc, ticketId) {
                // all available workshop seats for this tickets, before booked workshop seats by other tickets are substracted
                var availableSeats = workshops.reduce(function (acc, workshop) {
                    if (ungroupedTickets[ticketId].allowedWorkshops.includes(workshop.workshopId)) {
                        acc += workshop.seats;
                    }
                    return acc;
                }, 0);
                var seatsBookingsByOtherTicket = workshops.reduce(function (acc, workshop) {
                    function hasOtherSeats(booking, workshop) {
                        var otherWorkshopsIds = ungroupedTickets[booking.ticketUniqueId].allowedWorkshops.filter(function (workshopId) { return workshopId !== workshop.workshopId; });
                        var hasOtherSeats = true;
                        if (!otherWorkshopsIds.length) {
                            hasOtherSeats = false;
                        }
                        return hasOtherSeats;
                    }
                    var otherBookings = ticketsBookings.bookings.filter(function (booking) {
                        return (booking.count &&
                            booking.ticketUniqueId !== ticketId &&
                            ungroupedTickets[booking.ticketUniqueId].allowedWorkshops.includes(workshop.workshopId) &&
                            !hasOtherSeats(booking, workshop));
                    });
                    acc = acc.concat(otherBookings);
                    return acc;
                }, []);
                var uniqueBookings = seatsBookingsByOtherTicket.filter(function (value, index, self) { return self.indexOf(value) === index; });
                var seatsCountBookedByOtherTicket = uniqueBookings.reduce(function (acc, booking) {
                    acc += booking.count;
                    return acc;
                }, 0);
                acc[ticketId] = availableSeats - seatsCountBookedByOtherTicket;
                return acc;
            }, {});
            return seatsAvailableByUniqueId;
        }), takeUntil(this.unsubscribe))
            .subscribe(function (data) {
            _this.workshopsList = data;
        });
        this.isTicketSoldOut$ = this._store.select(fromRoot.getTicketsBooking).pipe(map(function (booking) {
            _this.isTicketBookingLoading = false;
            return booking.bookings.reduce(function (acc, booking) {
                acc[booking.ticketUniqueId] = booking.isTicketSold;
                return acc;
            }, {});
        }));
        this.availableTicketsCombined$ = observableCombineLatest(this._store.select(fromRoot.getTickets), this._store.select(fromRoot.getTicketsBooking)).pipe(filter(function (_a) {
            var ungroupedTickets = _a[0];
            if (ungroupedTickets) {
                return true;
            }
        }), map(function (_a) {
            var ungroupedTickets = _a[0], ticketsBookings = _a[1];
            var availability = Object.keys(ungroupedTickets).reduce(function (acc, ticketId) {
                acc[ticketId] = ungroupedTickets[ticketId].availableTickets;
                if (ticketsBookings) {
                    var realtimeBooking = ticketsBookings.bookings.find(function (booking) {
                        return booking.ticketUniqueId === ticketId;
                    });
                    if (realtimeBooking) {
                        acc[ticketId] = realtimeBooking.availableTickets;
                    }
                }
                return acc;
            }, {});
            return availability;
        }));
        this.totalAvailableTickets$ = observableCombineLatest(this._store.select(fromRoot.getTicketsBooking), this._store.select(fromRoot.getTickets))
            .filter(function (data) {
            return !!data[1];
        })
            .map(function (data) {
            var booking = data[0];
            var ungroupedTickets = data[1];
            var defaultLimit = 1000;
            var availability = Object.keys(ungroupedTickets).reduce(function (acc, ticketId) {
                acc[ticketId] =
                    ungroupedTickets[ticketId].availableTickets ||
                        ungroupedTickets[ticketId].ticketLimit ||
                        defaultLimit;
                if (booking) {
                    var realtimeBooking = booking.bookings.find(function (booking) {
                        return booking.ticketUniqueId === ticketId;
                    });
                    if (realtimeBooking) {
                        acc[ticketId] = acc[ticketId] - realtimeBooking.count;
                    }
                }
                return acc;
            }, {});
            return availability;
        });
        this.percentageOfAvailableTickets$ = observableCombineLatest(this._store.select(fromRoot.getTickets), this._store.select(fromRoot.getTicketsBooking)).pipe(filter(function (_a) {
            var ungroupedTickets = _a[0];
            if (ungroupedTickets) {
                return true;
            }
        }), map(function (_a) {
            var ungroupedTickets = _a[0], ticketBooking = _a[1];
            var ticketAvailability = Object.keys(ungroupedTickets).reduce(function (acc, ticketKey) {
                var ticket = ungroupedTickets[ticketKey];
                var uniqueId = ticket.uniqueId;
                var uniqueTicket = ungroupedTickets[uniqueId];
                acc[uniqueId] =
                    uniqueTicket.availableTickets / uniqueTicket.ticketLimit;
                return acc;
            }, {});
            var bookingsAvailability = ticketBooking.bookings.reduce(function (acc, booking) {
                var uniqueId = booking.ticketUniqueId;
                acc[uniqueId] = booking.availableTickets / booking.ticketLimit;
                return acc;
            }, {});
            return __assign({}, ticketAvailability, bookingsAvailability);
        }));
    };
    WidgetTicketSelectComponent.prototype.ngOnDestroy = function () {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    };
    /**
     * count prices of ticket per type;
     * based on ticket count of that type and number of sales available for this ticket type
     * in case there are more ticket than sales, make sum of prices of reduced tickets and full price tickets
     *
     * @param {TicketModel} ungroupedTickets
     * @param {string} ticketUniqueId
     * @returns {number}
     * @memberof WebShopTicketsComponent
     */
    WidgetTicketSelectComponent.prototype.getPricePerTicketType = function (ungroupedTickets, ticketUniqueId) {
        var ticketWithCount = ungroupedTickets[ticketUniqueId];
        var ticketCount = ticketWithCount.count;
        var fullTicketsPrice = ticketCount * ticketWithCount.price;
        return fullTicketsPrice;
    };
    /**
     * tell whether form is valid (at least one ticket is selected)
     *
     * @param {any} numberOfSelectedTickets
     * @returns
     * @memberof WebShopTicketsComponent
     */
    WidgetTicketSelectComponent.prototype.isFormValid = function (numberOfSelectedTickets) {
        return numberOfSelectedTickets ? true : false;
    };
    WidgetTicketSelectComponent.prototype.setValidation = function (isValid) {
        var stepsFormsActionName = ['tickets', 'ticketSelection'];
        if (isValid) {
            this._formsService.removeStepValidationFeedback(stepsFormsActionName, 'counters');
        }
        else {
            this._formsService.addStepValidationFeedback(stepsFormsActionName, 'counters', 'steps.missing-input.ticket-counter');
        }
        this._formsService.setFormValidity(isValid, null, stepsFormsActionName);
    };
    WidgetTicketSelectComponent.prototype.selectTicket = function (ticket) {
        this._ticketsService.selectTicket(ticket);
    };
    /**
     *
     *
     * @param {{value: number; decrease: number}} change can be either 1 or -1, depending whether ticket was added or removed
     * @param {any} ticketUniqueId
     * @memberof WebShopTicketsComponent
     */
    WidgetTicketSelectComponent.prototype.counterChange = function (data, uniqueId) {
        var value = data.value, decrease = data.decrease;
        //if ticket count is increased and if workshop selection is mandatory we set the step not valid
        if (!decrease && this.exhibitionSettings.isWorkshopsSelectionMandatory) {
            this._formsService.setFormValidity(false, null, [
                'workshop',
                'validation'
            ]);
        }
        //when we change ticket to different one and if address coppied is checked we uncheck it
        this._store.dispatch(new stepsActions.SetAddressCheckbox({
            checkedSlideIndex: null,
            isAddressCopied: false
        }));
        this._ticketsService.ticketCounterChanged(uniqueId, value, decrease);
    };
    WidgetTicketSelectComponent.prototype.ticketLoading = function (ticketLoading) {
        this.isTicketBookingLoading = ticketLoading;
    };
    WidgetTicketSelectComponent.prototype.onContinueClick = function (event) {
        var webshopOriginURL = window.location.protocol + "//" + window.location.host;
        var webshopPagePathname = "/webshop/" + this.eventId + "/tickets";
        var toWebshop = webshopOriginURL + webshopPagePathname;
        this._store.pipe(first()).subscribe(function (store) {
            try {
                for (var key in store) {
                    var storeItem = store[key];
                    setLocalStorageObject(key, storeItem);
                }
            }
            catch (error) {
                console.log(error);
            }
        });
        window.open(toWebshop);
    };
    return WidgetTicketSelectComponent;
}());
export { WidgetTicketSelectComponent };
