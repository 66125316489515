import * as fromRoot from '../../../app.reducer';

import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { Subscription } from 'rxjs';

@Component({
  moduleId: module.id,
  selector: 'app-walk-thru-info',
  templateUrl: './walk-thru-info.component.html',
  styleUrls: ['./walk-thru-info.component.scss']
})
export class WalkThruInfoComponent {
  public shopingStepList = [];
  public translationsLoaded: boolean = false;
  private subscriptions: Subscription = new Subscription();

  constructor(private _store: Store<fromRoot.State>) {
    this._store.select(fromRoot.getStepsValidity).subscribe(steps => {
      this.shopingStepList = Object.keys(steps)
        .sort((a, b) => {
          return steps[a].order - steps[b].order;
        })
        // only navigate to visible routes
        .filter(stepKey => {
          return steps[stepKey].visible && steps[stepKey].showInStepNavigation;
        });
    });
  }

  ngOnInit() {
    this.subscriptions.add(
      this._store.pipe(select(fromRoot.getTranslationsLoaded))
      .subscribe(
        (getTranslationsLoaded) => {
          this.translationsLoaded = getTranslationsLoaded;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
