/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./web-shop-buyer-questionnaire.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../_pages/web-shop-personalize/extra-data/extra-data.component.ngfactory";
import * as i3 from "../_pages/web-shop-personalize/extra-data/extra-data.component";
import * as i4 from "@ngrx/store";
import * as i5 from "../shared/services-with-reducers/customization/customization.service";
import * as i6 from "../shared/forms/forms.service";
import * as i7 from "../shared/services-with-reducers/helpers/helper.service";
import * as i8 from "@angular/common";
import * as i9 from "../step-navigation-buttons/step-navigation-buttons.component.ngfactory";
import * as i10 from "../step-navigation-buttons/step-navigation-buttons.component";
import * as i11 from "@angular/router";
import * as i12 from "../shared/window-size/window-size.service";
import * as i13 from "../shared/services-with-reducers/step-forms/steps-forms.service";
import * as i14 from "../login/login.service";
import * as i15 from "./web-shop-buyer-questionnaire.component";
var styles_WebShopBuyerQuestionnaireComponent = [i0.styles];
var RenderType_WebShopBuyerQuestionnaireComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WebShopBuyerQuestionnaireComponent, data: {} });
export { RenderType_WebShopBuyerQuestionnaireComponent as RenderType_WebShopBuyerQuestionnaireComponent };
function View_WebShopBuyerQuestionnaireComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-extra-data", [], null, null, null, i2.View_ExtraDataComponent_0, i2.RenderType_ExtraDataComponent)), i1.ɵdid(1, 245760, null, 0, i3.ExtraDataComponent, [i4.Store, i5.CustomizationService, i6.FormsService, i7.HelperService], { stepsFormsActionName: [0, "stepsFormsActionName"] }, null), i1.ɵpad(2, 2)], function (_ck, _v) { var currVal_0 = _ck(_v, 2, 0, "questionnaire", "questionnaire"); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_WebShopBuyerQuestionnaireComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "page-padding-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WebShopBuyerQuestionnaireComponent_1)), i1.ɵdid(2, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-step-navigation-buttons", [], null, null, null, i9.View_StepNavigationButtonsComponent_0, i9.RenderType_StepNavigationButtonsComponent)), i1.ɵdid(4, 180224, null, 0, i10.StepNavigationButtonsComponent, [i11.ActivatedRoute, i11.Router, i4.Store, i6.FormsService, i12.WindowSizeService, i13.StepsFormsService, i7.HelperService, i14.LoginService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.questionnaireLoaded; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_WebShopBuyerQuestionnaireComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-web-shop-buyer-questionnaire", [], null, null, null, View_WebShopBuyerQuestionnaireComponent_0, RenderType_WebShopBuyerQuestionnaireComponent)), i1.ɵdid(1, 114688, null, 0, i15.WebShopBuyerQuestionnaireComponent, [i6.FormsService, i4.Store, i7.HelperService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WebShopBuyerQuestionnaireComponentNgFactory = i1.ɵccf("app-web-shop-buyer-questionnaire", i15.WebShopBuyerQuestionnaireComponent, View_WebShopBuyerQuestionnaireComponent_Host_0, {}, {}, []);
export { WebShopBuyerQuestionnaireComponentNgFactory as WebShopBuyerQuestionnaireComponentNgFactory };
