<div class="page-padding-wrapper">
    <div *ngIf="!userProfile">
        <div
            class="login-message"
            [innerHTML]="'account-verification.login-message' | translate"
            data-translation-includes-children
            data-translation="account-verification.login-message"
            *ngIf="
                !(
                    'account-verification.login-message'
                    | translate
                    | isHiddenTranslation
                )
            "
        ></div>
        <app-login class="login-body"></app-login>
    </div>

    <div *ngIf="userProfile">
        <app-info-block
            [infoText]="'account-verification.unverified-email'"
        ></app-info-block>
    </div>
</div>
