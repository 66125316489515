import { APP_BASE_HREF, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  MissingTranslationHandler,
  TranslateModule,
  TranslateParser
} from '@ngx-translate/core';
import { metaReducers, reducers } from './app.reducer';

import { AppComponent } from './app.component';
import { AdditionalServicesEffect } from './shared/services-with-reducers/additional-services/additional-services.effects';
import { AddressAutocompleteDirective } from './shared/directives/address-autocomplete/address-autocomplete.directive';
import { AdjustSideHeadlineDirective } from './shared/directives/adjust-side-headline/adjust-side-headline.directive';
import { CalendarCustomDefaultsDirective } from './_pages/web-shop-tickets/calendarCustomDefaults.directive';
import { AuthInterceptor } from './shared/interceptors/auth-interceptor';
import { BasketComponent } from './basket/basket.component';
import { BigLoaderComponent } from './big-loader/big-loader.component';
import { BillingAddressComponent } from './_pages/web-shop-personalize/billing-address/billing-address.component';
import { BottomStickyComponent } from './_layout/bottom-sticky/bottom-sticky.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserCheckComponent } from './browser-check/browser-check.component';
import { BrowserModule } from '@angular/platform-browser';
import { BuyerInfoComponent } from './_pages/web-shop-personalize/buyer-info/buyer-info.component';
import { CarouselComponent } from './carousel/carousel.component';
import { ChangeCredentialsComponent } from './edit-account-form/change-credentials/change-credentials.component';
import { ChangeTicketDayComponent } from './edit-account-form/change-ticket-day/change-ticket-day.component';
import { ChangePasswordComponent } from './_pages/change-password/change-password.component';
import { ClickOutsideDirective } from './shared/directives/click-outside/click-outside.directive';
import { CofirmationWorkshopComponent } from './_pages/web-shop-confirmation/cofirmation-workshop/cofirmation-workshop.component';
import { ColorizerComponent } from './colorizer/colorizer.component';
import { ColorizerEffect } from './colorizer/colorizer.effects';
import { ColorizerService } from './colorizer/colorizer.service';
import { LoginService } from './login/login.service';
import { CookiesPopupComponent } from './cookies-popup/cookies-popup.component';
import { CountdownComponent } from './countdown/countdown.component';
import { CustomParser } from './shared/translate-parser/translate-parser';
import { CustomizationEffect } from './shared/services-with-reducers/customization/customization.effects';
import { DataProtectionPageComponent } from './_pages/data-protection-page/data-protection-page.component';
import { DfInputComponent } from './shared/forms/df-input/df-input.component';
import { DownloadTicketHolderComponent } from './_pages/web-shop-download/download-ticket-holder/download-ticket-holder.component';
import { DownloadTicketHolderProfileEditComponent } from './_pages/web-shop-download/download-ticket-holder-profile-edit/download-ticket-holder-profile-edit.component';
import { EditAccountFormComponent } from './edit-account-form/edit-account-form.component';
import { EditBillingAddressFormComponent } from './edit-account-form/edit-billing-address-form/edit-billing-address-form.component';
import { EffectsModule } from '@ngrx/effects';
import { ErrorInterceptor } from './shared/interceptors/error-interceptor';
import { EventPassedComponent } from './event-passed/event-passed.component';
import { ExhibitionEffect } from './shared/services-with-reducers/exhibition/exhibition.effects';
import { ExhibitionTileComponent } from './exhibition-tile/exhibition-tile.component';
import { ExtraDataComponent } from './_pages/web-shop-personalize/extra-data/extra-data.component';
import { FeedbackMessageComponent } from './feedback-message/feedback-message.component';
import { FileUploadModule } from '../imports/primeng-selected';
import { CalendarModule } from 'primeng/calendar';
import { FooterComponent } from './_layout/footer/footer.component';
import { ForgottenPasswordComponent } from './_pages/forgotten-password/forgotten-password.component';
import { FormComponent } from './_pages/web-shop-personalize/order-tickets/form/form.component';
import { FourOFourComponent } from './_pages/four-o-four/four-o-four.component';
import { HeaderInterceptor } from './shared/interceptors/header-interceptor';
import { HelperEffect } from './shared/services-with-reducers/helpers/helper.effects';
import { HomeComponent } from './_pages/home/home.component';
import { HttpModule } from '@angular/http';
import { IframeModalComponent } from './iframe-modal/iframe-modal.component';
import { KeysPipe } from './shared/pipes/keys/keys.pipe';
import { LegitimationEffect } from './shared/services-with-reducers/legitimation/legitimation.effects';
import { LoginComponent } from './login/login.component';
import { LoginGuardAdmin } from './shared/route-guards/admin-permission-guard';
import { LoginGuardSignedOut } from './shared/route-guards/user-logout-guard';
import { LoginGuardUser } from './shared/route-guards/user-permission-guard';
import { LoginPageComponent } from './_pages/login-page/login-page.component';
import { LogoutComponent } from './logout/logout.component';
import { MaintenancePageGuard } from './shared/route-guards/maintenance-page-guard';
import { MissingTranslation } from './shared/missing-translation/missing-translation';
import { ModalWindowComponent } from './shared/modal-window/modal-window.component';
import { MultipleTabsComponent } from './shared/multiple-tabs/multiple-tabs.component';
import { NewAccountComponent } from './_pages/new-account/new-account.component';
import { NewslettersUnsubscriptionsComponent } from './newsletters-unsubscriptions/newsletters-unsubscriptions.component';
import { NgModule } from '@angular/core';
import { MissingTranslationService } from './shared/missing-translation/missing-translation.service';
import { NumbersOnlyDirective } from './shared/directives/numbers/numbers.directive';
import { OrderTicketsComponent } from './_pages/web-shop-personalize/order-tickets/order-tickets.component';
import { PaymentLandingPageComponent } from './_pages/web-shop-confirmation/payment-landing-page/payment-landing-page.component';
import { PressNewsDetailComponent } from './_pages/press-news-detail/press-news-detail.component';
import { PressNewsEffect } from './press-news/press-news.effects';
import { AgeRatingComponent } from './_layout/age-rating/age-rating.component';
import { ProfileComponent } from './_pages/profile/profile.component';
import { RegistrationQuestionnaireComponent } from './edit-account-form/registration-questionnaire/registration-questionnaire.component';
import { ResetButtonComponent } from './reset-button/reset-button.component';
import { ResetEmailComponent } from './_pages/reset-email/reset-email.component';
import { ResetPasswordComponent } from './_pages/reset-password/reset-password.component';
import { Routing } from './app.routes';
import { SafeUrlPipe } from './shared/pipes/safe-url/safe-url.pipe';
import { SelfRegTimeoutComponent } from './self-reg-timeout/self-reg-timeout.component';
import { SharedModule } from '../app-shared/shared.module';
import { SortByPipe } from './shared/pipes/sort-by/sort-by.pipe';
import { SponsorListComponent } from './sponsor-list/sponsor-list.component';
import { StatusBarComponent } from './status-bar/status-bar.component';
import { StepNavigationButtonsComponent } from './step-navigation-buttons/step-navigation-buttons.component';
import { StepNavigationComponent } from './step-navigation/step-navigation.component';
import { StepPermissionGuard } from './shared/route-guards/step-permission-guard';
import { StepsFormsEffect } from './shared/services-with-reducers/step-forms/steps-forms.effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { TicketDropdownComponent } from './ticket-dropdown/ticket-dropdown.component';
import { TicketEffect } from './shared/services-with-reducers/tickets/ticket.effects';
import { TicketHolderQuestionnaireComponent } from './_pages/web-shop-download/ticket-holder-questionnaire/ticket-holder-questionnaire.component';
import { TopBarComponent } from './_layout/top-bar/top-bar.component';
import { TopBarWidgetComponent } from './_layout/top-bar/top-bar-widget/top-bar-widget.component';
import { WidgetAdminComponent } from './_pages/widget-admin/widget-admin.component';
import { BannerWidgetAdminComponent } from './_pages/widget-admin/banner-widget-admin/banner-widget-admin.component';
import { TicketsWidgetAdminComponent } from './_pages/widget-admin/tickets-widget-admin/tickets-widget-admin.component';
import { TranslationInputComponent } from './_pages/translations/translation-input/translation-input.component';
import { TranslationsComponent } from './_pages/translations/translations.component';
import { UserEffect } from './shared/services-with-reducers/user/user.effects';
import { VerifyEmailComponent } from './_pages/verify-email/verify-email.component';
import { VoucherComponent } from './_pages/web-shop-tickets/voucher/voucher.component';
import { WalkThruInfoComponent } from './_pages/web-shop-tickets/walk-thru-info/walk-thru-info.component';
import { WebSelfRegistrationComponent } from './web-self-registration/web-self-registration.component';
import { WebShopBuyerQuestionnaireComponent } from './web-shop-buyer-questionnaire/web-shop-buyer-questionnaire.component';
import { WebShopComponent } from './web-shop/web-shop.component';
import { WebShopConfirmationComponent } from './_pages/web-shop-confirmation/web-shop-confirmation.component';
import { WebShopDownloadComponent } from './_pages/web-shop-download/web-shop-download.component';
import { WebShopInvoiceComponent } from './_pages/web-shop-invoice/web-shop-invoice.component';
import { WebShopLegitimationComponent } from './_pages/web-shop-legitimation/web-shop-legitimation.component';
import { WebShopAccountVerificationComponent } from './_pages/web-shop-account-verification/web-shop-account-verification.component';
import { WebShopMenuComponent } from './_pages/web-shop-menu/web-shop-menu.component';
import { WebShopPersonalizeComponent } from './_pages/web-shop-personalize/web-shop-personalize.component';
import { WebShopRecipeComponent } from './_pages/webshop-recipe/webshop-recipe.component';
import { WebShopTicketsComponent } from './_pages/web-shop-tickets/web-shop-tickets.component';
import { WebShopWorkshopComponent } from './_pages/web-shop-workshop/web-shop-workshop.component';
import { WorkshopVisitorsCountComponent } from './_pages/web-shop-workshop/workshop-visitors-count/workshop-visitors-count.component';
import { WorkshopsVisitorsComponent } from './_pages/web-shop-workshop/workshops-visitors/workshops-visitors.component';
import { environment } from '../environments/environment';
import { EventSeriesComponent } from './_pages/event-series/event-series.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { MaintenencePageComponent } from './_pages/maintenence-page/maintenence-page.component';
import { WidgetModule } from './../widget/widget.module';
import { QuillModule } from 'ngx-quill';
import { RichTextDirective } from './shared/directives/rich-text/rich-text.directive';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeFr from '@angular/common/locales/fr';
import localeRu from '@angular/common/locales/ru';
import localeEs from '@angular/common/locales/es';
import localeIt from '@angular/common/locales/it';
import { TicketsDownloadComponent } from './_pages/profile/tickets-download/tickets-download.component';
import { ConfirmationWorkshopPricesComponent } from './_pages/web-shop-confirmation/confirmation-workshop-prices/confirmation-workshop-prices.component';
import { OneTimeDirective } from './shared/directives/one-time/one-time.directive';
import { FilterPlaceholderPipe } from './shared/pipes/filter-placeholder/filter-placeholder.pipe';
import { ApplicationInsightsService } from './shared/applicationInsights/applicationInsightsService';
import { ShowPasswordDirective } from './shared/directives/show-password/show-password.directive';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SkeletonLoaderComponent } from './shared/skeleton-loader/skeleton-loader.component';
import { PackageCounterComponent } from './_pages/web-shop-tickets/package-counter/package-counter.component';

registerLocaleData(localeDe, 'de');
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeRu, 'ru');
registerLocaleData(localeEs, 'es');
registerLocaleData(localeIt, 'it');

@NgModule({
  declarations: [
    AddressAutocompleteDirective,
    AdjustSideHeadlineDirective,
    CalendarCustomDefaultsDirective,
    RichTextDirective,
    AppComponent,
    BasketComponent,
    BuyerInfoComponent,
    ChangeCredentialsComponent,
    ChangeTicketDayComponent,
    CarouselComponent,
    ClickOutsideDirective,
    ColorizerComponent,
    ExhibitionTileComponent,
    StatusBarComponent,
    ExtraDataComponent,
    FooterComponent,
    ForgottenPasswordComponent,
    FormComponent,
    FourOFourComponent,
    HomeComponent,
    KeysPipe,
    LoginComponent,
    SafeUrlPipe,
    WebShopMenuComponent,
    NewAccountComponent,
    OrderTicketsComponent,
    PressNewsDetailComponent,
    ProfileComponent,
    MultipleTabsComponent,
    StepNavigationButtonsComponent,
    StepNavigationComponent,
    TopBarComponent,
    TopBarWidgetComponent,
    VoucherComponent,
    WebShopComponent,
    WebSelfRegistrationComponent,
    WebShopConfirmationComponent,
    WebShopInvoiceComponent,
    WebShopLegitimationComponent,
    WebShopAccountVerificationComponent,
    WebShopPersonalizeComponent,
    WebShopTicketsComponent,
    EditAccountFormComponent,
    LoginPageComponent,
    DfInputComponent,
    WidgetAdminComponent,
    TicketsWidgetAdminComponent,
    BannerWidgetAdminComponent,
    TranslationsComponent,
    TranslationInputComponent,
    TicketHolderQuestionnaireComponent,
    RegistrationQuestionnaireComponent,
    NewslettersUnsubscriptionsComponent,
    WebShopWorkshopComponent,
    ModalWindowComponent,
    TicketDropdownComponent,
    BillingAddressComponent,
    EditBillingAddressFormComponent,
    WalkThruInfoComponent,
    WebShopDownloadComponent,
    DownloadTicketHolderComponent,
    DownloadTicketHolderProfileEditComponent,
    FeedbackMessageComponent,
    ResetPasswordComponent,
    ResetEmailComponent,
    ChangePasswordComponent,
    WorkshopsVisitorsComponent,
    WorkshopVisitorsCountComponent,
    CofirmationWorkshopComponent,
    ConfirmationWorkshopPricesComponent,
    PaymentLandingPageComponent,
    VerifyEmailComponent,
    CountdownComponent,
    LogoutComponent,
    SponsorListComponent,
    WebShopRecipeComponent,
    SortByPipe,
    SelfRegTimeoutComponent,
    ResetButtonComponent,
    IframeModalComponent,
    BigLoaderComponent,
    EventPassedComponent,
    WebShopBuyerQuestionnaireComponent,
    NumbersOnlyDirective,
    BrowserCheckComponent,
    CookiesPopupComponent,
    BottomStickyComponent,
    DataProtectionPageComponent,
    EventSeriesComponent,
    SpinnerComponent,
    MaintenencePageComponent,
    AgeRatingComponent,
    TicketsDownloadComponent,
    OneTimeDirective,
    ShowPasswordDirective,
    SkeletonLoaderComponent,
    PackageCounterComponent
  ],
  imports: [
    /* Add .withServerTransition() to support Universal rendering.
     * The application ID can be any identifier which is unique on
     * the page.
     */
    SharedModule,
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'], // toggled buttons

          [{ header: 1 }, { header: 2 }], // custom button values
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
          [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
          [{ direction: 'rtl' }], // text direction

          [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
          [{ header: [1, 2, 3, 4, 5, 6, false] }],

          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          [{ font: [] }],
          [{ align: [] }],

          ['clean'], // remove formatting button
          ['link']
        ]
      },
      theme: 'snow'
    }),
    BrowserModule.withServerTransition({ appId: 'my-app' }),
    !environment.serverRendered
      ? StoreModule.forRoot(reducers, {
          metaReducers
        })
      : [],
    environment.serverRendered ? StoreModule.forRoot(reducers, {}) : [],

    EffectsModule.forRoot([
      AdditionalServicesEffect,
      CustomizationEffect,
      ExhibitionEffect,
      HelperEffect,
      LegitimationEffect,
      PressNewsEffect,
      UserEffect,
      StepsFormsEffect,
      ColorizerEffect,
      TicketEffect
    ]),
    StoreDevtoolsModule.instrument({
      maxAge: 50,
      logOnly: !environment.production
    }),
    FileUploadModule,
    CalendarModule,
    FormsModule,
    HttpModule,
    HttpClientModule,
    ReactiveFormsModule,
    Routing,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      parser: { provide: TranslateParser, useClass: CustomParser },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MissingTranslation,
        deps: [MissingTranslationService]
      }
    }),
    WidgetModule,    
    NgxSkeletonLoaderModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: APP_BASE_HREF, useValue: '/' },
    LoginGuardUser,
    LoginGuardSignedOut,
    MaintenancePageGuard,
    StepPermissionGuard,
    LoginGuardAdmin,
    LoginService,
    DatePipe,
    ApplicationInsightsService,
    FilterPlaceholderPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
