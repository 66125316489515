import { first, filter, skip } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import * as fromRoot from '../../app.reducer';
import * as userActions from '../../shared/services-with-reducers/user/user.actions';

import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { HelperService } from '../../shared/services-with-reducers/helpers/helper.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  public response: string;
  public hashStatus: string = '';
  public submitedForm = false;
  public resetLink = null;

  private hash: string;
  private crmHash: string = null;
  private subscriptions = new Subscription();

  constructor(
    private _route: ActivatedRoute,
    public _router: Router,
    private _store: Store<fromRoot.State>,
    private _helperService: HelperService
  ) {
    this._store.dispatch(new userActions.PasswordChanged(null));
    this._store.dispatch(new userActions.ResetReducer());
  }

  ngOnInit() {
    this._route.params.pipe(first()).subscribe(params => {
      if (params.hash) {
        this.hash = params.hash;
      }
    });

    this._route.queryParams.pipe(first()).subscribe(queryParams => {
      if (queryParams.crmHash) {
        this.crmHash = queryParams.crmHash;
      }
    });

    this.subscriptions.add(
      this._helperService
        .checkHashValidity(this.hash)
        .pipe(
          filter(data => !!data),
          first()
        )
        .subscribe(hashStatus => {
          this.hashStatus = hashStatus.status;
          if (
            hashStatus.status === 'expired' ||
            hashStatus.status === 'nonExistent' ||
            hashStatus.status === 'invalid'
          ) {
            this.resetLink = false;
          }
        })
    );

    this.subscriptions.add(
      // skip the first so we dont get the initial value from redux
      this._store
        .select(fromRoot.passChangeStatus)
        .pipe(skip(1))
        .subscribe(statusCode => {
          if (statusCode === 200) {
            this.resetLink = true;
          } else {
            this.resetLink = false;
          }
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  changePassword() {
    this._store.dispatch(
      new userActions.ChangePassword({ hash: this.hash, crmHash: this.crmHash })
    );
    this.submitedForm = true;
  }

  navigateHome() {
    this._router.navigate(['/']);
  }
}
