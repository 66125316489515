import { Component, Input } from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'app-translation-input',
  templateUrl: './translation-input.component.html',
  styleUrls: ['./translation-input.component.scss']
})
export class TranslationInputComponent {
  @Input() tree: Array<any>;

  public modalWindowOpen: boolean = false;
  public editedItem = { translation: '', description: '' };

  toggleCheck(item) {
    item.isActive = !item.isActive;
  }

  isNoMoreFallback(item) {
    item.isFallback = false;
  }

  openModalWindow(item) {
    this.modalWindowOpen = true;
    this.editedItem = item;
  }

  closeModalWindow(event) {
    this.isNoMoreFallback(this.editedItem);

    event.stopPropagation();
    this.modalWindowOpen = false;
  }
}
