/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./browser-check.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../shared/pipes/hide-translation/hide-translation.pipe";
import * as i4 from "../shared/modal-window/modal-window.component.ngfactory";
import * as i5 from "../shared/modal-window/modal-window.component";
import * as i6 from "@angular/common";
import * as i7 from "./browser-check.component";
import * as i8 from "@ngrx/store";
var styles_BrowserCheckComponent = [i0.styles];
var RenderType_BrowserCheckComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BrowserCheckComponent, data: {} });
export { RenderType_BrowserCheckComponent as RenderType_BrowserCheckComponent };
function View_BrowserCheckComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "button button-option"], ["modalWindow-close", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModalWindow($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "span", [["data-translation", "browsercheck.modalWindow.close"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(4, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("browsercheck.modalWindow.close")))); _ck(_v, 2, 0, currVal_0); }); }
export function View_BrowserCheckComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.HideTranslationPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 21, "app-modal-window", [["translateTitle", "true"]], null, [[null, "contextmenu"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("contextmenu" === en)) {
        var pd_0 = (_co.closeModalWindowOnRightClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ModalWindowComponent_0, i4.RenderType_ModalWindowComponent)), i1.ɵdid(2, 49152, null, 0, i5.ModalWindowComponent, [], { modalWindowActive: [0, "modalWindowActive"], modalWindowTitle: [1, "modalWindowTitle"], translateTitle: [2, "translateTitle"] }, null), (_l()(), i1.ɵeld(3, 0, null, 1, 17, "div", [["modalWindow-body", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 16, "div", [["class", "browser-icons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "browser-icon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.downloadBrowser("https://www.google.com/chrome/") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Chrome"])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "img", [["src", "../../assets/images/browsers/chrome.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "browser-icon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.downloadBrowser("https://www.mozilla.org/en-US/firefox/new/") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Firefox"])), (_l()(), i1.ɵeld(12, 0, null, null, 0, "img", [["src", "../../assets/images/browsers/firefox.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 3, "div", [["class", "browser-icon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.downloadBrowser("https://www.microsoft.com/en-us/edge") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Edge"])), (_l()(), i1.ɵeld(16, 0, null, null, 0, "img", [["src", "../../assets/images/browsers/edge.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 3, "div", [["class", "browser-icon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.downloadBrowser("https://support.apple.com/downloads/safari") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Safari"])), (_l()(), i1.ɵeld(20, 0, null, null, 0, "img", [["src", "../../assets/images/browsers/safari.png"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, 3, 1, null, View_BrowserCheckComponent_1)), i1.ɵdid(22, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.browserModalOpened; var currVal_1 = "browsercheck.modalWindow.title"; var currVal_2 = "true"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.browserModalCloseVisible; _ck(_v, 22, 0, currVal_3); }, null); }
export function View_BrowserCheckComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-browser-check", [], null, null, null, View_BrowserCheckComponent_0, RenderType_BrowserCheckComponent)), i1.ɵdid(1, 114688, null, 0, i7.BrowserCheckComponent, [i8.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BrowserCheckComponentNgFactory = i1.ɵccf("app-browser-check", i7.BrowserCheckComponent, View_BrowserCheckComponent_Host_0, {}, {}, []);
export { BrowserCheckComponentNgFactory as BrowserCheckComponentNgFactory };
