/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./navigation-tabs.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./navigation-tabs.component";
var styles_NavigationTabsComponent = [i0.styles];
var RenderType_NavigationTabsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavigationTabsComponent, data: {} });
export { RenderType_NavigationTabsComponent as RenderType_NavigationTabsComponent };
function View_NavigationTabsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h1", [["class", "title"]], [[1, "data-translation", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 1, 0, currVal_1); }); }
function View_NavigationTabsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "navigation-tabs-item-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.text; _ck(_v, 1, 0, currVal_0); }); }
function View_NavigationTabsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "navigation-tabs-item"]], [[2, "active", null], [1, "data-translation", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeTabTo(_v.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavigationTabsComponent_3)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_3 = _v.context.$implicit.text; _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.activeTab === _v.context.$implicit.id); var currVal_1 = _v.context.$implicit.title; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.title; _ck(_v, 1, 0, currVal_2); }); }
export function View_NavigationTabsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "navigation-tabs"]], [[2, "wide", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavigationTabsComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "navigation-tabs-group"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavigationTabsComponent_2)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.title; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.tabs; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.type === "wide"); _ck(_v, 0, 0, currVal_0); }); }
export function View_NavigationTabsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-navigation-tabs", [], null, null, null, View_NavigationTabsComponent_0, RenderType_NavigationTabsComponent)), i1.ɵdid(1, 49152, null, 0, i3.NavigationTabsComponent, [], null, null)], null, null); }
var NavigationTabsComponentNgFactory = i1.ɵccf("app-navigation-tabs", i3.NavigationTabsComponent, View_NavigationTabsComponent_Host_0, { tabs: "tabs", title: "title", type: "type", activeTab: "activeTab" }, { activeTabChanged: "activeTabChanged" }, []);
export { NavigationTabsComponentNgFactory as NavigationTabsComponentNgFactory };
