<div class="reset-email-wrapper">
    <div class="reset-email-header">
        <span data-translation="reset-email.title">{{ 'reset-email.title' | translate | hideTranslation }}</span>
    </div>

    <div *ngIf="!submitedForm && hashStatus === 'ok'" class="reset-email-body">
        <div class="reset-email-success-body" data-translation="reset-email.text-confirm">
            {{ 'reset-email.text-confirm' | translate | hideTranslation }}
        </div>
        <div class="reset-email-button-wrapper">
            <button class="button" type="akcept-button" (click)="changeEmail()" data-translation="reset-email.confirm">
                {{ 'reset-email.confirm' | translate | hideTranslation }}
            </button>
        </div>
    </div>

    <div *ngIf="submitedForm || hashStatus !== 'ok'" class="reset-email-body">
        <div *ngIf="resetLink === null || hashStatus === 'waiting'" class="reset-email-success-body">
            <div class="reset-email-fail-text" data-translation="reset-email.text-sending">
                {{ 'reset-email.text-sending' | translate | hideTranslation }}
            </div>
        </div>
        <div *ngIf="resetLink === false" class="reset-email-success-body">
            <div class="reset-email-fail-text" data-translation="reset-email.text-fail">
                {{ 'reset-email.text-fail' | translate | hideTranslation }}
            </div>
        </div>
        <div *ngIf="resetLink" class="reset-email-success-body">
            <div class="reset-email-success-text" data-translation="reset-email.text-success">
                {{ 'reset-email.text-success' | translate | hideTranslation }}
            </div>
        </div>
        <div *ngIf="resetLink || resetLink === false" class="reset-email-button-wrapper">
            <button class="button back-button" type="button" (click)="navigateHome()"
                data-translation="reset-email.button">
                <span>{{ 'reset-email.button' | translate | hideTranslation }}</span>
            </button>
        </div>
    </div>
</div>