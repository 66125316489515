import { Injectable } from '@angular/core';
import { TicketByIdModel, TicketModel } from '../services-with-reducers/tickets/ticket.interface';
import { TranslateService } from '@ngx-translate/core';
import { select, Store } from '@ngrx/store';
import { filter, first } from 'rxjs/operators';
import * as fromRoot from '../../app.reducer';
import { combineLatest } from 'rxjs';
import cloneDeep from 'lodash.clonedeep';
import * as ticketActions from '../../shared/services-with-reducers/tickets/ticket.actions';

enum EventName {
  AddToCart = 'addToCart',
  RemoveFromCart = 'removeFromCart',
  ProductDetail = 'productDetail',
  Checkout = 'checkout',
  BeginCheckout = 'begin_checkout',
  CheckoutOption = 'checkoutOption',
  Purchase = 'purchase'
}

@Injectable({
  providedIn: 'root'
})
export class GtmService {
  private uaPrefix: string = 'eec';
  private ga4Prefix: string = 'eecga4';

  constructor(
    private _translateService: TranslateService,
    private _store: Store<fromRoot.State>
  ) {}

  pushProductDetail(): void {
    const dataUA: Object = {
      event: `${this.uaPrefix}.${EventName.ProductDetail}`,
      ecommerce: {
        detail: {
          actionField: {
            list: 'Ticketauswahl'
          }
        }
      }
    };
    this.pushToDataLayer(dataUA);

    const dataGA4: Object = {
      event: `${this.ga4Prefix}.${EventName.ProductDetail}`,
      ecommerce: {
        item_list_name: 'Ticketauswahl',
        action: 'detail'
      }
    };
    this.pushToDataLayer(dataGA4);
  }

  pushAddToCart(ticket: TicketByIdModel, quantity: number): void {
    this._store
      .pipe(
        select(fromRoot.getExhibitionSettings),
        filter(data => !!data),
        first()
      )
      .subscribe(settings => {
        const dataUA: Object = {
          event: `${this.uaPrefix}.${EventName.AddToCart}`,
          ecommerce: {
            add: {
              actionField: {
                list: 'Shopping cart'
              },
              products: this.ticketToProducts(settings.eventId, ticket, quantity)
            }
          }
        };
        this.pushToDataLayer(dataUA);

        const dataGA4: Object = {
          event: `${this.ga4Prefix}.${EventName.AddToCart}`,
          ecommerce: {
            item_list_name: 'Shopping cart',
            items: this.ticketToItems(settings.eventId, ticket, quantity)
          }
        };
        this.pushToDataLayer(dataGA4);
      });
  }

  pushRemoveFromCart(ticket: TicketByIdModel, quantity: number): void {
    this._store
      .pipe(
        select(fromRoot.getExhibitionSettings),
        filter(data => !!data),
        first()
      )
      .subscribe(settings => {
        const dataUA: Object = {
          event: `${this.uaPrefix}.${EventName.RemoveFromCart}`,
          ecommerce: {
            remove: {
              actionField: {
                list: 'Shopping cart'
              },
              products: this.ticketToProducts(settings.eventId, ticket, quantity)
            }
          }
        };
        this.pushToDataLayer(dataUA);

        const dataGA4: Object = {
          event: `${this.ga4Prefix}.${EventName.RemoveFromCart}`,
          ecommerce: {
            item_list_name: 'Shopping cart',
            items: this.ticketToItems(settings.eventId, ticket, quantity)
          }
        };
        this.pushToDataLayer(dataGA4);
      });
  }

  pushCheckout(tickets: TicketByIdModel[]): void {
    this._store
      .pipe(
        select(fromRoot.getExhibitionSettings),
        filter(data => !!data),
        first()
      )
      .subscribe(settings => {
        const dataUA: Object = {
          event: `${this.uaPrefix}.${EventName.Checkout}`,
          ecommerce: {
            checkout: {
              actionField: {
                step: 1
              },
              products: this.ticketsToProducts(settings.eventId, tickets)
            }
          }
        };
        this.pushToDataLayer(dataUA);

        const dataGA4: Object = {
          event: `${this.ga4Prefix}.${EventName.BeginCheckout}`,
          ecommerce: {
            step: 1,
            items: this.ticketsToItems(settings.eventId, tickets)
          }
        };
        this.pushToDataLayer(dataGA4);
      });
  }

  pushPersonalization(): void {
    const dataUA: Object = {
      event: `${this.uaPrefix}.${EventName.Checkout}`,
      ecommerce: {
        checkout: {
          actionField: {
            step: 2
          }
        }
      }
    };
    this.pushToDataLayer(dataUA);

    const dataGA4: Object = {
      event: `${this.ga4Prefix}.${EventName.BeginCheckout}`,
      ecommerce: {
        step: 2
      }
    };
    this.pushToDataLayer(dataGA4);
  }

  pushConfirmation(): void {
    combineLatest([
      this._store.pipe(select(fromRoot.getExhibitionSettings)),
      this._store.pipe(select(fromRoot.getTickets)),
      this._store.pipe(select(fromRoot.getOrderUuid))
    ])
    .pipe(
      filter(data => !!data[0] && !!data[1] && !!data[2]),
      first()
    )
    .subscribe(data => {
      const [exhibitionSettings, tickets, orderUuid] = data;
      const ticketsWithCount = this.getTicketsWithCount(tickets);

      const products = this.ticketsToProducts(
        exhibitionSettings.eventId,
        ticketsWithCount
      );

      const revenue = products.reduce((revenue, product) => revenue + product.price * 100 * product.quantity, 0) / 100;

      const dataUA: Object = {
        event: `${this.uaPrefix}.${EventName.Checkout}`,
        ecommerce: {
          currencyCode: exhibitionSettings.currencySettings.currencyCode,
          checkout: {
            actionField: {
              id: orderUuid,
              revenue,
              step: 3
            },
            products
          }
        }
      };
      this.pushToDataLayer(dataUA);

      const dataGA4: Object = {
        event: `${this.ga4Prefix}.${EventName.BeginCheckout}`,
        ecommerce: {
          transaction_id: orderUuid,
          value: revenue,
          currency: exhibitionSettings.currencySettings.currencyCode,
          step: 3,
          items: this.ticketsToItems(exhibitionSettings.eventId, ticketsWithCount)
        }
      };
      this.pushToDataLayer(dataGA4);
    });
  }

  pushCheckoutOption(paymentOption: string): void {
    const dataUA: Object = {
      event: `${this.uaPrefix}.${EventName.CheckoutOption}`,
      ecommerce: {
        checkout_option: {
          actionField: {
            step: 3,
            option: paymentOption
          }
        }
      }
    };
    this.pushToDataLayer(dataUA);

    const dataGA4: Object = {
      event: `${this.ga4Prefix}.${EventName.CheckoutOption}`,
      ecommerce: {
        step: 3,
        action: 'checkout_option',
        option: paymentOption
      }
    };
    this.pushToDataLayer(dataGA4);
  }

  pushPurchase(): void {
    combineLatest([
      this._store.pipe(select(fromRoot.getExhibitionSettings)),
      this._store.pipe(select(fromRoot.getTickets)),
      this._store.pipe(select(fromRoot.getOrderUuid))
    ])
    .pipe(
      filter(data => !!data[0] && !!data[1] && !!data[2]),
      first()
    )
    .subscribe(data => {
      const [exhibitionSettings, tickets, orderUuid] = data;
      const ticketsWithCount = this.getTicketsWithCount(tickets);

      const products = this.ticketsToProducts(
        exhibitionSettings.eventId,
        ticketsWithCount
      );

      const revenue = products.reduce((revenue, product) => revenue + product.price * 100 * product.quantity, 0) / 100;

      const dataUA: Object = {
        event: `${this.uaPrefix}.${EventName.Purchase}`,
        ecommerce: {
          currencyCode: exhibitionSettings.currencySettings.currencyCode,
          checkout: {
            actionField: {
              id: orderUuid,
              revenue
            },
            products
          }
        }
      };
      this.pushToDataLayer(dataUA);

      const dataGA4: Object = {
        event: `${this.ga4Prefix}.${EventName.Purchase}`,
        ecommerce: {
          transaction_id: orderUuid,
          value: revenue,
          currency: exhibitionSettings.currencySettings.currencyCode,
          items: this.ticketsToItems(exhibitionSettings.eventId, ticketsWithCount)
        }
      };
      this.pushToDataLayer(dataGA4);
    
      // if we get to this page payment was successfull and we remove the vouchers
      this._store
      .pipe(
        select(fromRoot.getVoucherTickets),
        filter(data => !!data),
        first()
      )
      .subscribe((voucher: TicketByIdModel[]) => {
        voucher.map(item => {
          this._store.dispatch(
            new ticketActions.RemoveVoucher({ voucherCode: item.voucherCode, skipAnonymousChecks: false, skipSettingIsAnonyTicketTaken: true })
          );
        });
      });
    });
  }
  
  private ticketToProducts(
    eventId: number,
    ticket: TicketByIdModel,
    quantity: number
  ) {
    ticket = cloneDeep(ticket);
    ticket.count = quantity;
    return this.ticketsToProducts(eventId, [ticket]);
  }

  private ticketsToProducts(eventId: number, tickets: TicketByIdModel[]) {
    return tickets.map((ticket: TicketByIdModel) => {
      const name = this._translateService.instant(ticket.name);
      const category = ticket.groupName ? this._translateService.instant(ticket.groupName) : '';

      return {
        id: ticket.id,
        name: name,
        category: category,
        quantity: ticket.count,
        brand: eventId,
        coupon: ticket.voucherCode,
        price: ticket.price
      };
    });
  }

  private ticketToItems(
    eventId: number,
    ticket: TicketByIdModel,
    quantity: number
  ) {
    ticket = cloneDeep(ticket);
    ticket.count = quantity;
    return this.ticketsToItems(eventId, [ticket]);
  }

  private ticketsToItems(eventId: number, tickets: TicketByIdModel[]) {
    return tickets.map((ticket: TicketByIdModel) => {
      const name = this._translateService.instant(ticket.name);
      const category = ticket.groupName ? this._translateService.instant(ticket.groupName) : '';

      return {
        item_id: ticket.id,
        item_name: name,
        item_category: category,
        quantity: ticket.count,
        item_brand: eventId,
        coupon: ticket.voucherCode,
        price: ticket.price
      };
    });
  }

  private getTicketsWithCount(tickets: TicketModel): TicketByIdModel[] {
    const ticketsWithCount: TicketByIdModel[] = [];

    for (const uniqueId in tickets) {
      const ticket = tickets[uniqueId];

      if (ticket.count > 0) {
        ticketsWithCount.push(ticket);
      }
    }

    return ticketsWithCount;
  }

  private pushToDataLayer(data: Object): void {
    window['dataLayer'] = window['dataLayer'] || [];
    
    //clearing the ecommerce object
    //it's recommended that you use the following command to clear the ecommerce object prior to pushing an ecommerce event to the data layer. Clearing the object will prevent multiple ecommerce events on a page from affecting each other:
    window['dataLayer'].push({ ecommerce: null });

    //push new data:
    window['dataLayer'].push(data);    
  }
}