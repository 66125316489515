import {
  LegitimationListModel,
  LegitimationPostModel,
  LegitimationStatusModel
} from './legitimation.interface';

import { Action } from '@ngrx/store';

export enum ActionTypes {
  GET_LEGITIMATION_LIST = '[Legitimation] Get legititmation list',
  ADD_LEGITIMATION_TO_LIST = '[Legitimation] Set legititmation list',
  GET_LEGITIMATION_STATUS = '[Legitimation] Get legititmation status',
  SET_LEGITIMATION_STATUS = '[Legitimation] Set legititmation status',
  GET_LEGITIMATION_FAX_ID = '[Legitimation] Get legitimation fax id',
  SET_LEGITIMATION_FAX_ID = '[Legitimation] Set legitimation fax id',
  POST_LEGITIMATION_INFO = '[Legitimation] Post legitimation data',
  SET_LEGITIMATION_INFO_RESPONSE = '[Legitimation] Post legitimation response',
  RESET_REDUCER = '[Legitimation] reset reducer'
}

export class GetLegitimationList implements Action {
  readonly type = ActionTypes.GET_LEGITIMATION_LIST;
  constructor(public payload: number) {}
}

export class AddLegitimationToList implements Action {
  readonly type = ActionTypes.ADD_LEGITIMATION_TO_LIST;
  constructor(public payload: LegitimationListModel[]) {}
}

export class GetLegitimationStatus implements Action {
  readonly type = ActionTypes.GET_LEGITIMATION_STATUS;
  constructor(public payload: { userId; eventId }) {}
}

export class SetLegitimationStatus implements Action {
  readonly type = ActionTypes.SET_LEGITIMATION_STATUS;
  constructor(public payload: LegitimationStatusModel) {}
}

export class GetLegitimationFaxId implements Action {
  readonly type = ActionTypes.GET_LEGITIMATION_FAX_ID;
  constructor(public payload: { eventId: number; userId: number }) {}
}

export class SetLegitimationFaxId implements Action {
  readonly type = ActionTypes.SET_LEGITIMATION_FAX_ID;
  constructor(public payload: number) {}
}

export class PostLegitimationInfo implements Action {
  readonly type = ActionTypes.POST_LEGITIMATION_INFO;
  constructor(
    public payload: {
      feed: LegitimationPostModel;
      userId: number;
      eventId: number;
    }
  ) {}
}

export class SetLegitimationInfoResponse implements Action {
  readonly type = ActionTypes.SET_LEGITIMATION_INFO_RESPONSE;
  constructor(public payload: boolean) {}
}

export class ResetReducer implements Action {
  readonly type = ActionTypes.RESET_REDUCER;
}

export type Actions =
  | GetLegitimationList
  | AddLegitimationToList
  | GetLegitimationStatus
  | SetLegitimationStatus
  | GetLegitimationFaxId
  | SetLegitimationFaxId
  | PostLegitimationInfo
  | SetLegitimationInfoResponse
  | ResetReducer;
