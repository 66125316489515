import * as customizationActions from '../../shared/services-with-reducers/customization/customization.actions';
import * as exhibitionActions from '../../shared/services-with-reducers/exhibition/exhibition.actions';
import * as fromRoot from '../../app.reducer';
import * as helperActions from '../../shared/services-with-reducers/helpers/helper.actions';
import * as stepsActions from '../../shared/services-with-reducers/step-forms/steps-forms.actions';

import { ActivatedRoute } from '@angular/router';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ChangeDetectorRef
} from '@angular/core';
import {
  Observable,
  Subscription,
  combineLatest as observableCombineLatest
} from 'rxjs';
import { filter, first } from 'rxjs/operators';

import { AppService } from '../../app.service';
import { CustomizationService } from '../../shared/services-with-reducers/customization/customization.service';
import { ExhibitionModel } from '../../shared/services-with-reducers/exhibition/exhibition.interface';
import { LocalizedImagesModel, OperatorsSettingsModel } from '../../shared/services-with-reducers/customization/customization.interfaces';
import { select, Store } from '@ngrx/store';
import { VisibilityPayloadModel } from '../../shared/services-with-reducers/step-forms/step.interface';

@Component({
  moduleId: module.id,
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent implements OnInit, OnDestroy {
  public exhibitions: ExhibitionModel[];
  public localizedImages: LocalizedImagesModel = null;
  public localizedImages$: Observable<LocalizedImagesModel>;
  public isLoggedInAsAdmin$: Observable<boolean>;
  public exhibitionId$: Observable<number>;
  public exhibitionLanguage$: Observable<string>;
  public loaded: boolean = false;
  public operatorSettings: OperatorsSettingsModel;
  private _subscriptions = new Subscription();

  constructor(
    private _store: Store<fromRoot.State>,
    private _customizationService: CustomizationService,
    private _appService: AppService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) {
    this.exhibitionId$ = this._store.select(fromRoot.getSelectedExhibitionId);
    this.exhibitionLanguage$ = this._store.select(fromRoot.getLanguage);
  }

  ngOnInit() {
    //?Ev=80&Lng=de&Code=BMM18T3st4Us!

    this._subscriptions.add(
      this.route.queryParams.subscribe(params => {
        let newUrl = '/webshop/';
        const optionalParams = [];

        if (params.Ev) {
          //https://expo-demo.teamaxess.com/webshop/200/tickets?voucher=AXGS95
          newUrl += `${params.Ev}/tickets`;

          if (params.Code) {
            optionalParams.push(`voucher=${params.Code}`);
          }

          if (params.Lng) {
            optionalParams.push(`lang=${params.Lng}`);
          }

          if (optionalParams.length) {
            newUrl += `?` + optionalParams.join('&');
          }

          window.location.href = window.location.origin + newUrl;
        }
      })
    );

    this.isLoggedInAsAdmin$ = this._store.select(fromRoot.isLoggedInAsAdmin);

    // RESET reducers
    this._subscriptions.add(
      this._appService.resetReducers().subscribe(() => {
        this.loaded = true;
        this.cdr.detectChanges();
      })
    );

    // load new settings
    this._subscriptions.add(
      this._store
        .select(fromRoot.getAllExhibitionsCollection)
        .subscribe(data => {
          this.exhibitions = data.sort((a, b) => {
            let aStartDate = <Date>a.startDate;
            let bStartDate = <Date>b.startDate;

            return aStartDate.getTime() - bStartDate.getTime(); // date to timestamp
          });
        })
    );

    //removing custom scripts from an event
    const scripts = document.getElementById('generic-script');
    const allPageScripts = document.getElementById('generic-script-all-page');
    if (!!scripts) {
      scripts.remove();
    }
    if (!!allPageScripts) {
      allPageScripts.remove();
    }

    // load new events list
    this._subscriptions.add(
      this._store
        .select(fromRoot.getLanguage)
        .pipe(filter(lang => !!lang))
        .subscribe(lang => {
          this._store.dispatch(
            new exhibitionActions.GetListOfAllExhibitions(lang)
          );
        })
    );

    //if url route parameter 'sr' is true we enable the selfregistration (home url ending with '?sr=true')
    // we can disable the self registration with route parameter false or just home url without parameter
    this._subscriptions.add(
      this.route.queryParams.subscribe(params => {
        if (params.sr && params.sr === 'true') {
          this._store.dispatch(new helperActions.SetSelfRegistration(true));
        } else if (!params.sr || params.sr === 'false') {
          this._store.dispatch(new helperActions.SetSelfRegistration(false));
        }
      })
    );

    //if we come to home page we reset information about event series page
    this._store.dispatch(new helperActions.SetEventSeriesPage(null));

    this._store
      .select(fromRoot.getSelfRegistration)
      .pipe(first())
      .subscribe(selfRegistration => {
        this.setStepsVisibility(!selfRegistration);
      });

    // set default red environment
    this._customizationService.setColors('red');

    this._subscriptions.add(
      observableCombineLatest([
        this.exhibitionId$,
        this.exhibitionLanguage$
      ])
        .pipe(
          filter(([exhibitionId, exhibitionLanguage]) => !!exhibitionLanguage)
        )
        .subscribe(([exhibitionId, exhibitionLanguage]) => {
          const exhibitionOrOperatorId = exhibitionId ? exhibitionId : -1;

          // effects are no more triggered in this loop so call it in next one
          setTimeout(() => {
            this._store.dispatch(
              new customizationActions.GetLocalizedImages({
                eventId: Number(exhibitionOrOperatorId),
                countryCode: exhibitionLanguage
              })
            );

            this._store.dispatch(
              new exhibitionActions.GetTitles(exhibitionOrOperatorId)
            );

            this._store.dispatch(
              new exhibitionActions.GetProfessions(exhibitionOrOperatorId)
            );

            this._store.dispatch(
              new exhibitionActions.GetDepartments(exhibitionOrOperatorId)
            );

            this._store.dispatch(
              new exhibitionActions.GetOccupationalGroups(exhibitionOrOperatorId)
            );

            this.localizedImages$ = this._store.select(
              fromRoot.getLocalizedImages
            );

            this._subscriptions.add(
              this.localizedImages$
                .pipe(filter(images => !!images))
                .subscribe(images => {
                  this.localizedImages = images;
                })
            );
          });
        })
    );

    this._store.pipe(
      select(fromRoot.getOperatorsSettings),
      first(operatorSettings => !!operatorSettings)
    ).subscribe(operatorSettings => {
      this.operatorSettings = operatorSettings;
    });

    setTimeout(() => {
      this.cdr.detectChanges();
    }, 0);
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  setStepsVisibility(isVisible) {
    const stepsArray = ['legitimation', 'confirmation', 'payment', 'invoice'];
    const visibilityPayload: VisibilityPayloadModel[] = stepsArray.map(
      (step): VisibilityPayloadModel => {
        return {
          stepKey: step,
          visible: isVisible
        };
      }
    );

    visibilityPayload.push({ stepKey: 'recipe', visible: !isVisible });
    console.log(visibilityPayload)
    this._store.dispatch(
      new stepsActions.SetStepsVisibility(visibilityPayload)
    );
  }
}
