<div *ngIf="workshop">
  <div class="name">
    {{ workshop.workshopName | translate | hideTranslation }}
  </div>
  <div class="dates">
    {{ workshop.roomName | translate | hideTranslation }} |
    <span *ngIf="!hideWorkshopDate">
      {{ workshop.date | localizedDate: 'MMM d, yyyy' }} |
    </span>
    {{ workshop.start | localizedDate: 'shortTime' }} -
    {{ workshop.end | localizedDate: 'shortTime' }}
  </div>
</div>
