import { UserModel } from './services-with-reducers/user/user.interface';
import * as jwt_decode from 'jwt-decode';

const roleClaim: string =
  'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';

export class AppConstants {
  public static MISSING_TRANSLATION = 'TBD: TRANSLATE ME!';

  public static PASSWORD_MIN_LENGTH = 6;

  public static PAYMENT_METHODS = {
    FREE: 'FREE'
    // SAFERPAY: 1,
    // TWIN: 2,
    // IPAYMENT_CC: 3,
    // IPAYMENT_ELV: 4,
    // IPAYMENT_PAYPAL: 5,
    // YANDEX: 7,
    // SBERBANK: 8,
    // SAFERPAYAPI: 9
  };

  public static LEGITIMATION_MAX_FILE_SIZE = 3.5; // MegaBytes
  public static MB_TO_BINARY_BYTES_CONSTATNT = 1048576;

  /**
   * if value of TOKEN_VALIDITY_IN_MINUTES is changed it must be also changed in BE,
   * search there for variable 'TokenValidityInMinutes'
   * */
  public static TOKEN_VALIDITY_IN_MINUTES = 1440;

  public static datepickerLocalization = {
    de: {
      firstDayOfWeek: 0,
      // dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
      // dayNamesShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
      dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
      monthNames: [
        'Januar',
        'Februar',
        'März',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Dezember'
      ]
      // monthNamesShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
    },
    en: {
      firstDayOfWeek: 0,
      // dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      // dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      monthNames: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ]
      // monthNamesShort: [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ]
    }
  };

  public static LANGS = ['en', 'de', 'fr', 'it', 'sp'];

  public static DEFAULT_LANG = 'en';

  public static BREAKPOINTS = {
    xs: 554,
    sm: 768,
    md: 992,
    lg: 1200
  };

  public static COUNTRY_CODES = [
    'AT',
    'FR',
    'DE',
    'CH',
    'AF',
    'SA',
    'BH',
    'AE',
    'DZ',
    'KW',
    'MA',
    'JO',
    'YE',
    'AD',
    'AO',
    'MO',
    'NZ',
    'AR',
    'SD',
    'AU',
    'AZ',
    'BS',
    'BD',
    'BB',
    'BE',
    'BZ',
    'BJ',
    'BM',
    'IN',
    'BT',
    'BO',
    'BA',
    'BW',
    'BR',
    'BG',
    'BF',
    'BI',
    'CV',
    'CM',
    'CA',
    'CZ',
    'CL',
    'CO',
    'CR',
    'ME',
    'CU',
    'DK',
    'QA',
    'MV',
    'DJ',
    'DM',
    'DO',
    'EC',
    'EE',
    'SV',
    'GR',
    'ES',
    'MX',
    'GA',
    'GH',
    'GI',
    'GP',
    'GU',
    'GT',
    'GQ',
    'GN',
    'ER',
    'HT',
    'KR',
    'AM',
    'HN',
    'HR',
    'ID',
    'IR',
    'IQ',
    'IM',
    'IT',
    'ET',
    'JM',
    'TJ',
    'GL',
    'KH',
    'KE',
    'CY',
    'KG',
    'LA',
    'LV',
    'LS',
    'LR',
    'LY',
    'LI',
    'LT',
    'LB',
    'LU',
    'IS',
    'MG',
    'HU',
    'MK',
    'MU',
    'MW',
    'MY',
    'ML',
    'MT',
    'EG',
    'MZ',
    'MC',
    'MN',
    'MM',
    'NA',
    'NL',
    'NP',
    'NI',
    'NE',
    'NG',
    'JP',
    'NO',
    'PK',
    'PA',
    'PG',
    'PY',
    'PE',
    'PH',
    'PL',
    'PT',
    'TH',
    'PR',
    'KZ',
    'UY',
    'CF',
    'TG',
    'RO',
    'RU',
    'RW',
    'CG',
    'CD',
    'GE',
    'OM',
    'WS',
    'SM',
    'SN',
    'SC',
    'AL',
    'SL',
    'SG',
    'SI',
    'SK',
    'SO',
    'ZA',
    'RS',
    'LK',
    'VA',
    'FI',
    'SY',
    'SE',
    'SZ',
    'TW',
    'TZ',
    'TD',
    'GM',
    'TO',
    'TN',
    'TR',
    'TM',
    'UG',
    'UA',
    'GB',
    'US',
    'UZ',
    'VE',
    'VN',
    'HK',
    'IL',
    'ZM',
    'CN',
    'ZW',
    'TT',
    'IE',
    'XK',
    'AN',
    'BY',
    'JE',
    'RE',
    'FO',
    'MD',
    'NC'
  ];

  public static PHONE_PRECALL = {
    AT: '+43',
    DE: '+49',
    CH: '+41',
    AF: '+93',
    SA: '+966',
    BH: '+973',
    AE: '+971',
    DZ: '+213',
    KW: '+965',
    MA: '+212',
    JO: '+962',
    YE: '+967',
    AD: '+376',
    AO: '+244',
    MO: '+853',
    NZ: '+64',
    AR: '+54',
    SD: '+249',
    AU: '+61',
    AZ: '+994',
    BS: '+1242',
    BD: '+880',
    BB: '+1246',
    BL: '+32',
    BZ: '+501',
    BJ: '+229',
    BM: '+1441',
    IN: '+91',
    BT: '+975',
    BO: '+591',
    BA: '+387',
    BW: '+267',
    BR: '+55',
    BG: '+359',
    BF: '+226',
    BI: '+257',
    CV: '+238',
    CM: '+237',
    CA: '+1',
    CZ: '+420',
    CL: '+56',
    CO: '+57',
    CR: '+506',
    ME: '+382',
    CU: '+53',
    DK: '+45',
    QA: '+974',
    MV: '+960',
    DJ: '+253',
    DM: '+1767',
    DO: '+18',
    EC: '+593',
    EE: '+372',
    IE: '+353',
    SV: '+503',
    GR: '+30',
    ES: '+34',
    MX: '+52',
    FR: '+33',
    GA: '+241',
    GH: '+233',
    GI: '+350',
    GP: '+590',
    GU: '+1671',
    GT: '+502',
    GQ: '+240',
    GN: '+224',
    ER: '+291',
    HT: '+509',
    KR: '+82',
    AM: '+374',
    HN: '+504',
    HR: '+385',
    ID: '+62',
    IR: '+98',
    IQ: '+964',
    IM: '+44',
    IT: '+39',
    ET: '+251',
    JM: '+1876',
    TJ: '+992',
    GL: '+299',
    KH: '+855',
    KE: '+254',
    CY: '+357',
    KG: '+996',
    LA: '+856',
    LV: '+371',
    LS: '+266',
    LR: '+231',
    LY: '+218',
    LI: '+423',
    LT: '+370',
    LB: '+961',
    LU: '+352',
    IS: '+354',
    MD: '+261',
    HU: '+36',
    MK: '+389',
    MW: '+265',
    MY: '+60',
    ML: '+223',
    MT: '+356',
    EG: '+20',
    MZ: '+258',
    MC: '+377',
    MN: '+976',
    MM: '+95',
    NA: '+264',
    NL: '+31',
    NP: '+977',
    NI: '+505',
    NE: '+227',
    NG: '+234',
    JP: '+81',
    NO: '+47',
    PK: '+92',
    PA: '+507',
    PG: '+675',
    PY: '+595',
    PE: '+51',
    PH: '+63',
    PL: '+48',
    PT: '+351',
    TH: '+66',
    PR: '+1',
    KZ: '+7',
    UY: '+598',
    CF: '+236',
    CG: '+242',
    TG: '+228',
    RO: '+40',
    RU: '+7',
    RW: '+250',
    GE: '+995',
    OM: '+968',
    WS: '+685',
    SM: '+378',
    SN: '+221',
    SC: '+248',
    AL: '+355',
    SL: '+232',
    SG: '+65',
    SI: '+386',
    SK: '+421',
    SO: '+252',
    ZA: '+27',
    RS: '+381',
    LK: '+94',
    VA: '+3906698',
    FI: '+358',
    SY: '+963',
    SE: '+46',
    SZ: '+268',
    TW: '+886',
    TZ: '+255',
    TD: '+235',
    GM: '+220',
    TO: '+676',
    TN: '+216',
    TR: '+90',
    TM: '+993',
    UG: '+256',
    UA: '+380',
    GB: '+44',
    US: '+1',
    UZ: '+998',
    VE: '+58',
    VN: '+84',
    HK: '+852',
    IL: '+972',
    ZM: '+260',
    CN: '+86',
    ZW: '+263'
  };

  public static LANGUAGES = [
    { code: 'ca', name: 'Catalan; Valencian', nativeName: 'Català' },
    { code: 'zh', name: 'Chinese', nativeName: '中文 (Zhōngwén), 汉语, 漢語' },
    { code: 'hr', name: 'Croatian', nativeName: 'hrvatski' },
    { code: 'cs', name: 'Czech', nativeName: 'čeština' },
    { code: 'da', name: 'Danish', nativeName: 'dansk' },
    { code: 'nl', name: 'Dutch', nativeName: 'Nederlands, Vlaams' },
    { code: 'en', name: 'English', nativeName: 'English' },
    { code: 'et', name: 'Estonian', nativeName: 'eesti, eesti keel' },
    { code: 'fi', name: 'Finnish', nativeName: 'suomi, suomen kieli' },
    { code: 'fr', name: 'French', nativeName: 'français' },
    { code: 'de', name: 'German', nativeName: 'Deutsch' },
    { code: 'hu', name: 'Hungarian', nativeName: 'Magyar' },
    { code: 'it', name: 'Italian', nativeName: 'Italiano' },
    { code: 'no', name: 'Norwegian', nativeName: 'Norsk' },
    { code: 'pl', name: 'Polish', nativeName: 'polski' },
    { code: 'pt', name: 'Portuguese', nativeName: 'Português' },
    { code: 'ro', name: 'Romanian, Moldavian, Moldovan', nativeName: 'română' },
    { code: 'ru', name: 'Russian', nativeName: 'русский язык' },
    { code: 'sk', name: 'Slovak', nativeName: 'slovenčina' },
    { code: 'sl', name: 'Slovene', nativeName: 'slovenščina' },
    {
      code: 'es',
      name: 'Spanish; Castilian',
      nativeName: 'español, castellano'
    },
    { code: 'sv', name: 'Swedish', nativeName: 'svenska' },
    { code: 'tr', name: 'Turkish', nativeName: 'Türkçe' }
  ];

  //reducer names for localStorage synchronization:
  public static userReducer = 'user';
  public static additionalServicesReducer = 'additionalServices';
  public static exhibitionsReducer = 'exhibitions';
  public static ticketsReducer = 'tickets';
  public static customizationReducer = 'customization';
  public static stepsFormsReducer = 'stepsForms';
  public static helperReducer = 'helper';
  public static legitimationReducer = 'legitimation';
  public static pressnewsReducer = 'pressnews';
  public static colorizerReducer = 'colorizer';

  //not actual reducers, we only store data into local or session storage for these keys:
  public static parkingTicketsReducer = 'parkingTickets'; //TODO: should be a reducer!
  public static contingentTicketsReducer = 'contingentTickets'; //TODO: should be a reducer!
  public static activeWidgetTabReducer = 'activeWidgetTab';
  public static hideDataProtectionOnHomePageReducer = 'hideDataProtectionOnHomePage';
  public static hideDataProtectionOnEventReducer = 'hideDataProtectionOnEvent';
  public static browserSessionsReducer = 'browserSessions';
  public static tabCountReducer = 'tabCount';
  public static appVersionReducer = 'app-version';

  /**
   * List of all "global" reducers for which all data is stored into localStorage under the same global key (without a specific sessionId prefix).
   * Here we should only add reducers containing data which should be shared between browser tabs/windows on the same domain/subdomain.
   */
  public static syncedGlobalReducers = [
    AppConstants.userReducer
  ];

  /**
   * ONLY FOR WEBSHOP ADMIN:
   * List of all "session specific" reducers for which all data is stored into localStorage under a key prefixed by a specific sessionId when a currently logged in user is a webshop admin.
   * Here we should add reducers containing data which should NOT be shared between browser tabs/windows on the same domain/subdomain.
   */
  public static syncedAdminSessionReducers = [
    AppConstants.additionalServicesReducer,
    AppConstants.exhibitionsReducer,
    AppConstants.ticketsReducer,
    AppConstants.customizationReducer,
    AppConstants.stepsFormsReducer,
    AppConstants.helperReducer
  ];

  /**
   * ONLY FOR REGULAR USERS:
   * List of all "session specific" reducers for which all data is stored into localStorage under a key prefixed by a specific sessionId when a currently logged in user is NOT a webshop admin.
   * Here we should add reducers containing data which should NOT be shared between browser tabs/windows on the same domain/subdomain.
   */
  public static syncedUserSessionReducers = [
    AppConstants.additionalServicesReducer,
    AppConstants.exhibitionsReducer,
    AppConstants.ticketsReducer,
    AppConstants.customizationReducer,
    AppConstants.legitimationReducer,
    AppConstants.stepsFormsReducer,
    AppConstants.pressnewsReducer,
    AppConstants.helperReducer,
    AppConstants.colorizerReducer
  ];

  /**
   * ALL LOCAL STORAGE OBJECTS WHICH MUST BE STORED ON SESSION LEVEL MUST BE DEFINED HERE!
   * (every reducer defined in either syncedAdminSessionReducers or syncedUserSessionReducers must be added here)
   * List of all "session specific" reducers for which all data is stored into localStorage under a key prefixed by a specific sessionId.
   * Here we should add reducers containing data which should NOT be shared between browser tabs/windows on the same domain/subdomain.
   */
  public static allSyncedSessionReducers = [
    AppConstants.additionalServicesReducer,
    AppConstants.exhibitionsReducer,
    AppConstants.ticketsReducer,
    AppConstants.parkingTicketsReducer,
    AppConstants.contingentTicketsReducer,
    AppConstants.customizationReducer,
    AppConstants.legitimationReducer,
    AppConstants.stepsFormsReducer,
    AppConstants.pressnewsReducer,
    AppConstants.helperReducer,
    AppConstants.colorizerReducer,
    AppConstants.activeWidgetTabReducer
  ];

  public static getUserTypeFromJwt(userModel: UserModel): string {
    const tokedDecoded = jwt_decode(userModel.authToken);
    return tokedDecoded[roleClaim].toLowerCase();
  }

  /*   public static STEPS_SCRIPT_URL = {
    tickets:
      'https://5474840.fls.doubleclick.net/activityi;src=5474840;type=click;cat=1706tiau;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=',
    personal:
      'https://5474840.fls.doubleclick.net/activityi;src=5474840;type=sale;cat=1706tika;qty=1;cost=2550;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=',
    // TODO: change folowing urls to corect ones based on step name
    legitimation:
      'https://5474840.fls.doubleclick.net/activityi;src=5474840;type=sale;cat=1706tika;qty=1;cost=2550;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=',
    workshop:
      'https://5474840.fls.doubleclick.net/activityi;src=5474840;type=sale;cat=1706tika;qty=1;cost=2550;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=',
    menu:
      'https://5474840.fls.doubleclick.net/activityi;src=5474840;type=sale;cat=1706tika;qty=1;cost=2550;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=',
    confirmation:
      'https://5474840.fls.doubleclick.net/activityi;src=5474840;type=sale;cat=1706tika;qty=1;cost=2550;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=',
    invoice:
      'https://5474840.fls.doubleclick.net/activityi;src=5474840;type=sale;cat=1706tika;qty=1;cost=2550;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord='
  }; */
}
