<div
  class="container-fluid"
  *ngIf="
    (pageRunnungInIframe &&
      displayBanner &&
      exhibition &&
      localizedImages &&
      exhibitionSettings) ||
    (exhibition &&
      localizedImages &&
      exhibitionSettings &&
      !pageRunnungInIframe)
  "
>
  <div class="banner">
    <div class="logo-side" *ngIf="!exhibitionSettings?.hideHeaderLogoArea">
      <div class="logo-slope"></div>
      <div class="logo-wrap">
        <a
          href="{{ exhibitionSettings.sponsorLogoUrl }}"
          target="_blank"
          [ngClass]="{
            'hide-link': !exhibitionSettings.isSponsorLogoUrlVisible
          }"
        >
          <img
            class="exhibition-img"
            [src]="localizedImages.logo"
            alt=""
            *ngIf="localizedImages.logo"
          />
        </a>
        <div class="exhibition-date" *ngIf="exhibition">
          <span class="date-text">
            {{ exhibition.startDate | localizedDate | async }}</span
          >
          <span class="date-dash"> - </span>
          <span class="date-text">
            {{ exhibition.endDate | localizedDate | async }}</span
          >
        </div>
      </div>
    </div>
    <div class="img-side">
      <a
        href="{{ exhibitionSettings.bannerLink }}"
        target="_blank"
        [ngClass]="{'hide-link': !exhibitionSettings.isBannerLinkVisible}">
          <img
            class="exhibition-img"
            [src]="localizedImages.banner"
            alt=""
            *ngIf="localizedImages.banner"
          />
      </a>
    </div>
  </div>
  <div *ngIf="exhibitionSettings?.hasSponsorArea">
    <a
      href="{{ exhibitionSettings.sponsorBannerUrl }}"
      target="_blank"
      [ngClass]="{
        'hide-link': !exhibitionSettings.isSponsorBannerUrlVisible
      }"
    >
      <img
        class="sponsored-img"
        *ngIf="sponsorBanner"
        [src]="sponsorBanner"
        alt=""
      />
    </a>
  </div>

  <div class="mobile-date" *ngIf="exhibition">
    <!-- {{ 'date' | translate:{date: exhibition.startDate | localizedDate:'mediumDate'} }} -->
    {{ exhibition.startDate | localizedDate: 'MMM d, yyyy' }} -
    {{ exhibition.endDate | localizedDate: 'MMM d, yyyy' }}
  </div>
</div>
