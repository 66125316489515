import { CheckboxInput } from '../../shared/forms/inputs/input-checkbox.class';
import { CustomizationService } from '../../shared/services-with-reducers/customization/customization.service';
import { DropdownInput } from '../../shared/forms/inputs/input-dropdown.class';
import { InputBase } from '../../shared/forms/inputs/input-base.class';
import { TextInput } from '../../shared/forms/inputs/input-text.class';

export function getProfileBillingAddressEditForm(
  shouldDisplayAddress,
  customizationService: CustomizationService,
  selectedBillingAddress
): InputBase<any>[] {
  const inputs = [
    new TextInput({
      key: 'company',
      label: 'profile.company',
      order: 1,
      translate: true,
      required: false,
      value: selectedBillingAddress.company,
      cssClass: 'col-sm-12'
    }),
    new DropdownInput({
      key: 'country',
      label: 'profile.country',
      options: [],
      order: 2,
      translate: true,
      required: false,
      value: selectedBillingAddress.country,
      cssClass: 'col-md-5 col-lg-4'
    }),
    new TextInput({
      key: 'zipCode',
      label: 'profile.zip-code',
      order: 4,
      translate: true,
      required: true,
      value: selectedBillingAddress.zipCode,
      cssClass: 'col-md-3 col-sm-4',
      zipcodeValidation: true,
      zipCodeCitiesTag: 'edit-billing-address-form.get-profile-billing-address-form'
    }),
    new TextInput({
      key: 'city',
      label: 'profile.city',
      order: 5,
      translate: true,
      required: true,
      value: selectedBillingAddress.city,
      cssClass: 'col-md-9 col-sm-8 col-lg-5'
    }),
    new TextInput({
      key: 'street',
      label: 'profile.street',
      order: 6,
      translate: true,
      required: true,
      value: selectedBillingAddress.street,
      cssClass: 'col-sm-9 col-lg-5'
    })
  ];

  if (shouldDisplayAddress) {
    inputs.push(
      new TextInput({
        key: 'address',
        label: 'profile.address',
        order: 3,
        translate: true,
        required: false,
        value: selectedBillingAddress.address,
        cssClass: 'col-md-7 col-lg-5'
      })
    );
  }

  return inputs.sort((a, b) => a.order - b.order);
}
