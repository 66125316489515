<div class="modal-wrap" *ngIf="iframeModalUrl">

    <div class="modal-container" (click)="preventClick($event)">
        <iframe [src]="iframeModalUrl | safeResource" frameborder="0" class="iframe"></iframe>
        <div class="modal-buttons">
            <div class="button-wrap modal-window-close">
                <button (click)="closeModalWindow($event)" class="button button-option">
                    <span data-translation="modalWindow.close">{{ 'modalWindow.close' | translate | hideTranslation }}</span>
                </button>
            </div>
        </div>

    </div>
</div>
