<ng-template #ticketTypeGroupTemplate let-ticketTypeGroup="ticketTypeGroup">
  <div *ngFor="let subGroup of ticketTypeGroup.subGroup">
    <div class="ticket-header">
      <span class="title black">
        {{ subGroup.name | translate | hideTranslation }}
        <span *ngIf="subGroup.voucherCode" class="voucher-code">
          ({{ subGroup.voucherCode }})
        </span>
      </span>
      <span class="count black">{{ subGroup.tickets.length }}x</span>
      <span class="price black">
        {{subGroup.tickets[0].price| currencySymbolGap: settings.currencySettings}}
      </span>
      <span class="per-type-price price">
        {{(subGroup.tickets[0].price * subGroup.tickets.length).toFixed(2) | currencySymbolGap: settings.currencySettings}}
      </span>
    </div>
    <div *ngIf="subGroup.tickets[0].ticketHolderAdditionalData && 
      subGroup.tickets[0].ticketHolderAdditionalData.workshops &&
      subGroup.tickets[0].ticketHolderAdditionalData.workshops.length">
      <app-confirmation-workshop-prices *ngIf="!settings.hideWorkshopPrice"
        [workshops]="workshops"
        [subGroup]="subGroup"
        [currencySettings]="settings.currencySettings">
      </app-confirmation-workshop-prices>
    </div>
    <div class="ticket" *ngFor="let ticket of subGroup.tickets">
      <div class="ticket-data">
        <span class="name" *ngIf="ticket.ticketHolderConfirmation">
          {{ ticket.ticketHolderConfirmation.firstName }} 
          {{ ticket.ticketHolderConfirmation.lastName }}
        </span>
        <span *ngIf="!ticket.ticketHolderConfirmation ||
          !ticket.ticketHolderConfirmation.sendtoowner ||
          ticket.ticketHolderConfirmation.sendingOption === 'allToBuyer';
        else notToBuyer"
        class="email send-to-buyer"
          >{{ buyerEmail }}
        </span>
        <ng-template #notToBuyer>
          <span *ngIf="ticket.ticketHolderConfirmation.email" class="email">
            {{ ticket.ticketHolderConfirmation.email }}
          </span>
          <span *ngIf="!ticket.ticketHolderConfirmation.email" class="email">
            {{ buyerEmail }}
          </span>
        </ng-template>
        <app-ticket-dropdown
          (onSelect)="dropdownChanged($event)"
          [confirmationOptions]="confirmationOptions"
          [ticket]="ticket">
        </app-ticket-dropdown>
      </div>
      <div *ngIf="ticket.ticketHolderAdditionalData &&
        ticket.ticketHolderAdditionalData.workshops &&
        ticket.ticketHolderAdditionalData.workshops.length"
        class="ticket-workshops">
        <div class="workshops-title">{{ 'confirmation.workshops' | translate | hideTranslation }}</div>
        <div class="workshop-list">
          <app-cofirmation-workshop *ngFor="let workshopId of ticket.ticketHolderAdditionalData.workshops"
            [workshops]="workshops"
            [workshopId]="workshopId"
            class="workshop-by-name">
          </app-cofirmation-workshop>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<div class="page-padding-wrapper" *ngIf="orderSent">
  <app-loader></app-loader>
</div>
<div class="page-padding-wrapper" *ngIf="settings && !orderSent">
  <div *ngFor="let group of ticketsWithHolders" appAdjustSideHeadline>
    <div *ngIf="group.groupName && group.tickets.length" class="section-headline">
      <h2>{{ group.groupName | translate | hideTranslation }}</h2>
      <div class="section-headline-side">←</div>
    </div>
    <div *ngFor="let ticketTypeGroup of group.tickets" class="section-body confirmation-page">
      <ng-template *ngTemplateOutlet="ticketTypeGroupTemplate; context: { ticketTypeGroup: ticketTypeGroup }"></ng-template>
    </div>
    <div *ngFor="let package of group.packages">
      <div *ngIf="package.name" class="confirmation-package-header">
        <span class="title black">
          {{ group.packageName | translate | hideTranslation }}
        </span>
        <span class="count black">1x</span>
        <span class="price black">
          {{ package.price | currencySymbolGap: settings.currencySettings }}
        </span>
        <span class="per-type-price price">
          {{ package.price.toFixed(2) | currencySymbolGap: settings.currencySettings }}
        </span>
      </div>
      <div *ngFor="let ticketTypeGroup of package.ticketTypeGroup; let i = index"
        [ngClass]="{'package': package.name}" 
        class="section-body confirmation-page">
        <div *ngIf="package.name"
          [ngClass]="{'package': package.name}"
          class="section-headline">
          <h2>{{ ticketTypeGroup.groupName | translate | hideTranslation }}</h2>
          <div *ngIf="!!ticketTypeGroup.groupName && i === 0" class="section-headline-side">←</div>
        </div>
        <ng-template *ngTemplateOutlet="ticketTypeGroupTemplate; context: { ticketTypeGroup: ticketTypeGroup }"></ng-template>
      </div>
    </div>
  </div>

  <div *ngIf="hasParkingTickets" appAdjustSideHeadline>
    <div class="section-headline">
      <h2>{{ (parsedParkingTickets | keyvalue)[0].value.groupName | translate | hideTranslation }}</h2>
      <div class="section-headline-side">←</div>
    </div>
    <div class="section-body confirmation-page">
      <div *ngFor="let parkingTicket of parsedParkingTickets | keyvalue"
        class="ticket-header">
        <span class="title black">
          {{ parkingTicket.value.name | translate | hideTranslation }}
          <span style="padding-left: 15px;">
            {{ parkingTicket.value.since | date: 'mediumDate' }} -
            {{ parkingTicket.value.until | date: 'mediumDate' }}
          </span>
        </span>
        <span class="count black">1x</span>
        <span class="price black">{{ parkingTicket.value.price | currencySymbolGap: settings.currencySettings }}</span>
        <span class="per-type-price price">{{ parkingTicket.value.price.toFixed(2) | currencySymbolGap: settings.currencySettings }}</span>
      </div>
    </div>
  </div>

  <hr />
  <div class="row">
    <div *ngIf="checkboxesInputs?.length" class="col-md-8" >
      <form [formGroup]="checkboxesForm" class="row no-group-margin">
        <app-df-input *ngFor="let input of checkboxesInputs"
          [input]="input"
          [class]="input.cssClass"
          [inputSet]="checkboxesInputs"
          [action]="checkboxesFormsActionName"
          [form]="checkboxesForm">
        </app-df-input>
      </form>
    </div>

    <div class="confirmation-total col-md col-md-auto">
      <h2 class="total black" data-translation="confirmation.total">
        {{ 'confirmation.total' | translate | hideTranslation }}:
        <span class="price"> {{ totalCost.toFixed(2) | currencySymbolGap: settings.currencySettings }}</span>
      </h2>
    </div>
  </div>
  <app-step-navigation-buttons
    [payment]="true"
    [paymentValue]="totalCost"
    [hasAlternativeButton]="true">
    <button *ngIf="!totalCost"
      [disabled]="isAlternativeButtonDisabled"
      (click)="paymentSubmited(AppConstants.PAYMENT_METHODS.FREE, $event)"
      alternative-submit
      class="button save-button">
      <span data-translation="step-navigation.continue-button">{{ 'step-navigation.continue-button' | translate | hideTranslation }}</span>
    </button>

    <div *ngIf="totalCost"
      [class.disabled]="isAlternativeButtonDisabled"
      alternative-submit
      class="payment">
      <div class=" black" data-translation="step-navigation.payment-message"> {{ 'step-navigation.payment-message' | translate | hideTranslation }}:</div>
      <div class="payment-options">
        <div *ngFor="let method of settings.paymentMethods"
          (click)="paymentSubmited(method.id, $event)"
          class="payment-option">
            <div *ngIf="paymentMethodImages[method.id]" class="paymentImgContainer">
              <img [src]="paymentMethodImages[method.id]" [alt]="method.name" />
            </div>
            <div *ngIf="!paymentMethodImages[method.id]">{{method.name}}</div>
        </div>
      </div>
    </div>
  </app-step-navigation-buttons>
</div>
