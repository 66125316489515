/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./age-rating.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./age-rating.component";
var styles_AgeRatingComponent = [i0.styles];
var RenderType_AgeRatingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AgeRatingComponent, data: {} });
export { RenderType_AgeRatingComponent as RenderType_AgeRatingComponent };
export function View_AgeRatingComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { wrapper: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["wrapper", 1]], null, 4, "div", [["class", "age-rating-wrapper"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "age-rating"]], null, null, null, null, null)), i1.ɵdid(4, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵted(5, null, [" ", " + "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "age-rating-wrapper"; var currVal_1 = _co.wrapperDynamicClasses; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = "age-rating"; var currVal_3 = _co.dynamicClasses; _ck(_v, 4, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.rating; _ck(_v, 5, 0, currVal_4); }); }
export function View_AgeRatingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-age-rating", [], null, null, null, View_AgeRatingComponent_0, RenderType_AgeRatingComponent)), i1.ɵdid(1, 114688, null, 0, i3.AgeRatingComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AgeRatingComponentNgFactory = i1.ɵccf("app-age-rating", i3.AgeRatingComponent, View_AgeRatingComponent_Host_0, { wrapperDynamicClasses: "wrapperDynamicClasses", dynamicClasses: "dynamicClasses", rating: "rating", adjustPositions: "adjustPositions" }, {}, []);
export { AgeRatingComponentNgFactory as AgeRatingComponentNgFactory };
