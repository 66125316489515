import { TranslateService } from '@ngx-translate/core';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
var StatusBarService = /** @class */ (function () {
    function StatusBarService(_translateService) {
        this._translateService = _translateService;
        this.statusStack = [];
        this.DEFAULT_TIMEOUT = 15000;
    }
    StatusBarService.prototype.removeFirstStatusInArray = function () {
        var _this = this;
        setTimeout(function () {
            _this.statusStack.shift();
        }, this.getTimeOut());
    };
    StatusBarService.prototype.getTimeOut = function () {
        return this.customTimeOut ? this.customTimeOut : this.DEFAULT_TIMEOUT;
    };
    StatusBarService.prototype.setStatus = function (status, type, timeInMilisecond, dateTime) {
        if (timeInMilisecond) {
            this.customTimeOut = timeInMilisecond;
        }
        this.statusStack.push({ message: status, type: type, dateTime: dateTime });
        this.removeFirstStatusInArray();
    };
    StatusBarService.prototype.setServerStatus = function (status, type, timeInMilisecond, dateTime) {
        var _this = this;
        if (timeInMilisecond) {
            this.customTimeOut = timeInMilisecond;
        }
        this._translateService.get('error.server').subscribe(function (res) {
            _this.statusStack.push({ message: res + ": " + status, type: type, dateTime: dateTime });
            _this.removeFirstStatusInArray();
        });
    };
    StatusBarService.prototype.translateAndSetStatus = function (translationKey, status, dateTime) {
        var _this = this;
        this._translateService.get(translationKey).subscribe(function (translation) {
            _this.setStatus(translation, status, null, dateTime);
        });
    };
    StatusBarService.ngInjectableDef = i0.defineInjectable({ factory: function StatusBarService_Factory() { return new StatusBarService(i0.inject(i1.TranslateService)); }, token: StatusBarService, providedIn: "root" });
    return StatusBarService;
}());
export { StatusBarService };
