import { skip, first, filter } from 'rxjs/operators';
import * as fromRoot from '../../app.reducer';
import * as userActions from '../../shared/services-with-reducers/user/user.actions';

import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { AppConstants } from '../../shared/app-constants';
import { FormsService } from '../../shared/forms/forms.service';
import { InputBase } from '../../shared/forms/inputs/input-base.class';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { ValidationService } from '../../shared/validation/validation.service';
import { HelperService } from '../../shared/services-with-reducers/helpers/helper.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  public resetPasswordForm: FormGroup;
  public password: AbstractControl;
  public verifyPassword: AbstractControl;
  public resetLink = null;
  public minPasswordLength = AppConstants.PASSWORD_MIN_LENGTH;
  public submitedForm = false;
  public hashStatus: string = '';

  private hash: string;
  private crmHash: string = null;
  private subscriptions = new Subscription();

  constructor(
    private _router: Router,
    private _store: Store<fromRoot.State>,
    private _route: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _formsService: FormsService,
    private _validationService: ValidationService,
    private _location: Location,
    private _helperService: HelperService
  ) {
    // set password status change to null so we can react on it later when we got real response
    this._store.dispatch(new userActions.PasswordChanged(null));
    this._store.dispatch(new userActions.ResetReducer());

    this.resetPasswordForm = _formBuilder.group(
      {
        password: ['', [Validators.minLength(this.minPasswordLength)]],
        verifyPassword: ['', [Validators.minLength(this.minPasswordLength)]]
      },
      {
        validator: ValidationService.equalValueValidator(
          'password',
          'verifyPassword',
          false
        )
      }
    );

    this.password = this.resetPasswordForm.controls['password'];
    this.verifyPassword = this.resetPasswordForm.controls['verifyPassword'];
  }

  ngOnInit() {
    this._route.params.pipe(first()).subscribe(params => {
      if (params.hash) {
        this.hash = params.hash;
      }
    });

    this._route.queryParams.pipe(first()).subscribe(queryParams => {
      if (queryParams.crmHash) {
        this.crmHash = queryParams.crmHash;
      }
    });

    this.subscriptions.add(
      this._helperService
        .checkHashValidity(this.hash)
        .pipe(
          filter(data => !!data),
          first()
        )
        .subscribe(hashStatus => {
          this.hashStatus = hashStatus.status;
          if (
            hashStatus.status === 'expired' ||
            hashStatus.status === 'nonExistent' ||
            hashStatus.status === 'invalid'
          ) {
            this.resetLink = false;
          }
        })
    );

    this.subscriptions.add(
      // skip the first so we dont get the initial value from redux
      this._store
        .select(fromRoot.passChangeStatus)
        .pipe(skip(1))
        .subscribe(statusCode => {
          if (statusCode === 200) {
            this.resetLink = true;
          } else {
            this.resetLink = false;
          }
        })
    );
  }

  onLogIn(event) {
    this._router.navigate(['/']);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  navigateBack() {
    this._router.navigate(['/']);
  }

  navigateToLogin() {
    this._location.replaceState('/login');
  }

  submitResetPassword(password) {
    this._store.dispatch(
      new userActions.ResetPassword({ password, hash: this.hash, crmHash: this.crmHash })
    );
    this.submitedForm = true;
  }
}
