import {
  DoubleClickModel,
  ExhibitionHistoryListModel,
  ExhibitionModel,
  SelectOption
} from './exhibition.interface';

import { Action } from '@ngrx/store';
import { QuestionnaireDataInput } from '../customization/customization.interfaces';

export enum ActionTypes {
  SELECT = '[Exhibition] Set active',
  GET_LIST_OF_ALL_EXHIBITIONS = '[Exhibition] Get list of all exhibitions from API',
  ADD_ALL_EXHIBITIONS_TO_LIST = '[Exhibition] Add all exhibitions to a list',
  RESET_REDUCER = '[Exhibition] reset reducer',
  GET_EXHIBITION_HISTORY_LIST = '[Exhibition] Get Exhibition History List',
  SET_EXHIBITION_HISTORY_LIST = '[Exhibition] Set Exhibition History List',
  GET_DOUBLE_CLICK_SCRIPTS = '[Exhibition] Get Double click scripts via API',
  SET_DOUBLE_CLICK_SCRIPTS = '[Exhibition] Set Double click scripts',
  SET_GENERIC_SCRIPTS = '[Exhibition] Set Fb scripts',
  GET_GENERIC_SCRIPTS = '[Exhibition] Get Fb scripts',
  PARTIAL_RESET_REDUCER = '[Exhibition] partialy reset reducers',
  FIRST_LOAD_DONE = '[Exhibition] event loaded',
  GET_TITLES = '[Exhibition] Get title',
  SET_TITLES = '[Exhibition] Set title',
  GET_PROFESSIONS = '[Exhibition] Get professions',
  SET_PROFESSIONS = '[Exhibition] Set professions',
  GET_DEPARTMENTS = '[Exhibition] Get departments',
  SET_DEPARTMENTS = '[Exhibition] Set departments',
  GET_OCCUPATIONAL_GROUPS = '[Exhibition] Get occupational groups',
  SET_OCCUPATIONAL_GROUPS = '[Exhibition] Set occupational groups',
  GET_ALL_TITLES = '[Exhibition] Get all titles',
  SET_ALL_TITLES = '[Exhibition] Set all titles',
  GET_ALL_PROFESSIONS = '[Exhibition] Get all professions',
  SET_ALL_PROFESSIONS = '[Exhibition] Set all professions',
  GET_ALL_DEPARTMENTS = '[Exhibition] Get all departments',
  SET_ALL_DEPARTMENTS = '[Exhibition] Set all departments',
  GET_ALL_OCCUPATIONAL_GROUPS = '[Exhibition] Get all occupational groups',
  SET_ALL_OCCUPATIONAL_GROUPS = '[Exhibition] Set all occupational groups',
}

/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions.
 *
 * See Discriminated Unions: https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions
 */

export class SelectAction implements Action {
  readonly type = ActionTypes.SELECT;
  constructor(public payload: number) {}
}

export class GetListOfAllExhibitions implements Action {
  readonly type = ActionTypes.GET_LIST_OF_ALL_EXHIBITIONS;
  constructor(public payload: string) {}
}

export class AddAllExhibitionsToListAction implements Action {
  readonly type = ActionTypes.ADD_ALL_EXHIBITIONS_TO_LIST;
  constructor(public payload: ExhibitionModel[]) {}
}

export class ResetReducer implements Action {
  readonly type = ActionTypes.RESET_REDUCER;
}

export class PartialResetReducer implements Action {
  readonly type = ActionTypes.PARTIAL_RESET_REDUCER;
}

export class GetExhibitionHistoryList implements Action {
  readonly type = ActionTypes.GET_EXHIBITION_HISTORY_LIST;
  constructor(public payload: number) {}
}
export class FirstLoadDone implements Action {
  readonly type = ActionTypes.FIRST_LOAD_DONE;
}

export class SetExhibitionHistoryList implements Action {
  readonly type = ActionTypes.SET_EXHIBITION_HISTORY_LIST;
  constructor(public payload: ExhibitionHistoryListModel[]) {}
}

export class SetDoubleClickScripts implements Action {
  readonly type = ActionTypes.SET_DOUBLE_CLICK_SCRIPTS;
  constructor(public payload: DoubleClickModel) {}
}

export class GetDoubleClickScripts implements Action {
  readonly type = ActionTypes.GET_DOUBLE_CLICK_SCRIPTS;
  constructor(public payload: number) {}
}
export class GetGenericScripts implements Action {
  readonly type = ActionTypes.GET_GENERIC_SCRIPTS;
  constructor(public payload: number) {}
}
export class GetTitles implements Action {
  readonly type = ActionTypes.GET_TITLES;
  constructor(public payload: number) {}
}
export class SetTitles implements Action {
  readonly type = ActionTypes.SET_TITLES;
  constructor(public payload: SelectOption[]) {}
}
export class SetGenericScripts implements Action {
  readonly type = ActionTypes.SET_GENERIC_SCRIPTS;
  constructor(public payload: DoubleClickModel) {}
}
export class GetProfessions implements Action {
  readonly type = ActionTypes.GET_PROFESSIONS;
  constructor(public payload: number) {}
}
export class SetProfessions implements Action {
  readonly type = ActionTypes.SET_PROFESSIONS;
  constructor(public payload: SelectOption[]) {}
}
export class GetDepartments implements Action {
  readonly type = ActionTypes.GET_DEPARTMENTS;
  constructor(public payload: number) {}
}
export class SetDepartments implements Action {
  readonly type = ActionTypes.SET_DEPARTMENTS;
  constructor(public payload: SelectOption[]) {}
}
export class GetOccupationalGroups implements Action {
  readonly type = ActionTypes.GET_OCCUPATIONAL_GROUPS;
  constructor(public payload: number) {}
}
export class SetOccupationalGroups implements Action {
  readonly type = ActionTypes.SET_OCCUPATIONAL_GROUPS;
  constructor(public payload: SelectOption[]) {}
}
export class GetAllTitles implements Action {
  readonly type = ActionTypes.GET_ALL_TITLES;
  constructor() {}
}
export class SetAllTitles implements Action {
  readonly type = ActionTypes.SET_ALL_TITLES;
  constructor(public payload: QuestionnaireDataInput[]) {}
}
export class GetAllProfessions implements Action {
  readonly type = ActionTypes.GET_ALL_PROFESSIONS;
  constructor() {}
}
export class SetAllProfessions implements Action {
  readonly type = ActionTypes.SET_ALL_PROFESSIONS;
  constructor(public payload: QuestionnaireDataInput[]) {}
}
export class GetAllDepartments implements Action {
  readonly type = ActionTypes.GET_ALL_DEPARTMENTS;
  constructor() {}
}
export class SetAllDepartments implements Action {
  readonly type = ActionTypes.SET_ALL_DEPARTMENTS;
  constructor(public payload: QuestionnaireDataInput[]) {}
}
export class GetAllOccupationalGroups implements Action {
  readonly type = ActionTypes.GET_ALL_OCCUPATIONAL_GROUPS;
  constructor() {}
}
export class SetAllOccupationalGroups implements Action {
  readonly type = ActionTypes.SET_ALL_OCCUPATIONAL_GROUPS;
  constructor(public payload: QuestionnaireDataInput[]) {}
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Actions =
  | FirstLoadDone
  | GetListOfAllExhibitions
  | AddAllExhibitionsToListAction
  | SelectAction
  | ResetReducer
  | PartialResetReducer
  | GetExhibitionHistoryList
  | SetExhibitionHistoryList
  | SetDoubleClickScripts
  | GetDoubleClickScripts
  | SetGenericScripts
  | GetGenericScripts
  | GetTitles
  | SetTitles
  | GetProfessions
  | SetProfessions
  | GetDepartments
  | SetDepartments
  | GetOccupationalGroups
  | SetOccupationalGroups
  | GetAllTitles
  | SetAllTitles
  | GetAllProfessions
  | SetAllProfessions
  | GetAllDepartments
  | SetAllDepartments
  | GetAllOccupationalGroups
  | SetAllOccupationalGroups;
