import * as additionalServicesActions from './shared/services-with-reducers/additional-services/additional-services.actions';
import * as customizationActions from './shared/services-with-reducers/customization/customization.actions';
import * as exhibitionActions from './shared/services-with-reducers/exhibition/exhibition.actions';
import * as fromRoot from './app.reducer';
import * as legitimationActions from './shared/services-with-reducers/legitimation/legitimation.actions';
import * as stepsFormsActions from './shared/services-with-reducers/step-forms/steps-forms.actions';
import * as ticketActions from './shared/services-with-reducers/tickets/ticket.actions';
import * as userActions from './shared/services-with-reducers/user/user.actions';
import * as helperActions from './shared/services-with-reducers/helpers/helper.actions';
import {
  combineLatest as observableCombineLatest,
  fromEvent as observableFromEvent,
  merge as observableMerge,
  of as observableOf
} from 'rxjs';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { Store } from '@ngrx/store';
import { TicketsService } from './shared/services-with-reducers/tickets/tickets.service';
import { mapTo } from 'rxjs/operators';
import { CustomizationService } from './shared/services-with-reducers/customization/customization.service';
import { consoleLog, setLocalStorageObject } from './shared/app-utils';
import { ErrorHandlingService } from './shared/error-handling/error-handling.service';
import { FormsService } from './shared/forms/forms.service';
import { AppConstants } from './shared/app-constants';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public online$: Observable<boolean>;

  constructor(
    private _store: Store<fromRoot.State>,
    private _ticketsService: TicketsService,
    private _customizationService: CustomizationService,
    private _errorHandlingService: ErrorHandlingService,
    private _formsService: FormsService
  ) {
    if (typeof navigator !== 'undefined') {
      this.online$ = observableMerge(
        observableOf(navigator.onLine),
        observableFromEvent(window, 'online').pipe(mapTo(true)),
        observableFromEvent(window, 'offline').pipe(mapTo(false))
      );
    } else {
      this.online$ = observableOf(true);
    }
  }

  resetReducersWithCallback(
    releaseAllVouchersAndTickets: boolean,
    callback: Function
  ) {
    if (releaseAllVouchersAndTickets) {
      this._ticketsService.releaseAllVouchersAndTickets();
    }

    const timeOut = setTimeout(() => {
      callback();
    }, 5000);

    const sharedObservable = observableCombineLatest(
      this._store.select(fromRoot.getVoucherTickets),
      this._store.select(fromRoot.getTicketsBooking)
    )
      .filter(data => {
        let [vouchers, ticketBookings] = data;
        ticketBookings = ticketBookings || { bookings: [], timestamp: null };

        const noOfTicketsBooked =
          ticketBookings.bookings.reduce((acc, curr) => {
            return acc + (curr.count || 0);
          }, 0) || 0;

        const noOfVouchersBooked =
          vouchers.reduce((acc, curr) => {
            return acc + (curr.count || 0);
          }, 0) || 0;

        const allBookedCount = noOfTicketsBooked + noOfVouchersBooked;
        if (!allBookedCount) {
          clearTimeout(timeOut);
        }
        return !allBookedCount;
      })
      .first()
      .share();

    sharedObservable.subscribe(() => {
      callback();
    });

    return sharedObservable;
  }

  resetReducers(releaseAllVouchersAndTickets: boolean = true, releaseContingents: boolean = true) {
    consoleLog("Reset reducers");
    //clear all previously received errors from the API (so we don't interpret errors that occurred in a previous shopping process):
    this._errorHandlingService.clearAllErrors();
    this._formsService.resetInputsValidity();

    if (releaseContingents) {
      this._ticketsService.postReleaseTicketForDay();
    }

    //we have to make this operation synchronous as otherwise setStyles method would potentially be called before the store state has been changed
    //(e.g. the customization template wouldn't be changed):
    return this.resetReducersWithCallback(releaseAllVouchersAndTickets, () => {
      new Promise<void>(resolve => {
        this._store.dispatch(new customizationActions.PartialResetReducer()); // customization reducer keeps localized images and operator settings
        this._store.dispatch(new exhibitionActions.PartialResetReducer());
        this._store.dispatch(new stepsFormsActions.PartialResetReducer());
        this._store.dispatch(new additionalServicesActions.ResetReducer());
        this._store.dispatch(new ticketActions.ResetReducer());
        this._store.dispatch(new legitimationActions.ResetReducer());
        this._store.dispatch(new helperActions.ResetZipCodesCities());

        setLocalStorageObject(AppConstants.parkingTicketsReducer, '');
        setLocalStorageObject(AppConstants.contingentTicketsReducer, '');

        resolve();
      }).then(() => { 
        this._customizationService.setStyles();
      });
    });
  }

  resetReducersWithUser(releaseAllVouchersAndTickets: boolean = true, releaseContingents: boolean = true) {
    consoleLog("Reset event including user");
    //clear all previously received errors from the API (so we don't interpret errors that occurred in a previous shopping process):
    this._errorHandlingService.clearAllErrors();
    this._formsService.resetInputsValidity();
    
    if (releaseContingents) {
      this._ticketsService.postReleaseTicketForDay();
    }

    // customization reducer is kept on logout
    return this.resetReducersWithCallback(releaseAllVouchersAndTickets, () => {
      new Promise<void>(resolve => {
        this._store.dispatch(new customizationActions.ResetShoppingStartTime());
        this._store.dispatch(new userActions.ResetReducer());
        this._store.dispatch(new exhibitionActions.PartialResetReducer());
        this._store.dispatch(new stepsFormsActions.ResetReducer());
        this._store.dispatch(new additionalServicesActions.ResetReducer());
        this._store.dispatch(new ticketActions.ResetReducer());
        this._store.dispatch(new legitimationActions.ResetReducer());
        this._store.dispatch(new helperActions.ResetZipCodesCities());

        setLocalStorageObject(AppConstants.parkingTicketsReducer, '');
        setLocalStorageObject(AppConstants.contingentTicketsReducer, '');

        resolve();
      }).then(() => { 
        this._customizationService.setStyles();
      });
    });
  }
}