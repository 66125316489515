import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subject, combineLatest } from 'rxjs';
import { takeUntil, filter, first } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { WidgetService } from './../../../app/shared/services-with-reducers/widget/widget.service';
import { HelperService } from './../../../app/shared/services-with-reducers/helpers/helper.service';

import {
  LocalizedImagesModel,
  ExhibitionSettingModel
} from './../../../app/shared/services-with-reducers/customization/customization.interfaces';
import {
  BannerWidgetSettings,
  BannerWidgetPostSettings
} from '../../../app/_pages/widget-admin/widget-models.interface';

import * as fromRoot from '../../../app/app.reducer';
import * as exhibitionActions from './../../../app/shared/services-with-reducers/exhibition/exhibition.actions';

import { ActivatedRoute } from '@angular/router';
import {
  updateQueryStringParameter,
  getQueryParamsFromLocation
} from '../../../app/shared/app-utils';

@Component({
  selector: 'app-widget-banner-page',
  templateUrl: './widget-banner-page.component.html',
  styleUrls: ['./widget-banner-page.component.scss']
})
export class WidgetBannerPage implements OnInit, OnDestroy {
  public widgetSettings: BannerWidgetSettings;
  public backgroundImage: MimeType | string;
  public link: string = `${window.location.protocol}//${window.location.host}`;

  private loadSettingsProcessor = {
    preSelectedTicket: (
      preSelectedTicketData: BannerWidgetPostSettings['preSelectedTicket'],
      processedSettings
    ) => {
      const { ticketGroupNr, ticketPersonNr } = preSelectedTicketData;

      this.link = this._helperService.removeQueryParam('ticket', this.link);

      if (ticketGroupNr && ticketPersonNr) {
        const ticketUniqeId = `${ticketGroupNr}_${ticketPersonNr}`;

        // update returned value
        processedSettings['preSelectedTicket'] = ticketUniqeId;

        this.link = updateQueryStringParameter(
          this.link,
          'ticket',
          ticketUniqeId
        );
      }
    },
    preSelectedVoucher: (voucherCode: string) => {
      this.link = this._helperService.removeQueryParam('voucher', this.link);

      if (voucherCode) {
        this.link = updateQueryStringParameter(
          this.link,
          'voucher',
          voucherCode
        );
      }
    },
    eventId: (eventId, processedSett, widgetSettings: any) => {
      const hasTicket =
        widgetSettings.preSelectedTicket &&
        widgetSettings.preSelectedTicket.ticketPersonNr;
      const hasVoucher = widgetSettings.preSelectedVoucher;

      let link = `${this.link}`;

      if (hasTicket || hasVoucher) {
        link += `/webshop/${eventId}/tickets`;
      }

      this.link = link;
    }
  };

  private unsubscribe = new Subject<void>();

  constructor(
    private _route: ActivatedRoute,
    private _store: Store<fromRoot.State>,
    private _widgetService: WidgetService,
    private _helperService: HelperService
  ) {}

  ngOnInit() {
    combineLatest(this._route.params)
      .pipe(
        filter(([params]) => {
          if (params.id) {
            return true;
          }
        }),
        takeUntil(this.unsubscribe)
      )
      .subscribe(([params]) => {
        const widgetId = params.id;

        this._widgetService
          .getWidgetById(widgetId)
          .subscribe((widgetSettings: BannerWidgetSettings) => {
            this.widgetSettings = this._widgetService.processWidgetSettingsLoad(
              widgetSettings,
              this.loadSettingsProcessor
            ) as BannerWidgetSettings;

            const fileId = this.widgetSettings.background.fileId;

            if (fileId) {
              this._widgetService
                .getBackgroundFile(fileId)
                .subscribe(
                  backgroundImage =>
                    (this.backgroundImage = window.URL.createObjectURL(
                      backgroundImage
                    ))
                );
            } else {
              this._store.dispatch(
                new exhibitionActions.SelectAction(widgetSettings.eventId)
              );

              combineLatest(
                this._store.select(fromRoot.getExhibitionSettings),
                this._store.select(fromRoot.getLocalizedImages)
              )
                .pipe(
                  filter(
                    ([exhibitionSettings, localizedImages]: [
                      ExhibitionSettingModel,
                      LocalizedImagesModel
                    ]) => {
                      if (exhibitionSettings && localizedImages) {
                        return true;
                      }
                    }
                  ),
                  first()
                )
                .subscribe(
                  ([exhibitionSettings, localizedImages]: [
                    ExhibitionSettingModel,
                    LocalizedImagesModel
                  ]) => {
                    this.backgroundImage = localizedImages.logo;
                  }
                );
            }
          });
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
