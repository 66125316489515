/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./web-self-registration.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../banner/banner.component.ngfactory";
import * as i3 from "../banner/banner.component";
import * as i4 from "@ngrx/store";
import * as i5 from "@angular/router";
import * as i6 from "../shared/services-with-reducers/customization/customization.service";
import * as i7 from "../loader/loader.component.ngfactory";
import * as i8 from "../loader/loader.component";
import * as i9 from "../step-navigation/step-navigation.component.ngfactory";
import * as i10 from "../step-navigation/step-navigation.component";
import * as i11 from "../shared/services-with-reducers/tickets/tickets.service";
import * as i12 from "../shared/services-with-reducers/helpers/helper.service";
import * as i13 from "@angular/common";
import * as i14 from "./web-self-registration.component";
import * as i15 from "../shared/forms/forms.service";
import * as i16 from "../app.service";
var styles_WebSelfRegistrationComponent = [i0.styles];
var RenderType_WebSelfRegistrationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WebSelfRegistrationComponent, data: {} });
export { RenderType_WebSelfRegistrationComponent as RenderType_WebSelfRegistrationComponent };
function View_WebSelfRegistrationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-banner", [], null, null, null, i2.View_BannerComponent_0, i2.RenderType_BannerComponent)), i1.ɵdid(1, 245760, null, 0, i3.BannerComponent, [i4.Store, i5.ActivatedRoute, i6.CustomizationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_WebSelfRegistrationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loader", [], null, null, null, i7.View_LoaderComponent_0, i7.RenderType_LoaderComponent)), i1.ɵdid(1, 49152, null, 0, i8.LoaderComponent, [i1.ElementRef], null, null)], null, null); }
function View_WebSelfRegistrationComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-step-navigation", [], null, null, null, i9.View_StepNavigationComponent_0, i9.RenderType_StepNavigationComponent)), i1.ɵdid(1, 180224, null, 0, i10.StepNavigationComponent, [i4.Store, i1.ElementRef, i6.CustomizationService, i11.TicketsService, i12.HelperService], null, null)], null, null); }
export function View_WebSelfRegistrationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_WebSelfRegistrationComponent_1)), i1.ɵdid(1, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "page-content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WebSelfRegistrationComponent_2)), i1.ɵdid(5, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WebSelfRegistrationComponent_3)), i1.ɵdid(7, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(9, 212992, null, 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isSelfRefistrationEnabled; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.route.children.length; _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.route.children.length && !_co.eventIsOver); _ck(_v, 7, 0, currVal_2); _ck(_v, 9, 0); }, null); }
export function View_WebSelfRegistrationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-web-self-registration", [], [[8, "className", 0]], null, null, View_WebSelfRegistrationComponent_0, RenderType_WebSelfRegistrationComponent)), i1.ɵdid(1, 245760, null, 0, i14.WebSelfRegistrationComponent, [i15.FormsService, i5.ActivatedRoute, i5.Router, i4.Store, i6.CustomizationService, i16.AppService, i12.HelperService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).hostClasses; _ck(_v, 0, 0, currVal_0); }); }
var WebSelfRegistrationComponentNgFactory = i1.ɵccf("app-web-self-registration", i14.WebSelfRegistrationComponent, View_WebSelfRegistrationComponent_Host_0, {}, {}, []);
export { WebSelfRegistrationComponentNgFactory as WebSelfRegistrationComponentNgFactory };
