export interface buyerInfoReadOnlyModel {
  label: string;
  data: Array<{ title?: string; translate?: boolean }>;
  contains: string[];
  alwaysVisible?: boolean;
}

export function readOnlyModelFactory(): buyerInfoReadOnlyModel[] {
  return [
    {
      label: 'personalize.buyer-info.read-only.name',
      data: [],
      contains: ['gender', 'title', 'firstName', 'lastName']
    },
    {
      label: 'personalize.buyer-info.read-only.company',
      data: [],
      contains: ['company']
    },
    {
      label: 'personalize.buyer-info.read-only.position',
      data: [],
      contains: ['function']
    },
    {
      label: 'personalize.buyer-info.read-only.address',
      data: [],
      contains: ['street', 'city', 'zipCode', 'country']
    },
    {
      label: 'personalize.buyer-info.read-only.email',
      data: [],
      contains: ['email']
    },
    {
      label: 'personalize.buyer-info.read-only.phone',
      data: [],
      contains: ['telephone']
    },
    {
      label: 'personalize.buyer-info.vat',
      data: [],
      contains: ['vatNumber'],
      alwaysVisible: true
    }
  ];
}
