/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./feedback-message.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "../shared/pipes/hide-translation/hide-translation.pipe";
import * as i5 from "./feedback-message.component";
var styles_FeedbackMessageComponent = [i0.styles];
var RenderType_FeedbackMessageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FeedbackMessageComponent, data: {} });
export { RenderType_FeedbackMessageComponent as RenderType_FeedbackMessageComponent };
function View_FeedbackMessageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [], [[1, "data-translation", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(3, 1)], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.label; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 1, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent, 0), i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_v.parent.context.$implicit.label)))); _ck(_v, 1, 0, currVal_1); }); }
function View_FeedbackMessageComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.label; _ck(_v, 1, 0, currVal_0); }); }
function View_FeedbackMessageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [["class", "message-list-item"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FeedbackMessageComponent_2)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FeedbackMessageComponent_3)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.translate; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_v.context.$implicit.translate; _ck(_v, 4, 0, currVal_1); }, null); }
function View_FeedbackMessageComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [["class", "message-list-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["..."]))], null, null); }
export function View_FeedbackMessageComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.HideTranslationPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "message-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "message-header"], ["data-translation", "validation.feedback-message.title"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(5, 1), (_l()(), i1.ɵeld(6, 0, null, null, 5, "ul", [["class", "message-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_FeedbackMessageComponent_1)), i1.ɵdid(8, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(0, i3.SlicePipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FeedbackMessageComponent_4)), i1.ɵdid(11, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform(_co.feedbackMessages, 0, 3)); _ck(_v, 8, 0, currVal_1); var currVal_2 = (_co.feedbackMessages.length > 3); _ck(_v, 11, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 5, 0, i1.ɵnov(_v, 0), i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("validation.feedback-message.title")))); _ck(_v, 3, 0, currVal_0); }); }
export function View_FeedbackMessageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-feedback-message", [], null, null, null, View_FeedbackMessageComponent_0, RenderType_FeedbackMessageComponent)), i1.ɵdid(1, 49152, null, 0, i5.FeedbackMessageComponent, [], null, null)], null, null); }
var FeedbackMessageComponentNgFactory = i1.ɵccf("app-feedback-message", i5.FeedbackMessageComponent, View_FeedbackMessageComponent_Host_0, { feedbackMessages: "feedbackMessages" }, {}, []);
export { FeedbackMessageComponentNgFactory as FeedbackMessageComponentNgFactory };
