/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../web-shop-confirmation.component.scss.shim.ngstyle";
import * as i1 from "./confirmation-workshop-prices.component.scss.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../../shared/pipes/hide-translation/hide-translation.pipe";
import * as i5 from "../../../shared/pipes/currency-symbol-gap/currency-symbol-gap.pipe";
import * as i6 from "@angular/common";
import * as i7 from "./confirmation-workshop-prices.component";
var styles_ConfirmationWorkshopPricesComponent = [i0.styles, i1.styles];
var RenderType_ConfirmationWorkshopPricesComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_ConfirmationWorkshopPricesComponent, data: {} });
export { RenderType_ConfirmationWorkshopPricesComponent as RenderType_ConfirmationWorkshopPricesComponent };
function View_ConfirmationWorkshopPricesComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 12, "div", [["class", "ticket-header"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 3, "span", [["class", "title black"]], null, null, null, null, null)), (_l()(), i2.ɵted(2, null, ["", ""])), i2.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i2.ChangeDetectorRef]), i2.ɵppd(4, 1), (_l()(), i2.ɵeld(5, 0, null, null, 1, "span", [["class", "count black"]], null, null, null, null, null)), (_l()(), i2.ɵted(6, null, ["", "x"])), (_l()(), i2.ɵeld(7, 0, null, null, 2, "span", [["class", "price black"]], null, null, null, null, null)), (_l()(), i2.ɵted(8, null, ["", ""])), i2.ɵppd(9, 2), (_l()(), i2.ɵeld(10, 0, null, null, 2, "span", [["class", "per-type-price price"]], null, null, null, null, null)), (_l()(), i2.ɵted(11, null, ["", ""])), i2.ɵppd(12, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i2.ɵunv(_v, 2, 0, _ck(_v, 4, 0, i2.ɵnov(_v.parent, 0), i2.ɵunv(_v, 2, 0, i2.ɵnov(_v, 3).transform(_v.context.$implicit.name)))); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.count; _ck(_v, 6, 0, currVal_1); var currVal_2 = i2.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i2.ɵnov(_v.parent, 1), _v.context.$implicit.price, _co.currencySettings)); _ck(_v, 8, 0, currVal_2); var currVal_3 = i2.ɵunv(_v, 11, 0, _ck(_v, 12, 0, i2.ɵnov(_v.parent, 1), (_v.context.$implicit.price * _v.context.$implicit.count).toFixed(2), _co.currencySettings)); _ck(_v, 11, 0, currVal_3); }); }
export function View_ConfirmationWorkshopPricesComponent_0(_l) { return i2.ɵvid(0, [i2.ɵpid(0, i4.HideTranslationPipe, []), i2.ɵpid(0, i5.CurrencySymbolGapPipe, []), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_ConfirmationWorkshopPricesComponent_1)), i2.ɵdid(3, 278528, null, 0, i6.NgForOf, [i2.ViewContainerRef, i2.TemplateRef, i2.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.workshopsWithPrices; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_ConfirmationWorkshopPricesComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "app-confirmation-workshop-prices", [], null, null, null, View_ConfirmationWorkshopPricesComponent_0, RenderType_ConfirmationWorkshopPricesComponent)), i2.ɵdid(1, 114688, null, 0, i7.ConfirmationWorkshopPricesComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmationWorkshopPricesComponentNgFactory = i2.ɵccf("app-confirmation-workshop-prices", i7.ConfirmationWorkshopPricesComponent, View_ConfirmationWorkshopPricesComponent_Host_0, { workshops: "workshops", subGroup: "subGroup", currencySettings: "currencySettings" }, {}, []);
export { ConfirmationWorkshopPricesComponentNgFactory as ConfirmationWorkshopPricesComponentNgFactory };
