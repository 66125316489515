var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { ActionTypes } from './additional-services.actions';
import cloneDeep from 'lodash.clonedeep';
function isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}
export var initialState = {
    menuOptions: [],
    workshops: [],
    workshopBookings: { bookings: [], timestamp: null },
    workshopBookingsFull: {
        isFull: false,
        workshopId: null
    }
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    var clonedInitialState = cloneDeep(initialState);
    switch (action.type) {
        case ActionTypes.SET_MENU:
            var menuOptionList = action.payload;
            return __assign({}, state, { menuOptions: menuOptionList });
        case ActionTypes.SET_WORKSHOPS:
            var workshopList = action.payload;
            return __assign({}, state, { workshops: workshopList });
        case ActionTypes.REMOVE_MY_WORKSHOP_BOOKINGS:
            var cleanedBookings = state.workshopBookings.bookings.map(function (booking) {
                var seats = booking.bookingsNumber + booking.myBookings.length;
                return __assign({}, booking, { seats: seats, bookingsNumber: seats, myBookings: [] });
            });
            return __assign({}, state, { workshopBookings: { bookings: cleanedBookings, timestamp: null } });
        case ActionTypes.ADD_WORKSHOP_BOOKING_FULL:
            var workshopBookingsFull = action.payload;
            return __assign({}, state, { workshopBookingsFull: workshopBookingsFull });
        case ActionTypes.ADD_WORKSHOP_BOOKING:
            var payload_1 = action.payload.addWorkshopBookingModel;
            var workshopBookings = [];
            if (!payload_1) {
                return state;
            }
            else if (payload_1.added) {
                // we are adding
                //console.log('payload.added');
                var alreadyExist = state.workshopBookings.bookings.find(function (workshop) {
                    return workshop.workshopId === payload_1.workshopId;
                });
                var myBooking_1 = {
                    timestamp: Date.now(),
                    ticketHolderId: payload_1.ticketHolderId
                };
                if (alreadyExist) {
                    workshopBookings = state.workshopBookings.bookings.map(function (workshop) {
                        if (workshop.workshopId === payload_1.workshopId) {
                            workshop.bookingsNumber = payload_1.seats;
                            workshop.seats = payload_1.seats;
                            workshop.myBookings.push(myBooking_1);
                        }
                        return workshop;
                    });
                }
                else {
                    workshopBookings = state.workshopBookings.bookings.slice();
                    workshopBookings.push({
                        bookingsNumber: payload_1.seats,
                        workshopId: payload_1.workshopId,
                        seats: payload_1.seats,
                        myBookings: [myBooking_1]
                    });
                }
            }
            else {
                //console.log('payload.removed');
                // we are removing
                workshopBookings = state.workshopBookings.bookings.reduce(function (acc, workshop) {
                    if (workshop.workshopId === payload_1.workshopId) {
                        workshop.seats = payload_1.seats;
                    }
                    if (payload_1.timestamps) {
                        // removing because of time out
                        workshop.bookingsNumber = 0;
                        workshop.myBookings = workshop.myBookings.filter(function (booking) {
                            return !payload_1.timestamps.includes(booking.timestamp);
                        });
                    }
                    if (isNumber(payload_1.ticketHolderId)) {
                        workshop.bookingsNumber = payload_1.seats;
                        workshop.myBookings = workshop.myBookings.filter(function (booking) {
                            return payload_1.ticketHolderId !== booking.ticketHolderId;
                        });
                    }
                    acc.push(workshop);
                    return acc;
                }, []);
            }
            //console.log('reducer', workshopBookings);
            return __assign({}, state, { workshopBookings: { bookings: workshopBookings, timestamp: Date.now() } });
        case ActionTypes.RESET_REDUCER:
            return clonedInitialState;
        default: {
            return state;
        }
    }
}
export var menuOptions = function (state) { return state.menuOptions; };
export var workshops = function (state) { return state.workshops; };
export var getWorkshopBookings = function (state) { return state.workshopBookings; };
export var isWorkshopBookingsFull = function (state) {
    return state.workshopBookingsFull;
};
