var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { InputBase } from './input-base.class';
var CheckboxInput = /** @class */ (function (_super) {
    __extends(CheckboxInput, _super);
    function CheckboxInput(options) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, options) || this;
        _this.controlType = 'checkbox';
        _this.options = [];
        _this.options = options['options'] || [];
        return _this;
    }
    return CheckboxInput;
}(InputBase));
export { CheckboxInput };
