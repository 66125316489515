import { Component, Input, ViewChild, ElementRef, OnInit } from '@angular/core';

import { HelperService } from './../../../app/shared/services-with-reducers/helpers/helper.service';

import { BannerWidgetSettings } from '../../../app/_pages/widget-admin/widget-models.interface';

@Component({
  selector: 'app-widget-banner',
  templateUrl: './widget-banner.component.html',
  styleUrls: ['./widget-banner.component.scss']
})
export class WidgetBannerComponent implements OnInit {
  @Input()
  widgetSettings: BannerWidgetSettings;
  @Input()
  backgroundImage: MimeType | string;
  @Input()
  link: string;

  @ViewChild('banner')
  banner: ElementRef;

  constructor(private helperService: HelperService) {}

  ngOnInit() {
    const bannerElement = this.banner.nativeElement;

    this.helperService.applyFullHeightToAllParentElements(bannerElement, true);
  }
}
