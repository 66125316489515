<div class="container-fluid">
  <div class="page-content">
    <div class="img-side">
      <img
        *ngIf="!!localizedImages"
        class="exhibition-img"
        [src]="localizedImages.banner"
        alt=""
      />
    </div>
    <div class="page-padding-wrapper" *ngIf="!!eventSeries">
      <h2 class="event-series-header">{{ eventSeries.name }}</h2>
      <div class="event-series-description">
        {{ eventSeries.description }}
      </div>
      <div
        class="event-series-more-events"
        *ngIf="moreThanOneActiveEvents"
        data-translation="eventseries.more-events"
        [innerHTML]="'eventseries.more-events' | translate"
      ></div>
      <div
        *ngIf="!futureEvents.length && !activeEvents.length"
        class="event-series-no-events"
        data-translation="eventseries.no-events"
        [innerHTML]="'eventseries.no-events' | translate"
      >
        <button
          class="button "
          modalWindow-submit
          (click)="backToHomePage($event)"
        >
          <span data-translation="navigation.back-to-event">{{
            'navigation.back-to-event' | translate | hideTranslation
          }}</span>
        </button>
      </div>
    </div>

    <div class="page-padding-wrapper" *ngIf="noActiveEvents">
      <div
        class="event-series-no-events"
        data-translation="eventseries.no-events"
        [innerHTML]="'eventseries.no-events' | translate"
      >
        <button
          class="button "
          modalWindow-submit
          (click)="backToHomePage($event)"
        >
          <span data-translation="navigation.back-to-event">{{
            'navigation.back-to-event' | translate | hideTranslation
          }}</span>
        </button>
      </div>
    </div>
  </div>

  <div class="exhibition-tiles">
    <div class="container-fluid">
      <div *ngIf="moreThanOneActiveEvents" class="row tiles-row">
        <ng-container *ngFor="let exhibition of activeEvents">
          <div class="tile-col col-sm-6 col-md-4 col-lg-3">
            <div class="tile">
              <a
                class="exhibition-tile"
                *ngIf="exhibition"
                (click)="goToEvent(exhibition.id)"
              >
                <app-exhibition-tile
                  [exhibitionSerie]="exhibition"
                ></app-exhibition-tile>
              </a>
            </div>
          </div>
        </ng-container>
      </div>
      <div *ngIf="!moreThanOneActiveEvents" class="row tiles-row">
        <ng-container *ngFor="let exhibition of futureEvents">
          <div class="tile-col col-sm-6 col-md-4 col-lg-3">
            <div class="tile">
              <a
                class="exhibition-tile"
                *ngIf="exhibition"
                (click)="goToEvent(exhibition.id)"
              >
                <app-exhibition-tile
                  [exhibition]="exhibition"
                ></app-exhibition-tile>
              </a>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
