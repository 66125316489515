import { FormInputsPayloadModel } from '../step-forms/step.interface';
import { TicketConfirmationModel } from './ticket.interface';

export function getTicketHolderData(
  ticketHolderInputSets: FormInputsPayloadModel[],
  ticketIndex: number,
  buyerInfoEmail?: string
) {
  const inputMap = {};

  const ticketHolderInputSet = ticketHolderInputSets.find(
    (formInputsGroup: FormInputsPayloadModel) => {
      return formInputsGroup.index === ticketIndex;
    }
  );

  if (ticketHolderInputSet && ticketHolderInputSet.hasOwnProperty('inputSet')) {
    ticketHolderInputSet.inputSet.list.forEach(input => {
      // assign input value to the ticketOwner model
      inputMap[input.key] = input.value;

      //if ticket holder email is not mandatory and missing we use buyers email
      if (input.key === 'email') {
        if (!input.value) {
          input.value = buyerInfoEmail;
        }
      }
      // in case of send to owner, we need to assign the value from option number zero, as sets of checkboxes do not have any common value
      if (
        input.key === 'sendtoowner' &&
        input.options &&
        input.options.length
      ) {
        inputMap[input.key] = input.options[0].value;
      }
    });

    inputMap['ticketIndex'] = ticketIndex;
    return inputMap;
  } else {
    return null;
  }
}
