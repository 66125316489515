import * as fromRoot from '../../../app.reducer';
import * as stepsActions from './steps-forms.actions';

import { Action, Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  FormInputsPayloadModel,
  OrderModel,
  OrderResponseModel,
  VisibilityPayloadModel,
  InputsListModel
} from './step.interface';
import {
  Inject,
  Injectable,
  InjectionToken,
  Optional,
  Input
} from '@angular/core';
import { Observable, EMPTY as empty, of } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';

import { CustomizationService } from '../customization/customization.service';
import { QuestionnaireDataSection } from '../customization/customization.interfaces';
import { StepsFormsService } from './steps-forms.service';
import { HelperService } from '../helpers/helper.service';
import { InputBase } from '../../forms/inputs/input-base.class';
import { ErrorHandlingService } from '../../error-handling/error-handling.service';

export const USER_DEBOUNCE = new InjectionToken<number>('User Debounce');

@Injectable()
export class StepsFormsEffect {
  @Effect()
  buyerQuestionnaire$: Observable<Action> = this.actions$.pipe(
    ofType<stepsActions.GetBuyerQuestionnaire>(
      stepsActions.ActionTypes.GET_BUYER_QUESTIONNAIRE
    ),
    debounceTime(this.debounce),
    switchMap((data: any) => {
      const {
        eventId,
        stepName,
        lang,
        ticketPersonIds,
        previousQuestionnare
      } = data.payload;
      if (eventId === null) {
        return Observable.empty();
      }

      let questionnairePayload: FormInputsPayloadModel = {
        formInfo: [stepName, 'questionnaire'],
        inputSet: {
          rerender: false,
          list: []
        }
      };

      return this._stepsFormsService
        .getBuyerQuestionnaire(eventId, ticketPersonIds)
        .pipe(
          map((buyerQuestionnaire: QuestionnaireDataSection[]) => {
            /* Questionnaire */
            const questionnaires = this._customizationService.tansformQuestionnaireIntoInput(
              buyerQuestionnaire
            );

            questionnairePayload.inputSet.list = questionnaires;

            this.mergeQuestionnares(
              previousQuestionnare,
              questionnairePayload.inputSet
            );

            const visibilityPayload: VisibilityPayloadModel = {
              stepKey: 'questionnaire',
              visible:
                questionnairePayload.inputSet.list.length > 0 &&
                this._helperService.isSelfregistration()
            };

            this._store.dispatch(
              new stepsActions.SetStepsVisibility([visibilityPayload])
            );

            return new stepsActions.SetInputs(questionnairePayload);
          }),
          catchError(() => of(new stepsActions.SetInputs(questionnairePayload)))
        );
    })
  );

  @Effect()
  sendOrder$: Observable<Action> = this.actions$.pipe(
    ofType<stepsActions.sendOrder>(stepsActions.ActionTypes.SEND_ORDER),
    debounceTime(this.debounce),
    switchMap((data: any) => {
      if (!data.payload) {
        return Observable.empty();
      }

      const orderData = data.payload;
      return this._stepsFormsService.sendFinalOrder(orderData).pipe(
        map((orderResponseData: any) => {
          const orderResponse = <OrderResponseModel>{
            ...orderResponseData.body,
            orderPaid: false
          };

          if (!orderResponse.success) {
            this._errorHandlingService.errorHandler(orderResponse);
          }

          this._customizationService.resetShoppingStartTime();
          return new stepsActions.SetOrderResponse(orderResponse);
        }),
        catchError(() =>
          of(new stepsActions.SetOrderResponse({ error: 'fail' }))
        )
      );
    })
  );

  mergeQuestionnares(
    previousQuestionnare: InputsListModel,
    currentQuestionnare: InputsListModel
  ) {
    if (!previousQuestionnare || !previousQuestionnare.list) {
      return;
    }

    currentQuestionnare.list.map((current: InputBase<any>) => {
      let previousElement = previousQuestionnare.list.find(
        previous => previous.key === current.key
      );
      if (!previousElement) return;

      if (
        current.controlType === 'checkbox' &&
        previousElement.controlType === 'checkbox'
      ) {
        previousElement.options.map(previousOption => {
          let currentOption = current.options.find(
            currentOption => currentOption.key === previousOption.key
          );

          if (!currentOption) return;

          currentOption.value = previousOption.value;
        });
      }
      current.value = previousElement.value;
    });
  }

  constructor(
    private actions$: Actions,
    private _stepsFormsService: StepsFormsService,
    @Optional()
    @Inject(USER_DEBOUNCE)
    private debounce: number = 50,
    private _customizationService: CustomizationService,
    private _store: Store<fromRoot.State>,
    private _helperService: HelperService,
    private _errorHandlingService: ErrorHandlingService
  ) {}
}
