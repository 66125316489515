/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./self-reg-timeout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./self-reg-timeout.component";
var styles_SelfRegTimeoutComponent = [i0.styles];
var RenderType_SelfRegTimeoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SelfRegTimeoutComponent, data: {} });
export { RenderType_SelfRegTimeoutComponent as RenderType_SelfRegTimeoutComponent };
export function View_SelfRegTimeoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "reset-timeout-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["class", "reset-timeout-text"], ["data-translation", "selfreg.timeout.message"]], null, null, null, null, null)), i1.ɵdid(2, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"], translateParams: [1, "translateParams"] }, null), i1.ɵpod(3, { timeLeft: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "selfreg.timeout.message"; var currVal_1 = _ck(_v, 3, 0, _co.timeLeft); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_SelfRegTimeoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-self-reg-timeout", [], null, null, null, View_SelfRegTimeoutComponent_0, RenderType_SelfRegTimeoutComponent)), i1.ɵdid(1, 114688, null, 0, i3.SelfRegTimeoutComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SelfRegTimeoutComponentNgFactory = i1.ɵccf("app-self-reg-timeout", i3.SelfRegTimeoutComponent, View_SelfRegTimeoutComponent_Host_0, {}, { resetShop: "resetShop" }, []);
export { SelfRegTimeoutComponentNgFactory as SelfRegTimeoutComponentNgFactory };
