import { FormBuilder } from '@angular/forms';
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
var WidgetAdminService = /** @class */ (function () {
    function WidgetAdminService(fb) {
        this.fb = fb;
    }
    WidgetAdminService.prototype.processWidgetSettingsForSave = function (settings, saveSettingsProcessor) {
        for (var key in settings) {
            var setting = settings[key];
            if (saveSettingsProcessor[key]) {
                saveSettingsProcessor[key](setting, settings);
            }
        }
    };
    WidgetAdminService.prototype.processWidgetSettingForAdminForm = function (settings, loadSettingsProcessor, form) {
        var processedSettings = {};
        for (var key in settings) {
            var setting = settings[key];
            if (key in form.value) {
                if (loadSettingsProcessor[key]) {
                    loadSettingsProcessor[key](setting, processedSettings, settings);
                }
                else {
                    processedSettings[key] = setting;
                }
            }
        }
        return processedSettings;
    };
    WidgetAdminService.prototype.setTicketsToFormControls = function (availableTickets, form) {
        var _this = this;
        var ticketsFormGroup = form.get('tickets');
        availableTickets.forEach(function (_a) {
            var products = _a.products;
            products.forEach(function (product) {
                if (product.ticket != null) {
                    ticketsFormGroup.addControl(String(product.ticket.uniqueId), _this.fb.control(true));
                }
            });
        });
    };
    WidgetAdminService.prototype.createIframeEmbedCode = function (iframeURI, iframeAttributes) {
        var iframeAttributesString = '';
        for (var attribute in iframeAttributes) {
            var attributeValue = iframeAttributes[attribute];
            iframeAttributesString += " " + attribute + "=\"" + attributeValue + "\"";
        }
        return "<iframe " + iframeAttributesString + " src=\"" + iframeURI + "\"></iframe>";
    };
    WidgetAdminService.ngInjectableDef = i0.defineInjectable({ factory: function WidgetAdminService_Factory() { return new WidgetAdminService(i0.inject(i1.FormBuilder)); }, token: WidgetAdminService, providedIn: "root" });
    return WidgetAdminService;
}());
export { WidgetAdminService };
