export interface ApiCallResultModel {
  code: number;
  message: string;
}

export interface TranslationsModel {
  language: string;
  values: {
    [key: string]: string;
  };
}

export interface QuestionnaireInputResponseModel {
  fieldId: number;
  valueId: number;
  text: string;
}

export interface EventSeriesModel {
  isEventSeries: boolean;
  eventSeriesId: number;
}

export interface ZipCodeCities {
  cities: string[];
  zipCode: string;
  countryCode: string;
  formPath: string;
  isZipCodeInvalid: boolean;
  zipCodeHasValidation: boolean;
}

export enum TextOrDropdownInputTypes {
  Title = 'title',
  Function = 'function',
  Department = 'department',
  OccupationalGroup = 'occupationalGroup'
}

export interface BrowserSession {
  sessionId: string;
  active: boolean;
  created: Date;
  appVersion: string;
}
