import { combineLatest as observableCombineLatest, Subscription } from 'rxjs';

import { filter, first } from 'rxjs/operators';
import * as fromRoot from '../../app.reducer';
import * as userActions from '../../shared/services-with-reducers/user/user.actions';

import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  Renderer
} from '@angular/core';
import {
  FormBuilder,
  FormGroup
} from '@angular/forms';

import { select, Store } from '@ngrx/store';
import { FormsService } from '../../shared/forms/forms.service';
import { InputBase } from '../../shared/forms/inputs/input-base.class';
import { CustomizationService } from '../../shared/services-with-reducers/customization/customization.service';
import { HelperService } from '../../shared/services-with-reducers/helpers/helper.service';
import * as stepsActions from '../../shared/services-with-reducers/step-forms/steps-forms.actions';
import { BillingAddressModel, UserProfileModel } from '../../shared/services-with-reducers/user/user.interface';
import { getProfileBillingAddressEditForm } from './form-inputs.edit-billing-address';

@Component({
  moduleId: module.id,
  selector: 'app-edit-billing-address-form',
  templateUrl: './edit-billing-address-form.component.html',
  styleUrls: ['./edit-billing-address-form.component.scss']
})
export class EditBillingAddressFormComponent implements OnInit, OnDestroy {
  @Input()
  isEditEnabled: boolean;

  public form: FormGroup;
  public inputs: InputBase<any>[];
  public selectedAddressId = 0;
  public editAccountAction = ['editAccountAddressForm'];
  public billingAddressList: Array<BillingAddressModel>;
  private userId: number;
  private _subscriptions = new Subscription();

  constructor(
    private _customizationService: CustomizationService,
    private _formBuilder: FormBuilder,
    private _renderer: Renderer,
    private _store: Store<fromRoot.State>,
    private _formsService: FormsService,
    private _helperService: HelperService
  ) {
    this._store.dispatch(new userActions.SetSelectedBillingAddress(0));

    this._store
      .select(fromRoot.getProfile)
      .pipe(
        filter(data => !!data),
        first()
      )
      .subscribe((user: UserProfileModel) => {
        this.userId = user.id;
      });

    this._store.select(fromRoot.getProfileBillingAddress).subscribe(data => {});
  }

  public formSaveCallback = (inputs: InputBase<any>[], rerender: boolean) => {
    this.inputs = this._formsService.updateInputs(this.inputs, inputs);
    if (rerender) {
      this.form = this._formsService.toFormGroup(this.inputs);
    }
  };

  ngOnInit() {
    // initial billing address was loaded from API in profile component
    const combinedObservable = observableCombineLatest(
      this._store.select(fromRoot.getProfileBillingAddress),
      this._store.select(fromRoot.getSelectedBillingAddressId),
      this._store.select(fromRoot.getExhibitionSettings)
    )
      .pipe(
        // settings are not necessary as user can access profile without exhibition selected
        filter(data => !!data[0].length)
      )
      .subscribe(data => {
        setTimeout(() => {
          const [billingAddressList, selectedBillingAddressId, settings] = data;
          this.billingAddressList = billingAddressList;
          this.selectedAddressId = selectedBillingAddressId;

          if (this.billingAddressList.length) {
            const billingAddress: BillingAddressModel = this.billingAddressList[
              selectedBillingAddressId
            ];
            const isGoogleAutocompleteEnabled =
              settings && settings.isGoogleAutocompleteEnabled ? true : false;
            const profileEditForm = getProfileBillingAddressEditForm(
              isGoogleAutocompleteEnabled,
              this._customizationService,
              billingAddress
            );

            this.inputs = this._formsService.updateInputs(
              this.inputs,
              profileEditForm
            );

            //this.inputs = profileEditForm;
            this.form = this._formsService.toFormGroup(profileEditForm);

            this.enableOrDisableInputs(this.isEditEnabled);
          }
        });
      });

    this._subscriptions.add(combinedObservable);
  }

  ngOnDestroy() {
    // initial billing address was loaded from API in profile component
    this._subscriptions.unsubscribe();
  }

  loadBillingAddress() {
    this._store.dispatch(new userActions.GetProfileBillingAddress(this.userId));
  }

  deleteBillingAddress(addressId) {
    const addressToDelete = this.billingAddressList[addressId];
    this._store.dispatch(
      new userActions.DeleteProfileBillingAddress({
        userId: this.userId,
        addressId: addressToDelete.id
      })
    );

    if (this.billingAddressList.length) {
      this._store.dispatch(new userActions.SetSelectedBillingAddress(0));
    }

    this._store.pipe(select(fromRoot.getBuyerActiveBillingAddressId), first()).subscribe(selectedBuyerBillAddress => {
      if (addressToDelete.id === selectedBuyerBillAddress) {
        this._store.dispatch(new stepsActions.SetBuyerActiveBillingAddressId(null));
      }
    })
  }

  saveBillingAddress(addressId) {
    let dataToSave = <BillingAddressModel>(
      this._helperService.processFormValuesBeforeSave(this.form.value)
    );
    const databseAddressId = this.billingAddressList[addressId].id;
    dataToSave.address = dataToSave.address || '';

    this._store.dispatch(
      new userActions.PutProfileBillingAddress({
        userId: this.userId,
        addressId: databseAddressId,
        data: dataToSave
      })
    );

    this.isEditEnabled = false;
    this.enableOrDisableInputs(false);
  }

  cancelEdit() {
    this.loadBillingAddress();
    this.isEditEnabled = false;
    this.enableOrDisableInputs(false);
  }

  beginEdit() {
    this.isEditEnabled = true;
    this.enableOrDisableInputs(true);
  }

  // on change selected billing address
  onChange(event) {
    this._store.dispatch(
      new userActions.SetSelectedBillingAddress(event.target.value)
    );
  }

  enableOrDisableInputs(enable: boolean): void {
    if (enable === undefined) {
      enable = false;
    }

    this.inputs.forEach((input: InputBase<any>) => {
      input.disabled = !enable;
    });
  }
}
