import { AssignWorkshopToHolderModel } from '../step-forms/step.interface';

export interface MenuModel {
  id: number;
  day: string;
  selectedMenu?: number;
  meals: Menu[];
}

export interface Menu {
  id: number;
  name: string;
}

export interface WorkshopByDateModel {
  [key: number]: WorkshopsTodayModel;
}

export interface WorkshopTimelineItem {
  hourStart: number;
  hourEnd: number;
  hourHeight: number;
  displayEndTime: boolean;
}

export interface WorkshopsTodayModel {
  todayWorkshops: Array<WorkshopModel>;
  timeLineWrapHeight: number;
  timeLineList: Array<WorkshopTimelineItem>;
  rooms: Array<WorkshopRoomModel>;
  date?: string;
}

export interface WorkshopRoomModel {
  roomId: number;
  roomName: string;
  roomSortOrder?: number;
  workshops: Array<WorkshopModel>;
}

export interface WorkshopDayBorderHoursModel {
  earliest: number;
  latest: number;
}

export interface WorkshopModel {
  eventId: number;
  start: string;
  end: string;
  seats: number;
  imagePath: string;
  styles?: any;
  roomId: number;
  description: string;
  pdfPath: string;
  date: string;
  workshopName: string;
  workshopId: number;
  roomName: string;
  disabled: boolean;
  roomSortOrder: number;
  price: number;
}

export interface WorkshopBookingModel {
  bookingsNumber: number;
  workshopId: number;
  seats: number;
  myBookings: Array<{
    timestamp: number;
    ticketHolderId: number;
  }>;
}

export interface WorkshopBookingsModel {
  bookings: WorkshopBookingModel[];
  timestamp: number;
}

export interface WorkshopBookingPostModel {
  eventId: number;
  workshopId: number;
  seats: number;
  uuid: string;
  ticketHolderId?: number;
}

export class AddWorkshopBookingWithHolderModel {
  addWorkshopBookingModel: AddWorkshopBookingModel;
  assignWorkshopToHolderModel: AssignWorkshopToHolderModel;
}

export interface AddWorkshopBookingModel {
  workshopId: number;
  seats: number;
  timestamps?: Array<number>;
  added: boolean;
  ticketHolderId: number;
}

export interface WorkshopFullModel {
  isFull: boolean;
  workshopId: number;
}
