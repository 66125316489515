import {
  AddressCheckboxModel,
  BuyerVisitorCheckboxModel,
  AssignWorkshopToHolderModel,
  FormInputsPayloadModel,
  FormStatusPayloadModel,
  OrderModel,
  OrderPayloadModel,
  OrderResponseModel,
  TicketHolderAdditionalDataModel,
  VisibilityPayloadModel,
  InputsListModel
} from './step.interface';

import { Action } from '@ngrx/store';

export enum ActionTypes {
  // SET_TICKET_OWNER_SETTINGS = '[Customization] set new Ticket Owner Settings to store',
  // SET_TICKET_CONFIRMATION_OPTIONS = '[Customization] set ticket confirmation options',
  SET_SELECTED_STEP = '[StepsForms] Set name of selected step',
  SET_FORM_INPUTS = '[StepsForms] Set input set to given form',
  REMOVE_FORM = '[StepsForms] Remove input set and form',
  SET_FORM_VALIDITY = '[StepsForms] Set validity of given form',
  REMOVE_FORMS_VALIDITY = '[StepsForms] Remove validity of array of forms',
  SET_STEP_VISIBILITY = '[StepsForms] Set visibility of given step',
  SET_MULTIPLE_STEPS_VISIBILITY = '[StepsForms] Set visibility of multiple steps',
  SET_STEP_ORDER = '[StepsForms] Set new order number of a step',
  RESET_REDUCER = '[StepsForms] reset reducer',
  SET_ADDRESS_CHECBOX = '[StepsForms] set address checkbox',
  SET_BUYERVISITOR_CHECKBOX = '[StepsForms] set buyer visitor checkbox',
  SET_COPPIED_HOLDERS_INDEXES = '[StepsForms] set indexes on which data is coppied',
  GET_BUYER_QUESTIONNAIRE = '[StepsForms] Get Buyer Questionnaire',
  ASSIGN_WORKSHOP_TO_HOLDER = '[StepsForms] assign workshop to holder',
  SET_TICKET_HOLDER_ADDITIONAL_DATA = '[StepsForms] set ticket holder additional data as workshops and menus',
  SET_BUYER_ACTIVE_BILLINGADDRESS_ID = '[StepsForms] Set buyers Active Billing Address Id',
  SET_CONTINUE_AS_GUEST = '[StepsForms] Set value of checkbox continue as guest',
  SEND_ORDER = '[StepsForms] Send the whole order',
  SET_PAYMENT_METHOD = '[StepsForms] Set payment service',
  SET_ORDER_RESPONSE = '[StepsForms] Set order response',
  SET_BUYERINFO_FROM_URL = '[StepsForms] set buyer info from url',
  RESET_BUYERINFO_FROM_URL = '[StepsForms] reset buyer info from url',
  SET_TICKETHOLDER_FORM_VALIDITY = '[StepsForms] Set validity of a given ticket holder form',
  REMOVE_TICKETHOLDER_FORM = '[StepsForms] Remove ticket holder form',
  SET_BUYERINFO_FORM_VALIDITY = '[StepsForms] Set validity of the buyer info form',
  PARTIAL_RESET_REDUCER = '[StepsForms] partial reset reducer',
  SET_ANONYMOUS_HIDDEN_STEPS = '[StepsForms] Hide steps if anonymous ticket taken',
  SET_QUESTIONNAIRE_TICKET_PERSON_IDS = '[StepsForms] Set Questionnaire Ticket Person Ids'
}
/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions.
 *
 * See Discriminated Unions: https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions
 */
export class ResetBuyerInfoFromURL implements Action {
  readonly type = ActionTypes.RESET_BUYERINFO_FROM_URL;
}

export class SetBuyerInfoFromURL implements Action {
  readonly type = ActionTypes.SET_BUYERINFO_FROM_URL;
  constructor(public payload: object) {}
}

export class SetSelectedStep implements Action {
  readonly type = ActionTypes.SET_SELECTED_STEP;
  constructor(public payload: string) {}
}

export class RemoveForm implements Action {
  readonly type = ActionTypes.REMOVE_FORM;
  constructor(public payload: string[]) {}
}

export class SetInputs implements Action {
  readonly type = ActionTypes.SET_FORM_INPUTS;
  constructor(public payload: FormInputsPayloadModel, public skipHoldersCheck?: boolean) {}
}

export class SetFormValidity implements Action {
  readonly type = ActionTypes.SET_FORM_VALIDITY;
  constructor(public payload: FormStatusPayloadModel) {}
}

export class RemoveFormValidity implements Action {
  readonly type = ActionTypes.REMOVE_FORMS_VALIDITY;
  constructor(public payload: FormStatusPayloadModel[]) {}
}

export class SetStepsVisibility implements Action {
  readonly type = ActionTypes.SET_STEP_VISIBILITY;
  constructor(public payload: VisibilityPayloadModel[]) {}
}

export class SetMultipleStepsVisibility implements Action {
  readonly type = ActionTypes.SET_MULTIPLE_STEPS_VISIBILITY;
  constructor(public payload: VisibilityPayloadModel[]) {}
}

export class SetStepOrder implements Action {
  readonly type = ActionTypes.SET_STEP_ORDER;
  constructor(public payload: OrderPayloadModel) {}
}

export class SetAddressCheckbox implements Action {
  readonly type = ActionTypes.SET_ADDRESS_CHECBOX;
  constructor(public payload: AddressCheckboxModel) {}
}

export class SetBuyerVisitorCheckbox implements Action {
  readonly type = ActionTypes.SET_BUYERVISITOR_CHECKBOX;
  constructor(public payload: BuyerVisitorCheckboxModel) {}
}

export class SetCoppiedHoldersIndexes implements Action {
  readonly type = ActionTypes.SET_COPPIED_HOLDERS_INDEXES;
  constructor(public payload: number[]) {}
}

export class GetBuyerQuestionnaire implements Action {
  readonly type = ActionTypes.GET_BUYER_QUESTIONNAIRE;
  constructor(
    public payload: {
      eventId: number;
      stepName: string;
      lang: string;
      ticketPersonIds: number[];
      previousQuestionnare: InputsListModel;
    }
  ) {}
}

export class SetTicketHolderAdditionalData implements Action {
  readonly type = ActionTypes.SET_TICKET_HOLDER_ADDITIONAL_DATA;
  constructor(public payload: TicketHolderAdditionalDataModel) {}
}

export class assignWorkshopToHolder implements Action {
  readonly type = ActionTypes.ASSIGN_WORKSHOP_TO_HOLDER;
  constructor(
    public payload: {
      workshopToHolder: AssignWorkshopToHolderModel;
      workshopLimit: number;
    }
  ) {}
}

export class SetBuyerActiveBillingAddressId implements Action {
  readonly type = ActionTypes.SET_BUYER_ACTIVE_BILLINGADDRESS_ID;
  constructor(public payload: number) {}
}

export class SetContinueAsGuest implements Action {
  readonly type = ActionTypes.SET_CONTINUE_AS_GUEST;
  constructor(public payload: boolean) {}
}

export class sendOrder implements Action {
  readonly type = ActionTypes.SEND_ORDER;
  constructor(public payload: OrderModel) {}
}

export class SetOrderResponse implements Action {
  readonly type = ActionTypes.SET_ORDER_RESPONSE;
  constructor(public payload: OrderResponseModel) {}
}

export class setPaymentMethod implements Action {
  readonly type = ActionTypes.SET_PAYMENT_METHOD;
  constructor(public payload: string) {}
}

export class SetTicketHolderFormValidity implements Action {
  readonly type = ActionTypes.SET_TICKETHOLDER_FORM_VALIDITY;
  constructor(public payload: FormStatusPayloadModel) {}
}

export class RemoveTicketHolderForm implements Action {
  readonly type = ActionTypes.REMOVE_TICKETHOLDER_FORM;
  constructor(public payload: string[]) {}
}

export class SetBuyerinfoFormValidity implements Action {
  readonly type = ActionTypes.SET_BUYERINFO_FORM_VALIDITY;
  constructor(public payload: boolean) {}
}

export class ResetReducer implements Action {
  readonly type = ActionTypes.RESET_REDUCER;
}

export class PartialResetReducer implements Action {
  readonly type = ActionTypes.PARTIAL_RESET_REDUCER;
}

export class SetAnonymousHiddenSteps implements Action {
  readonly type = ActionTypes.SET_ANONYMOUS_HIDDEN_STEPS;
  constructor(public payload: string[]) {}
}

export class SetQuestionnaireTicketPersonIds implements Action {
  readonly type = ActionTypes.SET_QUESTIONNAIRE_TICKET_PERSON_IDS;
  constructor(public payload: number[]) {}
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Actions =
  | SetSelectedStep
  | RemoveForm
  | SetInputs
  | SetFormValidity
  | RemoveFormValidity
  | SetStepsVisibility
  | SetMultipleStepsVisibility
  | SetStepOrder
  | ResetReducer
  | GetBuyerQuestionnaire
  | SetAddressCheckbox
  | SetBuyerVisitorCheckbox
  | SetCoppiedHoldersIndexes
  | SetTicketHolderAdditionalData
  | assignWorkshopToHolder
  | SetBuyerActiveBillingAddressId
  | SetContinueAsGuest
  | sendOrder
  | SetOrderResponse
  | setPaymentMethod
  | SetBuyerInfoFromURL
  | ResetBuyerInfoFromURL
  | SetTicketHolderFormValidity
  | RemoveTicketHolderForm
  | SetBuyerinfoFormValidity
  | PartialResetReducer
  | SetAnonymousHiddenSteps
  | SetQuestionnaireTicketPersonIds;
