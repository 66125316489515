import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Subscription, combineLatest as observableCombineLatest } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import {
  ExhibitionSettingModel,
  LocalizedImagesModel,
  SponsorBannerModel
} from '../shared/services-with-reducers/customization/customization.interfaces';
import { Observable } from 'rxjs/Rx';

import { Store } from '@ngrx/store';
import { CustomizationService } from '../shared/services-with-reducers/customization/customization.service';

import { ExhibitionModel } from '../shared/services-with-reducers/exhibition/exhibition.interface';
import { TicketsWidgetSettings } from '../_pages/widget-admin/widget-models.interface';
import * as customizationActions from '../shared/services-with-reducers/customization/customization.actions';
import * as fromRoot from '../app.reducer';
import { pageRunningInIframe } from '../shared/app-utils';

@Component({
  moduleId: module.id,
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  public exhibitionSettings: ExhibitionSettingModel;
  public exhibitionSettings$: Observable<ExhibitionSettingModel>;
  public exhibitions$: Observable<ExhibitionModel[]>;
  public sponsorBanner: string;
  public exhibition: ExhibitionModel;
  public localizedImages: LocalizedImagesModel;
  public widgetSettings: TicketsWidgetSettings;
  public displayBanner: boolean = false;
  public pageRunnungInIframe: boolean = false;

  private routeId: number;

  constructor(
    private _store: Store<fromRoot.State>,
    private _route: ActivatedRoute,
    private _customizationService: CustomizationService
  ) {
    this.subscriptions.add(
      observableCombineLatest(
        this._store.select(fromRoot.getSelectedExhibitionId),
        this._store.select(fromRoot.getLanguage)
      )
        .pipe(
          filter(([eventId, language]) => {
            if (eventId && language) {
              return true;
            }
          })
        )
        .subscribe(([eventId, language]) => {
          this._customizationService
            .getRandomSponsor(eventId, language)
            .subscribe(banner => {
              this._store.dispatch(
                new customizationActions.SetRandomSponsor(banner)
              );
            });
        })
    );

    this.subscriptions.add(
      this._store
        .select(fromRoot.getLocalizedImages)
        .pipe(filter(images => !!images))
        .subscribe(images => {
          this.localizedImages = images;
        })
    );

    this.subscriptions.add(
      this._store
        .select(fromRoot.getSponsorBanner)
        .subscribe((banner: SponsorBannerModel) => {
          if (banner) {
            this.sponsorBanner = banner.sponsorBanner;
          }
        })
    );

    this.exhibitionSettings$ = this._store.select(
      fromRoot.getExhibitionSettings
    );
    this.exhibitions$ = this._store.select(
      fromRoot.getAllExhibitionsCollection
    );

    const params = this._route.params;
    const queryParams = this._route.queryParams;

    observableCombineLatest(params, queryParams)
      .filter(([params, queryParams]) => {
        if (params || queryParams) {
          return true;
        }
      })
      .subscribe(([params, queryParams]) => {
        const eventId = params.id || queryParams.eventId;

        this.routeId = Number(eventId);
      });

    this.exhibitionSettings$.subscribe(exhibitionSettings => {
      this.exhibitionSettings = exhibitionSettings;
    });

    this.exhibitions$.subscribe(exhibitions => {
      exhibitions.map(exhibition => {
        if (exhibition.id === this.routeId) {
          this.exhibition = exhibition;
        }
      });
    });
  }

  ngOnInit() {
    this.pageRunnungInIframe = pageRunningInIframe();

    if (this.pageRunnungInIframe) {
      this.loadWidgetSettings();
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  loadWidgetSettings() {
    this._store
      .select(fromRoot.getWidgetSettings)
      .pipe(
        filter(widgetSettings => {
          if (widgetSettings && widgetSettings.ticketsWidget) {
            return true;
          }
        }),
        first()
      )
      .subscribe(({ ticketsWidget }) => {
        this.widgetSettings = ticketsWidget;
        this.displayBanner = Boolean(ticketsWidget.banner);
      });
  }
}
