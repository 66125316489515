<div
  class="input-wrapper voucher-box"
  [ngClass]="{ 'self-reg': type === 'selfRegistration' }"
>
  <label
    *ngIf="!isSelfRegistrationEnabled"
    for="voucher-code"
    class="voucher-label"
    data-translation="ticket-selection.voucher.title"
  >
    {{ 'ticket-selection.voucher.title' | translate | hideTranslation }}
  </label>
  <div class="scan" *ngIf="isSelfRegistrationEnabled">
    <img class="scan-image" [src]="scanImageUrl" />
    <div class="scan-text-box">
      <div class="scan-text">
        <span data-translation="ticket.selfregistration.voucher.scan-text">{{
          'ticket.selfregistration.voucher.scan-text'
            | translate
            | hideTranslation
        }}</span>
      </div>
      <div class="scan-arrow"></div>
    </div>
  </div>

  <div class="voucher-form">
    <form [formGroup]="voucherForm" (submit)="onRedeem(voucherCode)">
      <div class="voucher-input-wrapper">
        <input
          [formControl]="voucherInput"
          type="text"
          autocomplete="off"
          id="voucher-code"
          [(ngModel)]="voucherCode"
          name="voucherCode"
          class="voucher-input"
        />
        <div class="voucher-input-error-message" *ngIf="voucherInput.errors">
          <small
            data-translation="ticket-selection.voucher.errors.maxlength"
            [translate]="'ticket-selection.voucher.errors.maxlength'"
            [translateParams]="{
              voucherMaxLength: voucherInput.errors.maxlength['requiredLength']
            }"
          >
          </small>
        </div>
      </div>
      <button
        type="submit"
        class="button voucher-button"
        [disabled]="!voucherInput.valid || !voucherCode"
      >
        <span data-translation="ticket-selection.voucher.redeem">{{
          'ticket-selection.voucher.redeem' | translate | hideTranslation
        }}</span>
      </button>
      <div
        class="ticket-selection-warning"
        *ngIf="ticketLimitWarning"
        [attr.data-translation]="ticketLimitWarning"
        [translate]="ticketLimitWarning"
        [translateParams]="{ maxTicketLimit: maxTicketLimit }"
      ></div>
    </form>
  </div>

  <app-loader *ngIf="loading" class="loader"></app-loader>

  <div class="voucher-input-error-message anonymous-css" *ngIf="isAnonymousAndMandatory && showVoucherWarning">
    <small data-translation="ticket-selection.regular-ticket-after-anonymous-ticket-message">
      {{ 'ticket-selection.regular-ticket-after-anonymous-ticket-message' | translate | hideTranslation }}
    </small>
  </div>

  <div class="voucher-input-error-message anonymous-css" *ngIf="isAnonymousWithPrice && showVoucherWarning">
    <small data-translation="ticket-selection.anonymous-priced-ticket-message">
      {{ 'ticket-selection.anonymous-priced-ticket-message' | translate | hideTranslation }}
    </small>
  </div>
</div>

<app-sponsor-list></app-sponsor-list>
