import * as fromRoot from '../../../app.reducer';
import * as legitimationActions from '../legitimation/legitimation.actions';
import * as userActions from './user.actions';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, combineLatest as observableCombineLatest } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { AppService } from '../../../app.service';
import { HelperService } from '../helpers/helper.service';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { environment } from '../../../../environments/environment';
import { ApplicationInsightsService } from '../../applicationInsights/applicationInsightsService';
import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
import * as i2 from "@angular/common/http";
import * as i3 from "@angular/router";
import * as i4 from "@ngrx/store";
import * as i5 from "../helpers/helper.service";
import * as i6 from "../../../app.service";
import * as i7 from "../../applicationInsights/applicationInsightsService";
var UserService = /** @class */ (function () {
    function UserService(document, _http, _router, _store, _helperService, _appService, _applicationInsightsService) {
        this.document = document;
        this._http = _http;
        this._router = _router;
        this._store = _store;
        this._helperService = _helperService;
        this._appService = _appService;
        this._applicationInsightsService = _applicationInsightsService;
    }
    UserService.prototype.forgottenPassword = function (data) {
        return this._http.post("" + environment.protocol + environment.webApiUrl + "/user/forgotten-password", data);
    };
    UserService.prototype.logout = function () {
        var _this = this;
        var webShopDownload = 'webshop-download';
        var urlBase = this._helperService.isSelfregistration()
            ? 'self-registration'
            : window.location.pathname.includes(webShopDownload) ? webShopDownload : 'webshop';
        Observable.combineLatest(this._store.pipe(select(fromRoot.getSelectedExhibitionId)), this._store.pipe(select(fromRoot.isEventSeriesPage)), this._store.pipe(select(fromRoot.getClaimedTicketHash)))
            .pipe(first())
            .subscribe(function (data) {
            var exhibitionId = data[0], eventSeriesPage = data[1], claimedTicketHash = data[2];
            _this._appService.resetReducersWithUser().subscribe(function () {
                _this._applicationInsightsService.clearUserId();
                if (exhibitionId) {
                    var toWebShopDownload = (urlBase.includes(webShopDownload) ? "?t=" + claimedTicketHash : '');
                    window.location.replace("/" + urlBase + "/" + exhibitionId + toWebShopDownload);
                }
                else if (!!eventSeriesPage && eventSeriesPage.isEventSeries) {
                    window.location.replace("/series/" + eventSeriesPage.eventSeriesId);
                }
                else {
                    window.location.replace("/");
                }
            });
        });
    };
    UserService.prototype.forcelogout = function () {
        this._store.dispatch(new userActions.ResetReducer());
        this._store.dispatch(new legitimationActions.ResetReducer());
        this._applicationInsightsService.clearUserId();
        this._router.navigate(['/logout']);
    };
    UserService.prototype.changePreferredLanguage = function (lang) {
        this._store.dispatch(new userActions.SetLanguage(lang));
    };
    UserService.prototype.login = function (username, password) {
        var httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        };
        return this._http.post("" + environment.protocol + environment.webApiUrl + "/user/login", JSON.stringify({ username: username, password: password }), httpOptions);
    };
    UserService.prototype.getRegistrationQuestionnaire = function (eventId) {
        var selfReg = '';
        if (this._helperService.isSelfregistration()) {
            selfReg = '?sr=true';
        }
        return this._http.get("" + environment.protocol + environment.webApiUrl + "/event/" + eventId + "/registration-questionnaire" + selfReg);
    };
    UserService.prototype.saveRegistrationQuestionnaire = function (questionnarireData) {
        var selfReg = '';
        if (this._helperService.isSelfregistration()) {
            selfReg = '?sr=true';
        }
        return this._http.post("" + environment.protocol + environment.webApiUrl + "/user/questionnaire" + selfReg, questionnarireData);
    };
    UserService.prototype.downloadTicketById = function (ticketId) {
        return this._http.get("" + environment.protocol + environment.webApiUrl + "/user/download-ticket/" + ticketId, { responseType: 'blob' });
    };
    UserService.prototype.downloadInvoice = function (orderId) {
        return this._http.get("" + environment.protocol + environment.webApiUrl + "/user/orders/" + orderId + "/invoice-download", { responseType: 'blob' });
    };
    UserService.prototype.downloadPassbook = function (ticketId) {
        return this._http.get("" + environment.protocol + environment.webApiUrl + "/user/entry-tickets/" + ticketId + "/passbook", { responseType: 'blob' });
    };
    UserService.prototype.downloadRefundReceipt = function (orderId) {
        return this._http.get("" + environment.protocol + environment.webApiUrl + "/user/orders/" + orderId + "/refund-receipt-download", { responseType: 'blob' });
    };
    UserService.prototype.refundOrder = function (orderId) {
        return this._http.post("" + environment.protocol + environment.webApiUrl + "/user/orders/" + orderId + "/refund", {});
    };
    UserService.prototype.refundSingleTicket = function (orderId, ticketId) {
        return this._http.post("" + environment.protocol + environment.webApiUrl + "/user/orders/" + orderId + "/entry-tickets/" + ticketId + "/refund", {});
    };
    UserService.prototype.downloadTicketRefundReceipt = function (orderId, ticketId) {
        return this._http.get("" + environment.protocol + environment.webApiUrl + "/user/orders/" + orderId + "/entry-tickets/" + ticketId + "/refund-receipt-download", { responseType: 'blob' });
    };
    UserService.prototype.downloadPackageRefundReceipt = function (orderId, packageIndex) {
        return this._http.get("" + environment.protocol + environment.webApiUrl + "/user/orders/" + orderId + "/packages/" + packageIndex + "/refund-package-receipt-download", { responseType: 'blob' });
    };
    UserService.prototype.refundPackage = function (orderId, packageIndex, cancelOrderBody) {
        return this._http.post("" + environment.protocol + environment.webApiUrl + "/user/orders/" + orderId + "/packages/" + packageIndex + "/refund-package", cancelOrderBody);
    };
    UserService.prototype.refundParkingTicket = function (orderId, ticketId) {
        return this._http.post("" + environment.protocol + environment.webApiUrl + "/user/orders/" + orderId + "/parking-tickets/" + ticketId + "/refund", {});
    };
    UserService.prototype.downloadParkingRefundReceipt = function (orderId, ticketId) {
        return this._http.get("" + environment.protocol + environment.webApiUrl + "/user/orders/" + orderId + "/parking-tickets/" + ticketId + "/refund-receipt-download", { responseType: 'blob' });
    };
    UserService.prototype.downloadParkingTicketById = function (parkingTicketId) {
        return this._http.get("" + environment.protocol + environment.webApiUrl + "/user/download-parking-ticket/" + parkingTicketId, { responseType: 'blob' });
    };
    UserService.prototype.getTicketsByUser = function () {
        return this._http.get("" + environment.protocol + environment.webApiUrl + "/user/ticket-history");
    };
    UserService.prototype.getEmailUnsubscriptions = function () {
        return this._http.get(environment.webApiUrl + "/unsubscriptions");
    };
    UserService.prototype.getProfile = function () {
        return this._http.get("" + environment.protocol + environment.webApiUrl + "/user");
    };
    UserService.prototype.createProfile = function (user) {
        var selfReg = '';
        if (this._helperService.isSelfregistration()) {
            selfReg = '?sr=true';
        }
        return this._http.post("" + environment.protocol + environment.webApiUrl + "/user" + selfReg, user);
    };
    UserService.prototype.updateProfile = function (user) {
        return this._http.patch("" + environment.protocol + environment.webApiUrl + "/user", user);
    };
    UserService.prototype.updateProfileWithoutValidation = function (user) {
        return this._http.patch("" + environment.protocol + environment.webApiUrl + "/user/novalidate", user);
    };
    UserService.prototype.getProfileBillingAddress = function () {
        return this._http.get("" + environment.protocol + environment.webApiUrl + "/user/billing-address");
    };
    UserService.prototype.putProfileBillingAddress = function (addressId, data) {
        return this._http.put("" + environment.protocol + environment.webApiUrl + "/user/billing-address/" + addressId, data);
    };
    UserService.prototype.postProfileBillingAddress = function (data) {
        return this._http.post("" + environment.protocol + environment.webApiUrl + "/user/billing-address", data);
    };
    UserService.prototype.deleteProfileBillingAddress = function (addressId) {
        return this._http.delete("" + environment.protocol + environment.webApiUrl + "/user/billing-address/" + addressId);
    };
    UserService.prototype.setNewPassword = function (password, language) {
        return this._http.post("" + environment.protocol + environment.webApiUrl + "/user/password/" + language, {
            password: password
        });
    };
    UserService.prototype.resetPassword = function (password, hash, crmHash) {
        return this._http.post("" + environment.protocol + environment.webApiUrl + "/user/password/reset", {
            password: password,
            hash: hash,
            crmHash: crmHash
        }, { observe: 'response' });
    };
    UserService.prototype.setNewEmail = function (email, password, language) {
        return this._http.post("" + environment.protocol + environment.webApiUrl + "/user/email/" + language, {
            email: email,
            password: password
        });
    };
    UserService.prototype.verifyEmail = function (hash, crmHash) {
        return this._http.post("" + environment.protocol + environment.webApiUrl + "/user/verify-email", {
            hash: hash,
            crmHash: crmHash
        }, { observe: 'response' });
    };
    UserService.prototype.sendVerifyEmail = function (language) {
        return this._http.get("" + environment.protocol + environment.webApiUrl + "/user/verify-email/" + language, { observe: 'response' });
    };
    UserService.prototype.changePassword = function (hash, crmHash) {
        return this._http.post("" + environment.protocol + environment.webApiUrl + "/user/password", {
            hash: hash,
            crmHash: crmHash
        }, { observe: 'response' });
    };
    UserService.prototype.changeEmail = function (hash, crmHash) {
        return this._http.post("" + environment.protocol + environment.webApiUrl + "/user/email", {
            hash: hash,
            crmHash: crmHash
        }, { observe: 'response' });
    };
    UserService.prototype.getListOfSocialLogins = function () {
        return this._http.get("" + environment.protocol + environment.webApiUrl + "/user/external-login/available");
    };
    UserService.prototype.getListOfSocialShares = function () {
        return this._http.get("" + environment.protocol + environment.webApiUrl + "/user/external-login/available-for-share");
    };
    UserService.prototype.updateUserWithBuyer = function () {
        var _this = this;
        observableCombineLatest(this._store.select(fromRoot.getProfile), this._store.select(fromRoot.getBuyerInfo))
            .pipe(filter(function (data) { return !!data[0] && !!data[1]; }), first())
            .subscribe(function (data) {
            var profile = data[0], buyerInfo = data[1];
            var updatedProfile = Object.assign({}, profile);
            var hasValuesToUpdate = false;
            buyerInfo.list.forEach(function (input) {
                if (input.controlType !== 'checkbox' && // there are no checkboxes we want to copy to profile
                    updatedProfile.hasOwnProperty(input.key) && // there is property to update
                    !updatedProfile[input.key] && // the property to update has no value to far
                    input.value // we have some value to update with
                ) {
                    updatedProfile[input.key] = input.value;
                    hasValuesToUpdate = true;
                }
            });
            if (hasValuesToUpdate) {
                _this._store.dispatch(new userActions.UpdateProfileWithoutValidation(updatedProfile));
            }
        });
    };
    UserService.ngInjectableDef = i0.defineInjectable({ factory: function UserService_Factory() { return new UserService(i0.inject(i1.DOCUMENT), i0.inject(i2.HttpClient), i0.inject(i3.Router), i0.inject(i4.Store), i0.inject(i5.HelperService), i0.inject(i6.AppService), i0.inject(i7.ApplicationInsightsService)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
