import { InputBase } from './../../shared/forms/inputs/input-base.class';
import * as fromRoot from '../../app.reducer';
import * as legitimationActions from '../../shared/services-with-reducers/legitimation/legitimation.actions';
import * as stepsFormsActions from '../../shared/services-with-reducers/step-forms/steps-forms.actions';
import * as userActions from '../../shared/services-with-reducers/user/user.actions';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  LegitimationStatusModel,
  legitimationType
} from '../../shared/services-with-reducers/legitimation/legitimation.interface';
import {
  Observable,
  Subscription,
  combineLatest as observableCombineLatest
} from 'rxjs';
import { filter, first, skip } from 'rxjs/operators';

import { ExhibitionModel, SelectOption } from '../../shared/services-with-reducers/exhibition/exhibition.interface';
import { ExhibitionSettingModel, OperatorsSettingsModel, QuestionnaireDataInput } from '../../shared/services-with-reducers/customization/customization.interfaces';
import { FormsService } from '../../shared/forms/forms.service';
import { StatusBarService } from '../../status-bar/status-bar.service';
import { StepsFormsService } from '../../shared/services-with-reducers/step-forms/steps-forms.service';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { UserProfileModel } from '../../shared/services-with-reducers/user/user.interface';
import { environment } from '../../../environments/environment';
import { getProfileEditForm } from '../../edit-account-form/form-inputs.edit-account';
import { HelperService } from '../../shared/services-with-reducers/helpers/helper.service';

@Component({
  moduleId: module.id,
  selector: 'app-web-shop-legitimation',
  templateUrl: './web-shop-legitimation.component.html',
  styleUrls: ['./web-shop-legitimation.component.scss']
})
export class WebShopLegitimationComponent implements OnInit, OnDestroy {
  public customUrl = ''; //`${environment.apiUrl}/event/${eventId}/user/${userId}legitimation-file?legitimationType=corporateBookExtract`;
  //http://expo-demo.teamaxess.com/WebShop/api/event/eventId/user/userId/legitimation-file/legitimationType
  public legitimationComment = '';
  public legitimationUrl = 'http://';
  public legitimation: LegitimationStatusModel;
  public responsePending: boolean = false;
  public faxId: number = null;
  public showFaxInfo: boolean;
  public previewFile: string;
  public isButtonDisabled = true;
  public selectedOption: legitimationType = null;
  public optionsValidity = {
    file: false,
    fax: false,
    url: false,
    noFile: false
  };
  public isLoggedIn$: Observable<boolean>;
  public eventSetting: ExhibitionSettingModel;
  public userProfile: UserProfileModel;
  public activeExhibition: ExhibitionModel;
  public isUploading: boolean = false;
  public showProfileForm: boolean = false;
  public isProfileUpdated: boolean = false;
  public afterFaxPrint: boolean = false;
  public modalWindowActive: boolean = false;
  public islegitimationSubmited: boolean = false;
  public isPrintWindowOpen: boolean = false;
  public legitimationForNewAccount: boolean = false;
  public doTicketsNeedLegitimation: boolean = false;
  public fileTypes: string[] = [];
  private subscriptions = new Subscription();
  private accountForm;
  private uploadedFilesIds: Array<number> = null;
  private binaryConversionConstant: number = 1024 * 1024;
  // to allow more file types add , .type
  public allowedFileTypes: string = ".gif,.jpg,.jpeg,.png,.pdf";
  // upload size in bytes 
  public maxFileUploadSize: number = 3500000;

  constructor(
    private _store: Store<fromRoot.State>,
    private _formsService: FormsService,
    private _router: Router,
    public route: ActivatedRoute,
    private _statusBarService: StatusBarService,
    private _translateService: TranslateService,
    private _stepsFormsService: StepsFormsService,
    private _helperService: HelperService
  ) {
    this.isLoggedIn$ = this._store.select(fromRoot.isUserLoggedIn);
    let firstLoad = true;

    this.subscriptions.add(
      this._store.pipe(select(fromRoot.doTicketsNeedLegitimation)).subscribe(doTicketsNeedLegitimation => {
        this.doTicketsNeedLegitimation = doTicketsNeedLegitimation;
      })
    );

    this._store
      .pipe(
        select(fromRoot.getProfile),
        filter(data => !!data),
        first()
      )
      .subscribe(profile => {
        this._store.dispatch(new userActions.GetProfile(profile.id));
      });

    this.subscriptions.add(
      observableCombineLatest([
        this._store.pipe(select(fromRoot.getProfile)),
        this._store.pipe(select(fromRoot.getSelectedExhibition)),
        this._store.pipe(select(fromRoot.getExhibitionSettings)),
        this._store.pipe(select(fromRoot.getLegitimationStatus)),
        this._store.pipe(select(fromRoot.getLegitimationFaxId)),
        this._store.pipe(select(fromRoot.getOperatorsSettings)),
        this._store.pipe(select(fromRoot.getAllTitles)),
        this._store.pipe(select(fromRoot.getTitles)),
        this._store.pipe(select(fromRoot.getAllProfessions)),
        this._store.pipe(select(fromRoot.getProfessions)),
        this._store.pipe(select(fromRoot.getAllDepartments)),
        this._store.pipe(select(fromRoot.getDepartments)),
        this._store.pipe(select(fromRoot.getAllOccupationalGroups)),
        this._store.pipe(select(fromRoot.getOccupationalGroups))
      ])
        .pipe(
          skip(1),
          filter(([profile, exhibition, eventSetting, legitimation]) => {
            return !!profile && !!exhibition && !!eventSetting && !!legitimation;
          })
        )
        .subscribe(
          async (
            data: [
              UserProfileModel,
              ExhibitionModel,
              ExhibitionSettingModel,
              LegitimationStatusModel,
              number,
              OperatorsSettingsModel,
              QuestionnaireDataInput[],
              SelectOption[],
              QuestionnaireDataInput[],
              SelectOption[],
              QuestionnaireDataInput[],
              SelectOption[],
              QuestionnaireDataInput[],
              SelectOption[]
            ]
          ) => {
            const [
              profile,
              exhibition,
              eventSetting,
              legitimation,
              faxId,
              operatorSettings,
              allTitles,
              titles,
              allProfessions,
              professions,
              allDepartments,
              departments,
              allOccupationalGroups,
              occupationalGroups
            ] = data;

            this.userProfile = profile;
            this.activeExhibition = exhibition;
            this.eventSetting = eventSetting;
            this.legitimation = legitimation;
            this.faxId = faxId;
            this.legitimationForNewAccount =
              eventSetting.goToLegitimationForNewAccount;

            // when user doesnt have profile complete we hide the legitimation and display profile form
            if (legitimation.status !== 'inProgress') {
              const validationCallback = () => {
                if (this.isProfileUpdated) {
                  this.showProfileForm = false;
                  firstLoad = false;
                } else if (!this.accountForm.valid) {
                  this.showProfileForm = true;
                  firstLoad = false;
                } else if (
                  this.accountForm.valid &&
                  !this.isProfileUpdated &&
                  firstLoad
                ) {
                  this.showProfileForm = false;
                } else if (
                  this.accountForm.valid &&
                  !this.isProfileUpdated &&
                  !firstLoad
                ) {
                  this.showProfileForm = true;
                }
              };

              this.accountForm = this._formsService.toFormGroup(
                this.createProfileEditForm(
                  operatorSettings,
                  titles,
                  allTitles,
                  professions,
                  allProfessions,
                  departments,
                  allDepartments,
                  occupationalGroups,
                  allOccupationalGroups
                )
              );

              this._helperService.triggerCallbackOnceFormValidationIsDone(
                this.accountForm,
                validationCallback
              );
            }

            let legitimationValid = legitimation.status === 'approved';

            this._formsService.setFormValidity(legitimationValid, null, [
              'legitimation',
              'validation'
            ]);
          }
        )
    );

    this.subscriptions.add(
      observableCombineLatest([
        this._store.pipe(select(fromRoot.getSelectedExhibitionId)),
        this._store.pipe(select(fromRoot.getProfile))
      ])
        .pipe(filter(data => !!data[0] && !!data[1]))
        .subscribe(data => {
          const [eventId, profile] = data;
          this.customUrl = `${environment.webApiUrl}/event/${eventId}/user/${profile.id}/legitimation-file`;

          this._store.dispatch(
            new legitimationActions.GetLegitimationStatus({
              userId: profile.id,
              eventId
            })
          );
        })
    );
  }

  // converts maximum file upload size(size in bytes) to correct value size 
  convertBytes(): string {
    if (this.maxFileUploadSize === 0) {
      return '0 B';
  }
    var k = 1000, dm = 3, sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'], i = Math.floor(Math.log(this.maxFileUploadSize) / Math.log(k));
    return (parseFloat((this.maxFileUploadSize / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]).toString();
  }

  createProfileEditForm(
    operatorSettings: OperatorsSettingsModel, 
    titles: SelectOption[], 
    allTitles: QuestionnaireDataInput[],
    professions: SelectOption[], 
    allProfessions: QuestionnaireDataInput[], 
    departments: SelectOption[], 
    allDepartments: QuestionnaireDataInput[], 
    occupationalGroups: SelectOption[], 
    allOccupationalGroups: QuestionnaireDataInput[], 
  ) {
    let profileEditForm = getProfileEditForm(
      this.eventSetting,
      operatorSettings,
      false,
      [],
      [],
      [],
      []
    );

    const inputTitles = titles ? titles : [];
    const inputProfessions = professions ? professions : [];
    const inputDepartments = departments ? departments : [];
    const inputOccupationalGroups = occupationalGroups ? occupationalGroups : [];

    const updatedProfileEditForm = profileEditForm.map(profileItem => {
      let updatedItem;

      if (
        this.userProfile.hasOwnProperty(profileItem.key) &&
        (this.userProfile[profileItem.key] ||
          this.userProfile[profileItem.key] === 0)
      ) {
        updatedItem = {
          ...profileItem,
          value: this._formsService.getFunctionTextValue(
            this.userProfile,
            profileItem,
            inputTitles,
            allTitles,
            inputProfessions,
            allProfessions,
            inputDepartments,
            allDepartments,
            inputOccupationalGroups,
            allOccupationalGroups
          )
        };

        this._formsService.resetInvalidFunctionValue(
          updatedItem,
          inputTitles,
          inputProfessions,
          inputDepartments,
          inputOccupationalGroups,
          (input: InputBase<any>) => {
            updatedItem = {
              ...input,
              value: null
            };
          }
        );
      } else {
        updatedItem = { ...profileItem, value: '' };

        if (
          profileItem.key === 'newsletterChecked' &&
          profileItem.controlType === 'radio'
        ) {
          updatedItem = {
            ...profileItem,
            value: this.userProfile.newsletterChecked.toString()
          };
        }
      }

      if (profileItem.controlType === 'checkbox') {
        updatedItem.options.forEach(option => {
          if (this.userProfile.hasOwnProperty(option.key)) {
            option.value = this.userProfile[option.key];
          }
        });
      }

      return updatedItem;
    });

    return updatedProfileEditForm;
  }

  profileUpdated(event) {
    this.isProfileUpdated = event;
  }

  ngOnInit() {
    this.setLegitimationValidity();
    this.subscriptions.add(
      this._store
        .pipe(
          select(fromRoot.isLegitimationRequired),
          filter(isRequired => !!isRequired)
        )
        .subscribe(isRequired => {
          if (!isRequired.required) {
            this._store.dispatch(
              new stepsFormsActions.SetSelectedStep('tickets')
            );

            this._router.navigate(['./tickets'], {
              relativeTo: this.route.parent,
              preserveQueryParams: true
            });
          }
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  showFax() {
    /* Ask for legitimation ID */

    this.showFaxInfo = true;
    this.afterFaxPrint = false;

    this.subscriptions.add(
      observableCombineLatest(
        this._store.select(fromRoot.getSelectedExhibitionId),
        this._store.select(fromRoot.getProfile)
      )
        .pipe(filter(data => data[0] !== null && !!data[1]))
        .subscribe(data => {
          const [eventId, profile] = data;
          this._store.dispatch(
            new legitimationActions.GetLegitimationFaxId({
              eventId,
              userId: profile.id
            })
          );
        })
    );
  }

  closeFaxInfo() {
    //if user closed print window and did not legitimate we show him pop up window before he closes the fax template
    this.modalWindowActive = this.afterFaxPrint && !this.islegitimationSubmited;
    if (!this.modalWindowActive) {
      this.showFaxInfo = false;
    }
  }

  printTemplate(printableFax) {
    this._store
      .select(fromRoot.getLegitimationFaxId)
      .pipe(
        filter(faxId => faxId !== null),
        first()
      )
      .subscribe(faxId => {
        this.isPrintWindowOpen = true;
        this.faxId = faxId;
        this.optionsValidity.fax = true;
        this.setLegitimationValidity();

        setTimeout(() => {
          const myWindow = window.open('', '', 'width=600,height=800');
          myWindow.document.write(printableFax.innerHTML);
          myWindow.document.close();
          myWindow.focus();
          myWindow.print();
          myWindow.close();
          this.afterFaxPrint = true;
          this.isPrintWindowOpen = false;
        }, 50);
      });
  }

  modalWindowLegitimate(event) {
    event.stopPropagation();
    this.submitLegitimation();
    this.modalWindowActive = false;
    this.showFaxInfo = false;
  }

  leaveFaxTemplate(event) {
    event.stopPropagation();
    this.modalWindowActive = false;
    this.showFaxInfo = false;
  }

  /* selectFile() {
    (<HTMLInputElement>document.querySelector('.ui-fileupload-choose input')).click();
  }*/

  selectTypeChange(event, index) {
    this.fileTypes[index] = event.target.value;
  }

  remove(fileupload, event, index) {
    fileupload.remove(event, index);
    this.fileTypes.splice(index, 1);
  }

  onFilesSelected(event) {
    this.fileTypes.push(
      ...Object.keys(event.files).map(() => 'corporateBookExtract')
    );
  }

  uploadLegitimationDone(event) {
    this.optionsValidity.file = true;

    if (<any>JSON.parse(event.xhr.response).hasOwnProperty('filesId')) {
      this.uploadedFilesIds = (<any>JSON.parse(event.xhr.response)).filesId;
    }
    this.isUploading = false;
    this.setLegitimationValidity();
    this.submitLegitimation();
  }

  onClear() {
    this.fileTypes.length = 0;
  }

  uploadLegitimationError() {
    this.isUploading = false;
    this.fileTypes.length = 0;
    this._translateService.get('upload.error').subscribe((msg: any) => {
      this._statusBarService.setStatus(msg, 'error');
    });
  }

  onUrlChange(event) {
    const isUrlValid = event.target.validity.valid;
    this.optionsValidity.url = isUrlValid;
    this.setLegitimationValidity();
  }

  onCommentChange() {
    if (
      !this.eventSetting.isOptionalLegitimationCommentAllowed &&
      this.eventSetting.isLegitimationWithCommentAllowed
    ) {
      const isValid = this.legitimationComment.length >= 50 || false;
      this.optionsValidity.noFile = isValid;
      this.setLegitimationValidity();
    }
  }

  setLegitimationValidity() {
    // if optional comment is used make legitimation step valid
    if (
      this.eventSetting &&
      this.eventSetting.isOptionalLegitimationCommentAllowed &&
      this.selectedOption === 'noFile'
    ) {
      this.optionsValidity.noFile = true;
    }

    this.isButtonDisabled = !this.optionsValidity[this.selectedOption];

    const stepsFormsActionName = ['legitimation', 'validation'];
    if (this.isButtonDisabled) {
      this._formsService.addStepValidationFeedback(
        stepsFormsActionName,
        'block',
        'steps.missing-input.legitimation'
      );
    } else {
      this._formsService.removeStepValidationFeedback(
        stepsFormsActionName,
        'block'
      );
    }
  }

  selectOption(option) {
    this.selectedOption = option;
    this.setLegitimationValidity();
  }

  submitLegitimation() {
    this.responsePending = true;
    this.islegitimationSubmited = true;
    this.showFaxInfo = false;
    //set the response to negative, so we know once it changes
    this._store.dispatch(
      new legitimationActions.SetLegitimationInfoResponse(false)
    );

    observableCombineLatest(
      this._store.select(fromRoot.getSelectedExhibitionId),
      this._store.select(fromRoot.getProfile),
      this._store.select(fromRoot.getLanguage)
    )
      .pipe(first())
      .subscribe(data => {
        const eventId = data[0];
        const userId = data[1].id;
        const currentLang = data[2];

        //check what was the post message response
        this._store
          .select(fromRoot.getLegitimationPostResponse)
          .pipe(
            filter(response => {
              if (!response) {
                // there was a failure, so let the user resend (reenable the send button)
                this.responsePending = false;
              }
              return response;
            }), // it has boolean value, and we are iterested only in true (success) value
            first()
          )
          .subscribe(() => {
            this._store.dispatch(
              new legitimationActions.SetLegitimationStatus({
                status: 'inProgress'
              })
            );
          });

        this._store.dispatch(
          new legitimationActions.PostLegitimationInfo({
            feed: {
              userLanguage: currentLang,
              legitimationType: this.selectedOption,
              comment: this.legitimationComment,
              url: this.legitimationUrl,
              faxId: this.faxId,
              fileId: this.uploadedFilesIds
            },
            userId,
            eventId
          })
        );
      });
  }

  beforeUpload() {
    this.isUploading = true;
  }

  triggerUpload(uploader) {
    uploader.upload();
  }

  myUploader(event, form) {
    let authToken = null;

    observableCombineLatest(
      this._store.select(fromRoot.getUser),
      this._store.select(fromRoot.getProfile)
    )
      .pipe(
        filter(
          data => !!data[0] || (data[1] && data[1].hasOwnProperty('authToken'))
        ),
        first()
      )
      .subscribe(data => {
        const [user, profile] = data;
        if (user) {
          authToken = user.authToken;
        } else if (profile.hasOwnProperty('authToken')) {
          authToken = profile.authToken;
        }

        const files = event.files;
        let xhr = new XMLHttpRequest(),
          formData = new FormData();

        form.onBeforeUpload.emit({
          xhr: xhr,
          formData: formData
        });

        for (let i = 0; i < files.length; i++) {
          formData.append(this.fileTypes[i], files[i], files[i].name);
        }

        xhr.upload.addEventListener(
          'progress',
          (e: ProgressEvent) => {
            if (e.lengthComputable) {
              form.progress = Math.round((e.loaded * 100) / e.total);
            }

            form.onProgress.emit({ originalEvent: e, progress: form.progress });
          },
          false
        );

        xhr.onreadystatechange = () => {
          if (xhr.readyState == 4) {
            form.progress = 0;

            if (xhr.status >= 200 && xhr.status < 300)
              form.onUpload.emit({ xhr: xhr, files: files });
            else form.onError.emit({ xhr: xhr, files: files });

            form.clear();
          }
        };

        xhr.open(form.method, form.url, true);

        if (authToken) {
          xhr.setRequestHeader('Authorization', authToken);
        }

        form.onBeforeSend.emit({
          xhr: xhr,
          formData: formData
        });

        xhr.withCredentials = form.withCredentials;

        xhr.send(formData);
      });
  }

  onBackClick(): void {
    this._stepsFormsService.navigateRelativeTo(-1, this._router);
  }

  goToEvents() {
    this._router.navigate(['/']);
  }
}
