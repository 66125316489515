import { first, filter } from 'rxjs/operators';
import * as fromRoot from '../../app.reducer';
import * as stepsActions from '../../shared/services-with-reducers/step-forms/steps-forms.actions';
import * as ticketActions from '../../shared/services-with-reducers/tickets/ticket.actions';

import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit
} from '@angular/core';
import {
  OrderResponseModel,
  VisibilityPayloadModel
} from '../../shared/services-with-reducers/step-forms/step.interface';

import { AppService } from '../../app.service';
import { HelperService } from '../../shared/services-with-reducers/helpers/helper.service';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { UserService } from '../../shared/services-with-reducers/user/user.service';
import { environment } from '../../../environments/environment';
import { GtmService } from '../../shared/gtm/gtmService';
import { StepsFormsService } from '../../shared/services-with-reducers/step-forms/steps-forms.service';
import { getLocalStorageString } from '../../shared/app-utils';
import { AppConstants } from '../../shared/app-constants';

@Component({
  moduleId: module.id,
  selector: 'app-web-shop-invoice',
  templateUrl: './web-shop-invoice.component.html',
  styleUrls: ['./web-shop-invoice.component.scss']
})
export class WebShopInvoiceComponent
  implements OnInit, OnDestroy, AfterViewInit {
  public shareOn = {} as any;
  public exhibitionId$: Observable<number>;
  public selectedSendingOption: string;
  public isMobile: boolean;
  public invoiceDownloadUrl: string;
  public ticketsDownloadUrl: string;
  public ticketsMobileDownloadUrl: string;
  public hasMobileTicket: boolean;
  public socialMediaShares: Array<string> = [];
  public isDownloadTicketButtonVisible: boolean;
  private _subscription: Subscription = new Subscription();
  public isAnonymousTicketLoading: boolean = false;
  public isAnonymous: boolean = false;
  private allTicketsTicketRetrievalLink: boolean = false;

  constructor(
    private _appService: AppService,
    private _helperService: HelperService,
    private _store: Store<fromRoot.State>,
    private _userService: UserService,
    private _gtmService: GtmService,
    private _stepsFormsService: StepsFormsService
  ) {
    const voucherURL: string = this._helperService.getVoucherUrl();
    const ticketParams: string = this._helperService.getTicketParams();
    !!voucherURL && this.onResetShop(true, voucherURL);
    !!ticketParams && this.onResetShop(true, null, ticketParams);

    combineLatest([
      this._store.pipe(select(fromRoot.getTickets)),
      this._store.pipe(select(fromRoot.getTicketsTypes)),
      this._store.pipe(select(fromRoot.getTicketHolderInputSets)),
      this._store.pipe(select(fromRoot.getTicketHolderAdditionalData)),
      this._store.pipe(select(fromRoot.getIsAnonymousTicketTaken)),
      this._store.pipe(select(fromRoot.getAreTicketsSent))
    ])
      .pipe(
        filter(
          (
            [
              ungroupedTickets,
              ticketTypes,
              ticketHolderInputSets,
              ticketHolderAdditionalData
            ]
          ) => {
            return (
              !!ungroupedTickets &&
              !!ticketTypes &&
              !!ticketHolderInputSets &&
              !!ticketHolderAdditionalData
            );
          }
        ),
        first()
      )
      .subscribe(([ungroupedTickets, ticketTypes, ticketHolderInputSets, ticketHolderAdditionalData, isAnonymousTicketTaken, ticketsSent]) => {
        this.isAnonymous = isAnonymousTicketTaken;

        if (isAnonymousTicketTaken && !ticketsSent) {
          this._store.dispatch(new ticketActions.SetAreTicketsSent(true));
          this.isAnonymousTicketLoading = true;
          const ungroupedTicketsWithHolders = this._stepsFormsService.assignTicketsToHoldersForSave(
            ungroupedTickets,
            ticketHolderInputSets,
            ticketHolderAdditionalData,
            ''
          );

          this._stepsFormsService.prepareDataForSaveAndSend(
            ungroupedTicketsWithHolders,
            0,
            isAnonymousTicketTaken
          );
        }
      });

    this.exhibitionId$ = this._store.select(fromRoot.getSelectedExhibitionId);

    const visibilityPayload: VisibilityPayloadModel[] = [
      {
        stepKey: 'tickets',
        visible: false
      },
      {
        stepKey: 'personal',
        visible: false
      },
      {
        stepKey: 'workshop',
        visible: false
      },
      {
        stepKey: 'menu',
        visible: false
      },
      {
        stepKey: 'legitimation',
        visible: false
      },
      {
        stepKey: 'confirmation',
        visible: false
      }
    ];

    this._store.dispatch(
      new stepsActions.SetMultipleStepsVisibility(visibilityPayload)
    );
  }

  ngOnInit() {
    // we bought the tickets, remove the booking
    this._store.dispatch(new ticketActions.RemoveTicketBookings());

    setInterval(() => {
      this.isMobile = this._helperService.isMobile();
    }, 0)

    this._subscription.add(
      this._store
        .select(fromRoot.getTicketHolderInputSets)
        .subscribe(holders => {
          this.hasMobileTicket = holders.some(holder => {
            const sendingOption = holder.inputSet.list.find(
              input => input.key === 'sendingOption'
            );
            return sendingOption.value === 'mobilePerOwner' || sendingOption.value === 'ticketRetrivalLink';
          });

          //check if there are any parking tickets as then we need to show the ticket download button even if all other tickets are ticketRetrievalLink:
          const storedParkingTickets = getLocalStorageString(AppConstants.parkingTicketsReducer);

          const parkingTicketsDictionary =
            storedParkingTickets && JSON.parse(storedParkingTickets);

          const parkingTickets = Object.keys(parkingTicketsDictionary).reduce(
            function (filtered, key) {
              if (parkingTicketsDictionary[key].hasOwnProperty('price')) {
                filtered[key] = parkingTicketsDictionary[key];
              }
              return filtered;
            },
            {}
          );

          const hasParkingTickets = Object.keys(parkingTickets).length > 0;

          //check if all tickets are ticketRetrievalLink but only if we don't have any parking tickets:
          this.allTicketsTicketRetrievalLink = !hasParkingTickets && holders.length > 0 && !holders.some(ticketHolder => {
            const sendingOption = ticketHolder.inputSet.list.find(input => input.key === 'sendingOption');
            return !!sendingOption && sendingOption.value !== 'ticketRetrivalLink';
          });
        })
    );

    this._userService
      .getListOfSocialShares()
      .pipe(
        filter(data => !!data),
        first()
      )
      .subscribe((shares: Array<string>) => {
        this.socialMediaShares = shares;
      });

    this.exhibitionId$.pipe(first()).subscribe(() => {
      this._store.dispatch(new stepsActions.SetSelectedStep('invoice'));
    });

    this._store
      .select(fromRoot.getTicketSelectedSendingOptions)
      .pipe(first())
      .subscribe(sendingOption => {
        this.selectedSendingOption = sendingOption;
      });

    combineLatest([
      this._store.pipe(select(fromRoot.isDownloadTicketButtonVisible)),
      this._store.pipe(select(fromRoot.getOrderResponse))
    ])
      .pipe(first())
      .subscribe(
        ([isDownloadTicketButtonVisible, orderResponse]) => {
          this.isDownloadTicketButtonVisible = (this.allTicketsTicketRetrievalLink && !orderResponse.hasValidVisitorQuestionnaire) ? false : isDownloadTicketButtonVisible;
      });

    this._store
      .select(fromRoot.getOrderResponse)
      .pipe(
        filter(data => !!data),
        first()
      )
      .subscribe((orderResponse: OrderResponseModel) => {
        this.invoiceDownloadUrl = (orderResponse.showInvoiceDownload === null || orderResponse.showInvoiceDownload) && orderResponse.hash
          ? `${environment.protocol}${environment.webApiUrl}/person/download-invoice/${orderResponse.hash}`
          : '';
        this.ticketsDownloadUrl = orderResponse.hash
          ? `${environment.protocol}${environment.webApiUrl}/person/download-all-tickets/${orderResponse.hash}`
          : '';
        this.ticketsMobileDownloadUrl = orderResponse.hash
          ? `${environment.protocol}${environment.webApiUrl}/person/download-all-mobile-tickets/${orderResponse.hash}`
          : '';
        this.isAnonymousTicketLoading = false;
      });
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  urlEncoding(url) {
    return encodeURIComponent(url);
  }

  socialmediaShare(socialMedia) {
    let domain = environment.protocol + environment.origin;
    domain = domain || window.location.origin;

    this.exhibitionId$.pipe(first()).subscribe(exhibitionId => {
      const encodedUrl = `${this.urlEncoding(domain)}/webshop/${exhibitionId}`;
      let url = '';

      switch (socialMedia) {
        case 'facebook':
          url = `https://www.facebook.com/sharer/sharer.php?display=popup&u=${encodedUrl}&quote=I will attend this event`;
          break;

        case 'twitter':
          url = `https://twitter.com/intent/tweet?url=${encodedUrl}&text=I will attend this event`;
          break;

        case 'linkedin':
          url = `https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}&title=I will attend this event`;
          break;

        case 'xing':
          url = `https://www.xing.com/spi/shares/new?url=${encodedUrl}`;
          break;

        case 'vkontakte':
          url = `https://vk.com/share.php?url=${encodedUrl}`;
          break;

        case 'whatsapp':
          url = `https://api.whatsapp.com/send?text=I will attend this event ${encodedUrl}`;
          break;
      }
      window.open(
        url,
        '',
        'height=700,width=700,scrollbars=yes,toolbar=no,status=yes'
      );
    });
  }

  onResetShop(releaseAllVouchersAndTickets: boolean = true, voucherId?: string, ticketParams?: string) {
    this._store
      .select(fromRoot.getSelectedExhibitionId)
      .pipe(first())
      .subscribe(exhibitionId => {
        this._appService
          .resetReducers(releaseAllVouchersAndTickets)
          .subscribe(() => {
            window.location.replace(`/webshop/${exhibitionId}` + (!!voucherId ? `/tickets?voucher=${voucherId}` : '') + (!!ticketParams ? `/tickets${ticketParams}` : ''));
          });
      });
  }

  ngAfterViewInit() {
    this._gtmService.pushPurchase();
  }
}
