var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { ErrorHandlingService } from './../../../shared/error-handling/error-handling.service';
import { combineLatest as observableCombineLatest } from 'rxjs';
import * as fromRoot from '../../../app.reducer';
import * as stepsActions from '../../../shared/services-with-reducers/step-forms/steps-forms.actions';
import * as ticketActions from '../../../shared/services-with-reducers/tickets/ticket.actions';
import { first, filter } from 'rxjs/operators';
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormsService } from '../../../shared/forms/forms.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { getLocalStorageString, removeLocalStorageItem, setLocalStorageObject } from '../../../shared/app-utils';
import { AppConstants } from '../../../shared/app-constants';
var PaymentLandingPageComponent = /** @class */ (function () {
    function PaymentLandingPageComponent(_route, _router, _formsService, _store, _errorHandlingService) {
        var _this = this;
        this._route = _route;
        this._router = _router;
        this._formsService = _formsService;
        this._store = _store;
        this._errorHandlingService = _errorHandlingService;
        this.orderId = '';
        this._store.select(fromRoot.getSelectedExhibitionId).subscribe(function (eventId) {
            _this.getSelectedExhibitionId = eventId;
        });
    }
    PaymentLandingPageComponent.prototype.ngOnInit = function () {
        var _this = this;
        observableCombineLatest(this._route.params, this._route.queryParams)
            .pipe(first())
            .subscribe(function (data) {
            var params = data[0], queryParams = data[1];
            _this.orderId = queryParams.orderid;
            if (params.response) {
                _this.response = params.response;
                // If it is fail we stay on this page and show the failure to a user in template
                // Oterwise we set the hidden payment step to be valid and send user to invoice route
                _this._store
                    .select(fromRoot.getOrderResponse)
                    .pipe(first())
                    .subscribe(function (orderResponse) {
                    if (_this.response !== 'fail' && _this.response !== 'error') {
                        orderResponse = orderResponse || {
                            hash: _this.response,
                            szCode: _this.orderId,
                            orderPaid: true
                        };
                        /*
                         We have the hash from the order, but just for sure.. for case that we lost localStorage data by redirect,
                         we set the hash again to be the one taken from url. Also we set 'orderPaid' to true.
                         Then we redirect to invoice page for downloads
                      */
                        orderResponse.hash = _this.response;
                        orderResponse.szCode = _this.orderId;
                        orderResponse.orderPaid = true;
                        _this._store.dispatch(new stepsActions.SetOrderResponse(orderResponse));
                        // there is invisible payment step, set it now to valid so we can skip it and go to invoice page
                        _this._formsService.setStepValid(['payment', 'validation']);
                        _this._router.navigate([
                            "webshop/" + _this.getSelectedExhibitionId + "/invoice"
                        ]);
                    }
                    if (!Object.keys(queryParams).length) {
                        var errorMessageKey = '';
                        if (typeof orderResponse.errorCode === 'number') {
                            _this.removeParkingTickets(orderResponse.invalidTickets);
                            errorMessageKey = orderResponse.errorCode.toString();
                        }
                        else {
                            errorMessageKey = orderResponse.errorCode;
                        }
                        _this.translatedErrorMessage = {
                            status: 200,
                            key: errorMessageKey,
                            message: orderResponse.errorMessage
                        };
                    }
                });
                // if there was an error from BE previously, take it from queryParams
                if (Object.keys(queryParams).length) {
                    _this.translatedErrorMessage = __assign({}, queryParams);
                }
            }
        });
        // if there was error with status 409 || 404, use this error instad default and store message to queryParams (preserve it on reload)
        this._errorHandlingService.translatedErrorMessage
            .pipe(filter(function (translatedErrorMessage) {
            var statusCode = translatedErrorMessage && translatedErrorMessage.status;
            if (statusCode === 409 || statusCode === 404) {
                return true;
            }
        }), first())
            .subscribe(function (translatedErrorMessage) {
            _this._router.navigate(['.'], {
                relativeTo: _this._route,
                queryParams: __assign({}, translatedErrorMessage)
            });
            _this.translatedErrorMessage = translatedErrorMessage;
        });
    };
    PaymentLandingPageComponent.prototype.removeParkingTickets = function (invalidTickets) {
        var _this = this;
        var storedParkingTickets = getLocalStorageString(AppConstants.parkingTicketsReducer);
        var parsedParkingTickets = storedParkingTickets && JSON.parse(storedParkingTickets);
        var parsedParkingTicketsLength = Object.keys(parsedParkingTickets).length;
        invalidTickets.forEach(function (invalidTicket, index) {
            delete parsedParkingTickets[invalidTicket.ticketGroupNr +
                '_' +
                invalidTicket.ticketPersonNr +
                '_' +
                (parsedParkingTicketsLength - (index + 1))];
        });
        removeLocalStorageItem(AppConstants.parkingTicketsReducer);
        setLocalStorageObject(AppConstants.parkingTicketsReducer, parsedParkingTickets);
        this._store
            .select(fromRoot.getTickets)
            .pipe(first())
            .subscribe(function (tickets) {
            for (var uniqueId in tickets) {
                if (tickets[uniqueId].parking) {
                    tickets[uniqueId].count = Object.keys(parsedParkingTickets).length;
                }
            }
            _this._store.dispatch(new ticketActions.SetTickets(__assign({}, tickets)));
        });
    };
    PaymentLandingPageComponent.prototype.back = function () {
        var _this = this;
        this._store
            .select(fromRoot.getTickets)
            .pipe(first())
            .subscribe(function (tickets) {
            var ticketsCount = Object.keys(tickets).reduce(function (accu, curr) {
                var ticket = tickets[curr];
                return accu + ticket.count;
            }, 0);
            if (ticketsCount > 0) {
                _this._router.navigate([
                    "webshop/" + _this.getSelectedExhibitionId + "/confirmation"
                ]);
            }
            else {
                _this._router.navigate([
                    "webshop/" + _this.getSelectedExhibitionId + "/tickets"
                ]);
            }
        });
    };
    return PaymentLandingPageComponent;
}());
export { PaymentLandingPageComponent };
