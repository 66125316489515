<!-- CONTENT FOR WEBSHOP - NOT RUNNING - IN WIDGET -->
<ng-container *ngIf="!pageRunningInIframe">
  <div
    class="app-page-wrap"
    [ngClass]="{ 'hide-until-translations': !translationsLoaded }"
  >
    <div
      class="app-page"
      (contextmenu)="rightClick($event)"
      [ngClass]="{ 'self-registration': isSelfRegistrationEnabled }"
    >
      <div
        *ngIf="backgroundImage"
        class="page-background-with-image "
        [style.background-image]="'url(' + backgroundImage + ')'"
      ></div>

      <div
        *ngIf="!backgroundImage"
        class="page-background-without-image "
      ></div>

      <app-status-bar></app-status-bar>

      <app-top-bar class="top-bar"></app-top-bar>

      <div class="main-content">
        <router-outlet></router-outlet>
      </div>

      <app-footer class="footer"></app-footer>

      <app-browser-check *ngIf="!isSelfRegistrationEnabled">
      </app-browser-check>

      <app-bottom-sticky></app-bottom-sticky>

      <app-modal-window [modalWindowActive]="reloadModalWindowOpen" translateTitle="true" [modalWindowTitle]="'step-navigation.reload-required'">
        <button class="button button-option" modalWindow-close (click)="closeModalWindow($event)">
            <span data-translation="ticket.timeout.modalWindow.close">{{ 'ticket.timeout.modalWindow.close' | translate | hideTranslation }}</span>
        </button>
      </app-modal-window>

    </div>
  </div>

  <app-big-loader *ngIf="!translationsLoaded"></app-big-loader>

  <app-colorizer *ngIf="isLoggedInAsAdmin$ | async"></app-colorizer>

  <app-iframe-modal></app-iframe-modal>
</ng-container>

<!-- CONTENT FOR WEBSHOP - RUNNING - IN WIDGET -->
<ng-container *ngIf="pageRunningInIframe">
  <app-top-bar-widget class="top-bar"></app-top-bar-widget>

  <router-outlet></router-outlet>

  <!-- <app-footer class="footer-widget">
    </app-footer> -->

  <!-- <app-bottom-sticky></app-bottom-sticky> -->
</ng-container>
