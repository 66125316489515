<div
  class="workshop-available"
  [ngClass]="{
    'sold-out':
      (noSeatsLeft && !lastSeatWithSingleAssignedTicket) || workshop.disabled
  }"
>
  <span
    class="availability-text"
    *ngIf="noSeatsLeft && !lastSeatWithSingleAssignedTicket"
    data-translation="workshop.sold"
    >{{ 'workshop.sold' | translate | hideTranslation }}</span
  >

  <span
    class="availability-text"
    *ngIf="
      (!noSeatsLeft && !workshop.disabled) ||
      (noSeatsLeft && lastSeatWithSingleAssignedTicket)
    "
    data-translation="workshop.available"
  >
    <span *ngIf="showAmountOfAvailableWorkshops"
      >{{ workshopSeats }}
      {{ 'workshop.available' | translate | hideTranslation }}</span
    >
  </span>

  <span
    class="availability-text"
    *ngIf="
      (!noSeatsLeft || (noSeatsLeft && lastSeatWithSingleAssignedTicket)) &&
      workshop.disabled
    "
    data-translation="workshop.not-accessible"
    >{{ 'workshop.not-accessible' | translate | hideTranslation }}</span
  >

  <div class="visitors-count" *ngIf="noOfMyAssignedVisitors">
    <span>{{ noOfMyAssignedVisitors }}</span>
  </div>
</div>
