import { AfterViewInit, Component, ElementRef, Input } from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
  constructor(private _el: ElementRef) {}

  @Input() isFixed = false;

  click(event) {
    event.stopPropagation();
    event.preventDefault();
  }
}
