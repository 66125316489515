import {
  WidgetSettingTypeKeyname,
  WidgetSettingType
} from './../../../_pages/widget-admin/widget-models.interface';
import {
  CssSkinModel,
  ExhibitionSettingModel,
  LocalizedImagesModel,
  OperatorsSettingsModel,
  SponsorBannerModel,
  TicketOwnerSettingsModel
} from './customization.interfaces';
import { WidgetSettings } from '../../../_pages/widget-admin/widget-models.interface';

import { Action } from '@ngrx/store';
import { Params } from '@angular/router';

export enum ActionTypes {
  GET_EXHIBITION_SETTINGS = '[Customization] Get exhibition settings',
  SET_EXHIBITION_SETTINGS = '[Customization] Set exhibition settings',
  SET_EXHIBITION_STYLES = '[Customization] Set exhibition styles',
  GET_OPERATORS_SETTINGS = '[Customization] Get operators settings',
  SET_OPERATORS_SETTINGS = '[Customization] Set operators settings',
  GET_RANDOM_SPONSOR = '[Customization] Get random sponsor',
  SET_RANDOM_SPONSOR = '[Customization] Set random sponsor',
  GET_LOCALIZED_IMAGES = '[Customization] Get localized images',
  SET_LOCALIZED_IMAGES = '[Customization] Set localized images',
  RESET_REDUCER = '[Customization] reset reducer',
  PARTIAL_RESET_REDUCER = '[Customization] partial reset reducer',
  SET_ORDER_UUID = '[Customization] set uuid',
  SET_SHOPPING_START_TIME = '[Customization] Set shopping start time',
  RESET_SHOPPING_START_TIME = '[Customization] Reset shopping start time',
  SET_WIDGET_SETTINGS = '[Customization] Set widget settings',
  GET_WIDGET_SETTINGS = '[Customization] Get widget settings'
}
/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions.
 *
 * See Discriminated Unions: https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions
 */

export class GetExhibitionSettings implements Action {
  readonly type = ActionTypes.GET_EXHIBITION_SETTINGS;
  constructor(public payload: number) {}
}

export class SetExhibitionSettings implements Action {
  readonly type = ActionTypes.SET_EXHIBITION_SETTINGS;
  constructor(public payload: ExhibitionSettingModel) {}
}

export class SetExhibitionStyles implements Action {
  readonly type = ActionTypes.SET_EXHIBITION_STYLES;
  constructor(public payload: { cssSkin: CssSkinModel; isAdmin: boolean }) {}
}

export class GetOperatorsSettings implements Action {
  readonly type = ActionTypes.GET_OPERATORS_SETTINGS;
}

export class SetOperatorsSettings implements Action {
  readonly type = ActionTypes.SET_OPERATORS_SETTINGS;
  constructor(public payload: OperatorsSettingsModel) {}
}

export class GetRandomSponsor implements Action {
  readonly type = ActionTypes.GET_RANDOM_SPONSOR;
  constructor(public payload: { eventId: string; countryCode?: string }) {}
}

export class SetRandomSponsor implements Action {
  readonly type = ActionTypes.SET_RANDOM_SPONSOR;
  constructor(public payload: SponsorBannerModel) {}
}

export class GetLocalizedImages implements Action {
  readonly type = ActionTypes.GET_LOCALIZED_IMAGES;
  constructor(public payload: { eventId; countryCode }) {}
}

export class SetLocalizedImages implements Action {
  readonly type = ActionTypes.SET_LOCALIZED_IMAGES;
  constructor(public payload: LocalizedImagesModel) {}
}

export class ResetReducer implements Action {
  readonly type = ActionTypes.RESET_REDUCER;
}

export class PartialResetReducer implements Action {
  readonly type = ActionTypes.PARTIAL_RESET_REDUCER;
}

export class SetOrderUuid implements Action {
  readonly type = ActionTypes.SET_ORDER_UUID;
  constructor(public payload: string) {}
}

export class SetShoppingStartTime implements Action {
  readonly type = ActionTypes.SET_SHOPPING_START_TIME;
}

export class ResetShoppingStartTime implements Action {
  readonly type = ActionTypes.RESET_SHOPPING_START_TIME;
}

export class SetWidgetSettings implements Action {
  readonly type = ActionTypes.SET_WIDGET_SETTINGS;
  constructor(
    public payload: {
      type: WidgetSettingTypeKeyname;
      settings: WidgetSettingType;
    }
  ) {}
}

export class GetWidgetSettings implements Action {
  readonly type = ActionTypes.GET_WIDGET_SETTINGS;
  constructor() {}
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Actions =
  | GetExhibitionSettings
  | SetExhibitionSettings
  | SetExhibitionStyles
  | GetOperatorsSettings
  | SetOperatorsSettings
  | GetRandomSponsor
  | SetRandomSponsor
  | GetLocalizedImages
  | SetLocalizedImages
  | ResetReducer
  | PartialResetReducer
  | SetOrderUuid
  | SetWidgetSettings
  | GetWidgetSettings
  | SetShoppingStartTime
  | ResetShoppingStartTime;
