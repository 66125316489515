import { map, catchError } from 'rxjs/operators';
import { ErrorHandlingService } from '../../error-handling/error-handling.service';
import { Http } from '@angular/http';
import { StatusBarService } from '../../../status-bar/status-bar.service';
import { environment } from '../../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../../error-handling/error-handling.service";
import * as i2 from "@angular/http";
import * as i3 from "../../../status-bar/status-bar.service";
var AdditionalServicesService = /** @class */ (function () {
    function AdditionalServicesService(_errorHandlingService, _http, _statusBarService) {
        this._errorHandlingService = _errorHandlingService;
        this._http = _http;
        this._statusBarService = _statusBarService;
    }
    AdditionalServicesService.prototype.getMenuOptions = function (eventId) {
        var _this = this;
        return this._http
            .get("" + environment.protocol + environment.webApiUrl + "/event/" + eventId + "/menu-booking")
            .pipe(map(function (res) {
            try {
                return res.json() || {};
            }
            catch (err) {
                _this._statusBarService.setStatus(err, 'error');
            }
        }), catchError(function (e) {
            return _this._errorHandlingService.errorHandler(e);
        }));
    };
    AdditionalServicesService.prototype.getWorkshopList = function (eventId) {
        var _this = this;
        return this._http
            .get("" + environment.protocol + environment.webApiUrl + "/workshop/workshop-selection/" + eventId)
            .pipe(map(function (res) {
            try {
                return res.json() || {};
            }
            catch (err) {
                _this._statusBarService.setStatus(err, 'error');
            }
        }), catchError(function (e) {
            return _this._errorHandlingService.errorHandler(e);
        }));
    };
    AdditionalServicesService.prototype.postWorkshopBooking = function (data) {
        var _this = this;
        return this._http
            .post("" + environment.protocol + environment.webApiUrl + "/order/workshop-booking", data)
            .pipe(map(function (res) {
            try {
                return res.json() || {};
            }
            catch (err) {
                _this._statusBarService.setStatus(err, 'error');
            }
        }), catchError(function (e) {
            return _this._errorHandlingService.errorHandler(e);
        }));
    };
    AdditionalServicesService.prototype.releaseBookings = function (orderUuid) {
        var _this = this;
        return this._http
            .post("" + environment.protocol + environment.webApiUrl + "/order/workshop-booking/" + orderUuid + "/release", {})
            .pipe(map(function (res) {
            try {
                return res.json() || {};
            }
            catch (err) {
                _this._statusBarService.setStatus(err, 'error');
            }
        }), catchError(function (e) {
            return _this._errorHandlingService.errorHandler(e);
        }));
    };
    AdditionalServicesService.ngInjectableDef = i0.defineInjectable({ factory: function AdditionalServicesService_Factory() { return new AdditionalServicesService(i0.inject(i1.ErrorHandlingService), i0.inject(i2.Http), i0.inject(i3.StatusBarService)); }, token: AdditionalServicesService, providedIn: "root" });
    return AdditionalServicesService;
}());
export { AdditionalServicesService };
