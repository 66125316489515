var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import * as fromRoot from '../../../app.reducer';
import * as helperActions from '../helpers/helper.actions';
import * as userActions from './user.actions';
import { Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { InjectionToken } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';
import { CustomizationService } from '../customization/customization.service';
import { HelperService } from '../helpers/helper.service';
import { UserService } from './user.service';
import { transformeventsEmailUnsubscriptions } from '../customization/forms/newsletter-unsubscriptions-data';
import { AppConstants } from '../../app-constants';
import { ApplicationInsightsService } from '../../applicationInsights/applicationInsightsService';
export var USER_DEBOUNCE = new InjectionToken('User Debounce');
var UserEffect = /** @class */ (function () {
    function UserEffect(actions$, userService, debounce, _customizationService, _store, _helperService, _applicationInsightsService) {
        if (debounce === void 0) { debounce = 50; }
        var _this = this;
        this.actions$ = actions$;
        this.userService = userService;
        this.debounce = debounce;
        this._customizationService = _customizationService;
        this._store = _store;
        this._helperService = _helperService;
        this._applicationInsightsService = _applicationInsightsService;
        this.login$ = this.actions$.pipe(ofType(userActions.ActionTypes.LOGIN), switchMap(function (data) {
            var _a = data.payload, email = _a.email, password = _a.password;
            // TODO update interface LoginModel
            if (!email && !password) {
                return Observable.empty();
            }
            return _this.userService.login(email, password).pipe(map(function (user) {
                user.userType = AppConstants.getUserTypeFromJwt(user);
                if (user.hasOwnProperty('id')) {
                    _this._store.dispatch(new userActions.GetProfile(user.id));
                    _this._store.dispatch(new userActions.SetLoginTimestamp(Date.now()));
                    _this._applicationInsightsService.setUserId(user.id);
                }
                return new userActions.SetUser(user);
            }), catchError(function () { return of(new userActions.SetUser(null)); }));
        }));
        /*   @Effect()
        loginAsGuest$: Observable<Action> = this.actions$
          .ofType(userActions.LOGIN_AS_GUEST)
          .debounceTime(this.debounce)
          .switchMap(() => {
            return this.userService
              .loginAsGuest()
              .map((user: UserModel) => new userActions.SetUser(user))
              .catch(() => of(new userActions.SetUser(null)));
          }); */
        this.registrationQuestionnaire$ = this.actions$.pipe(ofType(userActions.ActionTypes.GET_REGISTRATION_QUESTIONNAIRE), debounceTime(this.debounce), switchMap(function (data) {
            var eventId = data.payload;
            return _this.userService.getRegistrationQuestionnaire(eventId).pipe(map(function (registrationQuestionnaire) {
                var questionnaire = registrationQuestionnaire;
                var questionnaireInputs = _this._customizationService.tansformQuestionnaireIntoInput(questionnaire);
                return new userActions.SetRegistrationQuestionnaire(questionnaireInputs);
            }), catchError(function () { return of(new userActions.SetRegistrationQuestionnaire([])); }));
        }));
        this.ticketsByUser$ = this.actions$.pipe(ofType(userActions.ActionTypes.GET_TICKETS_HISTORY_BY_USER), debounceTime(this.debounce), switchMap(function (data) {
            var userId = data.payload;
            if (!userId) {
                return Observable.empty();
            }
            return _this.userService.getTicketsByUser().pipe(map(function (ticketsByUser) {
                return new userActions.SetTicketsHistoryByUser(ticketsByUser);
            }), catchError(function () { return of(new userActions.SetTicketsHistoryByUser([])); }));
        }));
        this.eventsEmailUnsubscriptions$ = this.actions$.pipe(ofType(userActions.ActionTypes.GET_EVENTS_EMAIL_UNSUBSCRIPTIONS), debounceTime(this.debounce), switchMap(function (data) {
            var userId = data.payload;
            if (!userId) {
                return Observable.empty();
            }
            return _this.userService.getEmailUnsubscriptions().pipe(map(function (emailUnsubscriptions) {
                var eventsEmailUnsubscriptions = emailUnsubscriptions;
                var unsubscriptionInputs = transformeventsEmailUnsubscriptions(eventsEmailUnsubscriptions);
                return new userActions.SetEventsEmailUnsubscriptions(unsubscriptionInputs);
            }), catchError(function () { return of(new userActions.SetEventsEmailUnsubscriptions([])); }));
        }));
        this.getProfile$ = this.actions$.pipe(ofType(userActions.ActionTypes.GET_PROFILE), debounceTime(this.debounce), switchMap(function (data) {
            var userId = data.payload;
            _this._store.dispatch(new userActions.ProfileLoading(true));
            if (!userId) {
                _this._store.dispatch(new userActions.ProfileLoading(false));
                return Observable.empty();
            }
            return _this.userService.getProfile().pipe(map(function (profile) {
                // remove when BE will send null value for gender
                if (profile.gender === 'notSet') {
                    profile.gender = null;
                }
                profile.dateOfBirth = !!profile.dateOfBirth ? _this._helperService.getUTCdate(new Date(profile.dateOfBirth)) : null;
                return new userActions.SetProfile(profile);
            }), catchError(function () { return of(new userActions.SetProfile(null)); }));
        }));
        this.createProfile$ = this.actions$.pipe(ofType(userActions.ActionTypes.POST_PROFILE), debounceTime(this.debounce), switchMap(function (data) {
            var user = data.payload;
            if (!user) {
                return Observable.empty();
            }
            var questionnaire = null;
            if (user.hasOwnProperty('questionnaire')) {
                questionnaire = Object.assign({}, user.questionnaire);
                delete user.questionnaire;
            }
            return _this.userService.createProfile(user).pipe(map(function (profile) {
                if (questionnaire) {
                    var questionnaireToSave_1 = {
                        userId: profile.id,
                        questionnaire: questionnaire
                    };
                    setTimeout(function () {
                        _this._store.dispatch(new userActions.PostRegistrationQuestionnaire(questionnaireToSave_1));
                    }, 0);
                }
                _this._store.dispatch(new userActions.SetUser({
                    id: profile.id,
                    authToken: profile.authToken,
                    userType: profile.userType
                }));
                _this._store.dispatch(new userActions.GetProfile(profile.id));
                _this._helperService.redirectAfterLogin();
                return new userActions.SetProfile(profile);
            }), catchError(function () { return of(new userActions.SetProfile(null)); }));
        }));
        this.updateProfile$ = this.actions$.pipe(ofType(userActions.ActionTypes.PUT_PROFILE), debounceTime(this.debounce), switchMap(function (data) {
            var user = data.payload;
            if (!user) {
                return Observable.empty();
            }
            return _this.userService.updateProfile(user).pipe(map(function (profile) {
                return new userActions.SetProfile(profile);
            }), catchError(function () { return of(new userActions.SetProfile(null)); }));
        }));
        this.updateProfileWithoutValidation$ = this.actions$.pipe(ofType(userActions.ActionTypes.PUT_PROFILE_WITHOUT_VALIDATION), debounceTime(this.debounce), switchMap(function (data) {
            var user = data.payload;
            if (!user) {
                return Observable.empty();
            }
            return _this.userService.updateProfileWithoutValidation(user).pipe(map(function (profile) {
                return new userActions.SetProfile(profile);
            }), catchError(function () { return of(new userActions.SetProfile(null)); }));
        }));
        this.postRegistrationQuestionnaire$ = this.actions$.pipe(ofType(userActions.ActionTypes.POST_REGISTRATION_QUESTIONNAIRE_PROFILE), debounceTime(this.debounce), switchMap(function (data) {
            if (!data.payload) {
                return Observable.empty();
            }
            var _a = data.payload, questionnaire = _a.questionnaire, userId = _a.userId;
            return _this.userService
                .saveRegistrationQuestionnaire(questionnaire)
                .pipe(map(function (code) {
                _this._helperService.redirectAfterLogin();
                return new helperActions.SetApiCallResult({
                    code: code,
                    message: 'post registration questionnaire success'
                });
            }), catchError(function () {
                return of(new helperActions.SetApiCallResult({
                    code: 0,
                    message: 'post registration questionnaire fail'
                }));
            }));
        }));
        this.getProfileBillingAddress$ = this.actions$.pipe(ofType(userActions.ActionTypes.GET_PROFILE_BILLING_ADDRESS), debounceTime(this.debounce), switchMap(function (data) {
            var userId = data.payload;
            if (!userId) {
                return Observable.empty();
            }
            return _this.userService.getProfileBillingAddress().pipe(map(function (billingAddress) {
                return new userActions.SetProfileBillingAddress(billingAddress);
            }), catchError(function () { return of(new userActions.SetProfileBillingAddress([])); }));
        }));
        this.putProfileBillingAddress$ = this.actions$.pipe(ofType(userActions.ActionTypes.PUT_PROFILE_BILLING_ADDRESS), debounceTime(this.debounce), switchMap(function (actionData) {
            var _a = actionData.payload, userId = _a.userId, addressId = _a.addressId, data = _a.data;
            if (!actionData.payload) {
                return Observable.empty();
            }
            return _this.userService
                .putProfileBillingAddress(addressId, data)
                .pipe(map(function (billingAddress) {
                return new userActions.UpdateProfileBillingAddress(billingAddress);
            }), catchError(function () {
                return of(new userActions.UpdateProfileBillingAddress(null));
            }));
        }));
        this.deleteProfileBillingAddress$ = this.actions$.pipe(ofType(userActions.ActionTypes.DELETE_PROFILE_BILLING_ADDRESS), debounceTime(this.debounce), switchMap(function (data) {
            var _a = data.payload, userId = _a.userId, addressId = _a.addressId;
            return _this.userService
                .deleteProfileBillingAddress(addressId)
                .pipe(map(function (deletedBillingAddress) {
                /* As the billng address to be removed is for sure in the list,
               the next call to redux is actually synchronous */
                var billingAddresses;
                _this._store
                    .select(fromRoot.getProfileBillingAddress)
                    .subscribe(function (addresses) {
                    billingAddresses = addresses;
                });
                var clearedAddresses = billingAddresses.filter(function (currentAddress) {
                    return currentAddress.id !== deletedBillingAddress.id;
                });
                var addressesClone = JSON.parse(JSON.stringify(clearedAddresses));
                return new userActions.SetProfileBillingAddress(addressesClone);
            }), catchError(function () { return of(new userActions.SetProfileBillingAddress([])); }));
        }));
        this.patchEmail$ = this.actions$.pipe(ofType(userActions.ActionTypes.PATCH_EMAIL), debounceTime(this.debounce), switchMap(function (data) {
            var _a = data.payload, userId = _a.userId, email = _a.email, password = _a.password, language = _a.language;
            if (!data.payload) {
                return Observable.empty();
            }
            return _this.userService
                .setNewEmail(email, password, language)
                .pipe(map(function (code) { return new userActions.EmailChanged(200); }), catchError(function () {
                return of(new userActions.EmailChanged(500));
            }));
        }));
        this.patchPassword$ = this.actions$.pipe(ofType(userActions.ActionTypes.PATCH_PASSWORD), debounceTime(this.debounce), switchMap(function (data) {
            if (!data.payload) {
                return Observable.empty();
            }
            var _a = data.payload, userId = _a.userId, password = _a.password, language = _a.language;
            return _this.userService.setNewPassword(password, language).pipe(map(function (code) { return new userActions.PasswordChanged(code); }), catchError(function () { return of(new userActions.PasswordChanged(500)); }));
        }));
        this.resetPassword$ = this.actions$.pipe(ofType(userActions.ActionTypes.RESET_PASSWORD), debounceTime(this.debounce), switchMap(function (data) {
            if (!data.payload) {
                return Observable.empty();
            }
            var _a = data.payload, hash = _a.hash, password = _a.password, crmHash = _a.crmHash;
            return _this.userService.resetPassword(password, hash, crmHash).pipe(map(function (code) {
                return new userActions.PasswordChanged(code.status);
            }), catchError(function (err) {
                console.log('error', err);
                return of(new userActions.PasswordChanged(err.status));
            }));
        }));
        this.verifyEmail$ = this.actions$.pipe(ofType(userActions.ActionTypes.VERIFY_EMAIL), debounceTime(this.debounce), switchMap(function (payloadData) {
            if (!payloadData) {
                return Observable.empty();
            }
            var _a = payloadData.payload, hash = _a.hash, crmHash = _a.crmHash;
            return _this.userService.verifyEmail(hash, crmHash).pipe(map(function (response) {
                return new userActions.EmailVerified({
                    statusCode: response.status,
                    body: {
                        assignedTickets: response.body.assignedTickets,
                        assignedOrders: response.body.assignedOrders
                    }
                });
            }), catchError(function (err) {
                console.log('error caught 3', err);
                return of(new userActions.EmailVerified({
                    statusCode: err.status,
                    body: {
                        assignedTickets: null,
                        assignedOrders: null
                    }
                }));
            }));
        }));
        this.changePassword$ = this.actions$.pipe(ofType(userActions.ActionTypes.CHANGE_PASSWORD), debounceTime(this.debounce), switchMap(function (payloadData) {
            if (!payloadData) {
                return Observable.empty();
            }
            var _a = payloadData.payload, hash = _a.hash, crmHash = _a.crmHash;
            return _this.userService.changePassword(hash, crmHash).pipe(map(function (code) {
                return new userActions.PasswordChanged(code.status);
            }), catchError(function (err) {
                console.log('error', err);
                return of(new userActions.PasswordChanged(err.status));
            }));
        }));
        this.changeEmail$ = this.actions$.pipe(ofType(userActions.ActionTypes.CHANGE_EMAIL), debounceTime(this.debounce), switchMap(function (payloadData) {
            if (!payloadData) {
                return Observable.empty();
            }
            var _a = payloadData.payload, hash = _a.hash, crmHash = _a.crmHash;
            return _this.userService.changeEmail(hash, crmHash).pipe(map(function (code) {
                return new userActions.EmailChanged(code.status);
            }), catchError(function (err) {
                console.log('error', err);
                return of(new userActions.EmailChanged(err.status));
            }));
        }));
        this.requestPasswordRecovery$ = this.actions$.pipe(ofType(userActions.ActionTypes.POST_PASSWORD_RECOVERY), debounceTime(this.debounce), switchMap(function (data) {
            if (!data.payload) {
                return Observable.empty();
            }
            return _this.userService.forgottenPassword(data.payload).pipe(map(function () {
                return new userActions.RequestPasswordRecoveryResult(true);
            }), catchError(function () {
                return of(new userActions.RequestPasswordRecoveryResult(false));
            }));
        }));
        this.DownloadTicketById$ = this.actions$.pipe(ofType(userActions.ActionTypes.DOWNLOAD_TICKET_BY_ID), debounceTime(this.debounce), switchMap(function (data) {
            var _a = data.payload, id = _a.id, type = _a.type, ticketId = _a.ticketId, packageIndex = _a.packageIndex;
            if (!id) {
                return Observable.empty();
            }
            if (type === 'normalTicket') {
                return _this.userService.downloadTicketById(id).pipe(map(function (blob) {
                    _this._helperService.saveToFileSystem(blob, "ticket_" + id + ".pdf");
                    // if we load the user
                    return new helperActions.SetApiCallResult({
                        code: 200,
                        message: 'ticket download successfull'
                    });
                }), catchError(function () {
                    return of(new helperActions.SetApiCallResult({
                        code: 0,
                        message: 'ticket download fail'
                    }));
                }));
            }
            if (type === 'invoiceTicket') {
                return _this.userService.downloadInvoice(id).pipe(map(function (blob) {
                    _this._helperService.saveToFileSystem(blob, "invoice_" + id + ".pdf");
                    // if we load the user
                    return new helperActions.SetApiCallResult({
                        code: 200,
                        message: 'ticket download successfull'
                    });
                }), catchError(function () {
                    return of(new helperActions.SetApiCallResult({
                        code: 0,
                        message: 'ticket download fail'
                    }));
                }));
            }
            if (type === 'passBook') {
                return _this.userService.downloadPassbook(id).pipe(map(function (blob) {
                    _this._helperService.saveToFileSystem(blob, "passbook_" + id + ".pkpass");
                    // if we load the user
                    return new helperActions.SetApiCallResult({
                        code: 200,
                        message: 'ticket download successfull'
                    });
                }), catchError(function () {
                    return of(new helperActions.SetApiCallResult({
                        code: 0,
                        message: 'ticket download fail'
                    }));
                }));
            }
            if (type === 'refundReceipt') {
                return _this.userService.downloadRefundReceipt(id).pipe(map(function (blob) {
                    _this._helperService.saveToFileSystem(blob, "refund_order_" + id + ".pdf");
                    // if we load the user
                    return new helperActions.SetApiCallResult({
                        code: 200,
                        message: 'Refund receipt download successfull'
                    });
                }), catchError(function () {
                    return of(new helperActions.SetApiCallResult({
                        code: 0,
                        message: 'Refund receipt download fail'
                    }));
                }));
            }
            if (type === 'refundSingleTicketReceipt') {
                if (!!ticketId) {
                    return _this.userService.downloadTicketRefundReceipt(id, ticketId).pipe(map(function (blob) {
                        _this._helperService.saveToFileSystem(blob, "refund_ticket_" + ticketId + ".pdf");
                        // if we load the user
                        return new helperActions.SetApiCallResult({
                            code: 200,
                            message: 'Refund receipt download successfull'
                        });
                    }), catchError(function () {
                        return of(new helperActions.SetApiCallResult({
                            code: 0,
                            message: 'Refund receipt download fail'
                        }));
                    }));
                }
            }
            if (type === 'refundPackageReceipt') {
                if (packageIndex > -1) {
                    return _this.userService.downloadPackageRefundReceipt(id, packageIndex).pipe(map(function (blob) {
                        _this._helperService.saveToFileSystem(blob, "refund_package_" + id + "_" + packageIndex + ".pdf");
                        // if we load the user
                        return new helperActions.SetApiCallResult({
                            code: 200,
                            message: 'Refund receipt download successfull'
                        });
                    }), catchError(function () {
                        return of(new helperActions.SetApiCallResult({
                            code: 0,
                            message: 'Refund receipt download fail'
                        }));
                    }));
                }
            }
            if (type === 'parkingTicket') {
                return _this.userService.downloadParkingTicketById(id).pipe(map(function (blob) {
                    _this._helperService.saveToFileSystem(blob, "parkingTicket_" + id + ".pdf");
                    // if we load the user
                    return new helperActions.SetApiCallResult({
                        code: 200,
                        message: 'ticket download successfull'
                    });
                }), catchError(function () {
                    return of(new helperActions.SetApiCallResult({
                        code: 0,
                        message: 'ticket download fail'
                    }));
                }));
            }
            if (type === 'parkingRefundReceipt') {
                if (!!ticketId) {
                    return _this.userService.downloadParkingRefundReceipt(id, ticketId).pipe(map(function (blob) {
                        _this._helperService.saveToFileSystem(blob, "refund_parking_ticket_" + ticketId + ".pdf");
                        // if we load the user
                        return new helperActions.SetApiCallResult({
                            code: 200,
                            message: 'Refund receipt download successfull'
                        });
                    }), catchError(function () {
                        return of(new helperActions.SetApiCallResult({
                            code: 0,
                            message: 'Refund receipt download fail'
                        }));
                    }));
                }
            }
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], UserEffect.prototype, "login$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], UserEffect.prototype, "registrationQuestionnaire$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], UserEffect.prototype, "ticketsByUser$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], UserEffect.prototype, "eventsEmailUnsubscriptions$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], UserEffect.prototype, "getProfile$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], UserEffect.prototype, "createProfile$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], UserEffect.prototype, "updateProfile$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], UserEffect.prototype, "updateProfileWithoutValidation$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], UserEffect.prototype, "postRegistrationQuestionnaire$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], UserEffect.prototype, "getProfileBillingAddress$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], UserEffect.prototype, "putProfileBillingAddress$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], UserEffect.prototype, "deleteProfileBillingAddress$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], UserEffect.prototype, "patchEmail$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], UserEffect.prototype, "patchPassword$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], UserEffect.prototype, "resetPassword$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], UserEffect.prototype, "verifyEmail$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], UserEffect.prototype, "changePassword$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], UserEffect.prototype, "changeEmail$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], UserEffect.prototype, "requestPasswordRecovery$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], UserEffect.prototype, "DownloadTicketById$", void 0);
    return UserEffect;
}());
export { UserEffect };
