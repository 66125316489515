<div
  *ngIf="input.controlType === 'textbox' && input.key && !input.hidden"
  class="input-wrapper form-wrapper"
  [ngClass]="{
    'is-loading': isLoading,
    'questionnaire-wrapper': input.isQuestionnaire
  }"
>
  <h4 class="questionnaire-headline" *ngIf="input.isQuestionnaire">
    {{ input.label | translate | hideTranslation }}
  </h4>
  <div class="position-relative">
    <input
      *ngIf="
        input.key !== 'address' &&
        input.key !== 'company' &&
        input.key !== 'password' &&
        input.key !== 'verify-password' &&
        !input.isQuestionnaire &&
        input.key !== 'zipCode'
      "
      autocomplete="{{autoFillEmail ? 'email' : 'new-password'}}"
      [attr.data-translation]="input.label"
      [formControl]="form.controls[input.key]"
      [id]="formPath + '_' + input.key"
      [type]="input.type"
      [class.hasvalue]="form.controls[input.key].value"
      (input)="valueChanged$.next($event)"
      (change)="lostFocus(input.controlType, input.key, null, $event)"
      [attr.disabled]="input.disabled === true ? true : null"
    />

    <input
      *ngIf="input.key === 'zipCode'"
      autocomplete="new-password"
      class="{{zipCodeUniqueClass}}"
      [attr.data-translation]="input.label"
      [formControl]="form.controls[input.key]"
      [id]="formPath + '_' + input.key"
      [type]="input.type"
      [class.hasvalue]="form.controls[input.key].value"
      [attr.disabled]="input.disabled ? true : null"

      (input)="valueChanged$.next($event)"
      (focus)="showZipCodeCitiesDropdown(true, false, true, zipCodeUniqueClass)"
      (focusout)="showZipCodeCitiesDropdown(false, false, false, zipCodeUniqueClass)"
    />

    <div *ngIf="input.key === 'zipCode' && !!zipCodeCities && zipCodeCities.length > 1" [ngClass]="{'searchDropdown': !!zipCodeCities && zipCodeCities.length > 1, 'hideCitiesDropdown': !showZipCodeCities}">
      <div class="search-dropdown-content">
        <div *ngFor="let city of zipCodeCities" class="dropdown-item" (mousedown)="setSelectedCity(city)" title="{{city}}">
          <img src="../../../../assets/images/locationIcon.png">
          <strong> {{zipCode}} </strong>
          {{city}}
        </div>
      </div>
    </div>

    <input
      *ngIf="
        input.key !== 'address' &&
        input.key !== 'company' &&
        input.key !== 'password' &&
        input.key !== 'verify-password' &&
        input.isQuestionnaire
      "
      autocomplete="new-password"
      [attr.data-translation]="input.label"
      [formControl]="form.controls[input.key]"
      [id]="formPath + '_' + input.key"
      [type]="input.type"
      [class.hasvalue]="form.controls[input.key].value"
      (input)="valueChanged$.next($event)"
      (change)="lostFocus(input.controlType, input.key, null, $event)"
      [attr.disabled]="input.disabled === true ? true : null"
    />
    <ng-container *ngIf="input.key === 'password'">
      <input
        autocomplete="new-password"
        [attr.data-translation]="input.label"
        [formControl]="form.controls[input.key]"
        [id]="formPath + '_' + input.key"
        [type]="input.type"
        [class.hasvalue]="form.controls[input.key].value"
        (change)="lostFocus(input.controlType, input.key, null, $event)"
        [attr.disabled]="input.disabled === true ? true : null"
        [showPassword]="password"
      />
      <svg class="icon icon-key" #password>
        <use xlink:href="assets/defs.svg#icon-eye"></use>
      </svg>
    </ng-container>
    <ng-container *ngIf="input.key === 'verify-password'">
      <input
        autocomplete="new-password"
        [attr.data-translation]="input.label"
        [formControl]="form.controls[input.key]"
        [id]="formPath + '_' + input.key"
        [type]="input.type"
        [class.hasvalue]="form.controls[input.key].value"
        (change)="lostFocus(input.controlType, input.key, null, $event)"
        [attr.disabled]="input.disabled === true ? true : null"
        [showPassword]="verifyPassword"
      />
      <svg class="icon icon-key" #verifyPassword>
        <use xlink:href="assets/defs.svg#icon-eye"></use>
      </svg>
    </ng-container>
    <input
      *ngIf="input.key === 'address'"
      autocomplete="new-password"
      [attr.data-translation]="input.label"
      [formControl]="form.controls[input.key]"
      [id]="formPath + '_' + input.key"
      [type]="input.type ? input.type : 'search'"
      class="hide-placeholder"
      [ngClass]="{ hasvalue: form.controls[input.key].value }"
      (appAddressAutocomplete)="addressChange($event, 'address')"
      (isAddressAutocompleteLoading)="isLoading = $event"
      [searchType]="'geocode'"
      (keyup)="addressKeyup(input, $event)"
      [attr.disabled]="input.disabled === true ? true : null"
    />
    <input
      *ngIf="input.key === 'company'"
      autocomplete="new-password"
      [attr.data-translation]="input.label"
      [formControl]="form.controls[input.key]"
      [id]="formPath + '_' + input.key"
      [type]="input.type ? input.type : 'search'"
      class="hide-placeholder"
      (keyup)="addressKeyup(input, $event)"
      [ngClass]="{ hasvalue: form.controls[input.key].value }"
      (appAddressAutocomplete)="addressChange($event, 'company')"
      (isAddressAutocompleteLoading)="isLoading = $event"
      [searchType]="'establishment'"
      [attr.disabled]="input.disabled === true ? true : null"
    />
    <div
      *ngIf="
        (input.key === 'address' || input.key === 'company') &&
        !form.controls[input.key].value
      "
    >
      <svg class="lens-icon">
        <use xlink:href="assets/defs.svg#icon-lens"></use>
      </svg>
    </div>

    <label
      *ngIf="input.translate && !input.isQuestionnaire"
      class="placeholder"
      [attr.for]="formPath + '_' + input.key"
      [attr.data-translation]="input.label"
      [class.password-label]="
        input.key === 'password' || input.key === 'verify-password'
      "
      >{{ input.label | translate | hideTranslation }}</label
    >
    <label
      *ngIf="!input.translate && !input.isQuestionnaire"
      class="placeholder"
      [attr.for]="formPath + '_' + input.key"
      [innerHTML]="input.label | htmlInjector"
    ></label>
    <label
      *ngIf="input.placeholder && input.isQuestionnaire"
      class="placeholder questionnaire"
      [attr.for]="formPath + '_' + input.key"
    >
      {{ input.placeholder | translate }}
    </label>
  </div>

  <div
    class="input-error-wrapper"
    *ngIf="
      form.controls[input.key].errors &&
      form.controls[input.key].errors.maxlength
    "
  >
    <small
      [attr.data-translation]="'validation.input.max-length'"
      [translate]="'validation.input.max-length'"
      [translateParams]="{ maxLength: input.maxLengthValidation }"
    ></small>
  </div>
  <div
    class="input-error-wrapper"
    *ngIf="
      form.controls[input.key].errors &&
      form.controls[input.key].errors.minlength
    "
  >
    <small
      [attr.data-translation]="'validation.input.min-length'"
      [translate]="'validation.input.min-length'"
      [translateParams]="{ minLength: input.minLengthValidation }"
    ></small>
  </div>
  <div
    class="input-error-wrapper"
    *ngIf="
      form.controls[input.key].errors &&
      form.controls[input.key].errors.equalValue &&
      (form.controls[input.key].touched || form.controls[input.key].value)
    "
  >
    <small *ngIf="input.key === 'email' || input.key === 'password'"
      [attr.data-translation]="'validation.input.match-pair-' + input.key"
      [translate]="'validation.input.match-pair-' + input.key"
    ></small>
    <small *ngIf="input.key !== 'email' && input.key !== 'password'"
      [attr.data-translation]="'validation.input.match-pair'"
      [translate]="'validation.input.match-pair'"
      ></small>
  </div>

  <div
    class="input-error-wrapper"
    *ngIf="
      form.controls[input.key].errors &&
      form.controls[input.key].errors.required &&
      (form.controls[input.key].touched || form.controls[input.key].value)
    "
  >
    <small
      [attr.data-translation]="'validation.input.required'"
      [translate]="'validation.input.required'"
    ></small>
  </div>

  <div
    class="input-error-wrapper"
    *ngIf="
      form.controls[input.key].errors &&
      form.controls[input.key].errors.invalidPhoneNumber &&
      (form.controls[input.key].touched || form.controls[input.key].value)
    "
  >
    <small
      [attr.data-translation]="'validation.input.phone'"
      [translate]="'validation.input.phone'"
    ></small>
  </div>

  <div
    class="input-error-wrapper"
    *ngIf="
      form.controls[input.key].errors &&
      form.controls[input.key].errors.invalidZipCode &&
      (form.controls[input.key].touched || form.controls[input.key].value)
    "
  >
    <small
      [attr.data-translation]="'validation.input.zipcode'"
      [translate]="'validation.input.zipcode'"
    ></small>
  </div>

  <div
    class="input-error-wrapper"
    *ngIf="
      form.controls[input.key].errors &&
      form.controls[input.key].errors.invalidEmailAddress &&
      (form.controls[input.key].touched || form.controls[input.key].value)
    "
  >
    <small
      [attr.data-translation]="'validation.input.email'"
      [translate]="'validation.input.email'"
    ></small>
  </div>

  <div
    class="input-error-wrapper"
    *ngIf="
      form.controls[input.key].errors &&
      form.controls[input.key].errors.umlautCharacter &&
      (form.controls[input.key].touched || form.controls[input.key].value)
    "
  >
    <small
      [attr.data-translation]="'validation.input.email.umlaut-characters'"
      [translate]="'validation.input.email.umlaut-characters'"
    ></small>
  </div>
</div>

<div
  *ngIf="input.controlType === 'dropdown' && input.key && !input.hidden"
  class="input-wrapper form-wrapper"
  [class.questionnaire-wrapper]="input.isQuestionnaire"
>
  <h4 class="questionnaire-headline" *ngIf="input.isQuestionnaire">
    {{ input.label | translate | hideTranslation }}
  </h4>
  <div class="position-relative">
    <select
      [id]="formPath + '_' + input.key"
      [formControl]="form.controls[input.key]"
      [attr.data-translation]="'input.label'"
      (change)="newInputValue(input.controlType, input.key)"
      [class.hasvalue]="form.controls[input.key].value"
      [attr.disabled]="input.disabled === true ? true : null"
      (click)="selectClicked($event)"
    >
      <option value="" hidden>
        <!--  <span *ngIf="input.isQuestionnaire">
                {{ 'questionnaire.select.default' | translate | hideTranslation }} </span> -->
      </option>
      <option
        *ngFor="let opt of input.options"
        [value]="hasTextOrDropdownTypeOption ? (opt.value | translate) : opt.key"
        ><ng-container
          *ngIf="input.isQuestionnaire || opt.translate; else noTranslate"
        >
          {{ opt.value | translate }}
        </ng-container>
        <ng-template #noTranslate>
          {{ opt.value }}
        </ng-template>
      </option>
    </select>

    <label
      *ngIf="input.translate"
      class="placeholder"
      [attr.for]="formPath + '_' + input.key"
      [attr.data-translation]="input.label"
      >{{ input.label | translate | hideTranslation }}</label
    >
    <label
      *ngIf="!input.translate"
      class="placeholder"
      [attr.for]="formPath + '_' + input.key"
      [innerHTML]="input.label | htmlInjector"
    ></label>
  </div>
  <div
    class="input-error-wrapper"
    *ngIf="
      form.controls[input.key].errors &&
      form.controls[input.key].errors.required &&
      (form.controls[input.key].touched || form.controls[input.key].value)
    "
  >
    <small
      [attr.data-translation]="'validation.input.required'"
      [translate]="'validation.input.required'"
    ></small>
  </div>
</div>

<div
  *ngIf="input.controlType === 'radio' && input.key && !input.hidden"
  class="form-wrapper"
>
  <h4
    class="input-group-title questionnaire-headline"
    *ngIf="input.label && input.isQuestionnaire"
  >
    {{ input.label | translate | hideTranslation }}
  </h4>

  <div
    class="radio-button-row"
    [class.ng-invalid]="input.required && !form.controls[input.key].valid"
    [class.row-disabled]="input.disabled"
  >
    <div class="row">
      <div
        class="radio-button"
        *ngFor="let opt of input.options"
        [ngClass]="opt.cssClass"
      >
        <label
          class="decorated-radio-button"
          [attr.disabled]="input.disabled === true ? true : null"
        >
          <input
            type="radio"
            [ngClass]="{
              checked:
                opt.key.toString() == form.controls[input.key].value.toString()
            }"
            [value]="opt.key"
            [formControl]="form.controls[input.key]"
            (change)="newInputValue(input.controlType, input.key)"
            [attr.disabled]="input.disabled === true ? true : null"
          />
          <span class="radio-button"></span>
          <span *ngIf="input.isQuestionnaire" class="radio-label">{{
            opt.value | translate | hideTranslation
          }}</span>
          <span
            *ngIf="input.translate && !input.isQuestionnaire"
            class="radio-label"
            [attr.data-translation]="opt.value"
            >{{ opt.value | translate | hideTranslation }}</span
          >
          <span
            *ngIf="!input.translate && !input.isQuestionnaire"
            class="radio-label"
            [innerHTML]="opt.value | htmlInjector"
          ></span>
        </label>
      </div>
      <div
        class="input-error-wrapper"
        *ngIf="
          form.controls[input.key].errors &&
          form.controls[input.key].errors.required &&
          (form.controls[input.key].touched || form.controls[input.key].value)
        "
      >
        <small
          [attr.data-translation]="'validation.input.required'"
          [translate]="'validation.input.required'"
        ></small>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="input.controlType === 'checkbox' && input.key && !input.hidden"
  [formGroup]="form"
  class="form-wrapper no-validation-group"
>
  <div *ngIf="(input.text?.value | hideTranslation) && 
    input.text?.translation && 
    input.text?.position === 'prepend'"
    class="checkbox-text-row">
    <p [attr.data-translation]="input.text.translation"
      [appRichText]="input.text.value"
      data-translation-includes-children
      class="checkbox-text">
    </p>
  </div>
  <h4 class="input-group-title questionnaire-headline" *ngIf="input.label">
    {{ input.label | translate | hideTranslation }}
    <span
      class="questionnaire-subheadline"
      *ngIf="!!input.selectionLimit"
      [attr.data-translation]="'input.selectionLimit'"
      [translate]="'input.selectionLimit'"
      [translateParams]="{ selectionLimit: input.selectionLimit }"
    >
    </span>
  </h4>
  <div
    class="checkbox-button-row privacy-checkbox-button-row"
    [class.ng-invalid]="input.required && !form.controls[input.key].valid"
    [class.row-disabled]="input.disabled"
  >
    <div class="row">
      <div
        *ngFor="let opt of input.options"
        class="checkbox-button"
        [ngClass]="opt.cssClass"
      >
        <div
          [formGroupName]="input.key"
          class="decorated-checkbox no-validation-group"
        >
          <input
            type="checkbox"
            [required]="opt.required"
            [formControlName]="input.key + '_' + opt.key"
            (click)="checkCheckboxLimit(input.key, opt.key, $event)"
            (change)="newInputValue(input.controlType, input.key, opt.key)"
            id="{{ input.key + '_' + opt.key + '_' + formPath }}"
            [attr.disabled]="input.disabled === true ? true : null"
          />
          <label for="{{ input.key + '_' + opt.key + '_' + formPath }}"></label>
        </div>
        <svg class="checkbox-icon" *ngIf="opt.icon">
          <use [attr.xlink:href]="'assets/defs.svg#icon-' + opt.icon"></use>
        </svg>
        <div>
          <label
            *ngIf="input.isQuestionnaire"
            class="black checkbox-label"
            for="{{ input.key + '_' + opt.key + '_' + formPath }}"
          >
            <span>{{ opt.label | translate | hideTranslation }}</span>
          </label>
          <label
            *ngIf="input.translate && !input.isQuestionnaire"
            class="black checkbox-label"
            for="{{ input.key + '_' + opt.key + '_' + formPath }}"
          >
            <span [attr.data-translation]="opt.label">{{
              opt.label | translate | hideTranslation
            }}</span>
          </label>
          <label
            *ngIf="!input.translate && !input.isQuestionnaire"
            class="black checkbox-label"
            for="{{ input.key + '_' + opt.key + '_' + formPath }}"
            [innerHTML]="opt.label | htmlInjector"
          >
            <span></span>
          </label>
        </div>
      </div>
      <div
        class="input-error-wrapper"
        *ngIf="
          form.controls[input.key].errors &&
          form.controls[input.key].errors.required &&
          (form.controls[input.key].touched || form.controls[input.key].value)
        "
      >
        <small
          [attr.data-translation]="'validation.input.required'"
          [translate]="'validation.input.required'"
        ></small>
      </div>
    </div>
  </div>
  <div *ngIf="(input.text?.value | hideTranslation) && 
    input.text?.translation && 
    input.text?.position === 'append'"
    class="checkbox-text-row">
    <p [attr.data-translation]="input.text.translation"
      [appRichText]="input.text.value"
      data-translation-includes-children
      class="checkbox-text">
    </p>
  </div>
</div>

<div *ngIf="input.controlType === 'formButton' && !input.hidden">
  <button
    [disabled]="!form.valid"
    (click)="formSubmitButton(input.key)"
    class="form-button"
  >
    <span [attr.data-translation]="input.label">{{
      input.label | translate | hideTranslation
    }}</span>
  </button>
</div>

<div *ngIf="input.controlType === 'dateInput'" class="input-wrapper form-wrapper">
  <form [formGroup]="form" class="position-relative">
    <p-calendar 
    [id]="formPath + '_' + input.key"
    class="{{datePickerUniqueClass}}"
    placeholder="{{datePlaceHolder}}"
    autocomplete="new-password"
    formControlName="{{input.key}}"
    [attr.data-translation]="input.label"
    [class.hasvalue]="form.controls[input.key].value"
    [ngClass]="{'showCalendarButton': showCalendarButton, 'placeHolderShown': !!datePlaceHolder}"
    [attr.disabled]="input.disabled === true ? true : null"
    [inputStyle]="{'width':'100%'}"
    [style]="{'width': '100%'}"

    [monthNavigator]="true" 
    [yearNavigator]="true" 
    [minDate]="minDate"
    [maxDate]="maxDate"
    [showIcon]="showCalendarButton"
    yearRange="{{minYearRange}}:{{maxYearRange}}" 
    calendarCustomDefaults
    [readonlyInput]="true"

    (onFocus)="toggleDatePickerLabel(true, datePickerUniqueClass)"
    (onClose)="toggleDatePickerLabel(false, datePickerUniqueClass)"
    (onSelect)="lostFocus(input.controlType, input.key, null, $event)"
    >
    </p-calendar>
    <label
    *ngIf="input.translate"
    (click)="triggerDatePicker()"
    class="placeholder datePickerLabel {{datePickerUniqueClass}}"
    [attr.for]="formPath + '_' + input.key"
    [attr.data-translation]="input.label"
      >{{ input.label | translate | hideTranslation }}
    </label>
  </form>
</div>
