/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-window.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "../pipes/hide-translation/hide-translation.pipe";
import * as i5 from "./modal-window.component";
var styles_ModalWindowComponent = [i0.styles];
var RenderType_ModalWindowComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalWindowComponent, data: {} });
export { RenderType_ModalWindowComponent as RenderType_ModalWindowComponent };
function View_ModalWindowComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [], [[1, "data-translation", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(3, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.modalWindowTitle; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 1, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent, 0), i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.modalWindowTitle)))); _ck(_v, 1, 0, currVal_1); }); }
function View_ModalWindowComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.modalWindowTitle; _ck(_v, 1, 0, currVal_0); }); }
function View_ModalWindowComponent_4(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0), (_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_ModalWindowComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "modal-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "modal-container"]], [[2, "full-width", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.preventClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalWindowComponent_2)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalWindowComponent_3)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalWindowComponent_4)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵncd(null, 1), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "modal-buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [["class", "button-wrap"]], null, null, null, null, null)), i1.ɵncd(null, 2), (_l()(), i1.ɵeld(13, 0, null, null, 1, "div", [["class", "button-wrap modal-window-close"]], null, null, null, null, null)), i1.ɵncd(null, 3)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.translateTitle && !_co.isTitleWithParamTranslation); _ck(_v, 4, 0, currVal_1); var currVal_2 = (!_co.translateTitle && !_co.isTitleWithParamTranslation); _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.isTitleWithParamTranslation; _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fullWidth; _ck(_v, 1, 0, currVal_0); }); }
export function View_ModalWindowComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.HideTranslationPipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalWindowComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.modalWindowActive; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ModalWindowComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-window", [], null, null, null, View_ModalWindowComponent_0, RenderType_ModalWindowComponent)), i1.ɵdid(1, 49152, null, 0, i5.ModalWindowComponent, [], null, null)], null, null); }
var ModalWindowComponentNgFactory = i1.ɵccf("app-modal-window", i5.ModalWindowComponent, View_ModalWindowComponent_Host_0, { modalWindowActive: "modalWindowActive", modalWindowTitle: "modalWindowTitle", translateTitle: "translateTitle", fullWidth: "fullWidth", isTitleWithParamTranslation: "isTitleWithParamTranslation" }, {}, ["[modalWindow-titleWithParamTranslation]", "[modalWindow-body]", "[modalWindow-submit]", "[modalWindow-close]"]);
export { ModalWindowComponentNgFactory as ModalWindowComponentNgFactory };
