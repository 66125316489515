<ng-container *ngIf="ticketsWithHolders && ticketsWithHolders.length > 0">
  <div [ngStyle]="{ visibility: bookingLoading ? 'hidden' : 'visible' }">
    <div
      class="select-all"
      (click)="addWorkshopToAllHolders(workshop.workshopId)"
    >
      <div class="decorated-checkbox">
        <input
          type="checkbox"
          id="check_all_tickets"
          [checked]="assignAll"
          (click)="stopPropagation($event)"
        />
        <label for="check_all_tickets"></label>
      </div>
      <div>
        <label for="check_all_tickets">
          {{ 'ticket.assign-all' | translate }}
        </label>
      </div>
    </div>

    <div
      class="visitor"
      *ngFor="let ticket of ticketsWithHolders"
      (click)="
        addWorkshopToHolder(
          ticket.ticketHolderConfirmation.ticketIndex,
          workshop.workshopId,
          ticket.ticketHolderAdditionalData.workshops?.includes(
            workshop.workshopId
          ),
          $event
        )
      "
    >
      <ng-container
        *ngIf="ticket.allowedWorkshops?.includes(workshop.workshopId)"
      >
        <div class="visitor-container">
          <div class="decorated-checkbox">
            <input
              type="checkbox"
              [id]="
                'visitor_workshop_' +
                workshop.workshopId +
                '_' +
                ticket.ticketHolderConfirmation.ticketIndex
              "
              [checked]="
                ticket.ticketHolderAdditionalData.workshops?.includes(
                  workshop.workshopId
                )
              "
              (click)="stopPropagation($event)"
            />
            <label
              [attr.for]="
                'visitor_workshop_' +
                workshop.workshopId +
                '_' +
                ticket.ticketHolderConfirmation.ticketIndex
              "
            ></label>
          </div>
          <div>
            <label
              [attr.for]="
                'visitor_workshop_' +
                workshop.workshopId +
                '_' +
                ticket.ticketHolderConfirmation.ticketIndex
              "
            >
              <span
                *ngIf="
                  ticket.ticketHolderConfirmation.firstName &&
                  ticket.ticketHolderConfirmation.lastName
                "
                >{{ ticket.ticketHolderConfirmation.firstName }}
                {{ ticket.ticketHolderConfirmation.lastName }} -
              </span>
              {{ ticket.ticketGroupName | translate | hideTranslation }}
              {{ ticket.ticketName | translate | hideTranslation }}
            </label>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <app-modal-window
    [modalWindowActive]="isWorkshopLimitPerTicketReached"
    translateTitle="true"
    [modalWindowTitle]="'workshop.limitReached.modalWindow.title'"
  >
    <button
      class="button button-option"
      modalWindow-close
      (click)="closeModalWindow($event)"
    >
      <span data-translation="workshop.modalWindow.close">{{
        'workshop.modalWindow.close' | translate | hideTranslation
      }}</span>
    </button>
  </app-modal-window>

  <app-modal-window
    [modalWindowActive]="workshopsOverlap"
    translateTitle="true"
    [modalWindowTitle]="'workshop.modalWindow.title'"
  >
    <button
      class="button button-option"
      modalWindow-close
      (click)="closeModalWindow($event)"
    >
      <span data-translation="workshop.modalWindow.close">{{
        'workshop.modalWindow.close' | translate | hideTranslation
      }}</span>
    </button>
  </app-modal-window>

  <app-loader *ngIf="bookingLoading" isFixed="true"></app-loader>
</ng-container>
