import * as colorizerActions from './colorizer.actions';
import * as fromRoot from './../app.reducer';

import { Action, Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  CssSkinModel,
  ExhibitionCustomStyles
} from '../shared/services-with-reducers/customization/customization.interfaces';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Observable, EMPTY as empty, of } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';

import { ColorizerService } from './colorizer.service';
import { StyleTemplateModel } from './colorizer.interface';
import { generateSkinCss } from '../shared/services-with-reducers/customization/customization.styles';

export const COLORIZER_DEBOUNCE = new InjectionToken<number>(
  'Colorizer Debounce'
);

@Injectable()
export class ColorizerEffect {
  @Effect()
  loadAllStyles$: Observable<Action> = this.actions$.pipe(
    ofType<colorizerActions.GetAllStyles>(
      colorizerActions.ActionTypes.GET_ALL_STYLES
    ),
    debounceTime(this.debounce),
    switchMap(() => {
      return this._colorizerService.loadAllEventStyles().pipe(
        map(
          (allStyles: StyleTemplateModel[]) =>
            new colorizerActions.SetAllStyles(allStyles)
        ),
        catchError(() => of(new colorizerActions.SetAllStyles([])))
      );
    })
  );

  @Effect()
  loadStyle$: Observable<Action> = this.actions$.pipe(
    ofType<colorizerActions.LoadStyle>(colorizerActions.ActionTypes.LOAD_STYLE),
    debounceTime(this.debounce),
    switchMap((data: any) => {
      const styleId = data.payload;
      return this._colorizerService.loadEventStyle(styleId).pipe(
        map((styleProperties: any) => {
          const cssString = generateSkinCss(styleProperties.styles);
          const cssSkin: CssSkinModel = {
            priority: 5,
            css: cssString,
            variables: styleProperties.styles,
            title: styleProperties.title,
            id: styleId
          };

          this._store.dispatch(new colorizerActions.SetStyleId(styleId));

          return new colorizerActions.SetSelectedStyle(cssSkin);
        }),
        catchError(() => of(new colorizerActions.SetSelectedStyle(null)))
      );
    })
  );

  @Effect()
  updateStyle$: Observable<Action> = this.actions$.pipe(
    ofType<colorizerActions.UpdateStyle>(
      colorizerActions.ActionTypes.UPDATE_STYLE
    ),
    debounceTime(this.debounce),
    switchMap((data: any) => {
      return this._colorizerService
        .updateEventStyle(
          { styles: data.payload.styles, title: data.payload.title },
          data.payload.styleId
        )
        .pipe(
          map((statusCode: number) => {
            return new colorizerActions.SaveSuccess(true);
          }),
          catchError(() => of(new colorizerActions.SaveSuccess(false)))
        );
    })
  );

  @Effect()
  saveEventStyles$: Observable<Action> = this.actions$.pipe(
    ofType<colorizerActions.SaveEventStyles>(
      colorizerActions.ActionTypes.SAVE_EVENT_STYLES
    ),
    debounceTime(this.debounce),
    switchMap((data: any) => {
      if (!data.payload) return Observable.empty();
      return this._colorizerService.saveEventStyles(data.payload).pipe(
        map((statusCode: number) => {
          return new colorizerActions.SaveSuccess(true);
        }),
        catchError(() => of(new colorizerActions.SaveSuccess(false)))
      );
    })
  );

  @Effect()
  createEventStyles$: Observable<Action> = this.actions$.pipe(
    ofType<colorizerActions.CreateStyle>(
      colorizerActions.ActionTypes.CREATE_STYLE
    ),
    debounceTime(this.debounce),
    switchMap((data: any) => {
      if (!data.payload) return Observable.empty();
      return this._colorizerService.createEventStyle(data.payload).pipe(
        map((responseData: any) => {
          this._store.dispatch(
            new colorizerActions.AddStyleToList(responseData)
          );

          return new colorizerActions.SaveSuccess(true);
        }),
        catchError(() => of(new colorizerActions.SaveSuccess(false)))
      );
    })
  );

  @Effect()
  saveOperatorStyles$: Observable<Action> = this.actions$.pipe(
    ofType<colorizerActions.SaveOperatorStyles>(
      colorizerActions.ActionTypes.SAVE_OPERATOR_STYLES
    ),
    debounceTime(this.debounce),
    switchMap((data: any) => {
      if (!data.payload) return Observable.empty();
      return this._colorizerService.saveOperatorStyles(data.payload).pipe(
        map((statusCode: number) => new colorizerActions.SaveSuccess(true)),
        catchError(() => of(new colorizerActions.SaveSuccess(false)))
      );
    })
  );

  constructor(
    private _colorizerService: ColorizerService,
    private actions$: Actions,
    @Optional()
    @Inject(COLORIZER_DEBOUNCE)
    private debounce: number = 50,
    private _store: Store<fromRoot.State>
  ) {}
}
