import * as additionalServicesActions from '../../../shared/services-with-reducers/additional-services/additional-services.actions';
import * as fromRoot from '../../../app.reducer';
import * as stepsFormsActions from '../../../shared/services-with-reducers/step-forms/steps-forms.actions';

import { AssignWorkshopToHolderModel } from '../../../shared/services-with-reducers/step-forms/step.interface';
import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import {
  Subscription,
  combineLatest as observableCombineLatest,
  Subject,
  from,
  empty
} from 'rxjs';
import {
  WorkshopBookingPostModel,
  WorkshopModel,
  AddWorkshopBookingWithHolderModel
} from '../../../shared/services-with-reducers/additional-services/additional-services.interface';
import { filter, first, concatMap } from 'rxjs/operators';

import { StatusBarService } from '../../../status-bar/status-bar.service';
import { Store, ActionsSubject } from '@ngrx/store';
import { TicketSubgroupModel } from '../../../shared/services-with-reducers/tickets/ticket.interface';
import { TranslateService } from '@ngx-translate/core';
import { ofType } from '@ngrx/effects';

@Component({
  selector: 'app-workshops-visitors',
  templateUrl: './workshops-visitors.component.html',
  styleUrls: ['./workshops-visitors.component.scss']
})
export class WorkshopsVisitorsComponent
  implements OnChanges, OnInit, OnDestroy {
  @Input()
  ticketsWithHolders: TicketSubgroupModel[];
  @Input()
  workshop: WorkshopModel;
  @Input()
  workshopsList: WorkshopModel[];
  @Input()
  limitWorkshopPerTicket: number;
  @Input()
  enableWorkshopSelectionOverlapping: boolean;

  public noOfAssignedVisitors = 0;
  public bookingLoading = false;
  public isWorkshopLimitPerTicketReached: boolean = false;
  public workshopsOverlap: boolean = false;
  public assignAll: boolean = false;

  private _subscriptions = new Subscription();

  constructor(
    private _store: Store<fromRoot.State>,
    private _translateService: TranslateService,
    private _statusBarService: StatusBarService,
    private _actionSubject: ActionsSubject
  ) {}

  ngOnChanges() {
    this.noOfAssignedVisitors = this.getWorkshopAttendeesNumber(
      this.ticketsWithHolders,
      this.workshop
    );
  }

  ngOnInit() {
    this._subscriptions.add(
      this._store
        .select(fromRoot.isWorkshopBookingsFull)
        .pipe(filter(data => !!data))
        .subscribe(workshopIsFull => {
          if (
            workshopIsFull.isFull &&
            workshopIsFull.workshopId === this.workshop.workshopId
          ) {
            this.bookingLoading = false;

            this._store.dispatch(
              new additionalServicesActions.AddWorkshopBookingFull({
                isFull: false,
                workshopId: this.workshop.workshopId
              })
            );
          }
        })
    );

    this._subscriptions = this._actionSubject
      .pipe(
        ofType(additionalServicesActions.ActionTypes.AFTER_ADD_WORKSHOP_BOOKING)
      )
      .subscribe((data: any) => {
        this.processAfterAddBooking(data.payload);
      });

    this.selectAllTickets();
  }

  processAfterAddBooking(dataForCompareWorkshops) {
    observableCombineLatest(this._store.select(fromRoot.getWorkshopBookings))
      .first()
      .subscribe(data => {
        const [workshopBookingsModel] = data;

        workshopBookingsModel.bookings.map(workshop => {
          if (workshop.workshopId === this.workshop.workshopId) {
            this._store.dispatch(
              new stepsFormsActions.assignWorkshopToHolder({
                workshopToHolder: dataForCompareWorkshops,
                workshopLimit: this.limitWorkshopPerTicket
              })
            );
          }
        });

        this.bookingLoading = false;
        if (dataForCompareWorkshops) {
          dataForCompareWorkshops.subject.complete();
        }
      });
  }
  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  getWorkshopAttendeesNumber(ticketsWithHolders, workshop: WorkshopModel) {
    return ticketsWithHolders.reduce((agr, curr) => {
      return (agr += Number(
        curr.ticketHolderAdditionalData.workshops.includes(workshop.workshopId)
      ));
    }, 0);
  }

  stopPropagation(event: Event) {
    event.stopPropagation();
    event.preventDefault();
  }

  closeModalWindow(event) {
    event.stopPropagation();

    if (this.workshopsOverlap) {
      this.assignAll = false;
    }

    this.bookingLoading = false;
    this.workshopsOverlap = false;
    this.isWorkshopLimitPerTicketReached = false;
  }

  addWorkshopToHolder(
    ticketHolderNumber: number,
    workshopId: number,
    isAlreadyChecked: boolean,
    event?: Event
  ): Promise<any> {
    const processDoneSubject$: Subject<any> = new Subject();

    if (event) {
      this.assignAll = false;
    }

    //SEATS CHANGE
    if (!this.bookingLoading) {
      let seatsLeft = this.workshop.seats;
      const dataForCompareWorkshops: AssignWorkshopToHolderModel = {
        ticketHolderNumber: ticketHolderNumber,
        workshopId: workshopId,
        isAdded: isAlreadyChecked,
        subject: processDoneSubject$
      };

      this._store
        .select(fromRoot.getTicketHolderAdditionalData)
        .pipe(first())
        .subscribe(ticketHolderDatas => {
          const workshopLimitReached: boolean =
            ticketHolderDatas[ticketHolderNumber].workshopLimitReached;

          this.isWorkshopLimitPerTicketReached = !isAlreadyChecked
            ? workshopLimitReached
            : false;

          // reset checkall if limit reached
          if (workshopLimitReached) {
            this.assignAll = false;
          }

          this.compareWorkshops(
            this.workshopsList,
            dataForCompareWorkshops,
            ticketHolderDatas
          );
        });

      this._store
        .select(fromRoot.getWorkshopBookings)
        .pipe(
          filter(data => !!data),
          first()
        )
        .subscribe(bookings => {
          bookings.bookings.map(booking => {
            if (booking.workshopId === this.workshop.workshopId) {
              seatsLeft = booking.seats;
            }
          });
        });

      if (
        !this.workshopsOverlap &&
        !this.isWorkshopLimitPerTicketReached &&
        (seatsLeft || isAlreadyChecked)
      ) {
        this.bookingLoading = true;

        // first check the api and book the workshop seat until order is finished
        observableCombineLatest(
          this._store.select(fromRoot.getSelectedExhibitionId),
          this._store.select(fromRoot.getOrderUuid)
        )
          .first()
          .subscribe((data: [number, string]) => {
            let postData: WorkshopBookingPostModel = {
              eventId: data[0],
              workshopId,
              seats: isAlreadyChecked ? -1 : 1, // if already selected, remo the booking
              uuid: data[1],
              ticketHolderId: ticketHolderNumber
            };
            this._store.dispatch(
              new additionalServicesActions.PostWorkshopBooking(
                postData,
                dataForCompareWorkshops
              )
            );
          });
      } else if (
        !this.workshopsOverlap &&
        !this.isWorkshopLimitPerTicketReached
      ) {
        event && event.preventDefault();

        this._translateService
          .get('response.workshopFull')
          .subscribe((translation: string) => {
            this._statusBarService.setStatus(translation, 'error');
          });
      }
    }

    const processPromise$ = processDoneSubject$.toPromise();

    return processPromise$;
  }

  addWorkshopToAllHolders(workshopId: number) {
    this.assignAll = !this.assignAll;

    const allowedTickets = this.getAllowedTicketsForWorkshop();

    const ticketsToProcessSource$ = from(allowedTickets);

    const ticketsToProcessEmitter$ = ticketsToProcessSource$.pipe(
      // process tickets one-by-one to prevent issues with out-of-order responses
      concatMap((ticket: any) => {
        if (
          this.ticketAlreadyAssigned(ticket) !== this.assignAll &&
          !this.workshopsOverlap
        ) {
          return this.addWorkshopToHolder(
            ticket.ticketHolderConfirmation.ticketIndex,
            workshopId,
            !this.assignAll
          );
        } else {
          return empty();
        }
      })
    );

    this._subscriptions.add(ticketsToProcessEmitter$.subscribe());
  }

  getAllowedTicketsForWorkshop(): Array<Object> {
    return this.ticketsWithHolders.filter((ticket: any) => {
      const allowedWorkshops = ticket.allowedWorkshops;

      return (
        allowedWorkshops && allowedWorkshops.includes(this.workshop.workshopId)
      );
    });
  }

  selectAllTickets() {
    const allowedTickets = this.getAllowedTicketsForWorkshop();

    const unassignedTicket: Object = allowedTickets.find(
      item => !this.ticketAlreadyAssigned(item)
    );

    if (!unassignedTicket) {
      this.addWorkshopToAllHolders(this.workshop.workshopId);
    }
  }

  ticketAlreadyAssigned(ticket: any): boolean {
    const workshops: Array<Object> =
      ticket.ticketHolderAdditionalData.workshops;

    return workshops ? workshops.includes(this.workshop.workshopId) : false;
  }

  getFullDate(date) {
    const newDate = new Date(date);

    const day = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();

    return day + '/' + month + '/' + year;
  }

  compareWorkshops(
    workshops: WorkshopModel[],
    data: AssignWorkshopToHolderModel,
    ticketHolderDatas
  ) {
    this.workshopsOverlap = false;
    const userWorkshops = workshops.filter(workshop => {
      return ticketHolderDatas[data.ticketHolderNumber].workshops.includes(
        workshop.workshopId
      );
    });
    const selectedWorkshop = workshops.find(workshop => {
      return workshop.workshopId === data.workshopId;
    });

    if (this.enableWorkshopSelectionOverlapping) {
      return false;
    }

    return (this.workshopsOverlap =
      this.workshopsOverlap ||
      userWorkshops.reduce((acc, wokrshop) => {
        return acc || this.areWorkshopsOverlaping(wokrshop, selectedWorkshop);
      }, false));
  }

  areWorkshopsOverlaping(workshopA: WorkshopModel, workshopB: WorkshopModel) {
    if (workshopA && workshopB && workshopA !== workshopB) {
      // const dateWorkshopA = new Date(workshopA.date).toLocaleDateString(
      //   'en-US'
      // );
      // const dateWorkshopB = new Date(workshopA.date).toLocaleDateString(
      //   'en-US'
      // );

      const dateWorkshopA = this.getFullDate(workshopA.date);
      const dateWorkshopB = this.getFullDate(workshopB.date);

      if (dateWorkshopA === dateWorkshopB) {
        const startWorkshopA = new Date(
          new Date().toDateString() +
            ' ' +
            new Date(workshopA.start).toTimeString()
        );
        const startWorkshopB = new Date(
          new Date().toDateString() +
            ' ' +
            new Date(workshopB.start).toTimeString()
        );
        const endWorkshopA = new Date(
          new Date().toDateString() +
            ' ' +
            new Date(workshopA.end).toTimeString()
        );
        const endWorkshopB = new Date(
          new Date().toDateString() +
            ' ' +
            new Date(workshopB.end).toTimeString()
        );

        if (
          // check if worlshopA start or end is inside other workshop
          (startWorkshopA >= startWorkshopB && startWorkshopA < endWorkshopB) ||
          (endWorkshopA <= endWorkshopB && endWorkshopA > startWorkshopB) ||
          // check the same for worlshopB
          ((startWorkshopB >= startWorkshopA &&
            startWorkshopB < endWorkshopA) ||
            (endWorkshopB <= endWorkshopA && endWorkshopB > startWorkshopA))
        ) {
          return true;
        }
      }
    }
    return false;
  }
}
