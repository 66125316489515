/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./info-block.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./info-block.component";
var styles_InfoBlockComponent = [i0.styles];
var RenderType_InfoBlockComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InfoBlockComponent, data: {} });
export { RenderType_InfoBlockComponent as RenderType_InfoBlockComponent };
export function View_InfoBlockComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "info-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, ":svg:svg", [["class", "icon icon-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, ":svg:use", [[":xlink:href", "assets/defs.svg#icon-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "info-text"]], [[1, "data-translation", 0]], null, null, null, null)), i1.ɵdid(4, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"], translateParams: [1, "translateParams"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.infoText; var currVal_2 = _co.translateVariables; _ck(_v, 4, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.infoText; _ck(_v, 3, 0, currVal_0); }); }
export function View_InfoBlockComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-info-block", [], null, null, null, View_InfoBlockComponent_0, RenderType_InfoBlockComponent)), i1.ɵdid(1, 49152, null, 0, i3.InfoBlockComponent, [], null, null)], null, null); }
var InfoBlockComponentNgFactory = i1.ɵccf("app-info-block", i3.InfoBlockComponent, View_InfoBlockComponent_Host_0, { infoText: "infoText", translateVariables: "translateVariables" }, {}, []);
export { InfoBlockComponentNgFactory as InfoBlockComponentNgFactory };
