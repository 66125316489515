import * as helperActions from './helper.actions';

import { Actions, Effect, ofType } from '@ngrx/effects';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Observable, EMPTY as empty, of } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';

import { Action } from '@ngrx/store';
import { HelperService } from './helper.service';
import { TranslationsModel } from './helper.interface';

export const HELPER_DEBOUNCE = new InjectionToken<number>('Helper Debounce');

@Injectable()
export class HelperEffect {
  @Effect()
  LoadAndSetTranslations$: Observable<Action> = this.actions$.pipe(
    ofType<helperActions.LoadAndSetTranslationsAction>(
      helperActions.ActionTypes.LOAD_AND_SET_TRANSLATIONS
    ),
    debounceTime(this.debounce),
    switchMap((data: any) => {
      const { lang, eventId } = data.payload;
      return this.helperService.loadAndMergeTranslations(lang, eventId).pipe(
        map((translations: { [key: string]: string }) => {

          return new helperActions.SetTranslations({
            language: lang,
            values: translations
          });
        }),
        catchError(() => of(new helperActions.SetTranslations(null)))
      );
    })
  );

  @Effect()
  loadAllCountries$: Observable<Action> = this.actions$.pipe(
    ofType<helperActions.GetAllCountriesAction>(
      helperActions.ActionTypes.GET_ALL_COUNTRIES
    ),
    debounceTime(this.debounce),
    switchMap(() => {
      return this.helperService.getListOfCountries().pipe(
        map(
          (allCountries: string[]) =>
            new helperActions.AddAllCountriesToListAction(allCountries)
        ),
        catchError(() => of(new helperActions.AddAllCountriesToListAction([])))
      );
    })
  );

  constructor(
    private actions$: Actions,
    private helperService: HelperService,
    @Optional()
    @Inject(HELPER_DEBOUNCE)
    private debounce: number = 50
  ) {}
}
