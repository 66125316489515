import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from './../app-shared/shared.module';

import {
  WidgetRoutingModule,
  WidgetComponentsWithRoutes
} from './widget-routing.module';
import { WidgetBannerComponent } from './widget-components-without-routes';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    WidgetRoutingModule
  ],
  declarations: [WidgetComponentsWithRoutes, WidgetBannerComponent],
  exports: [WidgetBannerComponent]
})
export class WidgetModule {}
