import * as customizationActions from '../../../shared/services-with-reducers/customization/customization.actions';
import * as fromRoot from '../../../app.reducer';
import * as ticketActions from '../../../shared/services-with-reducers/tickets/ticket.actions';
import { StatusBarService } from '../../../../app/status-bar/status-bar.service';

import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import {
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  ExhibitionSettingModel,
  SponsorBannerModel
} from '../../../shared/services-with-reducers/customization/customization.interfaces';
import { Subscription, combineLatest as observableCombineLatest, Observable } from 'rxjs';
import {
  RedeemVoucherModel,
  TicketByIdModel,
  ProductGroupModel,
  TicketGroupTicketModel,
  TicketModel
} from '../../../shared/services-with-reducers/tickets/ticket.interface';

import { ActivatedRoute } from '@angular/router';
import { CustomizationService } from '../../../shared/services-with-reducers/customization/customization.service';
import { HelperService } from '../../../shared/services-with-reducers/helpers/helper.service';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { first } from 'rxjs/operators';
import { TicketsService } from '../../../shared/services-with-reducers/tickets/tickets.service';

const ERROR_TICKET_WARNING = 'ticket-selection.warning';

@Component({
  moduleId: module.id,
  selector: 'app-voucher',
  templateUrl: './voucher.component.html',
  styleUrls: ['./voucher.component.scss']
})
export class VoucherComponent implements OnInit, OnDestroy {
  @Input()
  type: string;
  @Input() maxTicketLimit: number;
  @Input()
  set numberOfAllTickets(value: number) {
    this.numberOfSelectedTickets = value;
    if (value < this.maxTicketLimit) {
      this.ticketLimitWarning = '';
    }
  }
  @Input() clearVoucherInput$: Observable<boolean> = new Observable<boolean>();

  public voucherCode: string;
  public voucherTickets: TicketByIdModel[] = [];
  public loading = false;
  private _subscriptions = new Subscription();
  public isSelfRegistrationEnabled: boolean;
  public barcode = '';
  public voucherTypingActive = false;
  public lastPressedKey: number = null;
  public ticketLimitWarning = '';
  private numberOfSelectedTickets: number;
  public isAnonymousAndMandatory: boolean = false;
  public isAnonymousWithPrice: boolean = false;
  voucherForm: FormGroup;
  voucherInput: AbstractControl;
  public currentVoucherTypeMandatory: boolean = false;
  public isAnonymousTicketTaken: boolean = false;
  public showVoucherWarning: boolean = false;
  public invalidVoucherWarning: string = '';

  public scanImageUrl: string = 'assets/scan.png';

  constructor(
    public route: ActivatedRoute,
    private _customizationService: CustomizationService,
    private _store: Store<fromRoot.State>,
    private _translateService: TranslateService,
    private _helperService: HelperService,
    private _formBuilder: FormBuilder,
    private _ticketsService: TicketsService,
    private _statusBarService: StatusBarService
  ) { }
  @HostListener('document:keydown', ['$event'])
  /*   scan(e) {
    //The scanner sends first CTL-B and at the end CTRL-J
    //"17 66 65 65 17 74" or: "ctrl B A A ctrl J"

    const code = e.keyCode ? e.keyCode : e.which;

    if (code == 16) return; // ignore shift

    if (code === 66 && this.lastPressedKey === 17) {
      this.voucherTypingActive = true;
    } else if (code === 74 && this.lastPressedKey === 17) {
      this.voucherTypingActive = false;
      this.voucherCode = this.barcode.substr(0, this.barcode.length - 1);
      this.onRedeem(this.voucherCode);
      this.barcode = '';
    } else if (this.voucherTypingActive) {
      this.barcode = this.barcode + String.fromCharCode(code);
    }

    this.lastPressedKey = code;
  } */
  scan(e) {
    const code = e.keyCode ? e.keyCode : e.which;
    this.isAnonymousAndMandatory = false;
    this.isAnonymousWithPrice = false;
    this.showVoucherWarning = false;

    if (code === 16 || code === 17) return; // don't add shift && ctrl to barcode!

    if (e.ctrlKey && code === 66) {
      this.voucherTypingActive = true;
    } else if (e.ctrlKey && code === 74) {
      this.voucherCode = this.barcode;
      this.barcode = '';
      this.voucherTypingActive = false;
      this.onRedeem(this.voucherCode);
    } else if (this.voucherTypingActive) {
      if (e.shiftKey) {
        this.barcode = this.barcode + String.fromCharCode(code).toUpperCase();
      } else {
        this.barcode = this.barcode + String.fromCharCode(code).toLowerCase();
      }
    }
  }

  ngOnInit() {
    this.clearVoucherInput$.subscribe(item => {
      if (this.showVoucherWarning) {
        this.showVoucherWarning = false;
        this.voucherCode = '';
      }
    })

    this.voucherForm = this._formBuilder.group({
      voucherInput: ['', Validators.maxLength(50)]
    });

    this.voucherInput = this.voucherForm.controls['voucherInput'];

    if (!this.showVoucherWarning) {
      this.showVoucherWarning = false;
      this.voucherCode = '';
    }

    this._subscriptions.add(
      this.voucherInput.valueChanges.subscribe(() => {
        if (!(this.isAnonymousTicketTaken && this.currentVoucherTypeMandatory)) {
          this.isAnonymousAndMandatory = false;
          this.isAnonymousWithPrice = false;
          this.showVoucherWarning = false;
        }
      })
    );

    this._subscriptions.add(
      this._store
        .select(fromRoot.getVoucherTickets)
        .subscribe(voucherTickets => {
          this.voucherTickets = voucherTickets;

          if (!this.showVoucherWarning) {
            this.showVoucherWarning = false;
            this.voucherCode = '';
          }

          this.loading = false;

          if (
            voucherTickets.length &&
            voucherTickets[0].hasOwnProperty('sponsors') &&
            voucherTickets[0].sponsors.length
          ) {
            if (
              'styles' in voucherTickets[0].sponsors[0] &&
              voucherTickets[0].sponsors[0]['sponsors']
            ) {
              this._customizationService.setVoucherStyles(
                voucherTickets[0].sponsors[0].styles
              );
            }

            // set new sponsor banner
            if ('sponsorBanner' in voucherTickets[0].sponsors[0]) {
              if (voucherTickets[0].sponsors[0].sponsorBanner) {
                this._store
                  .select(fromRoot.getSponsorBanner)
                  .pipe(first())
                  .subscribe((banner: SponsorBannerModel) => {
                    const newBanner = Object.assign({}, banner, {
                      sponsorBanner: voucherTickets[0].sponsors[0].sponsorBanner
                    });
                    this._store.dispatch(
                      new customizationActions.SetRandomSponsor(newBanner)
                    );
                  });
              }
            }

            // set new exhibition banner
            if ('eventBanner' in voucherTickets[0].sponsors[0]) {
              if (voucherTickets[0].sponsors[0].eventBanner) {
                this._store
                  .select(fromRoot.getExhibitionSettings)
                  .pipe(first())
                  .subscribe((settings: ExhibitionSettingModel) => {
                    const modifiedSettings = Object.assign({}, settings, {
                      eventBanner: voucherTickets[0].sponsors[0].eventBanner
                    });
                    this._store.dispatch(
                      new customizationActions.SetExhibitionSettings(
                        modifiedSettings
                      )
                    );
                  });
              }
            }
          }
        })
    );

    // check if a voucher came by URL as get param
    this._subscriptions.add(
      this.route.queryParams.subscribe(params => {
        if (params.voucher) {
          this.onRedeem(params.voucher);
          /*         this._store
          .select(fromRoot.isUserLoggedIn)
          .first()
          .subscribe(isUserLogged => {
            const actionPayload = {
              formInfo: ['tickets', 'login'],
              valid: isUserLogged
            };

            this._store.dispatch(
              new stepsActions.SetFormValidity(actionPayload)
            );
          }); */
        }
        this.isSelfRegistrationEnabled = this._helperService.isSelfregistration();
      })
    );

    this._subscriptions.add(
      this._store
        .select(fromRoot.getSelectedExhibitionId)
        .subscribe(eventId => {
          this.scanImageUrl =
            environment.protocol +
            environment.webApiUrl +
            '/event/' +
            eventId +
            '/scan-image';
        })
    );

    this._subscriptions.add(
      this._translateService
        .stream('response.voucherNotValid')
        .subscribe(translatedInvalidVoucherWarning => this.invalidVoucherWarning = translatedInvalidVoucherWarning)
    );
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  showInvalidVoucherStatusMessage() {
    const tzOffset = (new Date()).getTimezoneOffset() * 60000;
    const localISOTime = (new Date(Date.now() - tzOffset)).toISOString().slice(0, -1);
    const dateTime = localISOTime.substring(0, localISOTime.indexOf('.')).replace('T', ' ');

    this._statusBarService.setStatus(this.invalidVoucherWarning, 'error', null, `[${dateTime}]`);
  }

  checkIfAnonVouchTakenAndSelTickMandatory(voucherTicketRaw, ungroupedTickets: TicketModel, productGroups: ProductGroupModel[]): boolean {
    // set current voucher ticket id
    const voucherTicketRawId = `${voucherTicketRaw['groupId']}_${voucherTicketRaw['ticketTypeId']}`;
    const voucherProductGroups = !!productGroups && productGroups.filter(productGroup => productGroup.groupId === voucherTicketRaw['groupId']);

    if (!voucherProductGroups) {
      this.showInvalidVoucherStatusMessage();
      return true;
    }

    const voucherProductGroup = voucherProductGroups.find(productGroup => productGroup.products.some(product => { 
      if (product.ticket != null) {
        return product.ticket.personTypeId == voucherTicketRaw['ticketTypeId'] && product.ticket.isVoucher;
      }
    }));

    if (!voucherProductGroup) {
      this.showInvalidVoucherStatusMessage();
      return true;
    }

    // get tickets and ticketTypes so we can know if anonymous voucher is taken, and what kind of voucher we're processing now
    this.isAnonymousTicketTaken = this._ticketsService.checkIfAnonymousTicketTaken(ungroupedTickets);

    // if there is anonymous ticket taken check with what kind of voucher are we dealing right now
    if (this.isAnonymousTicketTaken) {
      this.currentVoucherTypeMandatory = voucherProductGroup.products.find(productGroup => productGroup.ticket.uniqueId === voucherTicketRawId).ticket.personalizationType === "mandatory";
    }

    // if there is anonymous ticket taken with price
    const product = voucherProductGroup.products.find(productGroup => productGroup.ticket.uniqueId === voucherTicketRawId && productGroup.ticket.price > 0);
    if (!!product) {
      this.isAnonymousWithPrice = product.ticket.personalizationType === "anonymous";
    }

    if (this.isAnonymousWithPrice) {
      this.showVoucherWarning = true;
      return this.isAnonymousWithPrice = true;
    }
    // if there is an anonymous ticket taken and if current voucher that is being redeemed is mandatory, return true 
    if ((this.isAnonymousTicketTaken && this.currentVoucherTypeMandatory)) {
      this.showVoucherWarning = true;
      return this.isAnonymousAndMandatory = true;
    }

    return false;
  }

  onRedeem(code: string) {
    if (code) {

      if (this.maxTicketLimit !== undefined && this.numberOfSelectedTickets !== undefined &&
        this.numberOfSelectedTickets === this.maxTicketLimit) {
        this.ticketLimitWarning = ERROR_TICKET_WARNING;
        return;
      }

      code = code.trim();
      // in case the voucher is not active yet
      if (!this.voucherTickets.find(voucher => voucher.voucherCode === code)) {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
        }, 2000);

        observableCombineLatest([
          this._store.pipe(select(fromRoot.getSelectedExhibitionId)),
          this._store.pipe(select(fromRoot.getOrderUuid)),
          this._store.pipe(select(fromRoot.getTickets)),
          this._store.pipe(select(fromRoot.getTicketsTypes))
        ])
          .pipe(first())
          .subscribe(([eventId, uuid, tickets, ticketTypes]) => {
            const releaseVouchers = [];

            if (
              this.isSelfRegistrationEnabled &&
              !!this.voucherTickets &&
              this.voucherTickets.length
            ) {
              this.voucherTickets.forEach(voucherTicket => {
                const releaseVoucher: RedeemVoucherModel = {
                  eventId: eventId,
                  voucherCode: voucherTicket.voucherCode,
                  countryCode: this._translateService.currentLang,
                  uuid,
                  ticketPersonId: voucherTicket.ticketPersonId
                };

                releaseVouchers.push(releaseVoucher);
                /* this._store.dispatch(
                  new ticketActions.ReleaseVoucher(releaseVoucher)
                ); */
              });
            }

            const redeemVoucher: RedeemVoucherModel = {
              eventId: eventId,
              voucherCode: code,
              countryCode: this._translateService.currentLang,
              uuid,
              ticketPersonId: null
            };

            this._ticketsService.getVoucherDetails(redeemVoucher).subscribe(voucherDetails => {
              if (this.checkIfAnonVouchTakenAndSelTickMandatory(voucherDetails, tickets, ticketTypes)) {
                this.loading = false;
                return;
              }

              //check if current voucher is for anonymous ticket:
              const voucherTicketId: string = `${voucherDetails['groupId']}_${voucherDetails['ticketTypeId']}`;
              const voucherProductGroup: ProductGroupModel = ticketTypes.find(productGroup => 
                productGroup.products.some(product => {
                  if (product.ticket != null) {
                    return product.ticket.uniqueId == voucherTicketId 
                  }
                }) 
              );

              if (voucherProductGroup != null) {
                const voucherTicket: TicketGroupTicketModel = voucherProductGroup.products.find(product => product.ticket.uniqueId === voucherTicketId).ticket;

                if (!voucherTicket) {
                  this.showInvalidVoucherStatusMessage();
                  return;
                }
                
                if (!!voucherTicket && voucherTicket.personalizationType === 'anonymous') {
                  this._store.dispatch(new ticketActions.RemoveTicketBookings());
                  
                  Object.keys(tickets).forEach(id => {
                    const ticket = tickets[id];
                    if (ticket.personalizationType !== 'anonymous') {
                      if (ticket.count > 0) {
                        this._ticketsService.ticketCounterChanged(ticket.uniqueId, 0, true);
                      }

                      if (ticket.voucherCode) {
                        releaseVouchers.push({
                          eventId: eventId,
                          voucherCode: ticket.voucherCode,
                          countryCode: this._translateService.currentLang,
                          uuid: uuid,
                          ticketPersonId: ticket.ticketPersonId
                        });
                      }
                    }
                  });
                }
              }

              this._store.dispatch(
                new ticketActions.GetVoucher({ 
                  redeemVoucher: redeemVoucher, 
                  releaseVouchers: releaseVouchers
                })
              );
            },
            () => {
              this.loading = false;
            });
          }
        );
      }
    }
  }
}
