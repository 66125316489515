import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-self-reg-timeout',
  templateUrl: './self-reg-timeout.component.html',
  styleUrls: ['./self-reg-timeout.component.scss']
})
export class SelfRegTimeoutComponent implements OnInit {
  @Output() resetShop = new EventEmitter<boolean>();

  public timeLeft = 15;

  constructor() {}

  ngOnInit() {
    const interval = setInterval(() => {
      this.timeLeft -= 1;
      if (!this.timeLeft) {
        clearInterval(interval);
        this.resetShop.emit(true);
      }
    }, 1000);
  }
}
