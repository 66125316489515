import {
  combineLatest as observableCombineLatest,
  Observable,
  Subscription
} from 'rxjs';

import { first, filter } from 'rxjs/operators';
import * as customizationActions from '../shared/services-with-reducers/customization/customization.actions';
import * as exhibitionActions from '../shared/services-with-reducers/exhibition/exhibition.actions';
import * as fromRoot from '../app.reducer';
import * as helperActions from '../shared/services-with-reducers/helpers/helper.actions';
import * as stepsActions from '../shared/services-with-reducers/step-forms/steps-forms.actions';
import * as stepsFormsActions from '../shared/services-with-reducers/step-forms/steps-forms.actions';

import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { AppService } from '../app.service';
import { CustomizationService } from '../shared/services-with-reducers/customization/customization.service';
import { FormsService } from '../shared/forms/forms.service';
import { HelperService } from '../shared/services-with-reducers/helpers/helper.service';
import { InputsListModel } from '../shared/services-with-reducers/step-forms/step.interface';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { VisibilityPayloadModel } from '../shared/services-with-reducers/step-forms/step.interface';

@Component({
  moduleId: module.id,
  selector: 'app-web-self-registration',
  templateUrl: './web-self-registration.component.html',
  styleUrls: ['./web-self-registration.component.scss']
})
export class WebSelfRegistrationComponent implements OnInit, OnDestroy {
  @HostBinding('class')
  hostClasses = 'flex-grow-column';
  public isSelfRefistrationEnabled: boolean;
  public exhibitionId$: Observable<number>;
  public eventIsOver = false;
  private _subscriptions = new Subscription();

  constructor(
    private _formsService: FormsService,
    public route: ActivatedRoute,
    private _router: Router,
    private _store: Store<fromRoot.State>,
    private _customizationService: CustomizationService,
    private _appService: AppService,
    private _helperService: HelperService
  ) {
    this.exhibitionId$ = this._store.select(fromRoot.getSelectedExhibitionId);
    this._store.dispatch(new helperActions.SetSelfRegistration(true));
    this._subscriptions.add(
      this._store
        .select(fromRoot.getSelfRegistration)
        .subscribe(selfRegistration => {
          this.isSelfRefistrationEnabled = selfRegistration;
        })
    );
  }

  ngOnInit() {
    // we reset reducers only when we come to ticket page
    this._store.pipe(
      select(fromRoot.getSelectedStep),
      first()
    ).subscribe(step => {
      if (step === 'tickets') {
        this._appService.resetReducers(true, false).subscribe(() => {
          this.getSettingsAndRedirect();
        });
      }
    });

    this._subscriptions.add(
      this.route.params.subscribe(data => {
        this.exhibitionId$.pipe(first()).subscribe(selectedExhibitionId => {
          // only reload data when there is change in exhibition
          if (!!data.id) {
            const exhibitionId: number = Number(data.id);

            if (exhibitionId !== selectedExhibitionId) {
              this._store.dispatch(new exhibitionActions.SelectAction(exhibitionId));
            }
          }
        });
      })
    );

    this._store
      .select(fromRoot.getSelfRegistration)
      .pipe(first())
      .subscribe(selfRegistration => {
        this.setStepsVisibility(!selfRegistration, [
          'legitimation',
          'confirmation',
          'payment',
          'invoice'
        ]);

        this.setStepsVisibility(selfRegistration, ['recipe']);

        // only if questionnaire is present

        this._subscriptions.add(
          this._store
            .select(fromRoot.getSelfRegQuestionnaire)
            .pipe(
              filter((data: InputsListModel) => {
                // if there are no data for questionnaire, load them
                if (data.list === null) {
                  this._helperService.loadBuyerQuestionnaireViaApi(
                    'questionnaire'
                  );
                  return false;
                }

                const questionnaireLoaded = data.list.length > 0;
                if (!questionnaireLoaded) {
                  this._formsService.setStepValid([
                    'questionnaire',
                    'questionnaire'
                  ]);
                }

                this.setStepsVisibility(questionnaireLoaded, ['questionnaire']);

                return questionnaireLoaded;
              })
            )
            .subscribe()
        );
      });

    // if you come to webshop page without any subpage decision to which step to go is made based on backoffice settings
    if (!this.route.children.length) {
      this.getSettingsAndRedirect();
    }

    this.route.queryParams.subscribe(params => {
      if (params.theme) {
        this._customizationService.setColors(params.theme);
      }

      if (params.reset) {
        this.getSettingsAndRedirect();
      }
    });
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  getSettingsAndRedirect() {
    observableCombineLatest(
      this._store.select(fromRoot.getExhibitionSettings),
      this._store.select(fromRoot.getSelectedExhibition),
      this._store.select(fromRoot.isLoggedInAsAdmin)
    )
      .pipe(
        filter(data => {
          return !!data[0] && !!data[1];
        }),
        first()
      )
      .subscribe(data => {
        const [settings, exhibition, isAdmin] = data;

        this._customizationService.setStyles();

        this.eventIsOver = false;
        if (exhibition.isOver && !isAdmin) {
          this._router.navigate(['./is-over'], {
            relativeTo: this.route
          });
          this.eventIsOver = true;
        } else {
          this._store.dispatch(
            new stepsFormsActions.SetSelectedStep('tickets')
          );
          this._store.dispatch(
            new exhibitionActions.SelectAction(exhibition.id)
          );
          this._router.navigate(['./tickets'], { relativeTo: this.route });
        }
      });
  }

  setStepsVisibility(isVisible, stepsArray) {
    const visibilityPayload: VisibilityPayloadModel[] = stepsArray.map(
      (step): VisibilityPayloadModel => {
        return {
          stepKey: step,
          visible: isVisible
        };
      }
    );
    //visibilityPayload.push({ stepKey: 'recipe', visible: !isVisible });
    this._store.dispatch(
      new stepsActions.SetStepsVisibility(visibilityPayload)
    );
  }
}
