import { InputBase } from './../../shared/forms/inputs/input-base.class';
import * as fromRoot from '../../app.reducer';
import * as legitimationActions from '../../shared/services-with-reducers/legitimation/legitimation.actions';
import * as stepsFormsActions from '../../shared/services-with-reducers/step-forms/steps-forms.actions';
import * as userActions from '../../shared/services-with-reducers/user/user.actions';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  LegitimationStatusModel,
  legitimationType
} from '../../shared/services-with-reducers/legitimation/legitimation.interface';
import {
  Observable,
  Subscription,
  combineLatest as observableCombineLatest
} from 'rxjs';
import { filter, first, skip } from 'rxjs/operators';

import { CustomizationService } from '../../shared/services-with-reducers/customization/customization.service';
import { ExhibitionModel } from '../../shared/services-with-reducers/exhibition/exhibition.interface';
import { ExhibitionSettingModel } from '../../shared/services-with-reducers/customization/customization.interfaces';
import { FormsService } from '../../shared/forms/forms.service';
import { StatusBarService } from '../../status-bar/status-bar.service';
import { StepsFormsService } from '../../shared/services-with-reducers/step-forms/steps-forms.service';
import { DataValidationService } from './../../shared/validation/data-validation.service';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { UserProfileModel } from '../../shared/services-with-reducers/user/user.interface';
import { environment } from '../../../environments/environment';
import { getProfileEditForm } from '../../edit-account-form/form-inputs.edit-account';
import { HelperService } from '../../shared/services-with-reducers/helpers/helper.service';
import { VisibilityPayloadModel } from '../../shared/services-with-reducers/step-forms/step.interface';
import * as stepsActions from '../../shared/services-with-reducers/step-forms/steps-forms.actions';

@Component({
  moduleId: module.id,
  selector: 'app-web-shop-account-verification',
  templateUrl: './web-shop-account-verification.component.html',
  styleUrls: ['./web-shop-account-verification.component.scss']
})
export class WebShopAccountVerificationComponent implements OnInit, OnDestroy {
  public userProfile: UserProfileModel;

  private subscriptions = new Subscription();

  constructor(
    private _store: Store<fromRoot.State>,
    private _customizationService: CustomizationService,
    private _formsService: FormsService,
    private _router: Router,
    public route: ActivatedRoute,
    private _statusBarService: StatusBarService,
    private _translateService: TranslateService,
    private _stepsFormsService: StepsFormsService,
    private _helperService: HelperService
  ) {
    this.subscriptions.add(
      observableCombineLatest(
        this._store.select(fromRoot.getProfile)
      ).subscribe(
        async (
          data: [
            UserProfileModel
          ]
        ) => {
          const [
            profile
          ] = data;

          this.userProfile = profile
        })
    );
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
