/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./widget-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./widget-page.component";
import * as i4 from "@ngrx/store";
import * as i5 from "../../app/shared/services-with-reducers/customization/customization.service";
var styles_WidgetPage = [i0.styles];
var RenderType_WidgetPage = i1.ɵcrt({ encapsulation: 0, styles: styles_WidgetPage, data: {} });
export { RenderType_WidgetPage as RenderType_WidgetPage };
export function View_WidgetPage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_WidgetPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-widget-page", [], null, null, null, View_WidgetPage_0, RenderType_WidgetPage)), i1.ɵdid(1, 245760, null, 0, i3.WidgetPage, [i2.ActivatedRoute, i4.Store, i5.CustomizationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WidgetPageNgFactory = i1.ɵccf("app-widget-page", i3.WidgetPage, View_WidgetPage_Host_0, {}, {}, []);
export { WidgetPageNgFactory as WidgetPageNgFactory };
