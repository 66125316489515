import * as pressNewsActions from './press-news.actions';

import { Actions, Effect } from '@ngrx/effects';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Observable, EMPTY as empty, of } from 'rxjs';
import { PressNewsListModel, PressNewsModel } from './press-news.interface';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';

import { Action } from '@ngrx/store';
import { PressNewsService } from './press-news.service';

export const PRESS_NEWS_DEBOUNCE = new InjectionToken<number>(
  'Press News Debounce'
);

@Injectable()
export class PressNewsEffect {
  @Effect()
  loadPressNews$: Observable<Action> = this.actions$
    .ofType<pressNewsActions.GetListOfPressNews>(
      pressNewsActions.ActionTypes.GET_LIST_OF_PRESS_NEWS
    )
    .pipe(
      debounceTime(this.debounce),
      switchMap((data: any) => {
        const eventId = data.payload;
        return this.pressNewsService
          .getPressNewsList(eventId)
          .pipe(
            map(
              (pressNewsList: PressNewsListModel[]) =>
                new pressNewsActions.AddPressNewsToListAction(pressNewsList)
            ),
            catchError(() =>
              of(new pressNewsActions.AddPressNewsToListAction([]))
            )
          );
      })
    );

  @Effect()
  loadPressNewsByID$: Observable<Action> = this.actions$
    .ofType<pressNewsActions.GetPressNewsById>(
      pressNewsActions.ActionTypes.GET_PRESS_NEWS_BY_ID
    )
    .pipe(
      debounceTime(this.debounce),
      switchMap((data: any) => {
        const eventId = data.payload;
        if (!eventId) {
          return Observable.empty();
        }

        return this.pressNewsService
          .getPressNews(eventId)
          .pipe(
            map(
              (pressNewsById: PressNewsModel) =>
                new pressNewsActions.SetPressNewsDetail(pressNewsById)
            ),
            catchError(() => of(new pressNewsActions.SetPressNewsDetail(null)))
          );
      })
    );

  constructor(
    private actions$: Actions,
    private pressNewsService: PressNewsService,
    @Optional()
    @Inject(PRESS_NEWS_DEBOUNCE)
    private debounce: number = 50
  ) {}
}
