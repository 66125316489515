import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import * as fromRoot from '../app.reducer';

import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { getLocalStorageString, setLocalStorageObject, setLocalStorageString } from '../shared/app-utils';
import { AppConstants } from '../shared/app-constants';

@Component({
  selector: 'app-cookies-popup',
  templateUrl: './cookies-popup.component.html',
  styleUrls: ['./cookies-popup.component.scss']
})
export class CookiesPopupComponent implements OnInit, OnDestroy {
  @Input() textWithLink: string;
  public hideDataProtectionOnHomePage =
    getLocalStorageString(AppConstants.hideDataProtectionOnHomePageReducer) || false;
  public hideDataProtectionPageOnEvent =
    JSON.parse(getLocalStorageString(AppConstants.hideDataProtectionOnEventReducer)) || [];
  public hideDataProtectionOnEventPage: boolean = false;
  public eventId: string | number;

  private _subscriptions: Subscription = new Subscription();

  constructor(
    private _router: Router,
    private _store: Store<fromRoot.State>
  ) {
    this._subscriptions.add(
      this._store
        .select(fromRoot.getSelectedExhibitionId)
        .pipe(
          filter(eventId => {
            this.eventId = eventId;
            return !!eventId;
          })
        )
        .subscribe(eventId => {
          this.hideDataProtectionPageOnEvent =
            JSON.parse(getLocalStorageString(AppConstants.hideDataProtectionOnEventReducer)) || [];

          this.hideDataProtectionOnEventPage = !!Object.keys(
            this.hideDataProtectionPageOnEvent
          ).find(id => id === eventId.toString());
        })
    );
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  clickText(event) {
    event.stopPropagation();
    event.preventDefault();
    if (event.target.tagName === 'A') {
      this._router.navigate(['/data-protection']);
    }
  }

  close() {
    if (!this.eventId) {
      setLocalStorageString(AppConstants.hideDataProtectionOnHomePageReducer, 'true');
      this.hideDataProtectionOnHomePage = true;
    } else {
      setLocalStorageObject(
        AppConstants.hideDataProtectionOnEventReducer,
        {
          ...this.hideDataProtectionPageOnEvent,
          [this.eventId]: true
        }
      );
      this.hideDataProtectionOnEventPage = true;
    }
  }
}
