var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { ActionTypes } from './user.actions';
import { createSelector } from 'reselect';
import cloneDeep from 'lodash.clonedeep';
import { AppConstants } from '../../app-constants';
export var initialState = {
    user: null,
    profile: null,
    language: '',
    billingAddress: [],
    selectedBillingAddressId: null,
    registrationQuestionnaire: [],
    ticketsHitory: [],
    eventsEmailUnsubscriptions: [],
    passRecoverySuccess: null,
    passChangeStatus: null,
    emailChangeStatus: null,
    loginTimestamp: null,
    emailVerifyStatus: null,
    profileLoading: false
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    var clonedInitialState = cloneDeep(initialState);
    switch (action.type) {
        case ActionTypes.SET_USER: {
            var user = action.payload;
            return __assign({}, state, { user: user });
        }
        case ActionTypes.SET_LOGIN_TIMESTAMP: {
            var loginTimestamp = action.payload;
            return __assign({}, state, { loginTimestamp: loginTimestamp });
        }
        case ActionTypes.PROFILE_LOADING: {
            var profileLoading = action.payload;
            return __assign({}, state, { profileLoading: profileLoading });
        }
        case ActionTypes.SET_PROFILE: {
            var profile = action.payload;
            return __assign({}, state, { profile: profile, profileLoading: false });
        }
        case ActionTypes.SET_LANGUAGE: {
            var language = action.payload;
            return __assign({}, state, { language: language });
        }
        case ActionTypes.SET_PROFILE_BILLING_ADDRESS: {
            var billingAddress = action.payload;
            return __assign({}, state, { billingAddress: billingAddress });
        }
        case ActionTypes.SET_SELECTED_BILLING_ADDRESS: {
            var selectedBillingAddressId = action.payload;
            return __assign({}, state, { selectedBillingAddressId: selectedBillingAddressId });
        }
        case ActionTypes.UPDATE_PROFILE_BILLING_ADDRESS: {
            var updatedAddress_1 = action.payload;
            if (updatedAddress_1) {
                var oldAddresses = state.billingAddress;
                var billingAddress = oldAddresses.map(function (address) {
                    if (address.id === updatedAddress_1.id) {
                        return updatedAddress_1;
                    }
                    else {
                        return address;
                    }
                });
                return __assign({}, state, { billingAddress: billingAddress });
            }
            return state;
        }
        case ActionTypes.SET_REGISTRATION_QUESTIONNAIRE: {
            var registrationQuestionnaire = action.payload;
            return __assign({}, state, { registrationQuestionnaire: registrationQuestionnaire });
        }
        case ActionTypes.SET_TICKETS_HISTORY_BY_USER: {
            var ticketsHistory = action.payload;
            return __assign({}, state, { ticketsHitory: ticketsHistory });
        }
        case ActionTypes.SET_EVENTS_EMAIL_UNSUBSCRIPTIONS: {
            var eventsEmailUnsubscriptions = action.payload;
            return __assign({}, state, { eventsEmailUnsubscriptions: eventsEmailUnsubscriptions });
        }
        case ActionTypes.SET_PROFILES_EMAIL: {
            var email = action.payload;
            var profile = state.profile;
            profile.email = email;
            return __assign({}, state, { profile: profile });
        }
        case ActionTypes.PASSWORD_RECOVERY_RESULT: {
            var passRecoverySuccess = action.payload;
            return __assign({}, state, { passRecoverySuccess: passRecoverySuccess });
        }
        case ActionTypes.SET_PASSWORD_CHANGED: {
            var passChangeStatus_1 = action.payload;
            return __assign({}, state, { passChangeStatus: passChangeStatus_1 });
        }
        case ActionTypes.SET_EMAIL_CHANGED: {
            var emailChangeStatus_1 = action.payload;
            return __assign({}, state, { emailChangeStatus: emailChangeStatus_1 });
        }
        case ActionTypes.SET_EMAIL_VERIFIED: {
            var response = action.payload;
            if (response) {
                return __assign({}, state, { emailVerifyStatus: {
                        statusCode: response.statusCode,
                        body: response.body
                    } });
            }
            else {
                return __assign({}, state, { emailVerifyStatus: null });
            }
        }
        case ActionTypes.RESET_REDUCER:
            return clonedInitialState;
        default: {
            return state;
        }
    }
}
/**
 * Because the data structure is defined within the reducer it is optimal to
 * locate our selector functions at this level. If store is to be thought of
 * as a database, and reducers the tables, selectors can be considered the
 * queries into said database. Remember to keep your selectors small and
 * focused so they can be combined and composed to fit each particular
 * use-case.
 */
export var getUser = function (state) { return state.user; };
export var getLoginTimestamp = function (state) { return state.loginTimestamp; };
export var isProfileLoading = function (state) { return state.profileLoading; };
export var getProfile = function (state) { return state.profile; };
export var getLanguage = function (state) { return state.language; };
export var getProfileBillingAddress = function (state) { return state.billingAddress; };
export var getSelectedBillingAddressId = function (state) {
    return state.selectedBillingAddressId;
};
export var getRegistrationQuestionnaire = function (state) {
    return state.registrationQuestionnaire;
};
export var getTicketsHistory = function (state) { return state.ticketsHitory; };
export var getPassRecoverySuccess = function (state) {
    return state.passRecoverySuccess;
};
export var passChangeStatus = function (state) { return state.passChangeStatus; };
export var emailChangeStatus = function (state) { return state.emailChangeStatus; };
export var emailVerifyStatus = function (state) { return state.emailVerifyStatus; };
export var getEventsEmailUnsubscriptions = function (state) {
    return state.eventsEmailUnsubscriptions;
};
export var isUserLoggedIn = createSelector(getProfile, function (user) {
    if (user && user.id) {
        return true;
    }
    else {
        return false;
    }
});
export var isLoggedInAsAdmin = createSelector(getUser, function (user) {
    return user && AppConstants.getUserTypeFromJwt(user) === 'admin';
});
