<div class="reset-password-wrapper">
  <div class="reset-password-header">
    <span data-translation="reset-password.title">
      {{ 'reset-password.title' | translate | hideTranslation }}
    </span>
  </div>

  <div class="reset-password-body">
    <form
      *ngIf="!submitedForm && hashStatus === 'ok'"
      [formGroup]="resetPasswordForm"
      class="column"
    >
      <div class="login-input-wrapper">
        <svg class="icon icon-key">
          <use xlink:href="assets/defs.svg#icon-key"></use>
        </svg>
        <input
          [formControl]="password"
          name="password"
          id="password-input"
          type="password"
          data-translation="reset-password.password.placeholder"
          placeholder="{{
            'reset-password.password.placeholder' | translate | hideTranslation
          }}"
          [showPassword]="passwordEye"
          required
        />
        <svg class="icon icon-key" #passwordEye>
          <use xlink:href="assets/defs.svg#icon-eye"></use>
        </svg>
      </div>
      <div
        class="reset-password-input-error-message"
        *ngIf="password.errors && password.errors.minlength && password.dirty"
      >
        <small data-translation="reset-password.password.validation">{{
          'reset-password.password.validation'
            | translate: { minLength: minPasswordLength }
        }}</small>
      </div>

      <div class="login-input-wrapper">
        <svg class="icon icon-key">
          <use xlink:href="assets/defs.svg#icon-key"></use>
        </svg>
        <input
          [formControl]="verifyPassword"
          name="verifyPassword"
          id="verifyPassword-input"
          type="password"
          data-translation="reset-password.verify-password.placeholder"
          placeholder="{{
            'reset-password.verify-password.placeholder'
              | translate
              | hideTranslation
          }}"
          [showPassword]="repeatPasswordEye"
          required
        />
        <svg class="icon icon-key" #repeatPasswordEye>
          <use xlink:href="assets/defs.svg#icon-eye"></use>
        </svg>
      </div>
      <div
        class="reset-password-input-error-message"
        *ngIf="verifyPassword.errors"
      >
        <small
          *ngIf="verifyPassword.errors['minlength']"
          data-translation="reset-password.password.validation"
          >{{
            'reset-password.password.validation'
              | translate: { minLength: minPasswordLength }
          }}</small
        >
        <small
          *ngIf="verifyPassword.errors['equalValue']"
          data-translation="reset-password.verify-password.validation"
          >{{
            'reset-password.verify-password.validation'
              | translate
              | hideTranslation
          }}</small
        >
      </div>

      <div class="reset-password-button-wrapper">
        <button
          class="back-button button button-option"
          type="button"
          (click)="navigateBack()"
          data-translation="reset-password.back"
        >
          <span>
            {{ 'reset-password.back' | translate | hideTranslation }}
          </span>
        </button>
        <button
          class="button submit-button"
          type="button"
          (click)="submitResetPassword(password.value)"
          [disabled]="!resetPasswordForm.valid"
          data-translation="reset-password.submit"
        >
          <span>{{
            'reset-password.submit' | translate | hideTranslation
          }}</span>
        </button>
      </div>
    </form>

    <div
      *ngIf="submitedForm || hashStatus !== 'ok'"
      class="reset-password-describtion"
    >
      <div
        *ngIf="resetLink === false"
      >
        <div
          class="reset-password-fail-text"
          data-translation="reset-password.fail.text"
        >
          <span>
            {{ 'reset-password.fail.text' | translate | hideTranslation }}
          </span>
        </div>
        <button
          class="back-button button button-option"
          type="button"
          (click)="navigateBack()"
          data-translation="reset-password.back"
        >
          <span>
            {{ 'reset-password.back' | translate | hideTranslation }}
          </span>
        </button>
      </div>
      <div
        *ngIf="resetLink === null || hashStatus === 'waiting'"
        class="reset-password-fail-text"
        data-translation="reset-password.sending.text"
      >
        <span>
          {{ 'reset-password.sending.text' | translate | hideTranslation }}
        </span>
      </div>
      <div
        *ngIf="resetLink"
      >
        <div
          class="reset-password-success-text"
          data-translation="reset-password.success.text"
        >
          <span>
            {{ 'reset-password.success.text' | translate | hideTranslation }}
          </span>
        </div>
        <button
          class="button"
          type="akcept-button"
          (click)="navigateToLogin()"
          routerLink="/login"
          data-translation="reset-password.accept"
        >
          <span>{{ 'reset-password.accept' | translate | hideTranslation }}</span>
        </button>
      </div>
    </div>
  </div>
</div>
