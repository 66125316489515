var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import * as signalR from '@aspnet/signalr';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { consoleLog } from '../app-utils';
import * as i0 from "@angular/core";
var SignalrService = /** @class */ (function () {
    function SignalrService() {
        this.connected$ = new BehaviorSubject(false);
        this.reconnecting$ = new BehaviorSubject(false);
        this._handlers = {};
        this.signalRUrl = this.getSignalROrigin() + "/websockets/notification";
        this.start();
    }
    SignalrService.prototype.start = function (isReconnect) {
        var _this = this;
        if (isReconnect === void 0) { isReconnect = false; }
        consoleLog("Connecting to SignalR at: " + this.signalRUrl);
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(this.signalRUrl)
            .build();
        this.connection
            .start()
            .then(function () {
            _this.connected$.next(true);
            _this.reconnecting$.next(false);
            if (isReconnect) {
                _this.reregisterAllHandlers();
            }
            _this.reconnect();
        })
            .catch(function () {
            _this.connected$.next(false);
            _this.reconnecting$.next(true);
            setTimeout(function () {
                _this.start(isReconnect);
            }, 10000);
        });
    };
    SignalrService.prototype.getSignalROrigin = function () {
        //On a server the SignalR is deployed under the virtual directory "signalr", on localhost isn't
        if (environment.origin.includes('localhost')) {
            return "https://localhost:44354";
        }
        else {
            return environment.protocol + environment.origin + '/signalr';
        }
    };
    SignalrService.prototype.reconnect = function () {
        var _this = this;
        this.connection.onclose(function () {
            _this.connected$.next(false);
            _this.reconnecting$.next(true);
            _this.start(true);
        });
    };
    SignalrService.prototype.disconnect = function () {
        var _this = this;
        this.connection.stop().then(function () {
            consoleLog('Disconnecting from SignalR...');
            _this.connected$.next(false);
            _this.reconnecting$.next(false);
        });
    };
    /**
     * Invoke method on the server
     * Returns promise which resolves another Promise with server response
     * @param methodName
     * @param args
     */
    SignalrService.prototype.invoke = function (methodName) {
        var _this = this;
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        var unsubscribe$ = new Subject();
        var alreadyConnected = this.connected$.getValue();
        // make sure that connection is available when invoking method
        var invokePromise = new Promise(function (resolve) {
            var _a;
            if (alreadyConnected) {
                resolve((_a = _this.connection).invoke.apply(_a, [methodName].concat(args)));
            }
            else {
                _this.connected$.takeUntil(unsubscribe$).subscribe(function (connected) {
                    var _a;
                    if (connected) {
                        unsubscribe$.next();
                        unsubscribe$.complete();
                        resolve((_a = _this.connection).invoke.apply(_a, [methodName].concat(args)));
                    }
                });
            }
        });
        return invokePromise;
    };
    /**
     * This function reregisters all existing handlers if we're reconnecting to SignalR service.
     * Otherwise handlers would become invalid.
     */
    SignalrService.prototype.reregisterAllHandlers = function () {
        var _this = this;
        if (this._handlers) {
            Object.keys(this._handlers).forEach(function (componentName) {
                var component = _this._handlers[componentName];
                Object.keys(component).forEach(function (methodName) {
                    var method = component[methodName];
                    _this.registerHandler(componentName, methodName, method);
                });
            });
        }
    };
    /**
     * This function will register new handler and stores its reference to _handlers object for easier access and removal of handler.
     * If handler is already registered, it will remove this handler and registers new.
     *
     * @param {String} componentName - NOTE: In case of a class you can use this.constructor.name. Name of the component where handler is registered
     * @param {String} methodName - Name of the method that is invoked
     * @param {Function} callback - The handler that will be raised when the hub method is invoked.
     */
    SignalrService.prototype.registerHandler = function (componentName, methodName, callback) {
        var componentHandlers = this._handlers[componentName];
        var currentSubscription = componentHandlers && componentHandlers[methodName];
        currentSubscription && this.connection.off(methodName);
        this._handlers[componentName] = __assign({}, componentHandlers);
        this._handlers[componentName][methodName] = callback;
        this.connection.on(methodName, callback);
    };
    /**
     * This function will remove handler and also remove its reference from _handlers object.
     * If no `methodName` is provided, all handlers for provided component name are removed
     *
     * @param {String} fromComponent - Name of the component to remove handlers from
     * @param {String} fromMethod - Name of the method to remove handlers for - Pass `fromMethod` if you want to remove specific handler; Otherwise all handlers are removed
     */
    SignalrService.prototype.removeHandler = function (fromComponent, fromMethod) {
        var componentHandlers = this._handlers[fromComponent];
        var hasHandlers = componentHandlers && Object.keys(componentHandlers);
        if (!hasHandlers) {
            return;
        }
        // remove all form component
        if (!fromMethod) {
            for (var methodName in this._handlers[fromComponent]) {
                var handler = this._handlers[fromComponent][methodName];
                handler && this.connection.off(methodName);
            }
            delete this._handlers[fromComponent];
        }
        else {
            // remove single
            var handler = componentHandlers[fromMethod];
            handler && this.connection.off(fromMethod);
            delete componentHandlers[fromMethod];
        }
    };
    SignalrService.ngInjectableDef = i0.defineInjectable({ factory: function SignalrService_Factory() { return new SignalrService(); }, token: SignalrService, providedIn: "root" });
    return SignalrService;
}());
export { SignalrService };
