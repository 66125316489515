import { first, filter } from 'rxjs/operators';
import * as fromRoot from '../../app.reducer';
import * as userActions from '../../shared/services-with-reducers/user/user.actions';

import { Component, OnDestroy, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { UserProfileModel } from '../../shared/services-with-reducers/user/user.interface';
import { HelperService } from '../../shared/services-with-reducers/helpers/helper.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit, OnDestroy {
  public verifyEmailResponse: Observable<number>;
  public assignedTickets: number;
  public assignedOrders: number;
  private subscriptions = new Subscription();
  public profile$: Observable<UserProfileModel>;
  public hashStatus: string = 'waiting';

  private verifyEmailHash: string;
  private crmHash: string = null;

  constructor(
    public _router: Router,
    private _route: ActivatedRoute,
    private _store: Store<fromRoot.State>,
    private _helperService: HelperService
  ) {
    this._store.dispatch(new userActions.EmailVerified(null));
    this._store.dispatch(new userActions.ResetReducer());
  }

  ngOnInit() {
    this._route.params.pipe(first()).subscribe(params => {
      if (params.hash) {
        this.verifyEmailHash = params.hash;
      }
    });

    this._route.queryParams.pipe(first()).subscribe(queryParams => {
      if (queryParams.crmHash) {
        this.crmHash = queryParams.crmHash;
      }
    });

    this.subscriptions.add(
      this._helperService
        .checkHashValidity(this.verifyEmailHash)
        .pipe(
          filter(data => !!data),
          first()
        )
        .subscribe(hashStatus => {
          if (
            hashStatus.status === 'expired' ||
            hashStatus.status === 'nonExistent' ||
            hashStatus.status === 'invalid'
          ) {
            this.verifyEmailResponse = of(408);
            this.hashStatus = 'fail';
          } else {
            this.hashStatus = 'ok';
          }
        })
    );

    this.subscriptions.add(
      this._store
        .select(fromRoot.emailVerifyStatus)
        .filter(response => !!response)
        .subscribe(response => {
          if (response.statusCode === 200) {
            this.verifyEmailResponse = of(response.statusCode);
            this.assignedTickets = response.body.assignedTickets;
            this.assignedOrders = response.body.assignedOrders;
            let profileLoaded: boolean = false;
            this.subscriptions.add(
              this._store
                .select(fromRoot.getProfile)
                .filter(profile => !!profile)
                .first()
                .subscribe(profile => {
                  profile.isEmailVerified = true;
                  profileLoaded = true;
                  this._store.dispatch(new userActions.SetProfile(profile));
              })
            );
            profileLoaded && !!this.verifyEmailResponse ? this.navigateHome() : this._router.navigate(['/login']);
          } else {
            this.verifyEmailResponse = of(response.statusCode);
          }
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  verifyEmail() {
    this._store.dispatch(
      new userActions.VerifyEmail({ hash: this.verifyEmailHash, crmHash: this.crmHash })
    );
  }

  navigateHome() {
    let webShopUrl: string;
    this.subscriptions.add(
      combineLatest([
        this._route.queryParams,
        this._store.pipe(select(fromRoot.getAllExhibitionsCollection), first())
      ]).subscribe(data => {
        const [queryParams, exhibitions] = data;
        if (Object.keys(queryParams).some(key => key === "eventId")) {
          const eventId = queryParams['eventId'];
          if (!!eventId) {
            // check if there is event with id from URI and if it's still active
            if (exhibitions.some(exhibition => exhibition.id == eventId && exhibition.isOver === false)) {
              webShopUrl = `webshop/${eventId}`;
            }
          }
        }
        // if we made account from ticket selection page, and validation is mandatory, go back
        // to ticket selection of event we were on, else go to home page
        if (!!webShopUrl) {
          this._router.navigate([`${webShopUrl}`]);
        } else {
          this._router.navigate(['/']);
        }
      })
    );
  }
}
