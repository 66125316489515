import * as fromRoot from '../../app.reducer';
import { Component, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { filter, first } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { AppService } from '../../app.service';
import { CustomizationService } from '../../shared/services-with-reducers/customization/customization.service';
import * as exhibitionActions from '../../shared/services-with-reducers/exhibition/exhibition.actions';
import * as stepsFormsActions from '../../shared/services-with-reducers/step-forms/steps-forms.actions';

@Component({
  moduleId: module.id,
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnDestroy {
  private subscriptions = new Subscription();
  public hideLogin: boolean = false;
  
  constructor(
    private _store: Store<fromRoot.State>,
    private activatedRoute: ActivatedRoute,
    private appService: AppService,
    private _customizationService: CustomizationService
  ) {
    this.subscriptions.add(
      this.activatedRoute.queryParams.subscribe(params => {
        if (Object.keys(params).some(key => key.toLowerCase() === 'login_user' || key.toLowerCase() === 'reg_mail')) {
          this.hideLogin = true;

          this.appService.resetReducers().first().subscribe(() => {
            this._store.pipe(
              select(fromRoot.getExhibitionSettings),
              first(data => !data)
            ).subscribe(() => {
              const eventIdKey: string = Object.keys(params).find(key => key.toLowerCase() === 'eventid');
              
              if (eventIdKey) {
                const eventId: number = +params[eventIdKey];
                this._store.dispatch(new exhibitionActions.SelectAction(eventId));
                this._store.dispatch(new stepsFormsActions.SetSelectedStep('tickets'));
  
                this._store.pipe(
                  select(fromRoot.getExhibitionSettings),
                  filter(data => !!data && data.eventId === eventId),
                  first()
                ).subscribe(() => {
                  this._customizationService.setStyles();
                  
                  this._store.dispatch(new exhibitionActions.GetDoubleClickScripts(eventId));
                  this._store.dispatch(new exhibitionActions.GetGenericScripts(eventId));
                  
                  this.hideLogin = false; 
                });
              } else {
                this._store.pipe(
                  select(fromRoot.getOperatorsSettings),
                  filter(data => !!data),
                  first()
                ).subscribe(() => {
                  this.hideLogin = false; 
                });
              }
            });
          });
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
