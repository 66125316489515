<div class="page-padding-wrapper">
  <!--<div *ngIf="response === 'success'" class="basic-header black">
        <h2 class="basic-title" data-translation="payment.success.title">{{ 'payment.success.title' | translate | hideTranslation }}</h2>
        <span data-translation="payment.success.subtitle">{{ 'payment.success.subtitle' | translate | hideTranslation }}</span>
    </div>-->
  <div *ngIf="response === 'fail'" class="basic-header black">
    <h2 class="basic-title" data-translation="payment.fail.title">
      {{ 'payment.fail.title' | translate | hideTranslation }}
    </h2>

    <span data-translation="payment.fail.subtitle">{{
      'payment.fail.subtitle' | translate | hideTranslation
    }}</span>
  </div>

  <div *ngIf="response === 'error'" class="basic-header black">
    <h2 class="basic-title" data-translation="payment.error.title">
      {{ 'payment.error.title' | translate | hideTranslation }}
    </h2>

    <span
      *ngIf="translatedErrorMessage && translatedErrorMessage.status !== 409"
      [attr.data-translation]="'response.' + translatedErrorMessage.key"
    >
      {{ translatedErrorMessage.message }}
    </span>

    <span
      *ngIf="!translatedErrorMessage"
      data-translation="payment.error.subtitle"
      >{{ 'payment.error.subtitle' | translate | hideTranslation }}</span
    >
  </div>

  <div class="payment-button-wrapper">
    <button
      class="button back-button"
      type="button"
      (click)="back()"
      data-translation="step-navigation.back-button"
    >
      <span>
        {{ 'step-navigation.back-button' | translate | hideTranslation }}
      </span>
    </button>
  </div>
</div>
