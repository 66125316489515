<div class="counter">
  <!--
        if available tickets is less than 10% of total limit of tickets we show the number of available tickets to the user
    -->
  <div
    class="counter-info tickets-left"
    *ngIf="
      !ticketSoldOut &&
      ticketType === tickets.TICKET_NORMAL &&
      percentageOfAvailableTickets < 0.1 &&
      !(workshopsMandatory && hasWorkshops && workshopSeatsAvailable <= counterValue)
    "
    [attr.data-translation]="'ticket-selection.tickets-left'"
    [translate]="'ticket-selection.tickets-left'"
    [translateParams]="{ availableTickets: totalAvailableTickets }"
  ></div>

  <div
    class="counter-info sold-out"
    *ngIf="
      ticketSoldOut &&
      ticketType === tickets.TICKET_NORMAL &&
      ticketType !== tickets.TICKET_PARKING
    "
    data-translation="ticket.counter.ticket.slodout"
  >
    {{ 'ticket.counter.ticket.slodout' | translate }}
  </div>

  <div
    class="counter-wrapper"
    *ngIf="
      ticketType === tickets.TICKET_PARKING ||
      (!(workshopsMandatory && hasWorkshops && workshopSeatsAvailable <= counterValue) &&
        ticketType !== tickets.TICKET_NORMAL) ||
      (!ticketSoldOut && ticketType === tickets.TICKET_NORMAL) ||
      !!counterValue ||
      wasCounterTouched
    "
  >
    <button
      class="counter-button"
      type="button"
      (click)="onDecrease()"
      [ngClass]="{ isTicketBookingLoading: isTicketBookingLoading }"
      [disabled]="workshopsSoldOut || !counterValue || disableOnPackageMinAmount"
    >
      <span>-</span>
    </button>
    <div class="selector-value">
      <div *ngIf="isTicketBookingLoading" class="button-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>

      <input
        *ngIf="!isTicketBookingLoading"
        appNumbersOnly
        type="number"
        [(ngModel)]="counterValue"
        (change)="onValueChange($event)"
        (click)="wasCounterTouched = true"
        class="counter-input"
        [disabled]="(workshopsSoldOut || !counterValue) && 
          ((workshopsMandatory && hasWorkshops && workshopSeatsAvailable <= counterValue) ||
          (counterValue >= voucherLimit && voucherLimit !== 0) ||
          ticketLimitWarning ||
          (ticketSoldOut && ticketType === tickets.TICKET_NORMAL) ||
          (package && !package.count) ||
          (disableOnPackageMinAmount && disableOnPackageMaxAmount))
        "
      />
    </div>
    <button
      class="counter-button"
      type="button"
      (click)="onIncrease()"
      [ngClass]="{ isTicketBookingLoading: isTicketBookingLoading }"
      [disabled]=" 
        (workshopsMandatory && hasWorkshops && workshopsSoldOut) ||
        (counterValue >= voucherLimit && voucherLimit !== 0) ||
        ticketLimitWarning ||
        (ticketSoldOut && ticketType === tickets.TICKET_NORMAL) || 
        (package && !package.count) || 
        disableOnPackageMaxAmount
      "
    >
      <span>+</span>
    </button>
  </div>
</div>
<div
  class="ticket-selection-warning"
  *ngIf="ticketLimitWarning &&
    !(workshopsMandatory && hasWorkshops && workshopsSoldOut && workshopSeatsAvailable <= counterValue && !ticketSoldOut)
  "
  [attr.data-translation]="ticketLimitWarning"
  [translate]="ticketLimitWarning"
  [translateParams]="{ maxTicketLimit: currentMaxLimit }"
></div>
<div
  *ngIf="
    workshopsMandatory && hasWorkshops && workshopsSoldOut && workshopSeatsAvailable <= counterValue && !ticketSoldOut
  "
  data-translation="ticket.counter.workshops.slodout"
>
  {{ 'ticket.counter.workshops.slodout' | translate }}
</div>
