import { Pipe, PipeTransform } from '@angular/core';

import { CurrencySettingsModel } from '../../services-with-reducers/customization/customization.interfaces';

@Pipe({
  name: 'currencySymbolGap'
})
export class CurrencySymbolGapPipe implements PipeTransform {
  transform(value: number, currencySettings: CurrencySettingsModel): string {
    /* if (this.currenciesToApplyGap.includes(currency) && value) {
      return value.replace(currency, `${currency} `);
    } */

    const numberWithSeparators = x => {
      return x
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, currencySettings.thousandsSeparator);
    };

    if (isNaN(value)) {
      value = 0;
    }

    const numberParts = value.toString().split('.');
    numberParts[0] = numberWithSeparators(numberParts[0]);
    const withDecimalMark = numberParts.join(currencySettings.decimalMark);
    const withSymbol = `${withDecimalMark} ${currencySettings.currencyCode}`;
    return withSymbol;
  }
}
