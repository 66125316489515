/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./carousel.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./carousel.component";
var styles_CarouselComponent = [i0.styles];
var RenderType_CarouselComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CarouselComponent, data: {} });
export { RenderType_CarouselComponent as RenderType_CarouselComponent };
export function View_CarouselComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "carousel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "carousel-content"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { width: 0, left: 1 }), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, ((100 * _co.slides.length) + "%"), ((0 - (100 * (_co.activeSlideIndex - 1))) + "%")); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CarouselComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-carousel", [], null, null, null, View_CarouselComponent_0, RenderType_CarouselComponent)), i1.ɵdid(1, 114688, null, 0, i3.CarouselComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CarouselComponentNgFactory = i1.ɵccf("app-carousel", i3.CarouselComponent, View_CarouselComponent_Host_0, { activeSlideIndex: "activeSlideIndex", slides: "slides" }, {}, ["[carousel-slide]"]);
export { CarouselComponentNgFactory as CarouselComponentNgFactory };
