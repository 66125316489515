import { ErrorHandlingService } from '../../error-handling/error-handling.service';
import { HttpClient } from '@angular/common/http';
import { StatusBarService } from '../../../status-bar/status-bar.service';
import { environment } from '../../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../error-handling/error-handling.service";
import * as i3 from "../../../status-bar/status-bar.service";
var LegitimationService = /** @class */ (function () {
    function LegitimationService(_http, _errorHandlingService, _statusBarService) {
        this._http = _http;
        this._errorHandlingService = _errorHandlingService;
        this._statusBarService = _statusBarService;
    }
    LegitimationService.prototype.saveLegitimationInfo = function (data, userId, eventId) {
        return (this._http.post("" + environment.protocol + environment.webApiUrl + "/event/" + eventId + "/legitimation/" + userId, data));
    };
    LegitimationService.prototype.getLegitimationList = function () {
        return (this._http.get("" + environment.protocol + environment.webApiUrl + "/user/legitimation-history"));
    };
    LegitimationService.prototype.getLegitimationStatus = function (eventId) {
        return (this._http.get("" + environment.protocol + environment.webApiUrl + "/user/event/" + eventId + "/legitimation-status"));
    };
    LegitimationService.prototype.getLegitimationFaxId = function (eventId, userId) {
        return (this._http.get("" + environment.protocol + environment.webApiUrl + "/event/" + eventId + "/fax-legitimation-id/" + userId));
    };
    LegitimationService.ngInjectableDef = i0.defineInjectable({ factory: function LegitimationService_Factory() { return new LegitimationService(i0.inject(i1.HttpClient), i0.inject(i2.ErrorHandlingService), i0.inject(i3.StatusBarService)); }, token: LegitimationService, providedIn: "root" });
    return LegitimationService;
}());
export { LegitimationService };
