import * as fromRoot from '../app.reducer';
import * as helperActions from '../shared/services-with-reducers/helpers/helper.actions';

import { Component, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';

@Component({
  selector: 'app-iframe-modal',
  templateUrl: './iframe-modal.component.html',
  styleUrls: ['./iframe-modal.component.scss']
})
export class IframeModalComponent implements OnInit {
  public iframeModalUrl = null;
  constructor(private _store: Store<fromRoot.State>) {}

  ngOnInit() {
    this._store.select(fromRoot.getModalIframeUrl).subscribe(url => {
      this.iframeModalUrl = url;
    });
  }

  preventClick(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  closeModalWindow(event) {
    this._store.dispatch(new helperActions.SetIframeUrl(null));
  }
}
