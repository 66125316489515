import { ActionTypes, Actions } from './exhibition.actions';
import {
  DoubleClickModel,
  ExhibitionHistoryListModel,
  ExhibitionModel,
  SelectOption
} from './exhibition.interface';

import { QuestionnaireDataInput } from '../customization/customization.interfaces';
import { createSelector } from 'reselect';

export interface State {
  allExhibitions: ExhibitionModel[];
  selectedExhibitionId: number | null;
  allCountries: Array<string>;
  exhibitionHistoryList: ExhibitionHistoryListModel[];
  doubleClickUrls: DoubleClickModel;
  genericScripts: DoubleClickModel;
  firstLoadDone: boolean;
  titles: SelectOption[];
  professions: SelectOption[];
  departments: SelectOption[];
  occupationalGroups: SelectOption[];
  allTitles: QuestionnaireDataInput[];
  allProfessions: QuestionnaireDataInput[];
  allDepartments: QuestionnaireDataInput[];
  allOccupationalGroups: QuestionnaireDataInput[];
}

export const initialState: State = {
  allExhibitions: [],
  selectedExhibitionId: null,
  allCountries: [],
  exhibitionHistoryList: [],
  doubleClickUrls: null,
  genericScripts: null,
  firstLoadDone: false,
  titles: null,
  professions: null,
  departments: null,
  occupationalGroups: null,
  allTitles: null,
  allProfessions: null,
  allDepartments: null,
  allOccupationalGroups: null
};

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.SELECT:
      const exhibitionID = !!action.payload && !isNaN(Number(action.payload)) ? Number(action.payload) : null;

      // dont trigger new state if the id is the same as previous one
      if (state.selectedExhibitionId === exhibitionID) {
        return state;
      }

      return {
        ...state,
        selectedExhibitionId: exhibitionID
      };

    case ActionTypes.ADD_ALL_EXHIBITIONS_TO_LIST: {
      const exhibitions = action.payload as ExhibitionModel[];
      return {
        ...state,
        allExhibitions: exhibitions
      };
    }

    case ActionTypes.SET_EXHIBITION_HISTORY_LIST: {
      return {
        ...state,
        exhibitionHistoryList: action.payload
      };
    }

    case ActionTypes.FIRST_LOAD_DONE: {
      return {
        ...state,
        firstLoadDone: true
      };
    }

    case ActionTypes.SET_DOUBLE_CLICK_SCRIPTS: {
      const doubleClickUrls = action.payload;

      return {
        ...state,
        doubleClickUrls: doubleClickUrls
      };
    }
    case ActionTypes.SET_GENERIC_SCRIPTS: {
      const genericScripts = action.payload;

      return {
        ...state,
        genericScripts
      };
    }

    case ActionTypes.SET_TITLES: {
      const titles = action.payload;

      return {
        ...state,
        titles: titles
      };
    }

    case ActionTypes.SET_PROFESSIONS: {
      const professions = action.payload;

      return {
        ...state,
        professions: professions
      };
    }

    case ActionTypes.SET_DEPARTMENTS: {
      const departments = action.payload;

      return {
        ...state,
        departments: departments
      };
    }

    case ActionTypes.SET_OCCUPATIONAL_GROUPS: {
      const occupationalGroups = action.payload;

      return {
        ...state,
        occupationalGroups: occupationalGroups
      };
    }

    case ActionTypes.SET_ALL_TITLES: {
      const allTitles = action.payload;

      return {
        ...state,
        allTitles: allTitles
      };
    }

    case ActionTypes.SET_ALL_PROFESSIONS: {
      const allProfessions = action.payload;

      return {
        ...state,
        allProfessions: allProfessions
      };
    }

    case ActionTypes.SET_ALL_DEPARTMENTS: {
      const allDepartments = action.payload;

      return {
        ...state,
        allDepartments: allDepartments
      };
    }

    case ActionTypes.SET_ALL_OCCUPATIONAL_GROUPS: {
      const allOccupationalGroups = action.payload;

      return {
        ...state,
        allOccupationalGroups: allOccupationalGroups
      };
    }

    case ActionTypes.RESET_REDUCER:
      return initialState;

    case ActionTypes.PARTIAL_RESET_REDUCER:
      return {
        ...initialState,
        allExhibitions: state.allExhibitions,
        allTitles: state.allTitles,
        allProfessions: state.allProfessions,
        allDepartments: state.allDepartments,
        allOccupationalGroups: state.allOccupationalGroups,
        doubleClickUrls: state.doubleClickUrls
      };

    default: {
      return state;
    }
  }
}

/**
 * Because the data structure is defined within the reducer it is optimal to
 * locate our selector functions at this level. If store is to be thought of
 * as a database, and reducers the tables, selectors can be considered the
 * queries into said database. Remember to keep your selectors small and
 * focused so they can be combined and composed to fit each particular
 * use-case.
 */

export const getAllExhibitions = (state: State) => state.allExhibitions;
export const getDoubleClickScripts = (state: State) => state.doubleClickUrls;
export const getGenericScripts = (state: State) => state.genericScripts;
export const isFirstLoad = (state: State) => !state.firstLoadDone;
export const getSelectedExhibitionId = (state: State) =>
  state.selectedExhibitionId;
export const getExhibitionHistoryList = (state: State) =>
  state.exhibitionHistoryList;

export const getSelected = createSelector(
  getAllExhibitions,
  getSelectedExhibitionId,
  (allExhibitions, selectedId) => {
    return allExhibitions.find(exhibition => {
      return exhibition.id === Number(selectedId);
    });
  }
);

export const getAllCountries = (state: State) => state.allCountries;
export const getTitles = (state: State) => state.titles;
export const getProfessions = (state: State) => state.professions;
export const getDepartments = (state: State) => state.departments;
export const getOccupationalGroups = (state: State) => state.occupationalGroups;
export const getAllTitles = (state: State) => state.allTitles;
export const getAllProfessions = (state: State) => state.allProfessions;
export const getAllDepartments = (state: State) => state.allDepartments;
export const getAllOccupationalGroups = (state: State) => state.allOccupationalGroups;
