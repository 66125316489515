import { ElementRef, NgZone, OnInit, Renderer, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { combineLatest as observableCombineLatest, Subject, of } from 'rxjs';
import { catchError, filter, first, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from './shared/app-constants';
import { AppService } from './app.service';
import { CustomizationService } from './shared/services-with-reducers/customization/customization.service';
import { HelperService } from './shared/services-with-reducers/helpers/helper.service';
import { StatusBarService } from './status-bar/status-bar.service';
import { select, Store } from '@ngrx/store';
import { TranslationsService } from './_pages/translations/translations.service';
import { UserService } from './shared/services-with-reducers/user/user.service';
import * as exhibitionActions from './shared/services-with-reducers/exhibition/exhibition.actions';
import * as fromRoot from './app.reducer';
import * as helperActions from './shared/services-with-reducers/helpers/helper.actions';
import { environment } from '../environments/environment';
import { pageRunningInIframe } from './shared/app-utils';
import { getQueryParamsFromLocation } from './shared/app-utils';
import { StepsFormsService } from './shared/services-with-reducers/step-forms/steps-forms.service';
var AppComponent = /** @class */ (function () {
    // we need to ensure all services globaly used initialize their singleton instance
    function AppComponent(route, element, _translateService, _customizationService, _renderer, _router, _translationsService, _store, _appService, _statusBarService, _helperService, _userService, _ngZone, _http, _stepsFormsService) {
        var _this = this;
        this.route = route;
        this.element = element;
        this._translateService = _translateService;
        this._customizationService = _customizationService;
        this._renderer = _renderer;
        this._router = _router;
        this._translationsService = _translationsService;
        this._store = _store;
        this._appService = _appService;
        this._statusBarService = _statusBarService;
        this._helperService = _helperService;
        this._userService = _userService;
        this._ngZone = _ngZone;
        this._http = _http;
        this._stepsFormsService = _stepsFormsService;
        this.translationsLoaded = false;
        this.navigationStepsInitialBorder = 5 / 1.414;
        this.favicon = document.querySelector('#favicon');
        this.wasAppDisconnected = false;
        this.resetByQueryParams = [
            't',
            'u'
        ];
        this.unsubscribe = new Subject();
        this.reloadModalWindowOpen = false;
        this._router.events.pipe(filter(function (e) { return e instanceof NavigationEnd; })).subscribe(function () {
            _this._stepsFormsService.scrollToTop();
        });
        this.resetAppWithLocationParams().then();
        // handle online / offline states
        this._appService.online$
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(function (connection) {
            if (!connection) {
                _this._statusBarService.setStatus('You are disconnected', 'error');
                _this.wasAppDisconnected = true;
            }
            if (connection && _this.wasAppDisconnected) {
                _this._statusBarService.setStatus('You are online', 'success');
                _this.wasAppDisconnected = false;
            }
        });
        this._store
            .pipe(select(fromRoot.getReloadRequired), filter(function (reloadRequired) { return reloadRequired; }), takeUntil(this.unsubscribe))
            .subscribe(function (reloadRequired) {
            _this.reloadModalWindowOpen = reloadRequired;
        });
        this._ngZone.onError.pipe(takeUntil(this.unsubscribe)).subscribe(function (e) {
            _this._http
                .post("" + environment.protocol + environment.webApiUrl + "/audit-log/shop-error", {
                error: e.toString(),
                stack: e.stack,
                userAgent: _this._helperService.browserInfo()
            })
                .pipe(catchError(function (err) { return of([]); }))
                .subscribe();
        });
        // get dom component of the Angular app and save it reference for later use
        this._helperService.setRefsToAppComponent(this._renderer, this.element.nativeElement);
        this.isLoggedInAsAdmin$ = this._store.select(fromRoot.isLoggedInAsAdmin);
        this._store.dispatch(new helperActions.GetAllCountriesAction());
        this._store.dispatch(new exhibitionActions.GetAllTitles());
        this._store.dispatch(new exhibitionActions.GetAllProfessions());
        this._store.dispatch(new exhibitionActions.GetAllDepartments());
        this._store.dispatch(new exhibitionActions.GetAllOccupationalGroups());
        this._store
            .select(fromRoot.getLocalizedImages)
            .pipe(filter(function (images) { return !!images; }), takeUntil(this.unsubscribe))
            .subscribe(function (images) {
            _this.backgroundImage = images.background;
            _this.favicon.href = !!images.favicon ? images.favicon : "favicon.ico";
        });
        this.handleLanguages();
        // check whether user's token is still valid, if not log him/her out
        var authTimespanInMs = AppConstants.TOKEN_VALIDITY_IN_MINUTES * 60 * 1000;
        var interval;
        this._store
            .select(fromRoot.getLoginTimestamp)
            .pipe(filter(function (timestamp) { return timestamp !== null; }), takeUntil(this.unsubscribe))
            .subscribe(function (timestamp) {
            if (interval) {
                clearInterval(interval);
            }
            if (timestamp) {
                interval = setInterval(function () {
                    var now = Date.now();
                    if (timestamp + authTimespanInMs < now) {
                        clearInterval(interval);
                        _this._userService.forcelogout();
                    }
                }, 1000);
            }
        });
    }
    AppComponent.prototype.onResizeEvent = function ($event) {
        var _this = this;
        setTimeout(function () {
            _this.handleStepNavSticky();
        }, 300);
    };
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.checkPageRunningInIframe();
        window.onpopstate = function (event) {
            var isIE = !!navigator.userAgent.match(/Trident/);
            if (!isIE) {
                document.location.href = document.location.href;
            }
        };
        this._helperService.stepNavigationRendered().subscribe(function () {
            _this.handleStepNavSticky();
        });
        this.processDataForAppInit();
    };
    AppComponent.prototype.handleStepNavSticky = function () {
        var stepNavigationElement = this.element.nativeElement.querySelector("app-step-navigation");
        var navigationStepsElement = document.getElementById("navigation-steps");
        var topBarElement = this.element.nativeElement.querySelector("app-top-bar");
        if (window.innerWidth > 576 && !!stepNavigationElement && !!navigationStepsElement && !!topBarElement) {
            var top_1 = topBarElement.offsetHeight - Math.ceil(this.navigationStepsInitialBorder) + "px";
            stepNavigationElement.style.top = top_1;
        }
    };
    AppComponent.prototype.ngOnDestroy = function () {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    };
    AppComponent.prototype.processDataForAppInit = function () {
        var _this = this;
        this._store
            .select(fromRoot.getTranslations)
            .pipe(filter(function (data) { return !!data; }), takeUntil(this.unsubscribe))
            .subscribe(function (data) {
            _this.translationsLoaded = true;
        });
        this._store
            .select(fromRoot.getSelfRegistration)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(function (selfRegistration) {
            _this.isSelfRegistrationEnabled = selfRegistration;
        });
        // once styles in redux store are updated, ask customization service to add new style element with new styleset
        this._store
            .select(fromRoot.getExhibitionStyles)
            .pipe(filter(function (data) { return !!data && data.css !== null; }), takeUntil(this.unsubscribe))
            .subscribe(function (skinModel) {
            _this._customizationService.addCSS(skinModel.css, 'skin');
        });
        // once we switch language get new list of exhibitions
        this._store
            .select(fromRoot.getLanguage)
            .pipe(filter(function (lang) { return !!lang; }), takeUntil(this.unsubscribe))
            .subscribe(function (lang) {
            _this._store.dispatch(new exhibitionActions.GetListOfAllExhibitions(lang));
        });
    };
    AppComponent.prototype.handleLanguages = function () {
        var _this = this;
        observableCombineLatest([
            this._store.pipe(select(fromRoot.getUserLanguage)),
            this._store.pipe(select(fromRoot.getOperatorsSettings)),
            this._store.pipe(select(fromRoot.getExhibitionSettings)),
            this.route.queryParams
        ])
            .pipe(filter(function (data) {
            return !!data[1] || !!data[2];
        }), takeUntil(this.unsubscribe))
            .subscribe(function (data) {
            var userLang = data[0], operatorSettings = data[1], eventSettings = data[2], params = data[3];
            var supportedLanguages = [];
            if (!!eventSettings && eventSettings.languages.length) {
                supportedLanguages = eventSettings.languages;
            }
            else if (!!operatorSettings && operatorSettings.languages.length) {
                supportedLanguages = operatorSettings.languages;
            }
            else if (!!operatorSettings) {
                supportedLanguages.push(operatorSettings.defaultLanguage);
            }
            var langToUse = '';
            // browser preferred language
            var browserLang = _this._translateService.getBrowserLang();
            // params comming from url
            var paramLang = '';
            if (params && params.lang) {
                paramLang = params.lang.toLowerCase();
            }
            var supportsUserLang = supportedLanguages.indexOf(userLang) >= 0;
            var isSelfRegistration = _this._helperService.isSelfregistration();
            // set the language with priority fallback. First come first serve
            if (supportedLanguages.indexOf(paramLang) >= 0) {
                langToUse = paramLang;
                _this._userService.changePreferredLanguage(langToUse);
            }
            else if (isSelfRegistration && userLang && supportsUserLang) {
                langToUse = userLang;
            }
            else if (isSelfRegistration &&
                supportedLanguages.indexOf('de') >= 0) {
                // TODO this language ('de') should come in event settings probably (need to add option in BE admin first)
                langToUse = 'de';
            }
            else if (supportsUserLang) {
                langToUse = userLang;
            }
            else if (supportedLanguages.indexOf(browserLang) >= 0) {
                langToUse = browserLang;
            }
            else if (supportedLanguages.indexOf('en') >= 0) {
                langToUse = 'en';
            }
            else {
                langToUse = supportedLanguages[0];
            }
            // set allowed langs and activate default one
            _this._helperService.setLanguage(langToUse);
            _this._helperService.setSupportedLanguages(supportedLanguages);
        });
    };
    AppComponent.prototype.rightClick = function (event) {
        var _this = this;
        this.isLoggedInAsAdmin$
            .pipe(first(), takeUntil(this.unsubscribe))
            .subscribe(function (isAdmin) {
            if (!isAdmin) {
                return true;
            }
            var clickedElement = event.target;
            if (clickedElement.attributes.hasOwnProperty('data-translation')) {
                event.preventDefault();
                var key = clickedElement.attributes['data-translation'].value;
                _this._translationsService.activeTranslation = key;
                if (_this._router.url !== '/translations') {
                    _this._router.navigate(['/translations']);
                }
            }
            else {
                var closest = clickedElement.closest('[data-translation-includes-children]');
                if (closest) {
                    event.preventDefault();
                    var key = closest.attributes['data-translation'].value;
                    _this._translationsService.activeTranslation = key;
                    if (_this._router.url !== '/translations') {
                        _this._router.navigate(['/translations']);
                    }
                }
            }
            //for firefox buttons
            if (clickedElement.classList.contains('button')) {
                event.preventDefault();
                var key = clickedElement.firstElementChild.attributes['data-translation']
                    .value;
                _this._translationsService.activeTranslation = key;
                _this._router.navigate(['/translations']);
            }
        });
    };
    /**
     * Reset App if location contains specific parameter.
     * We need to get the queryParams immediately (synchronously) therefore we are not taking it from ActivatedRoute,
     *  as initial value of queryParams from ActivatedRoute is an emty Object
     */
    AppComponent.prototype.resetAppWithLocationParams = function () {
        var loacationQueryParams = getQueryParamsFromLocation();
        var resolutionPromise = new Promise(function (resolve) {
            resolve(null);
        });
        var shouldResetAppState = false;
        var _loop_1 = function (key) {
            var queryParam = loacationQueryParams[key];
            var resetByQueryParam = this_1.resetByQueryParams.find(function (resetByQueryParam) { return resetByQueryParam[key]; });
            var resetByQueryParamValue = resetByQueryParam && resetByQueryParam[key];
            // reset on specific queryparam value
            if (resetByQueryParamValue && resetByQueryParamValue == queryParam) {
                shouldResetAppState = true;
            }
            // reset if queryParam is present
            else if (this_1.resetByQueryParams &&
                this_1.resetByQueryParams.indexOf(key) !== -1) {
                shouldResetAppState = true;
            }
        };
        var this_1 = this;
        for (var key in loacationQueryParams) {
            _loop_1(key);
        }
        if (shouldResetAppState) {
            resolutionPromise = this.resetAppReducers();
        }
        return resolutionPromise;
    };
    AppComponent.prototype.resetAppReducers = function () {
        var _this = this;
        var resetReducerPromise = new Promise(function (resolve) {
            _this._appService.resetReducers().subscribe(function () { return resolve(); });
        });
        return resetReducerPromise;
    };
    AppComponent.prototype.checkPageRunningInIframe = function () {
        this.pageRunningInIframe = pageRunningInIframe();
    };
    AppComponent.prototype.closeModalWindow = function (event) {
        event.preventDefault();
        event.stopPropagation();
        this.reloadModalWindowOpen = false;
        this._appService.resetReducersWithUser().subscribe(function () {
            window.location.reload();
            return;
        });
    };
    return AppComponent;
}());
export { AppComponent };
