<div class="ticket-header" *ngFor="let workshop of workshopsWithPrices">
  <span class="title black">{{
    workshop.name | translate | hideTranslation
  }}</span>
  <span class="count black">{{ workshop.count }}x</span>
  <span class="price black">{{
    workshop.price | currencySymbolGap: currencySettings
  }}</span>
  <span class="per-type-price price">{{
    (workshop.price * workshop.count).toFixed(2)
      | currencySymbolGap: currencySettings
  }}</span>
</div>
