<div class="translations-wrapper">
    <div
        class="translation-info"
        *ngIf="selectedEvent"
        data-translation="translations.working-on-event"
        [translate]="'translations.working-on-event'"
        [translateParams]="{ eventName: selectedEvent.eventName }"
    ></div>
    <div
        class="translation-info"
        *ngIf="!selectedEvent"
        data-translation="translations.working-on-default"
    >
        {{ 'translations.working-on-default' | translate | hideTranslation }}
    </div>

    <app-navigation-tabs
        *ngIf="tabs.length"
        [tabs]="tabs"
        (activeTabChanged)="onTabChange($event)"
        type="normal"
        [activeTab]="activeTab"
    ></app-navigation-tabs>

    <div *ngIf="translationTreeLoaded && !isSaving">
        <app-translation-input
            id="translations-container"
            [tree]="translationTree"
        ></app-translation-input>
    </div>

    <app-loader *ngIf="translationTreeLoaded && isSaving"></app-loader>

    <div class="translations-control">
        <button class="button" (click)="nextEmptyTranslation()">
            <span data-translation="translations.to-first-empty-translation">
                {{
                    'translations.to-first-empty-translation'
                        | translate
                        | hideTranslation
                }}
            </span>
        </button>
        <button class="button" (click)="openModalWindow('load')">
            <span data-translation="translations.copy-translations">
                {{
                    'translations.copy-translations'
                        | translate
                        | hideTranslation
                }}
                ({{ activeLang }})
            </span>
        </button>
        <button class="button" (click)="openModalWindow('save')">
            <span data-translation="translations.save-translation">
                {{
                    'translations.save-translation'
                        | translate
                        | hideTranslation
                }}
                ({{ activeLang }})
            </span>
        </button>

        <button class="button" (click)="goToDataProtectionModal()">
            <span data-translation="translations.data-protection">
                {{
                    'translations.data-protection' | translate | hideTranslation
                }}
            </span>
        </button>

        <button class="button" (click)="goToMaintenanceModal()">
            <span data-translation="translations.maintenance">
                {{ 'translations.maintenance' | translate | hideTranslation }}
            </span>
        </button>
    </div>

    <app-modal-window
        [(modalWindowActive)]="modalWindowOpen"
        [modalWindowTitle]="modalTitle"
        [translateTitle]="false"
    >
        <div modalWindow-body>
            <div
                class="tile"
                *ngFor="let exhibition of (exhibitions$ | async)"
                (click)="selectedTile(exhibition.id)"
                [ngClass]="{ highlight: selectedEventID === exhibition.id }"
            >
                <img class="tile-img" [src]="exhibition.eventLogo" alt />
                <div class="tile-title">{{ exhibition.eventName }}</div>
                <div class="tile-time">
                    {{ exhibition.startDate | localizedDate: 'MMM d, yyyy' }} -
                    {{ exhibition.endDate | localizedDate: 'MMM d, yyyy' }}
                </div>
            </div>

            <div
                class="tile"
                (click)="selectedTile('default')"
                [ngClass]="{ highlight: selectedEventID === 'default' }"
            >
                <div
                    class="tile-title"
                    data-translation="translations.default-translations"
                >
                    {{
                        'translations.default-translations'
                            | translate
                            | hideTranslation
                    }}
                </div>
            </div>
        </div>
        <button
            modalWindow-submit
            (click)="submitModalWindow($event)"
            class="button"
            [ngClass]="{ disabled: selectedEventID === null }"
        >
            <span [attr.data-translation]="'modalWindow.' + modalType">
                {{ 'modalWindow.' + modalType | translate | hideTranslation }}
            </span>
        </button>
        <button
            modalWindow-close
            (click)="closeModalWindow($event)"
            class="button button-option"
            style="color:currentColor;"
        >
            <span data-translation="modalWindow.close">
                {{ 'modalWindow.close' | translate | hideTranslation }}
            </span>
        </button>
    </app-modal-window>
    <div></div>
</div>
