var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import * as additionalServicesActions from '../additional-services/additional-services.actions';
import * as fromRoot from '../../../app.reducer';
import * as stepsActions from '../step-forms/steps-forms.actions';
import * as ticketActions from './ticket.actions';
import { HttpClient } from '@angular/common/http';
import { combineLatest as observableCombineLatest, of } from 'rxjs';
import { catchError, debounceTime, filter, first, map } from 'rxjs/operators';
import { ErrorHandlingService } from '../../error-handling/error-handling.service';
import { FormsService } from '../../forms/forms.service';
import { HelperService } from '../helpers/helper.service';
import { StatusBarService } from '../../../status-bar/status-bar.service';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { prepareTicketHolderData } from '../customization/forms/tickets-holder-data';
import { setTimeout } from 'core-js/library/web/timers';
import cloneDeep from 'lodash.clonedeep';
import { GtmService } from '../../gtm/gtmService';
import { consoleLog, getLocalStorageString, setLocalStorageObject } from '../../app-utils';
import { AppConstants } from '../../app-constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ngrx/store";
import * as i3 from "../../forms/forms.service";
import * as i4 from "../../../status-bar/status-bar.service";
import * as i5 from "../../error-handling/error-handling.service";
import * as i6 from "../helpers/helper.service";
import * as i7 from "@ngx-translate/core";
import * as i8 from "../../gtm/gtmService";
var TicketsService = /** @class */ (function () {
    function TicketsService(_http, _store, _formsService, _statusBarService, _errorHandlingService, _helperService, _translateService, _gtmService) {
        // once new voucher code was added and its count was set to 1 in reducer, we need to also create ticket holder for the ticket
        var _this = this;
        this._http = _http;
        this._store = _store;
        this._formsService = _formsService;
        this._statusBarService = _statusBarService;
        this._errorHandlingService = _errorHandlingService;
        this._helperService = _helperService;
        this._translateService = _translateService;
        this._gtmService = _gtmService;
        observableCombineLatest(this._store.select(fromRoot.getLastAddedVoucherTicket), this._store.select(fromRoot.getExhibitionSettings), this._store.select(fromRoot.getTickets))
            .pipe(filter(function (data) { return !!data[0] && !!data[1] && !!data[2]; }), debounceTime(50))
            .subscribe(function (data) {
            _this._store.dispatch(new ticketActions.RemoveLastVoucherAdded());
            var lastVoucherTicket = data[0], settings = data[1], ungroupedTickets = data[2];
            if (_this._helperService.isSelfregistration()) {
                _this.selectTicket(lastVoucherTicket);
            }
            else {
                // go thru tickets and sum price of selected ones.
                var numberOfSelectedVouchers_1 = 0;
                var numberOfSelectedLimitedVouchers_1 = 0;
                Object.keys(ungroupedTickets).forEach(function (key) {
                    if (ungroupedTickets[key].hasOwnProperty('voucherType')) {
                        numberOfSelectedVouchers_1 += ungroupedTickets[key].count;
                        if (ungroupedTickets[key].voucherType === 'limitedPromoCode') {
                            numberOfSelectedLimitedVouchers_1 += ungroupedTickets[key].count;
                        }
                    }
                });
                if (lastVoucherTicket.voucherType === 'oneTimeVoucher' || // there is no limitation for one time vouchers
                    (settings.limitPromoCodes > numberOfSelectedVouchers_1 &&
                        lastVoucherTicket.voucherType === 'promoCode') ||
                    (settings.limitLimitedPromocodes >
                        numberOfSelectedLimitedVouchers_1 &&
                        lastVoucherTicket.voucherType === 'limitedPromoCode')) {
                    _this.ticketCounterChanged(lastVoucherTicket.uniqueId, 1, false);
                }
                else {
                    _this.ticketCounterChanged(lastVoucherTicket.uniqueId, 0, false);
                }
            }
        });
    }
    TicketsService.prototype.getVoucherTicket = function (voucherRedeem) {
        var getParamsArray = [];
        if (this._helperService.isSelfregistration()) {
            getParamsArray.push('sr=true');
        }
        getParamsArray.push("uuid=" + voucherRedeem.uuid);
        var getParams = '?' + getParamsArray.join('&');
        return this._http.get("" + environment.protocol + environment.webApiUrl + "/voucher/redeem-voucher/" + voucherRedeem.eventId + "/" + voucherRedeem.voucherCode + getParams
        //`${environment.apiUrl}/voucher/redeem-voucher/${voucherRedeem.eventId}/${voucherRedeem.voucherCode}`,
        );
    };
    TicketsService.prototype.getVoucherDetails = function (voucherRedeem) {
        var getParamsArray = [];
        if (this._helperService.isSelfregistration()) {
            getParamsArray.push('sr=true');
        }
        var getParams = '?' + getParamsArray.join('&');
        return this._http.get("" + environment.protocol + environment.webApiUrl + "/voucher/voucher-details/" + voucherRedeem.eventId + "/" + voucherRedeem.voucherCode + getParams);
    };
    TicketsService.prototype.postTicketBooking = function (booking) {
        if (!booking.orderTicketBooking) {
            return of({
                groupId: booking.groupId,
                ticketTypeId: booking.ticketTypeId,
                ticketUniqueId: booking.uniqueId,
                availableTickets: 0,
                count: booking.count,
                ticketLimit: 0,
                isTicketSold: false,
                ticketPersonId: booking.ticketPersonId,
                sharedLimitTicketCount: booking.sharedLimitTicketCount
            });
        }
        booking.count = booking.count + booking.sharedLimitTicketCount;
        return this._http.post("" + environment.protocol + environment.webApiUrl + "/order/ticket-booking", 
        //`${environment.apiUrl}/order/ticket-booking`,
        booking);
    };
    TicketsService.prototype.releaseAllVouchersAndTickets = function () {
        // before we reset reducers, if there are any claimed vouchers nad tickets, we need to release them
        var _this = this;
        observableCombineLatest([
            this._store.pipe(select(fromRoot.getSelectedExhibitionId)),
            this._store.pipe(select(fromRoot.getVoucherTickets)),
            this._store.pipe(select(fromRoot.getOrderUuid)),
            this._store.pipe(select(fromRoot.getTickets))
        ])
            .filter(function (data) {
            return !!data[0];
        })
            .first()
            .subscribe(function (data) {
            var id = data[0], voucherTickets = data[1], uuid = data[2], tickets = data[3];
            // release vouchers
            if (voucherTickets) {
                voucherTickets.forEach(function (voucherTicket) {
                    var releaseVoucher = {
                        eventId: id,
                        voucherCode: voucherTicket.voucherCode,
                        countryCode: _this._translateService.currentLang,
                        uuid: uuid,
                        ticketPersonId: voucherTicket.ticketPersonId
                    };
                    _this._store.dispatch(new ticketActions.ReleaseVoucher(releaseVoucher));
                });
            }
            // release tickets
            if (tickets) {
                var releasedTicketIds_1 = [];
                var releaseTicketIds_1 = Object.keys(tickets)
                    .map(function (key) { return tickets[key]; })
                    .reduce(function (releaseTicketIds, ticket) {
                    var ticketUniqueId = tickets[ticket.uniqueId].hasOwnProperty('voucherCode') ? ticket.uniqueId : ticket.groupId + "_" + ticket.id;
                    if (!releaseTicketIds.includes(ticketUniqueId) && ticket.count > 0) {
                        releaseTicketIds.push(ticketUniqueId);
                    }
                    return releaseTicketIds;
                }, []);
                Object.keys(tickets).forEach(function (key) {
                    var ticket = tickets[key];
                    var ticketUniqueId = ticket.hasOwnProperty('voucherCode') ? ticket.uniqueId : ticket.groupId + "_" + ticket.id;
                    var releaseCurrentTicket = releaseTicketIds_1.includes(ticketUniqueId);
                    var isCurrentTicketNotReleased = !releasedTicketIds_1.includes(ticketUniqueId);
                    if (ticket.count || releaseCurrentTicket) {
                        var postTicketBooking = {
                            eventId: Number(id),
                            uuid: uuid,
                            count: 0,
                            groupId: ticket.groupId,
                            ticketTypeId: ticket.id,
                            ticketPersonId: ticket.ticketPersonId,
                            uniqueId: ticket.uniqueId,
                            sharedLimitTicketCount: 0,
                            orderTicketBooking: releaseCurrentTicket && isCurrentTicketNotReleased
                        };
                        releasedTicketIds_1.push(ticketUniqueId);
                        var voucherCode = '';
                        if (ticket.hasOwnProperty('voucherCode')) {
                            voucherCode = ticket.voucherCode;
                        }
                        _this._store.dispatch(new ticketActions.PostTicketBooking({
                            postTicketBooking: postTicketBooking,
                            voucherCode: voucherCode,
                            ticketLimit: ticket.ticketLimit,
                            successCallback: function () { }
                        }));
                    }
                });
            }
        });
    };
    TicketsService.prototype.releaseVoucherCode = function (voucherRedeem) {
        var selfReg = '';
        if (this._helperService.isSelfregistration()) {
            selfReg = '&sr=true';
        }
        var ticketPersonId = "";
        if (!!voucherRedeem.ticketPersonId) {
            ticketPersonId = "&ticketPersonId=" + voucherRedeem.ticketPersonId;
        }
        return this._http
            .post("" + environment.protocol + environment.webApiUrl + "/voucher/reset-event-voucher/" + voucherRedeem.eventId + "/" + voucherRedeem.voucherCode + "?uuid=" + voucherRedeem.uuid + selfReg + ticketPersonId, {})
            .pipe(map(function (res) {
            return true;
        }));
    };
    TicketsService.prototype.getTicketTypes = function (eventId, preferedTicketPersonNr, preferedTicketGroupNr) {
        var selfReg = '';
        if (this._helperService.isSelfregistration()) {
            selfReg = '&sr=true';
        }
        return this._http.get("" + environment.protocol + environment.webApiUrl + "/tickets/" + eventId + "/grouped-by-section?preferredTicketPersonNr=" + preferedTicketPersonNr + "&preferredTicketGroupNr=" + preferedTicketGroupNr + selfReg);
    };
    TicketsService.prototype.getVisitorFieldsForPersonalization = function (ticketId) {
        return this._http.get("" + environment.protocol + environment.webApiUrl + "/user/entry-tickets/" + ticketId);
    };
    TicketsService.prototype.postVisitorFieldsFromPersonalization = function (ticketId, data) {
        return this._http.post("" + environment.protocol + environment.webApiUrl + "/user/entry-tickets/" + ticketId, data);
    };
    TicketsService.prototype.postChangeTicketContingentDay = function (ticketId, day) {
        return this._http.post("" + environment.protocol + environment.webApiUrl + "/user/entry-tickets/" + ticketId + "/change-day-booking", {
            day: day
        });
    };
    TicketsService.prototype.getParkingTicketFee = function (eventId, ticketGroup, ticketPerson, since, until, validFormId) {
        var _this = this;
        return this._http
            .get("" + environment.protocol + environment.webApiUrl + "/event/" + eventId + "/ticket-groups/" + ticketGroup + "/ticket-persons/" + ticketPerson + "/parking-fee?since=" + since + "&until=" + until, { observe: 'response' })
            .pipe(map(function (res) {
            return res;
        }), catchError(function (e) {
            var storedParkingTickets = getLocalStorageString(AppConstants.parkingTicketsReducer);
            var parsedParkingTickets = storedParkingTickets && JSON.parse(storedParkingTickets);
            parsedParkingTickets[validFormId] = {
                since: null,
                until: null,
                price: 0
            };
            parsedParkingTickets = __assign({}, parsedParkingTickets);
            setLocalStorageObject(AppConstants.parkingTicketsReducer, parsedParkingTickets);
            return _this._errorHandlingService.errorHandler(e);
        }));
    };
    TicketsService.prototype.postBookTicketForDay = function (data) {
        return this._http.post("" + environment.protocol + environment.webApiUrl + "/order/day-booking", data);
    };
    TicketsService.prototype.postReleaseTicketForDay = function () {
        var _this = this;
        return this._store.pipe(select(fromRoot.getOrderUuid), filter(function (data) { return !!data; }), first()).subscribe(function (uuid) {
            return _this._http.post("" + environment.protocol + environment.webApiUrl + "/order/day-booking/" + uuid + "/release", {}).subscribe(function () {
                consoleLog("Releasing contingent tickets for uuid: " + uuid);
            });
        });
    };
    TicketsService.prototype.getSynchronizedBarcoodes = function (eventId) {
        var _this = this;
        return this._http
            .get("" + environment.protocol + environment.webApiUrl + "/tickets/" + eventId + "/sync-barcodes", { observe: 'response' })
            .pipe(map(function (res) {
            try {
                return res.status;
            }
            catch (err) {
                _this._statusBarService.setStatus(err, 'error');
            }
        }), catchError(function (e) {
            return _this._errorHandlingService.errorHandler(e);
        }));
    };
    TicketsService.prototype.postTicketHolderForm = function (data) {
        var selfReg = '';
        if (this._helperService.isSelfregistration()) {
            selfReg = '?sr=true';
        }
        return this._http.post("" + environment.protocol + environment.webApiUrl + "/person/visitors-form" + selfReg, data);
    };
    TicketsService.prototype.saveTicketHolderQuestionnaire = function (questionnarireData, userId, eventId) {
        return this._http.post("" + environment.protocol + environment.webApiUrl + "/user/" + userId + "/event/" + eventId + "/visitor-questionnaire", questionnarireData);
    };
    TicketsService.prototype.getTicketByHolder = function (hash) {
        return this._http.get("" + environment.protocol + environment.webApiUrl + "/person/ticket-by-holder/" + hash);
    };
    TicketsService.prototype.downloadTicket = function (hash) {
        return this._http.get("" + environment.protocol + environment.webApiUrl + "/person/download-ticket/" + hash, {
            responseType: 'blob'
        });
    };
    TicketsService.prototype.downloadMobileTicket = function (hash) {
        return this._http.get("" + environment.protocol + environment.webApiUrl + "/person/download-mobile-ticket/" + hash, {
            responseType: 'blob'
        });
    };
    TicketsService.prototype.downloadPassBook = function (hash) {
        return this._http.get("" + environment.protocol + environment.webApiUrl + "/person/download-passbook/" + hash, {
            responseType: 'blob'
        });
    };
    TicketsService.prototype.getTicketHolderQuestionnaire = function (eventId, args) {
        var selfReg = '';
        if (this._helperService.isSelfregistration()) {
            selfReg = '&sr=true';
        }
        var ticketPersonIds = args.reduce(function (accu, curr) {
            return accu + ("&ticketPersonIds=" + curr);
        }, '');
        return this._http.get("" + environment.protocol + environment.webApiUrl + "/event/" + eventId + "/visitor-questionnaire?" + selfReg + ticketPersonIds);
    };
    TicketsService.prototype.checkDailyTicketPerEmailLimit = function (validateDailyTicketPerEmailLimitBody) {
        return this._http.post("" + environment.protocol + environment.webApiUrl + "/order/validators/daily-ticket-per-email-limit", validateDailyTicketPerEmailLimitBody);
    };
    TicketsService.prototype.ticketCounterChanged = function (ticketUniqueId, count, decrease) {
        var _this = this;
        observableCombineLatest([
            this._store.pipe(select(fromRoot.getTickets)),
            this._store.pipe(select(fromRoot.getTicketHolderIndexes))
        ])
            .pipe(first())
            .subscribe(function (data) {
            var flatTickets = data[0], ticketHolderIndexes = data[1];
            var previousCountValue = flatTickets[ticketUniqueId].count;
            var newCountValue = count;
            var ticketDifference = newCountValue - previousCountValue;
            var numberOfAlreadyAssignedHolderIndexes = ticketHolderIndexes != null ? Object.keys(ticketHolderIndexes)
                .map(function (index) { return ticketHolderIndexes[index]; })
                .filter(function (indexTicketUniqueId) { return indexTicketUniqueId == ticketUniqueId; }).length : 0;
            var isTicketAddedByPackageCounter = numberOfAlreadyAssignedHolderIndexes == 0 && ticketDifference == 0 && !decrease;
            var ticketsAdded = isTicketAddedByPackageCounter ? newCountValue : ticketDifference;
            var holdersIndexes = _this.getHolderIndexesByTicketUniqueId(flatTickets, ticketUniqueId);
            var ticket = flatTickets[ticketUniqueId];
            if (!decrease) {
                _this._gtmService.pushAddToCart(ticket, newCountValue);
            }
            else {
                _this._gtmService.pushRemoveFromCart(ticket, newCountValue);
            }
            if (!flatTickets[ticketUniqueId].parking) {
                // there is ticket removed (ticket count decreased)
                if (decrease || ticketsAdded < 0) {
                    // for each ticket removed
                    Array.from(Array(Math.abs(ticketsAdded)).keys()).forEach(function () {
                        var toBeRemovedTicketIndex = holdersIndexes.pop();
                        //as there are various reducers interactions when removing ticket, we need to do it one by one in different redux cycles
                        setTimeout(function (_a) {
                            var removedIndex = _a[0];
                            _this._store.dispatch(new ticketActions.RemoveTicketHolderIndex(ticketUniqueId));
                            _this.removeTicketHolder(removedIndex);
                        }, 0, [toBeRemovedTicketIndex]);
                    });
                    // there is new ticket added (ticket count increased)
                }
                else {
                    // for each ticket added
                    Array.from(Array(Math.abs(ticketsAdded)).keys()).forEach(function () {
                        //as there are various reducers interactions when removing ticket, we need to do it one by one in different redux cycles
                        setTimeout(function () {
                            _this._store.dispatch(new ticketActions.SetTicketHolderIndex(ticketUniqueId));
                        }, 0);
                    });
                }
            }
            _this.updateTicketsCount(flatTickets, ticketUniqueId, holdersIndexes, count);
        });
    };
    TicketsService.prototype.getTicketByVoucherCode = function (voucherCode, flatTickets) {
        var ticketUniqueId = Object.keys(flatTickets).find(function (ticketUniqueId) {
            return flatTickets[ticketUniqueId].voucherCode === voucherCode;
        });
        return flatTickets[ticketUniqueId];
    };
    TicketsService.prototype.getHolderIndexesByTicketUniqueId = function (flatTickets, ticketUniqueId) {
        return flatTickets[ticketUniqueId].holdersIndexes.slice();
    };
    TicketsService.prototype.updateTicketsCount = function (flatTickets, ticketUniqueId, holderIndexes, value) {
        // update the tickets flat object with new counts of the tickets, as comming from the counter component
        var newValue = {};
        newValue[ticketUniqueId] = __assign({}, flatTickets[ticketUniqueId], { count: value, holdersIndexes: holderIndexes });
        var newticketCounts = Object.assign({}, flatTickets, newValue);
        this._store.dispatch(new ticketActions.SetTickets(newticketCounts));
    };
    TicketsService.prototype.addTicketHolder = function (addedTicketIndex) {
        var _this = this;
        observableCombineLatest([
            this._store.pipe(select(fromRoot.getTicketsCount)),
            this._store.pipe(select(fromRoot.getExhibitionSettings)),
            this._store.pipe(select(fromRoot.getTicketSelectedSendingOptions)),
            this._store.pipe(select(fromRoot.getTitles)),
            this._store.pipe(select(fromRoot.getProfessions)),
            this._store.pipe(select(fromRoot.getDepartments)),
            this._store.pipe(select(fromRoot.getOccupationalGroups))
        ])
            .pipe(filter(function (data) {
            var ticketsCount = data[0], settings = data[1], selectedTicketSendingOptions = data[2], titles = data[3], professions = data[4], departments = data[5], occupationalGroups = data[6];
            return ticketsCount !== null &&
                !!settings &&
                !!selectedTicketSendingOptions &&
                !!titles &&
                !!professions &&
                !!departments &&
                !!occupationalGroups;
        }), first())
            .subscribe(function (_a) {
            /* we need to avoid circular sore triggers (read > write > read > ...),
            thats why we load two following data sets from inside and not as part of combined observable */
            var ticketsCount = _a[0], settings = _a[1], selectedTicketSendingOptions = _a[2], titles = _a[3], professions = _a[4], departments = _a[5], occupationalGroups = _a[6];
            var ticketHolderAdditionalData = _this.getTicketHolderAdditionalData();
            // create new object with additional data for workshops and menus
            ticketHolderAdditionalData[addedTicketIndex] = {
                workshops: [],
                menus: []
            };
            _this._store.dispatch(new stepsActions.SetTicketHolderAdditionalData(ticketHolderAdditionalData));
            // new forms to be created (create new ticket holder)
            var inputSet = prepareTicketHolderData(settings, selectedTicketSendingOptions, titles, professions, departments, occupationalGroups);
            var stepsFormsActionName = [
                'personal',
                "ticketHolder_" + addedTicketIndex
            ];
            var buyerHolderPayload = {
                formInfo: stepsFormsActionName,
                index: addedTicketIndex,
                inputSet: {
                    rerender: false,
                    list: inputSet
                }
            };
            _this._store.dispatch(new stepsActions.SetInputs(buyerHolderPayload));
            _this._store.dispatch(new stepsActions.SetTicketHolderFormValidity({ formInfo: stepsFormsActionName, valid: false }));
            var formValid = false;
            if (_this._helperService.isSelfregistration() ||
                !settings.isTicketHolderVisible ||
                (ticketsCount === 1 &&
                    selectedTicketSendingOptions === 'mobilePerOwner')) {
                formValid = true;
            }
            var anyFieldRequired = inputSet.find(function (input) {
                return input.required && !input.hidden && !input.disabled;
            }) || selectedTicketSendingOptions === 'ticketRetrivalLink';
            formValid = !anyFieldRequired || formValid;
            _this._formsService.setFormValidity(formValid, null, stepsFormsActionName);
        });
    };
    TicketsService.prototype.checkIfAnonymousTicketTaken = function (ungroupedTickets) {
        return !!ungroupedTickets && Object.keys(ungroupedTickets).some(function (ticket) {
            var ungroupedTicket = ungroupedTickets[ticket];
            if (ungroupedTicket.personalizationType === 'anonymous' && ungroupedTicket.voucherCode) {
                return true;
            }
        });
    };
    TicketsService.prototype.removeTicketHolder = function (toBeRemovedTicketIndex) {
        var _this = this;
        observableCombineLatest(this._store.select(fromRoot.getTicketHolderAdditionalData), this._store.select(fromRoot.getSelectedExhibitionId), this._store.select(fromRoot.getOrderUuid))
            .pipe(filter(function (data) {
            return !!data[0] && !!data[1] && !!data[2];
        }), first())
            .subscribe(function (data) {
            var ticketHolderAdditionalData = data[0], eventId = data[1], orderUuid = data[2];
            var stepsFormsActionName = [
                'personal',
                "ticketHolder_" + toBeRemovedTicketIndex
            ];
            // remove the form and its input sets
            _this._store.dispatch(new stepsActions.RemoveForm(stepsFormsActionName));
            _this._store.dispatch(new stepsActions.RemoveTicketHolderForm(stepsFormsActionName));
            if (ticketHolderAdditionalData[toBeRemovedTicketIndex] &&
                ticketHolderAdditionalData[toBeRemovedTicketIndex].workshops) {
                ticketHolderAdditionalData[toBeRemovedTicketIndex].workshops.map(function (workshopID) {
                    var postData = {
                        eventId: eventId,
                        workshopId: workshopID,
                        seats: -1,
                        uuid: orderUuid,
                        ticketHolderId: toBeRemovedTicketIndex
                    };
                    _this._store.dispatch(new additionalServicesActions.PostWorkshopBooking(postData, null));
                });
            }
            // just set the validity of the removed form set to be valid (true)
            _this._formsService.removeStepValidation(stepsFormsActionName);
            // remove the addition (workshops and menu) data related to the ticketIndex and its ticket holder
            delete ticketHolderAdditionalData[toBeRemovedTicketIndex];
            _this._store.dispatch(new stepsActions.SetTicketHolderAdditionalData(ticketHolderAdditionalData));
            /* be sure after removing a ticket to start the carousel on position one
               oterwise it can happen that it want start on slide which no longer exist */
            _this._store.dispatch(new ticketActions.SetActiveHolderSlideIndex(0));
        });
    };
    TicketsService.prototype.getTicketHolderAdditionalData = function () {
        var state;
        this._store
            .pipe(select(fromRoot.getTicketHolderAdditionalData), first())
            .subscribe(function (data) { return (state = data || {}); });
        return state;
    };
    TicketsService.prototype.selectTicket = function (ticket) {
        var _this = this;
        this._store
            .select(fromRoot.getTickets)
            .pipe(filter(function (tickets) {
            return !!tickets;
        }), first())
            .subscribe(function (ungroupedTickets) {
            // create a deep copy so we dont modify the original object in store
            ungroupedTickets = cloneDeep(ungroupedTickets);
            if (ticket.count) {
                _this.ticketCounterChanged(ticket.uniqueId, 0, true);
            }
            else {
                Object.keys(ungroupedTickets).forEach(function (uniqueId) {
                    if (ungroupedTickets[uniqueId] &&
                        ungroupedTickets[uniqueId].count) {
                        //we have to comment out the setTimeout function as otherwise adding a newly selected ticket at SR would be executed before removing the previously selected ticket
                        //which would mess up the lastTicketIndex:
                        //setTimeout(() => {
                        _this.ticketCounterChanged(uniqueId, 0, true);
                        //});
                    }
                });
                setTimeout(function () {
                    var ungroupedTicket = ungroupedTickets[ticket.uniqueId];
                    if (ungroupedTicket && ungroupedTicket.availableTickets > 0) {
                        _this.ticketCounterChanged(ticket.uniqueId, 1, false);
                    }
                });
            }
        });
    };
    TicketsService.prototype.removeHoldersAndBookings = function () {
        var _this = this;
        this._store.pipe(select(fromRoot.getTicketHolderInputSets)).first().subscribe(function (ticketHolders) {
            return ticketHolders.forEach(function (ticketHolder) { return _this.removeTicketHolder(ticketHolder.index); });
        });
        this._store.dispatch(new ticketActions.RemoveTicketBookings());
        this._store.dispatch(new stepsActions.SetAddressCheckbox({
            checkedSlideIndex: null,
            isAddressCopied: false
        }));
        this._store.dispatch(new stepsActions.SetBuyerVisitorCheckbox({
            buyerVisitorCheckedSlideIndex: null,
            isBuyerVisitorChecked: false,
            showVisitorQuestionnaire: false
        }));
    };
    TicketsService.ngInjectableDef = i0.defineInjectable({ factory: function TicketsService_Factory() { return new TicketsService(i0.inject(i1.HttpClient), i0.inject(i2.Store), i0.inject(i3.FormsService), i0.inject(i4.StatusBarService), i0.inject(i5.ErrorHandlingService), i0.inject(i6.HelperService), i0.inject(i7.TranslateService), i0.inject(i8.GtmService)); }, token: TicketsService, providedIn: "root" });
    return TicketsService;
}());
export { TicketsService };
