import {
  AddTicketBookingModel,
  PostTicketBookingModel,
  RedeemVoucherModel,
  ProductGroupModel,
  TicketHolderModel,
  TicketModel,
  TicketVoucherModel,
  VisitorFormModel
} from './ticket.interface';
import { SendingOptionModel } from './ticket.interface';

import { Action } from '@ngrx/store';
import { InputBase } from '../../forms/inputs/input-base.class';
import { QuestionnaireSaveModel } from '../user/user.interface';

export enum ActionTypes {
  POST_TICKET_BOOKING = '[Ticket] Post tickets booking',
  ADD_TICKET_BOOKING = '[Ticket] add ticket booking',
  REMOVE_TICKET_BOOKINGS = '[Ticket] remove ticket bookings',
  LOAD_CLAIMING_TICKET_HOLDER = '[Ticket] Load ticket holder',
  SET_TICKET_HOLDER_INPUTS = '[Ticket] Set ticket holder inputs',
  DOWNLOAD_TICKET = '[Ticket] Download ticket',
  DOWNLOAD_MOBILE_TICKET = '[Ticket] Download mobile ticket',
  DOWNLOAD_PASS_BOOK = '[Ticket] Download pass book',
  SET_TICKET_HOLDER = '[Ticket] Set ticket holder',
  SET_ACTIVE_HOLDER_SLIDE_INDEX = '[Ticket] Set active holder slide Index in carousel',
  GET_TICKET_HOLDER_QUESTIONNAIRE = '[Ticket] Get ticket holder questionnaire',
  SET_TICKET_HOLDER_QUESTIONNAIRE = '[Ticket] Set ticket holder questionnaire',
  POST_TICKET_HOLDER_QUESTIONNAIRE = '[Ticket] Post Ticketholder Questionnaire via API',
  POST_TICKET_HOLDER_FORM = '[Ticket] Post Ticketholder form - secretary function',
  SET_POST_TICKET_HOLDER_FORM_RESULT = '[Ticket] Set result of Post call submitting Ticketholder form - secretary function',
  SET_CLAIMED_TICKET_HASH = '[Ticket] Set claimed ticket hash',
  SET_CLAIMED_TICKET_HASH_VALID = '[Ticket] Set claimed ticket hash valid',
  SET_SELECTED_TICKETS = '[Ticket] Set selected tickets',
  SET_SELECTED_PARKING_TICKETS = '[Ticket] Set selected parking tickets',
  ADD_VOUCHER_TICKET = '[Ticket] Add voucher ticket',
  SET_LAST_TICKET_INDEX = '[Ticket] Set last ticket index',
  SET_TICKETS_TYPES = '[Ticket] Set tyckets types',
  GET_TICKETS_TYPES = '[Ticket] Get tickets types from API',
  SET_SENDING_OPTIONS = '[Ticket] Set sending options',
  GET_VOUCHER = '[Ticket] Get Voucher',
  RELEASE_VOUCHER = '[Ticket] Release Voucher via API',
  REMOVE_VOUCHER = '[Ticket] Remove Voucher from redux store',
  REMOVE_LAST_VOUCHER_ADDED = '[Ticket] Remove last voucher added',
  SET_TICKET_SENDING_OPTIONS = '[Ticket] Set sending options',
  SET_LAST_PACKAGE_INDEX = '[Package] Set last package index',
  SET_IS_PACKAGE_LOADING = '[Package] Set is package loading',
  ADD_TICKET_HOLDER_INDEXES = '[Ticket] Add ticket holder indexes',
  SET_TICKET_HOLDER_INDEX = '[Ticket] Set ticket holder index',
  REMOVE_TICKET_HOLDER_INDEX = '[Ticket] Remove ticket holder index',
  SET_IS_ANONYMOUS_TICKET_TAKEN = '[Ticket] Set is anonymous ticket taken',
  SET_ARE_TICKETS_SENT = '[Ticket] Set are tickets sent on invoice step',
  RESET_REDUCER = '[Ticket] reset reducer'
}

/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions.
 *
 * See Discriminated Unions: https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions
 */

export class LoadTicketHolder implements Action {
  readonly type = ActionTypes.LOAD_CLAIMING_TICKET_HOLDER;
  constructor(public payload: string) {}
}

export class DownloadTicket implements Action {
  readonly type = ActionTypes.DOWNLOAD_TICKET;
  constructor(public payload: string) {}
}

export class DownloadMobileTicket implements Action {
  readonly type = ActionTypes.DOWNLOAD_MOBILE_TICKET;
  constructor(public payload: string) {}
}

export class DownloadPassBook implements Action {
  readonly type = ActionTypes.DOWNLOAD_PASS_BOOK;
  constructor(public payload: string) {}
}

export class SetTicketHolder implements Action {
  readonly type = ActionTypes.SET_TICKET_HOLDER;
  constructor(public payload: TicketHolderModel) {}
}

export class SetTicketHolderInputs implements Action {
  readonly type = ActionTypes.SET_TICKET_HOLDER_INPUTS;
  constructor(public payload: InputBase<any>[]) {}
}

export class SetTickets implements Action {
  readonly type = ActionTypes.SET_SELECTED_TICKETS;
  constructor(public payload: TicketModel) {}
}

export class SetParkingTickets implements Action {
  readonly type = ActionTypes.SET_SELECTED_PARKING_TICKETS;
  constructor(public payload: Object) {}
}

export class AddVoucherTicket implements Action {
  readonly type = ActionTypes.ADD_VOUCHER_TICKET;
  constructor(
    public payload: {
      voucherTicket: TicketVoucherModel;
      selfRegistration: boolean;
    }
  ) {}
}

export class AddTicketBooking implements Action {
  readonly type = ActionTypes.ADD_TICKET_BOOKING;
  constructor(public payload: AddTicketBookingModel) {}
}

export class RemoveTicketBookings implements Action {
  readonly type = ActionTypes.REMOVE_TICKET_BOOKINGS;
}

export class SetActiveHolderSlideIndex implements Action {
  readonly type = ActionTypes.SET_ACTIVE_HOLDER_SLIDE_INDEX;
  constructor(public payload: number) {}
}

export class PostTicketHolderQuestionnaire implements Action {
  readonly type = ActionTypes.POST_TICKET_HOLDER_QUESTIONNAIRE;
  constructor(
    public payload: {
      questionnaire: QuestionnaireSaveModel[];
      userId: number;
      eventId: number;
    }
  ) {}
}

export class GetTicketHolderQuestionnaire implements Action {
  readonly type = ActionTypes.GET_TICKET_HOLDER_QUESTIONNAIRE;
  constructor(
    public payload: {
      eventId: number;
      ticketPersonIds: number[];
    }
  ) {}
}

export class SetTicketHolderQuestionnaire implements Action {
  readonly type = ActionTypes.SET_TICKET_HOLDER_QUESTIONNAIRE;
  constructor(public payload: InputBase<any>[]) {}
}

export class SetClaimedTicketHash implements Action {
  readonly type = ActionTypes.SET_CLAIMED_TICKET_HASH;
  constructor(public payload: string) {}
}

export class SetTicketClaimedHashValid implements Action {
  readonly type = ActionTypes.SET_CLAIMED_TICKET_HASH_VALID;
  constructor(public payload: boolean) {}
}

export class PostTicketHolderForm implements Action {
  readonly type = ActionTypes.POST_TICKET_HOLDER_FORM;
  constructor(public payload: VisitorFormModel) {}
}

export class PostTicketBooking implements Action {
  readonly type = ActionTypes.POST_TICKET_BOOKING;
  constructor(
    public payload: {
      postTicketBooking: PostTicketBookingModel;
      voucherCode: string;
      ticketLimit: number;
      successCallback: Function;
    }
  ) {}
}

export class SetPostTicketHolderFormResult implements Action {
  readonly type = ActionTypes.SET_POST_TICKET_HOLDER_FORM_RESULT;
  constructor(public payload: boolean) {}
}

export class SetTicketsTypesAction implements Action {
  readonly type = ActionTypes.SET_TICKETS_TYPES;
  constructor(public payload: ProductGroupModel[], public skipInitialTicketTypesUpdate: boolean = false) {}
}

export class GetTicketsTypesAction implements Action {
  readonly type = ActionTypes.GET_TICKETS_TYPES;
  constructor(
    public payload: {
      eventId: number;
      preferedTicketPersonNr?: number;
      preferedTicketGroupNr?: number;
    }
  ) {}
}

export class SetSendingOptionsAction implements Action {
  readonly type = ActionTypes.SET_SENDING_OPTIONS;
  constructor(public payload: SendingOptionModel[]) {}
}

export class GetVoucher implements Action {
  readonly type = ActionTypes.GET_VOUCHER;
  constructor(
    public payload: {
      redeemVoucher: RedeemVoucherModel;
      releaseVouchers: RedeemVoucherModel[];
    }
  ) {}
}

export class ReleaseVoucher implements Action {
  readonly type = ActionTypes.RELEASE_VOUCHER;
  constructor(public payload: RedeemVoucherModel) {}
}

export class RemoveVoucher implements Action {
  readonly type = ActionTypes.REMOVE_VOUCHER;
  constructor(
    public payload: {
      voucherCode: string;
      skipAnonymousChecks: boolean;
      skipSettingIsAnonyTicketTaken?: boolean;
    }
  ) {}
}

export class RemoveLastVoucherAdded implements Action {
  readonly type = ActionTypes.REMOVE_LAST_VOUCHER_ADDED;
}

export class SetLastPackageIndex implements Action {
  readonly type = ActionTypes.SET_LAST_PACKAGE_INDEX;
  constructor(public payload: number) {}
}

export class SetIsPackageLoading implements Action {
  readonly type = ActionTypes.SET_IS_PACKAGE_LOADING;
  constructor(public payload: boolean) {}
}

export class AddTicketHolderIndexes implements Action {
  readonly type = ActionTypes.ADD_TICKET_HOLDER_INDEXES;
  constructor(public payload: number) {}
}

export class SetTicketHolderIndex implements Action {
  readonly type = ActionTypes.SET_TICKET_HOLDER_INDEX;
  constructor(public payload: string) {}
}

export class RemoveTicketHolderIndex implements Action {
  readonly type = ActionTypes.REMOVE_TICKET_HOLDER_INDEX;
  constructor(public payload: string) {}
}

export class SetIsAnonymousTicketTaken implements Action {
  readonly type = ActionTypes.SET_IS_ANONYMOUS_TICKET_TAKEN;
  constructor(public payload: boolean) {}
}

export class SetAreTicketsSent implements Action {
  readonly type = ActionTypes.SET_ARE_TICKETS_SENT;
  constructor(public payload: boolean) {}
}
export class ResetReducer implements Action {
  readonly type = ActionTypes.RESET_REDUCER;
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Actions =
  | LoadTicketHolder
  | SetTicketHolder
  | SetTicketHolderInputs
  | SetTicketHolderQuestionnaire
  | PostTicketHolderForm
  | PostTicketBooking
  | SetPostTicketHolderFormResult
  | SetClaimedTicketHash
  | SetTicketClaimedHashValid
  | SetTickets
  | SetParkingTickets
  | AddVoucherTicket
  | SetActiveHolderSlideIndex
  | PostTicketHolderQuestionnaire
  | SetTicketsTypesAction
  | GetTicketsTypesAction
  | SetSendingOptionsAction
  | GetVoucher
  | ReleaseVoucher
  | RemoveVoucher
  | RemoveLastVoucherAdded
  | ResetReducer
  | AddTicketBooking
  | SetLastPackageIndex
  | SetIsPackageLoading
  | AddTicketHolderIndexes
  | SetTicketHolderIndex
  | RemoveTicketHolderIndex
  | RemoveTicketBookings
  | SetIsAnonymousTicketTaken
  | SetAreTicketsSent;
