import { Actions, ActionTypes } from './colorizer.actions';

import { CssSkinModel } from '../shared/services-with-reducers/customization/customization.interfaces';

import { StyleTemplateModel } from './colorizer.interface';

import cloneDeep from 'lodash.clonedeep';

export interface State {
  styleTemplateId: number;
  styleTemplates: StyleTemplateModel[];
  selectedStyleTemplateStyles: CssSkinModel;
}

export const themeDefault = require('../../assets/admin/theme-default.json');

export const initialState: State = {
  styleTemplateId: null,
  styleTemplates: null,
  selectedStyleTemplateStyles: {
    priority: null,
    css: null,
    variables: null
  }
};

export function reducer(state = initialState, action: Actions): State {
  const clonedInitialState: State = cloneDeep(initialState);

  switch (action.type) {
    case ActionTypes.SET_ALL_STYLES:
      return {
        ...state,
        styleTemplates: action.payload
      };

    case ActionTypes.SET_STYLE:
      const stylesVariables = action.payload ? action.payload.variables : {};

      return {
        ...state,
        selectedStyleTemplateStyles: {
          ...action.payload,
          variables: { ...themeDefault, ...stylesVariables }
        }
      };

    case ActionTypes.SET_STYLE_ID:
      const styleID = action.payload;

      // dont trigger new state if the id is the same as previous one
      if (state.styleTemplateId === styleID) {
        return state;
      }

      return {
        ...state,
        styleTemplateId: styleID
      };

    case ActionTypes.ADD_STYLE_TO_LIST:
      const style = action.payload;

      return {
        ...state,
        styleTemplates: [...state.styleTemplates, style],
        styleTemplateId: style.id
      };

    case ActionTypes.RESET_REDUCER:
      return clonedInitialState;

    default: {
      return state;
    }
  }
}

export const allStyleTemplates = (state: State) => state.styleTemplates;
export const getStyle = (state: State) => state.selectedStyleTemplateStyles;
export const getStyleId = (state: State) => state.styleTemplateId;
