import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';

import { Observable } from 'rxjs/Observable';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  constructor() {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.method === 'GET') {
      // for IE to prevent caching (Maintenace page not preserved issue)
      const customRequest = req.clone({
        headers: req.headers
          .set('Cache-Control', 'no-cache')
          .set('Pragma', 'no-cache')
      });

      return next.handle(customRequest);
    }
    return next.handle(req);
  }
}
