import {
  combineLatest as observableCombineLatest,
  Subscription
} from 'rxjs';

import { first, filter } from 'rxjs/operators';
import * as additionalServicesActions from '../../shared/services-with-reducers/additional-services/additional-services.actions';
import * as fromRoot from '../../app.reducer';
import * as stepsActions from '../../shared/services-with-reducers/step-forms/steps-forms.actions';

import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  AfterViewInit
} from '@angular/core';
import {
  FormInputsPayloadModel,
  InputsListModel,
  OrderResponseModel,
  TicketHolderAdditionalDataModel
} from '../../shared/services-with-reducers/step-forms/step.interface';
import {
  SendingOptionModel,
  ProductGroupModel,
  TicketModel,
  ProductsConfirmationModel
} from '../../shared/services-with-reducers/tickets/ticket.interface';

import { AppConstants } from '../../shared/app-constants';
import { CustomizationService } from '../../shared/services-with-reducers/customization/customization.service';
import { ExhibitionSettingModel } from '../../shared/services-with-reducers/customization/customization.interfaces';
import { FormGroup } from '@angular/forms';
import { FormsService } from '../../shared/forms/forms.service';
import { InputBase } from '../../shared/forms/inputs/input-base.class';
import { Router } from '@angular/router';
import { StepsFormsService } from '../../shared/services-with-reducers/step-forms/steps-forms.service';
import { Store } from '@ngrx/store';
import { UserService } from '../../shared/services-with-reducers/user/user.service';
import { WorkshopModel } from '../../shared/services-with-reducers/additional-services/additional-services.interface';
import { GtmService } from '../../shared/gtm/gtmService';
import { getLocalStorageString } from '../../shared/app-utils';

@Component({
  moduleId: module.id,
  selector: 'app-web-shop-confirmation',
  templateUrl: './web-shop-confirmation.component.html',
  styleUrls: ['./web-shop-confirmation.component.scss']
})
export class WebShopConfirmationComponent
  implements OnInit, OnDestroy, AfterViewInit {
  public checkboxesInputs: InputBase<any>[];
  public checkboxesForm: FormGroup;
  public checkboxesFormsActionName = ['confirmation', 'checkboxes'];
  public ticketHolderInputSets: FormInputsPayloadModel[];
  public totalCost = 0;
  public ticketsWithHolders: ProductsConfirmationModel[] = [];
  public ungroupedTicketsWithHolders: any[] = [];
  public confirmationOptions;
  public buyerEmail: string;
  public workshops: WorkshopModel[] = [];
  public isAlternativeButtonDisabled = false;
  public AppConstants = AppConstants;
  public twintPayment = false;
  public settings: ExhibitionSettingModel;
  public orderSent = false;
  public parsedParkingTickets: any;
  public hasParkingTickets: boolean = false;
  private subscriptions = new Subscription();
  private hiddenForm = null;
  public paymentMethodImages = {
    'Saferpay#All': '../../assets/payment-options-logo/saferpay.png',
    'SaferpayApi#TWINT': '../../assets/payment-options-logo/twint-logo-quer.png',
    'SaferpayApi#VISA': '../../assets/payment-options-logo/visa.png',
    'SaferpayApi#ALIPAY': '../../assets/payment-options-logo/alipay.png',
    'SaferpayApi#BANCONTACT': '../../assets/payment-options-logo/bancontact.png',
    'SaferpayApi#BONUS': '../../assets/payment-options-logo/bonuscard.png',
    'SaferpayApi#DIRECTDEBIT': '../../assets/payment-options-logo/directdebit.png',
    'SaferpayApi#EPRZELEWY': '../../assets/payment-options-logo/eprzelewy.png',
    'SaferpayApi#EPS': '../../assets/payment-options-logo/eps.png',
    'SaferpayApi#GIROPAY': '../../assets/payment-options-logo/giropay.png',
    'SaferpayApi#IDEAL': '../../assets/payment-options-logo/ideal.png',
    // 'SaferpayApi#INVOICE': '../../assets/payment-options-logo/american_express.png', logo needs to be added per customer (language/currency)
    'SaferpayApi#JCB': '../../assets/payment-options-logo/jcb.png',
    'SaferpayApi#MYONE': '../../assets/payment-options-logo/myone.png',
    'SaferpayApi#PAYDIREKT': '../../assets/payment-options-logo/paydirekt.png',
    'SaferpayApi#POSTCARD': '../../assets/payment-options-logo/postfinance.png',
    'SaferpayApi#POSTFINANCE': '../../assets/payment-options-logo/postfinance.png',
    'SaferpayApi#SAFERPAYTEST': '../../assets/payment-options-logo/saferpaytest.png',
    'SaferpayApi#SOFORT': '../../assets/payment-options-logo/klarna.svg',
    'SaferpayApi#UNIONPAY': '../../assets/payment-options-logo/unionpay.png',
    'SaferpayApi#VPAY': '../../assets/payment-options-logo/vpay.png',
    'SaferpayApi#AllMethods': '../../assets/payment-options-logo/saferpay.png',
    'SaferpayApi#PAYPAL': '../../assets/payment-options-logo/pay_pal.png',
    'SaferpayApi#MASTERCARD': '../../assets/payment-options-logo/mastercard.png',
    'SaferpayApi#AMEX': '../../assets/payment-options-logo/american_express.png',
    'SaferpayApi#DINERS': '../../assets/payment-options-logo/diners.png',
    'SaferpayApi#MAESTRO': '../../assets/payment-options-logo/maestro.png',
    'IPayment#cc': '../../assets/payment-options-logo/credit_card.png',
    'IPayment#elv': '../../assets/payment-options-logo/sepa_logo.png',
    'IPayment#paypal': '../../assets/payment-options-logo/pay_pal.png',
    'Yandex#All': '../../assets/payment-options-logo/yandex-money.png',
    'Sberbank#All': '../../assets/payment-options-logo/sberbank.png',
    'Ingenico#AllMethods': '../../assets/payment-options-logo/saferpay.png',
    'Ingenico#CreditCard##MasterCard': '../../assets/payment-options-logo/mastercard.png',
    'Ingenico#PAYPAL##PAYPAL': '../../assets/payment-options-logo/pay_pal.png',
    'PayOne#MASTERCARD': '../../assets/payment-options-logo/mastercard.png',
    'PayOne#VISA': '../../assets/payment-options-logo/visa.png',
    'PayOne#GIROPAY': '../../assets/payment-options-logo/giropay.png',
    'PayOne#PAYPAL': '../../assets/payment-options-logo/pay_pal.png',
    'PayOne#AMEX': '../../assets/payment-options-logo/american_express.png',
  };

  constructor(
    private _store: Store<fromRoot.State>,
    private _formsService: FormsService,
    private _stepsFormsService: StepsFormsService,
    private _customizationService: CustomizationService,
    private _userService: UserService,
    public _router: Router,
    private _el: ElementRef,
    private _gtmService: GtmService
  ) { }

  ngOnInit() {
    // remove the order response if there is an
    this._store.dispatch(new stepsActions.SetOrderResponse(null));
    this._store.dispatch(new stepsActions.setPaymentMethod(null));

    this.subscriptions.add(
      this._store
        .select(fromRoot.getExhibitionSettings)
        .pipe(filter(data => !!data))
        .subscribe(settings => {
          this.settings = settings;
        })
    );

    this.subscriptions.add(
      this._store
        .select(fromRoot.getBuyerInfo)
        .pipe(filter(data => !!data))
        .subscribe((inputs: InputsListModel) => {
          if (inputs.list.length) {
            const email = inputs.list.find(input => {
              return input.key === 'email';
            });
            this.buyerEmail = email.value;
          }
        })
    );

    // in case we already piad the order, go to invoice page for downlaod

    observableCombineLatest(
      this._store.select(fromRoot.getOrderResponse),
      this._store.select(fromRoot.getSelectedExhibitionId),
      this._store.select(fromRoot.getTotalPrice),
      this._store.select(fromRoot.getTicketHolderAdditionalData)
    )
      .pipe(
        filter(data => {
          return !!data[0] && !!data[1] && !!data[2] && !!data[3];
        }),
        first()
      )
      .subscribe(
        (
          data: [
            OrderResponseModel,
            number,
            number,
            TicketHolderAdditionalDataModel
          ]
        ) => {
          const [
            orderResponse,
            eventId,
            totalPrice,
            ticketHolderAdditionalData
          ] = data;

          this.totalCost =
            (totalPrice * 100 +
            this.calculateParkingTicketsPrice() * 100 +
            this.calculateWorkshopsPrice(ticketHolderAdditionalData) * 100) / 100;

          if (orderResponse.orderPaid || this.totalCost === 0) {
            this._router.navigate([`webshop/${eventId}/invoice`]);
          }
        }
      );

    this.subscriptions.add(
      this._store.select(fromRoot.getExhibitionSettings).subscribe(settings => {
        if (settings && settings.twintPayment) {
          this.twintPayment = true;
        }
      })
    );

    this.subscriptions.add(
      observableCombineLatest(
        this._store.select(fromRoot.getOrderResponse),
        this._store.select(fromRoot.getSelectedExhibitionId)
      )
        .pipe(
          filter(data => {
            return !!data[0] && !!data[1];
          })
        )
        .subscribe((data: [OrderResponseModel, number]) => {
          const [orderResponse, eventId] = data;

          if (!orderResponse.orderPaid) {
            if (orderResponse.payUrl) {
              window.open(orderResponse.payUrl, '_self');
              // in case there is no redirec url go to payment landing page
            } else if (orderResponse.payForm) {
              //this.hiddenForm
              const form = document.createElement('div');
              form.innerHTML = orderResponse.payForm;
              this._el.nativeElement.appendChild(form);

              if (form.firstChild.nodeName === 'FORM') {
                const formElement = <HTMLFormElement>form.firstChild;
                formElement.submit();
              }
            } else if (orderResponse.hash) {
              this._router.navigate([
                `webshop/${eventId}/confirmation/${orderResponse.hash}`
              ]);
              //if order response success is false we redirect user to error page
            } else if (!orderResponse.success) {
              this._router.navigate([`webshop/${eventId}/confirmation/error`]);
            }
          }
        })
    );

    // check validity of the step in order to set alternative payment button to disabled/enabled

    this.subscriptions.add(
      this._store.select(fromRoot.getStepsValidity).subscribe(stepsValidity => {
        this.isAlternativeButtonDisabled = !stepsValidity.confirmation.valid;
      })
    );

    this._store
      .select(fromRoot.getSelectedExhibitionId)
      .pipe(first())
      .subscribe(eventId => {
        this.subscriptions.add(
          this._store
            .select(fromRoot.getWorkshops)
            .pipe(
              filter(workshops => {
                // if workshops are present go on
                if (!!workshops) {
                  return true;
                } else {
                  // oherwise load the workshops via API
                  this._store.dispatch(
                    new additionalServicesActions.GetWorkshops(eventId)
                  );
                  return false;
                }
              })
            )
            .subscribe((workshops: WorkshopModel[]) => {
              this.workshops = workshops;
            })
        );
      });

    this.subscriptions.add(
      observableCombineLatest(
        this._store.select(fromRoot.getTickets),
        this._store.select(fromRoot.getTicketsTypes),
        this._store.select(fromRoot.getTicketHolderInputSets),
        this._store.select(fromRoot.getTicketHolderAdditionalData),
        this._store.select(fromRoot.getTicketsCount),
        this._store.select(fromRoot.getTotalPrice)
      )
        .pipe(
          filter(
            (
              data: [
                TicketModel,
                ProductGroupModel[],
                FormInputsPayloadModel[],
                TicketHolderAdditionalDataModel,
                number,
                number
              ]
            ) => {
              return (
                !!data[0] &&
                !!data[1] &&
                !!data[2] &&
                //data[2].length &&
                !!data[3] &&
                !!data[4]
              );
            }
          )
        )
        .subscribe(data => {
          const [
            ungroupedTickets,
            ticketTypes,
            ticketHolderInputSets,
            ticketHolderAdditionalData,
            ticketsCount,
            totalPrice
          ] = data;

          this.ticketHolderInputSets = ticketHolderInputSets;

          // remove the subtypes of tickets to make space for real ordered tickets
          this.ticketsWithHolders = this._stepsFormsService.assignTicketsToHolders(
            ungroupedTickets,
            ticketTypes,
            ticketHolderInputSets,
            ticketHolderAdditionalData
          );
          this.ungroupedTicketsWithHolders = this._stepsFormsService.assignTicketsToHoldersForSave(
            ungroupedTickets,
            ticketHolderInputSets,
            ticketHolderAdditionalData,
            this.buyerEmail
          );

          this.totalCost =
            (totalPrice * 100 +
            this.calculateParkingTicketsPrice() * 100 +
            this.calculateWorkshopsPrice(ticketHolderAdditionalData) * 100) / 100;
        })
    );

    this.subscriptions.add(
      this._store
        .select(fromRoot.getConfirmationCheckboxesInputs)
        .pipe(filter(data => !!data))
        .subscribe((checkboxes: InputsListModel) => {
          if (checkboxes && checkboxes.list.length) {
            this.checkboxesInputs = checkboxes.list.slice(0);
            this.checkboxesForm =
              this.checkboxesForm ||
              this._formsService.toFormGroup(
                checkboxes.list,
                this.checkboxesFormsActionName
              );
          }
        })
    );

    this.subscriptions.add(
      observableCombineLatest(
        this._store.select(fromRoot.getSendingOptions),
        this._store.select(fromRoot.getExhibitionSettings),
        this._store.select(fromRoot.isTicketHolderVisible),
        this._store.select(fromRoot.getTicketHolderInputSets),
        this._store.select(fromRoot.getTicketSelectedSendingOptions)
      )
        .pipe(filter(data => !!data))
        .subscribe(
          (
            data: [
              SendingOptionModel[],
              ExhibitionSettingModel,
              boolean,
              FormInputsPayloadModel[],
              string
            ]
          ) => {
            this.confirmationOptions = [];
            const [
              confirmationOptions,
              settings,
              isTicketHolderVisible,
              ticketHolderInputSets,
              ticketSelectedSendingOption
            ] = data;

            this.confirmationOptions = confirmationOptions.filter(option => {
              return option.isEnabled;
            });
          }
        )
    );
  }

  calculateWorkshopsPrice(
    ticketHolderAdditionalData: TicketHolderAdditionalDataModel
  ): number {
    let totalWorkshopPrice = 0;
    for (const key of Object.keys(ticketHolderAdditionalData)) {
      let additionalData: any = ticketHolderAdditionalData[key];
      if (additionalData) {
        additionalData.workshops.forEach(workshopId => {
          let workshop = this.workshops.find(workshop => {
            return workshop.workshopId === workshopId;
          });

          totalWorkshopPrice += workshop.price;
        });
      }
    }

    return totalWorkshopPrice;
  }

  calculateParkingTicketsPrice(): number {
    const storedParkingTickets = getLocalStorageString(AppConstants.parkingTicketsReducer);

    const parkingTicketsDictionary =
      storedParkingTickets && JSON.parse(storedParkingTickets);

    this.parsedParkingTickets = Object.keys(parkingTicketsDictionary).reduce(
      function (filtered, key) {
        if (parkingTicketsDictionary[key].hasOwnProperty('price')) {
          filtered[key] = parkingTicketsDictionary[key];
        }
        return filtered;
      },
      {}
    );

    this.hasParkingTickets = Object.keys(this.parsedParkingTickets).length > 0;

    const parkingTicketPrice = Object.keys(this.parsedParkingTickets)
      .reduce((accu, curr) => {
        const ticket = this.parsedParkingTickets[curr];

        if (ticket.price) {
          return accu + parseInt(ticket.price, 10);
        }
        return accu;
      }, 0)
      .toFixed(2);

    return parseInt(parkingTicketPrice, 10);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  paymentSubmited(paymentMethod: string, event) {
    event.stopPropagation();
    this.orderSent = true;

    this._store.dispatch(new stepsActions.setPaymentMethod(paymentMethod));

    this._stepsFormsService.prepareDataForSaveAndSend(
      this.ungroupedTicketsWithHolders,
      this.totalCost
    );

    //this._formsService.setFormValidity(true, null, ['payment', 'validation']);

    /* compare ticket buyer and logged in user,
       if buyer has more credentials than user use the buyers information and add them to user
    */

    this._userService.updateUserWithBuyer();

    this._gtmService.pushCheckoutOption(paymentMethod.toString());
  }

  dropdownChanged(data) {
    let selectedSendingOption = data.sendingOption;

    observableCombineLatest(
      this._store.select(fromRoot.getExhibitionSettings),
      this._store.select(fromRoot.getSendingOptions)
    )
      .pipe(first())
      .subscribe(settings => {
        const [exhibitionSettings, allSendingOptions] = settings;

        const ticketHolderform: FormInputsPayloadModel = JSON.parse(
          JSON.stringify(
            this.ticketHolderInputSets.find(inputSet => {
              return (
                inputSet.index ===
                data.ticket.ticketHolderConfirmation.ticketIndex
              );
            })
          )
        );

        let sendToOwner: boolean = true;
        let sendToOwnerHidden: boolean = true;

        const isMobilePerBuyer: boolean = selectedSendingOption === 'mobilePerBuyer';

        const isTicketRetrivalLinkBuyer: boolean = selectedSendingOption === 'ticketRetrivalLinkBuyer';

        const isAllToBuyer: boolean = selectedSendingOption === 'allToBuyer';

        const isTicketRetrivalLink: boolean = selectedSendingOption === 'ticketRetrivalLink';

        const isMobilePerOwner: boolean = selectedSendingOption === "mobilePerOwner";

        const isNormalPerOwner: boolean = selectedSendingOption === "normalPerOwner";

        if (!isAllToBuyer && !isNormalPerOwner) {

          if (isMobilePerBuyer) {
            sendToOwner = false;
            selectedSendingOption = "mobilePerOwner";
          } else if (isTicketRetrivalLinkBuyer) {
            sendToOwner = false;
            selectedSendingOption = "ticketRetrivalLink";
          }

          for (let i = 0; i < allSendingOptions.length; i++) {
            const sendingOption = allSendingOptions[i];
            if (sendingOption.isEnabled) {
              if (isMobilePerBuyer && sendingOption.value === "mobilePerOwner") {
                sendToOwner = sendToOwnerHidden = false;
                break;
              }
              else if (isMobilePerOwner && sendingOption.value === "mobilePerBuyer") {
                sendToOwnerHidden = !sendToOwner;
                break;
              }
              else if (isTicketRetrivalLinkBuyer && sendingOption.value === "ticketRetrivalLink") {
                sendToOwner = sendToOwnerHidden = false;
                break;
              }
              else if (isTicketRetrivalLink && sendingOption.value === "ticketRetrivalLinkBuyer") {
                sendToOwnerHidden = !sendToOwner;
                break;
              }
            }
          };
        }

        if (isAllToBuyer) {
          sendToOwner = false;
        }

        const sendingOptions = ticketHolderform.inputSet.list.find(
          input => input.key === 'sendingOption'
        );
        const sendtoowner = ticketHolderform.inputSet.list.find(
          input => input.key === 'sendtoowner'
        );

        sendingOptions.value = selectedSendingOption;
        sendtoowner.hidden = sendToOwnerHidden;
        sendtoowner.options[0].value = sendToOwner;
        sendtoowner.options[0].label = `personalize.sending-options.${selectedSendingOption.toLowerCase()}`;
        sendtoowner.options[0].icon = selectedSendingOption;

        const chackValidityAndRedirect = true;

        this._customizationService.setRequiredHoldersFiledsBasedOnSendinOption(
          ticketHolderform,
          exhibitionSettings,
          selectedSendingOption,
          chackValidityAndRedirect
        );

      });

    // this._store.dispatch(new stepsActions.SetInputs(ticketHolderform));
  }

  ngAfterViewInit() {
    this._gtmService.pushConfirmation();
  }
}
