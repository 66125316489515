import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-age-rating',
  styleUrls: ['./age-rating.component.scss'],
  template: `
    <div #wrapper class="age-rating-wrapper" [ngClass]="wrapperDynamicClasses">
      <div class="age-rating" [ngClass]="dynamicClasses">
        {{rating}} +
      </div>
    </div>
  `
})
export class AgeRatingComponent implements OnInit {
  @Input()
  wrapperDynamicClasses: { [key: string]: any };
  @Input()
  dynamicClasses: { [key: string]: any };
  @Input()
  rating: number | string;
  @Input()
  adjustPositions: { top: string; right: string; bottom: string; left: string };

  @ViewChild('wrapper')
  wrapper: ElementRef;

  public positions: string;

  constructor() {}

  ngOnInit() {
    if (this.adjustPositions) {
      for (const key in this.adjustPositions) {
        this.wrapper.nativeElement.style[key] = this.adjustPositions[key];
      }
    }
  }
}
