import { take, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as fromRoot from '../../app.reducer';

function getState(store) {
  let state;

  store.select(fromRoot.isUserLoggedIn).subscribe(s => (state = s));
  return state;
}

@Injectable()
export class LoginGuardUser implements CanActivate {
  constructor(private _router: Router, private _store: Store<fromRoot.State>) {}

  canActivate(): Observable<boolean> {
    return this._store.select(fromRoot.isUserLoggedIn).pipe(
      map(isLoggedIn => {
        if (isLoggedIn) {
          return isLoggedIn;
        }

        this._router.navigate(['/']);
        return false;
      }),
      take(1)
    );
  }
}
