var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { first, filter } from 'rxjs/operators';
import * as additionalServicesActions from '../../shared/services-with-reducers/additional-services/additional-services.actions';
import * as fromRoot from '../../app.reducer';
import { CustomizationService } from '../../shared/services-with-reducers/customization/customization.service';
import { Store } from '@ngrx/store';
import cloneDeep from 'lodash.clonedeep';
var WebShopMenuComponent = /** @class */ (function () {
    function WebShopMenuComponent(_store, _customizationService) {
        var _this = this;
        this._store = _store;
        this._customizationService = _customizationService;
        this._store
            .select(fromRoot.getMenuOptions)
            .pipe(filter(function (data) {
            // if there are no data for this exhibition yet, load them
            if (data.length === 0) {
                _this.loadMenuViaApi();
            }
            return data.length > 0;
        }), first()) // only go for the first one with data
            .subscribe(function (menuListData) {
            _this.menuListForDisplay = menuListData;
            _this.menuListToSave = cloneDeep(menuListData);
        });
    }
    WebShopMenuComponent.prototype.loadMenuViaApi = function () {
        var _this = this;
        this._store
            .select(fromRoot.getSelectedExhibitionId)
            .pipe(first())
            .subscribe(function (eventId) {
            _this._store.dispatch(new additionalServicesActions.GetMenuOptions(eventId));
        });
    };
    WebShopMenuComponent.prototype.onMenuSelection = function (menu, item) {
        var isValid = true;
        this.menuListToSave = this.menuListToSave.map(function (menuData) {
            var id = menuData.id, selectedMenu = menuData.selectedMenu;
            if (item.id === id) {
                return __assign({}, menuData, { selectedMenu: menu.id });
            }
            else {
                return menuData;
            }
        });
        this._store.dispatch(new additionalServicesActions.SetMenu(this.menuListToSave));
    };
    return WebShopMenuComponent;
}());
export { WebShopMenuComponent };
