import * as fromRoot from '../../../app.reducer';
import * as helperActions from '../../../shared/services-with-reducers/helpers/helper.actions';
import * as ticketActions from '../../../shared/services-with-reducers/tickets/ticket.actions';
import * as exhibitionActions from '../../../shared/services-with-reducers/exhibition/exhibition.actions';

import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  OnDestroy,
  Input
} from '@angular/core';
import { filter, first } from 'rxjs/operators';

import { CustomizationService } from '../../../shared/services-with-reducers/customization/customization.service';
import { FormGroup } from '@angular/forms';
import { FormsService } from '../../../shared/forms/forms.service';
import { HelperService } from '../../../shared/services-with-reducers/helpers/helper.service';
import { InputBase } from '../../../shared/forms/inputs/input-base.class';
import { select, Store } from '@ngrx/store';
import {
  TicketHolderModel,
  TicketRecordOrdersTicketModel
} from '../../../shared/services-with-reducers/tickets/ticket.interface';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest as observableCombineLatest, Subscription } from 'rxjs';
import {
  prepareTicketHolderDownloadData,
  prepareTicketHolderPersonalizationData
} from './form-inputs.ticket-holder-download';
import { TicketsService } from '../../../shared/services-with-reducers/tickets/tickets.service';
import { SelectOption } from '../../../shared/services-with-reducers/exhibition/exhibition.interface';
import { ExhibitionSettingModel, QuestionnaireDataInput } from '../../../shared/services-with-reducers/customization/customization.interfaces';

@Component({
  moduleId: module.id,
  selector: 'app-download-ticket-holder',
  templateUrl: './download-ticket-holder.component.html',
  styleUrls: ['./download-ticket-holder.component.scss']
})
export class DownloadTicketHolderComponent implements OnInit, OnDestroy {
  @Output()
  isValid = new EventEmitter<Object>();

  @Input()
  ticket: TicketRecordOrdersTicketModel;

  public subscriptions = new Subscription();
  public form: FormGroup;
  public inputs: InputBase<any>[];
  public actionName = ['ticket-holder-download-data'];
  public loaded: boolean = false;

  constructor(
    private _store: Store<fromRoot.State>,
    public _customizationService: CustomizationService,
    private _formsService: FormsService,
    private _helperService: HelperService,
    private _translateService: TranslateService,
    private _ticketService: TicketsService
  ) {
    this._store.dispatch(new helperActions.GetAllCountriesAction());
    this._store.dispatch(new exhibitionActions.GetAllTitles());
    this._store.dispatch(new exhibitionActions.GetAllProfessions());
    this._store.dispatch(new exhibitionActions.GetAllDepartments());
    this._store.dispatch(new exhibitionActions.GetAllOccupationalGroups());
  }

  ngOnInit() {
    observableCombineLatest([
      this._store.pipe(select(fromRoot.getExhibitionSettings)),
      this._store.pipe(select(fromRoot.getTicketHolder)),
      this._store.pipe(select(fromRoot.getTitles)),
      this._store.pipe(select(fromRoot.getAllTitles)),
      this._store.pipe(select(fromRoot.getProfessions)),
      this._store.pipe(select(fromRoot.getAllProfessions)),
      this._store.pipe(select(fromRoot.getDepartments)),
      this._store.pipe(select(fromRoot.getAllDepartments)),
      this._store.pipe(select(fromRoot.getOccupationalGroups)),
      this._store.pipe(select(fromRoot.getAllOccupationalGroups))
    ])
      .pipe(
        filter((data: [
          ExhibitionSettingModel, 
          TicketHolderModel,
          SelectOption[], 
          QuestionnaireDataInput[],
          SelectOption[], 
          QuestionnaireDataInput[],
          SelectOption[], 
          QuestionnaireDataInput[],
          SelectOption[], 
          QuestionnaireDataInput[]
        ]) => {
          const [
            settings,
            ticketHolder,
            titles,
            allTitles,
            professions,
            allProfessions,
            departments,
            allDepartments,
            occupationalGroups,
            allOccupationalGroups
          ] = data;

        return !!settings && 
          !!ticketHolder && 
          !!titles &&
          !!allTitles && 
          !!professions && 
          !!allProfessions && 
          !!departments && 
          !!allDepartments && 
          !!occupationalGroups && 
          !!allOccupationalGroups;
      }),
      first()
    )
    .subscribe(([
      settings,
      ticketHolder,
      titles,
      allTitles,
      professions,
      allProfessions,
      departments,
      allDepartments,
      occupationalGroups,
      allOccupationalGroups
    ]) => {

      if (!this.ticket) {
        const $inputs = prepareTicketHolderDownloadData(
          settings,
          ticketHolder,
          titles,
          allTitles,
          professions,
          allProfessions,
          departments,
          allDepartments,
          occupationalGroups,
          allOccupationalGroups,
          this._translateService
        );

        $inputs.subscribe(inputs => {
          this._store.dispatch(
            new ticketActions.SetTicketHolderInputs(inputs)
          );

          this.loaded = true;
        });
        } else {
          this.subscriptions.add(
            this._ticketService
              .getVisitorFieldsForPersonalization(this.ticket.id)
              .subscribe((result: any) => {
                const $inputs = prepareTicketHolderPersonalizationData(
                  result.fieldSettings,
                  titles,
                  allTitles,
                  professions,
                  allProfessions,
                  departments,
                  allDepartments,
                  occupationalGroups,
                  allOccupationalGroups,
                  this._translateService
                );

                $inputs.subscribe(inputs => {
                  this.form = this._formsService.toFormGroup(
                    inputs,
                    this.actionName
                  );

                  // check the validity on first time and set it
                  const validationCallback = () => {
                    this.isValid.emit({
                      formName: 'ticketholder',
                      valid: !this.form.invalid,
                      inputs: this.inputs,
                      form: this.form
                    });
                  };
                  this._helperService.triggerCallbackOnceFormValidationIsDone(
                    this.form,
                    validationCallback
                  );

                  this._store.dispatch(
                    new ticketActions.SetTicketHolderInputs(inputs)
                  );

                  this.loaded = true;
                });
              })
          );
        }
      });

    this.subscriptions.add(
      this._store
        .select(fromRoot.getTicketHolderInputs)
        .subscribe((inputs: InputBase<any>[]) => {
          this.inputs = inputs;
          this.form = this._formsService.toFormGroup(inputs, this.actionName);

          // check the validity on first time and set it
          const validationCallback = () => {
            this.isValid.emit({
              formName: 'ticketholder',
              valid: !this.form.invalid,
              inputs: this.inputs,
              form: this.form
            });
          };
          this._helperService.triggerCallbackOnceFormValidationIsDone(
            this.form,
            validationCallback
          );
        })
    );
  }

  public inputsChanged = (inputs: InputBase<any>[], rerender: boolean) => {
    this.inputs = this._formsService.updateInputs(this.inputs, inputs);
    this._store.dispatch(new ticketActions.SetTicketHolderInputs(this.inputs));

    this.isValid.emit({
      formName: 'ticketholder',
      valid: !this.form.invalid,
      inputs: this.inputs,
      form: this.form
    });

    if (rerender) {
      this.form = this._formsService.toFormGroup(inputs, this.actionName);
    }
  };

  cancelProfileEdit(): void {
    this.ticket.expanded = false;
  }

  sumbitProfileUpdate(): void {
    let data = {
      ticketId: this.ticket.id
    };

    for (const field in this.form.controls) {
      const control = this.form.get(field);
      data[field] = control.value;
    }

    this.subscriptions.add(
      this._ticketService
        .postVisitorFieldsFromPersonalization(this.ticket.id, data)
        .subscribe(() => {
          this.ticket.expanded = false;

          this.ticket.firstName = this.form.get('firstName').value;
          this.ticket.lastName = this.form.get('lastName').value;
          this.ticket.email = this.form.get('email').value;
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
