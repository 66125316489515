import { Component, Input, OnInit } from '@angular/core';

import { WorkshopModel } from '../../../shared/services-with-reducers/additional-services/additional-services.interface';
import { TicketSubgroupModel } from '../../../shared/services-with-reducers/tickets/ticket.interface';
import { ConfirmationWorkshopPricesModel } from './confirmation-workshop-prices-model';
import { CurrencySettingsModel } from '../../../shared/services-with-reducers/customization/customization.interfaces';

@Component({
  selector: 'app-confirmation-workshop-prices',
  templateUrl: './confirmation-workshop-prices.component.html',
  styleUrls: [
    '../web-shop-confirmation.component.scss',
    './confirmation-workshop-prices.component.scss'
  ]
})
export class ConfirmationWorkshopPricesComponent implements OnInit {
  @Input() workshops: WorkshopModel[];
  @Input() subGroup: TicketSubgroupModel;
  @Input() currencySettings: CurrencySettingsModel;

  public workshopsWithPrices: ConfirmationWorkshopPricesModel[] = [];

  constructor() {}

  ngOnInit() {
    this.subGroup.tickets.forEach(ticket => {
      let additionalData: any = ticket.ticketHolderAdditionalData;

      if (additionalData) {
        additionalData.workshops.forEach(workShopId => {
          let workshopModel = this.workshopsWithPrices.find(workshop => {
            return workshop.id == workShopId;
          });

          if (!workshopModel) {
            let workshop = this.workshops.find(workshop => {
              return workshop.workshopId === workShopId;
            });
            workshopModel = new ConfirmationWorkshopPricesModel(
              workshop.workshopId,
              workshop.workshopName,
              workshop.price
            );

            if (workshopModel.price > 0) {
              this.workshopsWithPrices.push(workshopModel);
            }
          }

          workshopModel.count++;
        });
      }
    });
  }
}
