import { ExhibitionSettingModel } from '../../../shared/services-with-reducers/customization/customization.interfaces';
import { AppConstants } from '../../../shared/app-constants';
import { CheckboxInput } from '../../../shared/forms/inputs/input-checkbox.class';
import { DropdownInput } from '../../../shared/forms/inputs/input-dropdown.class';
import { InputBase } from '../../../shared/forms/inputs/input-base.class';
import { Observable } from 'rxjs/Rx';
import { QuestionnaireDataInput } from '../../../shared/services-with-reducers/customization/customization.interfaces';
import { RadioInput } from '../../../shared/forms/inputs/input-radio.class';
import { TextInput } from '../../../shared/forms/inputs/input-text.class';
import { TicketHolderModel } from '../../../shared/services-with-reducers/tickets/ticket.interface';
import { TranslateService } from '@ngx-translate/core';
import { DateInput } from '../../../shared/forms/inputs/input-date.class';
import { SelectOption } from './../../../shared/services-with-reducers/exhibition/exhibition.interface';
import { TextOrDropdownInputTypes } from './../../../shared/services-with-reducers/helpers/helper.interface';

export const prepareTicketHolderDownloadData = (
  settings: ExhibitionSettingModel,
  ticketHolder: TicketHolderModel,
  titles: SelectOption[],
  allTitles: QuestionnaireDataInput[],
  professions: SelectOption[],
  allProfessions: QuestionnaireDataInput[],
  departments: SelectOption[],
  allDepartments: QuestionnaireDataInput[],
  occupationalGroups: SelectOption[],
  allOccupationalGroups: QuestionnaireDataInput[],
  translateService: TranslateService
): Observable<InputBase<any>[]> => {
  let inputs = [];
  const ticketHolderSettings = settings.ticketOwnerSettings.fieldSettings;
  const addressAutocompleteSettings = settings.isGoogleAutocompleteEnabled;

  // we need to load some translation like 'personalize.holder.salutation' and so we know when to render the form
  // translations on first load are needed for the text Input of Profession field (when comes as number)
  return translateService
    .stream('personalize.holder.salutation')
    .filter(translation => {
      return translation !== AppConstants.MISSING_TRANSLATION;
    })
    .switchMap(() => {
      inputs = [];
      if (ticketHolderSettings.Salutation.isVisible) {
        inputs.push(
          new DropdownInput({
            key: 'gender',
            label: 'personalize.holder.salutation',
            options: [
              { key: 'male', value: 'gender.male', translate: true },
              { key: 'female', value: 'gender.female', translate: true },
              { key: 'diverse', value: 'gender.diverse', translate: true },
              { key: 'notSpecified', value: 'gender.notSpecified', translate: true },
            ],
            translate: true,
            order: ticketHolderSettings.Salutation.order,
            value: ticketHolder.gender,
            required: ticketHolderSettings.Salutation.isMandatory,
            cssClass: 'col-lg-2 col-md-3 col-sm-6 column-grow-1'
          })
        );
      }

      if (ticketHolderSettings.PersonTitle.isVisible) {
        if (titles.length) {
          inputs.push(
            new DropdownInput({
              key: TextOrDropdownInputTypes.Title,
              label: 'personalize.holder.title',
              order: ticketHolderSettings.PersonTitle.order,
              value: ticketHolder.title,
              translate: true,
              required: ticketHolderSettings.PersonTitle.isMandatory,
              cssClass: 'col-lg-2 col-md-2 col-sm-6 column-grow-1',
              options: titles
            })
          );
        } else {
          let val = ticketHolder.title;
          if (!isNaN(<number>ticketHolder.title) && !titles.length) {
            const title = allTitles.find(
              (title: QuestionnaireDataInput) => {
                return title.id === Number(ticketHolder.title);
              }
            );
            if (title) {
              translateService.get(title.text).subscribe(translation => {
                val = translation;
              });
            }
          }

          inputs.push(
            new TextInput({
              key: TextOrDropdownInputTypes.Title,
              label: 'personalize.holder.title',
              order: ticketHolderSettings.PersonTitle.order,
              value: val,
              translate: true,
              required: ticketHolderSettings.PersonTitle.isMandatory,
              cssClass: 'col-lg-2 col-md-2 col-sm-6 column-grow-1',
              maxLengthValidation: 50
            })
          );
        }
      }

      if (ticketHolderSettings.FirstName.isVisible) {
        inputs.push(
          new TextInput({
            key: 'firstName',
            label: 'personalize.holder.first-name',
            order: ticketHolderSettings.FirstName.order,
            value: ticketHolder.firstName,
            translate: true,
            required: ticketHolderSettings.FirstName.isMandatory,
            cssClass: 'col-lg-4 col-md-3 col-sm-6 column-grow-1',
            minLengthValidation: 3,
            maxLengthValidation: 30
          })
        );
      }

      if (ticketHolderSettings.LastName.isVisible) {
        inputs.push(
          new TextInput({
            key: 'lastName',
            label: 'personalize.holder.last-name',
            order: ticketHolderSettings.LastName.order,
            value: ticketHolder.lastName,
            translate: true,
            required: ticketHolderSettings.LastName.isMandatory,
            cssClass: 'col-lg-4 col-md-4 col-sm-6 column-grow-1',
            maxLengthValidation: 50
          })
        );
      }

      if (ticketHolderSettings.Company.isVisible) {
        inputs.push(
          new TextInput({
            key: 'company',
            label: 'personalize.holder.company',
            order: ticketHolderSettings.Company.order,
            value: ticketHolder.company,
            translate: true,
            required: ticketHolderSettings.Company.isMandatory,
            cssClass: 'col-md-6 column-grow-1',
            maxLengthValidation: 50
          })
        );
      }

      if (ticketHolderSettings.PersonFunction.isVisible) {
        if (professions.length) {
          inputs.push(
            new DropdownInput({
              key: TextOrDropdownInputTypes.Function,
              label: 'personalize.holder.position',
              order: ticketHolderSettings.PersonFunction.order,
              value: ticketHolder.function,
              translate: true,
              required: ticketHolderSettings.PersonFunction.isMandatory,
              cssClass: 'col-sm-6 column-grow-1',
              options: professions
            })
          );
        } else {
          let val = ticketHolder.function;
          if (!isNaN(<number>ticketHolder.function) && !professions.length) {
            const profession = allProfessions.find(
              (profession: QuestionnaireDataInput) => {
                return profession.id === Number(ticketHolder.function);
              }
            );
            if (profession) {
              translateService.get(profession.text).subscribe(translation => {
                val = translation;
              });
            }
          }

          inputs.push(
            new TextInput({
              key: TextOrDropdownInputTypes.Function,
              label: 'personalize.holder.position',
              order: ticketHolderSettings.PersonFunction.order,
              value: val,
              translate: true,
              required: ticketHolderSettings.PersonFunction.isMandatory,
              cssClass: 'col-sm-6 column-grow-1',
              maxLengthValidation: 100
            })
          );
        }
      }

      if (ticketHolderSettings.Department.isVisible) {
        if (departments.length) {
          inputs.push(
            new DropdownInput({
              key: TextOrDropdownInputTypes.Department,
              label: 'personalize.holder.department',
              order: ticketHolderSettings.Department.order,
              value: ticketHolder.department,
              translate: true,
              required: ticketHolderSettings.Department.isMandatory,
              cssClass: 'col-sm-6 column-grow-1',
              options: departments
            })
          );
        } else {
          let val = ticketHolder.department;
          if (!isNaN(<number>ticketHolder.department) && !departments.length) {
            const department = allDepartments.find(
              (department: QuestionnaireDataInput) => {
                return department.id === Number(ticketHolder.department);
              }
            );
            if (department) {
              translateService.get(department.text).subscribe(translation => {
                val = translation;
              });
            }
          }

          inputs.push(
            new TextInput({
              key: TextOrDropdownInputTypes.Department,
              label: 'personalize.holder.department',
              order: ticketHolderSettings.Department.order,
              value: val,
              translate: true,
              required: ticketHolderSettings.Department.isMandatory,
              cssClass: 'col-sm-6 column-grow-1',
              maxLengthValidation: 100
            })
          );
        }
      }

      if (ticketHolderSettings.OccupationalGroup.isVisible) {
        if (occupationalGroups.length) {
          inputs.push(
            new DropdownInput({
              key: TextOrDropdownInputTypes.OccupationalGroup,
              label: 'personalize.holder.occupationalGroup',
              order: ticketHolderSettings.OccupationalGroup.order,
              value: ticketHolder.occupationalGroup,
              translate: true,
              required: ticketHolderSettings.OccupationalGroup.isMandatory,
              cssClass: 'col-sm-6 column-grow-1',
              options: occupationalGroups
            })
          );
        } else {
          let val = ticketHolder.occupationalGroup;
          if (!isNaN(<number>ticketHolder.occupationalGroup) && !occupationalGroups.length) {
            const occupationalGroup = allOccupationalGroups.find(
              (occupationalGroup: QuestionnaireDataInput) => {
                return occupationalGroup.id === Number(ticketHolder.occupationalGroup);
              }
            );
            if (occupationalGroup) {
              translateService.get(occupationalGroup.text).subscribe(translation => {
                val = translation;
              });
            }
          }

          inputs.push(
            new TextInput({
              key: TextOrDropdownInputTypes.OccupationalGroup,
              label: 'personalize.holder.occupationalGroup',
              order: ticketHolderSettings.OccupationalGroup.order,
              value: val,
              translate: true,
              required: ticketHolderSettings.OccupationalGroup.isMandatory,
              cssClass: 'col-sm-6 column-grow-1',
              maxLengthValidation: 100
            })
          );
        }
      }

      if (ticketHolderSettings.Country.isVisible) {
        inputs.push(
          new DropdownInput({
            key: 'country',
            label: 'personalize.holder.country',
            options: [],
            order: ticketHolderSettings.Country.order,
            value: ticketHolder.country,
            translate: true,
            required: ticketHolderSettings.Country.isMandatory,
            cssClass: 'col-md-12 col-lg-4 col column-grow-1'
          })
        );
      }

      if (
        addressAutocompleteSettings &&
        ticketHolderSettings.SearchAddress.isVisible
      ) {
        inputs.push(
          new TextInput({
            key: 'address',
            label: 'personalize.holder.address',
            order: 8,
            value: ticketHolder.address,
            translate: true,
            required: false,
            cssClass: 'col-md-12 col-lg-6 column-grow-1'
          })
        );
      }

      if (ticketHolderSettings.ZipCode.isVisible) {
        inputs.push(
          new TextInput({
            key: 'zipCode',
            label: 'personalize.holder.zip-code',
            order: ticketHolderSettings.ZipCode.order,
            value: ticketHolder.zipCode,
            translate: true,
            required: ticketHolderSettings.ZipCode.isMandatory,
            cssClass: 'col-md-3 col-sm-4 col-lg-2 column-grow-1',
            zipcodeValidation: true,
            maxLengthValidation: 12,
            zipCodeCitiesTag: 'downTH-profile-edit.TH-down-data'
          })
        );
      }

      if (ticketHolderSettings.City.isVisible) {
        inputs.push(
          new TextInput({
            key: 'city',
            label: 'personalize.holder.city',
            order: ticketHolderSettings.City.order,
            value: ticketHolder.city,
            translate: true,
            required: ticketHolderSettings.City.isMandatory,
            cssClass: 'col-md-9 col-sm-8 col-lg-5 column-grow-1',
            maxLengthValidation: 50
          })
        );
      }

      if (ticketHolderSettings.Street.isVisible) {
        inputs.push(
          new TextInput({
            key: 'street',
            label: 'personalize.holder.street',
            order: ticketHolderSettings.Street.order,
            value: ticketHolder.street,
            translate: true,
            required: ticketHolderSettings.Street.isMandatory,
            cssClass: 'col-md-9 col-sm-8 col-lg-5 column-grow-1',
            maxLengthValidation: 50
          })
        );
      }

      if (ticketHolderSettings.Phone.isVisible) {
        inputs.push(
          new TextInput({
            key: 'telephone',
            label: 'personalize.holder.phone',
            order: ticketHolderSettings.Phone.order,
            value: ticketHolder.telephone,
            translate: true,
            required: ticketHolderSettings.Phone.isMandatory,
            cssClass: 'col-md-6 column-grow-1',
            phoneValidation: !settings.isTelephoneValidationDisabled
          })
        );
      }

      if (ticketHolderSettings.DateOfBirth.isVisible) {
        inputs.push(
          new DateInput({
            key: 'dateOfBirth',
            label: 'personalize.holder.dateOfBirth',
            order: ticketHolderSettings.DateOfBirth.order,
            value: ticketHolder.dateOfBirth,
            translate: true,
            required: ticketHolderSettings.DateOfBirth.isMandatory,
            cssClass: 'col-sm-5 column-grow-1',
          })
        );
      }

      inputs.push(
        new TextInput({
          key: 'email',
          label: 'personalize.holder.email',
          order: ticketHolderSettings.Email.order,
          value: ticketHolder.email,
          disabled: !!ticketHolder.email,
          translate: true,
          required: true,
          cssClass: 'col-md-6 column-grow-1',
          emailValidation: true,
          type: 'email'
        })
      );

      const newsletterInputType = settings.buyerSettings.newsletterInputType;
      if (newsletterInputType !== 'hidden') {
        if (
          newsletterInputType === 'checkboxUncheckedByDefault' ||
          newsletterInputType === 'checkboxCheckedByDefault'
        ) {
          inputs.push(
            new CheckboxInput({
              key: 'newsletter',
              label: '',
              order: 21,
              translate: true,
              required: false,
              options: [
                {
                  key: 'Newsletter',
                  value: newsletterInputType === 'checkboxCheckedByDefault',
                  label: 'personalize.buyer-info.newsletter',
                  cssClass: 'col-sm-12',
                  required: false
                }
              ],
              cssClass: 'col-sm-12'
            })
          );
        } else if (newsletterInputType === 'radioButtonUnselected') {
          inputs.push(
            new RadioInput({
              key: 'newsletter',
              label: 'profile.newsletters.headline',
              order: 21,
              translate: true,
              required: true,
              options: [
                {
                  key: 'true',
                  value: 'personalize.buyer-info.newsletter',
                  cssClass: 'col-sm-12'
                },
                {
                  key: 'false',
                  value: 'personalize.buyer-info.no-newsletter',
                  cssClass: 'col-sm-12'
                }
              ],
              cssClass: 'col-sm-12'
            })
          );
        }
      }

      return Observable.of(inputs.sort((a, b) => a.order - b.order));
    });
};

export const prepareTicketHolderPersonalizationData = (
  personalizationData: any,
  titles: SelectOption[],
  allTitles: QuestionnaireDataInput[],
  professions: SelectOption[],
  allProfessions: QuestionnaireDataInput[],
  departments: SelectOption[],
  allDepartments: QuestionnaireDataInput[],
  occupationalGroups: SelectOption[],
  allOccupationalGroups: QuestionnaireDataInput[],
  translateService: TranslateService,
): Observable<InputBase<any>[]> => {
  let inputs = [];
  const addressAutocompleteSettings = false;

  // we need to load some translation like 'personalize.holder.salutation' and so we know when to render the form
  // translations on first load are needed for the text Input of Profession field (when comes as number)
  return translateService
    .stream('personalize.holder.salutation')
    .filter(translation => {
      return translation !== AppConstants.MISSING_TRANSLATION;
    })
    .switchMap(() => {
      inputs = [];
      if (personalizationData.Salutation.isVisible) {
        inputs.push(
          new DropdownInput({
            key: 'gender',
            label: 'personalize.holder.salutation',
            options: [
              { key: 'male', value: 'gender.male', translate: true },
              { key: 'female', value: 'gender.female', translate: true },
              { key: 'diverse', value: 'gender.diverse', translate: true },
              { key: 'notSpecified', value: 'gender.notSpecified', translate: true },
            ],
            translate: true,
            value: personalizationData.Salutation.value,
            required: personalizationData.Salutation.isMandatory,
            cssClass: 'col-lg-2 col-md-3 col-sm-6 column-grow-1'
          })
        );
      }

      if (personalizationData.PersonTitle.isVisible) {
        if (titles.length) {
          inputs.push(
            new DropdownInput({
              key: TextOrDropdownInputTypes.Title,
              label: 'personalize.holder.title',
              value: personalizationData.PersonTitle.value,
              translate: true,
              required: personalizationData.PersonTitle.isMandatory,
              cssClass: 'col-lg-2 col-md-2 col-sm-6 column-grow-1',
              options: titles
            })
          );
        } else {
          let val = personalizationData.PersonTitle.value;
          if (
            !isNaN(personalizationData.PersonTitle.value as number) &&
            !titles.length
          ) {
            const title = allTitles.find(
              (title: QuestionnaireDataInput) => {
                return (
                  title.id ===
                  Number(personalizationData.PersonTitle.value)
                );
              }
            );
            if (title) {
              translateService.get(title.text).subscribe(translation => {
                val = translation;
              });
            }
          }

          inputs.push(
            new TextInput({
              key: TextOrDropdownInputTypes.Title,
              label: 'personalize.holder.title',
              value: val,
              translate: true,
              required: personalizationData.PersonTitle.isMandatory,
              cssClass: 'col-lg-2 col-md-2 col-sm-6 column-grow-1',
              maxLengthValidation: 50
            })
          );
        }
      }

      if (personalizationData.FirstName.isVisible) {
        inputs.push(
          new TextInput({
            key: 'firstName',
            label: 'personalize.holder.first-name',
            value: personalizationData.FirstName.value,
            translate: true,
            required: personalizationData.FirstName.isMandatory,
            cssClass: 'col-lg-4 col-md-3 col-sm-6 column-grow-1',
            minLengthValidation: 3,
            maxLengthValidation: 30
          })
        );
      }

      if (personalizationData.LastName.isVisible) {
        inputs.push(
          new TextInput({
            key: 'lastName',
            label: 'personalize.holder.last-name',
            value: personalizationData.LastName.value,
            translate: true,
            required: personalizationData.LastName.isMandatory,
            cssClass: 'col-lg-4 col-md-4 col-sm-6 column-grow-1',
            maxLengthValidation: 50
          })
        );
      }

      if (personalizationData.Company.isVisible) {
        inputs.push(
          new TextInput({
            key: 'company',
            label: 'personalize.holder.company',
            value: personalizationData.Company.value,
            translate: true,
            required: personalizationData.Company.isMandatory,
            cssClass: 'col-md-6 column-grow-1',
            maxLengthValidation: 50
          })
        );
      }

      if (personalizationData.PersonFunction.isVisible) {
        if (professions.length) {
          inputs.push(
            new DropdownInput({
              key: TextOrDropdownInputTypes.Function,
              label: 'personalize.holder.position',
              value: personalizationData.PersonFunction.value,
              translate: true,
              required: personalizationData.PersonFunction.isMandatory,
              cssClass: 'col-sm-6 column-grow-1',
              options: professions
            })
          );
        } else {
          let val = personalizationData.PersonFunction.value;
          if (
            !isNaN(personalizationData.PersonFunction.value as number) &&
            !professions.length
          ) {
            const profession = allProfessions.find(
              (profession: QuestionnaireDataInput) => {
                return (
                  profession.id ===
                  Number(personalizationData.PersonFunction.value)
                );
              }
            );
            if (profession) {
              translateService.get(profession.text).subscribe(translation => {
                val = translation;
              });
            }
          }

          inputs.push(
            new TextInput({
              key: TextOrDropdownInputTypes.Function,
              label: 'personalize.holder.position',
              value: val,
              translate: true,
              required: personalizationData.PersonFunction.isMandatory,
              cssClass: 'col-sm-6 column-grow-1',
              maxLengthValidation: 100
            })
          );
        }
      }

      if (personalizationData.Department.isVisible) {
        if (departments.length) {
          inputs.push(
            new DropdownInput({
              key: TextOrDropdownInputTypes.Department,
              label: 'personalize.holder.department',
              value: personalizationData.Department.value,
              translate: true,
              required: personalizationData.Department.isMandatory,
              cssClass: 'col-sm-6 column-grow-1',
              options: departments
            })
          );
        } else {
          let val = personalizationData.Department.value;
          if (
            !isNaN(personalizationData.Department.value as number) &&
            !departments.length
          ) {
            const department = allDepartments.find(
              (department: QuestionnaireDataInput) => {
                return (
                  department.id ===
                  Number(personalizationData.Department.value)
                );
              }
            );
            if (department) {
              translateService.get(department.text).subscribe(translation => {
                val = translation;
              });
            }
          }

          inputs.push(
            new TextInput({
              key: TextOrDropdownInputTypes.Department,
              label: 'personalize.holder.department',
              value: val,
              translate: true,
              required: personalizationData.Department.isMandatory,
              cssClass: 'col-sm-6 column-grow-1',
              maxLengthValidation: 100
            })
          );
        }
      }

      if (personalizationData.OccupationalGroup.isVisible) {
        if (occupationalGroups.length) {
          inputs.push(
            new DropdownInput({
              key: TextOrDropdownInputTypes.OccupationalGroup,
              label: 'personalize.holder.occupationalGroup',
              value: personalizationData.OccupationalGroup.value,
              translate: true,
              required: personalizationData.OccupationalGroup.isMandatory,
              cssClass: 'col-sm-6 column-grow-1',
              options: occupationalGroups
            })
          );
        } else {
          let val = personalizationData.OccupationalGroup.value;
          if (
            !isNaN(personalizationData.OccupationalGroup.value as number) &&
            !occupationalGroups.length
          ) {
            const occupationalGroup = allOccupationalGroups.find(
              (occupationalGroup: QuestionnaireDataInput) => {
                return (
                  occupationalGroup.id ===
                  Number(personalizationData.OccupationalGroup.value)
                );
              }
            );
            if (occupationalGroup) {
              translateService.get(occupationalGroup.text).subscribe(translation => {
                val = translation;
              });
            }
          }

          inputs.push(
            new TextInput({
              key: TextOrDropdownInputTypes.OccupationalGroup,
              label: 'personalize.holder.occupationalGroup',
              value: val,
              translate: true,
              required: personalizationData.OccupationalGroup.isMandatory,
              cssClass: 'col-sm-6 column-grow-1',
              maxLengthValidation: 100
            })
          );
        }
      }

      if (personalizationData.Country.isVisible) {
        inputs.push(
          new DropdownInput({
            key: 'country',
            label: 'personalize.holder.country',
            options: [],
            value: personalizationData.Country.value,
            translate: true,
            required: personalizationData.Country.isMandatory,
            cssClass: 'col-md-12 col-lg-4 col column-grow-1'
          })
        );
      }

      if (
        addressAutocompleteSettings &&
        personalizationData.SearchAddress.isVisible
      ) {
        inputs.push(
          new TextInput({
            key: 'address',
            label: 'personalize.holder.address',
            value: personalizationData.Address.value,
            translate: true,
            required: false,
            cssClass: 'col-md-12 col-lg-6 column-grow-1'
          })
        );
      }

      if (personalizationData.ZipCode.isVisible) {
        inputs.push(
          new TextInput({
            key: 'zipCode',
            label: 'personalize.holder.zip-code',
            value: personalizationData.ZipCode.value,
            translate: true,
            required: personalizationData.ZipCode.isMandatory,
            cssClass: 'col-md-3 col-sm-4 col-lg-2 column-grow-1',
            zipcodeValidation: true,
            maxLengthValidation: 12,
            zipCodeCitiesTag: 'downTH-profile-edit.TH-personalization-data'
          })
        );
      }

      if (personalizationData.City.isVisible) {
        inputs.push(
          new TextInput({
            key: 'city',
            label: 'personalize.holder.city',
            value: personalizationData.City.value,
            translate: true,
            required: personalizationData.City.isMandatory,
            cssClass: 'col-md-9 col-sm-8 col-lg-5 column-grow-1',
            maxLengthValidation: 50
          })
        );
      }

      if (personalizationData.Street.isVisible) {
        inputs.push(
          new TextInput({
            key: 'street',
            label: 'personalize.holder.street',
            value: personalizationData.Street.value,
            translate: true,
            required: personalizationData.Street.isMandatory,
            cssClass: 'col-md-9 col-sm-8 col-lg-5 column-grow-1',
            maxLengthValidation: 50
          })
        );
      }

      if (personalizationData.Phone.isVisible) {
        inputs.push(
          new TextInput({
            key: 'telephone',
            label: 'personalize.holder.phone',
            value: personalizationData.Phone.value,
            translate: true,
            required: personalizationData.Phone.isMandatory,
            cssClass: 'col-md-6 column-grow-1'
          })
        );
      }

      if (personalizationData.DateOfBirth.isVisible) {
        inputs.push(
          new DateInput({
            key: 'dateOfBirth',
            label: 'personalize.holder.dateOfBirth',
            value: personalizationData.DateOfBirth.value,
            translate: true,
            required: personalizationData.DateOfBirth.isMandatory,
            cssClass: 'col-sm-5 column-grow-1',
          })
        );
      }

      inputs.push(
        new TextInput({
          key: 'email',
          label: 'personalize.holder.email',
          value: personalizationData.Email.value,
          translate: true,
          required: true,
          cssClass: 'col-md-6 column-grow-1',
          emailValidation: true,
          type: 'email'
        })
      );

      return Observable.of(inputs);
    });
};
