import * as fromRoot from '../../app.reducer';
import * as stepsActions from '../services-with-reducers/step-forms/steps-forms.actions';
import * as customizationActions from '../../shared/services-with-reducers/customization/customization.actions';

import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Observable, combineLatest as observableCombineLatest } from 'rxjs';
import { debounceTime, first, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

@Injectable()
export class StepPermissionGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _store: Store<fromRoot.State>
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const combinedObservable = observableCombineLatest(
      this._store.select(fromRoot.getStepsValidity),
      this._store.select(fromRoot.getOrderedStepsValidityArray),
      this._store.select(fromRoot.getSelectedExhibitionId),
      this._store.select(fromRoot.getLanguage)
    );

    return combinedObservable.pipe(
      first(),
      debounceTime(10),
      map(data => {
        const [
          stepsValidity,
          orderedStepsValidityArray,
          selectedExhibitionId,
          language
        ] = data;

        // get random background image from backend, app component is subscribed
        setTimeout(() => {
          this._store.dispatch(
            new customizationActions.GetLocalizedImages({
              eventId: Number(selectedExhibitionId),
              countryCode: language
            })
          );
        });

        // get page name (key) from 3rd position in url
        const pageName = state.url.split('/')[3];

        const cleanPageName = pageName.replace(/\?.*?$/, ''); // remove get parameters
        // grant access to invoice page anytime (we use this guard just to set selected step)
        if (cleanPageName === 'invoice' || cleanPageName === 'recipe') {
          return true;
        }

        // on other steps check if all forsm of previos steps are valid
        if (
          (stepsValidity.hasOwnProperty(cleanPageName) &&
            !stepsValidity[cleanPageName].visible) ||
          stepsValidity[cleanPageName].disabled
        ) {
          const lastNotDisabledPage = orderedStepsValidityArray
            .slice(0)
            .reverse()
            .find(step => {
              return (
                step.value.disabled === false &&
                step.value.visible === true &&
                step.value.showInStepNavigation
              );
            });

          if (lastNotDisabledPage) {
            const lastValidUrl = state.url.replace(
              pageName,
              lastNotDisabledPage.key
            );

            this._router.navigate([lastValidUrl], {
              queryParams: route.queryParams
            });
          }
        }

        this._store.dispatch(new stepsActions.SetSelectedStep(cleanPageName));
        return true;
      })
    );
  }
}
