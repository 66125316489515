import {
  ExhibitionSettingModel,
  OperatorsSettingsModel,
  IWithFieldSetting
} from '../shared/services-with-reducers/customization/customization.interfaces';

import { CheckboxInput } from '../shared/forms/inputs/input-checkbox.class';
import { DropdownInput } from '../shared/forms/inputs/input-dropdown.class';
import { InputBase } from '../shared/forms/inputs/input-base.class';
import { TextInput } from '../shared/forms/inputs/input-text.class';
import { RadioInput } from '../shared/forms/inputs/input-radio.class';
import { DateInput } from '../shared/forms/inputs/input-date.class';
import { TextOrDropdownInputTypes } from '../shared/services-with-reducers/helpers/helper.interface';
import { SelectOption } from '../shared/services-with-reducers/exhibition/exhibition.interface';

export function getProfileEditForm(
  exhibitionSettings: ExhibitionSettingModel,
  operatorsSettings: OperatorsSettingsModel,
  shouldDisplayAddress: boolean,
  titles: SelectOption[],
  professions: SelectOption[],
  departments: SelectOption[],
  occupationalGroups: SelectOption[]
): InputBase<any>[] {
  let settingsWithFields: IWithFieldSetting;
  if (exhibitionSettings && exhibitionSettings.registrationSettings) {
    settingsWithFields = exhibitionSettings.registrationSettings;
  } else if (operatorsSettings && operatorsSettings.registrationSettings) {
    settingsWithFields = operatorsSettings.registrationSettings;
  }

  const inputs = [];
  if (settingsWithFields.fieldSettings.Salutation.isVisible) {
    inputs.push(
      new DropdownInput({
        key: 'gender',
        label: 'profile.salutation',
        options: [
          { key: 'male', value: 'gender.male', translate: true },
          { key: 'female', value: 'gender.female', translate: true },
          { key: 'diverse', value: 'gender.diverse', translate: true },
          { key: 'notSpecified', value: 'gender.notSpecified', translate: true },
        ],
        translate: true,
        order: settingsWithFields.fieldSettings.Salutation.order,
        required: settingsWithFields
          ? settingsWithFields.fieldSettings.Salutation.isMandatory
          : true,
        cssClass: 'col-md-3 col-sm-6 column-grow-1'
      })
    );
  }

  if (settingsWithFields.fieldSettings.PersonTitle.isVisible) {
    if (titles && titles.length) {
      inputs.push(
        new DropdownInput({
          key: TextOrDropdownInputTypes.Title,
          label: 'profile.title',
          order: settingsWithFields.fieldSettings.PersonTitle.order,
          translate: true,
          required: settingsWithFields
            ? settingsWithFields.fieldSettings.PersonTitle.isMandatory
            : false,
          cssClass: 'col-lg-2 col-md-2 col-sm-6 column-grow-1',
          options: titles
        })
      );
    } else {
      inputs.push(
        new TextInput({
          key: TextOrDropdownInputTypes.Title,
          label: 'profile.title',
          order: settingsWithFields.fieldSettings.PersonTitle.order,
          translate: true,
          required: settingsWithFields
            ? settingsWithFields.fieldSettings.PersonTitle.isMandatory
            : false,
          cssClass: 'col-lg-2 col-md-2 col-sm-6 column-grow-1',
          maxLengthValidation: 50
        })
      );
    }
  }
  if (settingsWithFields.fieldSettings.FirstName.isVisible) {
    inputs.push(
      new TextInput({
        key: 'firstName',
        label: 'profile.first-name',
        order: settingsWithFields.fieldSettings.FirstName.order,
        translate: true,
        required: settingsWithFields
          ? settingsWithFields.fieldSettings.FirstName.isMandatory
          : true,
        cssClass: 'col-md-3 col-sm-6 col-lg-4 column-grow-1',
        minLengthValidation: 1,
        maxLengthValidation: 30
      })
    );
  }
  if (settingsWithFields.fieldSettings.LastName.isVisible) {
    inputs.push(
      new TextInput({
        key: 'lastName',
        label: 'profile.last-name',
        order: settingsWithFields.fieldSettings.LastName.order,
        translate: true,
        required: settingsWithFields
          ? settingsWithFields.fieldSettings.LastName.isMandatory
          : true,
        cssClass: 'col-md-4 col-sm-6 column-grow-1',
        maxLengthValidation: 50
      })
    );
  }

  if (settingsWithFields.fieldSettings.Company.isVisible) {
    inputs.push(
      new TextInput({
        key: 'company',
        label: 'profile.company',
        order: settingsWithFields.fieldSettings.Company.order,
        translate: true,
        required: settingsWithFields
          ? settingsWithFields.fieldSettings.Company.isMandatory
          : false,
        cssClass: 'col-sm-6 column-grow-1',
        maxLengthValidation: 50
      })
    );
  }

  if (settingsWithFields.fieldSettings.Country.isVisible) {
    inputs.push(
      new DropdownInput({
        key: 'country',
        label: 'profile.country',
        options: [],
        order: settingsWithFields.fieldSettings.Country.order,
        translate: true,
        required: settingsWithFields
          ? settingsWithFields.fieldSettings.Country.isMandatory
          : false,
        cssClass: 'col-sm-6 column-grow-1'
      })
    );
  }

  if (settingsWithFields.fieldSettings.ZipCode.isVisible) {
    inputs.push(
      new TextInput({
        key: 'zipCode',
        label: 'profile.zip-code',
        order: settingsWithFields.fieldSettings.ZipCode.order,
        translate: true,
        required: settingsWithFields
          ? settingsWithFields.fieldSettings.ZipCode.isMandatory
          : true,
        cssClass: 'col-sm-4 column-grow-1',
        zipcodeValidation: true,
        maxLengthValidation: 12,
        zipCodeCitiesTag: 'edit-acc-form.get-profile-edit-form'
      })
    );
  }
  if (settingsWithFields.fieldSettings.City.isVisible) {
    inputs.push(
      new TextInput({
        key: 'city',
        label: 'profile.city',
        order: settingsWithFields.fieldSettings.City.order,
        translate: true,
        required: settingsWithFields
          ? settingsWithFields.fieldSettings.City.isMandatory
          : true,
        cssClass: 'col-sm-7 column-grow-1',
        maxLengthValidation: 50
      })
    );
  }
  if (settingsWithFields.fieldSettings.Street.isVisible) {
    inputs.push(
      new TextInput({
        key: 'street',
        label: 'profile.street',
        order: settingsWithFields.fieldSettings.Street.order,
        translate: true,
        required: settingsWithFields
          ? settingsWithFields.fieldSettings.Street.isMandatory
          : true,
        cssClass: 'col-sm-7 column-grow-1',
        maxLengthValidation: 50
      })
    );
  }

  if (settingsWithFields.fieldSettings.Phone.isVisible) {
    inputs.push(
      new TextInput({
        key: 'telephone',
        label: 'profile.phone',
        order: settingsWithFields.fieldSettings.Phone.order,
        translate: true,
        required: settingsWithFields
          ? settingsWithFields.fieldSettings.Phone.isMandatory
          : false,
        cssClass: 'col-md-4 col-sm-12 column-grow-1',
        phoneValidation: exhibitionSettings
          ? !exhibitionSettings.isTelephoneValidationDisabled
          : !operatorsSettings.isTelephoneValidationDisabled
      })
    );
  }

  if (settingsWithFields.fieldSettings.PersonFunction.isVisible) {
    if (professions && professions.length) {
      inputs.push(
        new DropdownInput({
          key: TextOrDropdownInputTypes.Function,
          label: 'profile.position',
          order: settingsWithFields.fieldSettings.PersonFunction.order,
          translate: true,
          required: settingsWithFields
            ? settingsWithFields.fieldSettings.PersonFunction.isMandatory
            : false,
          cssClass: 'col-sm-5 column-grow-1',
          options: professions
        })
      );
    } else {
      inputs.push(
        new TextInput({
          key: TextOrDropdownInputTypes.Function,
          label: 'profile.position',
          order: settingsWithFields.fieldSettings.PersonFunction.order,
          translate: true,
          required: settingsWithFields
            ? settingsWithFields.fieldSettings.PersonFunction.isMandatory
            : false,
          cssClass: 'col-sm-5 column-grow-1',
          maxLengthValidation: 100
        })
      );
    }
  }

  if (settingsWithFields.fieldSettings.Department.isVisible) {
    if (departments && departments.length) {
      inputs.push(
        new DropdownInput({
          key: TextOrDropdownInputTypes.Department,
          label: 'profile.department',
          order: settingsWithFields.fieldSettings.Department.order,
          translate: true,
          required: settingsWithFields
            ? settingsWithFields.fieldSettings.Department.isMandatory
            : false,
          cssClass: 'col-sm-5 column-grow-1',
          options: departments
        })
      );
    } else {
      inputs.push(
        new TextInput({
          key: TextOrDropdownInputTypes.Department,
          label: 'profile.department',
          order: settingsWithFields.fieldSettings.Department.order,
          translate: true,
          required: settingsWithFields
            ? settingsWithFields.fieldSettings.Department.isMandatory
            : false,
          cssClass: 'col-sm-5 column-grow-1',
          maxLengthValidation: 100
        })
      );
    }
  }

  if (settingsWithFields.fieldSettings.OccupationalGroup.isVisible) {
    if (occupationalGroups && occupationalGroups.length) {
      inputs.push(
        new DropdownInput({
          key: TextOrDropdownInputTypes.OccupationalGroup,
          label: 'profile.occupationalGroup',
          order: settingsWithFields.fieldSettings.OccupationalGroup.order,
          translate: true,
          required: settingsWithFields
            ? settingsWithFields.fieldSettings.OccupationalGroup.isMandatory
            : false,
          cssClass: 'col-sm-5 column-grow-1',
          options: occupationalGroups
        })
      );
    } else {
      inputs.push(
        new TextInput({
          key: TextOrDropdownInputTypes.OccupationalGroup,
          label: 'profile.occupationalGroup',
          order: settingsWithFields.fieldSettings.OccupationalGroup.order,
          translate: true,
          required: settingsWithFields
            ? settingsWithFields.fieldSettings.OccupationalGroup.isMandatory
            : false,
          cssClass: 'col-sm-5 column-grow-1',
          maxLengthValidation: 100
        })
      );
    }
  }

  if (
    shouldDisplayAddress &&
    settingsWithFields.fieldSettings.SearchAddress.isVisible
  ) {
    inputs.push(
      new TextInput({
        key: 'address',
        label: 'profile.address',
        order: settingsWithFields.fieldSettings.SearchAddress.order,
        translate: true,
        required: false,
        cssClass: 'col-sm-6 column-grow-1'
      })
    );
  }

  if (settingsWithFields.fieldSettings.DateOfBirth.isVisible) {
    inputs.push(
      new DateInput({
        key: 'dateOfBirth',
        label: 'profile.dateOfBirth',
        order: settingsWithFields.fieldSettings.DateOfBirth.order,
        translate: true,
        required: settingsWithFields
        ? settingsWithFields.fieldSettings.DateOfBirth.isMandatory
        : false,
        cssClass: 'col-sm-5 column-grow-1'
      })
    );
  }
  // get highest order number from already pushed input fields to inputs array, after that we can safely push all fields that do not get order from API
  let highestOrder = inputs.length > 0 ? inputs.reduce((max, input) => max.order > input.order ? max : input).order : 0;

  const newsletterInputType = exhibitionSettings ? exhibitionSettings.buyerSettings.newsletterInputType : operatorsSettings.newsletterInputType;
  if (newsletterInputType !== 'hidden') {
    if (
      newsletterInputType === 'checkboxUncheckedByDefault' ||
      newsletterInputType === 'checkboxCheckedByDefault'
    ) {
      inputs.push(
        new CheckboxInput({
          key: 'newsletterChecked',
          label: '',
          order: ++highestOrder,
          translate: true,
          required: false,
          disabled: true,
          options: [
            {
              key: 'newsletterChecked',
              label: 'personalize.buyer-info.newsletter',
              value: newsletterInputType === 'checkboxCheckedByDefault',
              cssClass: 'col-sm-12'
            }
          ],
          cssClass: 'col-sm-12'
        })
      );
    } else if (newsletterInputType === 'radioButtonUnselected') {
      inputs.push(
        new RadioInput({
          key: 'newsletterChecked',
          label: '',
          order: ++highestOrder,
          translate: true,
          required: true,
          disabled: false,
          options: [
            {
              key: 'true',
              value: 'personalize.buyer-info.newsletter',
              cssClass: 'col-sm-12'
            },
            {
              key: 'false',
              value: 'personalize.buyer-info.no-newsletter',
              cssClass: 'col-sm-12'
            }
          ],
          cssClass: 'col-sm-12'
        })
      );
    }
  }

  return inputs.sort((a, b) => a.order - b.order);
}

export function getNewAccountForm(
  exhibitionSettings: ExhibitionSettingModel,
  operatorsSettings: OperatorsSettingsModel,
  titles: SelectOption[],
  professions: SelectOption[],
  departments: SelectOption[],
  occupationalGroups: SelectOption[]
): InputBase<any>[] {
  let settingsWithFields: IWithFieldSetting;
  if (exhibitionSettings && exhibitionSettings.registrationSettings) {
    settingsWithFields = exhibitionSettings.registrationSettings;
  } else if (operatorsSettings && operatorsSettings.registrationSettings) {
    settingsWithFields = operatorsSettings.registrationSettings;
  }

  const inputs = [];
  if (settingsWithFields.fieldSettings.Salutation.isVisible) {
    inputs.push(
      new DropdownInput({
        key: 'gender',
        label: 'profile.salutation',
        options: [
          { key: 'male', value: 'gender.male', translate: true },
          { key: 'female', value: 'gender.female', translate: true },
          { key: 'diverse', value: 'gender.diverse', translate: true },
          { key: 'notSpecified', value: 'gender.notSpecified', translate: true },
        ],
        translate: true,
        order: settingsWithFields.fieldSettings.Salutation.order,
        required: settingsWithFields
          ? settingsWithFields.fieldSettings.Salutation.isMandatory
          : true,
        cssClass: 'col-md-3 col-sm-6 column-grow-1'
      })
    );
  }

  if (settingsWithFields.fieldSettings.PersonTitle.isVisible) {
    if (titles && titles.length) {
      inputs.push(
        new DropdownInput({
          key: TextOrDropdownInputTypes.Title,
          label: 'profile.title',
          order: settingsWithFields.fieldSettings.PersonTitle.order,
          translate: true,
          required: settingsWithFields
            ? settingsWithFields.fieldSettings.PersonTitle.isMandatory
            : false,
          cssClass: 'col-lg-2 col-md-2 col-sm-6 column-grow-1',
          options: titles
        })
      );
    } else {
      inputs.push(
        new TextInput({
          key: TextOrDropdownInputTypes.Title,
          label: 'profile.title',
          order: settingsWithFields.fieldSettings.PersonTitle.order,
          translate: true,
          required: settingsWithFields
            ? settingsWithFields.fieldSettings.PersonTitle.isMandatory
            : false,
          cssClass: 'col-lg-2 col-md-2 col-sm-6 column-grow-1',
          maxLengthValidation: 50
        })
      );
    }
  }

  if (settingsWithFields.fieldSettings.FirstName.isVisible) {
    inputs.push(
      new TextInput({
        key: 'firstName',
        label: 'profile.first-name',
        order: settingsWithFields.fieldSettings.FirstName.order,
        translate: true,
        required: settingsWithFields
          ? settingsWithFields.fieldSettings.FirstName.isMandatory
          : true,
        cssClass: 'col-md-3 col-sm-6 col-lg-4 column-grow-1',
        minLengthValidation: 1,
        maxLengthValidation: 30
      })
    );
  }

  if (settingsWithFields.fieldSettings.LastName.isVisible) {
    inputs.push(
      new TextInput({
        key: 'lastName',
        label: 'profile.last-name',
        order: settingsWithFields.fieldSettings.LastName.order,
        translate: true,
        required: settingsWithFields
          ? settingsWithFields.fieldSettings.LastName.isMandatory
          : true,
        cssClass: 'col-md-4 col-sm-6 column-grow-1',
        maxLengthValidation: 50
      })
    );
  }

  if (settingsWithFields.fieldSettings.Company.isVisible) {
    inputs.push(
      new TextInput({
        key: 'company',
        label: 'profile.company',
        order: settingsWithFields.fieldSettings.Company.order,
        translate: true,
        required: settingsWithFields
          ? settingsWithFields.fieldSettings.Company.isMandatory
          : false,
        cssClass: 'col-sm-6 column-grow-1',
        maxLengthValidation: 50
      })
    );
  }

  if (settingsWithFields.fieldSettings.Country.isVisible) {
    inputs.push(
      new DropdownInput({
        key: 'country',
        label: 'profile.country',
        options: [],
        order: settingsWithFields.fieldSettings.Country.order,
        translate: true,
        required: settingsWithFields
          ? settingsWithFields.fieldSettings.Country.isMandatory
          : false,
        cssClass: 'col-sm-6 column-grow-1'
      })
    );
  }

  if (settingsWithFields.fieldSettings.ZipCode.isVisible) {
    inputs.push(
      new TextInput({
        key: 'zipCode',
        label: 'profile.zip-code',
        order: settingsWithFields.fieldSettings.ZipCode.order,
        translate: true,
        required: settingsWithFields
          ? settingsWithFields.fieldSettings.ZipCode.isMandatory
          : true,
        cssClass: 'col-sm-4 column-grow-1',
        zipcodeValidation: true,
        maxLengthValidation: 12,
        zipCodeCitiesTag: 'edit-acc-form.get-new-acc-form'
      })
    );
  }

  if (settingsWithFields.fieldSettings.City.isVisible) {
    inputs.push(
      new TextInput({
        key: 'city',
        label: 'profile.city',
        order: settingsWithFields.fieldSettings.City.order,
        translate: true,
        required: settingsWithFields
          ? settingsWithFields.fieldSettings.City.isMandatory
          : true,
        cssClass: 'col-sm-7 column-grow-1',
        maxLengthValidation: 50
      })
    );
  }

  if (settingsWithFields.fieldSettings.Street.isVisible) {
    inputs.push(
      new TextInput({
        key: 'street',
        label: 'profile.street',
        order: settingsWithFields.fieldSettings.Street.order,
        translate: true,
        required: settingsWithFields
          ? settingsWithFields.fieldSettings.Street.isMandatory
          : true,
        cssClass: 'col-sm-7 column-grow-1',
        maxLengthValidation: 50
      })
    );
  }

  if (settingsWithFields.fieldSettings.Phone.isVisible) {
    inputs.push(
      new TextInput({
        key: 'telephone',
        label: 'profile.phone',
        order: settingsWithFields.fieldSettings.Phone.order,
        translate: true,
        required: settingsWithFields
          ? settingsWithFields.fieldSettings.Phone.isMandatory
          : false,
        cssClass: 'col-md-4 col-sm-12 column-grow-1',
        phoneValidation: exhibitionSettings
          ? !exhibitionSettings.isTelephoneValidationDisabled
          : !operatorsSettings.isTelephoneValidationDisabled
      })
    );
  }

  inputs.push(
    new TextInput({
      key: 'email',
      label: 'profile.email',
      order: settingsWithFields.fieldSettings.Email.order,
      translate: true,
      required: true,
      cssClass: 'col-md-4 col-sm-6 column-grow-1',
      emailValidation: true,
      caseInsensitive: true,
      type: 'email'
    })
  );

  if (settingsWithFields.fieldSettings.MailConfirm.isVisible) {
    inputs.push(
      new TextInput({
        key: 'verify-email',
        label: 'profile.email-verify',
        order: settingsWithFields.fieldSettings.MailConfirm.order,
        translate: true,
        required: true,
        cssClass: 'col-md-4 col-sm-6 column-grow-1',
        emailValidation: true,
        sameAsValidation: 'email',
        caseInsensitive: true,
        type: 'email'
      })
    );
  }

  if (settingsWithFields.fieldSettings.PersonFunction.isVisible) {
    if (professions && professions.length) {
      inputs.push(
        new DropdownInput({
          key: TextOrDropdownInputTypes.Function,
          label: 'profile.position',
          order: settingsWithFields.fieldSettings.PersonFunction.order,
          translate: true,
          required: settingsWithFields
            ? settingsWithFields.fieldSettings.PersonFunction.isMandatory
            : false,
          cssClass: 'col-sm-5 column-grow-1',
          options: professions
        })
      );
    } else {
      inputs.push(
        new TextInput({
          key: TextOrDropdownInputTypes.Function,
          label: 'profile.position',
          order: settingsWithFields.fieldSettings.PersonFunction.order,
          translate: true,
          required: settingsWithFields
            ? settingsWithFields.fieldSettings.PersonFunction.isMandatory
            : false,
          cssClass: 'col-sm-5 column-grow-1',
          maxLengthValidation: 100
        })
      );
    }
  }

  if (settingsWithFields.fieldSettings.Department.isVisible) {
    if (departments && departments.length) {
      inputs.push(
        new DropdownInput({
          key: TextOrDropdownInputTypes.Department,
          label: 'profile.department',
          order: settingsWithFields.fieldSettings.Department.order,
          translate: true,
          required: settingsWithFields
            ? settingsWithFields.fieldSettings.Department.isMandatory
            : false,
          cssClass: 'col-sm-5 column-grow-1',
          options: departments
        })
      );
    } else {
      inputs.push(
        new TextInput({
          key: TextOrDropdownInputTypes.Department,
          label: 'profile.department',
          order: settingsWithFields.fieldSettings.Department.order,
          translate: true,
          required: settingsWithFields
            ? settingsWithFields.fieldSettings.Department.isMandatory
            : false,
          cssClass: 'col-sm-5 column-grow-1',
          maxLengthValidation: 100
        })
      );
    }
  }

  if (settingsWithFields.fieldSettings.OccupationalGroup.isVisible) {
    if (occupationalGroups && occupationalGroups.length) {
      inputs.push(
        new DropdownInput({
          key: TextOrDropdownInputTypes.OccupationalGroup,
          label: 'profile.occupationalGroup',
          order: settingsWithFields.fieldSettings.OccupationalGroup.order,
          translate: true,
          required: settingsWithFields
            ? settingsWithFields.fieldSettings.OccupationalGroup.isMandatory
            : false,
          cssClass: 'col-sm-5 column-grow-1',
          options: occupationalGroups
        })
      );
    } else {
      inputs.push(
        new TextInput({
          key: TextOrDropdownInputTypes.OccupationalGroup,
          label: 'profile.occupationalGroup',
          order: settingsWithFields.fieldSettings.OccupationalGroup.order,
          translate: true,
          required: settingsWithFields
            ? settingsWithFields.fieldSettings.OccupationalGroup.isMandatory
            : false,
          cssClass: 'col-sm-5 column-grow-1',
          maxLengthValidation: 100
        })
      );
    }
  }

  if (settingsWithFields.fieldSettings.SearchAddress.isVisible) {
    inputs.push(
      new TextInput({
        key: 'address',
        label: 'profile.address',
        order: settingsWithFields.fieldSettings.SearchAddress.order,
        translate: true,
        required: false,
        cssClass: 'col-sm-6 column-grow-1'
      })
    );
  }

  if (settingsWithFields.fieldSettings.DateOfBirth.isVisible) {
    inputs.push(
      new DateInput({
        key: 'dateOfBirth',
        label: 'profile.dateOfBirth',
        order: settingsWithFields.fieldSettings.DateOfBirth.order,
        translate: true,
        required: settingsWithFields
        ? settingsWithFields.fieldSettings.DateOfBirth.isMandatory
        : false,
        cssClass: 'col-sm-5 column-grow-1'
      })
    );
  }

  // get highest order number from already pushed input fields to inputs array, after that we can safely push all fields that do not get order from API
  let highestOrder = inputs.length > 0 ? inputs.reduce((max, input) => max.order > input.order ? max : input).order : 0;

  inputs.push(
    new TextInput({
      key: 'password',
      label: 'profile.password',
      order: ++highestOrder,
      translate: true,
      required: true,
      cssClass: 'col-sm-6 column-grow-1',
      type: 'password'
    })
  );

  inputs.push(
    new TextInput({
      key: 'verify-password',
      label: 'profile.password-verify',
      order: ++highestOrder,
      translate: true,
      required: true,
      cssClass: 'col-sm-6 column-grow-1',
      sameAsValidation: 'password',
      type: 'password'
    })
  );

  return inputs.sort((a, b) => a.order - b.order);
}

export function getPasswordChangeForm(): InputBase<any>[] {
  const inputs = [
    new TextInput({
      key: 'password',
      label: 'profile.password',
      order: 1,
      translate: true,
      required: true,
      cssClass: 'col-md-6 col-lg-3',
      //sameAsValidation: 'verify-password',
      type: 'password'
    }),
    new TextInput({
      key: 'verify-password',
      label: 'profile.password-verify',
      order: 2,
      translate: true,
      required: true,
      cssClass: 'col-md-6 col-lg-3',
      sameAsValidation: 'password',
      type: 'password'
    })
  ];

  return inputs;
}

export function getEmailChangeForm(): InputBase<any>[] {
  const inputs = [
    new TextInput({
      key: 'email',
      label: 'profile.email',
      order: 1,
      translate: true,
      required: true,
      cssClass: 'col-md-4',
      emailValidation: true,
      caseInsensitive: true,
      autocomplete: false,
      type: 'email'
      //sameAsValidation: 'verify-email'
    }),
    new TextInput({
      key: 'verify-email',
      label: 'profile.email-verify',
      order: 2,
      translate: true,
      required: true,
      cssClass: 'col-md-4',
      sameAsValidation: 'email',
      caseInsensitive: true,
      autocomplete: false,
      type: 'email'
    }),
    new TextInput({
      key: 'password',
      label: 'profile.password',
      order: 3,
      translate: true,
      required: true,
      cssClass: 'col-md-4',
      type: 'password',
      autocomplete: false
    })
  ];

  return inputs;
}
