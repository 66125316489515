import * as fromRoot from '../../../app.reducer';

import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter
} from '@angular/core';
import { BehaviorSubject } from 'rxjs/Rx';

import {
  WorkshopBookingModel,
  WorkshopModel
} from '../../../shared/services-with-reducers/additional-services/additional-services.interface';

import { Store } from '@ngrx/store';
import { Subscription } from '../../../../../node_modules/rxjs';
import { filter, first } from 'rxjs/operators';

@Component({
  selector: 'app-workshop-visitors-count',
  templateUrl: './workshop-visitors-count.component.html',
  styleUrls: ['./workshop-visitors-count.component.scss']
})
export class WorkshopVisitorsCountComponent implements OnInit, OnDestroy {
  @Input()
  workshopBookings: WorkshopBookingModel[];
  @Input()
  workshop: WorkshopModel;
  @Input()
  ticketsWithHolders: BehaviorSubject<any[]>;
  @Input()
  showAmountOfAvailableWorkshops: boolean;

  public noSeatsLeft: boolean = false;
  //public noOfAssignedVisitors = 0;
  public noOfMyAssignedVisitors = 0;
  public workshopSeats = 0;
  public lastSeatWithSingleAssignedTicket = false;
  public ticketsWithHoldersArray = [];

  private _subscriptions = new Subscription();

  constructor(private _store: Store<fromRoot.State>) {}

  ngOnInit() {
    this.workshopSeats = this.workshop.seats;

    this.ticketsWithHolders.subscribe(ticketsWithHolders => {
      this.isWorkshopSoldOut(this.workshop.seats);

      this.ticketsWithHoldersArray = ticketsWithHolders;
      this.noOfMyAssignedVisitors = this.getMyWorkshopAttendeesNumber(
        ticketsWithHolders,
        this.workshop
      );

      this._subscriptions.add(
        this._store
          .select(fromRoot.getWorkshopBookings)
          .pipe(filter(data => !!data))
          .subscribe(bookings => {
            bookings.bookings.map(booking => {
              if (booking.workshopId === this.workshop.workshopId) {
                this.workshopSeats = booking.seats;
                this.isWorkshopSoldOut(booking.seats);
              }
            });
          })
      );
    });
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  isWorkshopSoldOut(booking: number) {
    let isSoldOut = false;

    this._store
      .select(fromRoot.getTicketsCount)
      .pipe(first())
      .subscribe(tickets => {
        if (booking <= 0) {
          isSoldOut = true;
        } else {
          isSoldOut = false;
        }

        this.checkLastSeatWithSingleAssignedTicket(booking, tickets);
      });

    this.noSeatsLeft = isSoldOut;
  }

  checkLastSeatWithSingleAssignedTicket(
    booking: string | number,
    tickets: string | number
  ) {
    const noOfMyAssignedVisitors = this.getMyWorkshopAttendeesNumber(
      this.ticketsWithHoldersArray,
      this.workshop
    );
    const lastSeatAndSingleTicket =
      booking == 0 && tickets == 1 && noOfMyAssignedVisitors === 1;

    this.lastSeatWithSingleAssignedTicket = lastSeatAndSingleTicket;
  }

  getMyWorkshopAttendeesNumber(ticketsWithHolders, workshop: WorkshopModel) {
    return ticketsWithHolders.reduce((agr, curr) => {
      return (agr += curr.ticketHolderAdditionalData
        ? Number(
            curr.ticketHolderAdditionalData.workshops.includes(
              workshop.workshopId
            )
          )
        : 0);
    }, 0);
  }
}
