import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hideTranslation'
})
export class HideTranslationPipe implements PipeTransform {
  transform(value: string, args?: any): any {
    if (value && value.startsWith('-notshown-')) {
      return '';
    }
    return value;
  }
}
