import { BuyerSettings } from '../customization.interfaces';
import { CheckboxInput } from '../../../forms/inputs/input-checkbox.class';
import { DropdownInput } from '../../../forms/inputs/input-dropdown.class';
import { InputBase } from '../../../forms/inputs/input-base.class';
import { TextInput } from '../../../forms/inputs/input-text.class';

export const prepareBillingAddressData = (
  buyerSettings: BuyerSettings,
  isGoogleAutocompleteEnabled: boolean,
): InputBase<any>[] => {
  const addressAutocompleteSettings = isGoogleAutocompleteEnabled;

  const inputs = [
    new TextInput({
      key: 'company',
      label: 'personalize.buyer-info.company',
      order: 1,
      translate: true,
      required: buyerSettings.fieldSettings.Company.isMandatory,
      cssClass: 'col-sm-6 column-grow-1'
    }),
    new DropdownInput({
      key: 'country',
      label: 'personalize.buyer-info.country',
      options: [],
      order: 2,
      translate: true,
      required: buyerSettings.fieldSettings.Country.isMandatory,
      cssClass: 'col-sm-6 column-grow-1'
    }),
    new TextInput({
      key: 'zipCode',
      label: 'personalize.buyer-info.zip-code',
      order: 4,
      translate: true,
      required: buyerSettings.fieldSettings.ZipCode.isMandatory,
      cssClass: 'col-md-3 col-sm-4 col-lg-4 column-grow-1',
      zipcodeValidation: true,
      zipCodeCitiesTag: 'forms.prepare-billing-address-data'
    }),
    new TextInput({
      key: 'city',
      label: 'personalize.buyer-info.city',
      order: 5,
      translate: true,
      required: buyerSettings.fieldSettings.City.isMandatory,
      cssClass: 'col-md-9 col-sm-8 col-lg-5 column-grow-1'
    }),
    new TextInput({
      key: 'street',
      label: 'personalize.buyer-info.street',
      order: 6,
      translate: true,
      required: buyerSettings.fieldSettings.Street.isMandatory,
      cssClass: 'col-md-9 col-sm-8 col-lg-5 column-grow-1'
    }),

    new CheckboxInput({
      key: 'save-address',
      label: '',
      order: 8,
      translate: true,
      required: false,
      options: [
        {
          key: 'saveBillingAddress',
          value: 0,
          label: 'personalize.buyer-info.billing-address.save-address',
          cssClass: 'col-sm-12'
        }
      ],
      cssClass: 'col-sm-12'
    })
  ];

  if (addressAutocompleteSettings) {
    inputs.push(
      new TextInput({
        key: 'address',
        label: 'personalize.buyer-info.address',
        order: 3,
        translate: true,
        required: false,
        cssClass: 'col-md-12 col-lg-8 column-grow-1'
      })
    );
  }

  return inputs.sort((a, b) => a.order - b.order);
};
