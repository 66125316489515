/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ticket-dropdown.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "../shared/pipes/hide-translation/hide-translation.pipe";
import * as i5 from "./ticket-dropdown.component";
import * as i6 from "@ngrx/store";
var styles_TicketDropdownComponent = [i0.styles];
var RenderType_TicketDropdownComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TicketDropdownComponent, data: {} });
export { RenderType_TicketDropdownComponent as RenderType_TicketDropdownComponent };
function View_TicketDropdownComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "dropdown-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dropdownItemClicked(_v.parent.context.$implicit.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, ":svg:svg", [["class", "dropdown-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, ":svg:use", [], [[1, ":xlink:href", 0]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "span", [["class", "dropdown-item-text"]], [[1, "data-translation", 0]], null, null, null, null)), i1.ɵppd(4, 1), (_l()(), i1.ɵted(5, null, [" ", " "])), i1.ɵppd(6, 1), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(8, 1)], null, function (_ck, _v) { var currVal_0 = ("assets/defs.svg#icon-" + _v.parent.context.$implicit.value); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent, 0), ("confirmation.sending-options." + _v.parent.context.$implicit.value))); _ck(_v, 3, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 5, 0, _ck(_v, 8, 0, i1.ɵnov(_v.parent.parent, 1), i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 7).transform(i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent.parent, 0), ("confirmation.sending-options." + _v.parent.context.$implicit.value))))))); _ck(_v, 5, 0, currVal_2); }); }
function View_TicketDropdownComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [["class", "dropdown-item-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TicketDropdownComponent_2)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit.value !== _co.selectedSendingOption); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_TicketDropdownComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.LowerCasePipe, []), i1.ɵpid(0, i4.HideTranslationPipe, []), (_l()(), i1.ɵeld(2, 0, null, null, 15, "div", [["appClickOutside", ""], ["class", "dropdown select"]], null, [[null, "clickOutside"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickOutside" === en)) {
        var pd_0 = ((_co.opened = false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "show": 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 8, "button", [["aria-expanded", "false"], ["aria-haspopup", "true"], ["class", "dropdown-button btn dropdown-toggle"], ["data-toggle", "dropdown"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.opened = !_co.opened) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, ":svg:svg", [["class", "dropdown-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, ":svg:use", [], [[1, ":xlink:href", 0]], null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 5, "span", [["class", "dropdown-item-text"]], [[1, "data-translation", 0]], null, null, null, null)), i1.ɵppd(9, 1), (_l()(), i1.ɵted(10, null, ["", ""])), i1.ɵppd(11, 1), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(13, 1), (_l()(), i1.ɵeld(14, 0, null, null, 3, "div", [["class", "dropdown-menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "ul", [["aria-labelledby", "dropdownMenuButton"], ["class", "dropdown-menu-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TicketDropdownComponent_1)), i1.ɵdid(17, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "dropdown select"; var currVal_1 = _ck(_v, 4, 0, _co.opened); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_5 = _co.confirmationOptions; _ck(_v, 17, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = ("assets/defs.svg#icon-" + _co.selectedIcon); _ck(_v, 7, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i1.ɵnov(_v, 0), ("confirmation.sending-options." + _co.selectedSendingOption))); _ck(_v, 8, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 10, 0, _ck(_v, 13, 0, i1.ɵnov(_v, 1), i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 12).transform(i1.ɵunv(_v, 10, 0, _ck(_v, 11, 0, i1.ɵnov(_v, 0), ("confirmation.sending-options." + _co.selectedSendingOption))))))); _ck(_v, 10, 0, currVal_4); }); }
export function View_TicketDropdownComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ticket-dropdown", [], null, null, null, View_TicketDropdownComponent_0, RenderType_TicketDropdownComponent)), i1.ɵdid(1, 114688, null, 0, i5.TicketDropdownComponent, [i6.Store, i2.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TicketDropdownComponentNgFactory = i1.ɵccf("app-ticket-dropdown", i5.TicketDropdownComponent, View_TicketDropdownComponent_Host_0, { confirmationOptions: "confirmationOptions", ticket: "ticket" }, { onSelect: "onSelect" }, []);
export { TicketDropdownComponentNgFactory as TicketDropdownComponentNgFactory };
