import * as exhibitionActions from '../../shared/services-with-reducers/exhibition/exhibition.actions';
import * as fromRoot from '../../app.reducer';

import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  OnDestroy
} from '@angular/core';
import {
  Observable,
  combineLatest as observableCombineLatest,
  Subscription
} from 'rxjs';
import { filter, first, take } from 'rxjs/operators';

import { DOCUMENT } from '@angular/platform-browser';
import { ExhibitionModel } from '../../shared/services-with-reducers/exhibition/exhibition.interface';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { TranslationsService } from './translations.service';
import { WindowSizeService } from '../../shared/window-size/window-size.service';

@Component({
  moduleId: module.id,
  selector: 'app-translations',
  templateUrl: './translations.component.html',
  styleUrls: ['./translations.component.scss']
})
export class TranslationsComponent implements OnInit, OnDestroy {
  translationTree: Array<any> = [];
  translationsByLang: any;
  operatorTranslationsByLang: any;
  activeLang: string;
  tabs: Array<any> = [];
  translationTreeLoaded = false;
  activeTab: string;

  modalWindowOpen = false;
  modalType: string; // load / save
  modalTitle: string;
  confirmationPosition: any = {};
  selectedEventID: number | string = 'default';
  selectedEvent: ExhibitionModel;
  isSaving = false;

  public exhibitions$: Observable<ExhibitionModel[]>;

  private _subscription: Subscription = new Subscription();
  private elementsWithRte: Array<string> = [
    'footer.title',
    'eventseries.more-events',
    'singleNews.body',
    'eventseries.no-events',
    'legitimation.pending.subtitle',
    'legitimation.login-message',
    'legitimation.subtitle',
    'legitimation.fax-template.text',
    'legitimation.fax-template.legitimation-number',
    'legitimation.fax-template.bottom-text',
    'workshop.description',
    'invoice.title',
    'invoice.subtitle.summary',
    'invoice.subtitle.mobile',
    'invoice.subtitle.multiple',
    'personalize.buyer-info.section-title',
    'personalize.privacy-link.text',
    'personalize.privacy-optional.text',
    'ticket-selection.login-message',
    'ticket-selection.top-info-text',
    'ticket.selfregistration.info-text',
    'step-walktrough.title',
    'workshop.title',
    'workshop.infoText'
  ];

  constructor(
    @Inject(DOCUMENT) private document: any,
    private _translationsService: TranslationsService,
    private _windowSizeService: WindowSizeService,
    private _translateService: TranslateService,
    private el: ElementRef,
    private _store: Store<fromRoot.State>,
    private _router: Router
  ) {
    this.activeLang = this._translateService.currentLang;
    this._store.dispatch(
      new exhibitionActions.GetListOfAllExhibitions(this.activeLang)
    );

    this.exhibitions$ = this._store.select(
      fromRoot.getAllExhibitionsCollection
    );
  }

  ngOnInit() {    
    this._store.pipe(
      select(fromRoot.getSupportedLanguages),
      filter(data => !!data && !!data.length),
      first()
      )
      .subscribe(languages => {
        const langTranslations = languages.reduce((acc, curr) => {
          acc.push(`languages.${curr}`);
          return acc;
        }, []);

        this._translateService
          .stream(langTranslations)
          .subscribe(translations => {
            this.tabs = languages.reduce((acc, lang) => {
              acc.push({
                id: lang,
                title: translations[`languages.${lang}`],
                text: ''
              });

              return acc;
            }, []);
          });
      });

    if (!this.activeLang) {
      this.onTabChange('en');
    } else {
      this.activeTab = this.activeLang;
    }

    this._subscription.add(
      this._store.pipe(
        select(fromRoot.getSelectedExhibitionId))
        .subscribe(eventId => {
          if (eventId === null) {
            this.selectedEventID = 'default';
          } else {
            this.selectedEventID = Number(eventId);
          }

          this.setSelectedExhibition(this.selectedEventID);

          const combined = observableCombineLatest([
            this._translationsService.getDefaultTranslations(),
            this._translationsService.getLangTranslations(
              this.activeLang,
              'default'
            ),
            this._translationsService.getLangTranslations(
              this.activeLang,
              this.selectedEventID
            )
            ]).pipe(take(2));

          // on first load wait for default and specific lang file to load before matching them
          combined.subscribe(data => {
            this.operatorTranslationsByLang = data[1];
            this.translationsByLang = data[2];

            this.translationTree = this.getArrayOfDefaultTranslations(
              data[0],
              [],
              true
            );
            this.assignLangTranslations(this.translationTree);
            this.translationTreeLoaded = true;
            this.scrollToPreselectedInput();
          });

          this._router.events.subscribe(data => {
            if (data) {
              this.document.body.classList.remove('no-scroll');
            }
          });
        })
    );
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  setSelectedExhibition(eventId: number | string) {
    if (eventId !== 'default') {
      this._subscription.add(
        this.exhibitions$.subscribe(exhibitions => {
          this.selectedEvent = exhibitions.find(
            exhibition => exhibition.id === eventId
          );
        })
      );
    } else {
      this.selectedEvent = null;
    }
  }

  scrollToInput(el, time) {
    this._windowSizeService.scrollToElement(el, 50, 255, time);
  }

  scrollToPreselectedInput() {
    if (this._translationsService.activeTranslation) {
      setTimeout(() => {
        const activeInput = (document.querySelector(
          '#translations-container input[translation-path="' +
            this._translationsService.activeTranslation +
            '"]'
        ) as HTMLInputElement) as any;
        this.scrollToInput(activeInput, 0.01);
        setTimeout(() => {
          activeInput.focus();
        }, 100);

        this._translationsService.activeTranslation = '';
      }, 100);
    }
  }

  onTabChange(value) {
    if (this.activeLang !== value) {
      this.activeLang = value;
      this.activeTab = value;
      this.loadTranslations();
    }
  }

  openModalWindow(type) {
    this.modalWindowOpen = true;
    this.modalType = type;
    this._subscription.add(
      this._translateService
        .get('modalWindow.' + type + '-title', {
          lang: this.activeLang.toUpperCase()
        })
        .subscribe((title: string) => {
          this.modalTitle = title;
        })
    );

    this.document.body.classList.add('no-scroll');
  }

  closeModalWindow(event) {
    event.stopPropagation();
    this.modalWindowOpen = false;
    this.document.body.classList.remove('no-scroll');
  }

  submitModalWindow(event) {
    event.stopPropagation();
    this.document.body.classList.remove('no-scroll');
    this.modalWindowOpen = false;
    if (this.modalType === 'save') {
      this.saveTranslations();
    } else if (this.modalType === 'load') {
      this.loadTranslations();
    }
  }

  selectedTile(id) {
    this.selectedEventID = id;
  }

  // load translations

  loadTranslations() {
    this.setSelectedExhibition(this.selectedEventID);
    this._subscription.add(
      observableCombineLatest([
        this._translationsService.getLangTranslations(
          this.activeLang,
          this.selectedEventID
        ),
        this._translationsService.getLangTranslations(
          this.activeLang,
          'default'
        )
      ]).subscribe(data => {
        this.translationsByLang = data[0];
        this.operatorTranslationsByLang = data[1];
        this.assignLangTranslations(this.translationTree);
      })
    );
  }

  // called from HTML template
  saveTranslations() {
    this.isSaving = true;
    this.setSelectedExhibition(this.selectedEventID);
    const generatedTranslations = this.generateTranslationsToSave(
      this.translationTree
    );

    this._subscription.add(
      this._translationsService
        .saveTranslations(
          this.activeLang,
          this.selectedEventID,
          generatedTranslations
        )
        .subscribe(_ => (this.isSaving = false), _ => (this.isSaving = false))
    );
  }

  // called from HTML template
  nextEmptyTranslation() {
    const emptyInputs = (document.querySelectorAll(
      '#translations-container input:invalid'
    ) as any) as HTMLInputElement[];
    if (emptyInputs.length) {
      const firstEmptyInput: HTMLInputElement = emptyInputs[0];
      const timeToScroll = 0.3;

      this.scrollToInput(firstEmptyInput, timeToScroll);

      setTimeout(() => {
        firstEmptyInput.focus();
      }, timeToScroll * 1000);
    }
  }

  /* Functions for building the tree structure */

  generateTranslationsToSave(translationsTree: Array<any>): Object {
    const translationsToSave = {};

    translationsTree.forEach(element => {
      if (element.subtree.length) {
        translationsToSave[element.key] = this.generateTranslationsToSave(
          element.subtree
        );
      } else if (!element.isFallback) {
        if (element.isActive) {
          translationsToSave[element.key] = element.translation;
        } else {
          translationsToSave[element.key] = `-notshown-${element.translation}`;
        }
      }
    });

    return translationsToSave;
  }

  assignLangTranslations(translationArray: Array<any>) {
    let deafaultTranslations;
    try {
      deafaultTranslations = require('../../../assets/i18n/' +
        this.activeLang +
        '.json');
    } catch (error) {
      console.warn(error);
    }

    translationArray.forEach(element => {
      element.shouldDisplayRTE = this.shouldDisplayRTE(element.key);

      if (element.subtree.length) {
        this.assignLangTranslations(element.subtree);
      } else {
        const eventTranslation = this.getTranslationByPath(
          element.path,
          this.translationsByLang
        );

        let fallbackTranslation = this.getTranslationByPath(
          element.path,
          this.operatorTranslationsByLang
        );
        element.fallbackTranslation = fallbackTranslation;

        if (eventTranslation) {
          fallbackTranslation = eventTranslation;
        }

        element.isFallback = !eventTranslation && !!fallbackTranslation;

        if (
          !fallbackTranslation &&
          deafaultTranslations &&
          deafaultTranslations[element.key]
        ) {
          fallbackTranslation = deafaultTranslations[element.key];
          element.isFallback = true;
        }

        if (fallbackTranslation.startsWith('-notshown-')) {
          element.isActive = false;
          element.translation = fallbackTranslation.replace(/^-notshown-/, '');
        } else {
          element.translation = fallbackTranslation;
        }
      }
    });
  }

  shouldDisplayRTE(elementKey: string): boolean {
    if (elementKey.includes('.info')) return true;
    if (elementKey.startsWith('step-walktrough.')) return true;

    return this.elementsWithRte.includes(elementKey);
  }

  getTranslationByPath(
    pathArray: Array<any>,
    translationsByLang: Object
  ): string {
    if (translationsByLang && translationsByLang.hasOwnProperty(pathArray[0])) {
      if (typeof translationsByLang[pathArray[0]] === 'string') {
        return translationsByLang[pathArray[0]];
      } else {
        const tempPath = [...pathArray];
        const key = tempPath.shift();

        return this.getTranslationByPath(tempPath, translationsByLang[key]);
      }
    } else {
      return '';
    }
  }

  getArrayOfDefaultTranslations(
    tree: Array<any>,
    pathArray: Array<string>,
    isBaseLevel: boolean
  ): Array<any> {
    const translations = [];

    for (const key of Object.keys(tree)) {
      let description = '';
      let subtree = [];
      const nextLevelPath = [...pathArray, key];

      if (typeof tree[key] === 'string') {
        description = tree[key];
      } else {
        subtree = this.getArrayOfDefaultTranslations(
          tree[key],
          nextLevelPath,
          false
        );
        // we know it is object with another subtree so we do recursive call
      }

      if ('-notshown-') {
      }

      const translation = {
        key: key,
        description: description,
        subtree: subtree,
        path: nextLevelPath,
        pathString: nextLevelPath.join('.'),
        translation: '',
        isBaseLevel: isBaseLevel,
        isActive: true,
        isFallback: true,
        fallbackTranslation: ''
      };

      translations.push(translation);
    }

    return translations;
  }

  goToDataProtectionModal() {
    this._router.navigate(['/translations/data-protection']);
  }

  goToMaintenanceModal() {
    this._router.navigate(['/maintenance']);
  }
}
