/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./download-ticket-holder-profile-edit.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/forms/df-input/df-input.component.ngfactory";
import * as i3 from "../../../shared/forms/df-input/df-input.component";
import * as i4 from "../../../shared/forms/forms.service";
import * as i5 from "../../../shared/services-with-reducers/helpers/helper.service";
import * as i6 from "@ngrx/store";
import * as i7 from "../../../shared/validation/validation.service";
import * as i8 from "@ngx-translate/core";
import * as i9 from "@angular/forms";
import * as i10 from "@angular/common";
import * as i11 from "../../../loader/loader.component.ngfactory";
import * as i12 from "../../../loader/loader.component";
import * as i13 from "../../../shared/pipes/hide-translation/hide-translation.pipe";
import * as i14 from "./download-ticket-holder-profile-edit.component";
import * as i15 from "../../../shared/services-with-reducers/customization/customization.service";
import * as i16 from "../../../shared/services-with-reducers/tickets/tickets.service";
var styles_DownloadTicketHolderProfileEditComponent = [i0.styles];
var RenderType_DownloadTicketHolderProfileEditComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DownloadTicketHolderProfileEditComponent, data: {} });
export { RenderType_DownloadTicketHolderProfileEditComponent as RenderType_DownloadTicketHolderProfileEditComponent };
function View_DownloadTicketHolderProfileEditComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-df-input", [], [[8, "className", 0]], null, null, i2.View_DfInputComponent_0, i2.RenderType_DfInputComponent)), i1.ɵdid(1, 4964352, null, 0, i3.DfInputComponent, [i4.FormsService, i5.HelperService, i6.Store, i1.ElementRef, i1.Renderer2, i7.ValidationService], { input: [0, "input"], inputSet: [1, "inputSet"], action: [2, "action"], form: [3, "form"], saveFormCallback: [4, "saveFormCallback"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _v.context.$implicit; var currVal_2 = _co.inputs; var currVal_3 = _co.actionName; var currVal_4 = _co.form; var currVal_5 = _co.inputsChanged; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.cssClass; _ck(_v, 0, 0, currVal_0); }); }
function View_DownloadTicketHolderProfileEditComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 11, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "col-sm-12 col-md-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "button", [["class", "button reject-button"], ["data-translation", "profile.cancel"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancelProfileEdit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), i1.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(7, 1), (_l()(), i1.ɵeld(8, 0, null, null, 5, "div", [["class", "col-sm-12 col-md-4 offset-md-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 4, "button", [["class", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sumbitProfileUpdate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 3, "span", [["data-translation", "profile.save"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), i1.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(13, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 5, 0, _ck(_v, 7, 0, i1.ɵnov(_v.parent.parent.parent, 0), i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("profile.cancel")))); _ck(_v, 5, 0, currVal_0); var currVal_1 = !_co.form.valid; _ck(_v, 9, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 11, 0, _ck(_v, 13, 0, i1.ɵnov(_v.parent.parent.parent, 0), i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform("profile.save")))); _ck(_v, 11, 0, currVal_2); }); }
function View_DownloadTicketHolderProfileEditComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "form", [["class", "row"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i9.ɵangular_packages_forms_forms_bg, [], null, null), i1.ɵdid(2, 540672, null, 0, i9.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i9.ControlContainer, null, [i9.FormGroupDirective]), i1.ɵdid(4, 16384, null, 0, i9.NgControlStatusGroup, [[4, i9.ControlContainer]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DownloadTicketHolderProfileEditComponent_3)), i1.ɵdid(6, 278528, null, 0, i10.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DownloadTicketHolderProfileEditComponent_4)), i1.ɵdid(8, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.form; _ck(_v, 2, 0, currVal_7); var currVal_8 = _co.inputs; _ck(_v, 6, 0, currVal_8); var currVal_9 = _co.ticket; _ck(_v, 8, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 4).ngClassValid; var currVal_5 = i1.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 4).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_DownloadTicketHolderProfileEditComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DownloadTicketHolderProfileEditComponent_2)), i1.ɵdid(2, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.form; _ck(_v, 2, 0, currVal_0); }, null); }
function View_DownloadTicketHolderProfileEditComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loader", [], null, null, null, i11.View_LoaderComponent_0, i11.RenderType_LoaderComponent)), i1.ɵdid(1, 49152, null, 0, i12.LoaderComponent, [i1.ElementRef], null, null)], null, null); }
export function View_DownloadTicketHolderProfileEditComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i13.HideTranslationPipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DownloadTicketHolderProfileEditComponent_1)), i1.ɵdid(2, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["loading", 2]], null, 0, null, View_DownloadTicketHolderProfileEditComponent_5))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loaded; var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_DownloadTicketHolderProfileEditComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-download-ticket-holder-profile-edit", [], null, null, null, View_DownloadTicketHolderProfileEditComponent_0, RenderType_DownloadTicketHolderProfileEditComponent)), i1.ɵdid(1, 245760, null, 0, i14.DownloadTicketHolderProfileEditComponent, [i6.Store, i15.CustomizationService, i4.FormsService, i5.HelperService, i8.TranslateService, i16.TicketsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DownloadTicketHolderProfileEditComponentNgFactory = i1.ɵccf("app-download-ticket-holder-profile-edit", i14.DownloadTicketHolderProfileEditComponent, View_DownloadTicketHolderProfileEditComponent_Host_0, { ticket: "ticket" }, { isValid: "isValid" }, []);
export { DownloadTicketHolderProfileEditComponentNgFactory as DownloadTicketHolderProfileEditComponentNgFactory };
