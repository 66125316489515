<div appAdjustSideHeadline>
  <!-- *ngIf="ticketHolderInputSets.length > 1 || ticketSelectedSendingOption !== 'mobilePerOwner'" -->
  <div class="section-headline">
    <h2 data-translation="personalize.order-tickets.title">
      {{ 'personalize.order-tickets.title' | translate | hideTranslation }}:
    </h2>
    <div
      class="section-headline-side"
      data-translation="personalize.order-tickets.headline"
    >
      {{ 'personalize.order-tickets.headline' | translate | hideTranslation }}
    </div>
  </div>
  <div class="section-body" *ngIf="ticketHolderInputSets">
    <app-info-block
      infoText="personalize.order-tickets.subtitle"
      *ngIf="
        !(
          'personalize.order-tickets.subtitle'
          | translate
          | isHiddenTranslation
        )
      "
    ></app-info-block>
    <div class="order-wrapper">
      <ul class="validity-list" *ngIf="ticketHolderInputSets.length > 1">
        <li
          *ngFor="let validity of ticketHoldersValidity; let i = index"
          (click)="goToSlide(i)"
          class="validity-list-list"
          [ngClass]="{
            active: activeSlideIndex === i,
            valid: validity
          }"
        >
          <div class="validity-list-index" [ngClass]="{ valid: validity }">
            <span>{{ i + 1 }}</span>
          </div>

          <div class="validity-list-icons">
            <svg class="validity-list-icon" [ngClass]="{ valid: validity }">
              <use
                xlink:href="assets/defs.svg#icon-user"
                href="assets/defs.svg#icon-user"
              ></use>
            </svg>
          </div>

          <div class="validity-list-detail">
            <div class="detail-ticket-user">
              <span *ngIf="ticketHolderArray[i].gender"
                >{{
                  'gender.' + ticketHolderArray[i].gender | translate
                }}&#32;</span
              >
              <span *ngIf="ticketHolderArray[i].firstName"
                >{{ ticketHolderArray[i].firstName }}&#32;</span
              >
              <span *ngIf="ticketHolderArray[i].lastName"
                >{{ ticketHolderArray[i].lastName }}&#32;</span
              >
            </div>

            <div *ngIf="ticketNames[i]" class="detail-ticket-name">
              {{
                ticketNames[i].ticketGroupName | translate | hideTranslation
              }}:&#32;
              {{ ticketNames[i].ticketName | translate }}
              &#32;
            </div>

            <ng-container
              *ngFor="let inputNamesCombination of inputNamesForDuplicateSearch"
            >
              <div
                data-translation="personalize.holder.duplicate"
                class="duplicates-warning"
                [class.duplicates-error]="
                  uniqueVisitorCheckType === 'mustBeUnique'
                "
                *ngIf="
                  !!duplicatesList[i] && !!duplicatesList[i][duplicateKeyName(inputNamesCombination)] && duplicatesList[i][duplicateKeyName(inputNamesCombination)].duplicateIndexes.length > 0
                "
              >
                {{ 'personalize.holder.duplicate' | translate }}:&#32;
                {{
                  'personalize.holder.' + duplicateKeyName(inputNamesCombination) | translate
                }}
              </div>
            </ng-container>

            <div
              data-translation="personalize.holder.ticket-overlimit"
              class="ticket-overlimit-error"
              *ngIf="!!ticketsOverLimitList[i] && ticketsOverLimitList[i].isChecked && ticketsOverLimitList[i].isInvalid"
            >
              {{ 'personalize.holder.ticket-overlimit' | translate }}
            </div>
          </div>

          <div *ngIf="validity" class="validity-list-icons">
            <svg
              class="validity-list-icon icon-checkmark"
              [ngClass]="{ valid: validity }"
            >
              <use
                xlink:href="assets/defs.svg#icon-checkmark"
                href="assets/defs.svg#icon-checkmark"
              ></use>
            </svg>
          </div>
        </li>
      </ul>

        <div class="order-info-ticket-info">
          <span
            class="ticket-name black"
            *ngIf="ticketNames.length && ticketNames[activeSlideIndex]"
          >
            {{
              ticketNames[activeSlideIndex].ticketGroupName
                | translate
                | hideTranslation
            }}:
            {{ ticketNames[activeSlideIndex].ticketName | translate }}
            <ng-container
              *ngIf="ticketNames[activeSlideIndex]?.day; let day"
            >
              <br />
              {{ day | localizedDate: 'dd. MM. y' }}

              <ng-container
                *ngIf="ticketNames[activeSlideIndex]?.end; let end"
              >
                - {{ end | localizedDate: 'dd.MM. y' }}
              </ng-container>
            </ng-container>
          </span>
          <div class="workshops-list"
            *ngIf="ticketNames.length && ticketNames[activeSlideIndex] && ticketNames[activeSlideIndex].workshopsNames"
          >
            <div
              *ngFor="
                let workshopName of ticketNames[activeSlideIndex]
                  .workshopsNames
              "
              class="text-break"
            >
              {{ workshopName | translate }}
            </div>
          </div>
        </div>

        <div class="center-items">
          <div class="order-info-copy" *ngIf="shouldDisplayCheckbox">
            <div class="copy-data">
              <div class="decorated-checkbox">
                <input
                  type="checkbox"
                  id="autofill"
                  [(ngModel)]="copyAddressChecked"
                  (change)="copyAddress($event.target.checked)"
                />
                <label for="autofill"></label>
              </div>
              <div>
                <label for="autofill">
                  <span data-translation="personalize.order-tickets.copy-form">{{
                    'personalize.order-tickets.copy-form'
                      | translate
                      | hideTranslation
                  }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>

      <div class="order-navigation" *ngIf="ticketHolderInputSets.length > 1">
        <button
          class="previous-button"
          type="button"
          (click)="previousPage()"
          [disabled]="isNextandPreviousButtonDisabled"
        >
          <svg class="icon-arrow-left" role="button">
            <use xlink:href="assets/defs.svg#icon-arrow-left"></use>
          </svg>
          <svg class="icon-user" role="button">
            <use xlink:href="assets/defs.svg#icon-user"></use>
          </svg>
          <span
            class="black carousel-button-text"
            data-translation="personalize.order-tickets.previous"
            >{{
              'personalize.order-tickets.previous' | translate | hideTranslation
            }}</span
          >
        </button>
        <div class="current-person">
          <b>{{ activeSlideIndex + 1}}</b> /
          {{ ticketHolderInputSets.length }}
        </div>
        <button
          class="next-button"
          type="button"
          (click)="nextPage()"
          [disabled]="isNextandPreviousButtonDisabled"
        >
          <span
            class="black carousel-button-text"
            data-translation="personalize.order-tickets.next"
            >{{
              'personalize.order-tickets.next' | translate | hideTranslation
            }}</span
          >
          <svg class="icon-arrow-right" role="button">
            <use xlink:href="assets/defs.svg#icon-arrow-right"></use>
          </svg>
          <svg class="icon-user" role="button">
            <use xlink:href="assets/defs.svg#icon-user"></use>
          </svg>
        </button>
      </div>
      <app-carousel
        id="holder-carousel"
        *ngIf="ticketHolderInputSets?.length"
        [slides]="ticketHolderInputSets"
        [activeSlideIndex]="activeSlideIndex + 1"
      >
        <div
          carousel-slide
          *ngFor="let ticket of ticketHolderInputSets; let index = index"
          [style.width]="100 / ticketHolderInputSets.length + '%'"
          class="carousel-slide"
          [attr.data-slide-index]="index"
        >
          <app-form
            [formPath]="ticket.formInfo"
            (updatedTicketHolderInputs)="updatedTicketHolderInput($event)"
            (handleFormValueChange)="handleFormValueChange($event)"
            *ngIf="activeSlideIndex === index"
          ></app-form>

          <div class="center-items-buyer-visitor">
            <div 
              *ngIf="ticketSelectedSendingOption === 'ticketRetrivalLink' && isVisitorQuestionnaireEnabled"
              class="order-info-buyer-visitor"
              [ngClass]="{ 'buyerVisitorInput': slideWithBuyerVisitor !== null && slideWithBuyerVisitor !== activeSlideIndex }"
            >
              <div class="copy-data">
                <div class="decorated-checkbox">
                  <input                  
                    type="checkbox"
                    id="buyerVisitor"
                    [checked]="isBuyerVisitorChecked"
                    (change)="buyerVisitorPopup()"
                    [disabled]="slideWithBuyerVisitor !== null && slideWithBuyerVisitor !== activeSlideIndex"
                  />
                  <label for="buyerVisitor"></label>
                </div>
                <div>
                  <label for="buyerVisitor">
                    <span data-translation="personalize.order-tickets.order-info-buyer-visitor">{{
                      'personalize.order-tickets.order-info-buyer-visitor'
                        | translate
                        | hideTranslation
                    }}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div
            class="carousel-placeholder"
            *ngIf="activeSlideIndex !== index"
          ></div>
        </div>
      </app-carousel>
    </div>
  </div>
</div>

<app-modal-window
  [modalWindowActive]="showDuplicatesWarning && !showDuplicatedWarningAlreadyClosed"
  [translateTitle]="true"
  [modalWindowTitle]="'order.duplicates-warning.modalWindow.title'"
>
  <button
    class="button button-option"
    modalWindow-close
    (click)="closeModalWindow($event)"
  >
    <span data-translation="order.duplicates-warning.modalWindow.close">{{
      'order.duplicates-warning.modalWindow.close' | translate | hideTranslation
    }}</span>
  </button>
</app-modal-window>

<app-modal-window
  [modalWindowActive]="
    showTicketLimitWarning && !showTicketLimitWarningAlreadyClosed && (!showDuplicatesWarning || showDuplicatedWarningAlreadyClosed)
  "
  [translateTitle]="false"
  [isTitleWithParamTranslation]="true"
>
  <span 
    modalWindow-titleWithParamTranslation 
    [attr.data-translation]="'order.ticket-overlimit-warning.modalWindow.title'"> 
    {{ 'order.ticket-overlimit-warning.modalWindow.title' | translate: { ticketLimitPerEmail: exhibitionSettings.ticketLimitPerEmail } }}
  </span>
  <button
    class="button button-option"
    modalWindow-close
    (click)="closeModalWindow($event, 'ticket-overlimit')"
  >
    <span data-translation="order.ticket-overlimit-warning.modalWindow.close">{{
      'order.ticket-overlimit-warning.modalWindow.close' | translate | hideTranslation
    }}</span>
  </button>
</app-modal-window>

<!-- Modal window with question for buyer is he also a visitor -->
<app-modal-window
  [modalWindowActive]="modalWindowOpen"
  [translateTitle]="true"
  [modalWindowTitle]="'personalize.order-tickets.buyer-visitor-question'"
  >
  <button
    class="button"
    modalWindow-submit
    (click)="buyerVisitorAdditionalData(true)"
  >
    <span data-translation="personalize.order-tickets.buyer-visitor-question-yes">{{
      'personalize.order-tickets.buyer-visitor-question-yes' | translate | hideTranslation
    }}</span>
  </button>
  <button
    class="button button-option"
    modalWindow-close
    (click)="buyerVisitorAdditionalData(false)"
  >
    <span data-translation="personalize.order-tickets.buyer-visitor-question-no">{{
      'personalize.order-tickets.buyer-visitor-question-no' | translate | hideTranslation
    }}</span>
  </button>
</app-modal-window>
