/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./reset-email.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "../../shared/pipes/hide-translation/hide-translation.pipe";
import * as i5 from "./reset-email.component";
import * as i6 from "@angular/router";
import * as i7 from "@ngrx/store";
import * as i8 from "../../shared/services-with-reducers/helpers/helper.service";
var styles_ResetEmailComponent = [i0.styles];
var RenderType_ResetEmailComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ResetEmailComponent, data: {} });
export { RenderType_ResetEmailComponent as RenderType_ResetEmailComponent };
function View_ResetEmailComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "reset-email-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "reset-email-success-body"], ["data-translation", "reset-email.text-confirm"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(4, 1), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "reset-email-button-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "button", [["class", "button"], ["data-translation", "reset-email.confirm"], ["type", "akcept-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeEmail() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(9, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("reset-email.text-confirm")))); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 7, 0, _ck(_v, 9, 0, i1.ɵnov(_v.parent, 0), i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("reset-email.confirm")))); _ck(_v, 7, 0, currVal_1); }); }
function View_ResetEmailComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "reset-email-success-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "reset-email-fail-text"], ["data-translation", "reset-email.text-sending"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(4, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent, 0), i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("reset-email.text-sending")))); _ck(_v, 2, 0, currVal_0); }); }
function View_ResetEmailComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "reset-email-success-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "reset-email-fail-text"], ["data-translation", "reset-email.text-fail"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(4, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent, 0), i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("reset-email.text-fail")))); _ck(_v, 2, 0, currVal_0); }); }
function View_ResetEmailComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "reset-email-success-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "reset-email-success-text"], ["data-translation", "reset-email.text-success"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(4, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent, 0), i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("reset-email.text-success")))); _ck(_v, 2, 0, currVal_0); }); }
function View_ResetEmailComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "reset-email-button-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "button", [["class", "button back-button"], ["data-translation", "reset-email.button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateHome() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(5, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent, 0), i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("reset-email.button")))); _ck(_v, 3, 0, currVal_0); }); }
function View_ResetEmailComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "reset-email-body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResetEmailComponent_3)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResetEmailComponent_4)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResetEmailComponent_5)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResetEmailComponent_6)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.resetLink === null) || (_co.hashStatus === "waiting")); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.resetLink === false); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.resetLink; _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.resetLink || (_co.resetLink === false)); _ck(_v, 8, 0, currVal_3); }, null); }
export function View_ResetEmailComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.HideTranslationPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "reset-email-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "reset-email-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "span", [["data-translation", "reset-email.title"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(6, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResetEmailComponent_1)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResetEmailComponent_2)), i1.ɵdid(10, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (!_co.submitedForm && (_co.hashStatus === "ok")); _ck(_v, 8, 0, currVal_1); var currVal_2 = (_co.submitedForm || (_co.hashStatus !== "ok")); _ck(_v, 10, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 4, 0, _ck(_v, 6, 0, i1.ɵnov(_v, 0), i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("reset-email.title")))); _ck(_v, 4, 0, currVal_0); }); }
export function View_ResetEmailComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-reset-email", [], null, null, null, View_ResetEmailComponent_0, RenderType_ResetEmailComponent)), i1.ɵdid(1, 245760, null, 0, i5.ResetEmailComponent, [i6.ActivatedRoute, i6.Router, i7.Store, i8.HelperService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ResetEmailComponentNgFactory = i1.ɵccf("app-reset-email", i5.ResetEmailComponent, View_ResetEmailComponent_Host_0, {}, {}, []);
export { ResetEmailComponentNgFactory as ResetEmailComponentNgFactory };
