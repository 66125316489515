<div class="verify-email-wrapper">
    <div class="verify-email-header">
        <span data-translation="verify-email.title">{{ 'verify-email.title' | translate | hideTranslation }}</span>
    </div>

    <div *ngIf="hashStatus === 'waiting'" class="verify-email-success-body">
        <div class="reset-email-fail-text" data-translation="verify-email.text-sending">
            {{ 'verify-email.text-sending' | translate | hideTranslation }}
        </div>
    </div>
    
    <div *ngIf="hashStatus !== 'waiting'">
        <div *ngIf="!verifyEmailResponse || (verifyEmailResponse | async) === 200" class="verify-email-success-body">
            <div class="verify-email-success-text" data-translation="verify-email.text-send" [translate]="'verify-email.text-send'"
                [translateParams]="{assignedTickets: assignedTickets, assignedOrders: assignedOrders}">
            </div>
            <button class="button" type="akcept-button" (click)="verifyEmail()" data-translation="verify-email.akcept">
                <span>{{ 'verify-email.akcept' | translate | hideTranslation }}</span>
            </button>
        </div>

        <div *ngIf="!!verifyEmailResponse && (verifyEmailResponse | async) !== 200" class="verify-email-success-body">
            <div *ngIf="(verifyEmailResponse | async) === 408">
                <div class="verify-email-success-text" data-translation="verify-email.text-timeout">
                    {{ 'verify-email.text-timeout' | translate | hideTranslation }}
                </div>
            </div>
            <div *ngIf="(verifyEmailResponse | async) !== 408" class="verify-email-success-text" data-translation="verify-email.text-fail">
                {{ 'verify-email.text-fail' | translate | hideTranslation }}
            </div>
            <button class="button back-button" type="button" (click)="navigateHome()" data-translation="verify-email.back">
                <span>{{ 'verify-email.back' | translate | hideTranslation }}</span>
            </button>
        </div>
    </div>
</div>