<div *ngIf="exhibition">
    <div class="square-img">
        <img class="tile-img" [src]="exhibition.eventLogo" alt="" />
    </div>
    <div class="tile-title">{{ exhibition.eventName }}</div>
    <div class="tile-time">
        {{ exhibition.startDate | localizedDate | async }} -
        {{ exhibition.endDate | localizedDate | async }}
    </div>
</div>

<div *ngIf="exhibitionSerie">
    <div class="square-img">
        <img class="tile-img" [src]="exhibitionSerie.logo" alt="" />
    </div>
    <div class="tile-title">{{ exhibitionSerie.name }}</div>
    <div class="tile-time">
        {{ exhibitionSerie.startDate | localizedDate | async }} -
        {{ exhibitionSerie.endDate | localizedDate | async }}
    </div>
</div>
