import { Pipe, PipeTransform } from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safeResource'
})
export class SafeResourcePipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}

  transform(url) {
    return this.sanitized.bypassSecurityTrustResourceUrl(url);
  }
}
