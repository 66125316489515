var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import * as fromRoot from '../../../app.reducer';
import * as helperActions from '../helpers/helper.actions';
import * as ticketActions from './ticket.actions';
import { select, Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ItemType, } from './ticket.interface';
import { InjectionToken } from '@angular/core';
import { EMPTY, Observable, of, combineLatest } from 'rxjs';
import { catchError, debounceTime, first, map, mergeMap, switchMap } from 'rxjs/operators';
import { CustomizationService } from '../customization/customization.service';
import { HelperService } from '../helpers/helper.service';
import { Location } from '@angular/common';
import { StatusBarService } from '../../../status-bar/status-bar.service';
import { FormsService } from './../../forms/forms.service';
import { TicketsService } from './tickets.service';
import { TranslateService } from '@ngx-translate/core';
import * as customizationActions from '../customization/customization.actions';
export var USER_DEBOUNCE = new InjectionToken('User Debounce');
var TicketEffect = /** @class */ (function () {
    function TicketEffect(actions$, _ticketsService, _formsService, _location, debounce, _customizationService, _store, _helperService, _statusBarService, _translateService) {
        if (debounce === void 0) { debounce = 50; }
        var _this = this;
        this.actions$ = actions$;
        this._ticketsService = _ticketsService;
        this._formsService = _formsService;
        this._location = _location;
        this.debounce = debounce;
        this._customizationService = _customizationService;
        this._store = _store;
        this._helperService = _helperService;
        this._statusBarService = _statusBarService;
        this._translateService = _translateService;
        this.loadTicketTypes$ = this.actions$.pipe(ofType(ticketActions.ActionTypes.GET_TICKETS_TYPES), switchMap(function (data) {
            var eventId = data.payload.eventId;
            if (eventId === '') {
                return Observable.empty();
            }
            return _this._ticketsService
                .getTicketTypes(eventId, data.payload.preferedTicketPersonNr, data.payload.preferedTicketGroupNr)
                .pipe(map(function (sectionGroups) {
                /*  normally we would just:
                  return new userActions.SetTicketsTypesAction(ticketTypes);
    
                  but as we also want to set in redux a flat structure with all tickets without groups,
                  the new structer will be an object with ids of tickets as keys
               */
                var packageIndex = 0;
                var ticketTypes = [];
                var flatTickets = {};
                sectionGroups.forEach(function (sectionGroup, sectionIndex) {
                    var section = {
                        sectionIndex: sectionIndex,
                        sectionId: sectionGroup.groupId,
                        sectionName: sectionGroup.groupName,
                        expanded: sectionGroup.expanded,
                        groupDescription: sectionGroup.groupDescription
                    };
                    sectionGroup.items.forEach(function (product) {
                        var productGroup = {
                            groupId: 0,
                            groupName: null,
                            packageName: null,
                            products: [],
                            preferredTicketType: false,
                            section: section
                        };
                        switch (product.itemType) {
                            case ItemType.Tariff:
                                var ticketType = product.ticketType;
                                var voucherProductGroup_1 = {
                                    groupId: ticketType.ticketTypeId,
                                    groupName: ticketType.ticketTypeName,
                                    packageName: null,
                                    products: [],
                                    preferredTicketType: ticketType.preferredTicketType,
                                    section: __assign({}, section, { sectionIndex: -1 })
                                };
                                productGroup.groupId = ticketType.ticketTypeId;
                                productGroup.groupName = ticketType.ticketTypeName;
                                productGroup.preferredTicketType = ticketType.preferredTicketType;
                                ticketType.tickets.forEach(function (ticket) {
                                    if (!!ticket.validFrom) {
                                        ticket.validFrom = new Date(ticket.validFrom + (!ticket.validFrom.toString().endsWith('Z') ? 'Z' : ''));
                                    }
                                    if (!!ticket.validTill) {
                                        ticket.validTill = new Date(ticket.validTill + (!ticket.validTill.toString().endsWith('Z') ? 'Z' : ''));
                                    }
                                    if (ticket.isVoucher) {
                                        voucherProductGroup_1.products.push({ ticket: ticket, package: null, article: null });
                                    }
                                    else {
                                        productGroup.products.push({ ticket: ticket, package: null, article: null });
                                    }
                                });
                                if (productGroup.products.length) {
                                    ticketTypes.push(productGroup);
                                }
                                if (voucherProductGroup_1.products.length) {
                                    ticketTypes.push(voucherProductGroup_1);
                                }
                                break;
                            case ItemType.Package:
                                var currentPackage = product.package;
                                productGroup.packageName = currentPackage.info;
                                var newPackage = {
                                    packageNumber: currentPackage.packageNumber,
                                    name: currentPackage.name,
                                    tariff: currentPackage.tariff,
                                    variablePrice: currentPackage.variablePrice,
                                    packageTariffSheetNumber: currentPackage.packageTariffSheetNumber,
                                    packageTariffSheetValidNumber: currentPackage.packageTariffSheetValidNumber,
                                    info: currentPackage.info,
                                    contents: [],
                                    count: 0,
                                    total: 0,
                                    isDisabled: false
                                };
                                var packageContent_1 = {
                                    packageIndex: packageIndex,
                                    packageGroups: [],
                                    total: 0
                                };
                                currentPackage.items.forEach(function (item) {
                                    if (item.itemType == ItemType.Tariff) {
                                        var ticketType_1 = item.ticketType;
                                        var packageVoucherProductGroup_1 = {
                                            groupId: item.ticketType.ticketTypeId,
                                            groupName: item.ticketType.ticketTypeName,
                                            packageName: null,
                                            products: [],
                                            preferredTicketType: ticketType_1.preferredTicketType,
                                            section: __assign({}, section, { sectionIndex: -1 })
                                        };
                                        ticketType_1.tickets.forEach(function (ticket) {
                                            ticket.ticketTypeId = ticketType_1.ticketTypeId;
                                            if (!!ticket.validFrom) {
                                                ticket.validFrom = new Date(ticket.validFrom + (!ticket.validFrom.toString().endsWith('Z') ? 'Z' : ''));
                                            }
                                            if (!!ticket.validTill) {
                                                ticket.validTill = new Date(ticket.validTill + (!ticket.validTill.toString().endsWith('Z') ? 'Z' : ''));
                                            }
                                            if (ticket.isVoucher) {
                                                packageVoucherProductGroup_1.products.push({ ticket: ticket, package: null, article: null });
                                            }
                                            else {
                                                var currentPackageGroup = packageContent_1.packageGroups.find(function (group) { return group.ticketTypeId == ticket.ticketTypeId; });
                                                if (!!currentPackageGroup) {
                                                    currentPackageGroup.products.push({ ticket: ticket, package: null, article: null });
                                                }
                                                else {
                                                    var packageGroup = {
                                                        ticketTypeId: ticketType_1.ticketTypeId,
                                                        ticketTypeName: ticketType_1.ticketTypeName,
                                                        products: []
                                                    };
                                                    packageGroup.products.push({ ticket: ticket, package: null, article: null });
                                                    packageContent_1.packageGroups.push(packageGroup);
                                                }
                                            }
                                        });
                                        if (packageVoucherProductGroup_1.products.length) {
                                            ticketTypes.push(packageVoucherProductGroup_1);
                                        }
                                    }
                                });
                                if (packageContent_1.packageGroups.length) {
                                    newPackage.contents.push(packageContent_1);
                                    productGroup.products.push({ ticket: null, package: newPackage, article: null });
                                    ticketTypes.push(productGroup);
                                    packageIndex++;
                                }
                                break;
                        }
                    });
                });
                ticketTypes.forEach(function (productGroup) {
                    productGroup.products.forEach(function (product) {
                        if (product.ticket != null) {
                            var ticket = product.ticket;
                            if (_this._helperService.isSelfregistration()) {
                                ticket.allowedWorkshops = [];
                            }
                            ticket.uniqueId = productGroup.groupId + "_" + ticket.personTypeId;
                            ticket.shouldCalendarBeDisplayed = ticket.hasDaySellLimit && ticket.tageOffset === 31;
                            flatTickets[ticket.uniqueId] = _this.mapTicket(ticket, productGroup.groupName, productGroup.groupId);
                            if (ticket.classification === 'parking') {
                                flatTickets[ticket.uniqueId]['parking'] = [];
                            }
                            if (ticket.hasDaySellLimit) {
                                flatTickets[ticket.uniqueId]['days'] = [];
                            }
                        }
                        else if (product.package != null) {
                            var currentPackage_1 = product.package;
                            if (currentPackage_1.contents.length) {
                                currentPackage_1.contents.forEach(function (packageContent) {
                                    packageContent.packageGroups.forEach(function (packageGroup) {
                                        if (packageGroup.products.length) {
                                            packageGroup.products.forEach(function (packageProduct) {
                                                if (packageProduct.ticket != null) {
                                                    var packageTicket = packageProduct.ticket;
                                                    packageTicket.uniqueId = packageTicket.ticketTypeId + "_" + packageTicket.personTypeId + "_PN" + currentPackage_1.packageNumber + "_PI" + packageContent.packageIndex;
                                                    packageTicket.shouldCalendarBeDisplayed = packageTicket.hasDaySellLimit && packageTicket.tageOffset === 31;
                                                    flatTickets[packageTicket.uniqueId] = _this.mapTicket(packageTicket, packageGroup.ticketTypeName, packageGroup.ticketTypeId);
                                                    flatTickets[packageTicket.uniqueId].packageNumber = currentPackage_1.packageNumber;
                                                    flatTickets[packageTicket.uniqueId].packageIndex = packageContent.packageIndex;
                                                    if (packageTicket.classification === 'parking') {
                                                        flatTickets[packageTicket.uniqueId]['parking'] = [];
                                                    }
                                                    if (packageTicket.hasDaySellLimit) {
                                                        flatTickets[packageTicket.uniqueId]['days'] = [];
                                                    }
                                                }
                                            });
                                        }
                                    });
                                });
                            }
                        }
                    });
                });
                if (!!data.payload.preferedTicketGroupNr) {
                    if (!!data.payload.preferedTicketPersonNr) {
                        var ticketUniqueId = data.payload.preferedTicketGroupNr + "_" + data.payload.preferedTicketPersonNr;
                        if (!!flatTickets[ticketUniqueId] && flatTickets[ticketUniqueId].isVisible) {
                            flatTickets[ticketUniqueId].preferredTicket = true;
                        }
                    }
                    _this._helperService.setIsReloadSections(true);
                }
                _this._store.dispatch(new ticketActions.SetLastPackageIndex(packageIndex - 1));
                _this._store.dispatch(new ticketActions.SetTickets(flatTickets));
                return new ticketActions.SetTicketsTypesAction(ticketTypes);
            }), catchError(function (error) {
                console.log(error);
                return of(new ticketActions.SetTicketsTypesAction([]));
            }));
        }));
        this.postTicketHolderQuestionnaire$ = this.actions$.pipe(ofType(ticketActions.ActionTypes.POST_TICKET_HOLDER_QUESTIONNAIRE), debounceTime(this.debounce), switchMap(function (data) {
            if (!data.payload) {
                return Observable.empty();
            }
            var _a = data.payload, questionnaire = _a.questionnaire, userId = _a.userId, eventId = _a.eventId;
            return _this._ticketsService
                .saveTicketHolderQuestionnaire(questionnaire, userId, eventId)
                .pipe(map(function (code) {
                return new helperActions.SetApiCallResult({
                    code: code,
                    message: 'post ticketholder questionnaire success'
                });
            }), catchError(function () {
                return of(new helperActions.SetApiCallResult({
                    code: 0,
                    message: 'post ticketholder questionnaire fail'
                }));
            }));
        }));
        this.getClaimingTicketHolder$ = this.actions$.pipe(ofType(ticketActions.ActionTypes.LOAD_CLAIMING_TICKET_HOLDER), debounceTime(this.debounce), switchMap(function (data) {
            var hash = data.payload;
            if (!hash) {
                return Observable.empty();
            }
            return _this._ticketsService.getTicketByHolder(hash).pipe(map(function (ticketHolder) {
                // if we load the user
                if (ticketHolder.hasOwnProperty('id')) {
                    _this._store.dispatch(new ticketActions.SetTicketClaimedHashValid(true));
                    return new ticketActions.SetTicketHolder(ticketHolder);
                }
                else {
                    // else if the has is not valid, we need to give some feedback
                    _this._store.dispatch(new ticketActions.SetTicketClaimedHashValid(false));
                    return new ticketActions.SetTicketHolder(null);
                }
            }), catchError(function () {
                _this._store.dispatch(new ticketActions.SetTicketClaimedHashValid(false));
                return of(new ticketActions.SetTicketHolder(null));
            }));
        }));
        this.downloadTicket$ = this.actions$.pipe(ofType(ticketActions.ActionTypes.DOWNLOAD_TICKET), debounceTime(this.debounce), switchMap(function (data) {
            var hash = data.payload;
            if (!hash) {
                return Observable.empty();
            }
            return _this._ticketsService.downloadTicket(hash).pipe(map(function (blob) {
                _this._helperService.saveToFileSystem(blob, 'ticket.pdf');
                // if we load the user
                return new ticketActions.SetTicketHolder(null);
            }), catchError(function () {
                return of(new ticketActions.SetTicketHolder(null));
            }));
        }));
        this.downloadMobileTicket$ = this.actions$.pipe(ofType(ticketActions.ActionTypes.DOWNLOAD_MOBILE_TICKET), debounceTime(this.debounce), switchMap(function (data) {
            var hash = data.payload;
            if (!hash) {
                return Observable.empty();
            }
            return _this._ticketsService.downloadMobileTicket(hash).pipe(map(function (blob) {
                _this._helperService.saveToFileSystem(blob, 'MobileTicket.pdf');
                // if we load the user
                return new ticketActions.SetTicketHolder(null);
            }), catchError(function () {
                return of(new ticketActions.SetTicketHolder(null));
            }));
        }));
        this.downloadPassBook$ = this.actions$.pipe(ofType(ticketActions.ActionTypes.DOWNLOAD_PASS_BOOK), debounceTime(this.debounce), switchMap(function (data) {
            var hash = data.payload;
            if (!hash) {
                return Observable.empty();
            }
            return _this._ticketsService.downloadPassBook(hash).pipe(map(function (blob) {
                _this._helperService.saveToFileSystem(blob, 'Passbook.pkpass');
                // if we load the user
                return new ticketActions.SetTicketHolder(null);
            }), catchError(function () {
                return of(new ticketActions.SetTicketHolder(null));
            }));
        }));
        this.ticketHolderQuestionnaire$ = this.actions$.pipe(ofType(ticketActions.ActionTypes.GET_TICKET_HOLDER_QUESTIONNAIRE), debounceTime(this.debounce), switchMap(function (data) {
            var eventId = data.payload.eventId;
            var ticketPersonIds = data.payload.ticketPersonIds;
            if (eventId === null) {
                return Observable.empty();
            }
            return _this._ticketsService
                .getTicketHolderQuestionnaire(eventId, ticketPersonIds)
                .pipe(map(function (ticketHolderQuestionnaire) {
                var questionnaireInputs = _this._customizationService.tansformQuestionnaireIntoInput(ticketHolderQuestionnaire);
                return new ticketActions.SetTicketHolderQuestionnaire(questionnaireInputs);
            }), catchError(function () {
                return of(new ticketActions.SetTicketHolderQuestionnaire([]));
            }));
        }));
        this.postTicketHolderForm$ = this.actions$.pipe(ofType(ticketActions.ActionTypes.POST_TICKET_HOLDER_FORM), debounceTime(this.debounce), switchMap(function (data) {
            // TODO: fix interface VisitorFormModel
            if (data.payload === null) {
                return Observable.empty();
            }
            return _this._ticketsService.postTicketHolderForm(data.payload).pipe(map(function () {
                return new ticketActions.SetPostTicketHolderFormResult(true);
            }), catchError(function () {
                return of(new ticketActions.SetPostTicketHolderFormResult(false));
            }));
        }));
        this.loadVoucher$ = this.actions$.pipe(ofType(ticketActions.ActionTypes.GET_VOUCHER), debounceTime(this.debounce), switchMap(function (data) {
            var _a = data.payload, redeemVoucher = _a.redeemVoucher, releaseVouchers = _a.releaseVouchers;
            if (!redeemVoucher) {
                return Observable.empty();
            }
            return _this._ticketsService.getVoucherTicket(redeemVoucher).pipe(map(function (voucherTicketRaw) {
                var groupId = voucherTicketRaw.groupId, ticketTypeId = voucherTicketRaw.ticketTypeId, voucherCode = voucherTicketRaw.voucherCode, ticketCount = voucherTicketRaw.ticketCount;
                //Create ticketUniqueId for voucher ticket, and set count to the ticket count returned from the API redeem-voucher
                voucherTicketRaw.ticketUniqueId = groupId + "_" + ticketTypeId + "_" + voucherCode;
                voucherTicketRaw.count = ticketCount;
                //Add voucher to ticket booking
                _this._store.dispatch(new ticketActions.AddTicketBooking(voucherTicketRaw));
                releaseVouchers.forEach(function (releaseVoucher) {
                    _this._store.dispatch(new ticketActions.ReleaseVoucher(__assign({}, releaseVoucher, { skipAnonymousChecks: true })));
                });
                /* REMOVE FOLLOWING ONCE CORRECT DATA ARE COMMING FROM API */
                if (voucherTicketRaw.hasOwnProperty('afterSale')) {
                    voucherTicketRaw.price = voucherTicketRaw.afterSale;
                }
                delete voucherTicketRaw.sortNr;
                delete voucherTicketRaw.voucherMaxUsings;
                delete voucherTicketRaw.voucherMaxUsedNumbers;
                delete voucherTicketRaw.ticketToOwner;
                delete voucherTicketRaw.asMobileTicket;
                delete voucherTicketRaw.ticketHolder;
                delete voucherTicketRaw.ticketSendingMode;
                delete voucherTicketRaw.bocUId;
                delete voucherTicketRaw.unicodeNr;
                delete voucherTicketRaw.posNr;
                delete voucherTicketRaw.serialNr;
                delete voucherTicketRaw.validFrom;
                delete voucherTicketRaw.validTill;
                /* END: REMOVE FOLLOWING ONCE CORRECT DATA ARE COMMING FROM API */
                var voucherTicket = voucherTicketRaw;
                if (voucherTicket.hasOwnProperty('allowedWorkshopsFull') &&
                    voucherTicket.allowedWorkshopsFull) {
                    _this._translateService
                        .get('voucher.workshops.soldout')
                        .subscribe(function (translation) {
                        _this._statusBarService.setStatus(translation, 'error');
                    });
                    return new ticketActions.AddVoucherTicket(null);
                }
                // transform JSON result to object with keys as ticketGroup Ids and values as ticketSales
                // set timestamp when the voucher was activated
                voucherTicket.activatedTimestamp = Date.now();
                // set unique ticketId
                voucherTicket.uniqueId = groupId + "_" + ticketTypeId;
                _this._store.dispatch(new customizationActions.SetShoppingStartTime());
                return new ticketActions.AddVoucherTicket({
                    voucherTicket: voucherTicket,
                    selfRegistration: _this._helperService.isSelfregistration()
                });
            }), catchError(function (error) {
                console.log(error);
                return of(new ticketActions.AddVoucherTicket(null));
            }));
        }));
        this.releaseVoucher$ = this.actions$.pipe(ofType(ticketActions.ActionTypes.RELEASE_VOUCHER), mergeMap(function (data) {
            var voucherRedeemData = data.payload;
            if (!voucherRedeemData) {
                return Observable.empty();
            }
            return _this._ticketsService.releaseVoucherCode(voucherRedeemData).pipe(map(function (success) {
                // transform JSON result to object with keys as ticketGroup Ids and values as ticketSales
                return new ticketActions.RemoveVoucher({ voucherCode: voucherRedeemData.voucherCode, skipAnonymousChecks: voucherRedeemData.skipAnonymousChecks });
            }), catchError(function (error) {
                console.log(error);
                return of(new ticketActions.RemoveVoucher(null));
            }));
        }));
        this.postTicketBooking$ = this.actions$.pipe(ofType(ticketActions.ActionTypes.POST_TICKET_BOOKING), mergeMap(function (data) {
            if (!data.payload) {
                return Observable.empty();
            }
            return _this._ticketsService
                .postTicketBooking(data.payload.postTicketBooking)
                .map(function (bookings) {
                bookings.count = data.payload.postTicketBooking.count - data.payload.postTicketBooking.sharedLimitTicketCount;
                bookings.ticketUniqueId = data.payload.postTicketBooking.uniqueId;
                bookings.ticketLimit = data.payload.ticketLimit;
                // if availableTickets is not set = null we never set ticket to be sold out
                if (bookings.availableTickets === null) {
                    bookings.isTicketSold = false;
                }
                else {
                    bookings.isTicketSold = bookings.availableTickets ? false : true;
                }
                data.payload.successCallback();
                return new ticketActions.AddTicketBooking(bookings);
            })
                .catch(function (error) {
                console.log(error);
                var ticketAlreadySold = {
                    groupId: data.payload.postTicketBooking.groupId,
                    ticketTypeId: data.payload.postTicketBooking.ticketTypeId,
                    ticketPersonId: data.payload.postTicketBooking.ticketPersonId,
                    ticketUniqueId: data.payload.postTicketBooking.uniqueId,
                    availableTickets: 0,
                    count: 0,
                    isTicketSold: true,
                    ticketLimit: data.payload.ticketLimit
                };
                return of(new ticketActions.AddTicketBooking(ticketAlreadySold));
            });
        }));
        this.setTicketIndex$ = this.actions$.pipe(ofType(ticketActions.ActionTypes.ADD_TICKET_HOLDER_INDEXES, ticketActions.ActionTypes.SET_TICKET_HOLDER_INDEX), first(), switchMap(function () {
            combineLatest([
                _this._store.pipe(select(fromRoot.getTicketHolderIndexes)),
                _this._store.pipe(select(fromRoot.getTickets))
            ])
                .filter(function (_a) {
                var ticketHolderIndexes = _a[0], tickets = _a[1];
                if (ticketHolderIndexes == null) {
                    return false;
                }
                for (var ticketIndex in ticketHolderIndexes) {
                    if (ticketHolderIndexes[ticketIndex] == '') {
                        return false;
                    }
                }
                var _loop_1 = function (uniqueId) {
                    var numberOfIndexes = Object.keys(ticketHolderIndexes).filter(function (index) { return ticketHolderIndexes[index] === uniqueId; }).length;
                    if (numberOfIndexes == 0) {
                        return "continue";
                    }
                    if (tickets[uniqueId].count != numberOfIndexes) {
                        return { value: false };
                    }
                };
                for (var uniqueId in tickets) {
                    var state_1 = _loop_1(uniqueId);
                    if (typeof state_1 === "object")
                        return state_1.value;
                }
                return true;
            })
                .debounceTime(_this.debounce)
                .subscribe(function (_a) {
                var ticketHolderIndexes = _a[0], tickets = _a[1];
                _this._store.pipe(select(fromRoot.getTicketHolderInputSets))
                    .subscribe(function (ticketHolderInputSets) {
                    var newFlatTickets = __assign({}, tickets);
                    var ticketUniqueIds = Object.keys(newFlatTickets);
                    var _loop_2 = function (i) {
                        var uniqueId = ticketUniqueIds[i];
                        newFlatTickets[uniqueId].holdersIndexes = Object.keys(ticketHolderIndexes)
                            .filter(function (index) { return ticketHolderIndexes[index] === uniqueId; })
                            .map(function (index) { return +index; });
                        var _loop_3 = function (ticketIndex) {
                            setTimeout(function () {
                                if (!ticketHolderInputSets) {
                                    _this._ticketsService.addTicketHolder(ticketIndex);
                                }
                                else {
                                    var hasTicketInputSet = ticketHolderInputSets.some(function (ticketHolderInputSet) { return ticketHolderInputSet.index == ticketIndex; });
                                    if (!hasTicketInputSet) {
                                        _this._ticketsService.addTicketHolder(ticketIndex);
                                    }
                                }
                            }, 0);
                        };
                        for (var _i = 0, _a = newFlatTickets[uniqueId].holdersIndexes; _i < _a.length; _i++) {
                            var ticketIndex = _a[_i];
                            _loop_3(ticketIndex);
                        }
                        i++;
                        out_i_1 = i;
                    };
                    var out_i_1;
                    for (var i = 0; i < ticketUniqueIds.length;) {
                        _loop_2(i);
                        i = out_i_1;
                    }
                    _this._formsService.setFormValidity(true, null, ['tickets', 'packageLoading']);
                    _this._store.dispatch(new ticketActions.SetIsPackageLoading(false));
                    return of(new ticketActions.SetTickets(newFlatTickets));
                })
                    .unsubscribe();
            });
            return EMPTY;
        }));
        this.removeTicketIndex$ = this.actions$.pipe(ofType(ticketActions.ActionTypes.REMOVE_TICKET_HOLDER_INDEX), first(), switchMap(function () {
            _this._store.pipe(select(fromRoot.getTicketHolderIndexes))
                .debounceTime(_this.debounce)
                .subscribe(function () {
                _this._store.dispatch(new ticketActions.SetIsPackageLoading(false));
            });
            return EMPTY;
        }));
    }
    TicketEffect.prototype.mapTicket = function (ticket, groupName, groupId) {
        return {
            id: ticket.personTypeId,
            uniqueId: ticket.uniqueId,
            holdersIndexes: [],
            name: ticket.ticketName,
            groupName: groupName,
            groupId: groupId,
            count: 0,
            price: ticket.price,
            requiresLegitimation: ticket.requiresLegitimation,
            allowedWorkshops: ticket.allowedWorkshops,
            allowedWorkshopsFull: ticket.allowedWorkshopsFull,
            ticketTypeOrder: ticket.ticketTypeOrder,
            availableTickets: ticket.availableTickets,
            ticketLimit: ticket.ticketLimit,
            info: ticket.info,
            infoExpanded: ticket.infoExpanded,
            classification: ticket.classification,
            ticketPersonId: ticket.ticketPersonId,
            isVisible: ticket.isVisible,
            isVoucher: ticket.isVoucher,
            workshopsByDay: ticket.workshopsByDay,
            hasDaySellLimit: ticket.hasDaySellLimit,
            durationInDays: ticket.durationInDays,
            personalizationType: ticket.personalizationType,
            validFrom: ticket.validFrom,
            validTill: ticket.validTill,
            shouldCalendarBeDisplayed: ticket.shouldCalendarBeDisplayed,
            tageOffset: ticket.tageOffset,
            packageSettings: ticket.packageSettings,
        };
    };
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TicketEffect.prototype, "loadTicketTypes$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TicketEffect.prototype, "postTicketHolderQuestionnaire$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TicketEffect.prototype, "getClaimingTicketHolder$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TicketEffect.prototype, "downloadTicket$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TicketEffect.prototype, "downloadMobileTicket$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TicketEffect.prototype, "downloadPassBook$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TicketEffect.prototype, "ticketHolderQuestionnaire$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TicketEffect.prototype, "postTicketHolderForm$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TicketEffect.prototype, "loadVoucher$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TicketEffect.prototype, "releaseVoucher$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TicketEffect.prototype, "postTicketBooking$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TicketEffect.prototype, "setTicketIndex$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TicketEffect.prototype, "removeTicketIndex$", void 0);
    return TicketEffect;
}());
export { TicketEffect };
