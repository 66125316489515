import {
  combineLatest as observableCombineLatest,
  Observable,
  Subscription
} from 'rxjs';
import * as fromRoot from '../../../app.reducer';

import { Component, Input, OnInit, OnDestroy } from '@angular/core';

import { CustomizationService } from '../../../shared/services-with-reducers/customization/customization.service';
import { FormGroup } from '@angular/forms';
import { FormsService } from '../../../shared/forms/forms.service';
import { InputBase } from '../../../shared/forms/inputs/input-base.class';
import { InputsListModel } from '../../../shared/services-with-reducers/step-forms/step.interface';
import { Store } from '@ngrx/store';
import { HelperService } from '../../../shared/services-with-reducers/helpers/helper.service';

@Component({
  moduleId: module.id,
  selector: 'app-extra-data',
  templateUrl: './extra-data.component.html',
  styleUrls: ['./extra-data.component.scss']
})
export class ExtraDataComponent implements OnInit, OnDestroy {
  public questionare$: Observable<InputsListModel>;
  public inputs: InputBase<any>[];
  public form: FormGroup;
  public subscriptions = new Subscription();
  @Input()
  stepsFormsActionName: Array<string>;

  constructor(
    private _store: Store<fromRoot.State>,
    private _customizationService: CustomizationService,
    private _formsService: FormsService,
    private _helperService: HelperService
  ) {}

  ngOnInit() {
    if (this._helperService.isSelfregistration()) {
      this.questionare$ = this._store.select(fromRoot.getSelfRegQuestionnaire);
    } else {
      this.questionare$ = this._store.select(fromRoot.getQuestionnaire);
    }

    this.subscriptions.add(
      observableCombineLatest(
        this.questionare$,
        this._store.select(fromRoot.getSelectedExhibitionId),
        this._store.select(fromRoot.getLanguage)
      ).subscribe((data: [InputsListModel, number, string]) => {
        const [questionnaires, eventId, lang] = data;
        if (
          questionnaires &&
          questionnaires.list &&
          !(Number(eventId) === 90 && lang === 'en')
        ) {
          questionnaires.list.map(list => {
            if (list.previousValueId) {
              let element = questionnaires.list.find(
                p =>
                  p.value == list.previousValueId ||
                  (p.controlType === 'checkbox' &&
                    p.options.find(
                      (option: { value: boolean; key: number }) =>
                        option.value === true &&
                        option.key === list.previousValueId
                    ))
              );

              list.hidden = !element;
            }

            list.options = list.options.sort((a, b) => {
              return a.order - b.order;
            });
          });

          if (questionnaires.rerender || !this.form || !this.inputs) {
            this.form = this._formsService.toFormGroup(
              questionnaires.list,
              this.stepsFormsActionName
            );
          }

          this.inputs = this._formsService.updateInputs(
            this.inputs,
            questionnaires.list
          );
        } else {
          this.inputs = null;
          this._formsService.setStepValid(this.stepsFormsActionName);
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
