import { SignalrService } from './../signalr/signalr.service';
import { combineLatest as observableCombineLatest, Subject } from 'rxjs';

import {
  OperatorsSettingsModel,
  ExhibitionSettingModel
} from '../services-with-reducers/customization/customization.interfaces';

import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';

import { CustomizationService } from '../services-with-reducers/customization/customization.service';
import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { hubMethods } from '../signalr/signalr-signals';
import { HelperService } from '../../shared/services-with-reducers/helpers/helper.service';
import { HomeComponent } from '../../_pages/home/home.component';
import { first, takeUntil } from 'rxjs/operators';
import { AppConstants } from '../app-constants';
import { getAppVersion } from '../app-utils';

@Injectable()
export class MaintenancePageGuard implements CanActivate, OnDestroy {
  eventId: string;

  private _name = this.constructor.name;
  private _canActivateSubject$ = new Subject<boolean>();
  private _reloadRequiredSubject$ = new Subject<boolean>();
  private _unsubscribe = new Subject<void>();

  constructor(
    private _ngZone: NgZone,
    private _router: Router,
    private _customizationService: CustomizationService,
    private _signalrService: SignalrService,
    private _helperService: HelperService
  ) {
    // listen to maintenace change from signalr
    this.handleOperatorMaintenace();
    this.handleEventMaintenace();
    this.handleReloadRequired();

    observableCombineLatest([
      this._canActivateSubject$,
      this._reloadRequiredSubject$
    ])
    .pipe(
      takeUntil(this._unsubscribe)
    )
    .subscribe(([canActivate, reloadRequired]) => {
      if (!canActivate) {
        this.toMaintenacePage();
      }

      if (canActivate && reloadRequired) {
        this._helperService.setReloadRequired(true);
      }
    });
  }

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const canActivatePromise = new Promise<boolean>(resolve => {
      let eventIdFromUrl = state.url.split('/')[2];

      if (eventIdFromUrl) {
        this.eventId = eventIdFromUrl;
      } else {
        this.eventId = null;
      }

      // get maintenace data from api
      this.handleAlreadyRunningMaintenace();

      observableCombineLatest([
        this._canActivateSubject$,
        this._reloadRequiredSubject$
      ])
      .pipe(
        first()
      )
      .subscribe(([canActivate, reloadRequired]) => {
        if (canActivate && reloadRequired) {
          canActivate = route.component === HomeComponent;
        }

        resolve(canActivate);
      });
    });

    return canActivatePromise;
  }

  handleOperatorMaintenace() {
    this._signalrService.registerHandler(
      this._name,
      hubMethods.maintenaceOperatorStarted,
      () => {
        this._canActivateSubject$.next(false);
      }
    );
  }

  handleEventMaintenace() {
    this._signalrService.registerHandler(
      this._name,
      hubMethods.maintenaceEventStarted,
      maintenaceData => {
        if (this.eventId == maintenaceData) {
          this._canActivateSubject$.next(false);
        }
      }
    );
  }

  handleReloadRequired() {
    this._signalrService.registerHandler(
      this._name,
      hubMethods.reloadRequired,
      () => {
        this._reloadRequiredSubject$.next(true);
      }
    );
  }

  handleAlreadyRunningMaintenace() {
    return observableCombineLatest(
      this._customizationService.getOperatorSettings(),
      this._customizationService.getCustomSettings(this.eventId)
    ).subscribe((data: [OperatorsSettingsModel, ExhibitionSettingModel]) => {
      const [operatorSettings, eventSettings] = data;

      //in case there are no operator settings or event settings we show maintenence page
      if (operatorSettings.maintenance.isPresent || eventSettings.maintenance.isPresent) {
        this._canActivateSubject$.next(false);
      } else {
        this._canActivateSubject$.next(true);
      }

      //a page reload is required if:
      //-there's a new version of the application (this is checked here),
      //-or the ReloadRequired message is received via the SignalR communication.
      const version = getAppVersion();
      const oldAppVersion = localStorage.getItem(AppConstants.appVersionReducer);
  
      if (!(oldAppVersion && oldAppVersion === version)) {
        this._reloadRequiredSubject$.next(true);
      } else {
        this._reloadRequiredSubject$.next(false);
      }
    });
  }

  toMaintenacePage() {
    return this._ngZone.run(() => this._router.navigate(['/maintenance']));
  }
}
