import { FormGroup, ValidationErrors } from '@angular/forms';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

import { combineLatest as observableCombineLatest } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs/Rx';
import { Subject } from 'rxjs/Subject';
import { Store, ActionsSubject, select } from '@ngrx/store';

import { FormsService } from '../shared/forms/forms.service';
import { HelperService } from '../shared/services-with-reducers/helpers/helper.service';
import { ofType } from '@ngrx/effects';
import {
  getNewAccountForm,
  getProfileEditForm
} from './form-inputs.edit-account';
import { InputBase } from '../shared/forms/inputs/input-base.class';
import { FormInputsPayloadModel } from '../shared/services-with-reducers/step-forms/step.interface';
import {
  UserProfileModel,
  UserProfileUpdateModel
} from '../shared/services-with-reducers/user/user.interface';
import {
  ExhibitionSettingModel,
  OperatorsSettingsModel,
  QuestionnaireDataInput
} from '../shared/services-with-reducers/customization/customization.interfaces';
import * as fromRoot from '../app.reducer';
import * as stepsActions from '../shared/services-with-reducers/step-forms/steps-forms.actions';
import * as helperActions from '../shared/services-with-reducers/helpers/helper.actions';
import * as userActions from '../shared/services-with-reducers/user/user.actions';
import { RegistrationQuestionnaireComponent } from './registration-questionnaire/registration-questionnaire.component';
import { SelectOption } from '../shared/services-with-reducers/exhibition/exhibition.interface';

@Component({
  moduleId: module.id,
  selector: 'app-edit-account-form',
  templateUrl: './edit-account-form.component.html',
  styleUrls: ['./edit-account-form.component.scss']
})
export class EditAccountFormComponent implements OnInit, OnDestroy {
  @ViewChild(RegistrationQuestionnaireComponent)
  registrationQuestionnaireComponent: RegistrationQuestionnaireComponent;
  @Input()
  formType: 'new-account' | 'edit-account';
  @Input()
  validationStepName: Array<string>;
  @Input() 
  userUrlFormData: object;
  @Output()
  isProfileUpdated = new EventEmitter<Boolean>();
  buyerInfo: any;
  public form: FormGroup;
  public inputs: InputBase<any>[];
  public isEditEnabled = false;
  public $settings: Observable<ExhibitionSettingModel>;
  public validRegistrationQuestionnaire = true; // set questionare validity to true.. it is updated once added to page
  public editAccountAction = ['editAccountForm'];
  public isContinueAsGuest: boolean;
  public hideFormInputs: boolean;
  public processingSubmit: boolean = false;
  public listOfAllCountries: Array<any>;
  public operatorsSettings: OperatorsSettingsModel;
  public feedbackMessages = [];

  private userProfile;
  private questionnaireDataToSave: Object = null;
  private unsubscribe = new Subject<void>();
  private buyerInfoActionName = ['personal', 'buyerinfo'];
  public isSpinnerActive$: Observable<boolean>;

  constructor(
    private _store: Store<fromRoot.State>,
    private _helperService: HelperService,
    private _formsService: FormsService,
    private _actionSubject: ActionsSubject
  ) {
    this._store.dispatch(new helperActions.SetSpinnerValue(false));
  }

  public formSaveCallback = (inputs: InputBase<any>[], rerender: boolean) => {
    const newInProfile = {};

    inputs.forEach(input => {
      newInProfile[input.key] = input.value;

      if (input.controlType === 'checkbox') {
        input.options.forEach(option => {
          newInProfile[option.key] = option.value;
        });
      }
    });

    //TODO: REMOVE????
    if (this.formType === 'edit-account') {
      const mergedProfile: UserProfileModel = Object.assign(
        {},
        this.userProfile,
        newInProfile
      );
    }

    if (this.formType === 'new-account' && rerender) {
      this.generateNewAccountForm(newInProfile, this.listOfAllCountries);
    }

    this.inputs = this._formsService.updateInputs(this.inputs, inputs);

    //because of google autocomplete we need to rebuild the form with autocomplete results
    if (rerender) {
      this.form = this._formsService.toFormGroup(this.inputs);
      this.calculateFeedbackMessages();
    }
  };

  ngOnInit() {
    this.$settings = this._store.select(fromRoot.getExhibitionSettings);

    if (this.formType === 'edit-account') {
      // when new data for profile from API is loaded process it
      observableCombineLatest([
        this._store.pipe(select(fromRoot.isProfileLoading)),
        this._store.pipe(select(fromRoot.getProfile))
      ])
        .pipe(
          filter(([isProfileLoading, profile]) => isProfileLoading === false && !!profile),
          first()
        )
        .subscribe(() => {
          this.getProfile();
        });
    }

    this._store
      .select(fromRoot.isContinueAsGuest)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(isContinueAsGuest => {
        this.isContinueAsGuest = isContinueAsGuest;

        // only hide inputs on personalize page
        if (
          this.validationStepName &&
          this.validationStepName[0] === 'personal' &&
          this.validationStepName[1] === 'registration'
        ) {
          this.hideFormInputs = isContinueAsGuest;

          if (this.inputs) {
            this.inputs = [
              ...this.enablePasswordInForm(!isContinueAsGuest, this.inputs)
            ];
          }
        } else if (
          this.validationStepName &&
          this.validationStepName[0] === 'tickets' &&
          this.validationStepName[1] === 'registration'
        ) {
          if (this.inputs) {
            this.inputs = [
              ...this.enablePasswordInForm(!isContinueAsGuest, this.inputs)
            ];
          }
        }

        this.form = this._formsService.toFormGroup(this.inputs);
        this.calculateFeedbackMessages();
      });

    observableCombineLatest(
      this._store.select(fromRoot.getAllCountriesList),
      this._store.select(fromRoot.getBuyerInfoFromURL)
    )
      .pipe(
        filter(data => {
          return !!data[0];
        }),
        first()
      )
      .takeUntil(this.unsubscribe)
      .subscribe(data => {
        const [allCountriesList, buyerInfoFromURL] = data;

        this.listOfAllCountries = allCountriesList;

        let prefilledValues = {};
        if (buyerInfoFromURL) {
          Object.keys(buyerInfoFromURL).forEach(parameter => {
            prefilledValues[buyerInfoFromURL[parameter].key] =
              buyerInfoFromURL[parameter].value;
          });
        }

        if (this.userUrlFormData && (!buyerInfoFromURL || Object.keys(buyerInfoFromURL).length < 1)) {
          Object.keys(this.userUrlFormData).forEach(key => {
            prefilledValues[key] = this.userUrlFormData[key];
          });
        }
        
        if (this.formType === 'new-account') {
          this.isEditEnabled = true;
          this.generateNewAccountForm(prefilledValues, allCountriesList);
        }
      });

    this._actionSubject
      .pipe(ofType(userActions.ActionTypes.POST_PROFILE))
      .subscribe((data: any) => {
        setTimeout(() => {
          this.processingSubmit = false;
        }, 3000);
      });

    this._store.pipe(select(fromRoot.getLanguage)).subscribe(() => {
      if (!!this.inputs) {
        this.form = this._formsService.toFormGroup(this.inputs);
      }
    });

    if (this.form){
      this.feedbackMessages = [];
      this.setAccountFormFeedbackMessages();
      this.setQuestionnaireFeedbackMessages();
  
      if (this.feedbackMessages.length === 0) {
        this.validRegistrationQuestionnaire = true;
      }

      this.calculateFeedbackMessages();
    }

    this.isSpinnerActive$ = this._store.pipe(select(fromRoot.getSpinnerValue));
  }

  isButtonDisabled() {
    return (
      this.processingSubmit ||
      !this.form.valid ||
      !this.validRegistrationQuestionnaire
    );
  }

  calculateFeedbackMessages(questionnaireChanged: boolean = false) {
    if (!this.form) return;

    if (questionnaireChanged) {
      this.feedbackMessages = [];
      this.setAccountFormFeedbackMessages();
      this.setQuestionnaireFeedbackMessages();

      if (this.feedbackMessages.length === 0) {
        this.validRegistrationQuestionnaire = true;
      }
    } else {
      this.form.valueChanges.subscribe(() => {
        this.feedbackMessages = [];
        this.setAccountFormFeedbackMessages();
        this.setQuestionnaireFeedbackMessages();

        if (this.feedbackMessages.length === 0) {
          this.validRegistrationQuestionnaire = true;
        }
      });
    }
  }

  setAccountFormFeedbackMessages() {
    Object.keys(this.form.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.form.get(key).errors;
      if (controlErrors != null) {
        let control = this.inputs.filter(p => p.key === key)[0];
        Object.keys(controlErrors).forEach(keyError => {
          this.feedbackMessages.push({
            key: key,
            label: control.label,
            translate: control.translate
          });
        });
      }
    });
  }

  setQuestionnaireFeedbackMessages() {
    if (
      !this.registrationQuestionnaireComponent ||
      this.validRegistrationQuestionnaire
    ) {
      return;
    }

    let questionnaireForm = this.registrationQuestionnaireComponent.form;

    Object.keys(questionnaireForm.controls).forEach(key => {
      const controlErrors: ValidationErrors = questionnaireForm.get(key).errors;
      if (controlErrors != null) {
        let control = this.registrationQuestionnaireComponent.inputs.filter(
          p => p.key === key
        )[0];

        if (control.hidden) {
          return;
        }

        Object.keys(controlErrors).forEach(keyError => {
          this.feedbackMessages.push({
            key: key,
            label: control.label,
            translate: control.translate
          });
        });
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  enableOrDisableInputs() {
    const disabled = !this.isEditEnabled;

    this.inputs.forEach((input: InputBase<any>) => {
      if (input.controlType === 'dateInput'){
        setTimeout(() => {
          input.disabled = disabled;
        }, 0);
      } else {
        input.disabled = disabled;
      }
    });
  }

  getProfile() {
    observableCombineLatest([
      this._store.pipe(select(fromRoot.getExhibitionSettings)),
      this._store.pipe(select(fromRoot.getProfile)),
      this._store.pipe(select(fromRoot.getOperatorsSettings)),
      this._store.pipe(select(fromRoot.getAllCountriesList)),
      this._store.pipe(select(fromRoot.getTitles)),
      this._store.pipe(select(fromRoot.getAllTitles)),
      this._store.pipe(select(fromRoot.getProfessions)),
      this._store.pipe(select(fromRoot.getAllProfessions)),
      this._store.pipe(select(fromRoot.getDepartments)),
      this._store.pipe(select(fromRoot.getAllDepartments)),
      this._store.pipe(select(fromRoot.getOccupationalGroups)),
      this._store.pipe(select(fromRoot.getAllOccupationalGroups))
    ])
      .pipe(
        filter((data: [
          ExhibitionSettingModel, 
          UserProfileModel, 
          OperatorsSettingsModel,
          string[],
          SelectOption[], 
          QuestionnaireDataInput[],
          SelectOption[], 
          QuestionnaireDataInput[],
          SelectOption[], 
          QuestionnaireDataInput[],
          SelectOption[], 
          QuestionnaireDataInput[]
        ]) => {
          const [
            exhibitionSettings,
            profile,
            operatorSettings,
            allCountries,
            titles,
            allTitles,
            professions,
            allProfessions,
            departments,
            allDepartments,
            occupationalGroups,
            allOccupationalGroups
          ] = data;

          return !!profile 
            && !!operatorSettings 
            && !!allCountries 
            && !!allTitles 
            && !!allProfessions 
            && !!allDepartments
            && !!allOccupationalGroups ;
        }),
        takeUntil(this.unsubscribe)
      )
      .subscribe(([
          settings,
          profile,
          operatorsSettings,
          allCountries,
          titles,
          allTitles,
          professions,
          allProfessions,
          departments,
          allDepartments,
          occupationalGroups,
          allOccupationalGroups
        ]) => {

        this.userProfile = profile;

        const inputTitles = titles ? titles : [];
        const inputProfessions = professions ? professions : [];
        const inputDepartments = departments ? departments : [];
        const inputOccupationalGroups = occupationalGroups ? occupationalGroups : [];

        if (profile && operatorsSettings) {
          let profileEditForm: InputBase<any>[] = [];

          let isGoogleAutocompleteEnabled = false;

          //workaround for bug #2744 ""Find address" isn't visible in your profile despite being set up in admin":
          //in case we're editing the profile from the operator level we wouldn't get the event level settings and also the isGoogleAutocompleteEnabled property
          //but that property is fixed, permanently set to true in web API code so I think it should be fine if we interpret it as true on the operator level by default.
          //isGoogleAutocompleteEnabled property only influences the visibility of the SearchAddress field which has its own isVisible property defined in operator level settings anyway.
          if (settings === null || (settings.isGoogleAutocompleteEnabled)) {
            isGoogleAutocompleteEnabled = true;
          }

          profileEditForm = getProfileEditForm(
            settings,
            operatorsSettings,
            isGoogleAutocompleteEnabled,
            inputTitles,
            inputProfessions,
            inputDepartments,
            inputOccupationalGroups
          );

          const updatedProfileEditForm = profileEditForm.map(profileItem => {
            let updatedItem;

            if (
              profile.hasOwnProperty(profileItem.key) &&
              (profile[profileItem.key] || profile[profileItem.key] === 0)
            ) {
              updatedItem = {
                ...profileItem,
                value: this._formsService.getFunctionTextValue(
                  profile,
                  profileItem,
                  inputTitles,
                  allTitles,
                  inputProfessions,
                  allProfessions,
                  inputDepartments,
                  allDepartments,
                  inputOccupationalGroups,
                  allOccupationalGroups
                )
              };

              this._formsService.resetInvalidFunctionValue(
                updatedItem,
                inputTitles,
                inputProfessions,
                inputDepartments,
                inputOccupationalGroups,
                (input: InputBase<any>) => {
                  updatedItem = {
                    ...input,
                    value: null
                  };
                }
              );
            } else {
              updatedItem = {
                ...profileItem,
                value: profileItem.key === 'newsletterChecked' ? 'false' : ''
              };
            }

            if (profileItem.controlType === 'checkbox') {
              updatedItem.options.forEach(option => {
                if (profile.hasOwnProperty(option.key)) {
                  option.value = profile[option.key];
                }
              });
            }

            return updatedItem;
          });

          this.inputs = this._formsService.updateInputs(
            this.inputs,
            updatedProfileEditForm
          );

          this.addCountriesToDropdown(this.inputs, allCountries);

          this.form = this._formsService.toFormGroup(updatedProfileEditForm);
          this.calculateFeedbackMessages();

          /* if profile is not valid on load, make it editable straight away */
          const validationCallback = () => {
            if (!!this.form && !this.form.valid) {
              this.isEditEnabled = true;

              Object.keys(this.form.controls).forEach(key => {
                this.form.controls[key].markAsTouched();
              });

              this.enableOrDisableInputs();
            }
          };
          
          this._helperService.triggerCallbackOnceFormValidationIsDone(
            this.form,
            validationCallback,
            true
          );
          /* END: if profile is not valid on load, make it editable straight away */

          this.enableOrDisableInputs();
        }
      });
  }

  generateNewAccountForm(prefilledValues, listOfAllCountries) {
    observableCombineLatest([
      this._store.pipe(select(fromRoot.getExhibitionSettings)),
      this._store.pipe(select(fromRoot.getOperatorsSettings)),
      this._store.pipe(select(fromRoot.isContinueAsGuest)),
      this._store.pipe(select(fromRoot.getTitles)),
      this._store.pipe(select(fromRoot.getProfessions)),
      this._store.pipe(select(fromRoot.getDepartments)),
      this._store.pipe(select(fromRoot.getOccupationalGroups))
    ])
      .pipe(
        filter((data: [
          ExhibitionSettingModel, 
          OperatorsSettingsModel,
          boolean,
          SelectOption[],
          SelectOption[],
          SelectOption[],
          SelectOption[]
        ]) => !!data),
        takeUntil(this.unsubscribe)
      )
      .subscribe(([
        settings,
        operatorsSettings,
        isContinueAsGuest,
        titles, 
        professions, 
        departments, 
        occupationalGroups
      ]) => {
        const newAccountForm = getNewAccountForm(
          settings,
          operatorsSettings,
          titles,
          professions,
          departments,
          occupationalGroups
        );

        const updatedNewAccountForm = newAccountForm.map(profileItem => {
          let updatedItem;
          if (
            prefilledValues.hasOwnProperty(profileItem.key) &&
            (prefilledValues[profileItem.key] ||
              prefilledValues[profileItem.key] === 0)
          ) {
            updatedItem = {
              ...profileItem,
              value: prefilledValues[profileItem.key]
            };
          } else {
            updatedItem = { ...profileItem, value: '' };
          }

          if (profileItem.controlType === 'checkbox') {
            updatedItem.options.forEach(option => {
              if (prefilledValues.hasOwnProperty(option.key)) {
                option.value = prefilledValues[option.key];
              }
            });
          }

          return updatedItem;
        });

        const updatedInputs = this.enablePasswordInForm(
          !isContinueAsGuest,
          updatedNewAccountForm
        );

        this.inputs = this._formsService.updateInputs(
          this.inputs,
          updatedInputs
        );

        this.addCountriesToDropdown(this.inputs, listOfAllCountries);

        this.form = this._formsService.toFormGroup(this.inputs);
        this.calculateFeedbackMessages();

        this.enableOrDisableInputs();
      });
  }

  enablePasswordInForm(
    enable: boolean,
    updatedNewAccountForm: InputBase<any>[]
  ) {
    updatedNewAccountForm.forEach(input => {
      if (input.key.endsWith('password')) {
        input.hidden = !enable;
        input.required = enable;
      }
    });

    return updatedNewAccountForm;
  }

  sumbitProfileUpdate() {
    this.isEditEnabled = false;
    this.enableOrDisableInputs();

    let userData: UserProfileUpdateModel = this._helperService.processFormValuesBeforeSave(this.form.value) as UserProfileUpdateModel;
    userData = this.profileUpdateWithAdditionalProps(userData);

    this._store.dispatch(new userActions.UpdateProfile(userData));

    this.isProfileUpdated.emit(true);
  }

  setIsAsGuest(event) {
    this._store.dispatch(
      new stepsActions.SetContinueAsGuest(event.target.checked)
    );

    setTimeout(() => {
      this.form.updateValueAndValidity({ onlySelf: false, emitEvent: true });

      let questionnaireForm = this.registrationQuestionnaireComponent ? this.registrationQuestionnaireComponent.form : null;
      if (questionnaireForm) {
        questionnaireForm.updateValueAndValidity({
          onlySelf: false,
          emitEvent: true
        });
      }
    }, 0);
  }

  submitNewAccount() {
    if (
      this.processingSubmit ||
      !this.form.valid ||
      !this.validRegistrationQuestionnaire
    ) {
      return;
    }

    this._store.dispatch(new helperActions.SetSpinnerValue(true));
    this.processingSubmit = true;

    observableCombineLatest([
      this._store.pipe(select(fromRoot.getLanguage)),
      this._store.pipe(select(fromRoot.getSelectedExhibitionId))
    ])
      .pipe(first())
      .subscribe(([language, exhibitionId]) => {

        let userData: any = this._helperService.processFormValuesBeforeSave(this.form.value);

        //userData.userName = '';
        delete userData.address;
        delete userData['verify-email'];
        delete userData['verify-password'];

        if (!userData.hasOwnProperty('provider')) {
          userData['provider'] = '';
        }
        if (!userData.hasOwnProperty('providerKey')) {
          userData['providerKey'] = '';
        }

        if (this.questionnaireDataToSave) {
          userData['questionnaire'] = this.questionnaireDataToSave;
        }

        userData.language = language;
        userData.EventId = exhibitionId;
        userData.isGuest = this.isContinueAsGuest;

        this._store.dispatch(new userActions.CreateProfile(userData));

        //If we have the validation step names object from parent, copy data to buyer
        if (this.validationStepName && this.isContinueAsGuest && this.inputs) {
          this._store
            .select(fromRoot.getBuyerInfo)
            .pipe(first())
            .subscribe(buyerInfo => {
              // overwrite the buyer form with updated data from the registratin form
              const that = this;
              buyerInfo.list.forEach(function(element) {
                const sameInput = that.inputs.find((input: InputBase<any>) => {
                  return input.value && input.key === element.key;
                });
                if (sameInput) {
                  element.value = sameInput.value;
                }
              });

              const buyerInfoPayload: FormInputsPayloadModel = {
                formInfo: this.buyerInfoActionName,
                inputSet: {
                  rerender: true,
                  list: buyerInfo.list
                }
              };

              this._store.dispatch(
                new stepsActions.SetInputs(buyerInfoPayload)
              );
            });
        }
      });
  }

  registrationQuestionnaireIsValid(isValid) {
    this.validRegistrationQuestionnaire = isValid;
  }

  questionnaireDataChanged(formInputs: InputBase<any>[]) {
    if (formInputs) {
      formInputs.map((input: InputBase<any>) => {
        if (input.previousValueId) {
          let element = formInputs.find(
            p =>
              p.value == input.previousValueId ||
              (p.controlType === 'checkbox' &&
                p.options.find(
                  (option: { value: boolean; key: number }) =>
                    option.value === true &&
                    option.key === input.previousValueId
                ))
          );

          input.hidden = !element;
        }
      });

      const questionnaireValues = this._helperService.processQuestionnaireValuesBeforeSave(
        formInputs
      );

      if (questionnaireValues.length) {
        this._store
          .select(fromRoot.getSelectedExhibitionId)
          .pipe(first())
          .subscribe((id: number) => {
            if (id === null) {
              id = -1;
            }
            this.questionnaireDataToSave = {
              eventId: id,
              values: questionnaireValues
            };
          });
      }

      this.calculateFeedbackMessages(true);
    }
  }

  onProfileEdit() {
    this.isEditEnabled = true;
    this.enableOrDisableInputs();
  }

  cancelProfileEdit() {
    // this._store.dispatch(new userActions.GetProfile(this.userProfile.id));
    this.isEditEnabled = false;
    this.enableOrDisableInputs();
  }

  addCountriesToDropdown(inputs, countryList) {
    inputs.map(input => {
      if (input.key === 'country') {
        this._formsService
          .translateCountries(countryList)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(subs => {
            subs.subscribe(countries => {
              input.options = countries;
            });
          });
      }
    });
  }

  profileUpdateWithAdditionalProps(
    userData: UserProfileUpdateModel
  ): UserProfileUpdateModel {
    let updatedData = { ...userData };

    observableCombineLatest(
      this._store.select(fromRoot.getSelectedExhibitionId),
      this._store.select(fromRoot.getLanguage),
      this._store.select(fromRoot.getSelfRegistration)
    )
      .filter(([EventId, language]) => {
        if (EventId && language) {
          return true;
        }
      })
      .pipe(first())
      .subscribe(([EventId, language, IsSelfRegistration]) => {
        updatedData = { ...updatedData, EventId, language, IsSelfRegistration };
      });

    updatedData.id = this.userProfile.id;

    delete updatedData.address;

    return updatedData;
  }

  profileCreateWithAdditionalProps(userData: UserProfileModel) {
    let updatedData = { ...userData };
  }
}
