import * as exhibitionActions from '../../shared/services-with-reducers/exhibition/exhibition.actions';
import * as fromRoot from '../../app.reducer';
import * as legitimationActions from '../../shared/services-with-reducers/legitimation/legitimation.actions';
import * as userActions from '../../shared/services-with-reducers/user/user.actions';

import {
  ExhibitionHistoryListModel,
  ExhibitionModel
} from '../../shared/services-with-reducers/exhibition/exhibition.interface';
import {
  Observable,
  combineLatest as observableCombineLatest,
  Subscription
} from 'rxjs';
import { filter, first } from 'rxjs/operators';

import { Component, OnDestroy } from '@angular/core';
import { LegitimationListModel } from '../../shared/services-with-reducers/legitimation/legitimation.interface';
import { StatusBarService } from '../../status-bar/status-bar.service';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { UserProfileModel } from '../../shared/services-with-reducers/user/user.interface';
import { UserService } from '../../shared/services-with-reducers/user/user.service';

@Component({
  moduleId: module.id,
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnDestroy {
  public legitimationList$: Observable<LegitimationListModel[]>;
  public profile$: Observable<UserProfileModel>;
  public exhibitionHistoryList$: Observable<ExhibitionHistoryListModel[]>;
  public lowLimit = 3;
  public hiLimit = 10000;
  public billingAddressPresent: boolean = false;
  public isEmailVerified: boolean = true;
  public isProfileLoaded: boolean = false;

  public limits = {
    legitimation: this.lowLimit,
    ticket: this.lowLimit,
    exhibition: this.lowLimit
  };

  public limitsTranslations = {
    legitimation: 'profile.toggle.more',
    ticket: 'profile.toggle.more',
    exhibition: 'profile.toggle.more'
  };

  private _subscriptions = new Subscription();

  constructor(
    private _store: Store<fromRoot.State>,
    public _userService: UserService,
    private _statusBarService: StatusBarService,
    private _translateService: TranslateService
  ) {
    this._subscriptions.add(
      this._store
        .select(fromRoot.getLanguage)
        .pipe(filter(lang => !!lang))
        .subscribe(lang => {
          this._store.dispatch(
            new exhibitionActions.GetListOfAllExhibitions(lang)
          );
        })
    );

    this._store
      .select(fromRoot.getProfile)
      .pipe(
        filter(data => !!data),
        first()
      )
      .subscribe((user: UserProfileModel) => {
        this._store.dispatch(new userActions.GetProfileBillingAddress(user.id));
        this._store.dispatch(
          new legitimationActions.GetLegitimationList(user.id)
        );
      });

    this._subscriptions.add(
      this._store
        .select(fromRoot.getProfileBillingAddress)
        .pipe(filter(data => !!data))
        .subscribe(data => {
          this.billingAddressPresent = !!data.length;
        })
    );

    this.legitimationList$ = this._store.select(fromRoot.getLegitimationList);

    this.profile$ = this._store.select(fromRoot.getProfile);
    this.profile$
      .pipe(
        filter(profile => {
          return !!profile;
        })
      )
      .subscribe(profile => {
        if (profile.hasOwnProperty('isEmailVerified')) {
          this.isEmailVerified = profile.isEmailVerified;
        }
        if (!this.isProfileLoaded) {
          this.isProfileLoaded = true;
          // always reload profile on refresh
          this._store.dispatch(new userActions.GetProfile(profile.id));
        } else {
          this._store.dispatch(
            new exhibitionActions.GetExhibitionHistoryList(profile.id)
          );
          this._store.dispatch(
            new userActions.GetTicketsHistoryByUser(profile.id)
          );
        }
      });

    this.exhibitionHistoryList$ = this._store.select(
      fromRoot.getExhibitionHistoryList
    );
  }

  resendAccountVerification() {
    observableCombineLatest(
      this._store.select(fromRoot.getLanguage),
      this._store.select(fromRoot.getProfile)
    )
      .filter(data => !!data[0] && !!data[1])
      .first()
      .subscribe(data => {
        const [language, profile] = data;
        this._userService
          .sendVerifyEmail(language)
          .subscribe(res => {
            if (res.status === 200) {
              this._translateService
                .get('profile.verify-email.send')
                .subscribe((translation: any) => {
                  this._statusBarService.setStatus(translation, 'success');
                });
            } else {
              this._translateService
                .get('profile.verify-email.notSend')
                .subscribe((translation: any) => {
                  this._statusBarService.setStatus(translation, 'error');
                });
            }
          });
      });
  }

  toggleList(section: 'ticket' | 'exhibition' | 'legitimation') {
    if (this.lowLimit === this.limits[section]) {
      this.limits[section] = this.hiLimit;
      this.limitsTranslations[section] = 'profile.toggle.less';
    } else {
      this.limits[section] = this.lowLimit;
      this.limitsTranslations[section] = 'profile.toggle.more';
    }
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
