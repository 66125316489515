var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { OnInit, OnDestroy } from '@angular/core';
import * as fromRoot from '../app.reducer';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { getLocalStorageString, setLocalStorageObject, setLocalStorageString } from '../shared/app-utils';
import { AppConstants } from '../shared/app-constants';
var CookiesPopupComponent = /** @class */ (function () {
    function CookiesPopupComponent(_router, _store) {
        var _this = this;
        this._router = _router;
        this._store = _store;
        this.hideDataProtectionOnHomePage = getLocalStorageString(AppConstants.hideDataProtectionOnHomePageReducer) || false;
        this.hideDataProtectionPageOnEvent = JSON.parse(getLocalStorageString(AppConstants.hideDataProtectionOnEventReducer)) || [];
        this.hideDataProtectionOnEventPage = false;
        this._subscriptions = new Subscription();
        this._subscriptions.add(this._store
            .select(fromRoot.getSelectedExhibitionId)
            .pipe(filter(function (eventId) {
            _this.eventId = eventId;
            return !!eventId;
        }))
            .subscribe(function (eventId) {
            _this.hideDataProtectionPageOnEvent =
                JSON.parse(getLocalStorageString(AppConstants.hideDataProtectionOnEventReducer)) || [];
            _this.hideDataProtectionOnEventPage = !!Object.keys(_this.hideDataProtectionPageOnEvent).find(function (id) { return id === eventId.toString(); });
        }));
    }
    CookiesPopupComponent.prototype.ngOnInit = function () {
    };
    CookiesPopupComponent.prototype.ngOnDestroy = function () {
        this._subscriptions.unsubscribe();
    };
    CookiesPopupComponent.prototype.clickText = function (event) {
        event.stopPropagation();
        event.preventDefault();
        if (event.target.tagName === 'A') {
            this._router.navigate(['/data-protection']);
        }
    };
    CookiesPopupComponent.prototype.close = function () {
        var _a;
        if (!this.eventId) {
            setLocalStorageString(AppConstants.hideDataProtectionOnHomePageReducer, 'true');
            this.hideDataProtectionOnHomePage = true;
        }
        else {
            setLocalStorageObject(AppConstants.hideDataProtectionOnEventReducer, __assign({}, this.hideDataProtectionPageOnEvent, (_a = {}, _a[this.eventId] = true, _a)));
            this.hideDataProtectionOnEventPage = true;
        }
    };
    return CookiesPopupComponent;
}());
export { CookiesPopupComponent };
