import * as fromRoot from '../../app.reducer';
import * as pressNewsActions from '../../press-news/press-news.actions';

import { Component, OnInit, OnDestroy } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

@Component({
  moduleId: module.id,
  selector: 'app-press-news-detail',
  templateUrl: './press-news-detail.component.html',
  styleUrls: ['./press-news-detail.component.scss']
})
export class PressNewsDetailComponent implements OnInit, OnDestroy {
  private _subscriptions = new Subscription();
  public singleNews$: Observable<Object>;
  public singleNews;

  constructor(
    private _route: ActivatedRoute,
    private _store: Store<fromRoot.State>
  ) {
    this.singleNews$ = this._store.select(fromRoot.getPressNewsDetail);
    this._subscriptions.add(
      this.singleNews$.subscribe(newsData => {
        this.singleNews = newsData;
      })
    );
  }

  ngOnInit() {
    const { params } = this._route;

    if (params) {
      this._subscriptions.add(
        params.subscribe(data => {
          this._store.dispatch(new pressNewsActions.SelectPressNewsId(data.id));
        })
      );
    }
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
