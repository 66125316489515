<div class="container-fluid h-100-p">
    <div class="page-content h-100-p">
        <div class="page-wrapper h-100-p">
            <div class="navigation-wrapper" *ngIf="isAdmin$ | async">
                <app-navigation-tabs
                    class="navigation-tabs"
                    [tabs]="tabs"
                    (activeTabChanged)="onTabChange($event)"
                    type="normal"
                    [activeTab]="activeTab"
                ></app-navigation-tabs>
                <h1>
                    <span class="edit">
                        <a
                            routerLink="/admin/maintenance/{{ activeTab }}"
                            class="button"
                        >
                            <span data-translation="page.maintenance.edit">
                                {{ 'page.maintenance.edit' | translate }}
                            </span>
                        </a>
                    </span>
                </h1>
            </div>
            <section [innerHTML]="htmlContent | htmlInjector"></section>
        </div>
    </div>
</div>
