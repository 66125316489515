var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import * as additionalServicesActions from './additional-services.actions';
import { Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { AddWorkshopBookingModel, MenuModel, WorkshopModel, AddWorkshopBookingWithHolderModel } from './additional-services.interface';
import { InjectionToken } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, map, mergeMap, switchMap } from 'rxjs/operators';
import { AdditionalServicesService } from './additional-services.service';
export var ADDITIONAL_SERVICES_DEBOUNCE = new InjectionToken('Additional Services Debounce');
var AdditionalServicesEffect = /** @class */ (function () {
    function AdditionalServicesEffect(actions$, additionalServicesService, _store, debounce) {
        if (debounce === void 0) { debounce = 50; }
        var _this = this;
        this.actions$ = actions$;
        this.additionalServicesService = additionalServicesService;
        this._store = _store;
        this.debounce = debounce;
        this.loadMenuOptions$ = this.actions$.pipe(ofType(additionalServicesActions.ActionTypes.GET_MENU_OPTIONS), debounceTime(this.debounce), switchMap(function (data) {
            var eventId = data.payload;
            if (!eventId) {
                return Observable.empty();
            }
            return _this.additionalServicesService.getMenuOptions(eventId).pipe(map(function (menuOptions) {
                return new additionalServicesActions.SetMenu(menuOptions);
            }), catchError(function (error) {
                console.log(error);
                return of(new additionalServicesActions.SetMenu([]));
            }));
        }));
        this.loadWorkshops$ = this.actions$.pipe(ofType(additionalServicesActions.ActionTypes.GET_WORKSHOPS), debounceTime(this.debounce), switchMap(function (data) {
            var eventId = data.payload;
            if (!eventId) {
                return Observable.empty();
            }
            return _this.additionalServicesService.getWorkshopList(eventId).pipe(map(function (workshopList) {
                return new additionalServicesActions.SetWorkshops(workshopList);
            }), catchError(function (error) {
                console.log(error);
                return of(new additionalServicesActions.SetWorkshops([]));
            }));
        }));
        // debounceTime to prevent "out of order" responses (can cause full workshop appearing as not full)
        this.postWorkshopBooking$ = this.actions$.pipe(ofType(additionalServicesActions.ActionTypes.POST_WORKSHOP_BOOKING), debounceTime(this.debounce), mergeMap(function (data) {
            // TODO update interface WorkshopBookingPostModel
            var payload = data.payload;
            if (!payload) {
                return Observable.empty();
            }
            var ticketHolderId = payload.ticketHolderId;
            delete payload.ticketHolderId;
            return _this.additionalServicesService.postWorkshopBooking(payload).pipe(map(function (bookings) {
                bookings.added = payload.seats >= 0 ? true : false;
                bookings.ticketHolderId = ticketHolderId;
                _this._store.dispatch(new additionalServicesActions.AddWorkshopBookingFull({
                    isFull: false,
                    workshopId: payload.workshopId
                }));
                var model = new AddWorkshopBookingWithHolderModel();
                model.addWorkshopBookingModel = bookings;
                model.assignWorkshopToHolderModel = data.assignWorkshopToHolderModel;
                return new additionalServicesActions.AddWorkshopBooking(model);
            }), catchError(function (error) {
                console.log(error);
                _this._store.dispatch(new additionalServicesActions.AddWorkshopBookingFull({
                    isFull: true,
                    workshopId: payload.workshopId
                }));
                return of(new additionalServicesActions.AddWorkshopBooking(null));
            }));
        }));
        this.afterAddWorkshopBooking$ = this.actions$.pipe(ofType(additionalServicesActions.ActionTypes.ADD_WORKSHOP_BOOKING), switchMap(function (data) {
            return of(_this._store.dispatch(new additionalServicesActions.AfterAddWorkshopBooking(data.payload.assignWorkshopToHolderModel)));
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], AdditionalServicesEffect.prototype, "loadMenuOptions$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], AdditionalServicesEffect.prototype, "loadWorkshops$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], AdditionalServicesEffect.prototype, "postWorkshopBooking$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Observable)
    ], AdditionalServicesEffect.prototype, "afterAddWorkshopBooking$", void 0);
    return AdditionalServicesEffect;
}());
export { AdditionalServicesEffect };
