import { take, map } from 'rxjs/operators';
import * as fromRoot from '../../app.reducer';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as userActions from '../services-with-reducers/user/user.actions';

function getState(store) {
  let state;

  store.select(fromRoot.isUserLoggedIn).subscribe(s => (state = s));
  return state;
}

@Injectable()
export class LoginGuardSignedOut implements CanActivate {
  constructor(private _router: Router, private _store: Store<fromRoot.State>) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {

    return this._store.select(fromRoot.isUserLoggedIn).pipe(
      map(isLoggedIn => {
        if (Object.keys(route.queryParams).some(param => param.toLowerCase() === 'login_user' || param.toLowerCase() === 'reg_mail')) {
          this._store.dispatch(new userActions.ResetReducer());
          return true;
        }

        if (!isLoggedIn) {
          return true;
        }

        this._router.navigate(['/']);
        return false;
      }),
      take(1)
    );
  }
}
