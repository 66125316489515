import * as fromRoot from '../../../app.reducer';
import * as stepsActions from './steps-forms.actions';

import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import {
  FormInputsPayloadModel,
  OrderModel,
  OrderPayloadModel,
  TicketHolderAdditionalDataModel,
  VisibilityPayloadModel,
  InputsListModel
} from './step.interface';
import {
  PackageConfirmationGroupModel,
  ProductsConfirmationModel,
  TicketConfirmationModel,
  TicketModel,
  TicketSubgroupModel
} from '../tickets/ticket.interface';
import { filter, first } from 'rxjs/operators';
import { DateTime } from 'luxon';

import { FormsService } from '../../forms/forms.service';
import { HelperService } from '../helpers/helper.service';
import { Injectable } from '@angular/core';
import { InputBase } from '../../forms/inputs/input-base.class';
import { select, Store } from '@ngrx/store';
import { ProductGroupModel } from '../tickets/ticket.interface';
import { environment } from '../../../../environments/environment';
import { getTicketHolderData } from '../tickets/ticket-functions';
import { combineLatest as observableCombineLatest } from 'rxjs';
import { QuestionnaireInputResponseModel } from '../helpers/helper.interface';
import { ErrorHandlingService } from '../../error-handling/error-handling.service';
import { ExhibitionSettingModel, QuestionnaireDataInput } from '../customization/customization.interfaces';
import { UserProfileModel } from '../user/user.interface';
import { getLocalStorageString } from '../../app-utils';
import { AppConstants } from '../../app-constants';

@Injectable({
  providedIn: 'root'
})
export class StepsFormsService {

  workshopsOnTicketSelection: boolean;

  constructor(
    private _router: Router,
    private _http: HttpClient,
    private _store: Store<fromRoot.State>,
    private _helperService: HelperService,
    private _formsService: FormsService,
    private _errorHandlingService: ErrorHandlingService
  ) {
    // react on need for legitimation and hide/show the legitimation step
    this._store
      .select(fromRoot.isLegitimationRequired)
      .pipe(
        filter(isLegitimationRequired => {
          if (isLegitimationRequired && !this._helperService.isSelfregistration()) {
            return true;
          }
        })
      )
      .subscribe(isLegitimationRequired => {
        const visibilityPayload: VisibilityPayloadModel = {
          stepKey: 'legitimation',
          visible: isLegitimationRequired.required
        };

        this._store.dispatch(
          new stepsActions.SetStepsVisibility([visibilityPayload])
        );
      });

    observableCombineLatest([
      this._store.pipe(select(fromRoot.getExhibitionSettings)),
      this._store.pipe(select(fromRoot.doTicketsNeedLegitimation))
    ]).subscribe(([settings, doTicketsNeedLegitimation]) => {
      if (settings) {
        this.workshopsOnTicketSelection = settings.workshopsOnTicketSelection;
        const orderPayload: OrderPayloadModel = {
          stepKey: 'legitimation',
          newOrder: settings.goToLegitimationForNewAccount && !doTicketsNeedLegitimation ? 1 : 3
        };

        this._store.dispatch(new stepsActions.SetStepOrder(orderPayload));
      }
    });

    // react on need for workshops and hide/show the workshop step
    this._store
      .select(fromRoot.doTicketsNeedWorkshops)
      .subscribe(doTicketsNeedWorkshops => {
        const visibilityPayload: VisibilityPayloadModel = {
          stepKey: 'workshop',
          visible: this.workshopsOnTicketSelection ? false : doTicketsNeedWorkshops
        };

        if (!this._helperService.isSelfregistration()) {
          // as a fast solution we hide workshops on self registration (later this should come from event settings)
          this._store.dispatch(
            new stepsActions.SetStepsVisibility([visibilityPayload])
          );
        }
      });


  }

  sendFinalOrder(orderData: OrderModel) {
    let selfReg = '';
    if (this._helperService.isSelfregistration()) {
      selfReg = '?sr=true';
    }

    //clear all previously received errors from the API (so we don't interpret errors that occured earlier in the shopping process):
    this._errorHandlingService.clearAllErrors();

    return this._http.post(
      //`${environment.apiUrl}/buyer-questionnaire/${eventId}`,
      `${environment.protocol}${environment.webApiUrl}/order/save-order${selfReg}`,
      orderData,
      { observe: 'response' }
    );
  }

  getBuyerQuestionnaire(eventId, args: number[]) {
    let selfReg = '';
    if (this._helperService.isSelfregistration()) {
      selfReg = '&sr=true';
    }

    const ticketPersonIds = args.reduce((accu, curr) => {
      return accu + `&ticketPersonIds=${curr}`;
    }, '');

    return this._http.get(
      `${environment.protocol}${environment.webApiUrl}/event/${eventId}/buyer-questionnaire?${selfReg}${ticketPersonIds}`
    );
  }

  navigateRelativeTo(x: number, router) {
    observableCombineLatest([
      this._store.pipe(select(fromRoot.getStepsValidity)),
      this._store.pipe(select(fromRoot.getSelectedStep)),
      this._store.pipe(select(fromRoot.getSelectedExhibitionId))
    ])
      .pipe(
        filter(([validations, activeStepKey, selectedEventId]) => {
          return !!validations && !!activeStepKey && selectedEventId !== null;
        }),
        first()
      )
      .subscribe(([validations, activeStepKey, selectedEventId]) => {
        const visibleSteps = Object.keys(validations)
          // make sure steps are ordered correctly
          .sort((a, b) => {
            return validations[a].order - validations[b].order;
          })
          // only navigate to visible routes
          .filter(stepKey => {
            return validations[stepKey].visible;
          });
        
        const indexOfStep = visibleSteps.indexOf(activeStepKey);
        
        if ((x < 0 && indexOfStep > 0) || (x > 0 && indexOfStep < visibleSteps.length - 1)) {
          this.checkAndRedirectToNextStep(validations, visibleSteps, selectedEventId, indexOfStep, x, router, activeStepKey);
        }
      });
  }

  checkAndRedirectToNextStep(validations, visibleSteps, selectedEventId, indexOfStep, x, router, activeStepKey) { 
    const urlBase = this._helperService.isSelfregistration()
      ? 'self-registration'
      : 'webshop';

    let navigateTo = visibleSteps[indexOfStep + x];

    if (navigateTo === 'payment') {
      console.log('%c currentStep ', 'background: #5AAC56; color: #fff', `/${urlBase}/${selectedEventId}/${activeStepKey}`);
      console.log(x === -1 ? '%c "back button" URL ' : '%c "continue button" URL ', 'background: #D46A6A; color: #fff', `/${urlBase}/${selectedEventId}/${navigateTo}`);
    }

    if (!validations[navigateTo].showInStepNavigation) {
      const ticketStep = visibleSteps.find(step => step === 'tickets');
      navigateTo = validations[ticketStep].showInStepNavigation 
        ? ticketStep 
        : (validations[visibleSteps[0]].showInStepNavigation ? visibleSteps[0] : 'tickets');

      console.log('%c re-navigated URL ', 'background: #6BA099; color: #fff', `/${urlBase}/${selectedEventId}/${navigateTo}`);
    }

    this._store.dispatch(new stepsActions.SetSelectedStep(navigateTo));
    router.navigate([`/${urlBase}/${selectedEventId}/${navigateTo}`]);
  }

  navigateToLastNotDisabledPage() {
    observableCombineLatest(
      this._store.select(fromRoot.getOrderedStepsValidityArray),
      this._store.select(fromRoot.getSelectedExhibitionId)
    )
      .first()
      .subscribe(data => {
        const [orderedStepsValidityArray, eventId] = data;
        const lastNotDisabledPage = orderedStepsValidityArray
          .slice(0)
          .reverse()
          .find(step => {
            return (
              step.value.disabled === false &&
              step.value.visible &&
              step.value &&
              step.value.showInStepNavigation
            );
          });

        this._router.navigate([
          `/webshop/${eventId}/${lastNotDisabledPage.key}`
        ]);
      });
  }

  //scroll to top
  scrollToTop() {
    window.scroll(0, 0);
  }

  /**
   * This function combine together listOfAllTickets (ungroupedTickets), tickets in tree structure and TicketHolders
   *
   * @param {any} ungroupedTickets
   * @param {any} ticketTypes
   * @param {any} ticketHolderInputSets
   * @returns
   * @memberof StepsFormsService
   */
  assignTicketsToHolders(
    ungroupedTickets: TicketModel,
    ticketTypes: ProductGroupModel[],
    ticketHolderInputSets: FormInputsPayloadModel[],
    ticketHolderAdditionalData: TicketHolderAdditionalDataModel
  ) {
    let ticketsWithHolders: ProductsConfirmationModel[] = JSON.parse(
      JSON.stringify(ticketTypes)
    ).map(ticketGroup => {
      return {
        groupId: ticketGroup.groupId,
        groupName: ticketGroup.groupName,
        packageName: ticketGroup.packageName,
        products: ticketGroup.products,

        packages: [],
        tickets: [],
        articles: []
      };
    });

    Object.keys(ungroupedTickets).forEach((ticketUniqueId, index) => {
      const ticketById = ungroupedTickets[ticketUniqueId];
      if (ticketById.count) {
        ticketById.holdersIndexes.forEach(ticketIndex => {
          // get a group of tickets (one day ticket, week ticket, etc.) to which this one belong
          let productGroup: ProductsConfirmationModel = ticketsWithHolders.find(ticketType => {
            if (ticketById.packageNumber) {
              return ticketType.products.some(prod => {
                if (!!prod.package && prod.package.packageNumber === ticketById.packageNumber) {
                  return prod.package.contents.some(pc => pc.packageGroups.some(pg => pg.ticketTypeId === ticketById.groupId));
                }
              });
            }
            return ticketType.groupId === ticketById.groupId;
          });
          
          let subGroup: TicketSubgroupModel;
          let pkgGroup: PackageConfirmationGroupModel;
          let ticketGroup: TicketConfirmationModel;
          
          if (ticketById.packageNumber) {
            const packageName = productGroup.products.find(prod => prod.package.packageNumber === ticketById.packageNumber).package.name;
            pkgGroup = productGroup.packages.find(pkgGroup => pkgGroup.number == ticketById.packageNumber && pkgGroup.index === ticketById.packageIndex);

            if (!pkgGroup) {
              pkgGroup = {
                name: packageName,
                number: ticketById.packageNumber,
                index: ticketById.packageIndex,
                price: 0,
                ticketTypeGroup: [],
                articleSubGroup: []
              }
              productGroup.packages.push(pkgGroup);
            }
          }

          ticketGroup = ticketById.packageNumber ? pkgGroup.ticketTypeGroup.find(tg => tg.groupId === ticketById.groupId) : productGroup.tickets.find(tg => tg.groupId === ticketById.groupId);
          if (!ticketGroup) {
            ticketGroup = {
              groupId: ticketById.groupId,
              groupName: ticketById.groupName,
              subGroup: []
            }
            if (pkgGroup) {
              pkgGroup.ticketTypeGroup.push(ticketGroup);
            } else {
              productGroup.tickets.push(ticketGroup);
            }
          }
          subGroup = ticketGroup.subGroup.find(subgroup => subgroup.uniqueId === ticketById.uniqueId);
          // in case this subgroup does not exist yet, create it
          if (!subGroup) {
            subGroup = {
              uniqueId: ticketById.uniqueId,
              ticketTypeId: ticketById.id,
              name: ticketById.name,
              voucherCode: ticketById.voucherCode,
              tickets: [],
              ticketTypeOrder: ticketById.ticketTypeOrder
            };
            ticketGroup.subGroup.push(subGroup);
          }

          // once we are sure the subgroup exists push this particular ticket into it
          subGroup.tickets.push({
            ticketName: ticketById.name,
            price: ticketById.price,
            ticketHolderAdditionalData: ticketHolderAdditionalData ? ticketHolderAdditionalData[ticketIndex] : null,
            ticketHolderConfirmation: ticketHolderAdditionalData ? getTicketHolderData(
              ticketHolderInputSets,
              ticketIndex
            ) : null
          });
          if (pkgGroup) {
            pkgGroup.price += ticketById.price;
          }
        });
      }
    });

    // This logic places a horizontal <hr> line between packages and tickets inside BASKET
    const allPkgGroups = ticketsWithHolders.filter(x => x.packages.length > 0);
    const lastPkgGrp = allPkgGroups.length > 0 ? allPkgGroups[allPkgGroups.length - 1] : null;
    const lastPkg = lastPkgGrp ? lastPkgGrp.packages[lastPkgGrp.packages.length - 1] : null;
    const isTicketTaken = ticketsWithHolders.some(productGroup => productGroup.tickets.length > 0);

    ticketsWithHolders = ticketsWithHolders.map(productGroup => {
      // sort tickets by ticketType in packages ticketTypeGroup array
      productGroup.packages.forEach(pkg => {
        pkg.addBasketPackageBorder = isTicketTaken || pkg !== lastPkg;
        pkg.ticketTypeGroup.forEach(tg => {
          tg.subGroup.sort((a, b) => {
            return a.ticketTypeOrder - b.ticketTypeOrder;
          });
        });
      });

      // sort tickets by ticketType in tickets array
      productGroup.tickets.forEach(t => {
        t.subGroup.sort((a, b) => {
          return a.ticketTypeOrder - b.ticketTypeOrder;
        });
      });

      return productGroup;
    });

    // sort products without packages first
    ticketsWithHolders.sort((a, b) => (a.packageName ? 1 : 0) - (b.packageName ? 1 : 0));

    return ticketsWithHolders;
  }

  /**
   * This function combine together listOfAllTickets (ungroupedTickets), tickets in tree structure and TicketHolders
   *
   * @param {any} ungroupedTickets
   * @param {any} ticketTypes
   * @param {any} ticketHolderInputSets
   * @returns
   * @memberof StepsFormsService
   */
  assignUngroupedTicketsToHolders(
    ungroupedTickets: TicketModel,
    ticketHolderInputSets: FormInputsPayloadModel[],
    ticketHolderAdditionalData: TicketHolderAdditionalDataModel
  ) {
    const ticketsWithHolders = [];

    Object.keys(ungroupedTickets).forEach(ticketUniqueId => {
      const ticketById = ungroupedTickets[ticketUniqueId];
      if (ticketById.count) {
        // for each ticket asigned to a ticket holder form
        ticketById.holdersIndexes.forEach(ticketIndex => {
          ticketsWithHolders.push({
            ticketUniqueId: ticketById.uniqueId,
            ticketName: ticketById.name,
            ticketTypeId: ticketById.id,
            ticketGroupName: ticketById.groupName,
            ticketPrice: ticketById.price,
            ticketCount: ticketById.count,
            ticketHasAllowedWorkshops:
              ticketById.allowedWorkshops &&
              ticketById.allowedWorkshops.length > 0,
            allowedWorkshops: ticketById.allowedWorkshops,
            ticketHolderAdditionalData: ticketHolderAdditionalData[ticketIndex],
            ticketHolderConfirmation: getTicketHolderData(
              ticketHolderInputSets,
              ticketIndex
            )
          });
        });
      }
    });

    return ticketsWithHolders;
  }

  /**
   * This function combine together listOfAllTickets (ungroupedTickets), tickets in tree structure and TicketHolders
   *
   * @param {any} ungroupedTickets
   * @param {any} ticketTypes
   * @param {any} ticketHolderInputSets
   * @returns
   * @memberof StepsFormsService
   */
  assignTicketsToHoldersForSave(
    ungroupedTickets: TicketModel,
    ticketHolderInputSets: FormInputsPayloadModel[],
    ticketHolderAdditionalData: TicketHolderAdditionalDataModel,
    buyerInfoEmail?
  ) {
    const ticketsWithHolders = [];

    // US2870 - data being prepared and sent via 'save-order'. we're sending two more parameters inside 'ticketHolder': isBuyerVisitorChecked and buyerVisitorQuestionnaire
    let buyerVisitorCheckedSlideIndex: number;
    let isBuyerVisitorChecked: boolean;
    let buyerVisitorQuestionnaire: Array<QuestionnaireInputResponseModel>;

    observableCombineLatest([
      this._store.pipe(select(fromRoot.getTicketHolderQuestionnaireInputs)),
      this._store.pipe(select(fromRoot.getBuyerVisitorCheckbox))
    ])
      .filter(data => !!data)
      .pipe(first())
      .subscribe(([questionnaire, buyerVisitorCheckbox]) => {
        if(!!buyerVisitorCheckbox) {
          buyerVisitorCheckedSlideIndex = buyerVisitorCheckbox.buyerVisitorCheckedSlideIndex;
          isBuyerVisitorChecked = buyerVisitorCheckbox.isBuyerVisitorChecked;
          // US2870 - we have to process raw questionnaire and proceed with info needed by 'save-order' which is three parameters: fieldId, valueId and text
          buyerVisitorQuestionnaire = this._helperService.processQuestionnaireValuesBeforeSave(
            questionnaire
          );
        }
      });

    Object.keys(ungroupedTickets).forEach((ticketUniqueId, index) => {
      const ticketById = ungroupedTickets[ticketUniqueId];
      if (ticketById.count) {
        // get contingent days booked for this ticket type
        const contingentDaysForTicket = this._helperService.getContingentDaysForTicket(
          ticketUniqueId
        );

        // for each ticket counted in the ticket type
        ticketById.holdersIndexes.forEach((ticketIndex, index) => {
          let ticketHolder: any = getTicketHolderData(
            ticketHolderInputSets,
            ticketIndex,
            buyerInfoEmail
          );

          // US2870 - only single ticketHolder/visitor in an order will have visitorQuestionnaire answered
          if (ticketIndex === buyerVisitorCheckedSlideIndex) {
            ticketHolder.isBuyerAVisitor = isBuyerVisitorChecked;
            ticketHolder.visitorQuestionnaire = buyerVisitorQuestionnaire;
          }

          const contingenTicketData = contingentDaysForTicket[index];

          // contingent tickes should have the index as in array
          const day = contingenTicketData ? contingenTicketData.day : null;

          if (ticketHolder) {
            let ticketHoldersWorkshops = [];

            const thisTicketHolderIndex = ticketHolder.ticketIndex;
            if (
              ticketHolderAdditionalData.hasOwnProperty(thisTicketHolderIndex)
            ) {
              ticketHoldersWorkshops =
                ticketHolderAdditionalData[thisTicketHolderIndex].workshops;
            }

            const tickerToOwner = ticketHolder['sendtoowner'];
            let sendingOption = ticketHolder['sendingOption'];
            const asMobileTicket =
              ticketHolder['sendingOption'] === 'mobilePerOwner' ||
                ticketHolder['sendingOption'] === 'mobilePerBuyer'
                ? true
                : false;

            ticketHolder = this.removeAttributes(ticketHolder, [
              'ticketIndex',
              'address',
              'sendtoowner',
              'sendingOption'
            ]);
            ticketHolder = this.separateAddress(ticketHolder);

            const voucherCode =
              'voucherCode' in ticketById ? ticketById['voucherCode'] : '';

            if (sendingOption === 'mobilePerOwner' && !tickerToOwner) {
              sendingOption = 'mobilePerBuyer';
            }
            if (sendingOption === 'ticketRetrivalLink' && !tickerToOwner) {
              sendingOption = 'ticketRetrivalLinkBuyer';
            }

            const ticketHolderData = getTicketHolderData(
              ticketHolderInputSets,
              ticketIndex
            );

            ticketsWithHolders.push({
              groupId: ticketById.groupId,
              ticketTypeId: ticketById.id,
              // uniqueId: ticketById.uniqueId, // dont send the unique id to backend as it was FE construct
              ticketToOwner: tickerToOwner,
              ticketSendingMode: sendingOption,
              ticketHolder: ticketHolder,
              asMobileTicket: asMobileTicket,
              voucherCode: voucherCode,
              workshops: ticketHoldersWorkshops,
              ticketPersonId: ticketById.ticketPersonId,
              packageNumber: ticketById.packageNumber,
              shoppingCartPkgIndex: ticketById.packageIndex,
              day
            });
          }
        });
      }
    });

    return ticketsWithHolders;
  }

  removeAttributes(obj: Object, attributes: Array<string>) {
    let clearedObject = Object.assign({}, obj);
    attributes.forEach(attr => {
      if (clearedObject.hasOwnProperty(attr)) {
        delete clearedObject[attr];
      }
    });

    return clearedObject;
  }

  renameAttributes(obj: Object, attributes: Array<any>) {
    let renamedObject = Object.assign({}, obj);
    attributes.forEach(attr => {
      if (renamedObject.hasOwnProperty(attr.from)) {
        renamedObject[attr.to] = renamedObject[attr.from];
        delete renamedObject[attr.from];
      }
    });

    return renamedObject;
  }

  separateAddress(obj) {
    let clearedObject = Object.assign({}, obj);
    const toSeparate = [
      'company',
      'street',
      'city',
      'zipCode',
      'country'
    ];

    clearedObject.address = {};
    toSeparate.forEach(key => {
      if (clearedObject.hasOwnProperty(key)) {
        clearedObject.address[key] = clearedObject[key];
      } else {
        // TODO once BE can accept only data which are really present delete else statement
        clearedObject.address[key] = '';
      }
    });

    clearedObject = this.removeAttributes(clearedObject, toSeparate);
    return clearedObject;
  }

  prepareDataForSaveAndSend(ticketsWithHolders: any, totalCost: number, anonymousTicket?: any) {
    observableCombineLatest([
      this._store.pipe(select(fromRoot.getExhibitionSettings)),
      this._store.pipe(select(fromRoot.getPaymentMethod)),
      this._store.pipe(select(fromRoot.isTicketHolderVisible)),
      this._store.pipe(select(fromRoot.getOrderUuid)),
      this._store.pipe(select(fromRoot.getShoppingStartTime))
    ])
      .pipe(
        filter((data: [
          ExhibitionSettingModel, 
          string, 
          boolean,
          string, 
          Date
        ]) => {
          const [
            settings,
            paymentMethod,
          ] = data;
          
          return anonymousTicket ? !!data : !!settings && paymentMethod !== null;
        }),
        first()
      )
      .subscribe(([
        settings,
        paymentMethod,
        isTicketHolderVisible,
        uuid,
        shoppingStartTime
      ]) => {
                
        observableCombineLatest([
          this._store.pipe(select(fromRoot.getSteps)),
          this._store.pipe(select(fromRoot.getProfile)),
          this._store.pipe(select(fromRoot.getSelectedExhibitionId)),
          this._store.pipe(select(fromRoot.getBuyerActiveBillingAddressId)),
          this._store.pipe(select(fromRoot.getLanguage)),
          this._store.pipe(select(fromRoot.getTicketSelectedSendingOptions))
        ])
          .pipe(
            filter((data: [
              any,
              UserProfileModel,
              number,
              number,
              string,
              string
            ]) => {
              const [
                steps,
                profile,
                eventId
              ] = data;

              return !!steps && !!eventId;
            }),
            first()
          )
          .subscribe(([
            steps,
            profile,
            eventId,
            billingaddressId,
            language,
            selectedSendingOption
          ]) => {

            let dataToSave: OrderModel = {
              paymentProvider: paymentMethod,
              userAgent: this._helperService.browserInfo(),
              shoppingStartTime: shoppingStartTime,
              shoppingEndTime: new Date(),
              order: {
                userId: profile ? profile.id : null
              },
              day: new Date() // xgebi Date should be chosen same as amano parking
            };

            dataToSave.order['eventId'] = eventId;
            dataToSave.order['uuid'] = uuid;
            dataToSave.order['parkingTickets'] = [];

            const storedParkingTickets = getLocalStorageString(AppConstants.parkingTicketsReducer);
            const parkingTicketsDictionary =
              storedParkingTickets && JSON.parse(storedParkingTickets);

            const parsedParkingTickets = Object.keys(
              parkingTicketsDictionary
            ).reduce(function (filtered, key) {
              if (parkingTicketsDictionary[key].hasOwnProperty('price')) {
                filtered[key] = parkingTicketsDictionary[key];
              }
              return filtered;
            }, {});

            for (const ticketKey in parsedParkingTickets) {
              let { since, until } = parsedParkingTickets[ticketKey];
              const ticketKeyData = ticketKey.split('_');
              const groupId = Number(ticketKeyData[0]);
              const ticketTypeId = Number(ticketKeyData[1]);
              since = this._helperService.toStringWithoutOffset(
                new Date(since)
              );
              until = this._helperService.toStringWithoutOffset(
                new Date(until)
              );

              dataToSave.order['parkingTickets'].push({
                groupId,
                ticketTypeId,
                since,
                until
              });
            }

            for (const stepKey in steps) {
              const forms: InputsListModel[] = steps[stepKey]
                ? steps[stepKey].forms
                : null;

              if (forms) {
                for (const formKey in forms) {
                  let form: InputsListModel = forms[formKey];

                  if (form && form.list) {
                    // we need to ensure that we get values of all inputs, even those which are disabled
                    form.list.forEach((input: InputBase<any>) => {
                      input.disabled = false;
                    });

                    const formObj = this._formsService.toFormGroup(form.list, [
                      formKey
                    ]);

                    // if it is not a ticket holder (ticket holder is comming together with tickets) add to to dataToSave
                    if (
                      formObj &&
                      !(formKey.substring(0, 13) === 'ticketHolder_')
                    ) {
                      switch (formKey) {
                        // transform data from buyer questionnaire
                        case 'questionnaire': {
                          dataToSave.order.questionnaireFields = this._helperService.processQuestionnaireValuesBeforeSave(
                            form.list
                          );
                          break;
                        }

                        case 'billingaddress': {
                          dataToSave.order.billingAddress = this._helperService.processFormValuesBeforeSave(formObj.value);
                          break;
                        }

                        case 'buyerinfo': {
                          let buyer = this._helperService.processFormValuesBeforeSave(formObj.value);

                          const privacyForm = forms['privacy'];

                          // set privacy policy value
                          if (privacyForm) {
                            const privacyPolicyBase = privacyForm.list.find(
                              (inputBase: InputBase<any>) =>
                                inputBase.key === 'disclaimer'
                            );

                            const privacyPolicyOption =
                              privacyPolicyBase &&
                              privacyPolicyBase.options.find(
                                option =>
                                  option.key === 'disclaimerConfirmation'
                              );

                            if (privacyPolicyOption) {
                              buyer['PrivacyPolicyAccepted'] =
                                privacyPolicyOption.value;
                            }

                            const privacyPolicyOptionalBase = privacyForm.list.find(
                              (inputBase: InputBase<any>) =>
                                inputBase.key === 'disclaimerOptional'
                            );

                            const privacyPolicyOptionalOption =
                              privacyPolicyOptionalBase &&
                              privacyPolicyOptionalBase.options.find(
                                option =>
                                  option.key === 'disclaimerOptionalConfirmation'
                              );

                            if (privacyPolicyOptionalOption) {
                              buyer['PrivacyPolicyOptionalAccepted'] =
                              privacyPolicyOptionalOption.value;
                            };
                          }

                          buyer = this.removeAttributes(buyer, [
                            'password',
                            'verifyPassword',
                            'CreateAccount',
                            'DifferentBillingAddress',
                            'verifyEmail',
                            'createAccountButton',
                            'address'
                          ]);

                          buyer = this.renameAttributes(buyer, [
                            {
                              from: 'FairCatalogue',
                              to: 'isFairCatalogChecked'
                            },
                            { from: 'Newsletter', to: 'isNewsletterChecked' }
                          ]);

                          buyer = this.separateAddress(buyer);

                          dataToSave.order['buyer'] = buyer;

                          break;
                        }

                        // privacy policy (value processed in buyer)
                        case 'privacy':
                          break;

                        // terms and conditions
                        case 'checkboxes':
                          const termsBase = form.list.find(
                            (term: InputBase<any>) => term.key === 'terms'
                          );

                          const tradeConditionOption =
                            termsBase &&
                            termsBase.options.find(
                              option => option.key === 'tradeConditions'
                            );

                          if (tradeConditionOption) {
                            dataToSave.order['TermsAndConditionsAccepted'] =
                              tradeConditionOption.value;
                          }
                          break;

                        // transform any other data
                        default: {
                          dataToSave.order[
                            formKey
                          ] = this._helperService.processFormValuesBeforeSave(formObj.value);
                          break;
                        }
                      }
                    }
                  }
                }
              }
            }

            // check if billing address is needed at all
            this._store
              .select(fromRoot.isDifferenBillingAddressUsed)
              .pipe(first())
              .subscribe(isDifferentBillingAddressUsed => {
                if (!isDifferentBillingAddressUsed) {
                  delete dataToSave.order['billingAddress'];
                }
              });

            const clearedTicketsWithHolders = ticketsWithHolders.map(ticket => {
              ticket.ticketHolder.dateOfBirth = this._helperService.getUTCdate(ticket.ticketHolder.dateOfBirth);
              ticket.day = DateTime.fromISO(ticket.day).toISODate();
              if (!isTicketHolderVisible) {
                return { ...ticket, ticketHolder: null };
              }
              return ticket;
            });
            dataToSave.order.tickets = clearedTicketsWithHolders;

            dataToSave.order.orderPrice = totalCost;
            dataToSave.order.orderTicketSendingMode = selectedSendingOption;
            dataToSave.order.userLanguage = language;

            /* remove unwanted properties */

            if (dataToSave.order.hasOwnProperty('billingAddress')) {
              delete dataToSave.order['billingAddress']['address'];

              // in case buyer used billing address from profile of logged in user add it to billing address
              if (billingaddressId !== null) {
                dataToSave.order.billingAddress.id = billingaddressId;
              }
            }

            if (dataToSave.order.hasOwnProperty('buyerinfo')) {
              delete dataToSave.order['buyerinfo']['address'];
              delete dataToSave.order['buyerinfo']['createAccountButton'];
            }

            // dataToSave.order.orderPrice = 0;
            if (anonymousTicket) {
              dataToSave.order.buyer.isNewsletterChecked = false;
              dataToSave.paymentProvider = 'FREE';
            }

            this._store.dispatch(new stepsActions.sendOrder(dataToSave));

            console.log(
              JSON.stringify(dataToSave)
                .replace(/“([^“]*?)“:/g, '$1:')
                .replace(/“/g, "'")
            );
          });
      });
  }
}
