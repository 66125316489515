var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as fromRoot from '../../app.reducer';
import { ActivatedRoute, Router } from '@angular/router';
import { OnDestroy, OnInit } from '@angular/core';
import { Subscription, combineLatest as observableCombineLatest } from 'rxjs';
import { CustomizationService } from '../../shared/services-with-reducers/customization/customization.service';
import { FormsService } from '../../shared/forms/forms.service';
import { StatusBarService } from '../../status-bar/status-bar.service';
import { StepsFormsService } from '../../shared/services-with-reducers/step-forms/steps-forms.service';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { HelperService } from '../../shared/services-with-reducers/helpers/helper.service';
var WebShopAccountVerificationComponent = /** @class */ (function () {
    function WebShopAccountVerificationComponent(_store, _customizationService, _formsService, _router, route, _statusBarService, _translateService, _stepsFormsService, _helperService) {
        var _this = this;
        this._store = _store;
        this._customizationService = _customizationService;
        this._formsService = _formsService;
        this._router = _router;
        this.route = route;
        this._statusBarService = _statusBarService;
        this._translateService = _translateService;
        this._stepsFormsService = _stepsFormsService;
        this._helperService = _helperService;
        this.subscriptions = new Subscription();
        this.subscriptions.add(observableCombineLatest(this._store.select(fromRoot.getProfile)).subscribe(function (data) { return __awaiter(_this, void 0, void 0, function () {
            var profile;
            return __generator(this, function (_a) {
                profile = data[0];
                this.userProfile = profile;
                return [2 /*return*/];
            });
        }); }));
    }
    WebShopAccountVerificationComponent.prototype.ngOnInit = function () {
    };
    WebShopAccountVerificationComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    return WebShopAccountVerificationComponent;
}());
export { WebShopAccountVerificationComponent };
