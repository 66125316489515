<div
  class="translation-row"
  *ngFor="let item of tree"
  [class.base]="item.isBaseLevel"
>
  <input
    class="checkbox"
    type="checkbox"
    [checked]="item.isActive"
    (change)="toggleCheck(item)"
  />
  <span class="key">
    {{ item.key }}
  </span>
  <span class="description" *ngIf="item.description">
    {{ item.description }} (max 2000 characters)
    <div class="input-row">
      <input
        [placeholder]="item.fallbackTranslation"
        type="text"
        [ngClass]="{ 'fallback-translation': item.isFallback }"
        required="required"
        [(ngModel)]="item.translation"
        class="translation"
        [attr.translation-path]="item.pathString"
        maxlength="2000"
        (keyup)="isNoMoreFallback(item)"
      />
      <button
        class="edit-translation"
        (click)="openModalWindow(item)"
        *ngIf="item.shouldDisplayRTE"
      >
        <svg class="icon">
          <use xlink:href="assets/defs.svg#icon-pencil"></use>
        </svg>
      </button>
    </div>
  </span>
  <app-translation-input
    *ngIf="!item.description"
    [tree]="item.subtree"
  ></app-translation-input>
</div>

<app-modal-window
  [(modalWindowActive)]="modalWindowOpen"
  [fullWidth]="true"
  [modalWindowTitle]="editedItem.description"
>
  <div modalWindow-body>
    <quill-editor [(ngModel)]="editedItem.translation"></quill-editor>

    <button
      modalWindow-close
      (click)="closeModalWindow($event)"
      class="button button-option"
      style="color:currentColor;"
    >
      <span>
        {{ 'modalWindow.close' | translate | hideTranslation }}
      </span>
    </button>
  </div>
  <!-- <button
            modalWindow-submit
            (click)="submitModalWindow($event)"
            class="button"
            [ngClass]="{ disabled: selectedEventID === null }"
        >
            <span [attr.data-translation]="'modalWindow.' + modalType">
                {{ 'modalWindow.' + modalType | translate | hideTranslation }}
            </span>
        </button>
 -->
</app-modal-window>
