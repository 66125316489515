var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { ActionTypes } from './ticket.actions';
import { createSelector } from 'reselect';
import _ from 'lodash';
import cloneDeep from 'lodash.clonedeep';
export var initialState = {
    ticketHolder: null,
    ticketHolderInputs: [],
    ticketHolderQuestionnaireInputs: [],
    claimedTicketHash: '',
    claimedTicketHashValid: null,
    tickets: null,
    parkingTickets: null,
    ticketHolderSubmitResult: false,
    ticketTypes: null,
    initialTicketTypes: null,
    ticketSendingOptions: [],
    activeHolderSlideIndex: 0,
    lastVoucherTicket: null,
    ticketBookings: { bookings: [], timestamp: null },
    lastPackageIndex: null,
    isPackageLoading: false,
    ticketHolderIndexes: null,
    isAnonymousTicketTaken: false,
    areTicketsSent: false
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    var clonedInitialState = cloneDeep(initialState);
    switch (action.type) {
        case ActionTypes.SET_SELECTED_TICKETS:
            var tickets = action.payload;
            return __assign({}, state, { tickets: tickets });
        case ActionTypes.SET_SELECTED_PARKING_TICKETS:
            var parkingTickets = action.payload;
            return __assign({}, state, { parkingTickets: parkingTickets });
        case ActionTypes.ADD_VOUCHER_TICKET:
            if (action.payload) {
                var voucherTicket_1 = action.payload.voucherTicket;
                if (voucherTicket_1) {
                    var uniqueId_1 = voucherTicket_1.uniqueId + "_" + voucherTicket_1.voucherCode;
                    var allTickets = state.tickets;
                    var ticketWithVoucher = cloneDeep(allTickets[voucherTicket_1.uniqueId]);
                    ticketWithVoucher.uniqueId = uniqueId_1;
                    ticketWithVoucher.voucherCode = voucherTicket_1.voucherCode;
                    ticketWithVoucher.voucherType = voucherTicket_1.voucherType;
                    ticketWithVoucher.sponsors = voucherTicket_1.sponsors;
                    ticketWithVoucher.releasedInMinutes = voucherTicket_1.releasedInMinutes;
                    /*Commented out because availableTickets in allTickets is used as a connection between ticket
                    limit and consumed tickets for that particular voucher, but API returns (available - ticket count)*/
                    //ticketWithVoucher.availableTickets = voucherTicket.availableTickets;
                    ticketWithVoucher.activatedTimestamp =
                        voucherTicket_1.activatedTimestamp;
                    ticketWithVoucher.allowedWorkshopsFull =
                        voucherTicket_1.allowedWorkshopsFull;
                    ticketWithVoucher.quantity = voucherTicket_1.quantity;
                    allTickets[uniqueId_1] = ticketWithVoucher;
                    var productGroups_1 = state.ticketTypes;
                    var productGroupsWithVoucherIndex = Object.keys(productGroups_1).find(function (productGroupIndex) {
                        var productGroup = productGroups_1[productGroupIndex];
                        var productGroupProducts = productGroup.products;
                        var voucherTicketProductIndex = productGroupProducts.findIndex(function (product) {
                            if (product.ticket != null) {
                                return product.ticket.uniqueId == voucherTicket_1.uniqueId;
                            }
                        });
                        if (voucherTicketProductIndex != -1) {
                            var product = cloneDeep(productGroupProducts[voucherTicketProductIndex]);
                            var newVoucherTicket = cloneDeep(product.ticket);
                            newVoucherTicket.uniqueId = uniqueId_1;
                            newVoucherTicket.voucherCode = voucherTicket_1.voucherCode;
                            newVoucherTicket.voucherType = voucherTicket_1.voucherType;
                            newVoucherTicket.sponsors = voucherTicket_1.sponsors;
                            newVoucherTicket.releasedInMinutes = voucherTicket_1.releasedInMinutes;
                            newVoucherTicket.availableTickets = voucherTicket_1.availableTickets;
                            newVoucherTicket.allowedWorkshopsFull = voucherTicket_1.allowedWorkshopsFull;
                            newVoucherTicket.quantity = voucherTicket_1.quantity;
                            product.ticket = newVoucherTicket;
                            productGroup.products.unshift(product);
                            return true;
                        }
                    });
                    var productGroup = productGroups_1[productGroupsWithVoucherIndex];
                    productGroup.section.expanded = true;
                    productGroups_1.splice(productGroups_1.indexOf(productGroup), 1);
                    productGroups_1.unshift(productGroup);
                    var isAnonymousTicket = ticketWithVoucher.personalizationType === 'anonymous';
                    if (isAnonymousTicket) {
                        productGroups_1.forEach(function (productGroup) {
                            productGroup.products.forEach(function (product) {
                                if (product.ticket != null) {
                                    product.ticket.isVisible = product.ticket.personalizationType === 'anonymous' && !!product.ticket.voucherCode;
                                }
                                else if (!!product.package) {
                                    product.package.contents.forEach(function (content) {
                                        content.packageGroups.forEach(function (packageGroup) {
                                            packageGroup.products.forEach(function (packageProduct) {
                                                if (packageProduct.ticket) {
                                                    packageProduct.ticket.isVisible = packageProduct.ticket.personalizationType === 'anonymous' && !!packageProduct.ticket.voucherCode;
                                                }
                                            });
                                        });
                                    });
                                }
                            });
                        });
                    }
                    return __assign({}, state, { tickets: allTickets, ticketTypes: productGroups_1, lastVoucherTicket: ticketWithVoucher, isAnonymousTicketTaken: isAnonymousTicket });
                }
                else {
                    return state;
                }
            }
            else {
                return state;
            }
        case ActionTypes.REMOVE_LAST_VOUCHER_ADDED:
            return __assign({}, state, { lastVoucherTicket: null });
        case ActionTypes.REMOVE_TICKET_BOOKINGS:
            return __assign({}, state, { ticketBookings: { bookings: [], timestamp: null } });
        case ActionTypes.ADD_TICKET_BOOKING:
            var payload_1 = action.payload;
            var ticketBookings = [];
            var timestamp = null;
            if (!payload_1) {
                return state;
            }
            else {
                var bookingAlreadyExist = state.ticketBookings.bookings.find(function (ticket) {
                    return ticket.ticketUniqueId === payload_1.ticketUniqueId;
                });
                if (!bookingAlreadyExist) {
                    ticketBookings = state.ticketBookings.bookings.slice();
                    ticketBookings.push({
                        ticketTypeId: payload_1.ticketTypeId,
                        groupId: payload_1.groupId,
                        count: payload_1.count,
                        availableTickets: payload_1.availableTickets,
                        isTicketSold: payload_1.isTicketSold,
                        ticketUniqueId: payload_1.ticketUniqueId,
                        ticketLimit: payload_1.ticketLimit,
                        ticketPersonId: payload_1.ticketPersonId
                    });
                }
                else {
                    ticketBookings = state.ticketBookings.bookings.map(function (ticket) {
                        if (ticket.ticketUniqueId === payload_1.ticketUniqueId) {
                            ticket.availableTickets = payload_1.availableTickets;
                            ticket.count = payload_1.count;
                            ticket.groupId = payload_1.groupId;
                            ticket.ticketTypeId = payload_1.ticketTypeId;
                            ticket.isTicketSold = payload_1.isTicketSold;
                            ticket.ticketUniqueId = payload_1.ticketUniqueId;
                            ticket.ticketLimit = payload_1.ticketLimit;
                            ticket.ticketPersonId = payload_1.ticketPersonId;
                        }
                        return ticket;
                    });
                }
                if (payload_1.availableTickets !== null) {
                    timestamp = Date.now();
                }
            }
            return __assign({}, state, { ticketBookings: { bookings: ticketBookings, timestamp: timestamp } });
        case ActionTypes.SET_ACTIVE_HOLDER_SLIDE_INDEX:
            var activeSlide = action.payload;
            return __assign({}, state, { activeHolderSlideIndex: activeSlide });
        case ActionTypes.SET_TICKET_HOLDER: {
            var ticketHolder = action.payload;
            return __assign({}, state, { ticketHolder: ticketHolder });
        }
        case ActionTypes.SET_TICKET_HOLDER_INPUTS: {
            var ticketHolderInputs = action.payload;
            return __assign({}, state, { ticketHolderInputs: ticketHolderInputs });
        }
        case ActionTypes.SET_TICKET_HOLDER_QUESTIONNAIRE: {
            var questionnaireInputs = action.payload;
            return __assign({}, state, { ticketHolderQuestionnaireInputs: questionnaireInputs });
        }
        case ActionTypes.SET_POST_TICKET_HOLDER_FORM_RESULT: {
            var ticketHolderSubmitResult = action.payload;
            return __assign({}, state, { ticketHolderSubmitResult: ticketHolderSubmitResult });
        }
        case ActionTypes.SET_CLAIMED_TICKET_HASH: {
            var hash = action.payload;
            return __assign({}, state, { claimedTicketHash: hash });
        }
        case ActionTypes.SET_CLAIMED_TICKET_HASH_VALID: {
            var valid = action.payload;
            return __assign({}, state, { claimedTicketHashValid: valid });
        }
        case ActionTypes.SET_TICKETS_TYPES:
            var ticketTypeList = action.payload;
            return __assign({}, state, { ticketTypes: ticketTypeList, initialTicketTypes: action.skipInitialTicketTypesUpdate ? state.initialTicketTypes : cloneDeep(ticketTypeList) });
        case ActionTypes.SET_SENDING_OPTIONS:
            var ticketSendingOptions = action.payload;
            return __assign({}, state, { ticketSendingOptions: ticketSendingOptions });
        case ActionTypes.REMOVE_VOUCHER: {
            var voucherCode_1 = action.payload.voucherCode;
            var skipAnonymousChecks = action.payload.skipAnonymousChecks;
            var skipSettingIsAnonyTicketTaken = !!action.payload.skipSettingIsAnonyTicketTaken;
            /*
              It can happen that we release voucher when hitting Homepage
              and reducers were already reseted to initial state
              In that case we skip removing the voucherTickets from store
            */
            if (state.ticketTypes && state.ticketTypes.length) {
                var updatedProductGroupsTickets = state.ticketTypes.map(function (productGroup) {
                    if (productGroup) {
                        // return only tickets without the removed voucher ticket
                        var updatedProducts = productGroup.products.filter(function (product) {
                            if (product.ticket != null) {
                                return product.ticket.voucherCode !== voucherCode_1;
                            }
                            return true;
                        });
                        return __assign({}, productGroup, { products: updatedProducts });
                    }
                    else {
                        return __assign({}, productGroup);
                    }
                });
                // now check if the group still have some tickets, if not remove even the group itself
                var updatedProductGroups = updatedProductGroupsTickets.filter(function (productGroup) {
                    return productGroup.products.length;
                });
                var wasAnonymousTicketTaken_1 = false;
                var isAnonymousTicketTaken_1 = false;
                var updatedTickets_1 = Object.keys(state.tickets).reduce(function (acc, currKey) {
                    if (state.tickets[currKey].voucherCode !== voucherCode_1) {
                        acc[currKey] = state.tickets[currKey];
                    }
                    else if (state.tickets[currKey].personalizationType === 'anonymous') {
                        wasAnonymousTicketTaken_1 = true;
                    }
                    return acc;
                }, {});
                //if RemoveVoucher action was called from GetVoucher action/effects then we have to skip these checks and visibility changes:
                if (!skipAnonymousChecks) {
                    // Check for anonymous
                    Object.keys(updatedTickets_1).forEach(function (id) {
                        var ticket = updatedTickets_1[id];
                        if (ticket.personalizationType === 'anonymous' && ticket.count > 0) {
                            isAnonymousTicketTaken_1 = true;
                        }
                    });
                }
                return __assign({}, state, { ticketTypes: !isAnonymousTicketTaken_1 && wasAnonymousTicketTaken_1 ? cloneDeep(state.initialTicketTypes) : updatedProductGroups, tickets: updatedTickets_1, activeHolderSlideIndex: initialState.activeHolderSlideIndex, isAnonymousTicketTaken: !skipSettingIsAnonyTicketTaken && !skipAnonymousChecks ? isAnonymousTicketTaken_1 : state.isAnonymousTicketTaken });
            }
            else {
                return state;
            }
        }
        case ActionTypes.SET_IS_PACKAGE_LOADING:
            var isLoading = action.payload;
            return __assign({}, state, { isPackageLoading: isLoading });
        case ActionTypes.SET_LAST_PACKAGE_INDEX:
            var packageIndex = action.payload;
            return __assign({}, state, { lastPackageIndex: packageIndex });
        case ActionTypes.ADD_TICKET_HOLDER_INDEXES:
            var ticketHolderIndexesToAdd = action.payload;
            if (ticketHolderIndexesToAdd != null) {
                var ticketHolderIndexes = __assign({}, state.ticketHolderIndexes);
                var indexes = Object.keys(ticketHolderIndexes);
                var lastIndex = indexes.length > 0 ? +indexes[indexes.length - 1] : -1;
                for (var i = 1; i <= ticketHolderIndexesToAdd; i++) {
                    ticketHolderIndexes["" + (lastIndex + i)] = '';
                }
                return __assign({}, state, { ticketHolderIndexes: ticketHolderIndexes });
            }
            return state;
        case ActionTypes.SET_TICKET_HOLDER_INDEX:
            var addTicketUniqueId = action.payload;
            if (addTicketUniqueId != null) {
                var ticketHolderIndexesToUpdate = __assign({}, state.ticketHolderIndexes);
                var firstEmptyTicketIndex = _.findKey(ticketHolderIndexesToUpdate, function (ticketUniqueId) { return ticketUniqueId == ''; });
                if (firstEmptyTicketIndex === undefined) {
                    var ticketHolderIndexes = Object.keys(ticketHolderIndexesToUpdate);
                    var newTicketHolderIndex = ticketHolderIndexes.length > 0 ? +ticketHolderIndexes[ticketHolderIndexes.length - 1] + 1 : 0;
                    ticketHolderIndexesToUpdate[newTicketHolderIndex] = addTicketUniqueId;
                }
                else {
                    ticketHolderIndexesToUpdate[firstEmptyTicketIndex] = addTicketUniqueId;
                }
                return __assign({}, state, { ticketHolderIndexes: ticketHolderIndexesToUpdate });
            }
            return state;
        case ActionTypes.REMOVE_TICKET_HOLDER_INDEX:
            var removeTicketUniqueId_1 = action.payload;
            if (removeTicketUniqueId_1 != null) {
                var ticketHolderIndexesToDelete = __assign({}, state.ticketHolderIndexes);
                var ticketHolderIndex = _.findLastKey(ticketHolderIndexesToDelete, function (ticketUniqueId) { return ticketUniqueId == removeTicketUniqueId_1; });
                delete ticketHolderIndexesToDelete[ticketHolderIndex];
                return __assign({}, state, { ticketHolderIndexes: ticketHolderIndexesToDelete });
            }
            return state;
        case ActionTypes.SET_IS_ANONYMOUS_TICKET_TAKEN:
            return __assign({}, state, { isAnonymousTicketTaken: action.payload });
        case ActionTypes.SET_ARE_TICKETS_SENT:
            return __assign({}, state, { areTicketsSent: action.payload });
        case ActionTypes.RESET_REDUCER:
            return clonedInitialState;
        default: {
            return state;
        }
    }
}
/**
 * Because the data structure is defined within the reducer it is optimal to
 * locate our selector functions at this level. If store is to be thought of
 * as a database, and reducers the tables, selectors can be considered the
 * queries into said database. Remember to keep your selectors small and
 * focused so they can be combined and composed to fit each particular
 * use-case.
 */
export var getTickets = function (state) { return state.tickets; };
export var getParkingTickets = function (state) { return state.parkingTickets; };
export var getTicketHolder = function (state) { return state.ticketHolder; };
export var getTicketHolderSubmitResult = function (state) {
    return state.ticketHolderSubmitResult;
};
export var getTicketHolderQuestionnaireInputs = function (state) {
    return state.ticketHolderQuestionnaireInputs;
};
export var getClaimedTicketHash = function (state) { return state.claimedTicketHash; };
export var getClaimedTicketHashValid = function (state) {
    return state.claimedTicketHashValid;
};
export var getTicketHolderInputs = function (state) { return state.ticketHolderInputs; };
export var ticketTypes = function (state) { return state.ticketTypes; };
export var ticketsBooking = function (state) { return state.ticketBookings; };
export var getSendingOptions = function (state) { return state.ticketSendingOptions; };
export var getActiveHolderSlideIndex = function (state) {
    return state.activeHolderSlideIndex;
};
export var getLastAddedVoucherTicket = function (state) {
    return state.lastVoucherTicket;
};
export var getLastPackageIndex = function (state) {
    return state.lastPackageIndex;
};
export var getIsPackageLoading = function (state) {
    return state.isPackageLoading;
};
export var getTicketHolderIndexes = function (state) {
    return state.ticketHolderIndexes;
};
export var getIsAnonymousTicketTaken = function (state) { return state.isAnonymousTicketTaken; };
export var getAreTicketsSent = function (state) { return state.areTicketsSent; };
export var getVoucherTickets = createSelector(getTickets, function (tickets) {
    var ticketsById;
    if (tickets) {
        ticketsById = Object.keys(tickets)
            .filter(function (keyUniqueId) {
            return (tickets[keyUniqueId].hasOwnProperty('voucherCode') &&
                tickets[keyUniqueId].voucherCode);
        })
            .map(function (keyUniqueId) {
            return tickets[keyUniqueId];
        });
    }
    else {
        ticketsById = [];
    }
    return ticketsById;
});
export var getTicketSelectedSendingOptions = createSelector(getSendingOptions, function (sendingOptions) {
    return sendingOptions
        .filter(function (option) {
        return option.isSelected;
    })
        .map(function (option) {
        return option.value;
    })[0];
});
export var getTotalPrice = createSelector(getTickets, function (tickets) {
    if (tickets) {
        var normalTicketPrice = parseFloat(Object.keys(tickets)
            .reduce(function (accu, curr) {
            var ticket = tickets[curr];
            if (ticket.hasOwnProperty('parking')) {
                return accu;
            }
            return accu + ticket.count * ticket.price;
        }, 0)
            .toFixed(2));
        return normalTicketPrice;
    }
    else {
        return 0;
    }
});
export var doTicketsNeedLegitimation = createSelector(getTickets, function (tickets) {
    if (tickets) {
        return Object.keys(tickets).reduce(function (aggr, key) {
            return ((tickets[key].requiresLegitimation && tickets[key].count > 0) || aggr);
        }, false);
    }
    return false;
});
export var doTicketsNeedWorkshops = createSelector(getTickets, function (tickets) {
    if (tickets) {
        return Object.keys(tickets).reduce(function (aggr, key) {
            if (tickets[key].hasOwnProperty('allowedWorkshops') &&
                tickets[key].allowedWorkshops &&
                Array.isArray(tickets[key].allowedWorkshops)) {
                return ((tickets[key].allowedWorkshops.length && tickets[key].count > 0) ||
                    aggr);
            }
            else {
                return aggr;
            }
        }, false);
    }
    return false;
});
export var idsOfWorkshopsToShowInWorkshopScreen = createSelector(getTickets, function (tickets) {
    if (tickets) {
        return Object.keys(tickets).reduce(function (aggr, key) {
            if (tickets[key].hasOwnProperty('allowedWorkshops') &&
                Array.isArray(tickets[key].allowedWorkshops)) {
                if (tickets[key].allowedWorkshops.length && tickets[key].count > 0) {
                    aggr.push.apply(aggr, tickets[key].allowedWorkshops);
                }
                return aggr;
            }
            else {
                return aggr;
            }
        }, []);
    }
    return [];
});
