import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { first, filter, delay } from 'rxjs/operators';

import { TopBarComponent } from '../top-bar.component';

import { UserService } from '../../../shared/services-with-reducers/user/user.service';
import { WindowSizeService } from '../../../shared/window-size/window-size.service';
import { CustomizationService } from '../../../shared/services-with-reducers/customization/customization.service';
import { HelperService } from '../../../shared/services-with-reducers/helpers/helper.service';

import { TicketsWidgetSettings } from '../../../_pages/widget-admin/widget-models.interface';

import * as fromRoot from '../../../app.reducer';

@Component({
  selector: 'app-top-bar-widget',
  templateUrl: './top-bar-widget.component.html',
  styleUrls: ['./top-bar-widget.component.scss']
})
export class TopBarWidgetComponent extends TopBarComponent implements OnInit {
  public widgetSettings: TicketsWidgetSettings;
  public displayHeader: boolean = false;

  constructor(
    _router: Router,
    _userService: UserService,
    _store: Store<fromRoot.State>,
    _windowSizeService: WindowSizeService,
    _customizationService: CustomizationService,
    _helperService: HelperService
  ) {
    super(
      _router,
      _userService,
      _store,
      _windowSizeService,
      _customizationService,
      _helperService
    );
  }

  ngOnInit() {
    this._store
      .select(fromRoot.getWidgetSettings)
      .pipe(
        filter(widgetSettings => {
          if (widgetSettings && widgetSettings.ticketsWidget) {
            return true;
          }
        }),
        delay(0),
        first()
      )
      .subscribe(({ ticketsWidget }) => {
        this.widgetSettings = ticketsWidget;
        this.displayHeader = Boolean(ticketsWidget.header);
      });
  }
}
