import { map, catchError } from 'rxjs/operators';
import * as additionalServicesActions from './additional-services.actions';

import { ErrorHandlingService } from '../../error-handling/error-handling.service';
import { Http } from '@angular/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StatusBarService } from '../../../status-bar/status-bar.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdditionalServicesService {
  constructor(
    private _errorHandlingService: ErrorHandlingService,
    private _http: Http,
    private _statusBarService: StatusBarService
  ) {}

  getMenuOptions(eventId) {
    return this._http
      .get(
        `${environment.protocol}${
          environment.webApiUrl
        }/event/${eventId}/menu-booking`
      )
      .pipe(
        map(res => {
          try {
            return res.json() || {};
          } catch (err) {
            this._statusBarService.setStatus(err, 'error');
          }
        }),
        catchError(e => {
          return this._errorHandlingService.errorHandler(e);
        })
      );
  }

  getWorkshopList(eventId) {
    return this._http
      .get(
        `${environment.protocol}${
          environment.webApiUrl
        }/workshop/workshop-selection/${eventId}`
      )
      .pipe(
        map(res => {
          try {
            return res.json() || {};
          } catch (err) {
            this._statusBarService.setStatus(err, 'error');
          }
        }),
        catchError(e => {
          return this._errorHandlingService.errorHandler(e);
        })
      );
  }

  postWorkshopBooking(data) {
    return this._http
      .post(
        `${environment.protocol}${
          environment.webApiUrl
        }/order/workshop-booking`,
        data
      )
      .pipe(
        map(res => {
          try {
            return res.json() || {};
          } catch (err) {
            this._statusBarService.setStatus(err, 'error');
          }
        }),
        catchError(e => {
          return this._errorHandlingService.errorHandler(e);
        })
      );
  }

  releaseBookings(orderUuid: string) {
    return this._http
      .post(
        `${environment.protocol}${
          environment.webApiUrl
        }/order/workshop-booking/${orderUuid}/release`,
        {}
      )
      .pipe(
        map(res => {
          try {
            return res.json() || {};
          } catch (err) {
            this._statusBarService.setStatus(err, 'error');
          }
        }),
        catchError(e => {
          return this._errorHandlingService.errorHandler(e);
        })
      );
  }
}
