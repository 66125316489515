import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isHiddenTranslation'
})
export class IsHiddenTranslationPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value.startsWith('-notshown-')) {
      return true;
    }
    return false;
  }
}
