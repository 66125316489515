<div class="page-padding-wrapper" appAdjustSideHeadline>
  <div class="section-headline">
    <h2 data-translation="menu-booking.title">
      {{ 'menu-booking.title' | translate | hideTranslation }}
    </h2>
    <div class="section-headline-side" data-translation="menu-booking.title">
      {{ 'menu-booking.title' | translate | hideTranslation }}
    </div>
  </div>
  <div class="section-body">
    <div class="table-content row" *ngFor="let day of menuListForDisplay">
      <div class="col-sm-2 menu-day">
        <span>{{ day.day }}</span>
      </div>
      <div class="col-sm-10 meal-block">
        <div class="row">
          <div
            class="col-sm-6 col-md-3 table-column"
            *ngFor="let menu of day.meals"
          >
            <label class="img-label" [for]="day.id + '_' + menu.id">
              <img src="{{ menu.image }}" class="menu-image" />
              <div class="menu-name">{{ menu.name }}</div>
            </label>
            <label class="decorated-radio-button">
              <input
                type="radio"
                name="{{ day.day }}"
                [id]="day.id + '_' + menu.id"
                [value]="menu.id"
                [checked]="day.selectedMenu === menu.id"
                (change)="onMenuSelection(menu, day)"
              />
              <span class="radio-button"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-step-navigation-buttons></app-step-navigation-buttons>
</div>
