var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import * as colorizerActions from './colorizer.actions';
import { Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { CssSkinModel } from '../shared/services-with-reducers/customization/customization.interfaces';
import { InjectionToken } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';
import { ColorizerService } from './colorizer.service';
import { generateSkinCss } from '../shared/services-with-reducers/customization/customization.styles';
export var COLORIZER_DEBOUNCE = new InjectionToken('Colorizer Debounce');
var ColorizerEffect = /** @class */ (function () {
    function ColorizerEffect(_colorizerService, actions$, debounce, _store) {
        if (debounce === void 0) { debounce = 50; }
        var _this = this;
        this._colorizerService = _colorizerService;
        this.actions$ = actions$;
        this.debounce = debounce;
        this._store = _store;
        this.loadAllStyles$ = this.actions$.pipe(ofType(colorizerActions.ActionTypes.GET_ALL_STYLES), debounceTime(this.debounce), switchMap(function () {
            return _this._colorizerService.loadAllEventStyles().pipe(map(function (allStyles) {
                return new colorizerActions.SetAllStyles(allStyles);
            }), catchError(function () { return of(new colorizerActions.SetAllStyles([])); }));
        }));
        this.loadStyle$ = this.actions$.pipe(ofType(colorizerActions.ActionTypes.LOAD_STYLE), debounceTime(this.debounce), switchMap(function (data) {
            var styleId = data.payload;
            return _this._colorizerService.loadEventStyle(styleId).pipe(map(function (styleProperties) {
                var cssString = generateSkinCss(styleProperties.styles);
                var cssSkin = {
                    priority: 5,
                    css: cssString,
                    variables: styleProperties.styles,
                    title: styleProperties.title,
                    id: styleId
                };
                _this._store.dispatch(new colorizerActions.SetStyleId(styleId));
                return new colorizerActions.SetSelectedStyle(cssSkin);
            }), catchError(function () { return of(new colorizerActions.SetSelectedStyle(null)); }));
        }));
        this.updateStyle$ = this.actions$.pipe(ofType(colorizerActions.ActionTypes.UPDATE_STYLE), debounceTime(this.debounce), switchMap(function (data) {
            return _this._colorizerService
                .updateEventStyle({ styles: data.payload.styles, title: data.payload.title }, data.payload.styleId)
                .pipe(map(function (statusCode) {
                return new colorizerActions.SaveSuccess(true);
            }), catchError(function () { return of(new colorizerActions.SaveSuccess(false)); }));
        }));
        this.saveEventStyles$ = this.actions$.pipe(ofType(colorizerActions.ActionTypes.SAVE_EVENT_STYLES), debounceTime(this.debounce), switchMap(function (data) {
            if (!data.payload)
                return Observable.empty();
            return _this._colorizerService.saveEventStyles(data.payload).pipe(map(function (statusCode) {
                return new colorizerActions.SaveSuccess(true);
            }), catchError(function () { return of(new colorizerActions.SaveSuccess(false)); }));
        }));
        this.createEventStyles$ = this.actions$.pipe(ofType(colorizerActions.ActionTypes.CREATE_STYLE), debounceTime(this.debounce), switchMap(function (data) {
            if (!data.payload)
                return Observable.empty();
            return _this._colorizerService.createEventStyle(data.payload).pipe(map(function (responseData) {
                _this._store.dispatch(new colorizerActions.AddStyleToList(responseData));
                return new colorizerActions.SaveSuccess(true);
            }), catchError(function () { return of(new colorizerActions.SaveSuccess(false)); }));
        }));
        this.saveOperatorStyles$ = this.actions$.pipe(ofType(colorizerActions.ActionTypes.SAVE_OPERATOR_STYLES), debounceTime(this.debounce), switchMap(function (data) {
            if (!data.payload)
                return Observable.empty();
            return _this._colorizerService.saveOperatorStyles(data.payload).pipe(map(function (statusCode) { return new colorizerActions.SaveSuccess(true); }), catchError(function () { return of(new colorizerActions.SaveSuccess(false)); }));
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], ColorizerEffect.prototype, "loadAllStyles$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], ColorizerEffect.prototype, "loadStyle$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], ColorizerEffect.prototype, "updateStyle$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], ColorizerEffect.prototype, "saveEventStyles$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], ColorizerEffect.prototype, "createEventStyles$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], ColorizerEffect.prototype, "saveOperatorStyles$", void 0);
    return ColorizerEffect;
}());
export { ColorizerEffect };
