/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cookies-popup.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./cookies-popup.component";
import * as i5 from "@angular/router";
import * as i6 from "@ngrx/store";
var styles_CookiesPopupComponent = [i0.styles];
var RenderType_CookiesPopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CookiesPopupComponent, data: {} });
export { RenderType_CookiesPopupComponent as RenderType_CookiesPopupComponent };
function View_CookiesPopupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "cookie-popup"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "info-text"], ["data-translation", "cookies.popup.text"]], [[8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickText($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "button", [["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [["data-translation", "cookies.popup.agree"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.textWithLink; _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("cookies.popup.agree")); _ck(_v, 4, 0, currVal_1); }); }
function View_CookiesPopupComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "cookie-popup"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "info-text"], ["data-translation", "cookies.popup.text"]], [[8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickText($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "button", [["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [["data-translation", "cookies.popup.agree"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.textWithLink; _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("cookies.popup.agree")); _ck(_v, 4, 0, currVal_1); }); }
export function View_CookiesPopupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CookiesPopupComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CookiesPopupComponent_2)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.hideDataProtectionOnHomePage && !_co.eventId); _ck(_v, 1, 0, currVal_0); var currVal_1 = (!_co.hideDataProtectionOnEventPage && _co.eventId); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_CookiesPopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cookies-popup", [], null, null, null, View_CookiesPopupComponent_0, RenderType_CookiesPopupComponent)), i1.ɵdid(1, 245760, null, 0, i4.CookiesPopupComponent, [i5.Router, i6.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CookiesPopupComponentNgFactory = i1.ɵccf("app-cookies-popup", i4.CookiesPopupComponent, View_CookiesPopupComponent_Host_0, { textWithLink: "textWithLink" }, {}, []);
export { CookiesPopupComponentNgFactory as CookiesPopupComponentNgFactory };
