import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var LoginService = /** @class */ (function () {
    function LoginService() {
        this.activeTab$ = new Subject();
    }
    LoginService.prototype.setActiveTab = function (tab) {
        this.activeTab$.next(tab);
    };
    LoginService.ngInjectableDef = i0.defineInjectable({ factory: function LoginService_Factory() { return new LoginService(); }, token: LoginService, providedIn: "root" });
    return LoginService;
}());
export { LoginService };
