import { ContingentDataModel } from './../tickets/ticket.interface';
import * as fromRoot from '../../../app.reducer';
import * as helperActions from './helper.actions';
import * as stepsActions from '../step-forms/steps-forms.actions';
import * as bowser from 'bowser';

import { ElementRef, Injectable, Renderer } from '@angular/core';
import {
  Observable,
  combineLatest as observableCombineLatest,
  Subscriber,
  BehaviorSubject,
  Subject
} from 'rxjs';
import { catchError, filter, first, map } from 'rxjs/operators';

import { ErrorHandlingService } from '../../error-handling/error-handling.service';
import { FormGroup } from '@angular/forms';
import { InputBase } from '../../forms/inputs/input-base.class';
import { HttpClient } from '@angular/common/http';
import {
  QuestionnaireInputResponseModel,
  EventSeriesModel,
} from './helper.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { StatusBarService } from '../../../status-bar/status-bar.service';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { saveAs } from 'file-saver/FileSaver';
import { TicketModel } from '../tickets/ticket.interface';
import { InputsListModel } from '../step-forms/step.interface';
import { getLocalStorageString, setLocalStorageString } from '../../app-utils';
import { AppConstants } from '../../app-constants';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  public listOfAllCountries: Array<string>;
  public appEl: any;
  public appRenderer: any;
  private showModalWindowBH$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public showModalWindow$: Observable<boolean> = this.showModalWindowBH$.asObservable();
  private voteYesNoBH$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public voteYesNo$: Observable<boolean> = this.voteYesNoBH$.asObservable();
  public isStepNavigationRendered: Subject<boolean> = new Subject<boolean>();
  public buyerQuestionnaire: InputBase<any>[] = [];
  public visitorQuestionnaire: InputBase<any>[] = [];
  private isReloadSections: boolean = false;
  private emailRequired: boolean = false;
  private verifyEmailRequired: boolean = false;

  constructor(
    private _http: HttpClient,
    private _errorHandlingService: ErrorHandlingService,
    private _statusBarService: StatusBarService,
    private _store: Store<fromRoot.State>,
    private _translateService: TranslateService,
    private _router: Router,
    private _route: ActivatedRoute
  ) {
    this._store.select(fromRoot.getAllCountriesList).subscribe(list => {
      this.listOfAllCountries = list;
    });
    this.isMobile();

    observableCombineLatest(
      this._store.select(fromRoot.getLanguage),
      this._store.select(fromRoot.getSelectedExhibitionId)
    )
      .pipe(
        filter(data => {
          return !!data[0];
        })
      )
      .subscribe(data => {
        let [lang, eventId] = data;
        if (eventId === null) {
          eventId = -1;
        }

        setTimeout(() => {
          this._store.dispatch(
            new helperActions.LoadAndSetTranslationsAction({ eventId, lang })
          );
        });
      });

    this._store
      .select(fromRoot.getTranslations)
      .pipe(filter(translations => !!translations))
      .subscribe(translations => {
        // remove old translations
        this._translateService.resetLang(this._translateService.currentLang);

        this._translateService.setTranslation(
          translations.language,
          translations.values
        );

        /* after the translations are loaded and set to translation service for use
        also change the active langueage to be used */

        this._translateService.use(translations.language);
        this._translateService.setDefaultLang(translations.language);
      });
  }

  /*   stdTimezoneOffset(date) {
    var jan = new Date(date.getFullYear(), 0, 1);
    var jul = new Date(date.getFullYear(), 6, 1);
    return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
  } */

  /*  isDstObserved(date) {
    return date.getTimezoneOffset() < this.stdTimezoneOffset(date);
  } */

  /*   dstDifferenceMinues(date) {
    const difference = this.stdTimezoneOffset(date);
    return difference;
  } */

  /* internal API */

  setRefsToAppComponent(renderer: Renderer, el: ElementRef) {
    this.appRenderer = renderer;
    this.appEl = el;
  }

  setLanguage(lang: string) {
    this._store.dispatch(new helperActions.SetActiveLanguage(lang));
  }

  setSupportedLanguages(languages: string[]) {
    this._translateService.addLangs(languages);
    this._store.dispatch(new helperActions.setSupportedLanguages(languages));
  }

  redirectAfterLogin() {
    //get selected event ID and its step, if both present go to last selected step after login, otherwise go to homepage
    observableCombineLatest([
      this._store.pipe(select(fromRoot.getSelectedExhibitionId)),
      this._store.pipe(select(fromRoot.getSelectedStep)),
      this._store.pipe(select(fromRoot.isEventSeriesPage)),
      this._store.pipe(select(fromRoot.getBuyerInfo)),
      this._store.pipe(select(fromRoot.getStepsValidity)),
      this._store.pipe(select(fromRoot.getClaimedTicketHash))
    ])
      .pipe(first())
      .subscribe(([selectedExhibitionId, selectedStep, eventSeriesPage, buyerInfo, validations, ticketClaimedHash]) => {
        if (!!buyerInfo) {
          let isInputFilledValue: boolean = buyerInfo.list.some(buyerInfoItem => {
            return buyerInfoItem.value;
          });

          if (isInputFilledValue) {
            this.showModalWindow(isInputFilledValue);
          };
        }

        const visibleSteps = Object.keys(validations)
          // make sure steps are ordered correctly
          .sort((a, b) => {
            return validations[a].order - validations[b].order;
          })
          // only navigate to visible routes
          .filter(stepKey => {
            return validations[stepKey].visible;
        });

        if (selectedExhibitionId !== null && selectedStep !== null) {
          const queryString = this._router.url.includes('?');
          const urlBase = this.isSelfregistration() ? 'self-registration' : 'webshop';

          if (selectedStep === 'payment') {
            console.log('%c currentStep ', 'background: #D46A6A; color: #fff', `/${urlBase}/${selectedExhibitionId}/${selectedStep}`);
          }

          if (!validations[selectedStep].showInStepNavigation) {
            const ticketStep = visibleSteps.find(step => step === 'tickets');
            selectedStep = validations[ticketStep].showInStepNavigation
              ? ticketStep
              : (validations[visibleSteps[0]].showInStepNavigation ? visibleSteps[0] : 'tickets');

            console.log('%c re-navigated URL ', 'background: #6BA099; color: #fff', `/${urlBase}/${selectedExhibitionId}/${selectedStep}`);
          }

          if (queryString) {
            // Bug 3830 - if CONTINUE is pressed for the first time on personalization step, user is redirected to tickets step instead of confirmation step
            this._route.queryParams.first().subscribe(params => {
              this._router.navigate([`${urlBase}/${selectedExhibitionId}/${selectedStep}`], {
                queryParams: params
              });
            });
          } else {
            this._router.navigate([
              `${urlBase}/${selectedExhibitionId}/${selectedStep}`
            ]);
          }
        } else if (!!eventSeriesPage && eventSeriesPage.isEventSeries) {
          this._router.navigate([`/series/${eventSeriesPage.eventSeriesId}`]);
        } else if (ticketClaimedHash) {
          this._router.navigate([`webshop-download/${selectedExhibitionId}`], {queryParams: {t: ticketClaimedHash}});
        } else {
          this._router.navigate(['/']);
        }
      });
  }

  voteYesOrNo(vote: boolean) {
    this.voteYesNoBH$.next(vote);
  }

  showModalWindow(show: boolean) {
    this.showModalWindowBH$.next(show);
  }

  getListOfCountries() {
    return this._http.get(
      `${environment.protocol}${environment.webApiUrl}/country/preferred`
      //`${environment.apiUrl}/country/preferred`,
    );
  }

  loadCountries() {
    return this._http.get(
      `${environment.protocol}${environment.webApiUrl}/country/list`
    );
  }

  checkZipcodeValidity(countryCode, zipCode): Observable<any> {
    if (!zipCode) {
      return new Observable<any>((subscriber: Subscriber<any>) =>
        subscriber.next({ isValidZipCode: true })
      );
    }

    return this._http.get(
      `${environment.protocol}${environment.webApiUrl}/country/${countryCode}/check-zip-code/${zipCode}`
    );
  }

  checkHashValidity(hash): Observable<any> {
    return this._http.get(
      `${environment.protocol}${environment.webApiUrl}/hash/status/${hash}`
    );
  }

  loadAndMergeTranslations(lang, eventId): Observable<any> {
    this.setTranslationsStatus(false);

    return this._http
      .get(
        `${environment.protocol}${environment.webApiUrl}/event/${eventId}/translations/${lang}`,
        { observe: 'response' }
      )
      .pipe(
        map((resp: any) => {
          this.setTranslationsStatus(true);
          
          if (resp.body.backend) {
            return Object.assign(resp.body.backend, resp.body.frontend);
          } else {
            return resp.body.frontend;
          }
        }),
        catchError(e => {
          this.setTranslationsStatus(true);
          return this._errorHandlingService.errorHandler(e);
        })
      );
  }

  setTranslationsStatus(isTranslationsLoaded) {
    this._store.dispatch(new helperActions.SetTranslationsLoaded(isTranslationsLoaded));
  }

  convertDate(strDate) {
    // strDate = '03.09.1979';
    const dateParts = strDate.split('.');
    const newDate = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
    return newDate;
  }

  toStringWithoutOffset(date: Date) {
    return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${(
      '0' + date.getDate()
    ).slice(-2)}T${('0' + date.getHours()).slice(-2)}:${(
      '0' + date.getMinutes()
    ).slice(-2)}`;
  }

  processFormValuesBeforeSave(formValues: any) {
    let normalizedData = {};
    Object.keys(formValues).forEach(groupKey => {
      normalizedData[groupKey] = formValues[groupKey];
      
      // if it is an object we are dealing with checkbox set
      if (typeof formValues[groupKey] === 'object') {
        const groupRegex = new RegExp(`^${groupKey}_`);
        const isDateOfBirth = groupKey === 'dateOfBirth';
        if (isDateOfBirth) {
          const dateOfBirth = formValues[groupKey];
          if (!!dateOfBirth) {
            formValues[groupKey] = this.getUTCdate(dateOfBirth);
            normalizedData[groupKey] = formValues[groupKey];
          }
        } else {
          Object.keys(formValues[groupKey]).forEach(checkboxId => {
            const clearedId = checkboxId.replace(groupRegex, '');
            normalizedData[clearedId] = formValues[groupKey][checkboxId];
          });
        }
      }
    });
    return normalizedData;
  }

  processQuestionnaireValuesBeforeSave(
    formControls: InputBase<any>[]
  ): Array<QuestionnaireInputResponseModel> {
    let normalizedQuestionnaire = [];
    formControls.forEach(group => {
      if (
        (group.controlType === 'dropdown' || group.controlType === 'radio') &&
        group.hasOwnProperty('value') &&
        !group.hidden &&
        (group.value || group.value === 0)
      ) {
        normalizedQuestionnaire.push({
          fieldId: Number(group.key),
          valueId: Number(group.value),
          text: null
        });
      } else if (
        group.controlType === 'textbox' &&
        group.hasOwnProperty('value') &&
        !group.hidden &&
        group.value
      ) {
        const keySplited = group.key.split('_');
        normalizedQuestionnaire.push({
          fieldId: Number(keySplited[0]),
          valueId: Number(keySplited[1]),
          text: group.value
        });
      } else if (group.controlType === 'checkbox' && !group.hidden) {
        group.options.forEach(option => {
          if (option.value) {
            normalizedQuestionnaire.push({
              fieldId: Number(group.key),
              valueId: Number(option.key),
              text: null
            });
          }
        });
      }
    });

    return normalizedQuestionnaire;
  }

  isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  getVoucherUrl(): string {
    let voucherURL: string;

    this._route.queryParams.pipe(first()).subscribe(params => {
      Object.keys(params).find(key => {
        if (key === "voucher") {
          return voucherURL = params.voucher;
        }
      });
    });
    return !!voucherURL ? voucherURL : '';
  }

  getTicketParams(): string {
    let ticketParams: string[] = [];

    this._route.queryParams.pipe(first()).subscribe(params => {
      let counter: number = 0;
      ticketParams = Object.keys(params).map(key => {
        if (key === 'tt' || key === 'pt' || key === 'amt') {
          return (counter++ === 0 ? '?' : '&') + `${key}=${params[key]}`;
        }
      });
    });

    return ticketParams.length > 1 ? ticketParams.join('') : null;
  }

  isMobile() {
    if (typeof navigator !== 'undefined') {
      return (
        navigator.userAgent.match(
          /Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i
        ) && true
      );
    }
    return false;
  }

  stepNavigationRendered(): Subject<boolean> {
    this.isStepNavigationRendered.next(true);
    return this.isStepNavigationRendered;
  }

  browserInfo() {
    const browserInfo = bowser.getParser(window.navigator.userAgent)[
      'parsedResult'
    ];

    const browserName =
      browserInfo.browser.name + ' version: ' + browserInfo.browser.version;
    const systemInfo =
      browserInfo.os.name + ' version: ' + browserInfo.os.versionName;
    const platformInfo = browserInfo.platform.type;

    const completeInfo =
      'Browser: ' +
      browserName +
      ', SystemInfo: ' +
      systemInfo +
      ', Platform: ' +
      platformInfo;

    return completeInfo;
  }

  triggerCallbackOnceFormValidationIsDone(form: FormGroup, callback: Function, isUsedWithoutTimeout?: boolean) {
    if (form.pending) {
      const interval = setInterval(() => {
        if (!form.pending) {
          callback();
          clearInterval(interval);
        }
      }, 200);
    } else {
      if (isUsedWithoutTimeout){
        callback();
      } else {
        setTimeout(() => {
          callback();
        }, 200);
      }
    }
  }

  isSelfregistration() {
    let isSelfRegistration;
    this._store
      .select(fromRoot.getSelfRegistration)
      .pipe(first())
      .subscribe((selfRegistration: boolean) => {
        isSelfRegistration = selfRegistration;
      });

    return isSelfRegistration;
  }

  isEventSeriesPage() {
    let isEventSeriesPage;
    this._store
      .select(fromRoot.isEventSeriesPage)
      .pipe(first())
      .subscribe((isEventSeriesPage: EventSeriesModel) => {
        isEventSeriesPage = isEventSeriesPage;
      });

    return isEventSeriesPage;
  }

  saveToFileSystem(blob, name) {
    /* const contentDispositionHeader: string = response.headers.get('Content-Disposition');
    const parts: string[] = contentDispositionHeader.split(';');
    const filename = parts[1].split('=')[1];
    const blob = new Blob([response._body], { type: 'text/plain' });
    saveAs(blob, filename); */

    saveAs(blob, name);
    this._store.dispatch(new helperActions.SetSpinnerValue(false));
  }

  openIframe(url) {
    //this._store.dispatch(new helperActions.SetIframeUrl(url));

    //open in new tab for now

    let win = window.open(url, '_blank');
    win.focus();
  }

  removeQueryParam(key: string, sourceURL: string) {
    const queryString =
      sourceURL.indexOf('?') !== -1 ? sourceURL.split('?')[1] : '';

    let rtn = sourceURL.split('?')[0];
    let param;
    let params_arr = [];

    if (queryString !== '') {
      params_arr = queryString.split('&');

      for (let i = params_arr.length - 1; i >= 0; i -= 1) {
        param = params_arr[i].split('=')[0];

        if (param === key) {
          params_arr.splice(i, 1);
        }
      }

      if (params_arr.length > 0) {
        rtn = rtn + '?';
      }

      rtn = rtn + params_arr.join('&');
    }

    return rtn;
  }

  loadBuyerQuestionnaireViaApi(stepName) {
    observableCombineLatest([
      this._store.pipe(select(fromRoot.getSelectedExhibitionId)),
      this._store.pipe(select(fromRoot.getLanguage)),
      this._store.pipe(select(fromRoot.getTickets)),
      this._store.pipe(select(fromRoot.getQuestionnaire)),
      this._store.pipe(select(fromRoot.getSelfRegQuestionnaire)),
      this._store.pipe(select(fromRoot.getQuestionnaireTicketPersonIds))
    ])
      .pipe(first())
      .subscribe(
        (
          data: [number, string, TicketModel, InputsListModel, InputsListModel, number[]]
        ) => {
          const [id, lang, tickets, questionnaire, selfRegQuestionnaire, questionnaireTicketPersonIds] = data;

          const ticketPersonIds: number[] = [];

          if (tickets) {
            Object.keys(tickets).forEach(ticketUniqueId => {
              const ticketById = tickets[ticketUniqueId];
              const ticketPersonId = ticketById.ticketPersonId;
              if (ticketById.count > 0 && !ticketPersonIds.includes(ticketPersonId)) {
                ticketPersonIds.push(ticketPersonId);
              }
            });
          }

          if (!this.isSelfregistration()) {
            if (questionnaireTicketPersonIds.length === ticketPersonIds.length) {
              return;
            }
  
            this._store.dispatch(
              new stepsActions.SetQuestionnaireTicketPersonIds(ticketPersonIds)
            );
          }

          this._store.dispatch(
            new stepsActions.GetBuyerQuestionnaire({
              eventId: id,
              stepName,
              lang,
              ticketPersonIds,
              previousQuestionnare: this.isSelfregistration()
                ? selfRegQuestionnaire
                : questionnaire
            })
          );
        }
      );
  }

  applyFullHeightToAllParentElements(
    startingElement: HTMLElement,
    displayBlock?: boolean
  ) {
    let parentElement = startingElement.parentElement;

    while (parentElement) {
      parentElement.style.height = '100%';

      if (displayBlock) {
        parentElement.style.display = 'block';
      }

      parentElement = parentElement.parentElement;
    }
  }

  getContingentTicketsFromLocalStorage() {
    const storedContingentTickets = getLocalStorageString(AppConstants.contingentTicketsReducer);

    if (!storedContingentTickets) {
      setLocalStorageString(AppConstants.contingentTicketsReducer, '');
    }

    const parsedContingentTickets = storedContingentTickets
      ? JSON.parse(storedContingentTickets)
      : {};

    return parsedContingentTickets;
  }

  getContingentDaysForTicket(ticketUniqueId: string): ContingentDataModel[] {
    const parsedContingentTickets = this.getContingentTicketsFromLocalStorage();

    return Object.keys(parsedContingentTickets)
      .filter(key => key.startsWith(ticketUniqueId))
      .map(key => parsedContingentTickets[key]);
  }

  checkQuestionnairesForDuplicates() {
    observableCombineLatest([
      this._store.pipe(select(fromRoot.getStepsForms)),
      this._store.pipe(select(fromRoot.getTicketHolderQuestionnaireInputs))
    ])
      .pipe(first())
      .subscribe(
        ([stepsForms, visitorQuestions]) => {
          const stepFormList = stepsForms['personal']['forms']['questionnaire'];

          if (!!stepFormList) {
            this.buyerQuestionnaire = [...stepFormList['list']];
            this.visitorQuestionnaire = visitorQuestions;
          }

          if (this.buyerQuestionnaire && this.visitorQuestionnaire) {
            Object.keys(this.buyerQuestionnaire).forEach(buyerKey => {
              const buyerQuestion = this.buyerQuestionnaire[buyerKey];

              Object.keys(this.visitorQuestionnaire).forEach((visitorKey) => {
                const visitorQuestion = this.visitorQuestionnaire[visitorKey];

                if (buyerQuestion.key === visitorQuestion.key) {
                  if (visitorQuestion.value === '' || visitorQuestion.value === undefined) {
                    visitorQuestion.value = buyerQuestion.value;

                    visitorQuestion.options.forEach(visitorOption => {
                      const buyerOption = buyerQuestion.options.find(buyerOption => buyerOption.key === visitorOption.key);

                      if (!!buyerOption) {
                        visitorOption.value = buyerOption.value;
                      }
                    });
                  }
                }
              });
            });
          }
        }
      );
  }

  //date time functions:
  //TODO: consider using moment.js
  getUTCdate(date: Date): Date {
    if (!!date) {
      if (typeof date === 'string' || date instanceof String) {
        date = new Date(date);
      }
      
      if (date instanceof Date) {
        return new Date(
          Date.UTC(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            0,
            0,
            0
          )
        );
      }
    }
    return null;
  }

  /**
   * Removes the time part of a date object.
   * @param date
   * @returns Date object without time part.
   */
  truncateTime(date: Date): Date {
    if (!!date && date instanceof Date) {
      date.setHours(0, 0, 0, 0);
      return date;
    }

    return date;
  }

  /**
   * Checks if both dates without their time part are the same.
   * @param date1
   * @param date2
   * @returns True if both dates are the same.
   */
  areDatesSameWOTime(date1: Date, date2: Date) : boolean {
    if (!date1 && !date2) {
      return true;
    }

    if (!!date1 && !!date2 && this.truncateTime(date1).valueOf() === this.truncateTime(date2).valueOf()) {
      return true;
    }

    return false;
  }

  /**
   * Checks if the first date is greater than the second date.
   * @param date1
   * @param date2
   * @returns True if the first date is greater than the second one.
   */
  isFirstDateGreaterWOTime(date1: Date, date2: Date) : boolean {
    if (!!date1 && !date2) {
      return true;
    }

    if (!!date1 && !!date2 && this.truncateTime(date1).valueOf() > this.truncateTime(date2).valueOf()) {
      return true;
    }

    return false;
  }

  addMinutesToDate(date: Date, minutes: number) : Date {
    if (!!date) {
      return new Date(date.getTime() + minutes * 60000);
    }

    return date;
  }

  setIsReloadSections(isReloadSections: boolean) {
    this.isReloadSections = isReloadSections;
  }

  getReloadSections(): boolean {
    return this.isReloadSections;
  }


  /**
   *
   * @param values array to sort
   * @returns array sorted by values ascending
   */
  sortValuesAsc(values) {
    values.sort(function(a, b){
      if(a < b) { return -1; }
      if(a > b) { return 1; }
      return 0;
    });
    return values;
  }

  setOriginalEmailValues(emailRequired: boolean, verifyEmailRequired: boolean) {
    this.emailRequired = emailRequired;
    this.verifyEmailRequired = verifyEmailRequired;
  }

  getOriginalEmailValues(value: string): boolean {
    if (value === 'email') {
      return this.emailRequired;
    } else if (value === 'verifyEmail') {
      return this.verifyEmailRequired;
    }

    return false;
  }

  setReloadRequired(reloadRequired: boolean) {
    this._store.dispatch(new helperActions.SetReloadRequired(reloadRequired));
  }
}
