<div appAdjustSideHeadline *ngIf="inputs && inputs.length">
  <div class="section-headline">
    <h2 data-translation="personalize.ticket-holder.section-title">{{ 'personalize.ticket-holder.section-title' | translate |
      hideTranslation }}</h2>
    <div class="section-headline-side" data-translation="personalize.ticket-holder.headline">
      {{'personalize.ticket-holder.headline' | translate | hideTranslation }}</div>
  </div>
  <div class="section-body visitor-body">
    <form [formGroup]="form" class="row">
      <app-df-input 
        *ngFor="let input of inputs" 
        [saveFormCallback]="inputsChanged" 
        [input]="input"
        [class]="input.cssClass" 
        [inputSet]="inputs" 
        [form]="form" 
        [action]="customAction">
      </app-df-input>
    </form>
  </div>
</div>